<template>
  <div class="image-container">
    <el-image class="image" :preview-src-list="[url]" :src="url" fit="contain" />
    <ht-button v-if="showDel" type="danger" icon="el-icon-delete" class="del-button" circle @click="deleteImg" />
  </div>
</template>

<script>

export default {
  name: 'DwImage',
  props: {
    url: { type: String, default: undefined },
    showDel: { type: Boolean, default: true }
  },
  methods: {
    deleteImg() {
      this.$emit('delete-img')
    }
  }
}
</script>

<style scoped lang="scss">
.image-container{
  position: relative;
  width: 100px;
  .image{
    width: 100px;
    height: 100px;
    border: 1px solid #b4bccc;
  }
  .del-button{
    position: absolute;
    bottom: 10px;
    left: 85px;
  }
}
</style>
