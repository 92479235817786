var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.buttons.length > 0
    ? _c(
        "div",
        [
          _vm.buttons[0]
            ? [
                _vm.buttons[0].isPopoverButton
                  ? _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          disabled: _vm.buttons[0].disabled,
                          type: "text",
                          "danger-text": _vm.buttons[0].isDangerText,
                          "confirm-title": _vm.buttons[0].confirmTitle,
                          "button-color":
                            _vm.buttons[0].buttonColor &&
                            !_vm.buttons[0].isDangerText
                              ? _vm.buttons[0].buttonColor
                              : "",
                          icon: _vm.buttons[0].icon,
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.handleButtonClick(_vm.buttons[0].name)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.buttons[0].name) + "\n    ")]
                    )
                  : _c(
                      "ht-button",
                      {
                        attrs: {
                          type: "text",
                          icon: _vm.buttons[0].icon,
                          disabled: _vm.buttons[0].disabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleButtonClick(_vm.buttons[0].name)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.buttons[0].name))]
                    ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.buttons.length > 1
            ? _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.getDropdownButtons, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        { key: index, attrs: { command: item.name } },
                        [
                          item.isPopoverButton
                            ? _c(
                                "ht-popover-button",
                                {
                                  attrs: {
                                    disabled: item.disabled,
                                    type: "text",
                                    "danger-text": item.isDangerText,
                                    "confirm-title": item.confirmTitle,
                                    "button-color":
                                      item.buttonColor && !item.isDangerText
                                        ? item.buttonColor
                                        : "",
                                    icon: item.icon,
                                  },
                                  on: {
                                    confirmed: function ($event) {
                                      return _vm.handleButtonClick(item.name)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.name) + "\n        ")]
                              )
                            : _c(
                                "ht-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: item.icon,
                                    disabled: item.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleButtonClick(item.name)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("other-button"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }