var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "templateForm",
          staticClass: "left-container",
          attrs: {
            model: _vm.templateForm,
            rules: _vm.templateFormRules,
            "label-width": "120px",
            inline: "",
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "line-three-item-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "表番：",
                    prop: "code",
                    "label-width": "80px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入表番",
                      maxlength: 30,
                      "show-word-limit": "",
                    },
                    on: { change: _vm.changedItem },
                    model: {
                      value: _vm.templateForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "code", $$v)
                      },
                      expression: "templateForm.code",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "表名：",
                    prop: "name",
                    "label-width": "80px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入表名",
                      maxlength: 30,
                      "show-word-limit": "",
                    },
                    on: { change: _vm.changedItem },
                    model: {
                      value: _vm.templateForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "name", $$v)
                      },
                      expression: "templateForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "关联部品：",
                    prop: "nskMaterialId",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        placeholder:
                          _vm.nskMaterialList.length > 0
                            ? "请选择部品"
                            : "当前无可选部品",
                        clearable: "",
                        remote: "",
                        "remote-method": _vm.getNskMaterialsList,
                      },
                      on: {
                        change: _vm.changedItem,
                        clear: function ($event) {
                          _vm.templateForm.nskMaterialId = null
                        },
                      },
                      model: {
                        value: _vm.templateForm.nskMaterialId,
                        callback: function ($$v) {
                          _vm.$set(_vm.templateForm, "nskMaterialId", $$v)
                        },
                        expression: "templateForm.nskMaterialId",
                      },
                    },
                    _vm._l(_vm.nskMaterialList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: "" + item.name + item.code,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          ref: "list",
          staticStyle: { "margin-top": "10px" },
          attrs: { data: _vm.templateForm.steps },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.NskCheckTemplateStepTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                prop: item.prop,
                "min-width": item.width || 100,
                "class-name": item.hasMultiple ? "has-multiple" : "",
                "show-overflow-tooltip": false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "span",
                          { class: { "is-required-title": item.isRequired } },
                          [
                            _vm._v(
                              _vm._s(
                                item.isRequired ? "*" + item.label : item.label
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        item.label === "检查类型"
                          ? _c(
                              "el-select",
                              {
                                attrs: { placeholder: "检查类型" },
                                on: {
                                  change: function ($event) {
                                    return _vm.changedItem("检查类型")
                                  },
                                },
                                model: {
                                  value: row.specType,
                                  callback: function ($$v) {
                                    _vm.$set(row, "specType", $$v)
                                  },
                                  expression: "row.specType",
                                },
                              },
                              _vm._l(
                                _vm.specTypeList,
                                function (listItem, itemIndex) {
                                  return _c("el-option", {
                                    key: itemIndex,
                                    attrs: {
                                      label: listItem.value,
                                      value: listItem.name,
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : item.label === "特性"
                          ? _c(
                              "el-select",
                              {
                                attrs: { placeholder: "特性" },
                                on: { change: _vm.changedItem },
                                model: {
                                  value: row.character,
                                  callback: function ($$v) {
                                    _vm.$set(row, "character", $$v)
                                  },
                                  expression: "row.character",
                                },
                              },
                              _vm._l(
                                _vm.characterList,
                                function (listItem, itemIndex) {
                                  return _c("el-option", {
                                    key: itemIndex,
                                    attrs: {
                                      label: listItem.value,
                                      value: listItem.name,
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : item.label === "规格说明"
                          ? [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "规格说明",
                                  type: "textarea",
                                  autosize: { minRows: 1, maxRows: 3 },
                                  maxlength: 30,
                                  "show-word-limit": "",
                                },
                                on: { change: _vm.changedItem },
                                model: {
                                  value: row.specDesc,
                                  callback: function ($$v) {
                                    _vm.$set(row, "specDesc", $$v)
                                  },
                                  expression: "row.specDesc",
                                },
                              }),
                            ]
                          : item.label === "下公差-标准尺寸-上公差"
                          ? [
                              row.specType === "NUM_MEASUREMENT"
                                ? [
                                    _c("dw-input-number", {
                                      attrs: {
                                        placeholder: "下公差",
                                        min: 0,
                                        max: 9999,
                                        precision: 2,
                                        controls: false,
                                      },
                                      on: { change: _vm.changedItem },
                                      model: {
                                        value: row.lowerTolerance,
                                        callback: function ($$v) {
                                          _vm.$set(row, "lowerTolerance", $$v)
                                        },
                                        expression: "row.lowerTolerance",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "divider" }, [
                                      _vm._v("-"),
                                    ]),
                                    _vm._v(" "),
                                    _c("dw-input-number", {
                                      attrs: {
                                        placeholder: "标准尺寸",
                                        min: 0,
                                        max: 9999,
                                        precision: 2,
                                        controls: false,
                                      },
                                      on: { change: _vm.changedItem },
                                      model: {
                                        value: row.standardValue,
                                        callback: function ($$v) {
                                          _vm.$set(row, "standardValue", $$v)
                                        },
                                        expression: "row.standardValue",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "divider" }, [
                                      _vm._v("-"),
                                    ]),
                                    _vm._v(" "),
                                    _c("dw-input-number", {
                                      attrs: {
                                        placeholder: "上公差",
                                        min: 0,
                                        max: 9999,
                                        precision: 2,
                                        controls: false,
                                      },
                                      on: { change: _vm.changedItem },
                                      model: {
                                        value: row.upperTolerance,
                                        callback: function ($$v) {
                                          _vm.$set(row, "upperTolerance", $$v)
                                        },
                                        expression: "row.upperTolerance",
                                      },
                                    }),
                                  ]
                                : _c("span", [_vm._v(_vm._s("-"))]),
                            ]
                          : item.label === "量具"
                          ? _c(
                              "el-select",
                              {
                                attrs: { multiple: "", placeholder: "量具" },
                                on: { change: _vm.changedItem },
                                model: {
                                  value: row.measuringTools,
                                  callback: function ($$v) {
                                    _vm.$set(row, "measuringTools", $$v)
                                  },
                                  expression: "row.measuringTools",
                                },
                              },
                              _vm._l(
                                _vm.toolList,
                                function (listItem, itemIndex) {
                                  return _c("el-option", {
                                    key: itemIndex,
                                    attrs: {
                                      label: listItem.value,
                                      value: listItem.name,
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : item.label === "等级"
                          ? _c(
                              "el-select",
                              {
                                attrs: { placeholder: "等级" },
                                on: { change: _vm.changedItem },
                                model: {
                                  value: row.level,
                                  callback: function ($$v) {
                                    _vm.$set(row, "level", $$v)
                                  },
                                  expression: "row.level",
                                },
                              },
                              _vm._l(
                                _vm.levelList,
                                function (listItem, itemIndex) {
                                  return _c("el-option", {
                                    key: itemIndex,
                                    attrs: {
                                      label: listItem.value,
                                      value: listItem.name,
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 80 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          icon: "el-icon-delete",
                          "confirm-title": "请确认是否要删除该检查项？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.onDeleteStep($index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row-item item-right" },
        [
          _c(
            "ht-button",
            {
              attrs: { type: "success", icon: "el-icon-plus" },
              on: { click: _vm.onAddStep },
            },
            [_vm._v("添加检查项")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "row-item item-center" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmSubmit("templateForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }