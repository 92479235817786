import Layout from '@/layout'

const RemoteGuidanceRouter = {
  path: '/remoteGuidance',
  component: Layout,
  redirect: '/remoteGuidance/list',
  name: 'remoteGuidanceMenu',
  meta: { title: '远程协助1.0', icon: 'remote_guidance' },
  children: [
    {
      path: '/remoteGuidance/list',
      component: () => import('@/views/remoteGuidance/list'),
      hidden: true,
      name: 'remoteGuidanceList',
      meta: { title: '远程协助1.0', breadcrumb: false, activeMenu: '/remoteGuidance' }
    },
    {
      path: '/remoteGuidance/view/:guidanceId',
      component: () => import('@/views/remoteGuidance/view'),
      hidden: true,
      props: true,
      name: 'remoteGuidanceView',
      meta: { title: '协助详情', activeMenu: '/remoteGuidance' }
    }
  ]
}

export default RemoteGuidanceRouter
