<template>
  <el-card>
    <ht-action-panel :on-search="mySearch" :on-clear="myClear">
      <template slot="left">
        <common-search ref="commonSearch" :staff-placeholder="'发起人'" @handleSelect="handleSelect" @selectDate="selectDate" />
      </template>
    </ht-action-panel>
    <ht-table
      ref="list"
      v-loading="isLoading"
      :data="list"
      :row-key="getRowKey"
      :default-sort="{prop: 'createdAt', order: 'descending'}"
      style="cursor: pointer"
      @row-click="handleRowClick"
      @selection-change="selectionChange"
      @sort-change="sortChange"
    >
      <el-table-column-selection v-if="showSelector" />
      <el-table-column label="序号" type="index" fixed="left" />
      <el-table-column v-for="i in labelToProps" :key="i.prop" :label="i.label" :prop="i.prop" :sortable="i.sortable">
        <template v-slot="{row}">
          <span v-if="i.format === 'status'">
            <status :status="row.status.value" :status-types="{ 草稿: 'danger' }" />
          </span>
          <span v-else>
            <span v-if="i.secondProp">{{ (row[i.firstProp] && row[i.firstProp][i.secondProp]) ? row[i.firstProp][i.secondProp] : '-' }}</span>
            <span v-else>{{ row[i.prop] }}</span>          </span>
        </template>
      </el-table-column>
      <ht-table-column-operation>
        <template v-slot="{row}">
          <ht-button icon="el-icon-view" type="text" @click="gotoView(row.id)">详情</ht-button>
          &nbsp;|&nbsp;&nbsp;
          <ht-popover-button
            icon="el-icon-delete"
            type="text"
            :danger-text="true"
            confirm-title="请确认是否要删除该条数据？"
            @confirmed="deleteItem(row)"
          >删除
          </ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getRemoteGuidanceApi, deleteRemoteGuidanceItemApi } from '@/api/remoteGuidance/remoteGuidance'
import { sortQuery } from '@/utils'

export default {
  extends: HtListContainer,
  props: {
    bizType: { type: String, default: undefined },
    source: { type: String, default: 'remoteGuidance' },
    bizId: { type: [Number, String], default: undefined }
  },
  data() {
    return {
      labelToProps: [
        { label: '编号', prop: 'guidanceNo' },
        { label: '类型', prop: 'bizType', firstProp: 'bizType', secondProp: 'value', sortable: 'custom' },
        { label: '发起人', prop: 'staff.fullName', firstProp: 'staff', secondProp: 'fullName', sortable: 'custom' },
        { label: '参会人员', prop: 'joinUserNamesString' },
        { label: '创建时间', prop: 'createdAt', sortable: 'custom' },
        { label: '会议时长', prop: 'duration', sortable: 'custom' },
        { label: '备注', prop: 'comment' }
      ],
      showSelector: false,
      selectedCheckRecord: [],
      searchForm: {
        searchStaffName: undefined,
        searchDate: undefined
      }
    }
  },
  created() {
    if (this.bizType) {
      this.listQuery.bizType = this.bizType
    } else {
      if (this.$route.meta.bizType !== 'REMOTE_GUIDANCE') {
        this.listQuery.bizType = this.$route.meta.bizType
      }
    }
    if (this.bizId) {
      this.listQuery.bizId = this.bizId
    }
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getRemoteGuidanceApi(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.joinUserNamesString = (item.joinUserNames && item.joinUserNames.length > 0) ? item.joinUserNames.join(',') : '-'
        })
        this.afterGetList(resp)
      })
    },
    selectionChange(val) {
      this.selectedCheckRecord = val
    },
    showSelectedCheckRecord() {
      this.showSelector = true
      this.$message({ message: '请勾选需要批量删除的记录', center: true, type: 'warning' })
    },
    handleSelect(id) {
      this.listQuery.staffId = id
    },
    selectDate(date) {
      if (date) {
        this.listQuery.startAt = date[0]
        this.listQuery.endAt = date[1]
      }
    },
    handleRowClick(row, column) {
      if (column.label !== '操作' && !this.showSelector) {
        this.gotoView(row.id)
      }
    },
    gotoView(guidanceId) {
      let goUrl = ''
      if (this.listQuery.bizType === 'DANGEROUS_WORK') {
        goUrl = 'dangerOperationView'
      } else if (this.source === 'deviceMgr') {
        goUrl = 'deviceMgrViewCooperateWork'
      } else if (!this.listQuery.bizType) {
        goUrl = 'remoteGuidanceView'
      }
      this.$router.push({ name: goUrl, params: { guidanceId: guidanceId, bizType: this.listQuery.bizType }})
    },
    mySearch() {
      const searchForm = this.$refs.commonSearch.searchForm
      if (searchForm.searchStaffName) {
        if (this.listQuery.staffId) {
          this.onSearch()
        } else {
          this.$message({ message: '请在下拉框内选择员工后进行搜索', center: true, type: 'warning' })
        }
      } else if (this.listQuery.startAt && this.listQuery.endAt) {
        this.onSearch()
      }
    },
    myClear() {
      this.$refs.commonSearch.searchForm = { searchStaffName: undefined, searchDate: undefined }
      this.onClear()
    },
    sortChange(ev) {
      this.listQuery.sort = sortQuery(ev)
      this.getList()
    },
    deleteItem(row) {
      deleteRemoteGuidanceItemApi(row.id).then(() => {
        this.$message({ message: '删除成功！', center: true, type: 'success' })
        this.getList()
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}
</style>
