<template>
  <el-card>
    <el-card style="margin-bottom: 50px">
      <div class="title-card-container">
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">编号:</span>
          <span class="textStyle" style="color: #48a0ff;font-weight: bold">{{ hiddenDangerRecordDetail.recordNo }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">检查类型:</span>
          <span class="textStyle">{{ hiddenDangerRecordDetail.checkType ? hiddenDangerRecordDetail.checkType.value : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">隐患等级:</span>
          <span class="textStyle">{{ hiddenDangerRecordDetail.grade ? hiddenDangerRecordDetail.grade.value : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">创建时间:</span>
          <span class="textStyle">{{ hiddenDangerRecordDetail.createdAt || '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">发现人:</span>
          <span class="textStyle">{{ hiddenDangerRecordDetail.staff ? hiddenDangerRecordDetail.staff.fullName : '' }}</span>
        </div>
        <div class="title-card-item-full-line">
          <span class="labelStyle labelWidth_70">位置区域:</span>
          <span class="textStyle">{{ hiddenDangerRecordDetail.locationAndInfo || '-' }}</span>
        </div>
        <div class="title-card-item-full-line">
          <div class="labelStyle labelWidth_70" style="vertical-align: top;">备注:</div>
          <div v-if="!editComment" style="display: flex; align-items: center">
            <span class="textStyle">{{ hiddenDangerRecordDetail.comment || '-' }}</span>
            <el-link type="primary" @click="editComment = true">修改备注</el-link>
          </div>
          <span v-else>
            <el-input
              v-model="comment"
              type="textarea"
              :rows="5"
              style="width:450px"
              placeholder="请输入备注"
            />
            <el-link type="success" @click="confirmUpdateComment('comment')">确定提交</el-link>
            <el-link type="danger" @click="cancelEdit('comment', 'editComment')">取消输入</el-link>
          </span>
        </div>
      </div>
    </el-card>
    <el-divider><i class="el-icon-chat-line-square" /> 上报详情</el-divider>
    <ht-action-panel>
      <template slot="right">
        <ht-button
          type="primary"
          plain
          icon="el-icon-document"
          :disabled="remoteGuidanceData && remoteGuidanceData.length === 0"
          @click="remoteGuidanceRecords()"
        >远程指导记录</ht-button>
        <!--            <template>-->
        <!--              <ht-button v-if="!showSelector" type="primary" icon="el-icon-document" @click="editItemsComment">编辑</ht-button>-->
        <!--              <template v-else>-->
        <!--                <ht-popover-button-->
        <!--                  type="success"-->
        <!--                  :disabled="selectedHiddenDangerRecords.length === 0"-->
        <!--                  icon="el-icon-circle-check"-->
        <!--                  :confirm-title="`已选择${selectedHiddenDangerRecords.length}条记录，是否确认修改备注？`"-->
        <!--                  @confirmed="confirmReport"-->
        <!--                >确认修改-->
        <!--                </ht-popover-button>-->
        <!--                <ht-button type="warning" @click="cancelEdit()">取消修改</ht-button>-->
        <!--              </template>-->
        <!--            </template>-->
      </template>
    </ht-action-panel>
    <ht-table
      ref="list"
      v-loading="isLoading"
      :span-method="arraySpanMethod"
      :data="list"
    >
      <el-table-column label="序号" type="index" fixed="left" />
      <el-table-column align="left" label="时间" prop="createdAt" width="150px" />
      <el-table-column label="内容" width="500px">
        <template v-slot="{row}">
          <span v-if="row.mediaType.name === 'TXT'">
            <p>{{ row.content }}</p>
          </span>
          <div v-else-if="row.mediaType.name === 'IMG'">
            <el-image
              style="width: 100px; height: 100px"
              :src="row.content"
              :preview-src-list="[row.content]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="文字描述">
        <template v-slot="{row}">
          <div class="edit-box">
            <div v-if="!editDescription" class="textStyle">{{ hiddenDangerRecordDetail.description || '-' }}</div>
            <el-input
              v-else
              v-model="description"
              class="edit-input"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入需要修改的文字描述"
              :disabled="!editDescription"
            />
            <div class="edit-button">
              <el-link v-if="!editDescription" type="primary" @click="editDescription = true">修改描述</el-link>
              <span v-else>
                <el-link type="success" @click="confirmUpdateComment('description')">确定提交</el-link>
                <el-link type="danger" @click="cancelEdit('description', 'editDescription')">取消输入</el-link>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
    </ht-table>

    <ht-dialog ref="dialog" title="远程指导记录" width="70%">
      <div slot="content">
        <remote-guidance-dialog
          v-if="remoteGuidanceData && remoteGuidanceData[0] && remoteGuidanceData[0].id"
          :guidance-id="remoteGuidanceData[0].id"
          :biz-type="hiddenDangerRecordDetail.guidanceBizType.value"
          :biz-view="true"
        />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import { getHiddenDangerRecordDetailApi, getHiddenDangerRecordItemsApi, updateHiddenDangerRecordDetail } from '@/api/secureEnvironmentMgr/hiddenDangerRegister'
import HtListContainer from '@/components/HtListContainer'
import { getRemoteGuidanceIdApi } from '@/api/remoteGuidance/remoteGuidance'
import remoteGuidanceDialog from '@/views/remoteGuidance/view'

export default {
  components: { remoteGuidanceDialog },
  extends: HtListContainer,
  props: {
    recordId: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      checkList: [],
      showSelector: false,
      selectedHiddenDangerRecords: [],
      hiddenDangerRecordDetail: {},
      labelToProps: [
        {
          label: '发送人',
          prop: 'fromUser'
        },
        {
          label: '消息类型',
          prop: 'mediaType'
        },
        {
          label: '内容',
          prop: 'content'
        },
        {
          label: '时间',
          prop: 'chatTime'
        }
      ],
      visible: false,
      allSelected: false,
      editComment: false,
      comment: undefined,
      description: undefined,
      editDescription: undefined,
      remoteGuidanceData: undefined
    }
  },
  created() {
    this.getHiddenDangerRecordDetail()
    this.getHiddenDangerRecordItems()
  },
  methods: {
    getHiddenDangerRecordDetail() {
      getHiddenDangerRecordDetailApi(this.recordId, this.listQuery).then((resp) => {
        this.hiddenDangerRecordDetail = resp
        this.comment = this.hiddenDangerRecordDetail.comment
        this.description = this.hiddenDangerRecordDetail.description
        getRemoteGuidanceIdApi(resp.guidanceBizType.name, this.recordId).then((resp) => {
          this.remoteGuidanceData = resp
        })
      })
    },
    getHiddenDangerRecordItems() {
      let count = 1
      getHiddenDangerRecordItemsApi(this.recordId, this.listQuery).then((resp) => {
        resp.forEach(item => {
          item.showEditComment = false
          resp[0].isStartMerge = true
          resp[0].count = count
          for (let i = 0; i < resp.length - 1; i++) {
            if (resp[i + 1].recordId !== resp[i].recordId) {
              count++
              resp[i + 1].isStartMerge = true
              resp[i + 1].count = count
            }
          }
        })
        this.list = resp
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.list.length > 1 && columnIndex === 3) {
        if (row.isStartMerge) {
          return [this.list.length, 1]
        } else {
          return [0, 0]
        }
      }
    },
    // showSelectorOfItems() {
    //   this.showSelector = true
    //   this.$message({
    //     message: '请在表格中勾选需要修改的记录，请注意：仅允许修改备注项。',
    //     center: true,
    //     type: 'warning'
    //   })
    // },
    // onSelectionChange(val) {
    //   this.selectedHiddenDangerRecords = val
    // },
    // confirmReport(row) {
    //   const items = [{ id: row.id, comment: row.comment }]
    //   updateHiddenDangerRecordItems(this.recordId, { items }).then(() => {
    //     this.$message({
    //       message: '更新成功～',
    //       center: true,
    //       type: 'success'
    //     })
    //     this.showSelector = false
    //     this.getHiddenDangerRecordItems()
    //   })
    // },
    confirmUpdateComment(key) {
      this.editComment = false
      this.editDescription = false

      const data = {}
      data[key] = this[key]
      updateHiddenDangerRecordDetail(this.recordId, data).then(() => {
        this.$message({
          message: '更新成功～',
          center: true,
          type: 'success'
        })
        this.getHiddenDangerRecordDetail()
      }).catch(() => { this.getHiddenDangerRecordDetail() })
    },
    cancelEdit(key, show) {
      this[show] = false
      this[key] = this.hiddenDangerRecordDetail[key]
    },
    remoteGuidanceRecords() {
      this.$refs.dialog.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-box {
  position: relative;
  text-align: left;
}
.edit-button {
  position: absolute;
  top: calc(50% - 14px);
  right: 3%;
  width: 100px;
  text-align: right;
}
.edit-input {
  width: 70%;
  text-align: left;
}
</style>
