var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "设备编号" },
                model: {
                  value: _vm.listQuery.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "code", $$v)
                  },
                  expression: "listQuery.code",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "设备名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.addDevice },
                },
                [_vm._v("新增设备")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list, "row-key": _vm.getRowKey },
          on: {
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.selectionChange,
          },
        },
        [
          _vm.showSelector ? _c("ht-table-column-selection") : _vm._e(),
          _vm._v(" "),
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.labelToProps, function (i) {
            return _c("ht-table-column", {
              key: i.prop,
              attrs: { label: i.label, prop: i.prop, width: i.width || 100 },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        i.format === "img"
                          ? _c(
                              "span",
                              [
                                row.imgUrls.length > 0
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                      },
                                      attrs: {
                                        src: row.imgUrls[0],
                                        "preview-src-list": row.imgUrls,
                                        fit: "contain",
                                      },
                                    })
                                  : _c("el-image", {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                      },
                                      attrs: {
                                        src: _vm.placeholderImg,
                                        fit: "contain",
                                      },
                                    }),
                              ],
                              1
                            )
                          : i.format === "status"
                          ? _c(
                              "span",
                              [
                                _c("status", {
                                  attrs: {
                                    status: row[i.prop],
                                    effect: "light",
                                    "status-types": {
                                      可用: "success",
                                      不可用: "danger",
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    i.secondProp
                                      ? row[i.prop][i.secondProp]
                                      : row[i.prop]
                                  )
                                ),
                              ]),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "蓝牙信标mac地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("div", [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(_vm._s(row.beaconMacAddr || "-")),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备二维码" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-popover",
                      {
                        staticStyle: { "margin-right": "20px" },
                        attrs: {
                          placement: "right",
                          width: "230",
                          height: "400",
                          trigger: "hover",
                        },
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("vue-qr", {
                              ref: "Qrcode",
                              attrs: { text: row.code, size: 200 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-link",
                          {
                            staticStyle: { width: "30px", margin: "0 auto" },
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("vue-qr", {
                              attrs: { text: row.code, size: 30 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "el-icon-printer" },
                        on: {
                          click: function ($event) {
                            return _vm.printQRCode()
                          },
                        },
                      },
                      [_vm._v("打印二维码")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备来源", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.isSyncFromOuter ? "外部同步" : "内部创建")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.editDevice(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-view" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoView(
                              row.code,
                              row.id,
                              row.isSyncFromOuter
                            )
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    !row.isSyncFromOuter
                      ? _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "el-icon-delete",
                              "danger-text": true,
                              "confirm-title": "请确认是否要删除该设备？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.delDevice(row)
                              },
                            },
                          },
                          [_vm._v("删除\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c("ht-print", { ref: "printQRCode" }, [
        _c("div", [_c("img", { attrs: { src: _vm.qRCodeImg } })]),
      ]),
      _vm._v(" "),
      _c(
        "ht-dialog",
        { ref: "macFormDialog", attrs: { title: "编辑mac地址", width: "30%" } },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.macFormDialog
                ? _c("mac-form", {
                    ref: "macFrom",
                    attrs: {
                      "device-id": _vm.deviceId,
                      "beacon-mac-addr": _vm.beaconMacAddr,
                    },
                    on: {
                      "on-cancel": _vm.cancelMacFrom,
                      "on-confirm": _vm.confirmMacFrom,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "addDeviceDialog",
          attrs: { title: "新增设备", width: "40%" },
          on: { close: _vm.closeAddDeviceDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.addDeviceDialog
                ? _c("device-form", {
                    ref: "addDeviceForm",
                    on: {
                      "on-cancel": _vm.cancelAddDeviceFrom,
                      "on-confirm": _vm.confirmAddDeviceFrom,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "editDeviceDialog",
          attrs: { title: "编辑设备", width: "40%" },
          on: { close: _vm.closeEditDeviceDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.editDeviceDialog
                ? _c("device-form", {
                    ref: "editDeviceForm",
                    attrs: { "is-edit": "" },
                    on: {
                      "on-cancel": _vm.cancelEditDeviceFrom,
                      "on-confirm": _vm.confirmEditDeviceFrom,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }