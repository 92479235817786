<script>
import Triangle from '@/assets/image/workflow/triangle.png'
import AndroidWebViewMixin from '@/views/components/androidWebViewMixin.vue'

export default {
  name: 'StepUiMixin',
  mixins: [AndroidWebViewMixin],
  props: {
    height: { type: String, default: null },
    isEdit: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    currentIndex: { type: Number, default: 0 },
    isLastStep: { type: Boolean, default: true },
    isFocusedItem: { type: String, default: null },
    properties: { type: Object, default() { return {} } },
    results: { type: Object, default() { return {} } }
  },
  data() {
    return {
      Triangle,
      activeImgIndex: 0,
      submitResults: this.results,
      showMoreFunction: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isEdit) {
        this.showMoreFunction = false
        this.registerAndroidCallbackHandlers({
          locationHandler: this.setLocation,
          photoUrlHandler: this.setPhotoUrl,
          dictationResultHandler: this.setDictationResult
        })
        this.nativeGetLocation()
        if (this.properties.guidanceVideo) {
          this.nativePlayTTS(this.properties.guidanceVideo)
        }
      }
    })
  },
  methods: {
    setLocation(latitude, longitude, location) {
      this.submitResults.latitude = latitude
      this.submitResults.longitude = longitude
      this.submitResults.location = location
    },
    setPhotoUrl(photoUrl) {
      this.submitResults.uploadPhotos.push(photoUrl)
      this.$refs.customCarousel.setActiveItem(this.submitResults.uploadPhotos.length - 1)
    },
    setDictationResult(result) {
      this.submitResults.factDesc = this.submitResults.factDesc !== null ? this.submitResults.factDesc + result : result
    },
    fullScreenReferenceImage() {
      this.$refs.referenceImage.clickHandler()
    },
    setActiveItem(index) {
      this.activeImgIndex = index
    },
    delPhoto() {
      this.submitResults.uploadPhotos.splice(this.activeImgIndex, 1)
    },
    fullScreenPhoto() {
      const refName = 'result' + this.activeImgIndex
      this.$refs[refName][0].clickHandler()
    },
    inputDesc() {
      // this.$refs.rectDescRef.focus()
      this.nativeStartDictation()
    },
    clearFactDesc() {
      this.submitResults.factDesc = null
    },
    toPrev() {
      this.$emit('to-prev', this.submitResults)
    },
    toNext() {
      this.$emit('to-next', this.submitResults)
    },
    moreFunction() {
      this.showMoreFunction = !this.showMoreFunction
    },
    toRemoteGuidance() {
      this.showMoreFunction = false
      this.$emit('to-remote-guidance', this.submitResults)
    },
    toAiQaAssistant() {
      this.showMoreFunction = false
      this.$emit('to-ai-qa-assistant', this.submitResults)
    },
    onVoice() {
      this.$emit('on-voice', this.properties.guidanceVideo)
    },
    saveRecord() {
      this.$emit('save-record', this.submitResults)
    },
    submitRecord() {
      this.$emit('submit-record', this.submitResults)
    }
  }
}
</script>

