// 组件
// 巡检模版

// 通用记录 UniversalRecordType
import UniversalRecordTypeImg from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/universalRecordType/img.jpg'
import UniversalRecordTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/universalRecordType/stepUi'
import UniversalRecordTypeConfig from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/universalRecordType/config'

// 判断结果 ResultRecordType
import ResultRecordTypeImg from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/resultRecordType/img.jpg'
import ResultRecordTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/resultRecordType/stepUi'
import ResultRecordTypeConfig from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/resultRecordType/config'

// 记录数值 ValueRecordType
import ValueRecordTypeImg from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/valueRecordType/img.jpg'
import ValueRecordTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/valueRecordType/stepUi'
import ValueRecordTypeConfig from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/valueRecordType/config'

// 隐患登记 HiddenDangerRecordType
import HiddenDangerRecordTypeImg from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/hiddenDangerRecordType/img.jpg'
import HiddenDangerRecordTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/hiddenDangerRecordType/stepUi'
import HiddenDangerRecordTypeConfig from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/hiddenDangerRecordType/config'

// 事故登记 AccidentRecordType
import AccidentRecordTypeImg from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/accidentRecordType/img.jpg'
import AccidentRecordTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/accidentRecordType/stepUi'
import AccidentRecordTypeConfig from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/accidentRecordType/config'

// 拍照记录 PhotoRecordType
import PhotoRecordTypeImg from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/photoRecordType/img.jpg'
import PhotoRecordTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/photoRecordType/stepUi'
import PhotoRecordTypeConfig from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/photoRecordType/config'

// 快速巡检 QuickPatrolRecordType
import QuickPatrolRecordTypeImg from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/quickPatrolRecordType/img.jpg'
import QuickPatrolRecordTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/quickPatrolRecordType/stepUi'
import QuickPatrolRecordTypeConfig from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/quickPatrolRecordType/config'

// 商品上架 GoodsInboundType
import GoodsInboundTypeImg from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/goodsInbound/img.jpg'
import GoodsInboundTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/goodsInbound/stepUi'

// 拣货任务 GoodsPickingType
import GoodsPickingTypeImg from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/goodsPicking/img.jpg'
import GoodsPickingTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/goodsPicking/stepUi'

// 扫码确认 ScanCodeAndConfirmType
import ScanCodeAndConfirmTypeImg from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/scanCodeAndConfirmType/img.jpg'
import ScanCodeAndConfirmTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/scanCodeAndConfirmType/stepUi'
import ScanCodeAndConfirmTypeConfig from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/scanCodeAndConfirmType/config'

// 语音记录数值 VoiceRecordValueType
import VoiceRecordValueTypeImg from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/voiceRecordValueType/img.jpg'
import VoiceRecordValueTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/voiceRecordValueType/stepUi'
import VoiceRecordValueTypeConfig from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/voiceRecordValueType/config'

// OCR记录数值 OCRRecordValueType
import OCRRecordValueTypeImg from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/ocrRecordValueType/img.jpg'
import OCRRecordValueTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/ocrRecordValueType/stepUi'
import OCRRecordValueTypeConfig from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/ocrRecordValueType/config'

// 蓝牙记录数值 KeyboardRecordValueType
import KeyboardRecordValueTypeImg from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/keyboardRecordValueType/img.jpg'
import KeyboardRecordValueTypeStepUi from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/keyboardRecordValueType/stepUi'
import KeyboardRecordValueTypeConfig from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/keyboardRecordValueType/config'

// Rules
import {
  UniversalRecordTypeRules,
  ResultRecordTypeRules,
  ValueRecordTypeRules,
  HiddenDangerRecordTypeRules,
  AccidentRecordTypeRules,
  PhotoRecordTypeRules,
  QuickPatrolRecordTypeRules,
  ScanCodeAndConfirmTypeRules,
  VoiceRecordValueTypeRules,
  OCRRecordValueTypeRules,
  KeyboardRecordValueTypeRules
} from '@/views/taskMgr/newTemplateMgr/components/configRules'

// Properties
import {
  UniversalRecordTypeProperties,
  ResultRecordTypeProperties,
  ValueRecordTypeProperties,
  HiddenDangerRecordTypeProperties,
  AccidentRecordTypeProperties,
  PhotoRecordTypeProperties,
  QuickPatrolRecordTypeProperties,
  GoodsInboundTypeProperties,
  GoodsPickingTypeProperties,
  ScanCodeAndConfirmTypeProperties,
  VoiceRecordValueTypeProperties,
  OCRRecordValueTypeProperties,
  KeyboardRecordValueTypeProperties
} from '@/views/taskMgr/newTemplateMgr/components/properties'

// Results
import {
  UniversalRecordTypeResults,
  ResultRecordTypeResults,
  ValueRecordTypeResults,
  HiddenDangerRecordTypeResults,
  AccidentRecordTypeResults,
  PhotoRecordTypeResults,
  QuickPatrolRecordTypeResults,
  GoodsInboundTypeResults,
  GoodsPickingTypeResults,
  ScanCodeAndConfirmTypeResults,
  VoiceRecordValueTypeResults,
  OCRRecordValueTypeResults,
  KeyboardRecordValueTypeResults
} from '@/views/taskMgr/newTemplateMgr/components/results'

export const StepsUiProperties = [
  {
    name: '通用记录',
    type: 'PATROL',
    tag: 'UniversalRecordType',
    img: UniversalRecordTypeImg,
    stepUi: UniversalRecordTypeStepUi,
    config: UniversalRecordTypeConfig,
    configRules: UniversalRecordTypeRules,
    properties: UniversalRecordTypeProperties,
    results: UniversalRecordTypeResults,
    show: true
  },
  {
    name: '判断结果',
    type: 'PATROL',
    tag: 'ResultRecordType',
    img: ResultRecordTypeImg,
    stepUi: ResultRecordTypeStepUi,
    config: ResultRecordTypeConfig,
    configRules: ResultRecordTypeRules,
    properties: ResultRecordTypeProperties,
    results: ResultRecordTypeResults,
    show: true
  },
  {
    name: '记录数值',
    type: 'PATROL',
    tag: 'ValueRecordType',
    img: ValueRecordTypeImg,
    stepUi: ValueRecordTypeStepUi,
    config: ValueRecordTypeConfig,
    configRules: ValueRecordTypeRules,
    properties: ValueRecordTypeProperties,
    results: ValueRecordTypeResults,
    show: true
  },
  {
    name: '隐患登记',
    type: 'PATROL',
    tag: 'HiddenDangerRecordType',
    img: HiddenDangerRecordTypeImg,
    stepUi: HiddenDangerRecordTypeStepUi,
    config: HiddenDangerRecordTypeConfig,
    configRules: HiddenDangerRecordTypeRules,
    properties: HiddenDangerRecordTypeProperties,
    results: HiddenDangerRecordTypeResults,
    show: true
  },
  {
    name: '事故登记',
    type: 'PATROL',
    tag: 'AccidentRecordType',
    img: AccidentRecordTypeImg,
    stepUi: AccidentRecordTypeStepUi,
    config: AccidentRecordTypeConfig,
    configRules: AccidentRecordTypeRules,
    properties: AccidentRecordTypeProperties,
    results: AccidentRecordTypeResults,
    show: true
  },
  {
    name: '拍照记录',
    type: 'PATROL',
    tag: 'PhotoRecordType',
    img: PhotoRecordTypeImg,
    stepUi: PhotoRecordTypeStepUi,
    config: PhotoRecordTypeConfig,
    configRules: PhotoRecordTypeRules,
    properties: PhotoRecordTypeProperties,
    results: PhotoRecordTypeResults,
    show: true
  },
  {
    name: '快检清单',
    type: 'PATROL',
    tag: 'QuickPatrolRecordType',
    img: QuickPatrolRecordTypeImg,
    stepUi: QuickPatrolRecordTypeStepUi,
    config: QuickPatrolRecordTypeConfig,
    configRules: QuickPatrolRecordTypeRules,
    properties: QuickPatrolRecordTypeProperties,
    results: QuickPatrolRecordTypeResults,
    show: process.env.VUE_APP_CONFIG_HAS_CHECK_LIST === 'true'
  },
  // 临时拣货相关
  {
    name: '商品上架',
    type: 'PATROL',
    tag: 'GoodsInboundType',
    img: GoodsInboundTypeImg,
    stepUi: GoodsInboundTypeStepUi,
    properties: GoodsInboundTypeProperties,
    results: GoodsInboundTypeResults,
    show: true
  },
  {
    name: '拣货任务',
    type: 'PATROL',
    tag: 'GoodsPickingType',
    img: GoodsPickingTypeImg,
    stepUi: GoodsPickingTypeStepUi,
    properties: GoodsPickingTypeProperties,
    results: GoodsPickingTypeResults,
    show: true
  },
  // 临时准入相关
  {
    name: '扫码确认',
    type: 'PATROL',
    tag: 'ScanCodeAndConfirmType',
    img: ScanCodeAndConfirmTypeImg,
    stepUi: ScanCodeAndConfirmTypeStepUi,
    config: ScanCodeAndConfirmTypeConfig,
    configRules: ScanCodeAndConfirmTypeRules,
    properties: ScanCodeAndConfirmTypeProperties,
    results: ScanCodeAndConfirmTypeResults,
    show: true
  },
  {
    name: '语音记录数值',
    type: 'PATROL',
    tag: 'VoiceRecordValueType',
    img: VoiceRecordValueTypeImg,
    stepUi: VoiceRecordValueTypeStepUi,
    config: VoiceRecordValueTypeConfig,
    configRules: VoiceRecordValueTypeRules,
    properties: VoiceRecordValueTypeProperties,
    results: VoiceRecordValueTypeResults,
    show: true
  },
  {
    name: 'OCR记录数值',
    type: 'PATROL',
    tag: 'OCRRecordValueType',
    img: OCRRecordValueTypeImg,
    stepUi: OCRRecordValueTypeStepUi,
    config: OCRRecordValueTypeConfig,
    configRules: OCRRecordValueTypeRules,
    properties: OCRRecordValueTypeProperties,
    results: OCRRecordValueTypeResults,
    show: true
  },
  {
    name: '蓝牙记录数值',
    type: 'PATROL',
    tag: 'KeyboardRecordValueType',
    img: KeyboardRecordValueTypeImg,
    stepUi: KeyboardRecordValueTypeStepUi,
    config: KeyboardRecordValueTypeConfig,
    configRules: KeyboardRecordValueTypeRules,
    properties: KeyboardRecordValueTypeProperties,
    results: KeyboardRecordValueTypeResults,
    show: true
  }
]
