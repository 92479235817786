<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.name" placeholder="设备名称" />
      </template>
    </ht-action-panel>
    <ht-action-panel>
      <template slot="right">
        <ht-button v-if="list.length > 0" type="success" icon="el-icon-location" @click="openLocationDialog">查看设备位置</ht-button>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list">
      <el-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in DevicesAndAppsListTitle"
        :key="item.prop"
        :fixed="item.fixed"
        :label="item.label"
        :min-width="item.width || 100"
        :prop="item.prop"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '已安装产品App'">{{ row.nameVersionArray.toString() }}</span>
          <el-tag v-else-if="item.label === '设备状态'" :type="DeviceStatusStyleEnum[row[item.prop]]">{{ DeviceStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation>
        <template v-slot="{row}">
          <ht-button type="text" @click="openTraceDialog(row)">查看轨迹</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />

    <ht-dialog ref="deviceLocationsDialog" title="设备位置" width="1000px" :close-on-click-modal="false" @close="closeLocationDialog">
      <div slot="content">
        <device-locations v-if="showDeviceLocations" ref="deviceLocations " map-id="deviceLocations" :location-arr="deviceLocationArr" :center="deviceLocationCenter" />
      </div>
    </ht-dialog>

    <ht-dialog ref="deviceTracksDialog" title="设备轨迹" width="1000px" :close-on-click-modal="false" @close="closeTraceDialog">
      <div slot="content">
        <device-tracks v-if="showDeviceTracks" ref="deviceTracks" map-id="deviceTracks" :track-arr="deviceTrackArr" :center="deviceTrackCenter" :position="devicePosition" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getBoundAppsApi } from '@/api/devicesAndApps/devicesAndApps'
import { DevicesAndAppsListTitle } from '@/views/devicesAndApps/tableTitle'
import { DeviceStatusEnum, DeviceStatusStyleEnum } from '@/views/devicesAndApps/enum'
import DeviceTracks from '@/views/components/map/deviceTracks'
import DeviceLocations from '@/views/components/map/deviceLocations'
import { calculateCenter } from '@/utils/business'

export default {
  name: 'List',
  components: { DeviceLocations, DeviceTracks },
  extends: HtListContainer,
  data() {
    return {
      DevicesAndAppsListTitle, DeviceStatusEnum, DeviceStatusStyleEnum,
      showDeviceLocations: false,
      showDeviceTracks: false,
      deviceTrackArr: [],
      deviceTrackCenter: [],
      devicePosition: [],
      deviceLocationArr: [],
      deviceLocationCenter: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    calculateCenter,
    getList() {
      this.beforeGetList()
      getBoundAppsApi(this.listQuery).then((resp) => {
        resp.items.forEach((item) => {
          item.nameVersionArray = []
          item.installedDeviceAppVersions.forEach((version) => {
            item.nameVersionArray.push(`${version.deviceAppName} ${version.deviceAppVersion}`)
          })
          item.tracks = item.locationTrack.map(location => [location.longitude, location.latitude])
          item.center = this.calculateCenter(item.tracks)
        })
        this.afterGetList(resp)
      })
    },
    openTraceDialog(row) {
      if (row.tracks.length > 0) {
        this.deviceTrackArr = row.tracks
        this.deviceTrackCenter = row.center
        this.devicePosition = row.tracks[row.tracks.length - 1]
        this.showDeviceTracks = true
        this.$refs.deviceTracksDialog.dialogVisible = true
      } else {
        this.$message({ message: `当前设备无轨迹信息`, type: 'success', center: true })
      }
    },
    closeTraceDialog() {
      this.deviceTrackArr = []
      this.deviceTrackCenter = []
      this.devicePosition = []
      this.showDeviceTracks = false
      this.$refs.deviceTracksDialog.dialogVisible = false
    },
    openLocationDialog() {
      this.deviceLocationArr = this.list
        .map(item => item.locationTrack.length > 0 ? item.locationTrack[item.locationTrack.length - 1] : null)
        .filter(item => item !== null)
        .map(location => [location.longitude, location.latitude])
      if (this.deviceLocationArr.length > 0) {
        this.deviceLocationCenter = this.calculateCenter(this.deviceLocationArr)
        this.showDeviceLocations = true
        this.$refs.deviceLocationsDialog.dialogVisible = true
      } else {
        this.$message({ message: `当前无设备位置信息`, type: 'success', center: true })
      }
    },
    closeLocationDialog() {
      this.deviceLocationArr = []
      this.deviceLocationCenter = []
      this.showDeviceLocations = false
      this.$refs.deviceLocationsDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
</style>
