var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showInput
        ? _c(
            "el-form",
            {
              ref: "equipmentForm",
              attrs: {
                model: _vm.equipmentForm,
                rules: _vm.equipmentFormRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备名称：", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择设备名称" },
                          on: { change: _vm.handleNameChange },
                          model: {
                            value: _vm.equipmentForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipmentForm, "name", $$v)
                            },
                            expression: "equipmentForm.name",
                          },
                        },
                        _vm._l(_vm.EquipmentTypesEnum, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.value, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.equipmentForm.isWhole
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "产权编号：", prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入产权编号",
                              maxlength: "15",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.equipmentForm.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipmentForm, "code", $$v)
                              },
                              expression: "equipmentForm.code",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格型号：", prop: "specModel" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入规格型号",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.equipmentForm.specModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipmentForm, "specModel", $$v)
                          },
                          expression: "equipmentForm.specModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备厂家：", prop: "factoryInfo" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入设备厂家",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.equipmentForm.factoryInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipmentForm, "factoryInfo", $$v)
                          },
                          expression: "equipmentForm.factoryInfo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _vm.equipmentForm.isWhole
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "出厂日期：", prop: "producedAt" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "picker-options": _vm.forbiddenDate,
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择出厂日期",
                            },
                            model: {
                              value: _vm.equipmentForm.producedAt,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipmentForm, "producedAt", $$v)
                              },
                              expression: "equipmentForm.producedAt",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出厂编号：", prop: "producedNumber" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入出厂编号",
                          maxlength: "15",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.equipmentForm.producedNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipmentForm, "producedNumber", $$v)
                          },
                          expression: "equipmentForm.producedNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "unit-form-item",
                      attrs: {
                        label: "独立高度：",
                        prop: "independentHeight'",
                      },
                    },
                    [
                      [
                        _c("el-input-number", {
                          staticClass: "flex-1",
                          attrs: {
                            precision: 2,
                            controls: false,
                            placeholder: "请输入独立高度",
                          },
                          model: {
                            value: _vm.equipmentForm.independentHeight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.equipmentForm,
                                "independentHeight",
                                $$v
                              )
                            },
                            expression: "equipmentForm.independentHeight",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "unit" }, [_vm._v("米")]),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "unit-form-item",
                      attrs: { label: "设备价值：", prop: "equipmentValue" },
                    },
                    [
                      [
                        _c("el-input-number", {
                          staticClass: "flex-1",
                          attrs: {
                            precision: 2,
                            controls: false,
                            placeholder: "请输入设备价值",
                          },
                          model: {
                            value: _vm.equipmentForm.equipmentValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipmentForm, "equipmentValue", $$v)
                            },
                            expression: "equipmentForm.equipmentValue",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "unit" }, [_vm._v("万元")]),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备区域：", prop: "province" } },
                    [
                      _c("dw-china-zones-select", {
                        ref: "zones",
                        attrs: {
                          "province-code": _vm.chinaZonesSelected.provinceCode,
                          "city-code": _vm.chinaZonesSelected.cityCode,
                          "district-code": _vm.chinaZonesSelected.districtCode,
                          "province-name": _vm.chinaZonesSelected.provinceName,
                          "city-name": _vm.chinaZonesSelected.cityName,
                          "district-name": _vm.chinaZonesSelected.districtName,
                        },
                        on: {
                          "update:provinceCode": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "provinceCode",
                              $event
                            )
                          },
                          "update:province-code": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "provinceCode",
                              $event
                            )
                          },
                          "update:cityCode": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "cityCode",
                              $event
                            )
                          },
                          "update:city-code": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "cityCode",
                              $event
                            )
                          },
                          "update:districtCode": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "districtCode",
                              $event
                            )
                          },
                          "update:district-code": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "districtCode",
                              $event
                            )
                          },
                          "update:provinceName": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "provinceName",
                              $event
                            )
                          },
                          "update:province-name": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "provinceName",
                              $event
                            )
                          },
                          "update:cityName": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "cityName",
                              $event
                            )
                          },
                          "update:city-name": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "cityName",
                              $event
                            )
                          },
                          "update:districtName": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "districtName",
                              $event
                            )
                          },
                          "update:district-name": function ($event) {
                            return _vm.$set(
                              _vm.chinaZonesSelected,
                              "districtName",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址：", prop: "address" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入详细地址",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.equipmentForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipmentForm, "address", $$v)
                          },
                          expression: "equipmentForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _vm.equipmentForm.isWhole
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否异常：", prop: "isAbnormal" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.equipmentForm.isAbnormal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.equipmentForm, "isAbnormal", $$v)
                                },
                                expression: "equipmentForm.isAbnormal",
                              },
                            },
                            _vm._l(_vm.EquipmentAbnormalEnum, function (item) {
                              return _c(
                                "el-radio",
                                { key: item.name, attrs: { label: item.name } },
                                [_vm._v(_vm._s(item.value))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.equipmentForm.isWhole !== null
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否整机：", prop: "isWhole" } },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.equipmentForm.isWhole ? "是" : "否") +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "设备图片：", prop: "img" } },
                [
                  [
                    _vm.equipmentForm.img
                      ? _c("dw-image", {
                          attrs: { url: _vm.equipmentForm.img },
                          on: { "delete-img": _vm.onDeleteImage },
                        })
                      : _c("ht-upload-button", {
                          attrs: {
                            "file-type": ["img"],
                            "key-prefix": "workflow",
                            "select-img": "",
                          },
                          on: { "on-file-uploaded": _vm.onImageUploaded },
                        }),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "list-form-item",
                  attrs: { label: "设备资料：", prop: "documentInformation" },
                },
                [
                  _vm.showInput
                    ? _c("ht-upload-button", {
                        attrs: {
                          "file-type": ["doc", "pdf", "img"],
                          "key-prefix": "equipment",
                          "multiple-selection": "",
                          "button-title": "上传文件",
                        },
                        on: { "on-files-uploaded": _vm.onFilesUploaded },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("document-list", {
                attrs: {
                  "document-list": _vm.equipmentForm.documentInformation,
                  "show-operation": _vm.showInput,
                },
                on: { "on-delete": _vm.onDeleteDocument },
              }),
            ],
            1
          )
        : [
            _c(
              "el-descriptions",
              {
                staticClass: "equipment-descriptions",
                attrs: {
                  title: "设备信息",
                  column: 2,
                  size: "medium",
                  border: "",
                  "label-style": { width: "150px" },
                  "content-style": { minWidth: "150px" },
                },
              },
              [
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("设备名称")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.equipmentForm.name || "-"))]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("产权编号")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.equipmentForm.code || "-"))]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("规格型号")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.equipmentForm.specModel || "-")),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("设备厂家")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.equipmentForm.factoryInfo || "-")),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("出厂日期")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.equipmentForm.producedAt || "-")),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("出厂编号")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.equipmentForm.producedNumber || "-")),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("独立高度")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.equipmentForm.independentHeight
                            ? _vm.equipmentForm.independentHeight + "米"
                            : "-"
                        )
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("设备价值")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.equipmentForm.equipmentValue
                            ? _vm.equipmentForm.equipmentValue + "万元"
                            : "-"
                        )
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("设备区域")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.chinaZonesSelected.provinceName
                            ? _vm.chinaZonesSelected.provinceName +
                                "/" +
                                _vm.chinaZonesSelected.cityName +
                                "/" +
                                _vm.chinaZonesSelected.districtName
                            : "-"
                        )
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("详细地址")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.equipmentForm.address || "-")),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("是否异常")]),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            _vm.getIsAbnormal(_vm.equipmentForm.isAbnormal) &&
                            _vm.getIsAbnormal(_vm.equipmentForm.isAbnormal)
                              .style,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            (_vm.getIsAbnormal(_vm.equipmentForm.isAbnormal) &&
                              _vm.getIsAbnormal(_vm.equipmentForm.isAbnormal)
                                .value) ||
                              "-"
                          )
                        ),
                      ]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("是否整机")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.equipmentForm.isWhole ? "是" : "否")),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v("设备图片")]),
                    _vm._v(" "),
                    _vm.equipmentForm.img
                      ? _c("dw-image", {
                          attrs: {
                            url: _vm.equipmentForm.img,
                            "show-del": false,
                          },
                        })
                      : _c("span", [_vm._v("-")]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                staticClass: "detail-form",
                staticStyle: { "margin-top": "18px" },
                attrs: { "label-width": "90px" },
              },
              [_c("el-form-item", { attrs: { label: "设备资料" } })],
              1
            ),
            _vm._v(" "),
            _c("document-list", {
              attrs: {
                "document-list": _vm.equipmentForm.documentInformation,
                "show-operation": _vm.showInput,
              },
              on: { "on-delete": _vm.onDeleteDocument },
            }),
          ],
      _vm._v(" "),
      _vm.showInput
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ht-button",
                {
                  attrs: { icon: "el-icon-close" },
                  on: { click: _vm.cancelSubmit },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "ht-button",
                {
                  attrs: {
                    loading: _vm.isSubmitting,
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit("equipmentForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }