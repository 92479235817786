var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-descriptions",
    {
      staticClass: "equipment-descriptions",
      attrs: {
        column: 2,
        size: "medium",
        border: "",
        "label-style": { width: "100px", color: "#666" },
        "content-style": { minWidth: "150px" },
      },
    },
    [
      _c(
        "el-descriptions-item",
        { attrs: { span: _vm.nameSpan } },
        [
          _c("template", { slot: "label" }, [_vm._v("设备名称")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s((_vm.equipmentInfo && _vm.equipmentInfo.name) || "-")
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm.equipmentInfo && _vm.equipmentInfo.code
        ? _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("产权编号")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s((_vm.equipmentInfo && _vm.equipmentInfo.code) || "-")
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-descriptions-item",
        [
          _c("template", { slot: "label" }, [_vm._v("规格型号")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s((_vm.equipmentInfo && _vm.equipmentInfo.specModel) || "-")
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }