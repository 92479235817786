var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "20px" } },
    [
      _c("el-card", { staticStyle: { "margin-bottom": "50px" } }, [
        _c("div", { staticClass: "title-card-container" }, [
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_50" }, [
              _vm._v("编号:"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "textStyle",
                staticStyle: { color: "#48a0ff", "font-weight": "bold" },
              },
              [_vm._v(_vm._s(_vm.securityCheckDetail.recordNo))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_50" }, [
              _vm._v("时间:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(
                _vm._s(
                  _vm.securityCheckDetail.createdAt
                    ? _vm.securityCheckDetail.createdAt
                    : "-"
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_50" }, [
              _vm._v("巡查人:"),
            ]),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.securityCheckDetail.staff
                    ? _vm.securityCheckDetail.staff.fullName
                    : "-"
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "title-card-item-full-line" },
            [
              _c("span", { staticClass: "labelStyle labelWidth_50" }, [
                _vm._v("备注:"),
              ]),
              _vm._v(" "),
              !_vm.editComment
                ? [
                    _c("span", { staticClass: "textStyle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.securityCheckDetail.comment
                            ? _vm.securityCheckDetail.comment
                            : "-"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { "font-size": "24px", type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.editComment = true
                          },
                        },
                      },
                      [_vm._v("修改备注")]
                    ),
                  ]
                : _c(
                    "span",
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 4 },
                          placeholder: "请输入备注",
                        },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirmUpdateComment },
                        },
                        [_vm._v("确定提交")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              _vm.editComment = false
                            },
                          },
                        },
                        [_vm._v("取消输入")]
                      ),
                    ],
                    1
                  ),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("el-divider", [
        _c("i", { staticClass: "el-icon-chat-line-square" }),
        _vm._v(" 视频详情"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { width: "60%", margin: "0 auto" } },
        [
          _vm.securityCheckDetail.videoUrl
            ? _c("video-player", {
                ref: "videoPlayer",
                staticClass: "vjs-custom-skin",
                staticStyle: { "margin-left": "20px" },
                attrs: { options: _vm.playerOptions },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }