var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "own-card" },
    [
      _c("el-card", { staticStyle: { "margin-bottom": "50px" } }, [
        _c("div", { staticClass: "title-card-container" }, [
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("编号:"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "textStyle",
                staticStyle: { color: "#48a0ff", "font-weight": "bold" },
              },
              [_vm._v(_vm._s(_vm.reportItem.recordNo))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("记录人:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(
                _vm._s(
                  _vm.reportItem.staff ? _vm.reportItem.staff.fullName : ""
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("时间:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(_vm._s(_vm.reportItem.createdAt || "-")),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("div", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("记录名称:"),
            ]),
            _vm._v(" "),
            !_vm.isEditProjectName
              ? _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("span", { staticClass: "textStyle" }, [
                      _vm._v(_vm._s(_vm.reportItem.projectName || "-")),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.isEditProjectName = true
                          },
                        },
                      },
                      [_vm._v("修改记录名称")]
                    ),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("el-input", {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "输入记录名称" },
                      model: {
                        value: _vm.editProjectName,
                        callback: function ($$v) {
                          _vm.editProjectName = $$v
                        },
                        expression: "editProjectName",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.projectDetail(
                              "projectName",
                              _vm.editProjectName,
                              "isEditProjectName"
                            )
                          },
                        },
                      },
                      [_vm._v("确定提交")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.cancelEditProjectDetail(
                              "projectName",
                              "editProjectName",
                              "isEditProjectName"
                            )
                          },
                        },
                      },
                      [_vm._v("取消输入")]
                    ),
                  ],
                  1
                ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "title-card-item-one-third" },
            [
              _c("span", { staticClass: "labelStyle labelWidth_70" }, [
                _vm._v("状态:"),
              ]),
              _vm._v(" "),
              _vm.reportItem.status
                ? _c("status", {
                    attrs: {
                      status: _vm.reportItem.status.value,
                      "status-types": { 草稿: "danger", 已提交: "success" },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-full-line" }, [
            _c(
              "div",
              {
                staticClass: "labelStyle labelWidth_70",
                staticStyle: { "vertical-align": "top" },
              },
              [_vm._v("备注:")]
            ),
            _vm._v(" "),
            !_vm.isEditReportComment
              ? _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("span", { staticClass: "textStyle" }, [
                      _vm._v(_vm._s(_vm.reportItem.comment || "-")),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.isEditReportComment = true
                          },
                        },
                      },
                      [_vm._v("修改备注")]
                    ),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("el-input", {
                      staticStyle: { width: "450px" },
                      attrs: {
                        type: "textarea",
                        rows: 3,
                        placeholder: "请输入备注",
                      },
                      model: {
                        value: _vm.editReportComment,
                        callback: function ($$v) {
                          _vm.editReportComment = $$v
                        },
                        expression: "editReportComment",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.projectDetail(
                              "comment",
                              _vm.editReportComment,
                              "isEditReportComment"
                            )
                          },
                        },
                      },
                      [_vm._v("确定提交")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.cancelEditProjectDetail(
                              "comment",
                              "editReportComment",
                              "isEditReportComment"
                            )
                          },
                        },
                      },
                      [_vm._v("取消输入")]
                    ),
                  ],
                  1
                ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-full-line" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("位置区域:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(_vm._s(_vm.reportItem.locationAndInfo || "-")),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("el-divider", [
        _c("i", { staticClass: "el-icon-chat-line-square" }),
        _vm._v(" 记录报告"),
      ]),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: {
            data: _vm.list,
            "row-key": _vm.getRowKey,
            stripe: false,
            "highlight-current-row": false,
            height: "620",
          },
          on: { "selection-change": _vm.onSelectionChange },
        },
        [
          _vm.showSelector
            ? _c("el-table-column", { attrs: { type: "selection" } })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "记录项" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v("\n        " + _vm._s(row.checkName) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "时间", prop: "checkTime", "min-width": "160px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v("\n        " + _vm._s(row.checkTime) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "图片", "min-width": "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("image-box", {
                      attrs: { "check-item-images": row.items },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "文字描述", "min-width": "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "edit-box" },
                      [
                        !row.editRowDescription
                          ? _c("div", { staticClass: "textStyle" }, [
                              _vm._v(_vm._s(row.description || "-")),
                            ])
                          : _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                disabled: !row.editRowDescription,
                                type: "textarea",
                                rows: 5,
                                placeholder: "请输入文字描述",
                              },
                              model: {
                                value: row.descriptionRow,
                                callback: function ($$v) {
                                  _vm.$set(row, "descriptionRow", $$v)
                                },
                                expression: "row.descriptionRow",
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "edit-button" },
                          [
                            !row.editRowDescription
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        row.editRowDescription = true
                                      },
                                    },
                                  },
                                  [_vm._v("修改描述")]
                                )
                              : _c(
                                  "span",
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          type: "success",
                                          underline: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmUpdateRowData(
                                              row,
                                              "description",
                                              "editRowDescription"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("确定提交")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "danger",
                                          underline: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelEditRowData(
                                              row,
                                              "description",
                                              "editRowDescription"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("取消输入")]
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "记录项备注",
              prop: "checkComment",
              "min-width": "200px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "edit-box" },
                      [
                        !row.editRowComment
                          ? _c("div", { staticClass: "textStyle" }, [
                              _vm._v(_vm._s(row.checkComment || "-")),
                            ])
                          : _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                disabled: !row.editRowComment,
                                type: "textarea",
                                rows: 6,
                                placeholder: "请输入备注",
                              },
                              model: {
                                value: row.checkCommentRow,
                                callback: function ($$v) {
                                  _vm.$set(row, "checkCommentRow", $$v)
                                },
                                expression: "row.checkCommentRow",
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "edit-button" },
                          [
                            !row.editRowComment
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        row.editRowComment = true
                                      },
                                    },
                                  },
                                  [_vm._v("修改备注")]
                                )
                              : [
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: { display: "block" },
                                      attrs: {
                                        type: "success",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmUpdateRowData(
                                            row,
                                            "checkComment",
                                            "editRowComment"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("确定提交")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelEditRowData(
                                            row,
                                            "checkComment",
                                            "editRowComment"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消输入")]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.reportsRecordsVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.reportsRecordsVisible,
                title: "历史图文报告",
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.reportsRecordsVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "clearFloat" },
                [
                  _c(
                    "el-table",
                    { attrs: { border: "", data: _vm.reportsRecords } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "创建时间", prop: "createdAt" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "项目报告名称", prop: "name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadHistoryReport(
                                            row.ossFilePath
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(row.name))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1328308424
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "创建人", prop: "staffFullName" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ht-pagination", {
                    staticStyle: { float: "right", clear: "both" },
                    attrs: {
                      total: _vm.reportsRecordsPage.total,
                      page: _vm.reportsRecordsListQuery.page,
                      limit: _vm.reportsRecordsListQuery.perPage,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(
                          _vm.reportsRecordsListQuery,
                          "page",
                          $event
                        )
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(
                          _vm.reportsRecordsListQuery,
                          "perPage",
                          $event
                        )
                      },
                      pagination: _vm.getProjectReportsDocRecords,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ht-dialog",
        { ref: "dialog", attrs: { title: "远程指导记录", width: "70%" } },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.remoteGuidanceData &&
              _vm.remoteGuidanceData[0] &&
              _vm.remoteGuidanceData[0].id
                ? _c("remote-guidance-dialog", {
                    attrs: {
                      "guidance-id": _vm.remoteGuidanceData[0].id,
                      "biz-type": _vm.reportItem.guidanceBizType.value,
                      "biz-view": true,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }