var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "step-ui-detail-container",
          class: { "is-used-step-container": !_vm.isEdit },
          style: { height: _vm.height + "px" },
          attrs: { title: "hf_use_description" },
        },
        [
          _c(
            "div",
            { staticClass: "view-container" },
            [
              _c(
                "top-section",
                {
                  ref: "topSection",
                  attrs: {
                    "is-focused-item": _vm.isFocusedItem,
                    "current-index": _vm.currentIndex,
                    count: _vm.count,
                    title: _vm.properties.title,
                    "show-photo-button": false,
                    "show-more-function": false,
                    "show-jump": false,
                    "show-photos": false,
                    "show-scan": false,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "circle-button photo-button",
                      attrs: {
                        slot: "other-button",
                        type: "text",
                        disabled: _vm.buttonDisabled,
                      },
                      on: { click: _vm.nativeScanCode },
                      slot: "other-button",
                    },
                    [_vm._v("开始扫码")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "middle-container",
                  style: { height: _vm.getMiddleHeight + "px" },
                },
                [
                  _vm.isEdit || !_vm.submitResults.carFrameNum
                    ? _c("img", {
                        staticClass: "scan-code-img",
                        attrs: { src: _vm.ScanCode, alt: "" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.submitResults.carFrameNum
                    ? _c(
                        "div",
                        { staticClass: "result-container" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "deviceForm",
                              attrs: {
                                "label-width": "auto",
                                "label-position": "left",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "车架号：" } },
                                [
                                  _c("el-input", {
                                    ref: "value",
                                    staticClass: "value-input",
                                    attrs: {
                                      disabled: "",
                                      placeholder: "车架号",
                                    },
                                    model: {
                                      value: _vm.submitResults.carFrameNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitResults,
                                          "carFrameNum",
                                          $$v
                                        )
                                      },
                                      expression: "submitResults.carFrameNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "名号：" } },
                                [
                                  _c("el-input", {
                                    ref: "value",
                                    staticClass: "value-input",
                                    attrs: {
                                      disabled: "",
                                      placeholder: "名号",
                                    },
                                    model: {
                                      value: _vm.submitResults.nskMaterialName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitResults,
                                          "nskMaterialName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "submitResults.nskMaterialName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "批次：" } },
                                [
                                  _c("el-input", {
                                    ref: "value",
                                    staticClass: "value-input",
                                    attrs: {
                                      disabled: "",
                                      placeholder: "批次",
                                    },
                                    model: {
                                      value: _vm.submitResults.batchNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitResults,
                                          "batchNum",
                                          $$v
                                        )
                                      },
                                      expression: "submitResults.batchNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "检查日：" } },
                                [
                                  _c("el-input", {
                                    ref: "value",
                                    staticClass: "value-input",
                                    attrs: {
                                      disabled: "",
                                      placeholder: "检查日",
                                    },
                                    model: {
                                      value: _vm.submitResults.checkAt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitResults,
                                          "checkAt",
                                          $$v
                                        )
                                      },
                                      expression: "submitResults.checkAt",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("bottom-section", {
                ref: "bottomSection",
                attrs: {
                  count: _vm.count,
                  "current-index": _vm.currentIndex,
                  "is-last-step": _vm.isLastStep,
                  "button-disabled": _vm.buttonDisabled,
                },
                on: {
                  "to-prev": function ($event) {
                    return _vm.toPrev()
                  },
                  "to-next": function ($event) {
                    return _vm.toNext()
                  },
                  "save-record": function ($event) {
                    return _vm.saveRecord()
                  },
                  "submit-record": function ($event) {
                    return _vm.submitRecord()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }