<template>
  <div>
    <el-form v-if="showInput" ref="equipmentForm" :model="equipmentForm" :rules="equipmentFormRules" label-width="100px">
      <el-row class="line-two-item-row">
        <el-form-item label="设备名称：" prop="name">
          <el-select v-model="equipmentForm.name" placeholder="请选择设备名称" style="width: 100%" @change="handleNameChange">
            <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.value" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="equipmentForm.isWhole" label="产权编号：" prop="code">
          <el-input v-model="equipmentForm.code" placeholder="请输入产权编号" maxlength="15" show-word-limit />
        </el-form-item>
      </el-row>
      <el-row class="line-two-item-row">
        <el-form-item label="规格型号：" prop="specModel">
          <el-input v-model="equipmentForm.specModel" placeholder="请输入规格型号" maxlength="30" show-word-limit />
        </el-form-item>
        <el-form-item label="设备厂家：" prop="factoryInfo">
          <el-input v-model="equipmentForm.factoryInfo" placeholder="请输入设备厂家" maxlength="30" show-word-limit />
        </el-form-item>
      </el-row>
      <el-row class="line-two-item-row">
        <el-form-item v-if="equipmentForm.isWhole" label="出厂日期：" prop="producedAt">
          <el-date-picker v-model="equipmentForm.producedAt" :picker-options="forbiddenDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择出厂日期" style="width: 100%" />
        </el-form-item>
        <el-form-item label="出厂编号：" prop="producedNumber">
          <el-input v-model="equipmentForm.producedNumber" placeholder="请输入出厂编号" maxlength="15" show-word-limit />
        </el-form-item>
      </el-row>
      <el-row class="line-two-item-row">
        <el-form-item label="独立高度：" prop="independentHeight'" class="unit-form-item">
          <template>
            <el-input-number v-model="equipmentForm.independentHeight" class="flex-1" :precision="2" :controls="false" placeholder="请输入独立高度" />
            <span class="unit">米</span>
          </template>
        </el-form-item>
        <el-form-item label="设备价值：" prop="equipmentValue" class="unit-form-item">
          <template>
            <el-input-number v-model="equipmentForm.equipmentValue" class="flex-1" :precision="2" :controls="false" placeholder="请输入设备价值" />
            <span class="unit">万元</span>
          </template>
        </el-form-item>
      </el-row>
      <el-row class="line-two-item-row">
        <el-form-item label="设备区域：" prop="province">
          <dw-china-zones-select
            ref="zones"
            :province-code.sync="chinaZonesSelected.provinceCode"
            :city-code.sync="chinaZonesSelected.cityCode"
            :district-code.sync="chinaZonesSelected.districtCode"
            :province-name.sync="chinaZonesSelected.provinceName"
            :city-name.sync="chinaZonesSelected.cityName"
            :district-name.sync="chinaZonesSelected.districtName"
          />
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input v-model="equipmentForm.address" placeholder="请输入详细地址" maxlength="30" show-word-limit />
        </el-form-item>
      </el-row>
      <el-row class="line-two-item-row">
        <el-form-item v-if="equipmentForm.isWhole" label="是否异常：" prop="isAbnormal">
          <el-radio-group v-model="equipmentForm.isAbnormal">
            <el-radio v-for="item in EquipmentAbnormalEnum" :key="item.name" :label="item.name">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="equipmentForm.isWhole !== null" label="是否整机：" prop="isWhole">
          {{ equipmentForm.isWhole ? '是': '否' }}
        </el-form-item>
      </el-row>
      <el-form-item label="设备图片：" prop="img">
        <template>
          <dw-image v-if="equipmentForm.img" :url="equipmentForm.img" @delete-img="onDeleteImage" />
          <ht-upload-button v-else :file-type="['img']" key-prefix="workflow" select-img @on-file-uploaded="onImageUploaded" />
        </template>
      </el-form-item>
      <el-form-item label="设备资料：" prop="documentInformation" class="list-form-item">
        <ht-upload-button v-if="showInput" :file-type="['doc', 'pdf', 'img']" key-prefix="equipment" multiple-selection button-title="上传文件" @on-files-uploaded="onFilesUploaded" />
      </el-form-item>
      <document-list :document-list="equipmentForm.documentInformation" :show-operation="showInput" @on-delete="onDeleteDocument" />
    </el-form>

    <template v-else>
      <el-descriptions title="设备信息" class="equipment-descriptions" :column="2" size="medium" border :label-style="{width:'150px'}" :content-style="{minWidth: '150px'}">
        <el-descriptions-item>
          <template slot="label">设备名称</template>
          <span>{{ equipmentForm.name || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">产权编号</template>
          <span>{{ equipmentForm.code || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">规格型号</template>
          <span>{{ equipmentForm.specModel || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">设备厂家</template>
          <span>{{ equipmentForm.factoryInfo || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">出厂日期</template>
          <span>{{ equipmentForm.producedAt || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">出厂编号</template>
          <span>{{ equipmentForm.producedNumber || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">独立高度</template>
          <span>{{ equipmentForm.independentHeight ? `${equipmentForm.independentHeight}米` : '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">设备价值</template>
          <span>{{ equipmentForm.equipmentValue ? `${equipmentForm.equipmentValue}万元` : '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">设备区域</template>
          <span>{{ chinaZonesSelected.provinceName ? `${chinaZonesSelected.provinceName}/${chinaZonesSelected.cityName}/${chinaZonesSelected.districtName}`: '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">详细地址</template>
          <span>{{ equipmentForm.address || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否异常</template>
          <el-tag
            :type="getIsAbnormal(equipmentForm.isAbnormal) && getIsAbnormal(equipmentForm.isAbnormal).style"
          >{{ getIsAbnormal(equipmentForm.isAbnormal) && getIsAbnormal(equipmentForm.isAbnormal).value || '-' }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否整机</template>
          <span>{{ equipmentForm.isWhole ? '是' : '否' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">设备图片</template>
          <dw-image v-if="equipmentForm.img" :url="equipmentForm.img" :show-del="false" />
          <span v-else>-</span>
        </el-descriptions-item>
      </el-descriptions>

      <el-form class="detail-form" label-width="90px" style="margin-top: 18px">
        <el-form-item label="设备资料" />
      </el-form>
      <document-list :document-list="equipmentForm.documentInformation" :show-operation="showInput" @on-delete="onDeleteDocument" />
    </template>

    <div v-if="showInput" slot="footer" class="dialog-footer" style="text-align: center">
      <ht-button icon="el-icon-close" @click="cancelSubmit">取消</ht-button>
      <ht-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('equipmentForm')">保存</ht-button>
    </div>
  </div>
</template>

<script>
import { EquipmentTypesEnum, EquipmentLeaseStatusEnum, EquipmentAbnormalEnum } from '@/views/constructionEquipmentMgr/enum'
import { postEquipments, putEquipment } from '@/api/constructionEquipmentMgr/equipmentMgr'
import DocumentList from '@/views/constructionEquipmentMgr/equipmentMgr/components/documentList'
import { parseTime } from '@/utils'
import { getIsAbnormal } from '@/utils/get-display'

export default {
  name: 'EquipmentForm',
  components: { DocumentList },
  props: {
    isView: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    equipmentId: { type: [String, Number], default: undefined },
    equipmentData: { type: Object, default: () => {} }
  },
  data() {
    return {
      EquipmentTypesEnum, EquipmentLeaseStatusEnum, EquipmentAbnormalEnum,
      equipmentForm: {
        name: null,
        isWhole: null,
        code: null,
        province: null,
        city: null,
        district: null,
        address: null,
        specModel: null,
        factoryInfo: null,
        producedAt: null,
        producedNumber: null,
        independentHeight: null,
        equipmentValue: null,
        isAbnormal: false,
        img: null,
        documentInformation: [],
        checkItems: []
      },
      equipmentFormRules: {
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入产权编号', trigger: 'blur' }],
        specModel: [{ required: true, message: '请输入规格型号', trigger: 'blur' }],
        producedAt: [{ required: true, message: '请选择出厂日期', trigger: 'change' }],
        isAbnormal: [{ required: true, message: '请选择是否异常', trigger: 'change' }]
      },
      chinaZonesSelected: {
        provinceCode: undefined,
        cityCode: undefined,
        districtCode: undefined,
        provinceName: undefined,
        cityName: undefined,
        districtName: undefined
      },
      forbiddenDate: {
        disabledDate(date) {
          return date.getTime() > Date.now()
        }
      },
      isSubmitting: false
    }
  },
  computed: {
    showInput() {
      return (this.isView && this.isEdit) || !this.isView
    }
  },
  created() {
    if (this.isView) {
      this.getEquipmentInfo()
    }
  },
  methods: {
    getIsAbnormal,
    getEquipmentInfo() {
      Object.keys(this.equipmentForm).forEach(key => {
        if (key === 'producedAt') {
          this.equipmentForm[key] = this.equipmentData[key] || null
        } else if (key === 'isAbnormal') {
          this.equipmentForm[key] = this.equipmentData[key] || false
        } else if (key === 'documentInformation') {
          this.equipmentForm[key] = this.equipmentData[key] || []
        } else if (key === 'checkItems') {
          this.equipmentForm[key] = this.equipmentData[key] || []
        } else {
          this.equipmentForm[key] = this.equipmentData[key] || null
        }
      })
      this.chinaZonesSelected.provinceCode = this.equipmentForm.province && this.equipmentForm.province.code || null
      this.chinaZonesSelected.cityCode = this.equipmentForm.city && this.equipmentForm.city.code || null
      this.chinaZonesSelected.districtCode = this.equipmentForm.district && this.equipmentForm.district.code || null
      this.chinaZonesSelected.provinceName = this.equipmentForm.province && this.equipmentForm.province.name || null
      this.chinaZonesSelected.cityName = this.equipmentForm.city && this.equipmentForm.city.name || null
      this.chinaZonesSelected.districtName = this.equipmentForm.district && this.equipmentForm.district.name || null
    },
    handleNameChange(value) {
      const index = EquipmentTypesEnum.findIndex(item => item.name === value)
      if (index !== -1) {
        this.equipmentForm.isWhole = EquipmentTypesEnum[index].isWhole
        this.equipmentForm.producedAt = EquipmentTypesEnum[index].producedAt
      } else {
        this.equipmentForm.isWhole = false
        this.equipmentForm.producedAt = null
      }
    },
    onImageUploaded(context, url) {
      this.equipmentForm.img = url
    },
    onDeleteImage() {
      this.equipmentForm.img = ''
    },
    onFilesUploaded(context, urls) {
      urls.forEach(url => {
        const item = {
          fileUrl: url,
          fileName: url.split(/[\\/]/).pop(),
          uploadAt: parseTime(new Date())
        }
        this.equipmentForm.documentInformation.push(item)
      })
    },
    onDeleteDocument(index) {
      this.equipmentForm.documentInformation.splice(index, 1)
    },
    confirmSubmit() {
      this.equipmentForm.province = this.chinaZonesSelected.provinceCode ? { code: this.chinaZonesSelected.provinceCode, name: this.chinaZonesSelected.provinceName } : undefined
      this.equipmentForm.city = this.chinaZonesSelected.cityCode ? { code: this.chinaZonesSelected.cityCode, name: this.chinaZonesSelected.cityName } : undefined
      this.equipmentForm.district = this.chinaZonesSelected.districtCode ? { code: this.chinaZonesSelected.districtCode, name: this.chinaZonesSelected.districtName } : undefined
      this.equipmentForm.code = this.equipmentForm.isWhole ? this.equipmentForm.code : null
      this.$refs.equipmentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          if (this.isEdit) {
            putEquipment(this.equipmentId, this.equipmentForm).then(() => {
              this.$message({ message: `修改成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          } else {
            postEquipments(this.equipmentForm).then(() => {
              this.$message({ message: `新增成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    getSubmitData(form) {
      const submitData = {}
      Object.keys(form).forEach(key => {
        submitData[key] = form[key]
      })
    }
  }
}
</script>

<style scoped lang="scss">
.line-two-item-row{
  display: flex;
  .el-form-item{
    width: calc(50% - 10px); /* 每个项目的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}

.warning-info{
  color: #f56c6c;
  font-size: 12px;
}

.information-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.list-form-item{
  ::v-deep.el-form-item__content{
    text-align: right;
  }
}

.dialog-footer{
  margin-top: 20px;
}

.unit-form-item{
  ::v-deep .el-form-item__content {
    display: flex;
    .flex-1{
      flex: 1;
      input {
        text-align: left;
      }
    }
    .unit{
      margin-left: 10px;
    }
  }
}

.detail-form{
  ::v-deep .el-form-item__label{
    color:#000;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
