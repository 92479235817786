import request from '@/utils/request'
const construction = 'construction/'
const project = 'project/'
const projects = 'projects/'

// 获取工程详情
export function getProject(projectId, listQuery) {
  return request({ url: `${construction}${project}${projectId}`, method: 'GET', params: listQuery })
}

// 修改工程
export function putProject(projectId, data) {
  return request({ url: `${construction}${project}${projectId}`, method: 'PUT', data: data })
}

// 删除工程
export function deleteProject(projectId) {
  return request({ url: `${construction}${project}${projectId}`, method: 'DELETE' })
}

// 获取工程列表
export function getProjects(listQuery) {
  return request({ url: `${construction}${projects}`, method: 'GET', params: listQuery })
}

// 新增工程
export function postProjects(data) {
  return request({ url: `${construction}${projects}`, method: 'POST', data: data })
}

// 工程批量添加设备
export function postProjectEquipments(projectId, data) {
  return request({ url: `${construction}${project}${projectId}/equipments/`, method: 'POST', data: data })
}

export function getProjectTaskWorkOrders(projectId, listQuery) {
  return request({ url: `${construction}${project}${projectId}/task_work_orders/`, method: 'GET', params: listQuery })
}

// 检查工程下设备使用情况
export function getProjectStopLease(projectId, listQuery) {
  return request({ url: `${construction}${project}${projectId}/stop_lease`, method: 'GET', params: listQuery })
}

// 设备解绑接口
export function putProjectStopLease(projectId, data) {
  return request({ url: `${construction}${project}${projectId}/stop_lease`, method: 'PUT', data: data })
}

export function getProjectTaskWorkOrdersGanttStatic(projectId, listQuery) {
  return request({ url: `${construction}${project}${projectId}/task_work_orders/`, method: 'GET', params: listQuery })
}

export function putProjectTransshipment(projectId, data) {
  return request({ url: `${construction}${project}${projectId}/transshipment`, method: 'PUT', data: data })
}

// 设备租赁记录
export function getProjectEquipmentRecords(listQuery) {
  return request({ url: `${construction}${project}project_equipment_records/`, method: 'GET', params: listQuery })
}

// 饼图统计
export function getProjectsPieStatistic(listQuery) {
  return request({ url: `${construction}${projects}pie_statistic`, method: 'GET', params: listQuery })
}

// 修改设备的执行代码
export function putProjectEquipment(projectId, data) {
  return request({ url: `${construction}project_equipment/${projectId}`, method: 'PUT', data: data })
}

// 结束工程
export function putProjectFinished(projectId) {
  return request({ url: `${construction}${project}${projectId}/finished`, method: 'PUT' })
}
