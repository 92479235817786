var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { staticClass: "app-breadcrumb", attrs: { separator: "/" } },
    _vm._l(_vm.levelList, function (item, index) {
      return _c("el-breadcrumb-item", { key: item.path }, [
        item.redirect === "noRedirect" || index === _vm.levelList.length - 1
          ? _c("a", [_vm._v(_vm._s(item.meta.title))])
          : _c(
              "a",
              {
                staticClass: "no-redirect",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleLink(item)
                  },
                },
              },
              [_vm._v(_vm._s(item.meta.title))]
            ),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }