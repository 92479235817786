<template>
  <div class="user-profile-container">
    <div class="user-container">
      <div class="user-left-container">
        <div class="user-avatarUrl">
          <img v-if="user.avatarUrl" class="img-avatarUrl" :src="user.avatarUrl" alt="">
          <dw-default-avatar v-else :full-name="user.fullName" :width="100" :font-size="30" background="#ffffff" :color="variables.primaryColor" />
        </div>
        <div class="user-info">
          <span class="item">{{ user.fullName }}
            <span class="item">{{ user.userName }}</span>
            <span class="item">{{ user.roles[0] }}</span>
          </span>
        </div>
      </div>
      <div class="qr-code-container user-right-container" @click="openQrCode">
        <vue-qr ref="qrCode" :text="user.qrCode" :size="50" :margin="5" />
      </div>
    </div>
    <div class="footer-container" style="text-align: center">
      <el-button type="primary" @click="logout">退出登录</el-button>
    </div>

    <div v-if="showQrCodePreview" class="qr-code-preview">
      <div class="qr-code-preview-overlay" @click="closeQrCodePreview" />
      <div class="qr-code-preview-content">
        <vue-qr ref="qrCode" :text="user.qrCode" :size="300" :margin="20" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import im from '@/views/newRemoteGuidance/rongCloud/im'
import loginBgImage from '@/assets/image/loginBackground/login_background_new.jpg'
import variables from '@/styles/variables.scss'

export default {
  name: 'Index',
  data() {
    return {
      loginBgImage,
      showQrCodePreview: false
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'userInfo'
    ]),
    variables() {
      return variables
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.user = {
        fullName: this.userInfo.fullName,
        userName: this.userInfo.account.userName,
        mobile: this.userInfo.mobile,
        roles: this.userInfo.roleNames,
        avatarUrl: this.userInfo.avatarUrl,
        qrCode: this.userInfo.qrCode
      }
    },
    async logout() {
      if (this.userRoutes && this.userRoutes.featureMenuJson && !this.userInfo.roles.includes('ADMIN') && !this.userInfo.roles.includes('STAFF')) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'newRemoteGuidanceMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'contactList')
          if (menuIndex !== -1 && routers[index].children[menuIndex].activated) {
            im.imDisconnect().then(r => {}).catch(() => {
              this.$store.dispatch('user/logout')
              this.$router.push(`/login`)
            })
          }
        }
      }
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
    },
    openQrCode() {
      this.showQrCodePreview = true
    },
    closeQrCodePreview() {
      this.showQrCodePreview = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.user-profile-container{
  position: relative;
  padding: 20px 10px;
  .user-container{
    background: $primaryColor;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    .user-avatarUrl{
      width: 100px;
      margin-right: 10px;
      .img-avatarUrl{
        width: 100px;
        height: 100px;
        border-radius: 100px;
      }
    }
    .user-left-container{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .user-info{
        .item{
          color: #ffffff;
          display: block;
          margin: 5px 0;
          font-size: 1.1rem;
        }
      }
    }
    .user-right-container{

    }
  }
  .footer-container{
    margin-top: 50px;
    .el-button{
      width: 100%;
      height: 40px;
      font-size: 1.1rem;
    }
  }
}

.qr-code-preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;

  .qr-code-preview-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .qr-code-preview-content {
    max-width: 80%;
    max-height: 80%;
  }

  .qr-code-preview img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}
</style>
