<template>
  <div v-loading="isLoading" class="template-call-meeting-container">
    <div v-if="status === 'notInMeeting'" class="not-in-meeting-container">
      <div class="title-container">
        <div class="item">会议号：{{ roomId }}</div>
      </div>
      <div class="middle-container">
        <div class="join-userinfo">
          <div class="title">请输入您的参会名称</div>
          <el-input v-model="fullName" :maxlength="10" show-word-limit placeholder="请输入" />
        </div>
      </div>
      <ht-button type="primary" @click="joinMeeting">加入会议</ht-button>
    </div>
    <div v-else-if="status === 'inMeeting'" class="in-meeting-containers" />
    <div v-else-if="status === 'isLeaving'" class="is-leaving-containers">
      <div class="item">您已离开会议</div>
      <div class="item">参会时长: {{ getDuration(leftMeetingTimestamp - joinedMeetingTimestamp) }}</div>
    </div>
    <div v-else-if="status === 'isFinished'" class="is-finished-containers">
      <div class="item">会议已结束或者不存在</div>
    </div>
  </div>
</template>

<script>
import { templateAcceptInvite } from '@/views/newRemoteGuidance/js/connectAndGlobalMeeting'
import { getDuration, parseTime } from '@/utils'
import { getMeetingRoomTemp, postMeetingTemplateUser } from '@/api/newRemoteGuidance/template'
import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'
import im from '@/views/newRemoteGuidance/rongCloud/im'
import store from '@/store'

export default {
  name: 'Index',
  props: {
    roomId: { type: String, default: null },
    chatRoomId: { type: String, default: null },
    senderId: { type: String, default: null }
  },
  data() {
    return {
      status: null,
      statusEnum: [
        { name: 'notInMeeting', value: '待加入' },
        { name: 'inMeeting', value: '会议中' },
        { name: 'isLeaving', value: '离开会议' },
        { name: 'isFinished', value: '会议结束' }
      ],
      fullName: null,
      joinedMeetingTimestamp: null,
      leftMeetingTimestamp: null,
      userInfo: {
        imUserId: '6984529343672831',
        imToken: 'VR4l/Q6wI50oZ2XgBllTIk5nU+D+4a8hA5UvF3pA8+1MCK5G/uCzmg==@s8fp.cn.rongnav.com;s8fp.cn.rongcfg.com',
        username: null,
        fullName: this.fullName
      },
      isLoading: false,
      meetingInfo: null
    }
  },
  watch: {
    status(value) {}
  },
  created() {
    this.getMeetingStatus()
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      store.dispatch('app/isMobile', true)
    } else {
      store.dispatch('app/isMobile', false)
    }
  },
  methods: {
    parseTime, getDuration,
    getMeetingStatus() {
      this.isLoading = true
      getMeetingRoomTemp(this.roomId).then(resp => {
        this.isLoading = false
        this.meetingInfo = resp
        if (resp.status.name === 'PROCESSING') {
          this.status = 'notInMeeting'
        } else if (resp.status.name === 'FINISHED') {
          this.status = 'isFinished'
        }
      })
    },
    joinMeeting() {
      // 1。参会名称是否填写
      if (this.fullName) {
        this.isLoading = true
        // 2。会议是否结束
        getMeetingRoomTemp(this.roomId).then(resp => {
          this.meetingInfo = resp
          if (resp.status.name === 'PROCESSING') {
            const data = {
              tenantUuid: this.meetingInfo.tenantUuid,
              fullName: this.fullName
            }
            // 3。创建临时用户
            postMeetingTemplateUser(data).then(resp => {
              // 4。临时用户登陆
              const loginForm = {
                username: resp.account.userName,
                password: '123456',
                action: 'login',
                userType: 'staff'
              }
              this.$store.dispatch('user/templateUserLogin', loginForm).then(resp => {
                const inviteDetail = {
                  roomId: this.roomId,
                  chatRoomId: this.chatRoomId,
                  senderId: this.senderId
                }
                setTimeout(() => { // 后端收到连接回调时间较长
                  // 5。临时用户入会
                  templateAcceptInvite(inviteDetail)
                  this.joinedMeetingTimestamp = new Date().getTime()
                  document.addEventListener(EventsEnum.meetingAlreadyEvent, this.logout) // 退出会议
                  this.isLoading = false
                  this.status = 'inMeeting'
                }, 2000)
              }).catch(() => {
                this.$message({ message: '入会失败', type: 'error', center: true })
                this.isLoading = false
                this.status = 'isFinished'
              })
            }).catch(() => {
              this.$message({ message: '入会失败', type: 'error', center: true })
              this.isLoading = false
              this.status = 'isFinished'
            })
          } else if (resp.status.name === 'FINISHED') {
            this.$message({ message: '入会失败', type: 'error', center: true })
            this.isLoading = false
            this.status = 'isFinished'
          }
        })
      } else {
        this.$message({ message: '请填写参会名称', type: 'error', center: true })
      }
    },
    logout() {
      this.status = 'isLeaving'
      this.leftMeetingTimestamp = new Date().getTime()
      setTimeout(() => {
        im.imDisconnect().then(r => {
          this.$store.dispatch('user/logout')
        }).catch(() => {
        })
      }, 5000)
    }
  }
}
</script>

<style scoped lang="scss">
.template-call-meeting-container{
  width: 100%;
  height: 100%;
  .not-in-meeting-container, .in-meeting-containers, .is-leaving-containers, .is-finished-containers{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    font-size: 1.2rem;
  }
  .not-in-meeting-container{
    .title-container{
      .item{
        margin: 10px 0 15px;
      }
    }
    .middle-container{
      flex: 1;
      margin: 30px 0;
      .join-userinfo{
        padding: 40px 20px 50px;
        border-radius: 10px;
        border: 1px solid #aaaaaa;
        text-align: center;
        .title{
          font-weight: bold;
          font-size: 1.2rem;
          margin-bottom: 40px;
          color: #666666;
        }
        ::v-deep .el-input{
          font-size: 1rem;
          height: 40px;
          .el-input__inner{
            font-size: 1rem;
            height: 40px;
          }
          .el-input__suffix{
            font-size: 1rem;
          }
          .el-input__count{
            font-size: 1rem;
          }
        }
      }
    }
    .el-button{
      height: 40px;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  .is-leaving-containers, .is-finished-containers{
    align-items: center;
    padding-top: 100px;
    .item{
      margin: 10px 0 15px;
    }
  }
}
</style>
