var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("div", { staticClass: "info-container" }, [
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _c("div", { staticClass: "count" }, [
          _vm._v(
            _vm._s(_vm.isWarehouse ? "库存总数" : "现存设备数") +
              "：" +
              _vm._s(_vm.count)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.WarehouseEquipmentStockInboundListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.otherProp,
              attrs: {
                label: item.label,
                prop: item.otherProp,
                "min-width": item.width || 100,
                fixed: item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row[item.otherProp] || "-")),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.isWarehouse
            ? _c("ht-table-column", {
                attrs: { label: "库存数", fixed: "right", prop: "quantity" },
              })
            : _c("ht-table-column", {
                attrs: {
                  label: "现存设备数",
                  fixed: "right",
                  prop: "projectEquipmentQuantity",
                },
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }