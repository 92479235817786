<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import echarts from 'echarts'
import { debounce } from '@/utils'

function getFormatter(params) {
  const time = params[0].axisValue
  // const orderCount = params[0].seriesName + '：' + params[0].value
  // const orderFinishedCount = params[3].seriesName + '：' + params[3].value
  // const finishedCount = params[1].marker + params[1].seriesName + '：' + params[1].value
  // const unFinishedCount = params[2].marker + params[2].seriesName + '：' + params[2].value
  // return time + '<br>' + orderCount + '<br>' + orderFinishedCount + '<br>' + finishedCount + '<br>' + unFinishedCount

  const finishedCount = params[0].marker + params[0].seriesName + '：' + params[0].value
  const unFinishedCount = params[1].marker + params[1].seriesName + '：' + params[1].value
  return time + '<br>' + finishedCount + '<br>' + unFinishedCount
}

export default {
  name: 'PatrolFaultHandleChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true },
    firstName: { type: String, default: '整改后合格工单数' },
    secondName: { type: String, default: '不合格工单数' },
    xUnit: { type: String, default: '时间' },
    yUnit: { type: String, default: '数量' },
    color1: { type: String, default: '#589EF6' },
    color2: { type: String, default: '#ee7f4b' }
  },
  data() {
    return {
      chart: null,
      option: {
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: 0
          }
        ],
        toolbox: {
          feature: {
            restore: {}
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function(params) {
            return getFormatter(params)
          }
        },
        legend: {
          data: [this.firstName, this.secondName],
          bottom: 0
        },
        grid: {
          top: '12%',
          left: '5%',
          right: '13%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          name: this.xUnit,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          type: 'category',
          axisTick: { show: false, length: 1 },
          data: []
        },
        yAxis: {
          name: this.yUnit,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            show: false // 坐标轴线
          },
          axisTick: {
            show: false // 刻度线
          },
          axisLabel: {
            formatter: function(value) {
              if (value % 1 === 0) {
                return value
              } else {
                return ''
              }
            }
          }
        },
        series: [
          // {
          //   name: '当月下发工单总数',
          //   type: 'bar',
          //   itemStyle: { opacity: 0 },
          //   barWidth: 0.1,
          //   data: []
          // },
          {
            name: this.firstName,
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: {
                color: this.color1
              }
            },
            data: []
          },
          {
            name: this.secondName,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: {
                color: this.color2
              }
            },
            data: []
          }
          // {
          //   name: '当月已完成工单数',
          //   type: 'bar',
          //   itemStyle: { opacity: 0 },
          //   barWidth: 0.1,
          //   data: []
          // }
        ]
      }
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(time, currentFinishedRectificationCounts, currentFinishedAbnormalCounts, arr3, arr4) {
      this.option.xAxis.data = time
      // this.option.series[0].data = arr3
      // this.option.series[1].data = finishedCounts
      // this.option.series[2].data = finishedAbnormalCounts
      // this.option.series[3].data = arr4

      this.option.series[0].data = currentFinishedRectificationCounts
      this.option.series[1].data = currentFinishedAbnormalCounts
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
