<template>
  <div class="top-container">
    <el-tabs v-model="activeName" style="margin-top: 20px">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.value" :name="item.name" />
    </el-tabs>
    <work-order-list v-if="activeName === 'workOrder'" style="padding: 74px 20px 20px" />
    <work-order-img-search v-if="activeName === 'imgSearch'" style="padding-top: 74px" />
  </div>
</template>

<script>
import WorkOrderList from '@/views/taskMgr/patrolWorkOrderMgr/workOrderList'
import WorkOrderImgSearch from '@/views/taskMgr/patrolWorkOrderMgr/workOrderImgSearch'

export default {
  name: 'Index',
  components: { WorkOrderImgSearch, WorkOrderList },
  data() {
    return {
      tabs: [
        { name: 'workOrder', value: '全部巡检工单' },
        { name: 'imgSearch', value: '工单图片搜索' }
      ],
      activeName: 'workOrder'
    }
  }
}

</script>

<style scoped lang="scss">
.top-container {
  min-height: 600px;
  .el-tabs {
    position: fixed;
    z-index: 9;
    background: #ffffff;
    width: 100%;
    padding: 20px 20px 0;
    margin-top: 0 !important;
  }
}
</style>
