<template>
  <div>
    <el-form v-if="showInput" ref="projectForm" :model="projectForm" :rules="projectFormRules" label-width="120px">
      <el-row class="line-two-item-row">
        <el-form-item label="工程名称：" prop="name">
          <el-input v-model="projectForm.name" placeholder="请输入工程名称" maxlength="30" show-word-limit />
        </el-form-item>
        <el-form-item label="工程起止日期：" prop="duration">
          <el-date-picker v-model="projectForm.duration" type="daterange" range-separator="至" value-format="yyyy-MM-dd" placement="bottom-start" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%" />
        </el-form-item>
      </el-row>
      <el-row class="line-one-item-row">
        <el-form-item label="工程地址：" prop="location">
          <el-input id="tipinput" v-model="projectForm.location" placeholder="请输入工程地址" maxlength="100" show-word-limit @change="handleLocationChange" />
        </el-form-item>
      </el-row>
      <!--      <el-row class="line-one-item-row">-->
      <!--        <el-form-item prop="province">-->
      <!--          <div id="mapContainer" class="map-container" />-->
      <!--        </el-form-item>-->
      <!--      </el-row>-->
      <div id="mapContainer" class="map-container" />
      <el-row class="line-two-item-row">
        <el-form-item label="施工单位：" prop="constructionUnit">
          <el-input v-model="projectForm.constructionUnit" placeholder="请输入施工单位" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="监理单位：" prop="constructionControlUnit">
          <el-input v-model="projectForm.constructionControlUnit" placeholder="请输入监理单位" maxlength="50" show-word-limit />
        </el-form-item>
      </el-row>
      <el-row class="line-two-item-row">
        <el-form-item label="安装单位：" prop="installationUnit">
          <el-input v-model="projectForm.installationUnit" placeholder="请输入安装单位" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="联系人：" prop="contactName">
          <el-input v-model="projectForm.contactName" placeholder="请输入联系人" maxlength="10" show-word-limit />
        </el-form-item>
      </el-row>
      <el-row class="line-two-item-row">
        <el-form-item label="联系电话：" prop="contactMobile">
          <el-input v-model="projectForm.contactMobile" placeholder="请输入联系电话" maxlength="12" show-word-limit />
        </el-form-item>
        <el-form-item label="绑定客户账号：" prop="customerId">
          <el-select
            v-model="projectForm.customerId"
            filterable
            clearable
            :placeholder="customerList.length > 0 ? '请选择绑定客户账号': '当前无可选客户账号'"
            style="width: 100%"
            remote
            :remote-method="getCustomers"
          >
            <el-option v-for="item in customerList" :key="item.id" :label="item.fullName" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-row>
      <el-form-item label="工程资料：" prop="documentInformation" class="list-form-item">
        <ht-upload-button v-if="showInput" :file-type="['doc', 'pdf', 'img']" key-prefix="workflow" multiple-selection button-title="上传文件" @on-files-uploaded="onFilesUploaded" />
      </el-form-item>
    </el-form>
    <template v-else>
      <div class="project-title">
        <div class="project-name">{{ projectForm.name }}</div>
      </div>
      <div class="project-info-container">
        <el-descriptions class="project-descriptions" :column="2" size="medium" border :label-style="{width:'110px'}">
          <el-descriptions-item :span="2">
            <template slot="label">工程地址</template>
            <span>{{ projectForm.location || '-' }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">施工单位</template>
            <span>{{ projectForm.constructionUnit || '-' }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">监理单位</template>
            <span>{{ projectForm.constructionControlUnit || '-' }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">安装单位</template>
            <span>{{ projectForm.installationUnit || '-' }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">联系人</template>
            <span>{{ projectForm.contactName || '-' }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">联系电话</template>
            <span>{{ projectForm.contactMobile || '-' }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">绑定客户账号</template>
            <span>{{ projectData.customer ? projectData.customer.fullName: '-' }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">工程起止日期</template>
            <span>{{ projectData.projectStartAt ? `${projectData.projectStartAt} 至 ${projectData.projectEndAt}` : '-' }}</span>
          </el-descriptions-item>
        </el-descriptions>
        <div class="chart-container">
          <template v-if="ganttChartData && ganttChartData.length > 0">
            <img v-if="showFullScreenImg" :src="FullScreen" alt="全屏" class="full-screen-img" @click="openGanttChartDialog">
            <gantt-chart ref="ganttChart" :x-rotate="30" title="流程追踪图" height="250px" />
          </template>
          <div v-else class="no-data-info">暂无流程数据</div>
        </div>
      </div>
    </template>

    <document-list :document-list="projectForm.attachmentData" :show-operation="showInput" style="margin-bottom: 20px" @on-delete="onDeleteDocument" />

    <div v-if="showInput" slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('projectForm')">保存</el-button>
    </div>

    <el-form v-if="isView && !isEdit" :class="{'detail-form': !showInput}">
      <el-form-item :label="showInput ? '租用设备记录：': '租用设备记录'" prop="documentInformation" class="list-form-item">
        <el-button v-if="showInput" type="success" icon="el-icon-setting" @click="openLeaseEquipmentMgrDialog">租用设备管理</el-button>
      </el-form-item>
      <equipment-list ref="leaseHistoryEquipmentList" :table-title="EquipmentUsedListTitle" :equipment-list="projectForm.equipmentItems" style="margin-bottom: 20px" />
    </el-form>

    <ht-dialog ref="addEquipmentDialog" title="添加设备" :width="isMobile? '100%' : '800px'" @close="closeAddEquipmentDialog">
      <div slot="content">
        <add-equipment-list v-if="showAddEquipmentList" ref="addEquipmentList" :already-add-list="isAddedEquipmentItems" style="margin-bottom: 20px" @on-add="onAddEquipment" />
      </div>
    </ht-dialog>

    <ht-dialog ref="leaseEquipmentMgrDialog" title="租用设备管理" :width="isMobile? '100%' : '800px'" @close="closeLeaseEquipmentMgrDialog">
      <div slot="content">
        <lease-equipment-mgr
          v-if="showLeaseEquipmentMgr"
          ref="leaseEquipmentMgr"
          :project-id="projectId"
          :project-data="projectData"
          @cancel-submit="closeLeaseEquipmentMgrDialog"
          @confirm-submit="submitLeaseEquipmentMgrDialog"
          @confirm-update="updateLeaseEquipmentMgrDialog"
        />
      </div>
    </ht-dialog>

    <ht-dialog ref="ganttChartDialog" title="流程追踪图" :width="isMobile? '100%' : '1000px'" :close-on-click-modal="false" @close="closeGanttChartDialog">
      <div slot="content">
        <gantt-chart ref="ganttChartDialogChart" :x-rotate="20" height="400px" show-tool-box data-zoom-type="slider" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import { isValidPhoneNumber } from '@/utils/validate'
import { postProjects, putProject, getProjectTaskWorkOrders } from '@/api/constructionProjectMgr/projectMgr'
import { parseTime } from '@/utils'
import { getUserMgrList } from '@/api/systemMgr/userMgr'
import DocumentList from '@/views/constructionProjectMgr/projectMgr/components/documentList'
import EquipmentList from '@/views/constructionProjectMgr/projectMgr/components/equipmentList'
import AddEquipmentList from '@/views/constructionProjectMgr/projectMgr/components/addEquipmentList'
import LeaseEquipmentMgr from '@/views/constructionProjectMgr/projectMgr/components/leaseEquipmentMgr'
import GanttChart from '@/views/constructionProjectMgr/projectMgr/components/ganttChart'
import FullScreen from '@/assets/image/device/full_screen.png'
import { getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import { getAddress } from '@/utils/get-display'
import { EquipmentUsedListTitle } from '@/views/constructionProjectMgr/projectMgr/tableTitle'
import { mapGetters } from 'vuex'
const AMap = window.AMap

export default {
  name: 'ProjectForm',
  components: { DocumentList, EquipmentList, AddEquipmentList, LeaseEquipmentMgr, GanttChart },
  props: {
    isView: { type: Boolean, default: false }, // 新建还是查看
    isEdit: { type: Boolean, default: false }, // 查看还是编辑
    projectId: { type: [String, Number], default: undefined },
    projectData: { type: Object, default: () => {} }
  },
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (value && !(isValidPhoneNumber(value))) {
        callback(new Error('联系电话格式错误'))
      } else {
        callback()
      }
    }
    return {
      FullScreen, EquipmentUsedListTitle,
      projectForm: {
        name: null,
        duration: [],
        projectStartAt: null,
        projectEndAt: null,
        location: null,
        longitude: null,
        latitude: null,
        constructionUnit: null,
        constructionControlUnit: null,
        installationUnit: null,
        contactName: null,
        contactMobile: null,
        attachmentData: [],
        equipmentItems: [],
        customerId: null
      },
      projectFormRules: {
        name: [{ required: true, message: '请输入工程名称', trigger: 'blur' }],
        duration: [{ required: true, message: '请选择工程起止日期', trigger: 'change' }],
        location: [{ required: true, message: '请输入工程地址', trigger: 'change' }],
        contactMobile: [{ required: false, message: '联系电话格式错误', trigger: 'blur', validator: validatePhoneNumber }]
      },
      showAddEquipmentList: false,
      preAddEquipmentList: [],
      showLeaseEquipmentMgr: false,
      isSubmitting: false,
      customerList: [],
      showFullScreenImg: true,
      ganttChartData: [],
      isGanttChartLoading: false,
      map: null,
      autoOptions: {
        input: 'tipinput'
      },
      placeSearch: null,
      isInitAutoComplete: false
    }
  },
  computed: {
    isAddedEquipmentItems() {
      const equipmentItems = this.projectForm.equipmentItems.filter(item => item.status.name === 'UNDER_LEASED')
      return [...equipmentItems, ...this.preAddEquipmentList]
    },
    allEquipmentItems() {
      return [...this.projectForm.equipmentItems, ...this.preAddEquipmentList]
    },
    showInput() {
      return (this.isView && this.isEdit) || !this.isView
    },
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getCustomers()
    if (this.isView) {
      this.getProjectInfo()
    }
  },
  mounted() {
    if (!this.isView) {
      this.initMap()
    } else if (this.isEdit) {
      this.initMap()
    }
  },
  methods: {
    getAddress,
    // 初始化地图
    initMap() {
      this.map = new AMap.Map('mapContainer', {
        resizeEnable: true,
        zoom: 20
      })
      // 初始化AMap.AutoComplete
      this.initAutoComplete()
    },
    // 输入提示
    initAutoComplete() {
      this.isInitAutoComplete = true
      AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
        this.auto = new AMap.AutoComplete(this.autoOptions)
        this.placeSearch = new AMap.PlaceSearch({
          map: this.map
        })
        this.auto.on('select', this.selectLocation)
      })
    },
    selectLocation(e) {
      this.placeSearch.setCity(e.poi.adcode)
      this.placeSearch.search(e.poi.name)
      this.projectForm.longitude = parseFloat(e.poi.location.lng.toFixed(5))
      this.projectForm.latitude = parseFloat(e.poi.location.lat.toFixed(5))
      this.projectForm.location = e.poi.district + e.poi.address + e.poi.name
    },
    handleLocationChange() {
      this.projectForm.longitude = null
      this.projectForm.latitude = null
      this.projectForm.location = null
    },
    getProjectInfo() {
      Object.keys(this.projectForm).forEach(key => {
        this.projectForm[key] = this.projectData[key] || null
      })
      // this.projectForm.location = this.projectData.location
      //   ? this.projectData.location
      //   : getAddress('',
      //     this.projectData.province && this.projectData.province.name,
      //     this.projectData.city && this.projectData.city.name,
      //     this.projectData.district && this.projectData.district.name,
      //     this.projectData.address)
      this.projectForm.duration = this.projectForm.projectStartAt ? [this.projectForm.projectStartAt, this.projectForm.projectEndAt] : []
      if (this.showLeaseEquipmentMgr) {
        this.$nextTick(() => {
          this.$refs.leaseEquipmentMgr.getProjectInfo()
        })
      }
      if (!this.showInput) {
        const ganttQuery = { is_finished: true, sort: 'id' }
        this.isGanttChartLoading = true
        getProjectTaskWorkOrders(this.projectData.id, ganttQuery).then(resp => {
          this.ganttChartData = resp.map(obj => {
            return {
              name: getTowerWorkOrderType(obj.towerWorkType).value,
              startDate: obj.startAt,
              endDate: obj.finishAt
            }
          })
          this.isGanttChartLoading = false
          if (this.ganttChartData && this.ganttChartData.length > 0) {
            this.$nextTick(() => {
              this.$refs.ganttChart.getData(this.ganttChartData)
            })
          }
        }).catch(() => { this.isGanttChartLoading = false })
      }
    },
    getCustomers(value) {
      const listQuery = {
        offset: 0,
        count: 20,
        keyword: value
      }
      getUserMgrList(listQuery).then(resp => {
        this.customerList = resp
      })
    },
    getProvince(value) {
      this.projectForm.province = value
    },
    getCity(value) {
      this.projectForm.city = value
    },
    getDistrict(value) {
      this.projectForm.district = value
    },
    onFilesUploaded(context, urls) {
      urls.forEach(url => {
        const item = {
          fileUrl: url,
          fileName: url.split(/[\\/]/).pop(),
          uploadAt: parseTime(new Date())
        }
        this.projectForm.attachmentData.push(item)
      })
    },
    onDeleteDocument(index) {
      this.projectForm.attachmentData.splice(index, 1)
    },
    onStopEquipment(index) {
      this.projectForm.equipmentItems[index].status = { name: 'ENDED' }
      this.confirmSubmit()
    },
    onDeleteEquipment(index) {
      this.preAddEquipmentList.splice(index, 1)
    },
    confirmSubmit() {
      const submitData = this.getSubmitData()
      this.$refs.projectForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          if (this.isEdit) {
            putProject(this.projectId, submitData).then(() => {
              this.$message({ message: `修改成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          } else {
            postProjects(submitData).then(() => {
              this.$message({ message: `新增成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    getSubmitData() {
      this.projectForm.projectStartAt = this.projectForm.duration[0]
      this.projectForm.projectEndAt = this.projectForm.duration[1]
      const equipmentItems = []
      this.allEquipmentItems.forEach(item => {
        const data = {
          constructionEquipmentId: item.constructionEquipmentId,
          constructionEquipmentCode: item.constructionEquipmentCode,
          constructionEquipmentName: item.constructionEquipmentName
        }
        if (item.remark) {
          data.remark = item.remark
        }
        if (item.status) {
          data.status = item.status
        }
        if (item.id) {
          data.id = item.id
        }
        equipmentItems.push(data)
      })
      const submitData = {}
      Object.keys(this.projectForm).forEach(key => {
        if (key !== 'equipmentItems' && key !== 'duration') {
          submitData[key] = this.projectForm[key]
        }
      })
      submitData.equipmentItems = equipmentItems
      return submitData
    },
    openAddEquipmentDialog() {
      this.showAddEquipmentList = true
      this.$refs.addEquipmentDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.addEquipmentList.getList()
      })
    },
    closeAddEquipmentDialog() {
      this.showAddEquipmentList = false
      this.$refs.addEquipmentDialog.dialogVisible = false
    },
    onAddEquipment(data) {
      this.preAddEquipmentList.push(data)
      this.closeAddEquipmentDialog()
    },
    openLeaseEquipmentMgrDialog() {
      this.showLeaseEquipmentMgr = true
      this.$refs.leaseEquipmentMgrDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.leaseEquipmentMgr.getProjectInfo()
      })
    },
    closeLeaseEquipmentMgrDialog() {
      this.showLeaseEquipmentMgr = false
      this.$refs.leaseEquipmentMgrDialog.dialogVisible = false
    },
    submitLeaseEquipmentMgrDialog() {
      this.closeLeaseEquipmentMgrDialog()
      this.$emit('confirm-submit')
    },
    async updateLeaseEquipmentMgrDialog() {
      this.$emit('confirm-update')
    },
    openGanttChartDialog() {
      this.showFullScreenImg = false
      this.$refs.ganttChartDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.ganttChartDialogChart.getData(this.ganttChartData)
      })
    },
    closeGanttChartDialog() {
      this.showFullScreenImg = true
      this.$refs.ganttChartDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
.line-two-item-row{
  display: flex;
  .el-form-item{
    width: calc(50% - 10px); /* 每个工程的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}

.line-two-item-for-one-row{
  display: flex;
  .el-form-item{
    width: calc(50% - 20px); /* 每个工程的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}

.line-one-item-row{
  display: flex;
  .el-form-item{
    width: calc(100% - 20px); /* 每个工程的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}

.warning-info{
  color: #f56c6c;
  font-size: 12px;
}

.information-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.list-form-item{
  ::v-deep.el-form-item__content{
    text-align: right;
  }
}

.dialog-footer{
  margin-top: 20px;
}

.project-title{
  width: 100%;
  text-align: center;
  .project-name{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.project-info-container{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  .project-descriptions{
    flex: 2;
    margin-right: 10px;
    min-height: 250px;
    ::v-deep .el-descriptions__body{
      min-height: 250px;
      .el-descriptions--medium{
        min-height: 250px;
      }
    }
  }
  .project-form{
    flex: 2;
    background: #f1f1f1;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ::v-deep .el-form-item{
      margin-bottom: 0 !important;
      height: 40px !important;
      line-height: 40px;
      .el-form-item__label{
        height: 40px !important;
        line-height: 40px;
      }
      .el-form-item__content{
        height: 40px !important;
        line-height: 40px;
      }
    }
  }
  .chart-container{
    flex: 1;
    border: 1px solid #dddddd;
    min-height: 250px;
    position: relative;
    .no-data-info{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
    }
    .full-screen-img{
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      height: 30px;
      cursor: pointer;
    }
  }
}

.detail-form{
  ::v-deep .el-form-item__label{
    color:#000;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }
}

#mapContainer {
  //width: 100%;
  //height: 200px;
  width: 0;
  height: 0;
}
</style>
