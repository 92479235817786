import request from '@/utils/request'

export const remote_guidance = 'remote_guidance/'

export function getRemoteGuidanceApi(listQuery) {
  return request({ url: remote_guidance, method: 'GET', params: listQuery })
}

export function getRemoteGuidanceItemApi(guidanceId) {
  return request({ url: `${remote_guidance}${guidanceId}`, method: 'GET' })
}

export function getRemoteGuidanceChatRecordsApi(guidanceId, listQuery) {
  return request({ url: `${remote_guidance}${guidanceId}/chat_records/`, method: 'GET', params: listQuery })
}

export function docReportApi(guidanceId, data) {
  return request({ url: `${remote_guidance}${guidanceId}/doc_report`, method: 'POST', data: data })
}

export function remoteGuidanceDocReportRecordsApi(guidanceId, listQuery) {
  return request({ url: `${remote_guidance}${guidanceId}/doc_report`, method: 'GET', params: listQuery })
}

export function deleteRemoteGuidanceItemApi(guidanceId) {
  return request({ url: `${remote_guidance}${guidanceId}`, method: 'DELETE' })
}

// 通过业务id获取远程指导id
export function getRemoteGuidanceIdApi(bizType, bizId, listQuery) {
  return request({ url: `remote_guidance/?bizType=${bizType}&bizId=${bizId}&offset=0&count=1`, method: 'GET', params: listQuery })
}
