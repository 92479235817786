import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'

export default {
  methods: {
    addCurrentMeetingEventListener() {
      // 浏览器事件
      window.addEventListener('resize', this.handleResize)
      window.addEventListener('beforeunload', this.beforeunloadHandler)
      window.addEventListener('unload', this.unloadHandler)
      window.addEventListener('popstate', this.popstateHandler)
      // 后端接口同步事件
      document.addEventListener(EventsEnum.beKickedOutEvent, this.clearAllTimer)
      // 房间监听事件
      document.addEventListener(EventsEnum.onUserJoinEvent, this.onUserJoinFun)
      document.addEventListener(EventsEnum.onUserLeaveEvent, this.onUserLeaveFun)
      document.addEventListener(EventsEnum.onTrackPublishEvent, this.onTrackPublishFun)
      document.addEventListener(EventsEnum.onTrackUnPublishEvent, this.onTrackUnPublishFun)
      document.addEventListener(EventsEnum.onTrackReadyEvent, this.onTrackReadyFun)
      document.addEventListener(EventsEnum.onTrackNotReadyEvent, this.onTrackNotReadyFun)
      document.addEventListener(EventsEnum.onAudioMuteChangeEvent, this.refreshUserAudioTrack)
      document.addEventListener(EventsEnum.onVideoMuteChangeEvent, this.refreshUserVideoTrack)
      document.addEventListener(EventsEnum.onStateReportEvent, this.onStateReportFun)
      // 会议消息
      document.addEventListener(EventsEnum.meetingInviteAcceptEvent, this.meetingAcceptFun)
      document.addEventListener(EventsEnum.meetingInviteRejectEvent, this.meetingRejectFun)
      document.addEventListener(EventsEnum.meetingChatMessageEvent, this.getChatMessageFun)
      document.addEventListener(EventsEnum.sendChatMessageSuccessEvent, this.getChatMessageFun)
      document.addEventListener(EventsEnum.sendChatMessageFailEvent, this.getChatMessageFun)
      // 会议控制-设备相关
      document.addEventListener(EventsEnum.meetingDeviceQueryStatusEvent, this.meetingDeviceQueryStatusFun)
      document.addEventListener(EventsEnum.sendMicMuteEvent, this.micMuteByLocalFun)
      document.addEventListener(EventsEnum.sendCamMuteEvent, this.camMuteByLocalFun)
      document.addEventListener(EventsEnum.meetingDeviceStatusEvent, this.meetingDeviceStatusFun)
      document.addEventListener(EventsEnum.meetingMicMuteEvent, this.micMuteByRemoteFun)
      document.addEventListener(EventsEnum.meetingCamMuteEvent, this.camMuteByRemoteFun)
      document.addEventListener(EventsEnum.meetingCamSwitchEvent, this.camSwitchByRemoteFun)
      document.addEventListener(EventsEnum.meetingSwitchUvcCam, this.switchUvcCamByRemoteFun)
      document.addEventListener(EventsEnum.meetingCamZoomEvent, this.camZoomByRemoteFun)
      document.addEventListener(EventsEnum.meetingCamExposureEvent, this.camExposureByRemoteFun)
      document.addEventListener(EventsEnum.meetingFlashlightEvent, this.flashlightByRemoteFun)
      document.addEventListener(EventsEnum.meetingVideoConfigEvent, this.videoConfigByRemoteFun)
      // 会议控制-共享操作相关
      document.addEventListener(EventsEnum.meetingScreenStatusEvent, this.screenStatusChangedByRemoteFun)
      document.addEventListener(EventsEnum.meetingRecordingStatusEvent, this.recordingStatusChangedByRemoteFun)
      document.addEventListener(EventsEnum.meetingFocusGuidance, this.focusGuidanceByRemoteFun)
      document.addEventListener(EventsEnum.meetingFreezeGuidance, this.freezeGuidanceByRemoteFun)
      document.addEventListener(EventsEnum.meetingLaserEvent, this.laserByRemoteFun)
      document.addEventListener(EventsEnum.meetingPaintEvent, this.paintByRemoteFun)
    },
    removeCurrentMeetingEventListener() {
      // 浏览器事件
      window.removeEventListener('resize', this.handleResize)
      window.removeEventListener('beforeunload', this.beforeunloadHandler)
      window.removeEventListener('unload', this.unloadHandler)
      window.removeEventListener('popstate', this.popstateHandler)
      // 后端接口同步事件
      document.removeEventListener(EventsEnum.beKickedOutEvent, this.clearAllTimer)
      // 房间监听事件
      document.removeEventListener(EventsEnum.onTrackPublishEvent, this.onTrackPublishFun)
      document.removeEventListener(EventsEnum.onTrackUnPublishEvent, this.onTrackUnPublishFun)
      document.removeEventListener(EventsEnum.onTrackReadyEvent, this.onTrackReadyFun)
      document.removeEventListener(EventsEnum.onUserJoinEvent, this.onUserJoinFun)
      document.removeEventListener(EventsEnum.onUserLeaveEvent, this.onUserLeaveFun)
      document.removeEventListener(EventsEnum.onAudioMuteChangeEvent, this.refreshUserAudioTrack)
      document.removeEventListener(EventsEnum.onVideoMuteChangeEvent, this.refreshUserVideoTrack)
      document.removeEventListener(EventsEnum.onStateReportEvent, this.onStateReportFun)
      // 会议消息
      document.removeEventListener(EventsEnum.meetingInviteAcceptEvent, this.meetingAcceptFun)
      document.removeEventListener(EventsEnum.meetingInviteRejectEvent, this.meetingRejectFun)
      document.removeEventListener(EventsEnum.meetingChatMessageEvent, this.getChatMessageFun)
      document.removeEventListener(EventsEnum.sendChatMessageSuccessEvent, this.getChatMessageFun)
      document.removeEventListener(EventsEnum.sendChatMessageFailEvent, this.getChatMessageFun)
      // 会议控制-设备相关
      document.removeEventListener(EventsEnum.meetingDeviceQueryStatusEvent, this.meetingDeviceQueryStatusFun)
      document.removeEventListener(EventsEnum.sendMicMuteEvent, this.micMuteByLocalFun)
      document.removeEventListener(EventsEnum.sendCamMuteEvent, this.camMuteByLocalFun)
      document.removeEventListener(EventsEnum.meetingDeviceStatusEvent, this.meetingDeviceStatusFun)
      document.removeEventListener(EventsEnum.meetingMicMuteEvent, this.micMuteByRemoteFun)
      document.removeEventListener(EventsEnum.meetingCamMuteEvent, this.camMuteByRemoteFun)
      document.removeEventListener(EventsEnum.meetingCamSwitchEvent, this.camSwitchByRemoteFun)
      document.removeEventListener(EventsEnum.meetingCamZoomEvent, this.camZoomByRemoteFun)
      document.removeEventListener(EventsEnum.meetingCamExposureEvent, this.camExposureByRemoteFun)
      document.removeEventListener(EventsEnum.meetingFlashlightEvent, this.flashlightByRemoteFun)
      document.removeEventListener(EventsEnum.meetingVideoConfigEvent, this.videoConfigByRemoteFun)
      // 会议控制-共享操作相关
      document.removeEventListener(EventsEnum.meetingScreenStatusEvent, this.screenStatusChangedByRemoteFun)
      document.removeEventListener(EventsEnum.meetingRecordingStatusEvent, this.recordingStatusChangedByRemoteFun)
      document.removeEventListener(EventsEnum.meetingFocusGuidance, this.focusGuidanceByRemoteFun)
      document.removeEventListener(EventsEnum.meetingFreezeGuidance, this.freezeGuidanceByRemoteFun)
      document.removeEventListener(EventsEnum.meetingLaserEvent, this.laserByRemoteFun)
      document.removeEventListener(EventsEnum.meetingPaintEvent, this.paintByRemoteFun)
    }
  }
}
