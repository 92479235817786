<template>
  <div>
    <div class="info-container">
      <i style="color: #f56c6c" class="el-icon-warning" />
      <span>将</span>
      <span class="info">{{ ` ${knowledgeData.title} ` }}</span>
      <span>移动到</span>
      <span class="info">{{ ` ${ selectedFile ? selectedFile.name : '' } ` }}</span>
      <span v-if="selectedFile">下</span>
    </div>
    <el-form ref="moveKnowledgeForm" :model="moveKnowledgeForm" :rules="moveKnowledgeFormRules" label-width="100px">
      <single-check-tree :tree-list="getMoveTree" :hint-node-id="knowledgeData.cate1Id" hint-txt="当前所属目录" @node-click="handleClickTreeNode" />
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('moveKnowledgeForm')">保存</el-button>
    </div>
  </div>
</template>

<script>
import SingleCheckTree from '@/views/components/tree/singleCheckTree'
import _ from 'lodash'
import { putKnowledgeBase } from '@/api/knowleageBaseMgr/knowleageBaseMgr'
export default {
  name: 'MoveKnowledgeForm',
  components: { SingleCheckTree },
  props: {
    tree: { type: Array, default: () => [] },
    knowledgeData: { type: Object, default: () => {} }
  },
  data() {
    return {
      moveKnowledgeForm: {
        parentId: null,
        id: null
      },
      moveKnowledgeFormRules: {
        parentId: [{ required: true, message: '请选择所属目录', trigger: 'blur' }]
      },
      selectedFile: null,
      isSubmitting: false,
      moveTree: [],
      selectedNodeId: null
    }
  },
  computed: {
    getMoveTree() {
      const tree = _.cloneDeep(this.tree)
      tree.forEach(node => this.updateNode(node, this.selectedNodeId))
      return tree
    }
  },
  methods: {
    updateNode(node, selectedNodeId) {
      // 设置节点的基本属性
      node.couldSelected = node.isDefault ? false : node.level !== 0 && node.id !== this.knowledgeData.cate1Id
      node.checked = selectedNodeId === node.id

      // 如果节点有子节点，递归更新
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => this.updateNode(child, selectedNodeId))
      }
    },
    handleClickTreeNode(node, data) {
      this.selectedNodeId = data.checked ? data.id : null
      this.selectedFile = data.checked ? data : null
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      const form = {}
      const keys = ['title', 'content', 'sourceUrl', 'attachmentData', 'coverImg', 'status']
      keys.forEach(key => {
        form[key] = this.knowledgeData[key]
      })
      form.cate1Id = this.selectedFile.id
      form.cate2Id = this.selectedFile.parentId
      this.isSubmitting = true
      putKnowledgeBase(this.knowledgeData.id, form).then(resp => {
        this.$message({ message: `操作成功`, type: 'success', center: true })
        this.$emit('confirm-submit')
        this.isSubmitting = false
      }).catch(() => {
        this.isSubmitting = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.info-container{
  padding: 0 5px;
  .info{
    color: #f56c6c;
    font-weight: bold;
  }
}
</style>
