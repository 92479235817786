var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-item-container" }, [
    _vm.orderInfo
      ? _c(
          "div",
          { staticClass: "title-container" },
          [
            _vm.orderInfo.towerWorkTypeValue
              ? _c("el-tag", { staticClass: "order-type" }, [
                  _vm._v(_vm._s(_vm.orderInfo.towerWorkTypeValue)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", {
              staticClass: "order-name",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getContent(
                    _vm.orderInfo.taskWorkOrderName +
                      "（" +
                      _vm.orderInfo.taskWorkOrderCode +
                      "）"
                  )
                ),
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "equipment",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getContent(
                    "整机设备：" +
                      _vm.orderInfo.targetName +
                      "-" +
                      _vm.orderInfo.targetCode
                  )
                ),
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "img-container" },
      _vm._l(_vm.orderInfo.grouped, function (item, index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "img-container" },
            [
              _c("el-image", {
                attrs: { src: item.image, lazy: "" },
                on: {
                  click: function ($event) {
                    return _vm.onClickImg(item.taskWorkOrderId)
                  },
                },
              }),
              _vm._v(" "),
              _c("ht-button", {
                staticClass: "download",
                attrs: {
                  type: "primary",
                  circle: "",
                  size: "mini",
                  icon: "el-icon-download",
                },
                on: {
                  click: function ($event) {
                    return _vm.downloadFile(item.image)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "title",
            domProps: {
              innerHTML: _vm._s("步骤名称：" + _vm.getContent(item.title)),
            },
          }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }