<template>
  <div>
    <el-form ref="warehouseForm" :model="warehouseForm" :rules="warehouseFormRules" label-width="100px">
      <el-form-item label="仓库名称：" prop="name">
        <el-input v-model="warehouseForm.name" placeholder="请输入仓库名称" maxlength="15" show-word-limit />
      </el-form-item>
      <el-form-item label="仓库地址：" prop="location">
        <el-input id="tipinput" v-model="warehouseForm.location" placeholder="请输入仓库地址" maxlength="50" show-word-limit @change="handleLocationChange" />
      </el-form-item>
      <div id="mapContainer" class="map-container" />
      <el-form-item label="仓库管理员：" prop="staffId">
        <el-select
          v-model="warehouseForm.staffId"
          filterable
          :placeholder="staffList.length > 0 ? '请输入姓名进行搜索' : '当前无可选员工'"
          :popper-class="selectPopperClass"
          style="width: 100%"
          remote
          :remote-method="getStaffs"
          clearable
          @change="handleSelectStaff"
          @clear="warehouseForm.staffId = null"
        >
          <el-option v-for="item in staffList" :key="item.userId" :label="item.fullName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话：" prop="mobile">
        <el-input v-model="warehouseForm.mobile" placeholder="请输入联系电话" maxlength="12" show-word-limit />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('warehouseForm')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { validatePhoneNumber } from '@/utils/validate'
import { postWarehouses, putWarehouse } from '@/api/constructionEquipmentMgr/warehouse'
import { getUserMgrList } from '@/api/systemMgr/userMgr'
// import { getAddress } from '@/utils/getDisplay'
const AMap = window.AMap

export default {
  name: 'WarehouseForm',
  props: {
    isEdit: { type: Boolean, default: false },
    selectPopperClass: { type: String, default: null }
  },
  data() {
    return {
      warehouseId: null,
      warehouseForm: {
        name: null,
        location: null,
        longitude: null,
        latitude: null,
        staffId: null,
        mobile: null
      },
      warehouseFormRules: {
        name: [{ required: true, message: '请输入仓库名称', trigger: 'blur' }],
        location: [{ required: true, message: '请输入仓库地址', trigger: 'change' }],
        mobile: [{ required: false, message: '联系电话格式错误', trigger: 'blur', validator: validatePhoneNumber }]
      },
      isSubmitting: false,
      staffList: [],
      map: null,
      autoOptions: {
        input: 'tipinput'
      },
      placeSearch: null,
      isInitAutoComplete: false
    }
  },
  created() {
    this.getStaffs()
  },
  mounted() {
    this.initMap()
  },
  methods: {
    // 初始化地图
    initMap() {
      this.map = new AMap.Map('mapContainer', {
        resizeEnable: true,
        zoom: 20
      })
      // 初始化AMap.AutoComplete
      this.initAutoComplete()
    },
    // 输入提示
    initAutoComplete() {
      this.isInitAutoComplete = true
      AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
        this.auto = new AMap.AutoComplete(this.autoOptions)
        this.placeSearch = new AMap.PlaceSearch({
          map: this.map
        })
        this.auto.on('select', this.selectLocation)
      })
    },
    selectLocation(e) {
      this.placeSearch.setCity(e.poi.adcode)
      this.placeSearch.search(e.poi.name)
      this.warehouseForm.longitude = parseFloat(e.poi.location.lng.toFixed(5))
      this.warehouseForm.latitude = parseFloat(e.poi.location.lat.toFixed(5))
      this.warehouseForm.location = e.poi.name
      // e.poi.district + e.poi.address + e.poi.name
    },
    handleLocationChange() {
      this.warehouseForm.longitude = null
      this.warehouseForm.latitude = null
      this.warehouseForm.location = null
    },
    getStaffs(value) {
      const listQuery = {
        offset: 0,
        count: 20,
        keyword: value
      }
      getUserMgrList(listQuery).then(resp => {
        this.staffList = resp
      })
    },
    handleSelectStaff(value) {
      const index = this.staffList.findIndex(item => item.userId === value)
      this.warehouseForm.mobile = index !== -1 ? this.staffList[index].mobile : null
    },
    getWarehouseInfo(warehouseInfo) {
      this.warehouseId = warehouseInfo.id
      Object.keys(this.warehouseForm).forEach(key => {
        if (key === 'staffId') {
          this.warehouseForm[key] = warehouseInfo.staff && warehouseInfo.staff.id || null
        } else {
          this.warehouseForm[key] = warehouseInfo[key] || null
        }
      })
      // this.warehouseForm.location = warehouseInfo.location
      //   ? warehouseInfo.location
      //   : getAddress(
      //     '',
      //     warehouseInfo.province && warehouseInfo.province.name,
      //     warehouseInfo.city && warehouseInfo.city.name,
      //     warehouseInfo.district && warehouseInfo.district.name,
      //     warehouseInfo.address)
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      this.$refs.warehouseForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          if (this.isEdit) {
            putWarehouse(this.warehouseId, this.warehouseForm).then(() => {
              this.$message({ message: `修改成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          } else {
            postWarehouses(this.warehouseForm).then(() => {
              this.$message({ message: `新建成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
