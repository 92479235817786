var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.status
    ? _c(
        "span",
        [
          _c(
            "el-tag",
            {
              attrs: { type: _vm.statusTypes[_vm.status], effect: _vm.effect },
            },
            [_vm._v("\n    " + _vm._s(_vm.status) + "\n  ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }