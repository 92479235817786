<template>
  <el-card>
    <div class="title-container">
      <el-form
        ref="templateForm"
        class="left-container"
        :model="templateForm"
        :rules="templateFormRules"
        label-width="120px"
        inline
      >
        <el-form-item label="工作流程名称：" prop="name">
          <el-input v-model="templateForm.name" placeholder="请输入工作流程名称" :maxlength="20" show-word-limit class="template-name" @change="isDataSaved = false" />
        </el-form-item>
        <el-form-item label="工作流程类型：" prop="type">
          <el-select v-model="templateForm.type" placeholder="请选择工作流程类型" @change="handleTempTypeChange">
            <el-option
              v-for="(item,index) in getTaskTempTypeEnum()"
              :key="index"
              :label="item.value"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="templateForm.type === 'PATROL'" label="作业类型：" prop="towerWorkType">
          <el-select v-model="templateForm.towerWorkType" placeholder="请选择作业类型">
            <el-option
              v-for="(item,index) in TowerWorkOrderTypeObjectEnum"
              :key="index"
              :label="item.value"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div class="right-container">
        <ht-button type="info" plain @click="cancelCreate">返 回</ht-button>
        <ht-button type="primary" @click="saveFlow(null,true)">保存流程</ht-button>
      </div>
    </div>
    <div class="work-flow-container">
      <div class="flow-container">
        <div class="flow-title-container">
          <span class="title">任务流程</span>
          <ht-button type="text" icon="el-icon-plus" style="font-weight: bold; color: #2e5aff" @click="openStepsDialog">添加{{ getTaskTempType(originalTempType) ? getTaskTempType(originalTempType).stepName : '' }}</ht-button>
        </div>
        <flow :steps="steps" :is-checked-index="isCheckedIndex" class="left-box" @edit-step="editStep" @del-step="delStep" />
      </div>
      <div class="step-container">
        <div class="preview-container">
          <div class="title">步骤预览</div>
          <div v-if="steps.length > 0" class="step-ui-container" :class="{'is-checked-step-container': isCheckedStep}">
            <component
              :is="isCheckedStep.stepUi"
              v-if="isCheckedStep && isCheckedStep.stepUi"
              :properties="isCheckedStep.properties"
              :results="isCheckedStep.results"
              :count="steps.length"
              :current-index="isCheckedIndex"
              :is-last-step="isCheckedIndex === steps.length - 1"
              :is-focused-item="isFocusedItem"
              :height="480"
              is-edit
            />
            <div v-else />
          </div>
          <div v-if="isCheckedStep" class="operating-container">
            <!--            <eht-button type="primary" class="save-step-button" @click="saveStep()">保存步骤</ht-button>-->
            <ht-button type="danger" class="del-step-button" @click="delStep(isCheckedIndex)">删除步骤</ht-button>
          </div>
        </div>
        <div v-if="isCheckedStep && isCheckedStep.config" class="edit-container">
          <div class="title">步骤编辑</div>
          <component
            :is="isCheckedStep.config"
            v-if="isCheckedStep && isCheckedStep.config"
            ref="stepConfig"
            :properties.sync="isCheckedStep.properties"
            :index="isCheckedIndex"
            :config-rules="isCheckedStep.configRules"
            is-edit
            @focused-item="focusedItem"
            @save-step="saveFlow"
            @del-step="delStep"
          />
          <div v-else class="step-no-config">
            <!--            当前未选中步骤或当前步骤无需编辑-->
          </div>
        </div>
      </div>
    </div>
    <ht-dialog ref="stepsDialog" title="选择步骤" width="50%" @close="closeStepsDialog">
      <div slot="content">
        <steps :steps="getStepsUiProperties" :type="templateForm.type" @add-step="addStep" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import _ from 'lodash'
import Flow from '@/views/taskMgr/newTemplateMgr/components/flow'
import Steps from '@/views/taskMgr/newTemplateMgr/components/steps'
import { mapGetters } from 'vuex'
import { StepsUiProperties } from '@/views/taskMgr/newTemplateMgr/components/workFlowSteps'
import { getTaskTempTypeEnum, getTaskTempType } from '@/views/taskMgr/commonJs'
import { getTaskTemplate, postTaskTemplates, putTaskTemplate } from '@/api/taskMgr/workflow'
import { TowerWorkOrderTypeObjectEnum } from '@/views/taskMgr/enum'

export default {
  name: 'CreateWorkFlow',
  components: { Flow, Steps },
  data() {
    return {
      StepsUiProperties, TowerWorkOrderTypeObjectEnum,
      templateForm: {
        name: undefined,
        type: undefined,
        towerWorkType: undefined,
        steps: []
      },
      originalTempType: undefined,
      templateFormRules: {
        name: [{ required: true, message: '请输入工作流程名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择工作流程类型', trigger: 'change' }],
        towerWorkType: [{ required: true, message: '请选择作业类型', trigger: 'change' }]
      },
      templateId: null,
      steps: [],
      isCheckedStep: null,
      isCheckedIndex: -1,
      isDataSaved: true,
      isFocusedItem: null,
      isSavedSteps: []
    }
  },
  computed: {
    ...mapGetters(['userRoutes', 'isHasPatrolMenu', 'isHasRepairMenu']),
    getStepsUiProperties() {
      return StepsUiProperties.filter(item => item.show)
    }
  },
  created() {
    if (this.$route.params && this.$route.params.hasOwnProperty('templateId')) {
      this.templateId = this.$route.params.templateId
      this.getTemplateInfo()
    }
    this.addEventListener()
    this.initTemplateType()
  },
  mounted() {
    this.originalTempType = this.templateForm.type
  },
  beforeDestroy() {
    this.removeEventListener()
  },
  methods: {
    getTaskTempTypeEnum, getTaskTempType,
    initTemplateType() {
      if (this.isHasPatrolMenu && this.isHasRepairMenu) {
        this.templateForm.type = undefined
      } else if (this.isHasPatrolMenu) {
        this.templateForm.type = 'PATROL'
      } else if (this.isHasRepairMenu) {
        this.templateForm.type = 'REPAIR'
      } else {
        this.templateForm.type = undefined
      }
    },
    addEventListener() {
      window.addEventListener('beforeunload', this.beforeunloadHandler)
    },
    removeEventListener() {
      window.removeEventListener('beforeunload', this.beforeunloadHandler)
    },
    beforeunloadHandler(event) {
      if (!this.isDataSaved) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    getTemplateInfo() {
      getTaskTemplate(this.templateId).then(resp => {
        Object.keys(this.templateForm).forEach(item => {
          this.templateForm[item] = resp[item]
        })
        this.originalTempType = this.templateForm.type
        this.templateForm.steps.forEach(item => {
          let step = {}
          const index = StepsUiProperties.findIndex(uiItem => uiItem.tag === item.tag)
          if (index !== -1) {
            step = _.cloneDeep(StepsUiProperties[index])
            step.properties = item.properties
            step.results = item.results
            this.steps.push(step)
          }
        })
        if (this.steps.length > 0) {
          this.editStep(0)
        }
      })
    },
    handleTempTypeChange(newValue) {
      if (this.originalTempType && this.templateForm.type !== this.originalTempType) {
        this.$confirm('该操作将清空任务流程数据，是否确认修改？', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '确认修改',
          cancelButtonText: '放弃修改'
        })
          .then(() => {
            // 如果确认修改，更新原值为新的选中值
            this.originalTempType = newValue
            this.isDataSaved = false
            this.clearSteps()
          })
          .catch(() => {
            // 如果不修改，将选中值改回原值
            this.templateForm.type = this.originalTempType
          })
      } else {
        // 如果从没有值到有值，不进行处理，更新原值为新的选中值
        this.originalTempType = newValue
      }
      this.templateForm.towerWorkType = undefined
    },
    clearSteps() {
      this.steps = []
      this.isCheckedStep = null
      this.isCheckedIndex = null
      this.getTemplateData()
    },
    getTemplateData() {
      const steps = []
      this.steps.forEach((item, index) => {
        const stepItem = {
          name: item.name,
          tag: item.tag,
          isStart: index === 0,
          isEnd: index === this.steps.length - 1,
          properties: item.properties,
          results: item.results,
          sort: index + 1
        }
        steps.push(stepItem)
      })
      this.templateForm = {
        name: this.templateForm.name,
        steps: steps,
        type: this.templateForm.type,
        towerWorkType: this.templateForm.towerWorkType
      }
    },
    focusedItem(name) {
      this.isFocusedItem = name
      this.isDataSaved = false
    },
    cancelCreate() {
      if (!this.isDataSaved) {
        this.$confirm('修改内容未保存，请确认是否返回?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.$router.back()
        }).catch(() => {})
      } else {
        this.$router.back()
      }
    },
    saveFlow(saveText, isAllSaved = false) {
      this.isFocusedItem = null
      this.isDataSaved = true
      this.getTemplateData()
      if (this.templateForm.steps.length > 0) {
        this.$refs.templateForm.validate((valid) => {
          if (valid) {
            const verifyStepsProperties = isAllSaved ? this.verifyAllStepsData() : true
            if (verifyStepsProperties) {
              if (this.templateId) {
                putTaskTemplate(this.templateId, this.templateForm).then(resp => {
                  this.$message({ message: `${saveText || '保存'}成功`, type: 'success', center: true })
                })
              } else {
                postTaskTemplates(this.templateForm).then(resp => {
                  this.templateId = resp.id
                  this.$message({ message: `${saveText || '保存'}成功`, type: 'success', center: true })
                })
              }
            }
          } else {
            this.$message({ message: `请确认工作流程名称和工作流程类型是否填写`, type: 'error', center: true })
            return false
          }
        })
      } else {
        this.$message({ message: `请先添加任务步骤`, type: 'error', center: true })
      }
    },
    openStepsDialog() {
      // 临时模版加
      if (this.steps.filter(item => item.tag === 'GoodsInboundType').length > 0) {
        this.$message({ message: `当前流程已添加"商品上架"步骤项，不可再添加其他步骤`, type: 'error', center: true })
      } else if (this.steps.filter(item => item.tag === 'GoodsPickingType').length > 0) {
        this.$message({ message: `当前流程已添加"拣货任务"步骤项，不可再添加其他步骤`, type: 'error', center: true })
      } else {
        let canAdd = true
        if (this.templateForm.type === 'REPAIR' && this.steps.length >= 1) {
          canAdd = false
        }
        if (canAdd) {
          this.getTemplateData()
          this.$refs.templateForm.validate((valid) => {
            if (valid) {
              this.$refs.stepsDialog.dialogVisible = true
            } else {
              this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
              return false
            }
          })
        } else {
          const type = this.getTaskTempType(this.templateForm.type)
          this.$message({ message: `${type ? type.value : '巡检'}类型只可添加一个模版`, type: 'error', center: true })
        }
      }

      // 原来代码--start
      // let canAdd = true
      // if (this.templateForm.type === 'REPAIR' && this.steps.length >= 1) {
      //   canAdd = false
      // }
      // if (canAdd) {
      //   this.getTemplateData()
      //   this.$refs.templateForm.validate((valid) => {
      //     if (valid) {
      //       this.$refs.stepsDialog.dialogVisible = true
      //     } else {
      //       this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
      //       return false
      //     }
      //   })
      // } else {
      //   const type = this.getTaskTempType(this.templateForm.type)
      //   this.$message({ message: `${type ? type.value : '巡检'}类型只可添加一个模版`, type: 'error', center: true })
      // }
      // 原来代码--end
    },
    closeStepsDialog() {
      this.$refs.stepsDialog.dialogVisible = false
    },
    addStep(item) {
      if (this.steps.length > 0 && item.tag === 'GoodsInboundType') {
        this.$message({ message: `当前流程已添加其他步骤项，不可再添加"商品上架"步骤项`, type: 'error', center: true })
      } else if (this.steps.length > 0 && item.tag === 'GoodsPickingType') {
        this.$message({ message: `当前流程已添加其他步骤项，不可再添加"拣货任务"步骤项`, type: 'error', center: true })
      } else {
        this.isDataSaved = false
        const step = _.cloneDeep(item)
        let addIndex = 0
        if (this.steps.length > 0) {
          addIndex = this.isCheckedIndex + 1
        }
        this.steps.splice(addIndex, 0, step)
        this.isCheckedIndex = addIndex
        this.$refs.stepsDialog.dialogVisible = false
        this.editStep(this.isCheckedIndex)
      }
      // 原来代码--start
      // this.isDataSaved = false
      // const step = _.cloneDeep(item)
      // let addIndex = 0
      // if (this.steps.length > 0) {
      //   addIndex = this.isCheckedIndex + 1
      // }
      // this.steps.splice(addIndex, 0, step)
      // this.isCheckedIndex = addIndex
      // this.$refs.stepsDialog.dialogVisible = false
      // this.editStep(this.isCheckedIndex)
      // 原来代码--end
    },
    editStep(index) {
      this.isFocusedItem = null
      // this.isDataSaved = false
      this.isCheckedIndex = index
      this.isCheckedStep = this.steps[index]
    },
    saveStep() {
      if (this.$refs.stepConfig) {
        this.$refs.stepConfig.onSaveStep()
      } else {
        this.saveFlow()
      }
    },
    delStep(index) {
      this.isFocusedItem = null
      this.$confirm('请确认，是否删除此步骤?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.steps.splice(index, 1)
        if (index === this.isCheckedIndex) {
          this.isCheckedStep = null
          this.isCheckedIndex = null
        }
        this.isDataSaved = false
        // this.saveFlow('删除')
      }).catch(() => {})
    },
    verifyAllStepsData() {
      const errorStep = []
      this.templateForm.steps.forEach((step, index) => {
        if (!this.verifyStepData(step, false)) {
          errorStep.push(index + 1)
        }
      })
      if (errorStep.length > 0) {
        this.$message({ message: `当前工作流程第${errorStep.toString()}步有必填项未填写，请确认`, duration: 2000, type: 'error' })
        return false
      } else {
        return true
      }
    },
    verifyStepData(stepData, needErrorMessage = true) {
      let verifyResult = true
      if (stepData.tag === 'ResultRecordType') {
        if (!stepData.properties.title) {
          if (needErrorMessage) {
            this.$message({ message: '当前步骤未填写步骤名称', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      if (stepData.tag === 'UniversalRecordType') {
        if (!stepData.properties.title) {
          if (needErrorMessage) {
            this.$message({ message: '当前步骤未填写步骤名称', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      if (stepData.tag === 'ValueRecordType') {
        let errorMsg
        if (!stepData.properties.title) {
          errorMsg = '未填写步骤名称'
          verifyResult = false
        } else if (!stepData.properties.dataName) {
          errorMsg = '未填写数据名称'
          verifyResult = false
        } else if (!stepData.properties.minValue && stepData.properties.minValue !== 0) {
          errorMsg = '未填写参考下限'
          verifyResult = false
        } else if (!stepData.properties.maxValue && stepData.properties.maxValue !== 0) {
          errorMsg = '未填写参考上限'
          verifyResult = false
        } else if (!stepData.properties.dataUnit) {
          errorMsg = '未填写数据单位'
          verifyResult = false
        } else {
          const minValue = parseFloat(stepData.properties.minValue)
          const maxValue = parseFloat(stepData.properties.maxValue)
          if (minValue !== null && maxValue !== null && minValue >= maxValue) {
            errorMsg = '下限必须小于上限'
            verifyResult = false
          }
        }
        if (!verifyResult && needErrorMessage) {
          this.$message({ message: `当前步骤${errorMsg}`, duration: 2000, type: 'error' })
        }
        return verifyResult
      }
      return verifyResult
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.template-name{
  width: 300px;
}
.title-container{
  display: flex;
  flex-direction: row;
  .left-container{
    margin-right: 50px;
    flex: 1;
  }
}
.work-flow-container{
  display: flex;
  flex-direction: row;
  gap: 20px;
  .flow-container{
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    border: 1px solid #e8e8e8;
    .flow-title-container{
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: #e0eaf9;
      padding: 0 10px;
      .title{
        font-size: 16px;
        color: $primaryColor;
        font-weight: bold;
      }
    }
  }
  .step-container{
    gap: 20px;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: row;
    .preview-container{
      // todo 自适应
      width: 920px; // 848 + 1 + 1
      min-width: 920px;
      max-width: 920px;
      min-height: 560px;
      border: 1px solid #e8e8e8;
      .step-ui-container{
        // todo 自适应
        min-height: 480px;
        margin: 20px;
        padding: 15px;
      }

      .is-checked-step-container{
        border: 2px solid $primaryColor;
        border-radius: 8px;
        box-shadow: 0 4px 18px rgba(0, 0, 0, 0.5);
      }

      .operating-container{
        width: 100%;
        text-align: center;
        margin: 20px 0;
        .save-step-button{
          font-size: 16px;
        }
        .del-step-button{
          font-size: 16px;
        }
      }
    }
    .edit-container{
      width: 300px;
      min-width: 300px;
      max-width: 300px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      .step-no-config{
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-top: 50px;
      }
    }
    .title {
      height: 50px;
      line-height: 50px;
      background: #e0eaf9;
      padding: 0 10px;
      font-size: 16px;
      color: $primaryColor;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
