<template>
  <div :class="classObj" class="app-wrapper mobile">
    <div :class="[{hasTagsView:needTagsView, 'in-meeting-main-container': isInMeeting}]" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <mobile-navbar />
      </div>
      <div v-if="isInMeeting" class="in-meeting-info">该功能需要将屏幕旋转至竖屏</div>
      <mobile-app-main :show-bottom="showMainBottom" :class="{'in-meeting': isInMeeting}" style="margin-top: 50px" />
    </div>
  </div>
</template>

<script>
import { MobileAppMain, MobileNavbar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'

export default {
  name: 'MobileLayout',
  components: {
    MobileAppMain,
    MobileNavbar
  },
  mixins: [ResizeMixin],
  data() {
    return {
      showMainBottom: !!(this.$route.meta && this.$route.meta.params && this.$route.meta.params.showMainBottom)
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      isMobile: state => state.app.isMobile,
      isInMeeting: state => state.user.isInMeeting,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  watch: {
    '$route'(route) {
      // 监听路由变化，更新选中的菜单项
      this.showMainBottom = !!(route.meta && route.meta.params && route.meta.params.showMainBottom)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  //height: 100%;
  width: 100%;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100% ;
  transition: width 0.28s;
}
</style>

<style>
@media screen and (orientation: landscape) {
  .in-meeting {
    transform: rotate(-90deg);
    transform-origin: top left;
    width: 100vh;
    height: 100vw;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
  }
  .in-meeting-main-container{
    background: rgba(125, 125, 127, 0.1);
  }
  .in-meeting-info{
    position: fixed;
    top: 50%;
    left: 50%;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
