import request from '@/utils/request'
const construction = 'construction/'
const equipment_stocks = 'equipment_stocks/'
const equipment_stock = 'equipment_stock/'
const equipment_stock_records = 'equipment_stock_records/'
const warehouse_stocks = 'warehouse_stocks/'
const warehouse_stock = 'warehouse_stock/'
const warehouse_stock_records = 'warehouse_stock_records/'

// 库存管理（按设备） 获取设备库存列表
export function getEquipmentStocks(listQuery) {
  return request({ url: `${construction}${equipment_stocks}`, method: 'GET', params: listQuery })
}

// 库存管理（按设备） 获取库存详情
export function getEquipmentStock(equipmentId, listQuery) {
  return request({ url: `${construction}${equipment_stock}${equipmentId}`, method: 'GET', params: listQuery })
}

// 库存管理（按设备） 出库/ 入库
export function putEquipmentStocks(data) {
  return request({ url: `${construction}${equipment_stocks}`, method: 'PUT', data: data })
}

// 库存管理（按设备） 物流跟踪
export function getEquipmentStockRecords(equipmentId, listQuery) {
  return request({ url: `${construction}${equipment_stock_records}${equipmentId}`, method: 'GET', params: listQuery })
}

// 库存管理（按仓库） 获取仓库库存列表
export function getWarehouseStocks(listQuery) {
  return request({ url: `${construction}${warehouse_stocks}`, method: 'GET', params: listQuery })
}

// 库存管理（按仓库） 获取库存详情
export function getWarehouseStock(warehouseId, listQuery) {
  return request({ url: `${construction}${warehouse_stock}${warehouseId}`, method: 'GET', params: listQuery })
}

// 库存管理（按仓库） 出库/ 入库
export function putWarehouseStocks(data) {
  return request({ url: `${construction}${warehouse_stocks}`, method: 'PUT', data: data })
}

// 库存管理（按设备） 物流跟踪
export function getWarehouseStockRecords(equipmentId, listQuery) {
  return request({ url: `${construction}${warehouse_stock_records}${equipmentId}`, method: 'GET', params: listQuery })
}

// 库存管理（按设备）：可出入库的工程列表
export function getEquipmentStockProjects(equipmentId, listQuery) {
  return request({ url: `${construction}${equipment_stock}${equipmentId}/projects/`, method: 'GET', params: listQuery })
}

// 库存管理（按仓库）： 新设备可入库的设备
export function getWarehouseStockEquipments(listQuery) {
  return request({ url: `${construction}${warehouse_stock}equipments/`, method: 'GET', params: listQuery })
}

// 库存管理(按仓库)：可工程返库的工程（仅显示有库存的工程）
export function getWarehouseStockProjects(listQuery) {
  return request({ url: `${construction}${warehouse_stock}projects/`, method: 'GET', params: listQuery })
}

// 设备库存列表（仅展示有库存的设备）
export function getStocks(listQuery) {
  return request({ url: `${construction}stocks/`, method: 'GET', params: listQuery })
}
