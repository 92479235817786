<template>
  <ht-table ref="list" :data="videoList" style="cursor: pointer">
    <ht-table-column label="序号" type="index" fixed="left" />
    <ht-table-column label="视频链接" prop="url" :show-overflow-tooltip="false">
      <template v-slot="{row}">
        <el-link type="primary" :href="row.url" target="_blank">{{ row.url }}</el-link>
      </template>
    </ht-table-column>
  </ht-table>
</template>

<script>
export default {
  name: 'VideoList',
  props: {
    videoList: { type: Array, default: () => [] }
  }
}
</script>

<style scoped>

</style>
