var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "copyTemplateForm",
          staticStyle: { "margin-right": "20px" },
          attrs: {
            model: _vm.copyTemplateForm,
            rules: _vm.copyTemplateFormRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "表番：", prop: "code" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入表番",
                  maxlength: 30,
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.copyTemplateForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.copyTemplateForm, "code", $$v)
                  },
                  expression: "copyTemplateForm.code",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "表名：", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入表名",
                  maxlength: 30,
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.copyTemplateForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.copyTemplateForm, "name", $$v)
                  },
                  expression: "copyTemplateForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "关联部品：",
                prop: "nskMaterialId",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.nskMaterialList.length > 0
                        ? "请选择部品"
                        : "当前无可选部品",
                    clearable: "",
                    remote: "",
                    "remote-method": _vm.getNskMaterialsList,
                  },
                  on: {
                    clear: function ($event) {
                      _vm.copyTemplateForm.nskMaterialId = null
                    },
                  },
                  model: {
                    value: _vm.copyTemplateForm.nskMaterialId,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyTemplateForm, "nskMaterialId", $$v)
                    },
                    expression: "copyTemplateForm.nskMaterialId",
                  },
                },
                _vm._l(_vm.nskMaterialList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: "" + item.name + item.code,
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "ht-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "ht-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("copyTemplateForm")
                },
              },
            },
            [_vm._v("确定复制")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }