var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClearSearch } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "品番" },
                model: {
                  value: _vm.listQuery.nskMaterialCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "nskMaterialCode", $$v)
                  },
                  expression: "listQuery.nskMaterialCode",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "品名" },
                model: {
                  value: _vm.listQuery.nskMaterialName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "nskMaterialName", $$v)
                  },
                  expression: "listQuery.nskMaterialName",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "供应商" },
                model: {
                  value: _vm.listQuery.supplierName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "supplierName", $$v)
                  },
                  expression: "listQuery.supplierName",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd",
                  placement: "bottom-start",
                  "start-placeholder": "来料日",
                  "on-search": "",
                  "end-placeholder": "来料日",
                },
                model: {
                  value: _vm.receivingAt,
                  callback: function ($$v) {
                    _vm.receivingAt = $$v
                  },
                  expression: "receivingAt",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd",
                  placement: "bottom-start",
                  "start-placeholder": "检查日",
                  "on-search": "",
                  "end-placeholder": "来料日",
                },
                model: {
                  value: _vm.checkAt,
                  callback: function ($$v) {
                    _vm.checkAt = $$v
                  },
                  expression: "checkAt",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "检查员" },
                model: {
                  value: _vm.listQuery.staffName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "staffName", $$v)
                  },
                  expression: "listQuery.staffName",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "检查状态" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                _vm._l(_vm.ChecklistStatusEnum, function (key, value) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: key, value: value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "判定结果" },
                  model: {
                    value: _vm.listQuery.isAbnormal,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isAbnormal", $$v)
                    },
                    expression: "listQuery.isAbnormal",
                  },
                },
                _vm._l(_vm.ChecklistAbnormalEnum, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.value, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
          on: { "row-click": _vm.handleRowClick },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.NskCheckListMgrListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "检查员"
                          ? _c("span", [
                              _vm._v(_vm._s(row.staff.fullName || "-")),
                            ])
                          : item.label === "检查状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.ChecklistStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.ChecklistStatusEnum[row[item.prop]] ||
                                      "-"
                                  )
                                ),
                              ]
                            )
                          : item.label === "判定结果" && row.finishAt
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "status",
                                attrs: {
                                  type: row.isAbnormal ? "danger" : "success",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(row.isAbnormal ? "不合格" : "合格")
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 200 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-view" },
                        on: {
                          click: function ($event) {
                            return _vm.onView(row, true)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-button",
                      {
                        attrs: {
                          loading: _vm.loadingIndex === $index,
                          disabled:
                            _vm.loadingIndex !== -1 &&
                            _vm.loadingIndex !== $index,
                          type: "text",
                          icon: "el-icon-document",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onExport(row, $index)
                          },
                        },
                      },
                      [_vm._v("导出")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          icon: "el-icon-delete",
                          "confirm-title": "请确认是否要删除该部品？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.onDelete(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }