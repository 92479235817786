<template>
  <el-dialog
    ref="dialogRef"
    :visible.sync="meetingDialogVisible"
    :class="[`meeting-dialog`, { 'fullscreen-meeting-dialog': fullscreen, 'is-mobile-fullscreen-meeting-dialog': fullscreen && isMobile, 'is-mobile-in-meeting': isMobile && isInMeeting }]"
    :modal="!isSinoma"
    lock-scroll
    destroy-on-close
    modal-append-to-body
    :show-close="false"
    :fullscreen="fullscreen"
    :width="`${meetingVideoProperty.normalMeetingDialogSmallWidth}px`"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @before-close="beforeExitRoom"
  >
    <template slot="title">
      <meeting-head
        ref="meetingHeadRef"
        :meeting-info="meetingInfo"
        :exit-name="isCalling ? '取消会议': '离开会议'"
        :is-remote-share-screen="isRemoteShareScreen"
        :share-screen-user-info="shareScreenUserInfo"
        :is-remote-recording="isRemoteRecording"
        :recording-user-info="recordingUserInfo"
        :is-fullscreen="fullscreen"
        :is-mobile="isMobile"
        @exit-room="beforeExitRoom"
        @minimized="minimizedScreen"
        @exit-full="exitFullScreen"
        @enter-full="enterFulScreen"
      />
    </template>
    <div class="meeting-body">
      <div class="invite-list">
        <div v-for="(item, index) in inviteList" :key="index" class="invite-hint">
          <img :src="Call" alt="" class="icon">
          <span class="content">{{ `${item.fullName}等待接听：${item.waitingTime}s` }}</span>
          <span class="cancel" @click="inviteCancel(item.imUserId)">取消邀请</span>
        </div>
      </div>
      <div
        class="meeting-video-wrapper"
        :class="{
          'meeting-small-video-wrapper': showRemoteVideo && !enLarge && remoteStaffList.length > 1,
          'meeting-local-video-wrapper': showLocalVideo && !showRemoteVideo
        }"
      >
        <local-video
          v-if="showLocalVideo && !showRemoteVideo"
          :id="'user_' + imUserId"
          :user-info="userInfo"
          :is-expert="isExpert(userInfo)"
          is-large-video
          :is-share-video="isShareCameraVideo || isShareScreenVideo"
          :is-fullscreen="fullscreen"
          :video-track="localVideoTrack"
          :audio-track="localAudioTrack"
          :is-remote-paint="isStaff(userInfo) ? isRemotePaint : false"
          :is-remote-laser="isStaff(userInfo) ? isRemoteLaser : false"
          :remote-paint-info="isStaff(userInfo) ? remotePaintInfo: null"
          :remote-laser-info="isStaff(userInfo) ? remoteLaserInfo : null"
          :is-focused-guide="isFocusedGuidanceByRemote"
          :is-mobile="isMobile"
          @local-video-w-h="getLocalVideoWh"
        />
        <div v-else-if="!(showLocalVideo && !showRemoteVideo)" style="width: 100%">
          <remote-video
            v-if="isRemoteShareScreen"
            id="publicScreenVideo"
            is-screen
            :user-info="publicScreenVideo.userInfo"
            is-share-video
            is-large-video
            :is-fullscreen="fullscreen"
            :show-change-size-button="false"
            :remote-receivers="remoteReceivers"
            :video-track="remoteScreenVideoTrack"
            :is-mobile="isMobile"
          />
          <template v-else>
            <remote-video
              v-if="enLarge && enLargeVideoInfo && enLargeVideoInfo.userInfo"
              id="enlargeVideo"
              :video-info="enLargeVideoInfo"
              :user-info="enLargeVideoInfo.userInfo"
              :device-status="enLargeVideoInfo.deviceStatus"
              :video-track="enLargeVideoInfo.videoTrack && enLargeVideoInfo.videoTrack.hasOwnProperty('_streamId') ? enLargeVideoInfo.videoTrack : null"
              :audio-track="enLargeVideoInfo.audioTrack && enLargeVideoInfo.audioTrack.hasOwnProperty('_streamId') ? enLargeVideoInfo.audioTrack : null"
              :is-share-video="(enLargeVideoInfo.deviceStatus && enLargeVideoInfo.deviceStatus.isCamOn) || (enLargeVideoInfo.deviceStatus && enLargeVideoInfo.deviceStatus.isUvcConnected && enLargeVideoInfo.deviceStatus.isUvcCamOn)"
              is-large-video
              :show-change-size-button="remoteStaffList.length > 1"
              :is-fullscreen="fullscreen"
              :remote-receivers="remoteReceivers"
              :is-focused-guide="enLargeVideoInfo.isFocusedGuide"
              :is-remote-paint="isRemotePaint"
              :is-remote-laser="isRemoteLaser"
              :remote-paint-info="remotePaintInfo"
              :remote-laser-info="remoteLaserInfo"
              :is-mobile="isMobile"
              :is-freeze-guidance="isFreezeGuidance || isFrozenGuidanceByRemote"
              @zoom-video="zoomVideo"
              @cam-manual-focused="camManualFocused"
              @change-one-mic-mute="changeOneMicMute"
              @change-one-cam-mute="changeOneCamMute"
              @change-one-uvc-cam="changeOneUvcCam"
              @change-one-cam-switch="changeOneCamSwitch"
              @change-one-zoom-level="changeOneZoomLevel"
              @change-one-flashlight="changeOneFlashlight"
              @change-one-exposure-level="changeOneExposureLevel"
              @change-one-video-resolution="changeOneVideoResolution"
              @change-one-focus-guidance="changeOneFocusGuidance(0, $event)"
              @paint-drawing="sendPaintDrawingMsg"
              @laser-moving="sendLaserMovingMsg"
              @handle-resize="handleResize"
            />
            <div v-else class="remote-video-list-wrapper">
              <remote-video
                v-for="(item, index) in remoteUserList"
                v-show="showRemoteVideo && item.videoTrack && item.videoTrack.hasOwnProperty('_streamId') && item.userInfo && item.userInfo.imUserId"
                :id="'user_' + item.imUserId"
                :key="index"
                :video-info="item"
                :user-info="item.userInfo"
                :device-status="item.deviceStatus"
                :video-track="item.videoTrack && item.videoTrack.hasOwnProperty('_streamId') ? item.videoTrack : null"
                :audio-track="item.audioTrack && item.audioTrack.hasOwnProperty('_streamId') ? item.audioTrack : null"
                :is-share-video="item.deviceStatus && item.deviceStatus.isCamOn || (item.deviceStatus && item.deviceStatus.isUvcConnected && item.deviceStatus.isUvcCamOn)"
                :is-large-video="isLargeVideo"
                :show-change-size-button="remoteStaffList.length > 1"
                :is-fullscreen="fullscreen"
                :remote-receivers="remoteReceivers"
                :is-focused-guide="item.isFocusedGuide"
                :is-mobile="isMobile"
                @enLarge-video="enlargeVideo(index)"
                @cam-manual-focused="camManualFocused"
                @change-one-mic-mute="changeOneMicMute"
                @change-one-cam-mute="changeOneCamMute"
                @change-one-uvc-cam="changeOneUvcCam"
                @change-one-cam-switch="changeOneCamSwitch"
                @change-one-zoom-level="changeOneZoomLevel"
                @change-one-flashlight="changeOneFlashlight"
                @change-one-exposure-level="changeOneExposureLevel"
                @change-one-video-resolution="changeOneVideoResolution"
                @change-one-focus-guidance="changeOneFocusGuidance(index, $event)"
              />
            </div>
          </template>
        </div>
      </div>
      <box-message
        ref="boxMessageRef"
        :fullscreen="fullscreen"
        :room-id="roomId"
        :chat-room-id="chatRoomId"
        :user-info="userInfo"
        :messages-list="messagesList"
        :is-mobile="isMobile"
        :is-hidden-translation="isHiddenTranslation"
        @close-box="getDialogWidth('boxMessageRef')"
      />
      <box-attendees
        v-if="showAttendeesBox"
        ref="boxAttendeesRef"
        :fullscreen="fullscreen"
        :user-ids="userIds"
        :room-id="roomId"
        :user-info="userInfo"
        :show-bottom-button="isExpert(userInfo)"
        :is-mute-all-cam="isMuteAllCam"
        :is-mute-all-mic="isMuteAllMic"
        :is-share-audio="isShareAudio"
        :is-share-camera-video="isShareCameraVideo"
        :local-audio-track="localAudioTrack"
        :local-video-track="localVideoTrack"
        :remote-audio-tracks="remoteAudioTracks"
        :remote-video-tracks="remoteVideoTracks"
        :remote-device-states="remoteDeviceStates"
        :is-mobile="isMobile"
        @close-box="getDialogWidth('boxAttendeesRef')"
        @change-one-mic-mute="changeOneMicMute"
        @change-one-cam-mute="changeOneCamMute"
        @change-all-mic-mute="changeAllMicMute"
        @change-all-cam-mute="changeAllCamMute"
      />
      <box-online-user
        v-if="showOnlineUserBox"
        ref="boxOnlineUserRef"
        :fullscreen="fullscreen"
        :user-info="userInfo"
        :user-ids="userIds"
        :invite-list="inviteList"
        :is-mobile="isMobile"
        @invite="sendMeetingInviteMsg"
        @copy-meeting-url="copyMeetingUrl"
        @close-box="getDialogWidth('boxOnlineUserRef')"
      />
    </div>
    <template slot="footer">
      <meeting-footer
        ref="meetingFooter"
        :user-count="userIds.length + 1"
        :show-remote-video="showRemoteVideo"
        :show-share-camera-video="isStaff(userInfo) && !isExpert(userInfo)"
        :show-share-screen-video="isExpert(userInfo) || isStaff(userInfo)"
        :show-recording-video="isExpert(userInfo)"
        :show-pen="isExpert(userInfo) && isFocusGuidance"
        :show-paint="isExpert(userInfo) && isFocusGuidance"
        :show-guidance="isExpert(userInfo) && isFocusGuidance"
        :show-video-resolution="isStaff(userInfo) && !isExpert(userInfo)"
        :is-open-box="isOpenBox"
        :is-share-audio="isShareAudio"
        :is-share-camera-video="isShareCameraVideo"
        :is-share-screen-video="isShareScreenVideo"
        :is-recording-video="isRecording"
        :is-laser-moving="isLaserMoving"
        :is-paint-drawing="isPaintDrawing"
        :is-freeze-guidance="isFreezeGuidance"
        :fullscreen="fullscreen"
        :message-count="unreadCount"
        :local-bitrate="localBitrate"
        :video-resolution="videoResolution"
        :en-large-video-info="enLargeVideoInfo"
        :is-mobile="isMobile"
        @audio-share-click="audioShareFun"
        @camera-video-share-click="cameraVideoShareFun"
        @screen-video-share-click="screenVideoShareFun"
        @video-recording-click="videoRecordingFun"
        @laser-click="useLaser"
        @paint-click="usePaint"
        @clear-click="useClear"
        @guidance-click="freezeGuidance"
        @message-click="openMeetingMessage"
        @attendees-click="viewAttendees"
        @invite-person-click="viewOnlineUser"
        @select-video-resolution="selectVideoResolution"
      />
    </template>
    <freeze-guidance
      id="freezeGuidance"
      ref="freezeGuidanceRef"
      :fullscreen="fullscreen"
      :im-user-id="enLargeVideoInfo && enLargeVideoInfo.imUserId"
      :room-id="roomId"
      :frozen-guidance-info="frozenGuidanceInfo"
      :video-property="enLargeVideoInfo && enLargeVideoInfo.videoProperty"
      :is-remote-paint="freezeCanvasTrack.isRemotePaint"
      :is-remote-laser="freezeCanvasTrack.isRemoteLaser"
      :remote-laser-info="freezeCanvasTrack.remoteLaserInfo"
      :remote-paint-info="freezeCanvasTrack.remotePaintInfo"
      :en-large-video-rect="enLargeVideoRect"
      :is-mobile="isMobile"
      @freeze-guidance-start="freezeGuidanceStart"
      @freeze-guidance-finish="freezeGuidanceFinish"
      @freeze-guidance-start-fail="freezeGuidanceStartFail"
      @use-laser="freezeUseLaser"
      @laser-moving="sendLaserMovingMsg"
      @use-paint="freezeUsePaint"
      @paint-drawing="sendPaintDrawingMsg"
    />
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import rtc from '../rongCloud/rtc'
import im from '@/views/newRemoteGuidance/rongCloud/im'
// 图标
import Close from '@/assets/image/newRemoteGuidance/close.png'
import EnterFul from '@/assets/image/newRemoteGuidance/enter_full.png'
import ExitFul from '@/assets/image/newRemoteGuidance/exit_full.png'
import Minimize from '@/assets/image/newRemoteGuidance/minimize.png'
import Call from '@/assets/image/newRemoteGuidance/call_write.png'
// 组件
import MeetingHead from './meetingHead'
import MeetingFooter from './meetingFooter'
import LocalVideo from './localVideo'
import RemoteVideo from './remoteVideo'
import BoxMessage from './boxMessage'
import BoxAttendees from './boxAttendees'
import BoxOnlineUser from './boxOnlineUser'
import FreezeGuidance from './freezeGuidance'
// js-mixins
import meetingMsg from '../js/meetingMsg'
import meetingInvite from '../js/meetingInvite'
import meetingDeviceControl from '../js/meetingDeviceControl'
import meetingEventListener from '../js/meetingEventListener'
import meetingShareScreen from '../js/meetingShareScreen'
import meetingRecordScreen from '../js/meetingRecordScreen'
import meetingFocusGuidance from '../js/meetingFocusGuidance'
import meetingFreezeGuidance from '../js/meetingFreezeGuidance'
import meetingUseWhiteBoard from '../js/meetingUseWhiteBoard'
import meetingChatMessage from '../js/meetingChatMessage'
import meetingExceptionHandle from '../js/meetingExceptionHandle'
import meetingLocalOperation from '../js/meetingLocalOperation'
import meetingTrackMgr from '../js/meetingTrackMgr'

import dwCustomImMsg from '../js/dwCustomImMsg'
import { EventsEnum } from '../var/eventsVar'
import { meetingVideoProperty, videoConfigs } from '@/views/newRemoteGuidance/var/businessVar'
import { isExpert, isStaff, notWebsocket } from '../js/business'
import { getContactsApi, getMeeting, putKickImUser } from '@/api/newRemoteGuidance/newRemoteGuidance'
// 音频
import { Howl } from 'howler'
import HangUp from '@/assets/audio/hang_up.mp3'

export default {
  name: 'Meeting',
  components: { MeetingHead, MeetingFooter,
    LocalVideo, RemoteVideo,
    BoxMessage, BoxAttendees, BoxOnlineUser,
    FreezeGuidance
  },
  mixins: [
    meetingMsg, meetingInvite, meetingDeviceControl, meetingEventListener,
    meetingShareScreen, meetingRecordScreen, meetingFocusGuidance, meetingFreezeGuidance, meetingUseWhiteBoard,
    meetingChatMessage, meetingExceptionHandle, meetingLocalOperation, meetingTrackMgr],
  data() {
    return {
      Close, EnterFul, ExitFul, Minimize, Call,
      hangUp: new Howl({ src: [HangUp] }),
      meetingVideoProperty, videoConfigs,
      meetingDialogVisible: false,
      fullscreen: false,
      userInfo: null,
      userImInfo: null,
      imUserId: null,
      meetingInfo: null,
      room: null,
      roomId: null,
      chatRoomId: null,
      userIds: [],
      isCalling: false,
      isSingleMeeting: true,
      // 邀请计时
      invites: {},
      inviteList: [],
      aliveMessageInterval: null,
      // 用户列表
      onlineUserList: [],
      remoteUserList: [],
      remoteStaffList: [],
      videoResolution: '720P',
      // 全部麦克风，摄像头状态
      isMuteAllCam: false,
      isMuteAllMic: false,
      // 本地
      isShareAudio: true,
      isShareCameraVideo: false,
      localBitrate: null,
      localAudioTrack: null,
      localVideoTrack: null,
      localScreenVideoTrack: null,
      localVideoWH: { width: 848, height: 480 },
      // 远端
      remoteTracks: [],
      remoteAudioTracks: [],
      remoteVideoTracks: [],
      remoteReceivers: [],
      remoteDeviceStates: [],
      // 屏幕共享
      publicScreenVideo: {},
      isShareScreenVideo: false,
      isRemoteShareScreen: false,
      shareScreenUserInfo: null,
      shareScreenKeys: ['shareScreenImUserId', 'shareScreenFullName', 'shareScreenUserName'],
      remoteScreenVideoTrack: null,
      // 屏幕录制
      ryRecordId: null,
      isRecording: false,
      isRemoteRecording: false,
      recordingUserInfo: null,
      recordingKeys: ['recordingImUserId', 'recordingFullName', 'recordingUserName', 'ryRecordId'],
      // 聚焦指导
      isFocusGuidance: false,
      isFocusedGuidanceByRemote: false,
      focusedGuidanceInfo: null,
      focusedGuidanceKeys: ['focusedUserId'],
      // 标注指导
      isFreezeGuidance: false,
      isFrozenGuidanceByRemote: false,
      frozenGuidanceInfo: null,
      frozenGuidanceKeys: ['frozenUserId', 'frozenUserName', 'frozenFullName', 'frozenImgUrl'],
      freezeCanvasTrack: { isRemoteLaser: false, isRemotePaint: false, remoteLaserInfo: null, remotePaintInfo: null },
      showFreezeDialog: false,
      // 白板
      remoteLaserInfo: null,
      remotePaintInfo: null,
      remotePaintTimeStamp: null,
      isLaserMoving: false,
      isRemoteLaser: false,
      isPaintDrawing: false,
      isRemotePaint: false,
      isFreezeLaserMoving: false,
      isFreezePaintDrawing: false,
      // 放大
      enLarge: false,
      enLargeVideoInfo: {},
      // 会议消息，在线用户，参会用户box
      isOpenBox: false,
      unreadCount: 0,
      messagesList: [],
      showAttendeesBox: false,
      showOnlineUserBox: false,
      isSinoma: false, // 是否智科
      // todo 本地音视频来源选择
      cameras: [],
      speakers: [],
      microphones: [],
      enLargeVideoRect: {},
      isHiddenTranslation: process.env.VUE_APP_CONFIG_HAS_TRANSLATION !== 'true'
    }
  },
  computed: {
    showLocalVideo() {
      if (isExpert(this.userInfo)) {
        // 专家端显示本端条件：
        //  1。会议开始呼叫中无人进入：this.isCalling
        //  3。远端的用户列表为空：this.remoteUserList.length === 0
        //  4。远端用户列表不为空，但是里面没有视频流，并且当前不是在进行屏幕共享：this.remoteUserList.length !== 0，!this.isRemoteShareScreen，!this.remoteUserListHasVideoTrack()
        return (this.isCalling || this.remoteUserList.length === 0 || (this.remoteUserList.length !== 0 && !this.remoteUserListHasVideoTrack() && !this.isRemoteShareScreen))
      } else if (isStaff(this.userInfo)) {
        return !this.isRemoteShareScreen
      } else {
        return false
      }
    },
    showRemoteVideo() {
      if (isExpert(this.userInfo)) {
        return !(this.isCalling || this.remoteUserList.length === 0 || (this.remoteUserList.length !== 0 && !this.remoteUserListHasVideoTrack() && !this.isRemoteShareScreen))
      } else if (isStaff(this.userInfo)) {
        return this.isRemoteShareScreen
      } else {
        return false
      }
    },
    isLargeVideo() {
      return this.remoteUserList.filter(item => item.videoTrack && item.videoTrack.hasOwnProperty('_streamId')).length <= 1
    },
    showBottomGuidance() {
      // 显示激光笔，画笔，标注指导操作： 放大某一个视频或当前远程里面只有一个现场
      if (this.enLarge) {
        return true
      } else return this.remoteStaffList.length === 1
    },
    isMobile() {
      return store.getters.isMobile
    },
    isInMeeting() {
      return store.getters.isInMeeting
    }
  },
  watch: {
    isShareAudio: {
      handler: function() {
        this.refreshUserTracksStatus(true)
      }
    },
    isShareCameraVideo: {
      handler: function() {
        this.refreshUserTracksStatus(true)
      }
    },
    userIds: {
      handler: function() {
        this.getMeetingUserInfo()
      }
    },
    remoteTracks: {
      handler: function() {
        window.remoteTracks = this.remoteTracks
      }
    },
    localAudioTrack: {
      handler: function() {
        window.localTracks = [this.localAudioTrack, this.localVideoTrack, this.localScreenVideoTrack]
      }
    },
    localVideoTrack: {
      handler: function() {
        window.localTracks = [this.localAudioTrack, this.localVideoTrack, this.localScreenVideoTrack]
      }
    },
    localScreenVideoTrack: {
      handler: function() {
        window.localTracks = [this.localAudioTrack, this.localVideoTrack, this.localScreenVideoTrack]
      }
    }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
    this.messagesList = []
    this.getOnlineUserList()
    window.remoteTracks = this.remoteTracks
    window.localTracks = [this.localAudioTrack, this.localVideoTrack]
  },
  beforeDestroy() {
    this.removeCurrentMeetingEventListener()
  },
  methods: {
    isExpert, isStaff, notWebsocket,
    openDialog(userInfo, userImInfo) {
      store.dispatch('user/setIsInMeeting', true)
      this.isSinoma = store.getters.isSinoma
      if (store.getters.isSinoma) {
        this.fullscreen = true // isSinoma默认全屏展示
      }
      if (this.isMobile) {
        this.fullscreen = true
      }
      this.addCurrentMeetingEventListener()
      this.meetingDialogVisible = true
      this.userInfo = Object.assign({}, userInfo, userImInfo)
      this.userImInfo = userImInfo
      this.imUserId = userImInfo && userImInfo.imUserId
      // 1.进入会议
      // 专家:音频开启,摄像头关闭,共享关闭
      // 现场:音频开启,摄像头开启,共享关闭
      this.isShareAudio = true
      this.isShareCameraVideo = !isExpert(this.userInfo)
      this.isShareScreenVideo = false
    },
    closeDialog() {
      if (store.getters.isSinoma) {
        window.parent.postMessage({ type: 'IsMinimized', params: { isMinimized: true }}, '*')
        window.parent.postMessage({ type: 'InMeeting', params: { inMeeting: false }}, '*')
      }
      store.dispatch('user/changeIsBeingInvited', false)
      store.dispatch('user/setIsInMeeting', false)
      this.hangUp.play()
      this.removeCurrentMeetingEventListener()
      this.meetingDialogVisible = false
      this.imUserId = null
      this.$emit('close-dialog')
      document.dispatchEvent(new CustomEvent(EventsEnum.meetingExitEvent, { detail: { meetingDialogVisible: this.meetingDialogVisible }}))
    },
    async joinRoom(roomId, chatRoomId, isCreateRoom = false, meetingCreatorInfo) {
      this.roomId = roomId
      this.chatRoomId = chatRoomId
      return new Promise((resolve, reject) => {
        // rtc：加入会议房间
        rtc.joinRTCRoom(this.roomId).then(async res => { // 返回房间相关信息：房间号，房间内资源，房间内用户（除了自己之外的别人）
          let isJoinError = false // 设置加入房间出错isJoinError = false(代表加入正常)
          this.room = res.room
          this.getMeetingCreatorInfo(isCreateRoom, meetingCreatorInfo) // 获取房间信息：谁创建的会议
          await this.joinChatRoom(isCreateRoom, this.chatRoomId).catch(error => { // 加入房间的聊天时：加入聊天室失败，聊天功能不可用，会议功能不完整，无法进行会议
            isJoinError = true
            reject(error)
          }) // 加入聊天室
          await this.getLocalTrack({ audio: true, camera: false }).then(() => { // 获取本地track并发布
            this.publishAll({ audio: true, camera: false })
          }).catch(error => {
            // 53010：获取用户媒体资源流失败（表现为环境为http,这种环境无法获取用户媒体资源，无法进行会议）
            if (error.error && error.error.code && error.error.code === 53010) {
              isJoinError = true
            }
            reject(error)
          })
          if (!isJoinError) { // isJoinError=false: 上述操作没有异常，可以继续会议
            this.userIds = []
            this.remoteTracks = []
            this.userIds.push(...(res.userIds || [])) // 房间用户imUserIds
            this.remoteTracks.push(...(res.tracks || [])) // 房间所有的媒体流（音频+视频）
            if (this.remoteTracks.length > 0) { // 房间内有远端Track
              const videoTracks = this.remoteTracks.filter(track => track.isVideoTrack()) // 过滤videoTracks， 如果房间内有多个视频流，则订阅小流
              await this.subscribe(this.room, this.remoteTracks, videoTracks.length > 1) // 订阅媒体流
              this.isHasShare() // 有没有屏幕共享
              this.isHasFocus() // 有没有聚焦指导
              this.isHasFreeze() // 有没有标注指导
            }
            this.cameras = await rtc.getCameras() // 摄像头
            this.speakers = await rtc.getSpeakers() // 听筒
            this.microphones = await rtc.getMicrophones() // 麦克风
            this.isHasRecoding() // 是否有录制
            rtc.initRtcRoomListener(this.room) // 初始化房间监听事件
            this.messagesList = []
            this.unreadCount = 0
            getMeeting(this.roomId).then(res => {
              this.getHistoryChatMessage(res.chatRecords) // 获取历史消息
            })
            resolve(res)
          }
        }).catch(error => {
          if (error === '当前环境无法开启会议') {
            reject({ reason: '当前环境无法开启会议', error: error })
          } else {
            reject({ reason: '加入会议失败', error: error })
          }
        })
      })
    },
    beforeExitRoom(reason) {
      this.$confirm('请确认，是否退出会议?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.exitRoom(reason).then(() => {
          this.closeDialog()
        }).catch(() => {
          this.$log.myLog('退出错误')
          putKickImUser(this.userImInfo.imUserId).then(() => {
            this.closeDialog()
          }).catch(() => {
            this.closeDialog()
          })
        })
      }).catch(() => {})
      // if (this.isFocusedGuidanceByRemote) {
      //   this.$message({ message: `正在被聚焦指导中，不可退出会议`, type: 'error', center: true })
      // } else {
      //   this.$confirm('请确认，是否退出会议?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.exitRoom(reason).then(() => {
      //       this.closeDialog()
      //     }).catch(() => {
      //       this.$log.myLog('退出错误')
      //       putKickImUser(this.userImInfo.imUserId).then(() => {
      //         this.closeDialog()
      //       }).catch(() => {
      //         this.closeDialog()
      //       })
      //     })
      //   }).catch(() => {})
      // }
    },
    async exitRoom(reason) {
      const localTracks = []
      if (this.localAudioTrack) localTracks.push(this.localAudioTrack)
      if (this.localVideoTrack) localTracks.push(this.localVideoTrack)
      return new Promise(async(resolve, reject) => {
        // 退出会议前。如果有没有响应的会议邀请，撤回会议邀请
        if (!reason) {
          Object.keys(this.invites).forEach(inviteId => {
            this.sendMeetingInviteCancelMsg(inviteId)
            this.clearTimer(inviteId)
          })
        }
        // 退出时正在进行以下操作：
        if (this.isShareScreenVideo) { await this.screenVideoShareStop() } // 共享屏幕
        if (this.isRecording) { await this.screenRecordingFinish() } // 录制屏幕
        if (this.isPaintDrawing) { this.usePaint() }// 激光笔
        if (this.isLaserMoving) { this.useLaser() } // 实时画笔
        if (this.isFocusGuidance) { // 聚焦指导
          const result = this.remoteUserList.some(item => this.isExpert(item.userInfo))
          if (!result) { this.focusGuidanceLocalFinish(false) }
        }
        if (this.remoteTracks.length > 0) {
          await rtc.unsubscribe(this.room, this.remoteTracks, this.remoteTracks).catch(() => { reject() })
        }
        await this.unpublishAll({ audio: true, camera: true, screen: true }).catch(() => { reject() })
        await rtc.removeAllLocalTrack(localTracks).catch(() => { reject() })
        await im.quitChatRoom(this.chatRoomId).catch(() => { reject() })
        await rtc.leaveRTCRoom(this.room).catch(() => { reject() })
        this.userIds.length = 0
        this.remoteTracks.length = 0
        if (this.userIds.length === 0) { clearInterval(this.aliveMessageInterval) }
        this.room = null
        this.chatRoomId = null
        this.roomId = null
        await store.dispatch('user/changeIsBeingInvited', false)
        resolve()
      })
    },
    getMeetingCreatorInfo(isCreateRoom, meetingInfo) {
      if (isCreateRoom) {
        // 如果是创建，这set
        this.meetingInfo = meetingInfo
        rtc.setRoomAttribute(this.room, this.meetingInfo).catch(() => {})
      } else {
        // 如果是加入，这获取
        rtc.getRoomAttributes(this.room, ['meetingId', 'meetingCreator', 'meetingCreatorName', 'meetingCreatorAvatar']).then(data => {
          this.meetingInfo = data
        })
      }
    },
    joinChatRoom(isCreateRoom, chatRoomId) {
      return new Promise((resolve, reject) => {
        if (isCreateRoom) { // 聊天室：如果是isCreateRoom则进行joinChatRoom，反之joinExistChatRoom
          im.joinChatRoom(chatRoomId, 50).then(() => {
            this.aliveMessageInterval = setInterval(() => {
              const data = {
                msgType: dwCustomImMsg.DwMeetingChatRoomAlive.MsgType,
                content: '聊天室保活消息'
              }
              const obj = new dwCustomImMsg.DwMeetingChatRoomAlive(data)
              im.sendDwCustomChatRoomMsg(chatRoomId, obj)
            }, 30 * 60 * 1000)
            resolve()
          }).catch(error => {
            reject({ reason: '加入聊天室失败', error: error })
          })
        } else {
          im.joinExistChatRoom(chatRoomId, 50).then(() => {
            resolve()
          }).catch(error => {
            reject({ reason: '加入聊天室失败', error: error })
          })
        }
      })
    },
    getOnlineUserList() {
      return new Promise((resolve) => {
        // const listQuery = {
        //   roles: 'EXPERT,STAFF,ADMIN',
        //   offset: 0,
        //   count: 1000
        // }
        const listQuery = {
          offset: 0,
          count: 1000
        }
        getContactsApi(listQuery).then(resp => {
          this.onlineUserList = resp
          resolve(resp)
        })
      })
    },
    getMeetingUserInfo() {
      if (this.isRemoteShareScreen) {
        this.publicScreenVideo = {}
        const userId = this.shareScreenUserInfo.shareScreenImUserId
        const onLineIndex = this.onlineUserList.findIndex(item => item && item.imUserId === userId)
        this.publicScreenVideo = {
          userInfo: onLineIndex !== -1 ? this.onlineUserList[onLineIndex] : null
        }
      } else {
        this.remoteUserList = []
        this.remoteStaffList = []
        this.userIds.forEach(userId => {
          const onLineIndex = this.onlineUserList.findIndex(item => item && item.imUserId === userId)
          const videoTrackIndex = this.remoteVideoTracks.findIndex(track => track.getUserId() === userId)
          const audioTrackIndex = this.remoteAudioTracks.findIndex(track => track.getUserId() === userId)
          const deviceIndex = this.remoteDeviceStates.findIndex(item => item.imUserId === userId)
          const data = {
            imUserId: userId,
            isFocusedGuide: this.focusedGuidanceInfo && this.focusedGuidanceInfo.focusedUserId === userId,
            userInfo: onLineIndex !== -1 ? _.cloneDeep(this.onlineUserList[onLineIndex]) : null,
            videoTrack: videoTrackIndex !== -1 ? _.cloneDeep(this.remoteVideoTracks[videoTrackIndex]) : null,
            audioTrack: audioTrackIndex !== -1 ? _.cloneDeep(this.remoteAudioTracks[audioTrackIndex]) : null,
            deviceStatus: deviceIndex !== -1 ? _.cloneDeep(this.remoteDeviceStates[deviceIndex].deviceStatus) : null,
            remoteOperation: { isPaintDrawing: false, isLaserMoving: false, isClearPaint: false, isFocusOpen: false },
            videoProperty: deviceIndex !== -1 ? _.cloneDeep(this.remoteDeviceStates[deviceIndex].videoProperty) : null
          }
          if (data.userInfo || data.videoTrack || data.audioTrack) {
            this.remoteUserList.push(data)
          }
        })
        this.remoteUserList.forEach(item => {
          if (this.isStaff(item.userInfo) || this.notWebsocket(item.userInfo)) {
            this.remoteStaffList.push(item)
          }
        })
      }
    },
    getDeviceStatus(tracks) {
      tracks.forEach(track => {
        if (track.isVideoTrack()) {
          const imUserId = track.getUserId()
          const index = this.onlineUserList.findIndex(user => user.imUserId === imUserId)
          if (index !== -1) {
            this.sendDwQueryDeviceStatusMsg(imUserId)
          }
        }
      })
    },
    async onUserJoinFun(e) {
      const detail = e.detail
      this.isCalling = false
      this.userIds = this.room.getRemoteUserIds()
      this.acceptInviteFun(detail)
      await this.createUserJoinOrLeaveMessage(detail)
      await this.getOnlineUserList()
    },
    async onUserLeaveFun(e) {
      const detail = e.detail
      this.userIds = this.room.getRemoteUserIds()
      await this.createUserJoinOrLeaveMessage(detail, false) // 用户退出提示
      this.remoteVideoTracks.forEach((item, index) => {
        const userIndex = this.userIds.findIndex(user => user === item.getUserId())
        if (userIndex === -1) {
          this.remoteVideoTracks.splice(index, 1)
        }
      })
      detail.forEach(imUserId => {
        const deviceIndex = this.remoteDeviceStates.findIndex(item => item.imUserId === imUserId)
        if (deviceIndex !== -1) {
          this.remoteDeviceStates.splice(deviceIndex, 1)
        }
        const videoIndex = this.remoteVideoTracks.findIndex(item => item.getUserId() === imUserId)
        if (videoIndex !== -1) {
          this.remoteVideoTracks.splice(deviceIndex, 1)
        }
        const audioIndex = this.remoteVideoTracks.findIndex(item => item.getUserId() === imUserId)
        if (audioIndex !== -1) {
          this.remoteVideoTracks.splice(audioIndex, 1)
        }
        const userIndex = this.remoteUserList.findIndex(item => item.imUserId === imUserId)
        if (userIndex !== -1) {
          this.remoteUserList.splice(userIndex, 1)
        }
        const staffIndex = this.remoteStaffList.findIndex(item => item.imUserId === imUserId)
        if (staffIndex !== -1) {
          this.remoteStaffList.splice(staffIndex, 1)
        }
        this.remoteTracks.forEach((item, index) => {
          if (item.getUserId() === imUserId) {
            this.remoteTracks.splice(index, 1)
          }
        })
      })
      await this.getOnlineUserList()
      this.getMeetingUserInfo()
      // 如果退出的人是正在进行屏幕分享的人:保险2：监测到此人退会，远端共享屏幕为false
      if (detail && this.isRemoteShareScreen && detail.includes(this.shareScreenUserInfo.shareScreenImUserId)) {
        this.shareScreenUserInfo = null
        this.isRemoteShareScreen = false
        rtc.getRoomAttributes(this.room, this.shareScreenKeys).then(async() => {
          await rtc.deleteRoomAttributes(this.room, this.shareScreenKeys)
        })
        // 取消订阅公屏
        const index = this.remoteVideoTracks.findIndex(track => track.getTag() === 'PublicScreen')
        if (index !== -1) {
          await this.unsubscribe(this.room, [this.remoteVideoTracks[index]])
        }
      }
      if (detail && this.isRemoteRecording && detail.includes(this.recordingUserInfo.recordingImUserId)) {
        this.recordingUserInfo = null
        this.isRemoteRecording = false
      }
      // 退出的人是自己页面被点击放大的用户
      if (this.enLarge && this.enLargeVideoInfo && this.enLargeVideoInfo.userInfo) {
        if (detail.includes(this.enLargeVideoInfo.userInfo.imUserId)) {
          // 正在聚焦指导，退出聚焦指导
          if (this.isFocusGuidance) {
            this.isFocusGuidance = false
            rtc.deleteRoomAttributes(this.room, this.focusedGuidanceKeys).then(() => {
              this.focusedGuidanceInfo = null
              this.focusGuidanceLocalFinish(false)
            })
          }
          // 正在标注指导，退出标注指导
          if (this.isFreezeGuidance) {
            // todo 不知道后续需求要不要被指导用户退出时退出标注指导
            // this.$refs.freezeGuidanceRef.dialogVisible = false
            // this.freezeGuidanceFinish(false, this.enLargeVideoInfo.userInfo.imUserId, false)
          }
          // 取消放大，清除enLargeVideoInfo
          this.enLarge = false
          this.enLargeVideoInfo = {}
          await this.zoomVideo()
        }
        if (this.isLaserMoving) { this.useLaser() }
        if (this.isPaintDrawing) { this.usePaint() }
      }
      if (!this.isRemoteShareScreen && !this.enLarge) {
        this.remoteVideoTracks.forEach(item => {
          this.playTrack(item)
        })
      }
      if (this.remoteStaffList.length === 1) {
        // 只剩余一个现场，在没有任何人屏幕共享的情况下专家将该用户聚焦
        if (this.isExpert(this.userInfo) && !this.isShareScreenVideo && !this.isRemoteShareScreen) {
          await this.hasOneStaffToFocus()
        }
      }
      // todo 意外离会用户处理-持续更新
    },
    async createUserJoinOrLeaveMessage(imUserIds, join = true) {
      imUserIds.forEach(imUserId => {
        const index = this.onlineUserList.findIndex(item => item.imUserId === imUserId)
        if (index !== -1) {
          const userInfo = this.onlineUserList[index]
          const message = {
            msgType: 'DwMeetingJoinOrLeave',
            receivedTime: Date.now(),
            senderFullName: userInfo.fullName,
            senderUserName: userInfo.userName,
            content: join ? '加入会议' : '离开会议'
          }
          this.$message({ message: `${message.senderFullName}${message.content}`, type: join ? 'success' : 'info', center: true })
          this.messagesList.push(message)
        }
      })
    },
    meetingAcceptFun() {
      if (this.showOnlineUserBox && this.$refs.boxOnlineUserRef) {
        this.$refs.boxOnlineUserRef.refreshUserList()
      }
      this.isCalling = false
    },
    meetingRejectFun(e) {
      const detail = e.detail
      if (detail.reason === 'BUSY') {
        this.$message({ message: `${detail.senderFullName}忙线中`, type: 'error' })
      } else if (detail.reason === 'REJECT') {
        this.$message({ message: `${detail.senderFullName}拒绝了您的视频邀请`, type: 'error', center: true })
      }
      this.rejectInviteFun(detail.senderId)
      if (this.showOnlineUserBox && this.$refs.boxOnlineUserRef) {
        this.$refs.boxOnlineUserRef.refreshUserList()
      }
      if (this.isSingleMeeting) {
        this.exitRoom().then(() => {
          this.closeDialog()
          this.hangUp.play()
        }).catch(() => {
          putKickImUser(this.userImInfo.imUserId).then(() => {
            this.closeDialog()
          }).catch(() => {
            this.closeDialog()
          })
        })
      }
    },
    meetingNoResponseFun(fullName) {
      this.$message({ message: `${fullName}未响应您的邀请`, type: 'error', center: true })
      if (this.isSingleMeeting) {
        this.exitRoom().then(() => {
          this.closeDialog()
        }).catch(() => {
          putKickImUser(this.userImInfo.imUserId).then(() => {
            this.closeDialog()
          }).catch(() => {
            this.closeDialog()
          })
        })
      }
    },
    refreshUserAudioTrack() {
      this.refreshUserTracksStatus(true)
    },
    refreshUserVideoTrack(e) {
      const tracks = e.detail.tracks
      const index = this.remoteUserList.findIndex(item => item.imUserId === tracks.getUserId())
      if (index !== -1) {
        Object.keys(tracks).forEach(key => {
          this.remoteUserList[index][key] = tracks[key]
        })
      }
      this.refreshUserTracksStatus(true)
    },
    async refreshUserTracksStatus(refreshBoxAttendees = false) {
      this.getRemoteAudioTracks()
      this.getRemoteVideoTracks()
      await this.getOnlineUserList()
      this.getMeetingUserInfo()
      if (this.$refs.boxAttendeesRef && this.$refs.boxAttendeesRef.showBox && refreshBoxAttendees) {
        await this.$refs.boxAttendeesRef.refreshUserList()
      }
    },
    onStateReportFun(e) {
      const detail = e.detail
      this.localBitrate = detail.iceCandidatePair
      this.remoteReceivers = detail.receivers
    },
    getLocalVideoWh(value) {
      this.localVideoWH.width = value.width || meetingVideoProperty.normalLargeVideoWidth
      this.localVideoWH.height = value.height || meetingVideoProperty.normalLargeVideoHeight
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-dialog{
  position: fixed !important;
  top: 0;
  //bottom: auto !important;
  //top: 50%;
  //transform: translateY(-50%);
  ::v-deep .el-dialog{
    border-radius: 10px;
    margin-top: 0 !important;
    justify-content: center;
  }
  ::v-deep .el-dialog__header{
    //height: 56px;
    //line-height: 56px;
    padding: 0 20px;
    border-radius: 10px 10px 0 0;
  }
  ::v-deep .el-dialog__body{
    padding: 0 20px;
  }
  .meeting-body{
    width: 858px;
    .invite-list{
      position: absolute;
      z-index: 999;
      left: 25px;
      top: 110px;
      line-height: 40px;
      padding: 0 10px;
      border-radius: 5px;
      background: #2D2D2D81;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      .invite-hint{
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
          width: 15px;
          margin-right: 10px;
        }
        .content{
          flex: 1;
          min-width: 130px;
          margin-right: 10px;
        }
        .cancel{
          color: #f56c6c;
          height: 24px;
          line-height: 24px;
          background: #ffffff;
          border-radius: 5px;
          padding: 0 5px;
          cursor: pointer;
        }
        .cancel:hover{
          color: #ffffff;
          background: #f56c6c;
        }
      }
    }
    .meeting-video-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      min-height: calc(480px + 40px);
      .remote-video-list-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
    .meeting-small-video-wrapper{
      //height: 550px;
      overflow-y: scroll;
      .remote-video-list-wrapper{
        .remote-video-wrapper{
          margin: 5px;
        }
      }
    }
    .meeting-local-video-wrapper{
      min-height: 480px;
    }
  }

  ::v-deep .el-dialog__footer{
    padding: 0 10px 0;
    border-radius:  0 0 10px 10px;
  }
}
.fullscreen-meeting-dialog{
  ::v-deep .el-dialog{
    border-radius: 0;
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    min-width: 898px;
    //min-height: 700px;
  }
  ::v-deep .el-dialog__body{
    // 白边问题
    //flex: 1;
    //height: calc(100% - 200px);
    .meeting-body{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#freezeGuidance{
  z-index: 9;
}

.is-mobile-fullscreen-meeting-dialog{
  ::v-deep .el-dialog{
    min-width: auto !important;
    justify-content: flex-start;
  }

  ::v-deep .el-dialog__header{
    padding: 0;
    border-radius: 10px 10px 0 0;
  }

  ::v-deep .el-dialog__body{
    padding: 0;

    .meeting-body{
      justify-content: flex-start !important;
      .meeting-video-wrapper{
        min-height: auto;
        width: 100%;
      }
      .meeting-small-video-wrapper{
        height: auto;
      }
      .meeting-local-video-wrapper{
        min-height: auto;
      }
    }
  }

  ::v-deep .el-dialog__footer{
    padding: 0;
    border-radius:  0 0 10px 10px;

    .footer-container{
      .local-hint{
        margin-left: 0;
      }
      justify-content: flex-start;
    }
  }
}

@media screen and (orientation: landscape) {
  .is-mobile-in-meeting {
    opacity: 0;
  }
}
</style>
