var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value !== undefined
    ? _c(
        "span",
        [
          _vm.value
            ? _c("el-tag", { attrs: { type: "success" } }, [_vm._v("是")])
            : _c("el-tag", { attrs: { type: "danger" } }, [_vm._v("否")]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }