<template>
  <div>
    <ht-table ref="list" v-loading="isLoading" :data="list">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="i in labelToProps" :key="`${i.prop}-${i.secondProp}`" :label="i.label" :prop="i.prop">
        <template v-slot="{row}">
          <span v-if="i.format === 'changeDate'">
            {{ row[i.prop] | formatTime }}
          </span>
          <span v-else>
            <span v-if="i.secondProp">{{ (row[i.prop] && row[i.prop][i.secondProp]) ? row[i.prop][i.secondProp] : '-' }}</span>
            <span v-else>{{ row[i.prop] ? row[i.prop] : '-' }}</span>
          </span>
        </template>
      </ht-table-column>
      <!--      <ht-table-column-operation>-->
      <!--        <template v-slot="{row}">-->
      <!--          <ht-button type="text" @click="gotoView(row.code, row.id)">详情</ht-button>-->
      <!--          <ht-popover-button-->
      <!--            type="text"-->
      <!--            :danger-text="true"-->
      <!--            confirm-title="请确认是否要删除该条数据？"-->
      <!--          >删除-->
      <!--          </ht-popover-button>-->
      <!--        </template>-->
      <!--      </ht-table-column-operation>-->
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer/index'
import { getEquipmentOfMaintainTasksApi } from '@/api/devicesMgr/deviceMgr'

export default {
  name: 'BugReports',
  extends: HtListContainer,
  props: {
    deviceCode: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      labelToProps: [
        {
          label: '维修单号',
          prop: 'code'
        },
        {
          label: '专业',
          prop: 'major'
        },
        {
          label: '设备编号',
          prop: 'equipment',
          secondProp: 'code'
        },
        {
          label: '设备名称',
          prop: 'equipment',
          secondProp: 'name'
        },
        {
          label: '维修说明',
          prop: 'maintDesc'
        },
        {
          label: '维修负责人',
          prop: 'leaderAccount',
          secondProp: 'personName'
        },
        {
          label: '维修单状态',
          format: 'status'
        },
        {
          label: '内部/外部',
          prop: 'entrustType'
        },
        {
          label: '部门',
          prop: 'department',
          secondProp: 'depName'
        },
        {
          label: '服务单位',
          prop: 'leadingAccount',
          secondProp: 'personName'
        },
        {
          label: '维修人',
          prop: 'processMode'
        },
        {
          label: '计划开始',
          prop: 'scheduleStartDate',
          format: 'changeDate'
        },
        {
          label: '计划结束',
          prop: 'scheduleEndDate',
          format: 'changeDate'
        },
        {
          label: '实际开始',
          prop: 'maintTaskStartDate'
        },
        {
          label: '实际结束',
          prop: 'maintTaskEndDate'
        },
        {
          label: '验收人',
          prop: 'bugSource'
        },
        {
          label: '检修计划',
          prop: 'maintPlan'
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getEquipmentOfMaintainTasksApi(this.deviceCode).then((resp) => {
        this.afterGetList(resp)
      })
    }
  }

}
</script>

<style scoped>

</style>
