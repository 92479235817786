<template>
  <div class="patrol-statistics-container">
    <div class="single-statistical-card-container">
      <single-statistical-card v-for="(item, index) in patrolTaskWorkOrderStatistics" :key="index" :title="item.name" :count="item.value" />
    </div>
    <div class="statistics-chart-container">
      <div class="statistics-query-container">
        <div class="statistics-query-dimension-container">
          <div
            v-for="(item,index) in timeDimension"
            :key="index"
            class="statistics-query-dimension"
            :class="{'is-selected-dimension': item.name === isPatrolSelectedDimension}"
            @click="selectQueryPatrolTimeDimension(item.name)"
          >{{ item.value }}</div>
        </div>
        <el-date-picker
          v-model="patrolDateRange"
          style="width: 350px"
          :type="getPatrolTimeDimension.rangeType"
          range-separator="至"
          :start-placeholder="getPatrolTimeDimension.startLabel"
          :end-placeholder="getPatrolTimeDimension.endLabel"
          :clearable="false"
          :picker-options="pickerOptions"
          @blur="resetMinDate"
          @change="patrolDateRangeChange"
        />
      </div>
      <el-row :gutter="24">
        <el-col :span="12">
          <dw-statistic-chart :is-loading="isPatrolChartLoading" title="巡检完成情况统计" :box-style="{ 'margin-right': '20px', width: '100%' }" :title-style="{width: '220px'}">
            <template v-slot:chart>
              <patrol-finish-chart ref="patrolFinishChart" first-name="已完成工单数" second-name="未完成工单数" x-unit="数量" y-unit="时间" height="300px" />
            </template>
          </dw-statistic-chart>
        </el-col>
        <el-col :span="12">
          <dw-statistic-chart :is-loading="isPatrolChartLoading" title="故障处理统计" :box-style="{ 'margin-right': '20px', width: '100%' }" :title-style="{width: '220px'}">
            <template v-slot:chart>
              <patrol-fault-handle-chart ref="patrolFaultHandleChart" first-name="整改后合格故障数" second-name="不合格故障数" x-unit="时间" y-unit="数量" height="300px" :color1="variables.primaryColor" />
            </template>
          </dw-statistic-chart>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SingleStatisticalCard from '@/views/newDashboard/components/card/singleStatisticalCard'
import PatrolFinishChart from '@/views/newDashboard/components/chart/patrolFinishChart'
import PatrolFaultHandleChart from '@/views/newDashboard/components/chart/patrolFaultHandleChart'
import { parseTime } from '@/utils'
import { getTaskWorkOrderBarStatistics, getTaskWorkOrderGridStatistics } from '@/api/newDashboard/dashboard'
import variables from '@/styles/variables.scss'

export default {
  name: 'PatrolStatistics',
  components: {
    SingleStatisticalCard,
    PatrolFinishChart,
    PatrolFaultHandleChart
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          if (this.minDate !== null && this.maxDate === null) {
            if (this.isPatrolSelectedDimension === 'day') {
              const minDate = this.minDate.getDate()
              const lastDate = new Date(this.minDate).setDate(minDate - 30)
              const nextDate = new Date(this.minDate).setDate(minDate + 30)
              // 只能选 minDate 前后30天的范围 + 未来日期禁止选择
              return time.valueOf() < lastDate.valueOf() || time.valueOf() > nextDate.valueOf() || time.getTime() > Date.now()
            } else {
              const minMonth = this.minDate.getMonth()
              const lastYear = new Date(this.minDate).setMonth(minMonth - 11)
              const nextYear = new Date(this.minDate).setMonth(minMonth + 11)
              // 只能选 minDate 前后一年的范围  + 未来日期禁止选择
              return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf() || time.getTime() > Date.now()
            }
          }
          // 未来日期禁止选择
          return time.getTime() > Date.now()
        },
        onPick: ({ minDate, maxDate }) => {
          this.minDate = minDate
          this.maxDate = maxDate
        }
      },
      minDate: null,
      maxDate: null,
      timeDimension: [
        { name: 'month', value: '按月统计' },
        { name: 'day', value: '按日统计' }
      ],
      timeRange: [
        { name: 'month', rangeType: 'monthrange', startLabel: '开始月份', endLabel: '结束月份' },
        { name: 'day', rangeType: 'daterange', startLabel: '开始日期', endLabel: '结束日期' }
      ],
      patrolTaskWorkOrderStatistics: [
        { name: '今日待执行', value: 0 },
        { name: '今日已执行', value: 0 },
        { name: '待执行工单总数', value: 0 },
        { name: '累计完成工单数', value: 0 },
        { name: '累计异常工单数', value: 0 }
      ],
      patrolDateRange: null,
      patrolListQuery: null,
      isPatrolSelectedDimension: 'day',
      isPatrolChartLoading: false
    }
  },
  computed: {
    getPatrolTimeDimension() {
      const index = this.timeRange.findIndex(item => item.name === this.isPatrolSelectedDimension)
      if (index !== -1) {
        return this.timeRange[index]
      } else {
        return this.timeRange[1]
      }
    },
    variables() {
      return variables
    }
  },
  created() {
    this.initDate()
    this.getPatrolTaskWorkOrderCount()
    this.getPatrolStatisticCount()
  },
  methods: {
    initDate(isDate = true) {
      // 创建当前日期对象
      const today = new Date()
      let range = []

      if (isDate) {
        // 获取本周
        const startOfDay = new Date(today)
        const endOfDay = new Date(today)
        startOfDay.setTime(startOfDay.getTime() - 3600 * 1000 * 24 * 6) // 六天前

        // 格式化日期范围
        const startDate = startOfDay.toISOString().split('T')[0]
        const endDate = endOfDay.toISOString().split('T')[0]
        range = [startDate, endDate]
      } else {
        // 获取最近六个月的日期范围
        const year = today.getFullYear()
        const month = today.getMonth() // 获取当前月份，注意月份是从0开始的
        const startMonth = month - 5 // 从当前月份向前推六个月
        const start = new Date(year, startMonth, 1) // 设置为当月的第一天
        const end = new Date(year, month + 1, 0) // 设置为下月的第一天，确保包含六个月份

        // 格式化日期范围
        range = [parseTime(start, '{y}-{m}'), parseTime(end, '{y}-{m}')]
      }
      this.patrolListQuery = { startAt: range[0], endAt: range[1] }
      this.patrolDateRange = range
    },
    resetMinDate() {
      if (this.patrolDateRange && this.patrolDateRange.length && this.minDate && !this.maxDate) {
        this.minDate = new Date(this.patrolDateRange[0])
      } else {
        this.minDate = null
      }
    },
    selectQueryPatrolTimeDimension(value) {
      this.isPatrolSelectedDimension = value
      this.initDate(this.isPatrolSelectedDimension === 'day')
      this.getPatrolStatisticCount()
    },
    patrolDateRangeChange(value) {
      const range = this.dateRangeChange(value, 'isPatrolSelectedDimension')
      this.patrolListQuery = { startAt: range[0], endAt: range[1] }
      this.patrolDateRange = [range[0], range[1]]
      this.getPatrolStatisticCount()
    },
    dateRangeChange(value, type) {
      let range = []
      if (this[type] === 'day') {
        range = [parseTime(value[0], '{y}-{m}-{d}'), parseTime(value[1], '{y}-{m}-{d}')]
      } else {
        range = [parseTime(value[0], '{y}-{m}'), parseTime(value[1], '{y}-{m}')]
      }
      return range
    },
    getPatrolTaskWorkOrderCount() {
      const listQuery = {
        type: 'PATROL'
      }
      getTaskWorkOrderGridStatistics(listQuery).then(resp => {
        this.patrolTaskWorkOrderStatistics = resp
      })
    },
    getPatrolStatisticCount() {
      const listQuery = this.patrolListQuery
      listQuery.timeDimension = this.isPatrolSelectedDimension
      listQuery.type = 'PATROL'
      this.isPatrolChartLoading = true
      getTaskWorkOrderBarStatistics(listQuery).then(resp => {
        this.isPatrolChartLoading = false
        this.$nextTick(() => {
          this.$refs.patrolFaultHandleChart.getData(resp.time, resp.currentFinishedRectificationCounts, resp.currentFinishedAbnormalCounts)
          this.$refs.patrolFinishChart.getData(resp.time, resp.currentFinishedCounts, resp.currentUnfinishedCounts)
        })
      }).catch(() => {
        this.isPatrolChartLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.patrol-statistics-container{
  .single-statistical-card-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
  }
  .statistics-chart-container{
    .statistics-query-container{
      display: flex;
      align-items: center;
      justify-content: right;
      .statistics-query-dimension-container{
        display: flex;
        align-items: center;
        justify-content: center;
        .statistics-query-dimension{
          width: 100px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #ffffff;
          color: #606266;
          border: 1px solid #dcdfe6;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
          font-size: 14px;
        }
        .statistics-query-dimension:hover{
          color: $primaryColor;
          background: $primaryColorLight;
          border-color: $primaryColor;
        }
        .statistics-query-dimension:focus{
          color: $primaryColor;
          background: $primaryColorLight;
          border-color: $primaryColor;
        }
        .is-selected-dimension{
          color: #fff !important;
          background-color: $primaryColor !important;
          border-color: $primaryColor !important;
        }
      }
    }
  }
}
</style>
