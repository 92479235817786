<template>
  <div>
    <el-form ref="materialForm" :model="materialForm" :rules="materialFormRules" label-width="100px" style="margin-right: 20px">
      <el-form-item label="品番：" prop="code">
        <el-input v-model="materialForm.code" placeholder="请输入品番" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item label="品名：" prop="name">
        <el-input v-model="materialForm.name" placeholder="请输入品名" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item label="供应商：" prop="supplierName">
        <el-input v-model="materialForm.supplierName" placeholder="请输入供应商" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item label="车型：" prop="vehicleType">
        <el-input v-model="materialForm.vehicleType" placeholder="请输入车型" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item label="图番：" prop="illustrationNumber">
        <el-input v-model="materialForm.illustrationNumber" placeholder="请输入图番" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item label="部品图：" prop="illustrationImg">
        <template>
          <dw-image v-if="materialForm.illustrationImg" :url="materialForm.illustrationImg" @delete-img="onDeleteImage" />
          <ht-upload-button v-else :file-type="['img']" key-prefix="workflow" select-img @on-file-uploaded="onImageUploaded" />
        </template>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <ht-button icon="el-icon-close" @click="cancelSubmit">取消</ht-button>
      <ht-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="submitForm('materialForm')">确定</ht-button>
    </div>
  </div>
</template>

<script>
import { postNskMaterials, putNskMaterial } from '@/api/incomingInspectionMgr/nskMaterialMgr'
import { validateNumOrLetterOrSymbol } from '@/utils/validate'

export default {
  name: 'NskMaterialForm',
  props: {
    isEdit: { type: Boolean, default: false }
  },
  data() {
    return {
      materialForm: {
        code: null,
        name: null,
        supplierName: null,
        vehicleType: null,
        illustrationNumber: null,
        illustrationImg: null
      },
      materialFormRules: {
        code: [
          { required: true, message: '请输入品番', trigger: 'blur' },
          { message: '请输入数字、字母、或符号（./-_:）', trigger: 'blur', validator: validateNumOrLetterOrSymbol }
        ],
        name: [{ required: true, message: '请输入品名', trigger: 'blur' }],
        illustrationNumber: [
          { message: '请输入数字、字母、或符号（./-_:）', trigger: 'blur', validator: validateNumOrLetterOrSymbol }
        ]
      },
      isSubmitting: false,
      materialId: undefined
    }
  },
  methods: {
    getMaterialInfo(materialInfo) {
      this.materialId = materialInfo.id
      Object.keys(this.materialForm).forEach(key => (this.materialForm[key] = materialInfo[key]))
    },
    onImageUploaded(context, url) {
      this.materialForm.illustrationImg = url
    },
    onDeleteImage() {
      this.materialForm.illustrationImg = ''
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    submitForm(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.isSubmitting = true
          if (this.isEdit) {
            putNskMaterial(this.materialId, this.materialForm).then(() => {
              this.$message({ message: `修改成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          } else {
            postNskMaterials(this.materialForm).then(() => {
              this.$message({ message: `新建成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
