var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "workflow-steps-container",
          style: { height: _vm.windowHeight + "px" },
        },
        [
          _c(
            "div",
            { staticClass: "workflow-title", attrs: { id: "workflowTitle" } },
            [
              _vm._v(
                _vm._s(
                  "工单名称：" + ((_vm.workFlow && _vm.workFlow.name) || "-")
                )
              ),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.steps, function (item, index) {
            return [
              _c(
                "transition",
                { key: index, attrs: { name: _vm.transitionName } },
                [
                  _vm.currentStepIndex === index
                    ? _c(item.stepUi, {
                        key: index,
                        tag: "component",
                        staticClass: "component",
                        attrs: {
                          count: _vm.steps.length,
                          "current-index": _vm.currentStepIndex,
                          "is-first-step": index === 0,
                          "is-last-step": index === _vm.steps.length - 1,
                          properties: item.properties,
                          results: item.results,
                          height: _vm.getStepHeight,
                        },
                        on: {
                          "to-prev": _vm.toPrev,
                          "to-next": _vm.toNext,
                          "to-native-scan-code": _vm.toNativeScanCode,
                          "to-remote-guidance": _vm.toRemoteGuidance,
                          "to-ai-qa-assistant": _vm.toAiQaAssistant,
                          "jump-step": _vm.jumpStep,
                          "save-record": _vm.saveRecord,
                          "submit-record": _vm.submitRecord,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }