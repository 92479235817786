var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom-section" }, [
    _c("div", { staticClass: "bottom-container" }, [
      _vm.count > 1
        ? _c(
            "div",
            { staticClass: "bottom-left" },
            [
              _vm.currentIndex > 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "prev-button square-circle-button",
                      attrs: { type: "text", disabled: _vm.buttonDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("toPrev")
                        },
                      },
                    },
                    [_vm._v("上一项")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentIndex + 1 < _vm.count
                ? _c(
                    "el-button",
                    {
                      staticClass: "next-button square-circle-button",
                      attrs: { type: "text", disabled: _vm.buttonDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("toNext")
                        },
                      },
                    },
                    [_vm._v("下一项")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLastStep
        ? _c(
            "div",
            {
              staticClass: "bottom-right",
              style: { marginLeft: _vm.count > 1 ? "10px" : "0" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "submit-button square-circle-button",
                  attrs: { type: "text", disabled: _vm.buttonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("submitRecord")
                    },
                  },
                },
                [_vm._v("提交工单")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }