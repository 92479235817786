import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'
import { getObject } from '@/store/localStorage'
import router from '@/router'

const LOCAL_VUE_APP_BASE_API = localStorage.getItem('LOCAL_VUE_APP_BASE_API')

const baseUrl = LOCAL_VUE_APP_BASE_API || process.env.VUE_APP_BASE_API

const TIMEOUT_MS = 60000
// create an axios instance
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: TIMEOUT_MS // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation

      // 使用iframe嵌套，被嵌套网页不能Cookies.set到对应域名下，accessToken改为setObject,取用accessToken也使用getObject
      const currentRouter = router.currentRoute
      const isIframe = currentRouter.path.includes('newRemoteGuidance/externalCall')
      config.headers['Authorization'] = `JWT ${isIframe ? getObject('accessToken') : getToken()}`
      // config.headers['Authorization'] = `JWT ${getToken() ? getToken() : getObject('accessToken')}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.status === 0) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.errCode === 401) {
        // to re-login
        MessageBox.confirm(res.errMsg, '信息', { confirmButtonText: '确定', showCancelButton: false, type: 'warning' })
          .then(() => {
            document.dispatchEvent(new CustomEvent(EventsEnum.tokenInvalidEvent, { detail: { tokenInvalid: true }}))
            store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          })
      } else {
        // invite_record 会议埋点接口，不暴露错误信息
        if (!response.config.url.includes('invite_record')) {
          Message({ message: res.errMsg || 'Error', type: 'error', duration: 2000 })
        }
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res.data
    }
  },
  error => {
    console.log(error) // for debug
    if (error.code === 'ECONNABORTED') {
      Message({ message: `响应时间超过 ${TIMEOUT_MS / 1000} 秒`, type: 'error', duration: 3 * 1000 })
    } else {
      Message({ message: error.message, type: 'error', duration: 3 * 1000 })
    }
    return Promise.reject(error)
  }
)

export default service
