<template>
  <div
    class="local-video-wrapper"
    :class="{
      'focused-container': isFocusedGuide,
      'fullScreen-mobile-local-video-wrapper': isFullscreen && isMobile
    }"
  >
    <player-view
      :id="id"
      :video-size="getVideoSize"
      :no-login-type="true"
      :is-expert="isExpert"
      :is-share-video="isShareVideo"
      :video-track="videoTrack"
      :remote-paint-info="remotePaintInfo"
      :remote-laser-info="remoteLaserInfo"
      :show-remote-paint-canvas="isRemotePaint"
      :show-remote-laser-canvas="isRemoteLaser"
      :is-fullscreen="isFullscreen"
      :is-mobile="isMobile"
      @local-video-w-h="getLocalVideoWH"
    >
      <div slot="bottomLeft" class="bottom-left">
        <img class="head" :src="Head" alt="">
        <span v-if="isSinoma || stringLongOverTen(userInfo.account.userName)" class="name">{{ `${userInfo.fullName}` }}</span>
        <span v-else class="name">{{ `${userInfo.fullName}（${userInfo.account.userName}）` }}</span>
      </div>
    </player-view>
  </div>
</template>

<script>
import PlayerView from './playerView'
import Head from '@/assets/image/newRemoteGuidance/video/head.png'
import store from '@/store'
import { stringLongOverTen } from '@/utils'

export default {
  name: 'SmallVideo',
  components: { PlayerView },
  props: {
    id: { type: String, default: null },
    videoSize: { type: String, default: 'small' }, // small, large
    userInfo: { type: Object, default: () => {} },
    isExpert: { type: Boolean, default: true },
    isShareVideo: { type: Boolean, default: true },
    isLargeVideo: { type: Boolean, default: true },
    isFullscreen: { type: Boolean, default: false },
    videoTrack: { type: Object, default: () => {} },
    audioTrack: { type: Object, default: () => {} },
    isRemotePaint: { type: Boolean, default: false },
    remotePaintInfo: { type: Object, default: () => {} },
    isRemoteLaser: { type: Boolean, default: false },
    remoteLaserInfo: { type: Object, default: () => {} },
    isFocusedGuide: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      Head,
      isSinoma: undefined
    }
  },
  computed: {
    getVideoSize() {
      if (this.isFullscreen) {
        if (this.isLargeVideo) {
          return 'full-large'
        } else {
          return 'full-small'
        }
      } else {
        if (this.isLargeVideo) {
          return 'normal-large'
        } else {
          return 'normal-small'
        }
      }
    }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
  },
  methods: {
    stringLongOverTen,
    getLocalVideoWH(value) {
      this.$emit('local-video-w-h', value)
    }
  }
}
</script>

<style scoped lang="scss">
.local-video-wrapper{
  width: 100%;
  .bottom-left{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.6);
    .head{
      margin-right: 10px;
    }
    .name{
      color: #2E5AFF;
    }
  }
}
.focused-container{
  border: 2px solid #f56c6c;
}

.fullScreen-mobile-local-video-wrapper{

}
</style>
