export const ProjectLeaseStatusEnum = {
  UNDER_LEASED: '租赁中',
  ENDED: '已结束',
  NO_LEASED: '未租赁'
}

export const ProjectLeaseStatusStyleEnum = {
  UNDER_LEASED: 'success',
  ENDED: 'primary',
  NO_LEASED: 'info'
}

export const ProjectStatusEnum = [
  { name: true, value: '已结束', style: 'info' },
  { name: false, value: '未结束', style: 'primary' }
]

