var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-container",
      class: [
        _vm.videoSize + "-video-container",
        (_vm.isRemoteVideo ? "remote" : "local") +
          "-" +
          _vm.videoSize +
          "-video-container",
        {
          "android-video-container": _vm.isAndroid,
          "remote-video-container": _vm.isRemoteVideo,
          "local-video-container": !_vm.isRemoteVideo,
          "fullscreen-mobile-video-container": _vm.isFullscreen && _vm.isMobile,
        },
      ],
      attrs: { id: _vm.id + "_video_container" },
    },
    [
      _vm.showTopTools && _vm.id !== "publicScreenVideo"
        ? _c(
            "div",
            { staticClass: "top-tools tools" },
            [_vm._t("topLeft"), _vm._v(" "), _vm._t("topRight")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "middle-video-container" }, [
        _vm.showImg
          ? _c("img", {
              staticClass: "placeholder-img",
              attrs: { src: _vm.isExpert ? _vm.Expert : _vm.Staff, alt: "" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("video", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showVideo,
              expression: "showVideo",
            },
          ],
          ref: _vm.id + "Ref",
          staticClass: "video",
          class: [
            {
              "is-contain":
                _vm.videoTrack && _vm.videoTrack._tag === "PublicScreen",
            },
          ],
          staticStyle: { "object-fit": "cover" },
          attrs: { id: _vm.id },
        }),
        _vm._v(" "),
        (_vm.isRemoteVideo && !_vm.showImg && !_vm.bitRate && !_vm.isScreen) ||
        _vm.isSubscribing
          ? _c("div", { staticClass: "no-bit-rate" }, [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s("视频加载中")),
              ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: true,
                    expression: "true",
                  },
                ],
                staticClass: "loading",
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showFocusCanvas && _vm.isAndroid
          ? _c("canvas", {
              staticClass: "focus-canvas",
              class: {
                "focus-canvas-focused":
                  _vm.videoFocused &&
                  !_vm.showPaintCanvas &&
                  !_vm.showLaserCanvas,
              },
              attrs: { id: "focusCanvas", width: "50", height: "50" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showPaintCanvas ||
        _vm.showLaserCanvas ||
        _vm.showRemotePaintCanvas ||
        _vm.showRemoteLaserCanvas
          ? _c("canvas", {
              staticClass: "white-board-canvas",
              style: { top: _vm.isRemoteVideo ? "40px" : 0 },
              attrs: {
                id: "whiteBoardCanvas",
                width: _vm.getVideoWidth,
                height: _vm.getVideoHeight,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.noLoginType
          ? _c(
              "div",
              { staticClass: "bottom-tools no-login tools" },
              [_vm._t("bottomLeft")],
              2
            )
          : _c(
              "div",
              { staticClass: "bottom-tools login tools" },
              [_vm._t("bottomLeft"), _vm._v(" "), _vm._t("bottomRight")],
              2
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }