import request from '@/utils/request'

export const accident_records = 'security/accident_records/'

export function getAccidentRecordsApi(listQuery) {
  return request({ url: accident_records, method: 'GET', params: listQuery })
}

export function getAccidentRecordDetailApi(recordId) {
  return request({ url: `${accident_records}${recordId}`, method: 'GET' })
}

export function updateAccidentRecordDetail(recordId, data) {
  return request({ url: `${accident_records}${recordId}`, method: 'PUT', data: data })
}

export function deleteAccidentRecordDetail(recordId) {
  return request({ url: `${accident_records}${recordId}`, method: 'DELETE' })
}

export function getAccidentRecordItemsApi(recordId) {
  return request({ url: `${accident_records}${recordId}/items/`, method: 'GET' })
}

export function updateAccidentRecordItems(recordId, data) {
  return request({ url: `${accident_records}${recordId}/items/`, method: 'PUT', data: data })
}
