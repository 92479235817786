<template>
  <div>
    <el-form ref="transshipmentForm" :model="transshipmentForm" :rules="transshipmentFormRules" label-width="100px">
      <el-form-item label="转移工程：" prop="newConstructionProjectId">
        <el-select
          v-model="transshipmentForm.newConstructionProjectId"
          filterable
          :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'"
          style="width: 100%"
          remote
          :remote-method="getProjects"
        >
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="equipmentInfo && equipmentInfo.constructionEquipmentIsWhole" label="执行代码：" prop="executeCode">
        <el-input v-model="transshipmentForm.executeCode" :maxlength="5" show-word-limit placeholder="请输入执行代码" />
      </el-form-item>
      <el-form-item label="转移数量：" prop="quantity" class="quantity">
        <!--        <el-input v-model="transshipmentForm.projectEquipmentQuantity" disabled />-->
        <el-input-number
          v-model="transshipmentForm.quantity"
          :disabled="equipmentInfo && equipmentInfo.constructionEquipmentIsWhole"
          :min="1"
          placeholder="转移数量"
          :precision="0"
        />
        <span class="info">可转移数量：{{ transshipmentForm.projectEquipmentQuantity }}</span>
      </el-form-item>
      <el-form-item label="租用合同：" prop="attachmentData">
        <dw-file v-if="transshipmentForm.attachmentData" :url="transshipmentForm.attachmentData" show-del justify-content="left" @delete-file="transshipmentForm.attachmentData = null" />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传租用合同" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('transshipmentForm')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { getProjects, putProjectTransshipment } from '@/api/constructionProjectMgr/projectMgr'

export default {
  name: 'TransshipmentForm',
  data() {
    return {
      transshipmentForm: {
        newConstructionProjectId: null,
        constructionEquipmentId: null,
        executeCode: null,
        quantity: null,
        attachmentData: null
      },
      transshipmentFormRules: {
        newConstructionProjectId: [{ required: true, message: '请选择转移工程', trigger: 'change' }],
        executeCode: [{ required: true, message: '请输入执行代码', trigger: 'blur' }],
        quantity: [
          { required: true, message: '转移数量不能为0', trigger: 'blur' },
          { required: true, validator: this.validateQuantity, trigger: 'change' }
        ]
      },
      equipmentInfo: null,
      projectId: null,
      projectList: [],
      isSubmitting: false
    }
  },
  created() {
    this.getProjects()
  },
  methods: {
    validateQuantity(rule, value, callback) {
      if (this.transshipmentForm.quantity > this.transshipmentForm.projectEquipmentQuantity) {
        callback(new Error('转移数量不能大于可转移数量'))
      } else {
        callback()
      }
    },
    getInfo(equipmentInfo, projectId) {
      this.equipmentInfo = equipmentInfo
      this.projectId = projectId
      this.transshipmentForm.constructionEquipmentId = equipmentInfo.constructionEquipmentId
      this.transshipmentForm.projectEquipmentQuantity = equipmentInfo.projectEquipmentQuantity || 0
      this.transshipmentForm.quantity = equipmentInfo.projectEquipmentQuantity || 0
    },
    getProjects(value) {
      const query = {
        offset: 0,
        count: 20,
        keyword: value
      }
      getProjects(query).then(resp => {
        this.projectList = resp.filter(item => item.id !== this.projectId)
        this.isProjectLoading = false
      })
    },
    onFileUploaded(context, url) {
      this.transshipmentForm.attachmentData = url
      if (this.transshipmentForm.attachmentData) {
        this.$refs.transshipmentForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      const keys = ['newConstructionProjectId', 'constructionEquipmentId', 'executeCode', 'attachmentData', 'quantity']
      const transshipmentForm = {}
      keys.forEach(item => {
        if (item === 'attachmentData') {
          transshipmentForm[item] = this.transshipmentForm[item] ? [this.transshipmentForm[item]] : []
        } else {
          transshipmentForm[item] = this.transshipmentForm[item]
        }
      })
      this.$refs.transshipmentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          putProjectTransshipment(this.projectId, transshipmentForm).then(() => {
            this.$emit('confirm-submit')
            this.isSubmitting = false
          }).catch(() => { this.isSubmitting = false })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.quantity{
  ::v-deep .el-form-item__content{
    display: flex;
    flex-direction: row;
    .el-input-number{
      flex: 1;
      margin-right: 10px;
    }
    .info{
      color: #f56c6c;
    }
  }
}
</style>
