import request from '@/utils/request'

const project_check = 'project_reports/'

export function getProjectCheckApi(listQuery) {
  return request({ url: project_check, method: 'GET', params: listQuery })
}

export function getProjectCheckDetailApi(recordId) {
  return request({ url: `${project_check}${recordId}`, method: 'GET' })
}

export function updateProjectCheckDetailApi(recordId, data) {
  return request({ url: `${project_check}${recordId}`, method: 'PUT', data: data })
}

export function deleteProjectCheckDetail(recordId) {
  return request({ url: `${project_check}${recordId}`, method: 'DELETE' })
}

export function getProjectCheckItemsApi(recordId) {
  return request({ url: `${project_check}${recordId}/check_items/`, method: 'GET' })
}

export function updateProjectCheckItems(recordId, itemId, data) {
  return request({ url: `${project_check}${recordId}/check_items/${itemId}`, method: 'PUT', data: data })
}

export function updateProjectCheckItemsItems(recordId, data) {
  return request({ url: `${project_check}${recordId}/items/`, method: 'PUT', data: data })
}

// 生成图文报告
export function projectReportsDoc(recordId, data) {
  return request({ url: `${project_check}${recordId}/doc_reports/`, method: 'POST', data: data })
}

// 如果以前有生成过图文报告 获取记录
export function getProjectReportsDocRecordsApi(recordId, reportsRecordsListQuery) {
  return request({ url: `${project_check}${recordId}/doc_reports/`, method: 'GET', params: reportsRecordsListQuery })
}
