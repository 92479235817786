import request from '@/utils/request'

const nsk_materials = 'nsk/nsk_materials/'
const nsk_material = 'nsk/nsk_material/'

// 获取部品列表
export function getNskMaterials(listQuery) {
  return request({ url: `${nsk_materials}`, method: 'GET', params: listQuery })
}

// 新增部品
export function postNskMaterials(data) {
  return request({ url: `${nsk_materials}`, method: 'POST', data: data })
}

// 修改部品
export function putNskMaterial(materialId, data) {
  return request({ url: `${nsk_material}${materialId}`, method: 'PUT', data: data })
}

// 删除部品
export function deleteNskMaterial(materialId) {
  return request({ url: `${nsk_material}${materialId}`, method: 'DELETE' })
}

// 获取部品详情
export function getNskMaterial(materialId) {
  return request({ url: `${nsk_material}${materialId}`, method: 'GET' })
}
