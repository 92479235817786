var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "stock-detail-container" },
        [
          _c("dw-title", {
            attrs: {
              title: "库存详情（" + _vm.warehouseName + "）",
              "is-mobile": "",
            },
          }),
          _vm._v(" "),
          _vm._l(_vm.equipmentList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "equipment-item",
                on: {
                  click: function ($event) {
                    return _vm.getEquipmentLogisticsTraces(
                      item.constructionEquipmentId
                    )
                  },
                },
              },
              [
                _c("div", { staticClass: "item top-item" }, [
                  _vm._v(
                    "设备：" +
                      _vm._s(item.constructionEquipmentName) +
                      " " +
                      _vm._s(
                        item.constructionEquipmentCode
                          ? "(" + item.constructionEquipmentCode + ")"
                          : ""
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _vm._v(
                    "规格型号：" +
                      _vm._s(item.constructionEquipmentSpecModel || "-")
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item bottom-item" }, [
                  _c("span", { staticClass: "quantity" }, [
                    _vm._v("仓库库存数：" + _vm._s(item.quantity)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "button" }, [_vm._v("物流跟踪")]),
                ]),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "mobile-drawer",
          attrs: {
            title: "物流跟踪",
            visible: _vm.showLogisticsTraces,
            direction: "btt",
            "destroy-on-close": true,
            "before-close": _vm.closeLogisticsTrace,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLogisticsTraces = $event
            },
          },
        },
        [
          _c("logistics-trace", {
            staticClass: "logistics-trace-container",
            attrs: {
              "is-loading": _vm.isLogisticsTracesLoading,
              "logistics-traces": _vm.logisticsTraces,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }