<template>
  <div class="container">
    <a-scene
      :mindar-image="{imageTargetSrc: testMind.mindSrc,uiScanning: 'no'}"
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
    >
      <a-camera position="0 0 0" look-controls="enabled: false" />
      <a-entity id="example-target-0" mindar-image-target="targetIndex: 0">
        <a-image :src="imgSrc" position="0 0.552 0" height="0.552" width="1" rotation="0 0 0" />
      </a-entity>
      <a-entity id="example-target-1" mindar-image-target="targetIndex: 1">
        <a-image :src="imgSrc" position="0 0.552 0" height="0.552" width="1" rotation="0 0 0" />
      </a-entity>
      <a-entity id="example-target-2" mindar-image-target="targetIndex: 2">
        <a-image :src="imgSrc" position="0 0.552 0" height="0.552" width="1" rotation="0 0 0" />
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
import img0 from '@/views/test/mindAr/img/1.png'
import img1 from '@/views/test/mindAr/img/2.png'
import img2 from '@/views/test/mindAr/img/3.png'
export default {
  name: 'OneTarget',
  data() {
    return {
      imgSrc: null,
      testMind: {
        mindSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/d01.mind',
        imgSrcArr: [img0, img1, img2]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const exampleTarget0 = document.querySelector('#example-target-0')
      const exampleTarget1 = document.querySelector('#example-target-1')
      const exampleTarget2 = document.querySelector('#example-target-2')
      exampleTarget0.addEventListener('targetFound', event => {
        console.log(event)
        this.imgSrc = this.testMind.imgSrcArr[0]
      })
      exampleTarget0.addEventListener('targetLost', event => {
        this.imgSrc = null
      })
      exampleTarget1.addEventListener('targetFound', event => {
        this.imgSrc = this.testMind.imgSrcArr[1]
      })
      exampleTarget1.addEventListener('targetLost', event => {
        this.imgSrc = null
      })
      exampleTarget2.addEventListener('targetFound', event => {
        this.imgSrc = this.testMind.imgSrcArr[2]
      })
      exampleTarget2.addEventListener('targetLost', event => {
        this.imgSrc = null
      })
    }
  }
}
</script>

<style scoped>
</style>
