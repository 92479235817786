var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flow-steps-container" },
    [
      _c("div", { staticClass: "start-container step-container" }, [
        _c("img", {
          staticClass: "step-img",
          attrs: { src: _vm.Start, alt: "" },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "arrow",
          attrs: { src: _vm.DownArrow, alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c(
        "draggable",
        { attrs: { list: _vm.steps } },
        _vm._l(_vm.steps, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "step-container",
              on: {
                click: function ($event) {
                  return _vm.editStep(index)
                },
              },
            },
            [
              _c("div", { staticClass: "index" }, [
                _vm._v(" " + _vm._s(index + 1)),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "step-img",
                class: { "is-checked-step-img": _vm.isCheckedIndex === index },
                attrs: { src: item.img, alt: "" },
              }),
              _vm._v(" "),
              index !== _vm.steps.length - 1
                ? _c("img", {
                    staticClass: "arrow",
                    attrs: { src: _vm.DownArrow, alt: "" },
                  })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }