
export const LevelObjectEnum = [
  { name: 'A', value: 'A' },
  { name: 'B', value: 'B' },
  { name: 'C', value: 'C' }
]

export const SpecTypeObjectEnum = [
  { name: 'NUM_MEASUREMENT', value: '数值测量' },
  { name: 'RESULT_JUDGMENT', value: '结果判断' }
]

export const ChecklistStatusEnum = {
  FINISHED: '已完成',
  UNFINISHED: '未完成'
}

export const ChecklistStatusStyleEnum = {
  FINISHED: 'success',
  UNFINISHED: 'danger'
}

export const ChecklistStatusCnEnum = {
  已完成: '已完成',
  未完成: '未完成'
}

export const ChecklistStatusStyleCnEnum = {
  已完成: 'success',
  未完成: 'danger'
}

export const ChecklistAbnormalEnum = [
  { name: true, value: '不合格', style: 'danger' },
  { name: false, value: '合格', style: 'info' }
]

// 后面改接口
export const CharacterObjectEnum = [
  { name: 'DISTANCE', value: '距离' },
  { name: 'R_ANGLE', value: 'R角' },
  { name: 'SLOT_WIDTH', value: '槽宽' },
  { name: 'INNER_DIAMETER', value: '内径' }
]

export const ToolsObjectEnum = [
  { name: 'TWO_DIMENSION', value: '二次元' },
  { name: 'THREE_DIMENSION', value: '三次元' },
  { name: 'RULER', value: '卡尺' },
  { name: 'HEIGHT_METER', value: '高度计' }
]
