<template>
  <div>
    <ht-table ref="list" v-loading="isLoading" :data="getAttachmentList">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in AttachmentListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '附件名称'">
            <dw-file :url="row.url" :show-del="false" @view-file="downloadFile" />
          </span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation width="150">
        <template v-slot="{row,$index}">
          <el-link v-if="isCanPreview(row.url) !== 'other'" icon="el-icon-view" type="primary" :href="row.url" target="_blank">预览</el-link>
          <ht-button type="text" icon="el-icon-download" @click="downloadFile(row.url)">下载</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { downloadFile, isCanPreview } from '@/utils'
import { AttachmentListTitle } from '@/views/knowledgeBaseMgr/tableTitle'

export default {
  name: 'AttachmentsList',
  extends: HtListContainer,
  props: {
    attachmentList: { type: Array, default: () => [] },
    showOperation: { type: Boolean, default: true }
  },
  data() {
    return {
      AttachmentListTitle,
      src: undefined
    }
  },
  computed: {
    getAttachmentList() {
      return this.attachmentList.map(function(item) {
        return { url: item }
      })
    }
  },
  methods: {
    downloadFile, isCanPreview,
    onDelete(index) {
      this.$emit('on-delete', index)
    }
  }
}
</script>

<style scoped>

</style>
