var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-container" },
    [
      _c(
        "el-form",
        {
          ref: "configForm",
          staticClass: "left-container",
          attrs: { model: _vm.configForm, rules: _vm.configRules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "步骤名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入步骤名称",
                  maxlength: "10",
                  "show-word-limit": "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.focusedItem("title")
                  },
                },
                model: {
                  value: _vm.configForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.configForm, "title", $$v)
                  },
                  expression: "configForm.title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }