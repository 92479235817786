import { isIncludeChinese } from '@/utils/validate'
import { postTranslate } from '@/api/newRemoteGuidance/newRemoteGuidance'
import { UserTypeEnum } from '@/views/newRemoteGuidance/var/businessVar'

// 拥有联系人菜单就是远程指导的专家：联系人菜单可见范围（EXPERT，ADMIN）
export function isExpert(userInfo) {
  return userInfo && userInfo.roles && (userInfo.roles.includes('EXPERT') || userInfo.roles.includes('ADMIN'))
}

// 没有联系人菜单就是远程指导的现场
export function isStaff(userInfo) {
  // return userInfo && userInfo.roles && userInfo.roles.includes('STAFF')
  return userInfo && userInfo.roles && !userInfo.roles.includes('EXPERT') && !userInfo.roles.includes('ADMIN')
}

export function isHasContractList(userInfo) {
  return userInfo && userInfo.roles && (userInfo.roles.includes('EXPERT') || userInfo.roles.includes('ADMIN'))
}

export function notWebsocket(userInfo) {
  return !(userInfo && userInfo.loginPath && userInfo.loginPath.includes('Websocket'))
}

export function translateMsg(context, content) {
  return new Promise(resolve => {
    // 对消息进行翻译
    content.isHideTranslating = false
    content.isTranslating = true
    const data = {
      originalText: context,
      fromEnum: 'en',
      toEnum: 'cn'
    }
    if (isIncludeChinese(context)) {
      data.fromEnum = 'cn'
      data.toEnum = 'en'
    }
    postTranslate(data).then(res => {
      content.translation = res.transResult
      content.isTranslating = false
      resolve(content)
    }).catch(() => {
      content.isTranslating = false
      resolve(content)
    })
  })
}

export function getUserRoles(keys) {
  const arr = []
  keys.forEach(key => {
    UserTypeEnum[key] ? arr.push(UserTypeEnum[key]) : null
  })
  return arr.toString()
}

export function isSinoma(router) {
  return router.path.includes('newRemoteGuidance/externalCall/sinoma')
}

export function isExternalCall(router) {
  return router.path.includes('newRemoteGuidance/externalCall')
}

export function isExternalCommonCall(router) {
  return router.path.includes('newRemoteGuidance/externalCall/common')
}
