var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper mobile", class: _vm.classObj }, [
    _c(
      "div",
      {
        staticClass: "main-container",
        class: [
          {
            hasTagsView: _vm.needTagsView,
            "in-meeting-main-container": _vm.isInMeeting,
          },
        ],
      },
      [
        _c(
          "div",
          { class: { "fixed-header": _vm.fixedHeader } },
          [_c("mobile-navbar")],
          1
        ),
        _vm._v(" "),
        _vm.isInMeeting
          ? _c("div", { staticClass: "in-meeting-info" }, [
              _vm._v("该功能需要将屏幕旋转至竖屏"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("mobile-app-main", {
          class: { "in-meeting": _vm.isInMeeting },
          staticStyle: { "margin-top": "50px" },
          attrs: { "show-bottom": _vm.showMainBottom },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }