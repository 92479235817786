<template>
  <div class="home-page white-color gradient-text-two">
    <div class="box">
      <p class="shift-color">
        调起远程指导中,请稍等...
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { tempToken } from '@/api/userInfo/user'

export default {
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  created() {
    const date = Date.parse(new Date())
    tempToken().then((resp) => {
      const query = `businessUuid=${date}&sign=1&toke=${resp.tempToken}`
      setTimeout(() => {
        window.open(`yczd://?${query}`)
      }, 500)
    })
  }
}
</script>

<style scoped lang="scss">
  .home-page {
    background: white;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: bold;
  }
  .shift-color {
    background: linear-gradient(to bottom, #a1bde5, #42618f);
    -webkit-background-clip: text;
    color: transparent;
    height: 120px;
    margin: auto 0;
  }
  .box {
    animation: box 3s ease-in-out infinite;
    /* 其它浏览器兼容性前缀 */
    -webkit-animation: box 3s ease-in-out infinite;
    -moz-animation: box 3s ease-in-out infinite;
    -ms-animation: box 3s ease-in-out infinite;
    -o-animation: box 3s ease-in-out infinite;
  }
</style>
