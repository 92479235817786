<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClearSearch">
      <template slot="left">
        <el-input v-model="listQuery.name" placeholder="工程名称" />
        <el-input v-model="listQuery.location" placeholder="工程地址" />
        <el-date-picker
          v-model="createdAt"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          placement="bottom-start"
          start-placeholder="工程开始日期"
          end-placeholder="工程结束日期"
        />
        <el-select v-model="listQuery.isFinished" placeholder="工程状态">
          <el-option v-for="item in ProjectStatusEnum" :key="item.name" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <el-button type="success" icon="el-icon-plus" @click="onCreate">新增工程</el-button>
        <ht-button v-if="list.length > 0" type="primary" icon="el-icon-pie-chart" @click="openProjectStatisticalCakeDialog">工程统计</ht-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer" @row-click="handleRowClick">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in ProjectListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '工程地址'">
            {{ row[item.prop] || '-' }}
          </span>
          <span v-else-if="item.label === '工程起止日期'">
            {{ row.projectStartAt ? `${row.projectStartAt}至${row.projectEndAt}` : '-' }}
          </span>
          <template v-else-if="item.label === '工程资料'">
            <el-link
              :type="row.attachmentData && row.attachmentData.length > 0 ? 'primary': 'info'"
              :disabled="row.attachmentData && row.attachmentData.length === 0"
              @click="openDocumentDialog(row)"
            >{{ row.attachmentData && row.attachmentData.length || 0 }}</el-link>
          </template>
          <span v-else-if="item.label === '绑定客户账号'">
            {{ row.customer ? row.customer.fullName: '-' }}
          </span>
          <template v-else-if="item.label === '关联工单'">
            <el-link
              :type="row.workOrderCount > 0 ? 'primary': 'info'"
              :disabled="row.workOrderCount === 0"
              @click="openWorkOrderDialog(row)"
            >{{ row.workOrderCount || 0 }}</el-link>
          </template>
          <template v-else-if="item.label === '工程进度'">
            <el-progress v-if="row[item.prop] || row[item.prop] === 0" :percentage="row[item.prop]" :show-text="!row.isFinished" style="width: 95%" :color="row.isFinished ? '#e6ebf5' : '#2e5aff'" />
            <template v-else>-</template>
          </template>
          <el-tag v-else-if="item.label === '工程状态'" :type="getProjectStatus(row[item.prop]).style">{{ getProjectStatus(row[item.prop]).value || '-' }}</el-tag>
          <span v-else-if="item.label === '现存设备数'">{{ row[item.prop] || 0 }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation v-if="isMobile" :width="110">
        <template v-slot="{row}">
          <dw-table-dropdown-buttons :buttons="row.buttons" :row="row" @handle-button-click="handleButtonClick(row,arguments)" />
        </template>
      </ht-table-column-operation>
      <ht-table-column-operation v-else :width="360">
        <template v-slot="{row}">
          <ht-button :disabled="row.isFinished" type="text" icon="el-icon-setting" @click="openLeaseEquipmentMgrDialog(row)">租用设备</ht-button>
          <ht-button icon="el-icon-view" type="text" @click="onView(row)">详情</ht-button>
          <ht-button :disabled="row.isFinished" type="text" icon="el-icon-edit" @click="onEdit(row)">编辑</ht-button>
          <ht-popover-button :disabled="row.isFinished" type="text" :danger-text="true" icon="el-icon-warning-outline" confirm-title="操作后无法取消，请确认是否要结束工程!" @confirmed="onFinish(row)">结束工程</ht-popover-button>
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该条数据？" @confirmed="onDelete(row)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
    <ht-dialog ref="createProjectFormDialog" title="新增工程" :width="isMobile? '100%' : '900px'" :close-on-click-modal="false" @close="closeCreateProjectDialog">
      <div slot="content">
        <project-form v-if="showCreateProjectFormDialog" @cancel-submit="closeCreateProjectDialog" @confirm-submit="submitCreateProjectForm" />
      </div>
    </ht-dialog>
    <ht-dialog ref="documentDialog" title="工程资料" :width="isMobile? '100%' : '800px'" @close="closeDocumentDialog">
      <div slot="content">
        <document-list v-if="showDocumentList" ref="documentList" :document-list="documentList" :show-operation="false" />
      </div>
    </ht-dialog>
    <ht-dialog ref="workOrderDialog" title="工程工单" :width="isMobile? '100%' : '1000px'" @close="closeWorkOrderDialog">
      <div slot="content">
        <el-tabs v-model="workOrderActiveName" @tab-click="getWorkOrderList">
          <el-tab-pane v-for="(item, index) in getTaskTempTypeEnum()" :key="index" :label="item.value" :name="item.name" />
        </el-tabs>
        <work-order-list
          v-if="showWorkOrderList"
          ref="workOrderList"
          :is-loading="isWorkOrderLoading"
          :table-title="workOrderTableTitle"
          :list="workOrderList"
          :page="workOrderPage"
          :list-query="workOrderListQuery"
        />
      </div>
    </ht-dialog>

    <ht-dialog ref="leaseEquipmentMgrDialog" title="租用设备管理" :width="isMobile? '100%' : '900px'" :close-on-click-modal="false" @close="closeLeaseEquipmentMgrDialog">
      <div slot="content">
        <lease-equipment-mgr
          v-if="showLeaseEquipmentMgr"
          ref="leaseEquipmentMgr"
          :project-id="projectId"
          :project-data="projectData"
          @cancel-submit="closeLeaseEquipmentMgrDialog"
          @confirm-submit="submitLeaseEquipmentMgrDialog"
          @confirm-update="updateLeaseEquipmentMgrDialog"
        />
      </div>
    </ht-dialog>

    <ht-dialog ref="projectProgressChartDialog" title="工程分阶段完整情况统计" :width="isMobile? '100%' : '650px'" @close="closeProjectStatisticalCakeDialog">
      <div slot="content">
        <project-progress-chart v-if="showProjectProgressChart" ref="projectProgressChart" v-loading="isProjectProgressChartLoading" :count="projectProgressCount" height="300px" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import projectForm from '@/views/constructionProjectMgr/projectMgr/components/projectForm'
import DocumentList from '@/views/constructionProjectMgr/projectMgr/components/documentList'
import WorkOrderList from '@/views/constructionProjectMgr/projectMgr/components/workOrderList'
import LeaseEquipmentMgr from '@/views/constructionProjectMgr/projectMgr/components/leaseEquipmentMgr'
import ProjectProgressChart from '@/views/constructionProjectMgr/projectMgr/components/projectProgressChart'
import { ProjectListTitle } from '@/views/constructionProjectMgr/projectMgr/tableTitle'
import { ProjectStatusEnum,
  ProjectLeaseStatusEnum, ProjectLeaseStatusStyleEnum } from '@/views/constructionProjectMgr/projectMgr/enum'
import { PatrolWorkOrderListTitle, RepairWorkOrderListTitle } from '@/views/taskMgr/tableTitle'
import { getAddress, getProjectStatus } from '@/utils/get-display'
import {
  getProject,
  getProjects,
  deleteProject,
  getProjectTaskWorkOrders,
  getProjectsPieStatistic,
  putProjectFinished
} from '@/api/constructionProjectMgr/projectMgr'
import { getTaskTempTypeEnum } from '@/views/taskMgr/commonJs'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { projectForm, DocumentList, LeaseEquipmentMgr, WorkOrderList, ProjectProgressChart },
  extends: HtListContainer,
  data() {
    return {
      ProjectListTitle,
      ProjectStatusEnum,
      ProjectLeaseStatusEnum, ProjectLeaseStatusStyleEnum,
      PatrolWorkOrderListTitle, RepairWorkOrderListTitle,
      showCreateProjectFormDialog: false,
      showDocumentList: false,
      documentList: [],
      isAddedEquipmentItems: [],
      showAddEquipmentList: false,
      showLeaseEquipmentMgr: false,
      showWorkOrderList: false,
      leaseEquipmentMgrDialogTitle: '租用设备管理',
      projectId: undefined,
      projectData: undefined,
      workOrderActiveName: 'PATROL',
      isWorkOrderLoading: false,
      workOrderTableTitle: PatrolWorkOrderListTitle,
      workOrderList: [],
      workOrderPage: { total: 0 },
      workOrderListQuery: { page: 1, perPage: 20 },
      createdAt: [],
      showProjectProgressChart: false,
      projectProgressQueryDate: undefined,
      isProjectProgressChartLoading: false,
      projectProgressCount: 0
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getAddress, getTaskTempTypeEnum, getProjectStatus,
    onClearSearch() {
      this.createdAt = []
      this.onClear()
    },
    async getList() {
      this.beforeGetList()
      this.listQuery.createdStartAt = this.createdAt[0]
      this.listQuery.createdEndAt = this.createdAt[1]
      await getProjects(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.workOrderCount = item.workOrderCount || 0
          item.progressRatio = parseFloat((item.progressRatio * 100).toFixed(2))
          item.buttons = [
            { name: '租用设备', icon: 'el-icon-setting', isPopoverButton: false, disabled: item.isFinished },
            { name: '详情', icon: 'el-icon-view', isPopoverButton: false },
            { name: '编辑', icon: 'el-icon-edit', isPopoverButton: false, disabled: item.isFinished },
            { name: '结束工程', icon: 'el-icon-warning-outline', isPopoverButton: true, disabled: item.isFinished, isDangerText: true, confirmTitle: '操作后无法取消，请确认是否要结束工程!' },
            { name: '删除', icon: 'el-icon-delete', isPopoverButton: true, isDangerText: true, confirmTitle: '请确认是否要删除该条数据?' }
          ]
        })
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onCreate() {
      this.showCreateProjectFormDialog = true
      this.$refs.createProjectFormDialog.dialogVisible = true
    },
    closeCreateProjectDialog() {
      this.showCreateProjectFormDialog = false
      this.$refs.createProjectFormDialog.dialogVisible = false
    },
    submitCreateProjectForm() {
      this.closeCreateProjectDialog()
      this.getList()
    },
    handleRowClick(row, column) {
      if (column.label !== '工程资料' && column.label !== '关联工单' && column.label !== '操作') {
        this.onView(row)
      } else if (column.label === '工程资料') {
        this.openDocumentDialog(row)
      } else if (column.label === '关联工单') {
        this.openWorkOrderDialog(row)
      }
    },
    onView(row) {
      this.$router.push({
        name: 'projectMgrView',
        params: { projectId: row.id, isEdit: 0 }
      })
    },
    onEdit(row) {
      this.$router.push({
        name: 'projectMgrEdit',
        params: { projectId: row.id, isEdit: 1 }
      })
    },
    onFinish(row) {
      if (row.projectStockQuantity) {
        this.$message({ message: '请先将设备结束租用后再进行结束工程操作', center: true, type: 'warning' })
      } else {
        putProjectFinished(row.id).then(() => {
          this.$message({ message: '操作成功', center: true, type: 'success' })
          this.getList()
        })
      }
    },
    onDelete(row) {
      deleteProject(row.id).then(() => {
        this.$message({ message: '删除成功', center: true, type: 'success' })
        this.getList()
      })
    },
    openDocumentDialog(row) {
      this.documentList = row.attachmentData
      this.showDocumentList = true
      this.$refs.documentDialog.dialogVisible = true
    },
    closeDocumentDialog() {
      this.documentList = []
      this.showDocumentList = false
      this.$refs.documentDialog.dialogVisible = false
    },
    openWorkOrderDialog(row) {
      this.projectId = row.id
      this.showWorkOrderList = true
      this.$refs.workOrderDialog.dialogVisible = true
      this.$nextTick(() => {
        this.getWorkOrderList()
      })
    },
    closeWorkOrderDialog() {
      this.showWorkOrderList = false
      this.$refs.workOrderDialog.dialogVisible = false
      this.workOrderActiveName = 'PATROL'
      this.workOrderList = []
    },
    getWorkOrderList() {
      this.workOrderListQuery.type = this.workOrderActiveName
      this.workOrderTableTitle = this.workOrderActiveName === 'PATROL' ? PatrolWorkOrderListTitle : RepairWorkOrderListTitle
      this.isWorkOrderLoading = true
      getProjectTaskWorkOrders(this.projectId, this.workOrderListQuery).then(resp => {
        this.isWorkOrderLoading = false
        this.workOrderList = resp.items
        this.workOrderPage = resp.page
      }).catch(() => {
        this.isWorkOrderLoading = false
      })
    },
    handleButtonClick(row, $argument) {
      switch ($argument[0]) {
        case '租用设备':
          this.openLeaseEquipmentMgrDialog(row)
          break
        case '详情':
          this.onView(row)
          break
        case '编辑':
          this.onEdit(row)
          break
        case '结束工程':
          this.onFinish(row)
          break
        case '删除':
          this.onDelete(row)
          break
      }
    },
    openLeaseEquipmentMgrDialog(row) {
      getProject(row.id).then(resp => {
        this.projectId = row.id
        this.projectData = resp
        this.showLeaseEquipmentMgr = true
        this.$refs.leaseEquipmentMgrDialog.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.leaseEquipmentMgr.getProjectInfo(this.projectData)
        })
      })
    },
    closeLeaseEquipmentMgrDialog() {
      this.showLeaseEquipmentMgr = false
      this.$refs.leaseEquipmentMgrDialog.dialogVisible = false
      this.getList()
    },
    submitLeaseEquipmentMgrDialog() {
      this.closeLeaseEquipmentMgrDialog()
      this.getList()
    },
    async updateLeaseEquipmentMgrDialog() {
      getProject(this.projectId).then(resp => {
        this.projectData = resp
        this.$nextTick(() => {
          this.$refs.leaseEquipmentMgr.getProjectInfo(this.projectData)
          this.getList()
        })
      })
    },
    openProjectStatisticalCakeDialog() {
      this.showProjectProgressChart = true
      this.$refs.projectProgressChartDialog.dialogVisible = true
      this.getPatrolPieStatistical()
    },
    closeProjectStatisticalCakeDialog() {
      this.showProjectProgressChart = false
      this.$refs.projectProgressChartDialog.dialogVisible = false
    },
    getPatrolPieStatistical() {
      this.projectProgressCount = 0
      this.isProjectProgressChartLoading = true
      getProjectsPieStatistic({ isFinished: false }).then(resp => {
        resp.forEach(item => {
          this.projectProgressCount += item.projectCount
        })
        this.$refs.projectProgressChart.getData(resp, this.projectProgressCount)
        this.isProjectProgressChartLoading = false
      }).catch(() => {
        this.isProjectProgressChartLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}

.img-container{
  display: flex;
  align-items: center;
  justify-content: center;
  .img{
    width: 20px;
  }
}

.el-progress.el-progress--line{
  text-align: left;
}
</style>
