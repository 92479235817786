var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "warehouse-stock-container",
      class: [{ "is-card-container": _vm.isCard }],
      on: { click: _vm.onView },
    },
    [
      _c("div", { staticClass: "top-section" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(
            "设备：" +
              _vm._s((_vm.warehouseInfo && _vm.warehouseInfo.name) || "-")
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "button" }, [_vm._v("查看详情>")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "middle-section" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "item quantity" }, [
            _vm._v(
              "库存数：" +
                _vm._s(_vm.warehouseInfo && _vm.warehouseInfo.quantity)
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom-section" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }