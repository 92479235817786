<template>
  <div style="display: inline-block;">
    <span v-for="(i, index) in images" :key="index" class="imageBox">
      <span v-if="canDelete" class="iconBox" @click="deleteImage(i,index)">
        <a><i class="el-icon-close" style="position: absolute;top: 0;right: 0;color: white;" /></a>
      </span>
      <el-image
        style="width: 100%; height: 100%"
        fit="contain"
        :src="i"
        :preview-src-list="images"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: undefined
    },
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showIcon: false,
      preImgList: []
    }
  },
  methods: {
    deleteImage(i, index) {
      this.images.splice(index, 1)
      this.$emit('delete-image', index, this.images)
    }
  }
}
</script>

<style scoped>
.iconBox {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: grey;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  opacity: 0.5;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.imageBox {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 1px solid #E0E0E0;
  margin-right: 10px;
}
</style>
