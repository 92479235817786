var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-section", attrs: { title: "hf_use_description" } },
    [
      _c(
        "div",
        {
          staticClass: "view-title top-title",
          class: { "focused-item": _vm.isFocusedItem === "title" },
        },
        [
          _vm._v(
            _vm._s(_vm.currentIndex + 1 + "/" + _vm.count) +
              "：" +
              _vm._s(_vm.title || "步骤名称")
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "top-button-container" },
        [
          _vm._t("other-button"),
          _vm._v(" "),
          _vm.showScan
            ? _c(
                "el-button",
                {
                  staticClass: "circle-button photo-button",
                  attrs: { type: "text", disabled: _vm.buttonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("toNativeScanCode")
                    },
                  },
                },
                [_vm._v("扫码")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showJump && _vm.count > 1
            ? _c(
                "el-button",
                {
                  staticClass: "circle-button photo-button",
                  attrs: { type: "text", disabled: _vm.buttonDisabled },
                  on: { click: _vm.jumpStep },
                },
                [_vm._v("步骤跳转")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uploadPhotos.length < 9 && _vm.showPhotoButton
            ? _c(
                "el-button",
                {
                  staticClass: "circle-button photo-button",
                  attrs: {
                    type: "text",
                    title: "拍摄照片",
                    disabled: _vm.buttonDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("nativeTakePhoto")
                    },
                  },
                },
                [_vm._v("拍摄照片")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uploadPhotos.length > 0 && _vm.showPhotoButton && _vm.showPhotos
            ? _c(
                "el-badge",
                {
                  staticClass: "fullscreen-button-badge",
                  attrs: { value: _vm.uploadPhotos.length, type: "warning" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "circle-button fullscreen-button",
                      attrs: { type: "text", disabled: _vm.buttonDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("openPhoto")
                        },
                      },
                    },
                    [_vm._v("查看照片")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showMoreFunction
            ? _c(
                "el-button",
                {
                  staticClass: "circle-button more-function-button",
                  attrs: { type: "text", disabled: _vm.buttonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("moreFunction")
                    },
                  },
                },
                [_vm._v("更多功能")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showMoreFunction
            ? _c(
                "div",
                { staticClass: "more-function-container" },
                [
                  _c("img", {
                    staticClass: "more-function-img",
                    attrs: { src: _vm.Triangle, alt: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "square-button",
                      class: { "focused-item": _vm.isFocusedItem === "text" },
                      attrs: { type: "text", disabled: _vm.buttonDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("nativePlayTTS")
                        },
                      },
                    },
                    [_vm._v("语音播报")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "square-button top-border-button",
                      attrs: { type: "text", disabled: _vm.buttonDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("toRemoteGuidance")
                        },
                      },
                    },
                    [_vm._v("远程指导")]
                  ),
                  _vm._v(" "),
                  _vm.showAiQaAssistant
                    ? _c(
                        "el-button",
                        {
                          staticClass: "square-button top-border-button",
                          attrs: { type: "text", disabled: _vm.buttonDisabled },
                          on: {
                            click: function ($event) {
                              return _vm.handleActions("toAiQaAssistant")
                            },
                          },
                        },
                        [_vm._v("问答助手")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showPhotos
        ? _c(
            "web-view-carousel",
            {
              ref: "customCarousel",
              staticClass: "topCustomCarousel",
              staticStyle: { width: "0" },
              attrs: {
                height: "0",
                autoplay: false,
                arrow: "always",
                "show-icon": false,
                "button-disabled": _vm.buttonDisabled,
              },
              on: { change: _vm.carouselActiveChange },
            },
            _vm._l(_vm.uploadPhotos, function (item, index) {
              return _c(
                "web-view-carousel-item",
                {
                  key: index,
                  attrs: { "button-disabled": _vm.buttonDisabled },
                },
                [
                  _c("web-view-image", {
                    ref: "result" + index,
                    refInFor: true,
                    staticClass: "result-img",
                    attrs: {
                      src: item,
                      alt: "",
                      "preview-src-list": _vm.uploadPhotos,
                      "show-icon": false,
                      "show-del": "",
                      "button-disabled": _vm.buttonDisabled,
                    },
                    on: {
                      prev: function ($event) {
                        return _vm.handleActions("prevPhoto", arguments)
                      },
                      next: function ($event) {
                        return _vm.handleActions("nextPhoto", arguments)
                      },
                      "del-img": function ($event) {
                        return _vm.handleActions("delPhoto", arguments)
                      },
                      "close-viewer": function ($event) {
                        return _vm.handleActions("closePhoto")
                      },
                    },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "custom-dialog",
        {
          ref: "chooseItemDialog",
          attrs: { title: "选择跳转步骤", width: "500px", color: "#2e5aff" },
          on: { close: _vm.closeChooseItemDialog },
        },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleActions("jumpStep")
                    },
                  },
                  model: {
                    value: _vm.chooseStep,
                    callback: function ($$v) {
                      _vm.chooseStep = $$v
                    },
                    expression: "chooseStep",
                  },
                },
                _vm._l(_vm.count, function (item) {
                  return _c(
                    "el-radio",
                    {
                      key: item,
                      class: ["radio-" + item.color],
                      attrs: { label: item },
                    },
                    [_vm._v(_vm._s("第" + item + "步"))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }