import store from '@/store'
import { postUserSign } from '@/api/userInfo/user'
import Vue from 'vue'
const vm = new Vue({})

export function oneStopLoginSinomaFun(sessionKey) {
  return new Promise((resolve, reject) => {
    store.dispatch('user/sinomaSsoLogin', sessionKey).then(async() => {
      await store.dispatch('user/oneStopLoginGetUserImInfo')
      resolve()
    }).catch((err) => {
      vm.$message({ message: '授权验证失败', center: true, type: 'error' })
      reject(err)
    })
  })
}

export function oneStopLoginComFun(appId, outerId, userName) {
  return new Promise((resolve, reject) => {
    const userSignParams = {
      appId: appId,
      outerId: outerId,
      userName: userName
    }
    postUserSign(userSignParams).then(resp => {
      const loginParams = {
        sign: resp.sign,
        appId: appId,
        action: 'login',
        loginType: 'one_stop_login'
      }
      store.dispatch('user/oneStopLogin', loginParams)
        .then(async(resp) => {
          await store.dispatch('user/oneStopLoginGetUserImInfo')
          resolve(resp)
        }).catch((err) => {
          vm.$message({ message: '授权验证失败', center: true, type: 'error' })
          reject(err)
        })
    }).catch((err) => {
      reject(err)
    })
  })
}
