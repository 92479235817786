var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step-ui-detail-container", style: { height: _vm.height } },
    [
      _c("div", { staticClass: "view-container" }, [
        _c("div", { staticClass: "top-section" }, [
          _c(
            "div",
            {
              staticClass: "view-title top-title",
              class: { "focused-item": _vm.isFocusedItem === "title" },
            },
            [
              _vm._v(
                _vm._s(_vm.currentIndex + 1 + "/" + _vm.count) +
                  "：" +
                  _vm._s(_vm.properties.title || "步骤名称")
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "top-button-container" },
            [
              _vm.currentIndex > 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "top-title-button prev-button",
                      attrs: { type: "text" },
                      on: { click: _vm.toPrev },
                    },
                    [_vm._v("上一项")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentIndex + 1 < _vm.count
                ? _c(
                    "el-button",
                    {
                      staticClass: "top-title-button next-button",
                      attrs: { type: "text" },
                      on: { click: _vm.toNext },
                    },
                    [_vm._v("下一项")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "top-title-button voice-button",
                  class: { "focused-item": _vm.isFocusedItem === "text" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.nativePlayTTS(_vm.properties.guidanceVideo)
                    },
                  },
                },
                [_vm._v("语音播报")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "top-title-button more-function-button",
                  attrs: { type: "text" },
                  on: { click: _vm.moreFunction },
                },
                [_vm._v("更多功能")]
              ),
              _vm._v(" "),
              _vm.showMoreFunction
                ? _c(
                    "div",
                    { staticClass: "more-function-container" },
                    [
                      _c("img", {
                        staticClass: "more-function-img",
                        attrs: { src: _vm.Triangle, alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "top-title-button guidance-button",
                          attrs: { type: "text" },
                          on: { click: _vm.toRemoteGuidance },
                        },
                        [_vm._v("远程指导")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "top-title-button ai-button",
                          attrs: { type: "text" },
                          on: { click: _vm.toAiQaAssistant },
                        },
                        [_vm._v("问答助手")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "middle-section" }, [
          _c(
            "div",
            {
              staticClass: "left-container",
              class: { "focused-item": _vm.isFocusedItem === "referenceImage" },
            },
            [
              _c(
                "div",
                { staticClass: "sub-title only-text-title property-title" },
                [_vm._v("参考图")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "img-container reference-img-container" },
                [
                  _vm.properties.referenceImage
                    ? _c("dw-el-image", {
                        ref: "referenceImage",
                        staticClass: "reference-img",
                        attrs: {
                          src: _vm.properties.referenceImage,
                          alt: "",
                          "preview-src-list": [_vm.properties.referenceImage],
                          "show-icon": false,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.properties.referenceImage
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button fullscreen-button",
                          on: { click: _vm.fullScreenReferenceImage },
                        },
                        [_vm._v("查看参考图")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-container",
              class: { "focused-item": _vm.isFocusedItem === "text" },
            },
            [
              _c(
                "div",
                { staticClass: "sub-title only-text-title property-title" },
                [_vm._v("检查要求")]
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "check-standard",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  placeholder: "检查要求",
                  type: "textarea",
                  disabled: "",
                },
                model: {
                  value: _vm.properties.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.properties, "text", $$v)
                  },
                  expression: "properties.text",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom-section" }, [
          _c("div", { staticClass: "top-container" }, [
            _c("div", { staticClass: "left-container" }, [
              _c(
                "div",
                { staticClass: "title-container" },
                [
                  _vm.submitResults.uploadPhotos &&
                  _vm.submitResults.uploadPhotos.length < 9
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button photo-button",
                          attrs: { type: "text" },
                          on: { click: _vm.nativeTakePhoto },
                        },
                        [
                          _vm.properties.mustUploadPhoto
                            ? _c("span", { staticClass: "required-item" }, [
                                _vm._v("*"),
                              ])
                            : _vm._e(),
                          _vm._v("拍摄"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.submitResults.uploadPhotos &&
                  _vm.submitResults.uploadPhotos.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button del-button",
                          attrs: { type: "text" },
                          on: { click: _vm.delPhoto },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "img-container" },
                [
                  _c(
                    "dw-carousel",
                    {
                      ref: "customCarousel",
                      attrs: {
                        height: "140px",
                        autoplay: false,
                        arrow: "always",
                        "show-icon": false,
                      },
                      on: { change: _vm.setActiveItem },
                    },
                    _vm._l(
                      _vm.submitResults.uploadPhotos,
                      function (item, index) {
                        return _c(
                          "dw-carousel-item",
                          { key: index },
                          [
                            _c("dw-el-image", {
                              ref: "result" + index,
                              refInFor: true,
                              staticClass: "result-img",
                              attrs: {
                                src: item,
                                alt: "",
                                "preview-src-list": _vm.results.uploadPhotos,
                                "show-icon": false,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _vm._v(" "),
                  _vm.submitResults.uploadPhotos &&
                  _vm.submitResults.uploadPhotos.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button fullscreen-button",
                          on: { click: _vm.fullScreenPhoto },
                        },
                        [_vm._v("查看照片")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right-container" },
              [
                _c(
                  "div",
                  { staticClass: "title-container" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button fact-button",
                        attrs: { type: "text" },
                        on: { click: _vm.inputDesc },
                      },
                      [_vm._v("情况描述")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button clear-button",
                        attrs: { type: "text" },
                        on: { click: _vm.clearFactDesc },
                      },
                      [_vm._v("清空")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  ref: "rectDescRef",
                  staticClass: "check-standard",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { placeholder: "情况描述", type: "textarea" },
                  model: {
                    value: _vm.submitResults.factDesc,
                    callback: function ($$v) {
                      _vm.$set(_vm.submitResults, "factDesc", $$v)
                    },
                    expression: "submitResults.factDesc",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c(
                "el-button",
                {
                  staticClass: "save-button bottom-button",
                  attrs: { type: "text" },
                  on: { click: _vm.saveRecord },
                },
                [_vm._v("保存记录")]
              ),
              _vm._v(" "),
              _vm.isLastStep
                ? _c(
                    "el-button",
                    {
                      staticClass: "submit-button bottom-button",
                      attrs: { type: "text" },
                      on: { click: _vm.submitRecord },
                    },
                    [_vm._v(_vm._s(_vm.isLastStep ? "提交单据" : "保存记录"))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }