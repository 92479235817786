<template>
  <ht-table ref="list" v-loading="isLoading" :data="leaseDetailList">
    <ht-table-column label="序号" type="index" fixed="left" />
    <ht-table-column
      v-for="item in LeaseListTitle"
      :key="item.prop"
      :label="item.label"
      :prop="item.prop"
      :min-width="item.width || 100"
      :fixed="item.fixed"
    >
      <template v-slot="{row}">
        <span v-if="item.label === '工程地址'">
          {{ row[item.prop] || '-' }}
          <!--          {{ row[item.prop] ? row[item.prop] || '-': getAddress('',row.projectProvince && row.projectProvince.name, row.projectCity && row.projectCity.name, row.projectDistrict && row.projectDistrict.name, row.projectAddress) || '-' }}-->
        </span>
        <el-tag
          v-else-if="item.label === '状态'"
          :type="ProjectLeaseStatusStyleEnum[row[item.prop]]"
        >{{ ProjectLeaseStatusEnum[row[item.prop]] || '-' }}</el-tag>
        <span v-else>{{ row[item.prop] || '-' }}</span>
      </template>
    </ht-table-column>
  </ht-table>
</template>

<script>
import { LeaseListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import {
  ProjectLeaseStatusEnum,
  ProjectLeaseStatusStyleEnum
} from '@/views/constructionProjectMgr/projectMgr/enum'
import HtListContainer from '@/components/HtListContainer'
import { downloadFile } from '@/utils'
import { getAddress } from '@/utils/get-display'

export default {
  name: 'LeaseDetailList',
  extends: HtListContainer,
  props: {
    leaseDetailList: {
      type: Array,
      default: () => []
    },
    showOperation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      LeaseListTitle,
      ProjectLeaseStatusEnum,
      ProjectLeaseStatusStyleEnum
    }
  },
  methods: {
    downloadFile, getAddress,
    onDelete(index) {
      this.$emit('on-delete', index)
    }
  }
}
</script>

<style scoped>

</style>
