import rtc from '@/views/newRemoteGuidance/rongCloud/rtc'

// 聚焦指导
export default {
  methods: {
    isHasFocus() {
      rtc.getRoomAttributes(this.room, this.focusedGuidanceKeys).then(data => {
        if (data.hasOwnProperty('focusedUserId')) { // 取到focusedUserId：当前有用户正在被聚焦
          this.focusedGuidanceInfo = data
          this.isFocusGuidance = true
          const index = this.remoteUserList.findIndex(item => item.imUserId === data.focusedUserId)
          if (index !== -1) {
            this.enlargeVideo(index)
            this.enLargeVideoInfo.isFocusedGuide = true
          }
        }
      })
    },
    // 1v1模式下默认开启聚焦
    async hasOneStaffToFocus() {
      // 只有一个现场：this.remoteStaffList.length === 1
      const isOneStaff = this.remoteStaffList.length === 1
      if (isOneStaff && !this.isFocusGuidance) {
        // 在remoteUserList里面找到唯一的现场，对现场进行聚焦
        const index = this.remoteUserList.findIndex(item => item.imUserId === this.remoteStaffList[0].imUserId)
        if (index !== -1) {
          this.isFocusGuidance = true
          // 聚焦信息
          const data = {
            isFocus: true, // isFocus：true本地开启聚焦，false：本地取消聚焦
            focusedUserId: this.remoteUserList[index].imUserId
          }
          await this.changeOneFocusGuidance(index, data) // index: this.remoteUserList[index], data: 聚焦信息
        }
      }
    },
    // 对某人进行聚焦指导
    async changeOneFocusGuidance(index, event) {
      // 本地或远端屏幕共享中不可以进行聚焦指导
      if (event.isFocus && (this.isShareScreenVideo || this.isRemoteShareScreen)) {
        this.$message({ message: `屏幕共享中，不可进行聚焦指导`, duration: 1500, type: 'warning' })
      } else {
        // 聚焦指导：isFocus：true本地开启聚焦，false：本地取消聚焦
        this.isFocusGuidance = event.isFocus
        if (event.isFocus) { // 开始聚焦
          // 被聚焦用户数据
          const focusData = {
            focusedUserId: event.focusedUserId
          }
          // 设置房间聚焦信息：被聚焦用户
          setTimeout(() => {
            rtc.setRoomAttribute(this.room, focusData).then(() => {
              this.focusedGuidanceInfo = focusData
              // 属性设置成功，开始本地聚焦
              this.focusGuidanceLocalStart(index, event.focusedUserId, event.isFocus)
            }).catch(() => {
              // 属性设置失败，取消聚焦，删除房间聚焦信息
              this.isFocusGuidance = false
              rtc.deleteRoomAttributes(this.room, this.focusedGuidanceKeys).then(() => {
                this.focusedGuidanceInfo = null
              })
              if (this.enLargeVideoInfo) {
                this.enLargeVideoInfo.isFocusedGuide = false
                this.$message({ message: `聚焦指导启动失败`, duration: 1500, type: 'error' })
              }
            })
          }, 1000)
          if (this.isPaintDrawing) { this.usePaint() }
          if (this.isLaserMoving) { this.useLaser() }
        } else {
          if (this.isPaintDrawing) { this.usePaint() }
          if (this.isLaserMoving) { this.useLaser() }
          // 关闭聚焦，删除聚焦房间属性，结束本地聚焦
          rtc.deleteRoomAttributes(this.room, this.focusedGuidanceKeys).then(() => {
            this.focusedGuidanceInfo = null
            this.focusGuidanceLocalFinish(event.focusedUserId, event.isFocus)
          })
          // 取消聚焦，缩小窗口
          this.zoomVideo()
        }
      }
    },
    focusGuidanceLocalStart(index, imUserId, isFocus) {
      this.isFocusGuidance = isFocus
      // 聚焦指导为放大操作，如果没有放大，进行放大操作，设置用户isFocusedGuide被聚焦状态为true
      if (this.enLarge) {
        this.enLargeVideoInfo.isFocusedGuide = true
      } else {
        this.enlargeVideo(index)
        this.enLargeVideoInfo.isFocusedGuide = true
      }
      this.sendFocusGuidanceMsg(imUserId, isFocus)
    },
    focusGuidanceLocalFinish(imUserId, isFocus) {
      this.isFocusGuidance = isFocus
      if (this.enLarge) {
        this.enLargeVideoInfo.isFocusedGuide = false
      }
      rtc.deleteRoomAttributes(this.room, this.focusedGuidanceKeys).then(() => {
        this.focusedGuidanceInfo = null
      })
      this.sendFocusGuidanceMsg(imUserId, isFocus)
      // this.sendPaintDrawingMsg(undefined, false, this.enLargeVideoInfo.imUserId)
      // this.sendLaserMovingMsg(undefined, false, this.enLargeVideoInfo.imUserId)
    },
    focusGuidanceByRemoteFun(e) {
      const detail = e.detail
      if (detail.isFocused) {
        rtc.getRoomAttributes(this.room, this.focusedGuidanceKeys).then(data => {
          this.focusedGuidanceInfo = data
          if (this.isExpert(this.userInfo)) {
            this.isFocusGuidance = detail.isFocused
            const index = this.remoteUserList.findIndex(item => item.imUserId === this.focusedGuidanceInfo.focusedUserId)
            if (index !== -1) {
              this.enlargeVideo(index)
              this.enLargeVideoInfo.isFocusedGuide = true
            }
          } else if (this.focusedGuidanceInfo.focusedUserId === this.userImInfo.imUserId) {
            this.isFocusedGuidanceByRemote = detail.isFocused
          }
        })
      } else {
        this.isFocusGuidance = false
        this.focusedGuidanceInfo = null
        this.isFocusedGuidanceByRemote = false
        if (this.enLarge) {
          this.enLargeVideoInfo.isFocusedGuide = false
        }
        if (this.isExpert(this.userInfo)) {
          // 多现场会议中，取消聚焦，缩小窗口
          const staffCount = this.remoteStaffList.length
          if (staffCount > 1) {
            this.zoomVideo()
          }
        }
      }
    }
  }
}
