<template>
  <div class="external-call-container" style="height: 100%">
    <el-tabs v-model="activeName" class="menu-tab" type="border-card" style="height: 100%" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.label" :name="item.value" />
      <div v-show="isLoading" v-loading="isLoading" class="loading" />
      <contract-list v-if="activeName === 'Contracts'" />
      <meeting-records v-else-if="activeName === 'Records'" />
    </el-tabs>
    <user-info :app-id="appId" :outer-id="outerId" :user-name="userName" />
  </div>
</template>

<script>
import Connected from '@/assets/image/newRemoteGuidance/externalCall/connected.png'
import Disconnected from '@/assets/image/newRemoteGuidance/externalCall/disconnected.png'
import AvatarBlue from '@/assets/image/newRemoteGuidance/avatar_blue.jpg'
import ContractList from '@/views/newRemoteGuidance/contactList/list'
import MeetingRecords from '@/views/newRemoteGuidance/meetingRecords/list'
import UserInfo from '../sinoma/userInfo'
import { mapGetters } from 'vuex'
import { UserTypeEnum } from '@/views/newRemoteGuidance/var/businessVar'
import { oneStopLoginComFun } from '@/views/newRemoteGuidance/js/extarnalLogin'

export default {
  name: 'Index',
  components: { ContractList, MeetingRecords, UserInfo },
  props: {
    appId: { type: [Number, String], default: undefined },
    outerId: { type: [Number, String], default: undefined },
    userName: { type: String, default: undefined }
  },
  data() {
    return {
      Connected, Disconnected, AvatarBlue,
      activeName: '',
      isLoading: true,
      tabs: [
        { label: '联系人', value: 'Contracts' },
        { label: '会议记录', value: 'Records' }
      ],
      loginParams: {
        sign: undefined,
        appId: undefined,
        action: 'login',
        loginType: 'one_stop_login'
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'imIsConnected', 'isBeingInvited'])
  },
  created() {
    this.init()
  },
  mounted() {
    window.parent.postMessage({ type: 'MeetingFrameLoaded', params: { minWidth: '1400px', minHeight: '700px' }}, '*')
  },
  methods: {
    init() {
      if (this.$route.params) {
        const query = this.$route.query
        if (this.imIsConnected) {
          this.activeName = 'Records'
          this.activeName = query.from && query.from === 'externalCallCommonMeetingRecordsView' ? 'Records' : 'Contracts'
        } else {
          this.isLoading = true
          oneStopLoginComFun(this.appId, this.outerId, this.userName).then(() => {
            this.activeName = 'Contracts'
            this.isLoading = false
          }).catch(() => {
            this.isLoading = false
          })
        }
      }
    },
    getUserRole(key) {
      return UserTypeEnum[key]
    },
    handleClick() {

    }
  }
}
</script>

<style scoped lang="scss">
.external-call-container{
  position: relative;
  .menu-tab{
    ::v-deep .el-tabs__item{
      height: 50px !important;
      line-height: 50px !important;
    }
  }
  .loading{
    width: 100%;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}
</style>
