var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "equipment-card",
    },
    [
      _c("div", { staticClass: "item name" }, [
        _vm._v(
          "设备名称：" +
            _vm._s((_vm.equipmentInfo && _vm.equipmentInfo.name) || "-")
        ),
      ]),
      _vm._v(" "),
      _vm.equipmentInfo && _vm.equipmentInfo.isWhole
        ? _c("div", { staticClass: "item code" }, [
            _vm._v(
              "产权编号：" +
                _vm._s((_vm.equipmentInfo && _vm.equipmentInfo.code) || "-")
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "item specModel" }, [
        _vm._v(
          "规格型号：" +
            _vm._s((_vm.equipmentInfo && _vm.equipmentInfo.specModel) || "-")
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }