var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-container" }, [
    _c(
      "video",
      {
        ref: "videoPlayer",
        staticClass: "video",
        attrs: { controls: "" },
        on: { ended: _vm.exitFullscreen, click: _vm.clickHandler },
      },
      [_c("source", { attrs: { src: _vm.src, type: "video/mp4" } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }