<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: { type: String, default: 'circle' },
    title: { type: String, default: '' },
    extraClass: { type: String, default: '' }
  },
  render(h, context) {
    const { icon, title, extraClass } = context.props
    const vnodes = []

    if (icon) {
      vnodes.push(<svg-icon icon-class={icon} extra-class={extraClass}/>)
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>
