<template>
  <div class="step-ui-detail-container" :style="{height: height}">
    <div class="view-container">
      <div class="top-section">
        <div class="view-title top-title">{{ results.name || '维修记录' }}</div>
        <div class="top-button-container">
          <el-button class="top-title-button more-function-button" type="text" @click="moreFunction">更多功能</el-button>
          <div v-if="showMoreFunction" class="more-function-container">
            <img :src="Triangle" alt="" class="more-function-img">
            <el-button class="top-title-button guidance-button" type="text" @click="toRemoteGuidance">远程指导</el-button>
            <el-button class="top-title-button ai-button" type="text" @click="toAiQaAssistant">问答助手</el-button>
          </div>
        </div>
      </div>
      <div class="middle-section">
        <div class="full-line-container">
          <div class="repair-code content-item">
            <button class="name"> <span class="required-item">*</span>故障代码：</button>
            <el-select v-model="results.code" size="medium" class="repair-select" placeholder="请选择故障代码">
              <el-option
                v-for="(item, index) in codes"
                :key="index"
                :label="item"
                :value="item"
                class="repair-option"
              />
            </el-select>
          </div>
          <div class="repair-suggest content-item">
            <button class="name">维修建议：</button>
            <el-select v-model="results.suggest" size="medium" class="repair-select" placeholder="请选择维修建议">
              <el-option
                v-for="(item, index) in suggests"
                :key="index"
                :label="item"
                :value="item"
                class="repair-option"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="bottom-section">
        <div class="top-container">
          <div class="left-container">
            <div class="title-container">
              <el-button
                class="sub-title-button photo-button"
                type="text"
                @click="nativeTakePhoto"
              ><span class="required-item">*</span>拍摄</el-button>
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length > 0"
                class="sub-title-button del-button"
                type="text"
                @click="delPhoto"
              >删除</el-button>
            </div>
            <div class="img-container">
              <dw-carousel ref="customCarousel" height="140px" :autoplay="false" arrow="always" :show-icon="false" @change="setActiveItem">
                <dw-carousel-item v-for="(item, index) in submitResults.uploadPhotos" :key="index">
                  <dw-el-image :ref="`result${index}`" :src="item" alt="" class="result-img" :preview-src-list="results.uploadPhotos" :show-icon="false" />
                </dw-carousel-item>
              </dw-carousel>
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length > 0"
                class="sub-title-button fullscreen-button"
                @click="fullScreenPhoto"
              >查看照片</el-button>
            </div>
          </div>
          <div class="right-container">
            <div class="title-container">
              <el-button class="sub-title-button fact-button" type="text" @click="inputDesc">补充维修方法</el-button>
              <el-button class="sub-title-button clear-button" type="text" @click="clearFactDesc">清空</el-button>
            </div>
            <el-input
              ref="rectDescRef"
              v-model="submitResults.factDesc"
              placeholder="补充维修方法"
              style="width: 100%; height: 100%"
              type="textarea"
              class="check-standard"
            />
          </div>
        </div>
        <div class="bottom-container">
          <el-button v-if="!isLastStep" class="save-button bottom-button" type="text" @click="continueHandle">{{ `继续处理（未处理${count - currentIndex - 1}）` }}</el-button>
          <el-button v-if="isLastStep" class="submit-button bottom-button" type="text" @click="submitRecord">全部处理完成</el-button>
        </div>
      </div>
    </div>
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/templateMgr/components/stepUiMixin'
export default {
  name: 'StepUiVue',
  mixins: [StepUiMixin],
  props: {
    properties: { type: Object, default() { return {} } },
    results: {
      type: Object,
      default() {
        return {
          latitude: null,
          longitude: null,
          location: null
        }
      }
    }
  },
  data() {
    return {
      codes: [111, 222, 3333],
      suggests: ['当选项过多时，使用下拉菜单展示并选择内容', '小垃圾使用下拉菜单展示并选择内容', 'v-model的值为当前被选中的el-option的 value 属性值', '']
    }
  },
  methods: {
    continueHandle() {
      this.$emit('continue-handle', this.submitResults)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/taskMgr/templateMgr/components/stepUiCommonStyle";
.step-ui-detail-container{
  .view-container{
    .top-section{
      position: relative;
      .view-title{
        margin-right: 0;
        text-align: center;
      }
      .top-button-container{
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }
    .full-line-container{
      .content-item{
        font-size: 1.3rem;
        height: 40px;
        display: flex;
        align-items: center;
        margin: 10px 0;
          .name{
            border: none;
            background: none;
            text-align: right;
            width: 130px;
          }
        .repair-select {
          flex: 1;
          height: 40px;
          line-height: 40px;
          font-size: 1.3rem;
          ::v-deep .el-input {
            height: 40px;
          }
          ::v-deep .el-input--suffix .el-input__inner {
            height: 40px !important;
            line-height: 40px !important;
            font-size: 1.3rem !important;
          }
          ::v-deep .el-input__suffix .el-input__icon {
            font-size: 1.3rem !important;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.repair-option{
  height: 40px;
  line-height: 40px;
  font-size: 1.3rem;
  span{
    font-size: 1.3rem;
  }
}
</style>
