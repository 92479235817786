<template>
  <div class="project-statistics-container">
    <div class="single-statistical-card-container">
      <single-statistical-card v-for="(item, index) in projectStatistics" :key="index" :title="item.statusName" :count="item.projectCount" />
    </div>
    <div v-if="projectList.length > 0 || projectProgressCount > 0" class="statistics-chart-container">
      <el-row :gutter="24">
        <el-col :span="12">
          <dw-statistic-chart :is-loading="isProgressBarChartLoading" :title="`工程进度统计（共${projectList.length}个）`" :box-style="{width: '100%'}">
            <template v-slot:statistic>
              <el-button type="primary" @click="openProjectProgressDialog">查看全部</el-button>
            </template>
            <template v-slot:chart>
              <project-progress-bar-chart ref="projectProgressBarChart" v-loading="isProgressBarChartLoading" height="300px" />
            </template>
          </dw-statistic-chart>
        </el-col>
        <el-col :span="12">
          <dw-statistic-chart :is-loading="isProgressPieChartLoading" title="工程分阶段完成情况统计" :box-style="{width: '100%'}">
            <template v-slot:chart>
              <project-progress-pie-chart ref="projectProgressPieChart" v-loading="isProgressPieChartLoading" :count="projectProgressCount" height="300px" />
            </template>
          </dw-statistic-chart>
        </el-col>
      </el-row>
    </div>
    <ht-dialog ref="projectProgressListDialog" title="工程进度统计" width="700px" @close="closeProjectProgressDialog">
      <div slot="content">
        <ht-table ref="list" :data="projectList" style="cursor: pointer">
          <ht-table-column label="序号" type="index" fixed="left" />
          <ht-table-column
            v-for="item in listTitle"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :min-width="item.width || 100"
            :fixed="item.fixed"
          >
            <template v-slot="{row}">
              <template v-if="item.label === '工程进度'">
                <el-progress v-if="row[item.prop] || row[item.prop] === 0" :percentage="row[item.prop]" :show-text="!row.isFinished" style="width: 95%" :color="row.isFinished ? '#e6ebf5' : '#2e5aff'" />
                <template v-else>-</template>
              </template>
              <span v-else>{{ row[item.prop] || '-' }}</span>
            </template>
          </ht-table-column>
        </ht-table>
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import SingleStatisticalCard from '@/views/newDashboard/components/card/singleStatisticalCard'
import ProjectProgressPieChart from '@/views/constructionProjectMgr/projectMgr/components/projectProgressChart'
import ProjectProgressBarChart from '@/views/newDashboard/components/chart/projectProgressChart'
import { getProjects, getProjectsPieStatistic } from '@/api/constructionProjectMgr/projectMgr'
import { getProjectLeaseStatistics } from '@/api/newDashboard/dashboard'

export default {
  name: 'ProjectStatistics',
  components: {
    SingleStatisticalCard,
    ProjectProgressPieChart,
    ProjectProgressBarChart
  },
  data() {
    return {
      projectStatistics: [
        { name: '工程总数', value: 0 },
        { name: '租赁中', value: 0 },
        { name: '未租赁', value: 0 },
        { name: '租赁已结束', value: 0 }
      ],
      isProgressPieChartLoading: false,
      isProgressBarChartLoading: false,
      projectProgressCount: 0,
      projectList: [],
      listTitle: [
        { label: '工程名称', prop: 'name', width: 200 },
        { label: '工程进度', prop: 'progressRatio', width: 120, fixed: 'right' }
      ]
    }
  },
  created() {
    this.getProjectLeaseStatistics()
    this.getProgressBarStatistical()
    this.getProgressPieStatistical()
  },
  methods: {
    getProjectLeaseStatistics() {
      this.isLeaseStatisticsLoading = true
      getProjectLeaseStatistics().then(resp => {
        this.isLeaseStatisticsLoading = false
        this.projectStatistics = resp
      }).catch(() => {
        this.isLeaseStatisticsLoading = false
      })
    },
    getProgressBarStatistical() {
      const query = { offset: 0, count: 100, isFinished: false }
      this.isProgressBarChartLoading = true
      getProjects(query).then((resp) => {
        resp.forEach(item => {
          item.progressRatio = parseFloat((item.progressRatio * 100).toFixed(2))
        })
        this.projectList = resp
        const names = resp.map(item => { return item.name }).reverse()
        const ratios = resp.map(item => { return item.progressRatio }).reverse()
        if (ratios.length > 0) {
          this.$nextTick(() => {
            this.$refs.projectProgressBarChart.getData(names, ratios)
          })
        }
        this.isProgressBarChartLoading = false
      }).catch(() => {
        this.isProgressBarChartLoading = false
      })
    },
    getProgressPieStatistical() {
      this.projectProgressCount = 0
      this.isProgressPieChartLoading = true
      getProjectsPieStatistic({ isFinished: false }).then(resp => {
        resp.forEach(item => {
          this.projectProgressCount += item.projectCount
        })
        if (this.projectProgressCount > 0) {
          this.$nextTick(() => {
            this.$refs.projectProgressPieChart.getData(resp, this.projectProgressCount)
          })
        }
        this.isProgressPieChartLoading = false
      }).catch(() => {
        this.isProgressPieChartLoading = false
      })
    },
    openProjectProgressDialog() {
      this.$refs.projectProgressListDialog.dialogVisible = true
    },
    closeProjectProgressDialog() {
      this.$refs.projectProgressListDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
.project-statistics-container{
  .single-statistical-card-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
  }
}
</style>
