var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticStyle: { "min-height": "400px" },
    },
    [
      _vm.equipmentData
        ? _c("equipment-form", {
            attrs: {
              "is-view": "",
              "is-edit": _vm.isEdit === "1" || _vm.isEdit === 1,
              "equipment-id": _vm.equipmentData.id,
              "equipment-data": _vm.equipmentData,
            },
            on: {
              "cancel-submit": _vm.cancelSubmit,
              "confirm-submit": _vm.confirmSubmit,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "18px" },
          attrs: { "label-width": "90px" },
        },
        [_c("el-form-item", { attrs: { label: "租用详情" } })],
        1
      ),
      _vm._v(" "),
      _c("lease-detail-list", {
        attrs: {
          "lease-detail-list":
            _vm.equipmentData && _vm.equipmentData.rentalDetails,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }