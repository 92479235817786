var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-list-container" },
    [
      _vm.userList.length > 0
        ? _vm._l(_vm.userList, function (user, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "user-container",
                class: {
                  "online-user-container": user.isOnline,
                  "offline-user-container": !user.isOnline,
                  "in-meeting-user-container": user.isInGuidance,
                },
              },
              [
                user.avatarUrl
                  ? _c("el-image", {
                      staticClass: "avatar",
                      attrs: { src: user.avatarUrl || _vm.AvatarBlue },
                    })
                  : _c("dw-default-avatar", {
                      staticStyle: { "margin-left": "25px" },
                      attrs: {
                        "full-name": user.fullName,
                        width: 80,
                        "font-size": 26,
                        background: user.isOnline
                          ? _vm.variables.primaryColor
                          : "#eeeeee",
                        color: user.isOnline ? "#ffffff" : "#aaaaaa",
                      },
                    }),
                _vm._v(" "),
                _c("span", { staticClass: "fullName text" }, [
                  _vm._v(_vm._s("" + user.fullName)),
                  !_vm.stringLongOverTen(user.userName)
                    ? _c("span", { staticClass: "userName" }, [
                        _vm._v(_vm._s("（" + user.userName + "）")),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "role text" }, [
                  _vm._v(_vm._s(user.roleNames[0])),
                ]),
                _vm._v(" "),
                user.isInGuidance
                  ? _c(
                      "span",
                      { staticClass: "in-meeting-status text status" },
                      [_vm._v("会议中")]
                    )
                  : user.isOnline
                  ? _c("span", { staticClass: "online-status text status" }, [
                      _vm._v("在线"),
                    ])
                  : _c("span", { staticClass: "offline-status text status" }, [
                      _vm._v("离线"),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "operation-container" },
                  [
                    user.isOnline & !user.isInGuidance
                      ? [
                          _c(
                            "div",
                            { staticClass: "call-container" },
                            [
                              _c("svg-icon", {
                                staticClass: "call-icon",
                                attrs: { "icon-class": "call" },
                                on: {
                                  click: function ($event) {
                                    return _vm.call(user)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-checkbox", {
                            staticClass: "checkbox",
                            model: {
                              value: user.checked,
                              callback: function ($$v) {
                                _vm.$set(user, "checked", $$v)
                              },
                              expression: "user.checked",
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          })
        : [
            _c("div", { staticClass: "empty-container" }, [
              _c("img", {
                staticClass: "empty-img",
                attrs: { src: _vm.Empty, alt: "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "empty-text" }, [
                _vm._v("暂无在线用户"),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }