<template>
  <el-card>
    <ht-action-panel :on-clear="onClearSearch" :on-search="onSearch">
      <template slot="left">
        <el-select v-model="listQuery.type" placeholder="工单类型">
          <el-option v-for="(item,index) in getTaskTempTypeEnum()" :key="index" :label="item.value" :value="item.name" />
        </el-select>
        <el-input v-model="listQuery.code" placeholder="工单编号" />
        <el-input v-model="listQuery.name" placeholder="工单名称" />
        <!--        <el-select v-model="listQuery.targetName" placeholder="整机设备">-->
        <!--          <el-option v-for="(item,index) in EquipmentTypesEnum.filter(item => item.isWhole)" :key="index" :label="item.value" :value="item.name" />-->
        <!--        </el-select>-->
        <el-input v-model="listQuery.targetCode" placeholder="整机产权编号" />
        <el-date-picker v-model="createdAt" end-placeholder="创建时间" placement="bottom-start" range-separator="至" start-placeholder="创建时间" type="daterange" value-format="yyyy-MM-dd" />
        <el-date-picker v-model="finishedAt" end-placeholder="完成时间" placement="bottom-start" range-separator="至" start-placeholder="完成时间" type="daterange" value-format="yyyy-MM-dd" />
        <el-select v-model="listQuery.status" placeholder="状态">
          <el-option v-for="(value,key ) in TaskWorkOrderStatusEnum" :key="key" :label="value" :value="key" />
        </el-select>
        <el-select v-model="listQuery.targetName" placeholder="整机设备">
          <el-option v-for="(item,index) in EquipmentTypesEnum.filter(item => item.isWhole)" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer" @row-click="handleRowClick">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in MyWorkOrderListTitle"
        :key="item.prop"
        :label="item.label"
        :min-width="item.width || 100"
        :prop="item.prop"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '工单类型'">{{ getTaskTempType(row[item.prop]) ? getTaskTempType(row[item.prop]).value : '巡检' }}</span>
          <span v-else-if="item.label === '创建人'">{{ row.staff.fullName }}</span>
          <span v-else-if="item.label === '执行人'">{{ row.executor.fullName }}</span>
          <span v-else-if="item.label === '循环周期'">{{ LoopEnum[row[item.prop]] }}</span>
          <span v-else-if="item.label === '异常项'" :style="{color: row[item.prop] ? 'red' : ''}">{{ `${row[item.prop]}项` }}</span>
          <el-tag v-else-if="item.label === '状态'" :type="TaskWorkOrderStatusStyleEnum[row[item.prop]]">{{ TaskWorkOrderStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="80">
        <template v-slot="{row}">
          <ht-button icon="el-icon-view" type="text" @click="onView(row)">详情</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      :limit.sync="listQuery.perPage"
      :page.sync="listQuery.page"
      :total="page.total"
      style="float: right;margin-bottom: 10px"
      @pagination="getList"
    />
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getWorkOrders } from '@/api/taskMgr/workOrder'
import { MyWorkOrderListTitle } from '@/views/taskMgr/tableTitle'
import { LoopEnum, TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum } from '@/views/taskMgr/enum'
import { getLocation, getValueRecordResult, getResultTextColor } from '@/utils/get-display'
import { mapGetters } from 'vuex'
import { getTaskTempType, getTaskTempTypeEnum } from '@/views/taskMgr/commonJs'
import { EquipmentTypesEnum } from '@/views/constructionEquipmentMgr/enum'

export default {
  name: 'Index',
  extends: HtListContainer,
  data() {
    return {
      EquipmentTypesEnum,
      MyWorkOrderListTitle,
      LoopEnum,
      TaskWorkOrderStatusEnum,
      TaskWorkOrderStatusStyleEnum,
      createdAt: [],
      finishedAt: []
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getResultTextColor, getValueRecordResult, getLocation, getTaskTempType, getTaskTempTypeEnum,
    onClearSearch() {
      this.createdAt = []
      this.finishedAt = []
      this.onClear()
    },
    getList() {
      this.listQuery.createdStartAt = this.createdAt[0]
      this.listQuery.createdEndAt = this.createdAt[1]
      this.listQuery.finishedStartAt = this.finishedAt[0]
      this.listQuery.finishedEndAt = this.finishedAt[1]
      this.listQuery.executorId = this.userInfo.id
      this.beforeGetList()
      getWorkOrders(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    handleRowClick(row) {
      this.onView(row)
    },
    onView(row) {
      if (row.type === 'PATROL') {
        this.$router.push({
          name: 'myPatrolWorkOrderMgrView',
          params: { orderId: row.id, isMy: true }
        })
      } else {
        this.$router.push({
          name: 'myRepairWorkOrderMgrView',
          params: { orderId: row.id, isMy: true }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}
</style>
