<template>
  <div class="view-container">
    <div class="top-section">
      <div class="view-title top-title">上架-确认上架箱号</div>
    </div>
    <div class="result-container" :style="{height: height + 'px'}">
      <div class="result-top-container">
        <div class="result-top-left-container" title="hf_use_description">
          <el-button class="result-button" type="text" :disabled="buttonDisabled" title="仓库" @click="handleActions('choose-warehouse')">
            <span class="required-item">*</span>仓库:
          </el-button>
          <el-input ref="rectDescRef" v-model="submitResults.warehouseName" placeholder="请选择仓库" class="result-input" disabled />
        </div>
        <div class="result-top-right-container" title="hf_use_description">
          <el-button class="result-button" type="text" :disabled="buttonDisabled" title="小车号" @click="handleActions('scan-car-bar-code')">
            <span class="required-item">*</span>小车号:
          </el-button>
          <el-input ref="rectDescRef" v-model="submitResults.carCode" placeholder="请扫码" class="result-input" disabled>
            <svg-icon slot="append" icon-class="scan" />
          </el-input>
        </div>
      </div>
      <div class="result-bottom-container">
        <div class="result-middle-top-container">
          <span class="title txt">待上架箱号：</span>
          <el-button type="primary" :disabled="buttonDisabled" title="扫描箱号" @click="handleActions('scan-box-bar-code')">扫描箱号</el-button>
          <span class="count txt">待上架数：{{ getUnboundBoxes.length }}</span>
        </div>
        <div class="result-middle-bottom-container">
          <div v-for="(item, index) in getUnboundBoxes" :key="index" class="box-item">
            箱号：{{ item.code }}
            <el-button class="del-button" type="text" :disabled="buttonDisabled" @click="delBox(item.code)">删除{{ index + 1 }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <el-button class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-inbound')">去上架</el-button>
    </div>
    <custom-dialog ref="chooseItemDialog" :title="`选择仓库`" width="500px" color="#2e5aff" @close="closeChooseItemDialog">
      <template slot="content">
        <el-radio-group v-model="chooseItem" @change="closeChooseItemDialog">
          <el-radio v-for="(item, key) in chooseItems" :key="key" :label="item">{{ item.name }}</el-radio>
        </el-radio-group>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import CustomDialog from '@/views/taskMgr/newTemplateMgr/components/customDialog'
import { WarehouseList } from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/templateData'

export default {
  name: 'ConfirmBoxInbound',
  components: { CustomDialog },
  props: {
    height: { type: Number, default: 480 },
    results: { type: Object, default() {
      return {
        boxes: [],
        warehouse: null,
        warehouseName: null,
        carCode: null
      }
    } }
  },
  data() {
    return {
      buttonDisabled: this.disabled,
      submitResults: this.results,
      chooseItem: null,
      chooseItems: WarehouseList
    }
  },
  computed: {
    getUnboundBoxes() {
      return this.submitResults.boxes && this.submitResults.boxes.filter(item => !item.isInbound) || []
    }
  },
  watch: {
    results: {
      handler: function() {
        this.submitResults = this.results
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleActions(action) {
      switch (action) {
        case 'choose-warehouse':
          this.openChooseItemDialog()
          break
        case 'scan-car-bar-code':
          this.$emit('to-scan-code', 'car', this.submitResults)
          break
        case 'scan-box-bar-code':
          this.$emit('to-scan-code', 'box', this.submitResults)
          break
        case 'to-inbound':
          if (this.submitResults.warehouse && this.submitResults.carCode && this.getUnboundBoxes.length > 0) {
            this.$emit('to-inbound', 'box', this.submitResults)
            break
          }
          if (!this.submitResults.warehouse) {
            this.$message({ message: `请选择仓库`, duration: 2000, type: 'error', customClass: 'large-info-message' })
            break
          }
          if (!this.submitResults.carCode) {
            this.$message({ message: `请扫描小车号`, duration: 2000, type: 'error', customClass: 'large-info-message' })
            break
          }
          if (this.getUnboundBoxes.length === 0) {
            this.$message({ message: `请扫描待上架箱号`, duration: 2000, type: 'error', customClass: 'large-info-message' })
            break
          }
          break
      }
    },
    delBox(code) {
      this.$emit('del-box', code)
    },
    openChooseItemDialog() {
      this.$refs.chooseItemDialog.dialogVisible = true
      if (this.submitResults.warehouse && this.submitResults.warehouse.id) {
        const warehouseIndex = this.chooseItems.findIndex(item => item.id === this.submitResults.warehouse.id)
        if (warehouseIndex !== -1) {
          this.chooseItem = this.chooseItems[warehouseIndex]
        }
      }
      this.buttonDisabled = true
    },
    closeChooseItemDialog() {
      if (this.chooseItem) {
        this.submitResults.warehouse = this.chooseItem
        this.submitResults.warehouseName = this.chooseItem.name
      }
      this.$refs.chooseItemDialog.dialogVisible = false
      this.buttonDisabled = false
      this.$emit('choose-warehouse', this.submitResults)
    },
    getTtsText() {
      if (this.submitResults.warehouseName && this.submitResults.carCode) {
        if (this.getUnboundBoxes.length > 0) {
          return `当前待上架数量为${this.getUnboundBoxes.length}`
        } else if (this.submitResults.boxes.length > 0) {
          return `当前无待上架物品，请选择扫描箱号继续上架或选择完成上架结束任务`
        } else {
          return '请扫描箱号'
        }
      }
      if (!this.submitResults.warehouseName && !this.submitResults.carCode) {
        return '请选择仓库并扫描小车号'
      }
      if (!this.submitResults.warehouseName) {
        return '请选择仓库'
      }
      if (!this.submitResults.carCode) {
        return '请扫描小车号'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";

.view-container{
  .result-container{
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .result-top-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0 0;
      .result-top-left-container, .result-top-right-container{
        flex: 1;
        display: flex;
        flex-direction: row;
        .result-button{
          font-size: 1.5rem;
          color: #000000;
          font-weight: bold;
          padding: 0 10px 0 0 ;
        }
      }
      .result-top-left-container{
        margin-right: 10px;
      }
      .result-input{
        flex: 1;
      }
      ::v-deep .el-input__inner{
        height: 100%;
        color: #000000;
        font-size: 1.3rem;
        border: none;
        background: #ffffff;
        line-height: normal;
      }
      ::v-deep .el-input-group__append{
        height: 100%;
        color: #000000;
        font-size: 1.5rem;
        border: none;
        background: #ffffff;
        line-height: normal;
        .svg-icon{
          color: #aaaaaa;
        }
      }
    }
    .result-bottom-container{
      flex: 1;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding: 10px;
      overflow: hidden;
      .result-middle-top-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .txt{
          font-size: 1.3rem;
        }
        .count{
          flex: 1;
          text-align: right;
          margin-left: 10px;
          color: #666666;
        }
        .el-button {
          height: 35px;
          color: #ffffff;
          background: $mobilePrimaryColor;
          border-radius: 5px;
          font-size: 1.3rem;
        }
      }
      .result-middle-bottom-container{
        overflow: hidden;
        margin: 10px 20px 0;
        .box-item{
          //flex: 0 0 50%; /* 子元素占据容器宽度的50% */
          font-size: 1.2rem;
          padding: 10px 0;
          .del-button{
            margin-left: 20px;
            color: #f56c6c;
          }
        }
      }
    }
  }

  .bottom-container{
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    .square-circle-button {
      flex: 1;
      height: 40px;
      color: #ffffff;
      background: $mobilePrimaryColor;
      border-radius: 5px;
      font-size: 1.5rem;
    }
  }
}

.el-radio-group{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 30px;
  .el-radio{
    flex-basis: 50%;
    margin-right: 0;
    font-size: 1.3rem;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ::v-deep .el-radio__input{
      font-size: 1.3rem;
      .el-radio__inner{
        font-size: 1.3rem;
      }
      .el-radio__inner{
        width: 20px;
        height: 20px;
      }
    }
    ::v-deep .el-radio__label{
      font-size: 1.3rem;
    }
  }
}
</style>
