<template>
  <div class="outbound-container">
    <el-form ref="outboundForm" class="mobile-form" :model="outboundForm" :rules="outboundFormRules" label-width="110px">
      <el-form-item label="出库单：" prop="attachmentData">
        <dw-file
          v-if="outboundForm.attachmentData"
          :url="outboundForm.attachmentData"
          show-del
          justify-content="left"
          style="width: 100%"
          @delete-file="outboundForm.attachmentData = null"
        />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传出库单" button-type="primary" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
      <el-form-item label="收货方：" prop="constructionProjectId">
        <el-select
          v-model="outboundForm.constructionProjectId"
          filterable
          :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'"
          style="width: 100%"
          popper-class="mobile-select"
          remote
          :remote-method="getProjects"
          @change="handleSelectProject"
        >
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="title"><span>出货仓库：</span>{{ warehouseName || '-' }}</div>
    <template v-if="outboundForm.constructionProjectId">
      <div
        v-for="(item,index) in equipmentList"
        :key="index"
        class="equipment-card"
        :class="{'selected-equipment-card': item.outboundQuantity > 0, 'disabled-equipment-card': item.quantity === 0}"
      >
        <div class="top-item">设备：{{ item.name }} {{ item.code ? `(${item.code })` : '' }}</div>
        <div class="bottom-item">
          <div class="bottom-left-item">
            <div class="top">规格型号：{{ item.specModel || '-' }}</div>
            <span>库存数：{{ item.quantity }}</span>
          </div>
          <div class="bottom-right-item">
            <span class="top">出库数量</span>
            <el-input-number v-model="item.outboundQuantity" :min="0" placeholder="请输入出库数量" :precision="0" />
          </div>
        </div>
      </div>
      <div v-if="equipmentList.length === 0 && !isEquipmentLoading" class="empty-list">
        <img :src="Empty" class="empty-img">
        <span class="empty-info">没有设备</span>
      </div>
      <div v-if="isEquipmentLoading" class="loading-spinner"><img class="loading-img" :src="Loading" alt="">数据加载中...</div>
    </template>
    <div class="bottom-container">
      <el-button round icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button round :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认出库</el-button>
    </div>
  </div>
</template>

<script>
import { getEquipmentStocks, putWarehouseStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import { getProjects } from '@/api/constructionProjectMgr/projectMgr'
import Empty from '@/assets/image/mobile/mobile_empty.png'
import Loading from '@/assets/image/mobile/mobile_loading.png'
export default {
  name: 'WarehouseOutbound',
  props: {
    warehouseId: { type: [String, Number], default: null },
    warehouseName: { type: String, default: null }
  },
  data() {
    return {
      Loading, Empty,
      outboundForm: {
        action: 'outbound',
        warehouseId: null,
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: null
      },
      outboundFormRules: {
        warehouseId: [{ required: true, message: '请选择出货仓库' }],
        constructionProjectId: [{ required: true, message: '请选择收货方（工程方）', trigger: 'change' }]
        // attachmentData: [{ required: true, message: '请上传出库单', trigger: 'change' }]
      },
      warehouseInfo: null,
      isLoading: false,
      projectList: [],
      isProjectLoading: false,
      equipmentIds: [],
      equipmentList: [],
      isEquipmentLoading: false,
      isSubmitting: false
    }
  },
  created() {
    this.getProjects()
  },
  methods: {
    getProjects(value) {
      const query = {
        offset: 0,
        count: 20,
        keyword: value
      }
      getProjects(query).then(resp => {
        this.projectList = resp
        this.isProjectLoading = false
      })
    },
    handleSelectProject(value) {
      this.equipmentIds = []
      const index = this.projectList.findIndex(item => item.id === value)
      if (index !== -1) {
        this.equipmentIds = this.projectList[index].equipmentItems.filter(item => item.status === 'UNDER_LEASED').map(item => { return item.constructionEquipmentId })
        const query = {
          warehouseId: this.warehouseId,
          constructionEquipmentIds: [...new Set(this.equipmentIds)].toString() || -1
        }
        this.equipmentList = []
        this.isEquipmentLoading = true
        getEquipmentStocks(query).then(resp => {
          resp.forEach(item => {
            item.outboundQuantity = 0
          })
          this.equipmentList = resp
          this.isEquipmentLoading = false
        }).catch(() => { this.isEquipmentLoading = false })
      } else {
        this.equipmentIds = []
        this.equipmentList = []
      }
    },
    onFileUploaded(context, url) {
      this.outboundForm.attachmentData = url
      if (this.outboundForm.attachmentData) {
        this.$refs.outboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    confirmSubmit() {
      const outboundForm = this.getOutboundForm()
      this.$refs.outboundForm.validate(valid => {
        if (valid) {
          if (outboundForm.equipmentItems.some(item => item.quantity > 0)) {
            this.isSubmitting = true
            putWarehouseStocks(outboundForm).then(() => {
              this.$message({ message: `出库成功`, type: 'success', center: true })
              this.$router.go(-1)
              this.isSubmitting = false
            }).catch(() => { this.isSubmitting = false })
          } else {
            this.$message({ message: `请选择设备并输入出库数量`, type: 'error', center: true })
            return false
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getOutboundForm() {
      const data = {
        action: 'outbound',
        warehouseId: null,
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: []
      }
      data.warehouseId = this.warehouseId
      data.constructionProjectId = this.outboundForm.constructionProjectId
      console.log(this.equipmentList)
      data.equipmentItems = this.equipmentList.map(item => {
        return {
          constructionEquipmentId: item.id,
          constructionEquipmentName: item.name,
          constructionEquipmentCode: item.code,
          quantity: item.outboundQuantity,
          isWhole: item.isWhole
        }
      })
      data.attachmentData = this.outboundForm.attachmentData ? [this.outboundForm.attachmentData] : []
      return data
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';

.outbound-container{
  margin: 15px 15px 100px;
  font-size: 1rem;

  .title{
    font-size: 1.2rem;
    font-weight: bold;
    color: $primaryColor;
    margin: 20px 0;
    display: flex;
    align-items: center;
    span{
      width: 110px;
      padding-right: 12px;
      text-align: right;
    }
  }

  ::v-deep .el-button{
    font-size: 1rem;
  }

  .equipment-card{
    color: #666666;
    font-size: 1rem;
    background: #ffffff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    margin-bottom: 10px;
    .top-item{
      font-size: 1.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
      font-weight: bold;
      color: #000000;
    }
    .bottom-item{
      display: flex;
      flex-direction: row;
      .bottom-left-item{
        flex: 1;
        justify-content: flex-end;
      }
      .bottom-right-item {
        align-items: center;
      }
    }
    .bottom-item>div{
      display: flex;
      flex-direction: column;
      .top{
        margin-bottom: 10px;
      }
    }
  }

  .equipment-card>div{
    margin-bottom: 10px;
  }
  .equipment-card>div:last-child{
    margin-bottom: 0;
  }

  .selected-equipment-card{
    background: #f0f9eb;
  }

  .disabled-equipment-card{
    background: #f4f4f5;
  }
}

.mobile-form{
  ::v-deep .el-form-item{
    margin-bottom: 10px;
    .el-form-item__label{
      text-align: right !important;
    }
  }
}

</style>
