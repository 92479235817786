<template>
  <div v-if="buttons.length > 0">
    <template v-if="buttons[0]">
      <ht-popover-button
        v-if="buttons[0].isPopoverButton"
        :disabled="buttons[0].disabled"
        type="text"
        :danger-text="buttons[0].isDangerText"
        :confirm-title="buttons[0].confirmTitle"
        :button-color="buttons[0].buttonColor && !buttons[0].isDangerText ? buttons[0].buttonColor : ''"
        :icon="buttons[0].icon"
        @confirmed="handleButtonClick(buttons[0].name)"
      >{{ buttons[0].name }}
      </ht-popover-button>
      <ht-button v-else type="text" :icon="buttons[0].icon" :disabled="buttons[0].disabled" @click="handleButtonClick(buttons[0].name)">{{ buttons[0].name }}</ht-button>
    </template>
    <el-dropdown v-if="buttons.length > 1" trigger="click">
      <span class="el-dropdown-link"><i class="el-icon-arrow-down el-icon--right" /></span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(item,index) in getDropdownButtons" :key="index" :command="item.name">
          <ht-popover-button
            v-if="item.isPopoverButton"
            :disabled="item.disabled"
            type="text"
            :danger-text="item.isDangerText"
            :confirm-title="item.confirmTitle"
            :button-color="item.buttonColor && !item.isDangerText ? item.buttonColor : ''"
            :icon="item.icon"
            @confirmed="handleButtonClick(item.name)"
          >{{ item.name }}
          </ht-popover-button>
          <ht-button v-else type="text" :icon="item.icon" :disabled="item.disabled" @click="handleButtonClick(item.name)">{{ item.name }}</ht-button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <slot name="other-button" />
  </div>
</template>

<script>
export default {
  name: 'DwTableDropdownButtons',
  props: {
    buttons: { type: Array, default: () => [] },
    row: { type: Object, default: () => {} }
  },
  computed: {
    getDropdownButtons() {
      return this.buttons.slice(1)
    }
  },
  methods: {
    handleButtonClick(name) {
      this.$emit('handle-button-click', name)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.el-dropdown-link {
  cursor: pointer;
  color: $primaryColor;
  font-size: 14px;
  margin-left: -5px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
