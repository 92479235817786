var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        { attrs: { placement: "right", trigger: "focus" } },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "complexity-box-container" },
              [
                _vm._v("\n        密码强度：\n        "),
                _vm._l(_vm.passwordVerifyList, function (item, index) {
                  return _c("span", {
                    key: index,
                    staticClass: "complexity-box",
                    style: { background: _vm.verifyColor[index] },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", [
              _c("i", {
                class: _vm.isLengthPass ? "el-icon-check" : "el-icon-close",
                staticStyle: { "font-weight": "bolder", "margin-right": "5px" },
                style: { color: _vm.isLengthPass ? "#22c495" : "#f56c6c" },
              }),
              _vm._v("密码长度为8-20个字符"),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("i", {
                class: _vm.isComplexityPass ? "el-icon-check" : "el-icon-close",
                staticStyle: { "font-weight": "bolder", "margin-right": "5px" },
                style: { color: _vm.isComplexityPass ? "#22c495" : "#f56c6c" },
              }),
              _vm._v("密码包含字母、数字、特殊字符三种符号的任意组合"),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("i", {
                staticClass: "el-icon-warning",
                staticStyle: {
                  color: "#e6a23c",
                  "font-weight": "bolder",
                  "margin-right": "5px",
                },
              }),
              _vm._v("请不要使用日常社交账号密码，避免密码泄露带来的风险。"),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "reference" }, [_vm._t("passwordInput")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }