exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#666",
	"menuActiveText": "#2e5aff",
	"subMenuActiveText": "#2e5aff",
	"menuBg": "#fff",
	"menuHover": "#f1f2f4",
	"menuActiveBg": "#2e5aff",
	"subMenuBg": "#f1f2f4",
	"subMenuHover": "#dedede",
	"sideBarWidth": "210px",
	"primaryColor": "#2e5aff",
	"primaryColorMiddle": "#8099ff",
	"primaryColorLight": "#e0eaf9",
	"mobilePrimaryColor": "#2e5aff",
	"mobilePrimaryColorMiddle": "#6485fd",
	"mobilePrimaryColorLight": "#e0eaf9",
	"sidebarLogoBg": "#2e5aff",
	"sidebarLogoColor": "#fff",
	"hoverSideBarWidth": "190px"
};