import request from '@/utils/request'

const REMOTE_GUIDANCE_NEW = 'remote_guidance/new/'

// 当前用户获取融云token
export function postImInfoApi(data) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}im_info`, method: 'POST', data: data })
}

// 获取在线用户列表
export function getContactsApi(params) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}contacts/`, method: 'GET', params: params })
}

// 创建会议
export function postNewMeeting() {
  return request({ url: `${REMOTE_GUIDANCE_NEW}`, method: 'POST' })
}

// 会议列表
export function getMeetingList(params) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}`, method: 'GET', params: params })
}

// 会议详情
export function getMeeting(roomId) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}${roomId}`, method: 'GET' })
}

// 获取会议详情
export function getMeetingInfo(roomId, params) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}${roomId}`, method: 'GET', params: params })
}

// 会议录制
export function postVideoRecord(roomId, data) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}video_record/${roomId}`, method: 'POST', data: data })
}

// 结束会议，成员退会
export function putMeeting(roomId, data) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}${roomId}`, method: 'PUT', data: data })
}

// 将用户踢出房间
export function putKickImUser(imUserId) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}kick_im_user/${imUserId}`, method: 'PUT' })
}

// 根据imUserId获取用户信息
export function getMsgFromStaffInfo(imUserId) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}im_user_info/${imUserId}`, method: 'GET' })
}

export function postTranslate(data) {
  return request({ url: `outer_apis/xf_cloud/trans`, method: 'POST', data: data })
}

export function postInviteRecord(data) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}invite_record`, method: 'POST', data: data })
}
