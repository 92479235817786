<template>
  <div>
    <ht-action-panel>
      <template slot="right">
        <ht-button type="success" size="mini" icon="el-icon-plus" @click="openDialog('create')">添加</ht-button>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isKnowledgeLoading" :data="knowledgeList" :row-key="getRowKey" :default-sort="{prop: 'createdAt', order: 'descending'}" @sort-change="sortChange">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="i in knowledgeLabelToProps" :key="i.prop" :label="i.label" :prop="i.prop" :sortable="i.sortable">
        <template v-slot="{row}">
          <span v-if="i.prop === 'docDetails'">
            <el-link type="primary" @click="openDialog('view', row)">{{ row.details.length }}</el-link>
          </span>
          <span v-else>
            <span>{{ i.secondProp ? row[i.prop][i.secondProp] :row[i.prop] }}</span>
          </span>
        </template>
      </ht-table-column>
      <ht-table-column-operation>
        <template v-slot="{row}">
          <el-button type="text" @click="openDialog('edit', row)">编辑</el-button>
          <ht-popover-button
            type="text"
            :danger-text="true"
            confirm-title="请确认是否要删除该条数据？"
            @confirmed="deleteEquipmentKnowledgeLibsItem(row)"
          >删除
          </ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="knowledgePage.total"
      :page.sync="knowledgeListQuery.page"
      :limit.sync="knowledgeListQuery.perPage"
      @pagination="getDeviceKnowledge"
    />

    <ht-dialog ref="dialog" title="知识库" width="60%">
      <div slot="content">
        <ht-action-panel>
          <template slot="left">
            <span>知识名称：</span>
            <el-input v-if="canEdit()" v-model="docTitle" placeholder="请输入知识名称" style="width: 200px" />
            <span v-else>{{ docTitle }}</span>
          </template>
          <template v-if="canEdit()" slot="right">
            <ht-upload-button key-prefix="customer_cnbm/devices" :multiple-selection="true" @on-files-uploaded="onKnowledgeLibsUploaded" @on-upload-start="isLoading = true" @on-upload-finish="isLoading = false" />
          </template>
        </ht-action-panel>
        <ht-table :data="uploadList" border style="width: 100%;margin: 20px 0;">
          <el-table-column label="文件" width="300">
            <template v-slot="{row}">
              <el-link><span style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: 260px;display: inline-block;" :style="{color: variables.primaryColor}" @click="viewUploadFile(row)">{{ row.fileTitle }}</span></el-link>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="描述">
            <template v-slot="{row}">
              <el-input v-if="canEdit()" v-model="row.desc" placeholder="请输入文件描述" />
              <span v-else>{{ row.desc }}</span>
            </template>
          </el-table-column>
          <ht-table-column-operation v-if="canEdit()">
            <template v-slot="{row, $index}">
              <el-button type="text" style="color: red" @click="uploadList.splice($index, 1)">删除</el-button>
            </template>
          </ht-table-column-operation>
        </ht-table>
        <el-button :disabled="isLoading" type="primary" @click="submitKnowledge()">保存</el-button>
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import {
  deleteEquipmentKnowledgeLibs,
  equipmentsKnowledgeLibsApi,
  createEquipmentKnowledgeLibs, updateEquipmentKnowledgeLibs
} from '@/api/devicesMgr/deviceMgr'
import HtListContainer from '@/components/HtListContainer'
import { sortQuery } from '@/utils'
import variables from '@/styles/variables.scss'

const fileType = { jpg: 'IMG', png: 'IMG', pdf: 'PDF' }

export default {
  name: 'KnowledgeLibs',
  extends: HtListContainer,
  props: {
    deviceCode: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      knowledge: {},
      isKnowledgeLoading: false,
      knowledgeLabelToProps: [
        {
          label: '知识名称',
          prop: 'docTitle'
        },
        {
          label: '来源',
          prop: 'fromName'
        },
        {
          label: '时间',
          prop: 'createdAt',
          sortable: 'custom'
        },
        {
          label: '附件',
          prop: 'docDetails',
          format: 'link'
        }
      ],
      knowledgeList: [],
      knowledgeListQuery: { page: 1, perPage: 20 },
      knowledgePage: { total: 0 },
      uploadKnowledgeForm: {},
      uploadList: [],
      docTitle: undefined,
      openDialogType: undefined,
      libId: undefined,
      isLoading: false
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  created() {
    this.getDeviceKnowledge()
    this.uploadList = []
  },
  methods: {
    getDeviceKnowledge() {
      this.isKnowledgeLoading = true
      equipmentsKnowledgeLibsApi(this.deviceCode, this.knowledgeListQuery).then((resp) => {
        this.knowledgePage = resp.page
        this.isKnowledgeLoading = false
        resp.items.forEach((item) => {
          item.fromName = item.isFromRemoteGuidance ? '协同作业' : '本地上传'
        })
        this.knowledgeList = resp.items
      })
    },
    openDialog(type, row) {
      this.openDialogType = type
      if (type === 'create') {
        this.uploadList = []
        this.docTitle = undefined
      } else if (type === 'view' || type === 'edit') {
        this.libId = row.id
        this.docTitle = row.docTitle
        this.uploadList = row.details
      }
      this.$refs.dialog.dialogVisible = true
    },
    canEdit() {
      return this.openDialogType !== 'view'
    },
    onKnowledgeLibsUploaded(val, urls) {
      urls.forEach(item => {
        const index = item.lastIndexOf('/')
        const str = item.substring(index + 1, item.length)
        const typeIndex = item.lastIndexOf('.')
        const type = item.substring(typeIndex + 1, item.length)
        this.uploadList.push({ fileUrl: item, desc: undefined, fileType: fileType[type], fileTitle: str })
      })
    },
    deleteEquipmentKnowledgeLibsItem(row) {
      deleteEquipmentKnowledgeLibs(this.deviceCode, row.id).then(() => {
        this.$message({ message: '删除成功！', center: true, type: 'success' })
        this.getDeviceKnowledge()
      })
    },
    sortChange(ev) {
      this.knowledgeListQuery.sort = sortQuery(ev)
      this.getDeviceKnowledge()
    },
    viewUploadFile(row) {
      window.open(row.fileUrl)
    },
    submitKnowledge() {
      if (this.docTitle) {
        if (this.uploadList.length === 0) {
          this.$message({ message: '请至少上传一个附件后确认保存！', duration: 1500, type: 'warning' })
        } else {
          const data = {
            docTitle: this.docTitle,
            details: this.uploadList
          }
          const api = this.openDialogType === 'create' ? createEquipmentKnowledgeLibs(this.deviceCode, data) : updateEquipmentKnowledgeLibs(this.deviceCode, this.libId, data)
          api.then((resp) => {
            this.$message({ message: `保存成功`, duration: 1500, type: 'success' })
            this.getDeviceKnowledge()
            this.$refs.dialog.dialogVisible = false
          })
        }
      } else {
        this.$message({ message: '请填写知识名称后确认保存！', duration: 1500, type: 'warning' })
      }
    }
  }
}
</script>

<style scoped>

</style>
