var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.mySearch, "on-clear": _vm.myClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "事故类型" },
                  model: {
                    value: _vm.listQuery.accidentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "accidentType", $$v)
                    },
                    expression: "listQuery.accidentType",
                  },
                },
                _vm._l(_vm.accidentTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("common-search", {
                ref: "commonSearch",
                attrs: { "staff-placeholder": "登记人" },
                on: {
                  handleSelect: _vm.handleSelect,
                  selectDate: _vm.selectDate,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: {
            data: _vm.list,
            "row-key": _vm.getRowKey,
            "default-sort": { prop: "createdAt", order: "descending" },
          },
          on: {
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.selectionChange,
            "sort-change": _vm.sortChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.labelToProps, function (i) {
            return _c("ht-table-column", {
              key: i.prop,
              attrs: { label: i.label, prop: i.prop, sortable: i.sortable },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        i.format === "status"
                          ? _c(
                              "span",
                              [
                                _c("status", {
                                  attrs: {
                                    status: row.status.value,
                                    "status-types": {
                                      草稿: "danger",
                                      已提交: "success",
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("span", [
                              i.secondProp
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row[i.firstProp] &&
                                          row[i.firstProp][i.secondProp]
                                          ? row[i.firstProp][i.secondProp]
                                          : "-"
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v(_vm._s(row[i.prop]))]),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-view" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoView(row.id)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v("\n         |  \n        "),
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          icon: "el-icon-delete",
                          type: "text",
                          "danger-text": true,
                          "confirm-title": "请确认是否要删除该条数据？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.deleteItem(row)
                          },
                        },
                      },
                      [_vm._v("删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }