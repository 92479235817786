var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title-container",
      class: { "is-mobile-title-container": _vm.isMobile },
    },
    [
      _c("div", {
        staticClass: "title-span",
        style: { background: _vm.spanColor },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title-content", style: { color: _vm.titleColor } },
        [_vm._v(_vm._s(_vm.title))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }