var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "workflow-steps-container" },
    [
      _c("div", { staticClass: "workflow-title" }, [
        _vm._v(
          _vm._s("工单名称：" + ((_vm.workFlow && _vm.workFlow.name) || "-"))
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.steps, function (item, index) {
        return [
          _c(
            "transition",
            { key: index, attrs: { name: _vm.transitionName } },
            [
              _vm.currentStepIndex === index &&
              item.tag !== "QuickPatrolRecordType"
                ? _c(item.stepUi, {
                    key: index,
                    tag: "component",
                    staticClass: "component",
                    attrs: {
                      count: _vm.steps.length,
                      "current-index": _vm.currentStepIndex,
                      "is-last-step": index === _vm.steps.length - 1,
                      properties: item.properties,
                      results: item.results,
                    },
                    on: {
                      "to-prev": _vm.toPrev,
                      "to-next": _vm.toNext,
                      "to-remote-guidance": _vm.toRemoteGuidance,
                      "to-ai-qa-assistant": _vm.toAiQaAssistant,
                      "save-record": _vm.saveRecord,
                      "submit-record": _vm.submitRecord,
                      "jump-step": _vm.jumpStep,
                    },
                  })
                : _vm.currentStepIndex === index &&
                  item.tag === "QuickPatrolRecordType"
                ? _c("quick-patrol-work-order", {
                    staticClass: "component",
                    attrs: {
                      count: _vm.steps.length,
                      "current-index": _vm.currentStepIndex,
                      "is-last-step": index === _vm.steps.length - 1,
                      "access-token": _vm.accessToken,
                      "work-flow": item,
                    },
                    on: {
                      "to-prev": _vm.toPrev,
                      "to-next": _vm.toNext,
                      "to-remote-guidance": _vm.toRemoteGuidance,
                      "to-ai-qa-assistant": _vm.toAiQaAssistant,
                      "submit-record": _vm.submitRecord,
                      "jump-step": _vm.jumpStep,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }