<template>
  <el-card>
    <el-tabs v-model="activeName" @tab-click="getList">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.value" :name="item.name" />
    </el-tabs>
    <equipment-stock-list v-if="activeName === 'equipment'" ref="equipmentStockList" />
    <warehouse-stock-list v-if="activeName === 'warehouse'" ref="warehouseStockList" />
  </el-card>
</template>

<script>
import EquipmentStockList from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentStockList'
import WarehouseStockList from '@/views/constructionEquipmentMgr/stockMgr/components/warehouseStockList'

export default {
  name: 'List',
  components: { EquipmentStockList, WarehouseStockList },
  data() {
    return {
      activeName: 'equipment',
      tabs: [
        { name: 'equipment', value: '按设备展示' },
        { name: 'warehouse', value: '按仓库展示' }
      ]
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    getList() {
      this.$nextTick(() => {
        if (this.activeName === 'equipment') {
          this.$refs.equipmentStockList.getList()
        } else if (this.activeName === 'warehouse') {
          this.$refs.warehouseStockList.getList()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
