<template>
  <div
    v-if="showBox"
    ref="attendeesRef"
    class="attendees-container"
    :class="{'fullscreen-attendees-container': fullscreen, 'normal-attendees-container': !fullscreen, 'is-mobile-fullscreen-attendees-container': fullscreen && isMobile}"
  >
    <div ref="topContainerRef" class="top-container">
      <span class="title">参会人员列表（{{ count }}）</span>
      <img class="close-icon" :src="Close" alt="" @click="toggleBoxStatus">
    </div>
    <div class="bottom-container">
      <attendees-list
        :user-info="userInfo"
        :user-list="attendeesList"
        :show-bottom-button="showBottomButton"
        :is-mute-all-cam="isMuteAllCam || !isAllCamOn"
        :is-mute-all-mic="isMuteAllMic || !isAllMicOn"
        @change-one-mic-mute="changeOneMicMute"
        @change-one-cam-mute="changeOneCamMute"
        @change-all-mic-mute="changeAllMicMute"
        @change-all-cam-mute="changeAllCamMute"
      />
    </div>
  </div>
</template>

<script>
import AttendeesList from './attendeesList'
import Close from '@/assets/image/newRemoteGuidance/close_bold.png'
import { getContactsApi, getMeetingInfo } from '@/api/newRemoteGuidance/newRemoteGuidance'
import { boxMoveEvent, drag, mouseDown } from '../js/boxDrag'

export default {
  name: 'BoxAttendees',
  components: { AttendeesList },
  props: {
    fullscreen: { type: Boolean, default: false },
    showBottomButton: { type: Boolean, default: false },
    roomId: { type: String, default: undefined },
    userIds: { type: Array, default: () => [] },
    userInfo: { type: Object, default: () => {} },
    isShareAudio: { type: Boolean, default: true },
    isShareCameraVideo: { type: Boolean, default: true },
    localAudioTrack: { type: Object, default: () => {} },
    localVideoTrack: { type: Object, default: () => {} },
    remoteAudioTracks: { type: Array, default: () => [] },
    remoteVideoTracks: { type: Array, default: () => [] },
    isMuteAllMic: { type: Boolean, default: false },
    isMuteAllCam: { type: Boolean, default: false },
    remoteDeviceStates: { type: Array, default: () => [] },
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      Close,
      showBox: false,
      count: 0,
      meetingInfo: {},
      onlineUserList: [],
      attendeesList: [],
      initiator: undefined,
      attendeesBox: null,
      topContainer: null,
      boxPosition: { left: 0, right: 0 },
      topPosition: { startX: 0, startY: 0 }
    }
  },
  computed: {
    isAllCamOn() {
      return this.attendeesList.some(item => item.isCamOn)
    },
    isAllMicOn() {
      return this.attendeesList.some(item => item.isMicOn)
    }
  },
  watch: {
    userIds: {
      handler: function() {
        this.$nextTick(() => {
          this.refreshUserList()
        })
      },
      deep: true,
      immediate: true
    },
    isShareAudio: {
      handler: function() {
        this.attendeesList[0].isMicOn = this.isShareAudio
      }
    },
    isShareCameraVideo: {
      handler: function() {
        this.attendeesList[0].isCamOn = this.isShareCameraVideo
      }
    }
  },
  methods: {
    toggleBoxStatus() {
      this.showBox = !this.showBox
      if (this.showBox) {
        this.refreshUserList()
        this.$nextTick(() => {
          // 窗口移动
          this.attendeesBox = this.$refs.attendeesRef
          this.topContainer = this.$refs.topContainerRef
          boxMoveEvent(this.attendeesBox, this.topContainer, this.onMouseDown, this.onDrag)
        })
      } else {
        this.attendeesBox = this.$refs.attendeesRef
        this.topContainer = this.$refs.topContainerRef
        this.attendeesBox.removeEventListener('mousemove', this.onDrag)
        this.topContainer.removeEventListener('mousedown', this.onMouseDown)
        this.$emit('close-box')
      }
    },
    onMouseDown(e) {
      mouseDown(e, this.topPosition, this.attendeesBox, this.onDrag)
    },
    onDrag(e) {
      drag(e, this.topPosition, this.boxPosition, this.attendeesBox)
    },
    async refreshUserList() {
      await this.getMeetingInfo()
      await this.getOnlineUser()
      this.getAttendeesList()
    },
    getMeetingInfo() {
      return new Promise(resolve => {
        getMeetingInfo(this.roomId).then(resp => {
          this.meetingInfo = resp
          this.initiator = this.meetingInfo.staff.userName
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },
    getOnlineUser() {
      return new Promise(resolve => {
        getContactsApi({ roomId: this.roomId, offset: 0, count: 1000 }).then(resp => {
          this.onlineUserList = resp
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },
    // 参会人员列表
    getAttendeesList() {
      this.count = this.userIds.length + 1
      this.attendeesList = []
      this.userInfo.isSelf = true
      this.userInfo.userName = this.userInfo.account.userName
      if (this.initiator === this.userInfo.account.userName) {
        this.userInfo.isInitiator = true
      }
      this.attendeesList.push(this.userInfo)
      // 修改参会人员的麦克风和摄像头状态
      this.attendeesList[0].isMicOn = this.isShareAudio
      this.attendeesList[0].isCamOn = this.isShareCameraVideo
      this.userIds.forEach(userId => {
        const index = this.onlineUserList.findIndex(item => item.imUserId === userId)
        if (index !== -1) {
          if (this.onlineUserList[index].userName === this.initiator) {
            this.onlineUserList[index].isInitiator = true
          }
          // 远端用户列表只有现场才有设备状态，专家是没有的，现场根据设备状态进行修改，专家根据流的状态进行修改
          const remoteIndex = this.remoteDeviceStates.findIndex(item => item.imUserId === this.onlineUserList[index].imUserId)
          if (remoteIndex !== -1) {
            this.onlineUserList[index].isMicOn = this.remoteDeviceStates[remoteIndex].deviceStatus && this.remoteDeviceStates[remoteIndex].deviceStatus.isMicOn
            this.onlineUserList[index].isCamOn = this.remoteDeviceStates[remoteIndex].deviceStatus && this.remoteDeviceStates[remoteIndex].deviceStatus.isCamOn
          } else {
            const audioIndex = this.remoteAudioTracks.findIndex(item => item && item.getUserId() === this.onlineUserList[index].imUserId)
            this.onlineUserList[index].isMicOn = audioIndex !== -1 && !this.remoteAudioTracks[audioIndex]._remoteMuted
            const videoIndex = this.remoteVideoTracks.findIndex(item => item && item.getUserId() === this.onlineUserList[index].imUserId)
            this.onlineUserList[index].isCamOn = videoIndex !== -1 && !this.remoteVideoTracks[videoIndex]._remoteMuted
          }
          this.attendeesList.push(this.onlineUserList[index])
        }
      })
    },
    changeOneMicMute(imUserId, val) {
      this.$emit('change-one-mic-mute', imUserId, val)
    },
    changeOneCamMute(imUserId, val) {
      this.$emit('change-one-cam-mute', imUserId, val)
    },
    changeAllMicMute(val) {
      this.$emit('change-all-mic-mute', val)
    },
    changeAllCamMute(val) {
      this.$emit('change-all-cam-mute', val)
    }
  }
}
</script>

<style scoped lang="scss">
.attendees-container{
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 380px;
  height: 500px;
  z-index: 1000;
  .top-container{
    height: 48px;
    padding: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F3F3;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 10px 10px 0 0;
    cursor: move;
    .close-icon{
      right: 10px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
    .title{
      flex: 1;
      height: 28px;
      line-height: 28px;
      color: #000000;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
  }
  .bottom-container{
    flex: 1;
    overflow-y: auto;
    border-radius: 0 0 10px 10px;
  }
}

.is-mobile-fullscreen-attendees-container{
  height: 400px !important;
}
</style>
