import request from '@/utils/request'

const ASYNC_TASK_URL = 'async_task/async_tasks/'

export function createAsyncTask(data) {
  const url = `${ASYNC_TASK_URL}`
  return request({ url: url, method: 'post', data: data })
}

export function getAsyncTask(taskUuid) {
  const url = `${ASYNC_TASK_URL}${taskUuid}`
  return request({ url: url, method: 'get' })
}

export const ASYNC_TASK_STATUS = {
  failed: -1,
  created: 0,
  processing: 1,
  finished: 2
}

export const ASYNC_TASKS = {
  ImportBizProtocolDetail: 'import_biz_protocol_detail',
  ImportInnerSupplyMaterials: 'import_inner_supply_materials',
  ImportReqPlanLineItems: 'import_req_plan_line_items',
  ImportResultLineItems: 'update_result_line_items'
}
