<template>
  <div ref="container" class="container" :class="[{'mobile-container': isMobile}]">
    <div class="fixed-container">
      <ht-action-panel :on-search="onSearch" :on-clear="onClear" class="fixed-panel">
        <template slot="left">
          <el-switch v-model="listQuery.isAbnormal" active-color="#2E5AFF" inactive-color="#909399" active-text="只看异常" @change="onSearch" />
          <search-history-input ref="searchHistoryInput" v-model="listQuery.keyword" width="600px" :history-list="searchHistoryList" :record-list="searchRecordList" @change="onHistorySearch()" @on-del="onDelSearchHistory" />
        </template>
      </ht-action-panel>
    </div>
    <div ref="resultListContainer" v-loading="isListLoading" class="result-list-container" :style="{paddingTop: resultListContainerPaddingTop + 'px'}">
      <div v-if="list.length > 0" class="result-container">
        <result-item v-for="(item,index) in list" :key="index" :order-info="item" :keyword="listQuery.keyword" :is-match="isMatchSearch" @on-click="onView" />
      </div>
      <div v-else class="no-result-container">
        <img :src="Empty" class="empty-img">
        <span class="empty-info">{{ `未找到${listQuery.keyword ? '“' + listQuery.keyword + '”' : ''}相关图片，您可以尝试更换关键词搜索` }}</span>
      </div>
      <div v-if="isLoading && !isListLoading" class="loading-spinner"><img class="loading-img" :src="Loading" alt="">数据加载中...</div>
      <div v-if="isEnd && list.length > 0" class="already-loaded">已经到底部了</div>
    </div>

    <ht-dialog ref="workOrderViewDialog" title="工单详情" width="600px" fullscreen @close="closeWorkOrderViewDialog">
      <div slot="content">
        <work-order-view v-if="showWorkOrderView" ref="workOrderView" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import { getObject, removeObject, setObject } from '@/store/localStorage'
import SearchHistoryInput from '@/views/knowledgeBaseMgr/knowledgeSearch/components/searchHistoryInput'
import Empty from '@/assets/image/mobile/mobile_empty.png'
import { getWorkOrderGallery, getWorkOrderGalleryRecommendedWords } from '@/api/taskMgr/workOrder'
import ResultItem from '@/views/taskMgr/patrolWorkOrderMgr/components/resultItem'
import WorkOrderView from '@/views/taskMgr/patrolWorkOrderMgr/view'
import Loading from '@/assets/image/mobile/mobile_loading.png'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkOrderImgSearch',
  components: { SearchHistoryInput, ResultItem, WorkOrderView },
  data() {
    return {
      Empty, Loading,
      listQuery: {
        offset: 0,
        count: 100,
        keyword: '',
        isAbnormal: false
      },
      searchHistoryList: [],
      searchRecordList: [],
      count: 0,
      list: [],
      isEnd: false,
      isLoading: false,
      isListLoading: false,
      isMatchSearch: false,
      showWorkOrderView: false,
      resultListContainerPaddingTop: this.isMobile ? 100 : 55
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList(false, true)
    this.getWorkOrderGalleryRecommendedWords()
    this.searchHistoryList = getObject('workOrderMgr') && getObject('workOrderMgr').searchHistoryList || []
  },
  mounted() {
    window.addEventListener('wheel', this.checkDistanceToBottom,)
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.checkDistanceToBottom,)
  },
  methods: {
    checkDistanceToBottom(event) {
      this.$nextTick(() => {
        if (event.deltaY > 0) {
          const container = this.$refs.resultListContainer // 获取元素的引用
          const containerRect = container.getBoundingClientRect() // 获取元素相对于视口的位置信息
          const distanceToBottom = containerRect.bottom - window.innerHeight // 计算元素底部距离屏幕底部的距离
          if (!this.isLoading && !this.isEnd && distanceToBottom <= 400) {
            this.listQuery.offset += this.listQuery.count
            this.getList(true, false)
          }
        }
      })
    },
    onHistorySearch(keyword) {
      this.listQuery.keyword = keyword || this.listQuery.keyword
      this.onSearch()
    },
    onSearch() {
      if (this.listQuery.keyword) {
        this.searchHistoryList.unshift(this.listQuery.keyword)
        this.searchHistoryList = [...new Set(this.searchHistoryList)]
        const workOrderMgrObject = {
          searchHistoryList: this.searchHistoryList
        }
        setObject('workOrderMgr', workOrderMgrObject)
      }
      this.isMatchSearch = true
      this.getList(false, true, true)
    },
    onClear() {
      this.listQuery.keyword = null
      this.$refs.searchHistoryInput.keyword = null
      this.isMatchSearch = false
      this.getList(false, true, true)
    },
    onDelSearchHistory() {
      this.searchHistoryList = []
      removeObject('workOrderMgr')
    },
    getList(isConcat = false, isListLoading = true, isRefresh = false) {
      this.isLoading = true
      this.isListLoading = isListLoading
      this.listQuery.offset = isRefresh ? 0 : this.listQuery.offset
      if (isRefresh) {
        const element = document.getElementsByClassName('container')[0]
        if (element) {
          this.resultListContainerPaddingTop = this.isMobile ? 160 : 105
          element.scrollIntoView({
            behavior: 'smooth', // 平滑滚动
            block: 'start', // 从顶部开始滚动
            inline: 'nearest' // 保持元素的行内位置
          })
        }
      } else {
        this.resultListContainerPaddingTop = this.isMobile ? 100 : 55
      }
      getWorkOrderGallery(this.listQuery).then((resp) => {
        this.isLoading = false
        this.isListLoading = false
        this.count = resp.totalCount
        this.list = isConcat ? this.list.concat(resp.items) : resp.items
        this.isEnd = resp.items.length === 0
      }).catch(() => {
        this.isLoading = false
        this.isListLoading = false
      })
    },
    getWorkOrderGalleryRecommendedWords() {
      this.searchRecordList = []
      getWorkOrderGalleryRecommendedWords().then(resp => {
        this.searchRecordList = resp
      }).catch(() => {
        this.searchRecordList = []
      })
    },
    onView(taskWorkOrderId) {
      this.showWorkOrderView = true
      this.$refs.workOrderViewDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.workOrderView.getWorkOrderDetail(taskWorkOrderId)
      })
    },
    closeWorkOrderViewDialog() {
      this.showWorkOrderView = false
      this.$refs.workOrderViewDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.container{
  position: relative;
  //max-height: 1000px;
  //overflow: scroll;
  .fixed-container{
    position: fixed;
    background: #ffffff;
    z-index: 9;
    width: 100%;
    padding: 0 20px;
    .info{
      color: #f56c6c;
      width: 100%;
      background: #ffffff;
      padding: 10px 0;
    }
  }
  .result-list-container{
    padding: 55px 20px 20px;
    min-height: 400px;
    .no-result-container{
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .empty-info{
        color: #bbbbbb;
      }
    }
    .loading-spinner{
      padding: 10px 15px;
      text-align: center;
      color: $primaryColor;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .loading-img {
        width: 30px;
        height: 30px;
        animation: rotate 4s linear infinite; /* 4秒钟内完成一次旋转，无限循环 */
        margin-right: 10px;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .already-loaded{
      padding: 10px 15px;
      text-align: center;
      color: #666666;
    }
  }
}

.fixed-panel{
  max-width: 100%;
}

.mobile-container{
  ::v-deep .title-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
 ::v-deep .result-item-container .img-container .item{
   width:calc(50% - 15px);
 }
}
</style>
