<template>
  <div class="config-container">
    <el-form ref="configForm" :model="configForm" :rules="configRules" class="left-container">
      <el-form-item label="步骤名称：" prop="title">
        <el-input
          v-model="configForm.title"
          placeholder="请输入步骤名称"
          maxlength="10"
          show-word-limit
          @focus="focusedItem('title')"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Config',
  props: {
    index: {
      type: Number,
      default: 0
    },
    properties: {
      type: Object,
      default() {
        return {
          title: null
        }
      }
    },
    configRules: {
      type: Object,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      configForm: this.properties
    }
  },
  methods: {
    focusedItem(name) {
      this.$emit('focused-item', name)
    },
    onSaveStep() {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          this.$emit('save-step')
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    onDelStep() {
      this.$emit('del-step', this.index)
    }
  }
}
</script>

<style scoped lang="scss">
.config-container{
  padding: 20px 10px 30px;
  max-height: 700px;
  overflow-y: auto;
  position: relative;
  .right-container{
    text-align: center;
    height: 30px;
  }
}
</style>
