var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "div",
        { staticClass: "title-container" },
        [
          _c(
            "el-form",
            {
              ref: "templateForm",
              staticClass: "left-container",
              attrs: {
                model: _vm.templateForm,
                rules: _vm.templateFormRules,
                "label-width": "120px",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工作流程名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "template-name",
                    attrs: {
                      placeholder: "请输入工作流程名称",
                      maxlength: 20,
                      "show-word-limit": "",
                    },
                    on: {
                      change: function ($event) {
                        _vm.isDataSaved = false
                      },
                    },
                    model: {
                      value: _vm.templateForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "name", $$v)
                      },
                      expression: "templateForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工作流程类型：", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择工作流程类型" },
                      on: { change: _vm.handleTempTypeChange },
                      model: {
                        value: _vm.templateForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.templateForm, "type", $$v)
                        },
                        expression: "templateForm.type",
                      },
                    },
                    _vm._l(_vm.getTaskTempTypeEnum(), function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.value, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.templateForm.type === "PATROL"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "作业类型：", prop: "towerWorkType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择作业类型" },
                          model: {
                            value: _vm.templateForm.towerWorkType,
                            callback: function ($$v) {
                              _vm.$set(_vm.templateForm, "towerWorkType", $$v)
                            },
                            expression: "templateForm.towerWorkType",
                          },
                        },
                        _vm._l(
                          _vm.TowerWorkOrderTypeObjectEnum,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.value, value: item.name },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-container" },
            [
              _c(
                "ht-button",
                {
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.cancelCreate },
                },
                [_vm._v("返 回")]
              ),
              _vm._v(" "),
              _c(
                "ht-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveFlow(null, true)
                    },
                  },
                },
                [_vm._v("保存流程")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "work-flow-container" }, [
        _c(
          "div",
          { staticClass: "flow-container" },
          [
            _c(
              "div",
              { staticClass: "flow-title-container" },
              [
                _c("span", { staticClass: "title" }, [_vm._v("任务流程")]),
                _vm._v(" "),
                _c(
                  "ht-button",
                  {
                    staticStyle: { "font-weight": "bold", color: "#2e5aff" },
                    attrs: { type: "text", icon: "el-icon-plus" },
                    on: { click: _vm.openStepsDialog },
                  },
                  [
                    _vm._v(
                      "添加" +
                        _vm._s(
                          _vm.getTaskTempType(_vm.originalTempType)
                            ? _vm.getTaskTempType(_vm.originalTempType).stepName
                            : ""
                        )
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("flow", {
              staticClass: "left-box",
              attrs: {
                steps: _vm.steps,
                "is-checked-index": _vm.isCheckedIndex,
              },
              on: { "edit-step": _vm.editStep, "del-step": _vm.delStep },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "step-container" }, [
          _c("div", { staticClass: "preview-container" }, [
            _c("div", { staticClass: "title" }, [_vm._v("步骤预览")]),
            _vm._v(" "),
            _vm.steps.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "step-ui-container",
                    class: { "is-checked-step-container": _vm.isCheckedStep },
                  },
                  [
                    _vm.isCheckedStep && _vm.isCheckedStep.stepUi
                      ? _c(_vm.isCheckedStep.stepUi, {
                          tag: "component",
                          attrs: {
                            properties: _vm.isCheckedStep.properties,
                            results: _vm.isCheckedStep.results,
                            count: _vm.steps.length,
                            "current-index": _vm.isCheckedIndex,
                            "is-last-step":
                              _vm.isCheckedIndex === _vm.steps.length - 1,
                            "is-focused-item": _vm.isFocusedItem,
                            height: 480,
                            "is-edit": "",
                          },
                        })
                      : _c("div"),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isCheckedStep
              ? _c(
                  "div",
                  { staticClass: "operating-container" },
                  [
                    _c(
                      "ht-button",
                      {
                        staticClass: "del-step-button",
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.delStep(_vm.isCheckedIndex)
                          },
                        },
                      },
                      [_vm._v("删除步骤")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.isCheckedStep && _vm.isCheckedStep.config
            ? _c(
                "div",
                { staticClass: "edit-container" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("步骤编辑")]),
                  _vm._v(" "),
                  _vm.isCheckedStep && _vm.isCheckedStep.config
                    ? _c(_vm.isCheckedStep.config, {
                        ref: "stepConfig",
                        tag: "component",
                        attrs: {
                          properties: _vm.isCheckedStep.properties,
                          index: _vm.isCheckedIndex,
                          "config-rules": _vm.isCheckedStep.configRules,
                          "is-edit": "",
                        },
                        on: {
                          "update:properties": function ($event) {
                            return _vm.$set(
                              _vm.isCheckedStep,
                              "properties",
                              $event
                            )
                          },
                          "focused-item": _vm.focusedItem,
                          "save-step": _vm.saveFlow,
                          "del-step": _vm.delStep,
                        },
                      })
                    : _c("div", { staticClass: "step-no-config" }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "stepsDialog",
          attrs: { title: "选择步骤", width: "50%" },
          on: { close: _vm.closeStepsDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c("steps", {
                attrs: {
                  steps: _vm.getStepsUiProperties,
                  type: _vm.templateForm.type,
                },
                on: { "add-step": _vm.addStep },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }