var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "outboundForm",
          attrs: {
            model: _vm.outboundForm,
            rules: _vm.outboundFormRules,
            "label-width": "95px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "收货方（工程方）：",
                prop: "constructionProjectId",
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.projectList.length > 0
                        ? "请输入工程名称进行搜索"
                        : "当前无可选工程",
                    remote: "",
                    "remote-method": _vm.getProjects,
                  },
                  model: {
                    value: _vm.outboundForm.constructionProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.outboundForm, "constructionProjectId", $$v)
                    },
                    expression: "outboundForm.constructionProjectId",
                  },
                },
                _vm._l(_vm.projectList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { "text-align": "left" },
              attrs: { label: "出库设备：", prop: "constructionEquipmentId" },
            },
            [
              _c("equipment-descriptions", {
                attrs: { "equipment-info": _vm.equipmentInfo },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.equipmentInfo && _vm.equipmentInfo.isWhole
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "出库仓库：", prop: "warehouseId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.outboundForm.warehouseId,
                          callback: function ($$v) {
                            _vm.$set(_vm.outboundForm, "warehouseId", $$v)
                          },
                          expression: "outboundForm.warehouseId",
                        },
                      },
                      _vm._l(_vm.warehouseList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.warehouseId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "出库数量：", prop: "outboundQuantity" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "", placeholder: "请输入出库数量" },
                      model: {
                        value: _vm.outboundForm.outboundQuantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.outboundForm, "outboundQuantity", $$v)
                        },
                        expression: "outboundForm.outboundQuantity",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "ht-table",
                  {
                    ref: "list",
                    staticStyle: { "margin-bottom": "18px" },
                    attrs: { data: _vm.warehouseList },
                  },
                  [
                    _c("ht-table-column", {
                      attrs: { label: "序号", type: "index", fixed: "left" },
                    }),
                    _vm._v(" "),
                    _vm._l(
                      _vm.EquipmentWarehouseStockInfoListTitle,
                      function (item) {
                        return _c("ht-table-column", {
                          key: item.prop,
                          attrs: {
                            label: item.label,
                            prop: item.prop,
                            "min-width": item.width || 100,
                            fixed: item.fixed,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    item.label === "库存数"
                                      ? _c("span", [
                                          _vm._v(_vm._s(row[item.prop])),
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(row[item.prop] || "-")),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }
                    ),
                    _vm._v(" "),
                    _c("ht-table-column", {
                      attrs: {
                        label: "出库数量",
                        fixed: "right",
                        prop: "outboundQuantity",
                        "show-overflow-tooltip": false,
                        width: "200",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  min: 0,
                                  max: row.quantity,
                                  disabled: row.quantity === 0,
                                  placeholder: "请输入出库数量",
                                  precision: 0,
                                },
                                model: {
                                  value: row.outboundQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(row, "outboundQuantity", $$v)
                                  },
                                  expression: "row.outboundQuantity",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
              ],
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "出库单：", prop: "attachmentData" } },
            [
              _vm.outboundForm.attachmentData
                ? _c("dw-file", {
                    attrs: {
                      url: _vm.outboundForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.outboundForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["doc", "pdf", "img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传出库单",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认出库")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }