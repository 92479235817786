var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "menu-list-container",
      style: {
        width: _vm.treeWidth + "px",
        marginBottom: _vm.marginBottom + "px",
      },
    },
    [
      _vm.isDisabled
        ? _c("div", { staticClass: "disabled-container" })
        : _vm._e(),
      _vm._v(" "),
      _c("el-tree", {
        ref: _vm.treeRef,
        attrs: {
          id: _vm.treeId,
          data: _vm.treeList,
          props: _vm.defaultProps,
          "node-key": "id",
          "current-node-key": _vm.currentNodeKey,
          "default-expand-all": "",
          "highlight-current": "",
          "expand-on-click-node": false,
        },
        on: {
          "node-collapse": function ($event) {
            return _vm.$emit("click-outside")
          },
          "node-expand": function ($event) {
            return _vm.$emit("click-outside")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c(
                "span",
                { ref: node.id, staticClass: "custom-tree-node" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "node-name",
                      on: {
                        click: function ($event) {
                          return _vm.nodeClick(node, data)
                        },
                      },
                    },
                    [_vm._v(_vm._s(node[_vm.treeName]))]
                  ),
                  _vm._v(" "),
                  _vm.hasFunctionBox
                    ? [
                        data[_vm.treeLevelName] === 0 &&
                        !_vm.topLevelHasFunctionBox
                          ? _c(
                              "ht-button",
                              {
                                staticClass: "node-button",
                                attrs: { type: "text", icon: "el-icon-plus" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClick(true, node, data)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.topLevelFirstButtonName))]
                            )
                          : !data.isDefault
                          ? _c("i", {
                              staticClass: "el-icon-more node-icon",
                              attrs: { title: _vm.treeIconTitle },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick(false, node, data)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.showFunctionBox
        ? _c(
            "div",
            {
              staticClass: "function-box",
              style: { left: _vm.treeWidth + 2 + "px" },
              attrs: { id: "functionBox" },
            },
            [
              _c("i", { staticClass: "el-icon-caret-left more-function-icon" }),
              _vm._v(" "),
              _vm._t("functions"),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }