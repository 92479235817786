var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _vm.deviceDetail.isSyncFromOuter
        ? _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              model: {
                value: _vm.activatedTab,
                callback: function ($$v) {
                  _vm.activatedTab = $$v
                },
                expression: "activatedTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "设备数据", name: "设备数据" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isProductDataLoading,
                          expression: "isProductDataLoading",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-card",
                        { staticStyle: { "margin-bottom": "30px" } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _vm.deviceDetail.imgUrls &&
                                  _vm.deviceDetail.imgUrls.length !== 0
                                    ? [
                                        _c(
                                          "el-carousel",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                            },
                                            attrs: {
                                              trigger: "click",
                                              arrow: "always",
                                              height: "200px",
                                              autoplay: false,
                                            },
                                            on: { change: _vm.picChange },
                                          },
                                          _vm._l(
                                            _vm.deviceDetail.imgUrls,
                                            function (item) {
                                              return _c(
                                                "el-carousel-item",
                                                { key: item },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      src: item,
                                                      "preview-src-list":
                                                        _vm.deviceDetail
                                                          .imgUrls,
                                                      fit: "contain",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "ht-popover-button",
                                              {
                                                attrs: {
                                                  "danger-text": true,
                                                  "confirm-title":
                                                    "是否删除当前图片？",
                                                  icon: "el-icon-delete",
                                                  loading: _vm.deletePicLoading,
                                                },
                                                on: {
                                                  confirmed: _vm.deletePic,
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                            _vm._v(" "),
                                            _c("ht-upload-button", {
                                              attrs: {
                                                "key-prefix":
                                                  "customer_cnbm/devices",
                                                "multiple-selection": true,
                                              },
                                              on: {
                                                "on-files-uploaded":
                                                  _vm.onFilesUploaded,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              margin: "auto auto",
                                              width: "300px",
                                              height: "200px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%",
                                              },
                                              attrs: { src: _vm.common },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("ht-upload-button", {
                                              attrs: {
                                                "key-prefix":
                                                  "customer_cnbm/devices",
                                                "multiple-selection": true,
                                              },
                                              on: {
                                                "on-files-uploaded":
                                                  _vm.onFilesUploaded,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-descriptions",
                                    { attrs: { size: "medium" } },
                                    [
                                      _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "设备名称" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.deviceDetail.name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "设备编号" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.deviceDetail.code)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "mac地址" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.deviceDetail
                                                  .beaconMacAddr || "-"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.deviceProductionData.length > 0
                                    ? [
                                        _c("h3", [_vm._v("实时生产数据：")]),
                                        _vm._v(" "),
                                        _c(
                                          "el-descriptions",
                                          { attrs: { size: "medium" } },
                                          _vm._l(
                                            _vm.deviceProductionData,
                                            function (i) {
                                              return _c(
                                                "el-descriptions-item",
                                                {
                                                  key: i.dataId,
                                                  attrs: { label: i.dataName },
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        i.value +
                                                          "(" +
                                                          i.dataUnit +
                                                          ")"
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.deviceDetail.isSyncFromOuter
                        ? _c("el-card", [
                            _vm.deviceProductionData.length > 0
                              ? _c("div", { staticStyle: { width: "800px" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "10px",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        { attrs: { inline: true } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "指标名称" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "指标名称",
                                                  },
                                                  on: {
                                                    change: _vm.changeQuery,
                                                  },
                                                  model: {
                                                    value: _vm.barChartType,
                                                    callback: function ($$v) {
                                                      _vm.barChartType = $$v
                                                    },
                                                    expression: "barChartType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.deviceProductionData,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.dataId,
                                                      attrs: {
                                                        label: item.dataName,
                                                        value: item.dataId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "截止日期" } },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  type: "datetime",
                                                  placeholder: "数据数量",
                                                  "value-format": "timestamp",
                                                },
                                                on: { change: _vm.changeQuery },
                                                model: {
                                                  value: _vm.barChartTime,
                                                  callback: function ($$v) {
                                                    _vm.barChartTime = $$v
                                                  },
                                                  expression: "barChartTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "数据数量" } },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  "controls-position": "right",
                                                  min: 1,
                                                  max: 14,
                                                  placeholder: "数据数量",
                                                },
                                                on: { change: _vm.changeQuery },
                                                model: {
                                                  value: _vm.barChartItemsCount,
                                                  callback: function ($$v) {
                                                    _vm.barChartItemsCount = $$v
                                                  },
                                                  expression:
                                                    "barChartItemsCount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.barLoading,
                                          expression: "barLoading",
                                        },
                                      ],
                                    },
                                    [
                                      _c("bar-chart", {
                                        ref: "eChart",
                                        attrs: {
                                          "is-show-background": false,
                                          "is-show-data-zoom": false,
                                          "chart-data": _vm.barData.data,
                                          "x-axis-data": _vm.barData.xAxisName,
                                          "x-axis-name": "时间",
                                          "y-axis-name":
                                            "单位(" +
                                            _vm.barData.dataInfo.unit +
                                            ")",
                                          "axis-label-style": {
                                            color: "black",
                                          },
                                          "tooltip-formatter":
                                            _vm.barData.dataInfo.name +
                                            "(" +
                                            _vm.barData.dataInfo.unit +
                                            "){b0} : {c}",
                                          width: "900px",
                                          height: "450px",
                                          title: { text: "历史生产数据" },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("div", [_c("h5", [_vm._v("暂无历史数据")])]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "协同作业", name: "协同作业" } },
                [
                  _vm.activatedTab === "协同作业"
                    ? _c("remote-guidance-list", {
                        ref: "remoteGuidanceList",
                        attrs: {
                          source: "deviceMgr",
                          "biz-type": "COOPERATE_WORK",
                          "biz-id": _vm.deviceId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.deviceDetail.equipmentKnowledgeOnly
                ? [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "设备缺陷", name: "设备缺陷" } },
                      [
                        _vm.activatedTab === "设备缺陷"
                          ? _c("bug-reports", {
                              attrs: { "device-code": _vm.deviceCode },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "维修任务", name: "维修任务" } },
                      [
                        _vm.activatedTab === "维修任务"
                          ? _c("maintain-tasks", {
                              attrs: { "device-code": _vm.deviceCode },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "巡检任务", name: "巡检任务" } },
                      [
                        _vm.activatedTab === "巡检任务"
                          ? _c("patrol-tasks", {
                              attrs: { "device-code": _vm.deviceCode },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "设备知识库", name: "设备知识库" } },
                [
                  _vm.activatedTab === "设备知识库"
                    ? _c("knowledge-libs", {
                        attrs: { "device-code": _vm.deviceCode },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.deviceDetail.isSyncFromOuter
        ? _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              model: {
                value: _vm.notSyncActivatedTab,
                callback: function ($$v) {
                  _vm.notSyncActivatedTab = $$v
                },
                expression: "notSyncActivatedTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "设备知识库", name: "设备知识库" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isProductDataLoading,
                          expression: "isProductDataLoading",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-card",
                        { staticStyle: { "margin-bottom": "30px" } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _vm.deviceDetail.imgUrls &&
                                  _vm.deviceDetail.imgUrls.length !== 0
                                    ? [
                                        _c(
                                          "el-carousel",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                            },
                                            attrs: {
                                              trigger: "click",
                                              arrow: "always",
                                              height: "200px",
                                              autoplay: false,
                                            },
                                            on: { change: _vm.picChange },
                                          },
                                          _vm._l(
                                            _vm.deviceDetail.imgUrls,
                                            function (item) {
                                              return _c(
                                                "el-carousel-item",
                                                { key: item },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      src: item,
                                                      "preview-src-list":
                                                        _vm.deviceDetail
                                                          .imgUrls,
                                                      fit: "contain",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "ht-popover-button",
                                              {
                                                attrs: {
                                                  "danger-text": true,
                                                  "confirm-title":
                                                    "是否删除当前图片？",
                                                  icon: "el-icon-delete",
                                                  loading: _vm.deletePicLoading,
                                                },
                                                on: {
                                                  confirmed: _vm.deletePic,
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                            _vm._v(" "),
                                            _c("ht-upload-button", {
                                              attrs: {
                                                "key-prefix":
                                                  "customer_cnbm/devices",
                                                "multiple-selection": true,
                                              },
                                              on: {
                                                "on-files-uploaded":
                                                  _vm.onFilesUploaded,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              margin: "auto auto",
                                              width: "300px",
                                              height: "200px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%",
                                              },
                                              attrs: { src: _vm.common },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("ht-upload-button", {
                                              attrs: {
                                                "key-prefix":
                                                  "customer_cnbm/devices",
                                                "multiple-selection": true,
                                              },
                                              on: {
                                                "on-files-uploaded":
                                                  _vm.onFilesUploaded,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-descriptions",
                                    { attrs: { size: "medium" } },
                                    [
                                      _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "设备名称" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.deviceDetail.name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "设备编号" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.deviceDetail.code)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-descriptions-item",
                                        { attrs: { label: "mac地址" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.deviceDetail
                                                  .beaconMacAddr || "-"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.deviceProductionData.length > 0
                                    ? [
                                        _c("h3", [_vm._v("实时生产数据：")]),
                                        _vm._v(" "),
                                        _c(
                                          "el-descriptions",
                                          { attrs: { size: "medium" } },
                                          _vm._l(
                                            _vm.deviceProductionData,
                                            function (i) {
                                              return _c(
                                                "el-descriptions-item",
                                                {
                                                  key: i.dataId,
                                                  attrs: { label: i.dataName },
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        i.value +
                                                          "(" +
                                                          i.dataUnit +
                                                          ")"
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.deviceDetail.isSyncFromOuter
                        ? _c("el-card", [
                            _vm.deviceProductionData.length > 0
                              ? _c("div", { staticStyle: { width: "800px" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "10px",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        { attrs: { inline: true } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "指标名称" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "指标名称",
                                                  },
                                                  on: {
                                                    change: _vm.changeQuery,
                                                  },
                                                  model: {
                                                    value: _vm.barChartType,
                                                    callback: function ($$v) {
                                                      _vm.barChartType = $$v
                                                    },
                                                    expression: "barChartType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.deviceProductionData,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.dataId,
                                                      attrs: {
                                                        label: item.dataName,
                                                        value: item.dataId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "截止日期" } },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  type: "datetime",
                                                  placeholder: "数据数量",
                                                  "value-format": "timestamp",
                                                },
                                                on: { change: _vm.changeQuery },
                                                model: {
                                                  value: _vm.barChartTime,
                                                  callback: function ($$v) {
                                                    _vm.barChartTime = $$v
                                                  },
                                                  expression: "barChartTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "数据数量" } },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  "controls-position": "right",
                                                  min: 1,
                                                  max: 14,
                                                  placeholder: "数据数量",
                                                },
                                                on: { change: _vm.changeQuery },
                                                model: {
                                                  value: _vm.barChartItemsCount,
                                                  callback: function ($$v) {
                                                    _vm.barChartItemsCount = $$v
                                                  },
                                                  expression:
                                                    "barChartItemsCount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.barLoading,
                                          expression: "barLoading",
                                        },
                                      ],
                                    },
                                    [
                                      _c("bar-chart", {
                                        ref: "eChart",
                                        attrs: {
                                          "is-show-background": false,
                                          "is-show-data-zoom": false,
                                          "chart-data": _vm.barData.data,
                                          "x-axis-data": _vm.barData.xAxisName,
                                          "x-axis-name": "时间",
                                          "y-axis-name":
                                            "单位(" +
                                            _vm.barData.dataInfo.unit +
                                            ")",
                                          "axis-label-style": {
                                            color: "black",
                                          },
                                          "tooltip-formatter":
                                            _vm.barData.dataInfo.name +
                                            "(" +
                                            _vm.barData.dataInfo.unit +
                                            "){b0} : {c}",
                                          width: "900px",
                                          height: "450px",
                                          title: { text: "历史生产数据" },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("div", [_c("h5", [_vm._v("暂无历史数据")])]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.notSyncActivatedTab === "设备知识库"
                    ? _c("knowledge-libs", {
                        attrs: { "device-code": _vm.deviceCode },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "协同作业", name: "协同作业" } },
                [
                  _vm.notSyncActivatedTab === "协同作业"
                    ? _c("remote-guidance-list", {
                        ref: "remoteGuidanceList",
                        attrs: {
                          source: "deviceMgr",
                          "biz-type": "COOPERATE_WORK",
                          "biz-id": _vm.deviceId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }