var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "external-call-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "menu-tab",
          staticStyle: { height: "100%" },
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm._l(_vm.tabs, function (item, index) {
            return _c("el-tab-pane", {
              key: index,
              attrs: { label: item.label, name: item.value },
            })
          }),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
            staticClass: "loading",
          }),
          _vm._v(" "),
          _vm.activeName === "Contracts"
            ? _c("contract-list")
            : _vm.activeName === "Records"
            ? _c("meeting-records")
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("user-info", {
        attrs: {
          "app-id": _vm.appId,
          "outer-id": _vm.outerId,
          "user-name": _vm.userName,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }