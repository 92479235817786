<template>
  <div v-loading="isLoading">
    <div class="stock-detail-container">
      <dw-title :title="`库存详情（${warehouseName}）`" is-mobile />
      <div v-for="(item,index) in equipmentList" :key="index" class="equipment-item" @click="getEquipmentLogisticsTraces(item.constructionEquipmentId)">
        <div class="item top-item">设备：{{ item.constructionEquipmentName }} {{ item.constructionEquipmentCode ? `(${item.constructionEquipmentCode })` : '' }}</div>
        <div class="item">规格型号：{{ item.constructionEquipmentSpecModel || '-' }}</div>
        <div class="item bottom-item">
          <span class="quantity">仓库库存数：{{ item.quantity }}</span>
          <span class="button">物流跟踪</span>
        </div>
      </div>
    </div>
    <el-drawer
      title="物流跟踪"
      :visible.sync="showLogisticsTraces"
      direction="btt"
      class="mobile-drawer"
      :destroy-on-close="true"
      :before-close="closeLogisticsTrace"
    >
      <logistics-trace class="logistics-trace-container" :is-loading="isLogisticsTracesLoading" :logistics-traces="logisticsTraces" />
    </el-drawer>
  </div>
</template>

<script>
import { getEquipmentStockRecords, getWarehouseStock } from '@/api/constructionEquipmentMgr/stockMgr'
import LogisticsTrace from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/logisticsTrace'

export default {
  name: 'WarehouseStockView',
  components: { LogisticsTrace },
  props: {
    warehouseId: { type: [String, Number], default: null },
    warehouseName: { type: String, default: null }
  },
  data() {
    return {
      equipmentList: [],
      isLoading: false,
      logisticsTraces: [],
      isLogisticsTracesLoading: false,
      showLogisticsTraces: false
    }
  },
  created() {
    this.getEquipmentList()
  },
  methods: {
    getEquipmentList() {
      this.isLoading = true
      getWarehouseStock(this.warehouseId).then(resp => {
        this.equipmentList = resp
        this.isLoading = false
      }).catch(() => { this.isLoading = false })
    },
    getEquipmentLogisticsTraces(equipmentId) {
      this.isLogisticsTracesLoading = true
      this.showLogisticsTraces = true
      getEquipmentStockRecords(equipmentId).then(resp => {
        this.logisticsTraces = resp
        this.isLogisticsTracesLoading = false
      }).catch(() => { this.isLogisticsTracesLoading = false })
    },
    closeLogisticsTrace() {
      this.showLogisticsTraces = false
      this.logisticsTraces = []
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';

.stock-detail-container{
  margin: 15px 15px 100px;
  color: #666666;
  .equipment-item{
    font-size: 1rem;
    background: #f0f9eb;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    margin-bottom: 10px;
    .item{
      margin-bottom: 10px;
    }
    .item:last-child{
      margin-bottom: 0;
    }
    .top-item{
      font-size: 1.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
      font-weight: bold;
    }
    .bottom-item {
      display: flex;
      justify-content: space-between;
      .button{
        font-size: 1rem;
        color: $primaryColor;
      }
    }
  }
}
.logistics-trace-container{
  width: calc(100% - 10px);
  margin-left: 5px;
}
</style>
