<template>
  <div
    v-if="equipmentInfo"
    class="equipment-card"
    :class="{'selected-equipment-card': equipmentInfo.inboundQuantity > 0 || (!isAdded && equipmentInfo.isChecked), 'disabled-equipment-card': isProject && equipmentInfo.quantity === 0}"
  >
    <div class="top-item">
      <div>设备：{{ equipmentInfo.name }} {{ equipmentInfo.code ? `(${equipmentInfo.code })` : '' }}</div>
      <el-button v-if="isAdded" type="danger" icon="el-icon-delete" circle @click="delEquipment" />
      <el-checkbox v-else v-model="equipmentInfo.isChecked" />
    </div>
    <div class="bottom-item">
      <div><span>规格型号：</span>{{ equipmentInfo.specModel || '-' }}</div>
      <div v-if="isProject"><span>可入库数量：</span>{{ equipmentInfo.quantity }}</div>
      <div>
        <span class="top">入库数量：</span>
        <el-input-number v-if="isProject" v-model="equipmentInfo.inboundQuantity" :min="0" :max="equipmentInfo.quantity" :disabled="equipmentInfo.quantity === 0" placeholder="请输入入库数量" :precision="0" />
        <el-input-number v-else v-model="equipmentInfo.inboundQuantity" :min="0" :max="equipmentInfo.isWhole ? 1 : Infinity" placeholder="请输入入库数量" :precision="0" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewEquipmentInboundCard',
  props: {
    isProject: { type: Boolean, default: false },
    isAdded: { type: Boolean, default: false },
    equipmentInfo: { type: Object, default() { return null } }
  },
  data() {
    return {
      checked: false
    }
  },
  methods: {
    delEquipment() {
      this.$emit('del-equipment', this.equipmentInfo.id)
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-card{
  color: #666666;
  font-size: 1rem;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
  margin-bottom: 10px;
  .top-item{
    font-size: 1.1rem;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    div{
      font-weight: bold;
      color: #000000;
      margin-right: 10px;
      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }
    .el-checkbox{
      ::v-deep .el-checkbox__input{
        .el-checkbox__inner{
          width: 1rem;
          height: 1rem;
        }
        .el-checkbox__inner::after{
          top: 0.1rem;
          left: 0.3rem;
        }
      }
    }
  }
  .bottom-item>div{
    margin-bottom: 10px;
  }
  .bottom-item>div:last-child{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    .el-input-number{
      flex: 1;
    }
  }
}

.selected-equipment-card{
  background: #f0f9eb !important;
}

.disabled-equipment-card{
  background: #f4f4f5 !important;
}
</style>
