<template>
  <div class="container">
    <a-scene
      :mindar-image="{imageTargetSrc: mindArImageSrc,uiScanning: 'no'}"
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
    >
      <a-assets>
        <a-asset-item id="bearModel" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/band-example/bear/scene.gltf" />
        <a-asset-item id="raccoonModel" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/band-example/raccoon/scene.gltf" />
      </a-assets>

      <a-camera position="0 0 0" look-controls="enabled: false" />

      <a-entity mindar-image-target="targetIndex: 0">
        <a-gltf-model rotation="0 0 0 " position="0 -0.25 0" scale="0.05 0.05 0.05" src="#raccoonModel" animation-mixer /></a-entity>
      <a-entity mindar-image-target="targetIndex: 1">
        <a-gltf-model rotation="0 0 0 " position="0 -0.25 0" scale="0.05 0.05 0.05" src="#bearModel" animation-mixer /></a-entity>
    </a-scene>
  </div>
</template>

<script>
export default {
  name: 'MultiTargets',
  data() {
    return {
      // mindArImageSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/band.mind'
      mindArImageSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/targets%20%281%29.mind'
    }
  }
}
</script>

<style scoped>
</style>
