<template>
  <el-card v-loading="isLoading">
    <el-descriptions class="check-list-descriptions" :column="2" size="medium" border :label-style="{width:'110px'}">
      <el-descriptions-item>
        <template slot="label">品番</template>
        <span>{{ checklistInfo.nskMaterialCode || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">部品名称</template>
        <span>{{ checklistInfo.nskMaterialName || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">图番</template>
        <span>{{ checklistInfo.nskMaterialIllustrationNumber || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">车型</template>
        <span>{{ checklistInfo.vehicleType || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">供应商</template>
        <span>{{ checklistInfo.supplierName || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">来料日</template>
        <span>{{ checklistInfo.receivingAt || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">检查日</template>
        <span>{{ checklistInfo.checkAt || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">数量</template>
        <span>{{ checklistInfo.quantity || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">批次</template>
        <span>{{ checklistInfo.batchNum || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">模号</template>
        <span>{{ checklistInfo.moduleNumber || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">检查开始时间</template>
        <span>{{ checklistInfo.startAt || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">检查结束时间</template>
        <span>{{ checklistInfo.finishAt || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">检查员</template>
        <span>{{ checklistInfo.staff && checklistInfo.staff.fullName || '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">检查状态</template>
        <el-tag :type="ChecklistStatusStyleCnEnum[checklistInfo.status]">{{ ChecklistStatusCnEnum[checklistInfo.status] || '-' }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">判定结果</template>
        <el-tag v-if="checklistInfo.finishAt" class="status" :type="checklistInfo.isAbnormal ? 'danger' : 'success'">{{ checklistInfo.isAbnormal ? '不合格' : '合格' }}</el-tag>
        <span v-else>{{ '-' }}</span>
      </el-descriptions-item>
      <el-descriptions-item content-class-name="has-operation">
        <template slot="label">备注</template>
        <el-input v-if="isEditRemark" v-model="remark" style="margin-right: 10px" />
        <span v-else>{{ checklistInfo.remark || '-' }}</span>
        <ht-button v-if="isEditRemark" class="edit-button" type="text" icon="el-icon-close" @click="onCancel">取消</ht-button>
        <ht-button class="edit-button" type="text" :icon="isEditRemark ? 'el-icon-circle-check' : 'el-icon-edit'" @click="onEdit">{{ isEditRemark ? '确认' : '编辑' }}</ht-button>
      </el-descriptions-item>
    </el-descriptions>
    <el-row style="text-align: right; padding-top: 20px">
      <ht-button :loading="isExporting" icon="el-icon-document" type="primary" @click="onExport">导出</ht-button>
    </el-row>
    <ht-table ref="list" :data="checklistInfo.checkItems" style="margin-top: 20px">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in NskCheckListViewCheckItemTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '量具'">{{ row[item.prop].toString() }}</span>
          <template v-else-if="item.label === '检查结果'">
            <div v-if="row.specType === '数值测量'">
              <template v-if="row.result && row.result.measureResult && row.result.measureResult.length > 0">
                <span
                  v-for="(result,resultIndex) in getCheckMeasureResult(row.result.measureResult, row.abnormalData)"
                  :key="resultIndex"
                  v-html="`${result}${resultIndex !== getCheckMeasureResult(row.result.measureResult, row.abnormalData).length -1 ? '，' : ''}`"
                />
              </template>
              <template v-else>{{ '-' }}</template>
            </div>
            <span v-else :class="{'red-text': row.result.checkResult === '不合格' }">{{ row.result.checkResult || '-' }}</span>
          </template>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
  </el-card>
</template>

<script>
import { NskCheckListViewCheckItemTitle } from '@/views/incomingInspectionMgr/tableTitle'
import { getNskChecklist, postExportNskChecklist, putNskChecklist } from '@/api/incomingInspectionMgr/nskChecklistMgr'
import {
  ChecklistStatusCnEnum,
  ChecklistStatusStyleCnEnum
} from '@/views/incomingInspectionMgr/enum'

export default {
  name: 'View',
  data() {
    return {
      NskCheckListViewCheckItemTitle, ChecklistStatusCnEnum, ChecklistStatusStyleCnEnum,
      isLoading: false,
      checklistId: null,
      checklistInfo: {
        nskMaterialCode: null,
        nskMaterialName: null,
        nskMaterialIllustrationNumber: null,
        vehicleType: null,
        supplierName: null,
        receivingAt: null,
        checkAt: null,
        quantity: null,
        batchNum: null,
        moduleNumber: null,
        startAt: null,
        finishAt: null,
        staff: null,
        status: null,
        result: null,
        remark: null,
        checkItems: [],
        isAbnormal: null
      },
      remark: null,
      isEditRemark: false,
      isExporting: false
      // 演示刷新页面
      // getChecklistInfoSendTimer: null
    }
  },
  created() {
    if (this.$route.params && this.$route.params.hasOwnProperty('checklistId')) {
      this.checklistId = this.$route.params.checklistId
      this.getChecklistInfo()
      // 演示刷新页面
      // this.getChecklistInfoSendTimer = setInterval(() => {
      //   this.getChecklistInfo()
      // }, 5000)
    }
  },
  // 演示刷新页面
  // beforeDestroy() {
  //   clearInterval(this.getChecklistInfoSendTimer)
  // },
  methods: {
    getChecklistInfo() {
      this.isLoading = true
      getNskChecklist(this.checklistId).then(resp => {
        this.isLoading = false
        this.checklistInfo = resp
        // Object.keys(this.checklistInfo).forEach(key => {
        //   this.checklistInfo[key] = resp[key]
        //
        // })
      }).catch(() => {
        this.isLoading = false
      })
    },
    getCheckMeasureResult(measureResult, abnormalData) {
      const localAbnormalData = abnormalData ? abnormalData.map(value => Number(value)) : []
      if (measureResult.length > 0) {
        return measureResult.map(value => {
          if (localAbnormalData.includes(value)) {
            return `<span class="red-text">${value}</span>`
          } else {
            return `<span>${value}</span>`
          }
        })
      } else {
        return '-'
      }
    },
    onEdit() {
      if (this.isEditRemark) {
        putNskChecklist(this.checklistId, { remark: this.remark }).then(() => {
          this.$message({ message: '修改成功', type: 'success' })
          this.getChecklistInfo()
        }).catch(() => {})
      } else {
        this.remark = this.checklistInfo.remark
      }
      this.isEditRemark = !this.isEditRemark
    },
    onCancel() {
      this.isEditRemark = false
      this.remark = null
    },
    onExport() {
      this.isExporting = true
      this.$message({ message: `正在导出检查单，请稍候`, type: 'warning', center: true })
      postExportNskChecklist(this.checklistId).then((resp) => {
        this.isExporting = false
        window.open(resp.fileUrl)
      }).catch(() => {
        this.isExporting = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.check-list-descriptions{
  ::v-deep .el-descriptions__body{
    .el-descriptions--medium{
      .el-descriptions-row{
        display: flex;
        flex-direction: row;
        .el-descriptions-item__label{
          border: 1px solid #e6ebf5;
          border-bottom: none;
          border-right: none;
        }
        .el-descriptions-item__content{
          border: 1px solid #e6ebf5;
          border-bottom: none;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .has-operation{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    .el-descriptions--medium tbody:last-child{
      .el-descriptions-row{
        .el-descriptions-item__label{
          border: 1px solid #e6ebf5;
          border-right: none;
        }
        .el-descriptions-item__content{
          border: 1px solid #e6ebf5;
        }
      }
    }
  }
}
::v-deep .red-text{
  color: #f56c6c;
}
</style>
