import rtc from '@/views/newRemoteGuidance/rongCloud/rtc'

// 标注指导
export default {
  methods: {
    isHasFreeze() {
      rtc.getRoomAttributes(this.room, this.frozenGuidanceKeys).then(data => {
        if (data.hasOwnProperty('frozenUserId')) {
          this.frozenGuidanceInfo = data
          this.isFreezeGuidance = true
          const index = this.remoteUserList.findIndex(item => item.imUserId === data.frozenUserId)
          if (index !== -1) {
            this.enlargeVideo(index)
            this.showFreezeDialogFun(false, data.frozenImgUrl)
          }
        }
      })
    },
    getFreezeVideoRect() {
      const video = document.getElementById('enlargeVideo')
      const videoRect = video.getBoundingClientRect()
      this.enLargeVideoRect = {
        top: videoRect.top > 0 ? videoRect.top : 60,
        left: videoRect.left,
        width: videoRect.width,
        height: videoRect.height
      }
    },
    getLocalVideoRect() {
      const video = document.getElementById(`user_${this.imUserId}`)
      const videoRect = video.getBoundingClientRect()
      this.enLargeVideoRect = {
        top: videoRect.top,
        left: videoRect.left,
        width: videoRect.width,
        height: videoRect.height
      }
    },
    freezeGuidance() {
      this.isFreezeGuidance = !this.isFreezeGuidance
      if (this.isFreezeGuidance) {
        // 冻屏指导之前关闭实时画面的画笔和激光
        const video = document.getElementById('enlargeVideo')
        this.getFreezeVideoRect()
        this.showFreezeDialogFun(true, video)
      } else {
        rtc.deleteRoomAttributes(this.room, this.frozenGuidanceKeys).then(() => {
          this.frozenGuidanceInfo = null
        })
      }
    },
    showFreezeDialogFun(isVideo = true, value, isExpect = true) {
      this.$nextTick(() => {
        this.$refs.freezeGuidanceRef.showDialog(isVideo, value, isExpect)
      })
    },
    async freezeGuidanceStart(isFrozen, frozenUserId, frozenImgUrl) {
      const freezeData = {
        frozenUserId: this.enLargeVideoInfo.imUserId,
        frozenUserName: this.enLargeVideoInfo.userInfo.userName,
        frozenFullName: this.enLargeVideoInfo.userInfo.fullName,
        frozenImgUrl: frozenImgUrl
      }
      // setRoomAttribute和发送消息不能同时进行，需要先setRoomAttribute然后发送消息
      rtc.setRoomAttribute(this.room, freezeData).then(() => {
        this.frozenGuidanceInfo = freezeData
        this.sendFreezeGuidanceMsg(isFrozen, frozenUserId, frozenImgUrl)
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.isLaserMoving) { this.useLaser() }
            if (this.isPaintDrawing) { this.usePaint() }
            this.sendPaintMsg(false, this.enLargeVideoInfo.imUserId)
            this.sendLaserMsg(false, this.enLargeVideoInfo.imUserId)
          }, 250)
        })
      }).catch(() => {
        this.isFreezeGuidance = false
        this.showFreezeDialogFun()
        rtc.deleteRoomAttributes(this.room, this.frozenGuidanceKeys).then(() => {
          this.frozenGuidanceInfo = null
        })
        this.$message({ message: `标注指导启动失败`, duration: 1500, type: 'error' })
      })
    },
    freezeGuidanceStartFail() {
      this.isFreezeGuidance = false
      this.showFreezeDialogFun()
    },
    freezeGuidanceFinish(isFrozen, frozenUserId, sendFreezeGuidanceMsg = true, freezeResultImgUrl) {
      rtc.deleteRoomAttributes(this.room, this.frozenGuidanceKeys).then(() => {
        this.focusedGuidanceInfo = null
      })
      if (freezeResultImgUrl) {
        this.sendImageMsg(freezeResultImgUrl)
      }
      this.freezeCanvasTrack = {
        isRemoteLaser: false,
        isRemotePaint: false,
        remoteLaserInfo: null,
        remotePaintInfo: null
      }
      this.isFreezeGuidance = false
      this.frozenGuidanceInfo = null
      if (sendFreezeGuidanceMsg) {
        this.sendFreezeGuidanceMsg(isFrozen, frozenUserId)
      }
      this.sendPaintDrawingMsg(undefined, false, frozenUserId)
      this.sendLaserMovingMsg(undefined, false, frozenUserId)
    },
    freezeUseLaser(status, imUserId) {
      if (this.isFreezePaintDrawing) {
        this.isFreezePaintDrawing = false
        this.sendPaintMsg(this.isFreezePaintDrawing, imUserId)
      }
      this.isFreezeLaserMoving = status
      this.sendLaserMsg(this.isFreezeLaserMoving, imUserId)
    },
    freezeUsePaint(status, imUserId) {
      if (this.isFreezeLaserMoving) {
        this.isFreezeLaserMoving = false
        this.sendLaserMsg(this.isFreezeLaserMoving, imUserId)
      }
      this.isFreezePaintDrawing = status
      this.sendPaintMsg(this.isFreezePaintDrawing, imUserId)
    },
    freezeGuidanceByRemoteFun(e) {
      const detail = e.detail
      if (detail.isFrozen) {
        rtc.getRoomAttributes(this.room, this.frozenGuidanceKeys).then(data => {
          this.frozenGuidanceInfo = data
          if (this.isExpert(this.userInfo)) {
            this.isFreezeGuidance = detail.isFrozen
            const index = this.remoteUserList.findIndex(item => item.imUserId === this.frozenGuidanceInfo.frozenUserId)
            if (index !== -1) {
              this.enlargeVideo(index)
              this.getFreezeVideoRect()
              this.showFreezeDialogFun(false, detail.frozenImgUrl)
            }
          } else if (this.frozenGuidanceInfo.frozenUserId === this.userImInfo.imUserId) {
            // todo
            this.isFrozenGuidanceByRemote = detail.isFrozen
            this.getLocalVideoRect()
            this.showFreezeDialogFun(false, detail.frozenImgUrl, false)
          }
        })
      } else {
        if (this.isExpert(this.userInfo) || this.frozenGuidanceInfo.frozenUserId === this.userImInfo.imUserId) {
          this.isFreezeGuidance = false
          this.frozenGuidanceInfo = null
          this.isFrozenGuidanceByRemote = false
          this.showFreezeDialogFun()
        }
      }
    }
  }
}
