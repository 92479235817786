<template>
  <el-dialog
    :class="{'hidden-close-dialog': hiddenClose, 'black-background-dialog': isBlackBackground}"
    :modal="true"
    :visible.sync="dialogVisible"
    :width="width"
    :close-on-click-modal="closeOnClickModal"
    :fullscreen="fullscreen"
    :custom-class="customClass"
    append-to-body
    class="dialog"
    @close="onClose"
  >
    <template slot="title">
      <div class="title-box">
        <div class="title-span"><span :style="{background: color}" /></div>
        <div class="title-content" :style="{color: color}">{{ title }}</div>
      </div>
      <div class="title-middle-container">
        <slot name="title-middle" />
      </div>
      <div class="close-button-container">
        <slot name="close" />
      </div>
    </template>
    <slot name="content" />
  </el-dialog>
</template>

<script>
import variables from '@/styles/variables.scss'

export default {
  props: {
    title: { type: String, default: '' },
    width: { type: String, default: '30%' },
    hiddenClose: { type: Boolean, default: false },
    color: { type: String, default: variables.primaryColor },
    isBlackBackground: { type: Boolean, default: false },
    closeOnClickModal: { type: Boolean, default: true },
    fullscreen: { type: Boolean, default: false },
    customClass: { type: String, default: '' }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.title-box {
  height: 50px;
  line-height: 50px;
  .title-span {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    vertical-align: top;
    margin-left: 12px;
    margin-right: 12px;
    span {
      display: inline-block;
      height: 25px;
      width: 5px;
      margin-top: 13px;
    }
  }
  .title-content {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    font-size: 25px;
    font-weight: 500;
    color: $primaryColor;
  }
}
::v-deep .el-dialog__body{
  padding: 10px 30px 30px 30px;
}

::v-deep .el-dialog__headerbtn{
  font-size: 30px !important;
}

.hidden-close-dialog{
  ::v-deep .el-dialog__headerbtn{
    display: none;
  }
}

.black-background-dialog{
  background: #000000;
}

.close-button-container{
  position: absolute;
  top: 20px;
  right: 30px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
