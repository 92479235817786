var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { attrs: { label: _vm.label, prop: _vm.prop } },
    [
      _c("el-input", {
        staticStyle: { display: "none" },
        attrs: { readonly: true },
        model: {
          value: _vm.form[_vm.prop],
          callback: function ($$v) {
            _vm.$set(_vm.form, _vm.prop, $$v)
          },
          expression: "form[prop]",
        },
      }),
      _vm._v(" "),
      [
        _vm.editMode
          ? _c("ht-upload-button", {
              attrs: { "file-type": "all", "key-prefix": _vm.keyPrefix },
              on: { "on-file-uploaded": _vm.onFileUploaded },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.form[_vm.prop] && _vm.editMode
          ? _c(
              "ht-popover-button",
              {
                attrs: {
                  type: "danger",
                  icon: "el-icon-delete",
                  "confirm-title": "是否删除?",
                },
                on: { confirmed: _vm.onDeleteFile },
              },
              [_vm._v("\n      删除\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.form[_vm.prop]
          ? _c("ht-download-link", {
              staticClass: "download-link",
              attrs: { "link-url": _vm.form[_vm.prop], "link-title": "下载" },
            })
          : _vm._e(),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }