const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  isMobile: state => state.app.isMobile,
  isWeChat: state => state.app.isWeChat,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  userInfo: state => state.user.userInfo,
  userRoutes: state => state.user.userRoutes,
  userImInfo: state => state.user.userImInfo,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  isBeingInvited: state => state.user.isBeingInvited,
  imIsConnected: state => state.user.imIsConnected,
  isInMeeting: state => state.user.isInMeeting,
  isSinoma: state => state.user.isSinoma,
  isHasPatrolMenu: state => state.user.isHasPatrolMenu,
  isHasRepairMenu: state => state.user.isHasRepairMenu,
  isPasswordRemembered: state => state.user.isPasswordRemembered,
  activatedTab: state => state.tagsView.activatedTab
}
export default getters
