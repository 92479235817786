class BaseResultsClass {
  constructor(results) {
    this.uploadPhotos = results.uploadPhotos
    this.factDesc = results.factDesc
  }
}

export class ResultRecordTypeResultsClass extends BaseResultsClass {
  constructor(results) {
    super(results)
    this.checkResult = results.checkResult
  }
}

const BasePatrolResult = {
  location: null,
  uploadPhotos: [],
  factDesc: null
}

export const ResultRecordTypeResults = {
  ...BasePatrolResult,
  checkResult: null
}

export const UniversalRecordTypeResults = {
  ...BasePatrolResult
}

export const ValueRecordTypeResults = {
  ...BasePatrolResult,
  value: null
}

export const MaintenanceAidTypeResults = {
  errorRecords: []
}

export const MaintenanceRecordTypeResults = {

}
