var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step-ui-detail-container", style: { height: _vm.height } },
    [
      _c("div", { staticClass: "view-container" }, [
        _c("div", { staticClass: "top-section" }, [
          _c("div", { staticClass: "view-title top-title" }, [
            _vm._v(_vm._s(_vm.properties.deviceName || "设备名称")),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "top-button-container" },
            [
              _c(
                "el-button",
                {
                  staticClass: "top-title-button more-function-button",
                  attrs: { type: "text" },
                  on: { click: _vm.moreFunction },
                },
                [_vm._v("更多功能")]
              ),
              _vm._v(" "),
              _vm.showMoreFunction
                ? _c(
                    "div",
                    { staticClass: "more-function-container" },
                    [
                      _c("img", {
                        staticClass: "more-function-img",
                        attrs: { src: _vm.Triangle, alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "top-title-button guidance-button",
                          attrs: { type: "text" },
                          on: { click: _vm.toRemoteGuidance },
                        },
                        [_vm._v("远程指导")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "top-title-button ai-button",
                          attrs: { type: "text" },
                          on: { click: _vm.toAiQaAssistant },
                        },
                        [_vm._v("问答助手")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "top-sub-section" }, [
          _vm._v(
            "设备位置：" + _vm._s(_vm.properties.deviceLocation || "暂无")
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "middle-section" }, [
          _c("div", { staticClass: "left-container" }, [
            _c("div", { staticClass: "sub-title only-text-title" }, [
              _vm._v("设备图片"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img-container reference-img-container" },
              [
                _vm.properties.deviceImg
                  ? _c("dw-el-image", {
                      ref: "deviceImg",
                      staticClass: "result-img",
                      attrs: {
                        src: _vm.properties.deviceImg,
                        alt: "",
                        "preview-src-list": [_vm.properties.deviceImg],
                        "show-icon": false,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.properties.deviceImg
                  ? _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button fullscreen-button",
                        on: { click: _vm.fullScreenPhoto },
                      },
                      [_vm._v("查看设备图片")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-container" },
            [
              _c("div", { staticClass: "sub-title only-text-title" }, [
                _vm._v("设备数据"),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "check-standard",
                staticStyle: { width: "100%", height: "100%" },
                attrs: { placeholder: "检查要求", type: "textarea" },
                model: {
                  value: _vm.properties.deviceDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.properties, "deviceDesc", $$v)
                  },
                  expression: "properties.deviceDesc",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom-section" }, [
          _c("div", { staticClass: "top-container" }, [
            _c("div", { staticClass: "full-line-container" }, [
              _c("div", { staticClass: "title-container" }, [
                _c("div", { staticClass: "sub-title only-text-title" }, [
                  _vm._v(
                    "报警故障代码：" +
                      _vm._s(
                        _vm.results.errorRecords
                          ? _vm.results.errorRecords.length
                          : 0
                      ) +
                      "条"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "trouble-container",
                    attrs: { id: "troubleContainer" },
                  },
                  _vm._l(_vm.results.errorRecords, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "trouble-item" },
                      [
                        _c("div", { staticClass: "trouble-item-code" }, [
                          _vm._v("故障代码：" + _vm._s(item.equipmentCode)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.results.errorRecords && _vm.results.errorRecords.length > 3
                  ? _c(
                      "div",
                      { staticClass: "content-button-container" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "content-button up-button",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.scrollButtonClick(-80)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "button-label" }, [
                              _vm._v("向上"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "content-button down-button",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.scrollButtonClick(80)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "button-label" }, [
                              _vm._v("向下"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c(
                "el-button",
                {
                  staticClass: "record-button bottom-button",
                  attrs: { type: "text" },
                  on: { click: _vm.toRepairRecord },
                },
                [_vm._v("维修记录")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }