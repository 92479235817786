var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "roleForm",
          staticStyle: { "margin-right": "20px" },
          attrs: {
            model: _vm.roleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入角色名称" },
                model: {
                  value: _vm.roleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "name", $$v)
                  },
                  expression: "roleForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "远程指导类型：", prop: "remoteGuidanceRole" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.isDefaultRole,
                    placeholder: "请选择远程指导类型",
                  },
                  model: {
                    value: _vm.roleForm.remoteGuidanceRole,
                    callback: function ($$v) {
                      _vm.$set(_vm.roleForm, "remoteGuidanceRole", $$v)
                    },
                    expression: "roleForm.remoteGuidanceRole",
                  },
                },
                _vm._l(_vm.RemoteGuidanceRoleTypeEnum, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#f56c6c" } }, [
                _vm._v("远程指导的”联系人“菜单WEB端仅对专家开放"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色描述：", prop: "desc" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入角色描述" },
                model: {
                  value: _vm.roleForm.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.roleForm, "desc", $$v)
                  },
                  expression: "roleForm.desc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-left": "30%" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "ht-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "ht-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.submitRoleFormFun("roleForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }