<template>
  <el-card v-loading="isLoading">
    <el-form
      ref="templateForm"
      class="left-container"
      :model="templateForm"
      :rules="templateFormRules"
      label-width="120px"
      inline
    >
      <el-row class="line-three-item-row">
        <el-form-item label="表番：" prop="code" label-width="80px">
          <el-input v-model="templateForm.code" placeholder="请输入表番" :maxlength="30" show-word-limit @change="changedItem" />
        </el-form-item>
        <el-form-item label="表名：" prop="name" label-width="80px">
          <el-input v-model="templateForm.name" placeholder="请输入表名" :maxlength="30" show-word-limit @change="changedItem" />
        </el-form-item>
        <el-form-item label="关联部品：" prop="nskMaterialId" label-width="100px">
          <el-select
            v-model="templateForm.nskMaterialId"
            filterable
            :placeholder="nskMaterialList.length > 0 ? '请选择部品' : '当前无可选部品'"
            clearable
            style="width: 100%"
            remote
            :remote-method="getNskMaterialsList"
            @change="changedItem"
            @clear="templateForm.nskMaterialId = null"
          >
            <el-option v-for="item in nskMaterialList" :key="item.id" :label="`${item.name}${item.code}`" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
    <ht-table ref="list" :data="templateForm.steps" style="margin-top: 10px">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in NskCheckTemplateStepTitle"
        :key="item.prop"
        :prop="item.prop"
        :min-width="item.width || 100"
        :class-name="item.hasMultiple ? 'has-multiple' : ''"
        :show-overflow-tooltip="false"
      >
        <template v-slot:header>
          <span :class="{'is-required-title': item.isRequired}">{{ item.isRequired ? `*${item.label}` : item.label }}</span>
        </template>
        <template v-slot="{row, $index}">
          <el-select v-if="item.label=== '检查类型'" v-model="row.specType" placeholder="检查类型" @change="changedItem('检查类型')">
            <el-option v-for="(listItem, itemIndex) in specTypeList" :key="itemIndex" :label="listItem.value" :value="listItem.name" />
          </el-select>
          <el-select v-else-if="item.label=== '特性'" v-model="row.character" placeholder="特性" @change="changedItem">
            <el-option v-for="(listItem, itemIndex) in characterList" :key="itemIndex" :label="listItem.value" :value="listItem.name" />
          </el-select>
          <template v-else-if="item.label=== '规格说明'">
            <el-input v-model="row.specDesc" placeholder="规格说明" type="textarea" :autosize="{ minRows: 1, maxRows: 3}" :maxlength="30" show-word-limit @change="changedItem" />
          </template>
          <template v-else-if="item.label === '下公差-标准尺寸-上公差'">
            <template v-if="row.specType === 'NUM_MEASUREMENT'">
              <dw-input-number v-model="row.lowerTolerance" placeholder="下公差" :min="0" :max="9999" :precision="2" :controls="false" @change="changedItem" />
              <!--              <el-input v-model="row.lowerTolerance" placeholder="下公差" type="number" step="0.01" @input="handleInput(row.lowerTolerance, 'lowerTolerance', row)" @change="changedItem" />-->
              <span class="divider">-</span>
              <dw-input-number v-model="row.standardValue" placeholder="标准尺寸" :min="0" :max="9999" :precision="2" :controls="false" @change="changedItem" />
              <!--              <el-input v-model="row.standardValue" placeholder="标准尺寸" type="number" step="0.01" @input="handleInput(row.standardValue, 'standardValue', row)" @change="changedItem" />-->
              <span class="divider">-</span>
              <dw-input-number v-model="row.upperTolerance" placeholder="上公差" :min="0" :max="9999" :precision="2" :controls="false" @change="changedItem" />
              <!--              <el-input v-model="row.upperTolerance" placeholder="上公差" type="number" step="0.01" @input="handleInput(row.upperTolerance, 'upperTolerance', row)" @change="changedItem" />-->
            </template>
            <span v-else>{{ '-' }}</span>
          </template>
          <el-select v-else-if="item.label=== '量具'" v-model="row.measuringTools" multiple placeholder="量具" @change="changedItem">
            <el-option v-for="(listItem, itemIndex) in toolList" :key="itemIndex" :label="listItem.value" :value="listItem.name" />
          </el-select>
          <el-select v-else-if="item.label=== '等级'" v-model="row.level" placeholder="等级" @change="changedItem">
            <el-option v-for="(listItem, itemIndex) in levelList" :key="itemIndex" :label="listItem.value" :value="listItem.name" />
          </el-select>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="80">
        <template v-slot="{row, $index}">
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该检查项？" @confirmed="onDeleteStep($index)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <el-row class="row-item item-right">
      <ht-button type="success" icon="el-icon-plus" @click="onAddStep">添加检查项</ht-button>
    </el-row>
    <el-row class="row-item item-center">
      <el-button icon="el-icon-close" @click="cancelSubmit">返回</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('templateForm')">保存</el-button>
    </el-row>
  </el-card>
</template>

<script>
import {
  LevelObjectEnum
} from '@/views/incomingInspectionMgr/enum'
import movePng from '@/views/systemMgr/menuMgr/move.png'
import { getNskTemplate, postNskTemplates, putNskTemplate, getTemplateEnum } from '@/api/incomingInspectionMgr/nskTemplateMgr'
import { NskCheckTemplateStepTitle } from '@/views/incomingInspectionMgr/tableTitle'
import { getNskMaterials } from '@/api/incomingInspectionMgr/nskMaterialMgr'
import { validateNumOrLetterOrSymbol } from '@/utils/validate'
import DwInputNumber from '@/components/DwInputNumber/index'

export default {
  name: 'TemplateView',
  components: { DwInputNumber },
  props: {
    isEdit: { type: Boolean, default: false },
    templateId: { type: [String, Number], default: null }
  },
  data() {
    return {
      movePng,
      NskCheckTemplateStepTitle,
      isLoading: false,
      templateForm: {
        code: null,
        name: null,
        nskMaterialId: null,
        steps: [
          {
            specType: null,
            character: null,
            measuringTools: [],
            level: null,
            specDesc: null,
            standardValue: null,
            upperTolerance: null,
            lowerTolerance: null
          }
        ]
      },
      templateFormRules: {
        code: [
          { required: true, message: '请输入表番', trigger: 'blur' },
          { message: '请输入数字、字母、或符号（./-_:）', trigger: 'blur', validator: validateNumOrLetterOrSymbol }
        ],
        name: [{ required: true, message: '请输入表名', trigger: 'blur' }]
      },
      nskMaterialList: [],
      specTypeList: [],
      characterList: [],
      toolList: [],
      levelList: [],
      isSubmitting: false
    }
  },
  created() {
    this.getNskMaterialsList()
    this.getEnumList()
  },
  methods: {
    // el-input输入数字的一些处理
    handleInput(value, name, row, minValue = 0, maxValue = 99999, precision = 2) {
      if (!/^\d*\.?\d*$/.test(value)) {
        // 如果不是数字，则清空输入框
        row[name] = null
      } else {
        if (value < minValue) { // 最小值
          row[name] = minValue
        } else if (value > maxValue) { // 最大值处理
          row[name] = parseFloat(value.toString().slice(0, -1))
        } else {
          if (this.hasMoreThanTwoDecimals(value, precision)) { // 小数精度树立
            row[name] = parseFloat(value).toFixed(precision)
          } else {
            row[name] = value
          }
        }
      }
    },
    hasMoreThanTwoDecimals(number, precision) {
      // 将数字转换为字符串
      const numberStr = number.toString()
      // 找到小数点在字符串中的位置
      const decimalIndex = numberStr.indexOf('.')
      // 如果没有小数点，直接返回false
      if (decimalIndex === -1) {
        return false
      }
      // 计算小数点之后字符串的长度
      var decimalPartLength = numberStr.length - decimalIndex - 1
      // 判断这个长度是否大于number
      return decimalPartLength > precision
    },
    getTemplateInfo() {
      this.isLoading = true
      getNskTemplate(this.templateId).then(resp => {
        this.isLoading = false
        this.templateForm.code = resp.code
        this.templateForm.name = resp.name
        this.templateForm.nskMaterialId = resp.nskMaterialId
        this.templateForm.steps = resp.steps
      }).catch(() => {
        this.isLoading = false
      })
    },
    getNskMaterialsList(value) {
      const listQuery = {
        offset: 0,
        count: 20,
        keyword: value
      }
      getNskMaterials(listQuery).then(resp => {
        this.nskMaterialList = resp.filter(item => item.nskTemplateId === null)
      })
    },
    getEnumList() {
      this.levelList = LevelObjectEnum
      getTemplateEnum().then(resp => {
        this.specTypeList = resp.specType
        this.characterList = resp.character
        this.toolList = resp.measuringTools
      })
    },
    onAddStep() {
      const step = {
        specType: null,
        character: null,
        measuringTools: [],
        level: null,
        specDesc: null,
        standardValue: null,
        upperTolerance: null,
        lowerTolerance: null
      }
      this.templateForm.steps.push(step)
    },
    onDeleteStep(index) {
      this.isDataSaved = false
      this.templateForm.steps.splice(index, 1)
    },
    changedItem(value) {
      this.isDataSaved = false
      console.log(this.test)
    },
    cancelSubmit() {
      if (!this.isDataSaved) {
        this.$confirm('修改内容未保存，请确认是否返回?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.$router.back()
        }).catch(() => {})
      } else {
        this.$router.back()
      }
    },
    confirmSubmit(formName) {
      this.isDataSaved = true
      const templateData = this.getTemplateData()
      if (this.templateForm.steps.length > 0) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            if (this.verifyAllStepsData(templateData)) {
              this.isSubmitting = true
              if (this.isEdit) {
                putNskTemplate(this.templateId, templateData).then(resp => {
                  this.$message({ message: `保存成功`, type: 'success', center: true })
                  this.isSubmitting = false
                }).catch(() => {
                  this.isSubmitting = false
                })
              } else {
                postNskTemplates(this.templateForm).then(resp => {
                  this.$message({ message: `保存成功`, type: 'success', center: true })
                  this.isSubmitting = false
                }).catch(() => {
                  this.isSubmitting = false
                })
              }
            }
          } else {
            this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
            return false
          }
        })
      } else {
        this.$message({ message: `请添加至少一个检查项`, type: 'error', center: true })
      }
    },
    getTemplateData() {
      const steps = []
      this.templateForm.steps.forEach((item, index) => {
        const step = {
          specType: item.specType,
          character: item.character,
          measuringTools: item.measuringTools,
          level: item.level,
          specDesc: item.specDesc,
          standardValue: item.specType === 'NUM_MEASUREMENT' ? item.standardValue || item.standardValue === 0 ? parseFloat(item.standardValue) : null : null,
          upperTolerance: item.specType === 'NUM_MEASUREMENT' ? item.upperTolerance || item.upperTolerance === 0 ? parseFloat(item.upperTolerance) : null : null,
          lowerTolerance: item.specType === 'NUM_MEASUREMENT' ? item.lowerTolerance || item.lowerTolerance === 0 ? parseFloat(item.lowerTolerance) : null : null,
          isStart: index === 0,
          isEnd: index === this.templateForm.steps.length - 1,
          sort: index + 1
        }
        steps.push(step)
      })
      return {
        code: this.templateForm.code,
        name: this.templateForm.name,
        nskMaterialId: this.templateForm.nskMaterialId,
        steps: steps
      }
    },
    verifyAllStepsData(templateData) {
      const errorSteps = []
      templateData.steps.forEach((step, index) => {
        if (this.verifyStepData(step, index)) {
          errorSteps.push(this.verifyStepData(step, index))
        }
      })
      if (errorSteps.length > 0) {
        this.$message({ message: `当前检查模版${errorSteps.toString()}`, duration: 2000, type: 'error' })
        return false
      } else {
        return true
      }
    },
    verifyStepData(data, index) {
      const specTypeErrorMsg = data.specType ? null : `检查类型`
      const hasCharacterErrorMsg = data.character ? null : `特性`
      const hasStandard = this.getSpecificationDescription(data)
      const hasMeasuringToolsErrorMsg = data.measuringTools.length > 0 ? null : `量具`
      const hasLevelErrorMsg = data.level ? null : `等级`
      const errorArr = [specTypeErrorMsg, hasCharacterErrorMsg, hasStandard, hasMeasuringToolsErrorMsg, hasLevelErrorMsg].filter(item => item !== null)
      if (errorArr.length > 0) {
        return `第${index + 1}个检查项${errorArr.toString()}未填写`
      } else {
        return null
      }
    },
    getSpecificationDescription(data) {
      if (data.specType === 'NUM_MEASUREMENT') {
        return data.standardValue !== null ? null : '下公差-标准尺寸-上公差'
      } else {
        return data.specDesc ? null : '规格说明'
      }
    }
  }
}
</script>

<style scoped lang="scss">

.line-three-item-row{
  display: flex;
  justify-content: space-between;
  .el-form-item{
    width: calc(33% - 10px); /* 每个项目的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;

    ::v-deep .el-form-item__content{
      width: calc(100% - 100px);
      .el-select, .el-input, .el-input-number{
        width: 100%;
      }
    }
  }
}
.is-required-title{
  color: #ff4949;
}

.el-table{
  ::v-deep .el-table__body{
    .el-table__row .has-multiple .cell{
      display: flex;
      flex-direction: row;
      justify-content: center;
      .el-input{
        flex: 1;
        .el-input__inner{
          padding-right: 0;
          padding-left: 0;
        }
      }
      .divider{
        margin: 0 5px;
      }
    }
  }
}

.row-item{
  margin-top: 20px;
}

.item-right{
  text-align: right;
}

.item-center{
  text-align: center;
}
</style>
