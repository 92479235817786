<template>
  <div class="view-container">
    <div class="top-section">
      <div class="view-title top-title">拣货-领取任务</div>
    </div>
    <div class="result-container" :style="{height: height + 'px'}">
      <div class="result-top-container">
        <div class="result-top-left-container" title="hf_use_description">
          <el-button class="result-button" type="text" :disabled="buttonDisabled" title="区域" @click="openChooseItemDialog('区域')">
            <span class="required-item">*</span>区域:
          </el-button>
          <el-input ref="rectDescRef" v-model="submitResults.areaName" placeholder="请选择区域" class="result-input" disabled />
        </div>
        <div class="result-top-right-container" title="hf_use_description">
          <el-button class="result-button" type="text" :disabled="buttonDisabled" title="楼层" @click="openChooseItemDialog('楼层')">
            <span class="required-item">*</span>楼层:
          </el-button>
          <el-input ref="rectDescRef" v-model="submitResults.floorName" placeholder="请选择楼层" class="result-input" disabled />
        </div>
      </div>
      <div class="result-bottom-container">
        <div class="result-middle-top-container">
          <span class="title txt task">任务组：{{ submitResults.taskCode }}</span>
          <span class="title txt goods">货品总数：{{ submitResults.goods.length }}</span>
          <div class="box">
            <el-button class="result-button" type="text" :disabled="buttonDisabled" title="容器号" @click="handleActions('scan-box-bar-code')">
              <span class="required-item">*</span>容器号:
            </el-button>
            <el-input ref="rectDescRef" v-model="submitResults.boxCode" placeholder="请扫描容器号" class="result-input" disabled>
              <svg-icon slot="append" icon-class="scan" />
            </el-input>
          </div>
        </div>
        <div class="result-middle-bottom-container">
          <div v-for="(item, index) in submitResults.goods" :key="index" class="box-item">
            货品：{{ item.code }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <el-button class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-pick')">去拣货</el-button>
    </div>

    <custom-dialog ref="chooseItemDialog" :title="`选择${dialogTitle}`" width="500px" color="#2e5aff" @close="closeChooseItemDialog">
      <template slot="content">
        <el-radio-group v-model="chooseItem" @change="closeChooseItemDialog">
          <el-radio v-for="(item, key) in chooseItems" :key="key" :label="item">{{ item.name }}</el-radio>
        </el-radio-group>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import CustomDialog from '@/views/taskMgr/newTemplateMgr/components/customDialog'
import { AreaList } from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/templateData'

export default {
  name: 'GetGoodsPicking',
  components: { CustomDialog },
  props: {
    height: { type: Number, default: 480 },
    results: {
      type: Object, default() {
        return {
          area: null,
          areaName: null,
          floor: null,
          floorName: null,
          task: null,
          taskCode: null,
          goods: [],
          boxCode: null
        }
      }
    }
  },
  data() {
    return {
      buttonDisabled: this.disabled,
      submitResults: this.results,
      dialogTitle: null,
      chooseItem: null,
      chooseItems: AreaList
    }
  },
  watch: {
    results: {
      handler: function() {
        this.submitResults = this.results
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleActions(action) {
      switch (action) {
        case 'scan-box-bar-code':
          this.$emit('to-scan-code', 'goods-box', this.submitResults)
          break
        case 'to-pick':
          if (!(this.submitResults.area && this.submitResults.areaName)) {
            this.$message({ message: `请选择区域`, duration: 2000, type: 'error', customClass: 'large-info-message' })
            break
          }
          if (!(this.submitResults.floor && this.submitResults.floorName)) {
            this.$message({ message: `请选择楼层`, duration: 2000, type: 'error', customClass: 'large-info-message' })
            break
          }
          if (!(this.submitResults.task && this.submitResults.taskCode && this.submitResults.goods.length > 0)) {
            this.$message({ message: `当前无拣货任务`, duration: 2000, type: 'error', customClass: 'large-info-message' })
            break
          }
          if (!this.submitResults.boxCode) {
            this.$message({ message: `请扫描容器号`, duration: 2000, type: 'error', customClass: 'large-info-message' })
            break
          }
          this.$emit('to-pick', 'task', this.submitResults)
          break
      }
    },
    openChooseItemDialog(name) {
      if (name === '区域') {
        this.dialogTitle = name
        this.chooseItems = AreaList
        this.chooseItem = this.submitResults.area
        if (this.submitResults.area && this.submitResults.area.id) {
          const areaIndex = this.chooseItems.findIndex(item => item.id === this.submitResults.area.id)
          if (areaIndex !== -1) {
            this.chooseItem = this.chooseItems[areaIndex]
          }
        }
        this.$refs.chooseItemDialog.dialogVisible = true
      } else if (name === '楼层') {
        if (this.submitResults.area) {
          this.dialogTitle = name
          this.chooseItems = this.submitResults.area.floors
          this.chooseItem = this.submitResults.floor
          if (this.submitResults.floor && this.submitResults.floor.id) {
            const areaIndex = this.chooseItems.findIndex(item => item.id === this.submitResults.floor.id)
            if (areaIndex !== -1) {
              this.chooseItem = this.chooseItems[areaIndex]
            }
          }
          this.$refs.chooseItemDialog.dialogVisible = true
        } else {
          this.$message({ message: `请先选择区域`, duration: 2000, type: 'error', customClass: 'large-info-message' })
        }
      }
    },
    closeChooseItemDialog() {
      if (this.dialogTitle === '区域') {
        if (this.chooseItem) {
          this.submitResults.area = this.chooseItem
          this.submitResults.areaName = this.chooseItem.name
          this.submitResults.floor = null
          this.submitResults.floorName = null
          this.submitResults.task = []
          this.submitResults.taskCode = null
          this.submitResults.goods = []
          this.submitResults.boxCode = null
          this.$emit('choose-area', this.submitResults)
        }
      } else if (this.dialogTitle === '楼层') {
        if (this.chooseItem) {
          this.submitResults.floor = this.chooseItem
          this.submitResults.floorName = this.chooseItem.name
          if (this.chooseItem.tasks.length > 0) {
            this.submitResults.task = this.chooseItem.tasks[0]
            this.submitResults.taskCode = this.chooseItem.tasks[0].code
            this.submitResults.goods = this.submitResults.task.goods
            this.$emit('choose-floor', this.submitResults)
          } else {
            this.$message({ message: `当前楼层暂无拣货任务`, duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
        }
      }
      this.buttonDisabled = false
      this.$refs.chooseItemDialog.dialogVisible = false
    },
    getTtsText() {
      if (this.submitResults.areaName && this.submitResults.floorName) {
        return `任务号${this.submitResults.taskCode}、货品总数${this.submitResults.goods.length}、请扫描容器号`
      }
      if (!this.submitResults.areaName && !this.submitResults.floorName) {
        return '请选择区域和楼层'
      }
      if (!this.submitResults.areaName) {
        return '请选择区域'
      }
      if (!this.submitResults.floorName) {
        return '请选择楼层'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";

.view-container{
  .result-container{
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .result-top-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0 0;
      .result-top-left-container, .result-top-right-container{
        flex: 1;
        display: flex;
        flex-direction: row;
        .result-button{
          font-size: 1.5rem;
          color: #000000;
          font-weight: bold;
          padding: 0 10px 0 0 ;
        }
      }
      .result-top-left-container{
        margin-right: 10px;
      }
      .result-input{
        flex: 1;
      }
      ::v-deep .el-input__inner{
        height: 100%;
        color: #000000;
        font-size: 1.3rem;
        border: none;
        background: #ffffff;
        line-height: normal;
      }
      ::v-deep .el-input-group__append{
        height: 100%;
        color: #000000;
        font-size: 1.5rem;
        border: none;
        background: #ffffff;
        line-height: normal;
        .svg-icon{
          color: #aaaaaa;
        }
      }
    }
    .result-bottom-container{
      flex: 1;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding: 10px;
      overflow: hidden;
      .result-middle-top-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .txt{
          font-size: 1.3rem;
          margin-right: 10px;
        }
        .box{
          width: 40%;
          display: flex;
          flex-direction: row;
          .result-button{
            font-size: 1.3rem;
            color: #000000;
            padding: 0 10px 0 0 ;
          }
          .el-input{
            border: 1px solid #dddddd;
            border-radius: 5px;
          }
          ::v-deep .el-input__inner{
            height: 100%;
            color: #000000;
            font-size: 1.3rem;
            background: #ffffff;
            line-height: normal;
            border: none;
          }
          ::v-deep .el-input-group__append{
            height: 100%;
            color: #000000;
            font-size: 1.3rem;
            border: none;
            background: #ffffff;
            line-height: normal;
            .svg-icon{
              color: #aaaaaa;
            }
          }
        }
      }
      .result-middle-bottom-container{
        overflow: hidden;
        margin: 10px 20px 0;
        .box-item{
          //flex: 0 0 50%; /* 子元素占据容器宽度的50% */
          font-size: 1.2rem;
          padding: 10px 0;
          .del-button{
            margin-left: 20px;
            color: #f56c6c;
          }
        }
      }
    }
  }

  .bottom-container{
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    .square-circle-button {
      flex: 1;
      height: 40px;
      color: #ffffff;
      background: $mobilePrimaryColor;
      border-radius: 5px;
      font-size: 1.5rem;
    }
  }
}

.el-radio-group{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 30px;
  .el-radio{
    flex-basis: 50%;
    margin-right: 0;
    font-size: 1.3rem;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ::v-deep .el-radio__input{
      font-size: 1.3rem;
      .el-radio__inner{
        font-size: 1.3rem;
      }
      .el-radio__inner{
        width: 20px;
        height: 20px;
      }
    }
    ::v-deep .el-radio__label{
      font-size: 1.3rem;
    }
  }
}
</style>
