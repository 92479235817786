var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _vm.showReturnButton
      ? _c(
          "div",
          {
            staticClass: "mobile-hamburger-container",
            staticStyle: { "padding-left": "5px" },
            on: { click: _vm.clickHamburger },
          },
          [
            _c(
              "svg",
              {
                staticClass: "hamburger-svg",
                attrs: {
                  viewBox: "0 0 1024 1024",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "64",
                  height: "64",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M694.272 809.024l-295.808-286.848 295.36-274.752a32 32 0 0 0-43.616-46.848l-320 297.696a32 32 0 0 0-0.512 46.4l320 310.304a32.032 32.032 0 0 0 44.576-45.952",
                    fill: "#3D3A39",
                    "p-id": "4184",
                  },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "title-container" }, [
      _vm._v(_vm._s(_vm.routerTitle || _vm.title)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }