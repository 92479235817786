<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import echarts from 'echarts'
import { debounce } from '@/utils'

export default {
  name: 'RepairCountChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true },
    firstName: { type: String, default: '' },
    secondName: { type: String, default: '' },
    xUnit: { type: String, default: '时间' },
    yUnit: { type: String, default: '数量' },
    color1: { type: String, default: '#589EF6' },
    color2: { type: String, default: '#ee7f4b' }
  },
  data() {
    return {
      chart: null,
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: [this.firstName, this.secondName],
          bottom: 0
        },
        grid: {
          top: '12%',
          left: '5%',
          right: '13%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          name: this.xUnit,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          type: 'category',
          axisTick: { show: false },
          data: []
        },
        yAxis: {
          name: this.yUnit,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            show: false // 坐标轴线
          },
          axisTick: {
            show: false // 刻度线
          },
          axisLabel: {
            formatter: function(value) {
              if (value % 1 === 0) {
                return value
              } else {
                return ''
              }
            }
          }
        },
        series: [
          {
            name: this.firstName,
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            // label: { // 柱状图上的数据
            //   show: true,
            //   fontSize: 14,
            //   fontWeight: 'bold'
            // },
            itemStyle: {
              normal: {
                color: this.color1
              }
            },
            data: []
          },
          {
            name: this.secondName,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            // label: { // 柱状图上的数据
            //   show: true,
            //   fontSize: 14,
            //   fontWeight: 'bold'
            // },
            itemStyle: {
              normal: {
                color: this.color2
              }
            },
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(time, finishedCounts, finishedAbnormalCounts) {
      this.option.xAxis.data = time
      this.option.series[0].data = finishedCounts
      this.option.series[1].data = finishedAbnormalCounts
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
