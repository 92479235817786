<template>
  <div
    class="remote-video-wrapper"
    :class="{
      'focused-container': isFocusedGuide,
      'is-screen-container': isScreen,
      'fullscreen-mobile-remote-video-wrapper': isFullscreen && isMobile
    }"
  >
    <player-view
      :id="id"
      :video-size="getVideoSize"
      :is-expert="isExpert()"
      :is-android="isAndroid()"
      :is-share-video="isShareVideo"
      :is-remote-muted="isRemoteMuted"
      :video-track="videoTrack"
      :is-screen="isScreen"
      show-top-tools
      is-remote-video
      :show-focus-canvas="false"
      :is-large-video="isLargeVideo"
      :show-laser-canvas="videoInfo && videoInfo.remoteOperation && videoInfo.remoteOperation.isLaserMoving"
      :show-paint-canvas="videoInfo && videoInfo.remoteOperation && videoInfo.remoteOperation.isPaintDrawing"
      :is-clear-paint="videoInfo && videoInfo.remoteOperation && videoInfo.remoteOperation.isClearPaint"
      :video-property="videoInfo && videoInfo.videoProperty"
      :im-user-id="videoInfo && videoInfo.userInfo && videoInfo.userInfo.imUserId"
      :is-subscribing="videoInfo && videoInfo.isSubscribing"
      :remote-paint-info="remotePaintInfo"
      :remote-laser-info="remoteLaserInfo"
      :show-remote-paint-canvas="isRemotePaint"
      :show-remote-laser-canvas="isRemoteLaser"
      :is-cam-on="deviceStatus && deviceStatus.isCamOn"
      :is-uvc-cam-on="deviceStatus && deviceStatus.isUvcConnected && deviceStatus.isUvcCamOn"
      :bit-rate="getBitRate"
      :is-fullscreen="isFullscreen"
      :is-mobile="isMobile"
      @cam-manual-focused="camManualFocused"
      @paint-drawing="paintDrawing"
      @laser-moving="laserMoving"
      @handle-resize="handleResize"
    >
      <div v-if="!isScreen" slot="topLeft" class="top top-left">
        <div v-if="isLargeVideo && videoTrack" class="large-window">
          <div class="remote-hint">远程控制</div>
          <video-button
            :icon="deviceStatus && !deviceStatus.isMicOn ? MicrophoneOff : MicrophoneOn"
            name="麦克风"
            @click="changeOneMicMute"
          />
          <video-button
            :icon="deviceStatus && !deviceStatus.isCamOn ? CameraOff : CameraOn"
            :is-disabled="(deviceStatus && deviceStatus.isUvcConnected && deviceStatus.isUvcCamOn) || isFreezeGuidance"
            name="摄像头"
            @click="changeOneCamMute"
          />
          <template v-if="deviceStatus && userInfo && (userInfo.loginPath.includes('Android') || userInfo.loginPath.includes('GLASSES'))">
            <video-button
              v-if="deviceStatus && deviceStatus.isUvcConnected"
              :icon="deviceStatus.isUvcCamOn ? UsbOn : UsbOff"
              :name="deviceStatus.isUvcCamOn ? '关闭外置镜头' : '外置镜头'"
              @click="changeOneUvcCam"
            />
            <video-button
              :icon="CameraRollover"
              :is-disabled="deviceStatus && !deviceStatus.isCamOn"
              name="切换镜头"
              @click="changeOneCamSwitch"
            />
            <video-button
              v-if="deviceStatus && deviceStatus.isFlashSupported"
              :icon="deviceStatus.isFlashOn ? FlashOn : FlashOff"
              name="闪光灯"
              :is-disabled="deviceStatus && !deviceStatus.isCamOn"
              @click="changeOneFlashlight"
            />
            <!--            <video-button v-if="deviceStatus" :icon="deviceStatus.isFlashOn ? FlashOn : FlashOff" name="闪光灯" @click="changeOneFlashlight" />-->
            <!--            <video-button :icon="CameraPhotograph" name="高清拍照" />-->
            <template v-if="deviceStatus && deviceStatus.camExposureLevels">
              <video-button :icon="Exposure" :is-disabled="deviceStatus && !deviceStatus.isCamOn" />
              <div class="exposure-level">
                <el-select v-model="camExposureLevel" :disabled="deviceStatus && !deviceStatus.isCamOn" placeholder="曝光" @change="changeOneCamExposureLevel">
                  <el-option
                    v-for="(item, index) in deviceStatus.camExposureLevels"
                    :key="index"
                    :label="`曝光级别${item}`"
                    :value="item"
                  />
                </el-select>
              </div>
            </template>
            <!--            <video-button :icon="Screenshot" name="抓拍截屏" />-->
            <video-button :icon="Zoom" :is-disabled="deviceStatus && !deviceStatus.isCamOn" />
            <div class="zoom-level">
              <el-select v-model="camZoomLevel" :disabled="deviceStatus && !deviceStatus.isCamOn" placeholder="镜头缩放" @change="changeOneCamZoomLevel">
                <el-option
                  v-for="(item, index) in 5"
                  :key="index"
                  :label="`镜头缩放${item}`"
                  :value="item"
                />
              </el-select>
            </div>
          </template>
          <video-button :icon="Adjust" :is-disabled="deviceStatus && !deviceStatus.isCamOn" />
          <div class="video-config">
            <el-select v-model="videoResolution" :disabled="deviceStatus && !deviceStatus.isCamOn" placeholder="视频质量" @change="changeOneVideoResolution">
              <el-option
                v-for="(item, index) in VideoResolutions"
                :key="index"
                x
                :label="item.value"
                :value="item.name"
              />
            </el-select>
          </div>
        </div>
        <div v-else-if="videoTrack" class="small-window">
          <video-button :icon="deviceStatus && !deviceStatus.isMicOn ? MicrophoneOff : MicrophoneOn" name="麦克风" @click="changeOneMicMute" />
          <video-button :icon="deviceStatus && !deviceStatus.isCamOn ? CameraOff : CameraOn" :is-disabled="isFreezeGuidance" name="摄像头" @click="changeOneCamMute" />
        </div>
      </div>
      <div slot="topRight" class="top top-right">
        <video-button
          v-if="!isScreen"
          :icon="isFocusedGuide ? FocusWhite : FocusRed"
          :name="isFocusedGuide ? '退出指导': '聚焦指导'"
          :is-disabled="deviceStatus && !deviceStatus.isCamOn && deviceStatus.isUvcConnected && !deviceStatus.isUvcCamOn"
          is-red
          :is-red-click="isFocusedGuide"
          @click="changeOneFocusGuidance"
        />
      </div>
      <div slot="bottomLeft" class="bottom-left">
        <div class="name-container">
          <img class="icon" :src="Head" alt="">
          <span v-if="isSinoma || stringLongOverTen(userInfo.userName)" class="name">{{ `${userInfo && userInfo.fullName}` }}</span>
          <span v-else class="name">{{ `${userInfo && userInfo.fullName}（${userInfo && userInfo.userName}）` }}</span>
        </div>
        <template v-if="!isMobile">
          <div v-for="(item, index) in getLoginIcon()" :key="index" class="login-type-container">
            <img class="icon" :src="item" alt="">
            <span class="content">{{ getLoginType()[index] }}</span>
          </div>
        </template>
      </div>
      <div v-if="videoTrack && getBitRate !== null" slot="bottomRight" class="bottom-right">
        <div class="send-container item left">
          <img :src="ReceiveArrows" alt="" class="icon">
          <span class="content">{{ `${getBitRate}kbps` }}</span>
        </div>
      </div>
    </player-view>
  </div>
</template>

<script>
import PlayerView from './playerView'
import VideoButton from './videoButton'
import MicrophoneOn from '@/assets/image/newRemoteGuidance/video/microphone_on.png'
import MicrophoneOff from '@/assets/image/newRemoteGuidance/video/microphone_off.png'
import CameraOn from '@/assets/image/newRemoteGuidance/video/camera_on.png'
import CameraOff from '@/assets/image/newRemoteGuidance/video/camera_off.png'
import UsbOn from '@/assets/image/newRemoteGuidance/video/usb_on.png'
import UsbOff from '@/assets/image/newRemoteGuidance/video/usb_off.png'
import CameraRollover from '@/assets/image/newRemoteGuidance/video/camera_rollover.png'
import CameraPhotograph from '@/assets/image/newRemoteGuidance/video/camera_photograph.png'
import FlashOff from '@/assets/image/newRemoteGuidance/video/flash_off.png'
import FlashOn from '@/assets/image/newRemoteGuidance/video/flash_on.png'
import Screenshot from '@/assets/image/newRemoteGuidance/video/screenshot.png'
import Exposure from '@/assets/image/newRemoteGuidance/video/exposure.png'
import FocusWhite from '@/assets/image/newRemoteGuidance/video/focus_white.png'
import FocusRed from '@/assets/image/newRemoteGuidance/video/focus_red.png'
import FocusOn from '@/assets/image/newRemoteGuidance/video/focus_on.png'
import FocusOff from '@/assets/image/newRemoteGuidance/video/focus_off.png'
import Zoom from '@/assets/image/newRemoteGuidance/video/zoom.png'
import Head from '@/assets/image/newRemoteGuidance/video/head.png'
import Websocket from '@/assets/image/newRemoteGuidance/video/pc.png'
import Android from '@/assets/image/newRemoteGuidance/video/phone.png'
import Glasses from '@/assets/image/newRemoteGuidance/video/glass.png'
import Small from '@/assets/image/newRemoteGuidance/video/small.png'
import Big from '@/assets/image/newRemoteGuidance/video/big.png'
import Adjust from '@/assets/image/newRemoteGuidance/video/adjust_wathet.jpg'
import SendArrows from '@/assets/image/newRemoteGuidance/video/send_arrows.png'
import ReceiveArrows from '@/assets/image/newRemoteGuidance/video/receive_arrows.png'
import { LoginIconEnum, UserLoginPathEnum, VideoResolutions } from '@/views/newRemoteGuidance/var/businessVar'
import store from '@/store'
import { stringLongOverTen } from '@/utils'

export default {
  name: 'SmallVideo',
  components: { PlayerView, VideoButton },
  props: {
    id: { type: String, default: null },
    videoInfo: { type: Object, default: () => {} },
    userInfo: { type: Object, default: () => {} },
    videoTrack: { type: Object, default: () => {}, deep: true, immediate: true },
    audioTrack: { type: Object, default: () => {}, deep: true, immediate: true },
    deviceStatus: { type: Object, default: () => {}, deep: true, immediate: true },
    isScreen: { type: Boolean, default: false },
    isShareVideo: { type: Boolean, default: true },
    isRemoteMuted: { type: Boolean, default: true },
    isLargeVideo: { type: Boolean, default: true },
    isFullscreen: { type: Boolean, default: false },
    showChangeSizeButton: { type: Boolean, default: true },
    remoteReceivers: { type: Array, default: () => [], deep: true, immediate: true },
    isRemotePaint: { type: Boolean, default: false },
    remotePaintInfo: { type: Object, default: () => {} },
    isRemoteLaser: { type: Boolean, default: false },
    remoteLaserInfo: { type: Object, default: () => {} },
    isFocusedGuide: { type: Boolean, default: false },
    isFreezeGuidance: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      UserLoginPathEnum,
      LoginIconEnum,
      VideoResolutions,
      MicrophoneOn, MicrophoneOff,
      CameraOn, CameraOff,
      UsbOn, UsbOff,
      CameraRollover,
      FlashOn, FlashOff,
      CameraPhotograph,
      Exposure,
      Screenshot,
      FocusWhite, FocusRed, FocusOn, FocusOff,
      Zoom,
      Adjust,
      Head, Websocket, Android, Glasses,
      Small, Big,
      SendArrows, ReceiveArrows,
      camZoomLevel: this.deviceStatus && this.deviceStatus.camZoomLevel ? this.deviceStatus.camZoomLevel : 1,
      camExposureLevel: this.deviceStatus && this.deviceStatus.camExposureLevel ? this.deviceStatus.camExposureLevel : 0,
      front: [{ name: '前置', value: true }, { name: '后置', value: false }],
      isFront: false,
      videoResolution: this.deviceStatus && this.deviceStatus.videoResolution ? this.deviceStatus.videoResolution : '720P',
      isSinoma: undefined
    }
  },
  computed: {
    getVideoSize() {
      if (this.isFullscreen) {
        if (this.isLargeVideo) {
          return 'full-large'
        } else {
          return 'full-small'
        }
      } else {
        if (this.isLargeVideo) {
          return 'normal-large'
        } else {
          return 'normal-small'
        }
      }
    },
    getBitRate() {
      if (this.videoTrack) {
        const index = this.remoteReceivers.findIndex(item => item.trackId === this.videoTrack.getTrackId())
        if (index !== -1) {
          return this.remoteReceivers[index].bitrate
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  watch: {
    deviceStatus: {
      handler() {
        this.camZoomLevel = this.deviceStatus && this.deviceStatus.camZoomLevel ? this.deviceStatus.camZoomLevel : 1
        this.camExposureLevel = this.deviceStatus && this.deviceStatus.camExposureLevel ? this.deviceStatus.camExposureLevel : 0
        this.videoResolution = this.deviceStatus && this.deviceStatus.videoResolution ? this.deviceStatus.videoResolution : '720P'
      },
      deep: true
    }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
  },
  methods: {
    stringLongOverTen,
    getLoginType() {
      return this.userInfo && this.userInfo.loginPath.map(path => {
        return this.UserLoginPathEnum[path]
      })
    },
    getLoginIcon() {
      return this.userInfo && this.userInfo.loginPath.map(path => {
        return this.LoginIconEnum[path]
      })
    },
    isExpert() {
      return this.userInfo && this.userInfo.roles && (this.userInfo.roles.includes('EXPERT') || this.userInfo.roles.includes('ADMIN'))
    },
    isAndroid() {
      return this.userInfo && this.userInfo.loginPath && (this.userInfo.loginPath.includes('Android') || this.userInfo.loginPath.includes('GLASSES'))
    },
    camManualFocused(data) {
      if (this.isLargeVideo) {
        this.$emit('cam-manual-focused', data)
      }
    },
    enlargeVideo() {
      this.$emit('enLarge-video')
    },
    zoomVideo() {
      this.$emit('zoom-video')
    },
    changeOneMicMute() {
      this.$emit('change-one-mic-mute', this.userInfo.imUserId, !this.deviceStatus.isMicOn)
    },
    changeOneCamMute(user) {
      this.$emit('change-one-cam-mute', this.userInfo.imUserId, !this.deviceStatus.isCamOn)
    },
    changeOneUvcCam() {
      this.$emit('change-one-uvc-cam', this.userInfo.imUserId, !this.deviceStatus.isUvcCamOn)
    },
    changeOneCamSwitch() {
      this.$emit('change-one-cam-switch', this.userInfo.imUserId, !this.isFront)
    },
    changeOneFlashlight() {
      this.$emit('change-one-flashlight', this.userInfo.imUserId, !this.deviceStatus.isFlashOn)
    },
    changeOneCamExposureLevel() {
      this.$emit('change-one-exposure-level', this.userInfo.imUserId, this.camExposureLevel)
    },
    changeOneCamZoomLevel() {
      this.$emit('change-one-zoom-level', this.userInfo.imUserId, this.camZoomLevel)
    },
    changeOneVideoResolution() {
      this.$emit('change-one-video-resolution', this.userInfo.imUserId, this.videoResolution)
    },
    changeOneFocusGuidance() {
      const data = {
        isFocus: !this.isFocusedGuide,
        focusedUserId: this.userInfo.imUserId
      }
      this.$emit('change-one-focus-guidance', data)
    },
    paintDrawing(paintData, status, targetUserId, senderScreenSize) {
      this.$emit('paint-drawing', paintData, status, targetUserId, senderScreenSize)
    },
    laserMoving(laserData, status, targetUserId, senderScreenSize) {
      this.$emit('laser-moving', laserData, status, targetUserId, senderScreenSize)
    },
    handleResize() {
      this.$emit('handle-resize')
    }
  }
}
</script>

<style scoped lang="scss">
.remote-video-wrapper{
  .top{
    color: #ffffff;
    z-index: 1;
    .large-window{
      display: flex;
      align-items: center;
      justify-content: center;
      .remote-hint{
        margin-left: 10px;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        font-weight: bolder;
      }
      .cam-switch{
        width: 60px;
        margin-left: -20px
      }
      .exposure-level{
        width: 95px;
        margin-left: -20px
      }
      .zoom-level{
        width: 85px;
        margin-left: -20px
      }
      .video-config{
        width: 100px;
        margin-left: -20px
      }
      ::v-deep .el-input{
        display: flex;
        justify-content: space-between;
      }
      ::v-deep.el-input__inner {
        padding: 10px;
        background: #2D2D2D00;
        border: none;
        color: #ffffff;
      }
      ::v-deep.el-input__icon{
        width: 15px;
      }
    }
    .small-window{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .top-right{
    margin-right: 10px;
    height: 30px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.6);
  }
  .bottom-left{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.6);
    .name-container{
      display: flex;
      align-items: center;
      justify-content: center;
      .icon{
        margin-right: 10px;
      }
      .name{
        color: #2E5AFF;
      }
    }
    .login-type-container{
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon{
        width: 15px;
        margin-right: 5px;
      }
      .content{
        color: #333333;
      }
    }
  }
  .bottom-right{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.6);
    .item {
      display: flex;
      align-items: center;
      height: 26px;
      .icon{
        width: 18px;
      }
      .content{
        color: #2e5aff;
      }
    }
    .left{
      margin-right: 10px;
    }
  }
}

.focused-container{
  border: 2px solid #f56c6c;
}

.is-screen-container{
  border: 2px solid #2e5aff;
}

.fullscreen-mobile-remote-video-wrapper{
  width: 100%;
  ::v-deep .top-tools{
    overflow: scroll !important;
  }
  .large-window{
    display: -webkit-box !important;
    overflow: scroll !important;
  }
  .is-red-clicked{
    width: 80px;
  }
  .is-red-button{
    width: 80px;
  }
}
</style>
