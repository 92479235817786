var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-container" },
    [
      _c(
        "el-form",
        {
          ref: "configForm",
          staticClass: "left-container",
          attrs: { model: _vm.configForm, rules: _vm.configFormRules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "步骤名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入步骤名称",
                  maxlength: "15",
                  "show-word-limit": "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.focusedItem("title")
                  },
                },
                model: {
                  value: _vm.configForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.configForm, "title", $$v)
                  },
                  expression: "configForm.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "参考图片：",
                prop: "referenceImage",
                "label-width": "85px",
              },
            },
            [
              _vm.configForm.referenceImage
                ? _c("dw-image", {
                    attrs: { url: _vm.configForm.referenceImage },
                    on: { "delete-img": _vm.deleteReferenceImage },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["img"],
                      "key-prefix": "workflow",
                      "select-img": "",
                    },
                    on: {
                      focused: function ($event) {
                        return _vm.focusedItem("referenceImage")
                      },
                      "on-file-uploaded": _vm.onImageUploaded,
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "检查要求：", prop: "text" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入检查要求",
                  type: "textarea",
                  rows: 6,
                  maxlength: 70,
                  "show-word-limit": "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.focusedItem("text")
                  },
                  change: _vm.textChange,
                },
                model: {
                  value: _vm.configForm.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.configForm, "text", $$v)
                  },
                  expression: "configForm.text",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "数据名称：", prop: "dataName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入数据名称",
                  maxlength: "5",
                  "show-word-limit": "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.focusedItem("dataName")
                  },
                },
                model: {
                  value: _vm.configForm.dataName,
                  callback: function ($$v) {
                    _vm.$set(_vm.configForm, "dataName", $$v)
                  },
                  expression: "configForm.dataName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "has-sub-item",
              attrs: { label: "参考范围：", prop: "minValue" },
            },
            [
              _c(
                "div",
                { staticClass: "value-container" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "minValue" } },
                    [
                      _c("el-input-number", {
                        staticClass: "value-input",
                        attrs: { max: 99999, min: 0.0, label: "参考下限" },
                        on: {
                          change: function ($event) {
                            return _vm.changeValue("minValue")
                          },
                          focus: function ($event) {
                            return _vm.focusedItem("minValue")
                          },
                        },
                        model: {
                          value: _vm.configForm.minValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.configForm, "minValue", $$v)
                          },
                          expression: "configForm.minValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider" }, [_vm._v("至")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "maxValue" } },
                    [
                      _c("el-input-number", {
                        staticClass: "value-input",
                        attrs: { max: 99999, min: 0.01, label: "参考上限" },
                        on: {
                          change: function ($event) {
                            return _vm.changeValue("maxValue")
                          },
                          focus: function ($event) {
                            return _vm.focusedItem("maxValue")
                          },
                        },
                        model: {
                          value: _vm.configForm.maxValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.configForm, "maxValue", $$v)
                          },
                          expression: "configForm.maxValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "数据单位：", prop: "dataUnit" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入数据单位",
                  maxlength: "3",
                  "show-word-limit": "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.focusedItem("dataUnit")
                  },
                },
                model: {
                  value: _vm.configForm.dataUnit,
                  callback: function ($$v) {
                    _vm.$set(_vm.configForm, "dataUnit", $$v)
                  },
                  expression: "configForm.dataUnit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "mustUploadPhoto" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.configForm.mustUploadPhoto,
                    callback: function ($$v) {
                      _vm.$set(_vm.configForm, "mustUploadPhoto", $$v)
                    },
                    expression: "configForm.mustUploadPhoto",
                  },
                },
                [_vm._v("必须拍摄照片")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }