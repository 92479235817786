export const ProjectListTitle = [
  { label: '工程名称', prop: 'name', width: 200 },
  { label: '工程地址', prop: 'location', width: 200 },
  { label: '工程起止日期', prop: 'projectStartAt', width: 180 },
  { label: '施工单位', prop: 'constructionUnit' },
  { label: '监理单位', prop: 'constructionControlUnit' },
  { label: '安装单位', prop: 'installationUnit' },
  { label: '联系人', prop: 'contactName' },
  { label: '联系电话', prop: 'contactMobile' },
  { label: '工程资料', prop: 'attachmentData' },
  { label: '绑定客户账号', prop: 'customer' },
  { label: '创建时间', prop: 'createdAt', width: 150 },
  { label: '修改时间', prop: 'updatedAt', width: 150 },
  { label: '结束时间', prop: 'finishedAt', width: 150 },
  { label: '工程进度', prop: 'progressRatio', width: 120, fixed: 'right' },
  { label: '现存设备数', prop: 'projectStockQuantity', width: 85, fixed: 'right' },
  { label: '工程状态', prop: 'isFinished', width: 70, fixed: 'right' },
  { label: '关联工单', prop: 'workOrderCount', width: 70, fixed: 'right' }
]

export const documentListTitle = [
  { label: '资料名称', prop: 'fileName', width: 120 },
  { label: '上传时间', prop: 'uploadAt' }
]
const EquipmentListBaseTitle = [
  { label: '设备名称', prop: 'constructionEquipmentName', width: 130 },
  { label: '产权编号', prop: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'constructionEquipmentSpecModel' },
  { label: '是否整机', prop: 'constructionEquipmentIsWhole', width: 70 }
]

export const EquipmentListTitle = [
  ...EquipmentListBaseTitle,
  { label: '所在仓库', prop: 'warehouseName' },
  { label: '库存数量', prop: 'quantity' }
]

export const EquipmentUsedListTitle = [
  ...EquipmentListBaseTitle,
  { label: '出库仓库', prop: 'warehouseName' },
  { label: '租用数量', prop: 'quantity' },
  { label: '执行代码', prop: 'executeCode' },
  { label: '租用开始时间', prop: 'startAt', width: 150 },
  { label: '租用结束时间', prop: 'endAt', width: 150 },
  { label: '状态', prop: 'status', fixed: 'right' }
]

export const EquipmentAddedListTitle = [
  ...EquipmentListBaseTitle,
  { label: '出库仓库', prop: 'warehouseName' },
  { label: '库存数量', prop: 'quantity' },
  { label: '租用数量', prop: 'leaseQuantity', width: 160, fixed: 'right' },
  { label: '执行代码', prop: 'executeCode', width: 150, fixed: 'right' }
]

export const EquipmentHasStockListTitle = [
  ...EquipmentListBaseTitle,
  { label: '现存数量', prop: 'projectEquipmentQuantity', alwaysShow: true },
  { label: '执行代码', prop: 'executeCode', alwaysShow: true }
]

