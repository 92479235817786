<template>
  <div>
    <el-form ref="modifyExecuteCodeForm" :model="modifyExecuteCodeForm" :rules="modifyExecuteCodeFormRules" label-width="110px">
      <el-form-item label="设备名称：">{{ equipmentInfo && equipmentInfo.constructionEquipmentName }}</el-form-item>
      <el-form-item label="原执行代码：">{{ equipmentInfo && equipmentInfo.executeCode }}</el-form-item>
      <el-form-item label="新执行代码：" prop="executeCode">
        <el-input v-model="modifyExecuteCodeForm.executeCode" placeholder="请输入设备执行代码" :maxlength="5" show-word-limit />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('modifyExecuteCodeForm')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { putProjectEquipment } from '@/api/constructionProjectMgr/projectMgr'

export default {
  name: 'ModifyExecuteCodeForm',
  data() {
    return {
      projectId: null,
      equipmentInfo: null,
      modifyExecuteCodeForm: {
        executeCode: null,
        constructionEquipmentId: null
      },
      modifyExecuteCodeFormRules: {
        executeCode: [{ required: true, message: '请输入设备执行代码', trigger: 'blur' }]
      },
      isSubmitting: false
    }
  },
  methods: {
    getInfo(equipmentInfo, projectId) {
      this.equipmentInfo = equipmentInfo
      this.projectId = projectId
      this.modifyExecuteCodeForm.constructionEquipmentId = equipmentInfo.constructionEquipmentId
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      this.$refs.modifyExecuteCodeForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          putProjectEquipment(this.projectId, this.modifyExecuteCodeForm).then(resp => {
            this.$message({ message: `修改成功`, type: 'success', center: true })
            this.$emit('confirm-submit')
            this.isSubmitting = false
          }).catch(() => {
            this.isSubmitting = false
          })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
