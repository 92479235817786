<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.code" placeholder="品番" />
        <el-input v-model="listQuery.name" placeholder="品名" />
        <el-input v-model="listQuery.illustrationNumber" placeholder="图番" />
        <el-input v-model="listQuery.supplierName" placeholder="供应商" />
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <ht-button type="success" icon="el-icon-plus" @click="toggleCreateNskMaterialDialog(true)">新建部品</ht-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in NskMaterialMgrListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '关联检查模版'">{{ row.nskTemplateId === null ? '-': `${ row[item.prop]}-${row.nskTemplateCode}` }}</span>
          <div v-else-if="item.label === '部品图'" class="img-container">
            <el-image v-if="row.illustrationImg" :src="row.illustrationImg" :preview-src-list="[row.illustrationImg]" class="img" />
            <span v-else>{{ '-' }}</span>
          </div>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="150">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-edit" @click="toggleEditNskMaterialDialog(row,true)">编辑</ht-button>
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该部品？" @confirmed="onDelete(row)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="createNskMaterialDialog" title="新建部品" :width="isMobile ? '100%' : '600px'" :close-on-click-modal="false" @close="toggleCreateNskMaterialDialog(false)">
      <div slot="content">
        <nsk-material-form v-if="showCreateNskMaterialForm" ref="createNskMaterialForm" @cancel-submit="toggleCreateNskMaterialDialog(false)" @confirm-submit="toggleCreateNskMaterialDialog(false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="editNskMaterialDialog" title="编辑部品" :width="isMobile ? '100%' : '600px'" :close-on-click-modal="false" @close="toggleEditNskMaterialDialog(null,false)">
      <div slot="content">
        <nsk-material-form v-if="showEditNskMaterialForm" ref="editNskMaterialForm" is-edit @cancel-submit="toggleEditNskMaterialDialog(null,false)" @confirm-submit="toggleEditNskMaterialDialog(null,false,true)" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { NskMaterialMgrListTitle } from '@/views/incomingInspectionMgr/tableTitle'
import NskMaterialForm from '@/views/incomingInspectionMgr/nskMaterialMgr/components/nskMaterialForm'
import { mapGetters } from 'vuex'
import { deleteNskMaterial, getNskMaterials } from '@/api/incomingInspectionMgr/nskMaterialMgr'

export default {
  name: 'List',
  components: { NskMaterialForm },
  extends: HtListContainer,
  data() {
    return {
      NskMaterialMgrListTitle,
      showCreateNskMaterialForm: false,
      showEditNskMaterialForm: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getNskMaterials(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onDelete(row) {
      deleteNskMaterial(row.id).then(() => {
        this.$message({ message: '删除成功', type: 'success' })
        this.getList()
      })
    },
    toggleCreateNskMaterialDialog(isOpen = true, isRefresh = false) {
      this.showCreateNskMaterialForm = isOpen
      this.$refs.createNskMaterialDialog.dialogVisible = isOpen
      if (isRefresh) {
        this.getList()
      }
    },
    toggleEditNskMaterialDialog(row, isOpen = true, isRefresh = false) {
      this.showEditNskMaterialForm = isOpen
      this.$refs.editNskMaterialDialog.dialogVisible = isOpen
      this.$nextTick(() => {
        if (row) {
          this.$refs.editNskMaterialForm.getMaterialInfo(row)
        }
      })
      if (isRefresh) {
        this.getList()
      }
    }
  }
}
</script>

<style scoped>

</style>
