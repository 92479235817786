import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login']
// const whiteList = ['/login', '/sparkBot', '/sparkbot'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  if (to.path.includes('newRemoteGuidance/externalCall')) {
    if (to.path.includes('newRemoteGuidance/externalCall/sinoma')) {
      await store.dispatch('user/setIsSinoma', true)
    }
    next()
  } else if (to.path.includes('/newRemoteGuidance/temporaryCall')) {
    next()
  } else if (to.path.includes('sparkBot') || to.path.includes('sparkbot')) {
    next()
  } else if (to.path.includes('taskWorkOrder') || to.path.includes('trainingWorkOrder')) {
    next()
  } else if (to.path.includes('test') || to.path.includes('arTest')) {
    next()
  } else {
    // start progress bar
    NProgress.start()
    // set page title
    document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = getToken()
    if (hasToken) {
      // await store.dispatch('user/getMessage')
      if (to.path === '/login') {
        // if is logged in, redirect to the home page
        next({ path: '/' })
        NProgress.done()
      } else {
        // determine whether the user has obtained his permission roles through getInfo
        const hasRoles = store.getters.roles && store.getters.roles.length > 0
        if (hasRoles) {
          // dynamically add accessible routes
          next()
        } else {
          try {
            // get user info
            // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
            // generate accessible routes map based on roles
            const { roles } = await store.dispatch('user/setRoes')
            const accessRoutes = await store.dispatch('permission/generateRoutes', { roles: roles, isConfigMenu: true })
            // dynamically add accessible routes
            router.addRoutes(accessRoutes)
            // hack method to ensure that addRoutes is complete
            // set the replace: true, so the navigation will not leave a history record
            // 远程指导IM
            await store.dispatch('user/getUserImInfo', accessRoutes)
            await store.dispatch('user/setUserHasMenu', accessRoutes)
            next({ ...to, replace: true })
          } catch (error) {
            // remove token and go to login page to re-login
            await store.dispatch('user/resetToken')
            Message.error(error || 'Has Error')
            next(`/login`)
            NProgress.done()
          }
        }
      }
    } else {
      /* has no token*/

      if (whiteList.indexOf(to.path) !== -1) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
