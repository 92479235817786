import { postImInfoApi, postInviteRecord, putKickImUser } from '@/api/newRemoteGuidance/newRemoteGuidance'
import Meeting from '@/views/newRemoteGuidance/components/meeting'
import im from '../rongCloud/im'
import rtc from '../rongCloud/rtc'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'
import dwCustomImMsg from './dwCustomImMsg'
import log from '@/utils/log'
import { Howl } from 'howler'
import { postUserSign } from '@/api/userInfo/user'
import { InviteRecordType, MeetingErrorMsg } from '@/views/newRemoteGuidance/var/businessVar'
import { isExternalCall, isExternalCommonCall, isSinoma } from '@/views/newRemoteGuidance/js/business'

// 初始化融云IM，并建立连接
export function getUserImInfoAndConnectIm() {
  return new Promise(async resolve => {
    let isInit = false
    // 正式服有 process.env.VUE_APP_IM_NAVI 配置的使用融云私有部署
    if (process.env.VUE_APP_IM_NAVI) {
      im.initIM(process.env.VUE_APP_IM_APP_KEY, process.env.VUE_APP_IM_NAVI)
      isInit = true
    } else if (process.env.VUE_APP_IS_DEVELOPMENT === 'true') {
      // 本地运行需要的时候写一下，不需要就不要连接了，以防修改VUE_APP_BASE_API导致后段无法收到回调，一个劲的获取新的imUserId
      im.initIM(process.env.VUE_APP_IM_APP_KEY)
      isInit = true
    } else {
      // 其他环境用的是融云公有云，只需要VUE_APP_IM_APP_KEY
      im.initIM(process.env.VUE_APP_IM_APP_KEY)
      isInit = true
    }
    if (isInit) {
      imConnectAndRtcInit().then(resp => {
        im.addConnectEventListener(resp && resp.imUserId)
        resolve(resp)
        im.registerMessage()
        addGlobalEventListener()
      }).catch((err) => {
        log.myErrorLog(err)
      })
    }
  })
}

export function imConnectAndRtcInit() {
  return new Promise(async(resolve, reject) => {
    postImInfoApi().then(resp => {
      im.imConnect(resp.imToken).then(() => {
        store.dispatch('user/setImConnectedStatue', true)
        window.parent.postMessage({ type: 'IsConnected', params: { isConnected: true }}, '*')
        rtc.initRTCClient()
        const inviteMp3 = require('@/assets/audio/invite.mp3')
        const hangUpMp3 = require('@/assets/audio/hang_up.mp3')
        vm.invite = new Howl({ src: [inviteMp3] })
        vm.hangUp = new Howl({ src: [hangUpMp3] })
        resolve(resp)
      })
    }).catch(err => {
      reject(err)
    })
  })
}

export function templateAcceptInvite(detail) {
  vm.inviteAccept(detail)
}

export async function linkDisconnectedFun(e) {
  await store.dispatch('user/setImConnectedStatue', false)
  const detail = e.detail
  if (detail.status === 31020 || detail.status === 31004) {
    vm.$message({
      message: `连接中断，系统正在尝试重新连接`,
      type: 'error',
      duration: 500,
      center: true
    })
    imConnectAndRtcInit().then(() => {
      vm.$message({
        message: `连接成功，通讯已恢复`,
        type: 'success',
        duration: 1000,
        center: true
      })
    })
  } else if (detail.status === 31010) {
    document.dispatchEvent(new CustomEvent(EventsEnum.beKickedOutEvent))
    if (store.getters.isBeingInvited) {
      await store.dispatch('user/changeIsBeingInvited', false)
      vm.$msgbox.close()
      await vm.inviteStopBall()
      await vm.hangUpPlay()
    }
    const currentRouter = router.currentRoute
    if (isSinoma(currentRouter)) {
      window.parent.postMessage({ type: 'IsConnected', params: { isConnected: false }}, '*')
      vm.$confirm(`您的账户已断开连接，是否尝试重新连接`, '通知', {
        confirmButtonText: '重新连接',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        showClose: false,
        type: 'warning'
      }).then(async() => {
        window.parent.postMessage({ type: 'IsConnecting', params: { isCancelConnecting: false }}, '*')
        if (vm.meetingDialogVisible) {
          vm.meeting.$children[0].closeDialog()
        }
        store.dispatch('user/sinomaSsoLogin', currentRouter.params.sessionKey).then(async() => {
          await store.dispatch('user/oneStopLoginGetUserImInfo')
        }).catch(() => {
          vm.$message({ message: '授权失败', center: true, type: 'error' })
        })
      }).catch(() => {
        window.parent.postMessage({ type: 'IsConnecting', params: { isCancelConnecting: true }}, '*')
        if (vm.meetingDialogVisible) {
          vm.meeting.$children[0].closeDialog()
        }
      })
    } else if (isExternalCommonCall(currentRouter)) {
      window.parent.postMessage({ type: 'IsConnected', params: { isConnected: false }}, '*')
      vm.$confirm(`您的账户已断开连接，是否尝试重新连接`, '通知', {
        confirmButtonText: '重新连接',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        showClose: false,
        type: 'warning'
      }).then(async() => {
        if (vm.meetingDialogVisible) {
          vm.meeting.$children[0].closeDialog()
        }
        const userSignParams = {
          appId: currentRouter.params.appId,
          outerId: currentRouter.params.outerId,
          userName: currentRouter.params.userName
        }
        postUserSign(userSignParams).then(resp => {
          const loginParams = {
            sign: resp.sign,
            appId: currentRouter.params.appId,
            action: 'login',
            loginType: 'one_stop_login'
          }
          store.dispatch('user/oneStopLogin', loginParams)
            .then(async() => {
              await store.dispatch('user/oneStopLoginGetUserImInfo')
            }).catch(() => {
              vm.$message({ message: '授权失败', center: true, type: 'error' })
            })
        })
      }).catch(() => {
        if (vm.meetingDialogVisible) {
          vm.meeting.$children[0].closeDialog()
        }
      })
    } else {
      vm.$confirm(`您的账户已在别端登陆，本端将退出登陆`, '通知', {
        confirmButtonText: '我知道了',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      }).then(async() => {
        if (vm.meetingDialogVisible) {
          vm.meeting.$children[0].closeDialog()
        }
        try {
          await store.dispatch('user/logout')
        } finally {
          router.push(`/login`)
        }
      }).catch(() => {
      })
    }
  }
}

// 监听全局响应事件
export function addGlobalEventListener() {
  document.addEventListener(EventsEnum.disconnectEvent, linkDisconnectedFun) // im连接中断
  document.addEventListener(EventsEnum.meetingInviteEvent, meetingInviteFun)// 会议邀请
  document.addEventListener(EventsEnum.meetingInviteCancelEvent, meetingInviteCancelFun) // 邀请撤销
  document.addEventListener(EventsEnum.meetingExitEvent, meetingExitFun) // 退出会议
  document.addEventListener(EventsEnum.tokenInvalidEvent, meetingExitFun) // token非法
}

// 移除监听全局响应事件
export function removeGlobalEventListener() {
  document.removeEventListener(EventsEnum.disconnectEvent, linkDisconnectedFun)
  document.removeEventListener(EventsEnum.meetingInviteEvent, meetingInviteFun)
  document.removeEventListener(EventsEnum.meetingInviteCancelEvent, meetingInviteCancelFun)
  document.removeEventListener(EventsEnum.meetingExitEvent, meetingExitFun)
  document.removeEventListener(EventsEnum.tokenInvalidEvent, meetingExitFun)
}

export function openMeetingDialog() {
  // 创建根vue实例
  const vm_meeting = new Vue({
    // 挂载Component组件
    render: h => h(Meeting)
  }).$mount()
  // 添加到DOM并显示
  document.body.appendChild(vm_meeting.$el)
  // 作为弹窗显示
  vm_meeting.$el.style.position = 'absolute'
  vm_meeting.$el.style.zIndex = '99'

  return vm_meeting
}

const vm = new Vue({
  data() {
    return {
      meetingDialogVisible: false,
      meeting: undefined,
      userInfo: undefined,
      userImInfo: undefined,
      invite: undefined,
      hangUp: undefined
    }
  },
  methods: {
    async invitePlay() {
      vm.invite.play()
    },
    async inviteStopBall() {
      vm.invite.stop()
    },
    async hangUpPlay() {
      vm.hangUp.play()
    },
    async hangUpStop() {
      vm.hangUp.stop()
    },
    inviteAccept(detail) {
      const currentRouter = router.currentRoute
      if (isExternalCall(currentRouter)) {
        window.parent.postMessage({ type: 'IsMinimized', params: { isMinimized: false }}, '*')
      }
      vm.meeting = openMeetingDialog()
      vm.userInfo = store.getters.userInfo
      vm.userImInfo = store.getters.userImInfo
      vm.meeting.$children[0].openDialog(vm.userInfo, vm.userImInfo)
      vm.meetingDialogVisible = vm.meeting.$children[0].meetingDialogVisible
      vm.meeting.$children[0].joinRoom(detail.roomId, detail.chatRoomId, false).then(async() => {
        const data = {
          msgType: dwCustomImMsg.DwMeetingInviteAccept.MsgType,
          senderId: vm.userImInfo && vm.userImInfo.imUserId,
          senderFullName: vm.userInfo && vm.userInfo.fullName,
          senderUserName: vm.userInfo && vm.userInfo.account.userName,
          senderRoles: vm.userInfo && vm.userInfo.roles
        }
        const obj = new dwCustomImMsg.DwMeetingInviteAccept(data)
        im.sendDwCustomPrivateMsg(detail.senderId, obj)
        if (isExternalCall(currentRouter)) {
          window.parent.postMessage({ type: 'InMeeting', params: { inMeeting: true }}, '*')
        }
      }).catch(async firstError => {
        if (firstError.reason === '当前环境无法开启会议') {
          await vm.joinFailFun(MeetingErrorMsg.joinRoomPermissionError, false)
        } else if (firstError.error && firstError.error.code && firstError.error.code === 53010) {
          await vm.joinFailFun(MeetingErrorMsg.getMediaPermissionError)
        } else {
          putKickImUser(vm.userImInfo.imUserId).then(() => {
            vm.meeting.$children[0].joinRoom(detail.roomId, detail.chatRoomId, false).then(async() => {
              const data = {
                msgType: dwCustomImMsg.DwMeetingInviteAccept.MsgType,
                senderId: vm.userImInfo && vm.userImInfo.imUserId,
                senderFullName: vm.userInfo && vm.userInfo.fullName,
                senderUserName: vm.userInfo && vm.userInfo.account.userName,
                senderRoles: vm.userInfo && vm.userInfo.roles
              }
              const obj = new dwCustomImMsg.DwMeetingInviteAccept(data)
              im.sendDwCustomPrivateMsg(detail.senderId, obj)
              if (isExternalCall(currentRouter)) {
                window.parent.postMessage({ type: 'InMeeting', params: { inMeeting: true }}, '*')
              }
            }).catch(async(secondError) => {
              if (secondError.reason === '加入聊天室失败') {
                await vm.joinFailFun(MeetingErrorMsg.joinChatRoomError)
              } else {
                await vm.joinFailFun(MeetingErrorMsg.joinMeetingError)
              }
            })
          }).catch(() => {
            vm.joinFailFun(MeetingErrorMsg.joinMeetingError, false)
          })
        }
      })
    },
    inviteReject(detail, reason) {
      const userInfo = store.getters.userInfo
      const userImInfo = store.getters.userImInfo
      const data = {
        msgType: dwCustomImMsg.DwMeetingInviteReject.MsgType,
        senderId: userImInfo.imUserId,
        senderFullName: userInfo.fullName,
        senderUserName: userInfo.account.userName,
        senderRoles: userInfo.roles,
        reason: reason
      }
      const obj = new dwCustomImMsg.DwMeetingInviteReject(data)
      im.sendDwCustomPrivateMsg(detail.senderId, obj)
    },
    async inviteNoResponse(detail) {
      vm.$msgbox.close()
      vm.$notify({
        title: '会议提醒',
        message: `您错过了${detail.senderFullName}的会议邀请`,
        type: 'warning',
        duration: 0
      })
    },
    inviteCancel(detail) {
      vm.$msgbox.close()
      vm.$notify({
        title: '会议提醒',
        message: `${detail.senderFullName}取消了会议邀请`,
        type: 'warning',
        duration: 0
      })
    },
    closeMeetingDialog() {
      if (vm.meeting) {
        vm.meeting.$destroy() // 销毁实例
        vm.meeting.$el.parentNode.removeChild(vm.meeting.$el) // 移除 DOM 元素
        vm.meeting = null // 解除引用
      }
    },
    async joinFailFun(errorMsg, isJoined = true) {
      vm.$message({ message: errorMsg, center: true, type: 'error' })
      setTimeout(async() => {
        if (isJoined) {
          putKickImUser(vm.userImInfo.imUserId)
        }
        await vm.meeting.$children[0].closeDialog()
      }, 1000)
    }
  }
})

export async function meetingInviteFun(e) {
  const detail = e.detail
  const currentRouter = router.currentRoute

  log.myLog('isBeingInvited', store.getters.isBeingInvited)
  if (store.getters.isBeingInvited) {
    vm.inviteReject(detail, dwCustomImMsg.DwMeetingInviteReject.BUSY)
  } else {
    await vm.invitePlay()

    if (isExternalCall(currentRouter)) {
      window.parent.postMessage({ type: 'IsCalled', params: { fullName: detail.senderFullName, isCalled: true }}, '*')
    }

    await store.dispatch('user/changeIsBeingInvited', true)
    const timer = setTimeout(() => {
      vm.inviteStopBall()
      store.dispatch('user/changeIsBeingInvited', false)
      vm.$msgbox.close()
    }, 65 * 1000)
    vm.$confirm(`【${detail.senderFullName}】邀请您进行视频通话`, '会议邀请提示', {
      confirmButtonText: '接受',
      cancelButtonText: '拒绝',
      confirmButtonClass: 'el-button--primary',
      cancelButtonClass: 'el-button--danger',
      closeOnClickModal: false,
      closeOnPressEscape: false,
      showClose: false,
      type: 'warning',
      center: true
    }).then(() => {
      vm.inviteStopBall()
      vm.inviteAccept(detail)
      clearTimeout(timer)
    }).catch(async(errorMsg) => {
      await vm.inviteStopBall()
      await vm.hangUpPlay()
      // 拒绝邀请，需要记录拒绝邀请记录
      const userImInfo = store.getters.userImInfo
      const inviteRecordData = {
        type: InviteRecordType.REJECTED,
        roomId: detail.roomId,
        inviterImUserId: detail.senderId,
        inviteeImUserId: userImInfo.imUserId
      }
      postInviteRecord(inviteRecordData)
      await store.dispatch('user/changeIsBeingInvited', false)
      if (errorMsg === 'cancel') {
        vm.inviteReject(detail, dwCustomImMsg.DwMeetingInviteReject.REJECT)
      }
      window.parent.postMessage({ type: 'InMeeting', params: { inMeeting: false }}, '*')
      clearTimeout(timer)
    })
  }
}

export async function meetingInviteCancelFun(e) {
  const detail = e.detail
  const currentRouter = router.currentRoute
  const isExternalCallResult = isExternalCall(currentRouter)

  if (isExternalCallResult) {
    window.parent.postMessage({ type: 'IsCalled', params: { fullName: detail.senderFullName, isCalled: false }}, '*')
  }
  await vm.inviteStopBall()
  await vm.hangUpPlay()
  await store.dispatch('user/changeIsBeingInvited', false)
  // 外部呼叫不显示提示框
  if (isExternalCallResult) {
    vm.$msgbox.close()
  } else {
    if (detail.reason === 'NO_RESPONSE') {
      await vm.inviteNoResponse(detail)
    } else {
      await vm.inviteCancel(detail)
    }
  }
}

export async function meetingInviteNoResponseFun(e) {
  const detail = e.detail
  if (detail.reason === 'NO_RESPONSE') {
    await store.dispatch('user/changeIsBeingInvited', false)
    await vm.inviteNoResponse(detail)
  }
}

export function meetingExitFun(e) {
  vm.meetingDialogVisible = e.detail.meetingDialogVisible
  vm.closeMeetingDialog()
  vm.meeting = undefined
  vm.userInfo = undefined
  vm.userImInfo = undefined
  // 临时用户参会退出后全局告知事件
  document.dispatchEvent(new CustomEvent(EventsEnum.meetingAlreadyEvent))
}

export function tokenInvalidFun() {
  if (vm.meetingDialogVisible) {
    vm.meeting.$children[0].beforeExitRoom()
  }
}
