var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c("equipment-descriptions", {
        attrs: { "equipment-info": _vm.equipmentInfo },
      }),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "20px" },
          on: { "tab-click": _vm.getList },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabs, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.value, name: item.name },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.activeName === "warehouse"
        ? _c(
            "ht-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              ref: "warehouseList",
              attrs: { data: _vm.warehouseList },
            },
            [
              _c("ht-table-column", {
                attrs: { label: "序号", type: "index", fixed: "left" },
              }),
              _vm._v(" "),
              _vm._l(_vm.EquipmentWarehouseStockInfoListTitle, function (item) {
                return _c("ht-table-column", {
                  key: item.prop,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    "min-width": item.width || 100,
                    fixed: item.fixed,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label === "库存数"
                              ? _c("span", [_vm._v(_vm._s(row[item.prop]))])
                              : _c("span", [
                                  _vm._v(_vm._s(row[item.prop] || "-")),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          )
        : _vm.activeName === "project"
        ? _c(
            "ht-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              ref: "projectList",
              attrs: { data: _vm.projectList },
            },
            [
              _c("ht-table-column", {
                attrs: { label: "序号", type: "index", fixed: "left" },
              }),
              _vm._v(" "),
              _vm._l(_vm.EquipmentProjectStockInfoListTitle, function (item) {
                return _c("ht-table-column", {
                  key: item.prop,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    "min-width": item.width || 100,
                    fixed: item.fixed,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label === "现存数"
                              ? _c("span", [_vm._v(_vm._s(row[item.prop]))])
                              : _c("span", [
                                  _vm._v(_vm._s(row[item.prop] || "-")),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }