<template>
  <div>
    <div class="info-container">
      <template v-if="distributionsInfo.length > 0">
        <div class="info">当前有<span class="count">{{ distributionsInfo.length }}</span>设备分布点，请缩放地图查看全部分布点</div>
        <div v-if="isFullscreen" class="info">点击地图上的点标记，查看设备分布信息</div>
      </template>
      <div v-else class="info">当前无设备分布信息</div>
    </div>
    <div :id="mapId" :style="{height: height}" style="width: 100%" class="map-container" />
    <ht-dialog ref="equipmentsListDialog" title="详细信息" width="700px" @close="closeEquipmentsListDialog">
      <div slot="content">
        <equipment-list v-if="showEquipmentsList" ref="equipmentsList" :is-warehouse="isWarehouse" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import EquipmentList from '@/views/newDashboard/components/distribution/equipmentList'
import CloseImg from '@/assets/image/newRemoteGuidance/close_white.png'
import SharpImg from '@/assets/image/newRemoteGuidance/sharp.png'
const AMap = window.AMap

export default {
  name: 'MapComponent',
  components: { EquipmentList },
  props: {
    mapId: { type: String, default: 'mapContainer' },
    height: { type: String, default: '500px' },
    distributionsInfo: { type: Array, default: () => [] },
    center: { type: Array, default: () => [] },
    isFullscreen: { type: Boolean, default: false }
  },
  data() {
    return {
      CloseImg, SharpImg,
      map: null,
      isWarehouse: false,
      showEquipmentsList: false,
      pointInfo: null
    }
  },
  mounted() {
    this.initMap()
  },
  beforeDestroy() {
    if (this.map) {
      this.map.destroy()
    }
  },
  methods: {
    initMap() {
      this.map = new AMap.Map(this.mapId, {
        resizeEnable: true,
        expandZoomRange: true,
        center: this.distributionsInfo[0].position,
        zooms: [3, 20]
      })
      this.addMarkers()
    },
    addMarkers() {
      this.map.clearMap()
      this.distributionsInfo.forEach((item, index) => {
        const marker = new AMap.Marker({
          map: this.map,
          position: item.position
        })
        const content = []
        item.equipmentStockItems.forEach(item => {
          if (item.quantity > 0) {
            content.push(`${item.constructionEquipmentName}：${item.quantity}`)
          }
        })
        // content.push(item.type === 'warehouse' ? `库存总数：${item.count}` : `现存设备数：${item.count}`)
        content.push(`<el-button class="info-button">详细信息</el-button>`)
        const infoWindow = new AMap.InfoWindow({
          isCustom: true, // 使用自定义窗体
          content: this.createInfoWindow(item, content.join('<br/>')),
          offset: new AMap.Pixel(16, -45)
        })
        marker.on('click', () => {
          infoWindow.open(this.map, marker.getPosition())
        })
      })
    },
    // 构建自定义信息窗体
    createInfoWindow(item, content) {
      var info = document.createElement('div')
      info.className = 'custom-info input-card content-window-card'

      // 可以通过下面的方式修改自定义窗体的宽高
      info.style.width = '300px'
      // 定义顶部标题
      var top = document.createElement('div')
      var titleD = document.createElement('div')
      var closeX = document.createElement('img')
      top.className = 'info-top'
      titleD.innerHTML = item.name
      closeX.src = this.CloseImg
      closeX.onclick = this.closeInfoWindow

      top.appendChild(titleD)
      top.appendChild(closeX)
      info.appendChild(top)

      // 定义中部内容
      var middle = document.createElement('div')
      middle.className = 'info-middle'
      middle.style.backgroundColor = 'white'
      middle.innerHTML = content
      // 绑定按钮点击事件
      middle.querySelector('el-button').onclick = () => {
        this.openEquipmentsListDialog(item)
      }
      info.appendChild(middle)

      // 定义底部内容
      var bottom = document.createElement('div')
      bottom.className = 'info-bottom'
      bottom.style.position = 'relative'
      bottom.style.top = '0px'
      bottom.style.margin = '0 auto'
      var sharp = document.createElement('img')
      sharp.src = this.SharpImg
      bottom.appendChild(sharp)
      info.appendChild(bottom)
      return info
    },
    // 关闭信息窗体
    closeInfoWindow() {
      this.map.clearInfoWindow()
    },
    openEquipmentsListDialog(distributionsInfo) {
      this.showEquipmentsList = true
      this.$refs.equipmentsListDialog.dialogVisible = true
      this.isWarehouse = distributionsInfo.type === 'warehouse'
      this.$nextTick(() => {
        this.$refs.equipmentsList.getList(distributionsInfo)
      })
    },
    closeEquipmentsListDialog() {
      this.showEquipmentsList = false
      this.$refs.equipmentsListDialog.dialogVisible = false
      this.isWarehouse = false
    }
  }
}
</script>

<style lang="scss">

@import "~@/styles/variables.scss";
.amap-info-contentContainer{
  padding: 0;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
  .content-window-card{
    position: relative;
    bottom: 0;
    left: 0;
    width: auto;
    padding: 0;
    border: solid 1px silver;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    p {
      height: 2rem;
    }
    div.info-top {
      position: relative;
      background: none repeat scroll 0 0 #F9F9F9;
      border-bottom: 1px solid #CCC;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      background: $primaryColor;
      border-radius: 9px 9px  0  0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
    }

    div.info-top div {
      display: inline-block;
      font-weight: bold;
      padding: 0 10px;
      color: #ffffff;
      font-size: 18px;
      line-height: 22px;
    }

    div.info-top img {
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin-right: 10px;
      transition-duration: 0.25s;
    }

    div.info-middle {
      padding: 10px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 0 0 9px 9px;
    }

    .info-middle .info-button{
      cursor: pointer;
      display: block;
      color: #2E5AFF;
      font-weight: bold;
      text-decoration: underline;
      margin-top: 5px;
    }

    div.info-bottom {
      height: 0;
      width: 100%;
      clear: both;
      text-align: center;
    }

    div.info-bottom img {
      position: relative;
      z-index: 104;
    }

    span {
      margin-left: 5px;
      font-size: 11px;
    }
  }
}

</style>

<style scoped lang="scss">
.map-container {
  height: 100%;
  width: 100%;
}
.info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 14px;
  .info{
    .count{
      color: #f56c6c;
      font-size: 16px;
      margin: 0 5px;
    }
  }
}

</style>
