var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.container,
    { tag: "component" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticStyle: { "margin-bottom": "50px" },
        },
        [
          _c("div", { staticClass: "title-card-container" }, [
            _c("div", { staticClass: "title-card-item-one-third" }, [
              _c("span", { staticClass: "labelStyle labelWidth_90" }, [
                _vm._v("会议ID:"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "textStyle",
                  staticStyle: { "font-weight": "bold" },
                  style: { color: _vm.variables.primaryColor },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.recordDetail && _vm.recordDetail.roomId
                        ? _vm.recordDetail.roomId
                        : "-"
                    )
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title-card-item-one-third" }, [
              _c("span", { staticClass: "labelStyle labelWidth_90" }, [
                _vm._v("发起人:"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "textStyle" }, [
                _vm._v(
                  _vm._s(
                    _vm.recordDetail && _vm.recordDetail.staff
                      ? _vm.recordDetail.staff.fullName
                      : ""
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title-card-item-one-third" }, [
              _c("span", { staticClass: "labelStyle labelWidth_90" }, [
                _vm._v("参与人:"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "textStyle" }, [
                _vm._v(
                  _vm._s(
                    _vm.recordDetail && _vm.recordDetail.joinUserNamesString
                      ? _vm.recordDetail.joinUserNamesString
                      : "-"
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title-card-item-one-third" }, [
              _c("span", { staticClass: "labelStyle labelWidth_90" }, [
                _vm._v("创建时间:"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "textStyle" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.recordDetail && _vm.recordDetail.startAt
                        ? _vm.recordDetail.startAt
                        : "-"
                    )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title-card-item-one-third" }, [
              _c("span", { staticClass: "labelStyle labelWidth_90" }, [
                _vm._v("会议时长:"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "textStyle" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.recordDetail && _vm.recordDetail.duration
                        ? _vm.recordDetail.duration
                        : "-"
                    )
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("el-divider", [
        _c("i", { staticClass: "el-icon-chat-line-square" }),
        _vm._v(" 沟通记录"),
      ]),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: {
                    type: "primary",
                    disabled:
                      !_vm.videoRecords || _vm.videoRecords.length === 0,
                    icon: "el-icon-video-play",
                  },
                  on: {
                    click: function ($event) {
                      _vm.$refs.videoRecordsDialog.dialogVisible = true
                    },
                  },
                },
                [_vm._v("会议录屏")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { "margin-bottom": "50px" },
          attrs: { data: _vm.chatRecords, "row-key": _vm.getRowKey },
          on: { "selection-change": _vm.onSelectionChange },
        },
        [
          _vm.showSelector ? _c("ht-table-column-selection") : _vm._e(),
          _vm._v(" "),
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", width: "60" },
          }),
          _vm._v(" "),
          _vm._l(_vm.labelToProps, function (i) {
            return _c("ht-table-column", {
              key: i.prop,
              attrs: {
                label: i.label,
                prop: i.prop,
                "min-width": i.width || 100,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        i.label === "发送人"
                          ? _c("span", [_vm._v(_vm._s(row.fromStaff.fullName))])
                          : i.label === "发送时间"
                          ? _c("span", [_vm._v(_vm._s(row.createdAt))])
                          : i.label === "发送内容"
                          ? [
                              row.mediaType.name === "TXT"
                                ? _c("span", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(row.content.content.content)
                                      ),
                                    ]),
                                  ])
                                : row.mediaType.name === "IMG"
                                ? [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                      },
                                      attrs: {
                                        src: row.content.imageUri,
                                        "preview-src-list": [
                                          row.content.imageUri,
                                        ],
                                      },
                                    }),
                                  ]
                                : row.mediaType.name === "FILE"
                                ? [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          href: row.content.fileUrl,
                                          target: "_blank",
                                          underline: "",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "pdf" },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(row.content.name) +
                                            "（查看文件）\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "videoRecordsDialog",
          attrs: { title: "会议录屏", width: "70%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearFloat",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.videoRecords } },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", type: "index", fixed: "left" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "开始时间",
                      prop: "extraData.startTime",
                      "min-width": "30px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "结束时间",
                      prop: "extraData.endTime",
                      "min-width": "30px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "录屏",
                      prop: "name",
                      "min-width": "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.content
                              ? _c("video-player", {
                                  ref: "videoPlayer",
                                  staticClass: "vjs-custom-skin",
                                  attrs: { options: row.playerOptions },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "录屏时长",
                      prop: "videoDuration",
                      "min-width": "30px",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }