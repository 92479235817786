var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        filterable: "",
        remote: _vm.remote,
        clearable: "",
        disabled: _vm.disabled,
        multiple: _vm.multiple,
        "reserve-keyword": _vm.multiple,
        "default-first-option": "",
        placeholder: _vm.placeholder,
        loading: _vm.isLoading,
        "remote-method": _vm.onQuery,
        value: "",
      },
      on: {
        clear: function ($event) {
          _vm.modelValue = null
        },
      },
      model: {
        value: _vm.modelValue,
        callback: function ($$v) {
          _vm.modelValue = $$v
        },
        expression: "modelValue",
      },
    },
    [_vm._t("default", null, { options: _vm.options })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }