<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClearSearch">
      <template slot="left">
        <el-select v-model="listQuery.name" placeholder="设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.name" :value="item.value" />
        </el-select>
        <el-input v-model="listQuery.code" placeholder="产权编号" />
        <el-input v-model="listQuery.specModel" placeholder="规格型号" />
        <el-date-picker v-model="producedAt" type="daterange" range-separator="至" value-format="yyyy-MM-dd" placement="bottom-start" start-placeholder="出厂日期" end-placeholder="出厂日期" />
        <dw-china-zones-select ref="zones" :province-code.sync="listQuery.provinceCode" :city-code.sync="listQuery.cityCode" :district-code.sync="listQuery.districtCode" placeholder="请选择设备区域" />
        <el-select v-model="listQuery.isWhole" placeholder="是否整机">
          <el-option v-for="(item,index) in EquipmentIsWholeEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
        <el-select v-model="listQuery.isAbnormal" placeholder="是否异常">
          <el-option v-for="(item,index) in EquipmentAbnormalEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
        <el-select v-model="listQuery.leaseStatus" placeholder="租赁状态">
          <el-option v-for="(value,key) in EquipmentLeaseStatusEnum" :key="key" :label="value" :value="key" />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <ht-button type="success" icon="el-icon-plus" @click="onCreate">新增设备</ht-button>
        <ht-button v-if="list.length > 0" type="primary" icon="el-icon-pie-chart" @click="openPatrolStatisticalCakeDialog">工单统计</ht-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer" @row-click="handleRowClick">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in EquipmentListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '设备区域'">{{ row[item.prop] && row[item.prop].name ? `${row.province.name}/${row.city.name}/${row.district.name}`: '-' }}</span>
          <span v-else-if="item.label === '独立高度'">{{ row[item.prop] ? `${row[item.prop]}米` : '-' }}</span>
          <span v-else-if="item.label === '设备价值'">{{ row[item.prop] ? `${row[item.prop]}万元` : '-' }}</span>
          <div v-else-if="item.label === '设备图片'" class="img-container">
            <el-image v-if="row.img" :src="row.img" :preview-src-list="[row.img]" class="img" />
            <span v-else>{{ '-' }}</span>
          </div>
          <span v-else-if="item.label === '设备资料'">
            <el-link
              :type="row.documentInformation && row.documentInformation.length > 0 ? 'primary': 'info'"
              :disabled="row.documentInformation && row.documentInformation.length === 0"
              @click="openDocumentDialog(row)"
            >{{ row.documentInformation && row.documentInformation.length }}</el-link>
          </span>
          <template v-else-if="item.label === '租赁状态'">
            <!--             整机显示租赁状态，部件不显示-->
            <el-tag v-if="row.isWhole" :type="EquipmentLeaseStatusStyleEnum[row[item.prop]]">{{ EquipmentLeaseStatusEnum[row[item.prop]] || '-' }}</el-tag>
            <span v-else>-</span>
          </template>
          <template v-else-if="item.label === '是否异常'">
            <el-tag v-if="row.isWhole" :type="getIsAbnormal(row[item.prop]) && getIsAbnormal(row[item.prop]).style">{{ getIsAbnormal(row[item.prop]) && getIsAbnormal(row[item.prop]).value || '-' }}</el-tag>
            <span v-else>-</span>
          </template>
          <el-link
            v-else-if="item.label === '本月工单完成情况'"
            :type="getProgress(row[item.prop]) && getProgress(row[item.prop]).style || 'info'"
            :disabled="!getProgress(row[item.prop])"
            @click="openWorkOrderDialog(row)"
          >{{ getProgress(row[item.prop]) ? getProgress(row[item.prop]).value + row.progressRatio : '无巡检工单' }}</el-link>
          <span v-else-if="item.label === '出厂日期'">{{ row.isWhole ? row[item.prop] || '-' : '-' }}</span>
          <span v-else-if="item.label === '是否整机'">{{ row[item.prop] ? '是' : '否' }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation v-if="isMobile" :width="110">
        <template v-slot="{row}">
          <dw-table-dropdown-buttons :buttons="row.buttons" :row="row" @handle-button-click="handleButtonClick(row,arguments)" />
        </template>
      </ht-table-column-operation>
      <ht-table-column-operation v-else :width="showChecklist ? 280 :200"> <!--    300-->
        <template v-slot="{row}">
          <ht-button icon="el-icon-view" type="text" @click="onView(row)">详情</ht-button>
          <!--          租用详情改到详情页了，不在列表显示-->
          <!--          <ht-button type="text" icon="el-icon-view" @click="openLeaseDetailDialog(row)">租用详情</ht-button>-->
          <ht-button v-if="showChecklist" type="text" icon="el-icon-tickets" @click="openChecklistDialog(row)">快检清单</ht-button>
          <ht-button type="text" icon="el-icon-edit" @click="onEdit(row)">编辑</ht-button>
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该设备？" @confirmed="onDelete(row)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="createEquipmentFormDialog" title="新增设备" width="800px" :close-on-click-modal="false" @close="closeCreateEquipmentDialog">
      <div slot="content">
        <equipment-form v-if="showCreateEquipmentForm" @cancel-submit="closeCreateEquipmentDialog" @confirm-submit="submitCreateEquipmentForm" />
      </div>
    </ht-dialog>

    <ht-dialog ref="documentDialog" title="设备资料" width="800px" @close="closeDocumentDialog">
      <div slot="content">
        <document-list v-if="showDocumentList" ref="documentList" :document-list="documentList" :show-operation="false" />
      </div>
    </ht-dialog>

    <ht-dialog ref="leaseDetailDialog" title="租用详情" width="1000px" @close="closeLeaseDetailDialog">
      <div slot="content">
        <lease-detail-list v-if="showLeaseDetailList" ref="leaseDetailList" v-loading="leaseDetailListLoading" :lease-detail-list="leaseDetails" />
      </div>
    </ht-dialog>

    <ht-dialog ref="patrolProgressChartDialog" title="工单完成情况" width="650px" @close="closePatrolStatisticalCakeDialog">
      <div slot="content">
        <ht-action-panel>
          <template slot="right">
            <el-date-picker v-model="patrolProgressQueryDate" :clearable="false" type="month" value-format="yyyy-MM" placeholder="选择月" @change="patrolQueryDateChange" />
          </template>
        </ht-action-panel>
        <patrol-progress-chart v-if="showPatrolProgressChart" ref="patrolProgressChart" v-loading="isPatrolProgressChartLoading" :count="patrolProgressCount" height="300px" />
      </div>
    </ht-dialog>

    <ht-dialog ref="workOrderDialog" title="本月工单" width="1000px" @close="closeWorkOrderDialog">
      <div slot="content">
        <el-tabs v-model="workOrderActiveName" @tab-click="getWorkOrderList">
          <el-tab-pane v-for="(item, index) in getTaskTempTypeEnum()" :key="index" :label="item.value" :name="item.name" />
        </el-tabs>
        <work-order-list
          v-if="showWorkOrderList"
          ref="workOrderList"
          :is-loading="isWorkOrderLoading"
          :table-title="workOrderTableTitle"
          :list="workOrderList"
          :page="workOrderPage"
          :list-query="workOrderListQuery"
        />
      </div>
    </ht-dialog>

    <ht-dialog ref="checklistFormDialog" title="快检清单" width="600px" @close="closeChecklistDialog">
      <div slot="content">
        <checklist-form v-if="showChecklistForm" ref="checklistForm" :equipment-info="equipmentInfo" @cancel-submit="closeChecklistDialog" @confirm-submit="closeChecklistDialog(true)" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import { parseTime } from '@/utils'
import HtListContainer from '@/components/HtListContainer'
import EquipmentForm from '@/views/constructionEquipmentMgr/equipmentMgr/components/equipmentForm'
import DocumentList from '@/views/constructionEquipmentMgr/equipmentMgr/components/documentList'
import LeaseDetailList from '@/views/constructionEquipmentMgr/equipmentMgr/components/leaseDetailList'
import PatrolProgressChart from '@/views/constructionEquipmentMgr/equipmentMgr/components/patrolProgressChart'
import WorkOrderList from '@/views/constructionProjectMgr/projectMgr/components/workOrderList'
import ChecklistForm from '@/views/constructionEquipmentMgr/equipmentMgr/components/checklistForm'
import { deleteEquipment, getEquipment, getEquipments, getEquipmentsPieStatistic, getEquipmentWorkOrders, getEquipmentUsageCheck } from '@/api/constructionEquipmentMgr/equipmentMgr'
import { EquipmentListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import {
  EquipmentAbnormalEnum, EquipmentIsWholeEnum,
  EquipmentLeaseStatusEnum,
  EquipmentLeaseStatusStyleEnum,
  EquipmentTypesEnum
} from '@/views/constructionEquipmentMgr/enum'
import { PatrolWorkOrderListTitle, RepairWorkOrderListTitle } from '@/views/taskMgr/tableTitle'
import { getIsAbnormal, getProgress } from '@/utils/get-display'
import { getTaskTempTypeEnum } from '@/views/taskMgr/commonJs'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { EquipmentForm, DocumentList, LeaseDetailList, PatrolProgressChart, WorkOrderList, ChecklistForm },
  extends: HtListContainer,
  data() {
    return {
      EquipmentListTitle, EquipmentTypesEnum,
      EquipmentLeaseStatusEnum, EquipmentLeaseStatusStyleEnum,
      EquipmentAbnormalEnum, EquipmentIsWholeEnum,
      PatrolWorkOrderListTitle, RepairWorkOrderListTitle,
      producedAt: [],
      showCreateEquipmentForm: false,
      showDocumentList: false,
      documentList: [],
      showLeaseDetailList: false,
      leaseDetails: [],
      leaseDetailListLoading: false,
      showPatrolProgressChart: false,
      patrolProgressQueryDate: undefined,
      isPatrolProgressChartLoading: false,
      patrolProgressCount: 0,
      showWorkOrderList: false,
      equipmentId: undefined,
      workOrderActiveName: 'PATROL',
      isWorkOrderLoading: false,
      workOrderTableTitle: PatrolWorkOrderListTitle,
      workOrderList: [],
      workOrderPage: { total: 0 },
      workOrderListQuery: { page: 1, perPage: 20 },
      showChecklistForm: false,
      equipmentInfo: null,
      showChecklist: process.env.VUE_APP_CONFIG_HAS_CHECK_LIST === 'true'
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getIsAbnormal, getProgress, getTaskTempTypeEnum,
    onClearSearch() {
      this.$refs.zones.zones = []
      this.producedAt = []
      this.onClear()
    },
    getList() {
      this.listQuery.producedStartAt = this.producedAt[0]
      this.listQuery.producedEndAt = this.producedAt[1]
      this.beforeGetList()
      getEquipments(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.buttons = [
            { name: '详情', icon: 'el-icon-view', isPopoverButton: false },
            { name: '编辑', icon: 'el-icon-edit', isPopoverButton: false },
            { name: '删除', icon: 'el-icon-delete', isPopoverButton: true, isDangerText: true, confirmTitle: '请确认是否要删除该设备?' }
          ]
        })
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onCreate() {
      this.showCreateEquipmentForm = true
      this.$refs.createEquipmentFormDialog.dialogVisible = true
    },
    closeCreateEquipmentDialog() {
      this.showCreateEquipmentForm = false
      this.$refs.createEquipmentFormDialog.dialogVisible = false
    },
    submitCreateEquipmentForm() {
      this.closeCreateEquipmentDialog()
      this.getList()
    },
    handleRowClick(row, column) {
      if (column.label !== '设备资料' && column.label !== '本月工单完成情况' && column.label !== '操作') {
        this.onView(row)
      } else if (column.label === '设备资料') {
        this.openDocumentDialog(row)
      } else if (column.label === '本月工单完成情况') {
        this.openWorkOrderDialog(row)
      }
    },
    handleButtonClick(row, $argument) {
      switch ($argument[0]) {
        case '详情':
          this.onView(row)
          break
        case '编辑':
          this.onEdit(row)
          break
        case '删除':
          this.onDelete(row)
          break
      }
    },
    onView(row) {
      this.$router.push({
        name: 'equipmentMgrView',
        params: { equipmentId: row.id, isEdit: 0 }
      })
    },
    onEdit(row) {
      this.$router.push({
        name: 'equipmentMgrEdit',
        params: { equipmentId: row.id, isEdit: 1 }
      })
    },
    onDelete(row) {
      if (row.leaseStatus === 'UNDER_LEASED') {
        // 1.设备出租不可删除：设备状态为出租中，不可删除
        this.$message({ message: '设备状态为出租中，不可删除！', center: true, type: 'error' })
      } else {
        getEquipmentUsageCheck(row.id).then(resp => {
          if (resp.isHaveStockQuantity) {
            //  2.有库存不可以删除
            this.$message({ message: '该设备有库存，不可删除！', center: true, type: 'error' })
          } else if (resp.isHaveUnfinishedWorkOrders) {
            // 3.有未完成（已分发、执行中）的工单不可删除：该设备关联工单未完成，不可删除
            this.$message({ message: '该设备关联工单未完成，不可删除', center: true, type: 'error' })
          } else if (resp.isHaveLoopWorkOrders) {
            // 4.设备存在正在循环的工单，可删除：该设备已创建循环工单，删除后工单不再循环创建，请确认是否删除设备？
            this.$confirm('该设备已创建循环工单，删除后工单不再循环创建，请确认是否删除设备？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              this.deleteEquipment(row.id)
            }).catch(() => {})
          } else if (resp.isHaveRepairExperience) {
            // 5.有维修经验知识的不可删除，提示:该设备已创建维修经验，不可删除
            this.$message({ message: '该设备已创建维修经验，不可删除！', center: true, type: 'error' })
          } else {
            this.deleteEquipment(row.id)
          }
        })
      }
    },
    deleteEquipment(id) {
      deleteEquipment(id).then(() => {
        this.$message({ message: '删除成功', center: true, type: 'success' })
        this.getList()
      })
    },
    openDocumentDialog(row) {
      this.documentList = row.documentInformation
      this.showDocumentList = true
      this.$refs.documentDialog.dialogVisible = true
    },
    closeDocumentDialog() {
      this.documentList = []
      this.showDocumentList = false
      this.$refs.documentDialog.dialogVisible = false
    },
    openLeaseDetailDialog(row) {
      this.leaseDetailListLoading = true
      getEquipment(row.id).then(resp => {
        this.leaseDetailListLoading = false
        this.leaseDetails = resp.rentalDetails
        this.showLeaseDetailList = true
        this.$refs.leaseDetailDialog.dialogVisible = true
      }).catch(() => {
        this.leaseDetailListLoading = false
        this.leaseDetails = []
        this.showLeaseDetailList = true
        this.$refs.leaseDetailDialog.dialogVisible = true
      })
    },
    closeLeaseDetailDialog() {
      this.leaseDetails = []
      this.showLeaseDetailList = false
      this.$refs.leaseDetailDialog.dialogVisible = false
    },
    openPatrolStatisticalCakeDialog() {
      this.patrolProgressQueryDate = this.initDate()
      this.showPatrolProgressChart = true
      this.$refs.patrolProgressChartDialog.dialogVisible = true
      this.getPatrolPieStatistical()
    },
    closePatrolStatisticalCakeDialog() {
      this.showPatrolProgressChart = false
      this.$refs.patrolProgressChartDialog.dialogVisible = false
    },
    initDate() {
      const now = new Date()
      const year = now.getFullYear()
      const month = now.getMonth()
      return parseTime(new Date(year, month), '{y}-{m}')
    },
    getPatrolPieStatistical() {
      this.patrolProgressCount = 0
      const query = {
        monthAt: this.patrolProgressQueryDate
      }
      this.isPatrolProgressChartLoading = true
      getEquipmentsPieStatistic(query).then(resp => {
        resp.forEach(item => {
          this.patrolProgressCount += item.equipmentCount
        })
        this.$refs.patrolProgressChart.getData(resp, this.patrolProgressCount)
        this.isPatrolProgressChartLoading = false
      }).catch(() => {
        this.isPatrolProgressChartLoading = false
      })
    },
    patrolQueryDateChange(value) {
      this.patrolProgressQueryDate = value
      this.getPatrolPieStatistical()
    },
    openWorkOrderDialog(row) {
      this.equipmentId = row.id
      this.showWorkOrderList = true
      this.$refs.workOrderDialog.dialogVisible = true
      this.$nextTick(() => {
        this.getWorkOrderList()
      })
    },
    closeWorkOrderDialog() {
      this.showWorkOrderList = false
      this.$refs.workOrderDialog.dialogVisible = false
      this.workOrderActiveName = 'PATROL'
    },
    getWorkOrderList() {
      this.workOrderListQuery.type = this.workOrderActiveName
      this.workOrderTableTitle = this.workOrderActiveName === 'PATROL' ? PatrolWorkOrderListTitle : RepairWorkOrderListTitle
      this.isWorkOrderLoading = true
      getEquipmentWorkOrders(this.equipmentId, this.workOrderListQuery).then(resp => {
        this.isWorkOrderLoading = false
        this.workOrderList = resp.items
        this.workOrderPage = resp.page
      }).catch(() => {
        this.isWorkOrderLoading = false
      })
    },
    openChecklistDialog(row) {
      this.equipmentInfo = row
      this.showChecklistForm = true
      this.$refs.checklistFormDialog.dialogVisible = true
    },
    closeChecklistDialog(isRefresh = false) {
      this.equipmentInfo = null
      this.showChecklistForm = false
      this.$refs.checklistFormDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}

.img-container{
  display: flex;
  align-items: center;
  justify-content: center;
  .img{
    width: 20px;
  }
}
</style>
