<template>
  <div class="bottom-section">
    <div class="bottom-container">
      <div v-if="count > 1" class="bottom-left">
        <el-button v-if="currentIndex > 0" class="prev-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('toPrev')">上一项</el-button>
        <el-button v-if="currentIndex + 1 < count" class="next-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('toNext')">下一项</el-button>
      </div>
      <div v-if="isLastStep" class="bottom-right" :style="{marginLeft: count > 1 ? '10px' : '0'}">
        <el-button class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('submitRecord')">提交工单</el-button>
        <!--        <el-button class="save-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('saveRecord')">保存记录</el-button>-->
        <!--        <el-button v-if="isLastStep" class="submit-button square-circle-button" type="text" @click="handleActions('submitRecord')">上传记录</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatrolBottomSection',
  props: {
    count: { type: Number, default: 0 },
    currentIndex: { type: Number, default: 0 },
    isLastStep: { type: Boolean, default: false },
    buttonDisabled: { type: Boolean, default: false }
  },
  methods: {
    handleActions(action) {
      switch (action) {
        case 'toPrev':
          this.$emit('to-prev')
          break
        case 'toNext':
          this.$emit('to-next')
          break
        case 'saveRecord':
          this.$emit('save-record')
          break
        case 'submitRecord':
          this.$emit('submit-record')
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
</style>
