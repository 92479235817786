var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "工作流程名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "工作流程类型" },
                  model: {
                    value: _vm.listQuery.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "type", $$v)
                    },
                    expression: "listQuery.type",
                  },
                },
                _vm._l(_vm.getTaskTempTypeEnum(), function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.value, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v("新建工作流程")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.WorkFlowListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "工作流程类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTaskTempType(row[item.prop])
                                    ? _vm.getTaskTempType(row[item.prop]).value
                                    : "-"
                                )
                              ),
                            ])
                          : item.label === "作业类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTowerWorkOrderType(row[item.prop])
                                    ? _vm.getTowerWorkOrderType(row[item.prop])
                                        .value
                                    : "-"
                                )
                              ),
                            ])
                          : item.label === "创建人"
                          ? _c("span", [_vm._v(_vm._s(row.staff.fullName))])
                          : _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.isMobile
            ? _c("ht-table-column-operation", {
                attrs: { width: 100 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("dw-table-dropdown-buttons", {
                            attrs: { buttons: row.buttons, row: row },
                            on: {
                              "handle-button-click": function ($event) {
                                return _vm.handleButtonClick(row, arguments)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2614970760
                ),
              })
            : _c("ht-table-column-operation", {
                attrs: { width: 200 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "ht-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "el-icon-document-copy",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onCopy(row)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除该条数据?",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onDelete(row)
                              },
                            },
                          },
                          [_vm._v("\n          删除\n        ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "copyTemplateFormDialog",
          attrs: { title: "复制工单", width: "40%" },
          on: { close: _vm.cancelCopy },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "orderForm",
                  staticStyle: { "margin-right": "20px" },
                  attrs: {
                    model: _vm.copyTemplateForm,
                    rules: _vm.copyTemplateRules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工作流程名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "template-name",
                        attrs: {
                          placeholder: "请输入工作流程名称",
                          minlength: 5,
                          maxlength: 20,
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.copyTemplateForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.copyTemplateForm, "name", $$v)
                          },
                          expression: "copyTemplateForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "ht-button",
                    {
                      attrs: { type: "info", plain: "" },
                      on: { click: _vm.cancelCopy },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ht-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmCopy()
                        },
                      },
                    },
                    [_vm._v("确定复制")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }