<template>
  <div v-loading="isLoading" class="add-equipment-container">
    <div v-if="!isProject" class="search-container">
      <el-input v-model="listQuery.name" placeholder="设备名称" clearable @input="onSearch" @change="onSearch" @clear="onClear('name')" />
      <el-input v-model="listQuery.code" placeholder="产权编号" clearable @input="onSearch" @change="onSearch" @clear="onClear('code')" />
    </div>
    <div v-if="isProject" class="equipment-container project-equipment-container">
      <equipment-inbound-card v-for="(item,index) in list" :key="index" :equipment-info="item" is-project />
    </div>
    <div v-else v-infinite-scroll="getNewEquipment" infinite-scroll-distance="100" class="equipment-container">
      <equipment-inbound-card v-for="(item,index) in list" :key="index" :equipment-info="item" />
    </div>
    <div v-if="list.length === 0 && !(isLoading && !isListLoading)" class="empty-list">
      <img :src="Empty" class="empty-img">
      <span class="empty-info">没有设备</span>
    </div>
    <div v-if="isLoading && !isListLoading" class="loading-spinner"><img class="loading-img" :src="Loading" alt="">数据加载中...</div>
    <div v-if="list.length > 0" class="already-loaded">已经到底部了</div>
    <div class="bottom-container">
      <el-button round icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button round type="primary" icon="el-icon-check" @click="confirmSubmit">确认</el-button>
    </div>
  </div>
</template>

<script>
import { getWarehouseStockEquipments } from '@/api/constructionEquipmentMgr/stockMgr'
import { getProject } from '@/api/constructionProjectMgr/projectMgr'
import EquipmentInboundCard from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/equipmentInboundCard'
import Loading from '@/assets/image/mobile/mobile_loading.png'
import Empty from '@/assets/image/mobile/mobile_empty.png'

export default {
  name: 'AddEquipmentList',
  components: { EquipmentInboundCard },
  props: {
    alreadyAddList: { type: Array, default: () => [] }
  },
  data() {
    return {
      Loading, Empty,
      isLoading: false,
      isListLoading: false,
      list: [],
      listQuery: { name: undefined, code: undefined },
      selectedEquipments: [],
      selection: [],
      isProject: false
    }
  },
  methods: {
    onSearch() {
      this.getList(false, true, true)
    },
    onClear(name) {
      this.listQuery[name] = null
      this.getList(false, true, true)
    },
    getListEquipmentList(isProject = false, projectId) {
      this.isProject = isProject
      if (isProject) {
        this.isLoading = true
        getProject(projectId).then(resp => {
          resp.equipmentStockItems.forEach(item => {
            const index = this.alreadyAddList.findIndex(addItem => addItem.id === item.constructionEquipmentId)
            item.inboundQuantity = index !== -1 ? this.alreadyAddList[index].inboundQuantity : 0
            item.isChecked = index !== -1
            item.id = item.constructionEquipmentId
            item.name = item.constructionEquipmentName
            item.code = item.constructionEquipmentName
            item.specModel = item.constructionEquipmentSpecModel
            item.quantity = item.projectEquipmentQuantity
            item.isWhole = item.constructionEquipmentIsWhole
          })
          this.isLoading = false
          this.list = resp.equipmentStockItems
        }).catch(() => {
          this.isLoading = false
        })
      } else {
        this.getList(false, true, true)
      }
    },
    getList(isConcat = false, isListLoading = true, isRefresh = false) {
      // this.listQuery.page = isRefresh ? 1 : this.listQuery.page
      this.isLoading = true
      this.isListLoading = isListLoading
      setTimeout(() => {
        getWarehouseStockEquipments(this.listQuery).then(resp => {
          resp.forEach(item => {
            const index = this.alreadyAddList.findIndex(addItem => addItem.id === item.id)
            item.inboundQuantity = index !== -1 ? this.alreadyAddList[index].inboundQuantity : 0
            item.isChecked = index !== -1
          })
          // this.list = isConcat ? this.list.concat(resp.items) : resp.items
          this.list = resp
          // this.page = resp.page
          this.isLoading = false
          this.isListLoading = false
        }).catch(() => {
          this.isLoading = false
          this.isListLoading = false
        })
      }, 500)
    },
    getNewEquipment() {
      // if (!this.isLoading && this.listQuery.page < this.page.totalPages) {
      //   this.listQuery.page++
      //   this.getList(true, false)
      // }
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      this.$emit('on-add-equipments', this.list.filter(item => item.inboundQuantity > 0 || item.isChecked))
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';
.add-equipment-container{
  height: 100%;
  position: relative;
  overflow: auto;
  .search-container{
    width: calc(100% - 30px);
    padding: 0 0 15px;
    position: fixed;
    background: #ffffff;
    z-index: 9;
  }
  .equipment-container{
    padding: 50px 5px 0;
  }
  .project-equipment-container{
    padding-top: 10px;
  }

}
</style>
