<template>
  <div v-loading="isImportLoading" class="knowledge-container">
    <el-form ref="knowledgeForm" :model="knowledgeForm" :rules="knowledgeFormRules" label-width="70px">
      <el-form-item label="标题：" prop="title">
        <el-input v-model="knowledgeForm.title" placeholder="请输入标题" :maxlength="50" show-word-limit />
      </el-form-item>
      <el-form-item label="正文：" prop="content" class="content-item">
        <template v-if="getFileTypeByExtension(knowledgeForm.sourceUrl) === 'pdf'">
          <span class="pdf-info"> <i class="el-icon-warning" /> 该内容为pdf导入生成，不支持修改</span>
          <div class="pdf-content" :style="{height: contentHeight - 20 + 'px'}" v-html="knowledgeForm.content" />
        </template>
        <dw-tinymce-editor v-else-if="getFileTypeByExtension(knowledgeForm.sourceUrl) !== 'pdf'" ref="tinymceEditor" v-model="knowledgeForm.content" />
      </el-form-item>
      <el-form-item label="附件：" prop="attachmentData">
        <dw-file
          v-for="(item,index) in knowledgeForm.attachmentData"
          :key="index"
          :url="item"
          show-del
          justify-content="left"
          style="margin: 5px 0"
          :need-margin="false"
          @delete-file="onDeleteAttachment(index)"
        />
        <ht-upload-button
          :file-type="['doc', 'pdf', 'excel','img','zip','']"
          key-prefix="knowledge"
          multiple-selection
          button-title="上传附件"
          @on-files-uploaded="onFilesUploaded"
        />
      </el-form-item>

      <el-form-item label="封面：" prop="coverImg">
        <template>
          <dw-image v-if="knowledgeForm.coverImg" :url="knowledgeForm.coverImg" @delete-img="onDeleteImage" />
          <ht-upload-button v-else :file-type="['img']" key-prefix="workflow" select-img @on-file-uploaded="onImageUploaded" />
        </template>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <div class="right-container">
        <el-button type="success" icon="el-icon-view" @click="togglePreviewDialog(true)">预览</el-button>
        <el-button :loading="isSubmitting && knowledgeForm.status === 'DRAFT'" type="primary" icon="el-icon-check" @click="confirmSubmit('knowledgeForm', 'DRAFT')">保存为草稿</el-button>
        <el-button :loading="isSubmitting && knowledgeForm.status === 'PUBLISHED'" type="primary" icon="el-icon-s-promotion" @click="confirmSubmit('knowledgeForm', 'PUBLISHED')">发布</el-button>
      </div>
    </div>

    <ht-dialog ref="knowledgePreviewDialog" title="预览" width="1000px" :fullscreen="isFullscreenKnowledgePreviewDialog" class="knowledge-preview-dialog" @close="togglePreviewDialog(false)">
      <div slot="close">
        <img v-if="isFullscreenKnowledgePreviewDialog" class="maximize fullscreen-item" :src="ExitFul" alt="" @click="toggleFullscreenKnowledgePreviewDialog">
        <img v-else class="maximize fullscreen-item" :src="EnterFul" alt="" @click="toggleFullscreenKnowledgePreviewDialog">
      </div>
      <div slot="content">
        <knowledge-preview v-if="showKnowledgePreview" ref="knowledgePreview" :show-button="false" @on-close="togglePreviewDialog(false)" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import { getKnowledgeBase, postKnowledgeBases, putKnowledgeBase } from '@/api/knowleageBaseMgr/knowleageBaseMgr'
import KnowledgePreview from '@/views/knowledgeBaseMgr/knowledgeSearch/components/knowledgeView'
import EnterFul from '@/assets/image/newRemoteGuidance/enter_full.png'
import ExitFul from '@/assets/image/newRemoteGuidance/exit_full.png'
import { getFileTypeByExtension } from '@/utils/business'

export default {
  name: 'KnowledgeForm',
  components: { KnowledgePreview },
  props: {
    isEdit: { type: Boolean, default: false },
    isImport: { type: Boolean, default: false },
    categoryData: { type: Object, default: () => {} },
    knowledgeData: { type: Object, default: () => {} },
    isFullscreen: { type: Boolean, default: false }
  },
  data() {
    return {
      EnterFul, ExitFul,
      knowledgeForm: {
        title: null,
        content: null,
        attachmentData: [],
        coverImg: null,
        cate1Id: null,
        cate2Id: null,
        status: null,
        sourceUrl: null,
        sourceName: null
      },
      knowledgeFormRules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入正文', trigger: 'blur' }]
      },
      isSubmitting: false,
      showKnowledgePreview: false,
      isImportLoading: false,
      isFullscreenKnowledgePreviewDialog: false,
      contentHeight: 400
    }
  },
  watch: {
    isImportLoading(val) {
      if (!val) {
        this.getKnowledgeForm()
      }
    },
    isFullscreen() {
      this.getContentHeight()
    }
  },
  created() {
    if (this.isEdit) {
      this.getKnowledgeForm()
    }
    this.$nextTick(() => {
      this.getContentHeight()
    })
  },
  methods: {
    getFileTypeByExtension,
    getContentHeight() {
      const knowledgeContainer = document.getElementsByClassName('knowledge-container')[0]
      this.contentHeight = knowledgeContainer.offsetHeight - 250
    },
    getKnowledgeForm() {
      if (this.isEdit) {
        getKnowledgeBase(this.knowledgeData.id, { action: 'edited' }).then(resp => {
          Object.keys(this.knowledgeForm).forEach(key => {
            this.knowledgeForm[key] = resp[key]
          })
          if (this.knowledgeForm.content && this.$refs.tinymceEditor) {
            this.$refs.tinymceEditor.content = this.knowledgeForm.content
          }
        })
      } else {
        Object.keys(this.knowledgeForm).forEach(key => {
          this.knowledgeForm[key] = this.knowledgeData[key]
        })
        if (this.knowledgeForm.content && this.$refs.tinymceEditor) {
          this.$refs.tinymceEditor.content = this.knowledgeForm.content
        }
      }
    },
    onFilesUploaded(context, urls) {
      this.knowledgeForm.attachmentData = [...this.knowledgeForm.attachmentData, ...urls]
    },
    onDeleteAttachment(index) {
      this.knowledgeForm.attachmentData.splice(index, 1)
    },
    onImageUploaded(context, url) {
      this.knowledgeForm.coverImg = url
    },
    onDeleteImage() {
      this.knowledgeForm.coverImg = ''
    },
    cancelSubmit() {
      this.$confirm(`取消后，编辑或修改的内容不会被保存，确认要执行操作吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$emit('cancel-submit')
      })
    },
    confirmSubmit(formName, status) {
      this.knowledgeForm.status = status
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.isSubmitting = true
          if (this.isEdit) {
            putKnowledgeBase(this.knowledgeData.id, this.knowledgeForm).then(() => {
              this.$message({ message: `修改成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          } else {
            this.knowledgeForm.cate1Id = this.categoryData.id
            this.knowledgeForm.cate2Id = this.categoryData.parentId
            postKnowledgeBases(this.knowledgeForm).then(() => {
              this.$message({ message: `${status === 'DRAFT' ? '保存' : '发布'}成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    togglePreviewDialog(isOpen) {
      this.showKnowledgePreview = isOpen
      this.$refs.knowledgePreviewDialog.dialogVisible = isOpen
      this.$nextTick(() => {
        if (isOpen) {
          this.$refs.knowledgePreview.knowledgeInfo = this.knowledgeForm
        }
      })
      this.isFullscreenKnowledgePreviewDialog = false
    },
    toggleFullscreenKnowledgePreviewDialog() {
      this.isFullscreenKnowledgePreviewDialog = !this.isFullscreenKnowledgePreviewDialog
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 70px;
}
.fullscreen-item{
  width: 20px;
  height: 20px;
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 10px;
}

.pdf-info{
  margin-bottom: 10px;
  font-weight: bolder;
  i{
    color: #e6a23c;
  }
}
.pdf-content{
  color: #000000;
  max-height: 400px;
  overflow: scroll;
  border: 1px solid #ccc;
  div{
    position: relative !important;
  }
}
</style>

<style lang="scss">
#sidebar{
  position: relative !important;
  display: none;
}
#page-container{
  position: relative !important;
}
</style>
