var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equipment-statistics-container" },
    [
      _c(
        "div",
        { staticClass: "statistics-chart-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isLeaseCountLoading,
                        expression: "isLeaseCountLoading",
                      },
                    ],
                    staticClass: "equipment-count-card-container",
                  },
                  _vm._l(_vm.equipmentCountList, function (item, index) {
                    return _c("equipment-count-card", {
                      key: index,
                      staticClass: "card",
                      attrs: { list: item },
                    })
                  }),
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("dw-statistic-chart", {
                    attrs: {
                      "is-loading": _vm.isDistributionLoading,
                      title: "设备分布",
                      "box-style": {
                        "margin-right": "20px",
                        width: "100%",
                        height: "260px",
                        "margin-top": 0,
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "statistic",
                        fn: function () {
                          return [
                            _vm.equipmentDistributions.length > 0 &&
                            _vm.showEquipmentsDistribution
                              ? _c("img", {
                                  staticClass: "full-screen-img",
                                  attrs: { src: _vm.FullScreen, alt: "全屏" },
                                  on: { click: _vm.openDistributionsDialog },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "chart",
                        fn: function () {
                          return [
                            _vm.showEquipmentsDistribution
                              ? _c("equipments-distribution", {
                                  ref: "equipmentsDistribution",
                                  attrs: {
                                    "map-id": "equipmentsDistributionMap",
                                    height: "170px",
                                    "distributions-info":
                                      _vm.equipmentDistributions,
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-right": "0", "margin-left": "0" },
                      attrs: { gutter: 24 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "whole-container" },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-right": "0",
                                "padding-left": "0",
                              },
                              attrs: { span: 16 },
                            },
                            [
                              _c("dw-statistic-chart", {
                                attrs: {
                                  "is-loading": _vm.isTrendChartLoading,
                                  title: "整机租用趋势",
                                  "box-style": {
                                    "margin-right": "20px",
                                    width: "100%",
                                    "margin-top": 0,
                                  },
                                  "title-style": { width: "120px" },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "statistic",
                                    fn: function () {
                                      return [
                                        _c("el-date-picker", {
                                          staticClass:
                                            "month-range-date-picker",
                                          attrs: {
                                            type: "monthrange",
                                            "value-format": "yyyy-MM",
                                            "range-separator": "至",
                                            "start-placeholder": "开始月份",
                                            "end-placeholder": "结束月份",
                                            clearable: false,
                                            "picker-options":
                                              _vm.monthsPickerOptions,
                                          },
                                          on: { blur: _vm.resetMinDate },
                                          model: {
                                            value:
                                              _vm.equipmentLeaseQueryMonths,
                                            callback: function ($$v) {
                                              _vm.equipmentLeaseQueryMonths =
                                                $$v
                                            },
                                            expression:
                                              "equipmentLeaseQueryMonths",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "chart",
                                    fn: function () {
                                      return [
                                        _c("equipment-lease-trend-chart", {
                                          ref: "equipmentLeaseTrendChart",
                                          attrs: { height: "230px" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-right": "0",
                                "padding-left": "0",
                              },
                              attrs: { span: 8 },
                            },
                            [
                              _c("dw-statistic-chart", {
                                attrs: {
                                  "is-loading": _vm.isRateChartLoading,
                                  title: "整机租用率",
                                  "box-style": {
                                    "margin-right": "20px",
                                    width: "100%",
                                    "margin-top": 0,
                                  },
                                  "title-style": { width: "120px" },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "statistic",
                                    fn: function () {
                                      return [
                                        _c("el-date-picker", {
                                          staticClass: "month-date-picker",
                                          attrs: {
                                            type: "month",
                                            "value-format": "yyyy-MM",
                                            placeholder: "选择月份",
                                            clearable: false,
                                            "picker-options":
                                              _vm.monthPickerOptions,
                                          },
                                          model: {
                                            value: _vm.equipmentLeaseQueryMonth,
                                            callback: function ($$v) {
                                              _vm.equipmentLeaseQueryMonth = $$v
                                            },
                                            expression:
                                              "equipmentLeaseQueryMonth",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "chart",
                                    fn: function () {
                                      return [
                                        _c("equipment-lease-rate-chart", {
                                          ref: "equipmentLeaseRateChart",
                                          attrs: { height: "230px" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("dw-statistic-chart", {
                    attrs: {
                      "is-loading": _vm.isTimeChartLoading,
                      title: "整机年限",
                      "box-style": { "margin-right": "20px", width: "100%" },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "chart",
                        fn: function () {
                          return [
                            _c("equipment-time-chart", {
                              ref: "equipmentTimeChart",
                              attrs: { height: "230px" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "equipmentsDistributionsDialog",
          attrs: {
            id: "equipments-distributions-dialog",
            title: "设备分布",
            "close-on-click-modal": false,
            fullscreen: true,
          },
          on: { close: _vm.closeDistributionsDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showEquipmentsDistributionDialog
                ? _c("equipments-distribution", {
                    ref: "equipmentsDistributionDialogMap",
                    attrs: {
                      "map-id": "equipmentsDistributionDialogMap",
                      height: _vm.equipmentsDistributionDialogMapHeight,
                      "distributions-info": _vm.equipmentDistributions,
                      "is-fullscreen": "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }