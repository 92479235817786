<template>
  <div class="outbound-container">
    <equipment-card :is-loading="isEquipmentLoading" :equipment-info="equipmentInfo" />
    <el-form ref="outboundForm" class="mobile-form" :model="outboundForm" :rules="outboundFormRules">
      <el-form-item label="出库单：" prop="attachmentData">
        <dw-file
          v-if="outboundForm.attachmentData"
          :url="outboundForm.attachmentData"
          show-del
          justify-content="left"
          style="width: 100%"
          @delete-file="outboundForm.attachmentData = null"
        />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传出库单" button-type="primary" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
      <!--      <el-form-item label="收货方：" prop="constructionProjectId">-->
      <!--        <el-select v-model="outboundForm.constructionProjectId" v-loading="isProjectLoading" placeholder="收货方" popper-class="mobile-select" style="width: 100%">-->
      <!--          <el-option v-for="item in projects" :key="item.id" :label="item.name" :value="item.id" />-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="收货方：" prop="constructionProjectId">
        <el-select v-model="outboundForm.constructionProjectId" v-loading="isProjectLoading" :disabled="equipmentInfo && equipmentInfo.isWhole" placeholder="收货方" popper-class="mobile-select" style="width: 100%">
          <el-option v-for="item in projects" :key="item.constructionProjectId" :label="item.constructionProjectName" :value="item.constructionProjectId" />
        </el-select>
      </el-form-item>
      <template v-if="equipmentInfo && equipmentInfo.isWhole">
        <el-form-item label="出库仓库：" prop="warehouseId">
          <el-select v-model="outboundForm.warehouseId" disabled style="width: 100%" popper-class="mobile-select">
            <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.name" :value="item.warehouseId" />
          </el-select>
        </el-form-item>
        <el-form-item label="出库数量：" prop="outboundQuantity">
          <el-input v-model="outboundForm.outboundQuantity" disabled placeholder="请输入出库数量" />
        </el-form-item>
      </template>
      <template v-else>
        <div v-for="(item,index) in warehouseList" :key="index" class="warehouse-card" :class="{'selected-warehouse-card': item.outboundQuantity > 0}">
          <div class="left-item item">
            <span class="name top">{{ item.name }}</span>
            <span class="quantity bottom">当前库存数：{{ item.quantity }}</span>
          </div>
          <div class="right-item item">
            <span class="title top">出库数量</span>
            <el-input-number
              v-model="item.outboundQuantity"
              class="num bottom"
              :min="0"
              :max="item.quantity"
              :disabled="item.quantity === 0"
              placeholder="请输入出库数量"
              :precision="0"
            />
          </div>
        </div>
      </template>
    </el-form>
    <div class="bottom-container">
      <el-button round icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button round :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认出库</el-button>
    </div>
  </div>
</template>

<script>
import { getEquipmentStock, getEquipmentStockProjects, putEquipmentStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import { getEquipment } from '@/api/constructionEquipmentMgr/equipmentMgr'
import equipmentCard from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/equipmentCard'

export default {
  name: 'EquipmentOutbound',
  components: { equipmentCard },
  props: {
    equipmentId: { type: [String, Number], default: null }
  },
  data() {
    return {
      outboundForm: {
        action: 'outbound',
        constructionEquipmentId: null,
        constructionProjectId: null,
        projectName: null,
        warehouseItems: [],
        attachmentData: null,
        warehouseId: null,
        outboundQuantity: 1,
        isWhole: false
      },
      outboundFormRules: {
        constructionEquipmentId: [{ required: true, message: '请输入出库数量' }],
        projectName: [{ required: true, message: '请选择收货方' }],
        // attachmentData: [{ required: true, message: '请上传出库单', trigger: 'change' }]
        //   辅助验证
        warehouseId: [{ required: true, message: '请选择出库仓库', trigger: 'change' }],
        outboundQuantity: [{ required: true, message: '请输入出库数量', trigger: 'blur' }]
      },
      projects: [],
      isProjectLoading: false,
      isSubmitting: false,
      warehouseList: [],
      equipmentInfo: null,
      isEquipmentStockLoading: false,
      isEquipmentLoading: false
    }
  },
  created() {
    this.getEquipmentInfo()
  },
  methods: {
    getEquipmentInfo() {
      this.isEquipmentLoading = true
      getEquipment(this.equipmentId).then(resp => {
        this.isEquipmentLoading = false
        this.equipmentInfo = resp
        this.outboundForm.constructionEquipmentId = this.equipmentId
        this.outboundForm.isWhole = resp.isWhole
        this.getEquipmentStockProjects(resp)
        this.getEquipmentStock(resp)
      }).catch(() => { this.isEquipmentLoading = false })
    },
    getEquipmentStockProjects(equipmentInfo) {
      this.isProjectLoading = true
      getEquipmentStockProjects(equipmentInfo.id, { action: 'outBound' }).then(resp => {
        this.projects = resp
        if (equipmentInfo.isWhole) {
          this.outboundForm.projectName = resp[0].constructionProjectName
          this.outboundForm.constructionProjectId = resp[0].constructionProjectId
        }
        this.isProjectLoading = false
      }).catch(() => { this.isProjectLoading = false })
    },
    getEquipmentStock(equipmentInfo) {
      this.isWarehouseLoading = true
      getEquipmentStock(equipmentInfo.id).then(resp => {
        resp.forEach(item => { item.outboundQuantity = 0 })
        if (equipmentInfo.isWhole) {
          const index = resp.findIndex(item => item.quantity > 0)
          if (index !== -1) {
            this.outboundForm.warehouseId = resp[index].warehouseId
            resp[index].outboundQuantity = resp[index].quantity
          }
        }
        this.warehouseList = resp
        this.isWarehouseLoading = false
      }).catch(() => { this.isWarehouseLoading = false })
    },
    handleAction() {
      this.$router.replace({ name: 'MobileStockMgrList' })
    },
    onFileUploaded(context, url) {
      this.outboundForm.attachmentData = url
      if (this.outboundForm.attachmentData) {
        this.$refs.outboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    confirmSubmit() {
      const outboundForm = this.getOutboundForm()
      this.$refs.outboundForm.validate(valid => {
        if (valid) {
          if (outboundForm.warehouseItems.some(item => item.quantity > 0)) {
            this.isSubmitting = true
            putEquipmentStocks(outboundForm).then(() => {
              this.$message({ message: `出库成功`, type: 'success', center: true })
              this.$router.go(-1)
              this.isSubmitting = false
            }).catch(() => { this.isSubmitting = false })
          } else {
            this.$message({ message: `请选择仓库并输入出库数量`, type: 'error', center: true })
            return false
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getOutboundForm() {
      const data = {
        action: 'outbound',
        constructionEquipmentId: null,
        constructionProjectId: null,
        warehouseItems: [],
        attachmentData: [],
        isWhole: false
      }
      data.constructionEquipmentId = this.outboundForm.constructionEquipmentId
      data.constructionProjectId = this.outboundForm.constructionProjectId
      data.warehouseItems = this.warehouseList.map(item => { return { warehouseId: item.warehouseId, quantity: item.outboundQuantity } })
      data.attachmentData = this.outboundForm.attachmentData ? [this.outboundForm.attachmentData] : []
      data.isWhole = this.outboundForm.isWhole
      return data
    }
  }
}
</script>

<style scoped  lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';

.outbound-container{
  margin: 15px 15px 100px;
  font-size: 1rem;
  .equipment-card {
    font-size: 1.1rem;
    background: $primaryColorLight;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #666666;
    margin-bottom: 15px;
    .name{
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  ::v-deep .el-button{
    font-size: 1rem;
  }

  .warehouse-card{
    display: flex;
    flex-direction: row;
    color: #666666;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    .item{
      display: flex;
      flex-direction: column;
      .name{
        font-weight: bold;
        font-size: 1.1rem;
      }
      .top{
        margin-bottom: 10px;
      }
    }
    .left-item{
      flex: 1;
      justify-content: space-between;
    }
    .right-item {
      align-items: center;
    }
  }

  .selected-warehouse-card{
    background: #f0f9eb;
  }
}

.mobile-form{
  ::v-deep .el-form-item{
    margin-bottom: 10px;
    .el-form-item__label{
      text-align: right !important;
    }
  }
}
</style>
