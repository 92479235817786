<template>
  <div>
    <el-form ref="outboundForm" :model="outboundForm" :rules="outboundFormRules">
      <el-form-item label="出库仓库：" prop="warehouseId">{{ warehouseInfo && warehouseInfo.name }}</el-form-item>
      <el-form-item label="收货方（工程方）：" prop="constructionProjectId" label-width="150px">
        <el-select
          v-model="outboundForm.constructionProjectId"
          filterable
          :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'"
          style="width: 100%"
          remote
          :remote-method="getProjects"
        >
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-row class="line-two-item-row">
        <el-form-item label="出库设备：" prop="equipmentItems">
          <el-button type="success" icon="el-icon-plus" @click="openAddEquipmentDialog">添加设备</el-button>
        </el-form-item>
      </el-row>
      <ht-table ref="list" v-loading="isEquipmentLoading" :data="outboundForm.equipmentItems" style="margin-bottom: 18px">
        <ht-table-column label="序号" type="index" fixed="left" />
        <ht-table-column
          v-for="item in WarehouseEquipmentStockOutboundListTitle"
          :key="item.otherProp"
          :label="item.label"
          :prop="item.otherProp"
          :min-width="item.width || 100"
          :fixed="item.fixed"
        >
          <template v-slot="{row}">
            <span v-if="item.label === '库存数'">{{ row[item.otherProp] }}</span>
            <span v-else>{{ row[item.otherProp] || '-' }}</span>
          </template>
        </ht-table-column>
        <ht-table-column label="出库数量" fixed="right" prop="outboundQuantity" :show-overflow-tooltip="false" width="200">
          <template v-slot="{row}">
            <el-input-number
              v-model="row.outboundQuantity"
              :min="0"
              :max="row.quantity"
              :disabled="row.quantity === 0 || row.constructionEquipmentIsWhole"
              placeholder="请输入出库数量"
              :precision="0"
              style="width: 100%"
            />
          </template>
        </ht-table-column>
        <ht-table-column-operation :width="100">
          <template v-slot="{$index, row}">
            <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该条数据？" @confirmed="onDeleteEquipment($index)">删除</ht-popover-button>
          </template>
        </ht-table-column-operation>
      </ht-table>
      <el-form-item label="出库单：" prop="attachmentData" label-width="80px">
        <dw-file v-if="outboundForm.attachmentData" :url="outboundForm.attachmentData" show-del justify-content="left" @delete-file="outboundForm.attachmentData = null" />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传出库单" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认出库</el-button>
    </div>

    <ht-dialog ref="addEquipmentDialog" :title="`添加设备`" width="750px" @close="closeAddEquipmentDialog">
      <div slot="content">
        <add-equipment-list
          v-if="showAddEquipmentList"
          ref="addEquipmentList"
          :already-add-list="outboundForm.equipmentItems"
          :warehouse-id="outboundForm.warehouseId"
          style="margin-bottom: 20px"
          @on-add-equipments="closeAddEquipmentDialog(true, arguments)"
        />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import { WarehouseEquipmentStockOutboundListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getEquipmentStocks, putWarehouseStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import { getProjects } from '@/api/constructionProjectMgr/projectMgr'
import AddEquipmentList from '@/views/constructionEquipmentMgr/stockMgr/components/warehouseOutboundAddEquipmentList'

export default {
  name: 'OutboundForm',
  components: { AddEquipmentList },
  data() {
    return {
      WarehouseEquipmentStockOutboundListTitle,
      outboundForm: {
        action: 'outbound',
        warehouseId: null,
        warehouseName: null,
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: null
      },
      outboundFormRules: {
        warehouseId: [{ required: true, message: '请选择出货仓库' }],
        constructionProjectId: [{ required: true, message: '请选择收货方（工程方）', trigger: 'change' }],
        equipmentItems: [{ required: true, message: '请选择入库设备' }]
        // attachmentData: [{ required: true, message: '请上传出库单', trigger: 'change' }]
      },
      warehouseInfo: null,
      projectList: [],
      isProjectLoading: false,
      equipmentIds: [],
      equipmentList: [],
      isEquipmentLoading: false,
      isSubmitting: false,
      showAddEquipmentList: false
    }
  },
  methods: {
    initInfo(warehouseInfo) {
      this.warehouseInfo = warehouseInfo
      this.outboundForm.warehouseId = warehouseInfo.id
      this.outboundForm.warehouseName = warehouseInfo.name
      this.getProjects()
    },
    getProjects(value) {
      const query = {
        offset: 0,
        count: 20,
        keyword: value,
        isFinished: false
      }
      getProjects(query).then(resp => {
        this.projectList = resp
        this.isProjectLoading = false
      })
    },
    handleSelectProject(value) {
      this.equipmentIds = []
      const index = this.projectList.findIndex(item => item.id === value)
      if (index !== -1) {
        this.equipmentIds = this.projectList[index].equipmentItems.filter(item => item.status === 'UNDER_LEASED').map(item => { return item.constructionEquipmentId })
        const query = {
          warehouseId: this.outboundForm.warehouseId,
          constructionEquipmentIds: [...new Set(this.equipmentIds)].toString() || -1
        }
        this.equipmentList = []
        this.isEquipmentLoading = true
        getEquipmentStocks(query).then(resp => {
          resp.forEach(item => {
            item.outboundQuantity = 0
          })
          this.equipmentList = resp
          this.isEquipmentLoading = false
        }).catch(() => { this.isEquipmentLoading = false })
      } else {
        this.equipmentIds = []
        this.equipmentList = []
      }
    },
    openAddEquipmentDialog() {
      this.showAddEquipmentList = true
      this.$refs.addEquipmentDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.addEquipmentList.getList()
      })
    },
    closeAddEquipmentDialog(isAdd = false, $arguments) {
      if (isAdd) {
        this.outboundForm.equipmentItems = [...this.outboundForm.equipmentItems, ...$arguments[0]]
        if (this.outboundForm.equipmentItems && this.outboundForm.equipmentItems.length > 0) {
          this.$refs.outboundForm.clearValidate('equipmentItems')
        }
      }
      this.showAddEquipmentList = false
      this.$refs.addEquipmentDialog.dialogVisible = false
    },
    onDeleteEquipment(index) {
      this.outboundForm.equipmentItems.splice(index, 1)
    },
    onFileUploaded(context, url) {
      this.outboundForm.attachmentData = url
      if (this.outboundForm.attachmentData) {
        this.$refs.outboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      const outboundForm = this.getOutboundForm()
      this.$refs.outboundForm.validate(valid => {
        if (valid) {
          if (outboundForm.equipmentItems.some(item => item.quantity > 0)) {
            this.isSubmitting = true
            putWarehouseStocks(outboundForm).then(() => {
              this.$emit('confirm-submit')
              this.isSubmitting = false
              this.$message({ message: `出库成功`, type: 'success' })
            }).catch(() => { this.isSubmitting = false })
          } else {
            this.$message({ message: `请选择设备并输入出库数量`, type: 'error', center: true })
            return false
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getOutboundForm() {
      const data = {
        action: 'outbound',
        warehouseId: null,
        warehouseName: null,
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: []
      }
      data.warehouseId = this.outboundForm.warehouseId
      data.warehouseName = this.outboundForm.warehouseName
      data.constructionProjectId = this.outboundForm.constructionProjectId
      data.equipmentItems = this.outboundForm.equipmentItems.map(item => {
        return {
          constructionEquipmentId: item.constructionEquipmentId,
          constructionEquipmentName: item.constructionEquipmentName,
          constructionEquipmentCode: item.constructionEquipmentCode,
          quantity: item.outboundQuantity,
          isWhole: item.constructionEquipmentIsWhole }
      })
      data.attachmentData = this.outboundForm.attachmentData ? [this.outboundForm.attachmentData] : []
      return data
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  gap: 20px;
  margin-bottom: 10px;
}
</style>
