var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "step-ui-detail-container",
          class: { "is-used-step-container": !_vm.isEdit },
          style: { height: _vm.height + "px" },
          attrs: { title: "hf_use_description" },
        },
        [
          _c(
            "div",
            { staticClass: "view-container" },
            [
              _c("top-section", {
                ref: "topSection",
                attrs: {
                  "is-focused-item": _vm.isFocusedItem,
                  "current-index": _vm.currentIndex,
                  count: _vm.count,
                  title: _vm.properties.title,
                  "show-more-function": _vm.showMoreFunction,
                  "must-upload-photo": _vm.properties.mustUploadPhoto,
                  "upload-photos": _vm.submitResults.uploadPhotos,
                  "active-img-index": _vm.activeImgIndex,
                  "button-disabled": _vm.buttonDisabled,
                  "show-photos": !_vm.showMiddlePhotos,
                },
                on: {
                  "more-function": function ($event) {
                    return _vm.moreFunction()
                  },
                  "native-play-tts": function ($event) {
                    return _vm.nativePlayTTS(_vm.ttsText)
                  },
                  "to-remote-guidance": function ($event) {
                    return _vm.toRemoteGuidance()
                  },
                  "to-ai-qa-assistant": function ($event) {
                    return _vm.toAiQaAssistant()
                  },
                  "native-take-photo": _vm.toTakePhoto,
                  "open-photo": _vm.openPhoto,
                  "close-photo": _vm.closePhoto,
                  "del-photo": _vm.delPhoto,
                  "prev-photo": _vm.prevPhoto,
                  "next-photo": _vm.nextPhoto,
                  "set-active-item": _vm.setActiveItem,
                  "jump-step": _vm.jumpStep,
                },
              }),
              _vm._v(" "),
              _c("middle-section", {
                ref: "middleSection",
                attrs: {
                  "is-focused-item": _vm.isFocusedItem,
                  "active-name": _vm.activeName,
                  "reference-image": _vm.properties.referenceImage,
                  text: _vm.properties.text,
                  "fact-desc": _vm.submitResults.factDesc,
                  "button-disabled": _vm.buttonDisabled,
                  height: _vm.getMiddleHeight,
                  "show-text": false,
                  "show-reference-image": false,
                  "show-photos": _vm.showMiddlePhotos,
                  "must-upload-photo": _vm.properties.mustUploadPhoto,
                  "active-img-index": _vm.activeImgIndex,
                  "upload-photos": _vm.submitResults.uploadPhotos,
                },
                on: {
                  "select-reference-image": _vm.selectReferenceImage,
                  "select-text": _vm.selectText,
                  "select-photos": _vm.selectPhotos,
                  "set-active-item": _vm.setActiveItem,
                  "open-photo": _vm.openPhoto,
                  "close-photo": _vm.closePhoto,
                  "del-photo": _vm.delPhoto,
                  "prev-photo": _vm.prevPhoto,
                  "next-photo": _vm.nextPhoto,
                  "input-desc": _vm.inputDesc,
                  "clear-fact-desc": _vm.clearFactDesc,
                  "open-reference-image": _vm.openReferenceImage,
                  "close-reference-viewer": _vm.closeReferenceViewer,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "middle-second-section" }, [
                _c(
                  "div",
                  { staticClass: "result-container" },
                  [
                    _c("span", { staticClass: "required-item" }, [_vm._v("*")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "sub-title result-title" }, [
                      _vm._v("事故类型："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.buttonDisabled },
                        model: {
                          value: _vm.submitResults.accidentType,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitResults, "accidentType", $$v)
                          },
                          expression: "submitResults.accidentType",
                        },
                      },
                      _vm._l(_vm.AccidentTypeEnum, function (value, key) {
                        return _c(
                          "el-radio",
                          { key: key, attrs: { label: key } },
                          [_vm._v(_vm._s(value))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("bottom-section", {
                ref: "bottomSection",
                attrs: {
                  count: _vm.count,
                  "current-index": _vm.currentIndex,
                  "is-last-step": _vm.isLastStep,
                  "button-disabled": _vm.buttonDisabled,
                },
                on: {
                  "to-prev": function ($event) {
                    return _vm.toPrev()
                  },
                  "to-next": function ($event) {
                    return _vm.toNext()
                  },
                  "save-record": function ($event) {
                    return _vm.saveRecord()
                  },
                  "submit-record": function ($event) {
                    return _vm.submitRecord()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }