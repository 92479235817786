<script>
import { MenuItem } from 'element-ui'
export default {
  name: 'DwMenuItem',
  extends: MenuItem,
  computed: {
    // backgroundColor() {
    //   return this.active ? this.rootMenu.backgroundColor : '#ffffff'
    // },
    // itemStyle() {
    //   const style = {
    //     color: this.active ? this.activeTextColor : this.textColor
    //   }
    //   if (this.mode === 'horizontal' && !this.isNested) {
    //     style.borderBottomColor = this.active
    //       ? (this.rootMenu.activeTextColor ? this.activeTextColor : '')
    //       : 'transparent'
    //   }
    //   return style
    // }
  }
}
</script>
<style lang="scss" scoped>
.is-active{
  //background: #2e5aff;
  //padding: 10px;
  //border-radius: 10px;
  //color: #ffffff;
}
</style>
