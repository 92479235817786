<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import echarts from 'echarts'

function getFormatter(val) {
  const text = val.name
  const endIndex = text.indexOf('（') // 找到括号的位置作为截取的结束索引
  const frontText = text.substring(0, endIndex)
  return val.marker + frontText + '：' + val.value + '，' + '占比：' + val.percent + '%'
}

export default {
  name: 'PatrolProgressChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    color1: { type: String, default: '#409eff' },
    color2: { type: String, default: '#67c23a' },
    color3: { type: String, default: '#ffba00' },
    color4: { type: String, default: '#f56c6c' },
    color5: { type: String, default: '#909399' },
    count: { type: Number, default: 0 }
  },
  data() {
    return {
      chart: null,
      option: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 0,
          itemGap: 10,
          textStyle: {
            fontWeight: 'bold',
            fontSize: '14',
            lineHeight: '20',
            color: '#666666'
          }
        },
        grid: {
          bottom: '0',
          containLabel: true
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            top: -30,
            bottom: 50,
            label: {
              show: true,
              position: 'center',
              formatter: '统计设备数' + '\n' + this.count,
              fontWeight: 'bold',
              fontSize: '18',
              color: '#666666'
            },
            data: [
              { value: 0, name: '全部完成（完成度 = 100%）', itemStyle: { color: this.color1 }},
              { value: 0, name: '基本完成（70% ≤ 完成度 < 100%）', itemStyle: { color: this.color2 }},
              { value: 0, name: '部分完成（30% ≤ 完成度 < 70%）', itemStyle: { color: this.color3 }},
              { value: 0, name: '完成度较低（0% < 完成度 < 30%）', itemStyle: { color: this.color4 }},
              { value: 0, name: '未开始（完成度 = 0%）', itemStyle: { color: this.color5 }}
            ],
            tooltip: {
              formatter: function(val) {
                return getFormatter(val)
              },
              textStyle: {
                fontWeight: 'bold',
                fontSize: '14'
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(statistic, count) {
      // value
      statistic.forEach(item => {
        if (item.progressName === 'ALL') {
          this.option.series[0].data[0].value = item.equipmentCount
        } else if (item.progressName === 'MOST') {
          this.option.series[0].data[1].value = item.equipmentCount
        } else if (item.progressName === 'MINORITY') {
          this.option.series[0].data[2].value = item.equipmentCount
        } else if (item.progressName === 'LITTLE') {
          this.option.series[0].data[3].value = item.equipmentCount
        } else if (item.progressName === 'NONE') {
          this.option.series[0].data[4].value = item.equipmentCount
        }
      })
      this.option.series[0].label.formatter = '统计设备数' + '\n' + count
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
