<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.name" placeholder="角色名称" />
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <ht-button type="success" icon="el-icon-plus" @click="onCreate">新建角色</ht-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column label="角色名称" prop="name" />
      <ht-table-column label="远程指导类型" prop="remoteGuidanceRole">
        <template v-slot="{row}">
          <span>{{ RemoteGuidanceRoleTypeEnum[row.remoteGuidanceRole] }}</span>
        </template>
      </ht-table-column>
      <ht-table-column label="角色描述" prop="desc" />
      <ht-table-column-operation :width="130">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-edit" @click="openEditRoleDialog(row)">编辑</ht-button>
          <ht-popover-button
            v-if="row.tag !== 'ADMIN' && row.tag !== 'EXPERT' && row.tag !== 'STAFF'"
            type="text"
            :danger-text="true"
            icon="el-icon-delete"
            confirm-title="请确认是否要删除该条数据？"
            @confirmed="onDelete(row)"
          >删除
          </ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>

    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="createRoleDialog" title="新建角色" :width="isMobile ? '100%' : '600px'" :close-on-click-modal="false" @close="closeCreateRoleDialog">
      <div slot="content">
        <role-form v-if="showCreateRoleForm" ref="createRoleFormRef" @cancel-submit="closeCreateRoleDialog" @confirm-submit="closeCreateRoleDialog(true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="editRoleDialog" title="编辑角色" :width="isMobile ? '100%' : '600px'" :close-on-click-modal="false" @close="closeEditRoleDialog">
      <div slot="content">
        <role-form v-if="showEditRoleForm" ref="editRoleFormRef" is-edit :role-id="roleId" @cancel-submit="closeEditRoleDialog" @confirm-submit="closeEditRoleDialog(true)" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getRolesApi, delRoleApi } from '@/api/systemMgr/roleMgr'
import { RemoteGuidanceRoleTypeEnum } from '@/views/systemMgr/enum'
import RoleForm from '@/views/systemMgr/roleMgr/components/roleForm'
import { mapGetters } from 'vuex'

export default {
  components: { RoleForm },
  extends: HtListContainer,
  data() {
    return {
      RemoteGuidanceRoleTypeEnum,
      roleForm: {
        name: undefined,
        remoteGuidanceRole: undefined,
        desc: undefined
      },
      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        remoteGuidanceRole: [{ required: true, message: '请选择远程指导类型', trigger: 'change' }]
      },
      roleId: undefined,
      isSubmitting: false,
      showCreateRoleForm: false,
      showEditRoleForm: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getRolesApi(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onCreate() {
      this.showCreateRoleForm = true
      this.$refs.createRoleDialog.dialogVisible = true
    },
    closeCreateRoleDialog(isRefresh = false) {
      this.showCreateRoleForm = false
      this.$refs.createRoleDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    },
    openEditRoleDialog(row) {
      this.roleId = row.id
      this.showEditRoleForm = true
      this.$refs.editRoleDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.editRoleFormRef.getRoleInfo(row)
      })
    },
    closeEditRoleDialog(isRefresh = false) {
      this.showEditRoleForm = false
      this.$refs.editRoleDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    },
    onDelete(row) {
      delRoleApi(row.id).then(resp => {
        this.$message({ message: '删除成功', type: 'success' })
        this.getList()
      })
    }
  }
}
</script>

<style scoped>

</style>
