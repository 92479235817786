import request from '@/utils/request'

const nsk_checklists = 'nsk/nsk_checklists/'
const nsk_checklist = 'nsk/nsk_checklist/'

// 获取检查单列表
export function getNskChecklists(listQuery) {
  return request({ url: `${nsk_checklists}`, method: 'GET', params: listQuery })
}

// 新增检查单
export function postNskChecklists(data) {
  return request({ url: `${nsk_checklists}`, method: 'POST', data: data })
}

// 修改检查单
export function putNskChecklist(checklistId, data) {
  return request({ url: `${nsk_checklist}${checklistId}`, method: 'PUT', data: data })
}

// 删除检查单
export function deleteNskChecklist(checklistId) {
  return request({ url: `${nsk_checklist}${checklistId}`, method: 'DELETE' })
}

// 获取检查单详情
export function getNskChecklist(checklistId) {
  return request({ url: `${nsk_checklist}${checklistId}`, method: 'GET' })
}

// 检查单导出
export function postExportNskChecklist(checklistId) {
  return request({ url: `nsk/nsk_checklist_xlsx/${checklistId}`, method: 'POST' })
}
