var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticStyle: { "min-height": "400px" },
    },
    [
      _vm.projectData
        ? _c("project-form", {
            ref: "projectForm",
            attrs: {
              "is-view": "",
              "is-edit": _vm.isEdit === "1" || _vm.isEdit === 1,
              "project-id": _vm.projectData.id,
              "project-data": _vm.projectData,
            },
            on: {
              "cancel-submit": _vm.cancelSubmit,
              "confirm-submit": _vm.confirmSubmit,
              "confirm-update": _vm.confirmUpdate,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }