<template>
  <div>
    <el-form ref="equipmentForm" :model="equipmentForm" :rules="equipmentFormRules" label-width="100px" style="margin-right: 20px">
      <div v-for="(item, index) in equipmentForm.checkItems" :key="index">
        <el-form-item :label="`检查项${index +1}：`" :prop="`checkItems[${index}][question]`" class="step-item" :rules="equipmentFormRules.question">
          <el-input v-model="item.question" maxlength="100" :placeholder="`请输入检查项${index +1}内容：`" show-word-limit />
          <el-button icon="el-icon-delete" type="danger" @click="delStep(index)">删除</el-button>
        </el-form-item>
      </div>
      <el-form-item class="info-form-item" label-width="0">
        <span class="info">{{ equipmentForm.checkItems.length === 0 ? '暂无检查项' : '' }}</span>
        <el-button icon="el-icon-plus" type="primary" @click="addCheckItem">添加检查项</el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('equipmentForm')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { putEquipment } from '@/api/constructionEquipmentMgr/equipmentMgr'

export default {
  name: 'ChecklistForm',
  props: {
    equipmentInfo: { type: Object, default: () => {} }
  },
  data() {
    return {
      equipmentForm: {
        code: null,
        name: null,
        province: null,
        city: null,
        district: null,
        address: null,
        specModel: null,
        factoryInfo: null,
        producedAt: null,
        producedNumber: null,
        isAbnormal: false,
        img: null,
        documentInformation: [],
        checkItems: []
      },
      checkItems: {
        question: null,
        answer: null,
        location: null,
        isExecuting: false,
        uploadPhotos: []
      },
      equipmentFormRules: {
        question: [{ required: true, message: '请输入检查项内容', trigger: 'blur' }]
      },
      isSubmitting: false
    }
  },
  created() {
    this.getEquipmentInfo()
  },
  methods: {
    getEquipmentInfo() {
      Object.keys(this.equipmentForm).forEach(key => {
        if (key === 'producedAt') {
          this.equipmentForm[key] = this.equipmentInfo[key] || null
        } else if (key === 'isAbnormal') {
          this.equipmentForm[key] = this.equipmentInfo[key] || false
        } else if (key === 'documentInformation') {
          this.equipmentForm[key] = this.equipmentInfo[key] || []
        } else if (key === 'checkItems') {
          this.equipmentForm[key] = this.equipmentInfo.checkItems && this.equipmentInfo.checkItems.length > 0 ? this.equipmentInfo.checkItems : []
        } else {
          this.equipmentForm[key] = this.equipmentInfo[key] || null
        }
      })
    },
    addCheckItem() {
      const checkItem = {
        question: null,
        answer: null,
        location: null,
        isExecuting: false,
        uploadPhotos: []
      }
      this.equipmentForm.checkItems.push(checkItem)
    },
    delStep(index) {
      this.equipmentForm.checkItems.splice(index, 1)
    },
    confirmSubmit() {
      this.$refs.equipmentForm.validate(valid => {
        if (valid) {
          putEquipment(this.equipmentInfo.id, this.equipmentForm).then(() => {
            this.$message({ message: `修改成功`, type: 'success', center: true })
            this.$emit('confirm-submit')
            this.isSubmitting = false
          }).catch(() => {
            this.isSubmitting = false
          })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    }
  }
}
</script>

<style scoped lang="scss">
.step-item{
  ::v-deep .el-form-item__content{
    display: flex;
    flex-direction: row;
    align-items: center;
    .el-button{
      margin-left: 20px;
    }
  }
}

.info-form-item{
  ::v-deep .el-form-item__content{
    display: flex;
    flex-direction: row;
    align-items: center;
    .info{
      flex: 1;
      margin-left: 20px;
      font-size: 16px;
    }
  }
}
</style>
