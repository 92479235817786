var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "品番" },
                model: {
                  value: _vm.listQuery.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "code", $$v)
                  },
                  expression: "listQuery.code",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "品名" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "图番" },
                model: {
                  value: _vm.listQuery.illustrationNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "illustrationNumber", $$v)
                  },
                  expression: "listQuery.illustrationNumber",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "供应商" },
                model: {
                  value: _vm.listQuery.supplierName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "supplierName", $$v)
                  },
                  expression: "listQuery.supplierName",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleCreateNskMaterialDialog(true)
                    },
                  },
                },
                [_vm._v("新建部品")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.NskMaterialMgrListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "关联检查模版"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.nskTemplateId === null
                                    ? "-"
                                    : row[item.prop] + "-" + row.nskTemplateCode
                                )
                              ),
                            ])
                          : item.label === "部品图"
                          ? _c(
                              "div",
                              { staticClass: "img-container" },
                              [
                                row.illustrationImg
                                  ? _c("el-image", {
                                      staticClass: "img",
                                      attrs: {
                                        src: row.illustrationImg,
                                        "preview-src-list": [
                                          row.illustrationImg,
                                        ],
                                      },
                                    })
                                  : _c("span", [_vm._v(_vm._s("-"))]),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 150 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleEditNskMaterialDialog(row, true)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          icon: "el-icon-delete",
                          "confirm-title": "请确认是否要删除该部品？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.onDelete(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "createNskMaterialDialog",
          attrs: {
            title: "新建部品",
            width: _vm.isMobile ? "100%" : "600px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleCreateNskMaterialDialog(false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showCreateNskMaterialForm
                ? _c("nsk-material-form", {
                    ref: "createNskMaterialForm",
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleCreateNskMaterialDialog(false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleCreateNskMaterialDialog(false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "editNskMaterialDialog",
          attrs: {
            title: "编辑部品",
            width: _vm.isMobile ? "100%" : "600px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleEditNskMaterialDialog(null, false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showEditNskMaterialForm
                ? _c("nsk-material-form", {
                    ref: "editNskMaterialForm",
                    attrs: { "is-edit": "" },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleEditNskMaterialDialog(null, false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleEditNskMaterialDialog(
                          null,
                          false,
                          true
                        )
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }