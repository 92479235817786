<template>
  <div class="own-card">
    <el-card style="margin-bottom: 50px">
      <div class="title-card-container">
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">编号:</span>
          <span class="textStyle" style="color: #48a0ff;font-weight: bold">{{ reportItem.recordNo }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">记录人:</span>
          <span class="textStyle">{{ reportItem.staff ? reportItem.staff.fullName : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">时间:</span>
          <span class="textStyle">{{ reportItem.createdAt || '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <div class="labelStyle labelWidth_70">记录名称:</div>
          <div v-if="!isEditProjectName" style="display: flex; align-items: center">
            <span class="textStyle">{{ reportItem.projectName || '-' }}</span>
            <el-link type="primary" @click="isEditProjectName = true">修改记录名称</el-link>
          </div>
          <span v-else>
            <el-input
              v-model="editProjectName"
              placeholder="输入记录名称"
              style="width: 150px"
            />
            <el-link type="success" @click="projectDetail('projectName', editProjectName, 'isEditProjectName')">确定提交</el-link>
            <el-link type="danger" @click="cancelEditProjectDetail('projectName', 'editProjectName', 'isEditProjectName')">取消输入</el-link>
          </span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">状态:</span>
          <status v-if="reportItem.status" :status="reportItem.status.value" :status-types="{ 草稿: 'danger', 已提交: 'success' }" />
        </div>
        <div class="title-card-item-full-line">
          <div class="labelStyle labelWidth_70" style="vertical-align: top;">备注:</div>
          <div v-if="!isEditReportComment" style="display: flex; align-items: center">
            <span class="textStyle">{{ reportItem.comment || '-' }}</span>
            <el-link type="primary" @click="isEditReportComment = true">修改备注</el-link>
          </div>
          <span v-else>
            <el-input
              v-model="editReportComment"
              type="textarea"
              :rows="3"
              style="width:450px"
              placeholder="请输入备注"
            />
            <el-link type="success" @click="projectDetail('comment', editReportComment, 'isEditReportComment')">确定提交</el-link>
            <el-link type="danger" @click="cancelEditProjectDetail('comment', 'editReportComment', 'isEditReportComment')">取消输入</el-link>
          </span>
        </div>
        <div class="title-card-item-full-line">
          <span class="labelStyle labelWidth_70">位置区域:</span>
          <span class="textStyle">{{ reportItem.locationAndInfo || '-' }}</span>
        </div>
      </div>
    </el-card>
    <el-divider><i class="el-icon-chat-line-square" /> 记录报告</el-divider>
    <!--    <ht-action-panel>-->
    <!--      <template slot="right">-->
    <!--        <template>-->
    <!--          <ht-button-->
    <!--            type="primary"-->
    <!--            plain-->
    <!--            icon="el-icon-document"-->
    <!--            :disabled="remoteGuidanceData && remoteGuidanceData.length === 0"-->
    <!--            @click="remoteGuidanceRecords()"-->
    <!--          >远程指导记录</ht-button>-->
    <!--          <ht-button-->
    <!--            :disabled="reportsRecords.length === 0"-->
    <!--            type="warning"-->
    <!--            icon="el-icon-document"-->
    <!--            @click="reportsRecordsVisible = true"-->
    <!--          >查看历史图文报告-->
    <!--          </ht-button>-->
    <!--          <ht-button-->
    <!--            v-if="!showSelector"-->
    <!--            :disabled="list.length === 0"-->
    <!--            type="primary"-->
    <!--            icon="el-icon-document"-->
    <!--            @click="showSelectorOfItems"-->
    <!--          >生成图文报告-->
    <!--          </ht-button>-->
    <!--          <template v-else>-->
    <!--            <ht-popover-button-->
    <!--              type="success"-->
    <!--              :disabled="selectedRecordsCount === 0"-->
    <!--              icon="el-icon-circle-check"-->
    <!--              :loading="confirmReportLoading"-->
    <!--              :confirm-title="`已选择${selectedRecordsCount}条记录，是否确认生成报告？`"-->
    <!--              @confirmed="confirmReport"-->
    <!--            >确认生成报告-->
    <!--            </ht-popover-button>-->
    <!--            <ht-button type="warning" @click="cancelEdit()">取消生成报告</ht-button>-->
    <!--          </template>-->
    <!--        </template>-->
    <!--      </template>-->
    <!--    </ht-action-panel>-->
    <ht-table
      ref="list"
      v-loading="isLoading"
      :data="list"
      :row-key="getRowKey"
      :stripe="false"
      :highlight-current-row="false"
      height="620"
      @selection-change="onSelectionChange"
    >
      <el-table-column v-if="showSelector" type="selection" />
      <el-table-column label="序号" type="index" fixed="left" />
      <el-table-column label="记录项">
        <template v-slot="{row}">
          {{ row.checkName }}
        </template>
      </el-table-column>
      <el-table-column label="时间" prop="checkTime" min-width="160px">
        <template v-slot="{row}">
          {{ row.checkTime }}
        </template>
      </el-table-column>
      <el-table-column label="图片" min-width="300px">
        <template v-slot="{row}">
          <image-box :check-item-images="row.items" />
        </template>
      </el-table-column>
      <el-table-column label="文字描述" min-width="200px">
        <template v-slot="{row}">
          <div class="edit-box">
            <div v-if="!row.editRowDescription" class="textStyle">{{ row.description || '-' }}</div>
            <el-input
              v-else
              v-model="row.descriptionRow"
              :disabled="!row.editRowDescription"
              type="textarea"
              :rows="5"
              placeholder="请输入文字描述"
              class="edit-input"
            />

            <div class="edit-button">
              <el-link v-if="!row.editRowDescription" type="primary" @click="row.editRowDescription = true">修改描述</el-link>
              <span v-else>
                <el-link style="display:block" type="success" :underline="false" @click="confirmUpdateRowData(row, 'description', 'editRowDescription')">确定提交</el-link>
                <el-link type="danger" :underline="false" @click="cancelEditRowData(row, 'description', 'editRowDescription')">取消输入</el-link>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="记录项备注" prop="checkComment" min-width="200px">
        <template v-slot="{row}">
          <div class="edit-box">
            <div v-if="!row.editRowComment" class="textStyle">{{ row.checkComment || '-' }}</div>
            <el-input
              v-else
              v-model="row.checkCommentRow"
              :disabled="!row.editRowComment"
              type="textarea"
              :rows="6"
              placeholder="请输入备注"
              class="edit-input"
            />

            <div class="edit-button">
              <el-link v-if="!row.editRowComment" type="primary" @click="row.editRowComment = true">修改备注</el-link>
              <template v-else>
                <el-link style="display:block" type="success" :underline="false" @click="confirmUpdateRowData(row, 'checkComment', 'editRowComment')">确定提交</el-link>
                <el-link type="danger" @click="cancelEditRowData(row, 'checkComment', 'editRowComment')">取消输入</el-link>
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
    </ht-table>
    <el-dialog
      v-if="reportsRecordsVisible"
      :visible.sync="reportsRecordsVisible"
      title="历史图文报告"
      width="50%"
    >
      <div class="clearFloat">
        <el-table border :data="reportsRecords">
          <el-table-column label="创建时间" prop="createdAt" />
          <el-table-column label="项目报告名称" prop="name">
            <template v-slot="{row}">
              <el-link type="primary" @click="downloadHistoryReport(row.ossFilePath)">{{ row.name }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="staffFullName" />
        </el-table>
        <ht-pagination
          style="float: right;clear:both;"
          :total="reportsRecordsPage.total"
          :page.sync="reportsRecordsListQuery.page"
          :limit.sync="reportsRecordsListQuery.perPage"
          @pagination="getProjectReportsDocRecords"
        />
      </div>
    </el-dialog>

    <ht-dialog ref="dialog" title="远程指导记录" width="70%">
      <div slot="content">
        <remote-guidance-dialog
          v-if="remoteGuidanceData && remoteGuidanceData[0] && remoteGuidanceData[0].id"
          :guidance-id="remoteGuidanceData[0].id"
          :biz-type="reportItem.guidanceBizType.value"
          :biz-view="true"
        />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer/index'
import { getProjectCheckDetailApi,
  updateProjectCheckDetailApi,
  getProjectCheckItemsApi,
  updateProjectCheckItems,
  projectReportsDoc,
  getProjectReportsDocRecordsApi
} from '@/api/projectCheck/projectReports'
import ImageBox from '@/views/components/imageBox'
import { getRemoteGuidanceIdApi } from '@/api/remoteGuidance/remoteGuidance'
import remoteGuidanceDialog from '@/views/remoteGuidance/view'
import { stringTrailingSlash } from '@/utils'

export default {
  components: { ImageBox, remoteGuidanceDialog },
  extends: HtListContainer,
  props: {
    recordId: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      checkList: [],
      showSelector: false,
      selectedRecords: [],
      selectedRecordsCount: 0,
      reportItem: {},
      labelToProps: [
        {
          label: '发送人',
          prop: 'fromUser'
        },
        {
          label: '消息类型',
          prop: 'mediaType'
        },
        {
          label: '内容',
          prop: 'content'
        },
        {
          label: '时间',
          prop: 'chatTime'
        }
      ],
      visible: false,
      confirmReportLoading: false,
      isEditProjectName: false,
      editProjectName: undefined,
      editRowComment: false,
      comment: undefined,
      isEditReportComment: false,
      editReportComment: undefined,
      reportsRecordsVisible: false,
      reportsRecords: [],
      reportsRecordsListQuery: { page: 1, perPage: 20 },
      reportsRecordsPage: { total: 0 },
      rowDescription: undefined,
      rowComment: undefined,
      remoteGuidanceData: undefined
    }
  },
  created() {
    this.getReportItem()
    this.getAccidentChatRecords()
    this.getProjectReportsDocRecords()
  },
  methods: {
    getReportItem() {
      getProjectCheckDetailApi(this.recordId).then((resp) => {
        this.reportItem = resp
        this.editProjectName = this.reportItem.projectName
        this.editReportComment = this.reportItem.comment
        getRemoteGuidanceIdApi(resp.guidanceBizType.name, this.recordId).then((resp) => {
          this.remoteGuidanceData = resp
        })
      })
    },
    getAccidentChatRecords() {
      getProjectCheckItemsApi(this.recordId, this.listQuery).then((resp) => {
        if (resp.length > 0) {
          resp.forEach(item => {
            item.editComment = item.comment
            item.editRowDescription = false
            item.editRowComment = false
            item.descriptionRow = item.description
            item.checkCommentRow = item.checkComment
          })
          this.list = resp
        }
      })
    },
    onSelectionChange(val) {
      this.selectedRecords = val
      this.selectedRecordsCount = this.selectedRecords.length
    },
    showSelectorOfItems() {
      this.showSelector = true
      this.$nextTick(() => {
        this.$refs.list.toggleAllSelection()
      })
      this.$message({
        message: '请在表格中勾选需要生成报告的记录',
        center: true,
        type: 'warning'
      })
    },
    projectDetail(key, val, isShow) {
      const data = {}
      data[key] = val
      updateProjectCheckDetailApi(this.recordId, data).then(() => {
        this.$message({ message: '更新成功～', center: true, type: 'success' })
        this[isShow] = false
        this.getReportItem()
      })
    },
    cancelEditProjectDetail(key, val, show) {
      this[val] = this.reportItem[key]
      this[show] = false
    },
    confirmUpdateRowData(row, key, show) {
      row[show] = false
      const data = {}
      data[key] = row[`${key}Row`]
      updateProjectCheckItems(this.recordId, row.id, data).then(() => {
        this.$message({ message: '更新成功～', center: true, type: 'success' })
        this.getAccidentChatRecords()
      })
    },
    cancelEditRowData(row, key, show) {
      row[`${key}Row`] = row[key]
      row[show] = false
    },
    cancelEdit() {
      this.showSelector = false
      this.$nextTick(() => {
        this.$refs.list.clearSelection()
      })
    },
    confirmReport() {
      this.confirmReportLoading = true
      const checkItemIds = this.selectedRecords.map(i => i.id)
      projectReportsDoc(this.recordId, { checkItemIds: checkItemIds }).then((resp) => {
        window.open(`${stringTrailingSlash(process.env.VUE_APP_COS_SERVER)}${resp.filePath}`)
        this.showSelector = false
        this.confirmReportLoading = false
      }).catch(() => {
        this.showSelector = false
        this.confirmReportLoading = false
      })
    },
    getProjectReportsDocRecords() {
      getProjectReportsDocRecordsApi(this.recordId, this.reportsRecordsListQuery).then((resp) => {
        this.reportsRecords = resp.items
        this.reportsRecordsPage = resp.page
      })
    },
    downloadHistoryReport(val) {
      window.open(`${stringTrailingSlash(process.env.VUE_APP_COS_SERVER)}${val}`)
    },
    remoteGuidanceRecords() {
      this.$refs.dialog.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .clearFloat:after{
    content: '';
    display: block;
    clear: both;
  }
  .edit-box {
    position: relative;
    text-align: left;
  }
  .edit-button {
    position: absolute;
    top: calc(50% - 14px);
    right: 3%;
    width: 100px;
    text-align: right;
  }
  .edit-input {
    width: 70%;
    text-align: left;
  }
</style>
