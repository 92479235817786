import request from '@/utils/request'

const REMOTE_GUIDANCE_NEW = 'remote_guidance/new/'

// 检查会议状态
export function getMeetingRoomTemp(roomId) {
  return request({ url: `${REMOTE_GUIDANCE_NEW}${roomId}/temp`, method: 'GET' })
}

// 创建临时用户
export function postMeetingTemplateUser(data) {
  return request({ url: 'user/staffs/temp/', method: 'POST', data: data })
}
