var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "result-container",
          style: { height: _vm.height + "px" },
        },
        [
          _c("div", { staticClass: "result-top-container" }, [
            _c(
              "div",
              {
                staticClass: "result-top-left-container",
                attrs: { title: "hf_use_description" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "result-button",
                    attrs: {
                      type: "text",
                      disabled: _vm.buttonDisabled,
                      title: "区域",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openChooseItemDialog("区域")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "required-item" }, [_vm._v("*")]),
                    _vm._v("区域:\n        "),
                  ]
                ),
                _vm._v(" "),
                _c("el-input", {
                  ref: "rectDescRef",
                  staticClass: "result-input",
                  attrs: { placeholder: "请选择区域", disabled: "" },
                  model: {
                    value: _vm.submitResults.areaName,
                    callback: function ($$v) {
                      _vm.$set(_vm.submitResults, "areaName", $$v)
                    },
                    expression: "submitResults.areaName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "result-top-right-container",
                attrs: { title: "hf_use_description" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "result-button",
                    attrs: {
                      type: "text",
                      disabled: _vm.buttonDisabled,
                      title: "楼层",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openChooseItemDialog("楼层")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "required-item" }, [_vm._v("*")]),
                    _vm._v("楼层:\n        "),
                  ]
                ),
                _vm._v(" "),
                _c("el-input", {
                  ref: "rectDescRef",
                  staticClass: "result-input",
                  attrs: { placeholder: "请选择楼层", disabled: "" },
                  model: {
                    value: _vm.submitResults.floorName,
                    callback: function ($$v) {
                      _vm.$set(_vm.submitResults, "floorName", $$v)
                    },
                    expression: "submitResults.floorName",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "result-bottom-container" }, [
            _c("div", { staticClass: "result-middle-top-container" }, [
              _c("span", { staticClass: "title txt task" }, [
                _vm._v("任务组：" + _vm._s(_vm.submitResults.taskCode)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "title txt goods" }, [
                _vm._v("货品总数：" + _vm._s(_vm.submitResults.goods.length)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "result-button",
                      attrs: {
                        type: "text",
                        disabled: _vm.buttonDisabled,
                        title: "容器号",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("scan-box-bar-code")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "required-item" }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("容器号:\n          "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      ref: "rectDescRef",
                      staticClass: "result-input",
                      attrs: { placeholder: "请扫描容器号", disabled: "" },
                      model: {
                        value: _vm.submitResults.boxCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.submitResults, "boxCode", $$v)
                        },
                        expression: "submitResults.boxCode",
                      },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { slot: "append", "icon-class": "scan" },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "result-middle-bottom-container" },
              _vm._l(_vm.submitResults.goods, function (item, index) {
                return _c("div", { key: index, staticClass: "box-item" }, [
                  _vm._v(
                    "\n          货品：" + _vm._s(item.code) + "\n        "
                  ),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "submit-button square-circle-button",
              attrs: { type: "text", disabled: _vm.buttonDisabled },
              on: {
                click: function ($event) {
                  return _vm.handleActions("to-pick")
                },
              },
            },
            [_vm._v("去拣货")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "custom-dialog",
        {
          ref: "chooseItemDialog",
          attrs: {
            title: "选择" + _vm.dialogTitle,
            width: "500px",
            color: "#2e5aff",
          },
          on: { close: _vm.closeChooseItemDialog },
        },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.closeChooseItemDialog },
                  model: {
                    value: _vm.chooseItem,
                    callback: function ($$v) {
                      _vm.chooseItem = $$v
                    },
                    expression: "chooseItem",
                  },
                },
                _vm._l(_vm.chooseItems, function (item, key) {
                  return _c("el-radio", { key: key, attrs: { label: item } }, [
                    _vm._v(_vm._s(item.name)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-section" }, [
      _c("div", { staticClass: "view-title top-title" }, [
        _vm._v("拣货-领取任务"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }