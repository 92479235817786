var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c("input", {
        ref: "fileInputBtn",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          name: "file-input",
          multiple: _vm.multipleSelection,
          accept: _vm.fileAccept,
        },
        domProps: { value: _vm.filesValue },
        on: { change: _vm.onFilesSelected },
      }),
      _vm._v(" "),
      _vm.selectImg
        ? _c("el-image", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
            staticClass: "select-img",
            attrs: { src: _vm.SelectImg },
            on: { click: _vm.onFileInputClick },
          })
        : _c(
            "ht-button",
            {
              attrs: {
                type: _vm.buttonType,
                size: "mini",
                icon: _vm.buttonIcon,
                loading: _vm.isLoading,
              },
              on: { click: _vm.onFileInputClick },
            },
            [_vm._v(_vm._s(_vm.buttonTitle))]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }