<template>
  <div>
    <div style="display: flex;flex-wrap: wrap;">
      <div v-for="(i, index) in checkItemImages" :key="index" style="width: 100px; height: 100px;margin: 5px 10px 0 0">
        <el-image
          style="width: 100%;height: 100%"
          :src="i.content"
          :preview-src-list="seyPreImages()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageBox',
  props: {
    checkItemImages: {
      type: Array,
      default: undefined
    }
  },
  created() {
    this.seyPreImages()
  },
  methods: {
    seyPreImages() {
      return this.checkItemImages.map(e => e.content)
    }
  }
}
</script>

<style scoped>

</style>
