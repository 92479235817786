<template>
  <div class="stock-detail-container">
    <equipment-card :is-loading="isEquipmentLoading" :equipment-info="equipmentInfo" />
    <dw-title title="库存详情" is-mobile />
    <div v-if="warehouseStockList.length > 0" class="warehouse-container">
      <dw-title title="仓库库存" is-mobile />
      <div v-for="(item,index) in warehouseStockList" :key="index" class="warehouse-item">
        <div class="item">{{ item.name }}</div>
        <div class="item">库存数：{{ item.quantity }}</div>
      </div>
    </div>
    <div v-if="projectStockList.length > 0" class="project-container">
      <dw-title title="工程现存" is-mobile />
      <div v-for="(item,index) in projectStockList" :key="index" class="project-item">
        <div class="item">{{ item.constructionProjectName }}</div>
        <div class="item">现存数：{{ item.quantity }}</div>
      </div>
    </div>
    <dw-title v-if="logisticsTraces.length > 0" title="物流跟踪" is-mobile />
    <logistics-trace :is-loading="isLogisticsTracesLoading" :logistics-traces="logisticsTraces" />
  </div>
</template>

<script>
import equipmentCard from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/equipmentCard'
import { getEquipment } from '@/api/constructionEquipmentMgr/equipmentMgr'
import { getEquipmentStock, getEquipmentStockRecords } from '@/api/constructionEquipmentMgr/stockMgr'
import LogisticsTrace from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/logisticsTrace'

export default {
  name: 'EquipmentStockView',
  components: { equipmentCard, LogisticsTrace },
  props: {
    equipmentId: { type: [String, Number], default: null }
  },
  data() {
    return {
      equipmentInfo: null,
      warehouseStockList: [],
      projectStockList: [],
      logisticsTraces: [],
      isEquipmentLoading: false,
      isEquipmentStockLoading: false,
      isLogisticsTracesLoading: false
    }
  },
  created() {
    this.getEquipmentInfo()
    this.getEquipmentStockInfo()
    this.getEquipmentLogisticsTraces()
  },
  methods: {
    getEquipmentInfo() {
      this.isEquipmentLoading = true
      getEquipment(this.equipmentId).then(resp => {
        this.equipmentInfo = resp
        this.isEquipmentLoading = false
      }).catch(() => { this.isEquipmentLoading = false })
    },
    getEquipmentStockInfo() {
      this.isEquipmentStockLoading = true
      getEquipmentStock(this.equipmentId).then(resp => {
        this.warehouseStockList = resp.warehouseStock
        this.projectStockList = resp.projectStock
        this.isEquipmentStockLoading = false
      }).catch(() => { this.isEquipmentStockLoading = false })
    },
    getEquipmentLogisticsTraces() {
      this.isLogisticsTracesLoading = true
      getEquipmentStockRecords(this.equipmentId).then(resp => {
        this.logisticsTraces = resp
        this.isLogisticsTracesLoading = false
      }).catch(() => { this.isLogisticsTracesLoading = false })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/mobile-common-style.scss';
.stock-detail-container{
  margin: 15px 15px 100px;
  color: #666666;
  .warehouse-container, .project-container{
    font-size: 1.1rem;
    background: #f0f9eb;
    padding: 5px 15px 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    margin: 15px 0;
    .warehouse-item, .project-item{
      border-bottom: 1px solid #999999;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .item:first-child{
        font-weight: bold;
        flex: 2;
      }
      .item:last-child{
        flex: 1;
        text-align: right;
      }
    }
    .warehouse-item:first-child, .project-item:first-child{
      padding-top: 0;
    }
    .warehouse-item:last-child, .project-item:last-child{
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
</style>
