<template>
  <div>
    <el-form ref="copyTemplateForm" :model="copyTemplateForm" :rules="copyTemplateFormRules" label-width="100px" style="margin-right: 20px">
      <el-form-item label="表番：" prop="code">
        <el-input v-model="copyTemplateForm.code" placeholder="请输入表番" :maxlength="30" show-word-limit />
      </el-form-item>
      <el-form-item label="表名：" prop="name">
        <el-input v-model="copyTemplateForm.name" placeholder="请输入表名" :maxlength="30" show-word-limit />
      </el-form-item>
      <el-form-item label="关联部品：" prop="nskMaterialId" label-width="100px">
        <el-select
          v-model="copyTemplateForm.nskMaterialId"
          filterable
          :placeholder="nskMaterialList.length > 0 ? '请选择部品' : '当前无可选部品'"
          clearable
          style="width: 100%"
          remote
          :remote-method="getNskMaterialsList"
          @clear="copyTemplateForm.nskMaterialId = null"
        >
          <el-option v-for="item in nskMaterialList" :key="item.id" :label="`${item.name}${item.code}`" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <ht-button icon="el-icon-close" @click="cancelSubmit">取 消</ht-button>
      <ht-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="submitForm('copyTemplateForm')">确定复制</ht-button>
    </div>
  </div>
</template>

<script>
import { validateNumOrLetterOrSymbol } from '@/utils/validate'
import { getNskMaterials } from '@/api/incomingInspectionMgr/nskMaterialMgr'
import { postNskTemplates } from '@/api/incomingInspectionMgr/nskTemplateMgr'

export default {
  name: 'CopyTemplateForm',
  props: {
    templateData: { type: Object, default: () => {} }
  },
  data() {
    return {
      copyTemplateForm: {
        code: null,
        name: null,
        nskMaterialId: null,
        steps: []
      },
      copyTemplateFormRules: {
        code: [
          { required: true, message: '请输入表番', trigger: 'blur' },
          { message: '请输入数字、字母、或符号（./-_:）', trigger: 'blur', validator: validateNumOrLetterOrSymbol }
        ],
        name: [{ required: true, message: '请输入表名', trigger: 'blur' }]
      },
      nskMaterialList: [],
      isSubmitting: false
    }
  },
  created() {
    this.getCopyTemplateForm()
    this.getNskMaterialsList()
  },
  methods: {
    getCopyTemplateForm() {
      this.copyTemplateForm.name = '复制：' + this.templateData.name
      this.copyTemplateForm.code = 'copy-' + this.templateData.code
      const keys = ['specType', 'character', 'measuringTools', 'level', 'specDesc', 'standardValue', 'upperTolerance', 'lowerTolerance']
      this.copyTemplateForm.steps = this.templateData.steps.map(step => {
        return keys.reduce((newStep, key) => {
          newStep[key] = step[key] !== undefined ? step[key] : ''
          return newStep
        }, {})
      })
      this.copyTemplateForm.steps.forEach((step, index) => {
        step.isStart = index === 0
        step.isEnd = index === this.copyTemplateForm.steps.length - 1
        step.sort = index + 1
      })
    },
    getNskMaterialsList(value) {
      const listQuery = {
        offset: 0,
        count: 20,
        keyword: value
      }
      getNskMaterials(listQuery).then(resp => {
        this.nskMaterialList = resp.filter(item => item.nskTemplateId === null)
      })
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    submitForm(copyTemplateForm) {
      this.$refs[copyTemplateForm].validate(valid => {
        if (valid) {
          this.isSubmitting = true
          postNskTemplates(this.copyTemplateForm).then(resp => {
            this.$message({ message: `复制成功`, type: 'success', center: true })
            this.isSubmitting = false
            this.$emit('confirm-submit', resp.id)
          }).catch(() => {
            this.isSubmitting = false
          })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
