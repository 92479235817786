<template>
  <div>
    <el-form ref="stopLeaseForm" :model="stopLeaseForm" :rules="stopLeaseFormRules" label-width="100px">
      <el-form-item label="设备名称：">{{ equipmentInfo && equipmentInfo.constructionEquipmentName }}</el-form-item>
      <el-form-item label="现存数量：">{{ equipmentInfo && equipmentInfo.projectEquipmentQuantity }}</el-form-item>
      <el-form-item label="入库仓库：" prop="warehouseId">
        <el-select
          v-model="stopLeaseForm.warehouseId"
          filterable
          :placeholder="warehouseList.length > 0 ? '请输入仓库名称进行搜索': '当前无可选仓库'"
          style="width: 100%"
          remote
          :remote-method="getWarehouses"
        >
          <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit()">保存</el-button>
    </div>
  </div>
</template>

<script>
import { putProjectStopLease } from '@/api/constructionProjectMgr/projectMgr'
import { getWarehouses } from '@/api/constructionEquipmentMgr/warehouse'

export default {
  name: 'StopLeaseForm',
  data() {
    return {
      equipmentInfo: null,
      projectId: null,
      stopLeaseForm: {
        constructionEquipmentId: null,
        warehouseId: null,
        quantity: null,
        isWhole: false
      },
      stopLeaseFormRules: {
        warehouseId: [{ required: true, message: '请选择入库仓库', trigger: 'blur' }]
      },
      isSubmitting: false,
      warehouseList: []
    }
  },
  methods: {
    getInfo(equipmentInfo, projectId) {
      this.equipmentInfo = equipmentInfo
      this.projectId = projectId
      this.stopLeaseForm.constructionEquipmentId = equipmentInfo.constructionEquipmentId
      this.stopLeaseForm.quantity = equipmentInfo.projectEquipmentQuantity
      this.stopLeaseForm.isWhole = equipmentInfo.constructionEquipmentIsWhole
      this.getWarehouses()
    },
    getWarehouses(value) {
      const listQuery = {
        offset: 0,
        count: 20,
        name: value
      }
      getWarehouses(listQuery).then(resp => {
        resp.forEach(item => { item.inboundQuantity = 0 })
        this.warehouseList = resp
      })
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      this.$refs.stopLeaseForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          putProjectStopLease(this.projectId, this.stopLeaseForm).then(resp => {
            this.$message({ message: `修改成功`, type: 'success', center: true })
            this.$emit('confirm-submit')
            this.isSubmitting = false
          }).catch(() => {
            this.isSubmitting = false
          })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
