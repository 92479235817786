<template>
  <div class="config-container">
    <div
      v-for="(item, index) in VideoResolutions"
      :key="index"
      class="config-item"
      :class="[{'is-select': videoResolution === item.name}]"
      @click="selectResolution(item)"
    >{{ item.value }}</div>
  </div>
</template>

<script>
import { VideoResolutions } from '@/views/newRemoteGuidance/var/businessVar'

export default {
  name: 'Configuration',
  props: {
    videoResolution: {
      type: String,
      default: '720P'
    }
  },
  data() {
    return {
      VideoResolutions
    }
  },
  methods: {
    selectResolution(item) {
      this.$emit('select-resolution', item.name)
    }
  }
}
</script>

<style scoped lang="scss">
.config-container{
  background: #d4e5fe;
  border-radius: 10px;
  .config-item{
    margin: 10px;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
  }
  .is-select{
    background: #2E5AFF;
    color: #ffffff;
  }
  .config-item:hover{
    background: #2E5AFF;
    color: #ffffff;
  }
}
</style>
