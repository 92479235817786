<template>
  <el-card v-loading="isLoading" style="min-width: 1000px">
    <div class="order-title">
      <div class="order-name">{{ workOrder && workOrder.name }}</div>
      <div class="order-code">{{ workOrder ? `（${workOrder.code}）` : `` }}</div>
    </div>
    <div class="order-info-container">
      <el-descriptions class="order-descriptions" :column="2" size="medium" border :label-style="{width:'110px'}">
        <el-descriptions-item :span="2">
          <template slot="label">工程</template>
          <span>{{ workOrder && workOrder.targetInfo && workOrder.targetInfo.projectName || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">整机设备</template>
          <!--          <span>{{ workOrder && workOrder.targetName ? `${workOrder.targetName}-${workOrder.targetCode}`: '-' }}</span>-->
          <span>{{ workOrder && workOrder.targetCode ? workOrder.targetCode: '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">工作流程</template>
          <span>{{ workOrder && workOrder.taskTemplateName || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">作业类型</template>
          <span>{{ workOrder && getTowerWorkOrderType(workOrder.towerWorkType) ? getTowerWorkOrderType(workOrder.towerWorkType).value : '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">创建人</template>
          <span>{{ workOrder && workOrder.staff && workOrder.staff.fullName || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">创建时间</template>
          <span>{{ workOrder && workOrder.createdAt || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">执行人</template>
          <span>{{ workOrder && workOrder.executor && workOrder.executor.fullName || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">开始执行时间</template>
          <span>{{ workOrder && workOrder.startAt || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">完成时间</template>
          <span>{{ workOrder && workOrder.finishAt || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">工单执行状态</template>
          <span>{{ workOrder && TaskWorkOrderStatusEnum[workOrder.status] || '-' }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">文件上传状态</template>
          <span>{{ workOrder && FileUploadStatusEnum[workOrder.fileUploadStatus] || '-' }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <div class="device-tracks-container">
        <img v-if="showFullScreenImg && showDeviceTracks" :src="FullScreen" alt="全屏" class="full-screen-img" @click="openTraceDialog">
        <device-tracks
          v-if="showDeviceTracks"
          ref="smallDeviceTracks"
          class="device-tracks"
          height="250px"
          map-id="smallDeviceTracks"
          :show-image="showDeviceImg"
          :track-arr="deviceTrackArr"
          :center="deviceTrackCenter"
          :position="devicePosition"
        />
        <div v-else class="no-device-tracks-info">暂无轨迹信息</div>
      </div>
    </div>
    <file-list :file-list="workOrder && workOrder.uploadFiles" style="margin-bottom: 20px" @on-upload-file="onFileUploaded" @on-delete-file="onDeleteFile" />
    <el-row style="margin: 20px 0; text-align: right">
      <ht-button :loading="isExporting" type="primary" icon="el-icon-document" @click="onExportPdf">导出工单</ht-button>
      <ht-button type="primary" @click="openTraceDialog">查看轨迹</ht-button>
    </el-row>

    <ht-table id="workOrderSteps" ref="list" :data="steps" :stripe="false" row-key="id" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <ht-table-column label="序号" type="index" fixed="left" class-name="index-column" />
      <ht-table-column
        v-for="item in PatrolWorkOrderResultListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
        :align="item.align || 'center'"
        :show-overflow-tooltip="false"
      >
        <template v-slot="{row}">
          <div v-if="item.label === '图片'" class="result-img-container">
            <template v-if="row.results && row.results.uploadPhotos && row.results.uploadPhotos.length > 0">
              <el-image v-for="(imgItem, imgIndex) in row.results.uploadPhotos" :key="imgIndex" :src="imgItem" :preview-src-list="row.results.uploadPhotos" class="img" />
            </template>
            <template v-else>{{ '-' }}</template>
          </div>
          <span v-else-if="item.label === '步骤名称'" class="cell-overflow">{{ row.properties.title || '-' }}</span>
          <span v-else-if="item.label === '检查要求'" class="cell-overflow">
            <template> {{ row.properties.text || '-' }}</template>
          </span>
          <span v-else-if="item.label === '情况描述'" class="cell-overflow">
            <template v-if="row.results.factDesc && row.results.factDesc !== null && typeof row.results.factDesc === 'object'">
              <template v-if="row.results.factDesc.length > 0">
                <div v-for="(factItem, index) in row.results.factDesc" :key="index">{{ `${index + 1}.${factItem}` }}</div>
              </template>
              <template v-else>-</template>
            </template>
            <template v-else>{{ row.results.factDesc || '-' }}</template>
          </span>
          <span v-else-if="item.label === '结果'" :style="getResultTextColor(row)" class="cell-overflow">
            <template v-if="row.tag === 'ResultRecordType'">{{ row.results.checkResult ? ResultsEnum[row.results.checkResult] : '暂无' }}</template>
            <template v-else-if="row.tag === 'ValueRecordType'"><span v-html="getValueRecordResult(row)" /></template>
            <template v-else-if="row.tag === 'HiddenDangerRecordType'"><span v-html="getHiddenDangerRecordResult(row)" /></template>
            <template v-else-if="row.tag === 'AccidentRecordType'">{{ row.results.accidentType ? AccidentTypeEnum[row.results.accidentType] : '暂无' }}</template>
            <template v-else-if="row.tag === 'QuickPatrolRecordType'">{{ row.isTitle ? '-' : row.results.answer || '暂无' }}</template>
            <!--             临时准入相关-->
            <template v-else-if="row.tag === 'ScanCodeAndConfirmType'"><span v-html="getScanCodeResult(row)" /></template>
            <template v-else-if="row.tag === 'VoiceRecordValueType'"><span v-html="getValueRecordResult(row)" /></template>
            <template v-else-if="row.tag === 'OCRRecordValueType'"><span v-html="getValueRecordResult(row)" /></template>
            <template v-else-if="row.tag === 'KeyboardRecordValueType'"><span v-html="getValueRecordResult(row)" /></template>
            <!--             临时准入相关-->
            <template v-else>{{ '-' }}</template>
          </span>
          <span v-else-if="item.label === '定位'">
            <template> {{ row.results.location || '-' }}</template>
          </span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>

    <ht-dialog ref="deviceTracksDialog" :close-on-click-modal="false" title="轨迹" width="1000px" @close="closeTraceDialog">
      <div slot="content">
        <device-tracks
          v-if="showDeviceTracks"
          ref="deviceTracks"
          height="600px"
          map-id="workOrderDeviceTracks"
          :show-image="showDeviceImg"
          :track-arr="deviceTrackArr"
          :center="deviceTrackCenter"
          :position="devicePosition"
        />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import _ from 'lodash'
import { getWorkOrder, putWorkOrder, postWorkOrderPdf } from '@/api/taskMgr/workOrder'
import { PatrolWorkOrderListTitle, PatrolWorkOrderResultListTitle, InstallationRemoveUploadFilesTitle } from '@/views/taskMgr/tableTitle'
import {
  AccidentTypeEnum, FileUploadStatusEnum,
  FileUploadStatusStyleEnum,
  LoopEnum,
  ResultsEnum,
  TaskWorkOrderStatusEnum,
  TaskWorkOrderStatusStyleEnum
} from '@/views/taskMgr/enum'
import {
  getHiddenDangerRecordResult,
  getLocation,
  getResultTextColor,
  getScanCodeResult,
  getValueRecordResult
} from '@/utils/get-display'
import DeviceTracks from '@/views/components/map/deviceTracks'
import { calculateCenter } from '@/utils/business'
import { getTaskTempType, getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import FileList from '@/views/taskMgr/patrolWorkOrderMgr/components/fileList'
import FullScreen from '@/assets/image/device/full_screen.png'
import ExportWorkOrderExcel from '@/views/taskMgr/patrolWorkOrderMgr/components/exportWorkOrderExcel'

export default {
  components: { DeviceTracks, FileList },
  mixins: [ExportWorkOrderExcel],
  props: {
    isMy: { type: [String, Boolean], default: false },
    isDialog: { type: Boolean, default: false }
  },
  data() {
    return {
      FullScreen,
      PatrolWorkOrderListTitle, PatrolWorkOrderResultListTitle, InstallationRemoveUploadFilesTitle,
      LoopEnum, TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum, ResultsEnum, AccidentTypeEnum,
      FileUploadStatusStyleEnum, FileUploadStatusEnum,
      orderId: null,
      isLoading: false,
      workOrderList: [],
      workOrder: null,
      steps: [],
      showDeviceTracks: false,
      showDeviceImg: true,
      showFullScreenImg: true,
      deviceTrackArr: [],
      deviceTrackCenter: [],
      devicePosition: [],
      isExporting: false
    }
  },
  created() {
    if (!this.isDialog && this.$route.params && this.$route.params.hasOwnProperty('orderId')) {
      this.orderId = this.$route.params.orderId
      this.getWorkOrderDetail(this.orderId)
    }
  },
  methods: {
    getResultTextColor, getValueRecordResult, getLocation, getHiddenDangerRecordResult, calculateCenter, getScanCodeResult,
    getTaskTempType, getTowerWorkOrderType,
    getWorkOrderDetail(orderId) {
      this.isLoading = true
      getWorkOrder(orderId).then(resp => {
        this.isLoading = false
        this.workOrderList = [resp]
        this.workOrder = resp
        resp.steps.forEach(step => {
          if (step.results.hasOwnProperty('factDesc')) {
            if (typeof step.results.factDesc === 'object') {
              if (!step.results.factDesc) {
                step.results.factDesc = []
              }
            } else {
              step.results.factDesc = [step.results.factDesc]
            }
          }
          if (step.tag === 'QuickPatrolRecordType') {
            const children = _.cloneDeep(step.results.checkItems)
            children.forEach((item, index) => {
              const results = _.cloneDeep(item)
              children[index] = _.cloneDeep(step)
              children[index].results = results
              children[index].id = `${step.id}${index}`
              children[index].properties.title = `检查项${index + 1}`
              children[index].properties.text = results.question
              children[index].uploadAt = results.uploadAt || ''
            })
            step.children = children
            step.isTitle = true
          }
        })
        this.steps = resp.steps
        this.getDeviceTracks()
      }).catch(() => {
        this.isLoading = false
      })
    },
    onCancelLoop(row) {
      this.workOrder.isLoop = false
      this.putWorkOrderFun(this.workOrder.isLoop, this.workOrder.uploadFiles, '操作')
    },
    onFileUploaded(index, url, file) {
      const fileItem = { fileUrl: url, fileName: file.name }
      this.workOrder.uploadFiles[index].files.push(fileItem)
      this.putWorkOrderFun(this.workOrder.isLoop, this.workOrder.uploadFiles, '上传')
    },
    onDeleteFile(index, fileIndex) {
      this.workOrder.uploadFiles[index].files.splice(fileIndex, 1)
      this.putWorkOrderFun(this.workOrder.isLoop, this.workOrder.uploadFiles, '删除')
    },
    putWorkOrderFun(isLoop, uploadFiles, info) {
      const data = {
        isLoop: isLoop,
        uploadFiles: uploadFiles,
        action: 'edit'
      }
      data.fileUploadStatus = data.uploadFiles && data.uploadFiles.every(item => item.files.length > 0) ? 'FINISHED' : 'UNFINISHED'
      putWorkOrder(this.workOrder.id, data).then(() => {
        this.$message({ message: `${info}成功`, center: true, type: 'success' })
      })
    },
    getDeviceTracks() {
      this.deviceTrackArr = this.steps
        .map(item => item.results.hasOwnProperty('longitude') && item.results.hasOwnProperty('latitude') ? [item.results.longitude, item.results.latitude] : null)
        .filter(item => item !== null)
      if (this.deviceTrackArr.length > 0) {
        this.deviceTrackCenter = this.calculateCenter(this.deviceTrackArr)
        this.devicePosition = this.deviceTrackArr[this.deviceTrackArr.length - 1]
        this.showDeviceTracks = true
        this.showDeviceImg = true
      } else {
        this.deviceTrackCenter = this.calculateCenter([[116.397428, 39.90923]])
        this.devicePosition = [116.397428, 39.90923]
        this.showDeviceTracks = false
        this.showDeviceImg = false
      }
    },
    openTraceDialog() {
      this.showFullScreenImg = false
      this.$refs.deviceTracksDialog.dialogVisible = true
    },
    closeTraceDialog() {
      this.showFullScreenImg = true
      this.$refs.deviceTracksDialog.dialogVisible = false
    },
    onExportPdf() {
      if (this.workOrder && this.workOrder.status === 'FINISHED') {
        this.isExporting = true
        this.$message({ message: `正在导出工单，请稍候`, type: 'warning', center: true })
        postWorkOrderPdf(this.orderId).then((resp) => {
          this.isExporting = false
          window.open(resp.fileUrl)
        }).catch(() => {
          this.isExporting = false
        })
      } else {
        this.$message({ message: `当前工单尚未执行结束，不可进行导出操作`, type: 'error', center: true })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-title{
  width: 100%;
  text-align: center;
  .order-name{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .order-code{
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.line-two-item-row{
  display: flex;
  .el-form-item{
    width: calc(50% - 10px); /* 每个工程的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}

.line-two-item-for-one-row{
  display: flex;
  .el-form-item{
    width: calc(50% - 20px); /* 每个工程的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}

.el-image.img {
  width: 100px;
  margin: 5px;
}
.el-table__row--level-1{
  background: #f1f1f1 !important;
}

.index-column{
  background: #ffffff !important;
}

.index-column.is-leaf{
  background: #EEEEEE !important;
}

.order-info-container{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  .order-descriptions{
    flex: 2;
    margin-right: 10px;
    min-height: 250px;
    ::v-deep .el-descriptions__body{
      min-height: 250px;
      .el-descriptions--medium{
        min-height: 250px;
      }
    }
  }
  .device-tracks-container{
    flex: 1;
    border: 1px solid #e6ebf5;
    min-height: 250px;
    position: relative;
    .no-device-tracks-info{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
    }
    .full-screen-img{
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9999;
      height: 30px;
      cursor: pointer;
    }
  }
}
</style>
