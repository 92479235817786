var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.labelToProps, function (i) {
            return _c("ht-table-column", {
              key: i.prop + "-" + i.secondProp,
              attrs: { label: i.label, prop: i.prop },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        i.format === "changeDate"
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("formatTime")(row[i.prop])) +
                                  "\n        "
                              ),
                            ])
                          : _c("span", [
                              i.secondProp
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row[i.prop] && row[i.prop][i.secondProp]
                                          ? row[i.prop][i.secondProp]
                                          : "-"
                                      )
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(row[i.prop] ? row[i.prop] : "-")
                                    ),
                                  ]),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }