import request from '@/utils/request'

const staffBasicApi = 'user/staffs/'

// 获取用户管理列表
export function getUserMgrList(listQuery) {
  return request({ url: staffBasicApi, method: 'GET', params: listQuery })
}

// 更新用户信息
export function userInfoOperation(uuid, data) {
  return request({ url: `${staffBasicApi}${uuid}`, method: 'POST', data: data })
}

// 删除用户信息
export function deleteUserApi(uuid) {
  return request({ url: `${staffBasicApi}${uuid}`, method: 'DELETE' })
}

// 批量删除用户
export function deleteUsersApi(data) {
  return request({ url: `${staffBasicApi}`, method: 'DELETE', data: data })
}

// 更新用户信息
export function updateUserInfoApi(uuid, data) {
  return request({ url: `${staffBasicApi}${uuid}`, method: 'PUT', data: data })
}

// 创建用户信息
export function createUserInfoApi(data) {
  return request({ url: staffBasicApi, method: 'POST', data: data })
}

// 模糊搜索用户
export function fuzzySearchStaff(staffName) {
  return request({ url: `${staffBasicApi}keyword/${staffName}`, method: 'GET' })
}

// 登录日志
export function getLoginEventsList(staffId) {
  return request({ url: `${staffBasicApi}${staffId}/login_events/`, method: 'GET' })
}
