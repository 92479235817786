var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-page-container" }, [
    _vm.getMenuList().length > 0
      ? _c(
          "div",
          { staticClass: "menus-container" },
          _vm._l(_vm.getMenuList(), function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "menu-container",
                on: {
                  click: function ($event) {
                    return _vm.clickMenu(item)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "menu-img",
                  attrs: { src: item.imgUrl, alt: "" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "menu-name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "empty-menu" }, [
          _c("img", { staticClass: "empty-img", attrs: { src: _vm.Empty } }),
          _vm._v(" "),
          _c("span", { staticClass: "empty-info" }, [_vm._v("暂无可使用功能")]),
          _vm._v(" "),
          _c("span", { staticClass: "empty-info" }, [
            _vm._v("请联系管理员配置"),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }