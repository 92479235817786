var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "goods-container",
        style: { height: _vm.height - 10 + "px" },
      },
      [
        _c("div", { staticClass: "goods-list-container" }, [
          _c("div", { staticClass: "goods-list-title-container" }, [
            _c("span", { staticClass: "title" }, [_vm._v("拣货清单")]),
            _vm._v(" "),
            _c("span", { staticClass: "count" }, [
              _vm._v("总数：" + _vm._s(_vm.submitResults.goods.length)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "count" }, [
              _vm._v("已拣：" + _vm._s(_vm.getIsPickedGoods.length)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "goods-list" },
            _vm._l(_vm.submitResults.goods, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "goods-item",
                  class: [
                    { "selected-goods": index === _vm.currentGoodsIndex },
                  ],
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s("货品：" + item.code) +
                      "\n          "
                  ),
                  item.isPicked
                    ? _c("i", { staticClass: "el-icon-check" })
                    : index === _vm.currentGoodsIndex
                    ? _c("i", { staticClass: "el-icon-arrow-right" })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "goods-info-container" }, [
          _c("div", { staticClass: "item" }, [
            _vm._v(
              _vm._s(
                "货主：" +
                  ((_vm.submitResults.goods[_vm.currentGoodsIndex] &&
                    _vm.submitResults.goods[_vm.currentGoodsIndex].brand) ||
                    "")
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _vm._v(
              _vm._s(
                "货品：" +
                  ((_vm.submitResults.goods[_vm.currentGoodsIndex] &&
                    _vm.submitResults.goods[_vm.currentGoodsIndex].name) ||
                    "")
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item line" }, [
            _c("span", { staticClass: "item-left" }, [
              _vm._v(
                _vm._s(
                  "自库位：" +
                    ((_vm.submitResults.goods[_vm.currentGoodsIndex] &&
                      _vm.submitResults.goods[_vm.currentGoodsIndex]
                        .storageCode) ||
                      "")
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item-right" }, [
              _vm._v(
                _vm._s(
                  "颜色：" +
                    ((_vm.submitResults.goods[_vm.currentGoodsIndex] &&
                      _vm.submitResults.goods[_vm.currentGoodsIndex].color) ||
                      "")
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item line" }, [
            _c("div", { staticClass: "item-left" }, [
              _vm._v(
                _vm._s(
                  "数量：" +
                    ((_vm.submitResults.goods[_vm.currentGoodsIndex] &&
                      _vm.submitResults.goods[_vm.currentGoodsIndex].count) ||
                      0)
                )
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-right" }, [
              _vm._v(
                _vm._s(
                  "库存量：" +
                    ((_vm.submitResults.goods[_vm.currentGoodsIndex] &&
                      _vm.submitResults.goods[_vm.currentGoodsIndex]
                        .quantity) ||
                      0)
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _vm._v(
              _vm._s(
                "说明：" +
                  ((_vm.submitResults.goods[_vm.currentGoodsIndex] &&
                    _vm.submitResults.goods[_vm.currentGoodsIndex].desc) ||
                    "")
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item item-button" },
            [
              _vm._v("实际货品:\n        "),
              _vm.goods
                ? _c("span", { staticClass: "scan-result" }, [
                    _vm._v(_vm._s(_vm.goods.name)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("svg-icon", {
                attrs: { slot: "append", "icon-class": "scan" },
                slot: "append",
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "scan-button",
                  attrs: { type: "text", disabled: _vm.buttonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("to-scan-goods-code")
                    },
                  },
                },
                [_vm._v("扫描货品")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item item-button" },
            [
              _vm._v("出库库位:\n        "),
              _vm.outboundStorageCode
                ? _c("span", { staticClass: "scan-result" }, [
                    _vm._v(_vm._s("" + _vm.outboundStorageCode)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("svg-icon", {
                attrs: { slot: "append", "icon-class": "scan" },
                slot: "append",
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "scan-button",
                  attrs: { type: "text", disabled: _vm.buttonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("to-scan-storage-code")
                    },
                  },
                },
                [_vm._v("扫描库位")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item item-count item-bold" },
            [
              _c(
                "el-button",
                {
                  staticClass: "count-button",
                  attrs: { type: "text", disabled: _vm.buttonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("to-input-count")
                    },
                  },
                },
                [_vm._v("拣货数量")]
              ),
              _vm._v("\n        ： "),
              _c("el-input", {
                ref: "value",
                staticClass: "value-input scan-result",
                attrs: { disabled: "", type: "number" },
                model: {
                  value: _vm.count,
                  callback: function ($$v) {
                    _vm.count = $$v
                  },
                  expression: "count",
                },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bottom-container" },
      [
        _vm.currentGoodsIndex > 0
          ? _c(
              "el-button",
              {
                staticClass: "submit-button square-circle-button",
                attrs: { type: "text", disabled: _vm.buttonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.handleActions("to-prev-box")
                  },
                },
              },
              [_vm._v("上一个")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentGoodsIndex < _vm.submitResults.goods.length - 1
          ? _c(
              "el-button",
              {
                staticClass: "submit-button square-circle-button",
                attrs: { type: "text", disabled: _vm.buttonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.handleActions("to-next-box")
                  },
                },
              },
              [_vm._v("下一个")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "submit-button square-circle-button",
            attrs: { type: "text", disabled: _vm.buttonDisabled },
            on: {
              click: function ($event) {
                return _vm.handleActions("to-confirm")
              },
            },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-section" }, [
      _c("div", { staticClass: "view-title top-title" }, [
        _vm._v("拣货-开始拣货"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }