<template>
  <div>
    <el-popover
      placement="right"
      trigger="focus"
    >
      <div>
        <div class="complexity-box-container">
          密码强度：
          <span
            v-for="(item,index) in passwordVerifyList"
            :key="index"
            class="complexity-box"
            :style="{background: verifyColor[index]}"
          />
        </div>
        <div>
          <i
            :class="isLengthPass ? 'el-icon-check' : 'el-icon-close'"
            :style="{color: isLengthPass ? '#22c495' : '#f56c6c'}"
            style="font-weight: bolder; margin-right: 5px"
          />密码长度为8-20个字符</div>
        <div>
          <i
            :class="isComplexityPass ? 'el-icon-check' : 'el-icon-close'"
            :style="{color: isComplexityPass ? '#22c495' : '#f56c6c'}"
            style="font-weight: bolder; margin-right: 5px"
          />密码包含字母、数字、特殊字符三种符号的任意组合</div>
        <div>
          <i
            class="el-icon-warning"
            style="color: #e6a23c;font-weight: bolder; margin-right: 5px"
          />请不要使用日常社交账号密码，避免密码泄露带来的风险。</div>
      </div>
      <template slot="reference">
        <slot name="passwordInput" />
      </template>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'VerifyPassword',
  props: {
    isLengthPass: {
      type: Boolean,
      default: false
    },
    isComplexityPass: {
      type: Boolean,
      default: false
    },
    passwordVerifyList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      verifyColor: ['#dddddd', '#dddddd']
    }
  },
  watch: {
    passwordVerifyList: {
      handler: function(newArr) {
        this.getVerifyResult(newArr)
      },
      immediate: true
    }
  },
  methods: {
    getVerifyResult(arr) {
      const passVerifyList = arr.filter(function(item) {
        return item === true
      })
      const verifyColor = this.verifyColor
      verifyColor.forEach((item, index, verifyColor) => {
        verifyColor[index] = passVerifyList[index] ? '#22c495' : '#dddddd'
      })
      this.verifyColor = verifyColor
    }
  }
}
</script>

<style scoped lang="scss">
.complexity-box-container{

  margin: 5px 0 10px 0;
  display:flex;

  .complexity-box{
    align-self:center;
    margin-right: 5px;
    width: 20px;
    height: 5px;
    border-radius: 2px;
  }
}
</style>
