import * as RongIMLib from '@rongcloud/imlib-next'
import { EventsEnum } from '../var/eventsVar'
import dwCustomImMsg from '../js/dwCustomImMsg'
import log from '@/utils/log'

// 初始化IM
function initIM(appKey, naviUrl) {
  RongIMLib.init({
    // 应用 appkey 标识
    appkey: appKey,
    // 自定义导航地址
    navigators: naviUrl ? [naviUrl] : undefined,
    // 内部日志打印等级
    logOutputLevel: RongIMLib.LogL.ERROR
    // logOutputLevel: RongIMLib.LogL.DEBUG
  })
}

// IM连接
function imConnect(token) {
  return new Promise((resolve, reject) => {
    RongIMLib.connect(token).then((user) => {
      if (user.code === 0) {
        resolve(user)
      } else {
        reject(user)
      }
    }).catch(error => {
      reject(error)
    })
  })
}

// 断开IM连接
function imDisconnect() {
  return new Promise((resolve, reject) => {
    RongIMLib.disconnect().then(() => {
      resolve()
    }).catch((error) => {
      reject(error)
    })
  })
}

// 添加连接事件监听
function addConnectEventListener(imUserId) {
  const Events = RongIMLib.Events
  // 监听 IM 连接状态变化
  RongIMLib.addEventListener(Events.CONNECTING, () => {
    log.myLog('IM 连接状态onConnecting, imUserId', imUserId)
  })
  RongIMLib.addEventListener(Events.CONNECTED, () => {
    log.myLog('IM 连接状态onConnected, imUserId', imUserId)
  })
  RongIMLib.addEventListener(Events.DISCONNECT, disconnectHandle)
  // 监听消息通知
  RongIMLib.addEventListener(Events.MESSAGES, messagesHandle)
}

// 移除连接监听消息
function removeConnectEventListener(imUserId) {
  const Events = RongIMLib.Events
  RongIMLib.removeEventListener(Events.CONNECTING, () => {
    log.myLog('IM 连接状态onConnecting', imUserId)
  })
  RongIMLib.removeEventListener(Events.CONNECTED, () => {
    log.myLog('IM 连接状态onConnected', imUserId)
  })
  RongIMLib.removeEventListener(Events.DISCONNECT, disconnectHandle)
  RongIMLib.removeEventListener(Events.MESSAGES, messagesHandle)
}

function disconnectHandle(status) {
  log.myLog('连接中断，需要业务层进行重连处理 ->', status)
  document.dispatchEvent(new CustomEvent(EventsEnum.disconnectEvent, { detail: { status: status, content: 'IM连接终端，请点击进行重新连接' }}))
}

function messagesHandle(event) {
  const messages = event.messages
  if (messages && messages.length > 0) {
    // deleteMessageForSpecifiedUser(messages)
    messages.forEach(message => {
      dwCustomImMsg.messageHandle(message)
    })
  }
}

// 自定义消息
let DwMessage = null

function registerMessage() {
  DwMessage = RongIMLib.registerMessageType('DW:Msg', false, false, [], true)
}

function sendDwCustomPrivateMsg(targetId, content, dispatchEvent) {
  log.myLog(content)
  const conversation = {
    conversationType: RongIMLib.ConversationType.PRIVATE,
    targetId: targetId
  }
  const message = new DwMessage(content)
  const option = { isStatusMessage: true }
  RongIMLib.sendMessage(conversation, message, option).then(res => {
    if (res.code === RongIMLib.ErrorCode.SUCCESS) {
      log.myLog(`${content.msgType}-PRIVATE消息发送成功`, res)
      if (dispatchEvent) { document.dispatchEvent(new CustomEvent(EventsEnum[dispatchEvent], { detail: res.data.content })) }
    } else {
      log.myWarnLog(`${content.msgType}-PRIVATE消息发送失败`, res)
      if (dispatchEvent) { document.dispatchEvent(new CustomEvent(EventsEnum[dispatchEvent], { detail: res.data.content })) }
      if (res.code === 20604) {
        setTimeout(() => {
          sendDwCustomChatRoomMsg(targetId, content, dispatchEvent)
        }, 500)
      }
    }
  }).catch(error => {
    log.myErrorLog(`${content.msgType}-PRIVATE消息发送失败`, error)
  })
}

function sendDwCustomChatRoomMsg(targetId, content, dispatchEvent) {
  const conversation = {
    conversationType: RongIMLib.ConversationType.CHATROOM,
    targetId: targetId
  }
  const message = new DwMessage(content)
  const option = { isStatusMessage: true }
  RongIMLib.sendMessage(conversation, message, option).then(res => {
    if (res.code === RongIMLib.ErrorCode.SUCCESS) {
      log.myLog(`${content.msgType}-CHATROOM消息发送成功`, res)
      if (dispatchEvent) { document.dispatchEvent(new CustomEvent(EventsEnum[dispatchEvent], { detail: res.data.content })) }
    } else {
      log.myWarnLog(`${content.msgType}-CHATROOM消息发送失败`, res)
      if (dispatchEvent) { document.dispatchEvent(new CustomEvent(EventsEnum[dispatchEvent], { detail: res.data.content })) }
      if (res.code === 20604) {
        setTimeout(() => {
          sendDwCustomChatRoomMsg(targetId, content, dispatchEvent)
        }, 500)
      }
    }
  }).catch(error => {
    log.myErrorLog(`${content.msgType}-CHATROOM消息发送失败`, error)
  })
}

function deleteMessageForSpecifiedUser(messages) {
  const conversation = {
    conversationType: RongIMLib.ConversationType.PRIVATE,
    targetId: messages.targetId
  }
  const list = []
  messages.forEach(item => {
    list.push({
      messageUId: item.messageUId,
      sentTime: item.sentTime,
      messageDirection: item.messageDirection
    })
  })
  RongIMLib.deleteMessages(conversation, list).then(res => {
    if (res.code !== 0) {
      log.myLog('删除失败', res.code, res.msg)
    }
  }).catch(error => {
    log.myLog('删除失败', error)
  })
}

// 聊天室
function joinChatRoom(chatRoomId, count) {
  return new Promise((resolve, reject) => {
    RongIMLib.joinChatRoom(chatRoomId, { count: count }).then(res => {
      if (res.code === RongIMLib.ErrorCode.SUCCESS) {
        log.myLog('加入聊天室成功', res)
        // addChatRoomEventListener()
        resolve()
      } else {
        log.myWarnLog('加入聊天室失败', res)
        reject(res)
      }
    }).catch(error => {
      log.myErrorLog('加入聊天室失败', error)
      reject(error)
    })
  })
}

function joinExistChatRoom(chatRoomId, count) {
  return new Promise((resolve, reject) => {
    RongIMLib.joinExistChatRoom(chatRoomId, { count: count }).then(res => {
      if (res.code === RongIMLib.ErrorCode.SUCCESS) {
        // addChatRoomEventListener()
        resolve()
      } else {
        log.myWarnLog('加入已存在聊天室失败', res)
        reject(res)
      }
    }).catch(err => {
      log.myErrorLog('加入已存在聊天室失败', err)
      reject(err)
    })
  })
}

function quitChatRoom(chatRoomId) {
  return new Promise((resolve, reject) => {
    RongIMLib.quitChatRoom(chatRoomId).then(res => {
      if (res.code === RongIMLib.ErrorCode.SUCCESS) {
        // removeChatRoomEventListener()
        resolve()
      } else {
        log.myWarnLog('退出聊天室失败', res)
        reject(res)
      }
    }).catch((err) => {
      log.myErrorLog('退出聊天室失败' + err)
      reject(err)
    })
  })
}

function chatRoomEventListener() {
  return (event) => {
    log.myLog(event)
    if (event.rejoinedRoom) {
      log.myLog('SDK 内部重连聊天室信息:', event.rejoinedRoom)
    }
    if (event.updatedEntries) {
      log.myLog('监听到的聊天室 KV 更新:', event.updatedEntries)
    }
    if (event.userChange) {
      log.myLog('加入退出的用户通知:', event.userChange)
    }
    if (event.chatroomDestroyed) {
      log.myLog('聊天室销毁:', event.chatroomDestroyed)
    }
  }
}

function addChatRoomEventListener() {
  const listener = (event) => {
    if (event.rejoinedRoom) {
      log.myLog('SDK 内部重连聊天室信息:', event.rejoinedRoom)
    }
    if (event.updatedEntries) {
      log.myLog('监听到的聊天室 KV 更新:', event.updatedEntries)
    }
    if (event.userChange) {
      log.myLog('加入退出的用户通知:', event.userChange)
    }
    if (event.chatroomDestroyed) {
      log.myLog('聊天室销毁:', event.chatroomDestroyed)
    }
  }
  const Events = RongIMLib.Events
  RongIMLib.addEventListener(Events.CHATROOM, listener)
}

function removeChatRoomEventListener() {
  const Events = RongIMLib.Events
  RongIMLib.removeEventListener(Events.CHATROOM, chatRoomEventListener)
}

function sendTextMessageInChatRoom(targetId, data) {
  const conversation = {
    conversationType: RongIMLib.ConversationType.CHATROOM,
    targetId: targetId
  }
  const message = new RongIMLib.TextMessage(data)

  RongIMLib.sendMessage(conversation, message).then(res => {
    if (res.code === RongIMLib.ErrorCode.SUCCESS) {
      log.myLog('消息发送成功', res.data)
      document.dispatchEvent(new CustomEvent(EventsEnum.sendChatMessageSuccessEvent, { detail: res.data }))
    } else {
      log.myWarnLog('消息发送失败', res)
      document.dispatchEvent(new CustomEvent(EventsEnum.sendChatMessageFailEvent, { detail: res.data }))
    }
  }).catch(error => {
    log.myErrorLog('消息发送失败', error)
  })
}

function sendImageMessageInChatRoom(targetId, data) {
  const conversation = {
    conversationType: RongIMLib.ConversationType.CHATROOM,
    targetId: targetId
  }
  const message = new RongIMLib.ImageMessage(data)

  RongIMLib.sendMessage(conversation, message).then(res => {
    if (res.code === RongIMLib.ErrorCode.SUCCESS) {
      log.myLog('图片消息发送成功', res.data)
      document.dispatchEvent(new CustomEvent(EventsEnum.sendChatMessageSuccessEvent, { detail: res.data }))
    } else {
      log.myWarnLog('图片消息发送失败', res)
      document.dispatchEvent(new CustomEvent(EventsEnum.sendChatMessageFailEvent, { detail: res.data }))
    }
  }).catch(error => {
    log.myErrorLog('图片消息发送失败', error)
  })
}

function sendFileMessageInChatRoom(targetId, data) {
  const conversation = {
    conversationType: RongIMLib.ConversationType.CHATROOM,
    targetId: targetId
  }
  const message = new RongIMLib.FileMessage(data)

  RongIMLib.sendMessage(conversation, message).then(res => {
    if (res.code === RongIMLib.ErrorCode.SUCCESS) {
      log.myLog('文件消息发送成功', res.data)
      document.dispatchEvent(new CustomEvent(EventsEnum.sendChatMessageSuccessEvent, { detail: res.data }))
    } else {
      log.myWarnLog('文件消息发送失败', res)
      document.dispatchEvent(new CustomEvent(EventsEnum.sendChatMessageFailEvent, { detail: res.data }))
    }
  }).catch(error => {
    log.myErrorLog('文件消息发送失败', error)
  })
}

export default {
  initIM,
  imConnect,
  imDisconnect,
  registerMessage,
  addConnectEventListener,
  removeConnectEventListener,
  sendDwCustomPrivateMsg,
  sendDwCustomChatRoomMsg,
  deleteMessageForSpecifiedUser,
  joinChatRoom,
  joinExistChatRoom,
  quitChatRoom,
  sendTextMessageInChatRoom,
  sendImageMessageInChatRoom,
  sendFileMessageInChatRoom,
  addChatRoomEventListener,
  removeChatRoomEventListener
}

