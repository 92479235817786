<template>
  <component :is="container">
    <el-card v-loading="isLoading" style="margin-bottom: 50px">
      <div class="title-card-container">
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">会议ID:</span>
          <span class="textStyle" style="font-weight: bold" :style="{color: variables.primaryColor}">{{ recordDetail && recordDetail.roomId ? recordDetail.roomId : '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">发起人:</span>
          <span class="textStyle">{{ recordDetail && recordDetail.staff ? recordDetail.staff.fullName : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">参与人:</span>
          <span class="textStyle">{{ recordDetail && recordDetail.joinUserNamesString ? recordDetail.joinUserNamesString : '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">创建时间:</span>
          <span class="textStyle"> {{ recordDetail && recordDetail.startAt ? recordDetail.startAt : '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">会议时长:</span>
          <span class="textStyle"> {{ recordDetail && recordDetail.duration ? recordDetail.duration : '-' }}</span>
        </div>
      </div>
    </el-card>
    <el-divider><i class="el-icon-chat-line-square" /> 沟通记录</el-divider>
    <ht-action-panel>
      <template slot="right">
        <!--        <ht-button-->
        <!--          :disabled="reportsRecords.length === 0"-->
        <!--          type="warning"-->
        <!--          icon="el-icon-document"-->
        <!--          @click="$refs.reportsRecordsDialog.dialogVisible = true"-->
        <!--        >查看历史图文报告-->
        <!--        </ht-button>-->
        <!--        <ht-button-->
        <!--          v-if="!showSelector"-->
        <!--          :disabled="chatRecords.length === 0"-->
        <!--          type="primary"-->
        <!--          icon="el-icon-document"-->
        <!--          @click="showSelectorOfChatRecord"-->
        <!--        >生成图文报告</ht-button>-->
        <!--        <template v-else>-->
        <!--          <ht-popover-button-->
        <!--            type="success"-->
        <!--            :disabled="selectedChatRecord.length === 0"-->
        <!--            icon="el-icon-circle-check"-->
        <!--            :confirm-title="`已选择${selectedChatRecord.length}条记录，是否确认生成报告？`"-->
        <!--            :loading="confirmReportLoading"-->
        <!--            @confirmed="confirmReport"-->
        <!--          >确认生成-->
        <!--          </ht-popover-button>-->
        <!--          <ht-button type="warning" @click="cancelDoc()">取消下载</ht-button>-->
        <!--        </template>-->
        <ht-button
          type="primary"
          :disabled="!videoRecords || videoRecords.length === 0"
          icon="el-icon-video-play"
          @click="$refs.videoRecordsDialog.dialogVisible = true"
        >会议录屏</ht-button>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="chatRecords" style="margin-bottom: 50px" :row-key="getRowKey" @selection-change="onSelectionChange">
      <ht-table-column-selection v-if="showSelector" />
      <ht-table-column label="序号" type="index" width="60" />
      <ht-table-column v-for="i in labelToProps" :key="i.prop" :label="i.label" :prop="i.prop" :min-width="i.width || 100">
        <template v-slot="{row}">
          <span v-if="i.label === '发送人'">{{ row.fromStaff.fullName }}</span>
          <span v-else-if="i.label === '发送时间'">{{ row.createdAt }}</span>
          <template v-else-if="i.label === '发送内容'">
            <span v-if="row.mediaType.name === 'TXT'">
              <p>{{ row.content.content.content }}</p>
            </span>
            <template v-else-if="row.mediaType.name === 'IMG'">
              <el-image
                style="width: 100px; height: 100px"
                :src="row.content.imageUri"
                :preview-src-list="[row.content.imageUri]"
              />
            </template>
            <template v-else-if="row.mediaType.name === 'FILE'">
              <el-link type="primary" :href="row.content.fileUrl" target="_blank" underline>
                <svg-icon icon-class="pdf" /> {{ row.content.name }}（查看文件）
              </el-link>
            </template>
          </template>
        </template>
      </ht-table-column>
    </ht-table>
    <ht-dialog
      ref="videoRecordsDialog"
      title="会议录屏"
      width="70%"
    >
      <div slot="content" class="clearFloat">
        <el-table border :data="videoRecords">
          <el-table-column label="序号" type="index" fixed="left" />
          <el-table-column label="开始时间" prop="extraData.startTime" min-width="30px" />
          <el-table-column label="结束时间" prop="extraData.endTime" min-width="30px" />
          <el-table-column label="录屏" prop="name" min-width="100px">
            <template v-slot="{row}">
              <video-player
                v-if="row.content"
                ref="videoPlayer"
                class="vjs-custom-skin"
                :options="row.playerOptions"
              />
            </template>
          </el-table-column>
          <el-table-column label="录屏时长" prop="videoDuration" min-width="30px" />
        </el-table>
      </div>
    </ht-dialog>
  </component>
</template>

<script>
import { getMeeting } from '@/api/newRemoteGuidance/newRemoteGuidance'
import { stringTrailingSlash } from '@/utils'
import HtListContainer from '@/components/HtListContainer'
import { docReportApi } from '@/api/remoteGuidance/remoteGuidance'
import variables from '@/styles/variables.scss'

export default {
  extends: HtListContainer,
  props: {
    roomId: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      labelToProps: [
        { label: '发送人', prop: 'fromStaff.fullName', width: 100 },
        { label: '发送时间', prop: 'createdAt', width: 150 },
        { label: '发送内容', prop: 'content', width: 300 }
      ],
      container: 'el-card',
      isLoading: true,
      recordDetail: null,
      showSelector: false,
      chatRecords: [],
      selectedChatRecord: [],
      reportsRecords: [],
      videoRecords: [],
      confirmReportLoading: false
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  created() {
    this.getMeetingRecordDetail()
  },
  methods: {
    stringTrailingSlash,
    getMeetingRecordDetail() {
      this.isLoading = true
      getMeeting(this.roomId).then(resp => {
        this.isLoading = false
        this.recordDetail = resp
        this.chatRecords = resp.chatRecords.filter(item => item.mediaType.name !== 'VIDEO')
        this.videoRecords = resp.videoRecords.filter(item => item.mediaType.name === 'VIDEO')
        this.videoRecords.forEach(item => {
          item.playerOptions = {
            // videojs options
            muted: true,
            fluid: true,
            language: 'ch',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [{
              type: 'video/mp4',
              src: item.content.fileUrl
            }],
            notSupportedMessage: '此视频无法播放，请稍后再试',
            controlBar: {
              timeDivider: false,
              durationDisplay: true,
              remainingTimeDisplay: true,
              fullscreenToggle: true
            }
          }
        })
        this.recordDetail.joinUserNamesString = (this.recordDetail.joinUserNames && this.recordDetail.joinUserNames.length > 0) ? this.recordDetail.joinUserNames.join(',') : '-'
      }).catch(() => {
        this.isLoading = false
      })
    },
    showSelectorOfChatRecord() {
      this.showSelector = true
      this.$nextTick(() => {
        this.$refs.list.toggleAllSelection()
      })
      this.$message({
        message: '请在表格中勾选需要生成报告的记录',
        center: true,
        type: 'warning'
      })
    },
    onSelectionChange(val) {
      this.selectedChatRecord = val
    },
    confirmReport() {
      this.confirmReportLoading = true
      this.$message({
        message: '正在生成报告中，请稍等',
        center: true,
        type: 'warning'
      })
      const chatIds = []
      this.selectedChatRecord.forEach(item => {
        chatIds.push(item.id)
      })
      docReportApi(this.guidanceId, { chatIds: chatIds }).then((resp) => {
        window.open(`${stringTrailingSlash(process.env.VUE_APP_COS_SERVER)}${resp.filePath}`)
        this.showSelector = false
        this.confirmReportLoading = false
      }).catch(() => { this.confirmReportLoading = false })
    },
    cancelDoc() {
      this.showSelector = false
    }
  }
}
</script>

<style scoped>

</style>
