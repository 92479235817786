<template>
  <div class="head-container" :class="{'is-mobile-fullscreen-head-container': isFullscreen && isMobile}">
    <div class="meeting-describe">
      <div class="code-container describe-container">
        <img class="img" :src="Video" alt="">
        <span class="label">会议号：</span>
        <span class="value">{{ meetingInfo && meetingInfo.meetingId || '加载中' }}</span>
      </div>
      <div class="initiator-container  describe-container">
        <img class="img" :src="Initiator" alt="">
        <span class="label">发起人：</span>
        <span class="value">
          {{ `${meetingInfo && meetingInfo.meetingCreatorName || '加载中'}` }}
        </span>
        <span v-if="!isSinoma" class="value">
          {{ meetingInfo && meetingInfo.meetingCreator && !stringLongOverTen(meetingInfo.meetingCreator) ? `（${meetingInfo.meetingCreator}）` : '' }}
        </span>
        <!--        <span v-if="isSinoma" class="value">-->
        <!--          {{ `${meetingInfo && meetingInfo.meetingCreatorName || '加载中'}` }}-->
        <!--        </span>-->
        <!--        <span v-else class="value">-->
        <!--          {{ `${meetingInfo && meetingInfo.meetingCreatorName || '加载中'}（${meetingInfo && meetingInfo.meetingCreator || '加载中'}）` }}-->
        <!--        </span>-->
      </div>
      <div v-if="isRemoteShareScreen || isRemoteRecording" class="hint-container">
        <!--     共享屏幕提示-->
        <div v-if="isRemoteShareScreen" class="hint share-screen-hint">
          {{ `${shareScreenUserInfo.shareScreenFullName}屏幕共享中` }}
        </div>
        <!--     录制屏幕提示-->
        <div v-if="isRemoteRecording" class="hint recording-hint">
          {{ `${recordingUserInfo.recordingFullName}会议录屏中` }}
        </div>
      </div>
    </div>
    <div class="meeting-operation">
      <!--      <img class="minimize item" :src="Minimize" alt="" @click="minimizedScreen">-->
      <template v-if="!isSinoma">
        <img v-if="isFullscreen" class="maximize item" :src="ExitFul" alt="" @click="exitFullScreen">
        <img v-else class="maximize item" :src="EnterFul" alt="" @click="enterFulScreen">
      </template>
      <ht-button class="exit-button" :class="{'is_sinoma-full-exit-button': isFullscreen && isSinoma}" plain type="danger" @click="exitRoom">{{ exitName }}</ht-button>
    </div>
  </div>
</template>

<script>
import Video from '@/assets/image/newRemoteGuidance/video_conferencing.png'
import Initiator from '@/assets/image/newRemoteGuidance/initiator.png'
import EnterFul from '@/assets/image/newRemoteGuidance/enter_full.png'
import ExitFul from '@/assets/image/newRemoteGuidance/exit_full.png'
import Minimize from '@/assets/image/newRemoteGuidance/minimize.png'
import store from '@/store'
import { stringLongOverTen } from '@/utils'

export default {
  name: 'MeetingHead',
  props: {
    meetingInfo: { type: Object, default: () => {} }, // 会议信息
    exitName: { type: String, default: '离开会议' }, // 右上角按钮名称
    isRemoteShareScreen: { type: Boolean, default: false }, // 远端是否共享屏幕
    shareScreenUserInfo: { type: Object, default: () => {} }, // 共享屏幕用户信息
    isRemoteRecording: { type: Boolean, default: false }, // 远端是否录制屏幕
    recordingUserInfo: { type: Object, default: () => {} }, // 录制屏幕用户信息
    isFullscreen: { type: Boolean, default: false }, // 是否全屏
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      Video, Initiator, EnterFul, ExitFul, Minimize,
      isSinoma: undefined
    }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
  },
  methods: {
    stringLongOverTen,
    minimizedScreen() {
      this.$emit('minimized')
    },
    exitFullScreen() {
      this.$emit('exit-full')
    },
    enterFulScreen() {
      this.$emit('enter-full')
    },
    exitRoom() {
      this.$emit('exit-room')
    }
  }
}
</script>

<style scoped lang="scss">
.head-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  .meeting-describe{
    display: flex;
    flex-direction: row;
    align-items: center;
    .describe-container{
      display: flex;
      align-items: center;
      font-size: 16px;
      .img{
        width: 20px;
        margin-right: 5px;
      }
      .label{
        color: #333333;
      }
      .value{
        font-weight: bold;
        color: #2E5AFF;
      }
    }
    .code-container{
      margin-right: 20px;
      .img{
        width: 25px;
        margin-right: 5px;
      }
    }
  }
  .hint-container{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .hint{
    height: 22px;
    line-height: 22px;
    color: #ffffff;
    background: #f56c6c;
    margin: 1px;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
  }
  .meeting-operation{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .item{
      width: 20px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    .is_sinoma-full-exit-button{
      margin-right: 20px;
    }
  }
}

.is-mobile-fullscreen-head-container{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 25px 0 0 0;
  .meeting-describe{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .describe-container{
      font-size: 14px;
      margin: 2px 0;
    }
    .hint-container{
      justify-content: flex-start;
    }
  }
  .meeting-operation{

  }
}
</style>
