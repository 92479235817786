import { asyncRoutes, constantRoutes, lastRouters } from '@/router'
import userState from '../index'
/**
 * Use meta.role to determine if the current user has permission
 * @param permissions
 * @param route
 */
function hasRoles(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

// 递归删除路由
export function deleteAsyncRouter(routes, names) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (names.indexOf(tmp.name) === -1) {
      if (tmp.children) {
        tmp.children = deleteAsyncRouter(tmp.children, names)
      }
      res.push(tmp)
    }
  })

  return res
}

// export function filterAsyncRoutes(routes, permissions) {
//   const res = []
//   routes.forEach(route => {
//     const data = { ...route }
//     let tmp = {}
//     if (hasRoles(permissions, data)) {
//       if (data.component) {
//         tmp = {
//           path: data.path,
//           redirect: data.redirect || undefined,
//           name: data.name,
//           meta: data.meta,
//           children: data.children,
//           hidden: data.hidden,
//           props: data.props,
//           component: (resolve) => require([`@/${data.component}`], resolve)
//         }
//         if (tmp.children) {
//           tmp.children = filterAsyncRoutes(tmp.children, permissions)
//         }
//       }
//       res.push(tmp)
//     }
//   })
//   return res
// }

export function filterAsyncRoutes(routes, permissions) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasRoles(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: [],
  deleteRouters: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  DELETE_ROUTES: (state, name) => {
    if (state.deleteRouters.indexOf(name) === -1) {
      state.deleteRouters.push(name)
    }
  },
  REFRESH_ROUTES: (state, routes) => {
    state.routes = routes
  }
}

const actions = {
  generateRoutes({ commit, state }, query) {
    return new Promise(resolve => {
      let userRoutes = []
      if (query.isConfigMenu) {
        // 获取菜单
        const routes = []
        const data = userState.getters.userRoutes.featureMenuJson
        if (data && data.length > 0) {
          data.forEach(m1 => {
            const firstMenu = asyncRoutes.find(e => ((e.name === m1.name || e.hidden) && m1.activated))

            if (firstMenu && firstMenu.meta) {
              const configChildrenNames = m1.children.filter(e => e.activated).map(e => e.name)
              firstMenu.meta.title = m1.title
              firstMenu.children = firstMenu.children.filter(e => configChildrenNames.includes(e.name) || e.hidden)
              m1.children.forEach(m2 => {
                const subMenu = firstMenu.children.find(e => e.name === m2.name)
                if (subMenu) { subMenu.meta.title = m2.title }
              })
              routes.push(firstMenu)
            }
          })
          userRoutes = filterAsyncRoutes(routes, query.roles)
        }
      } else {
        userRoutes = filterAsyncRoutes(asyncRoutes, query.roles)
      }
      let routers = []
      if (query.roles.includes('ADMIN')) {
        routers = [...userRoutes, ...lastRouters]
      } else {
        routers = [...userRoutes]
      }
      commit('SET_ROUTES', routers)
      resolve(routers)
    })
  },
  // 添加删除的路径
  addDeleteRouter({ commit }, name) {
    return new Promise(resolve => {
      commit('DELETE_ROUTES', name)
      resolve(name)
    })
  },
  // 改变路由
  changeRouter({ commit }, permissions) {
    return new Promise(resolve => {
      const accessedRoutes = deleteAsyncRouter(asyncRoutes, state.deleteRouters)
      const routes = filterAsyncRoutes(accessedRoutes, permissions)
      commit('SET_ROUTES', routes)
      resolve(routes)
    })
  },
  // 清空删除的路径
  clearRouter({ commit }) {
    return new Promise(resolve => {
      state.deleteRouters = []
      resolve([])
    })
  },
  // 清除所有的路由，只剩下dashboard
  deleteAsyncRouter({ commit }) {
    return new Promise(resolve => {
      const accessedRoutes = {}
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
