var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "is-mobile-form": _vm.isMobile } },
    [
      _c(
        "el-form",
        {
          ref: "orderForm",
          class: { "mobile-form": _vm.isMobile },
          staticStyle: { "margin-right": "20px" },
          attrs: {
            model: _vm.orderForm,
            rules: _vm.orderFormRules,
            "label-width": _vm.labelWidth,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "工程：", prop: "projectId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.projectList.length > 0
                        ? "请输入工程名称进行搜索"
                        : "当前无可选工程",
                    "popper-class": _vm.selectPopperClass,
                    remote: "",
                    "remote-method": _vm.getProjects,
                  },
                  on: { change: _vm.handleSelectProject },
                  model: {
                    value: _vm.orderForm.projectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "projectId", $$v)
                    },
                    expression: "orderForm.projectId",
                  },
                },
                _vm._l(_vm.projectList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.orderForm.projectId
            ? _c(
                "el-form-item",
                { attrs: { label: "整机设备：", prop: "targetId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        placeholder:
                          _vm.equipmentList.length > 0
                            ? "请选择整机设备"
                            : "当前无可选整机设备",
                        "popper-class": _vm.selectPopperClass,
                      },
                      on: { change: _vm.handleSelectEquipment },
                      model: {
                        value: _vm.orderForm.targetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.orderForm, "targetId", $$v)
                        },
                        expression: "orderForm.targetId",
                      },
                    },
                    _vm._l(_vm.equipmentList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: _vm.getEquipmentName(item),
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "工作流程：", prop: "taskTemplateId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.workFlowList.length > 0
                        ? "请输入工作流程名称进行搜索"
                        : "当前无可选工作流程",
                    "popper-class": _vm.selectPopperClass,
                    remote: "",
                    "remote-method": _vm.getWorkFlows,
                  },
                  on: { change: _vm.handleSelectTaskTemplate },
                  model: {
                    value: _vm.orderForm.taskTemplateId,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "taskTemplateId", $$v)
                    },
                    expression: "orderForm.taskTemplateId",
                  },
                },
                _vm._l(_vm.workFlowList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.orderForm.taskTemplateId
            ? _c(
                "el-form-item",
                { attrs: { label: "作业类型：", prop: "towerWorkType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择作业类型",
                        disabled: "",
                        "popper-class": _vm.selectPopperClass,
                      },
                      model: {
                        value: _vm.orderForm.towerWorkType,
                        callback: function ($$v) {
                          _vm.$set(_vm.orderForm, "towerWorkType", $$v)
                        },
                        expression: "orderForm.towerWorkType",
                      },
                    },
                    _vm._l(
                      _vm.TowerWorkOrderTypeObjectEnum,
                      function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.value, value: item.name },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "工单名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入工单名称",
                  "show-word-limit": "",
                  maxlength: "50",
                },
                model: {
                  value: _vm.orderForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderForm, "name", $$v)
                  },
                  expression: "orderForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "执行人：", prop: "executorId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.userList.length > 0
                        ? "请输入执行人姓名进行搜索"
                        : "当前无可选执行人",
                    "popper-class": _vm.selectPopperClass,
                    remote: "",
                    "remote-method": _vm.getStaffs,
                  },
                  model: {
                    value: _vm.orderForm.executorId,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "executorId", $$v)
                    },
                    expression: "orderForm.executorId",
                  },
                },
                _vm._l(_vm.userList, function (item) {
                  return _c("el-option", {
                    key: item.userId,
                    attrs: { label: item.fullName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "循环周期：", prop: "isLoop" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "请选择循环周期",
                    "popper-class": _vm.selectPopperClass,
                  },
                  model: {
                    value: _vm.orderForm.isLoop,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "isLoop", $$v)
                    },
                    expression: "orderForm.isLoop",
                  },
                },
                _vm._l(_vm.LoopEnum, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMobile
        ? _c(
            "div",
            {
              staticClass: "mobile-dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.isSubmitting, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit("orderForm")
                    },
                  },
                },
                [_vm._v("下发工单")]
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close" },
                  on: { click: _vm.cancelSubmit },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.isSubmitting,
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit("orderForm")
                    },
                  },
                },
                [_vm._v("下发工单")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }