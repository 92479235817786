<template>
  <div>
    <el-form ref="inboundForm" :model="inboundForm" :rules="inboundFormRules" label-width="100px">
      <el-form-item label="入库仓库：" prop="warehouseId">
        {{ warehouseInfo && warehouseInfo.name }}
      </el-form-item>
      <el-form-item label="设备来源：" prop="constructionEquipmentSource">
        <el-radio-group v-model="inboundForm.constructionEquipmentSource" @change="handleEquipmentSourceChange">
          <el-radio v-for="(value, key) in EquipmentSourceEnum" :key="key" :label="key">{{ value }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="inboundForm.constructionEquipmentSource === 'OLD'">
        <el-form-item label="选择工程：" prop="constructionProjectId">
          <el-select
            v-model="inboundForm.constructionProjectId"
            filterable
            :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'"
            style="width: 100%"
            remote
            @change="handleSelectProject"
          >
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </template>
      <el-row class="line-two-item-row">
        <el-form-item label="入库设备：" prop="equipmentItems">
          <el-button
            type="success"
            icon="el-icon-plus"
            :disabled="(inboundForm.constructionEquipmentSource === 'OLD' && inboundForm.constructionProjectId === null) || !inboundForm.constructionEquipmentSource"
            @click="openAddEquipmentDialog"
          >添加设备</el-button>
        </el-form-item>
        <el-form-item label="入库总数量：">
          <span class="quantities"> {{ inboundQuantities }}</span>
        </el-form-item>
      </el-row>

      <ht-table ref="list" v-loading="inboundForm.constructionEquipmentSource === 'NEW' ? isEquipmentLoading : false" :data="inboundForm.equipmentItems" style="margin-bottom: 18px">
        <ht-table-column label="序号" type="index" fixed="left" />
        <ht-table-column
          v-for="item in tableTitle"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :fixed="item.fixed"
          :show-overflow-tooltip="item.label !== '本次入库数量'"
        >
          <template v-slot="{row}">
            <span v-if="item.label === '可入库数量'">{{ row[item.prop] }}</span>
            <template v-else-if="item.label === '本次入库数量'">
              <el-input-number
                v-if="inboundForm.constructionEquipmentSource === 'OLD'"
                v-model="row.inboundQuantity"
                :min="0"
                :max="row.quantity"
                disabled
                placeholder="请输入入库数量"
                :precision="0"
                style="width: 100%"
              />
              <el-input-number
                v-else-if="inboundForm.constructionEquipmentSource === 'NEW'"
                v-model="row.inboundQuantity"
                :min="0"
                :max="row.isWhole ? 1 : Infinity"
                placeholder="请输入入库数量"
                :precision="0"
                style="width: 100%"
              />
            </template>
            <span v-else>{{ row[item.prop] || '-' }}</span>
          </template>
        </ht-table-column>
        <ht-table-column-operation :width="100">
          <template v-slot="{$index, row}">
            <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该条数据？" @confirmed="onDeleteEquipment($index)">删除</ht-popover-button>
          </template>
        </ht-table-column-operation>
      </ht-table>

      <el-form-item label="入库文件：" prop="attachmentData">
        <dw-file v-if="inboundForm.attachmentData" :url="inboundForm.attachmentData" show-del justify-content="left" @delete-file="inboundForm.attachmentData = null" />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传入库文件" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认入库</el-button>
    </div>

    <ht-dialog ref="addEquipmentDialog" :title="`添加设备（${EquipmentSourceEnum[inboundForm.constructionEquipmentSource]}）`" width="750px" @close="closeAddEquipmentDialog">
      <div slot="content">
        <add-equipment-list
          v-if="showAddEquipmentList"
          ref="addEquipmentList"
          :already-add-list="inboundForm.equipmentItems"
          style="margin-bottom: 20px"
          @on-add-equipments="closeAddEquipmentDialog(true, arguments)"
        />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import { WarehouseEquipmentStockInboundListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { EquipmentSourceEnum } from '@/views/constructionEquipmentMgr/enum'
import { getWarehouseStockProjects, putWarehouseStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import AddEquipmentList from '@/views/constructionEquipmentMgr/stockMgr/components/warehouseInboundAddEquipmentList'
import { getProject } from '@/api/constructionProjectMgr/projectMgr'

export default {
  name: 'InboundForm',
  components: { AddEquipmentList },
  data() {
    return {
      WarehouseEquipmentStockInboundListTitle, EquipmentSourceEnum,
      inboundForm: {
        action: 'inbound',
        warehouseId: null,
        warehouseName: null,
        constructionEquipmentSource: 'OLD',
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: null
      },
      inboundFormRules: {
        warehouseId: [{ required: true, message: '请选择入货仓库' }],
        constructionEquipmentSource: [{ required: true, message: '请选择设备来源', trigger: 'change' }],
        constructionProjectId: [{ required: true, message: '请选择选择工程', trigger: 'change' }],
        equipmentItems: [{ required: true, message: '请选择入库设备' }]
        // attachmentData: [{ required: true, message: '请上传出库单', trigger: 'change' }]
      },
      isSubmitting: false,
      warehouseInfo: null,
      showAddEquipmentList: false,
      projectList: [],
      isProjectLoading: false,
      inboundQuantities: 0,
      isEquipmentLoading: false
    }
  },
  computed: {
    tableTitle() {
      if (this.inboundForm.constructionEquipmentSource === 'OLD') {
        const title = [
          { label: '可入库数量', prop: 'quantity' },
          { label: '本次入库数量', prop: 'inboundQuantity', width: 150, showOverflowTooltip: false }
        ]
        return [...WarehouseEquipmentStockInboundListTitle, ...title]
      } else {
        const title = [
          { label: '本次入库数量', prop: 'inboundQuantity', width: 150, showOverflowTooltip: false }
        ]
        return [...WarehouseEquipmentStockInboundListTitle, ...title]
      }
    }
  },
  watch: {
    'inboundForm.equipmentItems': {
      handler: function() {
        this.inboundQuantities = this.inboundForm.equipmentItems.reduce((quantity, current) => {
          return quantity + current.inboundQuantity || 0
        }, 0)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initInfo(warehouseInfo) {
      this.warehouseInfo = warehouseInfo
      this.inboundForm.warehouseId = warehouseInfo.id
      this.inboundForm.warehouseName = warehouseInfo.name
      this.getWarehouseStockProjects()
    },
    handleEquipmentSourceChange() {
      this.inboundForm.equipmentItems = []
      this.inboundForm.constructionProjectId = null
    },
    getWarehouseStockProjects() {
      getWarehouseStockProjects().then(resp => {
        this.projectList = resp
        this.isProjectLoading = false
      })
    },
    handleSelectProject(value) {
      this.inboundForm.equipmentItems = []
      this.inboundForm.constructionProjectId = value
      // this.getProjectEquipmentList(value)
    },
    getProjectEquipmentList(projectId) {
      this.isEquipmentLoading = true
      getProject(projectId).then(resp => {
        resp.equipmentStockItems.forEach(item => {
          item.inboundQuantity = 0
          item.id = item.constructionEquipmentId
          item.name = item.constructionEquipmentName
          item.code = item.constructionEquipmentName
          item.specModel = item.constructionEquipmentSpecModel
          item.quantity = item.projectEquipmentQuantity
          item.isWhole = item.constructionEquipmentIsWhole
          item.inboundQuantity = item.projectEquipmentQuantity
        })
        this.inboundForm.equipmentItems = resp.equipmentStockItems
        this.isEquipmentLoading = false
      }).catch(() => {
        this.isEquipmentLoading = false
      })
    },
    openAddEquipmentDialog() {
      this.showAddEquipmentList = true
      this.$refs.addEquipmentDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.addEquipmentList.getList(this.inboundForm.constructionEquipmentSource === 'OLD', this.inboundForm.constructionProjectId)
        // this.$refs.addEquipmentList.getList()
      })
    },
    closeAddEquipmentDialog(isAdd = false, $arguments) {
      if (isAdd) {
        this.inboundForm.equipmentItems = [...this.inboundForm.equipmentItems, ...$arguments[0]]
        if (this.inboundForm.equipmentItems && this.inboundForm.equipmentItems.length > 0) {
          this.$refs.inboundForm.clearValidate('equipmentItems')
        }
      }
      this.showAddEquipmentList = false
      this.$refs.addEquipmentDialog.dialogVisible = false
    },
    onDeleteEquipment(index) {
      this.inboundForm.equipmentItems.splice(index, 1)
    },
    onFileUploaded(context, url) {
      this.inboundForm.attachmentData = url
      if (this.inboundForm.attachmentData) {
        this.$refs.inboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      const inboundForm = this.getInboundForm()
      this.$refs.inboundForm.validate(valid => {
        if (valid) {
          if (inboundForm.equipmentItems.some(item => item.quantity > 0)) {
            this.isSubmitting = true
            putWarehouseStocks(inboundForm).then(() => {
              this.$emit('confirm-submit')
              this.isSubmitting = false
              this.$message({ message: `入库成功`, type: 'success' })
            }).catch(() => { this.isSubmitting = false })
          } else {
            this.$message({ message: `入库总数不能小于1`, type: 'error', center: true })
            return false
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getInboundForm() {
      const data = {
        action: 'inbound',
        warehouseId: null,
        warehouseName: null,
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: []
      }
      data.warehouseId = this.inboundForm.warehouseId
      data.warehouseName = this.inboundForm.warehouseName
      data.constructionProjectId = this.inboundForm.constructionEquipmentSource === 'OLD' ? this.inboundForm.constructionProjectId : null
      data.equipmentItems = this.inboundForm.equipmentItems.map(item => {
        return {
          constructionEquipmentId: item.id,
          constructionEquipmentName: item.name,
          constructionEquipmentCode: item.code,
          quantity: item.inboundQuantity || 0,
          isWhole: item.isWhole
        }
      }).filter(item => item.quantity > 0)
      data.attachmentData = this.inboundForm.attachmentData ? [this.inboundForm.attachmentData] : []
      return data
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.equipment-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  gap: 20px;
  margin-bottom: 10px;
}

.line-two-item-row{
  display: flex;
  .el-form-item{
    width: calc(50% - 10px); /* 每个项目的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}

.quantities{
  font-size: 14px;
  font-weight: bold;
  color: $primaryColor;
}
</style>
