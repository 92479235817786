<template>
  <div>
    <template-view ref="editTemplate" is-edit :template-id="templateId" />
  </div>
</template>

<script>
import TemplateView from '@/views/incomingInspectionMgr/nskTemplateMgr/components/templateView'
export default {
  name: 'Edit',
  components: { TemplateView },
  data() {
    return {
      templateId: null
    }
  },
  created() {
    if (this.$route.params && this.$route.params.hasOwnProperty('templateId')) {
      this.templateId = this.$route.params.templateId
      this.$nextTick(() => {
        this.$refs.editTemplate.getTemplateInfo()
      })
    }
  }
}
</script>

<style scoped>

</style>
