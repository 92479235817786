import Layout from '@/layout'

const ExternalAccessRouter = [
  {
    path: '/newRemoteGuidance/externalCall/sinoma/:sessionKey',
    component: () => import('@/views/newRemoteGuidance/externalCall/sinoma'),
    name: 'externalCallSinomaList',
    meta: { title: '远程指导', noCache: true },
    hidden: true,
    props: true
  },
  {
    path: '/newRemoteGuidance/externalCall/sinoma/recordView/:roomId/:sessionKey',
    component: () => import('@/views/newRemoteGuidance/externalCall/sinoma/recordView'),
    name: 'externalCallSinomaMeetingRecordsView',
    meta: { title: '远程指导', noCache: true },
    hidden: true,
    props: true
  },
  {
    path: '/newRemoteGuidance/temporaryCall/:roomId/:chatRoomId/:senderId',
    component: () => import('@/views/newRemoteGuidance/temporaryCall/index'),
    name: 'temporaryCallMeeting',
    meta: { title: '临时参会', noCache: true },
    hidden: true,
    props: true
  },
  {
    path: '/newRemoteGuidance/externalCall/common/:appId/:outerId/:userName',
    component: () => import('@/views/newRemoteGuidance/externalCall/common'),
    name: 'externalCallCommonList',
    meta: { title: '远程指导', noCache: true },
    hidden: true,
    props: true
  },
  {
    path: '/newRemoteGuidance/externalCall/common/recordView/:roomId/:appId/:outerId/:userName',
    component: () => import('@/views/newRemoteGuidance/externalCall/common/recordView'),
    name: 'externalCallCommonMeetingRecordsView',
    meta: { title: '远程指导', noCache: true },
    hidden: true,
    props: true
  },
  // 外部链接访问加入远程指导会议
  {
    path: '/byLinkCall/:roomId/:chatRoomId/:senderId/:senderFullName/:senderUserName/:isByLink',
    component: Layout,
    redirect: '/newRemoteGuidance/byLinkCall/:roomId/:chatRoomId/:senderId/:senderFullName/:senderUserName/:isByLink',
    hidden: true,
    children: [
      {
        path: '/newRemoteGuidance/byLinkCall/:roomId/:chatRoomId/:senderId/:senderFullName/:senderUserName/:isByLink',
        component: () => import('@/views/newRemoteGuidance/meetingRecords/list'),
        name: 'externalCallByLink',
        props: true,
        meta: { title: '会议记录', noCache: true }
      }
    ]
  },
  // 正式服隐藏ai问答
  // {
  //   path: '/sparkBot',
  //   component: () => import('@/views/sparkBot'),
  //   name: 'sparkBot',
  //   meta: { title: 'ai问答', noCache: true },
  //   hidden: true,
  //   props: true
  // },
  {
    path: '/taskWorkOrder',
    // component: () => import('@/views/taskMgr/executeWorkOrder/taskWorkOrder'),
    component: () => import('@/views/taskMgr/executeWorkOrder/patrolTaskWorkOrder'),
    name: 'taskWorkOrder',
    meta: { title: '执行工单', noCache: true },
    hidden: true,
    props: true
  },
  {
    path: '/trainingWorkOrder',
    // component: () => import('@/views/executeWorkOrder/index'),
    component: () => import('@/views/executeWorkOrder/newPatrolTaskWorkOrder'),
    name: 'trainingWorkOrder',
    meta: { title: '执行工单', noCache: true },
    hidden: true,
    props: true
  },
  {
    path: '/repairTaskWorkOrder',
    component: () => import('@/views/taskMgr/executeWorkOrder/repairTaskWorkOrder'),
    name: 'repairTaskWorkOrder',
    meta: { title: '执行工单', noCache: true },
    hidden: true,
    props: true
  }
]

export default ExternalAccessRouter
