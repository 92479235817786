var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticStyle: { "min-width": "1000px" },
    },
    [
      _c("div", { staticClass: "order-title" }, [
        _c("div", { staticClass: "order-name" }, [
          _vm._v(_vm._s(_vm.workOrder && _vm.workOrder.name)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "order-code" }, [
          _vm._v(_vm._s(_vm.workOrder ? "（" + _vm.workOrder.code + "）" : "")),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "order-info-container" },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "order-descriptions",
              attrs: {
                column: 2,
                size: "medium",
                border: "",
                "label-style": { width: "110px" },
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { span: 2 } },
                [
                  _c("template", { slot: "label" }, [_vm._v("工程")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.workOrder &&
                          _vm.workOrder.targetInfo &&
                          _vm.workOrder.targetInfo.projectName) ||
                          "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("整机设备")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.workOrder && _vm.workOrder.targetCode
                          ? _vm.workOrder.targetCode
                          : "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("工作流程")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.workOrder && _vm.workOrder.taskTemplateName) || "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("作业类型")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.workOrder &&
                          _vm.getTowerWorkOrderType(_vm.workOrder.towerWorkType)
                          ? _vm.getTowerWorkOrderType(
                              _vm.workOrder.towerWorkType
                            ).value
                          : "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("创建人")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.workOrder &&
                          _vm.workOrder.staff &&
                          _vm.workOrder.staff.fullName) ||
                          "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("创建时间")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s((_vm.workOrder && _vm.workOrder.createdAt) || "-")
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("执行人")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.workOrder &&
                          _vm.workOrder.executor &&
                          _vm.workOrder.executor.fullName) ||
                          "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("开始执行时间")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s((_vm.workOrder && _vm.workOrder.startAt) || "-")
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("完成时间")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s((_vm.workOrder && _vm.workOrder.finishAt) || "-")
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("工单执行状态")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.workOrder &&
                          _vm.TaskWorkOrderStatusEnum[_vm.workOrder.status]) ||
                          "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("文件上传状态")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.workOrder &&
                          _vm.FileUploadStatusEnum[
                            _vm.workOrder.fileUploadStatus
                          ]) ||
                          "-"
                      )
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "device-tracks-container" },
            [
              _vm.showFullScreenImg && _vm.showDeviceTracks
                ? _c("img", {
                    staticClass: "full-screen-img",
                    attrs: { src: _vm.FullScreen, alt: "全屏" },
                    on: { click: _vm.openTraceDialog },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showDeviceTracks
                ? _c("device-tracks", {
                    ref: "smallDeviceTracks",
                    staticClass: "device-tracks",
                    attrs: {
                      height: "250px",
                      "map-id": "smallDeviceTracks",
                      "show-image": _vm.showDeviceImg,
                      "track-arr": _vm.deviceTrackArr,
                      center: _vm.deviceTrackCenter,
                      position: _vm.devicePosition,
                    },
                  })
                : _c("div", { staticClass: "no-device-tracks-info" }, [
                    _vm._v("暂无轨迹信息"),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: { "file-list": _vm.workOrder && _vm.workOrder.uploadFiles },
        on: {
          "on-upload-file": _vm.onFileUploaded,
          "on-delete-file": _vm.onDeleteFile,
        },
      }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "20px 0", "text-align": "right" } },
        [
          _c(
            "ht-button",
            {
              attrs: {
                loading: _vm.isExporting,
                type: "primary",
                icon: "el-icon-document",
              },
              on: { click: _vm.onExportPdf },
            },
            [_vm._v("导出工单")]
          ),
          _vm._v(" "),
          _c(
            "ht-button",
            { attrs: { type: "primary" }, on: { click: _vm.openTraceDialog } },
            [_vm._v("查看轨迹")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          ref: "list",
          attrs: {
            id: "workOrderSteps",
            data: _vm.steps,
            stripe: false,
            "row-key": "id",
            "default-expand-all": "",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
        },
        [
          _c("ht-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              fixed: "left",
              "class-name": "index-column",
            },
          }),
          _vm._v(" "),
          _vm._l(_vm.PatrolWorkOrderResultListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: item.fixed,
                align: item.align || "center",
                "show-overflow-tooltip": false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "图片"
                          ? _c(
                              "div",
                              { staticClass: "result-img-container" },
                              [
                                row.results &&
                                row.results.uploadPhotos &&
                                row.results.uploadPhotos.length > 0
                                  ? _vm._l(
                                      row.results.uploadPhotos,
                                      function (imgItem, imgIndex) {
                                        return _c("el-image", {
                                          key: imgIndex,
                                          staticClass: "img",
                                          attrs: {
                                            src: imgItem,
                                            "preview-src-list":
                                              row.results.uploadPhotos,
                                          },
                                        })
                                      }
                                    )
                                  : [_vm._v(_vm._s("-"))],
                              ],
                              2
                            )
                          : item.label === "步骤名称"
                          ? _c("span", { staticClass: "cell-overflow" }, [
                              _vm._v(_vm._s(row.properties.title || "-")),
                            ])
                          : item.label === "检查要求"
                          ? _c(
                              "span",
                              { staticClass: "cell-overflow" },
                              [
                                [
                                  _vm._v(
                                    " " + _vm._s(row.properties.text || "-")
                                  ),
                                ],
                              ],
                              2
                            )
                          : item.label === "情况描述"
                          ? _c(
                              "span",
                              { staticClass: "cell-overflow" },
                              [
                                row.results.factDesc &&
                                row.results.factDesc !== null &&
                                typeof row.results.factDesc === "object"
                                  ? [
                                      row.results.factDesc.length > 0
                                        ? _vm._l(
                                            row.results.factDesc,
                                            function (factItem, index) {
                                              return _c("div", { key: index }, [
                                                _vm._v(
                                                  _vm._s(
                                                    index + 1 + "." + factItem
                                                  )
                                                ),
                                              ])
                                            }
                                          )
                                        : [_vm._v("-")],
                                    ]
                                  : [
                                      _vm._v(
                                        _vm._s(row.results.factDesc || "-")
                                      ),
                                    ],
                              ],
                              2
                            )
                          : item.label === "结果"
                          ? _c(
                              "span",
                              {
                                staticClass: "cell-overflow",
                                style: _vm.getResultTextColor(row),
                              },
                              [
                                row.tag === "ResultRecordType"
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          row.results.checkResult
                                            ? _vm.ResultsEnum[
                                                row.results.checkResult
                                              ]
                                            : "暂无"
                                        )
                                      ),
                                    ]
                                  : row.tag === "ValueRecordType"
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getValueRecordResult(row)
                                          ),
                                        },
                                      }),
                                    ]
                                  : row.tag === "HiddenDangerRecordType"
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getHiddenDangerRecordResult(row)
                                          ),
                                        },
                                      }),
                                    ]
                                  : row.tag === "AccidentRecordType"
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          row.results.accidentType
                                            ? _vm.AccidentTypeEnum[
                                                row.results.accidentType
                                              ]
                                            : "暂无"
                                        )
                                      ),
                                    ]
                                  : row.tag === "QuickPatrolRecordType"
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          row.isTitle
                                            ? "-"
                                            : row.results.answer || "暂无"
                                        )
                                      ),
                                    ]
                                  : row.tag === "ScanCodeAndConfirmType"
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getScanCodeResult(row)
                                          ),
                                        },
                                      }),
                                    ]
                                  : row.tag === "VoiceRecordValueType"
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getValueRecordResult(row)
                                          ),
                                        },
                                      }),
                                    ]
                                  : row.tag === "OCRRecordValueType"
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getValueRecordResult(row)
                                          ),
                                        },
                                      }),
                                    ]
                                  : row.tag === "KeyboardRecordValueType"
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getValueRecordResult(row)
                                          ),
                                        },
                                      }),
                                    ]
                                  : [_vm._v(_vm._s("-"))],
                              ],
                              2
                            )
                          : item.label === "定位"
                          ? _c(
                              "span",
                              [
                                [
                                  _vm._v(
                                    " " + _vm._s(row.results.location || "-")
                                  ),
                                ],
                              ],
                              2
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "deviceTracksDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "轨迹",
            width: "1000px",
          },
          on: { close: _vm.closeTraceDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showDeviceTracks
                ? _c("device-tracks", {
                    ref: "deviceTracks",
                    attrs: {
                      height: "600px",
                      "map-id": "workOrderDeviceTracks",
                      "show-image": _vm.showDeviceImg,
                      "track-arr": _vm.deviceTrackArr,
                      center: _vm.deviceTrackCenter,
                      position: _vm.devicePosition,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }