var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "statistic-box",
      style: _vm.boxStyle,
    },
    [
      _c("dw-chart-title", {
        attrs: { title: _vm.title, "title-style": _vm.titleStyle },
        scopedSlots: _vm._u(
          [
            {
              key: "statistic",
              fn: function () {
                return [_vm._t("statistic")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _vm._t("chart"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }