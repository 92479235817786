<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClearSearch">
      <template slot="left">
        <el-select v-model="listQuery.constructionEquipmentName" placeholder="设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.name" :value="item.value" />
        </el-select>
        <el-input v-model="listQuery.constructionEquipmentCode" placeholder="产权编号" />
        <el-input v-model="listQuery.constructionEquipmentSpecModel" placeholder="规格型号" />
        <el-date-picker
          v-model="createdAt"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          placement="bottom-start"
          start-placeholder="租用开始时间"
          end-placeholder="租用结束时间"
        />
        <el-select v-model="listQuery.status" placeholder="租赁状态">
          <el-option v-for="(value,key ) in ProjectLeaseStatusEnum" :key="key" :label="value" :value="key" />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="item in EquipmentLeaseRecordsListTitle" :key="item.prop" :label="item.label" :prop="item.prop" :min-width="item.width || 100" :fixed="item.fixed">
        <template v-slot="{row}">
          <el-tag v-if="item.label === '状态'" :type="ProjectLeaseStatusStyleEnum[row[item.prop]]">{{ ProjectLeaseStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { EquipmentLeaseRecordsListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getProjectEquipmentRecords } from '@/api/constructionProjectMgr/projectMgr'
import { ProjectLeaseStatusEnum, ProjectLeaseStatusStyleEnum } from '@/views/constructionProjectMgr/projectMgr/enum'
import { EquipmentTypesEnum } from '@/views/constructionEquipmentMgr/enum'

export default {
  name: 'List',
  extends: HtListContainer,
  data() {
    return {
      EquipmentTypesEnum,
      EquipmentLeaseRecordsListTitle,
      ProjectLeaseStatusEnum, ProjectLeaseStatusStyleEnum,
      createdAt: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onClearSearch() {
      this.createdAt = []
      this.onClear()
    },
    getList() {
      this.beforeGetList()
      this.listQuery.createdStartAt = this.createdAt[0]
      this.listQuery.createdEndAt = this.createdAt[1]
      getProjectEquipmentRecords(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
