import request from '@/utils/request'

// 凯胜一站式登陆
export function postKaiShengSso(data) {
  return request({
    url: 'outer_apis/kaisheng/sso',
    method: 'post',
    data: data
  })
}
