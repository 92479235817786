var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-container" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "20px" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabs, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.value, name: item.name },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.activeName === "workOrder"
        ? _c("work-order-list", { staticStyle: { padding: "74px 20px 20px" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName === "imgSearch"
        ? _c("work-order-img-search", {
            staticStyle: { "padding-top": "74px" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }