<template>
  <div>
    <equipment-descriptions :equipment-info="equipmentInfo" />
    <el-timeline style="padding: 20px 0 0 ">
      <el-timeline-item
        v-for="(item, index) in logisticsTraces"
        :key="index"
        icon="el-icon-arrow-up"
        type="primary"
        size="large"
      >
        <div class="trace-item">
          <div class="item top-item">
            <el-tag class="status action" :type="StockActionStyleEnum[item.action]">{{ StockActionEnum[item.action] }}</el-tag>
            <span class="createdAt">{{ item.createdAt }}</span>
            <span class="quantity">数量：{{ item.quantity }}</span>
          </div>
          <div class="item middle-item">
            <template v-if="item.action === 'INBOUND'">
              <span class="item position">{{ item.constructionProjectName || '(新设备)' }}</span>
              <span class="item action">{{ StockActionEnum[item.action] }}至</span>
              <span class="item position">{{ item.warehouseName }}</span>
            </template>
            <template v-else-if="item.action === 'OUTBOUND'">
              <span class="item position">{{ item.warehouseName }}</span>
              <span class="item action">{{ StockActionEnum[item.action] }}至</span>
              <span class="item position">{{ item.constructionProjectName }}</span>
            </template>
            <template v-else-if="item.action === 'TRANSSHIPMENT'">
              <span class="item position">{{ item.oldConstructionProjectName }}</span>
              <span class="item action">{{ StockActionEnum[item.action] }}至</span>
              <span class="item position">{{ item.constructionProjectName }}</span>
            </template>
          </div>
          <div class="item bottom-item">
            <dw-file
              v-if="item.attachmentData && item.attachmentData.length > 0"
              class="item attachment"
              :url="item.attachmentData[0]"
              :show-del="false"
              justify-content="left"
              :need-margin="false"
              @view-file="downloadFile"
            />
            <span v-else class="item attachment">未上传文件</span>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { getEquipmentStockRecords } from '@/api/constructionEquipmentMgr/stockMgr'
import { downloadFile } from '@/utils'
import { StockActionEnum, StockActionStyleEnum } from '@/views/constructionEquipmentMgr/enum'
import EquipmentDescriptions from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentDescriptions'

export default {
  name: 'LogisticsTrace',
  components: { EquipmentDescriptions },
  props: {
    idName: { type: String, default: 'id' }
  },
  data() {
    return {
      StockActionEnum, StockActionStyleEnum,
      equipmentInfo: null,
      logisticsTraces: []
    }
  },
  methods: {
    downloadFile,
    initInfo(equipmentInfo) {
      this.equipmentInfo = equipmentInfo
      getEquipmentStockRecords(equipmentInfo[this.idName]).then(resp => {
        this.logisticsTraces = resp
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.equipment-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  gap: 20px;
  margin-bottom: 20px;
}

.trace-item{
  //font-size: 1rem;
  font-size: 14px;
  color: #666666;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
  padding: 15px;
  .item {
    margin-bottom: 15px;
  }
  .top-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    .action{
      //font-size: 1.1rem;
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
    }
    .createdAt{
      //font-size: 0.9rem;
      font-size: 12px;
    }
    .quantity{
      flex: 1;
      text-align: right;
      font-weight: bold;
    }
  }
  .middle-item{
    .action{
      font-weight: bold;
      margin: 0 20px;
    }
  }
  .item:last-child{
    margin-bottom: 0;
  }
}

.el-timeline{
  ::v-deep .el-timeline-item{
    position: relative;
    margin-bottom: 20px;
    .el-timeline-item__tail{
      top: 50%;
      margin-top: 10px;
      border-left: 3px solid $primaryColor;
    }
    .el-timeline-item__node{
      width: 24px;
      height: 24px;
      left: 5px;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $primaryColor;
    }
  }
}

.trace-descriptions{
  width: 100%;
  //padding: 10px;
  //border: 1px solid #e5e5e5;
  //border-radius: 10px;
  //box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
</style>
