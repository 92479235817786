import request from '@/utils/request'

const document_es_test = 'document/es_test'
const document_knowledge_bases = 'document/knowledge_bases/'

// es测试接口
export function getDocumentEsTest(listQuery) {
  return request({ url: document_es_test, method: 'GET', params: listQuery })
}

// 知识列表
export function getKnowledgeBases(listQuery) {
  return request({ url: `${document_knowledge_bases}`, method: 'GET', params: listQuery })
}

// 新增知识
export function postKnowledgeBases(data) {
  return request({ url: `${document_knowledge_bases}`, method: 'POST', data: data })
}

// 修改知识
export function putKnowledgeBase(knowledgeId, data) {
  return request({ url: `${document_knowledge_bases}${knowledgeId}`, method: 'PUT', data: data })
}

// 删除知识
export function deleteKnowledgeBase(knowledgeId) {
  return request({ url: `${document_knowledge_bases}${knowledgeId}`, method: 'DELETE' })
}

// 查看详情
export function getKnowledgeBase(knowledgeId, listQuery) {
  return request({ url: `${document_knowledge_bases}${knowledgeId}`, method: 'GET', params: listQuery })
}

// 知识检索
export function getKnowledgeBasesEs(listQuery) {
  return request({ url: `${document_knowledge_bases}es`, method: 'GET', params: listQuery })
}

// 文件内容转换
export function postGetContent(data) {
  return request({ url: `document/get_content`, method: 'POST', data: data })
}

// 知识状态转换（发布/草稿）
export function putKnowledgeBasesPublished(knowledgeId, data) {
  return request({ url: `${document_knowledge_bases}${knowledgeId}/published`, method: 'PUT', data: data })
}

