var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      attrs: {
        content: _vm.tooltip || decodeURI(_vm.linkUrl),
        placement: "top",
        effect: "light",
        "open-delay": 300,
      },
    },
    [
      _vm.isDownload
        ? [
            _c(
              "el-link",
              {
                attrs: {
                  size: "mini",
                  href: _vm.linkUrl,
                  download: "下载附件",
                  target: "_blank",
                  icon: "el-icon-link",
                  type: "primary",
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.linkTitle) + "\n    ")]
            ),
          ]
        : [
            _c(
              "el-link",
              {
                attrs: {
                  size: "mini",
                  href: _vm.linkUrl,
                  target: "_blank",
                  icon: "el-icon-link",
                  type: "primary",
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.linkTitle) + "\n    ")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }