var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ht-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      ref: "list",
      attrs: { data: _vm.leaseDetailList },
    },
    [
      _c("ht-table-column", {
        attrs: { label: "序号", type: "index", fixed: "left" },
      }),
      _vm._v(" "),
      _vm._l(_vm.LeaseListTitle, function (item) {
        return _c("ht-table-column", {
          key: item.prop,
          attrs: {
            label: item.label,
            prop: item.prop,
            "min-width": item.width || 100,
            fixed: item.fixed,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    item.label === "工程地址"
                      ? _c("span", [
                          _vm._v(
                            "\n        " +
                              _vm._s(row[item.prop] || "-") +
                              "\n        "
                          ),
                        ])
                      : item.label === "状态"
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.ProjectLeaseStatusStyleEnum[
                                row[item.prop]
                              ],
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.ProjectLeaseStatusEnum[row[item.prop]] ||
                                  "-"
                              )
                            ),
                          ]
                        )
                      : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                  ]
                },
              },
            ],
            null,
            true
          ),
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }