export function extentPropsWithPrefix(columns, bizPrefix) {
  const newColumns = JSON.parse(JSON.stringify(columns))
  Object.keys(newColumns).forEach(key => {
    const item = newColumns[key]
    if (item['columns']) {
      item['columns'] = extentPropsWithPrefix(item.columns, bizPrefix)
    } else if (item['prop']) {
      item.prop = `${bizPrefix}.${item.prop}`
    }
  })
  return newColumns
}

// 经纬度计算中点
export function calculateCenter(coordinates) {
  if (coordinates.length > 0) {
    let sumLongitude = 0
    let sumLatitude = 0
    const numCoordinates = coordinates.length

    for (const [longitude, latitude] of coordinates) {
      sumLongitude += longitude
      sumLatitude += latitude
    }

    const centerLongitude = sumLongitude / numCoordinates
    const centerLatitude = sumLatitude / numCoordinates

    return [centerLongitude, centerLatitude]
  } else {
    return null
  }
}

// 根据文件地址/文件名称获取文件类型
export function getFileTypeByExtension(url) {
  // 使用 split() 函数将文件地址按照点号（.）分割成数组， 使用 pop() 函数获取数组中的最后一个元素，即文件扩展名
  const type = url && url.split('.').pop()
  switch (type && type.toLowerCase()) {
    case 'doc':
    case 'docx':
      return 'word'
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'image'
    case 'pdf':
      return 'pdf'
    case 'xls':
    case 'xlsx':
      return 'excel'
    case 'ppt':
    case 'pptx':
      return 'ppt'
    case 'zip':
    case 'rar':
      return 'zip'
    default:
      return 'unknown'
  }
}

// 构建匹配HTML标签的正则表达式
export function buildRegexForHtml(text) {
  let regex = ''
  for (let char of text) {
    // 将每个字符用正则表达式转义
    if (char === ' ') {
      continue
    }
    char = char.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    // 在每个字符后添加模式匹配任意HTML标签
    regex += char + '(?:\\s*(?:<[^>]*>\\s*)*)'
  }
  return new RegExp(regex, 'g')
}

// 在文本中查找匹配项并替换
export function replacePhraseWithLabel(text, part, startLabel, endLabel) {
  // 使用正则表达式查找匹配项
  const match = text.match(part)
  // 如果没有找到匹配项，返回原始文本
  if (!match) {
    return text
  }

  // 将每个匹配项包裹在 <startLabel> <endLabel> 标签中
  const replacedText = match.map(part => `${startLabel}${part}${endLabel}`).join('')
  // 将替换后的文本返回到调用位置
  return text.replace(part, replacedText)
}

export function isVideoOrImage(fileLink) {
  if (fileLink) {
    // 获取文件扩展名
    const extension = fileLink.split('.').pop().toLowerCase()

    // 定义视频和图片的扩展名列表
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'mkv', 'flv']
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']

    // 判断文件类型
    if (videoExtensions.includes(extension)) {
      return 'video'
    } else if (imageExtensions.includes(extension)) {
      return 'image'
    } else {
      return 'unknown'
    }
  } else {
    return 'unknown'
  }
}
