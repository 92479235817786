<template>
  <order-form
    v-if="showForm"
    is-mobile
    label-width="120px"
    select-popper-class="mobile-select"
    @confirm-submit="submitCreateOrderForm"
  />
</template>

<script>

import OrderForm from '@/views/taskMgr/patrolWorkOrderMgr/components/orderForm'
export default {
  name: 'Create',
  components: { OrderForm },
  data() {
    return {
      showForm: true
    }
  },
  methods: {
    submitCreateOrderForm() {
      this.$confirm('工单创建成功，是否继续创建新工单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '返回',
        type: 'warning',
        center: true,
        customClass: 'mobile-confirm'
      }).then(() => {
        this.showForm = false
        setTimeout(() => {
          this.showForm = true
        }, 200)
      }).catch(() => {
        this.$router.replace({ name: 'MobileHomePage' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
