<template>
  <div class="equipment-stock-container" :class="[{ 'is-card-container': isCard}]" @click="onView">
    <div class="top-section">
      <div class="name">设备：{{ equipmentInfo && `${equipmentInfo.name}${equipmentInfo.code ? '-' + equipmentInfo.code : '' }` }}</div>
      <el-tag v-if="equipmentInfo && equipmentInfo.isWhole" class="status" effect="dark" :type="EquipmentLeaseStatusStyleEnum[equipmentInfo.leaseStatus.name]">{{ EquipmentLeaseStatusEnum[equipmentInfo.leaseStatus.name] || '-' }}</el-tag>
    </div>
    <div class="middle-section">
      <div class="left">
        <div class="item spec-model">规格型号：{{ equipmentInfo && equipmentInfo.specModel || '-' }}</div>
        <div class="item quantity">库存数：{{ equipmentInfo && equipmentInfo.quantity }}</div>
      </div>
      <span class="button">查看详情></span>
    </div>
    <div class="bottom-section">
      <!--       todo-->
      <!--      <el-button v-if="!isCouldOutBound(equipmentInfo)" type="warning" round @click="onOutbound">出库</el-button>-->
      <!--      <el-button v-if="!isCouldInBound(equipmentInfo)" type="primary" round @click="onInbound">入库</el-button>-->
    </div>
  </div>
</template>

<script>
import { EquipmentLeaseStatusEnum, EquipmentLeaseStatusStyleEnum } from '@/views/constructionEquipmentMgr/enum'

export default {
  name: 'EquipmentStockCard',
  props: {
    equipmentInfo: { type: Object, default() { return {} } },
    isCard: { type: Boolean, default: true }
  },
  data() {
    return {
      EquipmentLeaseStatusEnum, EquipmentLeaseStatusStyleEnum
    }
  },
  methods: {
    isCouldOutBound(row) {
      // 库存数为0不可出库
      if (row.quantity === 0) { return true }
      if (row.isWhole) {
        // 整机租用状态为 UNDER_LEASED 才可出库
        return row.leaseStatus && row.leaseStatus.name !== 'UNDER_LEASED'
      } else {
        // 部件库存数大于0即可出库
        return false
      }
    },
    isCouldInBound(row) {
      if (row.isWhole) {
        // 整机库存数大于0不可入库
        return row.quantity > 0
      } else {
        // 部件随时可入库
        return false
      }
    },
    onView() {
      this.$emit('on-view', this.equipmentInfo)
    },
    onOutbound() {
      this.$emit('on-outbound', this.equipmentInfo)
    },
    onInbound() {
      this.$emit('on-inbound', this.equipmentInfo)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';

.equipment-stock-container{
  font-size: 1.1rem;
  color: #666666;
  background: $primaryColorLight;
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
  .top-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .name{
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
      font-weight: bold;
    }
  }
  .middle-section{
    //margin: 10px 0;
    margin: 10px 0 0;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .spec-model{
      margin-bottom: 5px;
    }
    .button{
      font-size: 1rem;
      color: $primaryColor;
    }
  }
  .bottom-section{
    display: flex;
    justify-content: space-between;
    .el-button{
      flex: 1;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
</style>
