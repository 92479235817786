// export const EquipmentTypesEnum = ['塔式起重机-基础', '塔式起重机-附墙', '塔式起重机-标准节', '施工升降机', '物料提升机']

export const EquipmentTypesEnum = [
  { name: '施工升降机', value: '施工升降机', isWhole: true },
  { name: '物料提升机', value: '物料提升机', isWhole: true },
  { name: '塔式起重机', value: '塔式起重机', isWhole: true },
  // { name: '塔式起重机-基础', value: '塔式起重机-基础', isWhole: false },
  { name: '塔式起重机-附墙', value: '塔式起重机-附墙', isWhole: false },
  { name: '塔式起重机-标准节', value: '塔式起重机-标准节', isWhole: false }
]

export const EquipmentTypeEnum = {
  TOWER_CRANE: '塔吊',
  LIFT: '升降机'
}

export const EquipmentLeaseStatusEnum = {
  UNDER_LEASED: '出租',
  FREE: '空闲'
}

export const EquipmentLeaseStatusStyleEnum = {
  UNDER_LEASED: 'success',
  FREE: 'info'
}

export const EquipmentIsWholeEnum = [
  { name: true, value: '是' },
  { name: false, value: '否' }
]

export const EquipmentAbnormalEnum = [
  { name: true, value: '是', style: 'danger' },
  { name: false, value: '否', style: 'info' }
]

export const PatrolProgressEnum = [
  { name: 'ALL', value: '全部完成', style: 'primary' },
  { name: 'MOST', value: '基本完成', style: 'success' },
  { name: 'MINORITY', value: '部分完成', style: 'warning' },
  { name: 'LITTLE', value: '完成度较低', style: 'danger' },
  { name: 'NONE', value: '未开始', style: 'info' }
]

export const WarehouseEquipmentStoreStatusEnum = [
  { name: 'UNMANAGED', value: '未管理', style: 'info' },
  { name: 'IN_STORAGE', value: '已入库', style: 'success' },
  { name: 'OUT_STORAGE', value: '已出库', style: 'danger' }
]

export const StockType = [
  { name: true, value: '大于0' },
  { name: false, value: '等于0' }
]

export const StockActionEnum = {
  INBOUND: '入库',
  OUTBOUND: '出库',
  TRANSSHIPMENT: '转移'
}

export const StockActionStyleEnum = {
  INBOUND: 'success',
  OUTBOUND: 'warning',
  TRANSSHIPMENT: 'primary'
}

export const EquipmentSourceEnum = {
  OLD: '工程返库',
  NEW: '新设备入库'
}

