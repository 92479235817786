import request from '@/utils/request'

const document_category = 'document/category/'
const document_categories = 'document/categories/'
const document_category_tree = 'document/category_tree/'

// 获取分类
export function getDocumentCategoryTree(listQuery) {
  return request({ url: `${document_category_tree}`, method: 'GET', params: listQuery })
}

// 添加分类
export function postDocumentCategories(data) {
  return request({ url: `${document_categories}`, method: 'POST', data: data })
}

// 修改分类
export function putDocumentCategory(categoryId, data) {
  return request({ url: `${document_category}${categoryId}`, method: 'PUT', data: data })
}

// 删除分类
export function deleteDocumentCategory(categoryId) {
  return request({ url: `${document_category}${categoryId}`, method: 'DELETE' })
}

// 检查当前分类下是否包含文档
export function getDocumentCategoryUsageCheck(categoryId, listQuery) {
  return request({ url: `${document_category}${categoryId}/usage_check`, method: 'GET', params: listQuery })
}
