import Layout from '@/layout'

const TpmRoutePatrolMgrRouter = {
  path: '/tpmRoutePatrolMgr',
  component: Layout,
  redirect: '/tpmRoutePatrolMgr/checklist/list',
  name: 'tpmRoutePatrolMenu',
  meta: { title: '巡检路线', icon: 'work_order' },
  children: [
    {
      path: '/tpmRoutePatrolMgr/checklist/list',
      component: () => import('@/views/tpmRoutePatrolMgr/index'),
      hidden: true,
      name: 'tpmRoutePatrolChecklist',
      meta: { title: '巡检路线', breadcrumb: false, activeMenu: '/tpmRoutePatrolMgr/checklist/list' }
    }
  ]
}

export default TpmRoutePatrolMgrRouter
