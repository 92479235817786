var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "remote-video-wrapper",
      class: {
        "focused-container": _vm.isFocusedGuide,
        "is-screen-container": _vm.isScreen,
        "fullscreen-mobile-remote-video-wrapper":
          _vm.isFullscreen && _vm.isMobile,
      },
    },
    [
      _c(
        "player-view",
        {
          attrs: {
            id: _vm.id,
            "video-size": _vm.getVideoSize,
            "is-expert": _vm.isExpert(),
            "is-android": _vm.isAndroid(),
            "is-share-video": _vm.isShareVideo,
            "is-remote-muted": _vm.isRemoteMuted,
            "video-track": _vm.videoTrack,
            "is-screen": _vm.isScreen,
            "show-top-tools": "",
            "is-remote-video": "",
            "show-focus-canvas": false,
            "is-large-video": _vm.isLargeVideo,
            "show-laser-canvas":
              _vm.videoInfo &&
              _vm.videoInfo.remoteOperation &&
              _vm.videoInfo.remoteOperation.isLaserMoving,
            "show-paint-canvas":
              _vm.videoInfo &&
              _vm.videoInfo.remoteOperation &&
              _vm.videoInfo.remoteOperation.isPaintDrawing,
            "is-clear-paint":
              _vm.videoInfo &&
              _vm.videoInfo.remoteOperation &&
              _vm.videoInfo.remoteOperation.isClearPaint,
            "video-property": _vm.videoInfo && _vm.videoInfo.videoProperty,
            "im-user-id":
              _vm.videoInfo &&
              _vm.videoInfo.userInfo &&
              _vm.videoInfo.userInfo.imUserId,
            "is-subscribing": _vm.videoInfo && _vm.videoInfo.isSubscribing,
            "remote-paint-info": _vm.remotePaintInfo,
            "remote-laser-info": _vm.remoteLaserInfo,
            "show-remote-paint-canvas": _vm.isRemotePaint,
            "show-remote-laser-canvas": _vm.isRemoteLaser,
            "is-cam-on": _vm.deviceStatus && _vm.deviceStatus.isCamOn,
            "is-uvc-cam-on":
              _vm.deviceStatus &&
              _vm.deviceStatus.isUvcConnected &&
              _vm.deviceStatus.isUvcCamOn,
            "bit-rate": _vm.getBitRate,
            "is-fullscreen": _vm.isFullscreen,
            "is-mobile": _vm.isMobile,
          },
          on: {
            "cam-manual-focused": _vm.camManualFocused,
            "paint-drawing": _vm.paintDrawing,
            "laser-moving": _vm.laserMoving,
            "handle-resize": _vm.handleResize,
          },
        },
        [
          !_vm.isScreen
            ? _c(
                "div",
                {
                  staticClass: "top top-left",
                  attrs: { slot: "topLeft" },
                  slot: "topLeft",
                },
                [
                  _vm.isLargeVideo && _vm.videoTrack
                    ? _c(
                        "div",
                        { staticClass: "large-window" },
                        [
                          _c("div", { staticClass: "remote-hint" }, [
                            _vm._v("远程控制"),
                          ]),
                          _vm._v(" "),
                          _c("video-button", {
                            attrs: {
                              icon:
                                _vm.deviceStatus && !_vm.deviceStatus.isMicOn
                                  ? _vm.MicrophoneOff
                                  : _vm.MicrophoneOn,
                              name: "麦克风",
                            },
                            on: { click: _vm.changeOneMicMute },
                          }),
                          _vm._v(" "),
                          _c("video-button", {
                            attrs: {
                              icon:
                                _vm.deviceStatus && !_vm.deviceStatus.isCamOn
                                  ? _vm.CameraOff
                                  : _vm.CameraOn,
                              "is-disabled":
                                (_vm.deviceStatus &&
                                  _vm.deviceStatus.isUvcConnected &&
                                  _vm.deviceStatus.isUvcCamOn) ||
                                _vm.isFreezeGuidance,
                              name: "摄像头",
                            },
                            on: { click: _vm.changeOneCamMute },
                          }),
                          _vm._v(" "),
                          _vm.deviceStatus &&
                          _vm.userInfo &&
                          (_vm.userInfo.loginPath.includes("Android") ||
                            _vm.userInfo.loginPath.includes("GLASSES"))
                            ? [
                                _vm.deviceStatus &&
                                _vm.deviceStatus.isUvcConnected
                                  ? _c("video-button", {
                                      attrs: {
                                        icon: _vm.deviceStatus.isUvcCamOn
                                          ? _vm.UsbOn
                                          : _vm.UsbOff,
                                        name: _vm.deviceStatus.isUvcCamOn
                                          ? "关闭外置镜头"
                                          : "外置镜头",
                                      },
                                      on: { click: _vm.changeOneUvcCam },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("video-button", {
                                  attrs: {
                                    icon: _vm.CameraRollover,
                                    "is-disabled":
                                      _vm.deviceStatus &&
                                      !_vm.deviceStatus.isCamOn,
                                    name: "切换镜头",
                                  },
                                  on: { click: _vm.changeOneCamSwitch },
                                }),
                                _vm._v(" "),
                                _vm.deviceStatus &&
                                _vm.deviceStatus.isFlashSupported
                                  ? _c("video-button", {
                                      attrs: {
                                        icon: _vm.deviceStatus.isFlashOn
                                          ? _vm.FlashOn
                                          : _vm.FlashOff,
                                        name: "闪光灯",
                                        "is-disabled":
                                          _vm.deviceStatus &&
                                          !_vm.deviceStatus.isCamOn,
                                      },
                                      on: { click: _vm.changeOneFlashlight },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.deviceStatus &&
                                _vm.deviceStatus.camExposureLevels
                                  ? [
                                      _c("video-button", {
                                        attrs: {
                                          icon: _vm.Exposure,
                                          "is-disabled":
                                            _vm.deviceStatus &&
                                            !_vm.deviceStatus.isCamOn,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "exposure-level" },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.deviceStatus &&
                                                  !_vm.deviceStatus.isCamOn,
                                                placeholder: "曝光",
                                              },
                                              on: {
                                                change:
                                                  _vm.changeOneCamExposureLevel,
                                              },
                                              model: {
                                                value: _vm.camExposureLevel,
                                                callback: function ($$v) {
                                                  _vm.camExposureLevel = $$v
                                                },
                                                expression: "camExposureLevel",
                                              },
                                            },
                                            _vm._l(
                                              _vm.deviceStatus
                                                .camExposureLevels,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: "曝光级别" + item,
                                                    value: item,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("video-button", {
                                  attrs: {
                                    icon: _vm.Zoom,
                                    "is-disabled":
                                      _vm.deviceStatus &&
                                      !_vm.deviceStatus.isCamOn,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "zoom-level" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.deviceStatus &&
                                            !_vm.deviceStatus.isCamOn,
                                          placeholder: "镜头缩放",
                                        },
                                        on: {
                                          change: _vm.changeOneCamZoomLevel,
                                        },
                                        model: {
                                          value: _vm.camZoomLevel,
                                          callback: function ($$v) {
                                            _vm.camZoomLevel = $$v
                                          },
                                          expression: "camZoomLevel",
                                        },
                                      },
                                      _vm._l(5, function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: "镜头缩放" + item,
                                            value: item,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("video-button", {
                            attrs: {
                              icon: _vm.Adjust,
                              "is-disabled":
                                _vm.deviceStatus && !_vm.deviceStatus.isCamOn,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "video-config" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.deviceStatus &&
                                      !_vm.deviceStatus.isCamOn,
                                    placeholder: "视频质量",
                                  },
                                  on: { change: _vm.changeOneVideoResolution },
                                  model: {
                                    value: _vm.videoResolution,
                                    callback: function ($$v) {
                                      _vm.videoResolution = $$v
                                    },
                                    expression: "videoResolution",
                                  },
                                },
                                _vm._l(
                                  _vm.VideoResolutions,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        x: "",
                                        label: item.value,
                                        value: item.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm.videoTrack
                    ? _c(
                        "div",
                        { staticClass: "small-window" },
                        [
                          _c("video-button", {
                            attrs: {
                              icon:
                                _vm.deviceStatus && !_vm.deviceStatus.isMicOn
                                  ? _vm.MicrophoneOff
                                  : _vm.MicrophoneOn,
                              name: "麦克风",
                            },
                            on: { click: _vm.changeOneMicMute },
                          }),
                          _vm._v(" "),
                          _c("video-button", {
                            attrs: {
                              icon:
                                _vm.deviceStatus && !_vm.deviceStatus.isCamOn
                                  ? _vm.CameraOff
                                  : _vm.CameraOn,
                              "is-disabled": _vm.isFreezeGuidance,
                              name: "摄像头",
                            },
                            on: { click: _vm.changeOneCamMute },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "top top-right",
              attrs: { slot: "topRight" },
              slot: "topRight",
            },
            [
              !_vm.isScreen
                ? _c("video-button", {
                    attrs: {
                      icon: _vm.isFocusedGuide ? _vm.FocusWhite : _vm.FocusRed,
                      name: _vm.isFocusedGuide ? "退出指导" : "聚焦指导",
                      "is-disabled":
                        _vm.deviceStatus &&
                        !_vm.deviceStatus.isCamOn &&
                        _vm.deviceStatus.isUvcConnected &&
                        !_vm.deviceStatus.isUvcCamOn,
                      "is-red": "",
                      "is-red-click": _vm.isFocusedGuide,
                    },
                    on: { click: _vm.changeOneFocusGuidance },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "bottom-left",
              attrs: { slot: "bottomLeft" },
              slot: "bottomLeft",
            },
            [
              _c("div", { staticClass: "name-container" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: _vm.Head, alt: "" },
                }),
                _vm._v(" "),
                _vm.isSinoma || _vm.stringLongOverTen(_vm.userInfo.userName)
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s("" + (_vm.userInfo && _vm.userInfo.fullName))
                      ),
                    ])
                  : _c("span", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          (_vm.userInfo && _vm.userInfo.fullName) +
                            "（" +
                            (_vm.userInfo && _vm.userInfo.userName) +
                            "）"
                        )
                      ),
                    ]),
              ]),
              _vm._v(" "),
              !_vm.isMobile
                ? _vm._l(_vm.getLoginIcon(), function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "login-type-container" },
                      [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: item, alt: "" },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.getLoginType()[index])),
                        ]),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.videoTrack && _vm.getBitRate !== null
            ? _c(
                "div",
                {
                  staticClass: "bottom-right",
                  attrs: { slot: "bottomRight" },
                  slot: "bottomRight",
                },
                [
                  _c("div", { staticClass: "send-container item left" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: _vm.ReceiveArrows, alt: "" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.getBitRate + "kbps")),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }