var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
    },
    [
      _c(
        "el-descriptions",
        {
          staticClass: "check-list-descriptions",
          attrs: {
            column: 2,
            size: "medium",
            border: "",
            "label-style": { width: "110px" },
          },
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("品番")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.checklistInfo.nskMaterialCode || "-")),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("部品名称")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.checklistInfo.nskMaterialName || "-")),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("图番")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.checklistInfo.nskMaterialIllustrationNumber || "-")
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("车型")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.checklistInfo.vehicleType || "-")),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("供应商")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.checklistInfo.supplierName || "-")),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("来料日")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.checklistInfo.receivingAt || "-")),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("检查日")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.checklistInfo.checkAt || "-"))]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("数量")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.checklistInfo.quantity || "-"))]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("批次")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.checklistInfo.batchNum || "-"))]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("模号")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.checklistInfo.moduleNumber || "-")),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("检查开始时间")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.checklistInfo.startAt || "-"))]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("检查结束时间")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.checklistInfo.finishAt || "-"))]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("检查员")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    (_vm.checklistInfo.staff &&
                      _vm.checklistInfo.staff.fullName) ||
                      "-"
                  )
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("检查状态")]),
              _vm._v(" "),
              _c(
                "el-tag",
                {
                  attrs: {
                    type: _vm.ChecklistStatusStyleCnEnum[
                      _vm.checklistInfo.status
                    ],
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.ChecklistStatusCnEnum[_vm.checklistInfo.status] || "-"
                    )
                  ),
                ]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("判定结果")]),
              _vm._v(" "),
              _vm.checklistInfo.finishAt
                ? _c(
                    "el-tag",
                    {
                      staticClass: "status",
                      attrs: {
                        type: _vm.checklistInfo.isAbnormal
                          ? "danger"
                          : "success",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.checklistInfo.isAbnormal ? "不合格" : "合格")
                      ),
                    ]
                  )
                : _c("span", [_vm._v(_vm._s("-"))]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            { attrs: { "content-class-name": "has-operation" } },
            [
              _c("template", { slot: "label" }, [_vm._v("备注")]),
              _vm._v(" "),
              _vm.isEditRemark
                ? _c("el-input", {
                    staticStyle: { "margin-right": "10px" },
                    model: {
                      value: _vm.remark,
                      callback: function ($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark",
                    },
                  })
                : _c("span", [_vm._v(_vm._s(_vm.checklistInfo.remark || "-"))]),
              _vm._v(" "),
              _vm.isEditRemark
                ? _c(
                    "ht-button",
                    {
                      staticClass: "edit-button",
                      attrs: { type: "text", icon: "el-icon-close" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ht-button",
                {
                  staticClass: "edit-button",
                  attrs: {
                    type: "text",
                    icon: _vm.isEditRemark
                      ? "el-icon-circle-check"
                      : "el-icon-edit",
                  },
                  on: { click: _vm.onEdit },
                },
                [_vm._v(_vm._s(_vm.isEditRemark ? "确认" : "编辑"))]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "text-align": "right", "padding-top": "20px" } },
        [
          _c(
            "ht-button",
            {
              attrs: {
                loading: _vm.isExporting,
                icon: "el-icon-document",
                type: "primary",
              },
              on: { click: _vm.onExport },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          ref: "list",
          staticStyle: { "margin-top": "20px" },
          attrs: { data: _vm.checklistInfo.checkItems },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.NskCheckListViewCheckItemTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "量具"
                          ? _c("span", [
                              _vm._v(_vm._s(row[item.prop].toString())),
                            ])
                          : item.label === "检查结果"
                          ? [
                              row.specType === "数值测量"
                                ? _c(
                                    "div",
                                    [
                                      row.result &&
                                      row.result.measureResult &&
                                      row.result.measureResult.length > 0
                                        ? _vm._l(
                                            _vm.getCheckMeasureResult(
                                              row.result.measureResult,
                                              row.abnormalData
                                            ),
                                            function (result, resultIndex) {
                                              return _c("span", {
                                                key: resultIndex,
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    "" +
                                                      result +
                                                      (resultIndex !==
                                                      _vm.getCheckMeasureResult(
                                                        row.result
                                                          .measureResult,
                                                        row.abnormalData
                                                      ).length -
                                                        1
                                                        ? "，"
                                                        : "")
                                                  ),
                                                },
                                              })
                                            }
                                          )
                                        : [_vm._v(_vm._s("-"))],
                                    ],
                                    2
                                  )
                                : _c(
                                    "span",
                                    {
                                      class: {
                                        "red-text":
                                          row.result.checkResult === "不合格",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(row.result.checkResult || "-")
                                      ),
                                    ]
                                  ),
                            ]
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }