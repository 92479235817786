var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "title-container" },
        [
          _c("div", { staticClass: "left-title" }, [_vm._v("文档分类")]),
          _vm._v(" "),
          _c(
            "ht-action-panel",
            {
              staticClass: "right-search",
              attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear },
            },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "标题" },
                    model: {
                      value: _vm.listQuery.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "title", $$v)
                      },
                      expression: "listQuery.title",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { placeholder: "发布人" },
                    model: {
                      value: _vm.listQuery.publishedStaffName,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "publishedStaffName", $$v)
                      },
                      expression: "listQuery.publishedStaffName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "状态" },
                      model: {
                        value: _vm.listQuery.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "status", $$v)
                        },
                        expression: "listQuery.status",
                      },
                    },
                    _vm._l(_vm.StatusEnum, function (value, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: value, value: key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content-container" }, [
        _c(
          "div",
          { staticClass: "left-category-container" },
          [
            _c("category-tree", {
              ref: "categoryTree",
              staticClass: "left-category-tree",
              attrs: {
                loading: _vm.isTreeLoading,
                "tree-list": _vm.getTree,
                "show-function-box": _vm.showFunctionBox,
                "margin-bottom": 200,
                "top-level-first-button-name": "新建一级目录",
                "is-disabled": _vm.isLoading,
              },
              on: {
                "node-click": _vm.handleClickTreeNode,
                "handle-click-more": _vm.handleClickMore,
                "click-outside": _vm.handleClickOutside,
              },
              scopedSlots: _vm._u([
                {
                  key: "functions",
                  fn: function () {
                    return [
                      _vm.showCreateSubCategory
                        ? _c(
                            "ht-button",
                            {
                              staticClass: "function-button top-button",
                              attrs: { type: "text", icon: "el-icon-plus" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleCreateCategoryDialog(true)
                                },
                              },
                            },
                            [_vm._v("新建子目录")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ht-button",
                        {
                          staticClass: "function-button",
                          class: { "top-button": !_vm.showCreateSubCategory },
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleAddKnowledgeDialog(true)
                            },
                          },
                        },
                        [_vm._v("新建文档")]
                      ),
                      _vm._v(" "),
                      _c("ht-upload-button", {
                        staticClass: "function-button",
                        attrs: {
                          "file-type": ["pdf", "doc"],
                          "button-type": "text",
                          "key-prefix": "knowledge",
                          "button-title": "导入文件",
                          "show-success-tip": false,
                        },
                        on: {
                          "on-file-uploaded": _vm.handleKnowledgeFileUploaded,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "ht-button",
                        {
                          staticClass: "function-button",
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleEditCategoryDialog(true)
                            },
                          },
                        },
                        [_vm._v("重命名")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ht-button",
                        {
                          staticClass: "function-button",
                          attrs: { type: "text", icon: "el-icon-rank" },
                          on: { click: _vm.handleClickMoveCategory },
                        },
                        [_vm._v("移动")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ht-popover-button",
                        {
                          staticClass: "function-button confirm-button",
                          attrs: {
                            type: "text",
                            icon: "el-icon-delete",
                            "confirm-title": "请确认是否要删除该目录？",
                          },
                          on: { confirmed: _vm.handleDelCategory },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right-knowledge-container" },
          [
            _c(
              "ht-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                ref: "list",
                attrs: { data: _vm.list },
              },
              [
                _c("ht-table-column", {
                  attrs: { label: "序号", type: "index", fixed: "left" },
                }),
                _vm._v(" "),
                _vm._l(_vm.KnowledgeMgrListTitle, function (item) {
                  return _c("ht-table-column", {
                    key: item.prop,
                    attrs: {
                      label: item.label,
                      prop: item.prop,
                      "min-width": item.width || 100,
                      fixed: item.fixed,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              item.label === "附件"
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type:
                                              row[item.prop] &&
                                              row[item.prop].length > 0
                                                ? "primary"
                                                : "info",
                                            disabled:
                                              row[item.prop] &&
                                              row[item.prop].length === 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAttachmentDialog(
                                                row,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row[item.prop] &&
                                                row[item.prop].length
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : item.label === "创建人"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (row.staff && row.staff.fullName) || "-"
                                      )
                                    ),
                                  ])
                                : item.label === "发布人"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (row.publishedStaff &&
                                          row.publishedStaff.fullName) ||
                                          "-"
                                      )
                                    ),
                                  ])
                                : item.label === "状态"
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm.StatusStyleEnum[
                                          row[item.prop]
                                        ],
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.StatusEnum[row[item.prop]])
                                      ),
                                    ]
                                  )
                                : item.label === "浏览次数"
                                ? _c("span", [
                                    _vm._v(_vm._s(row[item.prop] || "0")),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(row[item.prop] || "-")),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                _vm._v(" "),
                _c("ht-table-column-operation", {
                  attrs: { width: 280 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "ht-button",
                            {
                              attrs: { type: "text", icon: "el-icon-edit" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleEditKnowledgeDialog(
                                    row,
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          row.status === "PUBLISHED"
                            ? _c(
                                "ht-popover-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-notebook-1",
                                    "confirm-title": "请确认是否要转为草稿？",
                                  },
                                  on: {
                                    confirmed: function ($event) {
                                      return _vm.toggleKnowledgeStatus(
                                        row,
                                        "DRAFT"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("转为草稿")]
                              )
                            : row.status === "DRAFT"
                            ? _c(
                                "ht-popover-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-s-promotion",
                                    "confirm-title": "请确认是否要直接发布？",
                                  },
                                  on: {
                                    confirmed: function ($event) {
                                      return _vm.toggleKnowledgeStatus(
                                        row,
                                        "PUBLISHED"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("直接发布")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "ht-button",
                            {
                              attrs: { type: "text", icon: "el-icon-rank" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleMoveKnowledgeDialog(
                                    row,
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v("移动")]
                          ),
                          _vm._v(" "),
                          _c(
                            "ht-popover-button",
                            {
                              attrs: {
                                type: "text",
                                "danger-text": true,
                                icon: "el-icon-delete",
                                "confirm-title": "请确认是否要删除该条数据？",
                              },
                              on: {
                                confirmed: function ($event) {
                                  return _vm.handleDeleteKnowledge(row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("ht-pagination", {
              staticStyle: { float: "right", "margin-bottom": "10px" },
              attrs: {
                total: _vm.page.total,
                page: _vm.listQuery.page,
                limit: _vm.listQuery.perPage,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "perPage", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "addCategoryDialog",
          attrs: {
            title: "添加目录",
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleCreateCategoryDialog(false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showAddCategoryForm
                ? _c("category-form", {
                    ref: "addCategoryForm",
                    attrs: { "parent-id": _vm.currentNodeDataParentId },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleCreateCategoryDialog(false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleCreateCategoryDialog(false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "editCategoryDialog",
          attrs: {
            title: "编辑目录",
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleEditCategoryDialog(false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showEditCategoryForm
                ? _c("category-form", {
                    ref: "editCategoryForm",
                    attrs: {
                      "is-edit": "",
                      "category-data": _vm.currentNodeData,
                    },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleEditCategoryDialog(false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleEditCategoryDialog(false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "moveCategoryDialog",
          attrs: {
            title: "移动目录",
            width: "450px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleMoveCategoryDialog(false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showMoveCategoryForm
                ? _c("move-category-form", {
                    ref: "moveCategoryForm",
                    attrs: {
                      tree: _vm.tree,
                      "category-data": _vm.currentNodeData,
                    },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleMoveCategoryDialog(false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleMoveCategoryDialog(false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "addKnowledgeDialog",
          staticClass: "knowledge-edit-dialog",
          attrs: {
            title: "新建文档",
            width: "1000px",
            "close-on-click-modal": false,
            fullscreen: _vm.isFullscreenKnowledgeDialog,
          },
          on: {
            close: function ($event) {
              return _vm.toggleAddKnowledgeDialog(false)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "close" }, slot: "close" }, [
            _vm.isFullscreenKnowledgeDialog
              ? _c("img", {
                  staticClass: "maximize fullscreen-icon",
                  attrs: { src: _vm.ExitFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeDialog },
                })
              : _c("img", {
                  staticClass: "maximize fullscreen-icon",
                  attrs: { src: _vm.EnterFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeDialog },
                }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showAddKnowledgeForm
                ? _c("knowledge-form", {
                    ref: "addKnowledgeForm",
                    attrs: {
                      "category-data": _vm.currentNodeData,
                      "is-fullscreen": _vm.isFullscreenKnowledgeDialog,
                    },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleAddKnowledgeDialog(false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleAddKnowledgeDialog(false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "editKnowledgeDialog",
          staticClass: "knowledge-edit-dialog",
          attrs: {
            title: "编辑文档",
            width: "1000px",
            "close-on-click-modal": false,
            fullscreen: _vm.isFullscreenKnowledgeDialog,
          },
          on: {
            close: function ($event) {
              return _vm.toggleEditKnowledgeDialog(null, false)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "close" }, slot: "close" }, [
            _vm.isFullscreenKnowledgeDialog
              ? _c("img", {
                  staticClass: "maximize fullscreen-icon",
                  attrs: { src: _vm.ExitFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeDialog },
                })
              : _c("img", {
                  staticClass: "maximize fullscreen-icon",
                  attrs: { src: _vm.EnterFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeDialog },
                }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showEditKnowledgeForm
                ? _c("knowledge-form", {
                    ref: "editKnowledgeForm",
                    attrs: {
                      "is-edit": "",
                      "category-data": _vm.currentNodeData,
                      "is-fullscreen": _vm.isFullscreenKnowledgeDialog,
                      "knowledge-data": _vm.currentKnowledgeData,
                    },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleEditKnowledgeDialog(null, false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleEditKnowledgeDialog(null, false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "importKnowledgeDialog",
          staticClass: "knowledge-edit-dialog",
          attrs: {
            title: "文档导入",
            width: "1000px",
            "close-on-click-modal": false,
            fullscreen: _vm.isFullscreenKnowledgeDialog,
          },
          on: {
            close: function ($event) {
              return _vm.toggleImportKnowledgeDialog(false)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "close" }, slot: "close" }, [
            _vm.isFullscreenKnowledgeDialog
              ? _c("img", {
                  staticClass: "maximize fullscreen-icon",
                  attrs: { src: _vm.ExitFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeDialog },
                })
              : _c("img", {
                  staticClass: "maximize fullscreen-icon",
                  attrs: { src: _vm.EnterFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeDialog },
                }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showImportKnowledgeForm
                ? _c("knowledge-form", {
                    ref: "importKnowledgeForm",
                    attrs: {
                      "is-import": "",
                      "category-data": _vm.currentNodeData,
                      "is-fullscreen": _vm.isFullscreenKnowledgeDialog,
                      "knowledge-data": _vm.currentKnowledgeData,
                    },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleImportKnowledgeDialog(false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleImportKnowledgeDialog(false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "moveKnowledgeDialog",
          attrs: {
            title: "移动文件",
            width: "450px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleMoveKnowledgeDialog(null, false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showMoveKnowledgeForm
                ? _c("move-knowledge-form", {
                    ref: "moveKnowledge",
                    attrs: {
                      tree: _vm.tree,
                      "knowledge-data": _vm.currentKnowledgeData,
                    },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleMoveKnowledgeDialog(null, false)
                      },
                      "confirm-submit": function ($event) {
                        return _vm.toggleMoveKnowledgeDialog(null, false, true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "attachmentListDialog",
          attrs: { title: "附件", width: "600px" },
          on: {
            close: function ($event) {
              return _vm.toggleAttachmentDialog(null, false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showAttachmentList
                ? _c("attachment-list", {
                    ref: "documentList",
                    attrs: {
                      "attachment-list": _vm.attachmentList,
                      "show-operation": false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }