var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "container" },
    [
      _vm._l(_vm.articleList, function (item, index) {
        return _c("div", { key: index, staticClass: "article-container" }, [
          _c(
            "h3",
            {
              staticClass: "title",
              on: {
                click: function ($event) {
                  return _vm.openArticleDialog(item)
                },
              },
            },
            [_vm._v(_vm._s(item.title))]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "info",
            domProps: {
              innerHTML: _vm._s(
                _vm.replacePhraseWithLabel(
                  item.info,
                  item.keyword,
                  "<mark>",
                  "</mark>"
                )
              ),
            },
          }),
        ])
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "articleDialog",
          attrs: { title: "测试文章定位", width: "800px" },
          on: { close: _vm.closeArticleDialog },
        },
        [
          _c(
            "div",
            {
              staticClass: "article-detail-container",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c("h2", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.article && _vm.article.title)),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "content",
                domProps: {
                  innerHTML: _vm._s(_vm.article && _vm.article.content),
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }