// 维修记录页
import MaintenanceRecordTypeImg from '@/views/taskMgr/templateMgr/components/maintenanceRecordType/img.jpg'
import MaintenanceRecordType from '@/views/taskMgr/templateMgr/components/maintenanceRecordType/stepUi'
import { MaintenanceRecordTypeProperties } from '@/views/taskMgr/templateMgr/components/properties'
import { MaintenanceRecordTypeResults } from '@/views/taskMgr/templateMgr/components/results'

export const MaintenanceAidRecordProperties = {
  name: '维修记录页',
  type: 'REPAIR',
  tag: 'maintenanceRecordType',
  img: MaintenanceRecordTypeImg,
  stepUi: MaintenanceRecordType,
  properties: MaintenanceRecordTypeProperties,
  results: MaintenanceRecordTypeResults
}
