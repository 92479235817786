var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      attrs: {
        content: _vm.tooltip,
        placement: "top",
        effect: "light",
        "open-delay": 300,
      },
    },
    [
      _c(
        "span",
        { staticClass: "mall-link-highlight mall-font-size-default" },
        [_c("i", { staticClass: "el-icon-question" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }