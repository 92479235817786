import request from '@/utils/request'
const tack_work_order = 'task/work_order/'
const tack_work_orders = 'task/work_orders/'
const tack_step_result = 'task/step_result/'
const tack_work_order_pdf = 'task/work_order_pdf/'
const tack_work_order_gallery = 'task/work_order/gallery'
const tack_work_order_gallery_recommended_words = 'task/work_order/gallery/recommended_words/'

// 获取工单列表
export function getWorkOrders(listQuery) {
  return request({ url: `${tack_work_orders}`, method: 'GET', params: listQuery })
}

// 创建工单
export function postWorkOrders(data) {
  return request({ url: `${tack_work_orders}`, method: 'POST', data: data })
}

// 获取工单详情
export function getWorkOrder(orderId) {
  return request({ url: `${tack_work_order}${orderId}`, method: 'GET' })
}

// 修改工单详情
export function putWorkOrder(orderId, data) {
  return request({ url: `${tack_work_order}${orderId}`, method: 'PUT', data: data })
}

// 删除工单
export function deleteWorkOrder(orderId) {
  return request({ url: `${tack_work_order}${orderId}`, method: 'DELETE' })
}

// 工单步骤提交
export function putStepResult(stepId, data) {
  return request({ url: `${tack_step_result}${stepId}`, method: 'PUT', data: data })
}

// 外部访问调取接口
// 获取工单详情
export function getWorkOrderNoAuthorization(token, id) {
  return request({ url: `${tack_work_order}${id}`, method: 'GET', headers: { 'Authorization': token }})
}

// 工单步骤提交
export function putStepResultNoAuthorization(token, stepId, data) {
  return request({ url: `${tack_step_result}${stepId}`, method: 'PUT', data: data, headers: { 'Authorization': token }})
}

// 导出工单
export function postWorkOrderPdf(orderId) {
  return request({ url: `${tack_work_order_pdf}${orderId}`, method: 'POST', timeout: 180000 })
}

// 任务工单图库
export function getWorkOrderGallery(listQuery) {
  return request({ url: `${tack_work_order_gallery}`, method: 'GET', params: listQuery })
}

// 任务工单：图库（搜索词推荐）
export function getWorkOrderGalleryRecommendedWords(listQuery) {
  return request({ url: `${tack_work_order_gallery_recommended_words}`, method: 'GET', params: listQuery })
}
