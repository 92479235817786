<template>
  <div>
    <el-form ref="macForm" :model="macForm" :rules="rules" label-width="100px">
      <el-form-item label="mac地址：" prop="beaconMacAddr">
        <el-input
          v-model="macForm.beaconMacAddr"
          placeholder="请输入mac地址"
          style="width: 100%"
        />
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onSubmit('macForm')">确 定</el-button>
    </div>
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { updateEquipmentsMgrItemById } from '@/api/devicesMgr/deviceMgr'
import { isValidMacAddress } from '@/utils/validate'

export default {
  name: 'MacForm',
  extends: HtListContainer,
  props: {
    deviceId: {
      type: [Number, String],
      default: undefined
    },
    beaconMacAddr: {
      type: String,
      default: undefined
    }
  },
  data() {
    const validateBeaconMacAddr = (rule, value, callback) => {
      if (value !== '' && !(isValidMacAddress(value))) {
        callback(new Error('mac地址格式错误'))
      } else {
        callback()
      }
    }
    return {
      macForm: {
        beaconMacAddr: undefined
      },
      rules: {
        beaconMacAddr: [
          { required: false, trigger: 'blur', validator: validateBeaconMacAddr }
        ]
      }
    }
  },
  methods: {
    getBeaconMacAddr() {
      this.macForm.beaconMacAddr = this.beaconMacAddr || ''
    },
    onCancel() {
      this.$refs.macForm.clearValidate()
      this.$emit('on-cancel')
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateEquipmentsMgrItemById(this.deviceId, { beaconMacAddr: this.macForm.beaconMacAddr }).then(() => {
            this.$message({ message: '修改成功', type: 'success' })
            this.$emit('on-confirm')
          })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
