<template>
  <div>
    <ht-table ref="list" v-loading="isLoading" :data="list">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="i in labelToProps" :key="`${i.prop}-${i.secondProp}`" :label="i.label" :prop="i.prop">
        <template v-slot="{row}">
          <span v-if="i.format === 'changeDate'">
            {{ row[i.prop] | formatTime }}
          </span>
          <span v-else>
            <span v-if="i.secondProp">{{ (row[i.prop] && row[i.prop][i.secondProp]) ? row[i.prop][i.secondProp] : '-' }}</span>
            <span v-else>{{ row[i.prop] ? row[i.prop] : '-' }}</span>
          </span>
        </template>
      </ht-table-column>
      <!--      <ht-table-column-operation>-->
      <!--        <template v-slot="{row}">-->
      <!--          <ht-button type="text" @click="gotoView(row.code, row.id)">详情</ht-button>-->
      <!--          <ht-popover-button-->
      <!--            type="text"-->
      <!--            :danger-text="true"-->
      <!--            confirm-title="请确认是否要删除该条数据？"-->
      <!--          >删除-->
      <!--          </ht-popover-button>-->
      <!--        </template>-->
      <!--      </ht-table-column-operation>-->
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer/index'
import { getEquipmentOfPatrolTasksApi } from '@/api/devicesMgr/deviceMgr'

export default {
  name: 'BugReports',
  extends: HtListContainer,
  props: {
    deviceCode: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      labelToProps: [
        {
          label: '任务单号',
          prop: 'code'
        },
        {
          label: '任务日期',
          prop: 'taskDate',
          format: 'changeDate'
        },
        {
          label: '任务模式',
          prop: 'taskMode'
        },
        {
          label: '计划名称',
          prop: 'patrolPlan',
          secondProp: 'name'
        },
        {
          label: '设备编号',
          prop: 'equipment',
          secondProp: 'code'
        },
        {
          label: '巡检班组',
          prop: 'candidateTeamStr'
        },
        {
          label: '巡检人员',
          format: 'candidateAccountStr'
        },
        {
          label: '巡检方案',
          prop: 'entrustType'
        },
        {
          label: '预计开始时间',
          prop: 'scheduleStartTime',
          format: 'changeDate'
        },
        {
          label: '预计完成时间',
          prop: 'scheduleEndTime',
          format: 'changeDate'
        },
        {
          label: '预计工时',
          prop: 'scheduleWorkHour'
        },
        {
          label: '巡检状态',
          prop: 'patrolStatus'
        },
        {
          label: '备注',
          prop: 'remark'
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getEquipmentOfPatrolTasksApi(this.deviceCode).then((resp) => {
        this.afterGetList(resp)
      })
    }
  }

}
</script>

<style scoped>

</style>
