var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-scene",
        {
          attrs: {
            "mindar-image": {
              imageTargetSrc: _vm.mindArImageSrc,
              maxTrack: 2,
              uiScanning: "no",
            },
            "color-space": "sRGB",
            renderer: "colorManagement: true, physicallyCorrectLights",
            "vr-mode-ui": "enabled: false",
            "device-orientation-permission-ui": "enabled: false",
          },
        },
        [
          _c(
            "a-assets",
            [
              _c("a-asset-item", {
                attrs: {
                  id: "bearModel",
                  src: "https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/band-example/bear/scene.gltf",
                },
              }),
              _vm._v(" "),
              _c("a-asset-item", {
                attrs: {
                  id: "raccoonModel",
                  src: "https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/band-example/raccoon/scene.gltf",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("a-camera", {
            attrs: { position: "0 0 0", "look-controls": "enabled: false" },
          }),
          _vm._v(" "),
          _c(
            "a-entity",
            { attrs: { "mindar-image-target": "targetIndex: 0" } },
            [
              _c("a-gltf-model", {
                attrs: {
                  rotation: "0 0 0 ",
                  position: "0 -0.25 0",
                  scale: "0.05 0.05 0.05",
                  src: "#raccoonModel",
                  "animation-mixer": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a-entity",
            { attrs: { "mindar-image-target": "targetIndex: 1" } },
            [
              _c("a-gltf-model", {
                attrs: {
                  rotation: "0 0 0 ",
                  position: "0 -0.25 0",
                  scale: "0.05 0.05 0.05",
                  src: "#bearModel",
                  "animation-mixer": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }