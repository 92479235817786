<template>
  <el-card style="margin: 20px; min-width: 600px">
    <dw-title title="修改logo" />
    <div class="login-config">
      <el-form ref="configForm" :model="configForm" :rules="configRules" label-width="80px">
        <el-row :gutter="20">
          <!--          <el-form-item label="系统名称:" label-width="150px" prop="systemName">-->
          <!--            <el-input v-model="configForm.systemName" placeholder="请输入系统名称" clearable />-->
          <!--          </el-form-item>-->
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="系统logo:" label-width="150px" prop="logoUrl">
            <dw-image v-if="configForm.logoUrl" :url="configForm.logoUrl" @delete-img="deleteLogo" />
            <div v-else>
              <ht-upload-button
                class="upload-button"
                :button-icon="null"
                :file-type="['ico']"
                fixed-file-name
                file-name="favicon.ico"
                key-prefix="logo_config/logo"
                select-img
                @on-file-uploaded="onLogoUploaded"
              />
              <span class="tip">（名称：favicon.ico）</span>
            </div>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="登陆页背景:" label-width="150px" prop="backgroundImgUrl">
            <dw-image v-if="configForm.backgroundImgUrl" :url="configForm.backgroundImgUrl" @delete-img="deleteBackground" />
            <div v-else>
              <ht-upload-button
                class="upload-button"
                :button-icon="null"
                :file-type="['img']"
                fixed-file-name
                file-name="login_background.jpg"
                key-prefix="logo_config/login"
                select-img
                @on-file-uploaded="onBackgroundUploaded"
              />
              <span class="tip">（名称：login_background.jpg）</span>
            </div>
          </el-form-item>
        </el-row>
        <!--        <el-row :gutter="20">-->
        <!--          <el-form-item class="confirm-button-container">-->
        <!--            <el-button type="primary" icon="el-icon-check" @click="submitForm('configForm')">确定</el-button>-->
        <!--          </el-form-item>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      configForm: {
        systemName: undefined,
        logoUrl: process.env.VUE_APP_COS_SERVER + '/logo_config/logo/favicon.ico',
        backgroundImgUrl: process.env.VUE_APP_COS_SERVER + '/logo_config/login/login_background.jpg'
      },
      configRules: {
        // systemName: [{ required: true, message: '请输入系统名称', trigger: 'blur' }],
        // logoUrl: [{ required: true, message: '请上传logo', trigger: 'change' }],
        // backgroundImgUrl: [{ required: true, message: '请上传背景图片', trigger: 'change' }]
      }
    }
  },
  methods: {
    onLogoUploaded(context, url) {
      this.configForm.logoUrl = url
    },
    onBackgroundUploaded(context, url) {
      this.configForm.backgroundImgUrl = url
    },
    deleteLogo() {
      this.configForm.logoUrl = null
    },
    deleteBackground() {
      this.configForm.backgroundImgUrl = null
    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log(this.configForm)
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login-config{
  width: 500px;
  margin-left: 10px;
  margin-top: 10px;
}
.tip{
  color: #f56c6c;
  margin-left: 10px;
  margin-bottom: 5px;
}
.confirm-button-container {
  text-align: right;
}
</style>
