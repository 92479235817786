import request from '@/utils/request'

export const equipments_mgr = 'equipments/'
export const equipments_mgr_with_id = 'equipments/with_id/'

export function getEquipmentsMgrApi(listQuery) {
  return request({ url: equipments_mgr, method: 'GET', params: listQuery })
}

export function createEquipmentMgrApi(data) {
  return request({ url: equipments_mgr, method: 'POST', data: data })
}

export function getEquipmentDetailApi(equipmentCode) {
  return request({ url: `${equipments_mgr}${equipmentCode}`, method: 'GET' })
}

// 通过设备id删除设备
export function delEquipmentsMgrItemById(equipmentId) {
  return request({ url: `${equipments_mgr_with_id}${equipmentId}`, method: 'DELETE' })
}

// 通过设备id获取设备详情
export function getEquipmentDetailByIdApi(equipmentId) {
  return request({ url: `${equipments_mgr_with_id}${equipmentId}`, method: 'GET' })
}

export function updateEquipmentsMgrItem(equipmentCode, data) {
  return request({ url: `${equipments_mgr}${equipmentCode}`, method: 'PUT', data: data })
}

// 通过设备id修改设备
export function updateEquipmentsMgrItemById(equipmentId, data) {
  return request({ url: `${equipments_mgr_with_id}${equipmentId}`, method: 'PUT', data: data })
}

// 详情页生产数据
export function equipmentProductionDataApi(equipmentCode) {
  return request({ url: `${equipments_mgr}${equipmentCode}/production_data`, method: 'GET' })
}

export function equipmentProductionDataWithTimeApi(equipmentCode, query) {
  return request({ url: `${equipments_mgr}${equipmentCode}/production_data_with_time`, method: 'GET', params: query })
}

// 设备知识库
export function equipmentsKnowledgeLibsApi(equipmentCode, listQuery) {
  return request({ url: `${equipments_mgr}${equipmentCode}/knowledge_libs/`, method: 'GET', params: listQuery })
}

export function createEquipmentKnowledgeLibs(equipmentCode, data) {
  return request({ url: `${equipments_mgr}${equipmentCode}/knowledge_libs/`, method: 'POST', data: data })
}

export function updateEquipmentKnowledgeLibs(equipmentCode, libId, data) {
  return request({ url: `${equipments_mgr}${equipmentCode}/knowledge_libs/${libId}`, method: 'PUT', data: data })
}

export function deleteEquipmentKnowledgeLibs(equipmentCode, knowledgeLibsId) {
  return request({ url: `${equipments_mgr}${equipmentCode}/knowledge_libs/`, method: 'DELETE', data: { knowledgeIds: [knowledgeLibsId] }})
}

// 设备缺陷
export function getEquipmentOfBugReportsApi(equipmentCode) {
  return request({ url: `${equipments_mgr}${equipmentCode}/bug_reports/`, method: 'GET' })
}

// 巡检任务
export function getEquipmentOfPatrolTasksApi(equipmentCode) {
  return request({ url: `${equipments_mgr}${equipmentCode}/patrol_tasks/`, method: 'GET' })
}

// 维修任务
export function getEquipmentOfMaintainTasksApi(equipmentCode) {
  return request({ url: `${equipments_mgr}${equipmentCode}/maintain_tasks/`, method: 'GET' })
}
