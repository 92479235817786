var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    _vm._l(_vm.images, function (i, index) {
      return _c(
        "span",
        { key: index, staticClass: "imageBox" },
        [
          _vm.canDelete
            ? _c(
                "span",
                {
                  staticClass: "iconBox",
                  on: {
                    click: function ($event) {
                      return _vm.deleteImage(i, index)
                    },
                  },
                },
                [_vm._m(0, true)]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-image", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { fit: "contain", src: i, "preview-src-list": _vm.images },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [
      _c("i", {
        staticClass: "el-icon-close",
        staticStyle: {
          position: "absolute",
          top: "0",
          right: "0",
          color: "white",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }