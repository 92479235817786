<template>
  <div class="footer-container" :style="{width: footerWidth}">
    <span class="local-hint">本地操作</span>
    <operating-button class="item" name="麦克风" :icon="isShareAudio ? MicrophoneOpen : MicrophoneClose" @click="shareAudioButtonClick" />
    <operating-button v-if="showShareCameraVideo" class="item" name="摄像头" :icon="isShareCameraVideo ? CameraOpen :CameraClose" @click="shareVideoCameraButtonClick" />
    <operating-button v-if="showShareScreenVideo && !isMobile" class="item" :width="75" :name="isShareScreenVideo ? '结束共享' : '屏幕共享'" :icon="ScreenShare" :is-show-dot="isShareScreenVideo" @click="shareScreenVideoButtonClick" />
    <operating-button v-if="showRecordingVideo" class="item" :width="75" :name="isRecordingVideo ? '结束录屏' : '会议录屏'" :icon="ScreenRecording" :is-show-dot="isRecordingVideo" @click="recordingVideoClick" />
    <!--    <operating-button v-if="showPen && !isMobile" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" name="激光笔" :icon="LaserPen" :is-show-dot="isLaserMoving" @click="laserButtonClick" />-->
    <!--    <operating-button v-if="showPaint && !isMobile" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" :width="75" name="实时画笔" :icon="Paintbrush" :is-show-dot="isPaintDrawing" @click="paintButtonClick" />-->
    <!--    <operating-button v-if="showPaint && !isMobile" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" :width="75" name="清除轨迹" :icon="Clear" @click="clearButtonClick" />-->
    <operating-button v-if="showPen" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" name="激光笔" :icon="LaserPen" :is-show-dot="isLaserMoving" @click="laserButtonClick" />
    <operating-button v-if="showPaint" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" :width="75" name="实时画笔" :icon="Paintbrush" :is-show-dot="isPaintDrawing" @click="paintButtonClick" />
    <operating-button v-if="showPaint" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" :width="75" name="清除轨迹" :icon="Clear" @click="clearButtonClick" />
    <operating-button v-if="showGuidance && !(isMobile && !isHasVideo)" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" :width="75" name="标注指导" :icon="Guidance" :is-show-dot="isFreezeGuidance" @click="guidanceButtonClick" />
    <operating-button class="item" :width="75" name="会议消息" :icon="Message" is-show-num :num="messageCount" @click="messageButtonClick" />
    <operating-button class="item" :width="75" name="参会人员" :icon="Attendee" is-show-num :num="userCount" @click="attendeeButtonClick" />
    <operating-button v-if="showInvite" class="item" :width="75" name="邀请用户" :icon="InvitePerson" @click="inviteButtonClick" />
    <operating-button v-if="showVideoResolution" class="item" :width="100" name="视频质量调整" :icon="Adjust" @click="parameterButtonClick" />
    <box-config v-if="showVideoResolution && isShowVideoResolutionBox" ref="boxConfigRef" class="box-config" :video-resolution="videoResolution" @select-resolution="selectResolution" />

    <!--    <div class="button-container">-->
    <!--      <span class="local-hint">本地操作</span>-->
    <!--      <operating-button class="item" name="麦克风" :icon="isShareAudio ? MicrophoneOpen : MicrophoneClose" @click="shareAudioButtonClick" />-->
    <!--      <operating-button v-if="showShareCameraVideo" class="item" name="摄像头" :icon="isShareCameraVideo ? CameraOpen :CameraClose" @click="shareVideoCameraButtonClick" />-->
    <!--      <operating-button v-if="showShareScreenVideo" class="item" :width="70" :name="isShareScreenVideo ? '结束共享' : '屏幕共享'" :icon="ScreenShare" :is-show-dot="isShareScreenVideo" @click="shareScreenVideoButtonClick" />-->
    <!--      <operating-button v-if="showRecordingVideo" class="item" :width="70" :name="isRecordingVideo ? '结束录屏' : '会议录屏'" :icon="ScreenRecording" :is-show-dot="isRecordingVideo" @click="recordingVideoClick" />-->
    <!--      <operating-button v-if="showPen" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" name="激光笔" :icon="LaserPen" :is-show-dot="isLaserMoving" @click="laserButtonClick" />-->
    <!--      <operating-button v-if="showPaint" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" :width="70" name="实时画笔" :icon="Paintbrush" :is-show-dot="isPaintDrawing" @click="paintButtonClick" />-->
    <!--      <operating-button v-if="showPaint" :is-disabled="!isHasVideo || isFreezeGuidance" class="item" :width="70" name="清除轨迹" :icon="Clear" @click="clearButtonClick" />-->
    <!--      <operating-button v-if="showGuidance" :is-disabled="!isHasVideo" class="item" :width="70" name="标注指导" :icon="Guidance" :is-show-dot="isFreezeGuidance" @click="guidanceButtonClick" />-->
    <!--      <operating-button class="item" :width="70" name="会议消息" :icon="Message" is-show-num :num="messageCount" @click="messageButtonClick" />-->
    <!--      <operating-button class="item" :width="70" name="参会人员" :icon="Attendee" is-show-num :num="userCount" @click="attendeeButtonClick" />-->
    <!--      <operating-button v-if="showInvite" class="item" :width="70" name="邀请用户" :icon="InvitePerson" @click="inviteButtonClick" />-->
    <!--      <operating-button v-if="showVideoResolution" class="item" :width="100" name="视频质量调整" :icon="Adjust" @click="parameterButtonClick" />-->
    <!--      <box-config v-if="showVideoResolution && isShowVideoResolutionBox" ref="boxConfigRef" class="box-config" :video-resolution="videoResolution" @select-resolution="selectResolution" />-->
    <!--    </div>-->
    <div class="speed-container">
      <div v-if="localBitrate && localBitrate.bitrateSend" class="item">
        {{ localBitrate.bitrateSend }} kbps
        <img class="icon" :src="SendArrows" alt="">
      </div>
      <div v-if="localBitrate && localBitrate.bitrateRecv" class="item">
        {{ localBitrate.bitrateRecv }} kbps
        <img class="icon" :src="ReceiveArrows" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import BoxConfig from './boxConfig'
import OperatingButton from './operatingButton'
import MicrophoneOpen from '@/assets/image/newRemoteGuidance/microphone_open.png'
import MicrophoneClose from '@/assets/image/newRemoteGuidance/microphone_close.png'
import CameraOpen from '@/assets/image/newRemoteGuidance/camera_open.png'
import CameraClose from '@/assets/image/newRemoteGuidance/camera_close.png'
import ScreenShare from '@/assets/image/newRemoteGuidance/screen_share.png'
import ScreenRecording from '@/assets/image/newRemoteGuidance/screen_record.png'
import LaserPen from '@/assets/image/newRemoteGuidance/laser_pen_blue.png'
import Paintbrush from '@/assets/image/newRemoteGuidance/paintbrush.png'
import Clear from '@/assets/image/newRemoteGuidance/clear.png'
import Guidance from '@/assets/image/newRemoteGuidance/guidance.png'
import Message from '@/assets/image/newRemoteGuidance/message.png'
import Adjust from '@/assets/image/newRemoteGuidance/adjust_blue.png'
import Attendee from '@/assets/image/newRemoteGuidance/group.png'
import InvitePerson from '@/assets/image/newRemoteGuidance/invite_person.png'
import SendArrows from '@/assets/image/newRemoteGuidance/video/send_arrows.png'
import ReceiveArrows from '@/assets/image/newRemoteGuidance/video/receive_arrows.png'
export default {
  name: 'MeetingFooter',
  components: {
    OperatingButton,
    BoxConfig
  },
  props: {
    fullscreen: { type: Boolean, default: false },
    isOpenBox: { type: Boolean, default: false },
    isShareAudio: { type: Boolean, default: true },
    isShareCameraVideo: { type: Boolean, default: false },
    isShareScreenVideo: { type: Boolean, default: false },
    isRecordingVideo: { type: Boolean, default: false },
    isLaserMoving: { type: Boolean, default: false },
    isPaintDrawing: { type: Boolean, default: false },
    messageCount: { type: Number, default: 0 },
    userCount: { type: Number, default: 0 },
    showShareCameraVideo: { type: Boolean, default: false },
    showShareScreenVideo: { type: Boolean, default: false },
    showRecordingVideo: { type: Boolean, default: false },
    showPen: { type: Boolean, default: false },
    showPaint: { type: Boolean, default: false },
    showGuidance: { type: Boolean, default: false },
    showInvite: { type: Boolean, default: true },
    showRemoteVideo: { type: Boolean, default: false },
    localBitrate: { type: Object, default: () => {} },
    showVideoResolution: { type: Boolean, default: false },
    videoResolution: { type: String, default: '720P' },
    isFreezeGuidance: { type: Boolean, default: false },
    enLargeVideoInfo: { type: Object, default: () => {} },
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      MicrophoneOpen, MicrophoneClose,
      CameraOpen, CameraClose,
      ScreenShare, ScreenRecording,
      LaserPen, Paintbrush,
      Clear, Guidance, Message,
      Adjust, Attendee, InvitePerson,
      SendArrows, ReceiveArrows,
      footerWidth: '100%',
      isShowVideoResolutionBox: false
    }
  },
  computed: {
    isHasVideo() {
      const isCamOn = this.enLargeVideoInfo && this.enLargeVideoInfo.deviceStatus && this.enLargeVideoInfo.deviceStatus.isCamOn
      const isUvcOn = this.enLargeVideoInfo && this.enLargeVideoInfo.deviceStatus && this.enLargeVideoInfo.deviceStatus.isUvcConnected && this.enLargeVideoInfo.deviceStatus.isUvcCamOn
      return isCamOn || isUvcOn
    }
  },
  watch: {
    isOpenBox: {
      handler() {
        if (this.fullscreen) {
          this.footerWidth = '100%'
        } else {
          this.footerWidth = '890px'
        }
      }
    },
    fullscreen: {
      handler() {
        if (this.fullscreen) {
          this.footerWidth = '100%'
        } else {
          this.footerWidth = '890px'
        }
      }
    }
  },
  methods: {
    getFooterStyle() {
      let width = '100%'
      if (!this.fullscreen) {
        if (this.isOpenBox) {
          width = '1000px'
        } else {
          width = '100%'
        }
      }
      return { width: width }
    },
    shareAudioButtonClick() {
      this.$emit('audio-share-click', !this.isShareAudio)
    },
    shareVideoCameraButtonClick() {
      this.$emit('camera-video-share-click', !this.isShareCameraVideo)
    },
    shareScreenVideoButtonClick() {
      this.$emit('screen-video-share-click', !this.isShareScreenVideo)
    },
    recordingVideoClick() {
      this.$emit('video-recording-click', this.isRecordingVideo)
    },
    laserButtonClick() {
      this.$emit('laser-click')
    },
    paintButtonClick() {
      this.$emit('paint-click')
    },
    clearButtonClick() {
      this.$emit('clear-click')
    },
    guidanceButtonClick() {
      this.$emit('guidance-click')
    },
    messageButtonClick() {
      this.$emit('message-click')
    },
    attendeeButtonClick() {
      this.$emit('attendees-click')
    },
    inviteButtonClick() {
      this.$emit('invite-person-click')
    },
    parameterButtonClick() {
      this.isShowVideoResolutionBox = !this.isShowVideoResolutionBox
    },
    selectResolution(value) {
      this.$emit('select-video-resolution', value)
      this.isShowVideoResolutionBox = false
    }
  }
}
</script>

<style scoped lang="scss">
.footer-container{
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .button-container{
    display: flex;
    align-items: center;
    position: relative;
    .local-hint{
      width: 55px;
      font-size: 12px;
      margin-left: 10px;
      font-weight: bolder;
    }
    //.item{
    //  cursor: pointer;
    //}
    .box-config{
      position: absolute;
      right: -20px;
      bottom: 70px;
    }
  }

  .local-hint{
    width: 55px;
    font-size: 12px;
    margin-left: 10px;
    font-weight: bolder;
  }
  //.item{
  //  cursor: pointer;
  //}
  .box-config{
    position: absolute;
    right: -20px;
    bottom: 70px;
  }

  .speed-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #2E5AFF;
    font-size: 12px;
    //font-weight: bolder;
    margin-right: 10px;
    min-width: 90px;
    text-align: left;
    .item{
      margin: 3px 0;
      display: flex;
      align-items: center;
      height: 26px;
      flex-direction: row-reverse;
      .icon{
        width: 18px;
      }
    }
  }
}
</style>
