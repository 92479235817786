<template>
  <div style="margin-bottom: 20px">
    <equipment-descriptions :equipment-info="equipmentInfo" />

    <el-tabs v-model="activeName" style="margin-top: 20px" @tab-click="getList">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.value" :name="item.name" />
    </el-tabs>

    <ht-table v-if="activeName === 'warehouse'" ref="warehouseList" v-loading="isLoading" :data="warehouseList">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in EquipmentWarehouseStockInfoListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '库存数'">{{ row[item.prop] }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
    <ht-table v-else-if="activeName === 'project'" ref="projectList" v-loading="isLoading" :data="projectList">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in EquipmentProjectStockInfoListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '现存数'">{{ row[item.prop] }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
  </div>
</template>

<script>
import { EquipmentWarehouseStockInfoListTitle, EquipmentProjectStockInfoListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getEquipmentStock } from '@/api/constructionEquipmentMgr/stockMgr'
import EquipmentDescriptions from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentDescriptions'

export default {
  name: 'StockInfo',
  components: { EquipmentDescriptions },
  data() {
    return {
      EquipmentWarehouseStockInfoListTitle, EquipmentProjectStockInfoListTitle,
      activeName: 'warehouse',
      tabs: [
        { name: 'warehouse', value: '仓库库存' },
        { name: 'project', value: '工程现存' }
      ],
      equipmentInfo: null,
      warehouseList: [],
      projectList: [],
      isLoading: false
    }
  },
  methods: {
    initInfo(equipmentInfo) {
      this.equipmentInfo = equipmentInfo
      this.getList()
    },
    getList() {
      this.isLoading = true
      getEquipmentStock(this.equipmentInfo.id).then(resp => {
        this.warehouseList = resp.warehouseStock
        this.projectList = resp.projectStock
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  gap: 20px;
  margin-bottom: 10px;
}
</style>
