var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step-ui-detail-container", style: { height: _vm.height } },
    [
      _c("div", { staticClass: "view-container" }, [
        _c("div", { staticClass: "top-section" }, [
          _c("div", { staticClass: "view-title top-title" }, [
            _vm._v(_vm._s(_vm.results.name || "维修记录")),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "top-button-container" },
            [
              _c(
                "el-button",
                {
                  staticClass: "top-title-button more-function-button",
                  attrs: { type: "text" },
                  on: { click: _vm.moreFunction },
                },
                [_vm._v("更多功能")]
              ),
              _vm._v(" "),
              _vm.showMoreFunction
                ? _c(
                    "div",
                    { staticClass: "more-function-container" },
                    [
                      _c("img", {
                        staticClass: "more-function-img",
                        attrs: { src: _vm.Triangle, alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "top-title-button guidance-button",
                          attrs: { type: "text" },
                          on: { click: _vm.toRemoteGuidance },
                        },
                        [_vm._v("远程指导")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "top-title-button ai-button",
                          attrs: { type: "text" },
                          on: { click: _vm.toAiQaAssistant },
                        },
                        [_vm._v("问答助手")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "middle-section" }, [
          _c("div", { staticClass: "full-line-container" }, [
            _c(
              "div",
              { staticClass: "repair-code content-item" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "repair-select",
                    attrs: { size: "medium", placeholder: "请选择故障代码" },
                    model: {
                      value: _vm.results.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.results, "code", $$v)
                      },
                      expression: "results.code",
                    },
                  },
                  _vm._l(_vm.codes, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      staticClass: "repair-option",
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "repair-suggest content-item" },
              [
                _c("button", { staticClass: "name" }, [_vm._v("维修建议：")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "repair-select",
                    attrs: { size: "medium", placeholder: "请选择维修建议" },
                    model: {
                      value: _vm.results.suggest,
                      callback: function ($$v) {
                        _vm.$set(_vm.results, "suggest", $$v)
                      },
                      expression: "results.suggest",
                    },
                  },
                  _vm._l(_vm.suggests, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      staticClass: "repair-option",
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom-section" }, [
          _c("div", { staticClass: "top-container" }, [
            _c("div", { staticClass: "left-container" }, [
              _c(
                "div",
                { staticClass: "title-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "sub-title-button photo-button",
                      attrs: { type: "text" },
                      on: { click: _vm.nativeTakePhoto },
                    },
                    [
                      _c("span", { staticClass: "required-item" }, [
                        _vm._v("*"),
                      ]),
                      _vm._v("拍摄"),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.submitResults.uploadPhotos &&
                  _vm.submitResults.uploadPhotos.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button del-button",
                          attrs: { type: "text" },
                          on: { click: _vm.delPhoto },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "img-container" },
                [
                  _c(
                    "dw-carousel",
                    {
                      ref: "customCarousel",
                      attrs: {
                        height: "140px",
                        autoplay: false,
                        arrow: "always",
                        "show-icon": false,
                      },
                      on: { change: _vm.setActiveItem },
                    },
                    _vm._l(
                      _vm.submitResults.uploadPhotos,
                      function (item, index) {
                        return _c(
                          "dw-carousel-item",
                          { key: index },
                          [
                            _c("dw-el-image", {
                              ref: "result" + index,
                              refInFor: true,
                              staticClass: "result-img",
                              attrs: {
                                src: item,
                                alt: "",
                                "preview-src-list": _vm.results.uploadPhotos,
                                "show-icon": false,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _vm._v(" "),
                  _vm.submitResults.uploadPhotos &&
                  _vm.submitResults.uploadPhotos.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button fullscreen-button",
                          on: { click: _vm.fullScreenPhoto },
                        },
                        [_vm._v("查看照片")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right-container" },
              [
                _c(
                  "div",
                  { staticClass: "title-container" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button fact-button",
                        attrs: { type: "text" },
                        on: { click: _vm.inputDesc },
                      },
                      [_vm._v("补充维修方法")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button clear-button",
                        attrs: { type: "text" },
                        on: { click: _vm.clearFactDesc },
                      },
                      [_vm._v("清空")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  ref: "rectDescRef",
                  staticClass: "check-standard",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { placeholder: "补充维修方法", type: "textarea" },
                  model: {
                    value: _vm.submitResults.factDesc,
                    callback: function ($$v) {
                      _vm.$set(_vm.submitResults, "factDesc", $$v)
                    },
                    expression: "submitResults.factDesc",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-container" },
            [
              !_vm.isLastStep
                ? _c(
                    "el-button",
                    {
                      staticClass: "save-button bottom-button",
                      attrs: { type: "text" },
                      on: { click: _vm.continueHandle },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          "继续处理（未处理" +
                            (_vm.count - _vm.currentIndex - 1) +
                            "）"
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLastStep
                ? _c(
                    "el-button",
                    {
                      staticClass: "submit-button bottom-button",
                      attrs: { type: "text" },
                      on: { click: _vm.submitRecord },
                    },
                    [_vm._v("全部处理完成")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "name" }, [
      _c("span", { staticClass: "required-item" }, [_vm._v("*")]),
      _vm._v("故障代码："),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }