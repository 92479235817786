import Layout from '@/layout'

const ConstructionProjectMgrRouter = {
  path: '/constructionProjectMgr',
  component: Layout,
  redirect: 'noRedirect',
  name: 'constructionProjectMgrMenu',
  meta: { title: '工程管理', icon: 'project_mgr' },
  children: [
    // 工程清单
    {
      path: '/constructionProjectMgr/projectMgr/list',
      component: () => import('@/views/constructionProjectMgr/projectMgr/list'),
      name: 'projectMgrList',
      meta: { title: '工程清单' }
    },
    {
      path: '/constructionProjectMgr/projectMgr/edit/:projectId/:isEdit',
      component: () => import('@/views/constructionProjectMgr/projectMgr/view'),
      hidden: true,
      props: true,
      name: 'projectMgrEdit',
      meta: { title: '编辑工程', activeMenu: '/constructionProjectMgr/projectMgr/list' }
    },
    {
      path: '/constructionProjectMgr/projectMgr/view/:projectId/:isEdit',
      component: () => import('@/views/constructionProjectMgr/projectMgr/view'),
      hidden: true,
      props: true,
      name: 'projectMgrView',
      meta: { title: '工程详情', activeMenu: '/constructionProjectMgr/projectMgr/list' }
    },
    // 工程查阅
    {
      path: '/constructionProjectMgr/projectConsultMgr/list',
      component: () => import('@/views/constructionProjectMgr/projectConsultMgr/list'),
      name: 'projectConsultMgr',
      meta: { title: '工程查阅' }
    },
    {
      path: '/constructionProjectMgr/projectConsultMgr/view/:projectId/:isEdit',
      component: () => import('@/views/constructionProjectMgr/projectMgr/view'),
      hidden: true,
      props: true,
      name: 'projectConsultView',
      meta: { title: '工程详情', activeMenu: '/constructionProjectMgr/projectConsultMgr/list' }
    }
  ]
}

export default ConstructionProjectMgrRouter
