<template>
  <div class="card-container" :style="{height: height, background: cardColor}">
    <div class="count" :style="{color: countColor}">{{ count }}</div>
    <div class="title" :style="{color: titleColor}">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'SingleStatisticalCard',
  props: {
    height: { type: String, default: '100px' },
    count: { type: [String, Number], default: 0 },
    title: { type: String, default: '标题' },
    cardColor: { type: String, default: '#f8f8f8' },
    countColor: { type: String, default: '#42618f' },
    titleColor: { type: String, default: '#666666' }
  }
}
</script>

<style scoped lang="scss">
.card-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  .title{
    font-size: 14px;
  }
  .count{
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
