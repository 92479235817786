<template>
  <div v-if="resultInfo" class="result-container">
    <el-image :src="resultInfo.coverImg || KnowledgePlaceholder" class="left-img">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline" />
      </div>
    </el-image>
    <div class="right-container">
      <h3 v-if="resultInfo.highlightTitle" class="title" @click="onView" v-html="resultInfo.highlightTitle[0]" />
      <h3 v-else class="title" @click="onView">{{ resultInfo.title }}</h3>
      <template v-if="resultInfo.highlightContent && resultInfo.highlightContent.length > 0">
        <div v-for="(item, index) in resultInfo.highlightContent" :key="index" class="highlight-content" @click="onView(index)">
          <svg-icon icon-class="circle" />
          <span v-html="getHighlightContent(item)" />
        </div>
      </template>
      <div v-else class="content" @click="onView" v-html="getContent(resultInfo.content)" />
      <div class="time">{{ `最新发布时间：${resultInfo.publishedAt}` }}</div>
    </div>
  </div>
</template>

<script>
import KnowledgePlaceholder from '@/assets/image/placeholders/knowledgePlaceholder.jpg'
export default {
  name: 'ResultOverview',
  props: {
    resultInfo: { type: Object, default: null }
  },
  data() {
    return {
      KnowledgePlaceholder
    }
  },
  methods: {
    getHighlightContent(content) {
      return content.replace(/<(?!em|\/em)[^>]*>/g, '')
    },
    getContent(content) {
      return content.toString().replace(/<[^>]*>/g, '')
    },
    onView(index) {
      this.$emit('on-view', index)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.result-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  margin: 2px 2px 20px;
  .left-img{
    width: 150px;
    height: 100px;
    margin: auto 20px auto 10px;
    ::v-deep .image-slot{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #f5f7fa;
      color: #909399;
      font-size: 30px;
    }
  }
  .right-container{
    font-weight: normal;
    font-size: 14px;
    flex: 1;
    .title{
      font-size: 18px;
      line-height: 22px;
      margin: 8px 0;
    }
    .title:hover{
      color: $primaryColor;
      text-decoration: underline;
    }

    .highlight-content{
      height: 25px;
      line-height: 25px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all; /* 防止单词截断 */
    }
    .highlight-content:hover{
      //background: #fcfcb6;
      text-decoration: underline;
      color: $primaryColor;
      .svg-icon{
        color: $primaryColor;
      }
    }

    .content{
      max-height: 75px;
      min-height: 25px;
      line-height: 25px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* 显示3行 */
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all; /* 防止单词截断 */
    }

    .content:hover {
      text-decoration: underline;
      color: $primaryColor;
    }

    .time{
      font-size: 12px;
      margin: 10px 0 10px;
      color: #666666;
    }
  }
}

.result-container:last-child{
  margin-bottom: 0;
}
</style>

<style>
em {
  color: #f56c6c;
}
</style>
