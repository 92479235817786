var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ht-dialog",
    {
      attrs: { visible: _vm.dialogVisible, width: _vm.DIALOG_WIDTH.default },
      on: {
        close: function ($event) {
          _vm.dialogVisible = false
        },
      },
    },
    [
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "left" },
            [_vm._t("title", [_c("span", [_vm._v(_vm._s(_vm.title))])])],
            2
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-popover-button",
                {
                  attrs: {
                    type: "text",
                    icon: "el-icon-refresh",
                    "confirm-title": "请确认是恢复至本次加载时数据？",
                  },
                  on: {
                    confirmed: function ($event) {
                      return _vm.onRecoverRecordToStart()
                    },
                  },
                },
                [_vm._v("恢复至本次加载\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-popover-button",
                {
                  attrs: {
                    type: "text",
                    icon: "el-icon-delete",
                    "confirm-title": "请确认是否清空历史修改记录？",
                  },
                  on: {
                    confirmed: function ($event) {
                      return _vm.apiClearRecords()
                    },
                  },
                },
                [_vm._v("清空\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        { attrs: { data: _vm.records } },
        [
          _c("el-table-column-index"),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "时间", width: "165" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { class: _vm._timeStyle(row) }, [
                      _vm._v(_vm._s(row.time)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.userInfo
            ? _c("el-table-column", {
                attrs: { label: "操作人", width: "75" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            { class: _vm._timeStyle(row) },
                            [
                              _vm._t(
                                "user-info",
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(row.user.fullname)),
                                  ]),
                                ],
                                { userInfo: row.user }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作摘要" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { class: _vm._timeStyle(row) }, [
                      _vm._v(
                        "\n          " + _vm._s(row.digest) + "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { label: "操作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          context: $index,
                          "confirm-title": "请确认要否删除记录?",
                        },
                        on: { confirmed: _vm.apiRemoveRecord },
                      },
                      [_vm._v("删除\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          context: $index,
                          "confirm-title": "该操作将覆盖当前修改！",
                        },
                        on: { confirmed: _vm.onRecoverRecord },
                      },
                      [_vm._v("恢复\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.implementCompare
        ? _c("ht-dialog", {
            ref: "comparePane",
            attrs: { visible: _vm.comparePane.visible },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }