var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px", "min-width": "800px" } },
    [
      _c("div", { staticClass: "info-card" }, [
        _c("div", { staticClass: "left-container" }, [
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "bolder",
                "font-size": "40px",
                "line-height": "90px",
              },
            },
            [_vm._v(_vm._s(_vm.tenantInfo.name))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: {
                color: "grey",
                "font-size": "15px",
                "margin-left": "10px",
              },
            },
            [_vm._v("服务到期时间：" + _vm._s(_vm.tenantInfo.expiredAt))]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "right-container",
            style: {
              cursor: _vm.userInfo.roles.includes("ADMIN")
                ? "pointer"
                : "default",
            },
            on: {
              click: function ($event) {
                return _vm.goToDetailView("userMgrList")
              },
            },
          },
          [
            _c("i", {
              staticClass: "el-icon-user-solid",
              staticStyle: { color: "white" },
            }),
            _vm._v(
              "\n      系统用户数量：" +
                _vm._s(_vm.tenantInfo.staffCount) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.showEquipmentLeaseStatistics
        ? _c("dw-collapse-pane", {
            staticStyle: { "margin-top": "20px" },
            attrs: { "init-collapsed": false },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function (ref) {
                    var activated = ref.activated
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "title-container equipment-statistics-title-container",
                        },
                        [
                          _c("dw-title", {
                            attrs: {
                              title: "设备资产统计",
                              "title-color": "#000000",
                            },
                          }),
                          _vm._v(" "),
                          _vm.showViewEquipmentLeaseButton
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetailView(
                                        "equipmentLeaseRecords"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v("查看详情"),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("equipment-statistics", {
                        ref: "equipmentStatistics",
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1965807756
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showProjectStatistics
        ? _c("dw-collapse-pane", {
            staticStyle: { "margin-top": "20px" },
            attrs: { "init-collapsed": false },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function (ref) {
                    var activated = ref.activated
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "title-container project-statistics-title-container",
                        },
                        [
                          _c("dw-title", {
                            attrs: {
                              title: "工程进展统计",
                              "title-color": "#000000",
                            },
                          }),
                          _vm._v(" "),
                          _vm.showViewProjectSButton
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetailView(
                                        "projectMgrList"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v("查看详情"),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("project-statistics", { ref: "projectStatistics" }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              453033598
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPatrolStatistics
        ? _c("dw-collapse-pane", {
            staticStyle: { "margin-top": "20px" },
            attrs: { "init-collapsed": false },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function (ref) {
                    var activated = ref.activated
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "title-container patrol-statistics-title-container",
                        },
                        [
                          _c("dw-title", {
                            attrs: {
                              title: "巡检统计",
                              "title-color": "#000000",
                            },
                          }),
                          _vm._v(" "),
                          _vm.showViewPatrolButton
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetailView(
                                        "patrolWorkOrderMgr"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v("查看详情"),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("patrol-statistics", { ref: "patrolStatistics" }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1618232288
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRepairStatistics
        ? _c("dw-collapse-pane", {
            staticStyle: { "margin-top": "20px" },
            attrs: { "init-collapsed": false },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function (ref) {
                    var activated = ref.activated
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "title-container repair-statistics-title-container",
                        },
                        [
                          _c("dw-title", {
                            attrs: {
                              title: "维修统计",
                              "title-color": "#000000",
                            },
                          }),
                          _vm._v(" "),
                          _vm.showViewRepairButton
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToDetailView(
                                        "repairWorkOrderMgr"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v("查看详情"),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("repair-statistics", { ref: "repairStatistics" }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1271864594
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }