<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container"
    :style="{height: height + 'px'}"
    :class="{'is-used-step-container': !isEdit}"
    title="hf_use_description"
  >
    <div class="view-container">
      <top-section
        ref="topSection"
        :is-focused-item="isFocusedItem"
        :current-index="currentIndex"
        :count="count"
        :title="properties.title"
        :show-more-function="showMoreFunction"
        :must-upload-photo="properties.mustUploadPhoto"
        :upload-photos="submitResults.uploadPhotos"
        :active-img-index="activeImgIndex"
        :button-disabled="buttonDisabled"
        :show-photos="!showMiddlePhotos"
        @more-function="moreFunction()"
        @native-play-tts="nativePlayTTS(ttsText)"
        @to-remote-guidance="toRemoteGuidance()"
        @to-ai-qa-assistant="toAiQaAssistant()"
        @native-take-photo="toTakePhoto"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @set-active-item="setActiveItem"
        @jump-step="jumpStep"
      />
      <middle-section
        ref="middleSection"
        :is-focused-item="isFocusedItem"
        :active-name="activeName"
        :reference-image="properties.referenceImage"
        :text="properties.text"
        :fact-desc="submitResults.factDesc"
        :button-disabled="buttonDisabled"
        :height="getMiddleHeight"
        :show-photos="showMiddlePhotos"
        :must-upload-photo="properties.mustUploadPhoto"
        :active-img-index="activeImgIndex"
        :upload-photos="submitResults.uploadPhotos"
        @select-reference-image="selectReferenceImage()"
        @select-text="selectText"
        @select-photos="selectPhotos"
        @set-active-item="setActiveItem"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @input-desc="inputText('factDesc')"
        @clear-fact-desc="clearFactDesc"
        @open-reference-image="openReferenceImage"
        @close-reference-viewer="closeReferenceViewer"
      />
      <div class="middle-second-section">
        <div class="middle-left-container">
          <span class="required-item">*</span>
          <span :class="{'focused-item': isFocusedItem === 'dataName'}" class="sub-title result-title result-name">{{ properties.dataName || '数据名称' }}：</span>
          <div class="result-reference-container">
            <span class="third-title">参考范围</span>
            <span :class="{'focused-item': isFocusedItem === 'minValue'}" class="min-value value-item">{{ properties.minValue }}</span>
            一
            <span :class="{'focused-item': isFocusedItem === 'maxValue'}" class="max-value value-item">{{ properties.maxValue }}</span>
            <span :class="{'focused-item': isFocusedItem === 'dataUnit'}" class="sub-title result-title result-unit" style="margin-left: 5px">{{ properties.dataUnit || '单位' }}</span>
          </div>
        </div>
        <div class="middle-right-container">
          <!--          <el-input ref="value" v-model="submitResults.value" :disabled="buttonDisabled" type="number" class="value-input" placeholder="输入数据" />-->
          <!--          <el-button class="input-button square-circle-button" :disabled="buttonDisabled" @click="inputText('value')">数据输入</el-button>-->
          <el-input ref="value" v-model="submitResults.value" disabled type="number" class="value-input" placeholder="输入数据" />
          <el-button class="input-button square-circle-button" :disabled="buttonDisabled" @click="inputText('value')">输入数据</el-button>
        </div>
      </div>
      <bottom-section
        ref="bottomSection"
        :count="count"
        :current-index="currentIndex"
        :is-last-step="isLastStep"
        :button-disabled="buttonDisabled"
        @to-prev="toPrev()"
        @to-next="toNext()"
        @save-record="saveRecord()"
        @submit-record="submitRecord()"
      />
    </div>
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import TopSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/topSection'
import MiddleSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/middleSection'
import BottomSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/bottomSection'

export default {
  name: 'StepUi',
  components: { TopSection, MiddleSection, BottomSection },
  mixins: [StepUiMixin],
  props: {
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          referenceImage: null,
          guidanceVideo: null,
          text: null,
          mustUploadPhoto: null,
          dataName: null,
          minValue: null,
          maxValue: null,
          dataUnit: null
        }
      }
    },
    results: {
      type: Object,
      default() {
        return {
          latitude: null,
          longitude: null,
          location: null,
          uploadPhotos: [],
          factDesc: null,
          value: null
        }
      }
    }
  },
  data() {
    return {
      dictationItem: null
    }
  },
  computed: {
    getMiddleHeight() {
      return this.height - 160
    }
  },
  created() {
    const firstText = `步骤${this.currentIndex + 1}：${this.properties.title}，`
    const secondText = this.properties.guidanceVideo ? `${this.properties.guidanceVideo}，` : ''
    this.ttsText = firstText + secondText
  },
  methods: {
    setDictationResult(result) {
      if (this.dictationItem === 'factDesc') {
        if (this.submitResults.factDesc === null) {
          this.submitResults.factDesc = []
        }
        if (typeof this.submitResults.factDesc === 'string') {
          this.submitResults.factDesc = [this.submitResults.factDesc]
        }
        if (result) {
          this.submitResults.factDesc.push(result)
        }
      } else if (this.dictationItem === 'value') {
        if (isNaN(result)) {
          this.nativePlayTTS('请输入数字')
        } else {
          this.submitResults.value = result
        }
      }
    },
    inputText(value) {
      this.dictationItem = value
      this.nativeStartDictation()
      this.showMoreFunction = false
    },
    inputValue() {
      this.$refs.value.focus()
    },
    openInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = true
    },
    closeInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.step-ui-detail-container{
  .view-container{
    .middle-second-section{
      padding: 0 20px;
      //margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .middle-left-container,.middle-right-container{
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .middle-left-container{
        margin-right: 10px;
      }
      .result-title{
        color: #000000;
      }
      .value-input{
        flex: 1;
        margin-right: 10px;

        ::v-deep  .el-input__inner {
          height: 40px;
          line-height: 40px;
          font-size: 1.2rem;
          color: #000000;
        }
      }

      .result-reference-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.0rem;
        .third-title{
          font-size: 1.3rem;
        }
        .value-item{
          display: inline-block;
          min-width: 50px;
          min-height: 23px;
          text-align: center;
          font-size: 1.3rem;
          font-weight: bold;
        }
      }

      .result-name, .result-unit {
        margin-right: 3px;
      }
    }
    .bottom-section{
      .top-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        .top-left-container,.top-right-container{
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .top-left-container{
          margin-right: 10px;
        }
        .result-title{
          color: #000000;
        }
        .value-input{
          flex: 1;
          margin-right: 10px;

          ::v-deep  .el-input__inner {
            height: 40px;
            line-height: 40px;
            font-size: 1.2rem;
            color: #000000;
          }
        }

        .result-reference-container{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.0rem;
          .third-title{
            font-size: 1.3rem;
          }
          .value-item{
            display: inline-block;
            min-width: 50px;
            min-height: 23px;
            text-align: center;
            font-size: 1.3rem;
            font-weight: bold;
          }
        }

        .result-name, .result-unit {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
