// 异常情况处理（浏览器刷新，返回等）

import im from '@/views/newRemoteGuidance/rongCloud/im'
import rtc from '@/views/newRemoteGuidance/rongCloud/rtc'

export default {
  methods: {
    beforeunloadHandler(event) {
      event.preventDefault()
      event.returnValue = ''
    },
    async unloadHandler(event) {
      if (event.returnValue) {
        await this.abnormalExitRoom()
      }
    },
    async popstateHandler(event) {
      event.preventDefault()
      await this.abnormalExitRoom()
    },
    async abnormalExitRoom() {
      Object.keys(this.invites).forEach(inviteId => {
        this.sendMeetingInviteCancelMsg(inviteId)
        this.clearTimer(inviteId)
      })
      this.unpublishAll({ audio: true, camera: true, screen: true }).then(() => {})
      im.quitChatRoom(this.chatRoomId).then(() => {})
      rtc.leaveRTCRoom(this.room).then(() => {})
      if (this.isShareScreenVideo) { await this.screenVideoShareStop() }
      if (this.isRecording) { await this.screenRecordingFinish() }
      if (this.isPaintDrawing) { this.usePaint() }
      if (this.isLaserMoving) { this.useLaser() }
      this.closeDialog()
    }
  }
}
