<template>
  <div v-loading="isLoading" class="equipment-card">
    <div class="item name">设备名称：{{ equipmentInfo && equipmentInfo.name || '-' }}</div>
    <div v-if="equipmentInfo && equipmentInfo.isWhole" class="item code">产权编号：{{ equipmentInfo && equipmentInfo.code || '-' }}</div>
    <div class="item specModel">规格型号：{{ equipmentInfo && equipmentInfo.specModel || '-' }}</div>
  </div>
</template>

<script>
export default {
  name: 'EquipmentCard',
  props: {
    isLoading: { type: Boolean, default: false },
    equipmentInfo: { type: Object, default: () => { return {} } }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.equipment-card{
  font-size: 1.1rem;
  background: $primaryColorLight;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #666666;
  margin-bottom: 15px;
  .name{
    font-weight: bold;
    font-size: 1.1rem;
  }
}
</style>
