var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("equipment-descriptions", {
        attrs: { "equipment-info": _vm.equipmentInfo },
      }),
      _vm._v(" "),
      _c(
        "el-timeline",
        { staticStyle: { padding: "20px 0 0" } },
        _vm._l(_vm.logisticsTraces, function (item, index) {
          return _c(
            "el-timeline-item",
            {
              key: index,
              attrs: {
                icon: "el-icon-arrow-up",
                type: "primary",
                size: "large",
              },
            },
            [
              _c("div", { staticClass: "trace-item" }, [
                _c(
                  "div",
                  { staticClass: "item top-item" },
                  [
                    _c(
                      "el-tag",
                      {
                        staticClass: "status action",
                        attrs: { type: _vm.StockActionStyleEnum[item.action] },
                      },
                      [_vm._v(_vm._s(_vm.StockActionEnum[item.action]))]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "createdAt" }, [
                      _vm._v(_vm._s(item.createdAt)),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "quantity" }, [
                      _vm._v("数量：" + _vm._s(item.quantity)),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item middle-item" },
                  [
                    item.action === "INBOUND"
                      ? [
                          _c("span", { staticClass: "item position" }, [
                            _vm._v(
                              _vm._s(item.constructionProjectName || "(新设备)")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item action" }, [
                            _vm._v(
                              _vm._s(_vm.StockActionEnum[item.action]) + "至"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item position" }, [
                            _vm._v(_vm._s(item.warehouseName)),
                          ]),
                        ]
                      : item.action === "OUTBOUND"
                      ? [
                          _c("span", { staticClass: "item position" }, [
                            _vm._v(_vm._s(item.warehouseName)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item action" }, [
                            _vm._v(
                              _vm._s(_vm.StockActionEnum[item.action]) + "至"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item position" }, [
                            _vm._v(_vm._s(item.constructionProjectName)),
                          ]),
                        ]
                      : item.action === "TRANSSHIPMENT"
                      ? [
                          _c("span", { staticClass: "item position" }, [
                            _vm._v(_vm._s(item.oldConstructionProjectName)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item action" }, [
                            _vm._v(
                              _vm._s(_vm.StockActionEnum[item.action]) + "至"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item position" }, [
                            _vm._v(_vm._s(item.constructionProjectName)),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item bottom-item" },
                  [
                    item.attachmentData && item.attachmentData.length > 0
                      ? _c("dw-file", {
                          staticClass: "item attachment",
                          attrs: {
                            url: item.attachmentData[0],
                            "show-del": false,
                            "justify-content": "left",
                            "need-margin": false,
                          },
                          on: { "view-file": _vm.downloadFile },
                        })
                      : _c("span", { staticClass: "item attachment" }, [
                          _vm._v("未上传文件"),
                        ]),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }