var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-order-list-container" }, [
    _c(
      "div",
      { staticClass: "top-search-container" },
      [
        _c(
          "el-select",
          {
            staticClass: "item",
            attrs: {
              placeholder: "作业类型",
              "popper-class": _vm.selectPopperClass,
            },
            on: {
              change: function ($event) {
                return _vm.getList(false, true)
              },
            },
            model: {
              value: _vm.listQuery.towerWorkType,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "towerWorkType", $$v)
              },
              expression: "listQuery.towerWorkType",
            },
          },
          _vm._l(_vm.TowerWorkOrderTypeObjectEnum, function (item, index) {
            return _c("el-option", {
              key: index,
              attrs: { label: item.value, value: item.name },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "el-select",
          {
            staticClass: "item",
            attrs: {
              placeholder: "文件上传状态",
              "popper-class": _vm.selectPopperClass,
            },
            on: {
              change: function ($event) {
                return _vm.getList(false, true)
              },
            },
            model: {
              value: _vm.listQuery.fileUploadStatus,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "fileUploadStatus", $$v)
              },
              expression: "listQuery.fileUploadStatus",
            },
          },
          _vm._l(_vm.FileUploadStatusEnum, function (value, key) {
            return _c("el-option", {
              key: key,
              attrs: { label: value, value: key },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "ht-button",
          {
            staticClass: "mobile-button",
            attrs: { type: "info", size: "mini" },
            on: { click: _vm.onClear },
          },
          [_vm._v("清空")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.isListLoading,
            expression: "isListLoading",
          },
        ],
        ref: "cardListContainer",
        staticClass: "card-list-container",
      },
      [
        _vm._l(_vm.list, function (item, index) {
          return _c("work-order-card", {
            key: index,
            attrs: { "order-info": item },
            on: { "on-view": _vm.onView, "on-del": _vm.onDel },
          })
        }),
        _vm._v(" "),
        _vm.list.length === 0
          ? _c("div", { staticClass: "empty-list" }, [
              _c("img", {
                staticClass: "empty-img",
                attrs: { src: _vm.Empty },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "empty-info" }, [_vm._v("还没有数据")]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isLoading && !_vm.isListLoading
          ? _c("div", { staticClass: "loading-spinner" }, [
              _c("img", {
                staticClass: "loading-img",
                attrs: { src: _vm.Loading, alt: "" },
              }),
              _vm._v("数据加载中..."),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.list.length === _vm.page.total &&
        _vm.listQuery.page === _vm.page.totalPages
          ? _c("div", { staticClass: "already-loaded" }, [
              _vm._v("已经到底部了"),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }