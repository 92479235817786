<template>
  <div class="inbound-container">
    <el-form ref="inboundForm" class="mobile-form" :model="inboundForm" :rules="inboundFormRules" label-width="110px">
      <el-form-item label="入库文件：" prop="attachmentData">
        <dw-file
          v-if="inboundForm.attachmentData"
          :url="inboundForm.attachmentData"
          show-del
          justify-content="left"
          style="width: 100%"
          @delete-file="inboundForm.attachmentData = null"
        />
        <ht-upload-button v-else :file-type="['img']" key-prefix="warehouse" button-title="上传入库文件" button-type="primary" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
      <el-form-item label="设备来源：" prop="constructionEquipmentSource">
        <el-radio-group v-model="inboundForm.constructionEquipmentSource" @change="handleEquipmentSourceChange">
          <el-radio v-for="(value, key) in EquipmentSourceEnum" :key="key" :label="key">{{ value }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="inboundForm.constructionEquipmentSource === 'OLD'">
        <el-form-item label="选择工程：" prop="constructionProjectId">
          <el-select
            v-model="inboundForm.constructionProjectId"
            filterable
            :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'"
            style="width: 100%"
            remote
            popper-class="mobile-select"
            @change="handleSelectProject"
          >
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </template>
    </el-form>
    <template>
      <div class="title"><span>入库仓库：</span>{{ warehouseName || '-' }}</div>
      <div class="sub-title">
        <span class="name">已选设备：</span>
        <span class="num">{{ inboundForm.equipmentItems.length }}</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          :disabled="(inboundForm.constructionEquipmentSource === 'OLD' && inboundForm.constructionProjectId === null) || !inboundForm.constructionEquipmentSource"
          @click="openAddEquipmentDrawer"
        >添加设备</el-button>
      </div>
      <template v-if="inboundForm.constructionEquipmentSource === 'OLD'">
        <equipment-inbound-card v-for="(item,index) in inboundForm.equipmentItems" :key="index" :equipment-info="item" is-project is-added @del-equipment="delEquipment" />
      </template>
      <template v-else-if="inboundForm.constructionEquipmentSource === 'NEW'">
        <equipment-inbound-card v-for="(item,index) in inboundForm.equipmentItems" :key="index" :equipment-info="item" is-added @del-equipment="delEquipment" />
      </template>

    </template>
    <div class="bottom-container">
      <el-button round icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button round :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认入库</el-button>
    </div>
    <el-drawer
      :title="`添加设备（${EquipmentSourceEnum[inboundForm.constructionEquipmentSource]}）`"
      :visible.sync="showAddEquipmentList"
      direction="btt"
      class="mobile-drawer"
      :destroy-on-close="true"
      :close="closeAddEquipmentDrawer"
    >
      <add-equipment-list
        v-if="showAddEquipmentList"
        ref="addEquipmentList"
        :already-add-list="inboundForm.equipmentItems"
        style="margin-bottom: 20px"
        @on-add-equipments="closeAddEquipmentDrawer(true, arguments)"
      />
    </el-drawer>
  </div>
</template>

<script>
import { getWarehouseStockProjects, putWarehouseStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import { EquipmentSourceEnum } from '@/views/constructionEquipmentMgr/enum'
import AddEquipmentList from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/addEquipmentList'
import EquipmentInboundCard from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/equipmentInboundCard'

export default {
  name: 'WarehouseInbound',
  components: { AddEquipmentList, EquipmentInboundCard },
  props: {
    warehouseId: { type: [String, Number], default: null },
    warehouseName: { type: String, default: null }
  },
  data() {
    return {
      EquipmentSourceEnum,
      inboundForm: {
        action: 'inbound',
        warehouseId: null,
        constructionEquipmentSource: 'OLD',
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: null
      },
      inboundFormRules: {
        warehouseId: [{ required: true, message: '请选择入货仓库' }],
        constructionEquipmentSource: [{ required: true, message: '请选择设备来源', trigger: 'change' }],
        constructionProjectId: [{ required: true, message: '请选择选择工程', trigger: 'change' }],
        equipmentItems: [{ required: true, message: '请选择入库设备' }]
        // attachmentData: [{ required: true, message: '请上传出库单', trigger: 'change' }]
      },
      isSubmitting: false,
      warehouseInfo: null,
      showAddEquipmentList: false,
      projectList: [],
      isProjectLoading: false
    }
  },
  watch: {
    'inboundForm.equipmentItems': {
      handler: function() {
        this.inboundQuantities = this.inboundForm.equipmentItems.reduce((quantity, current) => {
          return quantity + current.inboundQuantity || 0
        }, 0)
        console.log(this.inboundQuantities)
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getWarehouseStockProjects()
  },
  methods: {
    handleEquipmentSourceChange() {
      this.inboundForm.equipmentItems = []
      this.inboundForm.constructionProjectId = null
    },
    getWarehouseStockProjects() {
      getWarehouseStockProjects().then(resp => {
        this.projectList = resp
        this.isProjectLoading = false
      })
    },
    handleSelectProject(value) {
      this.inboundForm.equipmentItems = []
      this.inboundForm.constructionProjectId = value
    },
    openAddEquipmentDrawer() {
      this.showAddEquipmentList = true
      this.$nextTick(() => {
        this.$refs.addEquipmentList.getListEquipmentList(this.inboundForm.constructionEquipmentSource === 'OLD', this.inboundForm.constructionProjectId)
      })
    },
    closeAddEquipmentDrawer(isAdd = false, $arguments) {
      console.log(isAdd)
      if (isAdd) {
        this.inboundForm.equipmentItems = $arguments[0]
        if (this.inboundForm.equipmentItems && this.inboundForm.equipmentItems.length > 0) {
          this.$refs.inboundForm.clearValidate('equipmentItems')
        }
      }
      this.showAddEquipmentList = false
    },
    delEquipment(equipmentId) {
      const index = this.inboundForm.equipmentItems.findIndex(item => item.id === equipmentId)
      if (index !== -1) {
        this.inboundForm.equipmentItems.splice(index, 1)
      }
    },
    onFileUploaded(context, url) {
      this.inboundForm.attachmentData = url
      if (this.inboundForm.attachmentData) {
        this.$refs.inboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    confirmSubmit() {
      const inboundForm = this.getInboundForm()
      this.$refs.inboundForm.validate(valid => {
        if (valid) {
          if (inboundForm.equipmentItems.every(item => item.quantity > 0)) {
            this.isSubmitting = true
            putWarehouseStocks(inboundForm).then(() => {
              this.$message({ message: `入库成功`, type: 'success', center: true })
              this.$router.go(-1)
              this.isSubmitting = false
            }).catch(() => { this.isSubmitting = false })
          } else {
            this.$message({ message: `请确认所选设备均已填写入库数量`, type: 'error', center: true })
            return false
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getInboundForm() {
      const data = {
        action: 'inbound',
        warehouseId: null,
        constructionProjectId: null,
        equipmentItems: [],
        attachmentData: []
      }
      data.warehouseId = this.warehouseId
      data.constructionProjectId = this.inboundForm.constructionEquipmentSource === 'OLD' ? this.inboundForm.constructionProjectId : null
      data.equipmentItems = this.inboundForm.equipmentItems.map(item => {
        return {
          constructionEquipmentId: item.id,
          constructionEquipmentName: item.name,
          constructionEquipmentCode: item.code,
          quantity: item.inboundQuantity || 0,
          isWhole: item.isWhole
        }
      })
      data.attachmentData = this.inboundForm.attachmentData ? [this.inboundForm.attachmentData] : []
      return data
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';
.inbound-container {
  margin: 15px 15px 100px;
  font-size: 1rem;
  .title{
    font-size: 1.2rem;
    font-weight: bold;
    color: $primaryColor;
    margin: 20px 0;
    display: flex;
    align-items: center;
    span{
      width: 110px;
      padding-right: 12px;
      text-align: right;
    }
  }
  .sub-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: bold;
    color: #666666;
    margin-bottom: 20px;
    .name{
      width: 110px;
      padding-right: 12px;
      text-align: right;
    }
    .num{
      color: $primaryColor;
      flex: 1;
    }
  }

  ::v-deep .el-button{
    font-size: 1rem;
  }

  .equipment-card{
    color: #666666;
    font-size: 1rem;
    background: #ffffff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    margin-bottom: 10px;
    .top-item{
      font-size: 1.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
      font-weight: bold;
      color: #000000;
    }
    .bottom-item{
      display: flex;
      flex-direction: row;
      .bottom-left-item{
        flex: 1;
        justify-content: flex-end;
      }
      .bottom-right-item {
        align-items: center;
      }
    }
    .bottom-item>div{
      display: flex;
      flex-direction: column;
      .top{
        margin-bottom: 10px;
      }
    }
  }

  .equipment-card>div{
    margin-bottom: 10px;
  }
  .equipment-card>div:last-child{
    margin-bottom: 0;
  }
}

.mobile-drawer.el-drawer__wrapper{
  ::v-deep .el-drawer__body{
    padding-bottom: 30px !important;
  }
}

.mobile-form{
  ::v-deep .el-form-item{
    margin-bottom: 10px;
    .el-form-item__label{
      text-align: right !important;
    }
  }
}
</style>
