<template>
  <el-card style="margin: 20px;">
    <dw-title title="个人中心" />
    <div class="user-profile-container">
      <div class="user-avatarUrl">
        <img v-if="user.avatarUrl" class="img-avatarUrl" src="" alt="">
        <dw-default-avatar v-else :full-name="user.fullName" />
      </div>
      <el-divider direction="vertical" />
      <el-form ref="form" class="form" label-width="80px">
        <el-form-item style="font-size: 16px" label="姓名:">{{ user.fullName }}</el-form-item>
        <el-form-item label="用户名:">{{ user.userName }}</el-form-item>
        <!--        <el-form-item label="角色:">{{ user.roles.toString() }}</el-form-item>-->
        <el-form-item label="角色:">{{ user.roles[0] }}</el-form-item>
      </el-form>
      <el-divider direction="vertical" />
      <el-form ref="form" class="qrcodeFrom">
        <el-form-item class="qr-form-item" label="二维码:">
          <el-popover
            placement="right"
            width="230"
            height="400"
            trigger="hover"
            style="margin-right: 20px"
          >
            <span>
              <vue-qr ref="qrCode" :text="user.qrCode" :size="200" />
            </span>
            <el-link slot="reference" style="width:150px;margin: 0 auto"><vue-qr ref="qrCode" :text="user.qrCode" :size="150" /></el-link>
          </el-popover>
          <ht-button type="primary" icon="el-icon-printer" @click="printQRCode()">打印二维码</ht-button>
        </el-form-item>
      </el-form>
    </div>

    <ht-print ref="printQRCode">
      <div>
        <img :src="qRCodeImg" width="400px" height="400px">
      </div>
    </ht-print>

  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  data() {
    return {
      user: {},
      qRCodeImg: ''
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'userInfo'
    ])
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.user = {
        fullName: this.userInfo.fullName,
        userName: this.userInfo.account.userName,
        mobile: this.userInfo.mobile,
        roles: this.userInfo.roleNames,
        avatarUrl: this.userInfo.avatarUrl,
        qrCode: this.userInfo.qrCode
      }
    },
    printQRCode() {
      this.qRCodeImg = this.$refs.qrCode.$el.src
      this.$nextTick(() => {
        this.$refs.printQRCode.print()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-profile-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 10px 10px;
  .user-avatarUrl{
    width: 180px;
    .img-avatarUrl{
      width: 150px;
      height: 150px;
      border-radius: 150px;
    }
  }
  .el-divider--vertical{
    height: 150px;
  }
  .el-form {
    ::v-deep .el-form-item{
      .el-form-item__label{
        font-size: 16px;
      }
      .el-form-item__content{
        font-size: 16px;
      }
    }
  }
}
.form{
  margin-right: 20px;
}
.qrcodeFrom{
  .el-form-item{
    margin-bottom: 0;
  }
  .qr-form-item{
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

}
</style>
