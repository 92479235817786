// 会议邀请相关
import { copyUrl } from '@/utils'

export default {
  methods: {
    inviteTimerFun(attendee) {
      const timer = setTimeout(() => {
        this.sendMeetingInviteNoResponseMsg(attendee)
        this.onInviteTimeout(attendee && attendee.imUserId)
      }, 59 * 1000)
      const inviteId = attendee && attendee.imUserId
      this.invites[inviteId] = timer
      const user = {
        imUserId: attendee && attendee.imUserId,
        fullName: attendee && attendee.fullName,
        waitingTime: 59
      }
      setInterval(() => {
        user.waitingTime -= 1
      }, 1000)
      this.inviteList.push(user)
    },
    acceptInviteFun(inviteIds) {
      inviteIds.forEach(item => {
        this.clearTimer(item)
        this.deleteItemInInviteList(item)
      })
    },
    rejectInviteFun(inviteId) {
      this.clearTimer(inviteId)
      this.deleteItemInInviteList(inviteId)
    },
    onInviteTimeout(inviteId) {
      delete this.invites[inviteId]
      this.deleteItemInInviteList(inviteId)
    },
    clearTimer(inviteId) {
      clearTimeout(this.invites[inviteId])
      delete this.invites[inviteId]
    },
    clearAllTimer() {
      Object.keys(this.invites).forEach(key => {
        clearTimeout(this.invites[key])
        delete this.invites[key]
      })
    },
    inviteCancel(inviteId) {
      this.sendMeetingInviteCancelMsg(inviteId)
      this.clearTimer(inviteId)
      this.deleteItemInInviteList(inviteId)
      if (this.showOnlineUserBox && this.$refs.boxOnlineUserRef) {
        this.$refs.boxOnlineUserRef.refreshUserList()
      }
    },
    deleteItemInInviteList(inviteId) {
      const index = this.inviteList.findIndex(item => item.imUserId === inviteId)
      if (index !== -1) {
        this.inviteList.splice(index, 1)
      }
    },
    copyMeetingUrl() {
      const origin = window.location.origin
      const path = 'newRemoteGuidance/temporaryCall/'
      const meetingId = this.meetingInfo.meetingId
      const chatRoomId = this.chatRoomId
      const senderId = this.userImInfo.imUserId
      const url = `${origin}/${path}${meetingId}/${chatRoomId}/${senderId}`
      copyUrl(url)
      if (this.$refs.boxOnlineUserRef) {
        this.$refs.boxOnlineUserRef.isCopiedUrl = true
        setTimeout(() => {
          this.$refs.boxOnlineUserRef.isCopiedUrl = false
        }, 3000)
      }
    }
  }
}
