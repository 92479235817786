<template>
  <div>
    <el-card>
      <ht-action-panel :on-search="onSearch" :on-clear="onClear">
        <template slot="left">
          <el-input v-model="listQuery.fullName" placeholder="姓名" />
          <el-select v-model="listQuery.roles" placeholder="角色">
            <el-option v-for="item in roleList" :key="item.tag" :label="item.name" :value="item.tag" />
          </el-select>
        </template>
      </ht-action-panel>
      <ht-action-panel style="margin-top: 10px">
        <template slot="left">
          <span class="online-count-container">用户数量：<span class="count">{{ count }}</span></span>
        </template>
        <template slot="right">
          <ht-button type="primary" icon="el-icon-phone" @click="createQuickMeeting">快速会议</ht-button>
          <ht-button type="primary" icon="el-icon-phone" @click="createMultiPersonMeeting">多人会议</ht-button>
        </template>
      </ht-action-panel>
      <div v-loading="isLoading" class="list-container">
        <contact-list ref="contactListRef" :user-list="list" @call="createSinglePersonMeeting" />
      </div>
    </el-card>
    <meeting-dialog v-if="showMeetingDialog" ref="meetingDialogRef" @close-dialog="closeDialog" />
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { ContactsLabelToProps, MeetingErrorMsg, UserTypeEnum } from '../var/businessVar'
import { getContactsApi, postNewMeeting, putKickImUser } from '@/api/newRemoteGuidance/newRemoteGuidance'
import ContactList from '../components/contactList'
import MeetingDialog from '../components/meeting'
import { mapGetters } from 'vuex'
import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'
import { getRolesApi } from '@/api/systemMgr/roleMgr'

export default {
  name: 'Index',
  components: {
    ContactList,
    MeetingDialog
  },
  extends: HtListContainer,
  data() {
    return {
      ContactsLabelToProps,
      UserTypeEnum,
      roleList: [],
      count: 0,
      inviteTimeout: null,
      attendee: null,
      attendees: [],
      showMeetingDialog: false
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'userImInfo', 'isSinoma'])
  },
  created() {
    this.getRoleList()
    this.getList()
    this.addEventListener()
  },
  beforeDestroy() {
    this.removeEventListener()
  },
  methods: {
    getRoleList() {
      const query = {
        offset: 0,
        count: 100
      }
      getRolesApi(query).then((resp) => {
        this.roleList = resp
      }).catch(() => {
        this.roleLis = []
      })
    },
    getList(e) {
      if (!e) {
        this.isLoading = true
      }
      this.listQuery.page = null
      this.listQuery.perPage = null
      this.listQuery.offset = 0
      this.listQuery.count = 1000
      this.listQuery.loginPath = 'PC'
      getContactsApi(this.listQuery).then(resp => {
        resp.forEach(item => {
          item.checked = false
        })
        this.list = resp
        this.count = resp.length
        this.isLoading = false
      }).catch(err => { this.afterGetList(null, err) })
    },
    addEventListener() {
      document.addEventListener(EventsEnum.contactsRefreshEvent, this.getList)
      document.addEventListener(EventsEnum.meetingRefreshEvent, this.getList)
    },
    removeEventListener() {
      document.removeEventListener(EventsEnum.contactsRefreshEvent, this.getList)
      document.removeEventListener(EventsEnum.meetingRefreshEvent, this.getList)
    },
    createSinglePersonMeeting(attendee) {
      this.showMeetingDialog = true
      this.$nextTick(() => {
        this.attendee = attendee
        this.$refs.meetingDialogRef.openDialog(this.userInfo, this.userImInfo)
        // 获取会议号
        postNewMeeting().then(res => {
          const roomId = res.roomId
          const meetingCreatorInfo = {
            meetingId: res.roomId,
            meetingCreator: res.staff.userName,
            meetingCreatorName: res.staff.fullName,
            meetingCreatorAvatar: String(res.staff.avatarUrl)
          }
          this.$refs.meetingDialogRef.isCalling = true
          // 加入会议
          this.$refs.meetingDialogRef.joinRoom(roomId, res.chatRoomId, true, meetingCreatorInfo).then(() => {
            this.$refs.meetingDialogRef.sendMeetingInviteMsg(attendee, true)
          }).catch(async(firstError) => {
            if (firstError.reason === '当前环境无法开启会议') {
              // 环境问题无法加入会议,不是https,针对发起会议的环境是不是https
              await this.joinFailFun(MeetingErrorMsg.joinRoomPermissionError, false)
            } else if (firstError && firstError.error.code && firstError.error.code === 53010) {
              //  53010获取音视频失败（主要针对嵌入会议的环境是http，发起会议的环境是https,可以加入会议但是获取不到音视频退会问题）
              await this.joinFailFun(MeetingErrorMsg.getMediaPermissionError)
            } else {
              putKickImUser(this.userImInfo.imUserId).then(() => {
                this.$refs.meetingDialogRef.joinRoom(roomId, res.chatRoomId, true, meetingCreatorInfo).then(() => {
                  this.$refs.meetingDialogRef.sendMeetingInviteMsg(attendee, true)
                }).catch(async(secondError) => {
                  if (secondError.reason === '加入聊天室失败') {
                    await this.joinFailFun(MeetingErrorMsg.joinChatRoomError)
                  } else {
                    await this.joinFailFun(MeetingErrorMsg.createRoomError)
                  }
                })
              }).catch(async() => {
                putKickImUser(this.userImInfo.imUserId)
                this.$message({ message: MeetingErrorMsg.createRoomError, center: true, type: 'error' })
                this.$refs.meetingDialogRef.closeDialog()
              })
            }
          })
        }).catch(() => {
          this.$message({ message: MeetingErrorMsg.createRoomError, center: true, type: 'error' })
          this.$refs.meetingDialogRef.closeDialog()
        })
      })
    },
    createMultiPersonMeeting() {
      const attendeeList = []
      this.list.forEach(item => {
        if (item.checked) {
          attendeeList.push(item)
        }
      })
      if (attendeeList.length > 0) {
        this.showMeetingDialog = true
        this.$nextTick(() => {
          this.attendees = attendeeList
          this.$refs.meetingDialogRef.openDialog(this.userInfo, this.userImInfo)
          postNewMeeting().then(res => {
            const roomId = res.roomId
            const meetingInfo = {
              meetingId: res.roomId,
              meetingCreator: res.staff.userName,
              meetingCreatorName: res.staff.fullName,
              meetingCreatorAvatar: String(res.staff.avatarUrl)
            }
            this.$refs.meetingDialogRef.isCalling = true
            this.$refs.meetingDialogRef.joinRoom(roomId, res.chatRoomId, true, meetingInfo).then(() => {
              attendeeList.forEach(attendee => {
                this.$refs.meetingDialogRef.sendMeetingInviteMsg(attendee, false)
              })
            }).catch(async(firstError) => {
              if (firstError.reason === '当前环境无法开启会议') {
                await this.joinFailFun(MeetingErrorMsg.joinRoomPermissionError, false)
              } else if (firstError && firstError.error.code && firstError.error.code === 53010) {
                await this.joinFailFun(MeetingErrorMsg.getMediaPermissionError)
              } else {
                putKickImUser(this.userImInfo.imUserId).then(() => {
                  this.$refs.meetingDialogRef.joinRoom(roomId, res.chatRoomId, true, meetingInfo).then(() => {
                    attendeeList.forEach(attendee => {
                      this.$refs.meetingDialogRef.sendMeetingInviteMsg(attendee, false)
                    })
                  }).catch(async(secondError) => {
                    if (secondError.reason === '加入聊天室失败') {
                      await this.joinFailFun(MeetingErrorMsg.joinChatRoomError)
                    } else {
                      await this.joinFailFun(MeetingErrorMsg.createRoomError)
                    }
                  })
                }).catch(() => {
                  this.$message({ message: MeetingErrorMsg.createRoomError, center: true, type: 'error' })
                  this.$refs.meetingDialogRef.closeDialog()
                })
              }
            })
          }).catch(() => {
            this.$message({ message: MeetingErrorMsg.createRoomError, center: true, type: 'error' })
            this.$refs.meetingDialogRef.closeDialog()
          })
        })
      } else {
        this.$message.error('请选择参会人员')
      }
    },
    createQuickMeeting() {
      this.showMeetingDialog = true
      this.$nextTick(() => {
        this.$refs.meetingDialogRef.openDialog(this.userInfo, this.userImInfo)
        postNewMeeting().then(res => {
          const roomId = res.roomId
          const meetingCreatorInfo = {
            meetingId: res.roomId,
            meetingCreator: res.staff.userName,
            meetingCreatorName: res.staff.fullName,
            meetingCreatorAvatar: String(res.staff.avatarUrl)
          }
          // 加入会议
          this.$refs.meetingDialogRef.joinRoom(roomId, res.chatRoomId, true, meetingCreatorInfo)
            .then(() => {})
            .catch(async(firstError) => {
              if (firstError.reason === '当前环境无法开启会议') {
              // 环境问题无法加入会议,不是https,针对发起会议的环境是不是https
                await this.joinFailFun(MeetingErrorMsg.joinRoomPermissionError, false)
              } else if (firstError && firstError.error.code && firstError.error.code === 53010) {
              //  53010获取音视频失败（主要针对嵌入会议的环境是http，发起会议的环境是https,可以加入会议但是获取不到音视频退会问题）
                await this.joinFailFun(MeetingErrorMsg.getMediaPermissionError)
              } else {
                putKickImUser(this.userImInfo.imUserId).then(() => {
                  this.$refs.meetingDialogRef.joinRoom(roomId, res.chatRoomId, true, meetingCreatorInfo)
                    .then(() => {
                    }).catch(async(secondError) => {
                      if (secondError.reason === '加入聊天室失败') {
                        await this.joinFailFun(MeetingErrorMsg.joinChatRoomError)
                      } else {
                        await this.joinFailFun(MeetingErrorMsg.createRoomError)
                      }
                    })
                }).catch(async() => {
                  putKickImUser(this.userImInfo.imUserId)
                  this.$message({ message: MeetingErrorMsg.createRoomError, center: true, type: 'error' })
                  this.$refs.meetingDialogRef.closeDialog()
                })
              }
            })
        }).catch(() => {
          this.$message({ message: MeetingErrorMsg.createRoomError, center: true, type: 'error' })
          this.$refs.meetingDialogRef.closeDialog()
        })
      })
    },
    async joinFailFun(errorMsg, isJoined = true) {
      this.$message({ message: errorMsg, center: true, type: 'error' })
      setTimeout(async() => {
        if (isJoined) {
          putKickImUser(this.userImInfo.imUserId)
        }
        await this.$refs.meetingDialogRef.closeDialog()
      }, 1000)
    },
    closeDialog() {
      this.showMeetingDialog = false
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.online-count-container{
  font-size: 20px;
  .count{
    color: $primaryColor;
  }
}

.list-container{
  min-height: 470px;
  overflow: auto;
}
</style>
