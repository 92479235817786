<template>
  <div>
    <div class="title-bar">
      <el-button type="primary" class="back-button" icon="el-icon-back" @click="onBack">返回</el-button>
      <user-info class="user-info" :app-id="appId" :outer-id="outerId" :user-name="userName" />
    </div>
    <detail :room-id="roomId" />
  </div>
</template>

<script>
import UserInfo from '../sinoma/userInfo'
import Detail from '@/views/newRemoteGuidance/meetingRecords/view'

export default {
  name: 'MeetingRecord',
  components: { UserInfo, Detail },
  props: {
    roomId: { type: [Number, String], default: undefined },
    appId: { type: [Number, String], default: undefined },
    outerId: { type: [Number, String], default: undefined },
    userName: { type: String, default: undefined }
  },
  methods: {
    onBack() {
      this.$router.push({
        name: 'externalCallCommonList',
        params: {
          appId: this.appId,
          outerId: this.outerId,
          userName: this.userName
        },
        query: {
          from: this.$route.name
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.title-bar{
  height: 49px;
  line-height: 49px;
  background: #f5f7fa;
  border-bottom: 1px solid #dfe4ed;
  position: relative;
  .back-button{
    margin-left: 20px;
  }
  .user-info{
    top: 0;
  }
}
</style>
