var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "title-container" },
        [
          _c("div", { staticClass: "left-title" }, [_vm._v("文档分类")]),
          _vm._v(" "),
          _c(
            "ht-action-panel",
            {
              staticClass: "right-search",
              attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear },
            },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c("span", { staticClass: "current-tree-node" }, [
                    _vm._v("当前目录：" + _vm._s(_vm.getCurrentTreeNode)),
                  ]),
                  _vm._v(" "),
                  _c("search-history-input", {
                    ref: "searchHistoryInput",
                    attrs: { "history-list": _vm.searchHistoryList },
                    on: {
                      change: _vm.onHistorySearch,
                      "on-del": _vm.onDelSearchHistory,
                    },
                    model: {
                      value: _vm.listQuery.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "keyword", $$v)
                      },
                      expression: "listQuery.keyword",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content-container" }, [
        _c(
          "div",
          { staticClass: "left-category-container" },
          [
            _c("folder-tree", {
              ref: "categoryTree",
              staticClass: "left-category-tree",
              attrs: {
                loading: _vm.isTreeLoading,
                "tree-list": _vm.getTree,
                "has-function-box": false,
                "tree-width": 198,
                "is-disabled": _vm.isLoading,
              },
              on: { "node-click": _vm.handleClickTreeNode },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
            ref: "rightKnowledgeContainer",
            staticClass: "right-knowledge-container",
          },
          [
            _vm.list && _vm.list.length > 0
              ? _c(
                  "div",
                  { staticClass: "results-container" },
                  _vm._l(_vm.list, function (item, index) {
                    return _c("result-overview", {
                      key: index,
                      attrs: { "result-info": item },
                      on: {
                        "on-view": function ($event) {
                          return _vm.onViewKnowledge(index, arguments)
                        },
                      },
                    })
                  }),
                  1
                )
              : _c("div", { staticClass: "empty-list" }, [
                  _c("img", {
                    staticClass: "empty-img",
                    attrs: { src: _vm.Empty },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "empty-info" }, [
                    _vm._v("暂无结果"),
                  ]),
                ]),
            _vm._v(" "),
            _vm.isLoading && !_vm.isListLoading
              ? _c("div", { staticClass: "loading-spinner" }, [
                  _c("img", {
                    staticClass: "loading-img",
                    attrs: { src: _vm.Loading, alt: "" },
                  }),
                  _vm._v("数据加载中..."),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isEnd && _vm.list.length > 0
              ? _c("div", { staticClass: "already-loaded" }, [
                  _vm._v("已经到底部了"),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "knowledgeViewDialog",
          staticClass: "knowledge-view-dialog",
          attrs: {
            title: "文档详情",
            width: "1000px",
            fullscreen: _vm.isFullscreenKnowledgeViewDialog,
          },
          on: { close: _vm.closeKnowledgeViewDialog },
        },
        [
          _c("div", { attrs: { slot: "close" }, slot: "close" }, [
            _vm.isFullscreenKnowledgeViewDialog
              ? _c("img", {
                  staticClass: "maximize fullscreen-item",
                  attrs: { src: _vm.ExitFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeViewDialog },
                })
              : _c("img", {
                  staticClass: "maximize fullscreen-item",
                  attrs: { src: _vm.EnterFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgeViewDialog },
                }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showKnowledgeView
                ? _c("knowledge-view", {
                    ref: "knowledgeView",
                    attrs: { list: _vm.list, keyword: _vm.listQuery.keyword },
                    on: { "on-close": _vm.closeKnowledgeViewDialog },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }