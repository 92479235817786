import request from '@/utils/request'
const tack_template = 'task/template/'
const tack_templates = 'task/templates/'

// 获取工作流程列表
export function getTaskTemplates(listQuery) {
  return request({ url: `${tack_templates}`, method: 'GET', params: listQuery })
}

// 创建工作流程
export function postTaskTemplates(data) {
  return request({ url: `${tack_templates}`, method: 'POST', data: data })
}

// 获取工作流程详情
export function getTaskTemplate(templateId, listQuery) {
  return request({ url: `${tack_template}${templateId}`, method: 'GET', params: listQuery })
}

// 修改工作流程
export function putTaskTemplate(templateId, data) {
  return request({ url: `${tack_template}${templateId}`, method: 'PUT', data: data })
}

// 删除工作流程
export function deleteTaskTemplate(templateId) {
  return request({ url: `${tack_template}${templateId}`, method: 'DELETE' })
}

// 检测工作流程是否被循环工单使用
export function getIsLoopedInOrder(templateId) {
  return request({ url: `${tack_template}${templateId}/check_is_looped`, method: 'GET' })
}
