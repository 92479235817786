<script>
import Triangle from '@/assets/image/workflow/triangle.png'
import AndroidWebViewMixin from '@/views/components/androidWebViewMixin.vue'
import { isVideoOrImage } from '@/utils/business'

export default {
  name: 'StepUiMixin',
  mixins: [AndroidWebViewMixin],
  props: {
    height: { type: Number, default: 480 },
    isEdit: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    currentIndex: { type: Number, default: 0 },
    isFirstStep: { type: Boolean, default: false },
    isLastStep: { type: Boolean, default: true },
    isFocusedItem: { type: String, default: null },
    properties: { type: Object, default() { return {} } },
    results: { type: Object, default() { return {} } },
    windowHeight: { type: Number, default: 480 }
  },
  data() {
    return {
      Triangle,
      activeImgIndex: 0,
      submitResults: this.results,
      showMoreFunction: false,
      activeName: 'referenceImage',
      ttsText: undefined,
      buttonDisabled: false,
      showScan: false,
      showMiddlePhotos: true,
      showContainer: true
    }
  },
  computed: {
    getMiddleHeight() {
      return this.height - 120
    }
  },
  watch: {
    isFocusedItem(value) {
      if (value === 'text') {
        this.activeName = 'text'
      } else if (value === 'referenceImage') {
        this.activeName = 'referenceImage'
      }
    }
  },
  created() {
    this.reload()
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isEdit) {
        this.showMoreFunction = false
        this.registerAndroidCallbackHandlers({
          locationHandler: this.setLocation,
          photoUrlHandler: this.setPhotoUrl,
          dictationResultHandler: this.setDictationResult,
          scanCodeResultHandler: this.setScanCodeResult,
          ocrResultHandler: this.setOcrResult
        })
        this.nativeGetLocation()
      }
    })
  },
  methods: {
    isVideoOrImage,
    setLocation(latitude, longitude, location) {
      // this.$message({ message: `${latitude};${longitude};${location}`, duration: 2000, type: 'error', customClass: 'large-info-message' })
      this.submitResults.latitude = latitude
      this.submitResults.longitude = longitude
      this.submitResults.location = location
    },
    setPhotoUrl(photoUrl) {
      this.submitResults.uploadPhotos.push(photoUrl)
      this.setActiveItem(this.submitResults.uploadPhotos.length - 1)
      this.saveRecord()
      if (this.showMiddlePhotos) {
        this.selectPhotos()
      }
    },
    setDictationResult(result) {
      if (this.submitResults.factDesc === null) {
        this.submitResults.factDesc = []
      }
      if (typeof this.submitResults.factDesc === 'string') {
        this.submitResults.factDesc = [this.submitResults.factDesc]
      }
      if (result) {
        this.submitResults.factDesc.push(result)
      }
    },
    setScanCodeResult() {},
    setOcrResult() {},
    selectReferenceImage() {
      this.activeName = 'referenceImage'
      if (this.properties.referenceImage) {
        this.openReferenceImage()
      }
      this.showMoreFunction = false
    },
    selectText() {
      this.activeName = 'text'
      this.nativePlayTTS(this.properties.guidanceVideo)
      this.showMoreFunction = false
    },
    selectPhotos() {
      this.activeName = 'photos'
      this.showMoreFunction = false
    },
    openReferenceImage() {
      this.$refs.middleSection.openReferenceImage()
      if (isVideoOrImage(this.properties.referenceImage) !== 'video') {
        this.buttonDisabled = true
      }
      this.showMoreFunction = false
    },
    openReferenceVideo() {
      this.$refs.middleSection.openReferenceVideo()
      this.showMoreFunction = false
    },
    closeReferenceViewer() {
      this.buttonDisabled = false
      this.showMoreFunction = false
    },
    fullScreenPhoto() {
      this.$refs.middleSection.openPhoto()
    },
    setActiveItem(index) {
      this.activeImgIndex = index
      this.showMoreFunction = false
    },
    openPhoto() {
      if (this.showMiddlePhotos) {
        this.$refs.middleSection.openPhoto(this.activeImgIndex)
      } else {
        this.$refs.topSection.openPhoto()
      }
      this.buttonDisabled = true
      this.showMoreFunction = false
    },
    closePhoto() {
      this.buttonDisabled = false
      this.showMoreFunction = false
    },
    delPhoto(index) {
      this.activeImgIndex = index
      this.submitResults.uploadPhotos.splice(index, 1)

      if (this.submitResults.uploadPhotos.length === 0) {
        this.activeImgIndex = 0
        this.closePhotoViewer()
      } else if (this.activeImgIndex === this.submitResults.uploadPhotos.length) {
        this.activeImgIndex = this.activeImgIndex - 1
      }
      if (!this.showMiddlePhotos) {
        this.$refs.topSection.isAlreadyDel(this.activeImgIndex)
      } else {
        this.$refs.middleSection.setActiveItem(this.activeImgIndex)
      }
      this.showMoreFunction = false
      this.saveRecord()
    },
    prevPhoto(index) {
      this.activeImgIndex = index
      if (!this.showMiddlePhotos) {
        this.$refs.topSection.isAlreadyDel(this.activeImgIndex)
      } else {
        this.$refs.middleSection.setActiveItem(this.activeImgIndex)
      }
    },
    nextPhoto(index) {
      this.activeImgIndex = index
      if (!this.showMiddlePhotos) {
        this.$refs.topSection.isAlreadyDel(this.activeImgIndex)
      } else {
        this.$refs.middleSection.setActiveItem(this.activeImgIndex)
      }
    },
    closePhotoViewer() {
      this.buttonDisabled = false
      this.showMoreFunction = false
    },
    inputDesc() {
      this.nativeStartDictation()
      this.showMoreFunction = false
    },
    clearFactDesc() {
      this.submitResults.factDesc = []
      this.showMoreFunction = false
    },
    toPrev() {
      this.$emit('to-prev', this.submitResults)
      this.showMoreFunction = false
    },
    toNext() {
      this.$emit('to-next', this.submitResults)
      this.showMoreFunction = false
    },
    moreFunction() {
      this.showMoreFunction = !this.showMoreFunction
    },
    toNativeScanCode() {
      this.$emit('to-native-scan-code', this.submitResults)
    },
    toRemoteGuidance() {
      this.showMoreFunction = false
      this.$emit('to-remote-guidance', this.submitResults)
    },
    toTakePhoto() {
      this.activeName = 'photos'
      this.nativeTakePhoto()
    },
    toAiQaAssistant() {
      this.showMoreFunction = false
      this.$emit('to-ai-qa-assistant', this.submitResults)
    },
    onVoice() {
      this.$emit('on-voice', this.properties.guidanceVideo)
    },
    saveRecord() {
      this.$emit('save-record', this.submitResults)
    },
    submitRecord() {
      this.$emit('submit-record', this.submitResults)
    },
    choseImgViewer(closeReference = true, closePhoto = true) {
      if (closeReference) {
        this.$refs.middleSection.closeReferenceImage()
      }

      if (closePhoto) {
        this.$refs.topSection.closePhoto()
      }
    },
    jumpStep(index) {
      this.$emit('jump-step', index, this.submitResults)
    },
    reload() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.showContainer = false
          setTimeout(() => {
            this.showContainer = true
            if (this.ttsText) {
              this.nativePlayTTS(this.ttsText)
            }
          })
        })
      }, 1000)
    },
    playInfo(tts) {
      this.nativePlayTTS(tts)
      this.$message({ message: tts, duration: 2000, type: 'error', customClass: 'large-info-message' })
    }
  }
}
</script>

