<template>
  <div>
    <ht-action-panel :on-search="getList" :on-clear="onClear">
      <template slot="left">
        <el-select v-model="query.constructionEquipmentName" placeholder="设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.name" :value="item.value" />
        </el-select>
        <el-input v-model="query.constructionEquipmentCode" placeholder="产权编号" />
        <el-select v-model="query.constructionEquipmentIsWhole" placeholder="是否整机">
          <el-option v-for="(item,index) in EquipmentIsWholeEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>
    <ht-action-panel>
      <template slot="right">
        <el-button type="success" icon="el-icon-plus" :disabled="selectedEquipments.length < 1" @click="handleAddLeaseEquipments">确认</el-button>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list" :row-key="getRowKey" @selection-change="onSelectionChange">
      <ht-table-column type="selection" width="55" reserve-selection :selectable="isSelectable" />
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in EquipmentListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '是否整机'">{{ row[item.prop] ? '是' : '否' }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="100">
        <template v-slot="{row}">
          <el-button :disabled="row.isAdded" type="text" icon="el-icon-plus" @click="handleAddLeaseEquipment(row)">添加</el-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
  </div>
</template>

<script>
import { EquipmentListTitle } from '@/views/constructionProjectMgr/projectMgr/tableTitle'
import { EquipmentIsWholeEnum, EquipmentTypesEnum } from '@/views/constructionEquipmentMgr/enum'
import HtListContainer from '@/components/HtListContainer'
import { downloadFile } from '@/utils'
import { getStocks } from '@/api/constructionEquipmentMgr/stockMgr'

export default {
  name: 'AddEquipmentList',
  extends: HtListContainer,
  props: {
    alreadyAddList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      EquipmentTypesEnum, EquipmentIsWholeEnum,
      EquipmentListTitle,
      list: [],
      isLoading: false,
      selectedEquipments: [],
      selection: [],
      query: {}
    }
  },
  methods: {
    downloadFile,
    isSelectable(row) {
      if (row) {
        return !row.isAdded
      }
    },
    onClear() {
      Object.keys(this.query).forEach(el => {
        delete this.query[el]
      })
      this.getList()
    },
    getList() {
      this.isLoading = true
      getStocks(this.query).then(resp => {
        resp.forEach(item => {
          const index = this.alreadyAddList.findIndex(addItem => addItem.constructionEquipmentId === item.constructionEquipmentId && addItem.warehouseId === item.warehouseId)
          item.isAdded = index !== -1
          item.executeCode = null
          item.leaseQuantity = 1
        })
        this.list = resp
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    onSelectionChange(val) {
      this.selection = val
      this.selectedEquipments = []
      val.forEach(item => {
        this.selectedEquipments.push(item)
      })
    },
    handleAddLeaseEquipments() {
      this.$emit('on-add-equipments', this.selectedEquipments)
    },
    handleAddLeaseEquipment(row) {
      // const equipmentData = row
      this.$emit('on-add-equipments', [row])
    },
    getItem(item) {
      return {
        constructionEquipmentId: item.id,
        constructionEquipmentCode: item.code,
        constructionEquipmentName: item.name,
        constructionEquipmentSpecModel: item.specModel,
        constructionEquipmentWarehouse: item.warehouse,
        constructionEquipmentQuantity: item.quantity,
        isWhole: item.isWhole,
        executeCode: null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pagination-container{
  ::v-deep .el-pagination {
    text-align: right;
  }
}
</style>
