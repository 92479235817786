import { login } from '@/api/userInfo/user'
import { getToken, removeToken, setToken } from '@/utils/auth'
import { clearAll, clearAllExcludeAccount, getObject, removeObject, setObject } from '../localStorage'
import router, { resetRouter } from '@/router'
import { userFeatureMenuApi } from '@/api/systemMgr/roleMgr'
import {
  getUserImInfoAndConnectIm,
  removeGlobalEventListener
} from '@/views/newRemoteGuidance/js/connectAndGlobalMeeting'
import im from '@/views/newRemoteGuidance/rongCloud/im'
import { postKaiShengSso } from '@/api/newRemoteGuidance/sinoma'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  permissions: [],
  userInfo: getObject('userInfo'),
  userRoutes: getObject('userRoutes'),
  userImInfo: getObject('userImInfo'),
  isBeingInvited: false,
  imIsConnected: false,
  isInMeeting: false,
  isSinoma: false,
  isHasPatrolMenu: false,
  isHasRepairMenu: false,
  isPasswordRemembered: false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_BUSINESSES: (state, businesses) => {
    state.businesses = businesses
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_USER_ROUTES: (state, userRoutes) => {
    state.userRoutes = userRoutes
  },
  SET_USER_IM_INFO: (state, userImInfo) => {
    state.userImInfo = userImInfo
  },
  SET_USER_IS_BEING_INVITED: (state, isBeingInvited) => {
    state.isBeingInvited = isBeingInvited
  },
  SET_USER_IM_IS_CONNECTED: (state, imIsConnected) => {
    state.imIsConnected = imIsConnected
  },
  SET_USER_IS_IN_MEETING: (state, isInMeeting) => {
    state.isInMeeting = isInMeeting
  },
  SET_USER_IS_SINOMA: (state, isSinoma) => {
    state.isSinoma = isSinoma
  },
  SET_USER_IS_HAS_PATROL_MENU: (state, isHasPatrolMenu) => {
    state.isHasPatrolMenu = isHasPatrolMenu
  },
  SET_USER_IS_HAS_REPAIR_MENU: (state, isHasRepairMenu) => {
    state.isHasRepairMenu = isHasRepairMenu
  }
}

const actions = {
  // userInfo login
  login({ commit }, userInfo) {
    const { username, password, action, userType } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        password: password,
        action: action,
        userType: userType
      })
        .then(response => {
          const { accessToken, userInfo } = response
          commit('SET_TOKEN', accessToken)
          setToken(accessToken)
          setObject('accessToken', accessToken)
          userFeatureMenuApi().then((resp) => {
            commit('SET_USER_ROUTES', resp)
            setObject('userRoutes', resp)

            commit('SET_USER_INFO', userInfo)
            setObject('userInfo', userInfo)
            resolve(userInfo)
          })
        }).catch(error => {
          reject(error)
        })
    })
  },
  oneStopLogin({ commit }, userInfo) {
    const { appId, sign, action, loginType } = userInfo
    return new Promise((resolve, reject) => {
      login({
        appId: appId,
        sign: sign,
        action: action,
        loginType: loginType
      }).then(response => {
        const { accessToken, userInfo } = response
        commit('SET_TOKEN', accessToken)
        // 使用iframe嵌套，被嵌套网页不能Cookies.set到对应域名下，accessToken改为setObject,取用accessToken也使用getObject
        setObject('accessToken', accessToken)
        userFeatureMenuApi().then((resp) => {
          commit('SET_USER_ROUTES', resp)
          setObject('userRoutes', resp)

          commit('SET_USER_INFO', userInfo)
          setObject('userInfo', userInfo)
          resolve(userInfo)
        })
      }).catch(error => {
        reject(error)
      })
    })
  },
  sinomaSsoLogin({ commit }, sessionKey) {
    return new Promise((resolve, reject) => {
      const data = {
        sessionKey: sessionKey,
        loginPath: 'PC'
      }
      postKaiShengSso(data).then(response => {
        const { accessToken, userInfo } = response
        commit('SET_TOKEN', accessToken)
        // 使用iframe嵌套，被嵌套网页不能Cookies.set到对应域名下，accessToken改为setObject,取用accessToken也使用getObject
        setObject('accessToken', accessToken)
        userFeatureMenuApi().then((resp) => {
          commit('SET_USER_ROUTES', resp)
          setObject('userRoutes', resp)

          commit('SET_USER_INFO', userInfo)
          setObject('userInfo', userInfo)
          resolve(userInfo)
        })
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 临时用户登陆并获取ImInfo进行连接
  templateUserLogin({ commit }, userInfo) {
    const { username, password, action, userType } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        password: password,
        action: action,
        userType: userType
      })
        .then(response => {
          const { accessToken, userInfo } = response
          commit('SET_TOKEN', accessToken)
          setToken(accessToken)
          setObject('accessToken', accessToken)
          getUserImInfoAndConnectIm().then(userImInfo => {
            setObject('userImInfo', userImInfo)
            commit('SET_USER_IM_INFO', userImInfo)
            commit('SET_USER_INFO', userInfo)
            setObject('userInfo', userInfo)
            resolve(userInfo)
          })
        }).catch(error => {
          reject(error)
        })
    })
  },
  // 刷新页面后，没有roles，当路由守卫走到try catch获取roles时，在内部调用setRoles，把存在localstorage里的userInfo里的roles存入state的roles里，跳出try catch
  setRoes({ commit, state }) {
    return new Promise(resolve => {
      commit('SET_ROLES', state.userInfo.roles ? state.userInfo.roles : 'TempRole')
      resolve(state.userInfo)
    })
  },
  // get userInfo info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getUserInfo().then(response => {
  //       const { userInfo, roles, permissions, businesses } = response
  //
  //       if (!userInfo) {
  //         reject('验证失败，请重新登录!')
  //       }
  //
  //       const { username, avatarUrl } = userInfo
  //
  //       // roles must be a non-empty array
  //       if (!roles || roles.length <= 0) {
  //         reject('getInfo: 角色必须为非空数组')
  //       }
  //
  //       commit('SET_ROLES', roles)
  //       commit('SET_PERMISSIONS', permissions)
  //       commit('SET_BUSINESSES', businesses)
  //       commit('SET_NAME', username)
  //       commit('SET_AVATAR', avatarUrl)
  //       commit('SET_USER_INFO', userInfo)
  //       resolve(response)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // userInfo logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      // im.removeEventListener()
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_USER_INFO', undefined)
      commit('SET_USER_IM_INFO', undefined)
      commit('SET_USER_IS_BEING_INVITED', false)
      removeToken()
      resetRouter()
      if (localStorage.getItem('isPasswordRemembered')) {
        clearAllExcludeAccount()
      } else {
        clearAll()
      }
      removeGlobalEventListener()
      im.removeConnectEventListener()
      dispatch('tagsView/delAllViews', null, { root: true })
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_USER_INFO', undefined)
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, roles) {
    return new Promise(async resolve => {
      resetRouter()
      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, {
        root: true
      })
      // dynamically add accessible routes
      router.addRoutes(accessRoutes)
      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })
      resolve()
    })
  },
  getUserImInfo({ commit }, routers) {
    // 判断用户是否有远程指导权限(联系人)
    const index = routers.findIndex(item => item.name === 'newRemoteGuidanceMenu')
    if (index !== -1) {
      const menuIndex = routers[index].children.findIndex(item => item.name === 'contactList')
      if (menuIndex !== -1) {
        getUserImInfoAndConnectIm().then(userImInfo => {
          setObject('userImInfo', userImInfo)
          commit('SET_USER_IM_INFO', userImInfo)
        })
      }
    }
  },
  setUserHasMenu({ commit }, routers) {
    // 工单管理菜单
    const taskMenuIndex = routers.findIndex(item => item.name === 'taskMgrMenu')
    const taskMgrMenuChildren = taskMenuIndex !== -1 ? routers[taskMenuIndex].children : []
    // 巡检工单管理菜单
    const patrolIndex = taskMgrMenuChildren.findIndex(item => item.name === 'patrolWorkOrderMgr')
    const isHasPatrolMenu = patrolIndex !== -1 || false
    commit('SET_USER_IS_HAS_PATROL_MENU', isHasPatrolMenu)
    // 维修工单管理菜单
    const repairIndex = taskMgrMenuChildren.findIndex(item => item.name === 'repairWorkOrderMgr')
    const isHasRepairMenu = repairIndex !== -1 || false
    commit('SET_USER_IS_HAS_REPAIR_MENU', isHasRepairMenu)
  },
  changeImInfo({ commit }, userImInfo) {
    setObject('userImInfo', userImInfo)
    commit('SET_USER_IM_INFO', userImInfo)
  },
  changeIsBeingInvited({ commit }, isBeingInvited) {
    commit('SET_USER_IS_BEING_INVITED', isBeingInvited)
  },
  setImConnectedStatue({ commit }, imIsConnected) {
    commit('SET_USER_IM_IS_CONNECTED', imIsConnected)
  },
  setIsInMeeting({ commit }, isInMeeting) {
    commit('SET_USER_IS_IN_MEETING', isInMeeting)
  },
  oneStopLoginGetUserImInfo({ commit }) {
    getUserImInfoAndConnectIm().then(userImInfo => {
      setObject('userImInfo', userImInfo)
      commit('SET_USER_IM_INFO', userImInfo)
    })
  },
  setIsSinoma({ commit }, isSinoma) {
    commit('SET_USER_IS_SINOMA', isSinoma)
  },
  saveAccount({ commit }, accountInfo) {
    setObject('accountInfo', accountInfo)
    setObject('isPasswordRemembered', true)
  },
  removeAccount({ commit }, keys) {
    keys.forEach(key => {
      removeObject(key)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
