<template>
  <div>
    <ht-action-panel :on-search="onSearch" :on-clear="onClearSearch">
      <template slot="left">
        <el-select v-model="listQuery.constructionEquipmentName" placeholder="设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.name" :value="item.value" />
        </el-select>
        <el-input v-model="listQuery.constructionEquipmentCode" placeholder="产权编号" />
        <el-input v-model="listQuery.constructionEquipmentSpecModel" placeholder="规格型号" />
        <el-input v-model="listQuery.oldConstructionProjectName" placeholder="转出工程" />
        <el-input v-model="listQuery.constructionProjectName" placeholder="转入工程" />
        <el-date-picker
          v-model="createdAt"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          placement="bottom-start"
          start-placeholder="转移时间"
          end-placeholder="转移时间"
        />
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="item in StockRecordMgrTransferListTitle" :key="item.prop" :label="item.label" :prop="item.prop" :min-width="item.width || 100" :fixed="item.fixed">
        <template v-slot="{row}">
          <span v-if="item.label === '操作类型'">{{ StockActionEnum[row[item.prop]] }}</span>
          <span v-else-if="item.label === '转移数量'">{{ row[item.prop] }}</span>
          <span v-else-if="item.label === '操作人'">{{ row.staff.fullName }}</span>
          <template v-else-if="item.label === '转移文件'">
            <dw-file
              v-if="row.attachmentData && row.attachmentData.length > 0"
              class="item attachment"
              :url="row.attachmentData[0]"
              :show-del="false"
              justify-content="left"
              :need-margin="false"
              @view-file="downloadFile"
            />
            <span v-else>{{ '-' }}</span>
          </template>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { StockRecordMgrTransferListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getStockRecords } from '@/api/constructionEquipmentMgr/stockRecordMgr'
import { downloadFile } from '@/utils'
import { EquipmentTypesEnum, StockActionEnum } from '@/views/constructionEquipmentMgr/enum'

export default {
  name: 'TransferReportListVue',
  extends: HtListContainer,
  data() {
    return {
      StockRecordMgrTransferListTitle, StockActionEnum, EquipmentTypesEnum,
      createdAt: []
    }
  },
  methods: {
    downloadFile,
    onClearSearch() {
      this.createdAt = []
      this.onClear()
    },
    getList() {
      this.listQuery.createdStartAt = this.createdAt[0]
      this.listQuery.createdEndAt = this.createdAt[1]
      this.listQuery.action = 'TRANSSHIPMENT'
      this.beforeGetList()
      getStockRecords(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
