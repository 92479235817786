var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: {
                    type: "success",
                    size: "mini",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openDialog("create")
                    },
                  },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isKnowledgeLoading,
              expression: "isKnowledgeLoading",
            },
          ],
          ref: "list",
          attrs: {
            data: _vm.knowledgeList,
            "row-key": _vm.getRowKey,
            "default-sort": { prop: "createdAt", order: "descending" },
          },
          on: { "sort-change": _vm.sortChange },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.knowledgeLabelToProps, function (i) {
            return _c("ht-table-column", {
              key: i.prop,
              attrs: { label: i.label, prop: i.prop, sortable: i.sortable },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        i.prop === "docDetails"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDialog("view", row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.details.length))]
                                ),
                              ],
                              1
                            )
                          : _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    i.secondProp
                                      ? row[i.prop][i.secondProp]
                                      : row[i.prop]
                                  )
                                ),
                              ]),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openDialog("edit", row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          "confirm-title": "请确认是否要删除该条数据？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.deleteEquipmentKnowledgeLibsItem(row)
                          },
                        },
                      },
                      [_vm._v("删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.knowledgePage.total,
          page: _vm.knowledgeListQuery.page,
          limit: _vm.knowledgeListQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.knowledgeListQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.knowledgeListQuery, "perPage", $event)
          },
          pagination: _vm.getDeviceKnowledge,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        { ref: "dialog", attrs: { title: "知识库", width: "60%" } },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "ht-action-panel",
                [
                  _c(
                    "template",
                    { slot: "left" },
                    [
                      _c("span", [_vm._v("知识名称：")]),
                      _vm._v(" "),
                      _vm.canEdit()
                        ? _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入知识名称" },
                            model: {
                              value: _vm.docTitle,
                              callback: function ($$v) {
                                _vm.docTitle = $$v
                              },
                              expression: "docTitle",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.docTitle))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.canEdit()
                    ? _c(
                        "template",
                        { slot: "right" },
                        [
                          _c("ht-upload-button", {
                            attrs: {
                              "key-prefix": "customer_cnbm/devices",
                              "multiple-selection": true,
                            },
                            on: {
                              "on-files-uploaded": _vm.onKnowledgeLibsUploaded,
                              "on-upload-start": function ($event) {
                                _vm.isLoading = true
                              },
                              "on-upload-finish": function ($event) {
                                _vm.isLoading = false
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "ht-table",
                {
                  staticStyle: { width: "100%", margin: "20px 0" },
                  attrs: { data: _vm.uploadList, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "文件", width: "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-link", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                    "white-space": "nowrap",
                                    width: "260px",
                                    display: "inline-block",
                                  },
                                  style: { color: _vm.variables.primaryColor },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewUploadFile(row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.fileTitle))]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "描述" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm.canEdit()
                              ? _c("el-input", {
                                  attrs: { placeholder: "请输入文件描述" },
                                  model: {
                                    value: row.desc,
                                    callback: function ($$v) {
                                      _vm.$set(row, "desc", $$v)
                                    },
                                    expression: "row.desc",
                                  },
                                })
                              : _c("span", [_vm._v(_vm._s(row.desc))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.canEdit()
                    ? _c("ht-table-column-operation", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadList.splice(
                                            $index,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3334120744
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitKnowledge()
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }