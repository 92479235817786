<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import echarts from 'echarts'
import variables from '@/styles/variables.scss'
import { debounce } from '@/utils'

function getFormatter(val) {
  const text = val.name
  const endIndex = text.indexOf('（') // 找到括号的位置作为截取的结束索引
  const frontText = text.substring(0, endIndex)
  return val.marker + frontText + '：' + val.value + '，' + '占比：' + val.percent + '%'
}

export default {
  name: 'ProjectProgressChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true },
    color1: { type: String, default: '#213BA1' },
    color2: { type: String, default: variables.primaryColor },
    color3: { type: String, default: '#6879FF' },
    color4: { type: String, default: '#C1C4FC' },
    color5: { type: String, default: '#909399' },
    count: { type: Number, default: 0 }
  },
  data() {
    return {
      chart: null,
      statisticColors: [this.color1, this.color2, this.color3, this.color4, this.color5],
      option: {
        title: {
          top: '30%',
          left: 'center',
          textStyle: {
            fontWeight: 'bold',
            fontSize: 16,
            color: '#666666'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 0,
          itemGap: 10,
          textStyle: {
            fontWeight: 'bold',
            fontSize: '14',
            lineHeight: '20',
            color: '#666666'
          }
        },
        grid: {
          bottom: '0',
          containLabel: true
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '65%'],
            top: -30,
            bottom: 50,
            label: {
              show: true,
              position: 'inside',
              fontWeight: 'bold',
              fontSize: 12,
              formatter: function(val) {
                return val.percent > 0 ? val.percent + '%' : ''
              }
            },
            startAngle: -90, // 起始角度
            data: [
              { value: 0, name: '全部完成（进度 = 100%）', itemStyle: { color: this.color1 }},
              { value: 0, name: '基本完成（70% ≤ 进度 < 100%）', itemStyle: { color: this.color2 }},
              { value: 0, name: '部分完成（30% ≤ 进度 < 70%）', itemStyle: { color: this.color3 }},
              { value: 0, name: '起始阶段（0% < 进度 < 30%）', itemStyle: { color: this.color4 }},
              { value: 0, name: '未开始（进度 = 0%）', itemStyle: { color: this.color5 }}
            ],
            tooltip: {
              formatter: function(val) {
                return getFormatter(val)
              },
              textStyle: {
                fontWeight: 'bold',
                fontSize: '14'
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(statistic, count) {
      this.option.title.text = '未结束工程数' + '\n' + count
      statistic.forEach(item => {
        if (item.progressName === 'ALL') {
          this.option.series[0].data[0].value = item.projectCount
        } else if (item.progressName === 'MOST') {
          this.option.series[0].data[1].value = item.projectCount
        } else if (item.progressName === 'MINORITY') {
          this.option.series[0].data[2].value = item.projectCount
        } else if (item.progressName === 'LITTLE') {
          this.option.series[0].data[3].value = item.projectCount
        } else if (item.progressName === 'NONE') {
          this.option.series[0].data[4].value = item.projectCount
        }
      })
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
