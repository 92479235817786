import { render, staticRenderFns } from "./MultiTracks.vue?vue&type=template&id=781f5451&scoped=true&"
import script from "./MultiTracks.vue?vue&type=script&lang=js&"
export * from "./MultiTracks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "781f5451",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangmin/Projects/devidia/devidia-p1-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('781f5451')) {
      api.createRecord('781f5451', component.options)
    } else {
      api.reload('781f5451', component.options)
    }
    module.hot.accept("./MultiTracks.vue?vue&type=template&id=781f5451&scoped=true&", function () {
      api.rerender('781f5451', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/test/mindAr/components/MultiTracks.vue"
export default component.exports