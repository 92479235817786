var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "transshipmentForm",
          attrs: {
            model: _vm.transshipmentForm,
            rules: _vm.transshipmentFormRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: "转移工程：", prop: "newConstructionProjectId" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.projectList.length > 0
                        ? "请输入工程名称进行搜索"
                        : "当前无可选工程",
                    remote: "",
                    "remote-method": _vm.getProjects,
                  },
                  model: {
                    value: _vm.transshipmentForm.newConstructionProjectId,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.transshipmentForm,
                        "newConstructionProjectId",
                        $$v
                      )
                    },
                    expression: "transshipmentForm.newConstructionProjectId",
                  },
                },
                _vm._l(_vm.projectList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.equipmentInfo && _vm.equipmentInfo.constructionEquipmentIsWhole
            ? _c(
                "el-form-item",
                { attrs: { label: "执行代码：", prop: "executeCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 5,
                      "show-word-limit": "",
                      placeholder: "请输入执行代码",
                    },
                    model: {
                      value: _vm.transshipmentForm.executeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.transshipmentForm, "executeCode", $$v)
                      },
                      expression: "transshipmentForm.executeCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "quantity",
              attrs: { label: "转移数量：", prop: "quantity" },
            },
            [
              _c("el-input-number", {
                attrs: {
                  disabled:
                    _vm.equipmentInfo &&
                    _vm.equipmentInfo.constructionEquipmentIsWhole,
                  min: 1,
                  placeholder: "转移数量",
                  precision: 0,
                },
                model: {
                  value: _vm.transshipmentForm.quantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.transshipmentForm, "quantity", $$v)
                  },
                  expression: "transshipmentForm.quantity",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "info" }, [
                _vm._v(
                  "可转移数量：" +
                    _vm._s(_vm.transshipmentForm.projectEquipmentQuantity)
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "租用合同：", prop: "attachmentData" } },
            [
              _vm.transshipmentForm.attachmentData
                ? _c("dw-file", {
                    attrs: {
                      url: _vm.transshipmentForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.transshipmentForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["doc", "pdf", "img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传租用合同",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmSubmit("transshipmentForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }