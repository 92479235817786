<template>
  <div v-loading="isLoading" class="knowledge-container">
    <h1 class="title">{{ knowledgeInfo && knowledgeInfo.title }}</h1>
    <div class="time">{{ `最新发布时间：${ knowledgeInfo && knowledgeInfo.updatedAt || '以实际发布时间为准'}` }}</div>
    <div class="content" v-html="knowledgeInfo && knowledgeInfo.content" />
    <div v-if=" knowledgeInfo && knowledgeInfo.attachmentData.length > 0" class="attachments">
      <span class="name">附件：</span>
      <dw-file
        v-for="(item,index) in knowledgeInfo.attachmentData"
        :key="index"
        :url="item"
        justify-content="left"
        style="margin: 5px 0"
        :show-del="false"
      />
    </div>
    <el-divider v-if="showButton" />
    <div v-if="showButton" class="operation-container" style="text-align: center">
      <el-button icon="el-icon-close" @click="onClose">关闭</el-button>
      <div v-if="list.length > 1" class="right-container">
        <el-button v-if="listIndex > 0" type="success" @click="onPrev"> <i class="el-icon-arrow-left" />上一条</el-button>
        <el-button v-if="listIndex < list.length - 1" type="success" @click="onNext">下一条<i class="el-icon-arrow-right" /></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getKnowledgeBase } from '@/api/knowleageBaseMgr/knowleageBaseMgr'
import { buildRegexForHtml, replacePhraseWithLabel } from '@/utils/business'

export default {
  name: 'KnowledgeView',
  props: {
    list: { type: Array, default: () => [] },
    showButton: { type: Boolean, default: true }
  },
  data() {
    return {
      listIndex: 0,
      knowledgeInfo: null,
      isLoading: false
    }
  },
  methods: {
    buildRegexForHtml, replacePhraseWithLabel,
    onClose() {
      this.$emit('on-close')
    },
    onPrev() {
      this.listIndex--
      this.getDetail()
    },
    onNext() {
      this.listIndex++
      this.getDetail()
    },
    getDetail($arguments) {
      this.isLoading = true
      getKnowledgeBase(this.list[this.listIndex].id, { action: 'check' }).then(resp => {
        this.knowledgeInfo = resp
        this.isLoading = false
        if ($arguments) {
          this.findMatchPart(resp.content, this.list[this.listIndex].highlightContent[$arguments[0]])
        }
      }).catch(() => {
        this.isLoading = false
      })
    },
    findMatchPart(content, matchPart) {
      this.knowledgeInfo.content = content
      const part = this.buildRegexForHtml(matchPart.replace(/<(em|\/em)[^>]*>/g, ''))
      // const part = matchPart.replace(/<(em|\/em)[^>]*>/g, '')

      // 给目标段落加标签
      this.knowledgeInfo.content = this.replacePhraseWithLabel(this.knowledgeInfo.content, part, '<span class="match-part"><em>', '</em></span>')
      this.$nextTick(() => {
        // 获取要滚动到的元素
        const element = document.getElementsByClassName('match-part')[0]

        // 滚动到元素的视图，设置 behavior 为 'smooth' 以实现平滑滚动
        if (element) {
          element.scrollIntoView()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.knowledge-container{
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  .title{
    text-align: center;
  }
  .time{
    color: #666666;
    text-align: right;
    margin-bottom: 20px;
  }

  .content{
    color: #000000;
    max-height: 400px;
    overflow: scroll;
    div{
      position: relative !important;
    }
  }

  .attachments{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 30px;
    .name{
      color: #666666;
    }
  }

  .operation-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .right-container{
      flex: 1;
      text-align: right;
    }
  }
}
</style>

<style lang="scss">
#sidebar{
  position: relative !important;
  display: none;
}
#page-container{
  position: relative !important;
}
</style>
