var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBox
    ? _c(
        "div",
        {
          ref: "attendeesRef",
          staticClass: "attendees-container",
          class: {
            "fullscreen-attendees-container": _vm.fullscreen,
            "normal-attendees-container": !_vm.fullscreen,
            "is-mobile-fullscreen-attendees-container":
              _vm.fullscreen && _vm.isMobile,
          },
        },
        [
          _c("div", { ref: "topContainerRef", staticClass: "top-container" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("参会人员列表（" + _vm._s(_vm.count) + "）"),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "close-icon",
              attrs: { src: _vm.Close, alt: "" },
              on: { click: _vm.toggleBoxStatus },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c("attendees-list", {
                attrs: {
                  "user-info": _vm.userInfo,
                  "user-list": _vm.attendeesList,
                  "show-bottom-button": _vm.showBottomButton,
                  "is-mute-all-cam": _vm.isMuteAllCam || !_vm.isAllCamOn,
                  "is-mute-all-mic": _vm.isMuteAllMic || !_vm.isAllMicOn,
                },
                on: {
                  "change-one-mic-mute": _vm.changeOneMicMute,
                  "change-one-cam-mute": _vm.changeOneCamMute,
                  "change-all-mic-mute": _vm.changeAllMicMute,
                  "change-all-cam-mute": _vm.changeAllCamMute,
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }