<template>
  <el-descriptions
    class="equipment-descriptions"
    :column="2"
    size="medium"
    border
    :label-style="{width: '100px',color: '#666'}"
    :content-style="{minWidth: '150px'}"
  >
    <el-descriptions-item :span="nameSpan">
      <template slot="label">设备名称</template>
      <span>{{ equipmentInfo && equipmentInfo.name || '-' }}</span>
    </el-descriptions-item>
    <el-descriptions-item v-if="equipmentInfo && equipmentInfo.code">
      <template slot="label">产权编号</template>
      <span>{{ equipmentInfo && equipmentInfo.code || '-' }}</span>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">规格型号</template>
      <span>{{ equipmentInfo && equipmentInfo.specModel || '-' }}</span>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import { EquipmentTypesEnum } from '@/views/constructionEquipmentMgr/enum'

export default {
  name: 'EquipmentDescriptions',
  props: {
    equipmentInfo: { type: Object, default() { return {} } }
  },
  data() {
    return {
      EquipmentTypesEnum
    }
  },
  computed: {
    nameSpan() {
      if (this.equipmentInfo && this.equipmentInfo.isWhole) {
        return 2
      } else {
        return this.equipmentInfo && this.getIsWhole()
      }
    }
  },
  methods: {
    getIsWhole() {
      const index = EquipmentTypesEnum.findIndex(item => item.value === this.equipmentInfo.name)
      return index !== -1 ? EquipmentTypesEnum[index].isWhole : false
    }
  }
}
</script>

<style scoped>

</style>
