<template>
  <div
    class="file-container"
    :class="{'has-more-operation': showView || showDownload || showTextDel, 'need-margin': needMargin, 'is-show-card-file-container': showCard}"
    :style="{justifyContent: justifyContent}"
  >
    <template v-if="showCard">
      <el-image v-if="isCanPreview(url) === 'image'" class="file-img" :src="url" :preview-src-list="[url]" alt="" />
      <span v-else class="file-name" @click="viewFile">{{ name || getFileName() }}</span>
    </template>
    <template v-else>
      <svg-icon :icon-class="`file_${getFileTypeByExtension()}`" type="primary" class="file-icon" style="font-size: 16px" />
      <span class="file-name" :class="{'file-name-flex-1': nameFlex1}" @click="viewFile">{{ name || getFileName() }}</span>
    </template>
    <el-link v-if="showView && isCanPreview(url) !== 'other'" icon="el-icon-view" type="primary" :href="url" target="_blank" class="operating-button">预览</el-link>
    <el-button v-if="showDownload" type="text" icon="el-icon-download" class="operating-button" @click="viewFile">下载</el-button>
    <ht-popover-button v-if="showDel && showTextDel" type="text" :danger-text="true" icon="el-icon-delete" class="operating-button" confirm-title="请确认是否要删除？" @confirmed="deleteFile">删除</ht-popover-button>
    <el-button v-else-if="showDel" type="danger" icon="el-icon-delete" class="operating-button" circle @click="deleteFile" />
  </div>
</template>

<script>
import { isCanPreview } from '@/utils'

export default {
  name: 'DwFile',
  props: {
    name: { type: String, default: undefined },
    url: { type: String, default: undefined },
    showView: { type: Boolean, default: false },
    showDownload: { type: Boolean, default: false },
    showDel: { type: Boolean, default: true },
    showTextDel: { type: Boolean, default: false },
    justifyContent: { type: String, default: 'center' },
    needMargin: { type: Boolean, default: true },
    showCard: { type: Boolean, default: false },
    nameFlex1: { type: Boolean, default: false }
  },
  methods: {
    isCanPreview,
    getFileTypeByExtension() {
      // 使用 split() 函数将文件地址按照点号（.）分割成数组， 使用 pop() 函数获取数组中的最后一个元素，即文件扩展名
      const type = this.url.split('.').pop()
      switch (type.toLowerCase()) {
        case 'doc':
        case 'docx':
          return 'word'
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'image'
        case 'pdf':
          return 'pdf'
        case 'xls':
        case 'xlsx':
          return 'excel'
        case 'ppt':
        case 'pptx':
          return 'ppt'
        case 'zip':
        case 'rar':
          return 'zip'
        default:
          return 'unknown'
      }
    },
    getFileName() {
      // 使用 split() 函数将文件地址按照斜杠（/）或反斜杠（\）分割成数组 ，使用 pop() 函数获取数组中的最后一个元素，即文件名称
      return this.url ? this.url.split(/[\\/]/).pop() : '-'
    },
    viewFile() {
      this.$emit('view-file', this.url)
    },
    deleteFile() {
      this.$emit('delete-file')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.file-container{
  display: flex;
  align-items: center;
  justify-content: center;
  .file-name{
    color: $primaryColor;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .file-name-flex-1{
    flex: 1;
    text-align: left;
  }
  .file-name:hover {
    text-decoration: underline;
  }
  .operating-button{
    margin-left: 10px;
  }
}
.is-show-card-file-container{
  position: relative;
  .file-img{
    width: 100%;
    height: 100px;
  }
  .operating-button{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.need-margin{
  padding: 0 10px;
  margin-right: 20px;
}

.has-more-operation{
  border-radius: 2px;
  .file-name{
    flex: 1;
    text-align: left;
  }
}
.has-more-operation:hover{
  background: #e5e5e5;
}
</style>
