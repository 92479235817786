var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("本地API配置")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "api-container" },
      [
        _c("span", { staticClass: "item" }, [_vm._v("API地址：")]),
        _vm._v(" "),
        _c("span", { staticClass: "item" }, [_vm._v("http://")]),
        _vm._v(" "),
        _c("el-input", {
          staticClass: "input-width",
          attrs: { placeholder: "请输入API地址" },
          model: {
            value: _vm.api,
            callback: function ($$v) {
              _vm.api = $$v
            },
            expression: "api",
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "item" }, [_vm._v(":")]),
        _vm._v(" "),
        _c("el-input", {
          staticClass: "input-width-mini",
          attrs: { placeholder: "端口号" },
          model: {
            value: _vm.api_port,
            callback: function ($$v) {
              _vm.api_port = $$v
            },
            expression: "api_port",
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "item" }, [_vm._v("/dw/v1/")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "center-button" },
      [
        _c(
          "ht-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.setURL()
              },
            },
          },
          [_vm._v("确定")]
        ),
        _vm._v(" "),
        _c(
          "ht-button",
          {
            attrs: { type: "default", icon: "el-icon-delete" },
            on: { click: _vm.onClearURL },
          },
          [_vm._v("恢复")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }