<template>
  <div class="view-container">
    <div class="top-section">
      <div class="view-title top-title">上架-执行上架</div>
    </div>
    <div class="goods-container" :style="{height: height - 10 + 'px'}">
      <div class="item item-bold">箱号：{{ getExecuteInboundBoxes[currentBoxIndex] && getExecuteInboundBoxes[currentBoxIndex].code }}</div>
      <div class="item item-bold">推荐库位：{{ getStorageLocation && getStorageLocation.code }}</div>
      <div class="item">库位容量：{{ getStorageLocation && getStorageLocation.capacity }}</div>
      <div class="item">库存量：{{ getStorageLocation && getStorageLocation.quantity }}</div>
      <div class="item">格号：{{ getCell(getStorageLocation && getStorageLocation.cellList) }}</div>
      <div class="item item-button">至箱号:
        <span v-if="boxCode" class="scan-result">{{ boxCode }}</span>
        <svg-icon slot="append" icon-class="scan" />
        <el-button type="text" class="scan-button" :disabled="buttonDisabled" @click="handleActions('to-scan-box-code')">扫描箱号</el-button>
      </div>
      <div class="item item-button">至库位:
        <span v-if="storageInfo" class="scan-result">{{ `${storageInfo.code}${cellInfo ? '（' + cellInfo.name +' ）' : ''}` }}</span>
        <svg-icon slot="append" icon-class="scan" />
        <el-button type="text" class="scan-button" :disabled="buttonDisabled" @click="handleActions('to-scan-storage-code')">扫描库位</el-button>
      </div>
    </div>
    <div class="bottom-container">
      <el-button v-if="currentBoxIndex > 0" class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-prev-box')">上一个</el-button>
      <el-button v-if="currentBoxIndex < getExecuteInboundBoxes.length - 1" class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-next-box')">下一个</el-button>
      <el-button class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-inbound')">确认</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StepUi',
  props: {
    height: { type: Number, default: 480 },
    results: { type: Object, default() { return { warehouse: null, boxes: [] } } }},
  data() {
    return {
      buttonDisabled: this.disabled,
      currentBoxIndex: 0,
      currentBox: null,
      submitResults: this.results,
      boxCode: null,
      storageInfo: null,
      cellInfo: null
    }
  },
  computed: {
    getExecuteInboundBoxes() {
      return this.submitResults.boxes && this.submitResults.boxes.filter(item => !item.isInbound) || []
    },
    getStorageLocation() {
      if (this.submitResults.warehouse && this.submitResults.warehouse.storageLocations && this.submitResults.warehouse.storageLocations.length > 0) {
        const storageLocations = this.submitResults.warehouse.storageLocations.filter(item => item.capacity > item.quantity && item.cellList.filter(item => !item.isInbound).length > 0)
        if (storageLocations.length > 0) {
          return storageLocations[0]
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  watch: {
    results: {
      handler: function() {
        this.submitResults = this.results
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getCell(cellList) {
      const cell = cellList.filter(item => !item.isInbound)[0] || []
      return cell && cell.name
    },
    handleActions(action) {
      switch (action) {
        case 'to-prev-box':
          this.getSubmitResults(false, 'prev')
          this.$emit('to-prev-box', this.submitResults, this.currentBoxIndex)
          break
        case 'to-next-box':
          this.getSubmitResults(false, 'next')
          this.$emit('to-next-box', this.submitResults, this.currentBoxIndex)
          break
        case 'to-inbound':
          if (this.currentBoxIndex === this.getExecuteInboundBoxes.length - 1) {
            this.getSubmitResults(true)
            if (this.boxCode && this.storageInfo && this.cellInfo) {
              this.$emit('to-inbound', 'goods', this.submitResults)
            }
          } else {
            this.getSubmitResults(true, 'next')
            if (this.boxCode && this.storageInfo && this.cellInfo) {
              this.$emit('to-inbound-next-box', this.submitResults)
            }
          }
          break
        case 'to-scan-box-code':
          this.$emit('to-scan-code', 'goods-box', this.submitResults)
          break
        case 'to-scan-storage-code':
          this.$emit('to-scan-code', 'goods-storage', this.submitResults)
          break
      }
    },
    setScanCodeResult(type, scanResult) {
      if (type === 'goods-box') {
        this.boxCode = scanResult
      } else if (type === 'goods-storage') {
        this.storageInfo = scanResult
        this.cellInfo = this.storageInfo.cellList.filter(item => !item.isInbound)[0] || []
      }
    },
    getSubmitResults(isSubmit, type) {
      if (isSubmit) {
        if (this.boxCode && this.storageInfo && this.cellInfo) {
          this.handleInbound()
        } else {
          if (!this.boxCode) {
            this.$message({ message: `请扫描箱号`, duration: 2000, type: 'error', customClass: 'large-info-message' })
          } else if (!this.storageInfo && !this.cellInfo) {
            this.$message({ message: `请扫描库位`, duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
        }
      } else {
        if (this.boxCode && this.storageInfo && this.cellInfo) {
          this.handleInbound()
        } else {
          this.currentBoxIndex = type === 'next' ? this.currentBoxIndex + 1 : this.currentBoxIndex - 1
        }
      }
    },
    handleInbound() {
      const boxIndex = this.submitResults.boxes && this.submitResults.boxes.findIndex(item => item.code === this.boxCode)
      if (boxIndex !== -1) {
        this.submitResults.boxes[boxIndex].isInbound = true
      }
      const storageLocations = this.submitResults.warehouse.storageLocations
      const storageIndex = storageLocations && storageLocations.findIndex(item => item.code === this.storageInfo.code)
      if (storageIndex !== -1) {
        const cellIndex = storageLocations[storageIndex].cellList && storageLocations[storageIndex].cellList.findIndex(item => item.code === this.cellInfo.code)
        if (cellIndex !== -1) {
          storageLocations[storageIndex].cellList[cellIndex].isInbound = true
          storageLocations[storageIndex].quantity++
        }
      }
    },
    getTtsText(isScan = false, isScanBox = false) {
      const boxCode = this.getExecuteInboundBoxes[this.currentBoxIndex] && this.getExecuteInboundBoxes[this.currentBoxIndex].code
      const storageCode = this.getStorageLocation && this.getStorageLocation.code
      if (isScan) {
        if (isScanBox) {
          if (this.boxCode === boxCode) {
            return `箱号确认一致`
          } else {
            return `箱号为${this.boxCode}`
          }
        } else {
          if (this.storageInfo.code === storageCode) {
            return `库位确认一致`
          } else {
            return `库位为${this.storageInfo.code}`
          }
        }
      } else {
        return `上架箱号为${boxCode}，推荐库位为${storageCode},请扫描实际箱号和库位进行确认`
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.view-container{
  .goods-container{
    margin: 0 20px 10px;
    background: #ffffff;
    padding: 20px 20px 10px;
    display: flex;
    flex-direction: column;
    .item {
      font-size: 1.2rem;
      margin-bottom: 20px;
    }
    .item-bold{
      font-weight: bolder;
    }
    .item-button{
      .scan-result{
        margin-right: 30px;
      }
      .svg-icon{
        color: $primaryColor;
      }
      .el-button{
        padding: 0;
        font-weight: bolder;
      }
    }
  }
  .bottom-container{
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    .square-circle-button {
      flex: 1;
      height: 40px;
      color: #ffffff;
      background: $mobilePrimaryColor;
      border-radius: 5px;
      font-size: 1.5rem;
    }
  }
}
</style>
