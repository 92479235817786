<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container is-used-step-container"
    :style="{height: height + 'px'}"
    title="hf_use_description"
  >
    <div class="view-container">
      <top-section
        ref="topSection"
        is-quick-patrol
        :is-focused-item="isFocusedItem"
        :current-index="currentIndex"
        :count="count"
        :title="properties.title"
        :show-more-function="showMoreFunction"
        :must-upload-photo="properties.mustUploadPhoto"
        :upload-photos="submitResults.uploadPhotos"
        :active-img-index="activeImgIndex"
        :button-disabled="buttonDisabled"
        @more-function="moreFunction()"
        @native-play-tts="nativePlayTTS(ttsText)"
        @to-remote-guidance="toRemoteGuidance()"
        @to-ai-qa-assistant="toAiQaAssistant()"
        @native-take-photo="nativeTakePhoto()"
        @open-photo="openPhoto()"
        @del-photo="delPhoto()"
        @set-active-item="setActiveItem()"
        @close-photo-viewer="closePhotoViewer()"
        @jump-step="jumpStep"
      />
      <div class="middle-section" :style="{height: getMiddleHeight + 'px'}">
        <div class="question-container">{{ isEdit ? '检查项' : `检查项${currentCheckIndex + 1}：${results.question}` }}</div>
      </div>
      <div class="bottom-section">
        <div class="bottom-container">
          <div v-if="showToPrev" class="bottom-left">
            <el-button class="prev-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('toPrev')">上一项</el-button>
          </div>
          <div class="bottom-right">
            <el-button v-for="(item,index) in buttonText" :key="index" class="square-circle-button" type="text" @click="handleActions('toNext', item)">{{ item }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import TopSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/topSection'
import { parseTime } from '@/utils'

export default {
  name: 'AskAnswer',
  components: { TopSection },
  mixins: [StepUiMixin],
  props: {
    isLastStep: { type: Boolean, default: false },
    isLastCheck: { type: Boolean, default: false },
    checkCount: { type: Number, default: 0 },
    currentCheckIndex: { type: Number, default: 0 },
    properties: { type: Object, default() { return { title: null, mustUploadPhoto: null } } },
    results: { type: Object, default() { return {} } }
  },
  data() {
    return {
      buttonText: ['否', '是']
    }
  },
  computed: {
    showToPrev() {
      // 显示上一项的条件：
      // 1。步骤项总数大于1 count > 1
      // 2。当前步骤项不是第一项 currentIndex > 0
      // 3。当前检查项是检查项的第一项 currentCheckIndex === 0 或 当前检查项是检查项的最后一项，且当前步骤是最后一步 isLastStep && isLastCheck
      const first = this.count > 1
      const second = this.currentIndex > 0
      const thirdFirst = this.currentCheckIndex === 0
      const thirdSecond = this.isLastStep && this.isLastCheck
      const third = thirdFirst || thirdSecond
      return first && second && third
    }
  },
  created() {
    const firstText = `检查项${this.currentCheckIndex + 1}：`
    const secondText = this.results.question
    this.ttsText = firstText + secondText
  },
  methods: {
    handleActions(action, text) {
      switch (action) {
        case 'toPrev':
          this.$emit('to-prev')
          break
        case 'toNext':
          this.toNext(text)
          break
      }
    },
    toNext(text) {
      this.submitResults.answer = text
      this.submitResults.isExecuting = true
      this.submitResults.uploadAt = parseTime(new Date())
      this.$emit('to-next', this.submitResults)
      this.showMoreFunction = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.question-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: bold;
}
</style>
