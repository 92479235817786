<template>
  <div class="result-item-container">
    <div v-if="orderInfo" class="title-container">
      <el-tag v-if="orderInfo.towerWorkTypeValue" class="order-type">{{ orderInfo.towerWorkTypeValue }}</el-tag>
      <span class="order-name" v-html="getContent(`${orderInfo.taskWorkOrderName}（${orderInfo.taskWorkOrderCode}）`)" />
      <span class="equipment" v-html="getContent(`整机设备：${orderInfo.targetName}-${orderInfo.targetCode}`)" />
    </div>
    <div class="img-container">
      <div v-for="(item,index) in orderInfo.grouped" :key="index" class="item">
        <div class="img-container">
          <el-image :src="item.image" lazy @click="onClickImg(item.taskWorkOrderId)" />
          <ht-button class="download" type="primary" circle size="mini" icon="el-icon-download" @click="downloadFile(item.image)" />
        </div>
        <span class="title" v-html="`步骤名称：${getContent(item.title)}`" />
      </div>
    </div>
  </div>
</template>

<script>
import { getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import { buildRegexForHtml, replacePhraseWithLabel } from '@/utils/business'
import { downloadFile } from '@/utils'

export default {
  name: 'ResultItem',
  props: {
    orderInfo: { type: Object, default() { return {} } },
    keyword: { type: String, default: '' },
    isMatch: { type: Boolean, default: false }
  },
  methods: {
    getTowerWorkOrderType, buildRegexForHtml, replacePhraseWithLabel, downloadFile,
    getContent(content) {
      if (this.isMatch && this.keyword) {
        const part = this.buildRegexForHtml(this.keyword)
        return this.replacePhraseWithLabel(content, part, '<em>', '</em>')
      } else {
        return content
      }
    },
    onClickImg(taskWorkOrderId) {
      this.$emit('on-click', taskWorkOrderId)
    }
  }
}
</script>

<style scoped lang="scss">
.result-item-container{
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  .title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 15px 10px 0;
    font-weight: bold;
    .order-type{
      font-size: 16px;
      margin-right: 10px;
    }
    .order-name{
      flex: 1;
      margin-right: 10px;
    }
  }
  .img-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    .item{
      cursor: pointer;
      width: calc(16.7% - 15px);
      display: flex;
      flex-direction: column;
      .img-container{
        margin-bottom: 5px;
        position: relative;
        width: 100%;
        aspect-ratio: 4/3;
        .el-image{
          width: 100%;
          aspect-ratio: 4/3;
        }
        .download{
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 5px;
        }
      }
      .title {
        width: 100%;
        font-size: 14px;
        //white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
      }
    }
  }
}
</style>

<style>
em {
  color: #f56c6c;
}
</style>
