const ProjectCheckRouter = {
  path: '/projectSiteRecord',
  component: () => import('@/layout'),
  name: 'projectSiteRecordMenu',
  redirect: '/projectSiteRecord/report/list',
  meta: { title: '微缺陷记录', icon: 'report' },
  children: [
    {
      path: '/projectSiteRecord/report/list',
      component: () => import('@/views/projectSiteRecord/list'),
      name: 'projectSiteRecordReport',
      hidden: true,
      meta: { title: '微缺陷记录', breadcrumb: false, activeMenu: '/projectSiteRecord' }
    },
    {
      path: '/projectSiteRecord/report/view/:recordId',
      component: () => import('@/views/projectSiteRecord/view'),
      hidden: true,
      props: true,
      name: 'projectSiteRecordReportView',
      meta: { title: '微缺陷记录', activeMenu: '/projectSiteRecord' }
    }
  ]
}

export default ProjectCheckRouter
