var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "姓名" },
                model: {
                  value: _vm.listQuery.fullName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "fullName", $$v)
                  },
                  expression: "listQuery.fullName",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "登录名" },
                model: {
                  value: _vm.listQuery.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "userName", $$v)
                  },
                  expression: "listQuery.userName",
                },
              }),
              _vm._v(" "),
              _vm.activeName === "系统员工"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "账号状态" },
                      model: {
                        value: _vm.listQuery.activated,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "activated", $$v)
                        },
                        expression: "listQuery.activated",
                      },
                    },
                    _vm._l(_vm.activatedList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.createUser },
                },
                [_vm._v("新建用户")]
              ),
              _vm._v(" "),
              !_vm.showSelector
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-printer" },
                      on: { click: _vm.showSelectorQrCode },
                    },
                    [_vm._v("批量下载二维码")]
                  )
                : [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          disabled: _vm.selectedQrCode.length === 0,
                          icon: "el-icon-printer",
                        },
                        on: { click: _vm.myHandleBatchDownload },
                      },
                      [_vm._v("确定选择")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning" },
                        on: {
                          click: function ($event) {
                            _vm.showSelector = false
                          },
                        },
                      },
                      [_vm._v("取消选择")]
                    ),
                  ],
              _vm._v(" "),
              !_vm.showDelSelector
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", icon: "el-icon-delete" },
                      on: { click: _vm.showDelSelect },
                    },
                    [_vm._v("批量删除")]
                  )
                : [
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "success",
                          disabled: _vm.selectedDelUser.length === 0,
                          "confirm-title":
                            "是否确认删除" +
                            _vm.selectedDelUser.length +
                            "个用户？",
                        },
                        on: { confirmed: _vm.handleDelete },
                      },
                      [_vm._v("确定删除\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning" },
                        on: {
                          click: function ($event) {
                            _vm.showDelSelector = false
                          },
                        },
                      },
                      [_vm._v("取消选择")]
                    ),
                  ],
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm._l(_vm.tabs, function (i, index) {
            return _c("el-tab-pane", {
              key: index,
              attrs: { label: i, name: i },
            })
          }),
          _vm._v(" "),
          _c(
            "app-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              ref: "list",
              attrs: {
                list: _vm.list,
                "table-titles": _vm.labelToProps,
                "row-key": _vm.getRowKey,
                "bt-width": _vm.activeName === "系统员工" ? 100 : 0,
                page: _vm.page,
                "list-query": _vm.listQuery,
              },
              on: {
                "selection-change": _vm.selectionChange,
                pagination: _vm.getList,
                subOptionButton: _vm.subOptionButton,
                confirmMethod: _vm.subOptionButton,
                sortChange: _vm.sortChange,
              },
              scopedSlots: _vm._u(
                [
                  _vm.activeName === "系统员工"
                    ? {
                        key: "qrCodeOperation",
                        fn: function (slotProp) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-printer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.printQRCode(slotProp.info.index)
                                  },
                                },
                              },
                              [_vm._v("打印二维码")]
                            ),
                            _vm._v(" "),
                            _c(
                              "ht-popover-button",
                              {
                                attrs: {
                                  type: "text",
                                  "button-color": "green",
                                  icon: "el-icon-refresh",
                                  "confirm-title":
                                    "更新二维码后，原二维码将失效不可用，请谨慎操作！",
                                },
                                on: {
                                  confirmed: function ($event) {
                                    return _vm.refreshQRCode(
                                      slotProp.info.row,
                                      slotProp.info.index
                                    )
                                  },
                                },
                              },
                              [_vm._v("更新二维码\n        ")]
                            ),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _vm.showSelector || _vm.showDelSelector
                ? _c("ht-table-column-selection", {
                    attrs: { slot: "isSelection" },
                    slot: "isSelection",
                    model: {
                      value: _vm.selectedQrCode,
                      callback: function ($$v) {
                        _vm.selectedQrCode = $$v
                      },
                      expression: "selectedQrCode",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-print", { ref: "printQRCode" }, [
        _c("div", [
          _c("img", {
            attrs: { src: _vm.qRCodeImg, width: "400px", height: "400px" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "HtUserDialog",
          attrs: {
            title: "用户信息",
            width: _vm.isMobile ? "100%" : "500px",
            "close-on-click-modal": false,
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "userForm",
                  staticStyle: { "margin-right": "20px" },
                  attrs: {
                    model: _vm.userForm,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录名：", prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          disabled: !_vm.userForm.isCreate,
                          maxlength: "20",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.userForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "userName", $$v)
                          },
                          expression: "userForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名：", prop: "fullName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入姓名",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.userForm.fullName,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "fullName", $$v)
                          },
                          expression: "userForm.fullName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入电话",
                          maxlength: "12",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.userForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "mobile", $$v)
                          },
                          expression: "userForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.userForm.role,
                            callback: function ($$v) {
                              _vm.$set(_vm.userForm, "role", $$v)
                            },
                            expression: "userForm.role",
                          },
                        },
                        _vm._l(_vm.roleOptions, function (item) {
                          return _c("el-option", {
                            key: item.tag,
                            attrs: { label: item.name, value: item.tag },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "margin-left": "30%" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.$refs.HtUserDialog.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.isSubmitting, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitUserInfoUpdate("userForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "HtLoginEventsDialog",
          attrs: { title: "登录日志", width: _vm.isMobile ? "100%" : "600px" },
        },
        [
          _c(
            "ht-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoginEventsLoading,
                  expression: "isLoginEventsLoading",
                },
              ],
              ref: "loginEventsList",
              attrs: { slot: "content", data: _vm.loginEventsList },
              slot: "content",
            },
            [
              _c("ht-table-column", {
                attrs: { label: "序号", type: "index", fixed: "left" },
              }),
              _vm._v(" "),
              _vm._l(_vm.loginEventsLabelToProps, function (i) {
                return _c("ht-table-column", {
                  key: i.secondProp,
                  attrs: { label: i.label, prop: i.prop, width: i.minWidth },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            i.secondProp
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row[i.prop] && row[i.prop][i.secondProp]
                                        ? row[i.prop][i.secondProp]
                                        : "-"
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(row[i.prop] ? row[i.prop] : "-")
                                  ),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("ht-pagination", {
            attrs: {
              total: _vm.loginEventsPage.total,
              page: _vm.loginEventsListQuery.page,
              limit: _vm.loginEventsListQuery.perPage,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.loginEventsListQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.loginEventsListQuery, "perPage", $event)
              },
              pagination: _vm.getLoginEventsList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }