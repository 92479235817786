<template>
  <ht-table ref="list" v-loading="isLoading" :data="fileList">
    <ht-table-column label="序号" type="index" fixed="left" />
    <ht-table-column
      v-for="item in InstallationRemoveUploadFilesTitle"
      :key="item.prop"
      :label="item.label"
      :prop="item.prop"
      :min-width="item.width || 100"
      :fixed="item.fixed"
    >
      <template v-slot="{row,$index}">
        <template v-if="item.label === '文件'">
          <template v-if="row[item.prop].length > 0">
            <dw-file
              v-for="(fileItem, fileIndex) in row[item.prop]"
              :key="fileIndex"
              :url="fileItem.fileUrl"
              :name="fileItem.fileName"
              :class="{'is-not-last': fileIndex !== row[item.prop].length - 1}"
              show-del
              show-download
              show-text-del
              show-view
              @delete-file="onDeleteFile($index,fileIndex)"
              @view-file="downloadFile"
            />
          </template>
          <span v-else>{{ '-' }}</span>
        </template>
        <span v-else>{{ row[item.prop] || '-' }}</span>
      </template>
    </ht-table-column>
    <ht-table-column-operation width="300" fixed="right">
      <template v-slot="{row,$index}">
        <ht-upload-button
          button-type="text"
          button-icon="el-icon-upload2"
          :file-type="['doc', 'pdf', 'img']"
          key-prefix="workOrder"
          button-title="上传"
          :show-success-tip="false"
          @on-file-uploaded="onFileUploaded($index, arguments)"
        />
      </template>
    </ht-table-column-operation>
  </ht-table>
</template>

<script>
import { InstallationRemoveUploadFilesTitle } from '@/views/taskMgr/tableTitle'
import HtListContainer from '@/components/HtListContainer'
import { downloadFile } from '@/utils'

export default {
  name: 'DocumentList',
  extends: HtListContainer,
  props: {
    fileList: { type: Array, default: () => [] }
  },
  data() {
    return {
      InstallationRemoveUploadFilesTitle
    }
  },
  methods: {
    downloadFile,
    onDeleteFile(index, fileIndex) {
      this.$emit('on-delete-file', index, fileIndex)
    },
    onFileUploaded(index, $arguments) {
      this.$emit('on-upload-file', index, $arguments[1], $arguments[2])
    }
  }
}
</script>

<style scoped lang="scss">
.is-not-last{
  margin-bottom: 5px;
}
</style>
