var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "角色名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v("新建角色")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _c("ht-table-column", { attrs: { label: "角色名称", prop: "name" } }),
          _vm._v(" "),
          _c("ht-table-column", {
            attrs: { label: "远程指导类型", prop: "remoteGuidanceRole" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.RemoteGuidanceRoleTypeEnum[row.remoteGuidanceRole]
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ht-table-column", { attrs: { label: "角色描述", prop: "desc" } }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 130 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.openEditRoleDialog(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    row.tag !== "ADMIN" &&
                    row.tag !== "EXPERT" &&
                    row.tag !== "STAFF"
                      ? _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除该条数据？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onDelete(row)
                              },
                            },
                          },
                          [_vm._v("删除\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "createRoleDialog",
          attrs: {
            title: "新建角色",
            width: _vm.isMobile ? "100%" : "600px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeCreateRoleDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showCreateRoleForm
                ? _c("role-form", {
                    ref: "createRoleFormRef",
                    on: {
                      "cancel-submit": _vm.closeCreateRoleDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeCreateRoleDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "editRoleDialog",
          attrs: {
            title: "编辑角色",
            width: _vm.isMobile ? "100%" : "600px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeEditRoleDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showEditRoleForm
                ? _c("role-form", {
                    ref: "editRoleFormRef",
                    attrs: { "is-edit": "", "role-id": _vm.roleId },
                    on: {
                      "cancel-submit": _vm.closeEditRoleDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeEditRoleDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }