var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "ht-action-panel",
            { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "姓名" },
                    model: {
                      value: _vm.listQuery.fullName,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "fullName", $$v)
                      },
                      expression: "listQuery.fullName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "角色" },
                      model: {
                        value: _vm.listQuery.roles,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "roles", $$v)
                        },
                        expression: "listQuery.roles",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.tag,
                        attrs: { label: item.name, value: item.tag },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ht-action-panel",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("template", { slot: "left" }, [
                _c("span", { staticClass: "online-count-container" }, [
                  _vm._v("用户数量："),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.count)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "right" },
                [
                  _c(
                    "ht-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-phone" },
                      on: { click: _vm.createQuickMeeting },
                    },
                    [_vm._v("快速会议")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ht-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-phone" },
                      on: { click: _vm.createMultiPersonMeeting },
                    },
                    [_vm._v("多人会议")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "list-container",
            },
            [
              _c("contact-list", {
                ref: "contactListRef",
                attrs: { "user-list": _vm.list },
                on: { call: _vm.createSinglePersonMeeting },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showMeetingDialog
        ? _c("meeting-dialog", {
            ref: "meetingDialogRef",
            on: { "close-dialog": _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }