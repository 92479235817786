// 会议音视频track处理
import rtc from '@/views/newRemoteGuidance/rongCloud/rtc'
import { RCFrameRate, RCLocalTrack, RCResolution } from '@rongcloud/plugin-rtc'
import _ from 'lodash'

export default {
  methods: {
    // 本地操作
    async getLocalTrack({ audio = false, camera = false, screen = false }) {
      return new Promise(async(resolve, reject) => {
        if (audio && this.isShareAudio) {
          await this.createMicrophoneAudioTrack().catch(error => {
            // reject(error)
            reject({ reason: '创建麦克风轨迹error', error: error })
          })
        }
        if (camera && this.isShareCameraVideo) {
          await this.createCameraVideoTrack().catch(error => {
            // reject(error)
            reject({ reason: '创建摄像头轨迹error', error: error })
          })
        }
        if (screen && this.isShareScreenVideo) {
          await this.createScreenVideoTrack().catch(error => {
            // reject(error)
            reject({ reason: '创建屏幕轨迹error', error: error })
          })
        }
        resolve()
      })
    },
    async createMicrophoneAudioTrack() {
      return new Promise((resolve, reject) => {
        rtc.createMicrophoneAudioTrack('user').then(track => {
          this.localAudioTrack = track
          resolve(track)
        }).catch(err => {
          this.isShareAudio = false
          if (err.code === 53012) {
            this.$message({ message: `麦克风权限已被禁止使用，请开启相关权限后重试`, type: 'error', center: true })
          }
          reject(err)
        })
      })
    },
    async createCameraVideoTrack() {
      return new Promise(async(resolve, reject) => {
        const options = {}
        const index = this.videoConfigs.findIndex(item => item.resolutionName === this.videoResolution)
        if (index !== -1) {
          options.frameRate = this.videoConfigs[index].frameRate
          options.resolution = this.videoConfigs[index].resolution
          options.bitRate = this.videoConfigs[index].bitRate
        } else {
          options.frameRate = RCFrameRate.FPS_30
          options.resolution = RCResolution.W1280_H720
        }
        rtc.createCameraVideoTrack('user', options).then(track => {
          this.localVideoTrack = track
          setTimeout(() => {
            rtc.playTrack(this.localVideoTrack, document.getElementById('user_' + this.imUserId), 'user_' + this.imUserId)
            resolve(track)
          })
        }).catch(err => {
          this.isShareCameraVideo = false
          if (err.code === 53012) {
            this.$message({ message: `摄像头权限已被禁止使用，请开启相关权限后重试`, type: 'error', center: true })
          }
          reject(err)
        })
      })
    },
    async createScreenVideoTrack() {
      return new Promise(async(resolve, reject) => {
        const options = { frameRate: RCFrameRate.FPS_30, resolution: RCResolution.W1920_H1080 }
        rtc.createScreenVideoTrack('PublicScreen', options).then(track => {
          track.on(RCLocalTrack.EVENT_LOCAL_TRACK_END, () => {
            this.isShareScreenVideo = false
            this.screenVideoShareStop()
          })
          this.localScreenVideoTrack = track
          resolve(track)
        }).catch(err => {
          this.isShareScreenVideo = false
          if (err.code === 53012) {
            this.$message({ message: `屏幕共享取消`, type: 'warning', center: true })
          } else if (err.code === 53033) {
            this.$message({ message: `该页面屏幕共享权限未开启，请开启相关权限后重试`, type: 'error', center: true })
          }
          reject(err)
        })
      })
    },
    async publishAll({ audio = true, camera = true, screen = false }) {
      return new Promise(async(resolve) => {
        const track = []
        if (audio && this.isShareAudio && this.localAudioTrack) track.push(this.localAudioTrack)
        if (camera && this.isShareCameraVideo && this.localVideoTrack) track.push(this.localVideoTrack)
        if (screen && this.isShareScreenVideo && this.localScreenVideoTrack) track.push(this.localScreenVideoTrack)
        if (track) {
          await rtc.publish(this.room, track).then()
        }
        resolve(track)
      })
    },
    async unpublishAll({ audio = false, camera = false, screen = false }) {
      return new Promise(async(resolve, reject) => {
        const tracks = []
        if (audio && this.localAudioTrack) tracks.push(this.localAudioTrack)
        if (camera && this.localVideoTrack) tracks.push(this.localVideoTrack)
        if (screen && this.localScreenVideoTrack) tracks.push(this.localScreenVideoTrack)
        if (tracks && tracks.length > 0) {
          await rtc.unpublish(this.room, tracks).then(res => {
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    audioShareFun(isShare) {
      // 是否有本地音频流：有代表之前获取到了，开关使用unmute，mute
      if (this.localAudioTrack) {
        this.isShareAudio = isShare
        if (this.isShareAudio) {
          this.localAudioTrack.unmute()
          this.sendMicMuteChatRoomMsg(true, false)
        } else {
          this.localAudioTrack.mute()
          this.sendMicMuteChatRoomMsg(false, false)
        }
      } else {
        // 没有代表之前没有获取到，需要重新获取音频流
        this.isShareAudio = true
        this.getLocalTrack({ audio: true }).then(() => {
          this.publishAll({ audio: true })
        })
      }
    },
    cameraVideoShareFun(isShare) {
      if (this.isRemoteShareScreen) {
        this.$message({ message: `当前有用户正在进行屏幕共享，开关摄像头操作暂不可用`, type: 'error', center: true })
      } else {
        // 是否有本地视频流：有代表之前获取到了，开关使用unmute，mute
        if (this.localVideoTrack) {
          this.isShareCameraVideo = isShare
          if (this.isShareCameraVideo) {
            this.localVideoTrack.unmute()
            this.sendCamMuteChatRoomMsg(true, false)
          } else {
            this.localVideoTrack.mute()
            this.sendCamMuteChatRoomMsg(false, false)
          }
        } else {
          // 没有代表之前没有获取到，需要重新获取视频流
          this.isShareCameraVideo = true
          this.getLocalTrack({ audio: false, camera: false }).then(() => {
            this.publishAll({ audio: false, camera: false })
          })
        }
      }
    },
    // 远端操作
    async subscribe(room, tracks, isSubTiny = false, needRefresh = true) { // 订阅资源
      return new Promise((resolve, reject) => {
        // 新发布资源之前就有公屏，订阅后不做播放处理
        const beforeResult = this.remoteTracks.some(track => track.getTag() === 'PublicScreen')
        rtc.subscribe(room, tracks, this.remoteTracks, isSubTiny).then(async remoteTracks => {
          if (remoteTracks) {
            this.remoteTracks = remoteTracks
            // 新发布资源订阅后有公屏，只播放公屏
            const afterResult = this.remoteTracks.some(track => track.getTag() === 'PublicScreen')
            await this.refreshUserTracksStatus()
            // this.getRemoteAudioTracks()
            // this.getRemoteVideoTracks()
            // await this.getOnlineUserList()
            // await this.getMeetingUserInfo()
            this.getDeviceStatus(tracks)
            this.$nextTick(async() => {
              // 资源订阅后，针对于专家，如果会议里只有一个现场，则此现场进入聚焦模式
              // 当前如果有人在屏幕共享，不进行聚焦处理： beforeResult表示在本次资源订阅前已经有公屏了
              // 订阅小流isSubTiny
              // todo
              //  需要优化：后进入的专家必须去检查当前房间有没有被聚焦的用户getRoomAttributes
              //  但是当有被聚焦的用户进行的操作必须依赖this.remoteUserList,this.remoteUserList又来自getMeetingUserInfo。所以到底怎么办呢
              if (needRefresh) {
                rtc.getRoomAttributes(this.room, this.focusedGuidanceKeys).then(async data => {
                  if (!data.hasOwnProperty('focusedUserId') && this.isExpert(this.userInfo) && !(this.isShareScreenVideo || this.isRemoteShareScreen) && !beforeResult && !isSubTiny) {
                    await this.hasOneStaffToFocus()
                  }
                })
              }
              // 新发布资源之前有没有公屏beforeResult：true有，false无
              if (!beforeResult && needRefresh) {
                // 新发布资源订阅后才有的公屏，beforeResult=false, afterResult = true,只播放公屏画面
                if (afterResult) {
                  const index = this.remoteTracks.findIndex(track => track.getTag() === 'PublicScreen')
                  await this.playTrack(this.remoteTracks[index])
                } else {
                  // 没有公屏,当前放大窗口，只播放放大窗口的画面，当前都是小窗口，则全部播放
                  if (this.enLarge) {
                    setTimeout(async() => {
                      await rtc.playTrack(tracks[0], document.getElementById('enlargeVideo'), 'enlargeVideo')
                    })
                  } else {
                    tracks.forEach(track => {
                      this.playTrack(track)
                    })
                  }
                }
              } else {
                const index = this.remoteTracks.findIndex(track => track.getTag() === 'PublicScreen')
                this.remoteScreenVideoTrack = this.remoteTracks[index]
              }
              resolve(tracks)
            })
          }
        }).catch(err => {
          this.$log.myErrorLog(err)
          this.$message({ message: '网络环境差，无法正常进行音视频会议', duration: 1500, type: 'warning' })
          reject()
        })
      })
    },
    async unsubscribe(room, tracks, needRefresh = true) {
      return new Promise((resolve, reject) => {
        rtc.unsubscribe(room, tracks, this.remoteTracks).then(async remoteTracks => {
          if (remoteTracks) {
            this.remoteTracks = remoteTracks
            if (needRefresh) {
              // this.getRemoteAudioTracks()
              // this.getRemoteVideoTracks()
              // await this.getOnlineUserList()
              // this.getMeetingUserInfo()
              await this.refreshUserTracksStatus()
              if (!this.isRemoteShareScreen) {
                const result = tracks.some(track => track.getTag() === 'PublicScreen')
                if (result) {
                  this.remoteVideoTracks.forEach(track => {
                    this.$nextTick(() => {
                      this.playTrack(track)
                    })
                  })
                }
              }
            }
          }
          resolve()
        }).catch(err => {
          this.$log.myErrorLog(err)
          reject(err)
        })
      })
    },
    remoteUserListHasVideoTrack() {
      return this.remoteUserList.some(item => item.videoTrack && item.videoTrack.hasOwnProperty('_streamId'))
    },
    getRemoteAudioTracks() {
      this.remoteAudioTracks = _.cloneDeep(this.remoteTracks.filter(track => track.isAudioTrack()))
    },
    getRemoteVideoTracks() {
      this.remoteVideoTracks = _.cloneDeep(this.remoteTracks.filter(track => track.isVideoTrack()))
    },
    onTrackPublishFun(e) {
      const detail = e.detail
      // remoteStaffList > 1多现场会议
      this.subscribe(detail.room, detail.tracks, this.remoteStaffList.length > 1).then()
    },
    async onTrackUnPublishFun(e) {
      const detail = e.detail
      this.unsubscribe(detail.room, detail.tracks).then(async() => {
        // 取消发布的是被聚焦的用户的视频,并且不是公屏，则取消聚焦操作
        if (this.isFocusGuidance && this.focusedGuidanceInfo) {
          const unsubscribeVideoTracks = detail.tracks.filter(track => track.isVideoTrack())
          const result = unsubscribeVideoTracks.filter(track => track.getUserId() === this.focusedGuidanceInfo.focusedUserId && track.getTag() !== 'PublicScreen')
          if (result && result.length > 0) {
            this.focusGuidanceLocalFinish(false)
          }
        }

        // 取消发布的是含有公屏,含有公屏的取消发布，不做聚焦处理
        const resultPublicScreen = detail.tracks.some(track => track.getTag() === 'PublicScreen')
        if (resultPublicScreen) {
          // 远端视频播放
          this.remoteVideoTracks.forEach(track => {
            this.$nextTick(() => { this.playTrack(track) })
          })
          // 本地视频播放
          if (this.isStaff(this.userInfo)) {
            this.$nextTick(() => { this.playTrack(this.localVideoTrack) })
          }
        } else {
          // 不含公屏
          // 取消发布的如果包含视频，判断剩余的的track里视频流还剩几个，如果只剩一个，那么是需要进行放大的，订阅正常的流
          const resultVideo = detail.tracks.some(track => track.isVideoTrack())
          if (resultVideo && !this.enLarge) {
            const videoTracks = this.remoteTracks.filter(track => track.isVideoTrack())
            if (videoTracks.length === 1) {
              await this.subscribe(detail.room, videoTracks)
            }
          }
        }

        await this.refreshUserTracksStatus()
      })
    },
    async onTrackReadyFun(e) {
      const detail = e.detail
      if (detail.getTag() === 'PublicScreen') {
        this.remoteScreenVideoTrack = detail
      }
      // 放大窗口/聚焦指导需要取消订阅原来的小流改为订阅大流，为确保该大流已经准备后，在此处播放大流
      if (this.enLarge && detail.getUserId() === this.enLargeVideoInfo.imUserId) {
        setTimeout(async function() {
          await rtc.playTrack(e.detail, document.getElementById('enlargeVideo'), 'enlargeVideo')
        }, 0)
      } else {
        // 眼镜取消订阅再重新订阅需要等流TrackReady
        await this.playTrack(e.detail)
      }
      await this.refreshUserTracksStatus()
    },
    onTrackNotReadyFun(e) {
      // const detail = e.detail
      // if (detail.isVideoTrack) {
      //   if (detail.trackTag === 'publicScreenVideo') {
      //     this.$confirm('屏幕共享存在问题，其他用户可能无法正常播放，是否重新发布屏幕共享？', '提示', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning',
      //       center: true
      //     }).then(() => {
      //       this.TrackNotReadyToPublish({ screen: true })
      //     })
      //   } else {
      //     this.$confirm('视频存在问题，其他用户可能无法正常播放，是否重新发布视频？', '提示', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning',
      //       center: true
      //     }).then(() => {
      //       this.TrackNotReadyToPublish({ camera: true })
      //     })
      //   }
      // } else {
      //   this.$confirm('音频存在问题，其他用户可能无法正常播放，是否重新发布音频？', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //     center: true
      //   }).then(() => {
      //     this.TrackNotReadyToPublish({ audio: true })
      //   })
      // }
    },
    TrackNotReadyToPublish({ audio = false, camera = false, screen = false }) {
      this.unpublishAll({ audio: audio, camera: camera, screen: screen }).then(() => {
        this.getLocalTrack({ audio: audio, camera: camera, screen: screen }).then(() => {
          this.publishAll({ audio: audio, camera: camera, screen: screen })
        }).catch(() => {
          this.$message({ message: `获取${audio ? '音频' : camera ? '视频' : '屏幕共享'}失败`, duration: 1500, type: 'error' })
        })
      })
    },
    playTrack(track) {
      return new Promise((resolve, reject) => {
        if (track) {
          const userId = track.getUserId()
          const tag = track.getTag()
          if (this.isRemoteShareScreen && tag === 'PublicScreen') {
            setTimeout(() => {
              rtc.playTrack(track, document.getElementById('publicScreenVideo'), 'publicScreenVideo').then(() => {
                resolve()
              }).catch((isReady, code) => {
                if (!isReady) {
                  // this.unsubscribe(this.room, [track]).then(() => {
                  //   this.subscribe(this.room, [track])
                  // })
                  // the track is not ready to play, 发送消息给对方，重新发布
                  // this.sendTrackNotReadyMsg(track.getUserId(), track.getTrackId(), track.getTag(), track.isVideoTrack())
                } else if (code === 53021) {
                  reject()
                }
              })
            })
          } else {
            if (this.enLarge) {
              const imUserId = this.enLargeVideoInfo.userInfo.imUserId
              const id = userId === imUserId ? 'enlargeVideo' : 'user_' + userId
              setTimeout(() => {
                rtc.playTrack(track, document.getElementById(id), id).then(() => {
                  resolve()
                }).catch((isReady, code) => {
                  if (!isReady) {
                    // this.unsubscribe(this.room, [track]).then(() => {
                    //   this.subscribe(this.room, [track])
                    // })
                    // this.sendTrackNotReadyMsg(track.getUserId(), track.getTrackId(), track.getTag(), track.isVideoTrack())
                  } else if (code === 53021) {
                    reject()
                  }
                })
              })
            } else {
              const id = 'user_' + userId
              setTimeout(() => {
                rtc.playTrack(track, document.getElementById('user_' + userId), id).then(() => {
                  resolve()
                }).catch((isReady, code) => {
                  if (!isReady) {
                    // this.unsubscribe(this.room, [track]).then(() => {
                    //   this.subscribe(this.room, [track])
                    // })
                    // this.sendTrackNotReadyMsg(track.getUserId(), track.getTrackId(), track.getTag(), track.isVideoTrack())
                  } else if (code === 53021) {
                    reject()
                  }
                })
              })
            }
          }
        }
      })
    }
  }
}
