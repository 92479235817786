<template>
  <el-card style="margin: 20px;  min-width: 600px">
    <dw-title title="修改密码" />
    <div class="password-width">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px" status-icon>
        <el-row :gutter="20">
          <el-form-item label="请输入旧密码:" label-width="150px" prop="oldPassword">
            <el-input v-model="form.oldPassword" placeholder="请输入旧密码" clearable show-password />
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="请输入新密码:" label-width="150px" prop="newPassword">
            <verify-password :is-length-pass="isLengthPass" :is-complexity-pass="isComplexityPass" :password-verify-list="[isLengthPass, isComplexityPass]">
              <template slot="passwordInput">
                <el-input v-model="form.newPassword" placeholder="请输入新密码" clearable show-password @keyup.native="checkCapslock" @blur="capsTooltip = true" />
              </template>
            </verify-password>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="请再次输入新密码:" label-width="150px" prop="confirmPassword">
            <el-input v-model="form.confirmPassword" placeholder="请再次输入新密码" clearable show-password />
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item class="confirm-button-container">
            <ht-button type="primary" icon="el-icon-check" @click="submitForm('form')">确定</ht-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import { resetPassword } from '@/api/userInfo/resetPassword'
import VerifyPassword from '@/components/VerifyPassword'
import { isValidPasswordLength, isValidPasswordComplexity } from '@/utils/validate'

export default {
  name: 'ResetPassword',
  component: 'resetPassword',
  components: { VerifyPassword },
  data() {
    const checkPassword = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    const validatePasswordLength = (rule, value, callback) => {
      if (!(isValidPasswordLength(value))) {
        callback(new Error('密码长度为8-20个字符'))
      } else {
        callback()
      }
    }

    const validatePasswordComplexity = (rule, value, callback) => {
      if (!(isValidPasswordComplexity(value))) {
        callback(new Error('密码包含字母、数字、特殊字符三种符号的任意组合'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 8, max: 20, message: '长度在 8 到 10 个字符', trigger: 'blur' },
          { trigger: 'blur', validator: validatePasswordLength },
          { trigger: 'blur', validator: validatePasswordComplexity }
        ],
        confirmPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 8, max: 20, message: '长度在 8 到 10 个字符', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      },
      isLengthPass: false,
      isComplexityPass: false
    }
  },
  methods: {
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        this.capsTooltip = shiftKey && (key >= 'a' && key <= 'z') || !shiftKey && (key >= 'A' && key <= 'Z')
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false
      }
      this.isLengthPass = isValidPasswordLength(this.form.newPassword)
      this.isComplexityPass = isValidPasswordComplexity(this.form.newPassword)
    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          resetPassword(this.form.oldPassword, this.form.newPassword)
            .then(resp => {
              this.$confirm(`密码修改成功，请重新登录系统！`, '提示', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showCancelButton: false,
                showClose: false,
                type: 'warning',
                center: true
              }).then(async() => {
                try {
                  await this.$store.dispatch('user/logout')
                } finally {
                  this.$router.push(`/login`)
                }
              }).catch(async() => {
                try {
                  await this.$store.dispatch('user/logout')
                } finally {
                  this.$router.push(`/login`)
                }
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
  .password-width {
    width: 370px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .confirm-button-container {
    text-align: right;
  }
</style>
