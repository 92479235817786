var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container",
      class: {
        "is-mobile-login-container": _vm.isMobile,
        "is-prod-login-container": _vm.isProdLogin,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "login-bg-container",
          attrs: { id: "loginBgContainer" },
        },
        [
          _c("img", {
            staticClass: "login-bg-image",
            attrs: { id: "loginBgImage", src: _vm.getLoginBgImage, alt: "" },
          }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              class: { "prod-login-form": _vm.isProdLogin },
              attrs: {
                id: "loginForm",
                model: _vm.loginForm,
                rules: _vm.loginRules,
                autocomplete: "on",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "title-container" }, [
                _c("span", { staticClass: "top-title" }, [_vm._v("欢迎登录")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-describe" }, [_vm._v("登陆账号")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "username",
                    attrs: {
                      placeholder: "请输入用户名",
                      name: "username",
                      type: "text",
                      tabindex: "1",
                      "aauto-complete": "new-password",
                    },
                    model: {
                      value: _vm.loginForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "username", $$v)
                      },
                      expression: "loginForm.username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    key: _vm.passwordType,
                    ref: "password",
                    attrs: {
                      type: _vm.passwordType,
                      placeholder: "请输入密码",
                      name: "password",
                      tabindex: "2",
                      "auto-complete": "new-password",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.capsTooltip = false
                      },
                    },
                    nativeOn: {
                      keyup: [
                        function ($event) {
                          return _vm.checkCapslock($event)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      ],
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class":
                            _vm.passwordType === "password"
                              ? "eye"
                              : "eye_open",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "remember-password" },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.changeRememberStatus },
                      model: {
                        value: _vm.isPasswordRemembered,
                        callback: function ($$v) {
                          _vm.isPasswordRemembered = $$v
                        },
                        expression: "isPasswordRemembered",
                      },
                    },
                    [_vm._v("记住密码")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ht-button",
                {
                  staticClass: "login-button",
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    size: "large",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [_vm._v("登   录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }