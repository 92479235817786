var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-container", staticStyle: { "max-width": "100%" } },
    [
      _c("el-input", {
        staticClass: "keyword-input",
        staticStyle: { "max-width": "100%" },
        style: { width: _vm.width },
        attrs: { placeholder: _vm.placeholder },
        on: {
          change: _vm.onChange,
          focus: function ($event) {
            return _vm.onFocus(true)
          },
        },
        model: {
          value: _vm.keyword,
          callback: function ($$v) {
            _vm.keyword = $$v
          },
          expression: "keyword",
        },
      }),
      _vm._v(" "),
      _vm.showContent &&
      (_vm.getHistoryList.length > 0 || _vm.recordList.length > 0)
        ? _c(
            "div",
            {
              staticClass: "search-content-container",
              staticStyle: { "max-width": "100%" },
              style: { width: _vm.width },
            },
            [
              _vm.getHistoryList.length > 0
                ? _c("div", { staticClass: "history-container" }, [
                    _c(
                      "div",
                      { staticClass: "search-title-container" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.historyListTitle)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ht-popover-button",
                          {
                            staticClass: "del-button",
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除搜索历史？",
                            },
                            on: { confirmed: _vm.onDelHistory },
                          },
                          [_vm._v("删除历史")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "history-list-container" },
                      _vm._l(_vm.getHistoryList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "history-item",
                            on: {
                              click: function ($event) {
                                return _vm.onClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.recordList.length > 0
                ? _c("div", { staticClass: "record-container" }, [
                    _c("div", { staticClass: "search-title-container" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.recordListTitle)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "record-list-container" },
                      _vm._l(_vm.recordList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "record-item",
                            on: {
                              click: function ($event) {
                                return _vm.onClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }