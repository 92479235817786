var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "step-ui-detail-container",
          class: { "is-used-step-container": !_vm.isEdit },
          style: { height: _vm.height + "px" },
          attrs: { title: "hf_use_description" },
        },
        [
          _c(
            "div",
            { staticClass: "view-container" },
            [
              _c("top-section", {
                ref: "topSection",
                attrs: {
                  "is-focused-item": _vm.isFocusedItem,
                  "current-index": _vm.currentIndex,
                  count: _vm.count,
                  title: _vm.properties.title,
                  "show-more-function": _vm.showMoreFunction,
                  "must-upload-photo": _vm.properties.mustUploadPhoto,
                  "upload-photos": _vm.submitResults.uploadPhotos,
                  "active-img-index": _vm.activeImgIndex,
                  "button-disabled": _vm.buttonDisabled,
                  "show-photos": !_vm.showMiddlePhotos,
                },
                on: {
                  "more-function": function ($event) {
                    return _vm.moreFunction()
                  },
                  "native-play-tts": function ($event) {
                    return _vm.nativePlayTTS(_vm.ttsText)
                  },
                  "to-remote-guidance": function ($event) {
                    return _vm.toRemoteGuidance()
                  },
                  "to-ai-qa-assistant": function ($event) {
                    return _vm.toAiQaAssistant()
                  },
                  "native-take-photo": _vm.toTakePhoto,
                  "open-photo": _vm.openPhoto,
                  "close-photo": _vm.closePhoto,
                  "del-photo": _vm.delPhoto,
                  "prev-photo": _vm.prevPhoto,
                  "next-photo": _vm.nextPhoto,
                  "set-active-item": _vm.setActiveItem,
                  "jump-step": _vm.jumpStep,
                },
              }),
              _vm._v(" "),
              _c("middle-section", {
                ref: "middleSection",
                attrs: {
                  "is-focused-item": _vm.isFocusedItem,
                  "active-name": _vm.activeName,
                  "reference-image": _vm.properties.referenceImage,
                  text: _vm.properties.text,
                  "fact-desc": _vm.submitResults.factDesc,
                  "button-disabled": _vm.buttonDisabled,
                  height: _vm.getMiddleHeight,
                  "show-photos": _vm.showMiddlePhotos,
                  "must-upload-photo": _vm.properties.mustUploadPhoto,
                  "active-img-index": _vm.activeImgIndex,
                  "upload-photos": _vm.submitResults.uploadPhotos,
                },
                on: {
                  "select-reference-image": function ($event) {
                    return _vm.selectReferenceImage()
                  },
                  "select-text": _vm.selectText,
                  "select-photos": _vm.selectPhotos,
                  "set-active-item": _vm.setActiveItem,
                  "open-photo": _vm.openPhoto,
                  "close-photo": _vm.closePhoto,
                  "del-photo": _vm.delPhoto,
                  "prev-photo": _vm.prevPhoto,
                  "next-photo": _vm.nextPhoto,
                  "input-desc": function ($event) {
                    return _vm.inputText("factDesc")
                  },
                  "clear-fact-desc": _vm.clearFactDesc,
                  "open-reference-image": _vm.openReferenceImage,
                  "close-reference-viewer": _vm.closeReferenceViewer,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "middle-second-section" }, [
                _c("div", { staticClass: "middle-left-container" }, [
                  _c("span", { staticClass: "required-item" }, [_vm._v("*")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "sub-title result-title result-name",
                      class: {
                        "focused-item": _vm.isFocusedItem === "dataName",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.properties.dataName || "数据名称") + "："
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "result-reference-container" }, [
                    _c("span", { staticClass: "third-title" }, [
                      _vm._v("参考范围"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "min-value value-item",
                        class: {
                          "focused-item": _vm.isFocusedItem === "minValue",
                        },
                      },
                      [_vm._v(_vm._s(_vm.properties.minValue))]
                    ),
                    _vm._v("\n          一\n          "),
                    _c(
                      "span",
                      {
                        staticClass: "max-value value-item",
                        class: {
                          "focused-item": _vm.isFocusedItem === "maxValue",
                        },
                      },
                      [_vm._v(_vm._s(_vm.properties.maxValue))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "sub-title result-title result-unit",
                        class: {
                          "focused-item": _vm.isFocusedItem === "dataUnit",
                        },
                        staticStyle: { "margin-left": "5px" },
                      },
                      [_vm._v(_vm._s(_vm.properties.dataUnit || "单位"))]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "middle-right-container" },
                  [
                    _c("el-input", {
                      ref: "value",
                      staticClass: "value-input",
                      attrs: {
                        disabled: "",
                        type: "number",
                        placeholder: "输入数据",
                      },
                      model: {
                        value: _vm.submitResults.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.submitResults, "value", $$v)
                        },
                        expression: "submitResults.value",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "input-button square-circle-button",
                        attrs: { disabled: _vm.buttonDisabled },
                        on: {
                          click: function ($event) {
                            return _vm.inputText("value")
                          },
                        },
                      },
                      [_vm._v("输入数据")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("bottom-section", {
                ref: "bottomSection",
                attrs: {
                  count: _vm.count,
                  "current-index": _vm.currentIndex,
                  "is-last-step": _vm.isLastStep,
                  "button-disabled": _vm.buttonDisabled,
                },
                on: {
                  "to-prev": function ($event) {
                    return _vm.toPrev()
                  },
                  "to-next": function ($event) {
                    return _vm.toNext()
                  },
                  "save-record": function ($event) {
                    return _vm.saveRecord()
                  },
                  "submit-record": function ($event) {
                    return _vm.submitRecord()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }