export const WorkFlowListTitle = [
  { label: '工作流程名称', prop: 'name', width: 160 },
  { label: '工作流程类型', prop: 'type' },
  { label: '作业类型', prop: 'towerWorkType' },
  { label: '创建时间', prop: 'createdAt', width: 160 },
  { label: '修改时间', prop: 'updatedAt', width: 160 },
  { label: '创建人', prop: 'staff' }
]

export const PatrolWorkOrderListTitle = [
  // { label: '工单类型', prop: 'type' },
  { label: '工单编号', prop: 'code', width: 120 },
  { label: '工单名称', prop: 'name', width: 250 },
  { label: '作业类型', prop: 'towerWorkType' },
  { label: '工程', prop: 'targetInfo.projectName', width: 250 },
  // { label: '整机设备名称', prop: 'targetName' },
  { label: '整机产权编号', prop: 'targetCode' },
  { label: '工作流程', prop: 'taskTemplateName' },
  { label: '创建人', prop: 'staff.fullName' },
  { label: '创建时间', prop: 'createdAt', width: 160 },
  { label: '执行人', prop: 'executor.fullName' },
  { label: '开始执行时间', prop: 'startAt', width: 160 },
  { label: '完成时间', prop: 'finishAt', width: 160 },
  { label: '循环周期', prop: 'isLoop', width: 80 },
  { label: '整机设备名称', prop: 'targetName' },
  { label: '异常项', prop: 'abnormalCount', fixed: 'right', width: 70 },
  { label: '文件上传状态', prop: 'fileUploadStatus', fixed: 'right' },
  { label: '工单执行状态', prop: 'status', fixed: 'right' }
]

export const RepairWorkOrderListTitle = [
  { label: '工单类型', prop: 'type' },
  { label: '工单编号', prop: 'code', width: 120 },
  { label: '工单名称', prop: 'name' },
  { label: '维修设备', prop: 'targetName', width: 160 },
  { label: '工作流程', prop: 'taskTemplateName' },
  { label: '创建人', prop: 'staff.fullName' },
  { label: '创建时间', prop: 'createdAt', width: 160 },
  { label: '执行人', prop: 'executor.fullName' },
  { label: '开始执行时间', prop: 'startAt', width: 160 },
  { label: '完成时间', prop: 'finishAt', width: 160 },
  { label: '状态', prop: 'status', fixed: 'right' }
]

export const WorkOrderListTitle = [
  { label: '工单类型', prop: 'type' },
  { label: '工单编号', prop: 'code', width: 120 },
  { label: '工单名称', prop: 'name' },
  { label: '巡检设备', prop: 'targetName', width: 160 },
  { label: '工作流程', prop: 'taskTemplateName' },
  { label: '创建人', prop: 'staff.fullName' },
  { label: '创建时间', prop: 'createdAt', width: 160 },
  { label: '执行人', prop: 'executor.fullName' },
  { label: '开始执行时间', prop: 'startAt', width: 160 },
  { label: '完成时间', prop: 'finishAt', width: 160 },
  { label: '循环周期', prop: 'isLoop' },
  { label: '异常项', prop: 'abnormalCount', fixed: 'right' },
  { label: '状态', prop: 'status', fixed: 'right' }
]

export const MyWorkOrderListTitle = [
  { label: '工单类型', prop: 'type' },
  { label: '工单编号', prop: 'code', width: 120 },
  { label: '工单名称', prop: 'name', width: 200 },
  // { label: '整机设备名称', prop: 'targetName' },
  { label: '整机产权编号', prop: 'targetCode' },
  { label: '工作流程', prop: 'taskTemplateName' },
  { label: '创建人', prop: 'staff.fullName' },
  { label: '创建时间', prop: 'createdAt', width: 160 },
  { label: '执行人', prop: 'executor.fullName' },
  { label: '开始执行时间', prop: 'startAt', width: 160 },
  { label: '完成时间', prop: 'finishAt', width: 160 },
  { label: '循环周期', prop: 'isLoop' },
  { label: '整机设备名称', prop: 'targetName' },
  { label: '异常项', prop: 'abnormalCount', fixed: 'right' },
  { label: '状态', prop: 'status', fixed: 'right' }
]

export const PatrolWorkOrderResultListTitle = [
  { label: '步骤名称', prop: 'properties.title', align: 'left' },
  { label: '检查要求', prop: 'properties.text', width: 200, align: 'left' },
  { label: '图片', prop: 'results.uploadPhotos', width: 200 },
  { label: '情况描述', prop: 'results.factDesc', width: 200, align: 'left' },
  { label: '结果', prop: 'results.checkResult', width: 160, align: 'left' },
  { label: '定位', prop: 'results.location', width: 160, align: 'left' },
  { label: '上传时间', prop: 'uploadAt', width: 160 }
]

export const RepairWorkOrderResultListTitle = [
  { label: '故障代码', prop: 'properties.title' },
  { label: '处理方法', prop: 'properties.text' },
  { label: '补充处理方法', prop: 'results.uploadPhotos' },
  { label: '图片', prop: 'results.factDesc', width: 200 },
  { label: '结果', prop: 'results.checkResult' },
  { label: '定位', prop: 'results.location', width: 160 },
  { label: '上传时间', prop: 'uploadAt', width: 160 }
]

export const InstallationRemoveUploadFilesTitle = [
  { label: '材料名称', prop: 'name' },
  { label: '文件', prop: 'files' }
]

export const MobilePatrolWorkOrderTitle = [
  { label: '工程', prop: 'targetInfo.projectName', isCard: true, isBold: true, isLarge: true },
  { label: '设备', prop: 'targetName', isCard: true, isBold: true, isLarge: true },
  { label: '工单名称', prop: 'name', isCard: true },
  { label: '工单编号', prop: 'code', isCard: true },
  { label: '作业类型', prop: 'towerWorkType', isCard: true, isBold: true },
  { label: '工单执行状态', prop: 'status', isCard: true },
  { label: '文件上传状态', prop: 'fileUploadStatus', isCard: true }
]
