var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "info-container" },
        [
          _vm.distributionsInfo.length > 0
            ? [
                _c("div", { staticClass: "info" }, [
                  _vm._v("当前有"),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.distributionsInfo.length)),
                  ]),
                  _vm._v("设备分布点，请缩放地图查看全部分布点"),
                ]),
                _vm._v(" "),
                _vm.isFullscreen
                  ? _c("div", { staticClass: "info" }, [
                      _vm._v("点击地图上的点标记，查看设备分布信息"),
                    ])
                  : _vm._e(),
              ]
            : _c("div", { staticClass: "info" }, [
                _vm._v("当前无设备分布信息"),
              ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "map-container",
        staticStyle: { width: "100%" },
        style: { height: _vm.height },
        attrs: { id: _vm.mapId },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "equipmentsListDialog",
          attrs: { title: "详细信息", width: "700px" },
          on: { close: _vm.closeEquipmentsListDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showEquipmentsList
                ? _c("equipment-list", {
                    ref: "equipmentsList",
                    attrs: { "is-warehouse": _vm.isWarehouse },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }