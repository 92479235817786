var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "materialForm",
          staticStyle: { "margin-right": "20px" },
          attrs: {
            model: _vm.materialForm,
            rules: _vm.materialFormRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "品番：", prop: "code" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入品番",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.materialForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.materialForm, "code", $$v)
                  },
                  expression: "materialForm.code",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "品名：", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入品名",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.materialForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.materialForm, "name", $$v)
                  },
                  expression: "materialForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "供应商：", prop: "supplierName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入供应商",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.materialForm.supplierName,
                  callback: function ($$v) {
                    _vm.$set(_vm.materialForm, "supplierName", $$v)
                  },
                  expression: "materialForm.supplierName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "车型：", prop: "vehicleType" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入车型",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.materialForm.vehicleType,
                  callback: function ($$v) {
                    _vm.$set(_vm.materialForm, "vehicleType", $$v)
                  },
                  expression: "materialForm.vehicleType",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "图番：", prop: "illustrationNumber" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入图番",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.materialForm.illustrationNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.materialForm, "illustrationNumber", $$v)
                  },
                  expression: "materialForm.illustrationNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "部品图：", prop: "illustrationImg" } },
            [
              [
                _vm.materialForm.illustrationImg
                  ? _c("dw-image", {
                      attrs: { url: _vm.materialForm.illustrationImg },
                      on: { "delete-img": _vm.onDeleteImage },
                    })
                  : _c("ht-upload-button", {
                      attrs: {
                        "file-type": ["img"],
                        "key-prefix": "workflow",
                        "select-img": "",
                      },
                      on: { "on-file-uploaded": _vm.onImageUploaded },
                    }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "ht-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "ht-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("materialForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }