<template>
  <div>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-select v-model="listQuery.name" placeholder="设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.name" :value="item.value" />
        </el-select>
        <el-input v-model="listQuery.code" placeholder="产权编号" />
        <el-input v-model="listQuery.specModel" placeholder="规格型号" />
        <el-select v-model="listQuery.isWhole" placeholder="是否整机">
          <el-option v-for="(item,index) in EquipmentIsWholeEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in EquipmentStockListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile? false:item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '库存总数' || item.label === '仓库库存数' || item.label === '工程现存数'">{{ row[item.prop] }}</span>
          <template v-else-if="item.label === '是否异常'">
            <el-tag v-if="row.isWhole" :type="getIsAbnormal(row[item.prop]) && getIsAbnormal(row[item.prop]).style">{{ getIsAbnormal(row[item.prop]) && getIsAbnormal(row[item.prop]).value || '-' }}</el-tag>
            <span v-else>-</span>
          </template>
          <template v-else-if="item.label === '租赁状态'">
            <!--             整机显示租赁状态，部件不显示-->
            <el-tag v-if="row.isWhole" :type="EquipmentLeaseStatusStyleEnum[row[item.prop].name]">{{ EquipmentLeaseStatusEnum[row[item.prop].name] || '-' }}</el-tag>
            <span v-else>-</span>
          </template>
          <span v-else-if="item.label === '是否整机'">{{ row[item.prop] ? '是': '否' }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation v-if="isMobile" :width="110">
        <template v-slot="{row}">
          <dw-table-dropdown-buttons :buttons="row.buttons" :row="row" @handle-button-click="handleButtonClick(row,arguments)" />
        </template>
      </ht-table-column-operation>
      <ht-table-column-operation v-else width="300">
        <template v-slot="{row}">
          <ht-button :disabled="row.totalQuantity === 0" type="text" icon="el-icon-view" @click="onViewEquipmentStockInfo(row)">库存详情</ht-button>
          <ht-button :disabled="isCouldOutBound(row)" type="text" icon="el-icon-right" @click="onEquipmentOutbound(row)">出库</ht-button>
          <ht-button :disabled="isCouldInBound(row)" type="text" icon="el-icon-back" @click="onEquipmentInbound(row)">入库</ht-button>
          <ht-button type="text" icon="el-icon-truck" @click="onViewLogistics(row)">物流跟踪</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="equipmentStockInfoDialog" title="库存详情" :width="isMobile ? '100%' : '700px'" @close="closeEquipmentStockInfoDialog">
      <div slot="content">
        <equipment-stock-info v-if="showEquipmentStockInfo" ref="equipmentStockInfo" />
      </div>
    </ht-dialog>

    <ht-dialog ref="equipmentOutboundDialog" title="出库" :width="isMobile ? '100%' : '700px'" :close-on-click-modal="false" @close="closeEquipmentOutboundDialog">
      <div slot="content">
        <equipment-outbound-form v-if="showEquipmentOutboundForm" ref="equipmentOutboundForm" @cancel-submit="closeEquipmentOutboundDialog" @confirm-submit="closeEquipmentOutboundDialog(true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="equipmentInboundDialog" title="入库" :width="isMobile ? '100%' : '700px'" :close-on-click-modal="false" @close="closeEquipmentInboundDialog">
      <div slot="content">
        <equipment-inbound-form v-if="showEquipmentInboundForm" ref="equipmentInboundForm" @cancel-submit="closeEquipmentInboundDialog" @confirm-submit="closeEquipmentInboundDialog(true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="logisticsTraceDialog" title="物流跟踪" :width="isMobile ? '100%' : '800px'" @close="closeLogisticsTraceDialog">
      <div slot="content">
        <logistics-trace v-if="showLogisticsTrace" ref="logisticsTrace" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import EquipmentStockInfo from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentStockInfo'
import EquipmentOutboundForm from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentOutboundForm'
import EquipmentInboundForm from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentInboundForm'
import LogisticsTrace from '@/views/constructionEquipmentMgr/stockMgr/components/logisticsTrace'
import {
  EquipmentIsWholeEnum,
  EquipmentLeaseStatusEnum,
  EquipmentLeaseStatusStyleEnum,
  EquipmentTypesEnum
} from '@/views/constructionEquipmentMgr/enum'
import { EquipmentStockListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getEquipmentStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import { getIsAbnormal } from '@/utils/get-display'
import { mapGetters } from 'vuex'

export default {
  name: 'StockByEquipmentList',
  components: { EquipmentStockInfo, EquipmentOutboundForm, EquipmentInboundForm, LogisticsTrace },
  extends: HtListContainer,
  data() {
    return {
      EquipmentTypesEnum,
      EquipmentLeaseStatusEnum, EquipmentLeaseStatusStyleEnum, EquipmentIsWholeEnum,
      EquipmentStockListTitle,
      showEquipmentStockInfo: false,
      showEquipmentOutboundForm: false,
      showEquipmentInboundForm: false,
      showLogisticsTrace: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getIsAbnormal,
    getList() {
      this.beforeGetList()
      getEquipmentStocks(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.buttons = [
            { name: '库存详情', icon: 'el-icon-view', isPopoverButton: false, disabled: item.totalQuantity === 0 },
            { name: '出库', icon: 'el-icon-right', isPopoverButton: false, disabled: this.isCouldOutBound(item) },
            { name: '入库', icon: 'el-icon-back', isPopoverButton: false, disabled: this.isCouldInBound(item) },
            { name: '物流跟踪', icon: 'el-icon-truck', isPopoverButton: false }
          ]
        })
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    isCouldOutBound(row) {
      // 仓库库存数为0不可出库
      return row.quantity === 0 || row.isAbnormal
      // // 库存数为0不可出库
      // if (row.quantity === 0) { return true }
      // if (row.isWhole) {
      //   // 整机租用状态为 UNDER_LEASED 才可出库
      //   return row.leaseStatus && row.leaseStatus.name !== 'UNDER_LEASED'
      // } else {
      //   // 部件库存数大于0即可出库
      //   return false
      // }
    },
    isCouldInBound(row) {
      if (row.isWhole) {
        // 整机库存数大于0不可入库
        return row.quantity > 0
      } else {
        // 部件随时可入库
        return false
      }
    },
    handleButtonClick(row, $argument) {
      switch ($argument[0]) {
        case '库存详情':
          this.onViewEquipmentStockInfo(row)
          break
        case '出库':
          this.onEquipmentOutbound(row)
          break
        case '入库':
          this.onEquipmentInbound(row)
          break
        case '物流跟踪':
          this.onViewLogistics(row)
          break
      }
    },
    onViewEquipmentStockInfo(row) {
      this.showEquipmentStockInfo = true
      this.$refs.equipmentStockInfoDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.equipmentStockInfo.initInfo(row)
      })
    },
    onEquipmentOutbound(row) {
      this.showEquipmentOutboundForm = true
      this.$refs.equipmentOutboundDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.equipmentOutboundForm.initInfo(row)
      })
    },
    onEquipmentInbound(row) {
      this.showEquipmentInboundForm = true
      this.$refs.equipmentInboundDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.equipmentInboundForm.initInfo(row)
      })
    },
    onViewLogistics(row) {
      this.showLogisticsTrace = true
      this.$refs.logisticsTraceDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.logisticsTrace.initInfo(row)
      })
    },
    closeEquipmentStockInfoDialog() {
      this.showEquipmentStockInfo = false
      this.$refs.equipmentStockInfoDialog.dialogVisible = false
    },
    closeEquipmentOutboundDialog(isRefresh = false) {
      this.showEquipmentOutboundForm = false
      this.$refs.equipmentOutboundDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    },
    closeEquipmentInboundDialog(isRefresh = false) {
      this.showEquipmentInboundForm = false
      this.$refs.equipmentInboundDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    },
    closeLogisticsTraceDialog() {
      this.showLogisticsTrace = false
      this.$refs.logisticsTraceDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped>

</style>
