<template>
  <el-card style="margin: 20px">
    <el-card style="margin-bottom: 50px">
      <div class="title-card-container">
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_50">编号:</span>
          <span class="textStyle" style="color: #48a0ff;font-weight: bold">{{ securityCheckDetail.recordNo }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_50">时间:</span>
          <span class="textStyle">{{ securityCheckDetail.createdAt ? securityCheckDetail.createdAt : '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_50">巡查人:</span>
          {{ securityCheckDetail.staff ? securityCheckDetail.staff.fullName : '-' }}
        </div>
        <div class="title-card-item-full-line">
          <span class="labelStyle labelWidth_50">备注:</span>
          <template v-if="!editComment">
            <span class="textStyle">{{ securityCheckDetail.comment ? securityCheckDetail.comment : '-' }}</span>
            <el-link font-size="24px" type="primary" @click="editComment = true">修改备注</el-link>
          </template>
          <span v-else>
            <el-input
              v-model="comment"
              type="textarea"
              :autosize="{ minRows: 4 }"
              placeholder="请输入备注"
            />
            <el-link type="primary" @click="confirmUpdateComment">确定提交</el-link>
            <el-link type="danger" @click="editComment = false">取消输入</el-link>
          </span>
        </div>
      </div>
    </el-card>
    <el-divider><i class="el-icon-chat-line-square" /> 视频详情</el-divider>
    <div style="width: 60%;margin: 0 auto">
      <video-player
        v-if="securityCheckDetail.videoUrl"
        ref="videoPlayer"
        style="margin-left: 20px;"
        class="vjs-custom-skin"
        :options="playerOptions"
      />
    </div>
  </el-card>
</template>

<script>
import { getSecurityCheckDetailApi, updateSecurityCheckRecords } from '@/api/secureEnvironmentMgr/securityCheck'
import HtListContainer from '@/components/HtListContainer'

export default {
  extends: HtListContainer,
  props: {
    recordId: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      checkList: [],
      showSelector: false,
      selectedChatRecord: [],
      securityCheckDetail: {},
      labelToProps: [
        {
          label: '发送人',
          prop: 'fromUser'
        },
        {
          label: '消息类型',
          prop: 'mediaType'
        },
        {
          label: '内容',
          prop: 'content'
        },
        {
          label: '时间',
          prop: 'chatTime'
        }
      ],
      editComment: false,
      comment: undefined,
      playerOptions: {}
    }
  },
  created() {
    this.getSecurityCheckDetail()
  },
  methods: {
    getSecurityCheckDetail() {
      getSecurityCheckDetailApi(this.recordId, this.listQuery).then((resp) => {
        this.securityCheckDetail = resp
        this.comment = this.securityCheckDetail.comment
        this.videoInfo(resp)
      })
    },
    showSelectorOfChatRecord() {
      this.showSelector = true
      this.$message({
        message: '请在表格中勾选需要生成报告的记录',
        center: true,
        type: 'warning'
      })
    },
    onSelectionChange(val) {
      this.selectedChatRecord = val
    },
    confirmUpdateComment() {
      this.editComment = false
      const data = { comment: this.comment }
      updateSecurityCheckRecords(this.recordId, data).then(() => {
        this.$message({
          message: '更新成功～',
          center: true,
          type: 'success'
        })
        this.getSecurityCheckDetail()
      })
    },
    videoInfo(value) {
      this.playerOptions = {
        // videojs options
        muted: true,
        fluid: true,
        language: 'ch',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          src: value.videoUrl
        }],
        notSupportedMessage: '此视频无法播放，请稍后再试',
        controlBar: {
          timeDivider: false,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
