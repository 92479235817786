<template>
  <div class="flow-steps-container">
    <div class="start-container step-container">
      <img :src="Start" alt="" class="step-img">
      <img :src="DownArrow" alt="" class="arrow">
    </div>
    <draggable :list="steps">
      <div v-for="(item, index) in steps" :key="index" class="step-container" @click="editStep(index)">
        <div class="index"> {{ index +1 }}</div>
        <img :src="item.img" alt="" class="step-img" :class="{'is-checked-step-img': isCheckedIndex === index}">
        <img v-if="index !== steps.length - 1" :src="DownArrow" alt="" class="arrow">
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Start from '@/assets/image/workflow/start.png'
import DownArrow from '@/assets/image/workflow/down_arrow.png'
import Del from '@/assets/image/common/delete_white_no_cicle.png'

export default {
  name: 'ProcessExample',
  components: { draggable },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    isCheckedIndex: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      Start, DownArrow, Del
    }
  },
  methods: {
    editStep(index) {
      this.$emit('edit-step', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.flow-steps-container{
  padding: 20px 10px 30px;
  background: #ffffff;
  height: calc(100% - 50px);
  max-height: 700px;
  overflow-y: auto;
  .step-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .index {
      position: absolute;
      top: 40px;
      color: #fff;
      padding: 0 5px;
      font-weight: bold;
      text-align: center;
      border-radius: 15px;
      background: #f56c6c;
      cursor: pointer;
    }
    .step-img{
      width: 170px;
      cursor: pointer;
    }
    .is-checked-step-img{
      border: 3px solid #f56c6c;
      border-radius: 8px;
    }
    .arrow{
      height: 40px;
      margin: 0 auto 5px;
    }
  }
  .start-container{
    .step-img {
      cursor: default;
      width: 80px;
    }
  }
 }
</style>
