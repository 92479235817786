<template>
  <el-card>
    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in RoutePatrolMgrListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
      >
        <template v-slot="{row}">
          <el-link
            v-if="item.label === '视频链接'"
            type="primary"
            :disabled="row.videoUrls.length < 1"
            @click="toggleVideoUrlsDialog(true,row.videoUrls)"
          >{{ row.videoUrls.length }}条</el-link>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />
    <ht-dialog ref="videoListDialog" title="视频链接" width="600px" @close="toggleVideoUrlsDialog(false)">
      <video-list v-if="showVideoList" slot="content" :video-list="videoList" />
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { RoutePatrolMgrListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getTpmRoutePatrolList } from '@/api/tpmRoutePatrolMgr/checklist'
import VideoList from './components/videoList'

export default {
  name: 'Index',
  components: { VideoList },
  extends: HtListContainer,
  data() {
    return {
      RoutePatrolMgrListTitle,
      showVideoList: false,
      videoList: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getTpmRoutePatrolList(this.listQuery).then(resp => {
        resp.items.forEach(item => {
          const videoUrls = []
          if (item.videoRecords) {
            Object.keys(item.videoRecords).forEach(key => {
              item.videoRecords[key].forEach(videoUrl => { videoUrls.push({ url: videoUrl }) })
            })
          }
          item.videoUrls = videoUrls
        })
        this.afterGetList(resp)
      }).catch(() => { this.isLoading = false })
    },
    toggleVideoUrlsDialog(visible = true, videoUrls) {
      this.$refs.videoListDialog.dialogVisible = visible
      this.showVideoList = visible
      this.videoList = videoUrls
    }
  }
}
</script>

<style scoped>

</style>
