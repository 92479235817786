<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import echarts from 'echarts'
import { debounce } from '@/utils'
import variables from '@/styles/variables.scss'

function getFormatter(params) {
  const data = params[0]
  const name = data.axisValue
  const radio = data.data.value
  return data.marker + `工程名称：${name}` + '<br>' + `工程进度：${radio}%`
}

export default {
  name: 'ProjectProgressChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true }
  },
  data() {
    return {
      chart: null,
      option: {
        dataZoom: [
          {
            type: 'slider',
            yAxisIndex: 0,
            realtime: true,
            minValueSpan: 9,
            maxValueSpan: 9,
            left: 0,
            textStyle: null,
            width: 15
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function(params) {
            return getFormatter(params)
          },
          position: function(point, params, dom, rect, size) {
            // 固定在顶部
            return ['10%', point[1]]
          }
        },
        grid: {
          top: '12%',
          left: '8%',
          right: '120px',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          name: '工程进度（%）',
          type: 'value',
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: { show: false } // 刻度线
        },
        yAxis: {
          name: '工程名称',
          type: 'category',
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          data: [],
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false }
        },
        series: [
          {
            data: [],
            type: 'bar',
            itemStyle: {
              color: variables.primaryColor
            },
            label: {
              show: true,
              formatter: '{b}（{c}%）',
              fontSize: 12,
              fontWeight: 'bolder'
            }
          }
        ]
      }
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(names, ratios) {
      this.option.yAxis.data = names
      this.option.series[0].data = ratios.map(item => {
        return {
          value: item,
          label: { position: item > 30 ? 'insideLeft' : 'right' }
        }
      })
      this.option.dataZoom[0].startValue = names.length - 1
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
