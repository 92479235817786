<template>
  <div class="user-info" :class="{'in-meeting-user-info': isInMeeting}">
    <template v-if="!isInMeeting">
      <template v-if="userInfo">
        <el-image v-if="userInfo.avatarUrl" class="avatar" :src="userInfo.avatarUrl || AvatarBlue" />
        <dw-default-avatar v-else class="avatar" :full-name="userInfo.fullName" :width="40" :font-size="14" style="margin-left: 25px;" />
        <span class="fullName">{{ `${userInfo.fullName}` }}</span>
      </template>
      <div class="status-container" @click="queryConnect">
        <el-image
          class="connected-status"
          :class="{'disconnected-status': !imIsConnected}"
          :src="imIsConnected ? Connected : Disconnected"
        />
        <span
          class="hint"
          :class="{'connected-hint': imIsConnected, 'disconnected-hint': !imIsConnected}"
        >{{ imIsConnected ? '点击断开': '点击连接' }}</span>
      </div>
    </template>
    <el-image
      :src="Minimize"
      class="minimized"
      title="最小化"
      :class="{'in-meeting-minimized': isInMeeting}"
      :style="{zIndex: isBeingInvited && isInMeeting ? 9999 : 1}"
      @click="onMinimized"
    />
    <!--    <el-button-->
    <!--      type="primary"-->
    <!--      class="minimized close"-->
    <!--      :class="{'in-meeting-minimized': isInMeeting}"-->
    <!--      :style="{zIndex: isBeingInvited && isInMeeting ? 9999 : 1}"-->
    <!--      @click="onMinimized"-->
    <!--    >最小化</el-button>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Connected from '@/assets/image/newRemoteGuidance/externalCall/connected.png'
import Disconnected from '@/assets/image/newRemoteGuidance/externalCall/disconnected.png'
import AvatarBlue from '@/assets/image/newRemoteGuidance/avatar_blue.jpg'
import Close from '@/assets/image/newRemoteGuidance/close.png'
import Minimize from '@/assets/image/newRemoteGuidance/minimize.png'
import { oneStopLoginSinomaFun } from '@/views/newRemoteGuidance/js/extarnalLogin'

export default {
  name: 'UserInfo',
  props: {
    sessionKey: { type: String, default: undefined }
  },
  data() {
    return {
      Connected, Disconnected, AvatarBlue, Close, Minimize
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'imIsConnected', 'isBeingInvited', 'isInMeeting'])
  },
  methods: {
    onMinimized() {
      window.parent.postMessage({ type: 'IsMinimized', params: { isMinimized: true }}, '*')
    },
    queryConnect() {
      if (!this.imIsConnected) {
        this.$confirm(`您的账户已断开通信，是否尝试重新连接`, '通知', {
          confirmButtonText: '重新连接',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          showClose: false,
          type: 'warning',
          center: true
        }).then(async() => {
          await oneStopLoginSinomaFun(this.sessionKey)
        })
      } else {
        this.$confirm(`您的账户通信连接正常，是否要断开连接`, '通知', {
          confirmButtonText: '断开连接',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          showClose: false,
          type: 'warning',
          center: true
        }).then(async() => {
          this.$emit('disconnect')
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user-info{
  display: flex;
  flex-direction: row;
  align-items: center;

  .status-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 20px;

    .hint{
      margin-top: 2px;
      font-size: 12px;
      font-weight: bold;
    }

    .connected-hint{
      color: #1890FF;
    }

    .disconnected-hint{
      color: #f56c6c;
    }

    .connected-status{
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .disconnected-status{
      cursor: pointer;
    }
  }

  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullName{
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    .userName{
      font-weight: normal;
      font-size: 14px;
    }
  }

  .minimized{
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    position: absolute;
    cursor: pointer;
  }

  .in-meeting-minimized{
    top: 8px;
  }
}
.in-meeting-user-info{
  position: absolute;
  top: 8px;
  right: 0;
}
</style>
