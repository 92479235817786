class BaseResultsClass {
  constructor(results) {
    this.uploadPhotos = results.uploadPhotos
    this.factDesc = results.factDesc
  }
}

export class ResultRecordTypeResultsClass extends BaseResultsClass {
  constructor(results) {
    super(results)
    this.checkResult = results.checkResult
  }
}

const BasePatrolResult = {
  location: null,
  uploadPhotos: [],
  factDesc: []
}

// 通用记录 UniversalRecordType
export const UniversalRecordTypeResults = {
  ...BasePatrolResult
}

// 判断结果 ResultRecordType
export const ResultRecordTypeResults = {
  ...BasePatrolResult,
  checkResult: null
}

// 记录数值 ValueRecordType
export const ValueRecordTypeResults = {
  ...BasePatrolResult,
  value: null
}

// 隐患登记 HiddenDangerRecordType
export const HiddenDangerRecordTypeResults = {
  ...BasePatrolResult,
  errorLevel: null,
  checkType: null
}

// 事故登记 AccidentRecordType
export const AccidentRecordTypeResults = {
  ...BasePatrolResult,
  accidentType: null
}

export const PhotoRecordTypeResults = {
  location: null,
  uploadPhotos: []
}

// 快速巡检 QuickPatrolRecordType
export const QuickPatrolRecordTypeResults = {
  location: null,
  checkItems: []
}

// 商品上架 GoodsInboundType
export const GoodsInboundTypeResults = {
  warehouse: null,
  warehouseName: null,
  boxes: [],
  carCode: null
}

// 拣货任务 GoodsPickingType
export const GoodsPickingTypeResults = {
  area: null,
  areaName: null,
  floor: null,
  floorName: null,
  task: null,
  taskCode: null,
  goods: [],
  boxCode: null
}

// 扫码确认 ScanCodeAndConfirmType
export const ScanCodeAndConfirmTypeResults = {
  location: null,
  carFrameNum: null,
  nskMaterialName: null,
  batchNum: null,
  checkAt: null
}

// 语音记录数值 VoiceRecordValueType
export const VoiceRecordValueTypeResults = {
  ...BasePatrolResult,
  value: null
}

// OCR记录数值 OCRRecordValueType
export const OCRRecordValueTypeResults = {
  ...BasePatrolResult,
  value: null
}

// 蓝牙记录数值 KeyboardRecordValueType
export const KeyboardRecordValueTypeResults = {
  ...BasePatrolResult,
  value: null
}
