<template>
  <span>
    <el-autocomplete
      v-model="searchForm.searchStaffName"
      :fetch-suggestions="querySearchAsync"
      :placeholder="staffPlaceholder"
      clearable
      :trigger-on-focus="false"
      :hide-loading="true"
      style="margin-right: 4px"
      @select="handleSelect"
    />
    <el-date-picker
      v-model="searchForm.searchDate"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      placement="bottom-start"
      @change="selectDate"
    />
  </span>
</template>

<script>
import { fuzzySearchStaff } from '@/api/systemMgr/userMgr'

export default {
  name: 'CommonSearch',
  props: {
    staffPlaceholder: {
      type: String,
      default: '巡查人'
    }
  },
  data() {
    return {
      searchForm: {
        searchStaffName: undefined,
        searchDate: undefined
      }
    }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.length !== 0) {
        fuzzySearchStaff(queryString)
          .then((resp) => {
            const list = resp
            list.forEach(item => { item.value = item.fullName })
            cb(list)
            if (list.length === 0) {
              list.push({
                value: '未搜索到结果'
              })
            }
          })
      }
    },
    handleSelect(item) {
      this.searchForm.searchStaffName = item.fullName
      this.$emit('handleSelect', item.id.toString(), item.fullName)
    },
    selectDate() {
      if (this.searchForm.searchDate) {
        this.$emit('selectDate', this.searchForm.searchDate)
      }
    }
  }
}
</script>

<style scoped>

</style>
