<template>
  <div>
    <ht-action-panel :on-search="onSearch" :on-clear="onClearSearch">
      <template slot="left">
        <el-input v-model="listQuery.code" placeholder="工单编号" />
        <el-input v-model="listQuery.name" placeholder="工单名称" />
        <el-input v-model="listQuery.projectName" placeholder="工程" />
        <!--        <el-select v-model="listQuery.targetName" placeholder="整机设备名称">-->
        <!--          <el-option v-for="(item,index) in EquipmentTypesEnum.filter(item => item.isWhole)" :key="index" :label="item.value" :value="item.name" />-->
        <!--        </el-select>-->
        <el-input v-model="listQuery.targetCode" placeholder="整机产权编号" />
        <el-select v-model="listQuery.towerWorkType" placeholder="作业类型">
          <el-option v-for="(item,index ) in TowerWorkOrderTypeObjectEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
        <el-date-picker v-model="createdAt" type="daterange" range-separator="至" value-format="yyyy-MM-dd" placement="bottom-start" start-placeholder="创建时间" end-placeholder="创建时间" />
        <el-date-picker v-model="finishedAt" type="daterange" range-separator="至" value-format="yyyy-MM-dd" placement="bottom-start" start-placeholder="完成时间" end-placeholder="完成时间" />
        <el-select v-model="listQuery.status" placeholder="工单执行状态">
          <el-option v-for="(value,key ) in TaskWorkOrderStatusEnum" :key="key" :label="value" :value="key" />
        </el-select>
        <el-select v-model="listQuery.fileUploadStatus" placeholder="文件上传状态">
          <el-option v-for="(value,key ) in FileUploadStatusEnum" :key="key" :label="value" :value="key" />
        </el-select>
        <el-select v-model="listQuery.targetName" placeholder="整机设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum.filter(item => item.isWhole)" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <ht-button type="success" icon="el-icon-plus" @click="onCreate">新建工单</ht-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer" @row-click="handleRowClick">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in PatrolWorkOrderListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '工单类型'">{{ getTaskTempType(row[item.prop]) ? getTaskTempType(row[item.prop]).value : '巡检' }}</span>
          <span v-else-if="item.label === '作业类型'">{{ getTowerWorkOrderType(row[item.prop]) ? getTowerWorkOrderType(row[item.prop]).value : '-' }}</span>
          <span v-else-if="item.label === '工程'">{{ row.targetInfo && row.targetInfo.projectName || '-' }}</span>
          <span v-else-if="item.label === '创建人'">{{ row.staff.fullName }}</span>
          <span v-else-if="item.label === '执行人'">{{ row.executor.fullName }}</span>
          <span v-else-if="item.label === '循环周期'">{{ LoopEnum[row[item.prop]] }}</span>
          <span v-else-if="item.label === '异常项'" :style="{color: row[item.prop] ? 'red' : ''}">{{ `${row[item.prop]}项` }}</span>
          <el-tag v-else-if="item.label === '工单执行状态'" :type="TaskWorkOrderStatusStyleEnum[row[item.prop]]">{{ TaskWorkOrderStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <el-tag v-else-if="item.label === '文件上传状态' && row[item.prop]" class="status" :type="FileUploadStatusStyleEnum[row[item.prop]]">{{ FileUploadStatusEnum[row[item.prop]] }}</el-tag>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation v-if="isMobile" :width="110">
        <template v-slot="{row, $index}">
          <dw-table-dropdown-buttons :buttons="row.buttons" :row="row" @handle-button-click="handleButtonClick(row,arguments)" />
        </template>
      </ht-table-column-operation>
      <ht-table-column-operation v-else :width="300">
        <template v-slot="{row, $index}">
          <ht-button type="text" icon="el-icon-view" @click="onView(row)">详情</ht-button>
          <ht-popover-button type="text" :danger-text="false" :disabled="!row.isLoop" icon="el-icon-video-pause" confirm-title="请确认是否要取消循环？" @confirmed="onCancelLoop(row)">取消循环</ht-popover-button>
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该条数据？" @confirmed="onDelete(row)">删除</ht-popover-button>
          <ht-button :loading="loadingIndex === $index" :disabled="(loadingIndex !== -1 && loadingIndex !== $index) || row.status !== 'FINISHED'" type="text" icon="el-icon-document" @click="onExportPdf(row, $index)">导出工单</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />

    <ht-dialog ref="createOrderFormDialog" title="新建工单" :width="isMobile? '100%' :'600px'" :close-on-click-modal="false" @close="closeCreateOrderDialog">
      <div slot="content">
        <order-form v-if="showCreateOrderForm" @cancel-submit="closeCreateOrderDialog" @confirm-submit="submitCreateOrderForm" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import OrderForm from '@/views/taskMgr/patrolWorkOrderMgr/components/orderForm'
import { deleteWorkOrder, getWorkOrders, postWorkOrderPdf, putWorkOrder } from '@/api/taskMgr/workOrder'
import { PatrolWorkOrderListTitle } from '@/views/taskMgr/tableTitle'
import { getTaskTempType, getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import { LoopEnum, TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum, TowerWorkOrderTypeObjectEnum, FileUploadStatusEnum, FileUploadStatusStyleEnum } from '@/views/taskMgr/enum'
import { getLocation, getValueRecordResult, getResultTextColor } from '@/utils/get-display'
import { EquipmentTypesEnum } from '@/views/constructionEquipmentMgr/enum'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkOrderList',
  components: { OrderForm },
  extends: HtListContainer,
  data() {
    return {
      EquipmentTypesEnum,
      PatrolWorkOrderListTitle,
      LoopEnum, TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum, TowerWorkOrderTypeObjectEnum, FileUploadStatusEnum, FileUploadStatusStyleEnum,
      createdAt: [],
      finishedAt: [],
      showCreateOrderForm: false,
      loadingIndex: -1
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getResultTextColor, getValueRecordResult, getLocation,
    getTaskTempType, getTowerWorkOrderType,
    onClearSearch() {
      this.createdAt = []
      this.finishedAt = []
      this.onClear()
    },
    getList() {
      this.listQuery.createdStartAt = this.createdAt[0]
      this.listQuery.createdEndAt = this.createdAt[1]
      this.listQuery.finishedStartAt = this.finishedAt[0]
      this.listQuery.finishedEndAt = this.finishedAt[1]
      this.listQuery.type = 'PATROL'
      this.beforeGetList()
      getWorkOrders(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.buttons = [
            // mobile不提供导出工单
            { name: '详情', icon: 'el-icon-view', isPopoverButton: false },
            { name: '取消循环', icon: 'el-icon-video-pause', isPopoverButton: true, disabled: !item.isLoop, isDangerText: false, confirmTitle: '请确认是否要取消循环?' },
            { name: '删除', icon: 'el-icon-delete', isPopoverButton: true, isDangerText: true, confirmTitle: '请确认是否要删除该条数据?' }
          ]
        })
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onCreate() {
      this.showCreateOrderForm = true
      this.$refs.createOrderFormDialog.dialogVisible = true
    },
    closeCreateOrderDialog() {
      this.showCreateOrderForm = false
      this.$refs.createOrderFormDialog.dialogVisible = false
    },
    submitCreateOrderForm() {
      this.closeCreateOrderDialog()
      this.getList()
    },
    handleRowClick(row, column) {
      if (column.label !== '操作') {
        this.onView(row)
      }
    },
    handleButtonClick(row, $argument) {
      switch ($argument[0]) {
        case '详情':
          this.onView(row)
          break
        case '取消循环':
          this.onCancelLoop(row)
          break
        case '删除':
          this.onDelete(row)
          break
      }
    },
    onView(row) {
      this.$router.push({ name: 'patrolWorkOrderMgrView', params: { orderId: row.id }})
    },
    onExportPdf(row, index) {
      if (row && row.status === 'FINISHED') {
        this.loadingIndex = index
        this.$message({ message: `正在导出工单，请稍候`, type: 'warning', center: true })
        postWorkOrderPdf(row.id).then((resp) => {
          this.loadingIndex = -1
          window.open(resp.fileUrl)
        }).catch(() => {
          this.loadingIndex = -1
        })
      } else {
        this.$message({ message: `当前工单尚未执行结束，不可进行导出操作`, type: 'error', center: true })
      }
    },
    onCancelLoop(row) {
      const data = { isLoop: false, action: 'edit' }
      putWorkOrder(row.id, data).then(() => {
        this.$message({ message: '操作成功', center: true, type: 'success' })
        this.getList()
      })
    },
    onDelete(row) {
      deleteWorkOrder(row.id).then(() => {
        this.$message({ message: '删除成功', center: true, type: 'success' })
        this.getList()
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}
</style>
