import { TaskTempTypeObjectEnum, TowerWorkOrderTypeObjectEnum } from '@/views/taskMgr/enum'

export function getTaskTempTypeEnum() {
  return TaskTempTypeObjectEnum.filter(item => item.isShow)
}

export function getTaskTempType(name) {
  const index = TaskTempTypeObjectEnum.findIndex(item => item.name === name)
  if (index !== -1) {
    return TaskTempTypeObjectEnum[index]
  } else {
    return ''
  }
}

export function getTowerWorkOrderType(name) {
  const index = TowerWorkOrderTypeObjectEnum.findIndex(item => item.name === name)
  if (index !== -1) {
    return TowerWorkOrderTypeObjectEnum[index]
  } else {
    return ''
  }
}
