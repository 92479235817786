var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.isCard ? "el-card" : "div",
    { tag: "component", staticClass: "collapse-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "title-row",
          attrs: { gutter: 10, justify: "space-between" },
        },
        [
          _c("el-col", { attrs: { span: 20 } }, [
            _c(
              "div",
              { style: _vm.titleClass },
              [
                _vm._t(
                  "title",
                  [_c("span", [_vm._v(_vm._s(_vm.activatedTitle))])],
                  { activated: !_vm.collapsed }
                ),
                _vm._v(" "),
                _vm._t("operation", null, { activated: !_vm.collapsed }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-link",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onToggleCollapse },
                },
                [
                  _vm._v(_vm._s(_vm.collapseTitle) + " "),
                  _c("i", {
                    ref: "collapseButton",
                    class: _vm.collapseButtonIcon,
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.collapsed,
                expression: "!collapsed",
              },
            ],
          },
          [_vm._t("content")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }