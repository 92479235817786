<template>
  <div class="steps-container">
    <div v-for="(stepItem, stepIndex) in getSteps" :key="stepIndex" class="step-container" @click="addStep(stepItem)">
      <img class="step-img" :src="stepItem.img" alt="">
      <span class="step-name">{{ stepItem.name }}</span>
      <img class="add-img" :src="Add" alt="">
    </div>
  </div>
</template>

<script>
import Add from '@/assets/image/common/add_white_no_circle.png'

export default {
  name: 'Steps',
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'REPAIR'
    }
  },
  data() {
    return {
      Add
    }
  },
  computed: {
    getSteps() {
      return this.steps.filter(item => item.type === this.type)
    }
  },
  methods: {
    addStep(item) {
      this.$emit('add-step', item)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.steps-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  .step-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .step-img{
      width: 170px;
      margin-bottom: 10px;
    }
    .step-name{
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #519dfd;
    }
    .add-img{
      position: absolute;
      top: -10px;
      right: -10px;
      width: 25px;
      height: 25px;
      padding: 5px;
      background: #f56c6c;
      border-radius: 30px;
    }
  }
}
</style>
