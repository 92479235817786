<script>
import { Button } from 'element-ui'
export default {
  name: 'HtButton',
  extends: Button,
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.el-button--primary{
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.el-button--primary.is-disabled{
  color: #c0c4cc;
  background-color: #fff;
  border-color: #e6ebf5;
}

.el-button--text{
  color: $primaryColor;
}

.el-button--text.is-disabled{
  color: #c0c4cc;
}
</style>
