var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("el-autocomplete", {
        staticStyle: { "margin-right": "4px" },
        attrs: {
          "fetch-suggestions": _vm.querySearchAsync,
          placeholder: _vm.staffPlaceholder,
          clearable: "",
          "trigger-on-focus": false,
          "hide-loading": true,
        },
        on: { select: _vm.handleSelect },
        model: {
          value: _vm.searchForm.searchStaffName,
          callback: function ($$v) {
            _vm.$set(_vm.searchForm, "searchStaffName", $$v)
          },
          expression: "searchForm.searchStaffName",
        },
      }),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd",
          placement: "bottom-start",
        },
        on: { change: _vm.selectDate },
        model: {
          value: _vm.searchForm.searchDate,
          callback: function ($$v) {
            _vm.$set(_vm.searchForm, "searchDate", $$v)
          },
          expression: "searchForm.searchDate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }