<template>
  <el-card v-loading="isImporting">
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.code" placeholder="表番" />
        <el-input v-model="listQuery.name" placeholder="表名" />
        <el-input v-model="listQuery.nskMaterialName" placeholder="关联部品名" />
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <ht-button type="success" icon="el-icon-download" @click="downloadFile(templateUrl)">模版下载</ht-button>
        <ht-upload-button :file-type="['excel']" key-prefix="nsk" :show-success-tip="false" button-title="导入检查表" @on-upload-start="onUploadStart" @on-file-uploaded="onFileUploaded" />
        <ht-button type="success" icon="el-icon-plus" @click="onCreate">新建检查表</ht-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in NskCheckTemplateListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '关联部品'">{{ row.nskMaterialId === null ? '-' : `${row[item.prop] || '-'}（${row.nskMaterialCode || '-'}）` }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="280">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-edit" @click="onEdit(row)">编辑</ht-button>
          <ht-button type="text" icon="el-icon-document-copy" @click="toggleCopyTemplateFormDialog(row, true)">复制</ht-button>
          <ht-button type="text" icon="el-icon-edit" @click="toggleRevisionHistoryDialog(row)">变更履历</ht-button>
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该检查表？" @confirmed="onDelete(row)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="copyTemplateFormDialog" title="复制检查单模版" :width="isMobile ? '100%' : '600px'" :close-on-click-modal="false" @close="toggleCopyTemplateFormDialog(null,false)">
      <div slot="content">
        <copy-template-form v-if="showCopyTemplateForm" ref="copyTemplateForm" :template-data="templateData" @cancel-submit="toggleCopyTemplateFormDialog(null,false)" @confirm-submit="confirmSubmitCopyTemplate" />
      </div>
    </ht-dialog>

    <ht-dialog ref="templateRevisionHistoryDialog" title="变更履历" :width="isLoading? '100%' :'1000px'" :close-on-click-modal="false" @close="toggleRevisionHistoryDialog(null,false)">
      <div slot="content">
        <template-revision-history v-if="showTemplateRevisionHistory" ref="showTemplateRevisionHistory" :template-id="templateData.id" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { NskCheckTemplateListTitle } from '@/views/incomingInspectionMgr/tableTitle'
import { mapGetters } from 'vuex'
import { deleteNskTemplate, getNskTemplates, postImportTemplates } from '@/api/incomingInspectionMgr/nskTemplateMgr'
import { downloadFile } from '@/utils'
import CopyTemplateForm from '@/views/incomingInspectionMgr/nskTemplateMgr/components/copyTemplateForm'
import TemplateRevisionHistory from '@/views/incomingInspectionMgr/nskTemplateMgr/components/templateRevisionHistory'

export default {
  name: 'List',
  components: { CopyTemplateForm, TemplateRevisionHistory },
  extends: HtListContainer,
  data() {
    return {
      NskCheckTemplateListTitle,
      isImporting: false,
      templateUrl: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/nsk/%E6%A8%A1%E6%9D%BF.xlsx',
      templateData: null,
      showCopyTemplateForm: false,
      showTemplateRevisionHistory: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    downloadFile,
    getList() {
      this.beforeGetList()
      getNskTemplates(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onUploadStart() {
      this.isImporting = true
    },
    onFileUploaded(context, url) {
      postImportTemplates({ fileUrl: url }).then(resp => {
        this.isImporting = false
        this.$message({ message: '导入模版成功', type: 'success' })
        this.getList()
      }).catch(() => {
        this.isImporting = false
      })
    },
    onCreate() {
      this.$router.push({ name: 'nskTemplateMgrCreate' })
    },
    onEdit(row) {
      this.$router.push({ name: 'nskTemplateMgrEdit', params: { templateId: row.id }})
    },
    onDelete(row) {
      deleteNskTemplate(row.id).then(() => {
        this.$message({ message: '删除成功', type: 'success' })
        this.getList()
      })
    },
    toggleCopyTemplateFormDialog(row, isOpen = true) {
      this.templateData = row
      this.showCopyTemplateForm = isOpen
      this.$refs.copyTemplateFormDialog.dialogVisible = isOpen
    },
    confirmSubmitCopyTemplate(templateId) {
      this.toggleCopyTemplateFormDialog()
      this.$router.push({ name: 'nskTemplateMgrEdit', params: { templateId: templateId }})
    },
    toggleRevisionHistoryDialog(row, isOpen = true) {
      this.templateData = row
      this.showTemplateRevisionHistory = isOpen
      this.$refs.templateRevisionHistoryDialog.dialogVisible = isOpen
    }
  }
}
</script>

<style scoped>

</style>
