var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "info-container" }, [
        _c("i", {
          staticClass: "el-icon-warning",
          staticStyle: { color: "#f56c6c" },
        }),
        _vm._v(" "),
        _c("span", [_vm._v("将")]),
        _vm._v(" "),
        _c("span", { staticClass: "info" }, [
          _vm._v(_vm._s(" " + _vm.knowledgeData.title + " ")),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("移动到")]),
        _vm._v(" "),
        _c("span", { staticClass: "info" }, [
          _vm._v(
            _vm._s(" " + (_vm.selectedFile ? _vm.selectedFile.name : "") + " ")
          ),
        ]),
        _vm._v(" "),
        _vm.selectedFile ? _c("span", [_vm._v("下")]) : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "moveKnowledgeForm",
          attrs: {
            model: _vm.moveKnowledgeForm,
            rules: _vm.moveKnowledgeFormRules,
            "label-width": "100px",
          },
        },
        [
          _c("single-check-tree", {
            attrs: {
              "tree-list": _vm.getMoveTree,
              "hint-node-id": _vm.knowledgeData.cate1Id,
              "hint-txt": "当前所属目录",
            },
            on: { "node-click": _vm.handleClickTreeNode },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmSubmit("moveKnowledgeForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }