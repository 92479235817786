var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c("ht-upload-button", {
        attrs: {
          "file-type": "doc",
          "button-type": "primary",
          "multiple-selection": false,
          "show-success-tip": false,
          "key-prefix": _vm.uploadKeyPrefix,
          "button-title": _vm.dynamicBtnTitle,
          "button-icon": "el-icon-document",
        },
        on: {
          "on-upload-start": _vm.onUploadStart,
          "on-upload-finish": _vm.onUploadFinish,
          "on-file-uploaded": _vm.onFileUploaded,
          "on-upload-progress": _vm.onUploadProgress,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }