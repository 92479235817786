var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "stopLeaseForm",
          attrs: {
            model: _vm.stopLeaseForm,
            rules: _vm.stopLeaseFormRules,
            "label-width": "100px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "设备名称：" } }, [
            _vm._v(
              _vm._s(
                _vm.equipmentInfo && _vm.equipmentInfo.constructionEquipmentName
              )
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "现存数量：" } }, [
            _vm._v(
              _vm._s(
                _vm.equipmentInfo && _vm.equipmentInfo.projectEquipmentQuantity
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "入库仓库：", prop: "warehouseId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.warehouseList.length > 0
                        ? "请输入仓库名称进行搜索"
                        : "当前无可选仓库",
                    remote: "",
                    "remote-method": _vm.getWarehouses,
                  },
                  model: {
                    value: _vm.stopLeaseForm.warehouseId,
                    callback: function ($$v) {
                      _vm.$set(_vm.stopLeaseForm, "warehouseId", $$v)
                    },
                    expression: "stopLeaseForm.warehouseId",
                  },
                },
                _vm._l(_vm.warehouseList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmSubmit()
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }