<template>
  <div class="card-container" :style="{ background: cardColor}">
    <div class="item">
      <div class="count" :style="{color: countColor}">{{ list && list[0] && list[0].count }}</div>
      <div class="title" :style="{color: titleColor}">{{ list && list[0] && list[0].title }}</div>
    </div>
    <div class="divider" />
    <div class="item">
      <div class="count" :style="{color: countColor}">{{ list && list[1] && list[1].count }}</div>
      <div class="title" :style="{color: titleColor}">{{ list && list[1] && list[1].title }}</div>
    </div>
    <div class="divider" />
    <div class="item">
      <div class="count" :style="{color: countColor}">{{ list && list[2] && list[2].count }}</div>
      <div class="title" :style="{color: titleColor}">{{ list && list[2] && list[2].title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftAndRightCard',
  props: {
    list: { type: Array, default() { return [] } },
    height: { type: String, default: '140px' },
    width: { type: String, default: '100%' },
    cardColor: { type: String, default: '#f8f8f8' },
    countColor: { type: String, default: '#42618f' },
    titleColor: { type: String, default: '#666666' }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.card-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  .item{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 10px;
  }
  .title{
    font-size: 14px;
  }
  .count{
    font-size: 20px;
    font-weight: bold;
  }
  .divider{
    width: 2px;
    height: 60%;
    background: #e5e5e5;;
  }
}
</style>
