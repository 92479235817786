import Layout from '@/layout'

const IncomingInspectionMgrRouter = {
  path: '/incomingInspectionMgr',
  component: Layout,
  redirect: 'noRedirect',
  name: 'incomingInspectionMgrMenu',
  meta: { title: '受入检查', icon: 'incoming_inspection' },
  children: [
    {
      path: '/incomingInspectionMgr/nskMaterialMgr/index',
      component: () => import('@/views/incomingInspectionMgr/nskMaterialMgr/list'),
      name: 'nskMaterialMgr',
      meta: { title: '部品管理', activeMenu: '/incomingInspectionMgr/nskMaterialMgr/index' }
    },
    {
      path: '/incomingInspectionMgr/nskTemplateMgr/index',
      component: () => import('@/views/incomingInspectionMgr/nskTemplateMgr/list'),
      name: 'nskTemplateMgr',
      meta: { title: '检查模版管理', activeMenu: '/incomingInspectionMgr/nskTemplateMgr/index' }
    },
    {
      path: '/incomingInspectionMgr/nskTemplateMgr/create',
      component: () => import('@/views/incomingInspectionMgr/nskTemplateMgr/create'),
      hidden: true,
      props: true,
      name: 'nskTemplateMgrCreate',
      meta: { title: '新建检查模版', activeMenu: '/incomingInspectionMgr/nskTemplateMgr/index' }
    },
    {
      path: '/incomingInspectionMgr/nskTemplateMgr/edit/:templateId',
      component: () => import('@/views/incomingInspectionMgr/nskTemplateMgr/edit'),
      hidden: true,
      props: true,
      name: 'nskTemplateMgrEdit',
      meta: { title: '编辑检查模版', activeMenu: '/incomingInspectionMgr/nskTemplateMgr/index' }
    },
    {
      path: '/incomingInspectionMgr/nskChecklistMgr/index',
      component: () => import('@/views/incomingInspectionMgr/nskChecklistMgr/list'),
      name: 'nskChecklistMgr',
      meta: { title: '检查单管理', activeMenu: '/incomingInspectionMgr/nskChecklistMgr/index' }
    },
    {
      path: '/incomingInspectionMgr/nskChecklistMgr/view/:checklistId',
      component: () => import('@/views/incomingInspectionMgr/nskChecklistMgr/view'),
      hidden: true,
      props: true,
      name: 'nskChecklistMgrView',
      meta: { title: '检查单详情', activeMenu: '/incomingInspectionMgr/nskChecklistMgr/index' }
    }
  ]
}

export default IncomingInspectionMgrRouter
