var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "20px" } },
    [
      _c("dw-title", { attrs: { title: "个人中心" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "user-profile-container" },
        [
          _c(
            "div",
            { staticClass: "user-avatarUrl" },
            [
              _vm.user.avatarUrl
                ? _c("img", {
                    staticClass: "img-avatarUrl",
                    attrs: { src: "", alt: "" },
                  })
                : _c("dw-default-avatar", {
                    attrs: { "full-name": _vm.user.fullName },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { direction: "vertical" } }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "font-size": "16px" },
                  attrs: { label: "姓名:" },
                },
                [_vm._v(_vm._s(_vm.user.fullName))]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "用户名:" } }, [
                _vm._v(_vm._s(_vm.user.userName)),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "角色:" } }, [
                _vm._v(_vm._s(_vm.user.roles[0])),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { direction: "vertical" } }),
          _vm._v(" "),
          _c(
            "el-form",
            { ref: "form", staticClass: "qrcodeFrom" },
            [
              _c(
                "el-form-item",
                { staticClass: "qr-form-item", attrs: { label: "二维码:" } },
                [
                  _c(
                    "el-popover",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: {
                        placement: "right",
                        width: "230",
                        height: "400",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("vue-qr", {
                            ref: "qrCode",
                            attrs: { text: _vm.user.qrCode, size: 200 },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          staticStyle: { width: "150px", margin: "0 auto" },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("vue-qr", {
                            ref: "qrCode",
                            attrs: { text: _vm.user.qrCode, size: 150 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ht-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-printer" },
                      on: {
                        click: function ($event) {
                          return _vm.printQRCode()
                        },
                      },
                    },
                    [_vm._v("打印二维码")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ht-print", { ref: "printQRCode" }, [
        _c("div", [
          _c("img", {
            attrs: { src: _vm.qRCodeImg, width: "400px", height: "400px" },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }