import Layout from '@/layout'

const KnowledgeBaseMgrRouter = {
  path: '/knowledgeBaseMgr',
  component: Layout,
  redirect: 'noRedirect',
  name: 'knowledgeBaseMgrMenu',
  meta: { title: '知识库管理', icon: 'q_a_document_mgr' },
  children: [
    {
      path: '/knowledgeBaseMgr/knowledgeMgr/index',
      component: () => import('@/views/knowledgeBaseMgr/knowledgeMgr/index'),
      name: 'knowledgeBaseMgrList',
      meta: { title: '知识管理', activeMenu: '/knowledgeBaseMgr/knowledgeMgr/index' }
    },
    {
      path: '/knowledgeBaseMgr/knowledgeSearch/index',
      component: () => import('@/views/knowledgeBaseMgr/knowledgeSearch/index'),
      name: 'knowledgeBaseSearchList',
      meta: { title: '知识检索', activeMenu: '/knowledgeBaseMgr/knowledgeSearch/index' }
    }
  ]
}

export default KnowledgeBaseMgrRouter
