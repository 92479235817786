import * as RongIMLib from '@rongcloud/imlib-next'
import { LogLevel } from '@rongcloud/imlib-next'
import { device, installer, RCRTCCode } from '@rongcloud/plugin-rtc'
import { EventsEnum } from '../var/eventsVar'
import log from '@/utils/log'

let rtcClient = null
function initRTCClient(options) {
  // 初始化 RTCLib
  // rtcClient = RongIMLib.installPlugin(installer)
  rtcClient = RongIMLib.installPlugin(installer, {
    logLevel: LogLevel.NONE
  })
}

async function getCameras() {
  return new Promise(async(resolve, reject) => {
    device.getCameras().then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

async function getSpeakers() {
  return new Promise(async(resolve, reject) => {
    device.getSpeakers().then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

async function getMicrophones() {
  return new Promise(async(resolve, reject) => {
    device.getMicrophones().then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

async function joinRTCRoom(roomId) {
  return new Promise(async(resolve, reject) => {
    if (rtcClient) {
      rtcClient.joinRTCRoom(roomId).then(res => {
        if (res.code !== RCRTCCode.SUCCESS) {
          reject(res.code)
        } else {
          resolve(res)
        }
      }).catch((res) => {
        reject(res)
      })
    } else {
      reject('当前环境无法开启会议')
    }
  })
}

async function leaveRTCRoom(room) {
  const { code } = await rtcClient.leaveRoom(room)
  log.myLog('退出房间', code)
  if (code !== RCRTCCode.SUCCESS) {
    log.myLog(`退出房间失败 -> code: ${code}`)
  }
}

async function setRoomAttribute(room, obj) {
  return new Promise(async(resolve, reject) => {
    try {
      for (const key of Object.keys(obj)) {
        const res = await room.setRoomAttribute(key, obj[key])
        if (res.code !== RCRTCCode.SUCCESS) {
          throw new Error('设置房间属性失败')
        }
      }
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

async function getRoomAttributes(room, keys) {
  return new Promise(async(resolve, reject) => {
    room.getRoomAttributes(keys).then(res => {
      if (res.code !== RCRTCCode.SUCCESS) {
        log.myLog('获取房间属性失败')
        reject(res.code)
      } else {
        resolve(res.data)
      }
    }).catch(error => {
      log.myLog('获取房间属性失败', error)
    })
  })
}

async function deleteRoomAttributes(room, keys) {
  return new Promise(async(resolve, reject) => {
    room.deleteRoomAttributes(keys).then(res => {
      if (res.code !== RCRTCCode.SUCCESS) {
        log.myLog('删除房间属性失败')
        reject(res.code)
      } else {
        resolve(res.data)
      }
    }).catch(error => {
      log.myLog('删除房间属性失败', error)
    })
  })
}

// 初始化房间监听事件
function initRtcRoomListener(room) {
  registerPeerReportListener(room)
  registerRoomEventListener(room)
}

function registerPeerReportListener(room) {
  room.registerReportListener({
    onICEConnectionStateChange(state, pcName) {
      log.myLog('onICEConnectionStateChange:', state, pcName)
    },
    onConnectionStateChange(state, pcName) {
      log.myLog('onConnectionStateChange:', state, pcName)
    },
    onStateReport(report) {
      document.dispatchEvent(new CustomEvent(EventsEnum.onStateReportEvent, { detail: report }))
    },
    onReportLiveAudioStates(data) {
    }
  })
}

function registerRoomEventListener(room) {
  room.registerRoomEventListener({
    // 本端被踢出房间时触发
    onKickOff(byServer, state, kickExtra) {
      log.myLog('本端被踢出房间时触发', byServer ? '被踢出房间' : 'rtcPing 超时', state === 1 ? 'Server 主动踢' : '其他设备登陆')
    },
    // 接收到房间信令时回调
    onMessageReceive(name, content, senderUserId, messageUId) {
      log.myLog('接收到房间信令时回调')
    },
    // 监听房间属性变更通知
    onRoomAttributeChange(name, content) {
      log.myLog('监听房间属性变更通知')
    },
    // 发布者禁用/启用音频
    onAudioMuteChange(audioTrack) {
      log.myLog('发布者禁用/启用音频')
      const data = { room: room, tracks: audioTrack }
      document.dispatchEvent(new CustomEvent(EventsEnum.onAudioMuteChangeEvent, { detail: data }))
    },
    // 发布者禁用/启用视频
    onVideoMuteChange(videoTrack) {
      log.myLog('发布者禁用/启用视频')
      const data = { room: room, tracks: videoTrack }
      document.dispatchEvent(new CustomEvent(EventsEnum.onVideoMuteChangeEvent, { detail: data }))
    },
    // 房间内其他用户新发布资源时触发
    onTrackPublish(tracks) {
      log.myLog('房间内其他用户新发布资源时触发')
      const data = { room: room, tracks: tracks }
      document.dispatchEvent(new CustomEvent(EventsEnum.onTrackPublishEvent, { detail: data }))
    },
    // 房间用户取消发布资源
    onTrackUnpublish(tracks) {
      log.myLog('房间用户取消发布资源')
      const data = { room: room, tracks: tracks }
      document.dispatchEvent(new CustomEvent(EventsEnum.onTrackUnPublishEvent, { detail: data }))
    },
    // 订阅的音视频流通道已建立, track 已可以进行播放
    onTrackReady(track) {
      log.myLog(`订阅的${track.isVideoTrack() ? '视频' : '音频'}流通道已建立, track已可以进行播放`)
      document.dispatchEvent(new CustomEvent(EventsEnum.onTrackReadyEvent, { detail: track }))
    },
    // 人员加入
    onUserJoin(joinUserIds) {
      log.myLog('人员加入')
      document.dispatchEvent(new CustomEvent(EventsEnum.onUserJoinEvent, { detail: joinUserIds }))
    },
    // 人员退出
    onUserLeave(leaveUserIds) {
      log.myLog('人员退出')
      document.dispatchEvent(new CustomEvent(EventsEnum.onUserLeaveEvent, { detail: leaveUserIds }))
    }
  })
}

function removeRoomEventListener(room) {
  room.registerRoomEventListener(null)
}

async function createMicrophoneAudioTrack(tag, options) {
  return new Promise(async(resolve, reject) => {
    const { code, track } = await rtcClient.createMicrophoneAudioTrack(tag, options)
    if (code !== RCRTCCode.SUCCESS) {
      log.myLog(`捕获音频流失败 -> code: ${code}`)
      reject({ code: code, content: '捕获音频流失败' })
    } else {
      resolve(track)
    }
  })
}

async function createCameraVideoTrack(tag, options) {
  return new Promise(async(resolve, reject) => {
    const { code, track } = await rtcClient.createCameraVideoTrack(tag, { frameRate: options.frameRate, resolution: options.resolution })
    if (code !== RCRTCCode.SUCCESS) {
      log.myLog(`捕获视频流失败 -> code: ${code}`)
      reject({ code: code, content: '捕获视频流失败' })
    } else {
      if (options.bitRate) {
        track.setBitrate(options.bitRate.max, options.bitRate.min, options.bitRate.start)
      }
      resolve(track)
    }
  })
}

async function createScreenVideoTrack(tag, options) {
  return new Promise(async(resolve, reject) => {
    const { code, track } = await rtcClient.createScreenVideoTrack(tag, options)
    if (code !== RCRTCCode.SUCCESS) {
      log.myLog(`捕获屏幕视频流失败 -> code: ${code}`)
      reject({ code: code, content: '捕获屏幕视频流失败' })
    } else {
      resolve(track)
    }
  })
}

// subscribe 拉取房间内其他参会者声音或图像资源
async function subscribe(room, tracks, remoteTracks, isSubTiny = false) {
  return new Promise(async(resolve, reject) => {
    const data = []
    tracks.forEach(track => {
      data.push({ track: track, subTiny: true })
    })
    // todo 大小流订阅存在问题
    // const { code } = await room.subscribe(tracks)
    const { code } = await room.subscribe(isSubTiny ? data : tracks)
    if (code !== RCRTCCode.SUCCESS) {
      log.myLog(`资源订阅失败 -> code: ${code}`)
      reject({ code: code, content: '资源订阅失败' })
    }
    log.myLog('资源订阅成功', isSubTiny, tracks)
    tracks.forEach(itemInTracks => {
      if (!remoteTracks.some(item => item.getTrackId() === itemInTracks.getTrackId())) {
        remoteTracks.push(itemInTracks)
      }
    })
    resolve(remoteTracks)
  })
}

async function unsubscribe(room, tracks, remoteTracks) {
  return new Promise(async(resolve, reject) => {
    const { code } = await room.unsubscribe(tracks)
    if (code !== RCRTCCode.SUCCESS) {
      log.myLog(`取消资源订阅失败 -> code: ${code}`)
      reject({ code: code, content: '取消资源订阅失败' })
    }
    log.myLog('取消资源成功', tracks)
    tracks.forEach(itemInTracks => {
      const index = remoteTracks.findIndex(item => item.getTrackId() === itemInTracks.getTrackId())
      if (index !== -1) {
        remoteTracks.splice(index, 1)
      }
    })
    resolve(remoteTracks)
  })
}

async function publish(room, tracks) {
  const { code } = await room.publish(tracks)

  if (code !== RCRTCCode.SUCCESS) {
    log.myLog('资源发布失败:', code)
    return false
  }
  return true
}

async function unpublish(room, tracks) {
  return new Promise(async(resolve, reject) => {
    log.myLog('unpublish', tracks)
    const { code } = await room.unpublish(tracks)

    if (code !== RCRTCCode.SUCCESS) {
      log.myLog('取消发布失败:', code)
      reject()
    }
    tracks.forEach(item => {
      item.destroy()
    })
    resolve()
  })
}

function playTrack(track, target, id) {
  return new Promise(async(resolve, reject) => {
    if (track.isAudioTrack()) {
      const { code } = await track.play()
      if (code !== RCRTCCode.SUCCESS) {
        log.myLog('isReady:', track.isReady(), 'userID:', track.getUserId(), 'TrackId:', track.getTrackId(), track)
        log.myLog('音频播放失败:', code, track.isSubscribed(), track.isReady())
        reject(code)
      }
      log.myLog('音频播放：', code)
      resolve()
    } else {
      const { code } = await track.play(target)
      if (code !== RCRTCCode.SUCCESS) {
        log.myLog('target:', target, 'isReady:', track.isReady(), 'userID:', track.getUserId(), 'TrackId:', track.getTrackId(), track)
        log.myLog('视频播放失败:', code, track.isSubscribed(), track.isReady())
        reject(track.isReady(), code)
      }
      log.myLog('视频播放：', code, track.getTrackId(), id, track.isReady())
      resolve()
    }
  })
}

function removeAllLocalTrack(tracks) {
  return new Promise(async resolve => {
    tracks.forEach(track => {
      track.destroy()
    })
    resolve()
  })
}

async function createLocalTracks(tag, MediaStream, option) {
  return new Promise(async(resolve, reject) => {
    const { code, track } = await rtcClient.createLocalTracks(tag, MediaStream, option)
    log.myLog(code, track)
    if (code !== RCRTCCode.SUCCESS) {
      log.myLog(`转换视频流失败 -> code: ${code}`)
      reject({ code: code, content: '转换视频流失败' })
    } else {
      resolve(track)
    }
  })
}

async function getRemoteTracksByUserId(room, imUserId) {
  return new Promise(async(resolve) => {
    const notSubscribeList = []
    const tracks = room.getRemoteTracksByUserId(imUserId)
    tracks.forEach(track => {
      if (!track.isSubscribed()) {
        notSubscribeList.push(track)
      }
    })
    resolve(notSubscribeList)
  })
}

export default {
  rtcClient,
  initRTCClient,
  getMicrophones,
  getCameras,
  getSpeakers,
  joinRTCRoom,
  leaveRTCRoom,
  setRoomAttribute,
  getRoomAttributes,
  deleteRoomAttributes,
  initRtcRoomListener,
  removeRoomEventListener,
  createMicrophoneAudioTrack,
  createCameraVideoTrack,
  createScreenVideoTrack,
  createLocalTracks,
  publish,
  unpublish,
  subscribe,
  unsubscribe,
  playTrack,
  removeAllLocalTrack,
  getRemoteTracksByUserId
}
