<template>
  <div class="container">
    <div id="example-scanning-overlay" class="">
      <div class="inner">
        <img :src="imgSrc">
        <div class="scanline" />
      </div>
    </div>
    <a-scene
      :mindar-image="{imageTargetSrc: mindSrc, uiScanning: '#example-scanning-overlay', uiError:'no', uiLoading:'no'}"
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
    >
      <a-assets>
        <img id="card" :src="imgSrc">
        <a-asset-item id="avatarModel" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/softmind/scene.gltf" />
      </a-assets>

      <a-camera position="0 0 0" look-controls="enabled: false" />
      <a-entity mindar-image-target="targetIndex: 0">
        <a-plane src="#card" position="0 0 0" height="0.552" width="1" rotation="0 0 0" />
        <a-gltf-model rotation="0 0 0 " position="0 0 0.1" scale="0.005 0.005 0.005" src="#avatarModel" animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate" />
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
export default {
  name: 'CustomUi',
  data() {
    return {
      mindSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/targets.mind',
      imgSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/img.jpg'
    }
  }
}
</script>

<style scoped lang="scss">
#example-scanning-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  z-index: 2;
}

#example-scanning-overlay .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background:
    linear-gradient(to right, white 10px, transparent 10px) 0 0,
    linear-gradient(to right, white 10px, transparent 10px) 0 100%,
    linear-gradient(to left, white 10px, transparent 10px) 100% 0,
    linear-gradient(to left, white 10px, transparent 10px) 100% 100%,
    linear-gradient(to bottom, white 10px, transparent 10px) 0 0,
    linear-gradient(to bottom, white 10px, transparent 10px) 100% 0,
    linear-gradient(to top, white 10px, transparent 10px) 0 100%,
    linear-gradient(to top, white 10px, transparent 10px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

@media (min-aspect-ratio: 1 / 1){
  #example-scanning-overlay .inner {
    width: 50vh;
    height: 50vh;
  }

}

#example-scanning-overlay img {
  opacity: 0.6;
  width: 90%;
  align-self: center;
}

#example-scanning-overlay .inner .scanline {
  position: absolute;
  width: 100%;
  height: 10px;
  animation: move 2s linear infinite;
}
</style>
