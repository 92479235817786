<template functional>
  <div
    v-bind="data.attrs"
    :class="[data.staticClass, 'el-divider', `el-divider--${props.direction}`]"
    v-on="listeners"
  >
    <div class="left item"><slot name="left" /></div>
    <div
      v-if="slots().default && props.direction !== 'vertical'"
      :class="['el-divider__text', `is-${props.contentPosition}`]"
    >
      <slot />
    </div>
    <div class="right item"><slot name="right" class="right" /></div>
  </div>
</template>

<script>
export default {
  name: 'DwDivider',
  props: {
    direction: {
      type: String,
      default: 'horizontal',
      validator(val) {
        return ['horizontal', 'vertical'].indexOf(val) !== -1
      }
    },
    contentPosition: {
      type: String,
      default: 'center',
      validator(val) {
        return ['left', 'center', 'right'].indexOf(val) !== -1
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.el-divider{
  display: flex;
  flex-direction: row;
  position: relative;
  .item{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #ffffff;
  }
  .left{
    right: 0;
    padding-right: 10px;
  }
  .right{
    right: 0;
    padding-left: 10px;
  }
}
</style>
