<template>
  <div class="middle-section" :style="{height: height + 'px'}">
    <div v-if="hasProperties" class="left-container inner-item" :class="{'focused-item': isFocusedItem === 'referenceImage' || isFocusedItem === 'text'}">
      <div class="title-container">
        <el-button
          v-if="showReferenceImage"
          class="sub-title-button reference-img-button"
          type="text"
          :class="{'focused-item': isFocusedItem === 'referenceImage', 'click-item': activeName === 'referenceImage'}"
          :disabled="buttonDisabled"
          @click="handleActions('selectReferenceImage')"
        >参考{{ isVideoOrImage(referenceImage) === 'video' ? '视频' : '图' }}</el-button>
        <el-button
          v-if="showText"
          class="sub-title-button text-button"
          type="text"
          :class="{'focused-item': isFocusedItem === 'text', 'click-item': activeName === 'text'}"
          :disabled="buttonDisabled"
          @click="handleActions('selectText')"
        >检查要求</el-button>
        <el-button
          v-if="showPhotos"
          class="sub-title-button text-button"
          type="text"
          :class="{'click-item': activeName === 'photos'}"
          :disabled="buttonDisabled"
          @click="handleActions('selectPhotos')"
        >照片</el-button>
      </div>
      <div class="content-container">
        <div v-if="showReferenceImage && activeName === 'referenceImage'" class="img-container reference-img-container">
          <template v-if="referenceImage">
            <web-view-video
              v-if="isVideoOrImage(referenceImage) === 'video'"
              ref="referenceImage"
              :src="referenceImage"
              :button-disabled="buttonDisabled"
              @on-finish-play="closeReferenceImageViewer"
            />
            <web-view-image
              v-else
              ref="referenceImage"
              :src="referenceImage"
              alt=""
              class="reference-img"
              :preview-src-list="[referenceImage]"
              :show-icon="false"
              :button-disabled="buttonDisabled"
              @close-viewer="closeReferenceImageViewer"
            />
          </template>
          <el-button
            v-if="referenceImage"
            class="sub-title-button fullscreen-button"
            :disabled="buttonDisabled"
            @click="handleActions('openReferenceImage')"
          >{{ isVideoOrImage(referenceImage) === 'video' ? '播放' : '查看参考图' }}</el-button>
        </div>
        <div v-else-if="activeName === 'text'" class="text-container">
          <div class="check-standard">{{ text }}</div>
        </div>
        <div v-else-if="showPhotos && activeName === 'photos'" class="left-img-container">
          <web-view-carousel
            ref="leftCustomCarousel"
            height="100%"
            :autoplay="false"
            arrow="always"
            :show-icon="false"
            :button-disabled="buttonDisabled"
            @change="carouselActiveChange"
          >
            <web-view-carousel-item v-for="(item, index) in uploadPhotos" :key="index" :button-disabled="buttonDisabled">
              <web-view-image
                :ref="`result${index}`"
                :src="item"
                alt=""
                class="result-img"
                :preview-src-list="uploadPhotos"
                :show-icon="false"
                :button-disabled="buttonDisabled"
                @prev="handleActions('prevPhoto', arguments)"
                @next="handleActions('nextPhoto',arguments)"
                @del-img="handleActions('delPhoto',arguments)"
                @close-viewer="handleActions('closePhoto')"
              />
            </web-view-carousel-item>
          </web-view-carousel>
          <el-button v-if="uploadPhotos && uploadPhotos.length > 0" :disabled="buttonDisabled" class="sub-title-button fullscreen-button" @click="handleActions('openPhoto')">查看照片</el-button>
          <el-button v-if="uploadPhotos && uploadPhotos.length > 0" :disabled="buttonDisabled" class="sub-title-button del-photo-button" @click="handleActions('delPhoto')">删除照片</el-button>
        </div>
      </div>
    </div>
    <div v-if="hasResults" class="right-container inner-item">
      <div class="title-container">
        <el-button class="sub-title-button fact-button" type="text" :disabled="buttonDisabled" @click="handleActions('inputDesc')">情况描述</el-button>
        <el-button class="sub-title-button clear-button" type="text" :disabled="buttonDisabled" @click="handleActions('clearFactDesc')">清空</el-button>
      </div>
      <div ref="rectDescRef" class="check-standard">
        <template v-if="factDesc !== null && typeof factDesc === 'object'">
          <template v-if="factDesc.length > 0">
            <div v-for="(item, index) in factDesc" :key="index">{{ `${index + 1}.${item}` }}</div>
          </template>
          <div v-else />
        </template>
        <template v-else>
          {{ factDesc || '请输入内容' }}
        </template>
      </div>
      <slot name="result" />
    </div>
    <div v-if="hasPhoto" class="right-container inner-item">
      <div class="title-container">
        <el-button
          v-if="uploadPhotos && uploadPhotos.length < 9"
          class="sub-title-button photo-button"
          type="text"
          @click="handleActions('nativeTakePhoto')"
        >拍摄</el-button>
        <el-button
          v-if="uploadPhotos && uploadPhotos.length > 0"
          class="sub-title-button del-button"
          type="text"
          @click="handleActions('delPhoto')"
        >删除</el-button>
      </div>
      <div class="img-container">
        <dw-carousel ref="customCarousel" height="100%" :autoplay="false" arrow="always" @change="handleActions('setActiveItem')">
          <dw-carousel-item v-for="(item, index) in uploadPhotos" :key="index">
            <dw-el-image :ref="`result${index}`" :src="item" alt="" class="result-img" :preview-src-list="uploadPhotos" :show-icon="false" />
          </dw-carousel-item>
        </dw-carousel>
        <el-button v-if="uploadPhotos && uploadPhotos.length > 0" class="sub-title-button fullscreen-button" @click="handleActions('fullScreenPhoto')">查看照片</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import WebViewCarousel from '@/views/taskMgr/newTemplateMgr/components/webViewCarousel/src/main'
import WebViewCarouselItem from '@/views/taskMgr/newTemplateMgr/components/webViewCarousel/src/item'
import WebViewImage from '@/views/taskMgr/newTemplateMgr/components/webViewImage/src/main'
import WebViewVideo from '@/views/taskMgr/newTemplateMgr/components/webViewVideo'
import { isVideoOrImage } from '@/utils/business'

export default {
  name: 'PatrolMiddleSection',
  components: { WebViewCarousel, WebViewCarouselItem, WebViewImage, WebViewVideo },
  props: {
    height: { type: Number, default: 480 },
    isFocusedItem: { type: String, default: null },
    activeName: { type: String, default: null },
    hasProperties: { type: Boolean, default: true },
    hasResults: { type: Boolean, default: true },
    hasPhoto: { type: Boolean, default: false },
    showReferenceImage: { type: Boolean, default: true },
    showText: { type: Boolean, default: true },
    showPhotos: { type: Boolean, default: false },
    referenceImage: { type: String, default: null },
    text: { type: String, default: null },
    mustUploadPhoto: { type: Boolean, default: false },
    uploadPhotos: { type: Array, default: () => [] },
    activeImgIndex: { type: Number, default: 0 },
    factDesc: { type: [String, Array], default: () => [] },
    buttonDisabled: { type: Boolean, default: false }
  },
  methods: {
    isVideoOrImage,
    handleActions(action, $arguments) {
      switch (action) {
        case 'selectReferenceImage':
          this.$emit('select-reference-image')
          break
        case 'selectText':
          this.$emit('select-text')
          break
        case 'selectPhotos':
          this.$emit('select-photos')
          break
        case 'openReferenceImage':
          this.$emit('open-reference-image')
          break
        case 'inputDesc':
          this.$emit('input-desc')
          break
        case 'clearFactDesc':
          this.$emit('clear-fact-desc')
          break
        case 'nativeTakePhoto':
          this.$emit('native-take-photo')
          break
        case 'setActiveItem':
          this.$emit('set-active-item')
          break
        case 'openPhoto':
          this.$emit('open-photo')
          break
        case 'closePhoto':
          this.$emit('close-photo')
          break
        case 'delPhoto':
          this.$emit('del-photo', this.activeImgIndex)
          break
        case 'prevPhoto':
          this.$emit('prev-photo', $arguments[0])
          break
        case 'nextPhoto':
          this.$emit('next-photo', $arguments[0])
          break
      }
    },
    carouselActiveChange(val) {
      this.$emit('set-active-item', val)
    },
    openReferenceImage() {
      this.$nextTick(() => {
        this.$refs.referenceImage.clickHandler()
      })
    },
    closeReferenceImage() {
      if (this.$refs.referenceImage) {
        this.$refs.referenceImage.closeViewer()
      }
    },
    closeReferenceImageViewer() {
      this.$emit('close-reference-viewer')
    },
    openPhoto() {
      const refName = 'result' + this.activeImgIndex
      this.$refs[refName][0].clickHandler()
    },
    closePhoto() {
      const refName = 'result' + this.activeImgIndex
      if (this.$refs[refName] && this.$refs[refName][0]) {
        this.$refs[refName][0].closeViewer()
      }
    },
    setActiveItem(index) {
      if (this.showPhotos) {
        this.$refs.leftCustomCarousel.setActiveItem(index)
      } else {
        this.$refs.customCarousel.setActiveItem(index)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.middle-section{
  display: flex;
  justify-content: space-between;

  .inner-item {
    flex-grow: 1;
    margin: 0 5px; /* 每个内部div的左右间距为5px */
  }

  .inner-item:first-child {
    margin-left: 0; /* 第一个内部div的左边间距为0 */
  }

  .inner-item:last-child {
    margin-right: 0; /* 最后一个内部div的右边间距为0 */
  }
}

.left-container{

  .left-img-container{
    flex: 1;
    height: 100%;
    text-align: center;
    position: relative;
    .reference-img{
      max-width: 100%;
      height: auto;

      ::v-deep .el-image__inner{
        width: 100%;
        height: auto;
      }
    }

    .result-img{
      height: auto;
      width: 100%;
      margin-bottom: 34px;
    }

    .el-carousel{
      height: 100%;
      background: #ffffff;
      .el-carousel__container{
        .el-carousel__item{
          display: flex;
          align-items: center;
        }
      }
      ::v-deep .el-carousel__indicators{
        display: none;
      }
      ::v-deep .el-carousel__arrow{
        width: auto;
        display: block;
        font-size: 1.3rem;
        background: none;
      }
    }
    .fullscreen-button{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      border: none;
      z-index: 99;
    }

    .del-photo-button{
      position: absolute;
      left: 50%;
      bottom: 40px;
      transform: translateY(-50%) translateX(-50%);
      background: rgba(255, 0, 0);
      color: #ffffff;
      border: none;
      z-index: 99;
      margin-left: 0;
    }
    .carousel-num{
      position: absolute;
      top: 0;
      right:0;
      font-size: 1.3rem;
    }
  }
}
</style>
