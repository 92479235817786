<template>
  <div>
    <section class="app-main" :class="[{'show-bottom-app-main': showBottom}]">
      <transition name="fade" mode="out-in">
        <keep-alive :include="cachedViews">
          <router-view :key="key" />
        </keep-alive>
      </transition>
    </section>
    <div v-if="showBottom" class="bottom-menu">
      <router-link to="/mobileHomePage" class="menu-item" :class="{ active: activeTab === 'mobileHomePage' }" @click="goToView('mobileHomePage')">
        <i class="el-icon-s-home bottom-icon" />
        <span>首页</span>
      </router-link>
      <router-link to="/mobileProfilePage" class="menu-item" :class="{ active: activeTab === 'mobileProfilePage' }" @click="goToView('mobileProfilePage')">
        <i class="el-icon-s-custom bottom-icon" />
        <span>我的</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileAppMain',
  props: {
    showBottom: { type: Boolean, default: true }
  },
  data() {
    return {
      activeTab: 'mobileHomePage'
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  },
  watch: {
    '$route.path'(newPath) {
      // 监听路由变化，更新选中的菜单项
      if (newPath === '/mobileHomePage') {
        this.activeTab = 'mobileHomePage'
      } else if (newPath === '/mobileProfilePage') {
        this.activeTab = 'mobileProfilePage'
      }
    }
  },
  mounted() {
    if (this.$route.path === '/mobileHomePage') {
      this.activeTab = 'mobileHomePage'
    } else if (this.$route.path === '/mobileProfilePage') {
      this.activeTab = 'mobileProfilePage'
    }
  },
  methods: {
    goToView(route) {
      this.activeTab = route
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.app-main {
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: scroll;
  font-size: 1.2rem;
}

.show-bottom-app-main{
  min-height: calc(100vh - 110px);
}

.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-views = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header+.app-main {
    padding-top: 84px;
  }
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f0f0f0;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  border-top: rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
  .menu-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 100%;
    color: #333;
    .bottom-icon{
      font-size: 30px;
    }
  }

  .menu-item.active {
    color: $primaryColor;
    background: #ffffff;
  }
}
</style>
