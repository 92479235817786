var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "result-container",
          style: { height: _vm.height + "px" },
        },
        [
          _c("div", { staticClass: "result-top-container" }, [
            _c(
              "div",
              {
                staticClass: "result-top-left-container",
                attrs: { title: "hf_use_description" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "result-button",
                    attrs: {
                      type: "text",
                      disabled: _vm.buttonDisabled,
                      title: "仓库",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleActions("choose-warehouse")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "required-item" }, [_vm._v("*")]),
                    _vm._v("仓库:\n        "),
                  ]
                ),
                _vm._v(" "),
                _c("el-input", {
                  ref: "rectDescRef",
                  staticClass: "result-input",
                  attrs: { placeholder: "请选择仓库", disabled: "" },
                  model: {
                    value: _vm.submitResults.warehouseName,
                    callback: function ($$v) {
                      _vm.$set(_vm.submitResults, "warehouseName", $$v)
                    },
                    expression: "submitResults.warehouseName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "result-top-right-container",
                attrs: { title: "hf_use_description" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "result-button",
                    attrs: {
                      type: "text",
                      disabled: _vm.buttonDisabled,
                      title: "小车号",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleActions("scan-car-bar-code")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "required-item" }, [_vm._v("*")]),
                    _vm._v("小车号:\n        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    ref: "rectDescRef",
                    staticClass: "result-input",
                    attrs: { placeholder: "请扫码", disabled: "" },
                    model: {
                      value: _vm.submitResults.carCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitResults, "carCode", $$v)
                      },
                      expression: "submitResults.carCode",
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { slot: "append", "icon-class": "scan" },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "result-bottom-container" }, [
            _c(
              "div",
              { staticClass: "result-middle-top-container" },
              [
                _c("span", { staticClass: "title txt" }, [
                  _vm._v("待上架箱号："),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.buttonDisabled,
                      title: "扫描箱号",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleActions("scan-box-bar-code")
                      },
                    },
                  },
                  [_vm._v("扫描箱号")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "count txt" }, [
                  _vm._v("待上架数：" + _vm._s(_vm.getUnboundBoxes.length)),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "result-middle-bottom-container" },
              _vm._l(_vm.getUnboundBoxes, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "box-item" },
                  [
                    _vm._v(
                      "\n          箱号：" + _vm._s(item.code) + "\n          "
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "del-button",
                        attrs: { type: "text", disabled: _vm.buttonDisabled },
                        on: {
                          click: function ($event) {
                            return _vm.delBox(item.code)
                          },
                        },
                      },
                      [_vm._v("删除" + _vm._s(index + 1))]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "submit-button square-circle-button",
              attrs: { type: "text", disabled: _vm.buttonDisabled },
              on: {
                click: function ($event) {
                  return _vm.handleActions("to-inbound")
                },
              },
            },
            [_vm._v("去上架")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "custom-dialog",
        {
          ref: "chooseItemDialog",
          attrs: { title: "选择仓库", width: "500px", color: "#2e5aff" },
          on: { close: _vm.closeChooseItemDialog },
        },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.closeChooseItemDialog },
                  model: {
                    value: _vm.chooseItem,
                    callback: function ($$v) {
                      _vm.chooseItem = $$v
                    },
                    expression: "chooseItem",
                  },
                },
                _vm._l(_vm.chooseItems, function (item, key) {
                  return _c("el-radio", { key: key, attrs: { label: item } }, [
                    _vm._v(_vm._s(item.name)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-section" }, [
      _c("div", { staticClass: "view-title top-title" }, [
        _vm._v("上架-确认上架箱号"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }