<template>
  <ht-table ref="list" v-loading="isLoading" :data="documentList">
    <ht-table-column label="序号" type="index" fixed="left" />
    <ht-table-column
      v-for="item in documentListTitle"
      :key="item.prop"
      :label="item.label"
      :prop="item.prop"
      :min-width="item.width || 100"
      :fixed="item.fixed"
    >
      <template v-slot="{row}">
        <span v-if="item.label === '资料名称'">
          <dw-file :url="row.fileUrl" :show-del="false" @view-file="downloadFile" />
        </span>
        <span v-else>{{ row[item.prop] || '-' }}</span>
      </template>
    </ht-table-column>
    <ht-table-column-operation :width="showOperation ? 200 : 120">
      <template v-slot="{row,$index}">
        <el-link v-if="isCanPreview(row.fileUrl) !== 'other'" icon="el-icon-view" type="primary" :href="row.fileUrl" target="_blank">预览</el-link>
        <ht-button type="text" icon="el-icon-download" @click="downloadFile(row.fileUrl)">下载</ht-button>
        <ht-popover-button
          v-if="showOperation"
          type="text"
          :danger-text="true"
          icon="el-icon-delete"
          confirm-title="请确认是否要删除该条数据？"
          @confirmed="onDelete($index)"
        >删除
        </ht-popover-button>
      </template>
    </ht-table-column-operation>
  </ht-table>
</template>

<script>
import { documentListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import HtListContainer from '@/components/HtListContainer'
import { downloadFile, isCanPreview } from '@/utils'

export default {
  name: 'DocumentList',
  extends: HtListContainer,
  props: {
    documentList: {
      type: Array,
      default: () => []
    },
    showOperation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      documentListTitle
    }
  },
  methods: {
    downloadFile, isCanPreview,
    onDelete(index) {
      this.$emit('on-delete', index)
    }
  }
}
</script>

<style scoped>

</style>
