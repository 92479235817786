<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.code" placeholder="设备编号" />
        <el-input v-model="listQuery.name" placeholder="设备名称" />
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <el-button type="success" icon="el-icon-plus" @click="addDevice">新增设备</el-button>
        <!--        <el-button v-if="!showSelector" type="danger" icon="el-icon-delete" @click="showSelectedCheckRecord">-->
        <!--          批量删除-->
        <!--        </el-button>-->
        <!--        <template v-else>-->
        <!--          <el-button type="danger" icon="el-icon-delete">-->
        <!--            确定删除-->
        <!--          </el-button>-->
        <!--          <el-button type="text" @click="showSelector = false">取消删除</el-button>-->
        <!--        </template>-->
      </template>
    </ht-action-panel>

    <ht-table
      ref="list"
      v-loading="isLoading"
      :data="list"
      style="cursor: pointer"
      :row-key="getRowKey"
      @row-click="handleRowClick"
      @selection-change="selectionChange"
    >
      <ht-table-column-selection v-if="showSelector" />
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="i in labelToProps" :key="i.prop" :label="i.label" :prop="i.prop" :width="i.width || 100">
        <template v-slot="{row}">
          <span v-if="i.format === 'img'">
            <el-image
              v-if="row.imgUrls.length > 0"
              style="width: 100px; height: 100px"
              :src="row.imgUrls[0]"
              :preview-src-list="row.imgUrls"
              fit="contain"
            />
            <el-image
              v-else
              style="width: 100px; height: 100px"
              :src="placeholderImg"
              fit="contain"
            />
          </span>
          <span v-else-if="i.format === 'status'">
            <status :status="row[i.prop]" effect="light" :status-types="{ 可用: 'success', 不可用: 'danger' }" />
          </span>
          <span v-else>
            <span>{{ i.secondProp ? row[i.prop][i.secondProp] :row[i.prop] }}</span>
          </span>
        </template>
      </ht-table-column>
      <el-table-column label="蓝牙信标mac地址">
        <template v-slot="{row, $index}">
          <div>
            <span style=" margin-right: 10px">{{ row.beaconMacAddr || '-' }}</span>
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              icon="el-icon-edit"-->
            <!--              @click="editMac(row)"-->
            <!--            >编辑</el-button>-->
          </div>
        </template>
      </el-table-column>
      <el-table-column label="设备二维码">
        <template v-slot="{row, $index}">
          <el-popover
            placement="right"
            width="230"
            height="400"
            trigger="hover"
            style="margin-right: 20px"
          >
            <span>
              <vue-qr ref="Qrcode" :text="row.code" :size="200" />
            </span>
            <el-link slot="reference" style="width:30px;margin: 0 auto"><vue-qr :text="row.code" :size="30" /></el-link>
          </el-popover>
          <el-button
            type="text"
            icon="el-icon-printer"
            @click="printQRCode()"
          >打印二维码</el-button>
        </template>
      </el-table-column>
      <el-table-column label="设备来源" width="100">
        <template v-slot="{row}">
          <span>{{ row.isSyncFromOuter? '外部同步': '内部创建' }}</span>
        </template>
      </el-table-column>
      <ht-table-column-operation width="200">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-edit" @click="editDevice(row)">编辑</ht-button>
          <ht-button type="text" icon="el-icon-view" @click="gotoView(row.code, row.id, row.isSyncFromOuter)">详情</ht-button>
          <ht-popover-button
            v-if="!row.isSyncFromOuter"
            type="text"
            icon="el-icon-delete"
            :danger-text="true"
            confirm-title="请确认是否要删除该设备？"
            @confirmed="delDevice(row)"
          >删除
          </ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />

    <ht-print ref="printQRCode">
      <div>
        <img :src="qRCodeImg">
      </div>
    </ht-print>

    <ht-dialog ref="macFormDialog" title="编辑mac地址" width="30%">
      <div slot="content">
        <mac-form v-if="macFormDialog" ref="macFrom" :device-id="deviceId" :beacon-mac-addr="beaconMacAddr" @on-cancel="cancelMacFrom" @on-confirm="confirmMacFrom" />
      </div>
    </ht-dialog>

    <ht-dialog ref="addDeviceDialog" title="新增设备" width="40%" @close="closeAddDeviceDialog">
      <div slot="content">
        <device-form v-if="addDeviceDialog" ref="addDeviceForm" @on-cancel="cancelAddDeviceFrom" @on-confirm="confirmAddDeviceFrom" />
      </div>
    </ht-dialog>

    <ht-dialog ref="editDeviceDialog" title="编辑设备" width="40%" @close="closeEditDeviceDialog">
      <div slot="content">
        <device-form v-if="editDeviceDialog" ref="editDeviceForm" is-edit @on-cancel="cancelEditDeviceFrom" @on-confirm="confirmEditDeviceFrom" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getEquipmentsMgrApi, delEquipmentsMgrItemById } from '@/api/devicesMgr/deviceMgr'
import placeholderImg from '@/assets/image/placeholders/placeholder.png'
import MacForm from '@/views/deviceMgr/components/macForm'
import deviceForm from '@/views/deviceMgr/components/deviceForm'
import { deepClone } from '@/utils/index.js'

export default {
  components: { MacForm, deviceForm },
  extends: HtListContainer,
  data() {
    return {
      qRCodeImg: '',
      labelToProps: [
        { label: '设备编号', prop: 'code' },
        { label: '设备名称', prop: 'name' },
        { label: '设备图片', prop: 'deviceImg', minWidth: 100, format: 'img', width: '150' },
        { label: '设备状态', prop: 'showActivated', minWidth: 50, fixed: 'right', format: 'status' }
      ],
      showSelector: false,
      selectedCheckRecord: [],
      placeholderImg,
      deviceId: undefined,
      macFormDialog: false,
      beaconMacAddr: undefined,
      addDeviceDialog: false,
      editDeviceDialog: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    deepClone,
    getList() {
      this.beforeGetList()
      getEquipmentsMgrApi(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.showActivated = item.activated ? '可用' : '不可用'
        })
        this.afterGetList(resp)
      })
    },
    selectionChange(val) {
      this.selectedCheckRecord = val
    },
    showSelectedCheckRecord() {
      this.showSelector = true
      this.$message({
        message: '请勾选需要批量删除的记录',
        center: true,
        type: 'warning'
      })
    },
    handleRowClick(row, column) {
      if (column.label !== '设备图片' && column.label !== '设备二维码' && column.label !== '操作' && !this.showSelector) {
        this.gotoView(row.code, row.id, row.isSyncFromOuter)
      }
    },
    gotoView(deviceCode, deviceId, isSync) {
      this.$router.push({ name: 'deviceMgrView', params: { deviceCode: deviceCode, deviceId: deviceId, isSync: isSync }})
    },
    printQRCode() {
      this.qRCodeImg = this.$refs.Qrcode.$el.src
      this.$nextTick(() => {
        this.$refs.printQRCode.print()
      })
    },
    editMac(row) {
      this.$nextTick(() => {
        this.$refs.macFormDialog.dialogVisible = true
        this.macFormDialog = true
        this.deviceId = row.id
        this.beaconMacAddr = row.beaconMacAddr
        this.$nextTick(() => {
          this.$refs.macFrom.getBeaconMacAddr()
        })
      })
    },
    cancelMacFrom() {
      this.macFormDialog = false
      this.$refs.macFormDialog.dialogVisible = false
      this.deviceId = undefined
      this.beaconMacAddr = undefined
    },
    confirmMacFrom() {
      this.cancelMacFrom()
      this.getList()
    },
    addDevice() {
      this.$nextTick(() => {
        this.$refs.addDeviceDialog.dialogVisible = true
        this.addDeviceDialog = true
      })
    },
    closeAddDeviceDialog() {
      this.$nextTick(() => {
        this.cancelAddDeviceFrom()
      })
    },
    cancelAddDeviceFrom() {
      this.addDeviceDialog = false
      this.$refs.addDeviceDialog.dialogVisible = false
    },
    confirmAddDeviceFrom() {
      this.cancelAddDeviceFrom()
      this.getList()
    },
    editDevice(row) {
      this.$nextTick(() => {
        this.$refs.editDeviceDialog.dialogVisible = true
        this.editDeviceDialog = true
        const deviceDetail = this.deepClone(row)
        const deviceInfo = {
          code: deviceDetail.code,
          name: deviceDetail.name,
          imgUrls: deviceDetail.imgUrls,
          beaconMacAddr: deviceDetail.beaconMacAddr
        }
        this.$nextTick(() => {
          this.$refs.editDeviceForm.getDeviceInfo(row.id, row.isSyncFromOuter, deviceInfo)
        })
      })
    },
    delDevice(row) {
      delEquipmentsMgrItemById(row.id).then(() => {
        this.$message({ message: '删除成功', type: 'success' })
        this.getList()
      })
    },
    closeEditDeviceDialog() {
      this.$nextTick(() => {
        this.cancelEditDeviceFrom()
      })
    },
    cancelEditDeviceFrom() {
      this.editDeviceDialog = false
      this.$refs.editDeviceDialog.dialogVisible = false
    },
    confirmEditDeviceFrom() {
      this.cancelEditDeviceFrom()
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}
</style>
