var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "step-ui-detail-container",
          class: { "is-used-step-container": !_vm.isEdit },
          style: { height: _vm.height + "px" },
          attrs: { title: "hf_use_description" },
        },
        [
          _c(
            "div",
            { staticClass: "view-container" },
            [
              _c("top-section", {
                ref: "topSection",
                attrs: {
                  "is-focused-item": _vm.isFocusedItem,
                  "current-index": _vm.currentIndex,
                  count: _vm.count,
                  title: _vm.properties.title,
                  "show-more-function": _vm.showMoreFunction,
                  "must-upload-photo": _vm.properties.mustUploadPhoto,
                  "upload-photos": _vm.submitResults.uploadPhotos,
                  "active-img-index": _vm.activeImgIndex,
                  "button-disabled": _vm.buttonDisabled,
                  "show-photos": !_vm.showMiddlePhotos,
                },
                on: {
                  "more-function": function ($event) {
                    return _vm.moreFunction()
                  },
                  "native-play-tts": function ($event) {
                    return _vm.nativePlayTTS(_vm.ttsText)
                  },
                  "to-native-scan-code": _vm.toNativeScanCode,
                  "to-remote-guidance": _vm.toRemoteGuidance,
                  "to-ai-qa-assistant": _vm.toAiQaAssistant,
                  "native-take-photo": _vm.toTakePhoto,
                  "open-photo": _vm.openPhoto,
                  "close-photo": _vm.closePhoto,
                  "del-photo": _vm.delPhoto,
                  "prev-photo": _vm.prevPhoto,
                  "next-photo": _vm.nextPhoto,
                  "set-active-item": _vm.setActiveItem,
                  "jump-step": _vm.jumpStep,
                },
              }),
              _vm._v(" "),
              _c("middle-section", {
                ref: "middleSection",
                attrs: {
                  "is-focused-item": _vm.isFocusedItem,
                  "active-name": _vm.activeName,
                  "reference-image": _vm.properties.referenceImage,
                  text: _vm.properties.text,
                  "fact-desc": _vm.submitResults.factDesc,
                  "button-disabled": _vm.buttonDisabled,
                  height: _vm.getMiddleHeight,
                  "show-text": false,
                  "show-reference-image": false,
                  "show-photos": _vm.showMiddlePhotos,
                  "must-upload-photo": _vm.properties.mustUploadPhoto,
                  "active-img-index": _vm.activeImgIndex,
                  "upload-photos": _vm.submitResults.uploadPhotos,
                },
                on: {
                  "select-reference-image": _vm.selectReferenceImage,
                  "select-text": _vm.selectText,
                  "select-photos": _vm.selectPhotos,
                  "set-active-item": _vm.setActiveItem,
                  "open-photo": _vm.openPhoto,
                  "close-photo": _vm.closePhoto,
                  "del-photo": _vm.delPhoto,
                  "prev-photo": _vm.prevPhoto,
                  "next-photo": _vm.nextPhoto,
                  "input-desc": _vm.inputDesc,
                  "clear-fact-desc": _vm.clearFactDesc,
                  "open-reference-image": _vm.openReferenceImage,
                  "close-reference-viewer": _vm.closeReferenceViewer,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "middle-second-section" }, [
                _c("div", { staticClass: "result-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "result-left-container",
                      attrs: { title: "hf_use_description" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "result-button",
                          attrs: {
                            type: "text",
                            disabled: _vm.buttonDisabled,
                            title: "检查类型",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openChooseItemDialog("检查类型")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "required-item" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("检查类型:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        ref: "rectDescRef",
                        staticClass: "result-input",
                        attrs: { placeholder: "请选择检查类型", disabled: "" },
                        model: {
                          value: _vm.submitResults.checkType,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitResults, "checkType", $$v)
                          },
                          expression: "submitResults.checkType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "result-right-container",
                      attrs: { title: "hf_use_description" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "result-button",
                          attrs: {
                            type: "text",
                            disabled: _vm.buttonDisabled,
                            title: "隐患等级",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openChooseItemDialog("隐患等级")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "required-item" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("隐患等级:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        ref: "rectDescRef",
                        staticClass: "result-input",
                        attrs: { placeholder: "请选择隐患等级", disabled: "" },
                        model: {
                          value: _vm.submitResults.errorLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitResults, "errorLevel", $$v)
                          },
                          expression: "submitResults.errorLevel",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("bottom-section", {
                ref: "bottomSection",
                attrs: {
                  count: _vm.count,
                  "current-index": _vm.currentIndex,
                  "is-last-step": _vm.isLastStep,
                  "button-disabled": _vm.buttonDisabled,
                },
                on: {
                  "to-prev": function ($event) {
                    return _vm.toPrev()
                  },
                  "to-next": function ($event) {
                    return _vm.toNext()
                  },
                  "save-record": function ($event) {
                    return _vm.saveRecord()
                  },
                  "submit-record": function ($event) {
                    return _vm.submitRecord()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "custom-dialog",
            {
              ref: "chooseItemDialog",
              attrs: {
                title: "选择" + _vm.dialogTitle,
                width: "500px",
                color: "#2e5aff",
              },
              on: { close: _vm.closeChooseItemDialog },
            },
            [
              _c(
                "template",
                { slot: "content" },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.closeChooseItemDialog },
                      model: {
                        value: _vm.chooseItem,
                        callback: function ($$v) {
                          _vm.chooseItem = $$v
                        },
                        expression: "chooseItem",
                      },
                    },
                    _vm._l(_vm.chooseItems, function (item, key) {
                      return _c(
                        "el-radio",
                        {
                          key: key,
                          class: ["radio-" + item.color],
                          attrs: { label: item.name },
                        },
                        [_vm._v(_vm._s(item.value))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }