var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClearSearch } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "工程名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "工程地址" },
                model: {
                  value: _vm.listQuery.location,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "location", $$v)
                  },
                  expression: "listQuery.location",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd",
                  placement: "bottom-start",
                  "start-placeholder": "工程开始日期",
                  "end-placeholder": "工程结束日期",
                },
                model: {
                  value: _vm.createdAt,
                  callback: function ($$v) {
                    _vm.createdAt = $$v
                  },
                  expression: "createdAt",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "工程状态" },
                  model: {
                    value: _vm.listQuery.isFinished,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isFinished", $$v)
                    },
                    expression: "listQuery.isFinished",
                  },
                },
                _vm._l(_vm.ProjectStatusEnum, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.value, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v("新增工程")]
              ),
              _vm._v(" "),
              _vm.list.length > 0
                ? _c(
                    "ht-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-pie-chart" },
                      on: { click: _vm.openProjectStatisticalCakeDialog },
                    },
                    [_vm._v("工程统计")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
          on: { "row-click": _vm.handleRowClick },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.ProjectListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "工程地址"
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(row[item.prop] || "-") +
                                  "\n        "
                              ),
                            ])
                          : item.label === "工程起止日期"
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.projectStartAt
                                      ? row.projectStartAt +
                                          "至" +
                                          row.projectEndAt
                                      : "-"
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : item.label === "工程资料"
                          ? [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type:
                                      row.attachmentData &&
                                      row.attachmentData.length > 0
                                        ? "primary"
                                        : "info",
                                    disabled:
                                      row.attachmentData &&
                                      row.attachmentData.length === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDocumentDialog(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      (row.attachmentData &&
                                        row.attachmentData.length) ||
                                        0
                                    )
                                  ),
                                ]
                              ),
                            ]
                          : item.label === "绑定客户账号"
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.customer ? row.customer.fullName : "-"
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : item.label === "关联工单"
                          ? [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type:
                                      row.workOrderCount > 0
                                        ? "primary"
                                        : "info",
                                    disabled: row.workOrderCount === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openWorkOrderDialog(row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.workOrderCount || 0))]
                              ),
                            ]
                          : item.label === "工程进度"
                          ? [
                              row[item.prop] || row[item.prop] === 0
                                ? _c("el-progress", {
                                    staticStyle: { width: "95%" },
                                    attrs: {
                                      percentage: row[item.prop],
                                      "show-text": !row.isFinished,
                                      color: row.isFinished
                                        ? "#e6ebf5"
                                        : "#2e5aff",
                                    },
                                  })
                                : [_vm._v("-")],
                            ]
                          : item.label === "工程状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.getProjectStatus(row[item.prop])
                                    .style,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getProjectStatus(row[item.prop])
                                      .value || "-"
                                  )
                                ),
                              ]
                            )
                          : item.label === "现存设备数"
                          ? _c("span", [_vm._v(_vm._s(row[item.prop] || 0))])
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.isMobile
            ? _c("ht-table-column-operation", {
                attrs: { width: 110 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("dw-table-dropdown-buttons", {
                            attrs: { buttons: row.buttons, row: row },
                            on: {
                              "handle-button-click": function ($event) {
                                return _vm.handleButtonClick(row, arguments)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2614970760
                ),
              })
            : _c("ht-table-column-operation", {
                attrs: { width: 360 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "ht-button",
                          {
                            attrs: {
                              disabled: row.isFinished,
                              type: "text",
                              icon: "el-icon-setting",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openLeaseEquipmentMgrDialog(row)
                              },
                            },
                          },
                          [_vm._v("租用设备")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-button",
                          {
                            attrs: { icon: "el-icon-view", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onView(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-button",
                          {
                            attrs: {
                              disabled: row.isFinished,
                              type: "text",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              disabled: row.isFinished,
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-warning-outline",
                              "confirm-title":
                                "操作后无法取消，请确认是否要结束工程!",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onFinish(row)
                              },
                            },
                          },
                          [_vm._v("结束工程")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除该条数据？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onDelete(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "createProjectFormDialog",
          attrs: {
            title: "新增工程",
            width: _vm.isMobile ? "100%" : "900px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeCreateProjectDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showCreateProjectFormDialog
                ? _c("project-form", {
                    on: {
                      "cancel-submit": _vm.closeCreateProjectDialog,
                      "confirm-submit": _vm.submitCreateProjectForm,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "documentDialog",
          attrs: { title: "工程资料", width: _vm.isMobile ? "100%" : "800px" },
          on: { close: _vm.closeDocumentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showDocumentList
                ? _c("document-list", {
                    ref: "documentList",
                    attrs: {
                      "document-list": _vm.documentList,
                      "show-operation": false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "workOrderDialog",
          attrs: { title: "工程工单", width: _vm.isMobile ? "100%" : "1000px" },
          on: { close: _vm.closeWorkOrderDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.getWorkOrderList },
                  model: {
                    value: _vm.workOrderActiveName,
                    callback: function ($$v) {
                      _vm.workOrderActiveName = $$v
                    },
                    expression: "workOrderActiveName",
                  },
                },
                _vm._l(_vm.getTaskTempTypeEnum(), function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.value, name: item.name },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.showWorkOrderList
                ? _c("work-order-list", {
                    ref: "workOrderList",
                    attrs: {
                      "is-loading": _vm.isWorkOrderLoading,
                      "table-title": _vm.workOrderTableTitle,
                      list: _vm.workOrderList,
                      page: _vm.workOrderPage,
                      "list-query": _vm.workOrderListQuery,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "leaseEquipmentMgrDialog",
          attrs: {
            title: "租用设备管理",
            width: _vm.isMobile ? "100%" : "900px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeLeaseEquipmentMgrDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showLeaseEquipmentMgr
                ? _c("lease-equipment-mgr", {
                    ref: "leaseEquipmentMgr",
                    attrs: {
                      "project-id": _vm.projectId,
                      "project-data": _vm.projectData,
                    },
                    on: {
                      "cancel-submit": _vm.closeLeaseEquipmentMgrDialog,
                      "confirm-submit": _vm.submitLeaseEquipmentMgrDialog,
                      "confirm-update": _vm.updateLeaseEquipmentMgrDialog,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "projectProgressChartDialog",
          attrs: {
            title: "工程分阶段完整情况统计",
            width: _vm.isMobile ? "100%" : "650px",
          },
          on: { close: _vm.closeProjectStatisticalCakeDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showProjectProgressChart
                ? _c("project-progress-chart", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isProjectProgressChartLoading,
                        expression: "isProjectProgressChartLoading",
                      },
                    ],
                    ref: "projectProgressChart",
                    attrs: { count: _vm.projectProgressCount, height: "300px" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }