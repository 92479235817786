export const ResultRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

export const UniversalRecordRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

export const ValueRecordRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }],
  dataName: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
  minValue: [
    { required: true, message: '请输入参考下限', trigger: 'blur' },
    { trigger: 'blur' }
  ],
  maxValue: [
    { required: true, message: '请输入参考上限', trigger: 'blur' }
  ],
  dataUnit: [{ required: true, message: '请输入数据单位', trigger: 'blur' }]
}

