var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "step-ui-detail-container",
          class: { "is-used-step-container": !_vm.isEdit },
          style: { height: _vm.height + "px" },
          attrs: { title: "hf_use_description" },
        },
        [
          _vm.showList
            ? _c("confirm-box-inbound", {
                ref: "confirmBoxInbound",
                attrs: {
                  height: _vm.getMiddleHeight,
                  results: _vm.submitResults,
                  disabled: _vm.buttonDisabled,
                },
                on: {
                  "choose-warehouse": _vm.chooseWarehouse,
                  "to-scan-code": _vm.toScanCode,
                  "to-inbound": _vm.toInbound,
                  "del-box": _vm.delBox,
                },
              })
            : !_vm.showList && _vm.showGoods
            ? _c("execute-goods-inbound", {
                ref: "executeGoodsInbound",
                attrs: {
                  height: _vm.getMiddleHeight,
                  results: _vm.submitResults,
                  disabled: _vm.buttonDisabled,
                },
                on: {
                  "to-prev-box": _vm.toPervBox,
                  "to-next-box": _vm.toNextBox,
                  "to-inbound-next-box": _vm.toInboundNextBox,
                  "to-inbound": _vm.toInbound,
                  "to-scan-code": _vm.toScanCode,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }