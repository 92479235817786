<template>
  <div>
    <ht-table ref="list" v-loading="isLoading" :data="list">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in tableTitle"
        :key="item.prop"
        :fixed="isMobile ? false : item.fixed"
        :label="item.label"
        :min-width="item.width || 100"
        :prop="item.prop"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '工单类型'">{{ getTaskTempType(row[item.prop]) ? getTaskTempType(row[item.prop]).value : '' }}</span>
          <span v-else-if="item.label === '作业类型'">{{ getTowerWorkOrderType(row[item.prop]) ? getTowerWorkOrderType(row[item.prop]).value : '-' }}</span>
          <span v-else-if="item.label === '巡检工程'">{{ row.targetInfo && row.targetInfo.projectName || '-' }}</span>
          <span v-else-if="item.label === '巡检设备'">{{ row.targetName ? `${row.targetName}-${row.targetCode}`: '-' }}</span>
          <span v-else-if="item.label === '创建人'">{{ row.staff.fullName }}</span>
          <span v-else-if="item.label === '执行人'">{{ row.executor.fullName }}</span>
          <span v-else-if="item.label === '循环周期'">{{ LoopEnum[row[item.prop]] }}</span>
          <span v-else-if="item.label === '异常项'" :style="{color: row[item.prop] ? 'red' : ''}">{{ `${row[item.prop]}项` }}</span>
          <el-tag v-else-if="item.label === '状态'" :type="TaskWorkOrderStatusStyleEnum[row[item.prop]]">{{ TaskWorkOrderStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <el-tag v-else-if="item.label === '工单执行状态'" :type="TaskWorkOrderStatusStyleEnum[row[item.prop]]">{{ TaskWorkOrderStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <el-tag v-else-if="item.label === '文件上传状态' && row[item.prop]" class="status" :type="FileUploadStatusStyleEnum[row[item.prop]]">{{ FileUploadStatusEnum[row[item.prop]] }}</el-tag>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="100">
        <template v-slot="{row}">
          <ht-button icon="el-icon-view" type="text" @click="onView(row)">详情</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { WorkOrderListTitle } from '@/views/taskMgr/tableTitle'
import {
  FileUploadStatusStyleEnum,
  LoopEnum,
  TaskWorkOrderStatusEnum,
  TaskWorkOrderStatusStyleEnum,
  FileUploadStatusEnum
} from '@/views/taskMgr/enum'
import { mapGetters } from 'vuex'
import { getTaskTempType, getTowerWorkOrderType, getTaskTempTypeEnum } from '@/views/taskMgr/commonJs'

export default {
  name: 'WorkOrderList',
  props: {
    isLoading: { type: Boolean, default: false },
    tableTitle: { type: Array, default: () => [] },
    list: { type: Array, default: () => [] },
    page: { type: Object, default() { return { total: '0' } } },
    listQuery: { type: Object, default: () => {} }
  },
  data() {
    return {
      WorkOrderListTitle,
      LoopEnum, TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum, FileUploadStatusStyleEnum, FileUploadStatusEnum
    }
  },
  computed: {
    ...mapGetters(['userRoutes', 'isMobile'])
  },
  methods: {
    getTaskTempType, getTowerWorkOrderType, getTaskTempTypeEnum,
    isHasWorkOrderMenu() {
      const featureMenuJson = this.userRoutes.featureMenuJson
      const menus = featureMenuJson.filter(item => item.name === 'taskMgrMenu' && item.activated)
      return menus.length > 0
    },
    getList() {
      // this.beforeGetList()
      // getProjectTaskWorkOrders(this.projectId).then(resp => {
      //   this.afterGetList(resp)
      // }).catch(() => {
      //   this.isLoading = false
      // })
      this.$emit('get-list')
    },
    onView(row) {
      if (this.isHasWorkOrderMenu()) {
        const name = row.type === 'PATROL' ? 'patrolWorkOrderMgrView' : 'repairWorkOrderMgrView'
        this.$router.push({
          name: name,
          params: { orderId: row.id, isMy: true }
        })
      } else {
        const name = row.type === 'PATROL' ? 'viewPatrolWorkOrder' : 'viewRepairWorkOrder'
        this.$router.push({
          name: name,
          params: { orderId: row.id, isMy: true }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pagination-container{
  ::v-deep .el-pagination {
    text-align: right;
  }
}
</style>
