import request from '@/utils/request'

const STATIC = 'statistics/'
const TASK_WORK_ORDER = 'task_work_order/'
const CONSTRUCTION_EQUIPMENT = 'construction_equipment/'
const CONSTRUCTION_PROJECT = 'construction_project/'

export function getTaskWorkOrderGridStatistics(listQuery) {
  return request({ url: `${STATIC}${TASK_WORK_ORDER}grid_statistics/`, method: 'GET', params: listQuery })
}

export function getTaskWorkOrderBarStatistics(listQuery) {
  return request({ url: `${STATIC}${TASK_WORK_ORDER}bar_statistics/`, method: 'GET', params: listQuery })
}

// 租用设备统计
export function getEquipmentLeaseStatistics(listQuery) {
  return request({ url: `${STATIC}${CONSTRUCTION_EQUIPMENT}lease_statistics/`, method: 'GET', params: listQuery })
}

// 设备分布
export function getEquipmentDistributionStatistics(listQuery) {
  return request({ url: `${STATIC}${CONSTRUCTION_EQUIPMENT}distribution_statistics/`, method: 'GET', params: listQuery })
}

// （整机/部件）设备总数，出租数统计
export function getEquipmentLeaseCount(listQuery) {
  return request({ url: `${STATIC}${CONSTRUCTION_EQUIPMENT}lease_count/`, method: 'GET', params: listQuery })
}

// 整机年限统计
export function getEquipmentAgeStatistics(listQuery) {
  return request({ url: `${STATIC}${CONSTRUCTION_EQUIPMENT}age_statistics/`, method: 'GET', params: listQuery })
}

// 工程租赁状态统计
export function getProjectLeaseStatistics(listQuery) {
  return request({ url: `${STATIC}${CONSTRUCTION_PROJECT}lease_statistics/`, method: 'GET', params: listQuery })
}

