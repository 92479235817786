// 全屏模式下默认定位：暂时没有用到
export function fullscreenEvent(box, topContainer, onMouseDown, onDrag) {
  box.style.top = 'auto'
  box.style.bottom = 0
  box.style.right = 0
  box.style.left = 'auto'
  topContainer.addEventListener('mousedown', onMouseDown)
  document.addEventListener('mouseup', (e) => {
    box.removeEventListener('mousemove', onDrag)
  })
}

// 正常模式下默认定位：暂时没有用到
export function normalScreenEvent(box, topContainer, onMouseDown, onDrag) {
  topContainer.removeEventListener('mousedown', onMouseDown)
  box.removeEventListener('mousemove', onDrag)
  box.style.top = '60px'
  box.style.bottom = 'auto'
  box.style.right = 0
  box.style.left = 'auto'
}

// 模式定位：目前全屏模式下，正常模式下都用此定位
export function boxMoveEvent(box, topContainer, onMouseDown, onDrag) {
  box.style.top = 'auto'
  box.style.bottom = 0
  box.style.right = 0
  box.style.left = 'auto'
  topContainer.addEventListener('mousedown', onMouseDown)
  document.addEventListener('mouseup', (e) => {
    box.removeEventListener('mousemove', onDrag)
  })
}

export function mouseDown(e, topPosition, box, onDrag) {
  topPosition.startX = e.clientX
  topPosition.startY = e.clientY
  box.addEventListener('mousemove', onDrag)
}

export function drag(e, topPosition, boxPosition, box) {
// 计算移动后的坐标点
  const x = e.clientX - topPosition.startX
  const y = e.clientY - topPosition.startY
  if (box.offsetLeft + x + 380 <= window.innerWidth && box.offsetLeft + x > 0) {
    boxPosition.left = box.offsetLeft + x
    box.style.left = boxPosition.left + 'px'
    topPosition.startX = e.clientX
  }
  if (box.offsetTop + y + 500 <= window.innerHeight && box.offsetTop + y > 0) {
    boxPosition.right = box.offsetTop + y
    box.style.top = boxPosition.right + 'px'
    topPosition.startY = e.clientY
  }
}
