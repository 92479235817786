<template>
  <div>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.name" placeholder="仓库名称" />
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in WarehouseStockListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '当前库存数'">{{ row[item.prop] }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation v-if="isMobile" :width="110">
        <template v-slot="{row}">
          <dw-table-dropdown-buttons :buttons="row.buttons" :row="row" @handle-button-click="handleButtonClick(row,arguments)" />
        </template>
      </ht-table-column-operation>
      <ht-table-column-operation v-else :width="220">
        <template v-slot="{row}">
          <ht-button :disabled="row.quantity === 0" type="text" icon="el-icon-view" @click="onViewWarehouseStockInfo(row)">库存详情</ht-button>
          <ht-button :disabled="row.quantity === 0" type="text" icon="el-icon-right" @click="onWarehouseOutbound(row)">出库</ht-button>
          <ht-button type="text" icon="el-icon-back" @click="onWarehouseInbound(row)">入库</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="warehouseStockInfoDialog" :title="`库存详情（${warehouseName}）`" :width="isMobile ? '100%' : '800px'" @close="closeWarehouseStockInfoDialog">
      <div slot="content">
        <warehouse-stock-info v-if="showWarehouseStockInfo" ref="warehouseStockInfo" />
      </div>
    </ht-dialog>

    <ht-dialog ref="warehouseOutboundDialog" title="出库" :width="isMobile ? '100%' : '800px'" :close-on-click-modal="false" @close="closeWarehouseOutboundDialog">
      <div slot="content">
        <warehouse-outbound-form v-if="showWarehouseOutboundForm" ref="warehouseOutboundForm" @cancel-submit="closeWarehouseOutboundDialog" @confirm-submit="closeWarehouseOutboundDialog(true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="warehouseInboundDialog" title="入库" :width="isMobile ? '100%' : '800px'" :close-on-click-modal="false" @close="closeWarehouseInboundDialog">
      <div slot="content">
        <warehouse-inbound-form v-if="showWarehouseInboundForm" ref="warehouseInboundForm" @cancel-submit="closeWarehouseInboundDialog" @confirm-submit="closeWarehouseInboundDialog(true)" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import WarehouseStockInfo from '@/views/constructionEquipmentMgr/stockMgr/components/warehouseStockInfo'
import WarehouseOutboundForm from '@/views/constructionEquipmentMgr/stockMgr/components/warehouseOutboundForm'
import WarehouseInboundForm from '@/views/constructionEquipmentMgr/stockMgr/components/warehouseInboundForm'
import { StockType } from '@/views/constructionEquipmentMgr/enum'
import { WarehouseStockListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getWarehouseStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import { mapGetters } from 'vuex'

export default {
  name: 'StockByWarehouseList',
  components: { WarehouseStockInfo, WarehouseOutboundForm, WarehouseInboundForm },
  extends: HtListContainer,
  data() {
    return {
      StockType, WarehouseStockListTitle,
      showWarehouseStockInfo: false,
      showWarehouseOutboundForm: false,
      showWarehouseInboundForm: false,
      warehouseName: null
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getWarehouseStocks(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.buttons = [
            { name: '库存详情', icon: 'el-icon-view', isPopoverButton: false, disabled: item.quantity === 0 },
            { name: '出库', icon: 'el-icon-right', isPopoverButton: false, disabled: item.quantity === 0 },
            { name: '入库', icon: 'el-icon-back', isPopoverButton: false }
          ]
        })
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    handleButtonClick(row, $argument) {
      switch ($argument[0]) {
        case '库存详情':
          this.onViewWarehouseStockInfo(row)
          break
        case '出库':
          this.onWarehouseOutbound(row)
          break
        case '入库':
          this.onWarehouseInbound(row)
          break
      }
    },
    onViewWarehouseStockInfo(row) {
      this.warehouseName = row.name
      this.showWarehouseStockInfo = true
      this.$refs.warehouseStockInfoDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.warehouseStockInfo.initInfo(row)
      })
    },
    onWarehouseOutbound(row) {
      this.showWarehouseOutboundForm = true
      this.$refs.warehouseOutboundDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.warehouseOutboundForm.initInfo(row)
      })
    },
    onWarehouseInbound(row) {
      this.showWarehouseInboundForm = true
      this.$refs.warehouseInboundDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.warehouseInboundForm.initInfo(row)
      })
    },
    closeWarehouseStockInfoDialog() {
      this.warehouseName = null
      this.showWarehouseStockInfo = false
      this.$refs.warehouseStockInfoDialog.dialogVisible = false
    },
    closeWarehouseOutboundDialog(isRefresh = false) {
      this.showWarehouseOutboundForm = false
      this.$refs.warehouseOutboundDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    },
    closeWarehouseInboundDialog(isRefresh = false) {
      this.showWarehouseInboundForm = false
      this.$refs.warehouseInboundDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    }
  }
}
</script>

<style scoped>

</style>
