class BasePropertiesClass {
  constructor(properties) {
    this.title = properties.title
    this.referenceImage = properties.referenceImage
    this.guidanceVideo = properties.guidanceVideo
    this.text = properties.text
    this.mustUploadPhoto = properties.mustUploadPhoto
  }
}

export class ResultRecordTypePropertiesClass extends BasePropertiesClass {
  constructor(properties) {
    super(properties)
    this.mustUploadPhoto = properties.mustUploadPhoto
  }
}

const BasePatrolProperties = {
  title: '步骤名称',
  referenceImage: null,
  guidanceVideo: null,
  text: null,
  mustUploadPhoto: null
}

// 通用记录 UniversalRecordType
export const UniversalRecordTypeProperties = {
  ...BasePatrolProperties,
  title: '通用记录'
}

// 判断结果 ResultRecordType
export const ResultRecordTypeProperties = {
  ...BasePatrolProperties,
  title: '判断结果'
}

// 记录数值 ValueRecordType
export const ValueRecordTypeProperties = {
  ...BasePatrolProperties,
  title: '记录数值',
  dataName: null,
  minValue: null,
  maxValue: null,
  dataUnit: null
}

// 隐患登记 HiddenDangerRecordType
export const HiddenDangerRecordTypeProperties = {
  title: '隐患登记',
  mustUploadPhoto: null
}

// 事故登记 AccidentRecordType
export const AccidentRecordTypeProperties = {
  title: '事故登记',
  mustUploadPhoto: null
}

export const PhotoRecordTypeProperties = {
  ...BasePatrolProperties,
  title: '拍照记录'
}

// 快速巡检 QuickPatrolRecordType
export const QuickPatrolRecordTypeProperties = {
  title: '步骤名称'
}

// 商品上架 GoodsInboundType
export const GoodsInboundTypeProperties = {
  title: '商品上架'
}

// 拣货任务 GoodsPickingType
export const GoodsPickingTypeProperties = {
  title: '拣货任务'
}

// 扫码确认 ScanCodeAndConfirmType
export const ScanCodeAndConfirmTypeProperties = {
  title: '扫码确认'
}

// 语音记录数值 VoiceRecordValueType
export const VoiceRecordValueTypeProperties = {
  ...BasePatrolProperties,
  title: '语音记录数值',
  dataName: null,
  minValue: null,
  maxValue: null,
  dataUnit: null
}

// OCR记录数值 OCRRecordValueType
export const OCRRecordValueTypeProperties = {
  ...BasePatrolProperties,
  title: 'OCR记录数值',
  dataName: null,
  minValue: null,
  maxValue: null,
  dataUnit: null
}

// 蓝牙记录数值 KeyboardRecordValueType
export const KeyboardRecordValueTypeProperties = {
  ...BasePatrolProperties,
  title: '蓝牙记录数值',
  dataName: null,
  minValue: null,
  maxValue: null,
  dataUnit: null
}
