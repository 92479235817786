<template>
  <div class="search-container" style="max-width: 100%">
    <el-input v-model="keyword" class="keyword-input" :placeholder="placeholder" :style="{width: width}" style="max-width: 100%" @change="onChange" @focus="onFocus(true)" />
    <div v-if="showContent && (getHistoryList.length > 0 || recordList.length > 0)" class="search-content-container" :style="{width: width}" style="max-width: 100%">
      <div v-if="getHistoryList.length > 0" class="history-container">
        <div class="search-title-container">
          <span class="title">{{ historyListTitle }}</span>
          <ht-popover-button
            type="text"
            class="del-button"
            :danger-text="true"
            icon="el-icon-delete"
            confirm-title="请确认是否要删除搜索历史？"
            @confirmed="onDelHistory"
          >删除历史</ht-popover-button>
        </div>
        <div class="history-list-container">
          <div v-for="(item,index) in getHistoryList" :key="index" class="history-item" @click="onClick(item)">{{ item }}</div>
        </div>
      </div>

      <div v-if="recordList.length > 0" class="record-container">
        <div class="search-title-container">
          <span class="title">{{ recordListTitle }}</span>
        </div>
        <div class="record-list-container">
          <div v-for="(item,index) in recordList" :key="index" class="record-item" @click="onClick(item)">{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchHistoryInput',
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '请输入搜索内容' },
    width: { type: String, default: '300px' },
    historyList: { type: Array, default: () => [] },
    historyListTitle: { type: String, default: '搜索历史' },
    recordList: { type: Array, default: () => [] },
    recordListTitle: { type: String, default: '推荐搜索' }
  },
  data() {
    return {
      keyword: this.value,
      showContent: false
    }
  },
  computed: {
    getHistoryList() {
      return this.historyList.slice(0, 10)
    }
  },
  watch: {
    keyword(newValue) {
      // 监听子组件内部数据的变化，并通过$emit方法向父组件发送一个名为'input'的自定义事件
      // 并传递当前子组件内部的childMessage数据作为参数
      this.$emit('input', newValue)
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    onChange() {
      this.$emit('change', this.keyword)
    },
    onFocus(isShow) {
      this.showContent = isShow
    },
    onDelHistory() {
      this.$emit('on-del')
    },
    onClick(keyword) {
      this.showContent = false
      this.keyword = keyword
      this.$emit('change', this.keyword)
    },
    handleClickOutside() {
      if (!this.$el.contains(event.target)) {
        this.showContent = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.search-container{
  position: relative;
  font-size: 14px;
  .search-content-container,{
    position: absolute;
    z-index: 9;
    background: #ffffff;
    border: 1px solid #dfe6ec;
    border-radius: 5px;
    .history-container, .record-container{
      .search-title-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        margin-top: 5px;
        .title{
          transition: all 0s ease 0s;
          color: rgb(34, 34, 34);
          font-size: 16px;
          display: block;
          line-height: 20px;
          font-weight: 500;
          border-radius: 0;
        }
        .del-button{
          color: red;
        }
      }
      .history-list-container, .record-list-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 5px 10px;
      }
      .history-item, .record-item{
        transition: all 0s ease 0s;
        color: rgb(85, 85, 85);
        font-size: 14px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        border-radius: 18px;
        background-color: rgb(242, 242, 242);
        margin-right: 12px;
        margin-bottom: 10px;
        padding: 6px 16px;
        white-space: nowrap;
        max-width: 95.6%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .history-item:hover,  .record-item:hover{
        background: $primaryColor;
        color: #ffffff;
      }
    }
  }
}
</style>
