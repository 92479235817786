<template>
  <el-card class="container">
    <div class="title-container">
      <div class="left-title">文档分类</div>
      <ht-action-panel class="right-search" :on-search="onSearch" :on-clear="onClear">
        <template slot="left">
          <span class="current-tree-node">当前目录：{{ getCurrentTreeNode }}</span>
          <search-history-input ref="searchHistoryInput" v-model="listQuery.keyword" :history-list="searchHistoryList" @change="onHistorySearch" @on-del="onDelSearchHistory" />
        </template>
      </ht-action-panel>
    </div>
    <div class="content-container">
      <div class="left-category-container">
        <folder-tree
          ref="categoryTree"
          class="left-category-tree"
          :loading="isTreeLoading"
          :tree-list="getTree"
          :has-function-box="false"
          :tree-width="198"
          :is-disabled="isLoading"
          @node-click="handleClickTreeNode"
        />
      </div>
      <div ref="rightKnowledgeContainer" v-loading="isLoading" class="right-knowledge-container">
        <div v-if="list && list.length > 0" class="results-container">
          <result-overview v-for="(item,index) in list" :key="index" :result-info="item" @on-view="onViewKnowledge(index, arguments)" />
        </div>
        <div v-else class="empty-list">
          <img :src="Empty" class="empty-img">
          <span class="empty-info">暂无结果</span>
        </div>
        <div v-if="isLoading && !isListLoading" class="loading-spinner"><img class="loading-img" :src="Loading" alt="">数据加载中...</div>
        <div v-if="isEnd && list.length > 0" class="already-loaded">已经到底部了</div>
      </div>
    </div>
    <ht-dialog ref="knowledgeViewDialog" title="文档详情" width="1000px" :fullscreen="isFullscreenKnowledgeViewDialog" class="knowledge-view-dialog" @close="closeKnowledgeViewDialog">
      <div slot="close">
        <img v-if="isFullscreenKnowledgeViewDialog" class="maximize fullscreen-item" :src="ExitFul" alt="" @click="toggleFullscreenKnowledgeViewDialog">
        <img v-else class="maximize fullscreen-item" :src="EnterFul" alt="" @click="toggleFullscreenKnowledgeViewDialog">
      </div>
      <div slot="content">
        <knowledge-view v-if="showKnowledgeView" ref="knowledgeView" :list="list" :keyword="listQuery.keyword" @on-close="closeKnowledgeViewDialog" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import _ from 'lodash'
import Empty from '@/assets/image/mobile/mobile_empty.png'
import FolderTree from '@/views/components/tree/folderTree'
import ResultOverview from '@/views/knowledgeBaseMgr/knowledgeSearch/components/resultOverview'
import KnowledgeView from '@/views/knowledgeBaseMgr/knowledgeSearch/components/knowledgeView'
import SearchHistoryInput from '@/views/knowledgeBaseMgr/knowledgeSearch/components/searchHistoryInput'
import { getKnowledgeBasesEs } from '@/api/knowleageBaseMgr/knowleageBaseMgr'
import { getDocumentCategoryTree } from '@/api/knowleageBaseMgr/documentCategoryMgr'
import { getObject, setObject, removeObject } from '@/store/localStorage'
import EnterFul from '@/assets/image/newRemoteGuidance/enter_full.png'
import ExitFul from '@/assets/image/newRemoteGuidance/exit_full.png'
import Loading from '@/assets/image/mobile/mobile_loading.png'

export default {
  name: 'Index',
  components: { FolderTree, ResultOverview, KnowledgeView, SearchHistoryInput },
  data() {
    return {
      Empty, EnterFul, ExitFul, Loading,
      tree: [],
      isTreeLoading: false,
      listQuery: {
        keyword: null,
        cate1Id: null,
        cate2Id: null,
        offset: 0,
        count: 10
      },
      list: [],
      currentNodeData: null,
      showKnowledgeView: false,
      searchHistoryList: [],
      isFullscreenKnowledgeViewDialog: true,
      isEnd: false,
      isLoading: false,
      isListLoading: false
    }
  },
  computed: {
    getTree() {
      let tree = _.cloneDeep(this.tree)
      tree.forEach(firstItem => {
        const firstOtherTree = firstItem.children
        firstItem.children = []
        tree = [firstItem, ...firstOtherTree]
      })
      return tree
    },
    getCurrentTreeNode() {
      if (this.currentNodeData) {
        return this.currentNodeData.name
      } else {
        return '全部'
      }
    }
  },
  created() {
    this.getDocumentCategoryTree()
    this.searchHistoryList = getObject('knowledgeMgr') && getObject('knowledgeMgr').searchHistoryList || []
  },
  mounted() {
    window.addEventListener('wheel', this.checkDistanceToBottom,)
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.checkDistanceToBottom,)
  },
  methods: {
    checkDistanceToBottom(event) {
      this.$nextTick(() => {
        if (event.deltaY > 0) {
          const container = this.$refs.rightKnowledgeContainer // 获取元素的引用
          const containerRect = container.getBoundingClientRect() // 获取元素相对于视口的位置信息
          const distanceToBottom = containerRect.bottom - window.innerHeight // 计算元素底部距离屏幕底部的距离
          if (!this.isLoading && !this.isEnd && distanceToBottom <= 400) {
            this.listQuery.offset += this.listQuery.count
            this.getList(true, false)
          }
        }
      })
    },
    getDocumentCategoryTree() {
      this.isTreeLoading = true
      getDocumentCategoryTree().then(resp => {
        this.isTreeLoading = false
        this.tree = resp
        this.$nextTick(() => {
          this.$refs.categoryTree.setCurrentNode(0, true)
          this.currentNodeData = null
          this.getList(false, true)
        })
      }).catch(() => {
        this.isTreeLoading = false
      })
    },
    handleClickTreeNode(node, data) {
      this.$refs.categoryTree.setCurrentNode(0, data.level === 0)
      this.currentNodeData = data.level === 0 ? null : data
      this.getList(false, true)
    },
    onDelSearchHistory() {
      this.searchHistoryList = []
      removeObject('knowledgeMgr')
    },
    onHistorySearch(keyword) {
      this.listQuery.keyword = keyword || this.listQuery.keyword
      this.onSearch()
    },
    onSearch() {
      if (this.listQuery.keyword) {
        this.searchHistoryList.unshift(this.listQuery.keyword)
        this.searchHistoryList = [...new Set(this.searchHistoryList)]
        const knowledgeMgrObject = {
          searchHistoryList: this.searchHistoryList
        }
        setObject('knowledgeMgr', knowledgeMgrObject)
      }
      this.getList(false, true, true)
    },
    onClear() {
      this.listQuery.keyword = null
      this.$refs.searchHistoryInput.keyword = null
      this.getList(false, true, true)
    },
    getList(isConcat = false, isListLoading = true, isRefresh = false) {
      this.listQuery.cate1Id = this.currentNodeData && this.currentNodeData.id || null
      this.listQuery.cate2Id = this.currentNodeData && this.currentNodeData.parentId || null
      this.isLoading = true
      this.isListLoading = isListLoading
      this.listQuery.offset = isRefresh ? 0 : this.listQuery.offset
      getKnowledgeBasesEs(this.listQuery).then(resp => {
        this.isLoading = false
        this.isListLoading = false
        this.list = isConcat ? this.list.concat(resp) : resp
        this.isEnd = resp.length === 0
      }).catch(() => {
        this.isLoading = false
        this.isListLoading = false
      })
    },
    onViewKnowledge(index, $arguments) {
      this.showKnowledgeView = true
      this.$refs.knowledgeViewDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.knowledgeView.listIndex = index
        this.$refs.knowledgeView.getDetail($arguments)
      })
    },
    closeKnowledgeViewDialog() {
      this.isFullscreenKnowledgeViewDialog = true
      this.showKnowledgeView = false
      this.$refs.knowledgeViewDialog.dialogVisible = false
    },
    toggleFullscreenKnowledgeViewDialog() {
      this.isFullscreenKnowledgeViewDialog = !this.isFullscreenKnowledgeViewDialog
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.container{
  height: 100%;
  position: relative;
  ::v-deep .el-card__body{
    width: 100%;
    height: 100%;
    padding: 0;
    .title-container{
      display: flex;
      flex-direction: row;
      position: fixed;
      width: 100%;
      top: 50px;
      background: #ffffff;
      z-index: 9;
      padding: 15px 20px 10px;
      .left-title{
        font-size: 18px;
        color: $primaryColor;
        font-weight: bolder;
        width: 200px;
        margin-right: 20px;
      }
      .right-search{
        .current-tree-node{
          background: #eef2ff;
          padding: 5px;
        }
      }
    }
    .content-container {
      display: flex;
      flex-direction: row;
      margin-top: 60px;
      padding: 20px;
      .left-category-container{
        width: 200px;
        margin-right: 20px;
        border: 1px solid #dfe6ec;
      }
      .right-knowledge-container{
        flex: 1;
        width: calc(100% - 300px);
        max-height: 800px;
        overflow-y: scroll;
        .results-container{
          min-height: 300px;
        }
        .empty-list{
          height: 300px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .empty-info{
            color: #bbbbbb;
          }
        }
        .loading-spinner{
          padding: 10px 15px;
          text-align: center;
          color: $primaryColor;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .loading-img {
            width: 30px;
            height: 30px;
            animation: rotate 4s linear infinite; /* 4秒钟内完成一次旋转，无限循环 */
            margin-right: 10px;
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
        .already-loaded{
          padding: 10px 15px;
          text-align: center;
          color: #666666;
        }
      }
    }
  }
}

.fullscreen-item{
  width: 20px;
  height: 20px;
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 10px;
}
</style>

<style lang="scss">
.knowledge-view-dialog{
  .is-fullscreen{
    .el-dialog__body{
      height: calc(100% - 80px) !important;
    }
    .el-dialog__body > div {
      height: 100%;
    }
    .knowledge-container{
      height: 100%;
      display: flex;
      flex-direction: column;
      .content{
        flex: 1;
        max-height: none;
      }
    }
  }
}
</style>
