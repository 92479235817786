var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "inbound-container",
    },
    [
      _c("equipment-card", {
        attrs: {
          "is-loading": _vm.isEquipmentLoading,
          "equipment-info": _vm.equipmentInfo,
        },
      }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "inboundForm",
          staticClass: "mobile-form",
          attrs: {
            model: _vm.inboundForm,
            rules: _vm.inboundFormRules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "入库文件：", prop: "attachmentData" } },
            [
              _vm.inboundForm.attachmentData
                ? _c("dw-file", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      url: _vm.inboundForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.inboundForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["doc", "pdf", "img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传入库文件",
                      "button-type": "primary",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备来源：",
                prop: "constructionEquipmentSource",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    disabled:
                      _vm.equipmentInfo && _vm.equipmentInfo.isWhole
                        ? (_vm.equipmentInfo && _vm.equipmentInfo.isWhole) ||
                          _vm.projectsQuantities === 0
                        : false,
                  },
                  on: { change: _vm.handleEquipmentSourceChange },
                  model: {
                    value: _vm.inboundForm.constructionEquipmentSource,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.inboundForm,
                        "constructionEquipmentSource",
                        $$v
                      )
                    },
                    expression: "inboundForm.constructionEquipmentSource",
                  },
                },
                _vm._l(_vm.EquipmentSourceEnum, function (value, key) {
                  return _c("el-radio", { key: key, attrs: { label: key } }, [
                    _vm._v(_vm._s(value)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.equipmentInfo && _vm.equipmentInfo.isWhole
            ? [
                _vm.inboundForm.constructionEquipmentSource === "OLD"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "选择工程：",
                          prop: "constructionProjectId",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              "popper-class": "mobile-select",
                            },
                            model: {
                              value: _vm.inboundForm.constructionProjectId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inboundForm,
                                  "constructionProjectId",
                                  $$v
                                )
                              },
                              expression: "inboundForm.constructionProjectId",
                            },
                          },
                          _vm._l(_vm.projectList, function (item) {
                            return _c("el-option", {
                              key: item.constructionProjectId,
                              attrs: {
                                label: item.constructionProjectName,
                                value: item.constructionProjectId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "入库仓库：", prop: "warehouseId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "remote-method": _vm.getWarehouses,
                          placeholder: "请选择入库仓库",
                          "popper-class": "mobile-select",
                        },
                        on: { change: _vm.handleWarehouseChange },
                        model: {
                          value: _vm.inboundForm.warehouseId,
                          callback: function ($$v) {
                            _vm.$set(_vm.inboundForm, "warehouseId", $$v)
                          },
                          expression: "inboundForm.warehouseId",
                        },
                      },
                      _vm._l(_vm.warehouseList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "入库数量：", prop: "inboundQuantity" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "", placeholder: "请输入入库数量" },
                      model: {
                        value: _vm.inboundForm.inboundQuantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.inboundForm, "inboundQuantity", $$v)
                        },
                        expression: "inboundForm.inboundQuantity",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _vm.inboundForm.constructionEquipmentSource === "OLD"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "选择工程：",
                          prop: "constructionProjectId",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              placeholder:
                                _vm.projectList.length > 0
                                  ? "请输入工程名称进行搜索"
                                  : "当前无可选工程",
                            },
                            on: { change: _vm.handleSelectProject },
                            model: {
                              value: _vm.inboundForm.constructionProjectId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inboundForm,
                                  "constructionProjectId",
                                  $$v
                                )
                              },
                              expression: "inboundForm.constructionProjectId",
                            },
                          },
                          _vm._l(_vm.projectList, function (item) {
                            return _c("el-option", {
                              key: item.constructionProjectId,
                              attrs: {
                                label: item.constructionProjectName,
                                value: item.constructionProjectId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.inboundForm.constructionEquipmentSource === "OLD"
                  ? _c("el-form-item", { attrs: { label: "可入库数量：" } }, [
                      _c("span", { staticClass: "quantities" }, [
                        _vm._v(" " + _vm._s(_vm.quantities)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "入库总数量：" } }, [
                  _c("span", { staticClass: "quantities" }, [
                    _vm._v(" " + _vm._s(_vm.inboundQuantities)),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.warehouseList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "warehouse-card",
                      class: {
                        "selected-warehouse-card": item.inboundQuantity > 0,
                      },
                    },
                    [
                      _c("div", { staticClass: "left-item item" }, [
                        _c("span", { staticClass: "name top" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "quantity bottom" }, [
                          _vm._v("当前库存数：" + _vm._s(item.quantity)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "right-item item" },
                        [
                          _c("span", { staticClass: "title top" }, [
                            _vm._v("入库数量"),
                          ]),
                          _vm._v(" "),
                          _vm.inboundForm.constructionEquipmentSource === "OLD"
                            ? _c("el-input-number", {
                                staticClass: "num bottom",
                                attrs: {
                                  min: 0,
                                  max: _vm.quantities,
                                  step: 1,
                                  "step-strictly": "",
                                  disabled:
                                    item.inboundQuantity > 0
                                      ? false
                                      : _vm.quantities === 0,
                                  placeholder: "请输入入库数量",
                                  precision: 0,
                                },
                                model: {
                                  value: item.inboundQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(item, "inboundQuantity", $$v)
                                  },
                                  expression: "item.inboundQuantity",
                                },
                              })
                            : _c("el-input-number", {
                                staticClass: "num bottom",
                                attrs: {
                                  min: 0,
                                  max:
                                    _vm.equipmentInfo &&
                                    _vm.equipmentInfo.isWhole
                                      ? 1
                                      : Infinity,
                                  step: 1,
                                  "step-strictly": "",
                                  placeholder: "请输入入库数量",
                                  precision: 0,
                                },
                                model: {
                                  value: item.inboundQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(item, "inboundQuantity", $$v)
                                  },
                                  expression: "item.inboundQuantity",
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-container" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认入库")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }