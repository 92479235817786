export const clearAll = () => {
  localStorage.clear()
}

export function setObject(key, obj) {
  localStorage.setItem(key, JSON.stringify(obj))
}

export function getObject(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function removeObject(key) {
  localStorage.removeItem(key)
}

export const clearAllExcludeAccount = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key !== 'accountInfo' && key !== 'isPasswordRemembered') {
      localStorage.removeItem(key)
    }
  }
}
