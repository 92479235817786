<template>
  <div class="home-page-container">
    <div v-if="getMenuList().length > 0" class="menus-container">
      <div v-for="(item, index) in getMenuList()" :key="index" class="menu-container" @click="clickMenu(item)">
        <img class="menu-img" :src="item.imgUrl" alt="">
        <div class="menu-name">{{ item.name }}</div>
      </div>
    </div>
    <div v-else class="empty-menu">
      <img :src="Empty" class="empty-img">
      <span class="empty-info">暂无可使用功能</span>
      <span class="empty-info">请联系管理员配置</span>
    </div>
  </div>
</template>

<script>
import RemoteGuidance from '@/assets/image/mobile/mobile_remote_guidance.png'
import WorkOrderMgr from '@/assets/image/mobile/mobile_work_order_mgr.png'
import WorkOrderCreate from '@/assets/image/mobile/mobile_work_order_create.png'
import StockMgr from '@/assets/image/mobile/mobile_stock_mgr.png'
import Empty from '@/assets/image/mobile/mobile_empty.png'
import { mapGetters } from 'vuex'
import { ADMIN, EXPERT } from '@/views/commonVar'

export default {
  name: 'Index',
  data() {
    return {
      RemoteGuidance, WorkOrderMgr, WorkOrderCreate, StockMgr, Empty,
      menuList: [
        { imgUrl: RemoteGuidance, name: '远程指导', routerName: 'MobileNewRemoteGuidance', menuName: 'newRemoteGuidanceMenu', roles: [EXPERT, ADMIN] },
        { imgUrl: WorkOrderMgr, name: '下发工单', routerName: 'MobilePatrolWorkOrderCreate', menuName: 'taskMgrMenu' },
        { imgUrl: WorkOrderCreate, name: '工单管理', routerName: 'MobilePatrolWorkOrderList', menuName: 'templateMgr' },
        { imgUrl: StockMgr, name: '库存管理', routerName: 'MobileStockMgrList', menuName: 'stockMgr' }
      ]
    }
  },
  computed: {
    ...mapGetters(['userRoutes', 'userInfo'])
  },
  created() {
    this.getMenuList()
    this.$store.dispatch('tagsView/removeActivatedTab', 'activatedTab')
  },
  methods: {
    getMenuList() {
      const result = []
      const featureMenuJson = this.userRoutes.featureMenuJson
      featureMenuJson.forEach(menu => this.findMenuItems(menu, result))
      return result
    },
    findMenuItems(menu, result) {
      const match = this.menuList.find(item => item.menuName === menu.name)
      if (match) {
        if (match.roles) {
          if (match.roles.some(item => this.userInfo.roles.includes(item))) {
            result.push(match)
          }
        } else {
          result.push(match)
        }
      }

      if (menu.children && menu.children.length > 0) {
        menu.children.forEach(child => this.findMenuItems(child, result))
      }
    },
    clickMenu(item) {
      this.$router.push({ name: item.routerName })
    }
  }
}
</script>

<style scoped lang="scss">
.home-page-container{
  .menus-container {
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5%;
    .menu-container {
      flex-basis: 30%;
      margin-bottom: 10px;
      text-align: center;
      .menu-img {
        width: 100px;
      }
      .menu-name{
        font-size: 1.2rem;
      }
    }
  }
  .empty-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    .empty-img{
      width: 30%;
      margin-bottom: 10px;
    }
    .empty-info{
      font-size: 1.2rem;
      color: #bbbbbb;
      margin-bottom: 10px;
    }
  }
}
</style>
