<template>
  <el-card class="row">
    请选择需要配菜单的角色
    <el-select v-model="roleSelectedId" placeholder="请选择需要配菜单的角色" @change="selectedRole()">
      <el-option
        v-for="item in roleOptions"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>

    <!--    <el-button type="warning" style="float: right" icon="el-icon-search" @click="previewMenu">预览菜单</el-button>-->

    <el-card v-loading="menuCardLoading" style="margin-top: 20px">
      <el-timeline>
        <draggable :list="allMenus">
          <el-timeline-item v-for="(menu, index) in allMenus" :key="menu.name" icon="el-icon-arrow-down" :hide-timestamp="true">
            <el-card style="margin-bottom: 10px;">
              <ht-collapse-pane v-if="menu.children.length > 1 || (menu.children[0] && menu.featureName !== menu.children[0].featureName)" :disabled="menu.name === 'newRemoteGuidanceMenu'">
                <template v-slot:title="{activated}">
                  <!--                   远程指导2.0限定角色为管理员，专家，现场：ADMIN,EXPERT,STAFF,非以上角色不可配置远程指导 getNewRemoteGuidanceMenuDisabled相关代码-->
                  <el-tooltip v-if="!activated" content="可拖动调整节点顺序" placement="bottom" effect="light">
                    <div style="height: 30px">
                      <el-image :src="movePng" class="move-icon" />
                      <el-tag type="primary" effect="dark" size="mini">{{ index + 1 }}</el-tag>
                      <span style="color: #999999">模块原名称：{{ menu.featureName }}</span>
                      <!--                      <span v-if="getNewRemoteGuidanceMenuDisabled(menu)" style="color: #f56c6c">-->
                      <!--                        <i style="color: #e6a23c" class="el-icon-warning" />{{ newRemoteGuidanceMenuDisabledInfo }}-->
                      <!--                      </span>-->
                      <span v-if="getNewRemoteGuidanceMenuInfo(menu)" style="color: #f56c6c">
                        <i style="color: #e6a23c" class="el-icon-warning" />{{ newRemoteGuidanceMenuDisabledInfo }}
                      </span>
                    </div>
                  </el-tooltip>
                  <div v-else style="height: 40px;width: 100%">
                    <el-image :src="movePng" class="move-icon" />
                    <el-tag type="primary" effect="dark" size="mini">{{ index + 1 }}</el-tag>
                    <span style="color: #999999">模块原名称：{{ menu.featureName }}</span>
                    <el-input v-model="menu.title" style="width: 35%" />
                    <!--                    <el-input v-model="menu.title" :disabled="getNewRemoteGuidanceMenuDisabled(menu)" style="width: 35%" />-->
                    <!--                    <span v-if="getNewRemoteGuidanceMenuDisabled(menu)" style="color: #f56c6c">-->
                    <!--                      <i style="color: #e6a23c" class="el-icon-warning" />{{ newRemoteGuidanceMenuDisabledInfo }}-->
                    <!--                    </span>-->
                    <!--                    <el-checkbox v-model="menu.activated" :disabled="getNewRemoteGuidanceMenuDisabled(menu)" :indeterminate="menu.isIndeterminate" @change="handleCheckAllChange(menu)" />-->
                    <span v-if="getNewRemoteGuidanceMenuInfo(menu)" style="color: #f56c6c">
                      <i style="color: #e6a23c" class="el-icon-warning" />{{ newRemoteGuidanceMenuDisabledInfo }}
                    </span>
                    <el-checkbox v-model="menu.activated" :indeterminate="menu.isIndeterminate" @change="handleCheckAllChange(menu)" />
                  </div>
                </template>
                <el-card slot="content" shadow="hover" style="margin-bottom: 1rem">
                  <el-tooltip content="可拖动调整节点顺序" placement="bottom" effect="light">
                    <div class="drag-cursor component-margin" style="width: 100%">
                      <table class="table table-striped">
                        <thead class="thead-dark">
                          <tr class="align-center">
                            <th scope="col">序号</th>
                            <th scope="col">菜单</th>
                            <th scope="col">模块原名称</th>
                            <th scope="col">模块所属</th>
                            <th scope="col">操作</th>
                          </tr>
                        </thead>
                        <draggable tag="tbody" :list="menu.children">
                          <tr v-for="(secondMenu, secondIndex) in menu.children" :key="secondIndex" class="align-center">
                            <td>{{ secondIndex + 1 }}</td>
                            <!--                            <td><el-input v-model="secondMenu.title" :disabled="getNewRemoteGuidanceMenuDisabled(menu)" style="width: 50%" /></td>-->
                            <td><el-input v-model="secondMenu.title" style="width: 50%" /></td>
                            <td>{{ secondMenu.featureName }}</td>
                            <td>
                              <el-tag v-if="secondMenu.isForWeb">WEB</el-tag>
                              <el-tag v-if="secondMenu.isForApp" type="success">APP</el-tag>
                            </td>
                            <td>
                              <el-checkbox
                                v-model="secondMenu.activated"
                                :disabled="menu.name === 'newRemoteGuidanceMenu'"
                                @change="handleCheckedCitiesChange(menu)"
                              />
                            </td>
                          </tr>
                        </draggable>
                      </table>
                      <!--                    {{ element.meta ? element.meta.title : '-' }}-->
                      <!--                    <el-timeline-item v-for="child in element.children" :key="child.path" icon="el-icon-arrow-down" :hide-timestamp="true">-->
                      <!--                      <el-tooltip content="可拖动调整节点顺序" placement="bottom" effect="light">-->
                      <!--                        <div class="drag-cursor component-margin" style="width: 100%">-->
                      <!--                          &lt;!&ndash;                            <svg-icon icon-class="drag" />&ndash;&gt;-->
                      <!--                        </div>-->
                      <!--                      </el-tooltip>-->
                    </div>
                  </el-tooltip>
                </el-card>
              </ht-collapse-pane>
              <div v-else style="height: 40px;width: 100%">
                <el-image :src="movePng" class="move-icon" />
                <el-tag type="primary" effect="dark" size="mini">{{ index + 1 }}</el-tag>
                <span style="color: #999999">模块原名称：{{ menu.featureName }}</span>
                <el-input v-model="menu.title" style="width: 30%" />
                <el-tag v-if="menu.isForWeb">WEB</el-tag>
                <el-tag v-if="menu.isForApp" type="success">APP</el-tag>
                <el-checkbox v-model="menu.activated" style="float: right" :indeterminate="menu.isIndeterminate" @change="handleCheckAllChange(menu)" />
              </div>
            </el-card>
          </el-timeline-item>
        </draggable>
      </el-timeline>

      <div>
        <el-button type="primary" @click="submitMenuConfig">保存修改</el-button>
      </div>
    </el-card>

    <ht-dialog ref="dialog" title="菜单预览" width="60%">
      <div slot="content">
        <div class="app-box">
          <el-row v-if="previewMenus.length > 0" :gutter="24">
            <el-col :span="6">
              <div class="app-menu">
                <span v-for="i in previewMenus" :key="`${i.name}preview`" class="app-menu-item" @click="clickMenu(i)">
                  {{ i.title }}
                </span>
              </div>
            </el-col>
            <el-col :span="18">
              <div class="app-second-menu">
                <span v-for="j in previewSecondMenus" :key="`${j.name}preview`" class="app-second-menu-item">
                  {{ j.title }}
                </span>
              </div>
            </el-col>
          </el-row>
          <div v-else>
            暂无菜单配置
          </div>
        </div>
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import draggable from 'vuedraggable'
import HtCollapsePane from '@/components/HtCollapsePane'
import { mapGetters } from 'vuex'
import { getRoleApi, getRolesApi, updateRoleApi } from '@/api/systemMgr/roleMgr'
import movePng from './move.png'

export default {
  name: 'Simple',
  display: 'Simple',
  order: 0,
  components: { draggable, HtCollapsePane },
  data() {
    return {
      movePng,
      dragging: false,
      roleForm: {
        name: null,
        remoteGuidanceRole: null,
        desc: null
      },
      allMenus: [],
      isIndeterminate: true,
      secondMenuCheckedList: [],
      roleOptions: [],
      roleSelectedId: undefined,
      roleSelectedFeatureMenuJson: [],
      previewMenus: [],
      previewSecondMenus: [],
      menuCardLoading: false,
      newRemoteGuidanceMenuDisabledInfo: '远程指导的“联系人”菜单WEB端仅对专家开放，其他角色配置后无效'
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  created() {
    // this.getAllMenusFun()
    this.getRolesFun()
  },
  methods: {
    getRolesFun() {
      getRolesApi({ readOnly: false }).then((resp) => {
        this.roleOptions = resp.items
        this.roleSelectedId = resp.items[0].id
        this.selectedRole()
      })
    },
    selectedRole() {
      this.menuCardLoading = true
      getRoleApi(this.roleSelectedId).then((resp) => {
        this.menuCardLoading = false
        Object.keys(this.roleForm).forEach(key => (this.roleForm[key] = resp[key]))
        this.allMenus = this.filterMenuEnabled(resp.featureMenuJson)
      })
    },
    filterMenuEnabled(featureMenuJson) {
      const filteredData = []

      for (const item of featureMenuJson) {
        if (item.enabled === true || !('enabled' in item)) {
          filteredData.push(item)
        }

        if (item.children) {
          item.children = this.filterMenuEnabled(item.children)
        }
      }

      return filteredData
    },
    submitMenuConfig() {
      this.roleForm.featureMenuJson = this.allMenus
      updateRoleApi(this.roleSelectedId, this.roleForm).then(() => {
        this.$message({ message: '保存成功，当前修改内容将在该角色用户下一次登录后生效。', center: true, type: 'success' })
      })
    },
    // getAllMenusFun() {
    //   getUserMenuApi().then((resp) => {
    //     resp.featureMenuJson.forEach((menu) => {
    //       menu.activated = false
    //       menu.isIndeterminate = false
    //       menu.children.forEach((secondMenu) => {
    //         secondMenu.activated = false
    //       })
    //     })
    //     this.allMenus = resp.featureMenuJson
    //   })
    // },
    handleCheckAllChange(menu) {
      menu.children.forEach((item) => { item.activated = menu.activated })
      this.handleCheckedCitiesChange(menu)
    },
    handleCheckedCitiesChange(menu) {
      const secondMenuChecked = menu.children.filter(e => e.activated).map(e => e.name)
      const checkedCount = secondMenuChecked.length
      menu.activated = checkedCount > 0
      menu.isIndeterminate = checkedCount > 0 && checkedCount < menu.children.length
    },
    previewMenu() {
      this.$refs.dialog.dialogVisible = true
      this.previewMenus = this.allMenus.filter(e => e.activated && e.isForApp)
      this.previewMenus.forEach(m1 => {
        m1.children = m1.children.filter(e => e.activated && e.isForApp)
      })
    },
    clickMenu(i) {
      this.previewSecondMenus = i.children
    },
    getNewRemoteGuidanceMenuDisabled(menu) {
      if (menu.name === 'newRemoteGuidanceMenu') {
        const currentRoles = this.roleOptions.filter(item => this.roleSelectedId === item.id)
        const currentRole = currentRoles[0] || []
        return currentRole && currentRole.tag !== 'ADMIN' && currentRole.tag !== 'EXPERT' && currentRole.tag !== 'STAFF'
      } else {
        return false
      }
    },
    getNewRemoteGuidanceMenuInfo(menu) {
      return menu.name === 'newRemoteGuidanceMenu'
    }
  }
}
</script>
<style scoped>
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table .thead-dark th {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #dee2e6;
}
.table td, .table th {
  padding: 0.3rem;
  vertical-align: center;
  /*border-top: 1px solid #dee2e6;*/
}
table, th, td {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}
.align-center {
  text-align: center;
}
.move-icon {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 10px;
}
.app-box {
  width: 95%;
  height: 400px;
  border: 1px solid #dee2e2;
  margin: 0 auto;
}
.app-menu {
  width: 100%;
  height: 398px;
  border-right: 1px solid #dee2e2;
}
.app-menu-item {
  display: block;
  height: 98px;
  line-height: 98px;
  border-bottom: 1px solid #dee2e2;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
}
.app-second-menu {
  width: 100%;
  margin-top: 25%;
}
.app-second-menu-item {
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
