exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tags-view-wrapper .tags-view-item .el-icon-close{width:16px;height:16px;line-height:16px;font-size:16px;font-weight:bold;vertical-align:-1px;border-radius:50%;text-align:center;-webkit-transition:all .3s cubic-bezier(0.645, 0.045, 0.355, 1);transition:all .3s cubic-bezier(0.645, 0.045, 0.355, 1);-webkit-transform-origin:100% 50%;transform-origin:100% 50%}.tags-view-wrapper .tags-view-item .el-icon-close:before{-webkit-transform:scale(0.6);transform:scale(0.6);display:inline-block;color:#fff}.tags-view-wrapper .tags-view-item .el-icon-close:hover{background-color:#fff;color:#f56c6c;font-weight:bold}.tags-view-wrapper .tags-view-item .el-icon-close:hover:before{color:#f56c6c}", ""]);

// exports
exports.locals = {
	"menuText": "#666",
	"menuActiveText": "#2e5aff",
	"subMenuActiveText": "#2e5aff",
	"menuBg": "#fff",
	"menuHover": "#f1f2f4",
	"menuActiveBg": "#2e5aff",
	"subMenuBg": "#f1f2f4",
	"subMenuHover": "#dedede",
	"sideBarWidth": "210px",
	"primaryColor": "#2e5aff",
	"primaryColorMiddle": "#8099ff",
	"primaryColorLight": "#e0eaf9",
	"mobilePrimaryColor": "#2e5aff",
	"mobilePrimaryColorMiddle": "#6485fd",
	"mobilePrimaryColorLight": "#e0eaf9",
	"sidebarLogoBg": "#2e5aff",
	"sidebarLogoColor": "#fff",
	"hoverSideBarWidth": "190px"
};