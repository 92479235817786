<template>
  <div
    v-if="showBox"
    ref="userListRef"
    class="on-user-list-container"
    :class="{'fullscreen-on-user-list-container': fullscreen,'normal-on-user-list-container': !fullscreen, 'is-mobile-fullscreen-on-user-list-container': fullscreen && isMobile}"
  >
    <div ref="topContainerRef" class="top-container">
      <span class="title">在线用户（{{ count }}）</span>
      <ht-button type="primary" icon="el-icon-link" @click="copyMeetingUrl">复制会议链接</ht-button>
      <span class="info">{{ isCopiedUrl ? '已复制' : '' }}</span>
      <img class="close-icon" :src="Close" alt="" @click="toggleBoxStatus">
    </div>
    <div class="bottom-container">
      <contact-list-small
        ref="contactListRef"
        :user-list="userList"
        @invite="inviteUser"
      />
    </div>
  </div>
</template>

<script>
import Close from '@/assets/image/newRemoteGuidance/close_bold.png'
import { getContactsApi } from '@/api/newRemoteGuidance/newRemoteGuidance'
import ContactListSmall from '../components/contactListSmall'
import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'
import { boxMoveEvent, drag, mouseDown } from '../js/boxDrag'

export default {
  name: 'OnlineUserLIst',
  components: {
    ContactListSmall
  },
  props: {
    fullscreen: { type: Boolean, default: false },
    userIds: { type: Array, default: () => [] },
    inviteList: { type: Array, default: () => [] },
    userInfo: { type: Object, default: null },
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      Close,
      showBox: false,
      count: 0,
      onlineUserList: [],
      userList: [],
      userListBox: null,
      topContainer: null,
      boxPosition: { left: 0, right: 0 },
      topPosition: { startX: 0, startY: 0 },
      isCopiedUrl: false
    }
  },
  watch: {
    inviteList: {
      handler: function(newList, oldList) {
        this.$nextTick(() => {
          const newArr = newList ? newList.map(i => i.imUserId) : []
          const oldArr = oldList ? oldList.map(i => i.imUserId) : []
          const isSame = newArr.length === oldArr.length && newArr.every(item => oldArr.includes(item))
          if (!isSame) {
            this.refreshUserList()
          }
        })
      },
      deep: true,
      immediate: true
    },
    userIds: {
      handler: function() {
        this.$nextTick(() => {
          this.refreshUserList()
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    addEventListener() {
      document.addEventListener(EventsEnum.contactsRefreshEvent, this.refreshUserList)
      document.addEventListener(EventsEnum.meetingRefreshEvent, this.refreshUserList)
    },
    removeEventListener() {
      document.removeEventListener(EventsEnum.contactsRefreshEvent, this.refreshUserList)
      document.removeEventListener(EventsEnum.meetingRefreshEvent, this.refreshUserList)
    },
    toggleBoxStatus() {
      this.showBox = !this.showBox
      if (this.showBox) {
        this.refreshUserList()
        this.addEventListener()
        this.$nextTick(() => {
          // 窗口移动
          this.userListBox = this.$refs.userListRef
          this.topContainer = this.$refs.topContainerRef
          boxMoveEvent(this.userListBox, this.topContainer, this.onMouseDown, this.onDrag)
        })
      } else {
        this.removeEventListener()
        this.userListBox = this.$refs.userListRef
        this.topContainer = this.$refs.topContainerRef
        this.userListBox.removeEventListener('mousemove', this.onDrag)
        this.topContainer.removeEventListener('mousedown', this.onMouseDown)
        this.$emit('close-box')
      }
    },
    onMouseDown(e) {
      mouseDown(e, this.topPosition, this.userListBox, this.onDrag)
    },
    onDrag(e) {
      drag(e, this.topPosition, this.boxPosition, this.userListBox)
    },
    async refreshUserList() {
      await this.getOnlineUser()
      this.getUserList()
    },
    getOnlineUser() {
      return new Promise(resolve => {
        // const listQuery = {
        //   roles: 'EXPERT,STAFF,ADMIN',
        //   offset: 0,
        //   count: 1000
        // }
        const listQuery = {
          offset: 0,
          count: 1000
        }
        getContactsApi(listQuery).then(resp => {
          this.onlineUserList = resp
          this.count = resp.length
          resolve()
        })
      })
    },
    getUserList() {
      this.userList = []
      this.onlineUserList.forEach(onLineItem => {
        const index = this.inviteList.findIndex(inviteItem => inviteItem.imUserId === onLineItem.imUserId)
        onLineItem.isCalling = index !== -1
        this.userList.push(onLineItem)
      })
    },
    inviteUser(userInfo) {
      this.userList.forEach((item, index) => {
        if (item.imUserId === userInfo.imUserId) {
          item.isCalling = true
          this.$set(this.userList, index, item)
        }
      })
      this.$emit('invite', userInfo)
    },
    copyMeetingUrl() {
      this.$emit('copy-meeting-url')
      this.isCopiedUrl = true
      setTimeout(() => {
        this.isCopiedUrl = false
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">
.on-user-list-container{
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 380px;
  height: 500px;
  z-index: 1000;
  .top-container{
    height: 48px;
    padding: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F3F3;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 10px 10px 0 0;
    cursor: move;
    .close-icon{
      right: 10px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
    .title{
      //flex: 1;
      height: 28px;
      line-height: 28px;
      color: #000000;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
    .el-button{
      margin-right: 10px;
      margin-left: 10px;
    }
    .info{
      flex: 1;
      height: 28px;
      line-height: 28px;
      color: #f56c6c;
      font-weight: bold;
    }
  }
  .bottom-container{
    flex: 1;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 0 0 10px 10px;
  }
}

.is-mobile-fullscreen-on-user-list-container{
  height: 400px !important;
}
</style>
