import Layout from '@/layout'

const ConstructionEquipmentMgrRouter = {
  path: '/constructionEquipmentMgr',
  component: Layout,
  redirect: 'noRedirect',
  name: 'constructionEquipmentMgrMenu',
  meta: { title: '设备管理', icon: 'equipment_mgr' },
  children: [
    // 设备清单
    {
      path: '/constructionEquipmentMgr/equipmentMgr/list',
      component: () => import('@/views/constructionEquipmentMgr/equipmentMgr/list'),
      name: 'equipmentMgr',
      meta: { title: '设备清单' }
    },
    {
      path: '/constructionEquipmentMgr/equipmentMgr/edit/:equipmentId/:isEdit',
      component: () => import('@/views/constructionEquipmentMgr/equipmentMgr/view'),
      name: 'equipmentMgrEdit',
      meta: { title: '编辑设备', activeMenu: '/constructionEquipmentMgr/equipmentMgr/list' },
      hidden: true,
      props: true
    },
    {
      path: '/constructionEquipmentMgr/equipmentMgr/view/:equipmentId/:isEdit',
      component: () => import('@/views/constructionEquipmentMgr/equipmentMgr/view'),
      name: 'equipmentMgrView',
      meta: { title: '设备详情', activeMenu: '/constructionEquipmentMgr/equipmentMgr/list' },
      hidden: true,
      props: true
    },
    {
      path: '/constructionEquipmentMgr/stockMgr/list',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/list'),
      name: 'stockMgr',
      meta: { title: '库存管理' }
    },
    {
      path: '/constructionEquipmentMgr/stockRecordMgr/list',
      component: () => import('@/views/constructionEquipmentMgr/stockRecordMgr/list'),
      name: 'stockReportMgr',
      meta: { title: '库存记录' }
    },
    {
      path: '/constructionEquipmentMgr/warehouseMgr/list',
      component: () => import('@/views/constructionEquipmentMgr/warehouseMgr/list'),
      name: 'warehouseMgr',
      meta: { title: '仓库管理' }
    }
    // 维修经验库
    // {
    //   path: '/constructionEquipmentMgr/repairExperienceLibraryMgr/list',
    //   component: () => import('@/views/constructionEquipmentMgr/repairExperienceLibraryMgr/list'),
    //   name: 'repairExperienceLibraryMgr',
    //   meta: { title: '维修经验库' }
    // }
  ]
}

export default ConstructionEquipmentMgrRouter
