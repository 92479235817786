<template>
  <div class="container">
    <a-scene
      :mindar-image="{imageTargetSrc: mindSrc, autoStart: true}"
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
    >
      <a-camera position="0 0 0" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;" raycaster="near: 10; far: 10000; objects: .clickable" />

      <a-entity mindar-image-target="targetIndex: 0">
        <a-plane color="white" position="0 0.4 0" height="0.552" width="1" rotation="0 0 0" />
        <a-text value="123" color="black" align="center" width="2" position="0 0.4 0" />
        <!--        <a-text value="正面" font="static/msdf/1-msdf.json" color="#33C3F0" negate="false" align="center" width="2" position="0 0.4 0" />-->
      </a-entity>

      <a-entity mindar-image-target="targetIndex: 1">
        <a-plane color="white" position="0 0.4 0" height="0.552" width="1" rotation="0 0 0" />
        <a-text value="物体正面" :font="frontMsdf" negate="false" color="black" align="center" width="2" position="0 0.4 0" />
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
export default {
  name: 'TestArText',
  data() {
    return {
      mindSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/meigui.mind',
      dynamicText: '浙江明康工程咨询有限公司17357585312张情波',
      frontMsdf: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/front-msdf.json',
      versoMsdf: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/verso-msdf.json'
    }
  }
}
</script>

<style scoped>
body {
  font-family: 'Microsoft YaHei', sans-serif;
}
</style>
