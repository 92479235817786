<template>
  <div
    v-if="dialogVisible"
    class="freeze-guidance-container"
    :class="{'fullscreen-mobile-freeze-guidance-container': fullscreen && isMobile}"
    :style="{
      top: enLargeVideoRect.top + 'px',
      left: fullscreen ? enLargeVideoRect.left + 'px' : '20px',
      width: getFreezeGuidanceImgWidth + 10 + 'px'
    }"
  >
    <div class="title-container">
      <div class="title-text-container">
        <img class="img" :src="Guidance" alt="">
        <span class="label">标注指导</span>
        <span class="value">：{{ frozenGuidanceInfo && frozenGuidanceInfo.frozenFullName ? frozenGuidanceInfo.frozenFullName : '' }}</span>
        <span v-if="!isSinoma" class="value">{{ frozenGuidanceInfo && frozenGuidanceInfo.frozenUserName && !stringLongOverTen(frozenGuidanceInfo.frozenUserName) ? `（${frozenGuidanceInfo.frozenUserName}）` : '' }}</span>

      </div>
      <div class="title-button-container">
        <img v-if="isExpect" class="close item" :src="Close" alt="" @click="closeDialog">
      </div>
    </div>
    <div v-loading="isLoading" class="content-container">
      <div class="paint-container">
        <img
          :id="id"
          ref="freezeImgRef"
          :src="freezeImgUrl"
          alt=""
          class="freeze-img"
          :style="{width: getFreezeGuidanceImgWidth + 'px'}"
          crossorigin
        >
        <canvas
          id="freezeCanvas"
          class="freeze-canvas"
          :width="getFreezeGuidanceImgWidth"
          :height="getFreezeGuidanceImgHeight"
        />
      </div>
      <!--      <div v-if="isExpect && !isMobile" class="operating-container">-->
      <div v-if="isExpect" class="operating-container">
        <operating-button class="item" name="激光笔" :icon="LaserPenBlue" :is-show-dot="isLaserMoving" @click="laserButtonClick" />
        <operating-button class="item" :width="70" name="实时画笔" :icon="Paintbrush" :is-show-dot="isPaintDrawing" @click="paintButtonClick" />
        <operating-button class="item" :width="70" name="清除轨迹" :icon="Clear" @click="clearButtonClick" />
      </div>
    </div>
  </div>
</template>

<script>
import Close from '@/assets/image/newRemoteGuidance/close.png'
import EnterFul from '@/assets/image/newRemoteGuidance/enter_full.png'
import ExitFul from '@/assets/image/newRemoteGuidance/exit_full.png'
import Minimize from '@/assets/image/newRemoteGuidance/minimize.png'
import OperatingButton from './operatingButton'
import LaserPenBlue from '@/assets/image/newRemoteGuidance/laser_pen_blue.png'
import Paintbrush from '@/assets/image/newRemoteGuidance/paintbrush.png'
import Clear from '@/assets/image/newRemoteGuidance/clear.png'
import LaserPenRed from '@/assets/image/newRemoteGuidance/laser_pen_red.jpg'
import Guidance from '@/assets/image/newRemoteGuidance/guidance.png'
import whiteBoard from '@/views/newRemoteGuidance/js/whiteBoard'
import { putObject } from '@/utils/cos'
import { meetingVideoProperty } from '@/views/newRemoteGuidance/var/businessVar'
import store from '@/store'
import { stringLongOverTen } from '@/utils'

export default {
  name: 'FreezeGuidance',
  components: { OperatingButton },
  mixins: [whiteBoard],
  props: {
    fullscreen: { type: Boolean, default: false },
    imUserId: { type: String, default: undefined },
    videoProperty: { type: Object, default: () => {} },
    roomId: { type: String, default: undefined },
    isRemoteLaser: { type: Boolean, default: false },
    isRemotePaint: { type: Boolean, default: false },
    remoteLaserInfo: { type: Object, default: () => {} },
    remotePaintInfo: { type: Object, default: () => {} },
    frozenGuidanceInfo: { type: Object, default: () => {} },
    enLargeVideoRect: { type: Object, default: () => {} },
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      Close, EnterFul, ExitFul, Minimize,
      LaserPenBlue, Paintbrush, Clear,
      LaserPenRed, Guidance,
      id: 'freezeImg',
      meetingVideoProperty,
      dialogVisible: false,
      canvasName: 'freezeCanvas',
      isLaserMoving: false,
      isPaintDrawing: false,
      canvas: null,
      canvasCtx: null,
      paintPosition: { lastX: undefined, lastY: undefined, currentX: undefined, currentY: undefined },
      paintTracings: [],
      paintAllTracings: [],
      paintSendTimer: null,
      laserPosition: { currentX: undefined, currentY: undefined },
      laserTracings: [],
      laserPenImg: undefined,
      laserSendTimer: null,
      video: null,
      videoStyle: null,
      freezeImg: null,
      freezeImgUrl: null,
      freezeResultImgUrl: null,
      isLoading: false,
      isExpect: false,
      remoteLaserTracings: [],
      remotePaintTracings: [],
      historyPaintAllTracings: [],
      historyRemotePaintAllTracings: [],
      isSinoma: undefined
    }
  },
  computed: {
    getImgHeight() {
      const img = document.getElementById('freezeImg')
      return img.offsetHeight || 500
    },
    // 图片裁剪
    getImgClipPath() {
      if (this.videoProperty && this.videoProperty.height && this.videoProperty.width) {
        // 0：宽被裁剪，1：正常，2： 高被裁剪
        if (this.getRemoteCutDirection() === 2) {
          const height = meetingVideoProperty.normalLargeVideoWidth * (this.videoProperty.height / this.videoProperty.width)
          const cutHeight = (height - this.getRemoteVideoHeight) / 2
          return `inset(round(50% - ${cutHeight}px) 0 round(50% - ${cutHeight}px) 0)`
        } else if (this.getRemoteCutDirection() === 0) {
          const width = this.getRemoteCutDirection() / (this.videoProperty.height / this.videoProperty.width)
          const cutWidth = (width - meetingVideoProperty.normalLargeVideoWidth) / 2
          return `inset(round(50% - ${cutWidth}px) 0 round(50% - ${cutWidth}px) 0)`
        } else {
          return 'none'
        }
      } else {
        return 'none'
      }
    },
    getFreezeGuidanceImgWidth() {
      if (this.fullscreen && this.isMobile) {
        return this.enLargeVideoRect.width - 10
      } else {
        return this.enLargeVideoRect.width / 2
      }
    },
    getFreezeGuidanceImgHeight() {
      if (this.fullscreen && this.isMobile) {
        return (this.enLargeVideoRect.width - 10) / (this.enLargeVideoRect.width / this.enLargeVideoRect.height)
      } else {
        return this.enLargeVideoRect.height / 2
      }
    }
  },
  watch: {
    dialogVisible: {
      handler: function() {
        if (this.dialogVisible) {
          this.$nextTick(() => {
            this.initCanvas()
          })
        }
      }
    },
    remoteLaserInfo: {
      handler: function() {
        this.remoteLaser(this.remoteLaserInfo)
      },
      immediate: true,
      deep: true
    },
    remotePaintInfo: {
      handler: function() {
        this.remotePaint(this.remotePaintInfo)
      },
      immediate: true,
      deep: true
    },
    isPaintDrawing: {
      handler: function() {
        if (!this.isPaintDrawing) {
          this.paintTracings = []
          if (this.isMobile) {
            this.canvas.removeEventListener('touchstart', this.mobilePaintDown, { passive: true })
            this.canvas.removeEventListener('touchend', this.mobilePaintUp, { passive: true })
            this.canvas.removeEventListener('touchmove', this.mobilePaintMove, { passive: true })
          } else {
            this.canvas.removeEventListener('mousedown', this.paintDown)
            this.canvas.removeEventListener('mouseup', this.paintUp)
            this.canvas.removeEventListener('mousemove', this.paintMove)
          }
          this.clearCanvas()
          clearInterval(this.paintSendTimer)
        }
      }
    },
    isLaserMoving: {
      handler: function() {
        if (!this.isLaserMoving) {
          this.laserTracings = []
          if (this.isMobile) {
            this.canvas.removeEventListener('touchmove', this.mobileLaserMove, { passive: true })
          } else {
            this.canvas.removeEventListener('mousemove', this.laserMove)
          }
          this.clearCanvas()
          clearInterval(this.laserSendTimer)
        }
      }
    },
    isRemoteLaser: {
      handler: function() {
        this.clearCanvas()
      }
    },
    isRemotePaint: {
      handler: function() {
        if (!this.isRemotePaint) {
          this.clearCanvas()
        }
      }
    }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
  },
  methods: {
    stringLongOverTen,
    showDialog(isVideo, value, isExpect) {
      this.dialogVisible = !this.dialogVisible
      this.isLaserMoving = false
      this.isPaintDrawing = false
      if (this.dialogVisible) {
        if (isVideo) {
          this.getFreezeImg(value)
        } else {
          this.freezeImgUrl = value
        }
        this.isExpect = isExpect
      } else {
        this.freezeImg = null
        this.freezeImgUrl = null
        this.clearCanvas()
      }
    },
    async closeDialog() {
      if (this.isExpect) {
        await this.getFreezeResultImg()
        this.dialogVisible = false
        this.$emit('freeze-guidance-finish', false, this.imUserId, true, this.freezeResultImgUrl)
        this.freezeImg = null
        this.freezeImgUrl = null
        this.clearCanvas()
        this.isLaserMoving = false
        this.isPaintDrawing = false
      }
    },
    getFreezeImg(video) {
      this.video = video
      // 图片大小显示为视频的1/4
      // this.videoStyle = {
      //   width: this.enLargeVideoRect.width / 2,
      //   height: this.enLargeVideoRect.height / 2
      // }

      this.videoStyle = {
        width: this.enLargeVideoRect.width / 2,
        height: this.enLargeVideoRect.height / 2
      }
      this.isLoading = true
      // 绘制标注图片
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = this.videoStyle.width
      canvas.height = this.videoStyle.height
      ctx.drawImage(video, 0, 0, this.videoStyle.width, this.videoStyle.height)
      this.freezeImg = canvas.toDataURL('image/png')
      // 上传标注图片
      this.onUploadImg(this.dataURLtoBlob(this.freezeImg), 'freeze').then((url) => {
        const img = document.getElementById('freezeImg')
        img.style.height = this.videoStyle.height
        const drawCanvas = document.getElementById(this.canvasName)
        drawCanvas.style.height = this.videoStyle.height
        this.$emit('freeze-guidance-start', true, this.imUserId, url, this.freezeImgUrl)
      }).catch(() => {
        this.$emit('freeze-guidance-start-fail')
      })
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    },
    // 没有放大的
    // getFreezeResultImg() {
    //   return new Promise((resolve, reject) => {
    //     // 原始画布
    //     const originalCanvas = document.getElementById(this.canvasName)
    //     const originalCtx = originalCanvas.getContext('2d')
    //
    //     const finalCanvas = document.createElement('canvas')
    //     const newCtx = finalCanvas.getContext('2d')
    //     finalCanvas.width = originalCanvas.offsetWidth
    //     finalCanvas.height = originalCanvas.offsetHeight
    //     // 图片涉及到跨域需要 img.crossOrigin = 'Anonymous'
    //     const img = new Image()
    //     img.crossOrigin = 'Anonymous'
    //     img.src = this.freezeImgUrl
    //     img.onload = () => {
    //       // 计算缩放比例
    //       const scale = Math.min(finalCanvas.width / img.width, finalCanvas.height / img.height)
    //       // 在中间位置绘制,并缩放
    //       newCtx.drawImage(img,
    //         (finalCanvas.width - img.width * scale) / 2,
    //         (finalCanvas.height - img.height * scale) / 2,
    //         img.width * scale,
    //         img.height * scale
    //       )
    //       newCtx.drawImage(originalCtx.canvas, 0, 0)
    //       this.onUploadImg(this.dataURLtoBlob(finalCanvas.toDataURL('image/png')), 'freeze_result').then((url) => {
    //         this.freezeResultImgUrl = url
    //         resolve(url)
    //       }).catch(() => {
    //         reject()
    //       })
    //     }
    //   })
    // },
    // 放大了图片的
    getFreezeResultImg() {
      return new Promise((resolve, reject) => {
        // 原始画布
        const originalCanvas = document.getElementById(this.canvasName)
        const originalCtx = originalCanvas.getContext('2d')

        // 创建一个新的画布来保存放大后的originalCanvas内容
        const scaledCanvas = document.createElement('canvas')
        const scaledCtx = scaledCanvas.getContext('2d')
        scaledCanvas.width = originalCanvas.width * 2
        scaledCanvas.height = originalCanvas.height * 2

        // 将originalCanvas的内容绘制到放大后的scaledCanvas上
        scaledCtx.drawImage(originalCtx.canvas, 0, 0, scaledCanvas.width, scaledCanvas.height)

        // 创建最终画布和上下文
        const finalCanvas = document.createElement('canvas')
        const finalCtx = finalCanvas.getContext('2d')
        finalCanvas.width = originalCanvas.width * 2
        finalCanvas.height = originalCanvas.height * 2

        // 将放大后的scaledCanvas的内容绘制到最终画布上
        finalCtx.drawImage(scaledCanvas, 0, 0)

        // 加载图片
        const img = new Image()
        img.crossOrigin = 'Anonymous'
        img.src = this.freezeImgUrl
        img.onload = () => {
          // 计算缩放比例
          const scale = Math.min(finalCanvas.width / img.width, finalCanvas.height / img.height)

          // 在中间位置绘制,并缩放, 在最终画布上绘制图片
          finalCtx.drawImage(img,
            (finalCanvas.width - img.width * scale) / 2,
            (finalCanvas.height - img.height * scale) / 2,
            img.width * scale,
            img.height * scale
          )

          finalCtx.drawImage(scaledCtx.canvas, 0, 0)

          // 将最终画布的数据URL转换为Blob并上传
          this.onUploadImg(this.dataURLtoBlob(finalCanvas.toDataURL('image/png')), 'freeze_result').then((url) => {
            this.freezeResultImgUrl = url
            resolve(url)
          }).catch(() => {
            reject()
          })
        }
        img.onerror = () => {
          reject(new Error('Image load failed'))
        }
      })
    },
    dataURLtoBlob(dataURL) {
      const bytes = atob(dataURL.split(',')[1])
      const ab = new ArrayBuffer(bytes.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/png' })
    },
    onUploadImg(freezeImgBlob, name) {
      return new Promise((resolve, reject) => {
        const timestamp = Date.now()
        putObject(`new_remote_guidance/${this.roomId}/${timestamp}_${name}.png`, freezeImgBlob, (error, data) => {
          if (!error) {
            this.isLoading = false
            this.freezeImgUrl = `https://${data.Location}`
            resolve(this.freezeImgUrl)
          } else {
            this.isLoading = false
            this.$message({ message: '标注指导启动失败', center: true, type: 'error' })
            reject()
          }
        })
      })
    },
    minimizedDialog() {},
    exitFullDialog() {
      this.fullscreen = false
    },
    enterFulDialog() {
      this.fullscreen = true
    },
    initCanvas() {
      this.canvas = document.getElementById(this.canvasName)
      this.canvasCtx = this.canvas.getContext('2d')
      this.canvasCtx.lineWidth = 5
      this.canvasCtx.strokeStyle = 'red'
      this.freezeImg = document.getElementById('freezeImg')
      const laserPen = new Image()
      laserPen.src = this.LaserPenRed
      laserPen.onload = () => {
        this.laserPenImg = laserPen
      }
    },
    laserButtonClick() {
      this.isPaintDrawing = false
      this.isLaserMoving = !this.isLaserMoving
      this.$emit('use-laser', this.isLaserMoving, this.imUserId)
      this.clearCanvas()
      this.laserTracings = []
      if (this.isLaserMoving) {
        if (this.isMobile) {
          this.canvas.addEventListener('touchmove', this.mobileLaserMove, { passive: false })
        } else {
          this.canvas.addEventListener('mousemove', this.laserMove)
        }
        this.laserSendTimer = setInterval(() => {
          if (this.laserTracings.length > 0) {
            this.getSendLaserTracings()
          }
        }, 250)
      } else {
        if (this.isMobile) {
          this.canvas.removeEventListener('touchmove', this.mobileLaserMove, { passive: true })
        } else {
          this.canvas.removeEventListener('mousemove', this.laserMove)
        }
        clearInterval(this.laserSendTimer)
      }
    },
    paintButtonClick() {
      this.isLaserMoving = false
      this.isPaintDrawing = !this.isPaintDrawing
      this.$emit('use-paint', this.isPaintDrawing, this.imUserId)
      if (this.isPaintDrawing) {
        if (this.isMobile) {
          this.canvas.addEventListener('touchstart', this.mobilePaintDown, { passive: false })
          this.canvas.addEventListener('touchend', this.mobilePaintUp, { passive: false })
        } else {
          this.canvas.addEventListener('mousedown', this.paintDown)
          this.canvas.addEventListener('mouseup', this.paintUp)
        }
      } else {
        if (this.isMobile) {
          this.canvas.removeEventListener('touchstart', this.mobilePaintDown, { passive: true })
          this.canvas.removeEventListener('touchend', this.mobilePaintUp, { passive: true })
          this.canvas.removeEventListener('touchmove', this.mobilePaintMove, { passive: true })
        } else {
          this.canvas.removeEventListener('mousedown', this.paintDown)
          this.canvas.removeEventListener('mouseup', this.paintUp)
          this.canvas.removeEventListener('mousemove', this.paintMove)
        }
        this.clearCanvas()
        clearInterval(this.paintSendTimer)
      }
    },
    clearButtonClick() {
      this.clearCanvas()
      this.sendPaintMsg(undefined, false)
      this.historyPaintAllTracings = []
      this.historyRemotePaintAllTracings = []
    },
    getSenderScreenSize() {
      // return { x: this.enLargeVideoRect.width / 2, y: this.enLargeVideoRect.height / 2 }
      return { x: this.getFreezeGuidanceImgWidth, y: this.getFreezeGuidanceImgHeight }
    },
    // 获取发送端和接收端的img比例
    getRemoteAndLocalScale(senderScreenSize) {
      if (this.fullscreen && this.isMobile) {
        const imgSize = { x: this.enLargeVideoRect.width - 10, y: (this.enLargeVideoRect.width - 10) / (this.enLargeVideoRect.width / this.enLargeVideoRect.height) }
        const xScale = imgSize.x / senderScreenSize.x
        const yScale = imgSize.y / senderScreenSize.y
        return { x: xScale, y: yScale }
      } else {
        const imgSize = { x: this.enLargeVideoRect.width / 2, y: this.enLargeVideoRect.height / 2 }
        const xScale = imgSize.x / senderScreenSize.x
        const yScale = imgSize.y / senderScreenSize.y
        return { x: xScale, y: yScale }
      }
    },
    handleResize() {
      this.repaintCanvas()
    },
    // todo：标注指导画笔恢复
    repaintCanvas() {
      this.$nextTick(() => {
        if (this.canvasCtx) {
          this.canvasCtx.lineWidth = 5
          this.canvasCtx.strokeStyle = 'red'
          if (this.historyPaintAllTracings.length > 0) {
            this.historyPaintAllTracings.forEach(item => {
              const scale = this.getRemoteAndLocalScale(item.style)
              item.tracings.forEach(trace => {
                this.paintDrawing(trace.x1 * scale.x, trace.y1 * scale.y, trace.x2 * scale.x, trace.y2 * scale.y)
              })
            })
          }
          if (this.historyRemotePaintAllTracings.length > 0) {
            this.historyRemotePaintAllTracings.forEach(item => {
              // const scale = this.getImgResizeScale(item.style)
              const scale = this.getRemoteAndLocalScale(item.style)
              item.tracings.forEach(trace => {
                this.paintDrawing(trace.x1 * scale.x, trace.y1 * scale.y, trace.x2 * scale.x, trace.y2 * scale.y)
              })
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.freeze-guidance-container {
  border-radius: 10px;
  background: #ffffff;
  border: 5px solid #ffffff;
  position: absolute;
  .title-container{
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background: #d4e5fe;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    .title-text-container{
      display: flex;
      align-items: center;
      font-size: 16px;
      .img{
        width: 20px;
        margin-right: 10px;
      }
      .label{
        color: #333333;
      }
      .value{
        font-weight: bold;
        color: #2E5AFF;
      }
    }
    .title-button-container{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .item{
        width: 20px;
        height: 20px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .content-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .paint-container{
      position: relative;
      //border: 1px solid #2E5AFF;
      display: flex;
      justify-content: center;
      .freeze-img{
        //width: 848px;
        //min-height: 400px;
        min-height: 200px;
      }
      .freeze-canvas{
        position: absolute;
        top: 0;
        left: 0;
        //width: 848px;
        //min-height: 400px;
        min-height: 200px;
      }
    }
    .operating-container{
      display: flex;
      align-items: center;
      justify-content: center;
      //margin-top: 10px;
      .item{
        cursor: pointer;
      }
    }
  }
}
.fullscreen-mobile-freeze-guidance-container{
  min-height: 200px;
  border-width: 10px 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);

  //.paint-container{
  //  margin-bottom: 5px;
  //}
  .freeze-img{
    min-height: auto !important;
  }

}
</style>
