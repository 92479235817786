import Layout from '@/layout'

const TaskMgrRouter = {
  path: '/taskMgr',
  component: Layout,
  redirect: 'noRedirect',
  name: 'taskMgrMenu',
  meta: { title: '工单管理', icon: 'work_order' },
  children: [
    // 工作流程管理
    {
      path: '/taskMgr/templateMgr/list',
      component: () => import('@/views/taskMgr/newTemplateMgr/index'),
      name: 'templateMgr',
      meta: { title: '工作流程管理', activeMenu: '/taskMgr/templateMgr/list' }
    },
    {
      path: '/taskMgr/templateMgr/create',
      component: () => import('@/views/taskMgr/newTemplateMgr/template'),
      hidden: true,
      props: true,
      name: 'templateMgrCreate',
      meta: { title: '新建工作流程', activeMenu: '/taskMgr/templateMgr/list' }
    },
    {
      path: '/taskMgr/templateMgr/edit/:templateId',
      component: () => import('@/views/taskMgr/newTemplateMgr/template'),
      hidden: true,
      props: true,
      name: 'templateMgrEdit',
      meta: { title: '编辑工作流程', activeMenu: '/taskMgr/templateMgr/list' }
    },
    // 巡检工单管理
    {
      path: '/taskMgr/patrolWorkOrderMgr/list',
      component: () => import('@/views/taskMgr/patrolWorkOrderMgr/index'),
      name: 'patrolWorkOrderMgr',
      meta: { title: '巡检工单管理', activeMenu: '/taskMgr/patrolWorkOrderMgr/list' }
    },
    {
      path: '/taskMgr/patrolWorkOrderMgr/view/:orderId',
      component: () => import('@/views/taskMgr/patrolWorkOrderMgr/view'),
      hidden: true,
      props: true,
      name: 'patrolWorkOrderMgrView',
      meta: { title: '巡检工单详情', activeMenu: '/taskMgr/patrolWorkOrderMgr/list' }
    },
    // 维修工单管理
    // {
    //   path: '/taskMgr/repairWorkOrderMgr/list',
    //   component: () => import('@/views/taskMgr/repairWorkOrderMgr/index'),
    //   name: 'repairWorkOrderMgr',
    //   meta: { title: '维修工单管理', activeMenu: '/taskMgr/repairWorkOrderMgr/list' }
    // },
    // {
    //   path: '/taskMgr/repairWorkOrderMgr/view/:orderId',
    //   component: () => import('@/views/taskMgr/repairWorkOrderMgr/view'),
    //   hidden: true,
    //   props: true,
    //   name: 'repairWorkOrderMgrView',
    //   meta: { title: '维修工单详情', activeMenu: '/taskMgr/repairWorkOrderMgr/list' }
    // },
    // 我的工单
    {
      path: '/taskMgr/myWorkOrderMgr/list',
      component: () => import('@/views/taskMgr/myWorkOrderMgr/index'),
      name: 'myWorkOrderMgr',
      meta: { title: '我的工单', activeMenu: '/taskMgr/myWorkOrderMgr/list' }
    },
    {
      path: '/taskMgr/myWorkOrderMgr/patrolView/:orderId/:isMy',
      component: () => import('@/views/taskMgr/patrolWorkOrderMgr/view'),
      hidden: true,
      props: true,
      name: 'myPatrolWorkOrderMgrView',
      meta: { title: '工单详情', activeMenu: '/taskMgr/myWorkOrderMgr/list' }
    },
    {
      path: '/taskMgr/myWorkOrderMgr/repairView/:orderId/:isMy',
      component: () => import('@/views/taskMgr/repairWorkOrderMgr/view'),
      hidden: true,
      props: true,
      name: 'myRepairWorkOrderMgrView',
      meta: { title: '工单详情', activeMenu: '/taskMgr/myWorkOrderMgr/list' }
    }
  ]
}

export default TaskMgrRouter
