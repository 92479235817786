var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "editor-container",
    },
    [
      _c("tinymce-editor", {
        ref: "tinymceEditor",
        attrs: {
          "api-key": "i7vi2n3r3wa8cijiodjqdcfidnhkyg02vsxj1qkdstppyzml",
          init: _vm.editorConfig,
        },
        on: { onInit: _vm.onEditorInit },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }