<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.name" placeholder="工作流程名称" />
        <el-select v-model="listQuery.type" placeholder="工作流程类型">
          <el-option v-for="(item,index) in getTaskTempTypeEnum()" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <ht-button type="success" icon="el-icon-plus" @click="onCreate">新建工作流程</ht-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in WorkFlowListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '工作流程类型'">{{ getTaskTempType(row[item.prop]) ? getTaskTempType(row[item.prop]).value : '-' }}</span>
          <span v-else-if="item.label === '作业类型'">{{ getTowerWorkOrderType(row[item.prop]) ? getTowerWorkOrderType(row[item.prop]).value : '-' }}</span>
          <span v-else-if="item.label === '创建人'">{{ row.staff.fullName }}</span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation v-if="isMobile" :width="100">
        <template v-slot="{row}">
          <dw-table-dropdown-buttons :buttons="row.buttons" :row="row" @handle-button-click="handleButtonClick(row,arguments)" />
        </template>
      </ht-table-column-operation>
      <ht-table-column-operation v-else :width="200">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-edit" @click="onEdit(row)">编辑</ht-button>
          <ht-button type="text" icon="el-icon-document-copy" @click="onCopy(row)">复制</ht-button>
          <ht-popover-button
            type="text"
            :danger-text="true"
            icon="el-icon-delete"
            confirm-title="请确认是否要删除该条数据?"
            @confirmed="onDelete(row)"
          >
            删除
          </ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="copyTemplateFormDialog" title="复制工单" width="40%" @close="cancelCopy">
      <div slot="content">
        <el-form ref="orderForm" :model="copyTemplateForm" :rules="copyTemplateRules" label-width="120px" style="margin-right: 20px">
          <el-form-item label="工作流程名称：" prop="name">
            <el-input v-model="copyTemplateForm.name" placeholder="请输入工作流程名称" :minlength="5" :maxlength="20" show-word-limit class="template-name" />
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <ht-button type="info" plain @click="cancelCopy">取 消</ht-button>
          <ht-button type="primary" @click="confirmCopy()">确定复制</ht-button>
        </div>
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { deleteTaskTemplate, getIsLoopedInOrder, getTaskTemplates, postTaskTemplates } from '@/api/taskMgr/workflow'
import { WorkFlowListTitle } from '@/views/taskMgr/tableTitle'
import { getTaskTempTypeEnum, getTaskTempType, getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import { mapGetters } from 'vuex'

export default {
  name: 'Index',
  extends: HtListContainer,
  data() {
    return {
      WorkFlowListTitle,
      copyTemplateForm: {
        name: undefined
      },
      copyTemplateRules: {
        name: [
          { required: true, message: '请输入工作流程名称', trigger: 'blur' }
        ]
      },
      copyTemplateData: null
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getTaskTempTypeEnum, getTaskTempType, getTowerWorkOrderType,
    getList() {
      this.isLoading = true
      getTaskTemplates(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.buttons = [
            { name: '编辑', icon: 'el-icon-edit', isPopoverButton: false },
            { name: '复制', icon: 'el-icon-video-pause', isPopoverButton: false },
            { name: '删除', icon: 'el-icon-delete', isPopoverButton: true, isDangerText: true, confirmTitle: '请确认是否要删除该条数据?' }
          ]
        })
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onCreate() {
      this.$router.push({ name: 'templateMgrCreate' })
    },
    handleButtonClick(row, $argument) {
      switch ($argument[0]) {
        case '编辑':
          this.onEdit(row)
          break
        case '复制':
          this.onCopy(row)
          break
        case '删除':
          this.onDelete(row)
          break
      }
    },
    onEdit(row) {
      this.$router.push({ name: 'templateMgrEdit', params: { templateId: row.id }})
    },
    onCopy(row) {
      this.copyTemplateData = row
      this.$refs.copyTemplateFormDialog.dialogVisible = true
      this.copyTemplateForm.name = '复制：' + row.name
    },
    cancelCopy() {
      this.copyTemplateData = null
      this.$refs.copyTemplateFormDialog.dialogVisible = false
      this.copyTemplateForm.name = undefined
    },
    confirmCopy() {
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          const templateData = {}
          templateData.name = this.copyTemplateForm.name
          templateData.type = this.copyTemplateData.type
          templateData.towerWorkType = this.copyTemplateData.towerWorkType
          templateData.steps = []
          this.copyTemplateData.steps.forEach(item => {
            templateData.steps.push({ ...{ sort: item.sort }, ...item.step })
          })
          postTaskTemplates(templateData).then(resp => {
            this.$router.push({ name: 'templateMgrEdit', params: { templateId: resp.id }})
            this.$message({ message: '复制成功', center: true, type: 'success' })
          })
        } else {
          this.$message({ message: `请确认工作流程名称`, type: 'error', center: true })
          return false
        }
      })
    },
    onDelete(row) {
      getIsLoopedInOrder(row.id).then((resp) => {
        if (resp.isLooped) {
          // 2.有循环工单可删除，提示：该工作流程已经创建循环工单，删除后工单不再循环创建，请确认是否删除？
          this.$confirm('该工作流程已经创建循环工单，删除后工单不再循环创建，请确认是否删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            this.deleteTemplate(row.id)
          }).catch(() => {})
        } else {
          this.deleteTemplate(row.id)
        }
      })
    },
    deleteTemplate(id) {
      deleteTaskTemplate(id).then(() => {
        this.$message({ message: '删除成功', center: true, type: 'success' })
        this.getList()
      })
    }
  }
}
</script>

<style scoped>

</style>
