var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "work-order-container",
      class: [
        _vm.FileUploadStatusStyleEnum[_vm.orderInfo.fileUploadStatus] + "-card",
        { "is-card-container": _vm.isCard },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "top-section", on: { click: _vm.onView } },
        _vm._l(_vm.MobilePatrolWorkOrderTitle, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              class: [
                {
                  "bold-item": item.isBold || false,
                  "large-item": item.isLarge || false,
                  "card-item": _vm.isCard,
                },
              ],
            },
            [
              item.label === "工程"
                ? [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.label + "：")),
                    ]),
                    _vm._v(
                      _vm._s(
                        "" +
                          ((_vm.orderInfo.targetInfo &&
                            _vm.orderInfo.targetInfo.projectName) ||
                            "-")
                      )
                    ),
                  ]
                : item.label === "设备"
                ? [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.label + "：")),
                    ]),
                    _vm._v(
                      _vm._s(
                        "" +
                          (_vm.orderInfo.targetName
                            ? _vm.orderInfo.targetName +
                              "-" +
                              _vm.orderInfo.targetCode
                            : "-")
                      )
                    ),
                  ]
                : item.label === "作业类型"
                ? _c(
                    "div",
                    {
                      style: {
                        color: _vm.getTowerWorkOrderType(
                          _vm.orderInfo.towerWorkType
                        ).color,
                      },
                    },
                    [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.label + "：")),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            "" +
                              (_vm.getTowerWorkOrderType(
                                _vm.orderInfo.towerWorkType
                              )
                                ? _vm.getTowerWorkOrderType(
                                    _vm.orderInfo.towerWorkType
                                  ).value
                                : "-")
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : item.label === "工单执行状态"
                ? _c(
                    "div",
                    [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.label + "：")),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tag",
                        {
                          staticClass: "status",
                          attrs: {
                            type: _vm.TaskWorkOrderStatusStyleEnum[
                              _vm.orderInfo.status
                            ],
                            effect: "dark",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.TaskWorkOrderStatusEnum[_vm.orderInfo.status]
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : item.label === "文件上传状态"
                ? _c("div", { staticClass: "last-item" }, [
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.label + "：")),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-tag",
                          {
                            staticClass: "status",
                            attrs: {
                              type: _vm.FileUploadStatusStyleEnum[
                                _vm.orderInfo.fileUploadStatus
                              ],
                              effect: "dark",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.FileUploadStatusEnum[
                                  _vm.orderInfo.fileUploadStatus
                                ]
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.label + "：")),
                    ]),
                    _vm._v(_vm._s("" + (_vm.orderInfo[item.prop] || "-"))),
                  ],
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.isCard
        ? _c(
            "div",
            { staticClass: "operation-container" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", round: "" },
                  on: { click: _vm.onDel },
                },
                [_vm._v("删除")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.onView },
                },
                [_vm._v("补充文件")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCard
        ? [
            _c(
              "div",
              { staticClass: "bottom-section" },
              _vm._l(_vm.orderInfo.uploadFiles, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "upload-File-item" },
                  [
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name + "：")),
                        ]),
                        _vm._v(" "),
                        _c("ht-upload-button", {
                          attrs: {
                            "file-type": ["img"],
                            "key-prefix": "workOrder",
                            "button-title": "上传文件",
                            "button-type": "primary",
                            "show-success-tip": false,
                          },
                          on: {
                            "on-file-uploaded": function ($event) {
                              return _vm.onUploaded(index, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "file-list-container" },
                      _vm._l(item.files, function (fileItem, fileIndex) {
                        return _c("dw-file", {
                          key: fileIndex,
                          class: [
                            { "is-last": fileIndex === item.files.length - 1 },
                            "file-item",
                          ],
                          attrs: {
                            url: fileItem.fileUrl,
                            name: fileItem.fileName,
                            "show-del": "",
                            "show-card": "",
                            "need-margin": false,
                          },
                          on: {
                            "delete-file": function ($event) {
                              return _vm.deleteFile(index, fileIndex)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }