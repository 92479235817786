<template>
  <div
    class="default-avatar"
    :style="{
      width: width + 'px',
      height: width + 'px',
      borderRadius: width + 'px',
      color: color,
      background: background,
      fontSize: fontSize + 'px',
      fontWeight: fontWeight
    }"
  >
    {{ fullName && fullName.length >= 2 ? fullName.substring(fullName.length - 2) :fullName }}
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'

export default {
  name: 'DwDefaultAvatar',
  props: {
    fullName: { type: String, default: undefined },
    width: { type: Number, default: 150 },
    color: { type: String, default: '#ffffff' },
    background: { type: String, default: variables.primaryColor },
    fontSize: { type: Number, default: 40 },
    fontWeight: { type: String, default: 'bolder' }
  },
  computed: {
    variables() {
      return variables
    }
  }
}
</script>

<style scoped lang="scss">
.default-avatar{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
