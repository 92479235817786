var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.getList },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabs, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.value, name: item.name },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.activeName === "inbound"
        ? _c("inbound-record-list", { ref: "inboundRecordList" })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName === "outbound"
        ? _c("outbound-record-list", { ref: "outboundRecordList" })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName === "transshipment"
        ? _c("transshipment-record-list", { ref: "transshipmentRecordList" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }