<template>
  <div style="display: inline-block">
    <ht-upload-button
      file-type="doc"
      button-type="primary"
      :multiple-selection="false"
      :show-success-tip="false"
      :key-prefix="uploadKeyPrefix"
      :button-title="dynamicBtnTitle"
      button-icon="el-icon-document"
      @on-upload-start="onUploadStart"
      @on-upload-finish="onUploadFinish"
      @on-file-uploaded="onFileUploaded"
      @on-upload-progress="onUploadProgress"
    />
  </div>
</template>

<script>

import { createAsyncTask, getAsyncTask, ASYNC_TASK_STATUS } from '@/api/async_task'

export default {
  name: 'HtAsyncImportButton',
  props: {
    uploadKeyPrefix: {
      type: String,
      default: 'temp/files'
    },
    buttonTitle: {
      type: String,
      default: '导入数据'
    },
    asyncType: {
      type: String,
      required: true,
      default: null
    },
    businessId: {
      type: [Number, String],
      required: true,
      default: null
    }
  },
  data() {
    return {
      loading: null,
      isLoading: false,
      urls: [],
      percent: 0
    }
  },
  computed: {
    dynamicBtnTitle() {
      if (this.isLoading) {
        return '上传中...'
      } else {
        return this.buttonTitle
      }
    }
  },
  methods: {
    onUploadStart() {
      this.isLoading = true
    },
    onUploadFinish() {
      this.isLoading = false
    },
    onUploadProgress(percent) {
      this.percent = percent
    },
    onFileUploaded(context, url) {
      this.fileUploaded([url])
    },
    fileUploaded(urls) {
      this.urls = urls
      this.$confirm('导入需要一点时间，请确认是否执行导入？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.loading = this.$loading(({
          lock: true,
          text: '导入中，请稍等...',
          spinner: 'el-icon-loading'
        }))
        this.doImportAsyncTask()
      })
    },
    genAsyncTaskParams() {
      return {
        taskType: this.asyncType,
        bizId: this.businessId,
        taskInfo: {
          fileUrls: this.urls
        }
      }
    },
    refreshAsyncTaskStatus(taskUuid, timeout = 500) {
      getAsyncTask(taskUuid)
        .then(response => {
          if (response.status === ASYNC_TASK_STATUS.processing || response.status === ASYNC_TASK_STATUS.created) {
            setTimeout(() => {
              this.refreshAsyncTaskStatus(taskUuid, timeout)
            }, timeout)
          } else if (response.status === ASYNC_TASK_STATUS.failed) {
            this.$notify({ title: '批量导入失败！', duration: 1500, type: 'error' })
            this.finishImport()
          } else if (response.status === ASYNC_TASK_STATUS.finished) {
            this.$notify({ title: '批量导入成功！', duration: 1500, type: 'success' })
            this.finishImport()
          }
        })
        .catch(() => {
          this.$notify({ title: '导入失败！', duration: 1500, type: 'error' })
          this.finishImport()
        })
    },
    finishImport() {
      this.loading.close()
      this.loading = null
      this.$emit('on-import-finished')
    },
    doImportAsyncTask() {
      const params = this.genAsyncTaskParams()
      createAsyncTask(params)
        .then(response => {
          this.refreshAsyncTaskStatus(response.uuid)
        })
        .catch(err => {
          this.$notify({ title: err, duration: 1500, type: 'error' })
          this.finishImport()
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
