var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "outboundForm",
          attrs: { model: _vm.outboundForm, rules: _vm.outboundFormRules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "出库仓库：", prop: "warehouseId" } },
            [_vm._v(_vm._s(_vm.warehouseInfo && _vm.warehouseInfo.name))]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "收货方（工程方）：",
                prop: "constructionProjectId",
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.projectList.length > 0
                        ? "请输入工程名称进行搜索"
                        : "当前无可选工程",
                    remote: "",
                    "remote-method": _vm.getProjects,
                  },
                  model: {
                    value: _vm.outboundForm.constructionProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.outboundForm, "constructionProjectId", $$v)
                    },
                    expression: "outboundForm.constructionProjectId",
                  },
                },
                _vm._l(_vm.projectList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "line-two-item-row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "出库设备：", prop: "equipmentItems" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", icon: "el-icon-plus" },
                      on: { click: _vm.openAddEquipmentDialog },
                    },
                    [_vm._v("添加设备")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ht-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isEquipmentLoading,
                  expression: "isEquipmentLoading",
                },
              ],
              ref: "list",
              staticStyle: { "margin-bottom": "18px" },
              attrs: { data: _vm.outboundForm.equipmentItems },
            },
            [
              _c("ht-table-column", {
                attrs: { label: "序号", type: "index", fixed: "left" },
              }),
              _vm._v(" "),
              _vm._l(
                _vm.WarehouseEquipmentStockOutboundListTitle,
                function (item) {
                  return _c("ht-table-column", {
                    key: item.otherProp,
                    attrs: {
                      label: item.label,
                      prop: item.otherProp,
                      "min-width": item.width || 100,
                      fixed: item.fixed,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              item.label === "库存数"
                                ? _c("span", [
                                    _vm._v(_vm._s(row[item.otherProp])),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(row[item.otherProp] || "-")),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }
              ),
              _vm._v(" "),
              _c("ht-table-column", {
                attrs: {
                  label: "出库数量",
                  fixed: "right",
                  prop: "outboundQuantity",
                  "show-overflow-tooltip": false,
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            min: 0,
                            max: row.quantity,
                            disabled:
                              row.quantity === 0 ||
                              row.constructionEquipmentIsWhole,
                            placeholder: "请输入出库数量",
                            precision: 0,
                          },
                          model: {
                            value: row.outboundQuantity,
                            callback: function ($$v) {
                              _vm.$set(row, "outboundQuantity", $$v)
                            },
                            expression: "row.outboundQuantity",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ht-table-column-operation", {
                attrs: { width: 100 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除该条数据？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onDeleteEquipment($index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "出库单：",
                prop: "attachmentData",
                "label-width": "80px",
              },
            },
            [
              _vm.outboundForm.attachmentData
                ? _c("dw-file", {
                    attrs: {
                      url: _vm.outboundForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.outboundForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["doc", "pdf", "img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传出库单",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认出库")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "addEquipmentDialog",
          attrs: { title: "添加设备", width: "750px" },
          on: { close: _vm.closeAddEquipmentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showAddEquipmentList
                ? _c("add-equipment-list", {
                    ref: "addEquipmentList",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      "already-add-list": _vm.outboundForm.equipmentItems,
                      "warehouse-id": _vm.outboundForm.warehouseId,
                    },
                    on: {
                      "on-add-equipments": function ($event) {
                        return _vm.closeAddEquipmentDialog(true, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }