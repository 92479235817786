<template>
  <el-card>
    <ht-action-panel :on-search="mySearch" :on-clear="myClear">
      <template slot="left">
        <common-search ref="commonSearch" @handleSelect="handleSelect" @selectDate="selectDate" />
      </template>
    </ht-action-panel>
    <ht-table
      ref="list"
      v-loading="isLoading"
      :data="list"
      :row-key="getRowKey"
      :default-sort="{prop: 'createdAt', order: 'descending'}"
      style="cursor: pointer"
      @row-click="handleRowClick"
      @selection-change="selectionChange"
      @sort-change="sortChange"
    >
      <ht-table-column-selection v-if="showSelector" v-model="selectedCheckRecord" />
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="i in labelToProps" :key="i.prop" :label="i.label" :prop="i.prop" :sortable="i.sortable" />
      <ht-table-column-operation>
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-view" @click="gotoView(row.id)">详情</ht-button>
          &nbsp;|&nbsp;&nbsp;
          <ht-popover-button
            icon="el-icon-delete"
            type="text"
            :danger-text="true"
            confirm-title="请确认是否要删除该条数据？"
            @confirmed="deleteData(row.id)"
          >删除
          </ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getSecurityCheckApi, deleteSecurityCheckRecord } from '@/api/secureEnvironmentMgr/securityCheck'
import { formatMsTimeToHour, sortQuery } from '@/utils'

export default {
  extends: HtListContainer,
  data() {
    return {
      labelToProps: [
        {
          label: '编号',
          prop: 'recordNo'
        },
        {
          label: '巡查人',
          prop: 'staff.fullName',
          sortable: 'custom'
        },
        {
          label: '时间',
          prop: 'createdAt',
          sortable: 'custom'
        },
        {
          label: '时长',
          prop: 'videoDuration',
          sortable: 'custom'
        },
        {
          label: '备注',
          prop: 'comment'
        }
      ],
      showSelector: false,
      selectedCheckRecord: [],
      searchForm: {
        searchStaffName: undefined,
        searchDate: undefined
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getSecurityCheckApi(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.videoDuration = formatMsTimeToHour(item.videoDuration)
        })
        this.afterGetList(resp)
      })
    },
    getRowKey(row) {
      return row.uuid
    },
    selectionChange(val) {
      this.selectedCheckRecord = val
    },
    handleSelect(id) {
      this.listQuery.staffId = id
      this.isSelectedStaff = true
    },
    selectDate(date) {
      if (date) {
        this.listQuery.startAt = date[0]
        this.listQuery.endAt = date[1]
      }
    },
    deleteData(id) {
      deleteSecurityCheckRecord(id).then(() => {
        this.$message({ message: `删除成功`, duration: 1500, type: 'success' })
        this.getList()
      })
    },
    handleRowClick(row, column) {
      if (column.label !== '操作' && !this.showSelector) {
        this.gotoView(row.id)
      }
    },
    gotoView(recordId) {
      this.$router.push({ name: 'securityCheckView', params: { recordId: recordId }})
    },
    mySearch() {
      const searchForm = this.$refs.commonSearch.searchForm
      if (searchForm.searchStaffName) {
        if (this.listQuery.staffId) {
          this.onSearch()
        } else {
          this.$message({ message: '请在下拉框内选择员工后进行搜索', center: true, type: 'warning' })
        }
      } else if (this.listQuery.startAt && this.listQuery.endAt) {
        this.onSearch()
      }
    },
    myClear() {
      this.$refs.commonSearch.searchForm = { searchStaffName: undefined, searchDate: undefined }
      this.onClear()
    },
    sortChange(ev) {
      this.listQuery.sort = sortQuery(ev)
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.el-tooltip__popper {
  max-width: 30% !important;
}
::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}
</style>
