<template>
  <div class="list-container">
    <div class="info-container">
      <div class="name">{{ name }}</div>
      <div class="count">{{ isWarehouse ? '库存总数' : '现存设备数' }}：{{ count }}</div>
    </div>
    <ht-table ref="list" v-loading="isLoading" :data="list">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in WarehouseEquipmentStockInboundListTitle"
        :key="item.otherProp"
        :label="item.label"
        :prop="item.otherProp"
        :min-width="item.width || 100"
        :fixed="item.fixed"
      >
        <template v-slot="{row}">
          <span>{{ row[item.otherProp] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column v-if="isWarehouse" label="库存数" fixed="right" prop="quantity" />
      <ht-table-column v-else label="现存设备数" fixed="right" prop="projectEquipmentQuantity" />
    </ht-table>
  </div>
</template>

<script>
import { WarehouseEquipmentStockInboundListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getWarehouseStock } from '@/api/constructionEquipmentMgr/stockMgr'
import { getProject } from '@/api/constructionProjectMgr/projectMgr'

export default {
  name: 'EquipmentList',
  props: {
    isWarehouse: { type: Boolean, default: false },
    id: { type: [Number, String], default: null }
  },
  data() {
    return {
      WarehouseEquipmentStockInboundListTitle,
      name: null,
      count: 0,
      list: [],
      isLoading: false
    }
  },
  methods: {
    getList(info) {
      this.name = info.name
      if (this.isWarehouse) {
        getWarehouseStock(info.id).then(resp => {
          this.list = resp
          this.count = this.list.reduce((quantity, current) => {
            if (current.quantity > 0) {
              return quantity + current.quantity
            }
            return quantity
          }, 0)
        })
      } else {
        getProject(info.id).then(resp => {
          this.name = resp.name
          this.list = resp.equipmentStockItems
          this.count = this.list.reduce((quantity, current) => {
            if (current.projectEquipmentQuantity > 0) {
              return quantity + current.projectEquipmentQuantity
            }
            return quantity
          }, 0)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-container{
  .info-container{
    height: 40px;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    .count{
      margin-left: 20px;
    }
  }
}
</style>
