<template>
  <div class="stock-mgr-container">
    <equipment-stock-list v-if="activeName === 'equipment'" ref="equipmentStockList">
      <template slot="tabs">
        <el-tabs v-model="activeName" class="top-tabs" type="card" @tab-click="getList">
          <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.value" :name="item.name" />
        </el-tabs>
      </template>
    </equipment-stock-list>
    <warehouse-stock-list v-if="activeName === 'warehouse'" ref="warehouseStockList">
      <template slot="tabs">
        <el-tabs v-model="activeName" class="top-tabs" type="card" @tab-click="getList">
          <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.value" :name="item.name" />
        </el-tabs>
      </template>
    </warehouse-stock-list>
  </div>
</template>

<script>
import EquipmentStockList from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/equipmentStockList'
import WarehouseStockList from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/warehouseStockList'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { EquipmentStockList, WarehouseStockList },
  data() {
    return {
      activeName: 'equipment',
      tabs: [
        { name: 'equipment', value: '按设备展示' },
        { name: 'warehouse', value: '按仓库展示' }
      ]
    }
  },
  computed: {
    ...mapGetters(['activatedTab'])
  },
  created() {
    this.activeName = this.activatedTab || 'equipment'
    this.$store.dispatch('tagsView/setActivatedTab', this.activeName)
  },
  methods: {
    getList() {
      this.$nextTick(() => {
        this.$store.dispatch('tagsView/setActivatedTab', this.activeName)
        if (this.activeName === 'equipment') {
          this.$refs.equipmentStockList.getList()
        } else if (this.activeName === 'warehouse') {
          this.$refs.warehouseStockList.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.stock-mgr-container{
  margin-bottom: 100px;
  position: relative;
  .top-tabs{
    ::v-deep .el-tabs__header{
      margin: auto;
      border: none;
      height: 35px;
      line-height: 35px;
      .el-tabs__nav{
        width: 100%;
        display: flex;
        border: none;
        height: 35px;
        line-height: 35px;
        .el-tabs__item{
          flex: 1;
          text-align: center;
          border: none;
          height: 35px;
          line-height: 35px;
          &.is-active{
            background: $primaryColor;
            color: #ffffff;
            border-radius: 20px;
            border: none;
            font-size: 1.1rem;
            height: 35px;
            line-height: 35px;
          }
        }

      }
    }
  }
}

</style>
