function myLog(...logs) {
  console.log(`[MY LOG]`, ...logs)
  // console.trace(`[MY LOG]`, ...logs)
}

function myInfoLog(...logs) {
  console.info(`[MY LOG]`, ...logs)
}

function myWarnLog(...logs) {
  console.warn(`[MY LOG]`, ...logs)
}

function myErrorLog(...logs) {
  console.error(`[MY LOG]`, ...logs)
}

function myDebugLog(...logs) {
  console.debug(`[MY LOG]`, ...logs)
}

function myTableLog(obj) {
  console.table(`[MY LOG]`, obj)
}

function myTraceLog(...logs) {
  console.trace(`[MY LOG]`, ...logs)
}

export default {
  myLog,
  myInfoLog,
  myWarnLog,
  myErrorLog,
  myDebugLog,
  myTableLog,
  myTraceLog
}
