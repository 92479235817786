<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.fullName" placeholder="姓名" />
        <el-input v-model="listQuery.userName" placeholder="登录名" />
        <el-select v-if="activeName === '系统员工'" v-model="listQuery.activated" placeholder="账号状态">
          <el-option
            v-for="item in activatedList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-action-panel>
      <template slot="right">
        <el-button type="success" icon="el-icon-plus" @click="createUser">新建用户</el-button>
        <el-button v-if="!showSelector" type="primary" icon="el-icon-printer" @click="showSelectorQrCode">批量下载二维码</el-button>
        <template v-else>
          <el-button type="success" :disabled="selectedQrCode.length === 0" icon="el-icon-printer" @click="myHandleBatchDownload">确定选择</el-button>
          <el-button type="warning" @click="showSelector = false">取消选择</el-button>
        </template>
        <el-button v-if="!showDelSelector" type="danger" icon="el-icon-delete" @click="showDelSelect">批量删除</el-button>
        <template v-else>
          <ht-popover-button
            type="success"
            :disabled="selectedDelUser.length === 0"
            :confirm-title="`是否确认删除${selectedDelUser.length}个用户？`"
            @confirmed="handleDelete"
          >确定删除
          </ht-popover-button>
          <el-button type="warning" @click="showDelSelector = false">取消选择</el-button>
        </template>
      </template>
    </ht-action-panel>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(i, index) in tabs" :key="index" :label="i" :name="i" />
      <app-table
        ref="list"
        v-loading="isLoading"
        :list="list"
        :table-titles="labelToProps"
        :row-key="getRowKey"
        :bt-width="activeName === '系统员工' ? 100 : 0"
        :page="page"
        :list-query="listQuery"
        @selection-change="selectionChange"
        @pagination="getList"
        @subOptionButton="subOptionButton"
        @confirmMethod="subOptionButton"
        @sortChange="sortChange"
      >
        <ht-table-column-selection v-if="showSelector || showDelSelector" slot="isSelection" v-model="selectedQrCode" />
        <template v-if="activeName === '系统员工'" #qrCodeOperation="slotProp">
          <el-button
            type="text"
            icon="el-icon-printer"
            @click="printQRCode(slotProp.info.index)"
          >打印二维码</el-button>
          <ht-popover-button
            type="text"
            button-color="green"
            icon="el-icon-refresh"
            :confirm-title="`更新二维码后，原二维码将失效不可用，请谨慎操作！`"
            @confirmed="refreshQRCode(slotProp.info.row, slotProp.info.index)"
          >更新二维码
          </ht-popover-button>
        </template>
      </app-table>
    </el-tabs>

    <ht-print ref="printQRCode">
      <div>
        <img :src="qRCodeImg" width="400px" height="400px">
      </div>
    </ht-print>

    <!--    编辑用户对话框-->
    <ht-dialog ref="HtUserDialog" title="用户信息" :width="isMobile? '100%' : '500px'" :close-on-click-modal="false">
      <div slot="content">
        <el-form ref="userForm" :model="userForm" :rules="rules" label-width="80px" style="margin-right: 20px">
          <el-form-item label="登录名：" prop="userName">
            <el-input v-model="userForm.userName" autocomplete="off" :disabled="!userForm.isCreate" maxlength="20" show-word-limit />
          </el-form-item>
          <el-form-item label="姓名：" prop="fullName">
            <el-input v-model="userForm.fullName" autocomplete="off" placeholder="请输入姓名" maxlength="10" show-word-limit />
          </el-form-item>
          <el-form-item label="电话：">
            <el-input v-model="userForm.mobile" autocomplete="off" placeholder="请输入电话" maxlength="12" show-word-limit />
          </el-form-item>
          <el-form-item label="角色：">
            <el-select v-model="userForm.role" placeholder="请选择" style="width: 100%">
              <el-option
                v-for="item in roleOptions"
                :key="item.tag"
                :label="item.name"
                :value="item.tag"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-left: 30%">
          <el-button @click="$refs.HtUserDialog.dialogVisible = false">取 消</el-button>
          <el-button :loading="isSubmitting" type="primary" @click="submitUserInfoUpdate('userForm')">确 定</el-button>
        </div>
      </div>
    </ht-dialog>

    <!--    登录日志对话框-->
    <ht-dialog
      ref="HtLoginEventsDialog"
      title="登录日志"
      :width="isMobile? '100%' : '600px'"
    >
      <ht-table slot="content" ref="loginEventsList" v-loading="isLoginEventsLoading" :data="loginEventsList">
        <ht-table-column label="序号" type="index" fixed="left" />
        <ht-table-column v-for="i in loginEventsLabelToProps" :key="i.secondProp" :label="i.label" :prop="i.prop" :width="i.minWidth">
          <template v-slot="{row}">
            <span v-if="i.secondProp">{{ (row[i.prop] && row[i.prop][i.secondProp]) ? row[i.prop][i.secondProp] : '-' }}</span>
            <span v-else>{{ row[i.prop] ? row[i.prop] : '-' }}</span>
          </template>
        </ht-table-column>
      </ht-table>
      <ht-pagination
        :total="loginEventsPage.total"
        :page.sync="loginEventsListQuery.page"
        :limit.sync="loginEventsListQuery.perPage"
        @pagination="getLoginEventsList"
      />
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import {
  createUserInfoApi,
  deleteUserApi,
  deleteUsersApi,
  getLoginEventsList,
  getUserMgrList,
  updateUserInfoApi,
  userInfoOperation
} from '@/api/systemMgr/userMgr'
import { handleBatchDownload, sortQuery } from '@/utils'
import { simpleResetPasswordApi } from '@/api/userInfo/resetPassword'
import { getRolesApi } from '@/api/systemMgr/roleMgr'
import { validatePhoneNumber } from '@/utils/validate'
import { mapGetters } from 'vuex'

export default {
  extends: HtListContainer,
  data() {
    return {
      isShowQRCode: false,
      labelToProps: [],
      qRCodeImg: '',
      showSelector: false,
      showDelSelector: false,
      selectedQrCode: [],
      userDialogVisible: false,
      userForm: {
        userName: undefined,
        fullName: undefined,
        mobile: undefined,
        updateUserUuid: undefined,
        role: undefined
      },
      rules: {
        userName: [{ required: true, message: '请输入登录名', trigger: 'blur' }],
        fullName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        mobile: [{ required: false, message: '联系电话格式错误', trigger: 'blur', validator: validatePhoneNumber }]
      },
      activatedList: [{ label: '全部' }, { label: '已上线', value: true }, { label: '未上线', value: false }],
      loginEventsVisible: false,
      loginEventsLabelToProps: [
        {
          label: '登录时间',
          prop: 'eventTime'
        },
        {
          label: 'IP地址',
          prop: 'eventParams',
          secondProp: 'ipAddress'
        },
        {
          label: '登录名',
          prop: 'eventParams',
          secondProp: 'userName'
        }
      ],
      isLoginEventsLoading: false,
      loginEventsList: [],
      loginEventsPage: { total: 0 },
      loginEventsListQuery: { page: 1, perPage: 20 },
      checkUserId: undefined,
      roleOptions: [],
      activeName: undefined,
      tabs: ['系统员工', '已删除员工'],
      selectedDelUser: [],
      isSubmitting: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getRolesFun()
    this.activeName = this.tabs[0]
    this.handleClick()
  },
  methods: {
    initTitles() {
      this.labelToProps = [
        { label: '姓名', prop: 'fullName', minWidth: '150px', sortable: 'custom' },
        { label: '登录名', prop: 'account.userName', firstProp: 'account', secondProp: 'userName', minWidth: '150px', sortable: 'custom' },
        { label: '角色', prop: 'showRoles', minWidth: '150px', sortable: 'custom' },
        { label: '电话', prop: 'mobile', minWidth: '120px' }
        // { label: '是否已注册到远程指导', prop: 'isRegisterToGuidance', statusType: { 未注册: 'info', 已注册: 'success' }, format: 'status', minWidth: '170px', fixed: 'right', sortable: 'custom' }
      ]
    },
    getList() {
      this.beforeGetList()
      this.listQuery.deleted = this.activeName === '已删除员工'
      this.listQuery.isTemporaryStaff = false
      getUserMgrList(this.listQuery).then((resp) => {
        resp.items.forEach((item) => {
          // item.showRoles = item.roleNames.join(',')
          item.showRoles = item.roleNames[0]
          item.otherButtons = [
            {
              name: '删除用户',
              dangerText: true,
              isPopoverButton: true,
              confirmTitle: '请确认是否删除该用户？',
              icon: 'el-icon-delete'
            },
            { name: '登录日志', disabled: false, icon: 'el-icon-document' },
            { name: item.account.activated ? '下线' : '上线', disabled: false, icon: 'el-icon-sort', color: '#e6a23c' },
            {
              name: '重置密码',
              dangerText: true,
              isPopoverButton: true,
              confirmTitle: '请确认是否重置密码?重置后密码为123456',
              icon: 'el-icon-refresh'
            }
            // {
            //   name: item.isRegisterToGuidance ? '已注册到远程指导' : '注册到远程指导',
            //   disabled: item.isRegisterToGuidance,
            //   isPopoverButton: true,
            //   buttonColor: item.isRegisterToGuidance ? '' : 'orange',
            //   confirmTitle: `请确认是否注册用户到远程指导？`,
            //   icon: 'el-icon-d-arrow-right'
            // }
          ]
          if (item.isRegisterToGuidance) {
            item.otherButtons.pop()
          }
          item.activated = item.account.activated ? '已上线' : '未上线'
          item.isRegisterToGuidance = item.isRegisterToGuidance ? '已注册' : '未注册'
          item.mainButton = { name: '编辑', disabled: false, icon: 'el-icon-edit' }
        })
        this.afterGetList(resp)
      })
    },
    getRolesFun() {
      getRolesApi().then((resp) => {
        this.roleOptions = resp.items
        this.userForm.role = resp.items[0].tag
      })
    },
    printQRCode(index) {
      this.qRCodeImg = this.$refs.list.$refs.Qrcode[index].$el.src
      this.$nextTick(() => {
        this.$refs.printQRCode.print()
      })
    },
    refreshQRCode(row, index) {
      const data = { action: 'REFRESH_QR_CODE' }
      userInfoOperation(row.uuid, data).then((resp) => {
        this.$message({ message: '更新成功', center: true, type: 'success' })
        this.getList()
      })
    },
    showSelectorQrCode() {
      this.showSelector = true
      this.$message({
        message: '请勾选需要下载的二维码',
        center: true,
        type: 'warning'
      })
    },
    showDelSelect() {
      this.showDelSelector = true
      this.$message({
        message: '请勾选需要删除的用户',
        center: true,
        type: 'warning'
      })
    },
    selectionChange(val) {
      this.showSelector ? this.selectedQrCode = val : []
      this.showDelSelector ? this.selectedDelUser = val : []
    },
    myHandleBatchDownload() {
      this.showSelector = false
      const data = []
      for (var i = 0; i < this.selectedQrCode.length; i++) {
        data.push({ img: this.$refs.list.$refs.Qrcode[i].$el.src, name: this.selectedQrCode[i].fullName })
      }
      handleBatchDownload(data, '二维码')
      this.$refs.list.clearSelection()
    },
    handleDelete() {
      // TODO 接口未出
      const data = this.selectedDelUser.map(i => i.uuid)
      deleteUsersApi({ staffUuids: data }).then(() => {
        this.$message({ message: '删除成功', center: true, type: 'success' })
        this.getList()
      })
    },
    getRowKey(row) {
      return row.userId
    },
    subOptionButton(row, btName, type) {
      if (btName === '编辑') {
        this.willUpdateUserInfo(row)
      } else if (btName === '登录日志') {
        this.viewLoginEvents(row)
      } else if (btName === '下线' || btName === '上线') {
        this.activateUser(row)
      }
      if (type === 'confirmMethod') {
        if (btName === '注册到远程指导') {
          this.registerToGuidance(row)
        } else if (btName === '重置密码') {
          this.simpleResetPassword(row.uuid)
        } else if (btName === '删除用户') {
          this.deleteUser(row.uuid)
        }
      }
    },
    sortChange(ev) {
      this.listQuery.sort = sortQuery(ev)
      this.getList()
    },
    willUpdateUserInfo(row) {
      this.userForm = {
        userName: row.account.userName,
        fullName: row.fullName,
        mobile: row.mobile,
        updateUserUuid: row.uuid,
        isCreate: false,
        role: row.roles[0]
      }
      this.$refs.HtUserDialog.dialogVisible = true
    },
    createUser() {
      this.userForm = {
        userName: undefined,
        fullName: undefined,
        mobile: undefined,
        password: '123456',
        isCreate: true,
        role: 'ADMIN'
      }
      this.$refs.HtUserDialog.dialogVisible = true
    },
    submitUserInfoUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            fullName: this.userForm.fullName,
            mobile: this.userForm.mobile,
            userName: this.userForm.isCreate ? this.userForm.userName : undefined,
            password: this.userForm.isCreate ? this.userForm.password : undefined,
            roles: [this.userForm.role]
          }
          this.isSubmitting = true
          if (!this.userForm.isCreate) {
            // const data = { fullName: this.userForm.fullName, mobile: this.userForm.mobile ? this.userForm.mobile : '', roles: this.userForm.role }
            updateUserInfoApi(this.userForm.updateUserUuid, data).then(() => {
              this.$refs.HtUserDialog.dialogVisible = false
              this.$message({ message: '编辑成功', center: true, type: 'success' })
              this.getList()
              this.isSubmitting = false
            }).catch(() => { this.isSubmitting = false })
          } else {
            createUserInfoApi(data).then(() => {
              this.$refs.HtUserDialog.dialogVisible = false
              this.$message({ message: '注册成功', center: true, type: 'success' })
              this.getList()
              this.isSubmitting = false
            }).catch(() => { this.isSubmitting = false })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    simpleResetPassword(uuid) {
      simpleResetPasswordApi(uuid).then(() => {
        this.$message({
          message: '密码已重置成功',
          type: 'success'
        })
      })
    },
    activateUser(row) {
      const data = { action: 'TOGGLE_ACTIVATE' }
      userInfoOperation(row.uuid, data).then((resp) => {
        this.$message({ message: '操作成功', center: true, type: 'success' })
        this.getList()
      })
    },
    deleteUser(uuid) {
      deleteUserApi(uuid).then(() => {
        this.$message({ message: '删除成功', center: true, type: 'success' })
        this.getList()
      })
    },
    viewLoginEvents(row) {
      this.$refs.HtLoginEventsDialog.dialogVisible = true
      this.checkUserId = row.userId
      this.getLoginEventsList(row)
    },
    getLoginEventsList() {
      getLoginEventsList(this.checkUserId).then((resp) => {
        this.loginEventsList = resp.items
        this.loginEventsPage = resp.page
      })
    },
    registerToGuidance(row) {
      userInfoOperation(row.uuid, { action: 'REGISTER_TO_GUIDANCE' }).then(() => {
        this.$message({ message: '注册成功', center: true, type: 'success' })
        this.getList()
      })
    },
    handleClick() {
      this.initTitles()
      if (this.activeName === '系统员工') {
        this.labelToProps.push(
          {
            label: '二维码',
            prop: 'qrCode',
            format: 'QRCode',
            minWidth: '400px'
          },
          {
            label: '状态',
            prop: 'activated',
            format: 'status',
            statusType: { 未上线: 'info', 已上线: 'success' },
            minWidth: '100px',
            fixed: 'right',
            sortable: 'custom'
          })
      }
      this.$nextTick(() => {
        this.getList()
      })
    }
  }
}
</script>

<style scoped>

</style>
