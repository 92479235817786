import Websocket from '@/assets/image/newRemoteGuidance/video/pc.png'
// import Android from '@/assets/newRemoteGuidance/video/phone.png'
import Glasses from '@/assets/image/newRemoteGuidance/video/glass.png'
import { RCFrameRate, RCResolution } from '@rongcloud/plugin-rtc'

export const ContactsLabelToProps = [
  { label: '头像', enLabel: 'avatar', prop: 'avatarUrl', width: '50px' },
  { label: '姓名', enLabel: 'fullName', prop: 'fullName', width: '150px' },
  { label: '用户名', enLabel: 'userName', prop: 'account.userName', firstProp: 'account', secondProp: 'userName', width: '150px' },
  { label: '角色', enLabel: 'role', prop: 'roles', width: '150px' }
]

export const UserTypeEnum = {
  ADMIN: '管理员',
  EXPERT: '专家',
  STAFF: '现场'
}

export const UserLoginPathEnum = {
  Websocket: 'PC登陆',
  Android: '设备登陆',
  GLASSES: '设备登陆'
}

export const LoginIconEnum = {
  Websocket: Websocket,
  Android: Glasses,
  GLASSES: Glasses
}

export const meetingVideoProperty = {
  normalMeetingDialogSmallWidth: 898, // 848 + 50
  normalMeetingDialogLargeWidth: 1278,
  normalLargeVideoWidth: 848,
  normalLargeVideoHeight: 480,
  normalSmallVideoWidth: 400,
  normalSmallVideoHeight: 226,
  fullscreenMeetingDialogMinWidth: 898,
  fullscreenMeetingDialogMinHeight: 700
}

export const videoConfigs = [
  { resolutionName: '1080P', frameRate: RCFrameRate.FPS_30, resolution: RCResolution.W1920_H1080, bitRate: { max: 4000, min: 400, start: 4000 }},
  { resolutionName: '720P', frameRate: RCFrameRate.FPS_30, resolution: RCResolution.W1280_H720, bitRate: { max: 2200, min: 250, start: 2200 }},
  { resolutionName: '480P', frameRate: RCFrameRate.FPS_30, resolution: RCResolution.W848_H480, bitRate: { max: 1860, min: 200, start: 1860 }}
]

export const VideoResolutions = [
  { name: '1080P', value: '全高清 1080P', enValue: 'Full HD 1080P' },
  { name: '720P', value: '高清 720P', enValue: 'HD 720P' },
  { name: '480P', value: '标清 480P', enValue: 'SD 480P' }
]

export const InviteRecordType = {
  INVITING: 'INVITING',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  NO_RESPONSE: 'NO_RESPONSE'
}

export const MeetingErrorMsg = {
  joinRoomPermissionError: '当前环境无法进行会议，入会失败',
  getMediaPermissionError: '当前环境不可进行音视频会议，入会失败',
  joinChatRoomError: '加入会议异常，入会失败',
  createRoomError: '会议创建失败',
  joinMeetingError: '加入会议失败',
  joinMeetingRepeat: '重复加入会议，请确认是否其他端登陆并参会中',
  confirmMeetingStatus: '加入会议失败，请确认当前会议是否结束',
  meetingFinishRefresh: '当前会议已结束，会议列表已刷新'
}
