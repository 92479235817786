<template>
  <div class="attendees-list-container">
    <div class="top-container" :style="{background: showBottomButton ? '#ffffff' : '#ffffff'}">
      <div v-for="(user, index) in list" :key="index" class="user-container">
        <el-image v-if="user.avatarUrl" class="avatar item" :src="user.avatarUrl || AvatarBlue" />
        <dw-default-avatar v-else :full-name="user.fullName" :width="40" :font-size="14" style="margin-right: 5px" />
        <div class="text item">
          <span class="fullName">{{ `${user.fullName}` }}</span>
          <span v-if="!isSinoma && !stringLongOverTen(user.userName)" class="userName">{{ `(${user.userName})` }}</span>
          <span v-if="user.isSelf" class="self">(自己)</span>
          <span v-if="user.isInitiator" class="initiator">(发起人)</span>
        </div>
        <div
          class="operation-container"
          :class="{'is-expect': isExpert(user), 'is-self' : userInfo.userName === user.userName, 'i-am-expect': isExpert(userInfo), 'i-am-staff': isStaff(userInfo)}"
        >
          <img :src="user.isMicOn ? MicrophoneOn : MicrophoneOff" class="microphone-icon icon item" alt="" @click="changeOneMicMute(user)">
          <div style="width: 25px; margin-right: 10px">
            <img v-if="isStaff(user)" :src="user.isCamOn ? CameraOn : CameraOff" class="camera-icon icon item" alt="" @click="changeOneCamMute(user)">
          </div>
        </div>
      </div>
    </div>
    <div v-if="showBottomButton" class="bottom-container">
      <div class="microphone-container item" :class="{'is-close': isMuteAllMic}" @click="changeAllMicMute">
        <img v-if="isMuteAllMic" :src="MicrophoneOff" class="icon" alt="">
        <img v-else :src="MicrophoneOn" class="icon" alt="">
        <span class="text">{{ isMuteAllMic? '全部开启' : '全部关闭' }}</span>
      </div>
      <div class="camera-container item" :class="{'is-close': isMuteAllCam}" @click="changeAllCamMute">
        <img v-if="isMuteAllCam" :src="CameraOff" class="icon" alt="">
        <img v-else :src="CameraOn" class="icon" alt="">
        <span class="text">{{ isMuteAllCam? '全部开启' : '全部关闭' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarBlue from '@/assets/image/newRemoteGuidance/avatar_blue.jpg'
import AvatarGrey from '@/assets/image/newRemoteGuidance/avatar_grey.png'
import MicrophoneOn from '@/assets/image/newRemoteGuidance/microphone_open.png'
import MicrophoneOff from '@/assets/image/newRemoteGuidance/microphone_close.png'
import CameraOn from '@/assets/image/newRemoteGuidance/camera_open.png'
import CameraOff from '@/assets/image/newRemoteGuidance/camera_close.png'
import { isExpert, isStaff } from '../js/business'
import store from '@/store'
import { stringLongOverTen } from '@/utils'

export default {
  name: 'AttendeesList',
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    },
    userList: {
      type: Array,
      default: () => []
    },
    showBottomButton: {
      type: Boolean,
      default: false
    },
    isMuteAllMic: {
      type: Boolean,
      default: false
    },
    isMuteAllCam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      AvatarBlue,
      AvatarGrey,
      CameraOn,
      CameraOff,
      MicrophoneOn,
      MicrophoneOff,
      isSinoma: undefined,
      list: []
    }
  },
  watch: {
    userList: {
      handler: function(val, oldVal) {
        this.list = val
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
  },
  methods: {
    isExpert, isStaff, stringLongOverTen,
    changeOneMicMute(user) {
      if (this.userInfo.userName === user.userName || this.isExpert(this.userInfo)) {
        this.$emit('change-one-mic-mute', user.imUserId, !user.isMicOn)
      }
    },
    changeOneCamMute(user) {
      if (!this.isExpert(user) && (this.userInfo.userName === user.userName || this.isExpert(this.userInfo))) {
        this.$emit('change-one-cam-mute', user.imUserId, !user.isCamOn)
      }
    },
    changeAllMicMute() {
      this.$emit('change-all-mic-mute', this.isMuteAllMic)
    },
    changeAllCamMute() {
      this.$emit('change-all-cam-mute', this.isMuteAllCam)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.attendees-list-container{
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  .top-container{
    flex: 1;
    overflow-y: auto;
    background: #F3F3F3;
    padding-bottom: 30px;
  }
  .user-container {
    flex: 1;
    height: 70px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid #E5E5E5;

    .item {
      margin-right: 10px;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }

    .text {
      flex: 1;
      text-align: left;

      .fullName{
        font-weight: bold;
        font-size: 14px;
      }
      .userName {
        font-weight: normal;
      }
      .self{
        color: #1DC763;
      }
      .initiator{
        color: $primaryColor;
      }
    }

    .operation-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon{
        width: 25px;
      }
    }
    .i-am-staff{
      .icon{
        cursor: not-allowed;
      }
    }
    .i-am-staff.is-expect{
      .icon{
        cursor: not-allowed;
      }
    }
    .i-am-expect{
      .icon{
        cursor: pointer !important;
      }
    }
    .is-self{
      .icon{
        cursor: pointer !important;
      }
    }
    .is-expect{
      .camera-icon{
        cursor: not-allowed !important;
      }
    }
  }
  .bottom-container{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F6F6F6;
    border-top: 1px solid rgb(229,229,229);
    border-radius: 0 0 10px 0;
    .item{
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e2ecfd;
      border-radius: 30px;
      padding: 0 15px;
      cursor: pointer;
      .icon{
        width: 20px;
        margin-right: 5px;
      }
      .text{
        color: #2E5AFF;
      }
    }
    .is-close{
      background: #efefef;
      .text{
        color: #666666;
      }
    }
    .microphone-container{
      margin-right: 20px;
    }
    .camera-container{
      margin-left: 20px;
    }
  }
}
</style>
