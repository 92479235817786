import rtc from '@/views/newRemoteGuidance/rongCloud/rtc'
import { postVideoRecord } from '@/api/newRemoteGuidance/newRemoteGuidance'

// 共享屏幕
export default {
  methods: {
    isHasShare() {
      rtc.getRoomAttributes(this.room, this.shareScreenKeys).then(data => {
        if (data.hasOwnProperty('shareScreenImUserId')) { // 取到shareScreenImUserId：当前有用户在共享屏幕
          if (data.shareScreenImUserId === this.imUserId) { // 共享屏幕的是自己：出现可能为上一次会议异常退出，房间内状态没有清除
            this.isShareScreenVideo = false // 关闭共享屏幕
            this.screenVideoShareStop().then()
          } else { // 有其他人在进行屏幕共享
            this.isRemoteShareScreen = true // 设置远端他人共享状态为true
            this.shareScreenUserInfo = data // 拿到用户信息
            this.remoteShareScreenChanged().then() // 远端后面用户共享屏幕处理
            this.$nextTick(() => {
              // 在remoteVideoTracks里找到tag为PublicScreen的视频，进行播放
              const result = this.remoteVideoTracks.some(track => track.getTag() === 'PublicScreen')
              if (result) {
                const index = this.remoteVideoTracks.findIndex(track => track.getTag() === 'PublicScreen')
                this.$nextTick(() => {
                  this.playTrack(this.remoteVideoTracks[index]).then()
                })
              } else {
                this.remoteVideoTracks.forEach(track => {
                  this.$nextTick(() => {
                    this.playTrack(track).then()
                  })
                })
              }
            })
          }
        }
      })
    },
    // 屏幕共享
    // 屏幕共享模式下不可以进行屏幕共享
    async screenVideoShareFun(isShare) {
      // 聚焦指导中不可以开启屏幕共享，如何在聚焦指导之前（聚焦指导消息发送晚于屏幕共享开启）开启的屏幕共享，
      if (this.isShareScreenVideo) {
        this.isShareScreenVideo = false
        this.localScreenVideoTrack.destroy()
        await this.screenVideoShareStop()
      } else {
        if (this.isFocusGuidance || this.isFocusedGuidanceByRemote) {
          this.$message({ message: `聚焦指导中，不可进行屏幕共享`, type: 'error', center: true })
        } else {
          if (this.isRemoteShareScreen) {
            this.$message({ message: `当前【${this.shareScreenUserInfo.shareScreenFullName}】在进行屏幕共享，请等待该用户共享结束后再进行操作`, type: 'error', center: true })
          } else {
            this.isShareScreenVideo = isShare
            if (this.isShareScreenVideo) {
              await this.screenVideoShareStart()
            } else {
              this.localScreenVideoTrack.destroy()
              await this.screenVideoShareStop()
            }
          }
        }
      }
    },
    async screenVideoShareStart() {
      this.getLocalTrack({ audio: false, camera: false, screen: true }).then(async() => {
        await this.publishAll({ audio: false, camera: false, screen: true })
        const shareData = {
          shareScreenImUserId: this.userImInfo.imUserId,
          shareScreenFullName: this.userInfo.fullName,
          shareScreenUserName: this.userInfo.account.userName
        }
        rtc.setRoomAttribute(this.room, shareData).catch(() => {})
        this.sendMeetingScreenStatusMsg(true)
        if (this.isRecording) {
          const data = {
            videoItems: [{ imUserId: this.imUserId, streamId: this.localScreenVideoTrack.getStreamId() }],
            action: 'update'
          }
          postVideoRecord(this.roomId, data)
        }
      })
    },
    async screenVideoShareStop() {
      if (this.isRecording) {
        // 更新屏幕录制状态（共享屏幕过程中只录制被共享的屏幕，结束共享屏幕需要将录制内容修改为会议内其他视频）
        const videoItems = []
        this.remoteVideoTracks.forEach(item => { videoItems.push({ imUserId: item.getUserId(), streamId: item.getStreamId() }) })
        const data = { videoItems: videoItems, action: 'update' }
        postVideoRecord(this.roomId, data)
      }
      await this.unpublishAll({ screen: true }) // 取消发布共享屏幕
      this.localScreenVideoTrack = null
      await rtc.deleteRoomAttributes(this.room, this.shareScreenKeys) // 删除房间共享屏幕相关属性
      this.sendMeetingScreenStatusMsg(false) // 发送停止共享消息
      await this.refreshUserTracksStatus()
      // this.getRemoteAudioTracks()
      // this.getRemoteVideoTracks()
      // await this.getOnlineUserList()
      // this.getMeetingUserInfo()
    },
    async screenStatusChangedByRemoteFun(e) {
      const detail = e.detail
      // 收到远端视频共享开始或结束消息
      this.isRemoteShareScreen = detail.isShare
      this.shareScreenUserInfo = {
        shareScreenImUserId: detail.senderId,
        shareScreenFullName: detail.senderFullName,
        shareScreenUserName: detail.senderUserName
      }
      await this.remoteShareScreenChanged()
    },
    // 远端共享状态改变
    async remoteShareScreenChanged() {
      // isRemoteShareScreen，只需获取当前会议的用户各项信息
      if (this.isRemoteShareScreen) {
        this.getMeetingUserInfo()
      } else {
        // 刷新处理会议音视频信息和用户信息（用户绘制画面内容，播放用户音视频）
        // this.getRemoteAudioTracks()
        // this.getRemoteVideoTracks()
        // await this.getOnlineUserList()
        // this.getMeetingUserInfo()
        await this.refreshUserTracksStatus()
        this.remoteScreenVideoTrack = null
      }
      // 重置专家，现场的共享状态
      if (this.isExpert(this.userInfo)) {
        this.isShareCameraVideo = false
        this.isShareScreenVideo = false
      } else if (this.isStaff(this.userInfo)) {
        this.isShareScreenVideo = false
      }
    }
  }
}
