class BasePropertiesClass {
  constructor(properties) {
    this.title = properties.title
    this.referenceImage = properties.referenceImage
    this.guidanceVideo = properties.guidanceVideo
    this.text = properties.text
    this.mustUploadPhoto = properties.mustUploadPhoto
  }
}

export class ResultRecordTypePropertiesClass extends BasePropertiesClass {
  constructor(properties) {
    super(properties)
    this.mustUploadPhoto = properties.mustUploadPhoto
  }
}

const BasePatrolProperties = {
  title: '步骤名称',
  referenceImage: null,
  guidanceVideo: null,
  text: null,
  mustUploadPhoto: null
}

export const ResultRecordTypeProperties = {
  ...BasePatrolProperties
}

export const UniversalRecordTypeProperties = {
  ...BasePatrolProperties
}

export const ValueRecordTypeProperties = {
  ...BasePatrolProperties,
  dataName: null,
  minValue: null,
  maxValue: null,
  dataUnit: null
}

export const MaintenanceAidTypeProperties = {
  deviceName: null,
  deviceImg: null,
  deviceDesc: null,
  deviceLocation: null
}

export const MaintenanceRecordTypeProperties = {
  name: '维修记录'
}
