export const EventsEnum = {
  beKickedOutEvent: 'beKickedOutEvent',
  tokenInvalidEvent: 'tokenInvalidEvent',
  disconnectEvent: 'disconnectEvent',
  callSessionEvent: 'callSessionEvent',
  onStateReportEvent: 'onStateReportEvent',
  meetingExitEvent: 'meetingExitEvent',
  // 会议事件
  onUserJoinEvent: 'onUserJoinEvent',
  onUserLeaveEvent: 'onUserLeaveEvent',
  onTrackReadyEvent: 'onTrackReadyEvent',
  onTrackNotReadyEvent: 'onTrackNotReadyEvent',
  onTrackPublishEvent: 'onTrackPublishEvent',
  onTrackUnPublishEvent: 'onTrackUnPublishEvent',
  onAudioMuteChangeEvent: 'onAudioMuteChangeEvent',
  onVideoMuteChangeEvent: 'onVideoMuteChangeEvent',
  contactsRefreshEvent: 'contactsRefreshEvent',
  meetingRefreshEvent: 'meetingRefreshEvent',
  // 会议邀请
  meetingInviteEvent: 'meetingInviteEvent',
  meetingInviteAcceptEvent: 'meetingInviteAcceptEvent',
  meetingInviteRejectEvent: 'meetingInviteRejectEvent',
  meetingInviteCancelEvent: 'meetingInviteCancelEvent',
  meetingInviteTimeoutEvent: 'meetingInviteTimeoutEvent',
  meetingInviteNoResponseEvent: 'meetingInviteNoResponseEvent',
  meetingScreenStatusEvent: 'meetingScreenStatusEvent',
  meetingRecordingStatusEvent: 'meetingRecordingStatusEvent',
  meetingChatMessageEvent: 'meetingChatMessageEvent',
  sendChatMessageSuccessEvent: 'sendChatMessageSuccessEvent',
  sendChatMessageFailEvent: 'sendChatMessageFailEvent',
  // 设备状态
  sendMicMuteEvent: 'sendMicMuteEvent',
  sendCamMuteEvent: 'sendCamMuteEvent',
  meetingMicMuteEvent: 'meetingMicMuteEvent',
  meetingCamMuteEvent: 'meetingCamMuteEvent',
  meetingCamSwitchEvent: 'meetingCamSwitchEvent',
  meetingCamZoomEvent: 'meetingCamZoomEvent',
  meetingCamExposureEvent: 'meetingCamExposureEvent',
  meetingFlashlightEvent: 'meetingFlashlightEvent',
  meetingVideoConfigEvent: 'meetingVideoConfigEvent',
  meetingSwitchUvcCam: 'meetingSwitchUvcCam',
  meetingLaserEvent: 'meetingLaserEvent',
  meetingPaintEvent: 'meetingPaintEvent',
  meetingDeviceStatusEvent: 'meetingDeviceStatusEvent',
  meetingDeviceQueryStatusEvent: 'meetingDeviceQueryStatusEvent',
  meetingFocusGuidance: 'meetingFocusGuidance',
  meetingFreezeGuidance: 'meetingFreezeGuidance',
  // 临时用户入会监听时间
  meetingAlreadyEvent: 'meetingAlreadyEvent'
}

export default {
  EventsEnum
}
