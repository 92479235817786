<template>
  <div class="example-container">
    <div id="example-scanning-overlay" class="hidden">
      <div class="inner">
        <img src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/card.png">
        <div class="scanline" />
      </div>
    </div>

    <a-scene
      mindar-image="imageTargetSrc: https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.2/examples/image-tracking/assets/card-example/card.mind; showStats: false; uiScanning: #example-scanning-overlay;"
      embedded=""
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
      inspector=""
      keyboard-shortcuts=""
      screenshot=""
    >
      <a-assets>
        <img id="card" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/card.png">
        <img id="icon-web" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/web.png">
        <img id="icon-location" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/location.png">
        <img id="icon-profile" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/profile.png">
        <img id="icon-phone" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/phone.png">
        <img id="icon-email" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/email.png">
        <img id="icon-play" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/play.png">
        <img id="icon-left" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/left.png">
        <img id="icon-right" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/right.png">
        <img id="paintandquest-preview" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/paintandquest-preview.png">
        <video id="paintandquest-video-mp4" autoplay="false" loop="true" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/paintandquest.mp4" playsinline="" webkit-playsinline="" />
        <video id="paintandquest-video-webm" autoplay="false" loop="true" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/paintandquest.webm" playsinline="" webkit-playsinline="" />
        <img id="coffeemachine-preview" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/coffeemachine-preview.png">
        <img id="peak-preview" src="https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/peak-preview.png">

        <a-asset-item id="avatarModel" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.2/examples/image-tracking/assets/card-example/softmind/scene.gltf" />
      </a-assets>

      <a-camera position="0 0 0" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;" raycaster="far: 10000; objects: .clickable" camera="" rotation="" wasd-controls="" />

      <a-entity id="mytarget" mytarget="" mindar-image-target="targetIndex: 0">
        <a-plane src="#card" position="0 0 0" height="0.552" width="1" rotation="0 0 0" material="" geometry="" />

        <a-entity id="portfolio-panel" visible="false" position="0 0 -0.01">
          <a-text value="Portfolio" color="black" align="center" width="2" position="0 0.4 0" text="" />
          <a-entity id="portfolio-item0">
            <a-video id="paintandquest-video-link" webkit-playsinline="" playsinline="" width="1" height="0.552" position="0 0 0" material="" geometry="" />
            <a-image id="paintandquest-preview-button" class="clickable" src="#paintandquest-preview" alpha-test="0.5" position="0 0 0" height="0.552" width="1" material="" geometry="" />
          </a-entity>
          <a-entity id="portfolio-item1" visible="false">
            <a-image class="clickable" src="#coffeemachine-preview" alpha-test="0.5" position="0 0 0" height="0.552" width="1" material="" geometry="" />
          </a-entity>
          <a-entity id="portfolio-item2" visible="false">
            <a-image class="clickable" src="#peak-preview" alpha-test="0.5" position="0 0 0" height="0.552" width="1" material="" geometry="" />
          </a-entity>

          <a-image id="portfolio-left-button" visible="false" class="clickable" src="#icon-left" position="-0.7 0 0" height="0.15" width="0.15" material="" geometry="" />
          <a-image id="portfolio-right-button" visible="false" class="clickable" src="#icon-right" position="0.7 0 0" height="0.15" width="0.15" material="" geometry="" />
        </a-entity>

        <a-image id="profile-button" visible="false" class="clickable" src="#icon-profile" position="-0.42 -0.5 0" height="0.15" width="0.15" animation="property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate" material="" geometry="" />

        <a-image
          id="web-button"
          visible="false"
          class="clickable"
          src="#icon-web"
          alpha-test="0.5"
          position="-0.14 -0.5 0"
          height="0.15"
          width="0.15"
          animation="property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
          material=""
          geometry=""
        />

        <a-image id="email-button" visible="false" class="clickable" src="#icon-email" position="0.14 -0.5 0" height="0.15" width="0.15" animation="property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate" material="" geometry="" />

        <a-image id="location-button" visible="false" class="clickable" src="#icon-location" position="0.42 -0.5 0" height="0.15" width="0.15" animation="property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate" material="" geometry="" />

        <a-gltf-model id="avatar" rotation="0 0 0" position="0 -0.25 0" scale="0.004 0.004 0.004" src="#avatarModel" gltf-model="" />

        <a-text id="text" class="clickable" value="" color="black" align="center" width="2" position="0 -1 0" geometry="primitive:plane; height: 0.1; width: 2;" material="opacity: 0.5" text="" />
      </a-entity>
      <canvas class="a-canvas" data-aframe-canvas="true" data-engine="three.js r147" width="1208" height="1508" />
      <div class="a-loader-title" style="display: none;" />
      <a-entity light="" data-aframe-default-light="" aframe-injected="" />
      <a-entity light="" position="" data-aframe-default-light="" aframe-injected="" />
    </a-scene>
    <video autoplay="" muted="" playsinline="" style="position: absolute; top: 0px; left: -200.667px; z-index: -2; width: 1005.33px; height: 754px;" width="640" height="480" />
  </div>
</template>

<script>
export default {
  name: 'Interactive'
}
</script>

<style scoped lang="scss">
.example-container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;

  #example-scanning-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    z-index: 2;
  }

  #example-scanning-overlay.hidden {
    display: none;
  }

  #example-scanning-overlay .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background:
      linear-gradient(to right, white 10px, transparent 10px) 0 0,
      linear-gradient(to right, white 10px, transparent 10px) 0 100%,
      linear-gradient(to left, white 10px, transparent 10px) 100% 0,
      linear-gradient(to left, white 10px, transparent 10px) 100% 100%,
      linear-gradient(to bottom, white 10px, transparent 10px) 0 0,
      linear-gradient(to bottom, white 10px, transparent 10px) 100% 0,
      linear-gradient(to top, white 10px, transparent 10px) 0 100%,
      linear-gradient(to top, white 10px, transparent 10px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 40px 40px;
  }

  @media (min-aspect-ratio: 1 / 1){
    #example-scanning-overlay .inner {
      width: 50vh;
      height: 50vh;
    }

  }

  #example-scanning-overlay img {
    opacity: 0.6;
    width: 90%;
    align-self: center;
  }

  #example-scanning-overlay .inner .scanline {
    position: absolute;
    width: 100%;
    height: 10px;
    animation: move 2s linear infinite;
  }
}
</style>
