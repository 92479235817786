var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      {
        staticClass: "app-main",
        class: [{ "show-bottom-app-main": _vm.showBottom }],
      },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            _c(
              "keep-alive",
              { attrs: { include: _vm.cachedViews } },
              [_c("router-view", { key: _vm.key })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showBottom
      ? _c(
          "div",
          { staticClass: "bottom-menu" },
          [
            _c(
              "router-link",
              {
                staticClass: "menu-item",
                class: { active: _vm.activeTab === "mobileHomePage" },
                attrs: { to: "/mobileHomePage" },
                on: {
                  click: function ($event) {
                    return _vm.goToView("mobileHomePage")
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-s-home bottom-icon" }),
                _vm._v(" "),
                _c("span", [_vm._v("首页")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "menu-item",
                class: { active: _vm.activeTab === "mobileProfilePage" },
                attrs: { to: "/mobileProfilePage" },
                on: {
                  click: function ($event) {
                    return _vm.goToView("mobileProfilePage")
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-s-custom bottom-icon" }),
                _vm._v(" "),
                _c("span", [_vm._v("我的")]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }