<template>
  <div class="config-container">
    <el-form ref="configForm" class="left-container" :model="configForm" :rules="configFormRules">
      <el-form-item label="步骤名称：" prop="title">
        <el-input
          v-model="configForm.title"
          placeholder="请输入步骤名称"
          maxlength="15"
          show-word-limit
          @focus="focusedItem('title')"
        />
      </el-form-item>
      <el-form-item label="参考图片：" prop="referenceImage" label-width="85px">
        <dw-image
          v-if="configForm.referenceImage"
          :url="configForm.referenceImage"
          @delete-img="deleteReferenceImage"
        />
        <ht-upload-button
          v-else
          :file-type="['img']"
          key-prefix="workflow"
          select-img
          @focused="focusedItem('referenceImage')"
          @on-file-uploaded="onImageUploaded"
        />
      </el-form-item>
      <el-form-item label="检查要求：" prop="text">
        <el-input
          v-model="configForm.text"
          placeholder="请输入检查要求"
          type="textarea"
          :rows="6"
          :maxlength="150"
          show-word-limit
          @focus="focusedItem('text')"
          @change="textChange"
        />
      </el-form-item>
      <el-form-item label="数据名称：" prop="dataName">
        <el-input
          v-model="configForm.dataName"
          placeholder="请输入数据名称"
          maxlength="5"
          show-word-limit
          @focus="focusedItem('dataName')"
        />
      </el-form-item>
      <el-form-item class="has-sub-item" label="参考范围：" prop="minValue">
        <div class="value-container">
          <el-form-item prop="minValue">
            <el-input-number
              v-model="configForm.minValue"
              class="value-input"
              label="参考下限"
              @change="changeValue('minValue')"
              @focus="focusedItem('minValue')"
            />
          </el-form-item>
          <div class="divider">至</div>
          <el-form-item prop="maxValue">
            <el-input-number
              v-model="configForm.maxValue"
              class="value-input"
              label="参考上限"
              @change="changeValue('maxValue')"
              @focus="focusedItem('maxValue')"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="数据单位：" prop="dataUnit">
        <el-input
          v-model="configForm.dataUnit"
          placeholder="请输入数据单位"
          maxlength="3"
          show-word-limit
          @focus="focusedItem('dataUnit')"
        />
      </el-form-item>
      <!--      <el-form-item prop="mustUploadPhoto">-->
      <!--        <el-checkbox v-model="configForm.mustUploadPhoto">必须拍摄照片</el-checkbox>-->
      <!--      </el-form-item>-->
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Config',
  props: {
    index: {
      type: Number,
      default: 0
    },
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          guidanceVideo: null,
          referenceImage: null,
          text: null,
          mustUploadPhoto: null
        }
      }
    },
    configRules: {
      type: Object,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      configForm: this.properties,
      configFormRules: this.configRules
    }
  },
  mounted() {
    this.configFormRules.minValue[1].validator = this.validateMinMax
  },
  methods: {
    focusedItem(name) {
      this.$emit('focused-item', name)
    },
    changeValue(name) {
      this.$refs.configForm.validateField('minValue')
      this.focusedItem(name)
    },
    onImageUploaded(context, url) {
      this.configForm.referenceImage = url
    },
    deleteReferenceImage() {
      this.configForm.referenceImage = null
    },
    textChange() {
      this.configForm.guidanceVideo = this.configForm.text
    },
    validateMinMax(rule, value, callback) {
      const minValue = parseFloat(this.configForm.minValue)
      const maxValue = parseFloat(this.configForm.maxValue)

      if (minValue !== null && maxValue !== null && minValue >= maxValue) {
        callback(new Error('下限必须小于上限'))
      } else {
        callback()
      }
    },
    onSaveStep() {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          this.$emit('save-step')
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    onDelStep() {
      this.$emit('del-step', this.index)
    }
  }
}
</script>

<style scoped lang="scss">
.config-container{
  padding: 20px 10px 30px;
  max-height: 700px;
  overflow-y: auto;
  position: relative;
  .right-container{
    text-align: center;
    height: 30px;
  }

  .has-sub-item{
    ::v-deep.el-form-item__error{
      display: none;
    }
  }

  .value-container{
    width: 100%;
    display: flex;
    .divider{
      flex: 1;
      text-align: center;
    }
    .el-form-item{
      display: flex;
      ::v-deep.el-form-item__content{
        flex: 1;
      }
      ::v-deep.el-form-item__error{
        display: inline;
      }

      ::v-deep .el-input-number{
        width: 120px;
        .el-input__inner{
          padding: 0 30px !important;
        }
      }
    }
  }
}
</style>
