export const NskMaterialMgrListTitle = [
  { label: '品番', prop: 'code', width: 100 },
  { label: '品名', prop: 'name', width: 100 },
  { label: '图番', prop: 'illustrationNumber', width: 100 },
  { label: '部品图', prop: 'illustrationImg', width: 100 },
  { label: '供应商', prop: 'supplierName', width: 100 },
  { label: '车型', prop: 'vehicleType', width: 100 },
  { label: '关联检查模版', prop: 'nskTemplateName', width: 150 }
]

export const NskCheckTemplateListTitle = [
  { label: '表番', prop: 'code', width: 100 },
  { label: '表名', prop: 'name', width: 100 },
  { label: '关联部品', prop: 'nskMaterialName', width: 100 }
]

export const NskCheckTemplateStepTitle = [
  { label: '检查类型', prop: 'specType', width: 70, isRequired: true },
  { label: '特性', prop: 'character', width: 80, isRequired: true },
  { label: '规格说明', prop: 'specDesc', width: 120 },
  { label: '下公差-标准尺寸-上公差', prop: 'nskCheckTemplateCode', width: 150, hasMultiple: true },
  { label: '量具', prop: 'measuringTools', width: 100, isRequired: true },
  { label: '等级', prop: 'level', width: 60, isRequired: true }
]

export const NskCheckTemplateAddRevisionHistoryTitle = [
  { label: '变更理由', prop: 'changeReason', width: 200, isRequired: true },
  { label: '承认者', prop: 'approver', width: 80 }
]

export const NskCheckTemplateRevisionHistoryTitle = [
  { label: '变更理由', prop: 'changeReason', width: 200 },
  { label: '变更者', prop: 'changer.fullName', width: 80 },
  { label: '承认者', prop: 'approver', width: 80 },
  { label: '变更时间', prop: 'createdAt', width: 100 }
]

export const NskCheckListMgrListTitle = [
  { label: '品番', prop: 'nskMaterialCode', width: 100 },
  { label: '品名', prop: 'nskMaterialName', width: 100 },
  { label: '图番', prop: 'nskMaterialIllustrationNumber', width: 100 },
  { label: '车型', prop: 'vehicleType', width: 100 },
  { label: '供应商', prop: 'supplierName', width: 100 },
  { label: '来料日', prop: 'receivingAt', width: 120 },
  { label: '检查日', prop: 'checkAt', width: 120 },
  { label: '数量', prop: 'quantity', width: 100 },
  { label: '批次', prop: 'batchNum', width: 100 },
  { label: '模号', prop: 'moduleNumber', width: 100 },
  { label: '检查员', prop: 'staff.fullName', width: 100 },
  { label: '检查开始时间', prop: 'startAt', width: 160 },
  { label: '检查结束时间', prop: 'finishAt', width: 160 },
  { label: '备注', prop: 'remark', width: 100 },
  { label: '检查状态', prop: 'status', fixed: 'right', width: 100 },
  { label: '判定结果', prop: 'abnormalCount', fixed: 'right', width: 100 }
]

export const NskCheckListViewCheckItemTitle = [
  { label: '特性', prop: 'character', width: 100 },
  { label: '规格说明', prop: 'specDesc', width: 100 },
  { label: '尺寸公差', prop: 'toleranceDesc', width: 100 },
  { label: '量具', prop: 'measuringTools', width: 100 },
  { label: '等级', prop: 'level', width: 50 },
  { label: '检查结果', prop: 'result.measureResult', width: 120 }
]
