var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { margin: "20px", "min-width": "600px" } },
    [
      _c("dw-title", { attrs: { title: "修改logo" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "login-config" },
        [
          _c(
            "el-form",
            {
              ref: "configForm",
              attrs: {
                model: _vm.configForm,
                rules: _vm.configRules,
                "label-width": "80px",
              },
            },
            [
              _c("el-row", { attrs: { gutter: 20 } }),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "系统logo:",
                        "label-width": "150px",
                        prop: "logoUrl",
                      },
                    },
                    [
                      _vm.configForm.logoUrl
                        ? _c("dw-image", {
                            attrs: { url: _vm.configForm.logoUrl },
                            on: { "delete-img": _vm.deleteLogo },
                          })
                        : _c(
                            "div",
                            [
                              _c("ht-upload-button", {
                                staticClass: "upload-button",
                                attrs: {
                                  "button-icon": null,
                                  "file-type": ["ico"],
                                  "fixed-file-name": "",
                                  "file-name": "favicon.ico",
                                  "key-prefix": "logo_config/logo",
                                  "select-img": "",
                                },
                                on: { "on-file-uploaded": _vm.onLogoUploaded },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "tip" }, [
                                _vm._v("（名称：favicon.ico）"),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "登陆页背景:",
                        "label-width": "150px",
                        prop: "backgroundImgUrl",
                      },
                    },
                    [
                      _vm.configForm.backgroundImgUrl
                        ? _c("dw-image", {
                            attrs: { url: _vm.configForm.backgroundImgUrl },
                            on: { "delete-img": _vm.deleteBackground },
                          })
                        : _c(
                            "div",
                            [
                              _c("ht-upload-button", {
                                staticClass: "upload-button",
                                attrs: {
                                  "button-icon": null,
                                  "file-type": ["img"],
                                  "fixed-file-name": "",
                                  "file-name": "login_background.jpg",
                                  "key-prefix": "logo_config/login",
                                  "select-img": "",
                                },
                                on: {
                                  "on-file-uploaded": _vm.onBackgroundUploaded,
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "tip" }, [
                                _vm._v("（名称：login_background.jpg）"),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }