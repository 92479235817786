var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.tableTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                fixed: _vm.isMobile ? false : item.fixed,
                label: item.label,
                "min-width": item.width || 100,
                prop: item.prop,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "工单类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTaskTempType(row[item.prop])
                                    ? _vm.getTaskTempType(row[item.prop]).value
                                    : ""
                                )
                              ),
                            ])
                          : item.label === "作业类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTowerWorkOrderType(row[item.prop])
                                    ? _vm.getTowerWorkOrderType(row[item.prop])
                                        .value
                                    : "-"
                                )
                              ),
                            ])
                          : item.label === "巡检工程"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  (row.targetInfo &&
                                    row.targetInfo.projectName) ||
                                    "-"
                                )
                              ),
                            ])
                          : item.label === "巡检设备"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.targetName
                                    ? row.targetName + "-" + row.targetCode
                                    : "-"
                                )
                              ),
                            ])
                          : item.label === "创建人"
                          ? _c("span", [_vm._v(_vm._s(row.staff.fullName))])
                          : item.label === "执行人"
                          ? _c("span", [_vm._v(_vm._s(row.executor.fullName))])
                          : item.label === "循环周期"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.LoopEnum[row[item.prop]])),
                            ])
                          : item.label === "异常项"
                          ? _c(
                              "span",
                              { style: { color: row[item.prop] ? "red" : "" } },
                              [_vm._v(_vm._s(row[item.prop] + "项"))]
                            )
                          : item.label === "状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.TaskWorkOrderStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.TaskWorkOrderStatusEnum[
                                      row[item.prop]
                                    ] || "-"
                                  )
                                ),
                              ]
                            )
                          : item.label === "工单执行状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.TaskWorkOrderStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.TaskWorkOrderStatusEnum[
                                      row[item.prop]
                                    ] || "-"
                                  )
                                ),
                              ]
                            )
                          : item.label === "文件上传状态" && row[item.prop]
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "status",
                                attrs: {
                                  type: _vm.FileUploadStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.FileUploadStatusEnum[row[item.prop]]
                                  )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 100 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { icon: "el-icon-view", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onView(row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }