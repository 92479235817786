<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container"
    :style="{height: height + 'px'}"
    :class="{'is-used-step-container': !isEdit}"
    title="hf_use_description"
  >
    <div class="view-container">
      <top-section
        ref="topSection"
        :is-focused-item="isFocusedItem"
        :current-index="currentIndex"
        :count="count"
        :title="properties.title"
        :show-scan="showScan"
        :show-more-function="showMoreFunction"
        :must-upload-photo="properties.mustUploadPhoto"
        :upload-photos="submitResults.uploadPhotos"
        :active-img-index="activeImgIndex"
        :button-disabled="buttonDisabled"
        :show-photos="!showMiddlePhotos"
        @more-function="moreFunction"
        @native-play-tts="nativePlayTTS(ttsText)"
        @to-native-scan-code="toNativeScanCode"
        @to-remote-guidance="toRemoteGuidance"
        @to-ai-qa-assistant="toAiQaAssistant"
        @native-take-photo="toTakePhoto"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @set-active-item="setActiveItem"
        @jump-step="jumpStep"
      />
      <middle-section
        ref="middleSection"
        :is-focused-item="isFocusedItem"
        :active-name="activeName"
        :reference-image="properties.referenceImage"
        :text="properties.text"
        :fact-desc="submitResults.factDesc"
        :button-disabled="buttonDisabled"
        :height="getMiddleHeight"
        :show-photos="showMiddlePhotos"
        :must-upload-photo="properties.mustUploadPhoto"
        :active-img-index="activeImgIndex"
        :upload-photos="submitResults.uploadPhotos"
        @select-reference-image="selectReferenceImage"
        @select-text="selectText"
        @select-photos="selectPhotos"
        @set-active-item="setActiveItem"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @input-desc="inputDesc"
        @clear-fact-desc="clearFactDesc"
        @open-reference-image="openReferenceImage"
        @close-reference-viewer="closeReferenceViewer"
      />
      <bottom-section
        ref="bottomSection"
        :count="count"
        :current-index="currentIndex"
        :is-last-step="isLastStep"
        :button-disabled="buttonDisabled"
        @to-prev="toPrev()"
        @to-next="toNext()"
        @save-record="saveRecord()"
        @submit-record="submitRecord()"
      />
    </div>
    <!--    <div v-if="isEdit" class="edit-overlay" />-->
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import TopSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/topSection'
import MiddleSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/middleSection'
import BottomSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/bottomSection'
import Triangle from '@/assets/image/workflow/triangle.png'
import Start from '@/assets/image/workflow/start.png'

export default {
  name: 'StepUi',
  components: { TopSection, MiddleSection, BottomSection },
  mixins: [StepUiMixin],
  props: {
    stepName: {
      type: String,
      default: ''
    },
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          referenceImage: null,
          guidanceVideo: null,
          text: null,
          mustUploadPhoto: null
        }
      }
    },
    results: {
      type: Object,
      default() {
        return {
          latitude: null,
          longitude: null,
          location: null,
          uploadPhotos: [],
          factDesc: null
        }
      }
    }
  },
  data() {
    return {
      title: '组件',
      showScan: process.env.VUE_APP_CONFIG_HAS_SCAN_CODE === 'true',
      Triangle, Start
    }
  },
  created() {
    const firstText = `步骤${this.currentIndex + 1}：${this.properties.title}，`
    const secondText = this.properties.guidanceVideo ? `${this.properties.guidanceVideo}，` : ''
    this.ttsText = firstText + secondText
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
</style>
