const DevicesAndAppsRouter = {
  path: '/devicesAndApps',
  component: () => import('@/layout'),
  name: 'devicesAndAppsMenu',
  redirect: '/devicesAndApps/boundDevices',
  meta: { title: '终端设备管理', icon: 'equipment_mgr' },
  children: [
    {
      path: '/devicesAndApps/boundDevices',
      component: () => import('@/views/devicesAndApps/list'),
      hidden: true,
      name: 'devicesAndAppsMgrList',
      meta: { title: '终端设备管理', breadcrumb: false, activeMenu: '/devicesAndApps' }
    }
  ]
}

export default DevicesAndAppsRouter
