<template>
  <div class="work-order-container" :class="[`${FileUploadStatusStyleEnum[orderInfo.fileUploadStatus]}-card`,{ 'is-card-container': isCard}]">
    <div class="top-section" @click="onView">
      <div
        v-for="(item,index) in MobilePatrolWorkOrderTitle"
        :key="index"
        class="item"
        :class="[{'bold-item': item.isBold || false,'large-item': item.isLarge || false, 'card-item': isCard}]"
      >
        <template v-if="item.label === '工程'"><span class="name">{{ `${item.label}：` }}</span>{{ `${orderInfo.targetInfo && orderInfo.targetInfo.projectName || '-'}` }}</template>
        <template v-else-if="item.label === '设备'"><span class="name">{{ `${item.label}：` }}</span>{{ `${orderInfo.targetName ? `${orderInfo.targetName}-${orderInfo.targetCode}`: '-'}` }}</template>
        <div v-else-if="item.label === '作业类型'" :style="{color: getTowerWorkOrderType(orderInfo.towerWorkType).color}">
          <span class="name">{{ `${item.label}：` }}</span>
          {{ `${getTowerWorkOrderType(orderInfo.towerWorkType) ? getTowerWorkOrderType(orderInfo.towerWorkType).value : '-'}` }}
        </div>
        <div v-else-if="item.label === '工单执行状态'">
          <span class="name">{{ `${item.label}：` }}</span>
          <el-tag class="status" :type="TaskWorkOrderStatusStyleEnum[orderInfo.status]" effect="dark">{{ TaskWorkOrderStatusEnum[orderInfo.status] }}</el-tag>
        </div>
        <div v-else-if="item.label === '文件上传状态'" class="last-item">
          <div>
            <span class="name">{{ `${item.label}：` }}</span>
            <el-tag class="status" :type="FileUploadStatusStyleEnum[orderInfo.fileUploadStatus]" effect="dark">{{ FileUploadStatusEnum[orderInfo.fileUploadStatus] }}</el-tag>
          </div>
          <!--          <span v-if="isCard" class="button">补充文件></span>-->
        </div>
        <template v-else><span class="name">{{ `${item.label}：` }}</span>{{ `${orderInfo[item.prop] || '-'}` }}</template>
      </div>
    </div>
    <div v-if="isCard" class="operation-container">
      <el-button type="danger" round @click="onDel">删除</el-button>
      <el-button type="primary" round @click="onView">补充文件</el-button>
    </div>
    <template v-if="!isCard">
      <div class="bottom-section">
        <div v-for="(item,index) in orderInfo.uploadFiles" :key="index" class="upload-File-item">
          <div style="margin-bottom: 10px">
            <span class="name">{{ `${item.name}：` }}</span>
            <ht-upload-button
              :file-type="['img']"
              key-prefix="workOrder"
              button-title="上传文件"
              button-type="primary"
              :show-success-tip="false"
              @on-file-uploaded="onUploaded(index, arguments)"
            />
          </div>
          <div class="file-list-container">
            <dw-file
              v-for="(fileItem, fileIndex) in item.files"
              :key="fileIndex"
              :url="fileItem.fileUrl"
              :name="fileItem.fileName"
              :class="[{'is-last': fileIndex === item.files.length - 1 }, 'file-item']"
              show-del
              show-card
              :need-margin="false"
              @delete-file="deleteFile(index,fileIndex)"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { isCanPreview } from '@/utils'
import { getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import {
  TaskWorkOrderStatusStyleEnum,
  TaskWorkOrderStatusEnum,
  FileListTitleEnum,
  FileUploadStatusEnum, FileUploadStatusStyleEnum
} from '@/views/taskMgr/enum'
import { MobilePatrolWorkOrderTitle } from '@/views/taskMgr/tableTitle'

export default {
  name: 'WorkOrderCard',
  props: {
    orderInfo: { type: Object, default() { return {} } },
    isCard: { type: Boolean, default: true }
  },
  data() {
    return {
      TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum,
      FileUploadStatusEnum, FileUploadStatusStyleEnum,
      MobilePatrolWorkOrderTitle, FileListTitleEnum
    }
  },
  methods: {
    getTowerWorkOrderType, isCanPreview,
    onDel() {
      this.$emit('on-del', this.orderInfo)
    },
    onView() {
      this.$emit('on-view', this.orderInfo)
    },
    getFileListTitle(towerWorkType) {
      return this.FileListTitleEnum.filter(item => item.isShow === towerWorkType)
    },
    deleteFile(index, fileIndex) {
      this.$emit('on-delete-file', index, fileIndex)
    },
    onUploaded(index, $arguments) {
      this.$emit('on-upload-file', index, $arguments[1], $arguments[2])
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.work-order-container{
  padding: 20px;
  font-size: 0.9rem;
  .top-section{
    .item{
      display: block;
      color: #666666;
      margin-bottom: 10px;
    }
    .card-item{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bold-item{
      font-weight: bold;
    }
    .large-item{
      font-size: 1.1rem;
      margin-bottom: 10px;
    }
    .name{
      font-weight: bold;
      color: #666666;
    }
    .status{
      margin-right: 10px;
      font-size: 0.9rem;
    }
  }
  .bottom-section{
    .upload-File-item{
      .name{
        color: #666666;
        font-weight: bold;
      }
      .file-list-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 5%;
      }
      .file-item{
        flex-basis: 30%;
        margin-bottom: 10px;
      }
      ::v-deep .el-button{
        font-size: 0.9rem;
      }
    }
  }
}

.is-card-container{
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
  .last-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    div{
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    .button{
      font-size: 1rem;
      color: $primaryColor;
    }
  }
}

.operation-container{
  display: flex;
  justify-content: space-between;
  .el-button{
    flex: 1;
    font-size: 1rem;
    font-weight: bold;
  }
}

.is-card-container{
  background: #f0f9eb;
}

.is-card-container.info-card {
  background: #f4f4f5;
}

.is-card-container.warning-card{
  background: #fdf6ec;
}

.is-card-container.success-card{
  background: #f0f9eb;
}

.is-card-container.danger-card{
  background: #fef0f0;
}

.is-card-container.primary-card{
  background: $primaryColorLight;
}
</style>
