var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-container",
      class: {
        "is-red-button": _vm.isRed,
        "is-red-clicked": _vm.isRedClick,
        "is-white-background-button": _vm.isWhiteBackground,
        "is-disabled": _vm.isDisabled,
      },
      on: { click: _vm.clickButton },
    },
    [
      _c("img", { staticClass: "icon", attrs: { src: _vm.icon, alt: "" } }),
      _vm._v(" "),
      _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }