var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClearSearch } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "会议ID" },
                model: {
                  value: _vm.listQuery.roomId,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "roomId", $$v)
                  },
                  expression: "listQuery.roomId",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "发起人" },
                model: {
                  value: _vm.listQuery.staffName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "staffName", $$v)
                  },
                  expression: "listQuery.staffName",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd",
                  placement: "bottom-start",
                  "start-placeholder": "创建时间",
                  "on-search": "",
                  "end-placeholder": "创建时间",
                },
                model: {
                  value: _vm.createdAt,
                  callback: function ($$v) {
                    _vm.createdAt = $$v
                  },
                  expression: "createdAt",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "会议状态" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                _vm._l(_vm.statusEnum, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.value, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
          on: { "row-click": _vm.handleRowClick },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.labelToProps, function (i) {
            return _c("ht-table-column", {
              key: i.prop,
              attrs: {
                label: i.label,
                prop: i.prop,
                "min-width": i.width || 100,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        i.label === "发起人"
                          ? _c("span", [_vm._v(_vm._s(row.staff.fullName))])
                          : i.label === "会议时常"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.status.name === "FINISHED"
                                    ? row.duration || "-"
                                    : "-"
                                )
                              ),
                            ])
                          : i.label === "会议状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    _vm.getStatus(row.status.name) &&
                                    _vm.getStatus(row.status.name).style,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      (_vm.getStatus(row.status.name) &&
                                        _vm.getStatus(row.status.name).value) ||
                                        "-"
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row[i.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 120 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status.name === "FINISHED"
                      ? _c(
                          "ht-button",
                          {
                            attrs: { type: "text", icon: "el-icon-view" },
                            on: {
                              click: function ($event) {
                                return _vm.onView(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        )
                      : _vm.userInfo &&
                        (_vm.userInfo.roles.includes("ADMIN") ||
                          _vm.userInfo.roles.includes("EXPERT"))
                      ? _c(
                          "ht-button",
                          {
                            attrs: {
                              disabled: row.status.name === "CREATING",
                              type: "text",
                              icon: "el-icon-plus",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.joinRoom(row)
                              },
                            },
                          },
                          [_vm._v("加入会议")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _vm.showMeetingDialog
        ? _c("meeting-dialog", {
            ref: "meetingDialogRef",
            on: { "close-dialog": _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }