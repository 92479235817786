export const MimeTypeMap = {
  'image/x-icon': 'ico',
  'mage/vnd.microsoft.icon': 'ico',
  'image/jpeg': 'img',
  'image/x-png': 'img',
  'image/x-ms-bmp': 'img',
  'application/msword': 'docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'text/plain': 'txt',
  'text/csv': 'csv',
  'application/zip': 'zip',
  'application/pdf': 'pdf'
}
