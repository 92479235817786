var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ht-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      ref: "list",
      attrs: { data: _vm.fileList },
    },
    [
      _c("ht-table-column", {
        attrs: { label: "序号", type: "index", fixed: "left" },
      }),
      _vm._v(" "),
      _vm._l(_vm.InstallationRemoveUploadFilesTitle, function (item) {
        return _c("ht-table-column", {
          key: item.prop,
          attrs: {
            label: item.label,
            prop: item.prop,
            "min-width": item.width || 100,
            fixed: item.fixed,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    item.label === "文件"
                      ? [
                          row[item.prop].length > 0
                            ? _vm._l(
                                row[item.prop],
                                function (fileItem, fileIndex) {
                                  return _c("dw-file", {
                                    key: fileIndex,
                                    class: {
                                      "is-not-last":
                                        fileIndex !== row[item.prop].length - 1,
                                    },
                                    attrs: {
                                      url: fileItem.fileUrl,
                                      name: fileItem.fileName,
                                      "show-del": "",
                                      "show-download": "",
                                      "show-text-del": "",
                                      "show-view": "",
                                    },
                                    on: {
                                      "delete-file": function ($event) {
                                        return _vm.onDeleteFile(
                                          $index,
                                          fileIndex
                                        )
                                      },
                                      "view-file": _vm.downloadFile,
                                    },
                                  })
                                }
                              )
                            : _c("span", [_vm._v(_vm._s("-"))]),
                        ]
                      : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                  ]
                },
              },
            ],
            null,
            true
          ),
        })
      }),
      _vm._v(" "),
      _c("ht-table-column-operation", {
        attrs: { width: "300", fixed: "right" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              var $index = ref.$index
              return [
                _c("ht-upload-button", {
                  attrs: {
                    "button-type": "text",
                    "button-icon": "el-icon-upload2",
                    "file-type": ["doc", "pdf", "img"],
                    "key-prefix": "workOrder",
                    "button-title": "上传",
                    "show-success-tip": false,
                  },
                  on: {
                    "on-file-uploaded": function ($event) {
                      return _vm.onFileUploaded($index, arguments)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }