<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container"
    :style="{height: height + 'px'}"
    :class="{'is-used-step-container': !isEdit}"
    title="hf_use_description"
  >
    <div class="view-container">
      <top-section
        ref="topSection"
        :is-focused-item="isFocusedItem"
        :current-index="currentIndex"
        :count="count"
        :title="properties.title"
        :show-photo-button="false"
        :show-more-function="false"
        :show-jump="false"
        :show-photos="false"
        :show-scan="false"
      >
        <el-button slot="other-button" class="circle-button photo-button" type="text" :disabled="buttonDisabled" @click="nativeScanCode">开始扫码</el-button>
      </top-section>
      <div :style="{height: getMiddleHeight + 'px'}" class="middle-container">
        <img v-if="isEdit || !submitResults.carFrameNum" class="scan-code-img" :src="ScanCode" alt="">
        <div v-if="submitResults.carFrameNum" class="result-container">
          <el-form ref="deviceForm" label-width="auto" label-position="left">
            <el-form-item label="车架号：">
              <el-input ref="value" v-model="submitResults.carFrameNum" disabled class="value-input" placeholder="车架号" />
            </el-form-item>
            <el-form-item label="名号：">
              <el-input ref="value" v-model="submitResults.nskMaterialName" disabled class="value-input" placeholder="名号" />
            </el-form-item>
            <el-form-item label="批次：">
              <el-input ref="value" v-model="submitResults.batchNum" disabled class="value-input" placeholder="批次" />
            </el-form-item>
            <el-form-item label="检查日：">
              <el-input ref="value" v-model="submitResults.checkAt" disabled class="value-input" placeholder="检查日" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <bottom-section
        ref="bottomSection"
        :count="count"
        :current-index="currentIndex"
        :is-last-step="isLastStep"
        :button-disabled="buttonDisabled"
        @to-prev="toPrev()"
        @to-next="toNext()"
        @save-record="saveRecord()"
        @submit-record="submitRecord()"
      />
    </div>
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import BottomSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/bottomSection'
import ScanCode from '@/assets/image/common/scan_code.png'
import { parseTime } from '@/utils'
import TopSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/topSection'

export default {
  name: 'StepUi',
  components: { TopSection, BottomSection },
  mixins: [StepUiMixin],
  props: {
    properties: { type: Object, default() { return {} } },
    results: { type: Object, default() { return {} } }
  },
  data() {
    return {
      ScanCode,
      dictationItem: null
    }
  },
  computed: {
    getMiddleHeight() {
      return this.height - 120
    }
  },
  created() {
    const firstText = `步骤${this.currentIndex + 1}：${this.properties.title}，`
    const secondText = this.properties.guidanceVideo ? `${this.properties.guidanceVideo}，` : ''
    this.ttsText = firstText + secondText
    if (!this.submitResults.carFrameNum) {
      setTimeout(() => {
        this.nativeScanCode()
      }, 2000)
    }
  },
  methods: {
    setScanCodeResult() {
      this.submitResults.carFrameNum = 'LB36662S6JL108666'
      this.submitResults.nskMaterialName = '车架'
      this.submitResults.batchNum = '000001'
      this.submitResults.checkAt = parseTime(new Date(), '{y}-{m}-{d}')
      this.nativePlayTTS('请确认扫码信息')
    },
    inputText(value) {
      this.dictationItem = value
      this.nativeStartDictation()
      this.showMoreFunction = false
    },
    inputValue() {
      this.$refs.value.focus()
    },
    openInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = true
    },
    closeInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = false
    },
    toNext() {
      if (this.results.carFrameNum) {
        this.$emit('to-next', this.submitResults)
        this.showMoreFunction = false
      } else {
        this.nativePlayTTS('请扫码')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.step-ui-detail-container{
  .view-container{
   .middle-container{
     display: flex;
     align-items: center;
     justify-content: center;
     .result-container{
      .el-form{
        ::v-deep .el-form-item__label{
          color: #666666;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 40px;
        }
        .value-span{
          flex: 1;
          margin-right: 10px;
          height: 40px;
          line-height: 40px;
          font-size: 1.2rem;
        }
        .value-input{

          flex: 1;
          margin-right: 10px;
          ::v-deep  .el-input__inner {
            height: 40px;
            line-height: 40px;
            font-size: 1.2rem;
            color: #666666;
          }
        }
      }
     }
   }
    .bottom-section{
      .top-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        .top-left-container,.top-right-container{
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .top-left-container{
          margin-right: 10px;
        }
        .result-title{
          color: #000000;
        }
        .value-input{
          flex: 1;
          margin-right: 10px;

          ::v-deep  .el-input__inner {
            height: 40px;
            line-height: 40px;
            font-size: 1.2rem;
            color: #000000;
          }
        }

        .result-reference-container{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.0rem;
          .third-title{
            font-size: 1.3rem;
          }
          .value-item{
            display: inline-block;
            min-width: 50px;
            min-height: 23px;
            text-align: center;
            font-size: 1.3rem;
            font-weight: bold;
          }
        }

        .result-name, .result-unit {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
