<template>
  <div class="title-box">
    <div class="title-span"><span /></div>
    <div class="title-content" :style="titleStyle">{{ title }}</div>
    <div class="title-operation-box">
      <slot class="title-operation" name="statistic" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DwChartTitle',
  props: {
    title: { type: String, default: '' },
    titleStyle: { type: Object, default() { return { width: '280px' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.title-box {
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .title-span {
    height: 20px;
    width: 5px;
    margin-left: 5px;
    margin-right: 10px;
    background: $primaryColor;
  }
  .title-content {
    font-size: 20px;
    font-weight: 500;
  }
  .title-operation-box {
    flex: 1;
    text-align: right;
  }
}
</style>
