<template>
  <el-card>
    <el-tabs v-if="deviceDetail.isSyncFromOuter" v-model="activatedTab" type="border-card">
      <!--      1.同步设备： 设备数据，协同作业，设备缺陷，维修任务，巡检任务，设备知识库-->
      <!--      a.equipmentKnowledgeOnly=true 设备数据，协同作业，设备知识库 -->
      <!--      b.equipmentKnowledgeOnly=false 设备数据，协同作业，设备缺陷，维修任务，巡检任务，设备知识库 -->
      <!--      1.创建设备： 设备知识库（包含了设备数据和设备知识库数据），协同作业-->
      <!--      a.equipmentKnowledgeOnly=true 设备知识库，协同作业-->
      <!--      b.equipmentKnowledgeOnly=false 设备知识库，协同作业 -->
      <el-tab-pane label="设备数据" name="设备数据">
        <div v-loading="isProductDataLoading">
          <el-card style="margin-bottom: 30px;">
            <el-row :gutter="24">
              <el-col :span="8">
                <template v-if="deviceDetail.imgUrls && deviceDetail.imgUrls.length !== 0">
                  <el-carousel trigger="click" arrow="always" height="200px" :autoplay="false" style="width: 100%;height: 100%" @change="picChange">
                    <el-carousel-item v-for="item in deviceDetail.imgUrls" :key="item">
                      <el-image
                        style="width: 100%;height: 100%;"
                        :src="item"
                        :preview-src-list="deviceDetail.imgUrls"
                        fit="contain"
                      />
                    </el-carousel-item>
                  </el-carousel>
                  <p style="text-align: center;">
                    <ht-popover-button :danger-text="true" confirm-title="是否删除当前图片？" icon="el-icon-delete" :loading="deletePicLoading" @confirmed="deletePic">删除</ht-popover-button>
                    <ht-upload-button key-prefix="customer_cnbm/devices" :multiple-selection="true" @on-files-uploaded="onFilesUploaded" />
                  </p>
                </template>
                <template v-else>
                  <div style="margin: auto auto;width: 300px;height: 200px">
                    <el-image :src="common" style="width: 100%;height: 100%" /></div>
                  <p style="text-align: center;">
                    <ht-upload-button key-prefix="customer_cnbm/devices" :multiple-selection="true" @on-files-uploaded="onFilesUploaded" />
                  </p>
                </template>
              </el-col>
              <el-col :span="16">
                <el-descriptions size="medium">
                  <el-descriptions-item label="设备名称"><strong>{{ deviceDetail.name }}</strong></el-descriptions-item>
                  <el-descriptions-item label="设备编号"><strong>{{ deviceDetail.code }}</strong></el-descriptions-item>
                  <el-descriptions-item label="mac地址"><strong>{{ deviceDetail.beaconMacAddr || '-' }}</strong></el-descriptions-item>
                </el-descriptions>
                <template v-if="deviceProductionData.length > 0">
                  <h3>实时生产数据：</h3>
                  <el-descriptions size="medium">
                    <el-descriptions-item v-for="i in deviceProductionData" :key="i.dataId" :label="i.dataName"><strong>{{ `${i.value}(${i.dataUnit})` }}</strong></el-descriptions-item>
                  </el-descriptions>
                </template>
              </el-col>
            </el-row>
          </el-card>
          <el-card v-if="deviceDetail.isSyncFromOuter">
            <div v-if="deviceProductionData.length > 0" style="width: 800px;">
              <div style="margin-bottom: 10px;margin-left: 10px">
                <el-form :inline="true">
                  <el-form-item label="指标名称">
                    <el-select v-model="barChartType" placeholder="指标名称" @change="changeQuery">
                      <el-option
                        v-for="item in deviceProductionData"
                        :key="item.dataId"
                        :label="item.dataName"
                        :value="item.dataId"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="截止日期">
                    <el-date-picker
                      v-model="barChartTime"
                      type="datetime"
                      placeholder="数据数量"
                      value-format="timestamp"
                      @change="changeQuery"
                    />
                  </el-form-item>
                  <el-form-item label="数据数量">
                    <el-input-number
                      v-model="barChartItemsCount"
                      controls-position="right"
                      :min="1"
                      :max="14"
                      placeholder="数据数量"
                      @change="changeQuery"
                    />
                  </el-form-item>
                </el-form>
              </div>
              <div v-loading="barLoading">
                <bar-chart
                  ref="eChart"
                  :is-show-background="false"
                  :is-show-data-zoom="false"
                  :chart-data="barData.data"
                  :x-axis-data="barData.xAxisName"
                  x-axis-name="时间"
                  :y-axis-name="`单位(${barData.dataInfo.unit})`"
                  :axis-label-style="{color: 'black'}"
                  :tooltip-formatter="`${barData.dataInfo.name}(${barData.dataInfo.unit}){b0} : {c}`"
                  width="900px"
                  height="450px"
                  :title="{text: '历史生产数据'}"
                />
              </div>
            </div>
            <div v-else>
              <h5>暂无历史数据</h5>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
      <el-tab-pane label="协同作业" name="协同作业">
        <remote-guidance-list v-if="activatedTab === '协同作业'" ref="remoteGuidanceList" :source="'deviceMgr'" biz-type="COOPERATE_WORK" :biz-id="deviceId" />
      </el-tab-pane>
      <template v-if="!deviceDetail.equipmentKnowledgeOnly">
        <el-tab-pane label="设备缺陷" name="设备缺陷">
          <bug-reports v-if="activatedTab === '设备缺陷'" :device-code="deviceCode" />
        </el-tab-pane>
        <el-tab-pane label="维修任务" name="维修任务">
          <maintain-tasks v-if="activatedTab === '维修任务'" :device-code="deviceCode" />
        </el-tab-pane>
        <el-tab-pane label="巡检任务" name="巡检任务">
          <patrol-tasks v-if="activatedTab === '巡检任务'" :device-code="deviceCode" />
        </el-tab-pane>
      </template>
      <el-tab-pane label="设备知识库" name="设备知识库">
        <knowledge-libs v-if="activatedTab === '设备知识库'" :device-code="deviceCode" />
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-if="!deviceDetail.isSyncFromOuter" v-model="notSyncActivatedTab" type="border-card">
      <el-tab-pane label="设备知识库" name="设备知识库">
        <div v-loading="isProductDataLoading">
          <el-card style="margin-bottom: 30px;">
            <el-row :gutter="24">
              <el-col :span="8">
                <template v-if="deviceDetail.imgUrls && deviceDetail.imgUrls.length !== 0">
                  <el-carousel trigger="click" arrow="always" height="200px" :autoplay="false" style="width: 100%;height: 100%" @change="picChange">
                    <el-carousel-item v-for="item in deviceDetail.imgUrls" :key="item">
                      <el-image
                        style="width: 100%;height: 100%;"
                        :src="item"
                        :preview-src-list="deviceDetail.imgUrls"
                        fit="contain"
                      />
                    </el-carousel-item>
                  </el-carousel>
                  <p style="text-align: center;">
                    <ht-popover-button :danger-text="true" confirm-title="是否删除当前图片？" icon="el-icon-delete" :loading="deletePicLoading" @confirmed="deletePic">删除</ht-popover-button>
                    <ht-upload-button key-prefix="customer_cnbm/devices" :multiple-selection="true" @on-files-uploaded="onFilesUploaded" />
                  </p>
                </template>
                <template v-else>
                  <div style="margin: auto auto;width: 300px;height: 200px">
                    <el-image :src="common" style="width: 100%;height: 100%" /></div>
                  <p style="text-align: center;">
                    <ht-upload-button key-prefix="customer_cnbm/devices" :multiple-selection="true" @on-files-uploaded="onFilesUploaded" />
                  </p>
                </template>
              </el-col>
              <el-col :span="16">
                <el-descriptions size="medium">
                  <el-descriptions-item label="设备名称"><strong>{{ deviceDetail.name }}</strong></el-descriptions-item>
                  <el-descriptions-item label="设备编号"><strong>{{ deviceDetail.code }}</strong></el-descriptions-item>
                  <el-descriptions-item label="mac地址"><strong>{{ deviceDetail.beaconMacAddr || '-' }}</strong></el-descriptions-item>
                </el-descriptions>
                <template v-if="deviceProductionData.length > 0">
                  <h3>实时生产数据：</h3>
                  <el-descriptions size="medium">
                    <el-descriptions-item v-for="i in deviceProductionData" :key="i.dataId" :label="i.dataName"><strong>{{ `${i.value}(${i.dataUnit})` }}</strong></el-descriptions-item>
                  </el-descriptions>
                </template>
              </el-col>
            </el-row>
          </el-card>
          <el-card v-if="deviceDetail.isSyncFromOuter">
            <div v-if="deviceProductionData.length > 0" style="width: 800px;">
              <div style="margin-bottom: 10px;margin-left: 10px">
                <el-form :inline="true">
                  <el-form-item label="指标名称">
                    <el-select v-model="barChartType" placeholder="指标名称" @change="changeQuery">
                      <el-option
                        v-for="item in deviceProductionData"
                        :key="item.dataId"
                        :label="item.dataName"
                        :value="item.dataId"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="截止日期">
                    <el-date-picker
                      v-model="barChartTime"
                      type="datetime"
                      placeholder="数据数量"
                      value-format="timestamp"
                      @change="changeQuery"
                    />
                  </el-form-item>
                  <el-form-item label="数据数量">
                    <el-input-number
                      v-model="barChartItemsCount"
                      controls-position="right"
                      :min="1"
                      :max="14"
                      placeholder="数据数量"
                      @change="changeQuery"
                    />
                  </el-form-item>
                </el-form>
              </div>
              <div v-loading="barLoading">
                <bar-chart
                  ref="eChart"
                  :is-show-background="false"
                  :is-show-data-zoom="false"
                  :chart-data="barData.data"
                  :x-axis-data="barData.xAxisName"
                  x-axis-name="时间"
                  :y-axis-name="`单位(${barData.dataInfo.unit})`"
                  :axis-label-style="{color: 'black'}"
                  :tooltip-formatter="`${barData.dataInfo.name}(${barData.dataInfo.unit}){b0} : {c}`"
                  width="900px"
                  height="450px"
                  :title="{text: '历史生产数据'}"
                />
              </div>
            </div>
            <div v-else>
              <h5>暂无历史数据</h5>
            </div>
          </el-card>
        </div>
        <knowledge-libs v-if="notSyncActivatedTab === '设备知识库'" :device-code="deviceCode" />
      </el-tab-pane>
      <el-tab-pane label="协同作业" name="协同作业">
        <remote-guidance-list v-if="notSyncActivatedTab === '协同作业'" ref="remoteGuidanceList" :source="'deviceMgr'" biz-type="COOPERATE_WORK" :biz-id="deviceId" />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import {
  getEquipmentDetailApi,
  equipmentProductionDataApi,
  equipmentProductionDataWithTimeApi,
  updateEquipmentsMgrItemById
} from '@/api/devicesMgr/deviceMgr'
import HtListContainer from '@/components/HtListContainer'
import barChart from '@/components/echarts/barChart'
import common from '@/assets/image/placeholders/common.png'
import remoteGuidanceList from '@/views/remoteGuidance/list'
import bugReports from '@/views/deviceMgr/components/bugReports'
import maintainTasks from '@/views/deviceMgr/components/maintainTasks'
import patrolTasks from '@/views/deviceMgr/components/patrolTasks'
import knowledgeLibs from '@/views/deviceMgr/components/knowledgeLibs'

export default {
  components: { barChart, remoteGuidanceList, bugReports, maintainTasks, patrolTasks, knowledgeLibs },
  extends: HtListContainer,
  props: {
    deviceCode: {
      type: [Number, String],
      default: undefined
    },
    deviceId: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      common,
      checkList: [],
      selectedChatRecord: [],
      guidanceItem: {},
      deviceDetail: {},
      deviceProductionData: [],
      barLoading: false,
      barData: {
        dataInfo: { name: undefined, unit: undefined },
        data: [],
        xAxisName: []
      },
      barChartType: undefined,
      barChartTime: undefined,
      barChartItemsCount: 7,
      deletePicIndex: 0,
      deletePicLoading: false,
      isProductDataLoading: false,
      activatedTab: '设备数据',
      notSyncActivatedTab: '设备知识库'
    }
  },
  created() {
    this.getDeviceDetail()
    if (this.$route.params.isSync === 'true') {
      this.getDeviceProductionData()
    }
    const date = new Date()
    this.barChartTime = Date.parse(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`)
  },
  methods: {
    getDeviceDetail() {
      getEquipmentDetailApi(this.deviceCode).then((resp) => {
        this.deviceDetail = resp
      })
    },
    getDeviceProductionData() {
      this.isProductDataLoading = true
      equipmentProductionDataApi(this.deviceCode).then((resp) => {
        this.deviceProductionData = resp
        if (this.deviceProductionData.length !== 0) {
          this.barChartType = this.deviceProductionData[0].dataId
          this.getDeviceProductionDataWithTime()
        }
        this.isProductDataLoading = false
      }).catch(() => { this.isProductDataLoading = false })
    },
    getDeviceProductionDataWithTime() {
      const query = {
        endTime: this.barChartTime,
        opcId: this.barChartType,
        count: this.barChartItemsCount
      }
      equipmentProductionDataWithTimeApi(this.deviceCode, query).then((resp) => {
        this.barData.dataInfo.name = resp.dataName
        this.barData.dataInfo.unit = resp.dataUnit
        resp.data.forEach(item => {
          this.barData.data.push(item.value)
          this.barData.xAxisName.push(item.time)
        })
        this.barLoading = false
      })
    },
    changeQuery() {
      this.barLoading = true
      this.barData.data = []
      this.barData.xAxisName = []
      this.getDeviceProductionDataWithTime()
    },
    picChange(newVal, oldVal) {
      this.deletePicIndex = newVal
    },
    deletePic() {
      this.deletePicLoading = true
      this.deviceDetail.imgUrls.splice(this.deletePicIndex, 1)
      updateEquipmentsMgrItemById(this.deviceId, { items: this.deviceDetail.imgUrls })
        .then(() => {
          this.$message({ message: `删除成功`, duration: 1500, type: 'success' })
          this.deletePicLoading = false
        }).catch(() => { this.deletePicLoading = false })
    },
    onFilesUploaded(val, urls) {
      urls.forEach(item => {
        this.deviceDetail.imgUrls.push(item)
      })
      updateEquipmentsMgrItemById(this.deviceId, { imgUrls: this.deviceDetail.imgUrls })
        .then(() => {
          this.$message({ message: `上传成功`, duration: 1500, type: 'success' })
          this.getDeviceDetail()
        })
    }
  }
}
</script>

<style scoped>

</style>
