<template>
  <span v-if="status">
    <el-tag :type="statusTypes[status]" :effect="effect">
      {{ status }}
    </el-tag>
  </span>
</template>

<script>
export default {
  name: 'Status',
  props: {
    status: { type: String, default: undefined },
    effect: { type: String, default: 'light' },
    statusTypes: { type: Object, default: undefined }
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>

</style>
