import request from '@/utils/request'
const construction = 'construction/'
const equipment = 'equipment/'
const equipments = 'equipments/'
const dropdown_equipments = 'dropdown_equipments/'

// 获取设备详情
export function getEquipment(equipmentId) {
  return request({ url: `${construction}${equipment}${equipmentId}`, method: 'GET' })
}

// 修改设备
export function putEquipment(equipmentId, data) {
  return request({ url: `${construction}${equipment}${equipmentId}`, method: 'PUT', data: data })
}

// 删除设备
export function deleteEquipment(equipmentId) {
  return request({ url: `${construction}${equipment}${equipmentId}`, method: 'DELETE' })
}

// 获取设备列表
export function getEquipments(listQuery) {
  return request({ url: `${construction}${equipments}`, method: 'GET', params: listQuery })
}

// 新增设备
export function postEquipments(data) {
  return request({ url: `${construction}${equipments}`, method: 'POST', data: data })
}

// 获取设备：下拉列表不分页
export function getDropdownEquipments(listQuery) {
  return request({ url: `${construction}${dropdown_equipments}`, method: 'GET', params: listQuery })
}

export function getEquipmentsPieStatistic(listQuery) {
  return request({ url: `${construction}${equipments}pie_statistic`, method: 'GET', params: listQuery })
}

export function getEquipmentWorkOrders(equipmentId, listQuery) {
  return request({ url: `${construction}${equipment}${equipmentId}/work_orders/`, method: 'GET', params: listQuery })
}

export function getEquipmentUsageCheck(equipmentId) {
  return request({ url: `${construction}${equipment}${equipmentId}/usage_check`, method: 'GET' })
}
