var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c("div", { staticClass: "dialog-outer-container" }, [
        _c(
          "div",
          {
            staticClass: "dialog-inner-container",
            style: { width: _vm.width },
          },
          [
            _c(
              "div",
              { staticClass: "title-container" },
              [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    style: { color: _vm.color, borderColor: _vm.color },
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm._v(" "),
                _c(
                  "ht-button",
                  {
                    staticClass: "close-button",
                    attrs: { type: "text" },
                    on: { click: _vm.onClose },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-container" },
              [_vm._t("content")],
              2
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }