var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outbound-container" },
    [
      _c(
        "el-form",
        {
          ref: "outboundForm",
          staticClass: "mobile-form",
          attrs: {
            model: _vm.outboundForm,
            rules: _vm.outboundFormRules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "出库单：", prop: "attachmentData" } },
            [
              _vm.outboundForm.attachmentData
                ? _c("dw-file", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      url: _vm.outboundForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.outboundForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["doc", "pdf", "img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传出库单",
                      "button-type": "primary",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "收货方：", prop: "constructionProjectId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.projectList.length > 0
                        ? "请输入工程名称进行搜索"
                        : "当前无可选工程",
                    "popper-class": "mobile-select",
                    remote: "",
                    "remote-method": _vm.getProjects,
                  },
                  on: { change: _vm.handleSelectProject },
                  model: {
                    value: _vm.outboundForm.constructionProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.outboundForm, "constructionProjectId", $$v)
                    },
                    expression: "outboundForm.constructionProjectId",
                  },
                },
                _vm._l(_vm.projectList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("出货仓库：")]),
        _vm._v(_vm._s(_vm.warehouseName || "-")),
      ]),
      _vm._v(" "),
      _vm.outboundForm.constructionProjectId
        ? [
            _vm._l(_vm.equipmentList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "equipment-card",
                  class: {
                    "selected-equipment-card": item.outboundQuantity > 0,
                    "disabled-equipment-card": item.quantity === 0,
                  },
                },
                [
                  _c("div", { staticClass: "top-item" }, [
                    _vm._v(
                      "设备：" +
                        _vm._s(item.name) +
                        " " +
                        _vm._s(item.code ? "(" + item.code + ")" : "")
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "bottom-item" }, [
                    _c("div", { staticClass: "bottom-left-item" }, [
                      _c("div", { staticClass: "top" }, [
                        _vm._v("规格型号：" + _vm._s(item.specModel || "-")),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("库存数：" + _vm._s(item.quantity))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "bottom-right-item" },
                      [
                        _c("span", { staticClass: "top" }, [
                          _vm._v("出库数量"),
                        ]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          attrs: {
                            min: 0,
                            placeholder: "请输入出库数量",
                            precision: 0,
                          },
                          model: {
                            value: item.outboundQuantity,
                            callback: function ($$v) {
                              _vm.$set(item, "outboundQuantity", $$v)
                            },
                            expression: "item.outboundQuantity",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _vm.equipmentList.length === 0 && !_vm.isEquipmentLoading
              ? _c("div", { staticClass: "empty-list" }, [
                  _c("img", {
                    staticClass: "empty-img",
                    attrs: { src: _vm.Empty },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "empty-info" }, [
                    _vm._v("没有设备"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isEquipmentLoading
              ? _c("div", { staticClass: "loading-spinner" }, [
                  _c("img", {
                    staticClass: "loading-img",
                    attrs: { src: _vm.Loading, alt: "" },
                  }),
                  _vm._v("数据加载中..."),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-container" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认出库")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }