import { render, staticRenderFns } from "./customDialog.vue?vue&type=template&id=5993d392&scoped=true&"
import script from "./customDialog.vue?vue&type=script&lang=js&"
export * from "./customDialog.vue?vue&type=script&lang=js&"
import style0 from "./customDialog.vue?vue&type=style&index=0&id=5993d392&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5993d392",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangmin/Projects/devidia/devidia-p1-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5993d392')) {
      api.createRecord('5993d392', component.options)
    } else {
      api.reload('5993d392', component.options)
    }
    module.hot.accept("./customDialog.vue?vue&type=template&id=5993d392&scoped=true&", function () {
      api.rerender('5993d392', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/taskMgr/newTemplateMgr/components/customDialog.vue"
export default component.exports