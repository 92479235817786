var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "audit-panel" }, [
    _c("div", { staticClass: "audit-row" }, [_vm._t("top")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "audit-row" }, [_vm._t("bottom")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }