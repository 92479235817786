var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inbound-container" },
    [
      _c(
        "el-form",
        {
          ref: "inboundForm",
          staticClass: "mobile-form",
          attrs: {
            model: _vm.inboundForm,
            rules: _vm.inboundFormRules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "入库文件：", prop: "attachmentData" } },
            [
              _vm.inboundForm.attachmentData
                ? _c("dw-file", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      url: _vm.inboundForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.inboundForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传入库文件",
                      "button-type": "primary",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备来源：",
                prop: "constructionEquipmentSource",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.handleEquipmentSourceChange },
                  model: {
                    value: _vm.inboundForm.constructionEquipmentSource,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.inboundForm,
                        "constructionEquipmentSource",
                        $$v
                      )
                    },
                    expression: "inboundForm.constructionEquipmentSource",
                  },
                },
                _vm._l(_vm.EquipmentSourceEnum, function (value, key) {
                  return _c("el-radio", { key: key, attrs: { label: key } }, [
                    _vm._v(_vm._s(value)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.inboundForm.constructionEquipmentSource === "OLD"
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "选择工程：",
                      prop: "constructionProjectId",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          placeholder:
                            _vm.projectList.length > 0
                              ? "请输入工程名称进行搜索"
                              : "当前无可选工程",
                          remote: "",
                          "popper-class": "mobile-select",
                        },
                        on: { change: _vm.handleSelectProject },
                        model: {
                          value: _vm.inboundForm.constructionProjectId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inboundForm,
                              "constructionProjectId",
                              $$v
                            )
                          },
                          expression: "inboundForm.constructionProjectId",
                        },
                      },
                      _vm._l(_vm.projectList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v("入库仓库：")]),
          _vm._v(_vm._s(_vm.warehouseName || "-")),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sub-title" },
          [
            _c("span", { staticClass: "name" }, [_vm._v("已选设备：")]),
            _vm._v(" "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.inboundForm.equipmentItems.length)),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-plus",
                  disabled:
                    (_vm.inboundForm.constructionEquipmentSource === "OLD" &&
                      _vm.inboundForm.constructionProjectId === null) ||
                    !_vm.inboundForm.constructionEquipmentSource,
                },
                on: { click: _vm.openAddEquipmentDrawer },
              },
              [_vm._v("添加设备")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.inboundForm.constructionEquipmentSource === "OLD"
          ? _vm._l(_vm.inboundForm.equipmentItems, function (item, index) {
              return _c("equipment-inbound-card", {
                key: index,
                attrs: {
                  "equipment-info": item,
                  "is-project": "",
                  "is-added": "",
                },
                on: { "del-equipment": _vm.delEquipment },
              })
            })
          : _vm.inboundForm.constructionEquipmentSource === "NEW"
          ? _vm._l(_vm.inboundForm.equipmentItems, function (item, index) {
              return _c("equipment-inbound-card", {
                key: index,
                attrs: { "equipment-info": item, "is-added": "" },
                on: { "del-equipment": _vm.delEquipment },
              })
            })
          : _vm._e(),
      ],
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-container" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认入库")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "mobile-drawer",
          attrs: {
            title:
              "添加设备（" +
              _vm.EquipmentSourceEnum[
                _vm.inboundForm.constructionEquipmentSource
              ] +
              "）",
            visible: _vm.showAddEquipmentList,
            direction: "btt",
            "destroy-on-close": true,
            close: _vm.closeAddEquipmentDrawer,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddEquipmentList = $event
            },
          },
        },
        [
          _vm.showAddEquipmentList
            ? _c("add-equipment-list", {
                ref: "addEquipmentList",
                staticStyle: { "margin-bottom": "20px" },
                attrs: { "already-add-list": _vm.inboundForm.equipmentItems },
                on: {
                  "on-add-equipments": function ($event) {
                    return _vm.closeAddEquipmentDrawer(true, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }