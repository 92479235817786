<template>
  <component :is="container">
    <el-card style="margin-bottom: 50px">
      <div class="title-card-container">
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">编号:</span>
          <span class="textStyle" style="color: #48a0ff;font-weight: bold">{{ guidanceItem.guidanceNo || '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">类型:</span>
          <span class="textStyle">{{ guidanceItem.bizType ? guidanceItem.bizType.value : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">发起人:</span>
          <span class="textStyle">{{ guidanceItem.staff ? guidanceItem.staff.fullName : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">参与人:</span>
          <span class="textStyle"> {{ guidanceItem.joinUserNamesString || '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">会议开始时间:</span>
          <span class="textStyle"> {{ guidanceItem.startAt || '-' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_90">会议时长:</span>
          <span class="textStyle">{{ guidanceItem.duration || '-' }}</span>
        </div>
      </div>
    </el-card>
    <el-divider><i class="el-icon-chat-line-square" /> 沟通记录</el-divider>
    <ht-action-panel>
      <template slot="right">
        <template v-if="bizType !== 'REMOTE_GUIDANCE' && !bizView">
          <ht-button
            :disabled="reportsRecords.length === 0"
            type="warning"
            icon="el-icon-document"
            @click="$refs.reportsRecordsDialog.dialogVisible = true"
          >查看历史图文报告
          </ht-button>
          <ht-button
            v-if="!showSelector"
            type="primary"
            icon="el-icon-document"
            @click="showSelectorOfChatRecord"
          >生成图文报告</ht-button>
          <template v-else>
            <ht-popover-button
              type="success"
              :disabled="selectedChatRecord.length === 0"
              icon="el-icon-circle-check"
              :confirm-title="`已选择${selectedChatRecord.length}条记录，是否确认生成报告？`"
              :loading="confirmReportLoading"
              @confirmed="confirmReport"
            >确认生成
            </ht-popover-button>
            <ht-button type="warning" @click="cancelDoc()">取消下载</ht-button>
          </template>
          <ht-button
            :disabled="!screenRecords || screenRecords.length === 0"
            icon="el-icon-video-play"
            @click="$refs.screenRecordsDialog.dialogVisible = true"
          >会议录屏</ht-button>
        </template>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list" :row-key="getRowKey" @selection-change="onSelectionChange">
      <ht-table-column-selection v-if="showSelector" />
      <el-table-column label="序号" type="index" fixed="left" />
      <el-table-column label="发送人" prop="fromFullName" />
      <el-table-column label="时间" prop="chatTime" />
      <el-table-column label="内容">
        <template v-slot="{row}">
          <span v-if="row.mediaType.name === 'TXT'">
            <p>{{ row.content }}</p>
          </span>
          <span v-else-if="row.mediaType.name === 'IMG'">
            <template v-if="row.fileType === 'pdf'">
              <el-link type="primary" :href="row.content" target="_blank" underline>
                <svg-icon icon-class="pdf" />（查看文件）
              </el-link>
            </template>
            <video-player
              v-else-if="row.fileType === 'mp4'"
              ref="videoPlayer"
              class="vjs-custom-skin"
              :options="row.playerOptions"
            />
            <el-image
              v-else
              style="width: 100px; height: 100px"
              :src="row.content"
              :preview-src-list="[row.content]"
            />
          </span>
        </template>
      </el-table-column>
    </ht-table>
    <ht-pagination
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getRemoteGuidanceChatRecords"
    />

    <ht-dialog
      ref="reportsRecordsDialog"
      title="历史图文报告"
      width="50%"
    >
      <div slot="content" class="clearFloat">
        <el-table border :data="reportsRecords">
          <el-table-column label="创建时间" prop="createdAt" />
          <el-table-column label="项目报告名称" prop="name">
            <template v-slot="{row}">
              <el-link type="primary" @click="downloadHistoryReport(row.ossFilePath)">{{ row.name }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="staffFullName" />
        </el-table>
        <ht-pagination
          style="float: right;clear:both;"
          :total="reportsRecordsPage.total"
          :page.sync="reportsRecordsListQuery.page"
          :limit.sync="reportsRecordsListQuery.perPage"
          @pagination="getProjectReportsDocRecords"
        />
      </div>
    </ht-dialog>

    <ht-dialog
      ref="screenRecordsDialog"
      title="会议录屏"
      :width="70"
    >
      <div slot="content" class="clearFloat">
        <el-table border :data="screenRecords">
          <el-table-column label="序号" type="index" fixed="left" />
          <el-table-column label="开始时间" prop="extraData.startTime" min-width="30px" />
          <el-table-column label="结束时间" prop="extraData.endTime" min-width="30px" />
          <el-table-column label="录屏" prop="name" min-width="100px">
            <template v-slot="{row}">
              <video-player
                v-if="row.content"
                ref="videoPlayer"
                class="vjs-custom-skin"
                :options="row.playerOptions"
              />
            </template>
          </el-table-column>
          <el-table-column label="录屏时长" prop="videoDuration" min-width="30px" />
        </el-table>
        <ht-pagination
          style="float: right;clear:both;"
          :total="screenRecordsPage.total"
          :page.sync="screenRecordsListQuery.page"
          :limit.sync="screenRecordsListQuery.perPage"
          @pagination="getRemoteGuidanceChatRecordsOfVideo"
        />
      </div>
    </ht-dialog>
  </component>
</template>

<script>
import {
  getRemoteGuidanceItemApi,
  getRemoteGuidanceChatRecordsApi,
  docReportApi,
  remoteGuidanceDocReportRecordsApi
} from '@/api/remoteGuidance/remoteGuidance'
import HtListContainer from '@/components/HtListContainer'
import { stringTrailingSlash } from '@/utils/index'

export default {
  extends: HtListContainer,
  props: {
    guidanceId: {
      type: [Number, String],
      default: undefined
    },
    bizType: {
      type: String,
      default: undefined
    },
    bizView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkList: [],
      showSelector: false,
      selectedChatRecord: [],
      guidanceItem: {},
      labelToProps: [
        {
          label: '发送人',
          prop: 'fromUser'
        },
        {
          label: '消息类型',
          prop: 'mediaType'
        },
        {
          label: '内容',
          prop: 'content'
        },
        {
          label: '时间',
          prop: 'chatTime'
        }
      ],
      visible: false,
      confirmReportLoading: false,
      playerOptions: {},
      container: 'el-card',
      reportsRecords: [],
      reportsRecordsListQuery: { page: 1, perPage: 20 },
      reportsRecordsPage: { total: 0 },
      screenRecords: [],
      screenRecordsListQuery: { page: 1, perPage: 20 },
      screenRecordsPage: { total: 0 }
    }
  },
  created() {
    this.getRemoteGuidanceItem()
    this.getRemoteGuidanceChatRecords()
    this.getRemoteGuidanceChatRecordsOfVideo()
    this.getProjectReportsDocRecords()
    this.container = this.bizView ? 'div' : 'el-card'
  },
  methods: {
    getRemoteGuidanceItem() {
      getRemoteGuidanceItemApi(this.guidanceId).then((resp) => {
        this.guidanceItem = resp
        this.guidanceItem.joinUserNamesString =
          (this.guidanceItem.joinUserNames && this.guidanceItem.joinUserNames.length > 0) ? this.guidanceItem.joinUserNames.join(',') : '-'
      })
    },
    getRemoteGuidanceChatRecords() {
      this.beforeGetList()
      getRemoteGuidanceChatRecordsApi(this.guidanceId, this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          const filePath = item.content
          var index = filePath.lastIndexOf('.')
          item.fileType = filePath.substring(index + 1)
          if (item.fileType === 'mp4') {
            item.playerOptions = {
              // videojs options
              muted: true,
              fluid: true,
              language: 'ch',
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [{
                type: 'video/mp4',
                src: item.content
              }],
              notSupportedMessage: '此视频无法播放，请稍后再试',
              controlBar: {
                timeDivider: false,
                durationDisplay: true,
                remainingTimeDisplay: true,
                fullscreenToggle: true
              }
            }
          }
        })
        this.afterGetList(resp)
      })
    },
    getRemoteGuidanceChatRecordsOfVideo() {
      this.screenRecordsListQuery.mediaTypes = 'VIDEO'
      getRemoteGuidanceChatRecordsApi(this.guidanceId, this.screenRecordsListQuery).then((resp) => {
        resp.items.forEach(item => {
          item.playerOptions = {
            // videojs options
            muted: true,
            fluid: true,
            language: 'ch',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [{
              type: 'video/mp4',
              src: item.content
            }],
            notSupportedMessage: '此视频无法播放，请稍后再试',
            controlBar: {
              timeDivider: false,
              durationDisplay: true,
              remainingTimeDisplay: true,
              fullscreenToggle: true
            }
          }
        })
        this.screenRecords = resp.items
        this.screenRecordsPage = resp.page
      })
    },
    getProjectReportsDocRecords() {
      remoteGuidanceDocReportRecordsApi(this.guidanceId, this.reportsRecordsListQuery).then((resp) => {
        this.reportsRecords = resp.items
        this.reportsRecordsPage = resp.page
      })
    },
    showSelectorOfChatRecord() {
      this.showSelector = true
      this.$nextTick(() => {
        this.$refs.list.toggleAllSelection()
      })
      this.$message({
        message: '请在表格中勾选需要生成报告的记录',
        center: true,
        type: 'warning'
      })
    },
    onSelectionChange(val) {
      this.selectedChatRecord = val
    },
    cancelDoc() {
      this.showSelector = false
    },
    confirmReport() {
      this.confirmReportLoading = true
      this.$message({
        message: '正在生成报告中，请稍等',
        center: true,
        type: 'warning'
      })
      const chatIds = []
      this.selectedChatRecord.forEach(item => {
        chatIds.push(item.id)
      })
      docReportApi(this.guidanceId, { chatIds: chatIds }).then((resp) => {
        window.open(`${stringTrailingSlash(process.env.VUE_APP_COS_SERVER)}${resp.filePath}`)
        this.showSelector = false
        this.confirmReportLoading = false
      }).catch(() => { this.confirmReportLoading = false })
    },
    cancelEdit() {
      this.editComment = false
      this.getRemoteGuidanceItem()
    },
    downloadHistoryReport(val) {
      window.open(`${stringTrailingSlash(process.env.VUE_APP_COS_SERVER)}${val}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.clearFloat:after{
  content: '';
  display: block;
  clear: both;
}
</style>
