var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-container",
      style: { height: _vm.height, background: _vm.cardColor },
    },
    [
      _c("div", { staticClass: "count", style: { color: _vm.countColor } }, [
        _vm._v(_vm._s(_vm.count)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title", style: { color: _vm.titleColor } }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }