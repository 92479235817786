<template>
  <component :is="componentName" />
</template>

<script>
import OneTarget from '@/views/test/mindAr/components/OneTarget'
import MultiTargets from '@/views/test/mindAr/components/MultiTargets'
import MultiTracks from '@/views/test/mindAr/components/MultiTracks'
import CustomUi from '@/views/test/mindAr/components/CustomUi'
import Interactive from '@/views/test/mindAr/components/Interactive'
import TestAr from '@/views/test/mindAr/components/TestAr'
import TestArText from '@/views/test/mindAr/components/TestArText'
import TestArArr from '@/views/test/mindAr/components/TestArArr'

export default {
  name: 'TestMindAr',
  components: {
    OneTarget,
    MultiTargets,
    MultiTracks,
    CustomUi,
    Interactive,
    TestAr,
    TestArText,
    TestArArr
  },
  data() {
    return {
      componentName: TestArArr
    }
  }
}
</script>
<style scoped>
.container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
