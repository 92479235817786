<template>
  <el-card v-loading="isLoading" class="statistic-box" :style="boxStyle">
    <dw-chart-title :title="title" :title-style="titleStyle">
      <template v-slot:statistic>
        <slot name="statistic" />
      </template>
    </dw-chart-title>
    <slot name="chart" />
  </el-card>
</template>

<script>
export default {
  name: 'DwStatisticChart',
  props: {
    isLoading: { type: Boolean, default: false },
    title: { type: String, default: '随便' },
    boxStyle: { type: Object, default() { return { width: '500px' } } },
    titleStyle: { type: Object, default() { return { width: '250px' } } }
  }
}
</script>

<style lang="scss" scoped>
.statistic-box{
  border-radius: 5px;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #e6ebf5;
  ::v-deep .el-card__body{
    padding: 10px;
  }
}
</style>
