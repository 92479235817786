<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import { debounce } from '@/utils'
import echarts from 'echarts'

export default {
  name: 'EquipmentLeaseTrendChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true },
    xUnit: { type: String, default: '月份' },
    yUnit: { type: String, default: '租用率%' }
  },
  data() {
    return {
      chart: null,
      option: {
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: 0
          }
        ],
        toolbox: {
          feature: {
            restore: {}
          }
        },
        grid: {
          top: '20%',
          left: '5%',
          right: '10%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          name: this.xUnit,
          data: [],
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          }
        },
        yAxis: {
          type: 'value',
          name: this.yUnit,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontWeight: 'bold',
            fontSize: '14'
          }
        },
        series: [
          {
            type: 'line',
            nameTextStyle: {
              fontSize: 14,
              fontWeight: 600
            },
            itemStyle: {
              normal: {
                color: '#2e5aff'
              }
            },
            label: {
              show: true,
              position: 'top',
              fontWeight: 'bolder',
              fontSize: 14,
              formatter: function(val) {
                return val.data > 0 ? val.data + '%' : ''
              }
            },
            data: []
          },
          {
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: 'rgba(255,255,255,0)'
              }
            }
          },
          {
            type: 'line',
            data: [],
            showSymbol: false,
            itemStyle: {
              normal: {
                color: 'rgba(255,255,255,0)'
              }
            }
          },
          {
            type: 'line',
            data: [],
            showSymbol: false,
            itemStyle: {
              normal: {
                color: 'rgba(255,255,255,0)'
              }
            }
          }
        ]
      }
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(data) {
      this.option.xAxis.data = data.time
      this.option.series[0].data = data.rentalRatio
      this.option.series[0].name = '当月租用率'
      this.option.series[1].data = data.underLeaseCounts
      this.option.series[1].name = '租用设备'
      this.option.series[2].data = data.freeCounts
      this.option.series[2].name = '未租用设备'

      this.option.tooltip.formatter = function(val) {
        const firstLine = val[0].axisValueLabel
        const secondLine = val[1].seriesName + ':' + val[1].data
        const thirdLine = val[2].seriesName + ':' + val[2].data
        const fourthLine = val[0].seriesName + ':' + val[0].data + '%'
        return `${firstLine}<br/>${secondLine}<br/>${thirdLine}<br/>${fourthLine}`
      }
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
