var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          class: [
            _vm.data.staticClass,
            "el-divider",
            "el-divider--" + _vm.props.direction,
          ],
        },
        "div",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _c("div", { staticClass: "left item" }, [_vm._t("left")], 2),
      _vm._v(" "),
      _vm.slots().default && _vm.props.direction !== "vertical"
        ? _c(
            "div",
            { class: ["el-divider__text", "is-" + _vm.props.contentPosition] },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "right item" }, [_vm._t("right")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }