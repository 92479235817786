var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-clear": _vm.onClearSearch, "on-search": _vm.onSearch } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "工单类型" },
                  model: {
                    value: _vm.listQuery.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "type", $$v)
                    },
                    expression: "listQuery.type",
                  },
                },
                _vm._l(_vm.getTaskTempTypeEnum(), function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.value, value: item.name },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "工单编号" },
                model: {
                  value: _vm.listQuery.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "code", $$v)
                  },
                  expression: "listQuery.code",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "工单名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "整机产权编号" },
                model: {
                  value: _vm.listQuery.targetCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "targetCode", $$v)
                  },
                  expression: "listQuery.targetCode",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "创建时间",
                  placement: "bottom-start",
                  "range-separator": "至",
                  "start-placeholder": "创建时间",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.createdAt,
                  callback: function ($$v) {
                    _vm.createdAt = $$v
                  },
                  expression: "createdAt",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "完成时间",
                  placement: "bottom-start",
                  "range-separator": "至",
                  "start-placeholder": "完成时间",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.finishedAt,
                  callback: function ($$v) {
                    _vm.finishedAt = $$v
                  },
                  expression: "finishedAt",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                _vm._l(_vm.TaskWorkOrderStatusEnum, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "整机设备" },
                  model: {
                    value: _vm.listQuery.targetName,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "targetName", $$v)
                    },
                    expression: "listQuery.targetName",
                  },
                },
                _vm._l(
                  _vm.EquipmentTypesEnum.filter(function (item) {
                    return item.isWhole
                  }),
                  function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.value, value: item.name },
                    })
                  }
                ),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
          on: { "row-click": _vm.handleRowClick },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.MyWorkOrderListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                "min-width": item.width || 100,
                prop: item.prop,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "工单类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTaskTempType(row[item.prop])
                                    ? _vm.getTaskTempType(row[item.prop]).value
                                    : "巡检"
                                )
                              ),
                            ])
                          : item.label === "创建人"
                          ? _c("span", [_vm._v(_vm._s(row.staff.fullName))])
                          : item.label === "执行人"
                          ? _c("span", [_vm._v(_vm._s(row.executor.fullName))])
                          : item.label === "循环周期"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.LoopEnum[row[item.prop]])),
                            ])
                          : item.label === "异常项"
                          ? _c(
                              "span",
                              { style: { color: row[item.prop] ? "red" : "" } },
                              [_vm._v(_vm._s(row[item.prop] + "项"))]
                            )
                          : item.label === "状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.TaskWorkOrderStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.TaskWorkOrderStatusEnum[
                                      row[item.prop]
                                    ] || "-"
                                  )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 80 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { icon: "el-icon-view", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onView(row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          limit: _vm.listQuery.perPage,
          page: _vm.listQuery.page,
          total: _vm.page.total,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }