<template>
  <div class="config-container">
    <el-form ref="configForm" :model="configForm" :rules="configRules" class="left-container">
      <el-form-item label="步骤名称：" prop="title">
        <el-input
          v-model="configForm.title"
          placeholder="请输入步骤名称"
          maxlength="10"
          show-word-limit
          @focus="focusedItem('title')"
        />
      </el-form-item>
      <el-form-item label="参考图片：" prop="referenceImage" label-width="85px">
        <dw-image
          v-if="configForm.referenceImage"
          :url="configForm.referenceImage"
          @delete-img="deleteReferenceImage"
        />
        <ht-upload-button
          v-else
          :file-type="['img']"
          key-prefix="workflow"
          select-img
          @focused="focusedItem('referenceImage')"
          @on-file-uploaded="onImageUploaded"
        />
      </el-form-item>
      <el-form-item label="检查要求：" prop="text">
        <el-input
          v-model="configForm.text"
          placeholder="请输入检查要求"
          type="textarea"
          :rows="6"
          :maxlength="70"
          show-word-limit
          @focus="focusedItem('text')"
          @change="textChange"
        />
      </el-form-item>
      <el-form-item prop="mustUploadPhoto">
        <el-checkbox v-model="configForm.mustUploadPhoto">必须拍摄照片</el-checkbox>
      </el-form-item>
    </el-form>
    <!--    <div class="right-container">-->
    <!--      <el-button type="primary" @click="onSaveStep">保存步骤</el-button>-->
    <!--      <el-button type="danger" @click="onDelStep">删除步骤</el-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'Config',
  props: {
    index: {
      type: Number,
      default: 0
    },
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          guidanceVideo: null,
          referenceImage: null,
          text: null,
          mustUploadPhoto: null
        }
      }
    },
    configRules: {
      type: Object,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      configForm: this.properties
    }
  },
  methods: {
    focusedItem(name) {
      this.$emit('focused-item', name)
    },
    onImageUploaded(context, url) {
      this.configForm.referenceImage = url
    },
    deleteReferenceImage() {
      this.configForm.referenceImage = null
    },
    textChange() {
      this.configForm.guidanceVideo = this.configForm.text
    },
    onSaveStep() {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          this.$emit('save-step')
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    onDelStep() {
      this.$emit('del-step', this.index)
    }
  }
}
</script>

<style scoped lang="scss">
.config-container{
  padding: 20px 10px 30px;
  max-height: 700px;
  overflow-y: auto;
  position: relative;
  .right-container{
    text-align: center;
    height: 30px;
  }
}
</style>
