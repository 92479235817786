var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showInput
        ? _c(
            "el-form",
            {
              ref: "projectForm",
              attrs: {
                model: _vm.projectForm,
                rules: _vm.projectFormRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工程名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入工程名称",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.projectForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "name", $$v)
                          },
                          expression: "projectForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工程起止日期：", prop: "duration" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd",
                          placement: "bottom-start",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.projectForm.duration,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "duration", $$v)
                          },
                          expression: "projectForm.duration",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-one-item-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工程地址：", prop: "location" } },
                    [
                      _c("el-input", {
                        attrs: {
                          id: "tipinput",
                          placeholder: "请输入工程地址",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        on: { change: _vm.handleLocationChange },
                        model: {
                          value: _vm.projectForm.location,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "location", $$v)
                          },
                          expression: "projectForm.location",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "map-container",
                attrs: { id: "mapContainer" },
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "施工单位：", prop: "constructionUnit" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入施工单位",
                          maxlength: "50",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.projectForm.constructionUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "constructionUnit", $$v)
                          },
                          expression: "projectForm.constructionUnit",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "监理单位：",
                        prop: "constructionControlUnit",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入监理单位",
                          maxlength: "50",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.projectForm.constructionControlUnit,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.projectForm,
                              "constructionControlUnit",
                              $$v
                            )
                          },
                          expression: "projectForm.constructionControlUnit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "安装单位：", prop: "installationUnit" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入安装单位",
                          maxlength: "50",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.projectForm.installationUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "installationUnit", $$v)
                          },
                          expression: "projectForm.installationUnit",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人：", prop: "contactName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入联系人",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.projectForm.contactName,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "contactName", $$v)
                          },
                          expression: "projectForm.contactName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "line-two-item-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话：", prop: "contactMobile" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入联系电话",
                          maxlength: "12",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.projectForm.contactMobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "contactMobile", $$v)
                          },
                          expression: "projectForm.contactMobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "绑定客户账号：", prop: "customerId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder:
                              _vm.customerList.length > 0
                                ? "请选择绑定客户账号"
                                : "当前无可选客户账号",
                            remote: "",
                            "remote-method": _vm.getCustomers,
                          },
                          model: {
                            value: _vm.projectForm.customerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.projectForm, "customerId", $$v)
                            },
                            expression: "projectForm.customerId",
                          },
                        },
                        _vm._l(_vm.customerList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.fullName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "list-form-item",
                  attrs: { label: "工程资料：", prop: "documentInformation" },
                },
                [
                  _vm.showInput
                    ? _c("ht-upload-button", {
                        attrs: {
                          "file-type": ["doc", "pdf", "img"],
                          "key-prefix": "workflow",
                          "multiple-selection": "",
                          "button-title": "上传文件",
                        },
                        on: { "on-files-uploaded": _vm.onFilesUploaded },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : [
            _c("div", { staticClass: "project-title" }, [
              _c("div", { staticClass: "project-name" }, [
                _vm._v(_vm._s(_vm.projectForm.name)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "project-info-container" },
              [
                _c(
                  "el-descriptions",
                  {
                    staticClass: "project-descriptions",
                    attrs: {
                      column: 2,
                      size: "medium",
                      border: "",
                      "label-style": { width: "110px" },
                    },
                  },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { span: 2 } },
                      [
                        _c("template", { slot: "label" }, [_vm._v("工程地址")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.projectForm.location || "-")),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("施工单位")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.projectForm.constructionUnit || "-")
                          ),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("监理单位")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.projectForm.constructionControlUnit || "-"
                            )
                          ),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("安装单位")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.projectForm.installationUnit || "-")
                          ),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("联系人")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.projectForm.contactName || "-")),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v("联系电话")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.projectForm.contactMobile || "-")),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v("绑定客户账号"),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.projectData.customer
                                ? _vm.projectData.customer.fullName
                                : "-"
                            )
                          ),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v("工程起止日期"),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.projectData.projectStartAt
                                ? _vm.projectData.projectStartAt +
                                    " 至 " +
                                    _vm.projectData.projectEndAt
                                : "-"
                            )
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "chart-container" },
                  [
                    _vm.ganttChartData && _vm.ganttChartData.length > 0
                      ? [
                          _vm.showFullScreenImg
                            ? _c("img", {
                                staticClass: "full-screen-img",
                                attrs: { src: _vm.FullScreen, alt: "全屏" },
                                on: { click: _vm.openGanttChartDialog },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("gantt-chart", {
                            ref: "ganttChart",
                            attrs: {
                              "x-rotate": 30,
                              title: "流程追踪图",
                              height: "250px",
                            },
                          }),
                        ]
                      : _c("div", { staticClass: "no-data-info" }, [
                          _vm._v("暂无流程数据"),
                        ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _c("document-list", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: {
          "document-list": _vm.projectForm.attachmentData,
          "show-operation": _vm.showInput,
        },
        on: { "on-delete": _vm.onDeleteDocument },
      }),
      _vm._v(" "),
      _vm.showInput
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close" },
                  on: { click: _vm.cancelSubmit },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.isSubmitting,
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit("projectForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isView && !_vm.isEdit
        ? _c(
            "el-form",
            { class: { "detail-form": !_vm.showInput } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "list-form-item",
                  attrs: {
                    label: _vm.showInput ? "租用设备记录：" : "租用设备记录",
                    prop: "documentInformation",
                  },
                },
                [
                  _vm.showInput
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "success", icon: "el-icon-setting" },
                          on: { click: _vm.openLeaseEquipmentMgrDialog },
                        },
                        [_vm._v("租用设备管理")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("equipment-list", {
                ref: "leaseHistoryEquipmentList",
                staticStyle: { "margin-bottom": "20px" },
                attrs: {
                  "table-title": _vm.EquipmentUsedListTitle,
                  "equipment-list": _vm.projectForm.equipmentItems,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "addEquipmentDialog",
          attrs: { title: "添加设备", width: _vm.isMobile ? "100%" : "800px" },
          on: { close: _vm.closeAddEquipmentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showAddEquipmentList
                ? _c("add-equipment-list", {
                    ref: "addEquipmentList",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { "already-add-list": _vm.isAddedEquipmentItems },
                    on: { "on-add": _vm.onAddEquipment },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "leaseEquipmentMgrDialog",
          attrs: {
            title: "租用设备管理",
            width: _vm.isMobile ? "100%" : "800px",
          },
          on: { close: _vm.closeLeaseEquipmentMgrDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showLeaseEquipmentMgr
                ? _c("lease-equipment-mgr", {
                    ref: "leaseEquipmentMgr",
                    attrs: {
                      "project-id": _vm.projectId,
                      "project-data": _vm.projectData,
                    },
                    on: {
                      "cancel-submit": _vm.closeLeaseEquipmentMgrDialog,
                      "confirm-submit": _vm.submitLeaseEquipmentMgrDialog,
                      "confirm-update": _vm.updateLeaseEquipmentMgrDialog,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "ganttChartDialog",
          attrs: {
            title: "流程追踪图",
            width: _vm.isMobile ? "100%" : "1000px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeGanttChartDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c("gantt-chart", {
                ref: "ganttChartDialogChart",
                attrs: {
                  "x-rotate": 20,
                  height: "400px",
                  "show-tool-box": "",
                  "data-zoom-type": "slider",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }