var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "default-avatar",
      style: {
        width: _vm.width + "px",
        height: _vm.width + "px",
        borderRadius: _vm.width + "px",
        color: _vm.color,
        background: _vm.background,
        fontSize: _vm.fontSize + "px",
        fontWeight: _vm.fontWeight,
      },
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(
            _vm.fullName && _vm.fullName.length >= 2
              ? _vm.fullName.substring(_vm.fullName.length - 2)
              : _vm.fullName
          ) +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }