import {
  EquipmentAbnormalEnum,
  PatrolProgressEnum,
  WarehouseEquipmentStoreStatusEnum
} from '@/views/constructionEquipmentMgr/enum'
import {
  ProjectStatusEnum
} from '@/views/constructionProjectMgr/projectMgr/enum'

// 数值记录模版显示
export function getValueRecordResult(row) {
  const properties = row.properties
  const results = row.results
  const dataName = properties.dataName || ''
  const dataUnit = properties.dataUnit || ''
  const value = results.value || results.value === 0

  const referenceRange = `${properties.minValue}-${properties.maxValue}${dataUnit}`
  const actualData = value ? `${results.value}${dataUnit}` : '暂无'

  return `${dataName}<br>参考范围：${referenceRange}<br>实际数据：${actualData}`
}

// 定位显示
export function getLocation(result) {
  const location = result.location ? result.location : '暂无'
  const longitude = result.longitude ? result.longitude : '暂无'
  const latitude = result.latitude ? result.latitude : '暂无'
  return '定位：' + location + ',' + '东经：' + longitude + ',' + '北纬：' + latitude
}

// 隐患登记模版显示
export function getHiddenDangerRecordResult(row) {
  const results = row.results
  const errorLevel = results.errorLevel || '暂无'
  const checkType = results.checkType || '暂无'

  if (results.errorLevel || results.checkType) {
    return `${checkType}-${errorLevel}`
  } else {
    return '暂无'
  }
}

// 设备综合管理
export function getAddress(separator = '/', province, city, district, address) {
  const results = [province, city, district, address].filter(item => item !== null && item !== '')
  return results.join(separator)
}

export function getIsAbnormal(isAbnormal) {
  const index = EquipmentAbnormalEnum.findIndex(item => item.name === isAbnormal)
  if (index !== -1) {
    return EquipmentAbnormalEnum[index]
  } else {
    return ''
  }
}

// 巡检工单执行状态
export function getProgress(progressName) {
  const index = PatrolProgressEnum.findIndex(item => item.name === progressName)
  if (index !== -1) {
    return PatrolProgressEnum[index]
  } else {
    return ''
  }
}

// 工单管理
export function getResultTextColor(row) {
  if (row.isAbnormal) {
    return 'color: red'
  } else {
    return null
  }
}

// 设备库存状态
export function getEquipmentStoreStatus(statusName) {
  const index = WarehouseEquipmentStoreStatusEnum.findIndex(item => item.name === statusName)
  if (index !== -1) {
    return WarehouseEquipmentStoreStatusEnum[index]
  } else {
    return ''
  }
}

// 工程状态
export function getProjectStatus(statusName) {
  const index = ProjectStatusEnum.findIndex(item => item.name === statusName)
  if (index !== -1) {
    return ProjectStatusEnum[index]
  } else {
    return ''
  }
}

// 扫码确认模版结果
export function getScanCodeResult(row) {
  const results = row.results
  const result = `车架：${results.carFrameNum || '-'}<br>品名：${results.nskMaterialName || '-'}<br>批次：${results.batchNum || '-'}<br>检查日：${results.checkAt || '-'}<br>`
  return results.carFrameNum ? result : '-'
}

export function getEquipmentName(equipment) {
  // const name = equipment.constructionEquipmentName
  const code = equipment.constructionEquipmentCode || ''
  const executeCode = equipment.executeCode || ''
  // return `${name}${code ? '-' : ''}${code}${executeCode ? '-' : ''}${executeCode}`
  return `${code}${executeCode ? '-' : ''}${executeCode}`
}
