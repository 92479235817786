<template>
  <div class="user-list-container">
    <template v-if="userList.length > 0">
      <div v-for="(user, index) in userList" :key="index" class="user-container">
        <el-image v-if="user.avatarUrl" class="avatar item" :src="user.avatarUrl || AvatarBlue" />
        <dw-default-avatar v-else :full-name="user.fullName" :width="40" :font-size="14" style="margin-right: 5px" />
        <span class="fullName text item">{{ `${user.fullName}` }}<span v-if="!isSinoma && !stringLongOverTen(user.userName)" class="userName">{{ `（${user.userName}）` }}</span> </span>
        <span class="role text item">{{ getUserRoles(user.roles) }}</span>
        <!--        <span class="online status text item">在线</span>-->
        <span v-if="user.isInGuidance" class="meeting status text item">会议中</span>
        <span v-else-if="user.isCalling" class="calling status text item">呼叫中</span>
        <div v-else class="call-container">
          <!--          <img :src="CallIcon" class="call-icon" alt="" @click="call(user)">-->
          <svg-icon icon-class="call" class="call-icon" @click="call(user)" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-container">
        <img class="empty-img" :src="Empty" alt="">
        <span class="empty-text">暂无在线用户</span>
      </div>
    </template>
  </div>
</template>

<script>
import AvatarBlue from '@/assets/image/newRemoteGuidance/avatar_blue.jpg'
import AvatarGrey from '@/assets/image/newRemoteGuidance/avatar_grey.png'
import CallIcon from '@/assets/image/newRemoteGuidance/call.png'
import MessageICon from '@/assets/image/newRemoteGuidance/message.png'
import Empty from '@/assets/image/newRemoteGuidance/empty.png'
import { getUserRoles } from '@/views/newRemoteGuidance/js/business'
import store from '@/store'
import { stringLongOverTen } from '@/utils'

export default {
  name: 'ContactListSmall',
  props: {
    userList: {
      type: Array,
      default: () => []
    },
    onLineUserInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      AvatarBlue,
      AvatarGrey,
      CallIcon,
      MessageICon,
      Empty,
      isSinoma: undefined
    }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
  },
  methods: {
    getUserRoles, stringLongOverTen,
    call(userInfo) {
      this.$emit('invite', userInfo)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.user-list-container{
  text-align: center;
  width: 100%;
  .user-container{
    height: 70px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid #E5E5E5;
    .item {
      margin-right: 10px;
    }
    .avatar{
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
    .text{
      text-align: left;
      font-size: 14px;
    }

    .fullName{
      flex: 1;
      font-weight: bold;
      font-size: 14px;
      .userName{
        font-weight: normal;
        font-size: 14px;
      }
    }

    .status{
      display: block;
      height: 22px;
      line-height: 22px;
      padding: 0 5px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      border-radius: 10px;
    }

    .online{
      background: $primaryColor;
    }
    .meeting{
      width: 55px;
      background: #f56c6c;
      margin-right: 0;
    }

    .calling{
      width: 55px;
      background: #67c23a;
      margin-right: 0;
    }

    .call-container{
      width: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      .call-icon{
        width: 25px;
        height: 25px;
        cursor: pointer;
        color: $primaryColor;
      }
      .call-icon:hover {
        width: 30px;
        height: 30px;
      }
    }
  }

  .empty-container{
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .empty-img{
      width: 120px;
      height: auto;
    }
    .empty-text{
      margin-top: 20px;
      font-size: 20px;
      color: #8a8a8a;
    }
  }
}
</style>
