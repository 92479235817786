<template>
  <div>
    <el-form ref="categoryForm" :model="categoryForm" :rules="categoryFormRules" label-width="100px">
      <el-form-item label="目录名称：" prop="name">
        <el-input v-model="categoryForm.name" placeholder="请输入目录名称" :maxlength="10" show-word-limit />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('categoryForm')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { postDocumentCategories, putDocumentCategory } from '@/api/knowleageBaseMgr/documentCategoryMgr'

export default {
  name: 'CategoryForm',
  props: {
    isEdit: { type: Boolean, default: false },
    parentId: { type: [String, Number], default: undefined },
    categoryData: { type: Object, default: () => {} }
  },
  data() {
    return {
      categoryForm: {
        parentId: null,
        name: undefined
      },
      categoryFormRules: {
        name: [{ required: true, message: '请输入目录名称', trigger: 'blur' }]
      },
      isSubmitting: false
    }
  },
  created() {
    if (this.isEdit) {
      this.getCategoryForm()
    }
  },
  methods: {
    getCategoryForm() {
      this.categoryForm.name = this.categoryData.name
      this.categoryForm.parentId = this.categoryData.parentId
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      this.categoryForm.parentId = this.isEdit ? this.categoryData.parentId : this.parentId
      this.$refs.categoryForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          if (this.isEdit) {
            putDocumentCategory(this.categoryData.id, this.categoryForm).then(() => {
              this.$message({ message: `修改成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          } else {
            postDocumentCategories(this.categoryForm).then(() => {
              this.$message({ message: `添加成功`, type: 'success', center: true })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => {
              this.isSubmitting = false
            })
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
