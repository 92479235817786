<template>
  <div class="navbar">
    <div v-if="showReturnButton" class="mobile-hamburger-container" style="padding-left: 5px" @click="clickHamburger">
      <svg class="hamburger-svg" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="64" height="64">
        <path d="M694.272 809.024l-295.808-286.848 295.36-274.752a32 32 0 0 0-43.616-46.848l-320 297.696a32 32 0 0 0-0.512 46.4l320 310.304a32.032 32.032 0 0 0 44.576-45.952" fill="#3D3A39" p-id="4184" />
      </svg>
    </div>
    <div class="title-container">{{ routerTitle || title }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import permission from '../../directive/permission/permission'
import im from '@/views/newRemoteGuidance/rongCloud/im'
import defaultSettings from '@/settings'

export default {
  directives: { permission },
  data() {
    return {
      title: defaultSettings.title || '',
      routerTitle: this.$route.meta.title,
      showReturnButton: this.$route.meta.params.prevMenu
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'userRoutes',
      'isMobile'
    ])
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      this.routerTitle = this.$route.meta.title
      this.showReturnButton = this.$route.meta.params.prevMenu
    }
  },
  methods: {
    clickHamburger() {
      if (this.showReturnButton) {
        this.$router.go(-1)
      } else {
        this.$router.push({ path: '/mobile' })
      }
    },
    async logout() {
      // 远程指导菜单是全分配给用户的，所以activated都是true,需要根据角色进行判断
      // 远程指导联系人菜单仅限于 EXPERT, ADMIN
      if (this.userRoutes && this.userRoutes.featureMenuJson && (this.userInfo.roles.includes('ADMIN') || this.userInfo.roles.includes('EXPERT'))) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'newRemoteGuidanceMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'contactList')
          if (menuIndex !== -1 && routers[index].children[menuIndex].activated) {
            im.imDisconnect().then(r => {}).catch(() => {
              this.$store.dispatch('user/logout')
              this.$router.push(`/login`)
            })
          }
        }
      }
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .mobile-hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }

    .hamburger-svg {
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
    }
  }

  .title-container {
    font-size: 1.2rem;
    line-height: 50px;
    text-align: center;
    width: 100%;
    padding: 0 30px;
  }
}
</style>
