<template>
  <div class="login-container" :class="{'is-mobile-login-container': isMobile, 'is-prod-login-container': isProdLogin}">
    <div id="loginBgContainer" class="login-bg-container">
      <!--      <div class="title">{{ title }}</div>-->
      <img id="loginBgImage" :src="getLoginBgImage" alt="" class="login-bg-image">
      <img :src="logo" alt="" class="login-logo">
      <el-form
        id="loginForm"
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        :class="{'prod-login-form': isProdLogin}"
        autocomplete="on"
        label-position="left"
      >
        <div class="title-container">
          <span class="top-title">欢迎登录</span>
          <!--          <span class="sub-title">{{ title }}</span>-->
        </div>

        <div class="form-describe">登陆账号</div>
        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入用户名"
            name="username"
            type="text"
            tabindex="1"
            aauto-complete="new-password"
          />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="new-password"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye_open'" />
          </span>
        </el-form-item>
        <div class="remember-password">
          <el-checkbox v-model="isPasswordRemembered" @change="changeRememberStatus">记住密码</el-checkbox>
        </div>

        <ht-button :loading="loading" type="primary" size="large" class="login-button" @click.native.prevent="handleLogin">登   录</ht-button>
      </el-form>
    </div>
  </div>
</template>

<script>
// 演示，轻量
import loginBgImage from '@/assets/image/loginBackground/login_background_new.png'
import loginBgMobileImage from '@/assets/image/loginBackground/login_background_mobile.jpg'
// 生产
import loginBgProdImage from '@/assets/image/loginBackground/login_background_prod.jpg'
import loginBgProdMobileImage from '@/assets/image/loginBackground/login_background_prod_mobile.png'
import { getObject } from '@/store/localStorage'
import defaultSettings from '@/settings'
import logo from '@/assets/image/logo/logo_shenhao.jpg'

export default {
  name: 'Login',
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'))
      } else {
        callback()
      }
    }
    return {
      loginBgImage, loginBgMobileImage, loginBgProdImage, loginBgProdMobileImage,
      title: defaultSettings.title || '',
      loginForm: {
        username: '',
        password: '',
        action: 'login',
        userType: 'staff'
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      VUE_APP_COS_SERVER: process.env.VUE_APP_COS_SERVER,
      isMobile: false,
      isPasswordRemembered: true,
      isProdLogin: process.env && process.env.VUE_APP_IS_PROD_LOGIN_BACKGROUND === 'true',
      logo: logo
    }
  },
  computed: {
    getLoginBgImage() {
      if (this.isProdLogin) {
        return this.isMobile ? loginBgProdMobileImage : loginBgProdImage
      } else {
        return this.isMobile ? loginBgMobileImage : loginBgImage
      }
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.isPasswordRemembered = !!localStorage.getItem('isPasswordRemembered')
    if (this.isPasswordRemembered) {
      const accountInfo = getObject('accountInfo')
      this.loginForm.username = accountInfo ? accountInfo.username : null
      this.loginForm.password = accountInfo ? accountInfo.password : null
    }
    this.$nextTick(() => {
      this.handleResizeImg()
    })
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
    const dashboard = window.document.getElementById('loginBgContainer')
    dashboard.style.minHeight = `${window.innerHeight}px`
    this.handleResize()
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        this.capsTooltip = shiftKey && (key >= 'a' && key <= 'z') || !shiftKey && (key >= 'A' && key <= 'Z')
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false
      }
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      let defaultRoute
      if (/micromessenger/i.test(navigator.userAgent)) {
        defaultRoute = { path: '/mobile' }
      } else {
        defaultRoute = { path: this.redirect || '/' }
      }
      // if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //   defaultRoute = { path: '/mobile' }
      // } else {
      //   defaultRoute = { path: this.redirect || '/' }
      // }

      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', this.loginForm)
            .then((resp) => {
              if (this.isPasswordRemembered) {
                this.$store.dispatch('user/saveAccount', { username: this.loginForm.username, password: this.loginForm.password })
              } else {
                this.$store.dispatch('user/removeAccount', ['username', 'password', 'isPasswordRemembered'])
              }
              this.$router.push(defaultRoute)
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    handleResize() {
      this.isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      this.handleResizeImg()
      this.handleResizeLoginFormPosition()
    },
    changeRememberStatus(value) {

    },
    handleResizeImg() {
      var viewportWidth = window.innerWidth
      var viewportHeight = window.innerHeight
      const windowRatio = viewportWidth / viewportHeight
      const img = window.document.getElementById('loginBgImage')
      if (windowRatio > 16 / 9) {
        img.style.width = '100%'
        img.style.height = 'auto'
      } else {
        img.style.width = 'auto'
        img.style.height = '100%'
      }
    },
    handleResizeLoginFormPosition() {
      const loginForm = window.document.getElementById('loginForm')
      loginForm.style.top = '50%;'
      loginForm.style.right = '20%;'
    }
  }
}
</script>

<style lang="scss">
  /* 修复input 背景不协调 和光标变色 */

  $bg: #283443;
  $light_gray: #666666;
  $cursor: #666666;

  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
      color: $cursor;
    }
  }

  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 40px;
      width: 85%;

      input {
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 40px;
        caret-color: $cursor;
      }

      input:-webkit-autofill {
        -webkit-text-fill-color: $cursor !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }

      input:-webkit-autofill:focus {
        -webkit-text-fill-color: $cursor !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
    }

    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: #ffffff;
      border-radius: 5px;
      color: $light_gray;
    }

    .el-input__inner {
      font-size: 16px;
    }

    .el-form-item__error {
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .prod-login-form{
      .el-form-item {
        border: 1px solid #aaaaaa !important;
        background: rgba(170, 170, 170, 0.1) !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../styles/mall";
  @import "~@/styles/variables.scss";
  $light_gray: #666666;

  .login-container {
    min-height: 600px;
    min-width: 500px;
    height: 100%;
    width: 100%;

    background-color: $var-mall-background-color;
    overflow-x: auto;

    .login-title-container {
      @extend .mall-flex-container;
      justify-content: flex-start;
      align-items: center;
      width: 1280px;
      margin-left: auto;
      margin-right: auto;

      .login-title-divider {
        font-size: 48px;
        margin: -20px 30px 0 30px;
        color: $var-mall-primary-title-color;
        font-weight: lighter;
      }

      .login-welcome {
        font-size: 38px;
        margin-top: -10px;
      }
    }

    .login-bg-container {
      position: relative;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      height: auto;

      .title {
        z-index: 2;
        position: absolute;
        top: 80px;
        padding-left: 80px;
        color: #ffffff;
        font-size: 30px;
        font-weight: bolder;
      }

      .login-bg-image {
        position: absolute;
        z-index: 1;
        object-fit: cover; /* 保持图片的比例，覆盖容器 */
      }

      .login-logo{
        position: absolute;
        z-index: 2;
        top: 50px;
        left: 100px;
        width: 200px;
      }

      .login-form {
        z-index: 2;
        position: absolute;
        top: 50%;
        right: 10%;
        width: 370px;
        max-width: 100%;
        transform: translateY(-50%);
        background-color: rgba(38, 73, 234, 0.7);
        padding: 70px 55px;
        border-radius: 15px;

        .el-form-item{
          ::v-deep .el-form-item__content {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }

      .login-button {
        width: 100%;
        color: white;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
        background: #3e7afc;
        border-color: #ffffff;
      }

      .tips {
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;

        span {
          &:first-of-type {
            margin-right: 16px;
          }
        }
      }

      .svg-container {
        width: 30px;
        padding: 6px 5px 6px 15px;
        vertical-align: middle;
        display: inline-block;

        .svg-icon{
          color: $light_gray;
        }
      }

      .title-container {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto 40px auto;

        .top-title {
          color: #ffffff;
          font-size: 26px;
          font-weight: bold;
        }
        .sub-title{
          color: #e1e1e1;
          font-size: 14px;
          margin-top: 5px;
          font-weight: bold;
        }
      }

      .form-describe{
        color: #e1e1e1;
        margin-bottom: 15px;
        font-size: 16px;
      }

      .show-pwd {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: white;
        cursor: pointer;
        user-select: none;
      }
    }

  }

  .is-mobile-login-container{
    min-height: auto;
    min-width: auto;
    .title {
      width: 100%;
      text-align: center;
      padding-left: 0 !important;
    }
    .login-bg-container{
      overflow: hidden;
    }
    .login-bg-image{
      width: auto !important;
      height: 100% !important;
    }
    .login-form{
      width: 80% !important;
      max-width: 370px !important;
      top: 20% !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }

  .remember-password{
    margin-bottom: 18px;
    ::v-deep.el-checkbox{
      font-size: 1.1rem;
      .el-checkbox__input{
        .el-checkbox__inner{
          width: 1rem;
          height: 1rem;
        }
        .el-checkbox__inner::after{
          top: 0.1rem;
          left: 0.3rem;
        }
      }
      .el-checkbox__label{
        color: #ffffff;
        font-size: 1rem;
        font-weight: bold;
      }
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #3e7afc;
          border-color: #ffffff;
        }
      }
    }
  }

//   生产服差异样式
  .is-prod-login-container{
    .top-title {
      color: #000000 !important;
    }

    .sub-title{
      color: #666666 !important;;
    }

    .prod-login-form{
      background-color: #ffffff !important;

      .form-describe{
        color: #666666 !important;;
      }
    }

    .remember-password{
      ::v-deep.el-checkbox{
        .el-checkbox__label{
          color: #3e7afc;
          font-size: 1rem;
          font-weight: bold;
        }
        .el-checkbox__input.is-checked{
          .el-checkbox__inner{
            background: #3e7afc;
            border-color: #ffffff;
          }
        }
      }
    }
  }
</style>
