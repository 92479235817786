import rtc from '@/views/newRemoteGuidance/rongCloud/rtc'
import { postVideoRecord } from '@/api/newRemoteGuidance/newRemoteGuidance'

// 录制屏幕
export default {
  methods: {
    isHasRecoding() {
      rtc.getRoomAttributes(this.room, this.recordingKeys).then(data => {
        if (data.hasOwnProperty('recordingImUserId')) { // 取到recordingImUserId：当前有用户在录制屏幕
          if (data.recordingImUserId === this.imUserId) { // 录制屏幕的是自己：出现可能为上一次会议异常退出，房间内状态没有清除
            this.isRecording = true
            this.ryRecordId = data.ryRecordId
          } else {
            this.isRemoteRecording = true
            this.recordingUserInfo = data
          }
        }
      })
    },
    async videoRecordingFun() {
      if (this.isRemoteRecording) {
        this.$message({
          message: `当前【${this.recordingUserInfo.recordingFullName}】正在进行屏幕录制，请等待该用户录制结束后再进行操作`,
          type: 'error',
          center: true
        })
      } else {
        this.isRecording = !this.isRecording
        if (this.isRecording) {
          if (this.remoteVideoTracks.length > 0) {
            await this.screenRecordingStart()
          } else {
            this.$message({
              message: `当前会议无可录制视频`,
              type: 'error',
              center: true
            })
            this.isRecording = false
          }
        } else {
          await this.screenRecordingFinish()
        }
      }
    },
    async screenRecordingStart() {
      const videoItems = []
      const audioItems = []
      if (this.isShareScreenVideo) {
        videoItems.push({ imUserId: this.localScreenVideoTrack.getUserId(), streamId: this.localScreenVideoTrack.getStreamId() })
      } else if (this.isRemoteShareScreen) {
        const PublicScreen = this.remoteVideoTracks.filter(item => item.getTag() === 'PublicScreen')
        PublicScreen.forEach(item => {
          videoItems.push({ imUserId: item.getUserId(), streamId: item.getStreamId() })
        })
      } else {
        this.remoteVideoTracks.forEach(item => {
          videoItems.push({ imUserId: item.getUserId(), streamId: item.getStreamId() })
        })
      }
      audioItems.push({ imUserId: this.localAudioTrack.getUserId(), streamId: this.localAudioTrack.getStreamId() })
      this.remoteAudioTracks.forEach(item => {
        audioItems.push({ imUserId: item.getUserId(), streamId: item.getStreamId() })
      })
      const data = {
        videoItems: videoItems,
        action: 'start'
      }
      if (videoItems.length > 0) {
        postVideoRecord(this.roomId, data).then(res => {
          this.ryRecordId = res.ryRecordId
          this.$message({ message: `开始录制`, type: 'success', center: true })
          const recordingData = {
            recordingImUserId: this.userImInfo.imUserId,
            recordingFullName: this.userInfo.fullName,
            recordingUserName: this.userInfo.account.userName,
            ryRecordId: this.ryRecordId
          }
          rtc.setRoomAttribute(this.room, recordingData).then(() => {
            this.sendMeetingRecordingStatusMsg(true)
          }).catch(() => {
            this.$message({ message: `开始录制失败`, type: 'error', center: true })
            this.isRecording = false
          })
        }).catch(() => {
          this.$message({ message: `开始录制失败`, type: 'error', center: true })
          this.isRecording = false
        })
      } else {
        this.$message({ message: `当前会议无视频，录制失败`, type: 'error', center: true })
        this.isRecording = false
      }
    },
    async screenRecordingFinish() {
      // todo ryRecordId经常出现问题，看是不是要处理下异常情况
      const data = { action: 'stop', ryRecordId: this.ryRecordId }
      postVideoRecord(this.roomId, data).then(() => {
        this.ryRecordId = null
        this.$message({ message: `结束录制，会议录屏已保存`, type: 'success', center: true })
        rtc.deleteRoomAttributes(this.room, this.recordingKeys)
        this.sendMeetingRecordingStatusMsg(false)
      }).catch(() => {
        this.$message({ message: `结束录制失败`, type: 'error', center: true })
        this.isRecording = true
      })
    },
    recordingStatusChangedByRemoteFun(e) {
      const detail = e.detail
      this.isRemoteRecording = detail.isRecord
      this.recordingUserInfo = {
        recordingImUserId: detail.senderId,
        recordingFullName: detail.senderFullName,
        recordingUserName: detail.senderUserName,
        ryRecordId: detail.ryRecordId
      }
    }
  }
}
