<template>
  <div class="container">
    <div class="title">本地API配置</div>
    <div class="api-container">
      <span class="item">API地址：</span>
      <span class="item">http://</span>
      <el-input v-model="api" placeholder="请输入API地址" class="input-width" />
      <span class="item">:</span>
      <el-input v-model="api_port" placeholder="端口号" class="input-width-mini" />
      <span class="item">/dw/v1/</span>
    </div>
    <div class="center-button">
      <ht-button type="primary" @click="setURL()">确定</ht-button>
      <ht-button type="default" icon="el-icon-delete" @click="onClearURL">恢复</ht-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      api: 'api.devidiadigital.com',
      api_port: '5001'
    }
  },
  created() {
    const api = localStorage.getItem('LOCAL_VUE_APP_BASE_API')
    if (api && api !== '') {
      const address = api.split('//')[1].split('/')[0]
      this.api = address.split(':')[0]
      this.api_port = address.split(':')[1]
    }
  },
  methods: {
    refreshView() {
      location = location.href
    },
    setURL() {
      const api = `http://${this.api}:${this.api_port}/dw/v1/`
      localStorage.setItem('LOCAL_VUE_APP_BASE_API', api)
      this.$notify({ title: 'API设置成功！页面会自动刷新！', duration: 1500, type: 'success' })
      this.refreshView()
    },
    onClearURL() {
      const api = process.env.VUE_APP_BASE_API
      if (api && api !== '') {
        const address = api.split('//')[1].split('/')[0]
        this.api = address.split(':')[0]
        this.api_port = address.split(':')[1]
      }
      localStorage.setItem('LOCAL_VUE_APP_BASE_API', api)
      this.$notify({ title: 'API设置清楚成功！页面会自动刷新！', duration: 1500, type: 'success' })
      this.refreshView()
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  width: 550px;
  height: 100px;
  line-height: 40px;
  padding-left: 100px;
  padding-top: 100px;
  .title{
    font-size: 20px;
    text-align: center;
  }
  .api-container{
    display: flex;
    flex-direction: row;
  }
  .item {
    color: #666666;
  }
  .input-width {
    width: 200px;
  }
  .input-width-mini {
    width: 70px;
  }
  .center-button {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
