var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "viewer-fade" } }, [
    _c(
      "div",
      {
        ref: "el-image-viewer__wrapper",
        staticClass: "el-image-viewer__wrapper",
        style: { "z-index": _vm.viewerZIndex },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", {
          staticClass: "el-image-viewer__mask",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleMaskClick($event)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "el-image-viewer__btn el-image-viewer__close custom_image-viewer__button",
          },
          [
            _c("button", { on: { click: _vm.hide } }, [
              _vm.showIcon
                ? _c("i", { staticClass: "el-icon-close" })
                : _vm._e(),
              _vm._v("关闭"),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.isSingle
          ? [
              _c(
                "span",
                {
                  staticClass:
                    "el-image-viewer__btn el-image-viewer__prev custom_image-viewer__button",
                  class: { "is-disabled": !_vm.infinite && _vm.isFirst },
                },
                [
                  _c("button", { on: { click: _vm.prev } }, [
                    _vm.showIcon
                      ? _c("i", { staticClass: "el-icon-arrow-left" })
                      : _vm._e(),
                    _vm._v("上一张"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "el-image-viewer__btn el-image-viewer__next custom_image-viewer__button",
                  class: { "is-disabled": !_vm.infinite && _vm.isLast },
                },
                [
                  _c("button", { on: { click: _vm.next } }, [
                    _vm._v("下一张"),
                    _vm.showIcon
                      ? _c("i", { staticClass: "el-icon-arrow-right" })
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "el-image-viewer__btn el-image-viewer__actions" },
          [
            _c("div", { staticClass: "el-image-viewer__actions__inner" }, [
              _c(
                "button",
                {
                  staticClass: "action_button",
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("zoomOut")
                    },
                  },
                },
                [
                  _vm._v("缩小"),
                  _vm.showIcon
                    ? _c("i", { staticClass: "el-icon-zoom-out" })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "action_button",
                  on: {
                    click: function ($event) {
                      return _vm.handleActions("zoomIn")
                    },
                  },
                },
                [
                  _vm._v("放大"),
                  _vm.showIcon
                    ? _c("i", { staticClass: "el-icon-zoom-in" })
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "el-image-viewer__canvas" },
          [
            _vm._l(_vm.urlList, function (url, i) {
              return [
                i === _vm.index
                  ? _c("img", {
                      key: url,
                      ref: "img",
                      refInFor: true,
                      staticClass: "el-image-viewer__img",
                      style: _vm.imgStyle,
                      attrs: { src: _vm.currentImg },
                      on: {
                        load: _vm.handleImgLoad,
                        error: _vm.handleImgError,
                        mousedown: _vm.handleMouseDown,
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }