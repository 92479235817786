var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "step-ui-detail-container is-used-step-container",
          style: { height: _vm.height + "px" },
          attrs: { title: "hf_use_description" },
        },
        [
          _c(
            "div",
            { staticClass: "view-container" },
            [
              _c("top-section", {
                ref: "topSection",
                attrs: {
                  "is-quick-patrol": "",
                  "is-focused-item": _vm.isFocusedItem,
                  "current-index": _vm.currentIndex,
                  count: _vm.count,
                  title: _vm.properties.title,
                  "show-more-function": _vm.showMoreFunction,
                  "must-upload-photo": _vm.properties.mustUploadPhoto,
                  "upload-photos": _vm.submitResults.uploadPhotos,
                  "active-img-index": _vm.activeImgIndex,
                  "button-disabled": _vm.buttonDisabled,
                },
                on: {
                  "more-function": function ($event) {
                    return _vm.moreFunction()
                  },
                  "native-play-tts": function ($event) {
                    return _vm.nativePlayTTS(_vm.ttsText)
                  },
                  "to-remote-guidance": function ($event) {
                    return _vm.toRemoteGuidance()
                  },
                  "to-ai-qa-assistant": function ($event) {
                    return _vm.toAiQaAssistant()
                  },
                  "native-take-photo": function ($event) {
                    return _vm.nativeTakePhoto()
                  },
                  "open-photo": function ($event) {
                    return _vm.openPhoto()
                  },
                  "del-photo": function ($event) {
                    return _vm.delPhoto()
                  },
                  "set-active-item": function ($event) {
                    return _vm.setActiveItem()
                  },
                  "close-photo-viewer": function ($event) {
                    return _vm.closePhotoViewer()
                  },
                  "jump-step": _vm.jumpStep,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "middle-section",
                  style: { height: _vm.getMiddleHeight + "px" },
                },
                [
                  _c("div", { staticClass: "question-container" }, [
                    _vm._v(
                      _vm._s(
                        _vm.isEdit
                          ? "检查项"
                          : "检查项" +
                              (_vm.currentCheckIndex + 1) +
                              "：" +
                              _vm.results.question
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-section" }, [
                _c("div", { staticClass: "bottom-container" }, [
                  _vm.showToPrev
                    ? _c(
                        "div",
                        { staticClass: "bottom-left" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "prev-button square-circle-button",
                              attrs: {
                                type: "text",
                                disabled: _vm.buttonDisabled,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleActions("toPrev")
                                },
                              },
                            },
                            [_vm._v("上一项")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bottom-right" },
                    _vm._l(_vm.buttonText, function (item, index) {
                      return _c(
                        "el-button",
                        {
                          key: index,
                          staticClass: "square-circle-button",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleActions("toNext", item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }