import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'
import log from '@/utils/log'

// 用户连接状态改变
class DwUserConnectStatus {
  static MsgType = 'DwUserConnectStatus'
  static ONLINE = 'ONLINE'
  static OFFLINE = 'OFFLINE'
  constructor(content) {
    this.msgType = content.msgType
    this.status = content.status
  }
}

// 用户与会状态改变
class DwUserMeetingStatus {
  static MsgType = 'DwUserMeetingStatus'
  static IN_MEETING = 'IN_MEETING'
  static OUT_MEETING = 'OUT_MEETING'
  constructor(content) {
    this.msgType = content.msgType
    this.status = content.status
  }
}

// 会议邀请消息
export class DwMeetingInvite {
  static MsgType = 'DwMeetingInvite'
  constructor(content) {
    this.msgType = content.msgType
    this.roomId = content.roomId
    this.chatRoomId = content.chatRoomId
    this.senderId = content.senderId
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.senderRoles = content.senderRoles
    this.senderAvataUrl = content.senderAvatarUrl
  }
}

// 会议邀请接受
class DwMeetingInviteAccept {
  static MsgType = 'DwMeetingInviteAccept'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.senderRoles = content.senderRoles
  }
}

// 会议邀请拒绝
class DwMeetingInviteReject {
  static MsgType = 'DwMeetingInviteReject'
  static BUSY = 'BUSY'
  static REJECT = 'REJECT'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.senderRoles = content.senderRoles
    this.reason = content.reason
  }
}

// 会议邀请取消
class DwMeetingInviteCancel {
  static MsgType = 'DwMeetingInviteCancel'
  static CANCEL = 'CANCEL'
  static NO_RESPONSE = 'NO_RESPONSE'
  constructor(content) {
    this.msgType = content.msgType
    this.roomId = content.roomId
    this.senderId = content.senderId
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.senderRoles = content.senderRoles
    this.reason = content.reason
  }
}

// 聊天室保活-聊天室消息
class DwMeetingChatRoomAlive {
  static MsgType = 'DwMeetingChatRoomAlive'
  constructor(content) {
    this.msgType = content.msgType
    this.content = content.content
  }
}

// 进出聊天室-聊天室消息
class DwMeetingEnterOrExitChatRoom {
  static MsgType = 'DwMeetingEnterOrExitChatRoom'
  constructor(content) {
    this.msgType = content.msgType
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.content = content.content
  }
}

// 会议屏幕共享-聊天室消息
class DwMeetingScreenStatus {
  static MsgType = 'DwMeetingScreenStatus'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.isShare = content.isShare
  }
}

// 会议视频录制-聊天室消息
class DwMeetingRecordingStatus {
  static MsgType = 'DwMeetingRecordingStatus'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.isRecord = content.isRecord
  }
}

class DwDeviceMessageDirection {
  static Command = 'COMMAND'
  static Status = 'STATUS'
}

// 设备状态询问
class DwQueryDeviceStatus {
  static MsgType = 'DwQueryDeviceStatus'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// isMicOn: true, isCamOn: true, isFront: true, isFlashOn: false, isFlashSupported: true,
// camZoomLevel: 1, camZoomLevels: 5, camExposureLevel: 0, camExposureLevels: [-12, -4, -2, 0, 2, 4, 12]
// 设备状态
class DwDeviceStatus {
  static MsgType = 'DwDeviceStatus'
  constructor(content) {
    this.direction = content.direction
    this.isMicOn = content.isMicOn
    this.isCamOn = content.isCamOn
    this.isUvcCamOn = content.isUvcCamOn
    this.isUvcConnected = content.isUvcConnected
    this.isFlashOn = content.isFlashOn
    this.isFlashSupported = content.isFlashSupported
    this.camZoomLevel = content.camZoomLevel
    this.camExposureLevel = content.camExposureLevel
    this.camExposureLevels = content.camExposureLevels
    this.screenHeight = content.screenHeight
    this.screenWidth = content.screenWidth
    this.senderId = content.senderId
    this.videoResolution = content.videoResolution
    this.msgType = content.msgType
  }
}

// 麦克风开关
class DwMicMute {
  static MsgType = 'DwMicMute'
  constructor(content) {
    this.msgType = content.msgType
    this.isMute = content.isMute
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 摄像头开关
class DwCamMute {
  static MsgType = 'DwCamMute'
  constructor(content) {
    this.msgType = content.msgType
    this.isMute = content.isMute
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 摄像头切换
class DwCamSwitch {
  static MsgType = 'DwCamSwitch'
  constructor(content) {
    this.msgType = content.msgType
    this.isFront = content.isFront
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 外置摄像头
class DwSwitchUvcCam {
  static MsgType = 'DwSwitchUvcCam'
  constructor(content) {
    this.msgType = content.msgType
    this.isUvcCam = content.isUvcCam
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 摄像头缩放级别
class DwCamZoom {
  static MsgType = 'DwCamZoom'
  constructor(content) {
    this.msgType = content.msgType
    this.zoomLevel = content.zoomLevel
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 摄像头曝光级别
class DwCamExposure {
  static MsgType = 'DwCamExposure'
  constructor(content) {
    this.msgType = content.msgType
    this.exposureLevel = content.exposureLevel
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 闪光灯开关
class DwFlashlight {
  static MsgType = 'DwFlashlight'
  constructor(content) {
    this.msgType = content.msgType
    this.isOn = content.isOn
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 手动聚焦
class DwManualFocus {
  static MsgType = 'DwManualFocus'
  constructor(content) {
    this.msgType = content.msgType
    this.offsetX = content.offsetX
    this.offsetY = content.offsetY
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 视频质量调整
class DwVideoConfig {
  static MsgType = 'DwVideoConfig'
  constructor(content) {
    this.msgType = content.msgType
    this.resolution = content.resolution
    this.senderId = content.senderId
    this.direction = content.direction
  }
}

// 实时画笔
class DwPaint {
  static MsgType = 'DwPaint'
  static Open = 'OPEN'
  static Clear = 'CLEAR'
  static Close = 'CLOSE'
  constructor(content) {
    this.msgType = content.msgType
    this.paintData = content.paintData
    this.status = content.status
    this.targetUserId = content.targetUserId
    this.senderScreenSize = content.senderScreenSize
  }
}

// 激光笔
class DwLaser {
  static MsgType = 'DwLaser'
  static Open = 'OPEN'
  static Close = 'CLOSE'
  constructor(content) {
    this.msgType = content.msgType
    this.laserData = content.laserData
    this.status = content.status
    this.targetUserId = content.targetUserId
    this.senderScreenSize = content.senderScreenSize
  }
}

// 聚焦指导
class DwFocusGuidance {
  static MsgType = 'DwFocusGuidance'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.isFocused = content.isFocused
    this.focusedUserId = content.focusedUserId
  }
}

// 标注指导
class DwFreezeGuidance {
  static MsgType = 'DwFreezeGuidance'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.isFrozen = content.isFrozen
    this.frozenUserId = content.frozenUserId
    this.frozenImgUrl = content.frozenImgUrl
  }
}

// track is not ready
class DwTrackNotReady {
  static MsgType = 'DwTrackNotReady'
  constructor(content) {
    this.msgType = content.msgType
    this.senderId = content.senderId
    this.trackId = content.trackId
    this.trackTag = content.trackTag
    this.isVideoTrack = content.isVideoTrack
  }
}

class DwMeetingTxtMessage {
  static MsgType = 'DwMeetingTxtMessage'
  constructor(content) {
    this.senderFullName = content.senderFullName
    this.senderUserName = content.senderUserName
    this.senderAvataUrl = content.senderAvataUrl
    this.content = content.content
    this.translation = content.translation
  }
}

function messageHandle(msg) {
  log.myLog(`收到消息-${msg.content && msg.content.msgType ? msg.content.msgType : msg.messageType}`, msg,)
  const contentObj = msg.content
  if (msg.messageDirection === 2) {
    switch (contentObj.msgType) {
      case DwUserConnectStatus.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.contactsRefreshEvent, { detail: contentObj }))
        break
      case DwUserMeetingStatus.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingRefreshEvent, { detail: contentObj }))
        break
      case DwMeetingInvite.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingInviteEvent, { detail: new DwMeetingInvite(contentObj) }))
        break
      case DwMeetingInviteAccept.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingInviteAcceptEvent, { detail: new DwMeetingInviteAccept(contentObj) }))
        break
      case DwMeetingInviteReject.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingInviteRejectEvent, { detail: new DwMeetingInviteReject(contentObj) }))
        break
      case DwMeetingInviteCancel.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingInviteCancelEvent, { detail: new DwMeetingInviteCancel(contentObj) }))
        break
      case DwMeetingScreenStatus.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingScreenStatusEvent, { detail: new DwMeetingScreenStatus(contentObj) }))
        break
      case DwMeetingRecordingStatus.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingRecordingStatusEvent, { detail: new DwMeetingRecordingStatus(contentObj) }))
        break
      case DwQueryDeviceStatus.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingDeviceQueryStatusEvent, { detail: new DwQueryDeviceStatus(contentObj) }))
        break
      case DwDeviceStatus.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingDeviceStatusEvent, { detail: new DwDeviceStatus(contentObj) }))
        break
      case DwMicMute.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingMicMuteEvent, { detail: new DwMicMute(contentObj) }))
        break
      case DwCamMute.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingCamMuteEvent, { detail: new DwCamMute(contentObj) }))
        break
      case DwCamSwitch.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingCamSwitchEvent, { detail: new DwCamSwitch(contentObj) }))
        break
      case DwSwitchUvcCam.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingSwitchUvcCam, { detail: new DwSwitchUvcCam(contentObj) }))
        break
      case DwCamZoom.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingCamZoomEvent, { detail: new DwCamZoom(contentObj) }))
        break
      case DwCamExposure.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingCamExposureEvent, { detail: new DwCamExposure(contentObj) }))
        break
      case DwFlashlight.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingFlashlightEvent, { detail: new DwFlashlight(contentObj) }))
        break
      case DwVideoConfig.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingVideoConfigEvent, { detail: new DwVideoConfig(contentObj) }))
        break
      case DwLaser.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingLaserEvent, { detail: new DwLaser(contentObj) }))
        break
      case DwPaint.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingPaintEvent, { detail: new DwPaint(contentObj), receivedTime: msg.receivedTime }))
        break
      case DwFocusGuidance.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingFocusGuidance, { detail: new DwFocusGuidance(contentObj) }))
        break
      case DwFreezeGuidance.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingFreezeGuidance, { detail: new DwFreezeGuidance(contentObj) }))
        break
      case DwTrackNotReady.MsgType:
        document.dispatchEvent(new CustomEvent(EventsEnum.onTrackNotReadyEvent, { detail: new DwTrackNotReady(contentObj) }))
        break
      default:
        document.dispatchEvent(new CustomEvent(EventsEnum.meetingChatMessageEvent, { detail: msg }))
        break
    }
  }
}

export default {
  DwUserConnectStatus,
  DwUserMeetingStatus,
  DwMeetingTxtMessage,
  DwMeetingInvite,
  DwMeetingInviteAccept,
  DwMeetingInviteReject,
  DwMeetingInviteCancel,
  DwMeetingChatRoomAlive,
  DwMeetingEnterOrExitChatRoom,
  DwMeetingScreenStatus,
  DwMeetingRecordingStatus,
  DwDeviceMessageDirection,
  DwQueryDeviceStatus,
  DwDeviceStatus,
  DwMicMute,
  DwCamMute,
  DwCamSwitch,
  DwCamZoom,
  DwCamExposure,
  DwFlashlight,
  DwManualFocus,
  DwVideoConfig,
  DwSwitchUvcCam,
  DwLaser,
  DwPaint,
  DwFocusGuidance,
  DwFreezeGuidance,
  DwTrackNotReady,
  messageHandle
}
