var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.newList && _vm.newList.length > 0 ? "添加记录：" : ""
              ) +
              "\n    "
          ),
          _c(
            "ht-button",
            {
              attrs: { type: "success", icon: "el-icon-plus" },
              on: { click: _vm.addRevisionHistory },
            },
            [_vm._v("新增变更记录")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.newList.length > 0
        ? _c(
            "ht-table",
            {
              ref: "list",
              staticStyle: { "margin-top": "10px" },
              attrs: { data: _vm.newList },
            },
            [
              _c("ht-table-column", {
                attrs: { label: "序号", type: "index", fixed: "left" },
              }),
              _vm._v(" "),
              _vm._l(
                _vm.NskCheckTemplateAddRevisionHistoryTitle,
                function (item) {
                  return _c("ht-table-column", {
                    key: item.prop,
                    attrs: {
                      prop: item.prop,
                      "min-width": item.width || 100,
                      "show-overflow-tooltip": false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                {
                                  class: {
                                    "is-required-title": item.isRequired,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.isRequired
                                        ? "*" + item.label
                                        : item.label
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              item.label === "变更理由"
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "变更理由",
                                      type: "textarea",
                                      autosize: { minRows: 1, maxRows: 3 },
                                      maxlength: 100,
                                    },
                                    model: {
                                      value: row.changeReason,
                                      callback: function ($$v) {
                                        _vm.$set(row, "changeReason", $$v)
                                      },
                                      expression: "row.changeReason",
                                    },
                                  })
                                : item.label === "承认者"
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "承认者",
                                      maxlength: 100,
                                    },
                                    model: {
                                      value: row.approver,
                                      callback: function ($$v) {
                                        _vm.$set(row, "approver", $$v)
                                      },
                                      expression: "row.approver",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }
              ),
              _vm._v(" "),
              _c("ht-table-column-operation", {
                attrs: { width: 80 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c(
                            "ht-button",
                            {
                              staticClass: "danger-btn",
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.onDeleteNewHistory($index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3037674122
                ),
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.newList.length > 0
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center", "margin-top": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ht-button",
                {
                  attrs: { icon: "el-icon-close" },
                  on: { click: _vm.cancelAdd },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "ht-button",
                {
                  attrs: {
                    loading: _vm.isSubmitting,
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAddRevisionHistory()
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("历史记录：")]),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { "margin-top": "10px" },
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.NskCheckTemplateRevisionHistoryTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "变更者"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  (row.changer && row.changer.fullName) || "-"
                                )
                              ),
                            ])
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 80 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          icon: "el-icon-delete",
                          "confirm-title": "请确认是否要删除该历史记录？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.onDeleteRevisionHistory(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }