var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isImporting,
          expression: "isImporting",
        },
      ],
    },
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "表番" },
                model: {
                  value: _vm.listQuery.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "code", $$v)
                  },
                  expression: "listQuery.code",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "表名" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "关联部品名" },
                model: {
                  value: _vm.listQuery.nskMaterialName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "nskMaterialName", $$v)
                  },
                  expression: "listQuery.nskMaterialName",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: { type: "success", icon: "el-icon-download" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadFile(_vm.templateUrl)
                    },
                  },
                },
                [_vm._v("模版下载")]
              ),
              _vm._v(" "),
              _c("ht-upload-button", {
                attrs: {
                  "file-type": ["excel"],
                  "key-prefix": "nsk",
                  "show-success-tip": false,
                  "button-title": "导入检查表",
                },
                on: {
                  "on-upload-start": _vm.onUploadStart,
                  "on-file-uploaded": _vm.onFileUploaded,
                },
              }),
              _vm._v(" "),
              _c(
                "ht-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v("新建检查表")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.NskCheckTemplateListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "关联部品"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.nskMaterialId === null
                                    ? "-"
                                    : (row[item.prop] || "-") +
                                        "（" +
                                        (row.nskMaterialCode || "-") +
                                        "）"
                                )
                              ),
                            ])
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: 280 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-document-copy" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleCopyTemplateFormDialog(row, true)
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleRevisionHistoryDialog(row)
                          },
                        },
                      },
                      [_vm._v("变更履历")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          icon: "el-icon-delete",
                          "confirm-title": "请确认是否要删除该检查表？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.onDelete(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "copyTemplateFormDialog",
          attrs: {
            title: "复制检查单模版",
            width: _vm.isMobile ? "100%" : "600px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleCopyTemplateFormDialog(null, false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showCopyTemplateForm
                ? _c("copy-template-form", {
                    ref: "copyTemplateForm",
                    attrs: { "template-data": _vm.templateData },
                    on: {
                      "cancel-submit": function ($event) {
                        return _vm.toggleCopyTemplateFormDialog(null, false)
                      },
                      "confirm-submit": _vm.confirmSubmitCopyTemplate,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "templateRevisionHistoryDialog",
          attrs: {
            title: "变更履历",
            width: _vm.isLoading ? "100%" : "1000px",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              return _vm.toggleRevisionHistoryDialog(null, false)
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showTemplateRevisionHistory
                ? _c("template-revision-history", {
                    ref: "showTemplateRevisionHistory",
                    attrs: { "template-id": _vm.templateData.id },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }