var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "deviceForm",
          attrs: {
            model: _vm.deviceForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备编码：",
                prop: _vm.isSync ? "isSyncCode" : "code",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { disabled: _vm.isSync, placeholder: "请输入设备编码" },
                model: {
                  value: _vm.deviceForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.deviceForm, "code", $$v)
                  },
                  expression: "deviceForm.code",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "设备名称：", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { disabled: _vm.isSync, placeholder: "请输入设备名称" },
                model: {
                  value: _vm.deviceForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.deviceForm, "name", $$v)
                  },
                  expression: "deviceForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "mac地址：", prop: "beaconMacAddr" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "请输入mac地址" },
                model: {
                  value: _vm.deviceForm.beaconMacAddr,
                  callback: function ($$v) {
                    _vm.$set(_vm.deviceForm, "beaconMacAddr", $$v)
                  },
                  expression: "deviceForm.beaconMacAddr",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "设备图片：", prop: "imgUrls" } },
            [
              _vm.deviceForm.imgUrls && _vm.deviceForm.imgUrls.length > 0
                ? _c("show-image", {
                    ref: "logoUrl",
                    attrs: { images: _vm.deviceForm.imgUrls },
                    on: {
                      "delete-image": function ($event) {
                        return _vm.deleteImage(arguments, "imgUrls")
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              [
                _c("ht-upload-button", {
                  attrs: {
                    "key-prefix": "customer_cnbm/devices",
                    "multiple-selection": true,
                  },
                  on: { "on-files-uploaded": _vm.onFileUploaded },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      display: "inline-block",
                      width: "330px",
                      "font-size": "13px",
                      color: "#999999",
                    },
                  },
                  [_vm._v(_vm._s(_vm.hint))]
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("ht-button", { on: { click: _vm.onCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "ht-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onSubmit("deviceForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }