var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.getProject },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabs, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.value, name: item.name },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.activeName === "now"
        ? [
            !_vm.isView
              ? _c(
                  "div",
                  { staticClass: "title" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.preAddEquipmentList &&
                            _vm.preAddEquipmentList.length > 0
                            ? "本次添加设备："
                            : ""
                        ) +
                        "\n      "
                    ),
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "success", icon: "el-icon-plus" },
                        on: { click: _vm.openAddEquipmentDialog },
                      },
                      [_vm._v("添加租用设备")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.preAddEquipmentList && _vm.preAddEquipmentList.length > 0
              ? [
                  _c("equipment-list", {
                    ref: "addedEquipmentList",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      "show-del": !_vm.isView,
                      "table-title": _vm.EquipmentAddedListTitle,
                      "equipment-list": _vm.preAddEquipmentList,
                    },
                    on: { "on-delete": _vm.onDeleteEquipment },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "ht-button",
                        {
                          attrs: { icon: "el-icon-close" },
                          on: { click: _vm.cancelSubmit },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ht-button",
                        {
                          attrs: {
                            loading: _vm.isSubmitting,
                            type: "primary",
                            icon: "el-icon-check",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmSubmit()
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [_vm._v("工程现存设备：")]),
            _vm._v(" "),
            _c("equipment-list", {
              ref: "equipmentInProjectList",
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                "show-stop": !_vm.isView,
                "show-edit": !_vm.isView,
                "table-title": _vm.EquipmentHasStockListTitle,
                "equipment-list": _vm.projectForm.equipmentStockItems,
              },
              on: {
                "on-stop": _vm.onStopLeaseEquipment,
                "on-edit": _vm.openModifyExecuteCodeDialog,
              },
            }),
          ]
        : [
            _c("div", { staticClass: "title" }, [_vm._v("历史租用记录：")]),
            _vm._v(" "),
            _c("equipment-list", {
              ref: "leaseHistoryEquipmentList",
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                "table-title": _vm.EquipmentUsedListTitle,
                "equipment-list": _vm.projectForm.equipmentItems,
              },
            }),
          ],
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "addEquipmentDialog",
          attrs: { title: "添加设备", width: _vm.isMobile ? "100%" : "800px" },
          on: { close: _vm.closeAddEquipmentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showAddEquipmentList
                ? _c("add-equipment-list", {
                    ref: "addEquipmentList",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { "already-add-list": _vm.preAddEquipmentList },
                    on: { "on-add-equipments": _vm.onAddEquipments },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "transshipmentDialog",
          attrs: { title: "转移设备", width: _vm.isMobile ? "500px" : "500px" },
          on: { close: _vm.closeTransshipmentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showTransshipmentForm
                ? _c("transshipment-form", {
                    ref: "transshipmentForm",
                    on: {
                      "cancel-submit": _vm.closeTransshipmentDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeTransshipmentDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "modifyExecuteCodeDialog",
          attrs: {
            title: "修改执行代码",
            width: _vm.isMobile ? "400px" : "400px",
          },
          on: { close: _vm.closeModifyExecuteCodeDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showModifyExecuteCodeForm
                ? _c("modify-execute-code-form", {
                    ref: "modifyExecuteCodeForm",
                    on: {
                      "cancel-submit": _vm.closeModifyExecuteCodeDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeModifyExecuteCodeDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "stopLeaseDialog",
          attrs: { title: "结束租用", width: _vm.isMobile ? "400px" : "400px" },
          on: { close: _vm.closeStopLeaseDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showStopLeaseForm
                ? _c("stop-lease-form", {
                    ref: "stopLeaseForm",
                    on: {
                      "cancel-submit": _vm.closeStopLeaseDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeStopLeaseDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }