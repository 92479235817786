<template>
  <div v-loading="isLoading" class="editor-container">
    <tinymce-editor
      ref="tinymceEditor"
      v-model="content"
      api-key="i7vi2n3r3wa8cijiodjqdcfidnhkyg02vsxj1qkdstppyzml"
      :init="editorConfig"
      @onInit="onEditorInit"
    />
  </div>
</template>

<script>
export default {
  name: 'DwTinymceEditor',
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isLoading: true, // 初始化加载状态为true
      content: this.value,
      editorConfig: {
        selector: '#tinyTextarea',
        language: 'zh_CN',
        auto_focus: false, // 自动获取焦点
        setup: function(editor) { // 初始化前执行
        },
        init_instance_callback: function(editor) { // 初始化结束后执行
        },
        readonly: false, // 只读模式
        block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3', // 区块列表
        branding: false, // 隐藏右下角技术支持
        elementpath: false, // 隐藏底栏的元素路径
        statusbar: false, // 隐藏状态栏
        contextmenu: 'copy bold italic underline strikethrough superscript subscript', // 上下文菜单
        font_formats: // 字体列表
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;' +
          '宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;' +
          'Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px', // 文字大小列表
        lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2', // 行高列表
        height: 400, // 编辑器高度
        min_height: 400,
        max_height: 600,
        menu: { // 自定义菜单
          edit: { title: '编辑', items: 'undo redo | cut copy paste pastetext | selectall' },
          view: { title: '查看', items: 'preview wordcount' },
          format: {
            title: '格式',
            items: 'bold italic underline strikethrough superscript subscript | formats | forecolor backcolor | removeformat'
          },
          table: { title: '表格', items: 'inserttable tableprops deletetable | cell row column' }
        },
        menubar: 'edit insert format table view', // 自定义菜单栏
        placeholder: '', // 内容预展示文本
        plugins: // 指定需加载的插件
          'quickbars preview link table charmap hr pagebreak lists advlist' +
          ' wordcount help emoticons autoresize autosave wordcount autolink insertdatetime',
        toolbar: // 自定义工具栏
          'undo redo | ' +
          'fontselect fontsizeselect |' + // fontselect
          'cut copy paste removeformat forecolor backcolor bold italic underline strikethrough quicktablecut pastetext blockquote subscript superscript |' +
          'alignleft aligncenter alignright alignjustify outdent indent bullist numlist lineheight | ' +
          'table  quickimage link |' +
          'charmap emoticons hr pagebreak insertdatetime preview wordcount',
        toolbar_mode: 'wrap', // 工具栏模式 floating / sliding / scrolling / wrap
        quickbars_insert_toolbar: '', // [插入]快捷工具栏
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote', // [选择]快捷工具栏
        custom_colors: true // 调色盘开关
      }
    }
  },
  watch: {
    content(newValue) {
      // 监听子组件内部数据的变化，并通过$emit方法向父组件发送一个名为'input'的自定义事件
      // 并传递当前子组件内部的childMessage数据作为参数
      this.$emit('input', newValue)
    }
  },
  methods: {
    onEditorInit() {
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.editor-container{
  width: 100%;
  min-height: 400px;
}
</style>
