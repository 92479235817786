var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "external-call-container",
      class: { "out-of-meeting-container": !_vm.isBeingInvited },
      staticStyle: { height: "100%" },
    },
    [
      _c("user-info", {
        attrs: { "session-key": _vm.sessionKey },
        on: { disconnect: _vm.disconnect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }