<template>
  <div>
    <el-cascader
      ref="cascader"
      v-model="zones"
      :options="options"
      clearable
      filterable
      style="width: 100%"
      :props="{ value: value }"
      :placeholder="placeholder"
      @change="zoneChanged"
    />
  </div>

</template>

<script>
export default {
  name: 'DwChinaZonesSelect',
  props: {
    placeholder: { type: String, default: '请选择省市区' },
    provinceId: { type: Number, default: null },
    provinceCode: { type: String, default: null },
    provinceName: { type: String, default: null },
    cityId: { type: Number, default: null },
    cityCode: { type: String, default: null },
    cityName: { type: String, default: null },
    districtId: { type: Number, default: null },
    districtCode: { type: String, default: null },
    districtName: { type: String, default: null },
    value: { type: String, default: 'value' }// 值为code
  },
  data() {
    return {
      zones: [],
      options: []
    }
  },
  created() {
    this.getZones()
  },
  methods: {
    getZones() {
      this.$store.dispatch('cache/getChinaZones')
        .then(zones => {
          this.options = zones
          this.initZones(this.provinceCode, this.cityCode, this.districtCode)
        })
    },
    // 初始化
    initZones(province, city, district) {
      const zones = [province, city, district]
      this.zones = zones.filter(item => item !== null)
    },
    getNODistrictData(zones) {
      for (let i = 0; i < zones.length; i++) {
        const provinceItem = zones[i]
        if (provinceItem.hasOwnProperty('children')) {
          for (let j = 0; j < provinceItem.children.length; j++) {
            const cityItem = provinceItem.children[j]
            cityItem.children = null
          }
        }
      }
      return zones
    },
    // 选择省市区
    zoneChanged(zones) {
      const [province, city, district] = zones
      // 重新搜索id，code，name
      this.search(province, city, district)
    },
    // 返回name和code
    search(province, city, district) {
      // 省
      const { provinceId, provinceCode, provinceName, provinceChildren } = this.getProvince(province)
      // 市
      const { cityId, cityCode, cityName, cityChildren } = this.getCity(city, provinceChildren)
      // 区
      const { districtId, districtCode, districtName } = this.getDistrict(district, cityChildren)

      // 同步给父组件的id
      this.$emit('update:provinceId', provinceId || null)
      this.$emit('update:cityId', cityId || null)
      this.$emit('update:districtId', districtId || null)
      // 同步给父组件的code
      this.$emit('update:provinceCode', provinceCode || null)
      this.$emit('update:cityCode', cityCode || null)
      this.$emit('update:districtCode', districtCode || null)
      // 同步给父组件的name
      this.$emit('update:provinceName', provinceName || null)
      this.$emit('update:cityName', cityName || null)
      this.$emit('update:districtName', districtName || null)
    },
    // 获取省市区id，code，name
    getJson(key, items) {
      let id, code, name
      let index = -1
      if (items && items.length > 0) {
        index = items.findIndex(item => item[this.value] === key)
        if (index !== -1) {
          id = items[index].id
          name = items[index].label
          code = items[index].value
        }
      }

      return { id: id, code: code, name: name, index: index }
    },
    // 获取省
    getProvince(province) {
      const { id, code, name, index } = this.getJson(province, this.options)
      const provinceChildren = index !== -1 ? this.options[index].children : undefined
      return { provinceId: id, provinceCode: code,
        provinceName: name, provinceChildren: provinceChildren }
    },
    // 获取市
    getCity(city, children) {
      const { id, code, name, index } = this.getJson(city, children)
      const cityChildren = index !== -1 ? children[index].children : undefined
      return { cityId: id, cityCode: code, cityName: name, cityChildren: cityChildren }
    },
    // 获取区
    getDistrict(district, children) {
      const { id, code, name, index } = this.getJson(district, children)
      const districtChildren = index !== -1 ? children[index].children : undefined
      return { districtId: id, districtCode: code,
        districtName: name, districtChildren: districtChildren }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
