<template>
  <el-card>
    <ht-table ref="list" v-loading="isLoading" :data="workOrderList">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in RepairWorkOrderListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '工单类型'">{{ getTaskTempType(row[item.prop]) ? getTaskTempType(row[item.prop]).value : '维修' }}</span>
          <span v-else-if="item.label === '创建人'">{{ row.staff.fullName }}</span>
          <span v-else-if="item.label === '执行人'">{{ row.executor.fullName }}</span>
          <el-tag v-else-if="item.label === '状态'" :type="TaskWorkOrderStatusStyleEnum[row[item.prop]]">{{ TaskWorkOrderStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
    <el-row style="margin: 20px 0; text-align: right">
      <!--      <ht-button type="primary" icon="el-icon-circle-check" @click="onExport">导出工单</ht-button>-->
    </el-row>

    <ht-table
      v-if="targetInfo && targetInfo.errorList && targetInfo.errorList.length > 0"
      ref="list"
      v-loading="isLoading"
      :data="steps"
    >
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in RepairWorkOrderResultListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
        :show-overflow-tooltip="false"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '故障代码'" class="cell-overflow">{{ row.properties.title || '-' }}</span>
          <span v-else-if="item.label === '处理方法'" class="cell-overflow">{{ row.properties.title || '-' }}</span>
          <span v-else-if="item.label === '补充处理方法'" class="cell-overflow">{{ row.properties.text || '-' }}</span>
          <div v-else-if="item.label === '图片'" class="result-img-container">
            <template v-if="row.results && row.results.errorRecords">
              <!--              <el-image-->
              <!--                v-for="(imgItem, imgIndex) in row.results.uploadPhotos"-->
              <!--                :key="imgIndex"-->
              <!--                :src="imgItem"-->
              <!--                :preview-src-list="row.results.uploadPhotos"-->
              <!--                class="img"-->
              <!--              />-->
            </template>
            <span v-else>{{ '-' }}</span>
          </div>
          <span v-else-if="item.label === '结果'" :style="getResultTextColor(row)" class="cell-overflow">
            <template v-if="row.tag === 'ResultRecordType'">
              {{ row.results.checkResult ? ResultsEnum[row.results.checkResult] : '-' }}
            </template>
            <template v-else-if="row.tag === 'ValueRecordType'">
              <span v-html=" getValueRecordResult(row)" />
            </template>
            <template v-else>
              {{ '-' }}
            </template>
          </span>
          <span v-else-if="item.label === '定位'">{{ row.results.location || '-' }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
    </ht-table>
  </el-card>
</template>

<script>
import { getWorkOrder, putWorkOrder } from '@/api/taskMgr/workOrder'
import { RepairWorkOrderListTitle, RepairWorkOrderResultListTitle } from '@/views/taskMgr/tableTitle'
import { LoopEnum, ResultsEnum, TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum } from '@/views/taskMgr/enum'
import { getLocation, getValueRecordResult, getResultTextColor } from '@/utils/get-display'
import { getTaskTempType } from '@/views/taskMgr/commonJs'

export default {
  props: {
    isMy: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    return {
      RepairWorkOrderListTitle, RepairWorkOrderResultListTitle,
      LoopEnum, TaskWorkOrderStatusEnum, TaskWorkOrderStatusStyleEnum, ResultsEnum,
      orderId: null,
      isLoading: false,
      workOrderList: [],
      workOrder: null,
      steps: [],
      targetInfo: {}
    }
  },
  created() {
    if (this.$route.params && this.$route.params.hasOwnProperty('orderId')) {
      this.orderId = this.$route.params.orderId
      this.getWorkOrderDetail()
    }
  },
  methods: {
    getResultTextColor, getValueRecordResult, getLocation, getTaskTempType,
    getWorkOrderDetail() {
      getWorkOrder(this.orderId).then(resp => {
        this.workOrderList = [resp]
        this.workOrder = resp
        this.steps = resp.steps
        this.targetInfo = resp.targetInfo
      })
    },
    onCancelLoop(row) {
      const data = {
        name: row.name,
        executorId: row.executorId,
        taskTemplateId: row.taskTemplateId,
        isLoop: false
      }
      putWorkOrder(row.id, data).then(() => {
        this.$message({ message: '操作成功', center: true, type: 'success' })
        this.getList()
      })
    },
    onExport() {}
  }
}
</script>

<style scoped lang="scss">
.result-img-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  .img{
    width: 80px;
  }
}
</style>
