var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-scene",
        {
          attrs: {
            "mindar-image": { imageTargetSrc: _vm.mindSrc, uiScanning: "no" },
            "color-space": "sRGB",
            renderer: "colorManagement: true, physicallyCorrectLights",
            "vr-mode-ui": "enabled: false",
            "device-orientation-permission-ui": "enabled: false",
          },
        },
        [
          _c("a-assets", [
            _c("img", { attrs: { id: "card", src: _vm.imgSrc } }),
          ]),
          _vm._v(" "),
          _c("a-camera", {
            attrs: { position: "0 0 0", "look-controls": "enabled: false" },
          }),
          _vm._v(" "),
          _c(
            "a-entity",
            { attrs: { "mindar-image-target": "targetIndex: 0" } },
            [
              _c("a-image", {
                attrs: {
                  src: _vm.imgSrc,
                  position: "0 0.552 0",
                  height: "0.552",
                  width: "1",
                  rotation: "0 0 0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }