<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          :item-background="getBackgroundColor(route)"
          :active-menu="activeMenu"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  methods: {
    getBackgroundColor(route) {
      let isActive = false
      isActive = this.isMenuActive(route, this.activeMenu)
      return isActive ? this.variables.menuActiveBg : this.variables.menuBg
    },
    isMenuActive(menu, activeMenu) {
      if (menu.path === activeMenu) {
        return true
      }

      if (menu.children && menu.children.length > 0) {
        for (let i = 0; i < menu.children.length; i++) {
          if (this.isMenuActive(menu.children[i], activeMenu)) {
            return true
          }
        }
      }

      return false
    },
    getStyle() {
      return `1px solid ${this.variables.sidebarLogoBg}`
    }
  }
}
</script>
