var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "equipment-stock-container",
      class: [{ "is-card-container": _vm.isCard }],
      on: { click: _vm.onView },
    },
    [
      _c(
        "div",
        { staticClass: "top-section" },
        [
          _c("div", { staticClass: "name" }, [
            _vm._v(
              "设备：" +
                _vm._s(
                  _vm.equipmentInfo &&
                    "" +
                      _vm.equipmentInfo.name +
                      (_vm.equipmentInfo.code
                        ? "-" + _vm.equipmentInfo.code
                        : "")
                )
            ),
          ]),
          _vm._v(" "),
          _vm.equipmentInfo && _vm.equipmentInfo.isWhole
            ? _c(
                "el-tag",
                {
                  staticClass: "status",
                  attrs: {
                    effect: "dark",
                    type: _vm.EquipmentLeaseStatusStyleEnum[
                      _vm.equipmentInfo.leaseStatus.name
                    ],
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.EquipmentLeaseStatusEnum[
                        _vm.equipmentInfo.leaseStatus.name
                      ] || "-"
                    )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middle-section" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "item spec-model" }, [
            _vm._v(
              "规格型号：" +
                _vm._s(
                  (_vm.equipmentInfo && _vm.equipmentInfo.specModel) || "-"
                )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item quantity" }, [
            _vm._v(
              "库存数：" +
                _vm._s(_vm.equipmentInfo && _vm.equipmentInfo.quantity)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "button" }, [_vm._v("查看详情>")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom-section" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }