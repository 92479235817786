import Layout from '@/layout'

const DeviceMgrRouter = {
  path: '/deviceMgr',
  component: Layout,
  redirect: '/deviceMgr/list',
  name: 'deviceMgrMenu',
  meta: { title: '设备数据demo', icon: 'device_mgr' },
  children: [
    {
      path: '/deviceMgr/list',
      component: () => import('@/views/deviceMgr/list'),
      hidden: true,
      name: 'deviceMgrList',
      meta: { title: '设备数据demo', breadcrumb: false, activeMenu: '/deviceMgr' }
    },
    {
      path: '/deviceMgr/view',
      redirect: '/deviceMgr/view/:deviceCode/:deviceId/:isSync',
      component: () => import('@/views/CommonRouterView'),
      hidden: true,
      props: true,
      name: 'deviceMgrView',
      children: [
        {
          path: '/deviceMgr/view/:deviceCode/:deviceId/:isSync',
          component: () => import('@/views/deviceMgr/view'),
          hidden: true,
          props: true,
          name: 'deviceMgrViewDetail',
          meta: { title: '设备详情', activeMenu: '/deviceMgr' }
        },
        {
          path: '/deviceMgr/view/:deviceCode/:deviceId/:bizType/:guidanceId',
          component: () => import('@/views/remoteGuidance/view'),
          hidden: true,
          props: true,
          name: 'deviceMgrViewCooperateWork',
          meta: { title: '协同作业详情', activeMenu: '/deviceMgr' }
        }
      ]
    }
  ]
}

export default DeviceMgrRouter
