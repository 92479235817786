var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outbound-container" },
    [
      _c("equipment-card", {
        attrs: {
          "is-loading": _vm.isEquipmentLoading,
          "equipment-info": _vm.equipmentInfo,
        },
      }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "outboundForm",
          staticClass: "mobile-form",
          attrs: { model: _vm.outboundForm, rules: _vm.outboundFormRules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "出库单：", prop: "attachmentData" } },
            [
              _vm.outboundForm.attachmentData
                ? _c("dw-file", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      url: _vm.outboundForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.outboundForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["doc", "pdf", "img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传出库单",
                      "button-type": "primary",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "收货方：", prop: "constructionProjectId" } },
            [
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isProjectLoading,
                      expression: "isProjectLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.equipmentInfo && _vm.equipmentInfo.isWhole,
                    placeholder: "收货方",
                    "popper-class": "mobile-select",
                  },
                  model: {
                    value: _vm.outboundForm.constructionProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.outboundForm, "constructionProjectId", $$v)
                    },
                    expression: "outboundForm.constructionProjectId",
                  },
                },
                _vm._l(_vm.projects, function (item) {
                  return _c("el-option", {
                    key: item.constructionProjectId,
                    attrs: {
                      label: item.constructionProjectName,
                      value: item.constructionProjectId,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.equipmentInfo && _vm.equipmentInfo.isWhole
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "出库仓库：", prop: "warehouseId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: "",
                          "popper-class": "mobile-select",
                        },
                        model: {
                          value: _vm.outboundForm.warehouseId,
                          callback: function ($$v) {
                            _vm.$set(_vm.outboundForm, "warehouseId", $$v)
                          },
                          expression: "outboundForm.warehouseId",
                        },
                      },
                      _vm._l(_vm.warehouseList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.warehouseId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "出库数量：", prop: "outboundQuantity" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "", placeholder: "请输入出库数量" },
                      model: {
                        value: _vm.outboundForm.outboundQuantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.outboundForm, "outboundQuantity", $$v)
                        },
                        expression: "outboundForm.outboundQuantity",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._l(_vm.warehouseList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "warehouse-card",
                    class: {
                      "selected-warehouse-card": item.outboundQuantity > 0,
                    },
                  },
                  [
                    _c("div", { staticClass: "left-item item" }, [
                      _c("span", { staticClass: "name top" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "quantity bottom" }, [
                        _vm._v("当前库存数：" + _vm._s(item.quantity)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right-item item" },
                      [
                        _c("span", { staticClass: "title top" }, [
                          _vm._v("出库数量"),
                        ]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "num bottom",
                          attrs: {
                            min: 0,
                            max: item.quantity,
                            disabled: item.quantity === 0,
                            placeholder: "请输入出库数量",
                            precision: 0,
                          },
                          model: {
                            value: item.outboundQuantity,
                            callback: function ($$v) {
                              _vm.$set(item, "outboundQuantity", $$v)
                            },
                            expression: "item.outboundQuantity",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-container" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认出库")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }