<template>
  <el-card v-loading="isLoading" style="min-height: 400px">
    <project-form
      v-if="projectData"
      ref="projectForm"
      is-view
      :is-edit="isEdit === '1' || isEdit === 1"
      :project-id="projectData.id"
      :project-data="projectData"
      @cancel-submit="cancelSubmit"
      @confirm-submit="confirmSubmit"
      @confirm-update="confirmUpdate"
    />
  </el-card>
</template>

<script>
import ProjectForm from '@/views/constructionProjectMgr/projectMgr/components/projectForm'
import { getProject } from '@/api/constructionProjectMgr/projectMgr'

export default {
  name: 'ProjectDetail',
  components: { ProjectForm },
  props: {
    projectId: { type: [String, Number], default: undefined },
    isEdit: { type: [String, Number], default: 0 }
  },
  data() {
    return {
      projectData: null,
      isLoading: false
    }
  },
  created() {
    this.getProjectDetail()
  },
  methods: {
    getProjectDetail() {
      this.isLoading = true
      getProject(this.projectId).then(resp => {
        this.isLoading = false
        resp.progressRatio = parseFloat((resp.progressRatio * 100).toFixed(2))
        this.projectData = resp
      }).catch(() => {
        this.isLoading = false
      })
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    confirmSubmit() {
      this.getProjectDetail()
    },
    confirmUpdate() {
      this.getProjectDetail()
    }
  }
}
</script>

<style scoped>

</style>
