<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import { debounce } from '@/utils'
import echarts from 'echarts'

export default {
  name: 'GanttChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true },
    xRotate: { type: Number, default: 45 },
    title: { type: String, default: '' },
    showToolBox: { type: Boolean, default: false },
    dataZoomType: { type: String, default: 'inside' }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(workOrders) {
      // 以下通过x轴value来做堆叠柱状图，x轴type不能用time，整个就是基于小时数来堆叠bar
      const minStartDate = new Date(Math.min(...workOrders.map(order => new Date(order.startDate).getTime())))
      minStartDate.setHours(0, 0, 0, 0) // 最小日期为最早开始节点当天0点

      // placeholder 空白占位堆叠数据，即开始时间 - 最小开始天数并转换为小时
      const placeholderData = workOrders.map(order => {
        const start = new Date(order.startDate).getTime()
        return (start - minStartDate.getTime()) / 3600000 // 转换为小时
      })

      // 实际工单持续时间，时间戳转换为持续的小时数
      const timeData = workOrders.map(order => {
        const start = new Date(order.startDate).getTime()
        const end = new Date(order.endDate).getTime()
        const time = (end - start) / 3600000
        return time < 0.01 ? 0.01 : parseFloat(time.toFixed(2)) // 转换为小时，保留两位小数，小于0.01的修改为0.01
      })

      const isWithinLimit = (Math.max(...timeData) - Math.min(...timeData)) <= 24
      // const xAxisLabel = isWithinLimit ? 'yy年MM月dd日 hh时' : 'yy年MM月dd日'
      const xAxisLabel = isWithinLimit ? 'yyyy-MM-dd hh:mm' : 'yyyy-MM-dd'

      const option = {
        title: {
          text: this.title,
          top: 20,
          left: 'center'
        },
        dataZoom: [
          {
            type: this.dataZoomType,
            yAxisIndex: 0,
            left: 0
          },
          {
            type: this.dataZoomType,
            xAxisIndex: 0
          }
        ],
        toolbox: {
          show: this.showToolBox,
          feature: {
            restore: {}
          }
        },
        grid: {
          left: '10%', // 左边距
          bottom: '10%', // 下边距
          containLabel: true // 是否包含坐标轴标签
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(value) {
              // 最小开始日期 + 小时数转换回时间戳，并转换为x轴标签日期
              const timestamp = minStartDate.getTime() + value * 3600000
              // 格式化时间戳为日期字符串
              return echarts.format.formatTime(xAxisLabel, new Date(timestamp))
            },
            rotate: this.xRotate // 设置标签旋转角度为45度
          },
          min: 0 // x轴只能使用value来做堆叠bar图，所以这里是0小时，而不是minStartDate
        },
        yAxis: {
          type: 'category',
          data: workOrders.map(item => item.name),
          inverse: true
        },

        // 添加 tooltip 配置, 对有效持续时间bar鼠标浮动时显示内容
        tooltip: {
          formatter: params => {
            if (params.seriesName === 'time') {
              // 确保只有在 'time' series 上悬停时才显示 tooltip
              const order = workOrders[params.dataIndex]
              const startTime = echarts.format.formatTime('yyyy-MM-dd hh:mm:ss', new Date(order.startDate))
              const endTime = echarts.format.formatTime('yyyy-MM-dd hh:mm:ss', new Date(order.endDate))
              return order.name + '<br/>开始时间: ' + startTime + '<br/>结束时间: ' + endTime + '<br/>持续: ' + params.value + '小时'
            }
          }
        },

        // 每个bar通过空白图+实际持续时间2部分堆叠而成
        series: [
          // 透明占位堆叠数据，bar只能从坐标轴作起点，堆叠柱状图空白占位
          {
            name: 'placeholder',
            type: 'bar',
            stack: 'total',
            itemStyle: {
              color: 'transparent',
              borderColor: 'transparent'
            },
            cursor: 'default',
            data: placeholderData
          },
          // 实际持续时间，位于空白堆叠的bar后方
          {
            name: 'time',
            type: 'bar',
            stack: 'total',
            barWidth: 20,
            itemStyle: {
              borderRadius: 5,
              color: '#589EF6'
            },
            barMinHeight: 1,
            data: timeData
          }
        ]
      }
      this.initChart(option)
    }
  }
}
</script>

<style scoped>

</style>
