<template>
  <div>
    <el-form ref="moveCategoryForm" :model="moveCategoryForm" :rules="moveCategoryFormRules" label-width="100px">
      <el-form-item label="目录名称：" prop="name">
        <el-input v-model="moveCategoryForm.name" placeholder="请输入目录名称" :maxlength="10" show-word-limit />
      </el-form-item>
      <div class="info-container">
        <i style="color: #f56c6c" class="el-icon-warning" />
        <span>将</span>
        <span class="info">{{ ` ${moveCategoryForm.name} ` }}</span>
        <span>移动到</span>
        <span class="info">{{ ` ${ selectedCategory ? selectedCategory.name : '' } ` }}</span>
        <span v-if="selectedCategory">下</span>
      </div>
      <single-check-tree :tree-list="getMoveTree" :hint-node-id="categoryData.parentId" hint-txt="当前父级目录" @node-click="handleClickTreeNode" />
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('moveCategoryForm')">保存</el-button>
    </div>
  </div>
</template>

<script>
import SingleCheckTree from '@/views/components/tree/singleCheckTree'
import _ from 'lodash'
import { putDocumentCategory } from '@/api/knowleageBaseMgr/documentCategoryMgr'
export default {
  name: 'MoveCategoryForm',
  components: { SingleCheckTree },
  props: {
    tree: { type: Array, default: () => [] },
    categoryData: { type: Object, default: () => {} }
  },
  data() {
    return {
      moveCategoryForm: {
        parentId: null,
        name: this.categoryData.name,
        level: 0
      },
      moveCategoryFormRules: {
        name: [{ required: true, message: '请输入目录名称', trigger: 'blur' }],
        parentId: [{ required: true, message: '请选择所属目录', trigger: 'blur' }]
      },
      selectedCategory: null,
      isSubmitting: false,
      selectedNodeId: null
    }
  },
  computed: {
    getMoveTree() {
      const tree = _.cloneDeep(this.tree)
      tree.forEach(node => this.updateNode(node, this.categoryData, this.selectedNodeId))
      return tree
    }
  },
  methods: {
    updateNode(node, categoryData, selectedNodeId) {
      // 设置节点的基本属性
      node.couldSelected = node.isDefault ? false : node.level === 0 && node.id !== categoryData.parentId
      node.checked = selectedNodeId === node.id

      // 如果节点有子节点，递归更新
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => this.updateNode(child, categoryData, selectedNodeId))
      }

      // 根据层级和ID更新couldSelected和checked属性
      if (node.level === 1) {
        node.couldSelected = node.isDefault ? false : node.id !== categoryData.id && node.id !== categoryData.parentId
      } else if (node.level === 2) {
        node.couldSelected = false
      }
    },
    handleClickTreeNode(node, data) {
      this.selectedNodeId = data.checked ? data.id : null
      this.selectedCategory = data.checked ? data : null
    },
    getMoveCategoryForm() {
      this.moveCategoryForm.id = this.categoryData && this.categoryData.id
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      this.moveCategoryForm.parentId = this.selectedNodeId
      this.moveCategoryForm.level = this.selectedCategory.level + 1
      if (this.moveCategoryForm.parentId) {
        this.isSubmitting = true
        putDocumentCategory(this.categoryData.id, this.moveCategoryForm).then(() => {
          this.$message({ message: `修改成功`, type: 'success', center: true })
          this.$emit('confirm-submit')
          this.isSubmitting = false
        }).catch(() => {
          this.isSubmitting = false
        })
      } else {
        this.$message({ message: `请选择所属目录`, type: 'error', center: true })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.info-container{
  padding: 0 5px;
  .info{
    color: #f56c6c;
    font-weight: bold;
  }
}
</style>
