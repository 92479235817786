var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.workOrderList },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.RepairWorkOrderListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "工单类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTaskTempType(row[item.prop])
                                    ? _vm.getTaskTempType(row[item.prop]).value
                                    : "维修"
                                )
                              ),
                            ])
                          : item.label === "创建人"
                          ? _c("span", [_vm._v(_vm._s(row.staff.fullName))])
                          : item.label === "执行人"
                          ? _c("span", [_vm._v(_vm._s(row.executor.fullName))])
                          : item.label === "状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.TaskWorkOrderStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.TaskWorkOrderStatusEnum[
                                      row[item.prop]
                                    ] || "-"
                                  )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("el-row", {
        staticStyle: { margin: "20px 0", "text-align": "right" },
      }),
      _vm._v(" "),
      _vm.targetInfo &&
      _vm.targetInfo.errorList &&
      _vm.targetInfo.errorList.length > 0
        ? _c(
            "ht-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              ref: "list",
              attrs: { data: _vm.steps },
            },
            [
              _c("ht-table-column", {
                attrs: { label: "序号", type: "index", fixed: "left" },
              }),
              _vm._v(" "),
              _vm._l(_vm.RepairWorkOrderResultListTitle, function (item) {
                return _c("ht-table-column", {
                  key: item.prop,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    "min-width": item.width || 100,
                    fixed: item.fixed,
                    "show-overflow-tooltip": false,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label === "故障代码"
                              ? _c("span", { staticClass: "cell-overflow" }, [
                                  _vm._v(_vm._s(row.properties.title || "-")),
                                ])
                              : item.label === "处理方法"
                              ? _c("span", { staticClass: "cell-overflow" }, [
                                  _vm._v(_vm._s(row.properties.title || "-")),
                                ])
                              : item.label === "补充处理方法"
                              ? _c("span", { staticClass: "cell-overflow" }, [
                                  _vm._v(_vm._s(row.properties.text || "-")),
                                ])
                              : item.label === "图片"
                              ? _c(
                                  "div",
                                  { staticClass: "result-img-container" },
                                  [
                                    row.results && row.results.errorRecords
                                      ? void 0
                                      : _c("span", [_vm._v(_vm._s("-"))]),
                                  ],
                                  2
                                )
                              : item.label === "结果"
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "cell-overflow",
                                    style: _vm.getResultTextColor(row),
                                  },
                                  [
                                    row.tag === "ResultRecordType"
                                      ? [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                row.results.checkResult
                                                  ? _vm.ResultsEnum[
                                                      row.results.checkResult
                                                    ]
                                                  : "-"
                                              ) +
                                              "\n          "
                                          ),
                                        ]
                                      : row.tag === "ValueRecordType"
                                      ? [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getValueRecordResult(row)
                                              ),
                                            },
                                          }),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s("-") +
                                              "\n          "
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : item.label === "定位"
                              ? _c("span", [
                                  _vm._v(_vm._s(row.results.location || "-")),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(row[item.prop] || "-")),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }