var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "tree-list-container",
    },
    [
      _c("el-tree", {
        ref: _vm.treeRef,
        attrs: {
          id: _vm.treeId,
          data: _vm.treeList,
          props: _vm.defaultProps,
          "node-key": "id",
          "current-node-key": _vm.currentNodeKey,
          "default-expand-all": "",
          "highlight-current": "",
          "expand-on-click-node": false,
        },
        on: {
          "node-collapse": function ($event) {
            return _vm.$emit("click-outside")
          },
          "node-expand": function ($event) {
            return _vm.$emit("click-outside")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c(
                "div",
                {
                  ref: node.id,
                  staticClass: "custom-tree-node",
                  class: { "is-checked": data.checked },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "node-name",
                      on: {
                        click: function ($event) {
                          return _vm.nodeClick(node, data, true)
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(node[_vm.treeName]) + "\n        "),
                      data.id === _vm.hintNodeId
                        ? _c("span", { staticClass: "node-hint" }, [
                            _vm._v("（" + _vm._s(_vm.hintTxt) + "）"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  data.couldSelected
                    ? _c(
                        "el-checkbox",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.handleCheck(node, data)
                            },
                          },
                          model: {
                            value: data.checked,
                            callback: function ($$v) {
                              _vm.$set(data, "checked", $$v)
                            },
                            expression: "data.checked",
                          },
                        },
                        [_vm._v("选择该目录")]
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }