<template>
  <div>
    <template-view ref="createTemplate" />
  </div>
</template>

<script>
import TemplateView from '@/views/incomingInspectionMgr/nskTemplateMgr/components/templateView'
export default {
  name: 'Create',
  components: { TemplateView }
}
</script>

<style scoped>

</style>
