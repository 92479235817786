var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isImportLoading,
          expression: "isImportLoading",
        },
      ],
      staticClass: "knowledge-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "knowledgeForm",
          attrs: {
            model: _vm.knowledgeForm,
            rules: _vm.knowledgeFormRules,
            "label-width": "70px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入标题",
                  maxlength: 50,
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.knowledgeForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.knowledgeForm, "title", $$v)
                  },
                  expression: "knowledgeForm.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "content-item",
              attrs: { label: "正文：", prop: "content" },
            },
            [
              _vm.getFileTypeByExtension(_vm.knowledgeForm.sourceUrl) === "pdf"
                ? [
                    _c("span", { staticClass: "pdf-info" }, [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _vm._v(" 该内容为pdf导入生成，不支持修改"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "pdf-content",
                      style: { height: _vm.contentHeight - 20 + "px" },
                      domProps: {
                        innerHTML: _vm._s(_vm.knowledgeForm.content),
                      },
                    }),
                  ]
                : _vm.getFileTypeByExtension(_vm.knowledgeForm.sourceUrl) !==
                  "pdf"
                ? _c("dw-tinymce-editor", {
                    ref: "tinymceEditor",
                    model: {
                      value: _vm.knowledgeForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.knowledgeForm, "content", $$v)
                      },
                      expression: "knowledgeForm.content",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "附件：", prop: "attachmentData" } },
            [
              _vm._l(_vm.knowledgeForm.attachmentData, function (item, index) {
                return _c("dw-file", {
                  key: index,
                  staticStyle: { margin: "5px 0" },
                  attrs: {
                    url: item,
                    "show-del": "",
                    "justify-content": "left",
                    "need-margin": false,
                  },
                  on: {
                    "delete-file": function ($event) {
                      return _vm.onDeleteAttachment(index)
                    },
                  },
                })
              }),
              _vm._v(" "),
              _c("ht-upload-button", {
                attrs: {
                  "file-type": ["doc", "pdf", "excel", "img", "zip", ""],
                  "key-prefix": "knowledge",
                  "multiple-selection": "",
                  "button-title": "上传附件",
                },
                on: { "on-files-uploaded": _vm.onFilesUploaded },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "封面：", prop: "coverImg" } },
            [
              [
                _vm.knowledgeForm.coverImg
                  ? _c("dw-image", {
                      attrs: { url: _vm.knowledgeForm.coverImg },
                      on: { "delete-img": _vm.onDeleteImage },
                    })
                  : _c("ht-upload-button", {
                      attrs: {
                        "file-type": ["img"],
                        "key-prefix": "workflow",
                        "select-img": "",
                      },
                      on: { "on-file-uploaded": _vm.onImageUploaded },
                    }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-container" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", icon: "el-icon-view" },
                  on: {
                    click: function ($event) {
                      return _vm.togglePreviewDialog(true)
                    },
                  },
                },
                [_vm._v("预览")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading:
                      _vm.isSubmitting && _vm.knowledgeForm.status === "DRAFT",
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit("knowledgeForm", "DRAFT")
                    },
                  },
                },
                [_vm._v("保存为草稿")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading:
                      _vm.isSubmitting &&
                      _vm.knowledgeForm.status === "PUBLISHED",
                    type: "primary",
                    icon: "el-icon-s-promotion",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit("knowledgeForm", "PUBLISHED")
                    },
                  },
                },
                [_vm._v("发布")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "knowledgePreviewDialog",
          staticClass: "knowledge-preview-dialog",
          attrs: {
            title: "预览",
            width: "1000px",
            fullscreen: _vm.isFullscreenKnowledgePreviewDialog,
          },
          on: {
            close: function ($event) {
              return _vm.togglePreviewDialog(false)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "close" }, slot: "close" }, [
            _vm.isFullscreenKnowledgePreviewDialog
              ? _c("img", {
                  staticClass: "maximize fullscreen-item",
                  attrs: { src: _vm.ExitFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgePreviewDialog },
                })
              : _c("img", {
                  staticClass: "maximize fullscreen-item",
                  attrs: { src: _vm.EnterFul, alt: "" },
                  on: { click: _vm.toggleFullscreenKnowledgePreviewDialog },
                }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showKnowledgePreview
                ? _c("knowledge-preview", {
                    ref: "knowledgePreview",
                    attrs: { "show-button": false },
                    on: {
                      "on-close": function ($event) {
                        return _vm.togglePreviewDialog(false)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }