var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "el-input-number",
        _vm.inputNumberSize ? "el-input-number--" + _vm.inputNumberSize : "",
        { "is-disabled": _vm.inputNumberDisabled },
        { "is-without-controls": !_vm.controls },
        { "is-controls-right": _vm.controlsAtRight },
      ],
      on: {
        dragstart: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm.controls
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "repeat-click",
                  rawName: "v-repeat-click",
                  value: _vm.decrease,
                  expression: "decrease",
                },
              ],
              staticClass: "el-input-number__decrease",
              class: { "is-disabled": _vm.minDisabled },
              attrs: { role: "button" },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.decrease($event)
                },
              },
            },
            [
              _c("i", {
                class:
                  "el-icon-" + (_vm.controlsAtRight ? "arrow-down" : "minus"),
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.controls
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "repeat-click",
                  rawName: "v-repeat-click",
                  value: _vm.increase,
                  expression: "increase",
                },
              ],
              staticClass: "el-input-number__increase",
              class: { "is-disabled": _vm.maxDisabled },
              attrs: { role: "button" },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.increase($event)
                },
              },
            },
            [
              _c("i", {
                class: "el-icon-" + (_vm.controlsAtRight ? "arrow-up" : "plus"),
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-input", {
        ref: "input",
        attrs: {
          value: _vm.displayValue,
          placeholder: _vm.placeholder,
          disabled: _vm.inputNumberDisabled,
          size: _vm.inputNumberSize,
          max: _vm.max,
          min: _vm.min,
          name: _vm.name,
          label: _vm.label,
        },
        on: {
          blur: _vm.handleBlur,
          focus: _vm.handleFocus,
          input: _vm.handleInput,
          change: _vm.handleInputChange,
        },
        nativeOn: {
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.increase($event)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.decrease($event)
            },
          ],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }