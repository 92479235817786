var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "div",
        {
          staticClass: "freeze-guidance-container",
          class: {
            "fullscreen-mobile-freeze-guidance-container":
              _vm.fullscreen && _vm.isMobile,
          },
          style: {
            top: _vm.enLargeVideoRect.top + "px",
            left: _vm.fullscreen ? _vm.enLargeVideoRect.left + "px" : "20px",
            width: _vm.getFreezeGuidanceImgWidth + 10 + "px",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("div", { staticClass: "title-text-container" }, [
              _c("img", {
                staticClass: "img",
                attrs: { src: _vm.Guidance, alt: "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "label" }, [_vm._v("标注指导")]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "：" +
                    _vm._s(
                      _vm.frozenGuidanceInfo &&
                        _vm.frozenGuidanceInfo.frozenFullName
                        ? _vm.frozenGuidanceInfo.frozenFullName
                        : ""
                    )
                ),
              ]),
              _vm._v(" "),
              !_vm.isSinoma
                ? _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.frozenGuidanceInfo &&
                          _vm.frozenGuidanceInfo.frozenUserName &&
                          !_vm.stringLongOverTen(
                            _vm.frozenGuidanceInfo.frozenUserName
                          )
                          ? "（" + _vm.frozenGuidanceInfo.frozenUserName + "）"
                          : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title-button-container" }, [
              _vm.isExpect
                ? _c("img", {
                    staticClass: "close item",
                    attrs: { src: _vm.Close, alt: "" },
                    on: { click: _vm.closeDialog },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "content-container",
            },
            [
              _c("div", { staticClass: "paint-container" }, [
                _c("img", {
                  ref: "freezeImgRef",
                  staticClass: "freeze-img",
                  style: { width: _vm.getFreezeGuidanceImgWidth + "px" },
                  attrs: {
                    id: _vm.id,
                    src: _vm.freezeImgUrl,
                    alt: "",
                    crossorigin: "",
                  },
                }),
                _vm._v(" "),
                _c("canvas", {
                  staticClass: "freeze-canvas",
                  attrs: {
                    id: "freezeCanvas",
                    width: _vm.getFreezeGuidanceImgWidth,
                    height: _vm.getFreezeGuidanceImgHeight,
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.isExpect
                ? _c(
                    "div",
                    { staticClass: "operating-container" },
                    [
                      _c("operating-button", {
                        staticClass: "item",
                        attrs: {
                          name: "激光笔",
                          icon: _vm.LaserPenBlue,
                          "is-show-dot": _vm.isLaserMoving,
                        },
                        on: { click: _vm.laserButtonClick },
                      }),
                      _vm._v(" "),
                      _c("operating-button", {
                        staticClass: "item",
                        attrs: {
                          width: 70,
                          name: "实时画笔",
                          icon: _vm.Paintbrush,
                          "is-show-dot": _vm.isPaintDrawing,
                        },
                        on: { click: _vm.paintButtonClick },
                      }),
                      _vm._v(" "),
                      _c("operating-button", {
                        staticClass: "item",
                        attrs: { width: 70, name: "清除轨迹", icon: _vm.Clear },
                        on: { click: _vm.clearButtonClick },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }