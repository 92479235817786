export const KnowledgeMgrListTitle = [
  { label: '标题', prop: 'title', width: 150 },
  { label: '附件', prop: 'attachmentData', width: 80 },
  { label: '创建人', prop: 'staff' },
  { label: '创建时间', prop: 'createdAt', width: 160 },
  { label: '发布人', prop: 'publishedStaff' },
  { label: '发布时间', prop: 'publishedAt', width: 160 },
  { label: '浏览次数', prop: 'viewNum', width: 80 },
  { label: '状态', prop: 'status', fixed: 'right', width: 80 }
]

export const AttachmentListTitle = [
  { label: '附件名称', prop: 'fileName', width: 120 }
]
