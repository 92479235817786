import MobileLayout from '@/layout/mobileLayout'

const MobileStockMgr = {
  path: '/mobile/mobileStockMgr',
  component: MobileLayout,
  hidden: true,
  children: [
    {
      path: 'mobile/mobileStockMgr/list',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/list'),
      meta: { title: '库存管理', params: { showMainBottom: false, prevMenu: 'MobileHomePage' }},
      name: 'MobileStockMgrList'
    },
    {
      path: 'mobile/mobileStockMgr/equipmentStockView/:equipmentId',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/equipmentStockView'),
      meta: { title: '库存详情', params: { showMainBottom: false, prevMenu: 'MobileStockMgrList', tab: 'equipment' }},
      name: 'MobileStockMgrEquipmentStockView',
      props: true
    },
    {
      path: 'mobile/mobileStockMgr/equipmentOutbound/:equipmentId',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/equipmentOutbound'),
      meta: { title: '出库', params: { showMainBottom: false, prevMenu: 'MobileStockMgrList', tab: 'equipment' }},
      name: 'MobileStockMgrEquipmentOutbound',
      props: true
    },
    {
      path: 'mobile/mobileStockMgr/equipmentInbound/:equipmentId',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/equipmentInbound'),
      meta: { title: '入库', params: { showMainBottom: false, prevMenu: 'MobileStockMgrList', tab: 'equipment' }},
      name: 'MobileStockMgrEquipmentInbound',
      props: true
    },
    {
      path: 'mobile/mobileStockMgr/warehouseStockView/:warehouseId/:warehouseName',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/warehouseStockView'),
      meta: { title: '库存详情', params: { showMainBottom: false, prevMenu: 'MobileStockMgrList', tab: 'warehouse' }},
      name: 'MobileStockMgrWarehouseStockView',
      props: true
    },
    {
      path: 'mobile/mobileStockMgr/warehouseOutbound/:warehouseId/:warehouseName',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/warehouseOutbound'),
      meta: { title: '出库', params: { showMainBottom: false, prevMenu: 'MobileStockMgrList', tab: 'warehouse' }},
      name: 'MobileStockMgrWarehouseOutbound',
      props: true
    },
    {
      path: 'mobile/mobileStockMgr/warehouseInbound/:warehouseId/:warehouseName',
      component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/warehouseInbound'),
      meta: { title: '入库', params: { showMainBottom: false, prevMenu: 'MobileStockMgrList', tab: 'warehouse' }},
      name: 'MobileStockMgrWarehouseInbound',
      props: true
    }
  ]
}

export default MobileStockMgr
