var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "home-page white-color gradient-text-two" },
      [
        _c("div", { staticClass: "box" }, [
          _c("p", { staticClass: "shift-color" }, [
            _vm._v("\n      调起远程指导中,请稍等...\n    "),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }