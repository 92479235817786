<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container"
    :style="{height: height + 'px'}"
    :class="{'is-used-step-container': !isEdit}"
    title="hf_use_description"
  >
    <confirm-box-inbound
      v-if="showList"
      ref="confirmBoxInbound"
      :height="getMiddleHeight"
      :results="submitResults"
      :disabled="buttonDisabled"
      @choose-warehouse="chooseWarehouse"
      @to-scan-code="toScanCode"
      @to-inbound="toInbound"
      @del-box="delBox"
    />
    <execute-goods-inbound
      v-else-if="!showList && showGoods"
      ref="executeGoodsInbound"
      :height="getMiddleHeight"
      :results="submitResults"
      :disabled="buttonDisabled"
      @to-prev-box="toPervBox"
      @to-next-box="toNextBox"
      @to-inbound-next-box="toInboundNextBox"
      @to-inbound="toInbound"
      @to-scan-code="toScanCode"
    />
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import ExecuteGoodsInbound from './executeGoodsInbound'
import ConfirmBoxInbound from './confirmBoxInbound'

export default {
  name: 'GoodsInbound',
  components: { ConfirmBoxInbound, ExecuteGoodsInbound },
  mixins: [StepUiMixin],
  props: {
    stepName: { type: String, default: '' },
    properties: { type: Object, default() { return { title: null } } },
    results: { type: Object, default() {
      return {
        boxes: [],
        warehouse: null,
        warehouseName: null,
        carCode: null
      }
    } }
  },
  data() {
    return {
      showList: true,
      showGoods: false,
      scanType: null
    }
  },
  computed: {
    getMiddleHeight() {
      return this.height - 100
    },
    getUnboundBoxes() {
      return this.submitResults.boxes && this.submitResults.boxes.filter(item => !item.isInbound) || []
    }
  },
  watch: {
    showList() {
      this.playTTS()
    }
  },
  created() {
    setTimeout(() => {
      this.playTTS()
    })
  },
  methods: {
    chooseWarehouse(results) {
      this.submitResults = results
      this.playTTS()
    },
    toScanCode(type, results) {
      this.submitResults = results
      this.scanType = type
      this.saveRecord()
      this.nativeScanCode()
    },
    setScanCodeResult() {
      if (this.scanType === 'car') {
        this.showList = true
        this.submitResults.carCode = `车-${this.getRandomNumber(6)}`
        this.playTTS()
      } else if (this.scanType === 'box') {
        this.showList = true
        this.submitResults.boxes.push({ code: `A-${this.getRandomNumber(8)}`, isInbound: false })
      } else if (this.scanType === 'goods-box') {
        this.showList = false
        const unboundedBoxes = this.submitResults.boxes && this.submitResults.boxes.filter(item => !item.isInbound) || []
        const randomUnboundBox = unboundedBoxes[Math.floor(Math.random() * unboundedBoxes.length)]
        if (randomUnboundBox) {
          this.$refs.executeGoodsInbound.setScanCodeResult('goods-box', randomUnboundBox.code)
          const ttsText = this.$refs.executeGoodsInbound.getTtsText(true, true)
          this.nativePlayTTS(ttsText)
        } else {
          this.$message({ message: `箱号错误`, duration: 2000, type: 'error', customClass: 'large-info-message' })
        }
      } else if (this.scanType === 'goods-storage') {
        this.showList = false
        if (this.submitResults.warehouse && this.submitResults.warehouse.storageLocations && this.submitResults.warehouse.storageLocations.length > 0) {
          const storageLocations = this.submitResults.warehouse.storageLocations.filter(item => item.capacity > item.quantity && item.cellList.filter(item => !item.isInbound).length > 0) || []
          const randomStorageLocation = storageLocations[Math.floor(Math.random() * storageLocations.length)]
          if (randomStorageLocation) {
            this.$refs.executeGoodsInbound.setScanCodeResult('goods-storage', randomStorageLocation)
            const ttsText = this.$refs.executeGoodsInbound.getTtsText(true, false)
            this.nativePlayTTS(ttsText)
          } else {
            this.$message({ message: `当前无可存放库位`, duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
        }
      }
    },
    delBox(code) {
      const boxes = this.submitResults.boxes
      const index = boxes.findIndex(item => item.code === code)
      if (index !== -1) {
        boxes.splice(index, 1)
      }
      this.submitResults.boxes = boxes
      this.saveRecord()
    },
    toInbound(type, results) {
      this.submitResults = results
      if (this.getUnboundBoxes.length === 0) {
        this.nativePlayTTS('当前无待上架物品')
        setTimeout(() => {
          this.submitRecord()
        }, 2000)
      } else {
        this.scanType = null
        this.showList = type === 'goods'
        this.showGoods = type === 'box'
      }
    },
    toInboundNextBox(results) {
      this.submitResults = results
      this.showGoods = false
      setTimeout(() => {
        this.showGoods = true
        this.playTTS()
      })
    },
    toPervBox(results, currentBoxIndex) {
      this.submitResults = results
      this.showGoods = false
      setTimeout(() => {
        this.showGoods = true
        this.$nextTick(() => {
          this.$refs.executeGoodsInbound.currentBoxIndex = currentBoxIndex
          this.playTTS()
        })
      })
    },
    toNextBox(results, currentBoxIndex) {
      this.submitResults = results
      this.showGoods = false
      setTimeout(() => {
        this.showGoods = true
        this.$nextTick(() => {
          this.$refs.executeGoodsInbound.currentBoxIndex = currentBoxIndex
          this.playTTS()
        })
      })
    },
    getRandomNumber(length) {
      let result = ''
      const characters = '0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    playTTS() {
      if (this.showList) {
        this.$nextTick(() => {
          const ttsText = this.$refs.confirmBoxInbound.getTtsText()
          this.nativePlayTTS(ttsText)
        })
      } else if (this.showGoods) {
        this.$nextTick(() => {
          const ttsText = this.$refs.executeGoodsInbound.getTtsText()
          this.nativePlayTTS(ttsText)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
