var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-container" },
    [
      _c("el-image", {
        staticClass: "image",
        attrs: { "preview-src-list": [_vm.url], src: _vm.url, fit: "contain" },
      }),
      _vm._v(" "),
      _vm.showDel
        ? _c("ht-button", {
            staticClass: "del-button",
            attrs: { type: "danger", icon: "el-icon-delete", circle: "" },
            on: { click: _vm.deleteImg },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }