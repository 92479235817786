<script>
import { PatrolWorkOrderListTitle, PatrolWorkOrderResultListTitle } from '@/views/taskMgr/tableTitle'
import { getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import {
  AccidentTypeEnum,
  FileUploadStatusEnum,
  LoopEnum,
  ResultsEnum,
  TaskWorkOrderStatusEnum
} from '@/views/taskMgr/enum'
import _ from 'lodash'
import { getHiddenDangerRecordResult, getValueRecordResult } from '@/utils/get-display'

export default {
  name: 'ExportWorkOrderExcel',
  methods: {
    getValueRecordResult,
    getHiddenDangerRecordResult,
    async onExportExcel() {
      this.isExporting = true
      // 创建工作簿
      const workbook = new this.ExcelJS.Workbook()

      // 添加工作表
      const worksheet1 = workbook.addWorksheet('工单信息')
      const worksheet2 = workbook.addWorksheet('工单步骤信息')

      // 设置每一列的宽度
      // worksheet1
      worksheet1.getColumn('A').width = 10 // 序号
      worksheet1.getColumn('B').width = 15 // 工单编号
      worksheet1.getColumn('C').width = 20 // 工单名称
      worksheet1.getColumn('D').width = 15 // 作业类型
      worksheet1.getColumn('E').width = 30 // 工程
      worksheet1.getColumn('F').width = 20 // 整机设备名称
      worksheet1.getColumn('G').width = 20 // 整机产权编号
      worksheet1.getColumn('H').width = 20 // 工作流程
      worksheet1.getColumn('I').width = 20 // 创建人
      worksheet1.getColumn('J').width = 20 // 创建时间
      worksheet1.getColumn('K').width = 20 // 执行人
      worksheet1.getColumn('L').width = 20 // 开始执行时间
      worksheet1.getColumn('M').width = 20 // 完成时间
      worksheet1.getColumn('N').width = 15 // 循环周期
      worksheet1.getColumn('O').width = 10 // 异常项
      worksheet1.getColumn('P').width = 15 // 文件上传状态
      worksheet1.getColumn('Q').width = 15 // 工单执行状态

      // worksheet2
      worksheet2.getColumn('A').width = 10 // 序号
      worksheet2.getColumn('B').width = 15 // 步骤名称
      worksheet2.getColumn('C').width = 30 // 检查要求
      worksheet2.getColumn('D').width = 15 // 图片
      worksheet2.getColumn('E').width = 30 // 情况描述
      worksheet2.getColumn('F').width = 30 // 结果
      worksheet2.getColumn('G').width = 20 // 定位
      worksheet2.getColumn('H').width = 20 // 上传时间

      // 定义表头
      const headers1 = this.getDetailTitle()
      const headers2 = this.getStepsTitle()

      // 处理数据
      const data1 = this.getDetailData()
      const data2 = this.getStepData()

      // 加载图片并转换为 base64 编码
      for (let i = 0; i < data2.length; i++) {
        const item = data2[i]
        if (item['图片']) {
          const imageUrls = item['图片'].split('\n')
          const imagePromises = imageUrls.map((url) => this.loadImage(url))
          const images = await Promise.all(imagePromises)
          item['图片'] = images.map((image) => this.convertImageToBase64(image))
        }
      }

      // 将数据添加到工作表中
      worksheet1.addRow(headers1)
      data1.forEach((item) => {
        worksheet1.addRow(Object.values(item))
      })
      worksheet2.addRow(headers2)
      data2.forEach((item) => {
        worksheet2.addRow(Object.values(item))
      })

      // 加载图片并将其添加到工作表中
      const imgColumn = 'D' // 指定要导出图片的列
      const rowHeight = 50 // 设置包含图片的行的行高

      const resultColumn = 'F' // 指定要显示结果的列表

      for (let i = 0; i < data2.length; i++) {
        const item = data2[i]
        if (item['图片']) {
          const images = item['图片'].map((base64Data) => this.convertBase64ToBuffer(base64Data))
          const row = worksheet2.getRow(i + 2) // 获取包含图片的行的行对象
          row.height = rowHeight * images.length
          row.width = 70
          for (let j = 0; j < images.length; j++) {
            const img = images[j]
            const imageId = workbook.addImage({
              buffer: img,
              extension: 'jpeg'
            })

            const cell = worksheet2.getCell(`${imgColumn}${i + 2}`)
            cell.value = '' // 清空单元格的值
            const columnIndex = imgColumn.charCodeAt(0) - 65

            // 计算图片的放置位置
            worksheet2.addImage(imageId, {
              tl: {
                col: columnIndex + 0.01,
                row: i + 1 + j * (1 / images.length) + 0.01
              },
              ext: { width: 67, height: 50 }
            })
          }
          item['图片'] = '' // 清空图片所在列的文字内容
        }

        // 将<br>转为\n
        const text = item['结果'].replace(/<br>/g, '\n')
        const cell = worksheet2.getCell(`${resultColumn}${i + 2}`)
        cell.value = text
        const arr1 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q']
        arr1.forEach(item => {
          worksheet1.getCell(`${item}${i + 2}`).alignment = {
            horizontal: 'justify'
          }
        })

        const arr2 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
        arr2.forEach(item => {
          worksheet2.getCell(`${item}${i + 2}`).alignment = {
            horizontal: 'justify'
          }
        })
      }

      // 保存工作簿为 Excel 文件并下载
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const fileName = `${this.workOrderList[0].name}-${this.workOrderList[0].code}.xlsx`
      this.FileSaver.saveAs(blob, fileName)
      this.isExporting = false
    },
    getDetailTitle() {
      const title = ['序号']
      PatrolWorkOrderListTitle.forEach(item => {
        title.push(item.label)
      })
      return title
    },
    getStepsTitle() {
      const title = ['序号']
      PatrolWorkOrderResultListTitle.forEach(item => {
        title.push(item.label)
      })
      return title
    },
    getDetailData() {
      return this.workOrderList.map((item, index) => ({
        '序号': index + 1,
        '工单编号': item.code,
        '工单名称': item.name,
        '作业类型': getTowerWorkOrderType(item.towerWorkType) ? getTowerWorkOrderType(item.towerWorkType).value : '',
        '工程': item.targetInfo.projectName,
        '整机设备名称': item.targetName,
        '整机产权编号': item.targetCode,
        '工作流程': item.taskTemplateName,
        '创建人': item.staff.fullName,
        '创建时间': item.createdAt,
        '执行人': item.executor.fullName,
        '开始执行时间': item.startAt,
        '完成时间': item.finishAt,
        '循环周期': LoopEnum[item.isLoop],
        '异常项': item.abnormalCount + '项',
        '文件上传状态': FileUploadStatusEnum[item.fileUploadStatus],
        '工单执行状态': TaskWorkOrderStatusEnum[item.status]
      }))
    },
    getStepData() {
      const steps = _.cloneDeep(this.steps)
      steps.forEach((item, index) => {
        if (item.hasOwnProperty('children')) {
          steps.splice(index + 1, 0, ...item.children)
        }
      })
      return steps.map((item, index) => ({
        '序号': index + 1,
        '步骤名称': item.properties.title,
        '检查要求': item.properties.text,
        '图片': item.results.uploadPhotos && item.results.uploadPhotos.join('\n'),
        '情况描述': item.results.factDesc && item.results.factDesc.map((element, index) => {
          const lineNumber = index + 1 // 序号从 1 开始计数
          return `${lineNumber}. ${element}`
        }).join('；\n'),
        '结果': this.getStepCheckResult(item),
        '定位': item.results.location,
        '上传时间': item.uploadAt
      }))
    },
    getStepCheckResult(item) {
      if (item.tag === 'ResultRecordType') {
        const name = ''
        const value = item.results.checkResult ? ResultsEnum[item.results.checkResult] : '暂无'
        return name + value
      } else if (item.tag === 'ValueRecordType') {
        return this.getValueRecordResult(item)
      } else if (item.tag === 'HiddenDangerRecordType') {
        return this.getHiddenDangerRecordResult(item)
      } else if (item.tag === 'AccidentRecordType') {
        const name = ''
        const value = item.results.accidentType ? AccidentTypeEnum[item.results.accidentType] : '暂无'
        return name + value
      } else if (item.tag === 'QuickPatrolRecordType') {
        return item.isTitle ? '' : item.results.answer || '暂无'
      } else {
        return ''
      }
    },
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = 'Anonymous'
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = url
      })
    },
    convertImageToBase64(image) {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0)
      return canvas.toDataURL('image/jpeg').replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    },
    convertBase64ToBuffer(base64Data) {
      const binaryString = window.atob(base64Data)
      const length = binaryString.length
      const bytes = new Uint8Array(length)
      for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes
    }
  }
}
</script>
