var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "add-equipment-container",
    },
    [
      !_vm.isProject
        ? _c(
            "div",
            { staticClass: "search-container" },
            [
              _c("el-input", {
                attrs: { placeholder: "设备名称", clearable: "" },
                on: {
                  input: _vm.onSearch,
                  change: _vm.onSearch,
                  clear: function ($event) {
                    return _vm.onClear("name")
                  },
                },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "产权编号", clearable: "" },
                on: {
                  input: _vm.onSearch,
                  change: _vm.onSearch,
                  clear: function ($event) {
                    return _vm.onClear("code")
                  },
                },
                model: {
                  value: _vm.listQuery.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "code", $$v)
                  },
                  expression: "listQuery.code",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isProject
        ? _c(
            "div",
            { staticClass: "equipment-container project-equipment-container" },
            _vm._l(_vm.list, function (item, index) {
              return _c("equipment-inbound-card", {
                key: index,
                attrs: { "equipment-info": item, "is-project": "" },
              })
            }),
            1
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.getNewEquipment,
                  expression: "getNewEquipment",
                },
              ],
              staticClass: "equipment-container",
              attrs: { "infinite-scroll-distance": "100" },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c("equipment-inbound-card", {
                key: index,
                attrs: { "equipment-info": item },
              })
            }),
            1
          ),
      _vm._v(" "),
      _vm.list.length === 0 && !(_vm.isLoading && !_vm.isListLoading)
        ? _c("div", { staticClass: "empty-list" }, [
            _c("img", { staticClass: "empty-img", attrs: { src: _vm.Empty } }),
            _vm._v(" "),
            _c("span", { staticClass: "empty-info" }, [_vm._v("没有设备")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading && !_vm.isListLoading
        ? _c("div", { staticClass: "loading-spinner" }, [
            _c("img", {
              staticClass: "loading-img",
              attrs: { src: _vm.Loading, alt: "" },
            }),
            _vm._v("数据加载中..."),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.list.length > 0
        ? _c("div", { staticClass: "already-loaded" }, [_vm._v("已经到底部了")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-container" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary", icon: "el-icon-check" },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }