var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "template-call-meeting-container",
    },
    [
      _vm.status === "notInMeeting"
        ? _c(
            "div",
            { staticClass: "not-in-meeting-container" },
            [
              _c("div", { staticClass: "title-container" }, [
                _c("div", { staticClass: "item" }, [
                  _vm._v("会议号：" + _vm._s(_vm.roomId)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "middle-container" }, [
                _c(
                  "div",
                  { staticClass: "join-userinfo" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("请输入您的参会名称"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        maxlength: 10,
                        "show-word-limit": "",
                        placeholder: "请输入",
                      },
                      model: {
                        value: _vm.fullName,
                        callback: function ($$v) {
                          _vm.fullName = $$v
                        },
                        expression: "fullName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "ht-button",
                { attrs: { type: "primary" }, on: { click: _vm.joinMeeting } },
                [_vm._v("加入会议")]
              ),
            ],
            1
          )
        : _vm.status === "inMeeting"
        ? _c("div", { staticClass: "in-meeting-containers" })
        : _vm.status === "isLeaving"
        ? _c("div", { staticClass: "is-leaving-containers" }, [
            _c("div", { staticClass: "item" }, [_vm._v("您已离开会议")]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "参会时长: " +
                  _vm._s(
                    _vm.getDuration(
                      _vm.leftMeetingTimestamp - _vm.joinedMeetingTimestamp
                    )
                  )
              ),
            ]),
          ])
        : _vm.status === "isFinished"
        ? _c("div", { staticClass: "is-finished-containers" }, [
            _c("div", { staticClass: "item" }, [
              _vm._v("会议已结束或者不存在"),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }