<template>
  <el-card>
    <el-tabs v-model="activeName" @tab-click="getList">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.value" :name="item.name" />
    </el-tabs>
    <inbound-record-list v-if="activeName === 'inbound'" ref="inboundRecordList" />
    <outbound-record-list v-if="activeName === 'outbound'" ref="outboundRecordList" />
    <transshipment-record-list v-if="activeName === 'transshipment'" ref="transshipmentRecordList" />
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { StockType } from '@/views/constructionEquipmentMgr/enum'
import InboundRecordList from '@/views/constructionEquipmentMgr/stockRecordMgr/components/inboundRecordList'
import OutboundRecordList from '@/views/constructionEquipmentMgr/stockRecordMgr/components/outboundRecordList'
import TransshipmentRecordList from '@/views/constructionEquipmentMgr/stockRecordMgr/components/transshipmentRecordList'

export default {
  name: 'List',
  components: { InboundRecordList, OutboundRecordList, TransshipmentRecordList },
  extends: HtListContainer,
  data() {
    return {
      StockType,
      activeName: 'inbound',
      tabs: [
        { name: 'inbound', value: '入库记录' },
        { name: 'outbound', value: '出库记录' }
        // { name: 'transshipment', value: '转移记录' }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.$nextTick(() => {
        if (this.activeName === 'inbound') {
          this.$refs.inboundRecordList.getList()
        } else if (this.activeName === 'outbound') {
          this.$refs.outboundRecordList.getList()
        } else if (this.activeName === 'transshipment') {
          this.$refs.transshipmentRecordList.getList()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
