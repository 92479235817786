<template>
  <el-card>
    <el-card style="margin-bottom: 50px">
      <div class="title-card-container">
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">编号:</span>
          <span class="textStyle" style="color: #48a0ff;font-weight: bold">{{ accidentDetail.recordNo }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">类型:</span>
          <span class="textStyle">{{ accidentDetail.accidentType ? accidentDetail.accidentType.value : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">登记人:</span>
          <span class="textStyle"> {{ accidentDetail.staff ? accidentDetail.staff.fullName : '' }}</span>
        </div>
        <div class="title-card-item-one-third">
          <span class="labelStyle labelWidth_70">登记时间:</span>
          <span class="textStyle"> {{ accidentDetail.createdAt }}</span>
        </div>
        <div class="title-card-item-two-third">
          <span class="labelStyle labelWidth_70">位置区域:</span>
          <span class="textStyle">{{ accidentDetail.locationAndInfo || '-' }}</span>
        </div>
        <div class="title-card-item-full-line">
          <div class="labelStyle labelWidth_70" style="vertical-align: top;">备注:</div>
          <div v-if="!editComment" style="display: flex; align-items: center">
            <span class="textStyle">{{ accidentDetail.comment || '-' }}</span>
            <el-link type="primary" @click="editComment = true">修改备注</el-link>
          </div>
          <span v-else>
            <el-input
              v-model="comment"
              type="textarea"
              :rows="5"
              style="width:450px"
              placeholder="请输入备注"
            />
            <el-link type="success" @click="confirmUpdateComment('comment')">确定提交</el-link>
            <el-link type="danger" @click="cancelEdit('comment', 'editComment')">取消输入</el-link>
          </span>
        </div>
      </div>
    </el-card>
    <el-divider><i class="el-icon-chat-line-square" /> 上报详情</el-divider>
    <ht-action-panel>
      <template slot="right">
        <el-button type="primary" plain icon="el-icon-document" :disabled="remoteGuidanceData && remoteGuidanceData.length === 0" @click="remoteGuidanceRecords()">远程指导记录</el-button>
      </template>
    </ht-action-panel>
    <ht-table
      ref="list"
      v-loading="isLoading"
      :span-method="arraySpanMethod"
      :data="list"
    >
      <el-table-column-selection v-if="showSelector" />
      <el-table-column label="序号" type="index" fixed="left" />
      <el-table-column align="left" label="时间" prop="createdAt" width="150px" />
      <el-table-column label="内容" width="500px">
        <template v-slot="{row}">
          <span v-if="row.mediaType.name === 'TXT'">
            <p>{{ row.content }}</p>
          </span>
          <div v-else-if="row.mediaType.name === 'IMG'">
            <el-image
              style="width: 100px; height: 100px"
              :src="row.content"
              :preview-src-list="[row.content]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="文字描述">
        <template v-slot="{row}">
          <div class="edit-box">
            <div v-if="!editDescription" class="textStyle">{{ accidentDetail.description || '-' }}</div>
            <el-input
              v-else
              v-model="description"
              class="edit-input"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入需要修改的文字描述"
              :disabled="!editDescription"
            />
            <div class="edit-button">
              <el-link v-if="!editDescription" type="primary" @click="editDescription = true">修改描述</el-link>
              <span v-else>
                <el-link type="success" @click="confirmUpdateComment('description')">确定提交</el-link>
                <el-link type="danger" @click="cancelEdit('description', 'editDescription')">取消输入</el-link>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
    </ht-table>

    <ht-dialog ref="dialog" title="远程指导记录" width="70%">
      <div slot="content">
        <remote-guidance-dialog
          v-if="remoteGuidanceData && remoteGuidanceData[0] && remoteGuidanceData[0].id"
          :guidance-id="remoteGuidanceData[0].id"
          :biz-type="accidentDetail.guidanceBizType.value"
          :biz-view="true"
        />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer/index'
import { getAccidentRecordDetailApi, getAccidentRecordItemsApi, updateAccidentRecordDetail } from '@/api/secureEnvironmentMgr/accidentRecord'
import { getRemoteGuidanceIdApi } from '@/api/remoteGuidance/remoteGuidance'
import remoteGuidanceDialog from '@/views/remoteGuidance/view'

export default {
  components: { remoteGuidanceDialog },
  extends: HtListContainer,
  props: {
    accidentId: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      checkList: [],
      showSelector: false,
      selectedChatRecord: [],
      accidentDetail: {},
      labelToProps: [
        {
          label: '发送人',
          prop: 'fromUser'
        },
        {
          label: '消息类型',
          prop: 'mediaType'
        },
        {
          label: '内容',
          prop: 'content'
        },
        {
          label: '时间',
          prop: 'chatTime'
        }
      ],
      visible: false,
      confirmReportLoading: false,
      bizType: undefined,
      editComment: false,
      comment: undefined,
      description: undefined,
      editDescription: undefined,
      remoteGuidanceData: undefined,
      remoteGuidanceId: undefined
    }
  },
  created() {
    this.getAccidentDetail()
    this.getAccidentChatRecords()
    this.bizType = this.$route.meta.bizType
  },
  methods: {
    getAccidentDetail() {
      getAccidentRecordDetailApi(this.accidentId).then((resp) => {
        this.accidentDetail = resp
        this.comment = this.accidentDetail.comment
        this.description = this.accidentDetail.description
        getRemoteGuidanceIdApi(resp.guidanceBizType.name, this.accidentId).then((resp) => {
          this.remoteGuidanceData = resp
        })
      })
    },
    getAccidentChatRecords() {
      let count = 1
      getAccidentRecordItemsApi(this.accidentId, this.listQuery).then((resp) => {
        resp.forEach(item => {
          item.showEditComment = false
          resp[0].isStartMerge = true
          resp[0].count = count
          for (let i = 0; i < resp.length - 1; i++) {
            if (resp[i + 1].recordId !== resp[i].recordId) {
              count++
              resp[i + 1].isStartMerge = true
              resp[i + 1].count = count
            }
          }
        })
        this.list = resp
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.list.length > 1 && columnIndex === 3) {
        if (row.isStartMerge) {
          return [this.list.length, 1]
        } else {
          return [0, 0]
        }
      }
    },
    confirmUpdateComment(key) {
      this.editComment = false
      this.editDescription = false

      const data = {}
      data[key] = this[key]
      updateAccidentRecordDetail(this.accidentId, data).then(() => {
        this.$message({
          message: '更新成功～',
          center: true,
          type: 'success'
        })
        this.getAccidentDetail()
      }).catch(() => { this.getAccidentDetail() })
    },
    cancelEdit(key, show) {
      this[show] = false
      this[key] = this.accidentDetail[key]
    },
    remoteGuidanceRecords() {
      this.$refs.dialog.dialogVisible = true
    }
  }
}
</script>

<style scoped>
.edit-box {
  position: relative;
  text-align: left;
}
.edit-button {
  position: absolute;
  top: calc(50% - 14px);
  right: 3%;
  width: 100px;
  text-align: right;
}
.edit-input {
  width: 70%;
  text-align: left;
}
</style>
