var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-list-container" },
    [
      _vm.userList.length > 0
        ? _vm._l(_vm.userList, function (user, index) {
            return _c(
              "div",
              { key: index, staticClass: "user-container" },
              [
                user.avatarUrl
                  ? _c("el-image", {
                      staticClass: "avatar item",
                      attrs: { src: user.avatarUrl || _vm.AvatarBlue },
                    })
                  : _c("dw-default-avatar", {
                      staticStyle: { "margin-right": "5px" },
                      attrs: {
                        "full-name": user.fullName,
                        width: 40,
                        "font-size": 14,
                      },
                    }),
                _vm._v(" "),
                _c("span", { staticClass: "fullName text item" }, [
                  _vm._v(_vm._s("" + user.fullName)),
                  !_vm.isSinoma && !_vm.stringLongOverTen(user.userName)
                    ? _c("span", { staticClass: "userName" }, [
                        _vm._v(_vm._s("（" + user.userName + "）")),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "role text item" }, [
                  _vm._v(_vm._s(_vm.getUserRoles(user.roles))),
                ]),
                _vm._v(" "),
                user.isInGuidance
                  ? _c("span", { staticClass: "meeting status text item" }, [
                      _vm._v("会议中"),
                    ])
                  : user.isCalling
                  ? _c("span", { staticClass: "calling status text item" }, [
                      _vm._v("呼叫中"),
                    ])
                  : _c(
                      "div",
                      { staticClass: "call-container" },
                      [
                        _c("svg-icon", {
                          staticClass: "call-icon",
                          attrs: { "icon-class": "call" },
                          on: {
                            click: function ($event) {
                              return _vm.call(user)
                            },
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            )
          })
        : [
            _c("div", { staticClass: "empty-container" }, [
              _c("img", {
                staticClass: "empty-img",
                attrs: { src: _vm.Empty, alt: "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "empty-text" }, [
                _vm._v("暂无在线用户"),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }