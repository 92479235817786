var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "example-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "a-scene",
        {
          attrs: {
            "mindar-image":
              "imageTargetSrc: https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.2/examples/image-tracking/assets/card-example/card.mind; showStats: false; uiScanning: #example-scanning-overlay;",
            embedded: "",
            "color-space": "sRGB",
            renderer: "colorManagement: true, physicallyCorrectLights",
            "vr-mode-ui": "enabled: false",
            "device-orientation-permission-ui": "enabled: false",
            inspector: "",
            "keyboard-shortcuts": "",
            screenshot: "",
          },
        },
        [
          _c(
            "a-assets",
            [
              _c("img", {
                attrs: {
                  id: "card",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/card.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-web",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/web.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-location",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/location.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-profile",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/profile.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-phone",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/phone.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-email",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/email.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-play",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/play.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-left",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/left.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "icon-right",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/icons/right.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "paintandquest-preview",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/paintandquest-preview.png",
                },
              }),
              _vm._v(" "),
              _c("video", {
                attrs: {
                  id: "paintandquest-video-mp4",
                  autoplay: "false",
                  loop: "true",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/paintandquest.mp4",
                  playsinline: "",
                  "webkit-playsinline": "",
                },
              }),
              _vm._v(" "),
              _c("video", {
                attrs: {
                  id: "paintandquest-video-webm",
                  autoplay: "false",
                  loop: "true",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/paintandquest.webm",
                  playsinline: "",
                  "webkit-playsinline": "",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "coffeemachine-preview",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/coffeemachine-preview.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  id: "peak-preview",
                  src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/portfolio/peak-preview.png",
                },
              }),
              _vm._v(" "),
              _c("a-asset-item", {
                attrs: {
                  id: "avatarModel",
                  src: "https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.2/examples/image-tracking/assets/card-example/softmind/scene.gltf",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("a-camera", {
            attrs: {
              position: "0 0 0",
              "look-controls": "enabled: false",
              cursor: "fuse: false; rayOrigin: mouse;",
              raycaster: "far: 10000; objects: .clickable",
              camera: "",
              rotation: "",
              "wasd-controls": "",
            },
          }),
          _vm._v(" "),
          _c(
            "a-entity",
            {
              attrs: {
                id: "mytarget",
                mytarget: "",
                "mindar-image-target": "targetIndex: 0",
              },
            },
            [
              _c("a-plane", {
                attrs: {
                  src: "#card",
                  position: "0 0 0",
                  height: "0.552",
                  width: "1",
                  rotation: "0 0 0",
                  material: "",
                  geometry: "",
                },
              }),
              _vm._v(" "),
              _c(
                "a-entity",
                {
                  attrs: {
                    id: "portfolio-panel",
                    visible: "false",
                    position: "0 0 -0.01",
                  },
                },
                [
                  _c("a-text", {
                    attrs: {
                      value: "Portfolio",
                      color: "black",
                      align: "center",
                      width: "2",
                      position: "0 0.4 0",
                      text: "",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "a-entity",
                    { attrs: { id: "portfolio-item0" } },
                    [
                      _c("a-video", {
                        attrs: {
                          id: "paintandquest-video-link",
                          "webkit-playsinline": "",
                          playsinline: "",
                          width: "1",
                          height: "0.552",
                          position: "0 0 0",
                          material: "",
                          geometry: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("a-image", {
                        staticClass: "clickable",
                        attrs: {
                          id: "paintandquest-preview-button",
                          src: "#paintandquest-preview",
                          "alpha-test": "0.5",
                          position: "0 0 0",
                          height: "0.552",
                          width: "1",
                          material: "",
                          geometry: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-entity",
                    { attrs: { id: "portfolio-item1", visible: "false" } },
                    [
                      _c("a-image", {
                        staticClass: "clickable",
                        attrs: {
                          src: "#coffeemachine-preview",
                          "alpha-test": "0.5",
                          position: "0 0 0",
                          height: "0.552",
                          width: "1",
                          material: "",
                          geometry: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-entity",
                    { attrs: { id: "portfolio-item2", visible: "false" } },
                    [
                      _c("a-image", {
                        staticClass: "clickable",
                        attrs: {
                          src: "#peak-preview",
                          "alpha-test": "0.5",
                          position: "0 0 0",
                          height: "0.552",
                          width: "1",
                          material: "",
                          geometry: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("a-image", {
                    staticClass: "clickable",
                    attrs: {
                      id: "portfolio-left-button",
                      visible: "false",
                      src: "#icon-left",
                      position: "-0.7 0 0",
                      height: "0.15",
                      width: "0.15",
                      material: "",
                      geometry: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("a-image", {
                    staticClass: "clickable",
                    attrs: {
                      id: "portfolio-right-button",
                      visible: "false",
                      src: "#icon-right",
                      position: "0.7 0 0",
                      height: "0.15",
                      width: "0.15",
                      material: "",
                      geometry: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("a-image", {
                staticClass: "clickable",
                attrs: {
                  id: "profile-button",
                  visible: "false",
                  src: "#icon-profile",
                  position: "-0.42 -0.5 0",
                  height: "0.15",
                  width: "0.15",
                  animation:
                    "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
                  material: "",
                  geometry: "",
                },
              }),
              _vm._v(" "),
              _c("a-image", {
                staticClass: "clickable",
                attrs: {
                  id: "web-button",
                  visible: "false",
                  src: "#icon-web",
                  "alpha-test": "0.5",
                  position: "-0.14 -0.5 0",
                  height: "0.15",
                  width: "0.15",
                  animation:
                    "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
                  material: "",
                  geometry: "",
                },
              }),
              _vm._v(" "),
              _c("a-image", {
                staticClass: "clickable",
                attrs: {
                  id: "email-button",
                  visible: "false",
                  src: "#icon-email",
                  position: "0.14 -0.5 0",
                  height: "0.15",
                  width: "0.15",
                  animation:
                    "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
                  material: "",
                  geometry: "",
                },
              }),
              _vm._v(" "),
              _c("a-image", {
                staticClass: "clickable",
                attrs: {
                  id: "location-button",
                  visible: "false",
                  src: "#icon-location",
                  position: "0.42 -0.5 0",
                  height: "0.15",
                  width: "0.15",
                  animation:
                    "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
                  material: "",
                  geometry: "",
                },
              }),
              _vm._v(" "),
              _c("a-gltf-model", {
                attrs: {
                  id: "avatar",
                  rotation: "0 0 0",
                  position: "0 -0.25 0",
                  scale: "0.004 0.004 0.004",
                  src: "#avatarModel",
                  "gltf-model": "",
                },
              }),
              _vm._v(" "),
              _c("a-text", {
                staticClass: "clickable",
                attrs: {
                  id: "text",
                  value: "",
                  color: "black",
                  align: "center",
                  width: "2",
                  position: "0 -1 0",
                  geometry: "primitive:plane; height: 0.1; width: 2;",
                  material: "opacity: 0.5",
                  text: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("canvas", {
            staticClass: "a-canvas",
            attrs: {
              "data-aframe-canvas": "true",
              "data-engine": "three.js r147",
              width: "1208",
              height: "1508",
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "a-loader-title",
            staticStyle: { display: "none" },
          }),
          _vm._v(" "),
          _c("a-entity", {
            attrs: {
              light: "",
              "data-aframe-default-light": "",
              "aframe-injected": "",
            },
          }),
          _vm._v(" "),
          _c("a-entity", {
            attrs: {
              light: "",
              position: "",
              "data-aframe-default-light": "",
              "aframe-injected": "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("video", {
        staticStyle: {
          position: "absolute",
          top: "0px",
          left: "-200.667px",
          "z-index": "-2",
          width: "1005.33px",
          height: "754px",
        },
        attrs: {
          autoplay: "",
          muted: "",
          playsinline: "",
          width: "640",
          height: "480",
        },
        domProps: { muted: true },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "hidden", attrs: { id: "example-scanning-overlay" } },
      [
        _c("div", { staticClass: "inner" }, [
          _c("img", {
            attrs: {
              src: "https://hiukim.github.io/mind-ar-js-doc/samples/assets/card-example/card.png",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "scanline" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }