<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container"
    :style="{height: height + 'px'}"
    :class="{'is-used-step-container': !isEdit}"
    title="hf_use_description"
  >
    <div class="view-container">
      <top-section
        ref="topSection"
        :is-focused-item="isFocusedItem"
        :current-index="currentIndex"
        :count="count"
        :title="properties.title"
        :show-more-function="showMoreFunction"
        :must-upload-photo="properties.mustUploadPhoto"
        :upload-photos="submitResults.uploadPhotos"
        :active-img-index="activeImgIndex"
        :button-disabled="buttonDisabled"
        :show-photos="!showMiddlePhotos"
        @more-function="moreFunction()"
        @native-play-tts="nativePlayTTS(ttsText)"
        @to-remote-guidance="toRemoteGuidance()"
        @to-ai-qa-assistant="toAiQaAssistant()"
        @native-take-photo="toTakePhoto"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @set-active-item="setActiveItem"
        @jump-step="jumpStep"
      />
      <middle-section
        ref="middleSection"
        :is-focused-item="isFocusedItem"
        :active-name="activeName"
        :reference-image="properties.referenceImage"
        :text="properties.text"
        :fact-desc="submitResults.factDesc"
        :button-disabled="buttonDisabled"
        :height="getMiddleHeight"
        :show-photos="showMiddlePhotos"
        :must-upload-photo="properties.mustUploadPhoto"
        :active-img-index="activeImgIndex"
        :upload-photos="submitResults.uploadPhotos"
        @select-reference-image="selectReferenceImage()"
        @select-text="selectText"
        @select-photos="selectPhotos"
        @set-active-item="setActiveItem"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @input-desc="inputText('factDesc')"
        @clear-fact-desc="clearFactDesc"
        @open-reference-image="openReferenceImage"
        @close-reference-viewer="closeReferenceViewer"
      />
      <div class="middle-second-section">
        <div class="middle-left-container">
          <span class="required-item">*</span>
          <span :class="{'focused-item': isFocusedItem === 'dataName'}" class="sub-title result-title result-name">{{ properties.dataName || '数据名称' }}：</span>
          <div class="result-reference-container">
            <span class="third-title">参考范围</span>
            <span :class="{'focused-item': isFocusedItem === 'minValue'}" class="min-value value-item">{{ properties.minValue }}</span>
            一
            <span :class="{'focused-item': isFocusedItem === 'maxValue'}" class="max-value value-item">{{ properties.maxValue }}</span>
            <span :class="{'focused-item': isFocusedItem === 'dataUnit'}" class="sub-title result-title result-unit" style="margin-left: 5px">{{ properties.dataUnit || '单位' }}</span>
          </div>
        </div>
        <div class="middle-right-container">
          <el-input ref="value" v-model="submitResults.value" disabled type="number" class="value-input" :class="{'is-abnormal-number': isAbnormalNumber}" placeholder="测量数据" />
          <!--          <el-button class="input-button square-circle-button" :disabled="buttonDisabled">测量数据</el-button>-->
        </div>
      </div>
      <bottom-section
        ref="bottomSection"
        :count="count"
        :current-index="currentIndex"
        :is-last-step="isLastStep"
        :button-disabled="buttonDisabled"
        @to-prev="toPrev()"
        @to-next="toNext()"
        @save-record="saveRecord()"
        @submit-record="submitRecord()"
      />
    </div>
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import TopSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/topSection'
import MiddleSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/middleSection'
import BottomSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/bottomSection'
import { isValidNumber } from '@/utils/validate'

export default {
  name: 'StepUi',
  components: { TopSection, MiddleSection, BottomSection },
  mixins: [StepUiMixin],
  props: {
    properties: { type: Object, default() { return {} } },
    results: { type: Object, default() { return {} } }
  },
  data() {
    return {
      dictationItem: null,
      keyboardInputContent: ''
    }
  },
  computed: {
    getMiddleHeight() {
      return this.height - 160
    },
    isAbnormalNumber() {
      return this.properties.minValue >= this.submitResults.value || this.submitResults.value >= this.properties.maxValue
    }
  },
  created() {
    const firstText = `步骤${this.currentIndex + 1}：${this.properties.title}，`
    const secondText = this.properties.guidanceVideo ? `${this.properties.guidanceVideo}，` : ''
    this.ttsText = firstText + secondText
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    inputText(value) {
      this.dictationItem = value
      this.nativeStartDictation()
      this.showMoreFunction = false
    },
    inputValue() {
      this.$refs.value.focus()
    },
    openInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = true
    },
    closeInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = false
    },
    handleKeydown(event) {
      // 检查是否是回车键
      if (event.keyCode === 13) { // 回车键的键码是13
        // 清空输入内容
        if (isValidNumber(this.keyboardInputContent)) {
          this.setKeyboardResult(Number(this.keyboardInputContent))
          this.keyboardInputContent = ''
        } else {
          this.playInfo(`输入内容为${this.keyboardInputContent},输入非法，请输入数字`)
          this.keyboardInputContent = ''
        }
      } else {
        // 如果是其他键，将字符转换为字符串并添加到inputContent变量中
        this.keyboardInputContent += event.key
      }
    },
    setKeyboardResult(result) {
      this.submitResults.value = result
      if (this.submitResults.value.length !== 0) {
        if (this.properties.minValue >= this.submitResults.value || this.submitResults.value >= this.properties.maxValue) {
          this.playInfo('测量数据异常，请复核')
        } else if (this.properties.minValue < this.submitResults.value && this.submitResults.value < this.properties.maxValue) {
          this.nativePlayTTS('测量数据正常')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.step-ui-detail-container{
  .view-container{
    .middle-second-section{
      padding: 0 20px;
      //margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .middle-left-container,.middle-right-container{
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .middle-left-container{
        margin-right: 10px;
      }
      .result-title{
        color: #000000;
      }
      .value-input{
        flex: 1;
        //margin-right: 10px;

        ::v-deep  .el-input__inner {
          height: 40px;
          line-height: 40px;
          font-size: 1.2rem;
          color: #000000;
        }
      }

      .is-abnormal-number{
        ::v-deep  .el-input__inner {
          border: 2px solid #f56c6c;
        }
      }

      .result-reference-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.0rem;
        .third-title{
          font-size: 1.3rem;
        }
        .value-item{
          display: inline-block;
          min-width: 50px;
          min-height: 23px;
          text-align: center;
          font-size: 1.3rem;
          font-weight: bold;
        }
      }

      .result-name, .result-unit {
        margin-right: 3px;
      }
    }
    .bottom-section{
      .top-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        .top-left-container,.top-right-container{
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .top-left-container{
          margin-right: 10px;
        }
        .result-title{
          color: #000000;
        }
        .value-input{
          flex: 1;
          margin-right: 10px;

          ::v-deep  .el-input__inner {
            height: 40px;
            line-height: 40px;
            font-size: 1.2rem;
            color: #000000;
          }
        }

        .result-reference-container{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.0rem;
          .third-title{
            font-size: 1.3rem;
          }
          .value-item{
            display: inline-block;
            min-width: 50px;
            min-height: 23px;
            text-align: center;
            font-size: 1.3rem;
            font-weight: bold;
          }
        }

        .result-name, .result-unit {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
