<template>
  <div class="step-ui-detail-container" :style="{height: height}">
    <div class="view-container">
      <div class="top-section">
        <div class="view-title top-title">{{ properties.deviceName || '设备名称' }}</div>
        <div class="top-button-container">
          <el-button class="top-title-button more-function-button" type="text" @click="moreFunction">更多功能</el-button>
          <div v-if="showMoreFunction" class="more-function-container">
            <img :src="Triangle" alt="" class="more-function-img">
            <el-button class="top-title-button guidance-button" type="text" @click="toRemoteGuidance">远程指导</el-button>
            <el-button class="top-title-button ai-button" type="text" @click="toAiQaAssistant">问答助手</el-button>
          </div>
        </div>
      </div>
      <div class="top-sub-section">设备位置：{{ properties.deviceLocation || '暂无' }}</div>
      <div class="middle-section">
        <div class="left-container">
          <div class="sub-title only-text-title">设备图片</div>
          <div class="img-container reference-img-container">
            <dw-el-image
              v-if="properties.deviceImg"
              ref="deviceImg"
              :src="properties.deviceImg"
              alt=""
              class="result-img"
              :preview-src-list="[properties.deviceImg]"
              :show-icon="false"
            />
            <el-button
              v-if="properties.deviceImg"
              class="sub-title-button fullscreen-button"
              @click="fullScreenPhoto"
            >查看设备图片</el-button>
          </div>
        </div>
        <div class="right-container">
          <div class="sub-title only-text-title">设备数据</div>
          <el-input
            v-model="properties.deviceDesc"
            placeholder="检查要求"
            style="width: 100%; height: 100%"
            type="textarea"
            class="check-standard"
          />
        </div>
      </div>
      <div class="bottom-section">
        <div class="top-container">
          <div class="full-line-container">
            <div class="title-container">
              <div class="sub-title only-text-title">报警故障代码：{{ results.errorRecords ? results.errorRecords.length: 0 }}条</div>
              <!--              <el-button class="sub-title-button" type="text">历史报警</el-button>-->
            </div>
            <div class="content-container">
              <div id="troubleContainer" class="trouble-container">
                <div v-for="(item, index) in results.errorRecords" :key="index" class="trouble-item">
                  <!--                  <div class="trouble-item-time">{{ item.time }}</div>-->
                  <div class="trouble-item-code">故障代码：{{ item.equipmentCode }}</div>
                </div>
              </div>
              <div v-if="results.errorRecords && results.errorRecords.length > 3" class="content-button-container">
                <el-button class="content-button up-button" type="text" @click="scrollButtonClick(-80)"><span class="button-label">向上</span></el-button>
                <el-button class="content-button down-button" type="text" @click="scrollButtonClick(80)"><span class="button-label">向下</span></el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-container">
          <el-button class="record-button bottom-button" type="text" @click="toRepairRecord">维修记录</el-button>
        </div>
      </div>
    </div>
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/templateMgr/components/stepUiMixin'
export default {
  name: 'StepUi',
  mixins: [StepUiMixin],
  props: {
    properties: {
      type: Object,
      default() {
        return {
          deviceName: null,
          deviceImg: null,
          deviceDesc: null,
          deviceLocation: null
        }
      }
    },
    results: {
      type: Object,
      default() {
        return {
          errorRecords: []
        }
      }
    }
  },
  data() {
    return {
      troubleList: [
        { time: '2023-10-12 10:19', code: 'W5-661' },
        { time: '2023-10-12 10:19', code: 'W5-662' },
        { time: '2023-10-12 10:19', code: 'W5-663' },
        { time: '2023-10-12 10:19', code: 'W5-664' },
        { time: '2023-10-12 10:19', code: 'W5-665' },
        { time: '2023-10-12 10:19', code: 'W5-666' },
        { time: '2023-10-12 10:19', code: 'W5-667' }
      ]
    }
  },
  methods: {
    scrollButtonClick(num) {
      const troubleContainer = document.getElementById('troubleContainer')
      troubleContainer.scrollTop += num
    },
    toRepairRecord() {
      this.$emit('to-repair-record')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/taskMgr/templateMgr/components/stepUiCommonStyle";
.step-ui-detail-container{
  .view-container{
    .top-section{
       position: relative;
      .view-title{
        margin-right: 0;
        text-align: center;
      }
      .top-button-container{
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }
    .top-sub-section{
      padding: 0 20px;
      font-size: 1.2rem;
    }
    .full-line-container{
      .content-container{
        .trouble-container{
          height: 120px;
          overflow-y: auto;
          .trouble-item{
            height: 40px;
            font-size: 1.3rem;
            display: flex;
            align-items: center;
            //.trouble-item-time{
            //  padding: 0 100px 0 20px;
            //}
            .trouble-item-code{
              padding: 0 100px 0 20px;
            }
          }
        }
      }
    }
  }
}
</style>
