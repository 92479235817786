import request from '@/utils/request'

const ROLES = 'user/roles/'
// 获取角色管理列表
export function getRolesApi(listQuery) {
  return request({ url: ROLES, method: 'GET', params: listQuery })
}

// 新建角色
export function createRoleApi(data) {
  return request({ url: ROLES, method: 'POST', data: data })
}

// 更新角色
export function updateRoleApi(roleId, data) {
  return request({ url: `${ROLES}${roleId}`, method: 'PUT', data: data })
}

// 获取单条角色
export function getRoleApi(roleId) {
  return request({ url: `${ROLES}${roleId}`, method: 'GET' })
}

// 获取角色的菜单
export function userFeatureMenuApi() {
  return request({ url: 'user/user_feature_menu', method: 'GET' })
}

// 删除角色
export function delRoleApi(roleId) {
  return request({ url: `${ROLES}${roleId}`, method: 'DELETE' })
}
