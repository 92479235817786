var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "local-video-wrapper",
      class: {
        "focused-container": _vm.isFocusedGuide,
        "fullScreen-mobile-local-video-wrapper":
          _vm.isFullscreen && _vm.isMobile,
      },
    },
    [
      _c(
        "player-view",
        {
          attrs: {
            id: _vm.id,
            "video-size": _vm.getVideoSize,
            "no-login-type": true,
            "is-expert": _vm.isExpert,
            "is-share-video": _vm.isShareVideo,
            "video-track": _vm.videoTrack,
            "remote-paint-info": _vm.remotePaintInfo,
            "remote-laser-info": _vm.remoteLaserInfo,
            "show-remote-paint-canvas": _vm.isRemotePaint,
            "show-remote-laser-canvas": _vm.isRemoteLaser,
            "is-fullscreen": _vm.isFullscreen,
            "is-mobile": _vm.isMobile,
          },
          on: { "local-video-w-h": _vm.getLocalVideoWH },
        },
        [
          _c(
            "div",
            {
              staticClass: "bottom-left",
              attrs: { slot: "bottomLeft" },
              slot: "bottomLeft",
            },
            [
              _c("img", {
                staticClass: "head",
                attrs: { src: _vm.Head, alt: "" },
              }),
              _vm._v(" "),
              _vm.isSinoma ||
              _vm.stringLongOverTen(_vm.userInfo.account.userName)
                ? _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s("" + _vm.userInfo.fullName)),
                  ])
                : _c("span", { staticClass: "name" }, [
                    _vm._v(
                      _vm._s(
                        _vm.userInfo.fullName +
                          "（" +
                          _vm.userInfo.account.userName +
                          "）"
                      )
                    ),
                  ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }