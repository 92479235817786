<template>
  <div style="padding: 20px; min-width: 800px;">
    <div class="info-card">
      <div class="left-container">
        <span style="font-weight: bolder;font-size: 40px;line-height: 90px">{{ tenantInfo.name }}</span>
        <span style="color: grey;font-size: 15px;margin-left: 10px">服务到期时间：{{ tenantInfo.expiredAt }}</span>
      </div>
      <div class="right-container" :style="{cursor: userInfo.roles.includes('ADMIN') ? 'pointer' : 'default'}" @click="goToDetailView('userMgrList')">
        <i class="el-icon-user-solid" style="color: white" />
        系统用户数量：{{ tenantInfo.staffCount }}
      </div>
    </div>

    <!--     设备租用统计-->
    <dw-collapse-pane v-if="showEquipmentLeaseStatistics" :init-collapsed="false" style="margin-top: 20px">
      <template v-slot:title="{activated}">
        <div class="title-container equipment-statistics-title-container">
          <dw-title title="设备资产统计" title-color="#000000" />
          <el-button v-if="showViewEquipmentLeaseButton" type="text" @click="goToDetailView('equipmentLeaseRecords')">查看详情<i class="el-icon-arrow-right" /></el-button>
        </div>
      </template>
      <template v-slot:content>
        <equipment-statistics ref="equipmentStatistics" />
      </template>
    </dw-collapse-pane>

    <!--工程进展统计-->
    <dw-collapse-pane v-if="showProjectStatistics" :init-collapsed="false" style="margin-top: 20px">
      <template v-slot:title="{activated}">
        <div class="title-container project-statistics-title-container">
          <dw-title title="工程进展统计" title-color="#000000" />
          <el-button v-if="showViewProjectSButton" type="text" @click="goToDetailView('projectMgrList')">查看详情<i class="el-icon-arrow-right" /></el-button>
        </div>
      </template>
      <template v-slot:content>
        <project-statistics ref="projectStatistics" />
      </template>
    </dw-collapse-pane>

    <!--巡检统计-->
    <dw-collapse-pane v-if="showPatrolStatistics" :init-collapsed="false" style="margin-top: 20px">
      <template v-slot:title="{activated}">
        <div class="title-container patrol-statistics-title-container">
          <dw-title title="巡检统计" title-color="#000000" />
          <el-button v-if="showViewPatrolButton" type="text" @click="goToDetailView('patrolWorkOrderMgr')">查看详情<i class="el-icon-arrow-right" /></el-button>
        </div>
      </template>
      <template v-slot:content>
        <patrol-statistics ref="patrolStatistics" />
      </template>
    </dw-collapse-pane>

    <!--    维修统计-->
    <dw-collapse-pane v-if="showRepairStatistics" :init-collapsed="false" style="margin-top: 20px">
      <template v-slot:title="{activated}">
        <div class="title-container repair-statistics-title-container">
          <dw-title title="维修统计" title-color="#000000" />
          <el-button v-if="showViewRepairButton" type="text" @click="goToDetailView('repairWorkOrderMgr')">查看详情<i class="el-icon-arrow-right" /></el-button>
        </div>
      </template>
      <template v-slot:content>
        <repair-statistics ref="repairStatistics" />
      </template>
    </dw-collapse-pane>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { tenantInfoApi } from '@/api/dashboard'
import EquipmentStatistics from '@/views/newDashboard/components/equipmentStatistics'
import ProjectStatistics from '@/views/newDashboard/components/projectStatistics'
import PatrolStatistics from '@/views/newDashboard/components/patrolStatistics'
import RepairStatistics from '@/views/newDashboard/components/repairStatistics'

export default {
  components: {
    EquipmentStatistics,
    ProjectStatistics,
    PatrolStatistics,
    RepairStatistics
  },
  data() {
    return {
      tenantInfo: {}
    }
  },
  computed: {
    ...mapGetters(['userRoutes', 'userInfo']),
    showEquipmentLeaseStatistics() {
      // 分配（设备清单）
      if (this.userRoutes && this.userRoutes.featureMenuJson) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'constructionEquipmentMgrMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'equipmentMgr')
          return menuIndex !== -1 && routers[index].children[menuIndex].activated
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showViewEquipmentLeaseButton() {
      // 分配（设备清单）
      if (this.userRoutes && this.userRoutes.featureMenuJson) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'constructionEquipmentMgrMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'equipmentMgr')
          return menuIndex !== -1 && routers[index].children[menuIndex].activated
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showProjectStatistics() {
      // 分配（工程管理：工程清单）
      if (this.userRoutes && this.userRoutes.featureMenuJson) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'constructionProjectMgrMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'projectMgrList')
          return menuIndex !== -1 && routers[index].children[menuIndex].activated
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showViewProjectSButton() {
      // 分配（工程管理：工程清单）
      if (this.userRoutes && this.userRoutes.featureMenuJson) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'constructionProjectMgrMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'projectMgrList')
          return menuIndex !== -1 && routers[index].children[menuIndex].activated
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showPatrolStatistics() {
      // 分配（设备清单、工程管理、工作流程管理、巡检工单管理、我的工单）可见
      if (this.userRoutes && this.userRoutes.featureMenuJson) {
        const routers = this.userRoutes.featureMenuJson

        // constructionEquipmentMgrMenu：equipmentMgr（设备清单）
        const constructionEquipmentIndex = routers.findIndex(item => item.name === 'constructionEquipmentMgrMenu')
        let hasEquipmentMgr = false
        if (constructionEquipmentIndex !== -1 && routers[constructionEquipmentIndex].activated) {
          const menuIndex = routers[constructionEquipmentIndex].children.findIndex(item => item.name === 'equipmentMgr')
          hasEquipmentMgr = menuIndex !== -1 && routers[constructionEquipmentIndex].children[menuIndex].activated
        }

        // constructionProjectMgrMenu（工程管理）
        const constructionProjectIndex = routers.findIndex(item => item.name === 'constructionProjectMgrMenu')
        const hasConstructionProject = constructionProjectIndex !== -1 && routers[constructionProjectIndex].activated

        // taskMgrMenu：	templateMgr（设备清单），patrolWorkOrderMgr（巡检工单管理），myWorkOrderMgr（我的工单）
        const taskMgrIndex = routers.findIndex(item => item.name === 'taskMgrMenu')
        let hasTemplateMgr = false
        let hasPatrolWorkOrderMgr = false
        let hasMyWorkOrderMgr = false
        if (taskMgrIndex !== -1 && routers[taskMgrIndex].activated) {
          const templateMgrMenuIndex = routers[constructionEquipmentIndex].children.findIndex(item => item.name === 'templateMgr')
          hasTemplateMgr = templateMgrMenuIndex !== -1 && routers[constructionEquipmentIndex].children[templateMgrMenuIndex].activated
          const patrolWorkOrderMgrMenuIndex = routers[constructionEquipmentIndex].children.findIndex(item => item.name === 'patrolWorkOrderMgr')
          hasPatrolWorkOrderMgr = patrolWorkOrderMgrMenuIndex !== -1 && routers[constructionEquipmentIndex].children[patrolWorkOrderMgrMenuIndex].activated
          const myWorkOrderMgrMenuIndex = routers[constructionEquipmentIndex].children.findIndex(item => item.name === 'myWorkOrderMgr')
          hasMyWorkOrderMgr = myWorkOrderMgrMenuIndex !== -1 && routers[constructionEquipmentIndex].children[myWorkOrderMgrMenuIndex].activated
        }
        return hasEquipmentMgr || hasConstructionProject || hasTemplateMgr || hasPatrolWorkOrderMgr || hasMyWorkOrderMgr
      } else {
        return false
      }
    },
    showViewPatrolButton() {
      // 分配：巡检工单管理
      if (this.userRoutes && this.userRoutes.featureMenuJson) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'taskMgrMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'patrolWorkOrderMgr')
          return menuIndex !== -1 && routers[index].children[menuIndex].activated
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showRepairStatistics() {
      // if (this.userRoutes && this.userRoutes.featureMenuJson) {
      //   const routers = this.userRoutes.featureMenuJson
      //   const index = routers.findIndex(item => item.name === 'taskMgrMenu')
      //   if (index !== -1 && routers[index].activated) {
      //     const menuIndex = routers[index].children.findIndex(item => item.name === 'repairWorkOrderMgr')
      //     return menuIndex !== -1 && routers[index].children[menuIndex].activated
      //   } else {
      //     return false
      //   }
      // } else {
      //   return false
      // }
      return false
    },
    showViewRepairButton() {
      return false
    }

  },
  created() {
    this.getTenantInfo()
  },
  methods: {
    getTenantInfo() {
      tenantInfoApi().then((resp) => {
        this.tenantInfo = resp
      })
    },
    goToDetailView(name) {
      if (this.userInfo.roles.includes('ADMIN')) {
        this.$router.push({ name: name })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.info-card {
  width: 100%;
  height: 100px;
  padding: 5px 30px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.right-container {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  background: $primaryColor;
}

.title-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  ::v-deep .title-container{
    padding: 0;
    margin-right: 10px;
  }
  .el-button{
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
  }
}
</style>
