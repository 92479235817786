var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "head-container",
      class: {
        "is-mobile-fullscreen-head-container": _vm.isFullscreen && _vm.isMobile,
      },
    },
    [
      _c("div", { staticClass: "meeting-describe" }, [
        _c("div", { staticClass: "code-container describe-container" }, [
          _c("img", { staticClass: "img", attrs: { src: _vm.Video, alt: "" } }),
          _vm._v(" "),
          _c("span", { staticClass: "label" }, [_vm._v("会议号：")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              _vm._s((_vm.meetingInfo && _vm.meetingInfo.meetingId) || "加载中")
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "initiator-container  describe-container" }, [
          _c("img", {
            staticClass: "img",
            attrs: { src: _vm.Initiator, alt: "" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "label" }, [_vm._v("发起人：")]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  "" +
                    ((_vm.meetingInfo && _vm.meetingInfo.meetingCreatorName) ||
                      "加载中")
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          !_vm.isSinoma
            ? _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.meetingInfo &&
                        _vm.meetingInfo.meetingCreator &&
                        !_vm.stringLongOverTen(_vm.meetingInfo.meetingCreator)
                        ? "（" + _vm.meetingInfo.meetingCreator + "）"
                        : ""
                    ) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.isRemoteShareScreen || _vm.isRemoteRecording
          ? _c("div", { staticClass: "hint-container" }, [
              _vm.isRemoteShareScreen
                ? _c("div", { staticClass: "hint share-screen-hint" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.shareScreenUserInfo.shareScreenFullName +
                            "屏幕共享中"
                        ) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isRemoteRecording
                ? _c("div", { staticClass: "hint recording-hint" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.recordingUserInfo.recordingFullName + "会议录屏中"
                        ) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "meeting-operation" },
        [
          !_vm.isSinoma
            ? [
                _vm.isFullscreen
                  ? _c("img", {
                      staticClass: "maximize item",
                      attrs: { src: _vm.ExitFul, alt: "" },
                      on: { click: _vm.exitFullScreen },
                    })
                  : _c("img", {
                      staticClass: "maximize item",
                      attrs: { src: _vm.EnterFul, alt: "" },
                      on: { click: _vm.enterFulScreen },
                    }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ht-button",
            {
              staticClass: "exit-button",
              class: {
                "is_sinoma-full-exit-button": _vm.isFullscreen && _vm.isSinoma,
              },
              attrs: { plain: "", type: "danger" },
              on: { click: _vm.exitRoom },
            },
            [_vm._v(_vm._s(_vm.exitName))]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }