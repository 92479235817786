<template>
  <div>
    <ht-action-panel v-if="!isProject" :on-search="getList" :on-clear="onClear">
      <template slot="left">
        <el-select v-model="listQuery.name" placeholder="设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.name" :value="item.value" />
        </el-select>
        <el-input v-model="listQuery.code" placeholder="产权编号" />
        <el-input v-model="listQuery.specModel" placeholder="规格型号" />
      </template>
      <template slot="right">
        <el-button type="success" icon="el-icon-plus" :disabled="selectedEquipments.length < 1" @click="handleAddLeaseEquipments">确认</el-button>
      </template>
    </ht-action-panel>
    <ht-action-panel v-else>
      <template slot="right">
        <el-button type="success" icon="el-icon-plus" :disabled="selectedEquipments.length < 1" @click="handleAddLeaseEquipments">确认</el-button>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list" :row-key="getRowKey" @selection-change="onSelectionChange">
      <ht-table-column type="selection" width="55" reserve-selection :selectable="isSelectable" />
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in WarehouseEquipmentStockInboundListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="item.fixed"
      >
        <template v-slot="{row}">
          <span>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column v-if="isProject" label="当前库存" prop="quantity" :width="100" />
      <ht-table-column-operation :width="100">
        <template v-slot="{row}">
          <el-button :disabled="row.isAdded" type="text" icon="el-icon-plus" @click="handleAddLeaseEquipment(row)">添加</el-button>
        </template>
      </ht-table-column-operation>
    </ht-table>

  </div>
</template>

<script>
import { WarehouseEquipmentStockInboundListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getWarehouseStockEquipments } from '@/api/constructionEquipmentMgr/stockMgr'
import { EquipmentTypesEnum } from '@/views/constructionEquipmentMgr/enum'
import { getProject } from '@/api/constructionProjectMgr/projectMgr'

export default {
  name: 'WarehouseInboundAddEquipmentList',
  props: {
    alreadyAddList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      EquipmentTypesEnum,
      WarehouseEquipmentStockInboundListTitle,
      selectedEquipments: [],
      selection: [],
      isLoading: false,
      isProject: false,
      list: [],
      listQuery: {
        name: undefined,
        code: undefined,
        specModel: undefined
      }
    }
  },
  methods: {
    isSelectable(row) {
      if (row) {
        return !row.isAdded
      }
    },
    onClear() {
      Object.keys(this.listQuery).forEach(key => {
        this.listQuery[key] = undefined
      })
      this.getList()
    },
    getRowKey(row) {
      if (row) {
        return row.id
      }
    },
    getList(isProject = false, projectId) {
      this.isProject = isProject
      if (isProject) {
        this.isLoading = true
        getProject(projectId).then(resp => {
          resp.equipmentStockItems.forEach(item => {
            const index = this.alreadyAddList.findIndex(addItem => addItem.id === item.constructionEquipmentId)
            item.isAdded = index !== -1
            item.id = item.constructionEquipmentId
            item.name = item.constructionEquipmentName
            item.code = item.constructionEquipmentCode
            item.specModel = item.constructionEquipmentSpecModel
            item.quantity = item.projectEquipmentQuantity
            item.isWhole = item.constructionEquipmentIsWhole
            item.inboundQuantity = item.projectEquipmentQuantity
          })
          this.isLoading = false
          this.list = resp.equipmentStockItems
        }).catch(() => {
          this.isLoading = false
        })
      } else {
        this.isLoading = true
        getWarehouseStockEquipments(this.listQuery).then(resp => {
          resp.forEach(item => {
            const index = this.alreadyAddList.findIndex(addItem => addItem.id === item.id)
            item.isAdded = index !== -1
            item.inboundQuantity = 1
          })
          this.isLoading = false
          this.list = resp
        }).catch(() => {
          this.isLoading = false
        })
      }
    },
    onSelectionChange(val) {
      this.selectedEquipments = val
    },
    handleAddLeaseEquipments() {
      this.$emit('on-add-equipments', this.selectedEquipments)
    },
    handleAddLeaseEquipment(row) {
      this.$emit('on-add-equipments', [row])
    }
  }
}
</script>

<style scoped lang="scss">
.pagination-container{
  ::v-deep .el-pagination {
    text-align: right;
  }
}
</style>
