var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "设备名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _vm.list.length > 0
                ? _c(
                    "ht-button",
                    {
                      attrs: { type: "success", icon: "el-icon-location" },
                      on: { click: _vm.openLocationDialog },
                    },
                    [_vm._v("查看设备位置")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.DevicesAndAppsListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                fixed: item.fixed,
                label: item.label,
                "min-width": item.width || 100,
                prop: item.prop,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "已安装产品App"
                          ? _c("span", [
                              _vm._v(_vm._s(row.nameVersionArray.toString())),
                            ])
                          : item.label === "设备状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.DeviceStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.DeviceStatusEnum[row[item.prop]] || "-"
                                  )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openTraceDialog(row)
                          },
                        },
                      },
                      [_vm._v("查看轨迹")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "deviceLocationsDialog",
          attrs: {
            title: "设备位置",
            width: "1000px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeLocationDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showDeviceLocations
                ? _c("device-locations", {
                    ref: "deviceLocations ",
                    attrs: {
                      "map-id": "deviceLocations",
                      "location-arr": _vm.deviceLocationArr,
                      center: _vm.deviceLocationCenter,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "deviceTracksDialog",
          attrs: {
            title: "设备轨迹",
            width: "1000px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeTraceDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showDeviceTracks
                ? _c("device-tracks", {
                    ref: "deviceTracks",
                    attrs: {
                      "map-id": "deviceTracks",
                      "track-arr": _vm.deviceTrackArr,
                      center: _vm.deviceTrackCenter,
                      position: _vm.devicePosition,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }