<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import echarts from 'echarts'
import variables from '@/styles/variables.scss'
import { debounce } from '@/utils'

export default {
  name: 'EquipmentTimeChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true },
    color1: { type: String, default: '#213BA1' },
    color2: { type: String, default: variables.primaryColor },
    color3: { type: String, default: '#6879FF' },
    color4: { type: String, default: '#C1C4FC' },
    color5: { type: String, default: '#909399' },
    count: { type: Number, default: 0 }
  },
  data() {
    return {
      chart: null,
      statisticColors: [this.color1, this.color2, this.color3, this.color4, this.color5],
      option: {
        title: {
          top: '35%',
          left: 'center',
          textStyle: {
            fontWeight: 'bold',
            fontSize: 16,
            color: '#666666'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 0,
          itemGap: 10,
          textStyle: {
            fontWeight: 'bold',
            fontSize: 14,
            lineHeight: 20,
            color: '#666666'
          }
        },
        grid: {
          bottom: '0',
          containLabel: true
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            top: 0,
            bottom: 30,
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'inside',
              fontWeight: 'bold',
              fontSize: 12,
              formatter: function(val) {
                return val.percent > 0 ? val.percent + '%' : ''
              }
            },
            startAngle: -90, // 起始角度
            data: [],
            tooltip: {
              formatter: function(val) {
                return val.marker + val.name + '：' + val.value + '<br>' + '占比：' + val.percent + '%'
              },
              textStyle: {
                fontWeight: 'bold',
                fontSize: '14'
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getColor(index) {
      if (index < this.statisticColors.length) {
        return this.statisticColors[index]
      } else {
        return this.statisticColors[index % this.statisticColors.length]
      }
    },
    getData(statistic, count) {
      this.option.title.text = '设备总数' + '\n' + count
      this.option.series[0].data = statistic.map(item => {
        return { value: item.equipmentCount, name: item.rangeName }
      })
      this.option.series[0].data.forEach((item, index) => {
        item.itemStyle = { 'color': this.getColor(index) }
      })
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
