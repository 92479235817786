var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "goods-container",
        style: { height: _vm.height - 10 + "px" },
      },
      [
        _c("div", { staticClass: "item item-bold" }, [
          _vm._v(
            "箱号：" +
              _vm._s(
                _vm.getExecuteInboundBoxes[_vm.currentBoxIndex] &&
                  _vm.getExecuteInboundBoxes[_vm.currentBoxIndex].code
              )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item item-bold" }, [
          _vm._v(
            "推荐库位：" +
              _vm._s(_vm.getStorageLocation && _vm.getStorageLocation.code)
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v(
            "库位容量：" +
              _vm._s(_vm.getStorageLocation && _vm.getStorageLocation.capacity)
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v(
            "库存量：" +
              _vm._s(_vm.getStorageLocation && _vm.getStorageLocation.quantity)
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v(
            "格号：" +
              _vm._s(
                _vm.getCell(
                  _vm.getStorageLocation && _vm.getStorageLocation.cellList
                )
              )
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item item-button" },
          [
            _vm._v("至箱号:\n      "),
            _vm.boxCode
              ? _c("span", { staticClass: "scan-result" }, [
                  _vm._v(_vm._s(_vm.boxCode)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("svg-icon", {
              attrs: { slot: "append", "icon-class": "scan" },
              slot: "append",
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "scan-button",
                attrs: { type: "text", disabled: _vm.buttonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.handleActions("to-scan-box-code")
                  },
                },
              },
              [_vm._v("扫描箱号")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item item-button" },
          [
            _vm._v("至库位:\n      "),
            _vm.storageInfo
              ? _c("span", { staticClass: "scan-result" }, [
                  _vm._v(
                    _vm._s(
                      "" +
                        _vm.storageInfo.code +
                        (_vm.cellInfo ? "（" + _vm.cellInfo.name + " ）" : "")
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("svg-icon", {
              attrs: { slot: "append", "icon-class": "scan" },
              slot: "append",
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "scan-button",
                attrs: { type: "text", disabled: _vm.buttonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.handleActions("to-scan-storage-code")
                  },
                },
              },
              [_vm._v("扫描库位")]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bottom-container" },
      [
        _vm.currentBoxIndex > 0
          ? _c(
              "el-button",
              {
                staticClass: "submit-button square-circle-button",
                attrs: { type: "text", disabled: _vm.buttonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.handleActions("to-prev-box")
                  },
                },
              },
              [_vm._v("上一个")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentBoxIndex < _vm.getExecuteInboundBoxes.length - 1
          ? _c(
              "el-button",
              {
                staticClass: "submit-button square-circle-button",
                attrs: { type: "text", disabled: _vm.buttonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.handleActions("to-next-box")
                  },
                },
              },
              [_vm._v("下一个")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "submit-button square-circle-button",
            attrs: { type: "text", disabled: _vm.buttonDisabled },
            on: {
              click: function ($event) {
                return _vm.handleActions("to-inbound")
              },
            },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-section" }, [
      _c("div", { staticClass: "view-title top-title" }, [
        _vm._v("上架-执行上架"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }