<template>
  <div class="container">
    <a-scene
      :mindar-image="{imageTargetSrc: mindSrc,uiScanning: 'no'}"
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
    >
      <a-assets>
        <img id="card" :src="imgSrc">
        <!--        <a-asset-item id="avatarModel" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/softmind/scene.gltf" />-->
      </a-assets>

      <a-camera position="0 0 0" look-controls="enabled: false" />
      <a-entity mindar-image-target="targetIndex: 0">
        <a-image :src="imgSrc" position="0 0.552 0" height="0.552" width="1" rotation="0 0 0" />
        <!--        <a-plane src="#card" position="0 0.552 0" height="0.552" width="1" rotation="0 0 0" />-->
        <!--        <a-gltf-model rotation="0 0 0 " position="0 0 0.1" scale="0.005 0.005 0.005" src="#avatarModel" animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate" />-->
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
export default {
  name: 'OneTarget',
  data() {
    return {
      mindSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/trash_can.mind',
      imgSrc: null
    }
  },
  created() {
    setTimeout(() => {
      this.imgSrc = 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/result.png'
    }, 1000)
  }
}
</script>

<style scoped>
</style>
