export const StatusEnum = {
  PUBLISHED: '已发布',
  DRAFT: '草稿'
}

export const StatusStyleEnum = {
  PUBLISHED: 'success',
  DRAFT: 'info'
}

export const Tree = [
  {
    code: '00',
    id: 1,
    level: 0,
    name: '全部',
    parentCode: '',
    parentId: 0,
    isDefault: false,
    children: [
      {
        code: '0001',
        id: 7,
        level: 1,
        name: '一级目录',
        parentCode: '00',
        parentId: 1,
        children: [],
        isDefault: true
      },
      {
        code: '0001',
        id: 2,
        level: 1,
        name: '一级目录',
        parentCode: '00',
        parentId: 1,
        isDefault: false,
        children: [
          {
            code: '000101',
            id: 4,
            level: 2,
            name: '二级目录',
            parentCode: '0001',
            parentId: 2,
            isDefault: false,
            children: []
          }
        ]
      },
      {
        code: '0002',
        id: 3,
        level: 1,
        name: '一级目录',
        parentCode: '00',
        parentId: 1,
        isDefault: false,
        children: [
          {
            code: '000201',
            id: 5,
            level: 2,
            name: '二级目录',
            parentCode: '0002',
            parentId: 3,
            isDefault: false,
            children: []
          }
        ]
      },
      {
        code: '0003',
        id: 6,
        level: 1,
        name: '一级目录',
        parentCode: '00',
        parentId: 1,
        isDefault: false,
        children: []
      }
    ]
  }
]
