var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "knowledge-container",
    },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.knowledgeInfo && _vm.knowledgeInfo.title)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "time" }, [
        _vm._v(
          _vm._s(
            "最新发布时间：" +
              ((_vm.knowledgeInfo && _vm.knowledgeInfo.updatedAt) ||
                "以实际发布时间为准")
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "content",
        domProps: {
          innerHTML: _vm._s(_vm.knowledgeInfo && _vm.knowledgeInfo.content),
        },
      }),
      _vm._v(" "),
      _vm.knowledgeInfo && _vm.knowledgeInfo.attachmentData.length > 0
        ? _c(
            "div",
            { staticClass: "attachments" },
            [
              _c("span", { staticClass: "name" }, [_vm._v("附件：")]),
              _vm._v(" "),
              _vm._l(_vm.knowledgeInfo.attachmentData, function (item, index) {
                return _c("dw-file", {
                  key: index,
                  staticStyle: { margin: "5px 0" },
                  attrs: {
                    url: item,
                    "justify-content": "left",
                    "show-del": false,
                  },
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showButton ? _c("el-divider") : _vm._e(),
      _vm._v(" "),
      _vm.showButton
        ? _c(
            "div",
            {
              staticClass: "operation-container",
              staticStyle: { "text-align": "center" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("关闭")]
              ),
              _vm._v(" "),
              _vm.list.length > 1
                ? _c(
                    "div",
                    { staticClass: "right-container" },
                    [
                      _vm.listIndex > 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.onPrev },
                            },
                            [
                              _c("i", { staticClass: "el-icon-arrow-left" }),
                              _vm._v("上一条"),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.listIndex < _vm.list.length - 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.onNext },
                            },
                            [
                              _vm._v("下一条"),
                              _c("i", { staticClass: "el-icon-arrow-right" }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }