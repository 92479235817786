<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <!--      <template v-if="device!=='mobile'">-->
      <template>
        <template>
          <div class="right-menu-item name-size">
            <template>
              <!--              <router-link to="/loading">-->
              <!--                <el-button type="text" icon="el-icon-phone" style="font-size: 14px">远程指导</el-button>-->
              <!--              </router-link>-->
              <small v-if="userInfo">{{ userInfo.fullName }}</small>
            </template>
          </div>
        </template>

        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <img v-if="userInfo && userInfo.avatarUrl" :src="AvatarImage" class="user-avatar">
            <dw-default-avatar v-else :full-name="userInfo && userInfo.fullName" :width="40" :font-size="14" />
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/profile/index">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <router-link to="/">
              <el-dropdown-item>首页</el-dropdown-item>
            </router-link>
            <router-link to="/reset-password">
              <el-dropdown-item>修改密码</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided>
              <span style="display:block;" @click="logout">登出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import permission from '../../directive/permission/permission'
import AvatarImage from '@/assets/image/avatar.png'
import im from '@/views/newRemoteGuidance/rongCloud/im'

export default {
  directives: { permission },
  components: {
    Breadcrumb,
    Hamburger
  },
  data() {
    return {
      AvatarImage,
      switchUserList: []
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device',
      'userInfo',
      'permissions',
      'userRoutes'
    ])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      // 远程指导菜单是全分配给用户的，所以activated都是true,需要根据角色进行判断
      // 远程指导联系人菜单仅限于 EXPERT, ADMIN
      if (this.userRoutes && this.userRoutes.featureMenuJson && (this.userInfo.roles.includes('ADMIN') || this.userInfo.roles.includes('EXPERT'))) {
        const routers = this.userRoutes.featureMenuJson
        const index = routers.findIndex(item => item.name === 'newRemoteGuidanceMenu')
        if (index !== -1 && routers[index].activated) {
          const menuIndex = routers[index].children.findIndex(item => item.name === 'contactList')
          if (menuIndex !== -1 && routers[index].children[menuIndex].activated) {
            im.imDisconnect().then(r => {}).catch(() => {
              this.$store.dispatch('user/logout')
              this.$router.push(`/login`)
            })
          }
        }
      }
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;
    color: #666666;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }

  .item {
    margin-top: 5px;
  }
}
</style>
