<template>
  <div v-loading="loading" class="menu-list-container" :style="{width: treeWidth + 'px', marginBottom: marginBottom + 'px'}">
    <div v-if="isDisabled" class="disabled-container" />
    <el-tree
      :id="treeId"
      :ref="treeRef"
      :data="treeList"
      :props="defaultProps"
      node-key="id"
      :current-node-key="currentNodeKey"
      default-expand-all
      highlight-current
      :expand-on-click-node="false"
      @node-collapse="$emit('click-outside')"
      @node-expand="$emit('click-outside')"
    >
      <span :ref="node.id" slot-scope="{ node, data }" class="custom-tree-node">
        <span class="node-name" @click="nodeClick(node, data)">{{ node[treeName] }}</span>
        <template v-if="hasFunctionBox">
          <ht-button v-if="data[treeLevelName] === 0 && !topLevelHasFunctionBox" class="node-button" type="text" icon="el-icon-plus" @click="handleClick(true, node, data)">{{ topLevelFirstButtonName }}</ht-button>
          <i v-else-if="!data.isDefault" class="el-icon-more node-icon" :title="treeIconTitle" @click="handleClick(false, node, data)" />
        </template>
      </span>
    </el-tree>
    <div v-if="showFunctionBox" id="functionBox" class="function-box" :style="{left: treeWidth + 2 + 'px'}">
      <i class="el-icon-caret-left more-function-icon" />
      <slot name="functions" />
    </div>
  </div>
</template>

<script>
import Triangle from '@/assets/image/workflow/triangle.png'

export default {
  props: {
    treeId: { type: String, default: 'tree' },
    treeRef: { type: String, default: 'tree' },
    treeWidth: { type: Number, default: 248 },
    marginBottom: { type: Number, default: 120 },
    loading: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    treeList: { type: Array, default: () => [] },
    defaultProps: { type: Object, default() { return { children: 'children', label: 'name' } } },
    currentNodeKey: { type: [String, Number], default: null },
    treeName: { type: String, default: 'label' },
    treeLevelName: { type: String, default: 'level' },
    topLevelHasFunctionBox: { type: Boolean, default: false },
    topLevelFirstButtonName: { type: String, default: '添加文件夹' },
    treeIconTitle: { type: String, default: '更多功能' },
    showFunctionBox: { type: Boolean, default: false },
    hasFunctionBox: { type: Boolean, default: true }
  },
  data() {
    return {
      Triangle,
      showAddSubFolder: true
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside() {
      if (!this.$el.contains(event.target)) {
        this.$emit('click-outside')
      }
    },
    setCurrentNode(index, isCurrent) {
      this.$nextTick(() => {
        if (this.$refs[this.treeRef].root && this.$refs[this.treeRef].root.childNodes[index]) {
          this.$refs[this.treeRef].root.childNodes[index].isCurrent = isCurrent
        }
      })
    },
    nodeClick(node, data) {
      this.$emit('node-click', node, data)
    },
    handleClick(isTopFirst = false, node, data) {
      this.$emit('handle-click-more', isTopFirst, node, data)
    },
    openFunctionBox(node, data) {
      this.$nextTick(() => {
        const tree = document.getElementById(this.treeId)
        const treeRect = tree.getBoundingClientRect()
        const container = this.$refs[node.id] // 获取容器元素的引用
        const rect = container.getBoundingClientRect() // 获取容器元素的位置信息

        this.toggleFunctionBox(node, data, rect.top - treeRect.top)
      })
    },
    toggleFunctionBox(node, data, top) {
      const functionBox = document.getElementById('functionBox')
      functionBox.style.top = top + 'px'
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.menu-list-container{
  position: relative;
}
.el-tree{
  font-size: 14px;
  ::v-deep .el-tree-node{
    .el-tree-node__content{
      height: 40px;
      line-height: 40px;
      .el-tree-node__expand-icon{
        font-size: 16px;
      }
    }
  }
}

.function-box {
  z-index: 2001;
  position: absolute;
  left: 250px;

  .more-function-icon{
    position: absolute;
    top: 5px;
    color: $primaryColor;
    font-size: 30px;
    margin-left: -9px;
  }
}

.custom-tree-node{
  width: 100%;
  display: flex;
  align-items: center;
  .node-name{
    flex: 1;
    cursor: default;
  }
  .node-icon{
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    padding-right: 20px;
  }
  .node-button{
    padding-right: 20px;
  }
}

.disabled-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: not-allowed;
}
</style>
