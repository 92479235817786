var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c("el-card", { staticStyle: { "margin-bottom": "50px" } }, [
        _c("div", { staticClass: "title-card-container" }, [
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("编号:"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "textStyle",
                staticStyle: { color: "#48a0ff", "font-weight": "bold" },
              },
              [_vm._v(_vm._s(_vm.accidentDetail.recordNo))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("类型:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(
                _vm._s(
                  _vm.accidentDetail.accidentType
                    ? _vm.accidentDetail.accidentType.value
                    : ""
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("登记人:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.accidentDetail.staff
                      ? _vm.accidentDetail.staff.fullName
                      : ""
                  )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("登记时间:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(" " + _vm._s(_vm.accidentDetail.createdAt)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-two-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_70" }, [
              _vm._v("位置区域:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(_vm._s(_vm.accidentDetail.locationAndInfo || "-")),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-full-line" }, [
            _c(
              "div",
              {
                staticClass: "labelStyle labelWidth_70",
                staticStyle: { "vertical-align": "top" },
              },
              [_vm._v("备注:")]
            ),
            _vm._v(" "),
            !_vm.editComment
              ? _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("span", { staticClass: "textStyle" }, [
                      _vm._v(_vm._s(_vm.accidentDetail.comment || "-")),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.editComment = true
                          },
                        },
                      },
                      [_vm._v("修改备注")]
                    ),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("el-input", {
                      staticStyle: { width: "450px" },
                      attrs: {
                        type: "textarea",
                        rows: 5,
                        placeholder: "请输入备注",
                      },
                      model: {
                        value: _vm.comment,
                        callback: function ($$v) {
                          _vm.comment = $$v
                        },
                        expression: "comment",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmUpdateComment("comment")
                          },
                        },
                      },
                      [_vm._v("确定提交")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.cancelEdit("comment", "editComment")
                          },
                        },
                      },
                      [_vm._v("取消输入")]
                    ),
                  ],
                  1
                ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("el-divider", [
        _c("i", { staticClass: "el-icon-chat-line-square" }),
        _vm._v(" 上报详情"),
      ]),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-document",
                    disabled:
                      _vm.remoteGuidanceData &&
                      _vm.remoteGuidanceData.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.remoteGuidanceRecords()
                    },
                  },
                },
                [_vm._v("远程指导记录")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { "span-method": _vm.arraySpanMethod, data: _vm.list },
        },
        [
          _vm.showSelector ? _c("el-table-column-selection") : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: "时间",
              prop: "createdAt",
              width: "150px",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "内容", width: "500px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.mediaType.name === "TXT"
                      ? _c("span", [_c("p", [_vm._v(_vm._s(row.content))])])
                      : row.mediaType.name === "IMG"
                      ? _c(
                          "div",
                          [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: row.content,
                                "preview-src-list": [row.content],
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "文字描述" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "edit-box" },
                      [
                        !_vm.editDescription
                          ? _c("div", { staticClass: "textStyle" }, [
                              _vm._v(
                                _vm._s(_vm.accidentDetail.description || "-")
                              ),
                            ])
                          : _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 5 },
                                placeholder: "请输入需要修改的文字描述",
                                disabled: !_vm.editDescription,
                              },
                              model: {
                                value: _vm.description,
                                callback: function ($$v) {
                                  _vm.description = $$v
                                },
                                expression: "description",
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "edit-button" },
                          [
                            !_vm.editDescription
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.editDescription = true
                                      },
                                    },
                                  },
                                  [_vm._v("修改描述")]
                                )
                              : _c(
                                  "span",
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmUpdateComment(
                                              "description"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("确定提交")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelEdit(
                                              "description",
                                              "editDescription"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("取消输入")]
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        { ref: "dialog", attrs: { title: "远程指导记录", width: "70%" } },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.remoteGuidanceData &&
              _vm.remoteGuidanceData[0] &&
              _vm.remoteGuidanceData[0].id
                ? _c("remote-guidance-dialog", {
                    attrs: {
                      "guidance-id": _vm.remoteGuidanceData[0].id,
                      "biz-type": _vm.accidentDetail.guidanceBizType.value,
                      "biz-view": true,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }