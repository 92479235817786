var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "inboundForm",
          attrs: {
            model: _vm.inboundForm,
            rules: _vm.inboundFormRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "入库仓库：", prop: "warehouseId" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.warehouseInfo && _vm.warehouseInfo.name) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备来源：",
                prop: "constructionEquipmentSource",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.handleEquipmentSourceChange },
                  model: {
                    value: _vm.inboundForm.constructionEquipmentSource,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.inboundForm,
                        "constructionEquipmentSource",
                        $$v
                      )
                    },
                    expression: "inboundForm.constructionEquipmentSource",
                  },
                },
                _vm._l(_vm.EquipmentSourceEnum, function (value, key) {
                  return _c("el-radio", { key: key, attrs: { label: key } }, [
                    _vm._v(_vm._s(value)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.inboundForm.constructionEquipmentSource === "OLD"
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "选择工程：",
                      prop: "constructionProjectId",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          placeholder:
                            _vm.projectList.length > 0
                              ? "请输入工程名称进行搜索"
                              : "当前无可选工程",
                          remote: "",
                        },
                        on: { change: _vm.handleSelectProject },
                        model: {
                          value: _vm.inboundForm.constructionProjectId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inboundForm,
                              "constructionProjectId",
                              $$v
                            )
                          },
                          expression: "inboundForm.constructionProjectId",
                        },
                      },
                      _vm._l(_vm.projectList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "line-two-item-row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "入库设备：", prop: "equipmentItems" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        icon: "el-icon-plus",
                        disabled:
                          (_vm.inboundForm.constructionEquipmentSource ===
                            "OLD" &&
                            _vm.inboundForm.constructionProjectId === null) ||
                          !_vm.inboundForm.constructionEquipmentSource,
                      },
                      on: { click: _vm.openAddEquipmentDialog },
                    },
                    [_vm._v("添加设备")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "入库总数量：" } }, [
                _c("span", { staticClass: "quantities" }, [
                  _vm._v(" " + _vm._s(_vm.inboundQuantities)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ht-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value:
                    _vm.inboundForm.constructionEquipmentSource === "NEW"
                      ? _vm.isEquipmentLoading
                      : false,
                  expression:
                    "inboundForm.constructionEquipmentSource === 'NEW' ? isEquipmentLoading : false",
                },
              ],
              ref: "list",
              staticStyle: { "margin-bottom": "18px" },
              attrs: { data: _vm.inboundForm.equipmentItems },
            },
            [
              _c("ht-table-column", {
                attrs: { label: "序号", type: "index", fixed: "left" },
              }),
              _vm._v(" "),
              _vm._l(_vm.tableTitle, function (item) {
                return _c("ht-table-column", {
                  key: item.prop,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    fixed: item.fixed,
                    "show-overflow-tooltip": item.label !== "本次入库数量",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label === "可入库数量"
                              ? _c("span", [_vm._v(_vm._s(row[item.prop]))])
                              : item.label === "本次入库数量"
                              ? [
                                  _vm.inboundForm
                                    .constructionEquipmentSource === "OLD"
                                    ? _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          max: row.quantity,
                                          disabled: "",
                                          placeholder: "请输入入库数量",
                                          precision: 0,
                                        },
                                        model: {
                                          value: row.inboundQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row,
                                              "inboundQuantity",
                                              $$v
                                            )
                                          },
                                          expression: "row.inboundQuantity",
                                        },
                                      })
                                    : _vm.inboundForm
                                        .constructionEquipmentSource === "NEW"
                                    ? _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          max: row.isWhole ? 1 : Infinity,
                                          placeholder: "请输入入库数量",
                                          precision: 0,
                                        },
                                        model: {
                                          value: row.inboundQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row,
                                              "inboundQuantity",
                                              $$v
                                            )
                                          },
                                          expression: "row.inboundQuantity",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : _c("span", [
                                  _vm._v(_vm._s(row[item.prop] || "-")),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._v(" "),
              _c("ht-table-column-operation", {
                attrs: { width: 100 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除该条数据？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onDeleteEquipment($index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "入库文件：", prop: "attachmentData" } },
            [
              _vm.inboundForm.attachmentData
                ? _c("dw-file", {
                    attrs: {
                      url: _vm.inboundForm.attachmentData,
                      "show-del": "",
                      "justify-content": "left",
                    },
                    on: {
                      "delete-file": function ($event) {
                        _vm.inboundForm.attachmentData = null
                      },
                    },
                  })
                : _c("ht-upload-button", {
                    attrs: {
                      "file-type": ["doc", "pdf", "img"],
                      "key-prefix": "warehouse",
                      "button-title": "上传入库文件",
                    },
                    on: { "on-file-uploaded": _vm.onFileUploaded },
                  }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: { click: _vm.confirmSubmit },
            },
            [_vm._v("确认入库")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "addEquipmentDialog",
          attrs: {
            title:
              "添加设备（" +
              _vm.EquipmentSourceEnum[
                _vm.inboundForm.constructionEquipmentSource
              ] +
              "）",
            width: "750px",
          },
          on: { close: _vm.closeAddEquipmentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showAddEquipmentList
                ? _c("add-equipment-list", {
                    ref: "addEquipmentList",
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      "already-add-list": _vm.inboundForm.equipmentItems,
                    },
                    on: {
                      "on-add-equipments": function ($event) {
                        return _vm.closeAddEquipmentDialog(true, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }