var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "仓库名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.WarehouseStockListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "当前库存数"
                          ? _c("span", [_vm._v(_vm._s(row[item.prop]))])
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.isMobile
            ? _c("ht-table-column-operation", {
                attrs: { width: 110 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("dw-table-dropdown-buttons", {
                            attrs: { buttons: row.buttons, row: row },
                            on: {
                              "handle-button-click": function ($event) {
                                return _vm.handleButtonClick(row, arguments)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2614970760
                ),
              })
            : _c("ht-table-column-operation", {
                attrs: { width: 220 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "ht-button",
                          {
                            attrs: {
                              disabled: row.quantity === 0,
                              type: "text",
                              icon: "el-icon-view",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onViewWarehouseStockInfo(row)
                              },
                            },
                          },
                          [_vm._v("库存详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-button",
                          {
                            attrs: {
                              disabled: row.quantity === 0,
                              type: "text",
                              icon: "el-icon-right",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onWarehouseOutbound(row)
                              },
                            },
                          },
                          [_vm._v("出库")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-button",
                          {
                            attrs: { type: "text", icon: "el-icon-back" },
                            on: {
                              click: function ($event) {
                                return _vm.onWarehouseInbound(row)
                              },
                            },
                          },
                          [_vm._v("入库")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "warehouseStockInfoDialog",
          attrs: {
            title: "库存详情（" + _vm.warehouseName + "）",
            width: _vm.isMobile ? "100%" : "800px",
          },
          on: { close: _vm.closeWarehouseStockInfoDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showWarehouseStockInfo
                ? _c("warehouse-stock-info", { ref: "warehouseStockInfo" })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "warehouseOutboundDialog",
          attrs: {
            title: "出库",
            width: _vm.isMobile ? "100%" : "800px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeWarehouseOutboundDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showWarehouseOutboundForm
                ? _c("warehouse-outbound-form", {
                    ref: "warehouseOutboundForm",
                    on: {
                      "cancel-submit": _vm.closeWarehouseOutboundDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeWarehouseOutboundDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "warehouseInboundDialog",
          attrs: {
            title: "入库",
            width: _vm.isMobile ? "100%" : "800px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeWarehouseInboundDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showWarehouseInboundForm
                ? _c("warehouse-inbound-form", {
                    ref: "warehouseInboundForm",
                    on: {
                      "cancel-submit": _vm.closeWarehouseInboundDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeWarehouseInboundDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }