import Layout from '@/layout'
import { EXPERT, ADMIN } from '@/views/commonVar'
// STAFF,

const NewRemoteGuidanceRouter = {
  path: '/newRemoteGuidance',
  component: Layout,
  redirect: 'noRedirect',
  name: 'newRemoteGuidanceMenu',
  meta: { title: '远程指导', icon: 'guidance_1' },
  children: [
    {
      path: '/newRemoteGuidance/contactList',
      redirect: '/newRemoteGuidance/contactList/list',
      component: () => import('@/views/CommonRouterView'),
      name: 'contactList',
      meta: { title: '联系人', roles: [EXPERT, ADMIN] }, // 联系人菜单限制为专家和管理员可见
      children: [
        {
          path: '/newRemoteGuidance/contactList/list',
          component: () => import('@/views/newRemoteGuidance/contactList/list'),
          name: 'newRemoteGuidanceMenuContactList',
          meta: { title: '联系人', breadcrumb: false }
        }
      ]
    },
    {
      path: '/newRemoteGuidance/meetingRecords/list',
      component: () => import('@/views/newRemoteGuidance/meetingRecords/list'),
      name: 'meetingRecords',
      meta: { title: '会议记录' }
    },
    {
      path: '/newRemoteGuidance/meetingRecords/view/:roomId',
      component: () => import('@/views/newRemoteGuidance/meetingRecords/view'),
      hidden: true,
      props: true,
      name: 'recordsView',
      meta: { title: '会议详情', activeMenu: '/newRemoteGuidance/meetingRecords/list' }
    }
  ]
}

export default NewRemoteGuidanceRouter
