import { meetingVideoProperty } from '@/views/newRemoteGuidance/var/businessVar'
import LaserPen from '@/assets/image/newRemoteGuidance/laser_pen_red.jpg'
import dwCustomImMsg from '@/views/newRemoteGuidance/js/dwCustomImMsg'

export default {
  data() {
    return {
      LaserPen,
      originalRatio: 480 / 848,
      canvas: null,
      canvasCtx: null,
      paintPosition: { lastX: undefined, lastY: undefined, currentX: undefined, currentY: undefined },
      paintTracings: [],
      paintAllTracings: [],
      paintSendTimer: null,
      laserPosition: { currentX: undefined, currentY: undefined },
      laserTracings: [],
      laserPenImg: undefined,
      laserSendTimer: null,
      remoteLaserTracings: [],
      remotePaintTracings: [],
      historyPaintAllTracings: [],
      historyRemotePaintAllTracings: []
    }
  },
  computed: {
    screenWidthScale() {
      const width = this.videoProperty && this.videoProperty.width ? this.videoProperty.width : meetingVideoProperty.normalLargeVideoWidth
      const videoWidth = meetingVideoProperty.normalLargeVideoWidth
      return width / videoWidth
    },
    screenHeightScale() {
      const height = this.videoProperty && this.videoProperty.height ? this.videoProperty.height : meetingVideoProperty.normalLargeVideoHeight
      const videoHeight = meetingVideoProperty.normalLargeVideoHeight
      if (this.getRemoteVideoHeight() !== 'auto') {
        return height / this.getRemoteVideoHeight()
      } else {
        return height / videoHeight
      }
    },
    // 获取远端视频video的比例
    getVideoRadio() {
      if (this.videoProperty && this.videoProperty.height && this.videoProperty.width) {
        return this.videoProperty.height / this.videoProperty.width
      } else {
        return this.originalRatio
      }
    }
  },
  methods: {
    getRemoteVideoHeight() {
      if (this.videoProperty && this.videoProperty.height && this.videoProperty.width) {
        const ratio = this.videoProperty.height / this.videoProperty.width
        const width = this.videoSize === 'full-large' ? document.getElementById(this.id).offsetWidth : meetingVideoProperty.normalLargeVideoWidth
        return width * ratio
      } else {
        return 'auto'
      }
    },
    getRemoteVideoWidth() {
      if (this.videoProperty && this.videoProperty.height && this.videoProperty.width) {
        const ratio = this.videoProperty.height / this.videoProperty.width
        const height = this.videoSize === 'full-large' ? document.getElementById(this.id).offsetHeightoffsetHeight : meetingVideoProperty.normalLargeVideoHeight
        return height / ratio
      } else {
        return 'auto'
      }
    },
    // 获取裁剪方向
    getRemoteCutDirection() {
      // 0：宽被裁剪，1：正常，2： 高被裁剪
      if (this.videoProperty && this.videoProperty.height && this.videoProperty.width) {
        const ratio = this.videoProperty.height / this.videoProperty.width
        if (ratio > this.originalRatio) {
          return 2
        } else {
          return 0
        }
      } else {
        return 1
      }
    },
    // 激光笔移动
    laserMove(e) {
      this.laserPosition.currentX = e.offsetX
      this.laserPosition.currentY = e.offsetY
      this.animate()
      this.laserTracings.push({ x: e.offsetX, y: e.offsetY })
    },
    mobileLaserMove(e) {
      e.preventDefault()
      const trace = e.changedTouches[0]
      // const canvas = document.getElementById('freezeCanvas')
      const canvas = document.getElementById(this.canvasName)
      const canvasRect = canvas.getBoundingClientRect()
      const currentX = trace.pageX - canvasRect.left
      const currentY = trace.pageY - canvasRect.top
      const offsetRect = { offsetX: currentX, offsetY: currentY }
      this.laserMove(offsetRect)
    },
    // 激光笔动画
    animate() {
      if (this.canvasCtx) {
        this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.canvasCtx.drawImage(this.laserPenImg, this.laserPosition.currentX - 12, this.laserPosition.currentY - 12, 24, 24)
      }
    },
    // 发送激光笔坐标
    getSendLaserTracings() {
      const arr = this.laserTracings
      const count = this.laserTracings.length > 100 ? this.laserTracings.length - 1 : Math.min(50, arr.length)
      const sendTracings = this.laserTracings.splice(0, count)
      this.sendLaserMsg(sendTracings)
    },
    // 发送激光笔轨迹消息
    sendLaserMsg(laserData, status = true) {
      this.$emit('laser-moving', laserData, status, this.imUserId, this.getSenderScreenSize())
    },
    // 鼠标点击，画笔开始绘制
    paintDown(e) {
      this.paintPosition.lastX = e.offsetX
      this.paintPosition.lastY = e.offsetY
      this.getPaintTracing(e.offsetX, e.offsetY, e.offsetX + 2, e.offsetY + 2)
      this.paintDrawing(e.offsetX, e.offsetY, e.offsetX + 2, e.offsetY + 2)
      if (this.isMobile) {
        this.canvas.addEventListener('touchmove', this.mobilePaintMove, { passive: false })
      } else {
        this.canvas.addEventListener('mousemove', this.paintMove)
      }
      this.paintSendTimer = setInterval(() => {
        if (this.paintTracings.length > 0) {
          this.getSendPaintTracings()
        }
      }, 250)
    },
    mobilePaintDown(e) {
      e.preventDefault()
      const trace = e.changedTouches[0]
      // const canvas = document.getElementById('freezeCanvas')
      const canvas = document.getElementById(this.canvasName)
      const canvasRect = canvas.getBoundingClientRect()
      const currentX = trace.pageX - canvasRect.left
      const currentY = trace.pageY - canvasRect.top
      const offsetRect = { offsetX: currentX, offsetY: currentY }
      this.paintDown(offsetRect)
    },
    // 画笔移动
    paintMove(e) {
      this.paintPosition.currentX = e.offsetX
      this.paintPosition.currentY = e.offsetY
      this.getPaintTracing(this.paintPosition.lastX, this.paintPosition.lastY, e.offsetX, e.offsetY)
      this.paintDrawing(this.paintPosition.lastX, this.paintPosition.lastY, this.paintPosition.currentX, this.paintPosition.currentY)
      this.paintPosition.lastX = this.paintPosition.currentX
      this.paintPosition.lastY = this.paintPosition.currentY
      if (new Blob([JSON.stringify(this.paintAllTracings)]).size / 1024 > 100) {
        this.getSendAllPaintTracings()
      }
    },
    mobilePaintMove(e) {
      e.preventDefault()
      const trace = e.changedTouches[0]
      // const canvas = document.getElementById('freezeCanvas')
      const canvas = document.getElementById(this.canvasName)
      const canvasRect = canvas.getBoundingClientRect()
      const currentX = trace.pageX - canvasRect.left
      const currentY = trace.pageY - canvasRect.top
      const offsetRect = { offsetX: currentX, offsetY: currentY }
      this.paintMove(offsetRect)
    },
    // 鼠标抬起，画笔结束绘制
    paintUp() {
      this.canvas.removeEventListener('mousemove', this.paintMove)
      this.getSendAllPaintTracings()
      clearInterval(this.paintSendTimer)
    },
    mobilePaintUp() {
      this.canvas.removeEventListener('touchmove', this.mobilePaintMove, { passive: false })
      this.getSendAllPaintTracings()
      clearInterval(this.paintSendTimer)
    },
    // 本地：画笔轨迹绘制
    paintDrawing(lastX, lastY, currentX, currentY) {
      this.canvasCtx.beginPath()
      this.canvasCtx.moveTo(lastX, lastY)
      this.canvasCtx.lineTo(currentX, currentY)
      this.canvasCtx.stroke()
    },
    // 获取画笔轨迹
    getPaintTracing(lastX, lastY, currentX, currentY) {
      this.paintTracings.push({ x1: lastX, y1: lastY, x2: currentX, y2: currentY })
      this.paintAllTracings.push({ x1: lastX, y1: lastY, x2: currentX, y2: currentY })
    },
    // 一定时间间隔内的画笔轨迹
    getSendPaintTracings() {
      const arr = this.paintTracings
      const count = this.paintTracings.length > 100 ? this.paintTracings.length - 1 : Math.min(50, arr.length)
      const sendTracings = this.paintTracings.splice(0, count)
      this.sendPaintMsg(sendTracings)
    },
    // 鼠标点击到鼠标抬起之间所有的画笔轨迹
    getSendAllPaintTracings() {
      if (this.paintAllTracings.length > 0) {
        setTimeout(() => {
          this.sendPaintMsg(this.paintAllTracings)
          this.setHistoryPaintAllTracings(this.paintAllTracings)
          this.paintAllTracings = []
        }, 500)
      }
    },
    // 发送画笔轨迹消息
    sendPaintMsg(paintData, status = true) {
      this.$emit('paint-drawing', paintData, status, this.imUserId, this.getSenderScreenSize())
    },
    // 远端激光笔轨迹绘制
    remoteLaser(laser) {
      if (laser && laser.status === dwCustomImMsg.DwLaser.Open && laser.laserData) {
        this.remoteLaserTracings = laser.laserData
        const count = this.remoteLaserTracings.length
        const ratio = Math.round(250 / count)
        this.remoteLaserTracings.forEach(item => {
          setTimeout(() => {
            this.clearCanvas()
            this.remoteAnimate(item.x, item.y, laser.senderScreenSize)
          }, ratio)
        })
      } else if (laser && laser.status === dwCustomImMsg.DwLaser.Close) {
        this.clearCanvas()
      }
    },
    // 远端激光笔动画
    remoteAnimate(x, y, senderScreenSize) {
      const scale = this.getRemoteAndLocalScale(senderScreenSize)
      this.canvasCtx.drawImage(this.laserPenImg, x * scale.x - 12, y * scale.y - 12, 24, 24)
    },
    // 远端画笔轨迹绘制
    remotePaint(paint) {
      if (paint && paint.status === dwCustomImMsg.DwPaint.Open && paint.paintData) {
        this.remotePaintTracings = paint.paintData
        const count = this.remotePaintTracings.length
        const ratio = Math.round(250 / count)
        this.remotePaintTracings.forEach(item => {
          setTimeout(() => {
            this.remotePaintDraw(item.x1, item.y1, item.x2, item.y2, paint.senderScreenSize)
          }, ratio)
        })
        this.historyRemotePaintAllTracings.push({ style: paint.senderScreenSize, tracings: this.remotePaintTracings })
      } else if (paint && (paint.status === dwCustomImMsg.DwPaint.Clear || paint.status === dwCustomImMsg.DwPaint.Close)) {
        this.clearCanvas()
      }
    },
    //  远端画笔绘画
    remotePaintDraw(x1, y1, x2, y2, senderScreenSize) {
      const scale = this.getRemoteAndLocalScale(senderScreenSize)
      this.canvasCtx.beginPath()
      this.canvasCtx.moveTo(x1 * scale.x, y1 * scale.y)
      this.canvasCtx.lineTo(x2 * scale.x, y2 * scale.y)
      this.canvasCtx.stroke()
    },
    // 清除画布
    clearCanvas() {
      if (this.canvasCtx) {
        this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      }
      this.historyPaintAllTracings = []
      this.historyRemotePaintAllTracings = []
    },
    setHistoryPaintAllTracings(tracings) {
      const video = document.getElementById(this.id)
      const data = {
        style: { x: video.clientWidth, y: video.clientHeight },
        tracings: tracings
      }
      this.historyPaintAllTracings.push(data)
    }
  }
}
