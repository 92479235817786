import MobileLayout from '@/layout/mobileLayout'
import { ADMIN, EXPERT } from '@/views/commonVar'
import MobileStockMgr from '@/router/modules/mobileStockMgr'

const MobileRouter = [
  {
    path: '/mobile',
    component: MobileLayout,
    redirect: '/mobileHomePage',
    hidden: true,
    children: [
      {
        path: '/mobileHomePage',
        component: () => import('@/views/mobileHomePage'),
        name: 'MobileHomePage',
        meta: { title: '首页', params: { showMainBottom: true, prevMenu: null }}
      }
    ]
  },
  {
    path: '/mobileProfile',
    component: MobileLayout,
    redirect: '/mobileProfilePage',
    hidden: true,
    children: [
      {
        path: '/mobileProfilePage',
        component: () => import('@/views/mobileProfilePage'),
        name: 'MobileProfilePage',
        meta: { title: '个人中心', params: { showMainBottom: true, prevMenu: null }}
      }
    ]
  },
  {
    path: '/mobile/newRemoteGuidance',
    component: MobileLayout,
    redirect: '/mobile/ewRemoteGuidance/contactList/list',
    hidden: true,
    children: [
      {
        path: '/mobile/ewRemoteGuidance/contactList/list',
        component: () => import('@/views/newRemoteGuidance/contactList/list'),
        name: 'MobileNewRemoteGuidance',
        meta: { title: '远程指导', params: { showMainBottom: false, prevMenu: 'MobileHomePage' }, roles: [EXPERT, ADMIN] }
      }
    ]
  },
  {
    path: '/mobile/patrolWorkOrderMgr',
    component: MobileLayout,
    hidden: true,
    children: [
      {
        path: 'mobile/patrolWorkOrderMgr/create',
        component: () => import('@/views/taskMgr/patrolWorkOrderMgr/mobile/create'),
        name: 'MobilePatrolWorkOrderCreate',
        meta: { title: '下发工单', params: { showMainBottom: false, prevMenu: 'MobileHomePage' }}
      },
      {
        path: 'mobile/patrolWorkOrderMgr/list',
        component: () => import('@/views/taskMgr/patrolWorkOrderMgr/mobile/list'),
        name: 'MobilePatrolWorkOrderList',
        meta: { title: '工单列表', params: { showMainBottom: false, prevMenu: 'MobileHomePage' }}
      },
      {
        path: 'mobile/patrolWorkOrderMgr/view/:orderId',
        component: () => import('@/views/taskMgr/patrolWorkOrderMgr/mobile/view'),
        name: 'MobilePatrolWorkOrderView',
        props: true,
        meta: { title: '工单详情', params: { showMainBottom: false, prevMenu: 'MobilePatrolWorkOrderList' }}
      }
      // {
      //   path: 'mobile/stockMgr/list',
      //   component: () => import('@/views/constructionEquipmentMgr/stockMgr/mobile/list'),
      //   name: 'MobileStockMgr',
      //   props: true,
      //   meta: { title: '库存管理', params: { showMainBottom: false, prevMenu: 'MobileHomePage' }}
      // }
    ]
  },
  MobileStockMgr
]

export default MobileRouter
