<template>
  <div class="step-ui-detail-container" :style="{height: height}">
    <div class="view-container">
      <div class="top-section">
        <div class="view-title top-title" :class="{'focused-item': isFocusedItem === 'title'}">{{ `${currentIndex+1}/${count}` }}：{{ properties.title || '步骤名称' }}</div>
        <div class="top-button-container">
          <el-button v-if="currentIndex > 0" class="top-title-button prev-button" type="text" @click="toPrev">上一项</el-button>
          <el-button v-if="currentIndex + 1 < count" class="top-title-button next-button" type="text" @click="toNext">下一项</el-button>
          <el-button :class="{'focused-item': isFocusedItem === 'text'}" class="top-title-button voice-button" type="text" @click="nativePlayTTS(properties.guidanceVideo)">语音播报</el-button>
          <el-button class="top-title-button more-function-button" type="text" @click="moreFunction">更多功能</el-button>
          <div v-if="showMoreFunction" class="more-function-container">
            <img :src="Triangle" alt="" class="more-function-img">
            <el-button class="top-title-button guidance-button" type="text" @click="toRemoteGuidance">远程指导</el-button>
            <el-button class="top-title-button ai-button" type="text" @click="toAiQaAssistant">问答助手</el-button>
          </div>
        </div>
      </div>
      <div class="middle-section">
        <div class="left-container" :class="{'focused-item': isFocusedItem === 'referenceImage'}">
          <div class="sub-title only-text-title">参考图</div>
          <div class="img-container reference-img-container">
            <dw-el-image
              v-if="properties.referenceImage"
              ref="referenceImage"
              :src="properties.referenceImage"
              alt=""
              class="reference-img"
              :preview-src-list="[properties.referenceImage]"
              :show-icon="false"
            />
            <el-button
              v-if="properties.referenceImage"
              class="sub-title-button fullscreen-button"
              @click="fullScreenReferenceImage"
            >查看参考图</el-button>
          </div>
        </div>
        <div class="right-container" :class="{'focused-item': isFocusedItem === 'text'}">
          <div class="sub-title only-text-title">检查要求</div>
          <el-input
            v-model="properties.text"
            placeholder="检查要求"
            style="width: 100%; height: 100%"
            type="textarea"
            class="check-standard"
            disabled
          />
        </div>
      </div>
      <div class="bottom-section">
        <div class="top-container">
          <div class="left-container">
            <div class="title-container">
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length < 9"
                class="sub-title-button photo-button"
                type="text"
                @click="nativeTakePhoto"
              >
                <span v-if="properties.mustUploadPhoto" class="required-item">*</span>拍摄</el-button>
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length > 0"
                class="sub-title-button del-button"
                type="text"
                @click="delPhoto"
              >删除</el-button>
            </div>
            <div class="img-container">
              <dw-carousel ref="customCarousel" height="140px" :autoplay="false" arrow="always" @change="setActiveItem">
                <dw-carousel-item v-for="(item, index) in submitResults.uploadPhotos" :key="index">
                  <dw-el-image :ref="`result${index}`" :src="item" alt="" class="result-img" :preview-src-list="results.uploadPhotos" :show-icon="false" />
                </dw-carousel-item>
              </dw-carousel>
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length > 0"
                class="sub-title-button fullscreen-button"
                @click="fullScreenPhoto"
              >查看照片</el-button>
            </div>
          </div>
          <div class="right-container">
            <div class="title-container">
              <el-button class="sub-title-button fact-button" type="text" @click="inputDesc">情况描述</el-button>
              <el-button class="sub-title-button clear-button" type="text" @click="clearFactDesc">清空</el-button>
            </div>
            <el-input
              ref="rectDescRef"
              v-model="submitResults.factDesc"
              placeholder="情况描述"
              style="width: 100%; height: 100%"
              type="textarea"
              class="check-standard"
            />
          </div>
        </div>
        <div class="bottom-container">
          <div class="bottom-left-container">
            <span class="required-item">*</span>
            <span class="sub-title result-title">检查结果：</span>
            <el-radio-group v-model="submitResults.checkResult">
              <el-radio v-for="(value, key) in ResultsEnum" :key="key" :label="key" title="hf_no_overlay">{{ value }}</el-radio>
            </el-radio-group>
          </div>
          <div class="bottom-right-container">
            <el-button class="save-button bottom-button" type="text" @click="saveRecord">保存记录</el-button>
            <el-button v-if="isLastStep" class="submit-button bottom-button" type="text" @click="submitRecord">{{ isLastStep ? '提交单据' : '保存记录' }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/templateMgr/components/stepUiMixin'
import { ResultsEnum } from '@/views/taskMgr/enum'

export default {
  name: 'StepUi',
  mixins: [StepUiMixin],
  props: {
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          referenceImage: null,
          guidanceVideo: null,
          text: null,
          mustUploadPhoto: null
        }
      }
    },
    results: {
      type: Object,
      default() {
        return {
          latitude: null,
          longitude: null,
          location: null,
          uploadPhotos: [],
          factDesc: null,
          checkResult: null
        }
      }
    }
  },
  data() {
    return {
      ResultsEnum
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/taskMgr/templateMgr/components/stepUiCommonStyle";
@import "~@/styles/variables.scss";

.step-ui-detail-container{
  .view-container{
    .bottom-section{
      .bottom-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 10px;
        .bottom-left-container{
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;
          .result-title{
            color: #000000;
          }
          .el-radio {
            margin-right: 5px;
            ::v-deep .el-radio__input .el-radio__inner{
              width: 1.3rem;
              height: 1.3rem;
              vertical-align: middle;
            }
            ::v-deep .el-radio__label{
              font-size: 1.3rem;
              vertical-align: middle;
              padding-left: 5px;
            }
          }
          .el-radio.is-checked{
            ::v-deep .el-radio__inner {
              border-color: $mobilePrimaryColor;
              background: $mobilePrimaryColor;;
            }
            ::v-deep .el-radio__label{
              color: $mobilePrimaryColor;;
            }
          }
        }
        .bottom-right-container{
          flex: 1;
          display: flex;
          flex-direction: row;
          .bottom-button{
            height: 40px;
            color: #ffffff;
            font-size: 1.5rem;
            background: $mobilePrimaryColor;;
            border-radius: 5px;
          }
          .save-button{
            flex: 1;
          }
          .submit-button{
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
