<template>
  <div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" v-on="$listeners" />
  <svg v-else :class="[svgClass,`icon-${type}`, extraClass]" aria-hidden="true" v-on="$listeners">
    <use :href="iconName" />
  </svg>
</template>

<script>
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
import { isExternal } from '@/utils/validate'

export default {
  name: 'SvgIcon',
  props: {
    iconClass: { type: String, required: true },
    className: { type: String, default: '' },
    type: { type: String, default: 'none' }, // primary,success,info,warning,danger,none
    extraClass: { type: String, default: '' }
  },
  computed: {
    isExternal() {
      return isExternal(this.iconClass)
    },
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover!important;
  display: inline-block;
}

.icon-none {
  color: $menuText
}

.icon-primary {
  color: $primaryColor;
}

.icon-success {
  color: #67c23a;
}

.icon-info {
  color: #909399;
}

.icon-danger {
  color: #e6a23c;
}

.icon-warning {
  color: #f56c6c;
}

.active-icon{
  color: $menuActiveText;
}
</style>
