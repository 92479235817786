import request from '@/utils/request'

const STATIC = 'statistics/'

export function bizCountApi(listQuery) {
  return request({ url: `${STATIC}biz_count`, method: 'GET', params: listQuery })
}

export function tenantInfoApi() {
  return request({ url: `user/staffs/tenant/info`, method: 'GET' })
}

export function loginCountApi(listQuery) {
  return request({ url: `${STATIC}login_count`, method: 'GET', params: listQuery })
}

export function onlineCountApi(listQuery) {
  return request({ url: `${STATIC}glass_online_count`, method: 'GET', params: listQuery })
}
