<template>
  <div>
    <el-form ref="inboundForm" :model="inboundForm" :rules="inboundFormRules" label-width="100px">
      <el-form-item label="入库设备：" prop="constructionEquipmentId">
        <equipment-descriptions :equipment-info="equipmentInfo" />
      </el-form-item>
      <el-form-item label="设备来源：" prop="constructionEquipmentSource">
        <el-radio-group
          v-model="inboundForm.constructionEquipmentSource"
          :disabled="isChangeEquipmentSourceDisabled"
          @change="handleEquipmentSourceChange"
        >
          <el-radio v-for="(value, key) in EquipmentSourceEnum" :key="key" :label="key">{{ value }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--       整机入库-->
      <template v-if="equipmentInfo && equipmentInfo.isWhole">
        <el-form-item v-if="inboundForm.constructionEquipmentSource === 'OLD'" label="选择工程：" prop="constructionProjectId">
          <el-select v-model="inboundForm.constructionProjectId" disabled style="width: 100%">
            <el-option v-for="item in projectList" :key="item.constructionProjectId" :label="item.constructionProjectName" :value="item.constructionProjectId" />
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库：" prop="warehouseId">
          <el-select v-model="inboundForm.warehouseId" :remote-method="getWarehouses" placeholder="请选择入库仓库" style="width: 100%" @change="handleWarehouseChange">
            <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="入库数量：" prop="inboundQuantity">
          <el-input v-model="inboundForm.inboundQuantity" disabled placeholder="请输入入库数量" />
        </el-form-item>
      </template>
      <!--       部件入库-->
      <template v-else>
        <template v-if="inboundForm.constructionEquipmentSource === 'OLD'">
          <el-form-item label="选择工程：" prop="constructionProjectId">
            <el-select v-model="inboundForm.constructionProjectId" filterable :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'" style="width: 100%" @change="handleSelectProject">
              <el-option v-for="item in projectList" :key="item.constructionProjectId" :label="item.constructionProjectName" :value="item.constructionProjectId" />
            </el-select>
          </el-form-item>
          <el-form-item label="入库仓库：" prop="warehouseId">
            <el-select v-model="inboundForm.warehouseId" :remote-method="getWarehouses" placeholder="请选择入库仓库" style="width: 100%" @change="handleWarehouseChange">
              <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="inboundForm.constructionProjectId" label="入库数量：" prop="inboundQuantity">
            <el-input v-model="inboundForm.inboundQuantity" disabled placeholder="请输入入库数量" />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="入库总数量：">
            <span class="quantities"> {{ inboundQuantities }}</span>
          </el-form-item>
          <ht-table ref="list" :data="warehouseList" style="margin-bottom: 18px">
            <ht-table-column label="序号" type="index" fixed="left" />
            <ht-table-column label="仓库名称" prop="name" />
            <ht-table-column label="入库数量" :show-overflow-tooltip="false" fixed="right" prop="inboundQuantity" width="200">
              <template v-slot="{row}">
                <el-input-number
                  v-if="inboundForm.constructionEquipmentSource === 'OLD'"
                  v-model="row.inboundQuantity"
                  :min="0"
                  :max="quantities"
                  :step="1"
                  step-strictly
                  :disabled="row.inboundQuantity > 0 ? false :quantities === 0"
                  placeholder="请输入入库数量"
                  :precision="0"
                  style="width: 100%"
                />
                <el-input-number
                  v-else
                  v-model="row.inboundQuantity"
                  :min="0"
                  :max="equipmentInfo && equipmentInfo.isWhole ? 1 : Infinity"
                  :step="1"
                  step-strictly
                  placeholder="请输入入库数量"
                  :precision="0"
                  style="width: 100%"
                />
              </template>
            </ht-table-column>
          </ht-table>
        </template>
      </template>
      <el-form-item label="入库文件：" prop="attachmentData">
        <dw-file v-if="inboundForm.attachmentData" :url="inboundForm.attachmentData" show-del justify-content="left" @delete-file="inboundForm.attachmentData = null" />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传入库文件" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认入库</el-button>
    </div>
  </div>
</template>

<script>
import { EquipmentSourceEnum } from '@/views/constructionEquipmentMgr/enum'
import { getWarehouseStocks, putEquipmentStocks, getEquipmentStockProjects } from '@/api/constructionEquipmentMgr/stockMgr'
import EquipmentDescriptions from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentDescriptions'

export default {
  name: 'InboundForm',
  components: { EquipmentDescriptions },
  data() {
    return {
      EquipmentSourceEnum,
      inboundForm: {
        action: 'inbound',
        constructionEquipmentId: null,
        constructionEquipmentSource: null,
        constructionProjectId: null,
        projectName: null,
        warehouseItems: [],
        attachmentData: null,
        warehouseId: null,
        inboundQuantity: 1,
        isWhole: false
      },
      inboundFormRules: {
        constructionEquipmentId: [{ required: true, message: '请选择入库设备' }],
        constructionEquipmentSource: [{ required: true, message: '请选择设备来源', trigger: 'change' }],
        constructionProjectId: [{ required: true, message: '请选择选择工程', trigger: 'change' }],
        // attachmentData: [{ required: true, message: '请上传入库文件', trigger: 'change' }],
        //   辅助验证
        warehouseId: [{ required: true, message: '请选择入库仓库', trigger: 'change' }],
        inboundQuantity: [{ required: true, message: '请输入入库数量', trigger: 'blur' }]
      },
      projectsQuantities: 0,
      isSubmitting: false,
      equipmentInfo: null,
      projectList: [],
      isProjectLoading: false,
      warehouseList: [],
      quantities: 0,
      inboundQuantities: 0
    }
  },
  computed: {
    isChangeEquipmentSourceDisabled() {
      if (this.equipmentInfo && this.equipmentInfo.isWhole) {
        return true
      } else {
        return this.equipmentInfo && this.equipmentInfo.projectStockQuantity === 0
      }
    }
  },
  watch: {
    warehouseList: {
      handler: function() {
        this.inboundQuantities = this.warehouseList.reduce((quantity, current) => {
          return quantity + current.inboundQuantity || 0
        }, 0)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initInfo(equipmentInfo) {
      this.equipmentInfo = equipmentInfo
      this.inboundForm.constructionEquipmentId = equipmentInfo.id
      this.inboundForm.isWhole = equipmentInfo.isWhole
      this.getEquipmentStockProjects(equipmentInfo)
      this.getWarehouses()
    },
    getEquipmentStockProjects(equipmentInfo) {
      this.quantities = 0
      this.projectsQuantities = 0
      getEquipmentStockProjects(equipmentInfo.id, { action: 'inbound' }).then(resp => {
        this.projectList = resp
        this.projectsQuantities = resp.reduce((quantity, current) => {
          if (current.quantity > 0) {
            return quantity + current.quantity
          }
          return quantity
        }, 0)
        this.inboundForm.constructionEquipmentSource = this.projectsQuantities > 0 ? 'OLD' : 'NEW'
        if (equipmentInfo && equipmentInfo.isWhole) {
          this.inboundForm.inboundQuantity = 1
          if (this.projectsQuantities > 0) {
            this.inboundForm.constructionProjectId = resp[0].constructionProjectId
          }
          this.quantities = this.projectsQuantities
        }
      })
    },
    handleEquipmentSourceChange(value) {
      if (value === 'OLD') { this.getEquipmentStockProjects(this.equipmentInfo) }
      this.inboundForm.constructionProjectId = null
      this.warehouseList.forEach(item => {
        item.inboundQuantity = 0
      })
    },
    getWarehouses(value) {
      getWarehouseStocks({ equipmentId: this.equipmentInfo.id, keyword: value }).then(resp => {
        resp.forEach(item => { item.inboundQuantity = 0 })
        this.warehouseList = resp
      })
    },
    handleWarehouseChange(value) {
      this.warehouseList.forEach(item => {
        item.inboundQuantity = 0
      })
      const index = this.warehouseList.findIndex(item => item.id === value)
      if (index !== -1) {
        this.warehouseList[index].inboundQuantity = this.inboundForm.inboundQuantity
      }
    },
    handleSelectProject(value) {
      const index = this.projectList.findIndex(item => item.constructionProjectId === value)
      this.inboundForm.inboundQuantity = index !== -1 ? this.projectList[index].quantity : 0
      this.quantities = index !== -1 ? this.projectList[index].quantity : 0
    },
    onFileUploaded(context, url) {
      this.inboundForm.attachmentData = url
      if (this.inboundForm.attachmentData) {
        this.$refs.inboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      const inboundForm = this.getInboundForm()
      this.$refs.inboundForm.validate(valid => {
        if (valid) {
          const hasQuantity = inboundForm.warehouseItems.some(item => item.quantity > 0)
          if (!hasQuantity) {
            this.$message({ message: `请选择仓库并输入入库数量`, type: 'error', center: true })
            return false
          }
          const inboundQuantities = inboundForm.warehouseItems.reduce((quantity, current) => {
            if (current.quantity > 0) {
              return quantity + current.quantity
            }
            return quantity
          }, 0)
          console.log(inboundQuantities, this.quantities)
          if (this.inboundForm.constructionEquipmentSource === 'OLD' && inboundQuantities > this.quantities) {
            this.$message({ message: `入库总数量不能大于可入库数量`, type: 'error', center: true })
            return false
          }
          if (this.equipmentInfo && this.equipmentInfo.isWhole && inboundQuantities !== 1) {
            this.$message({ message: `该设备入库总数量只能等于1`, type: 'error', center: true })
            return false
          }
          this.isSubmitting = true
          putEquipmentStocks(inboundForm).then(() => {
            this.$message({ message: `入库成功`, type: 'success', center: true })
            this.$emit('confirm-submit')
            this.isSubmitting = false
          }).catch(() => { this.isSubmitting = false })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getInboundForm() {
      const data = {
        action: 'inbound',
        constructionEquipmentId: null,
        constructionProjectId: null,
        warehouseItems: [],
        attachmentData: [],
        isWhole: false
      }
      data.constructionEquipmentId = this.inboundForm.constructionEquipmentId
      data.constructionProjectId = this.inboundForm.constructionProjectId
      data.warehouseItems = this.warehouseList.map(item => {
        return {
          warehouseId: item.id,
          warehouseName: item.name,
          quantity: item.inboundQuantity || 0 }
      }).filter(item => item.quantity > 0)
      data.attachmentData = this.inboundForm.attachmentData ? [this.inboundForm.attachmentData] : []
      data.isWhole = this.inboundForm.isWhole
      return data
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.equipment-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
}
.quantities{
  font-size: 14px;
  font-weight: bold;
  color: $primaryColor;
}

.line-two-item-row{
  display: flex;
  .el-form-item{
    width: calc(50% - 10px); /* 每个项目的宽度为50%，减去10像素的间隔 */
    margin-right: 20px;
  }
}
</style>
