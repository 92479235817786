<template>
  <el-card class="container">
    <div class="title-container">
      <div class="left-title">文档分类</div>
      <ht-action-panel class="right-search" :on-search="onSearch" :on-clear="onClear">
        <template slot="left">
          <el-input v-model="listQuery.title" placeholder="标题" />
          <el-input v-model="listQuery.publishedStaffName" placeholder="发布人" />
          <el-select v-model="listQuery.status" placeholder="状态">
            <el-option v-for="(value,key ) in StatusEnum" :key="key" :label="value" :value="key" />
          </el-select>
        </template>
      </ht-action-panel>
    </div>
    <div class="content-container">
      <div class="left-category-container">
        <category-tree
          ref="categoryTree"
          class="left-category-tree"
          :loading="isTreeLoading"
          :tree-list="getTree"
          :show-function-box="showFunctionBox"
          :margin-bottom="200"
          top-level-first-button-name="新建一级目录"
          :is-disabled="isLoading"
          @node-click="handleClickTreeNode"
          @handle-click-more="handleClickMore"
          @click-outside="handleClickOutside"
        >
          <template v-slot:functions>
            <ht-button v-if="showCreateSubCategory" class="function-button top-button" type="text" icon="el-icon-plus" @click="toggleCreateCategoryDialog(true)">新建子目录</ht-button>
            <ht-button class="function-button" :class="{'top-button': !showCreateSubCategory}" type="text" icon="el-icon-plus" @click="toggleAddKnowledgeDialog(true)">新建文档</ht-button>
            <ht-upload-button :file-type="['pdf','doc']" button-type="text" class="function-button" key-prefix="knowledge" button-title="导入文件" :show-success-tip="false" @on-file-uploaded="handleKnowledgeFileUploaded" />
            <ht-button class="function-button" type="text" icon="el-icon-edit" @click="toggleEditCategoryDialog(true)">重命名</ht-button>
            <ht-button class="function-button" type="text" icon="el-icon-rank" @click="handleClickMoveCategory">移动</ht-button>
            <ht-popover-button type="text" class="function-button confirm-button" icon="el-icon-delete" confirm-title="请确认是否要删除该目录？" @confirmed="handleDelCategory">删除</ht-popover-button>
          </template>
        </category-tree>
      </div>
      <div class="right-knowledge-container">
        <ht-table ref="list" v-loading="isLoading" :data="list">
          <ht-table-column label="序号" type="index" fixed="left" />
          <ht-table-column v-for="item in KnowledgeMgrListTitle" :key="item.prop" :label="item.label" :prop="item.prop" :min-width="item.width || 100" :fixed="item.fixed">
            <template v-slot="{row}">
              <span v-if="item.label === '附件'">
                <el-link
                  :type="row[item.prop] && row[item.prop].length > 0 ? 'primary': 'info'"
                  :disabled="row[item.prop] && row[item.prop].length === 0"
                  @click="toggleAttachmentDialog(row, true)"
                >{{ row[item.prop] && row[item.prop].length }}</el-link>
              </span>
              <span v-else-if="item.label === '创建人'">{{ row.staff && row.staff.fullName || '-' }}</span>
              <span v-else-if="item.label === '发布人'">{{ row.publishedStaff && row.publishedStaff.fullName || '-' }}</span>
              <el-tag v-else-if="item.label === '状态'" :type="StatusStyleEnum[row[item.prop]]">{{ StatusEnum[row[item.prop]] }}</el-tag>
              <span v-else-if="item.label === '浏览次数'">{{ row[item.prop] || '0' }}</span>
              <span v-else>{{ row[item.prop] || '-' }}</span>
            </template>
          </ht-table-column>
          <ht-table-column-operation :width="280">
            <template v-slot="{row}">
              <ht-button type="text" icon="el-icon-edit" @click="toggleEditKnowledgeDialog(row, true)">编辑</ht-button>
              <ht-popover-button v-if="row.status === 'PUBLISHED'" type="text" icon="el-icon-notebook-1" confirm-title="请确认是否要转为草稿？" @confirmed="toggleKnowledgeStatus(row, 'DRAFT')">转为草稿</ht-popover-button>
              <ht-popover-button v-else-if="row.status === 'DRAFT'" type="text" icon="el-icon-s-promotion" confirm-title="请确认是否要直接发布？" @confirmed="toggleKnowledgeStatus(row, 'PUBLISHED')">直接发布</ht-popover-button>
              <ht-button type="text" icon="el-icon-rank" @click="toggleMoveKnowledgeDialog(row, true)">移动</ht-button>
              <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该条数据？" @confirmed="handleDeleteKnowledge(row)">删除</ht-popover-button>
            </template>
          </ht-table-column-operation>
        </ht-table>
        <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />
      </div>
    </div>
    <ht-dialog ref="addCategoryDialog" :title="`添加目录`" width="400px" :close-on-click-modal="false" @close="toggleCreateCategoryDialog(false)">
      <div slot="content">
        <category-form v-if="showAddCategoryForm" ref="addCategoryForm" :parent-id="currentNodeDataParentId" @cancel-submit="toggleCreateCategoryDialog(false)" @confirm-submit="toggleCreateCategoryDialog(false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="editCategoryDialog" :title="`编辑目录`" width="400px" :close-on-click-modal="false" @close="toggleEditCategoryDialog(false)">
      <div slot="content">
        <category-form v-if="showEditCategoryForm" ref="editCategoryForm" is-edit :category-data="currentNodeData" @cancel-submit="toggleEditCategoryDialog(false)" @confirm-submit="toggleEditCategoryDialog(false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="moveCategoryDialog" title="移动目录" width="450px" :close-on-click-modal="false" @close="toggleMoveCategoryDialog(false)">
      <div slot="content">
        <move-category-form v-if="showMoveCategoryForm" ref="moveCategoryForm" :tree="tree" :category-data="currentNodeData" @cancel-submit="toggleMoveCategoryDialog(false)" @confirm-submit="toggleMoveCategoryDialog(false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="addKnowledgeDialog" title="新建文档" width="1000px" :close-on-click-modal="false" :fullscreen="isFullscreenKnowledgeDialog" class="knowledge-edit-dialog" @close="toggleAddKnowledgeDialog(false)">
      <div slot="close">
        <img v-if="isFullscreenKnowledgeDialog" class="maximize fullscreen-icon" :src="ExitFul" alt="" @click="toggleFullscreenKnowledgeDialog">
        <img v-else class="maximize fullscreen-icon" :src="EnterFul" alt="" @click="toggleFullscreenKnowledgeDialog">
      </div>
      <div slot="content">
        <knowledge-form v-if="showAddKnowledgeForm" ref="addKnowledgeForm" :category-data="currentNodeData" :is-fullscreen="isFullscreenKnowledgeDialog" @cancel-submit="toggleAddKnowledgeDialog(false)" @confirm-submit="toggleAddKnowledgeDialog(false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="editKnowledgeDialog" title="编辑文档" width="1000px" :close-on-click-modal="false" :fullscreen="isFullscreenKnowledgeDialog" class="knowledge-edit-dialog" @close="toggleEditKnowledgeDialog(null,false)">
      <div slot="close">
        <img v-if="isFullscreenKnowledgeDialog" class="maximize fullscreen-icon" :src="ExitFul" alt="" @click="toggleFullscreenKnowledgeDialog">
        <img v-else class="maximize fullscreen-icon" :src="EnterFul" alt="" @click="toggleFullscreenKnowledgeDialog">
      </div>
      <div slot="content">
        <knowledge-form v-if="showEditKnowledgeForm" ref="editKnowledgeForm" is-edit :category-data="currentNodeData" :is-fullscreen="isFullscreenKnowledgeDialog" :knowledge-data="currentKnowledgeData" @cancel-submit="toggleEditKnowledgeDialog(null,false)" @confirm-submit="toggleEditKnowledgeDialog(null,false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="importKnowledgeDialog" title="文档导入" width="1000px" :close-on-click-modal="false" :fullscreen="isFullscreenKnowledgeDialog" class="knowledge-edit-dialog" @close="toggleImportKnowledgeDialog(false)">
      <div slot="close">
        <img v-if="isFullscreenKnowledgeDialog" class="maximize fullscreen-icon" :src="ExitFul" alt="" @click="toggleFullscreenKnowledgeDialog">
        <img v-else class="maximize fullscreen-icon" :src="EnterFul" alt="" @click="toggleFullscreenKnowledgeDialog">
      </div>
      <div slot="content">
        <knowledge-form v-if="showImportKnowledgeForm" ref="importKnowledgeForm" is-import :category-data="currentNodeData" :is-fullscreen="isFullscreenKnowledgeDialog" :knowledge-data="currentKnowledgeData" @cancel-submit="toggleImportKnowledgeDialog(false)" @confirm-submit="toggleImportKnowledgeDialog(false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="moveKnowledgeDialog" title="移动文件" width="450px" :close-on-click-modal="false" @close="toggleMoveKnowledgeDialog(null,false)">
      <div slot="content">
        <move-knowledge-form v-if="showMoveKnowledgeForm" ref="moveKnowledge" :tree="tree" :knowledge-data="currentKnowledgeData" @cancel-submit="toggleMoveKnowledgeDialog(null,false)" @confirm-submit="toggleMoveKnowledgeDialog(null,false,true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="attachmentListDialog" title="附件" width="600px" @close="toggleAttachmentDialog(null,false)">
      <div slot="content">
        <attachment-list v-if="showAttachmentList" ref="documentList" :attachment-list="attachmentList" :show-operation="false" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import CategoryTree from '@/views/components/tree/folderTree'
import CategoryForm from '@/views/knowledgeBaseMgr/knowledgeMgr/components/categoryForm'
import KnowledgeForm from '@/views/knowledgeBaseMgr/knowledgeMgr/components/knowledgeForm'
import MoveCategoryForm from '@/views/knowledgeBaseMgr/knowledgeMgr/components/moveCategoryForm'
import MoveKnowledgeForm from '@/views/knowledgeBaseMgr/knowledgeMgr/components/moveKnowledgeForm'
import AttachmentList from '@/views/knowledgeBaseMgr/knowledgeMgr/components/attachmentList'
import { KnowledgeMgrListTitle } from '@/views/knowledgeBaseMgr/tableTitle'
import { StatusEnum, StatusStyleEnum } from '@/views/knowledgeBaseMgr/enum'
import { deleteDocumentCategory, getDocumentCategoryTree, getDocumentCategoryUsageCheck } from '@/api/knowleageBaseMgr/documentCategoryMgr'
import {
  getKnowledgeBases,
  postGetContent,
  deleteKnowledgeBase,
  putKnowledgeBasesPublished
} from '@/api/knowleageBaseMgr/knowleageBaseMgr'
import _ from 'lodash'
import { MimeTypeMap } from '@/utils/enum'
import EnterFul from '@/assets/image/newRemoteGuidance/enter_full.png'
import ExitFul from '@/assets/image/newRemoteGuidance/exit_full.png'

export default {
  name: 'Index',
  components: { CategoryTree, CategoryForm, KnowledgeForm, MoveCategoryForm, MoveKnowledgeForm, AttachmentList },
  extends: HtListContainer,
  data() {
    return {
      EnterFul, ExitFul,
      KnowledgeMgrListTitle, StatusEnum, StatusStyleEnum,
      tree: [],
      isTreeLoading: false,
      outsideClicked: false,
      showFunctionBox: false,
      showCreateSubCategory: false,
      showAddCategoryForm: false,
      showEditCategoryForm: false,
      showAddKnowledgeForm: false,
      showEditKnowledgeForm: false,
      showImportKnowledgeForm: false,
      showMoveCategoryForm: false,
      showMoveKnowledgeForm: false,
      showAttachmentList: false,
      currentNode: null,
      currentNodeData: null,
      currentNodeDataParentId: null,
      currentKnowledgeData: null,
      attachmentList: [],
      isFullscreenKnowledgeDialog: true
    }
  },
  computed: {
    getTree() {
      let tree = _.cloneDeep(this.tree)
      tree.forEach(firstItem => {
        const firstOtherTree = firstItem.children
        firstItem.children = []
        tree = [firstItem, ...firstOtherTree]
      })
      return tree
    }
  },
  created() {
    this.getDocumentCategoryTree()
  },
  methods: {
    getList(ignoreFunctionBox = false) {
      if (this.showFunctionBox && this.currentNode && this.currentNodeData && !ignoreFunctionBox) {
        this.showFunctionBox = false
        this.outsideClicked = true
      }
      this.listQuery.cate1Id = this.currentNodeData && this.currentNodeData.id || null
      this.listQuery.cate2Id = this.currentNodeData && this.currentNodeData.parentId || null
      this.beforeGetList()
      getKnowledgeBases(this.listQuery).then(resp => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    handleClickOutside() {
      if (this.showFunctionBox && this.currentNode && this.currentNodeData) {
        this.showFunctionBox = false
        this.outsideClicked = true
      }
    },
    getDocumentCategoryTree() {
      this.isTreeLoading = true
      getDocumentCategoryTree().then(resp => {
        this.isTreeLoading = false
        this.tree = resp
        this.$nextTick(() => {
          this.$refs.categoryTree.setCurrentNode(0, true)
          this.currentNodeData = null
          this.showFunctionBox = false
          this.outsideClicked = true
          this.getList()
        })
      }).catch(() => {
        this.isTreeLoading = false
      })
    },
    handleClickTreeNode(node, data) {
      this.$refs.categoryTree.setCurrentNode(0, data.level === 0)
      this.currentNodeData = data.level === 0 ? null : data
      this.getList()
      if (this.showFunctionBox) {
        this.handleClickMore(data.level === 0, node, data)
      }
    },
    handleClickMore(isTopFirst, node, data) {
      this.currentNode = node
      if (isTopFirst) { // 全部：添加目录
        this.showAddCategoryForm = true
        this.$refs.addCategoryDialog.dialogVisible = true
        this.currentNodeData = null
        this.currentNodeDataParentId = data.id
        this.getList(true)
      } else { // 其他：打开菜单
        this.showCreateSubCategory = node.level === 1 //  node.level === 1： 一级菜单
        if (this.currentNodeData && this.currentNodeData.id) {
          this.showFunctionBox = this.outsideClicked ? true : this.currentNodeData.id !== data.id
          this.outsideClicked = false
          if (this.showFunctionBox) {
            this.currentNodeData = data
            this.currentNodeDataParentId = data.id
            this.$refs.categoryTree.openFunctionBox(node, data)
            this.getList(true)
          } else {
            this.getList(true)
            this.currentNodeData = null
            this.currentNodeDataParentId = null
          }
        } else {
          this.currentNodeData = data
          this.currentNodeDataParentId = data.id
          this.showFunctionBox = true
          this.$refs.categoryTree.openFunctionBox(node, data)
          this.getList(true)
        }
      }
    },
    toggleCreateCategoryDialog(isOpen = true, isRefresh = false) {
      this.showAddCategoryForm = isOpen
      this.$refs.addCategoryDialog.dialogVisible = isOpen
      if (isRefresh) {
        this.getDocumentCategoryTree()
      }
    },
    toggleEditCategoryDialog(isOpen = true, isRefresh = false) {
      this.showEditCategoryForm = isOpen
      this.$refs.editCategoryDialog.dialogVisible = isOpen
      if (isRefresh) {
        this.getDocumentCategoryTree()
      }
    },
    toggleMoveCategoryDialog(isOpen = true, isRefresh = false) {
      this.showMoveCategoryForm = isOpen
      this.$refs.moveCategoryDialog.dialogVisible = isOpen
      if (isRefresh) {
        this.getDocumentCategoryTree()
      }
    },
    toggleFullscreenKnowledgeDialog() {
      this.isFullscreenKnowledgeDialog = !this.isFullscreenKnowledgeDialog
    },
    toggleAddKnowledgeDialog(isOpen = true, isRefresh = false) {
      this.showAddKnowledgeForm = isOpen
      this.$refs.addKnowledgeDialog.dialogVisible = isOpen
      if (isRefresh) {
        this.getList()
      }
      this.isFullscreenKnowledgeDialog = true
    },
    toggleEditKnowledgeDialog(row, isOpen = true, isRefresh = false) {
      this.currentKnowledgeData = row
      this.showEditKnowledgeForm = isOpen
      this.$refs.editKnowledgeDialog.dialogVisible = isOpen
      if (isRefresh) {
        this.getList()
      }
      this.isFullscreenKnowledgeDialog = true
    },
    toggleMoveKnowledgeDialog(row, isOpen = true, isRefresh = false) {
      this.showMoveKnowledgeForm = isOpen
      this.$refs.moveKnowledgeDialog.dialogVisible = isOpen
      this.currentKnowledgeData = row
      if (isRefresh) {
        this.getList()
      }
      this.isFullscreenKnowledgeDialog = true
    },
    toggleImportKnowledgeDialog(isOpen = true, isRefresh = false) {
      this.showImportKnowledgeForm = isOpen
      this.$refs.importKnowledgeDialog.dialogVisible = isOpen
      if (isRefresh) {
        this.getList()
      }
    },
    toggleKnowledgeStatus(row, status) {
      putKnowledgeBasesPublished(row.id, { status: status }).then(resp => {
        this.$message({ message: `操作成功`, type: 'success', center: true })
        this.getList()
      })
    },
    toggleAttachmentDialog(row, isOpen = true) {
      this.attachmentList = isOpen ? row.attachmentData : []
      this.showAttachmentList = isOpen
      this.$refs.attachmentListDialog.dialogVisible = isOpen
    },
    handleClickMoveCategory() {
      // 1。目录下有目录不可以移动
      if (this.currentNodeData && this.currentNodeData.children && this.currentNodeData.children.length > 0) {
        this.$message({ message: '移动一级目录前请先将二级目录移除或删除', type: 'error' })
      } else {
        // 2。目录下有文档不可以移动
        getDocumentCategoryUsageCheck(this.currentNodeData.id).then(resp => {
          if (resp && !resp.isIncludeFiles) {
            this.toggleMoveCategoryDialog()
          } else {
            this.$message({ message: '当前目录下存在文档，请先将文档移动到其他目录后再移动该目录', type: 'error' })
          }
        })
      }
    },
    handleDelCategory() {
      getDocumentCategoryUsageCheck(this.currentNodeData.id).then(resp => {
        if (resp && !resp.isIncludeFiles) {
          deleteDocumentCategory(this.currentNodeData.id).then(() => {
            this.$message({ message: '删除成功', type: 'success' })
            this.getDocumentCategoryTree()
          })
        } else {
          this.$confirm(`删除目录，将会删除该目录下${this.currentNodeData.level === 1 ? '所有目录和' : ''}所有文档!`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            deleteDocumentCategory(this.currentNodeData.id).then(() => {
              this.$message({ message: '删除成功', type: 'success' })
              this.getDocumentCategoryTree()
            })
          })
        }
      })
    },
    handleKnowledgeFileUploaded(data, fileUrl, file) {
      const documentData = {
        fileName: file.name,
        fileUrl: fileUrl,
        fileType: MimeTypeMap[file.type]
      }
      this.toggleImportKnowledgeDialog()
      this.$nextTick(() => {
        this.$refs.importKnowledgeForm.isImportLoading = true
        postGetContent(documentData).then(resp => {
          this.currentKnowledgeData = {
            title: file.name,
            content: resp.content.content,
            attachmentData: [],
            coverImg: null,
            cate1Id: this.currentNodeData.id,
            cate2Id: this.currentNodeData.parentId,
            status: 'PUBLISHED',
            sourceUrl: fileUrl,
            sourceName: file.name
          }
          this.$refs.importKnowledgeForm.isImportLoading = false
        }).catch(() => {
          this.$refs.importKnowledgeForm.isImportLoading = false
          this.toggleImportKnowledgeDialog(false)
        })
      })
    },
    handleDeleteKnowledge(row) {
      deleteKnowledgeBase(row.id).then(() => {
        this.$message({ message: '删除成功', type: 'success' })
        this.getList()
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.container{
  height: 100%;
  ::v-deep .el-card__body{
    width: 100%;
    height: 100%;
    .title-container{
      display: flex;
      flex-direction: row;
      .left-title{
        font-size: 18px;
        color: $primaryColor;
        font-weight: bolder;
        width: 250px;
        margin-right: 20px;
      }
    }
    .content-container {
      display: flex;
      flex-direction: row;
      .left-category-container{
        width: 250px;
        margin-right: 20px;
        border: 1px solid #dfe6ec;
      }
      .right-knowledge-container{
        flex: 1;
        width: calc(100% - 300px);
      }
    }
  }
  .function-button {
    width: 100%;
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    background: $primaryColor;
    border-radius: 0;
    margin-left: 10px;
    border-top: 1px solid #ffffff;
    padding: 0 10px;
  }

  .top-button{
    border-top: none;
  }
}
</style>

<style lang="scss">
@import "~@/styles/variables.scss";

.function-button{
  .el-button {
    width: 100%;
    height: 40px;
    color: #ffffff !important;
    font-size: 14px;
    background: $primaryColor;
    border-radius: 0;
    margin-left: 0;
  }
  .el-button--text{
    color: #ffffff !important;
  }
}
.confirm-button{
  padding: 0 !important;
  .el-button{
    border-top: 1px solid #ffffff;
  }
}

.fullscreen-icon{
  width: 20px;
  height: 20px;
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 10px;
}

</style>

<style lang="scss">
.knowledge-edit-dialog{
  .is-fullscreen{
    .el-dialog__body{
      height: calc(100% - 80px) !important;
    }
    .el-dialog__body > div {
      height: 100%;
    }
    .knowledge-container{
      height: 100%;
      display: flex;
      flex-direction: column;
      .content-item{
        flex: 1;
        max-height: none;
      }
    }
  }
}
</style>
