var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.RoutePatrolMgrListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "视频链接"
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: row.videoUrls.length < 1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleVideoUrlsDialog(
                                      true,
                                      row.videoUrls
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.videoUrls.length) + "条")]
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "videoListDialog",
          attrs: { title: "视频链接", width: "600px" },
          on: {
            close: function ($event) {
              return _vm.toggleVideoUrlsDialog(false)
            },
          },
        },
        [
          _vm.showVideoList
            ? _c("video-list", {
                attrs: { slot: "content", "video-list": _vm.videoList },
                slot: "content",
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }