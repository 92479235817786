<template>
  <div class="timeline-container">
    <el-timeline v-loading="isLoading" class="mobile-timeline">
      <el-timeline-item
        v-for="(item, index) in logisticsTraces"
        :key="index"
        icon="el-icon-arrow-up"
        type="primary"
        size="large"
      >
        <div class="trace-item">
          <div class="item top-item">
            <el-tag class="status action" :type="StockActionStyleEnum[item.action]">{{ StockActionEnum[item.action] }}</el-tag>
            <span class="createdAt">{{ item.createdAt }}</span>
            <span class="quantity">数量：{{ item.quantity }}</span>
          </div>
          <div class="item middle-item">
            <template v-if="item.action === 'INBOUND'">
              <span class="item position">{{ item.constructionProjectName || '(新设备)' }}</span>
              <span class="item action">{{ StockActionEnum[item.action] }}至</span>
              <span class="item position">{{ item.warehouseName }}</span>
            </template>
            <template v-else-if="item.action === 'OUTBOUND'">
              <span class="item position">{{ item.warehouseName }}</span>
              <span class="item action">{{ StockActionEnum[item.action] }}至</span>
              <span class="item position">{{ item.constructionProjectName }}</span>
            </template>
            <template v-else-if="item.action === 'TRANSSHIPMENT'">
              <span class="item position">{{ item.oldConstructionProjectName }}</span>
              <span class="item action">{{ StockActionEnum[item.action] }}至</span>
              <span class="item position">{{ item.constructionProjectName }}</span>
            </template>
          </div>
          <div class="item bottom-item">
            <dw-file
              v-if="item.attachmentData && item.attachmentData.length > 0"
              class="item attachment"
              :url="item.attachmentData[0]"
              :show-del="false"
              justify-content="left"
              :need-margin="false"
              @view-file="downloadFile"
            />
            <span v-else class="item attachment">未上传文件</span>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { downloadFile } from '@/utils'
import { StockActionEnum, StockActionStyleEnum } from '@/views/constructionEquipmentMgr/enum'

export default {
  name: 'LogisticsTrace',
  props: {
    isLoading: { type: Boolean, default: false },
    logisticsTraces: { type: Array, default: () => { return [] } }
  },
  data() {
    return {
      StockActionEnum, StockActionStyleEnum
    }
  },
  methods: {
    downloadFile
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/mobile-common-style.scss';

.mobile-timeline{
  margin-top: 10px;
  .trace-item{
    font-size: 1rem;
    color: #666666;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    padding: 15px;
    .item {
      margin-bottom: 10px;
    }
    .top-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      .action{
        font-size: 1.1rem;
        font-weight: bold;
        margin-right: 10px;
      }
      .createdAt{
        font-size: 0.9rem;
      }
      .quantity{
        flex: 1;
        text-align: right;
        font-weight: bold;
      }
    }
    .middle-item{
      .action{
        font-weight: bold;
        margin: 0 10px;
      }
    }
    .item:last-child{
      margin-bottom: 0;
    }
  }
}

.el-timeline{
  ::v-deep .el-timeline-item{
    position: relative;
    .el-timeline-item__tail{
      top: 50%;
    }
    .el-timeline-item__node{
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
