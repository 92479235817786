export const WarehouseList = [
  {
    name: '一号仓库', id: 1,
    storageLocations: [
      {
        code: '1-01', id: 11, capacity: 30, quantity: 0,
        cellList: [
          { name: '第1格', id: 111, isInbound: false },
          { name: '第2格', id: 112, isInbound: false },
          { name: '第3格', id: 113, isInbound: false },
          { name: '第4格', id: 114, isInbound: false }
        ]
      },
      {
        code: '1-02', id: 12, capacity: 40, quantity: 0,
        cellList: [
          { name: '第1格', id: 121, isInbound: false },
          { name: '第2格', id: 122, isInbound: false },
          { name: '第3格', id: 123, isInbound: false },
          { name: '第4格', id: 124, isInbound: false }
        ]
      },
      {
        code: '1-03', id: 13, capacity: 30, quantity: 0,
        cellList: [
          { name: '第1格', id: 131, isInbound: false },
          { name: '第2格', id: 132, isInbound: false },
          { name: '第3格', id: 133, isInbound: false },
          { name: '第4格', id: 134, isInbound: false }
        ]
      }
    ]
  },
  {
    name: '二号仓库', id: 2,
    storageLocations: [
      {
        code: '2-01', id: 21, capacity: 30, quantity: 0,
        cellList: [
          { name: '第1格', id: 211, isInbound: false },
          { name: '第2格', id: 212, isInbound: false },
          { name: '第3格', id: 213, isInbound: false },
          { name: '第4格', id: 214, isInbound: false }
        ]
      },
      {
        code: '2-02', id: 22, capacity: 40, quantity: 0,
        cellList: [
          { name: '第1格', id: 221, isInbound: false },
          { name: '第2格', id: 222, isInbound: false },
          { name: '第3格', id: 223, isInbound: false },
          { name: '第4格', id: 224, isInbound: false }
        ]
      },
      {
        code: '2-03', id: 23, capacity: 30, quantity: 0,
        cellList: [
          { name: '第1格', id: 231, isInbound: false },
          { name: '第2格', id: 232, isInbound: false },
          { name: '第3格', id: 233, isInbound: false },
          { name: '第4格', id: 234, isInbound: false }
        ]
      }
    ]
  },
  {
    name: '三号仓库', id: 3,
    storageLocations: [
      {
        code: '3-01', id: 31, capacity: 30, quantity: 0,
        cellList: [
          { name: '第1格', id: 311, isInbound: false },
          { name: '第2格', id: 312, isInbound: false },
          { name: '第3格', id: 313, isInbound: false },
          { name: '第4格', id: 314, isInbound: false }
        ]
      },
      {
        code: '3-02', id: 32, capacity: 40, quantity: 0,
        cellList: [
          { name: '第1格', id: 321, isInbound: false },
          { name: '第2格', id: 322, isInbound: false },
          { name: '第3格', id: 323, isInbound: false },
          { name: '第4格', id: 324, isInbound: false }
        ]
      },
      {
        code: '3-03', id: 33, capacity: 30, quantity: 0,
        cellList: [
          { name: '第1格', id: 331, isInbound: false },
          { name: '第2格', id: 332, isInbound: false },
          { name: '第3格', id: 333, isInbound: false },
          { name: '第4格', id: 334, isInbound: false }
        ]
      }
    ]
  }
]

export const AreaList = [
  {
    name: '一号仓库',
    id: 1,
    floors: [
      {
        name: '一楼',
        id: 11,
        tasks: [
          {
            code: 'T110001',
            goods: [
              { code: 'cg-11111111', name: '晨光水笔', brand: 'CG', color: '蓝色', count: 4, quantity: 10, desc: '晨光水笔的描述', storageCode: '1-02', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'bx-22222222', name: '白雪中性笔', brand: 'BX', color: '红色', count: 5, quantity: 10, desc: '白雪中性笔的描述', storageCode: '1-03', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'ym-33333333', name: '优漫钢笔', brand: 'YM', color: '金色', count: 2, quantity: 10, desc: '优漫钢笔的描述', storageCode: '1-04', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'jt-44444444', name: '嘉图圆珠笔', brand: 'JT', color: '绿色', count: 6, quantity: 10, desc: '嘉图圆珠笔的描述', storageCode: '1-05', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'hb-55555555', name: '华夏马克笔', brand: 'HB', color: '粉色', count: 1, quantity: 10, desc: '华夏马克笔的描述', storageCode: '1-06', isPicked: false, outboundStorageCode: null, outboundCount: 0 }
            ]
          },
          {
            code: 'T110002',
            goods: [
              { code: 'xy-66666666', name: '心愿记事本', brand: 'XY', color: '棕色', count: 3, quantity: 10, desc: '心愿记事本的描述', storageCode: '1-07', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'td-77777777', name: '天Draw绘图笔', brand: 'TD', color: '橙色', count: 4, quantity: 10, desc: '天Draw绘图笔的描述', storageCode: '1-08', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'ls-88888888', name: '理丝签字笔', brand: 'LS', color: '紫色', count: 5, quantity: 10, desc: '理丝签字笔的描述', storageCode: '1-09', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'zs-99999999', name: '纵横自动铅笔', brand: 'ZS', color: '灰色', count: 2, quantity: 10, desc: '纵横自动铅笔的描述', storageCode: '1-10', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'km-11111111', name: '康美圆珠笔', brand: 'KM', color: '白色', count: 3, quantity: 10, desc: '康美圆珠笔的描述', storageCode: '1-11', isPicked: false, outboundStorageCode: null, outboundCount: 0 }

            ]
          }
        ]
      },
      {
        name: '二楼',
        id: 12,
        tasks: [
          {
            code: 'T120001',
            goods: [
              { code: 'cg-11111111', name: '晨光圆珠笔', brand: 'CG', color: '黑色', count: 3, quantity: 10, desc: '晨光圆珠笔的描述', storageCode: '2-01', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'bx-11111111', name: '白雪圆珠笔', brand: 'BX', color: '黑色', count: 3, quantity: 10, desc: '白雪圆珠笔的描述', storageCode: '2-01', isPicked: false, outboundStorageCode: null, outboundCount: 0 }
            ]
          },
          {
            code: 'T120002',
            goods: [
              { code: 'cg-11111111', name: '晨光水笔', brand: 'CG', color: '蓝色', count: 4, quantity: 10, desc: '晨光水笔的描述', storageCode: '1-02', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'bx-22222222', name: '白雪中性笔', brand: 'BX', color: '红色', count: 5, quantity: 10, desc: '白雪中性笔的描述', storageCode: '1-03', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'ym-33333333', name: '优漫钢笔', brand: 'YM', color: '金色', count: 2, quantity: 10, desc: '优漫钢笔的描述', storageCode: '1-04', isPicked: false, outboundStorageCode: null, outboundCount: 0 }
            ]
          }
        ]
      }
    ]
  },
  {
    name: '二号仓库',
    id: 2,
    floors: [
      {
        name: '一楼',
        id: 21,
        tasks: [
          {
            code: 'T210001',
            goods: [
              { code: 'cg-11111111', name: '晨光水笔', brand: 'CG', color: '蓝色', count: 4, quantity: 10, desc: '晨光水笔的描述', storageCode: '1-02', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'bx-22222222', name: '白雪中性笔', brand: 'BX', color: '红色', count: 5, quantity: 10, desc: '白雪中性笔的描述', storageCode: '1-03', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'ym-33333333', name: '优漫钢笔', brand: 'YM', color: '金色', count: 2, quantity: 10, desc: '优漫钢笔的描述', storageCode: '1-04', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'jt-44444444', name: '嘉图圆珠笔', brand: 'JT', color: '绿色', count: 6, quantity: 10, desc: '嘉图圆珠笔的描述', storageCode: '1-05', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'hb-55555555', name: '华夏马克笔', brand: 'HB', color: '粉色', count: 1, quantity: 10, desc: '华夏马克笔的描述', storageCode: '1-06', isPicked: false, outboundStorageCode: null, outboundCount: 0 }
            ]
          },
          {
            code: 'T210002',
            goods: [
              { code: 'cg-11111111', name: '晨光圆珠笔', brand: 'CG', color: '黑色', count: 3, quantity: 10, desc: '晨光圆珠笔的描述', storageCode: '2-01', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'bx-11111111', name: '白雪圆珠笔', brand: 'BX', color: '黑色', count: 3, quantity: 10, desc: '白雪圆珠笔的描述', storageCode: '2-01', isPicked: false, outboundStorageCode: null, outboundCount: 0 }
            ]
          }
        ]
      },
      {
        name: '二楼',
        id: 22,
        tasks: [
          {
            code: 'T220001',
            goods: [
              { code: 'cg-11111111', name: '晨光圆珠笔', brand: 'CG', color: '黑色', count: 3, quantity: 10, desc: '晨光圆珠笔的描述', storageCode: '2-01', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'bx-11111111', name: '白雪圆珠笔', brand: 'BX', color: '黑色', count: 3, quantity: 10, desc: '白雪圆珠笔的描述', storageCode: '2-01', isPicked: false, outboundStorageCode: null, outboundCount: 0 }
            ]
          },
          {
            code: 'T220002',
            goods: [
              { code: 'cg-11111111', name: '晨光水笔', brand: 'CG', color: '蓝色', count: 4, quantity: 10, desc: '晨光水笔的描述', storageCode: '1-02', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'bx-22222222', name: '白雪中性笔', brand: 'BX', color: '红色', count: 5, quantity: 10, desc: '白雪中性笔的描述', storageCode: '1-03', isPicked: false, outboundStorageCode: null, outboundCount: 0 },
              { code: 'ym-33333333', name: '优漫钢笔', brand: 'YM', color: '金色', count: 2, quantity: 10, desc: '优漫钢笔的描述', storageCode: '1-04', isPicked: false, outboundStorageCode: null, outboundCount: 0 }
            ]
          }
        ]
      }
    ]
  }
]

export const StorageList = [
  { code: '1-01', id: 11 },
  { code: '1-02', id: 12 },
  { code: '1-03', id: 13 },
  { code: '2-01', id: 11 },
  { code: '2-02', id: 12 },
  { code: '2-03', id: 13 },
  { code: '3-01', id: 11 },
  { code: '3-02', id: 12 },
  { code: '3-03', id: 13 }
]
