var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.carouselClasses,
      on: {
        mouseenter: function ($event) {
          $event.stopPropagation()
          return _vm.handleMouseEnter($event)
        },
        mouseleave: function ($event) {
          $event.stopPropagation()
          return _vm.handleMouseLeave($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "el-carousel__container",
          style: { height: _vm.height },
        },
        [
          _vm.items.length > 0
            ? _c(
                "div",
                { staticClass: "el-carousel-button-container" },
                [
                  _vm.arrowDisplay
                    ? _c(
                        "transition",
                        { attrs: { name: "carousel-arrow-left" } },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.arrow === "always" || _vm.hover) &&
                                    (_vm.loop || _vm.activeIndex > 0),
                                  expression:
                                    "(arrow === 'always' || hover) && (loop || activeIndex > 0) ",
                                },
                              ],
                              staticClass:
                                "el-carousel__arrow el-carousel__arrow--left",
                              attrs: { type: "button" },
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.handleButtonEnter("left")
                                },
                                mouseleave: _vm.handleButtonLeave,
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.throttledArrowClick(
                                    _vm.activeIndex - 1
                                  )
                                },
                              },
                            },
                            [
                              _vm.showIcon
                                ? _c("i", { staticClass: "el-icon-arrow-left" })
                                : _vm._e(),
                              _vm._v("上一张\n        "),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "current-index" }, [
                    _vm._v(
                      _vm._s(_vm.activeIndex + 1 + "/" + _vm.items.length)
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.arrowDisplay
                    ? _c(
                        "transition",
                        { attrs: { name: "carousel-arrow-right" } },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.arrow === "always" || _vm.hover) &&
                                    (_vm.loop ||
                                      _vm.activeIndex < _vm.items.length - 1),
                                  expression:
                                    "(arrow === 'always' || hover) && (loop || activeIndex < items.length - 1)",
                                },
                              ],
                              staticClass:
                                "el-carousel__arrow el-carousel__arrow--right",
                              attrs: { type: "button" },
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.handleButtonEnter("right")
                                },
                                mouseleave: _vm.handleButtonLeave,
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.throttledArrowClick(
                                    _vm.activeIndex + 1
                                  )
                                },
                              },
                            },
                            [
                              _vm._v("\n          下一张"),
                              _vm.showIcon
                                ? _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
      _vm._v(" "),
      _vm.indicatorPosition !== "none"
        ? _c(
            "ul",
            { class: _vm.indicatorsClasses },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: [
                    "el-carousel__indicator",
                    "el-carousel__indicator--" + _vm.direction,
                    { "is-active": index === _vm.activeIndex },
                  ],
                  on: {
                    mouseenter: function ($event) {
                      return _vm.throttledIndicatorHover(index)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleIndicatorClick(index)
                    },
                  },
                },
                [
                  _c("button", { staticClass: "el-carousel__button" }, [
                    _vm.hasLabel
                      ? _c("span", [_vm._v(_vm._s(item.label))])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }