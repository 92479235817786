exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".function-button .el-button{width:100%;height:40px;color:#fff !important;font-size:14px;background:#2e5aff;border-radius:0;margin-left:0}.function-button .el-button--text{color:#fff !important}.confirm-button{padding:0 !important}.confirm-button .el-button{border-top:1px solid #fff}.fullscreen-icon{width:20px;height:20px;margin-right:30px;cursor:pointer;margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"menuText": "#666",
	"menuActiveText": "#2e5aff",
	"subMenuActiveText": "#2e5aff",
	"menuBg": "#fff",
	"menuHover": "#f1f2f4",
	"menuActiveBg": "#2e5aff",
	"subMenuBg": "#f1f2f4",
	"subMenuHover": "#dedede",
	"sideBarWidth": "210px",
	"primaryColor": "#2e5aff",
	"primaryColorMiddle": "#8099ff",
	"primaryColorLight": "#e0eaf9",
	"mobilePrimaryColor": "#2e5aff",
	"mobilePrimaryColorMiddle": "#6485fd",
	"mobilePrimaryColorLight": "#e0eaf9",
	"sidebarLogoBg": "#2e5aff",
	"sidebarLogoColor": "#fff",
	"hoverSideBarWidth": "190px"
};