export const Data1 =
  '从去年起，仿佛听得有人说我是仇猫的。那根据自然是在我的那一篇《兔和猫》；这是自画招供，当然无话可说，——但倒也毫不介意。我可很有点担心了。我是常不免于弄弄笔墨的，写了下来，印了出去，对于有些人似乎总是搔着痒处的时候少，碰着痛处的时候多。万一不慎，甚而至于得罪了名人或名教授，或者更甚而至于得罪了“负有指导青年责任的前辈”之流，可就危险已极。为什么呢？因为这些大角色是“不好惹”的。怎地“不好惹”呢？就是怕要浑身发热之后，做一封信登在报纸上，广告道：“看哪！狗不是仇猫的么？鲁迅先生却自己承认是仇猫的，而他还说要打‘落水狗’！”这“逻辑”的奥义，即在用我的话，来证明我倒是狗，于是而凡有言说，全都根本推翻，即使我说二二得四，三三见九，也没有一字不错。这些既然都错，则绅士口头的二二得七，三三见千等等，自然就不错了。<br/>' +
  '我于是就间或留心着查考它们成仇的“动机”。这也并非敢妄学现下的学者以动机来褒贬作品的那些时髦，不过想给自己预先洗刷洗刷。据我想，这在动物心理学家，是用不着费什么力气的，可惜我没有这学问。后来，在覃哈特博士（Dr. O. Dahnhardt）的《自然史底国民童话》里，总算发现了那原因了。据说，是这么一回事：动物们因为要商议要事，开了一个会议，鸟、鱼、兽都齐集了，单是缺了象。大家议定，派伙计去迎接它，拈到了当这差使的阄的就是狗。“我怎么找到那象呢？我没有见过它，也和它不认识。”它问。“那容易，”大众说，“它是驼背的。”狗去了，遇见一匹猫，立刻弓起脊梁来，它便招待，同行，将弓着脊梁的猫介绍给大家道：“象在这里！”但是大家都嗤笑它了。从此以后，狗和猫便成了仇家。<br/>' +
  '日尔曼人走出森林虽然还不很久，学术文艺却已经很可观，便是书籍的装潢，玩具的工致，也无不令人心爱。独有这一篇童话却实在不漂亮；结怨也结得没有意思。猫的弓起脊梁，并不是希图冒充，故意摆架子的，其咎却在狗的自己没眼力。然而原因也总可以算作一个原因。我的仇猫，是和这大大两样的。<br/>' +
  '其实人禽之辨，本不必这样严。在动物界，虽然并不如古人所幻想的那样舒适自由，可是噜苏做作的事总比人间少。它们适性任情，对就对，错就错，不说一句分辩话。虫蛆也许是不干净的，但它们并没有自命清高；鸷禽猛兽以较弱的动物为饵，不妨说是凶残的罢，但它们从来没有竖过“公理”“正义”的旗子，使牺牲者直到被吃的时候为止，还是一味佩服赞叹它们。而人呢，能直立了，自然就是一大进步；能说话了，自然又是一大进步；能写字作文了，自然还是一大进步。然而也就堕落，因为那时也开始了说空话。说空话尚无不可，甚至于连自己也不知道说着违心之论，则对于只能嗥叫的动物，实在免不得“颜厚有忸怩”。假使真有一位一视同仁的造物主，高高在上，那么，对于人类的这些小聪明，也许倒以为多事，正如我们在万生园里，看见猴子翻筋斗，母象请安，虽然往往破颜一笑，但同时也觉得不舒服，甚至于感到悲哀，以为这些多余的聪明，倒不如没有的好罢。然而，既经为人，便也只好“党同伐异”，学着人们的说话，随俗来谈一谈，辩一辩了。<br/>' +
  '现在说起我仇猫的原因来，自己觉得是理由充足，而且光明正大的。一、它的性情就和别的猛兽不同，凡捕食雀、鼠，总不肯一口咬死，定要尽情玩弄，放走，又捉住，捉住，又放走，直待自己玩厌了，这才吃下去，颇与人们的幸灾乐祸，慢慢地折磨弱者的坏脾气相同。二、它不是和狮虎同族的么？可是有这么一副媚态！但这也许是限于天分之故罢，假使它的身材比大十倍，那就真不知道它所取的是怎么一种态度。<br/>' +
  '然而，这些口实，仿佛又是提起笔来的时候添出来的，虽然也象是当时涌上心来的理由。要说得可靠一点，或者倒不如说不过因为它们配合时候的嗥叫，手续竟有这么繁重，闹得别人心烦，尤其是夜间要看书，睡觉的时候。当这些时候，我便要用长竹竿去攻击它们。<br/>' +
  '狗们在大道上配合时，常有闲汉拿了木棍痛打；我曾见大勃吕该尔（P. Bruegeld. A）的一张铜版画Allegorie der Wollust上，也画着这回事，可见这样的举动，是中外古今一致的。<br/>' +
  '自从那执拗的奥国学者弗罗特（S. Freud）提倡了精神分析说——psychoanalysis，听说章士钊先生是译作“心解”的，虽然简古，可是实在难解得很——以来，我们的名人名教授也颇有隐隐约约，检来应用的了，这些事便不免又要归宿到性欲上去。打狗的事我不管，至于我的打猫，却只因为它们嚷嚷，此外并无恶意，我自信我的嫉妒心还没有这么博大，当现下“动辄获咎”之秋，这是不可不预先声明的。<br/>' +
  '例如人们当配合之前，也很有些手续，新的是写情书，少则一束，多则一捆；旧的是什么“问名”“纳采”，磕头作揖，海昌蒋氏在北京举行婚礼，拜来拜去，就十足拜了三天，还印有一本红面子的《婚礼节文》，《序论》里大发议论道：“平心论之，既名为礼，当必繁重。专图简易，何用礼为？……然则世之有志于礼者，可以兴矣！不可退居于礼所不下之庶人矣！”然而我毫不生气，这是因为无须我到场；因此也可见我的仇猫，理由实在简简单单，只为了它们在我的耳朵边尽嚷的缘故。人们的各种礼式，局外人可以不见不闻，我就满不管，但如果当我正要看书或睡觉的时候，有人来勒令朗诵情书，奉陪作揖，那是为自卫起见，还要用长竹竿来抵御的。还有，平素不大交往的人，忽而寄给我一个红帖子，上面印着“为舍妹出阁”，“小儿完姻 ”，“敬请观礼”或“阖第光临”这些含有“阴险的暗示”的句子，使我不花钱便总觉得有些过意不去的，我也不十分高兴。<br/>' +
  '但是，这都是近时的话。再一回忆，我的仇猫却远在能够说出这些理由之前，也许是还在十岁上下的时候了。至今还分明记得，那原因是极其简单的：只因为它吃老鼠，——吃了我饲养着的可爱的小小的隐鼠。<br/>' +
  '听说西洋是不很喜欢黑猫的，不知道可确；但Edgar Allan Poe20的小说里的黑猫，却实在有点骇人。日本的猫善于成精，传说中的“猫婆”，那食人的惨酷确是更可怕。中国古时候虽然曾有“猫鬼”，近来却很少听到猫的兴妖作怪，似乎古法已经失传，老实起来了。只是我在童年，总觉得它有点妖气，没有什么好感。那是一个我的幼时的夏夜，我躺在一株大桂树下的小板桌上乘凉，祖母摇着芭蕉扇坐在桌旁，给我猜谜，讲故事。忽然，桂树上沙沙地有趾爪的爬搔声，一对闪闪的眼睛在暗中随声而下，使我吃惊，也将祖母讲着的话打断，另讲猫的故事了——<br/>' +
  '“你知道么？猫是老虎的先生。”她说。“小孩子怎么会知道呢，猫是老虎的师父。老虎本来是什么也不会的，就投到猫的门下来。猫就教给它扑的方法，捉的方法，吃的方法，像自己的捉老鼠一样。这些教完了；老虎想，本领都学到了，谁也比不过它了，只有老师的猫还比自己强，要是杀掉猫，自己便是最强的角色了。它打定主意，就上前去扑猫。猫是早知道它的来意的，一跳，便上了树，老虎却只能眼睁睁地在树下蹲着。猫还没有教给它上树。”<br/>' +
  '这是侥幸的，我想，幸而老虎很性急，否则从桂树上就会爬下一匹老虎来。然而究竟很怕人，我要进屋子里睡觉去了。夜色更加黯然；桂叶瑟瑟地作响，微风也吹动了，想来草席定已微凉，躺着也不至于烦得翻来复去了。<br/>' +
  '几百年的老屋中的豆油灯的微光下，是老鼠跳梁的世界，飘忽地走着，吱吱地叫着，那态度往往比“名人名教授”还轩昂。猫是饲养着的，然而吃饭不管事。祖母她们虽然常恨鼠子们啮破了箱柜，偷吃了东西，我却以为这也算不得什么大罪，也和我不相干，况且这类坏事大概是大个子的老鼠做的，决不能诬陷到我所爱的小鼠身上去。这类小鼠大抵在地上走动，只有拇指那么大，也不很畏惧人，我们那里叫它“隐鼠”，与专住在屋上的伟大者是两种。我的床前就帖着两张花纸，一是“八戒招赘”，满纸长嘴大耳，我以为不甚雅观；别的一张“老鼠成亲”却可爱，自新郎、新妇以至傧相、宾客、执事，没有一个不是尖腮细腿，象煞读书人的，但穿的都是红衫绿裤。我想，能举办这样大仪式的，一定只有我所喜欢的那些隐鼠。现是粗俗了，在路上遇见人类的迎娶仪仗，也不过当作性交的广告看，不甚留心；但那时的想看“老鼠成亲”的仪式，却极其神往，即使象海昌蒋氏似的连拜三夜，怕也未必会看得心烦。正月十四的夜，是我不肯轻易便睡，等候它们的仪仗从床下出来的夜。然而仍然只看见几个光着身子的隐鼠在地面游行，不象正在办着喜事。直到我敖不住了，怏怏睡去，一睁眼却已经天明，到了灯节了。也许鼠族的婚仪，不但不分请帖，来收罗贺礼，虽是真的“观礼”，也绝对不欢迎的罢，我想，这是它们向来的习惯，无法抗议的。<br/>' +
  '老鼠的大敌其实并不是猫。春后，你听到它“咋！咋咋咋咋！”地叫着，大家称为“老鼠数铜钱”的，便知道它的可怕的屠伯已经光临了。这声音是表现绝望的惊恐的，虽然遇见猫，还不至于这样叫。猫自然也可怕，但老鼠只要窜进一个小洞去，它也就奈何不得，逃命的机会还很多。独有那可怕的屠伯——蛇，身体是细长的，圆径和鼠子差不多，凡鼠子能到的地方，它也能到，追逐的时间也格外长，而且万难幸免，当“数钱”的时候，大概是已经没有第二步办法的了。<br/>' +
  '有一回，我就听得一间空屋里有着这种“数钱”的声音，推门进去，一条蛇伏在横梁上，看地上，躺着一匹隐鼠，口角流血，但两肋还是一起一落的。取来给躺在一个纸盒子里，大半天，竟醒过来了，渐渐地能够饮食，行走，到第二日，似乎就复了原，但是不逃走。放在地上，也时时跑到人面前来，而且缘腿而上，一直爬到膝髁。给放在饭桌上，便捡吃些菜渣，舔舔碗沿；放在我的书桌上，则从容地游行，看见砚台便舔吃了研着的墨汁。这使我非常惊喜了。我听父亲说过的，中国有一种墨猴，只有拇指一般大，全身的毛是漆黑而且发亮的。它睡在笔筒里，一听到磨墨，便跳出来，等着，等到人写完字，套上笔，就舔尽了砚上的余墨，仍旧跳进笔筒里去了。我就极愿意有这样的一个墨猴，可是得不到；问那里有，那里买的呢，谁也不知道。“慰情聊胜无”，这隐鼠总可以算是我的墨猴了罢，虽然它舔吃墨汁，并不一定肯等到我写完字。<br/>' +
  '已经记不分明，这样地大约有一两月；有一天，我忽然感到寂寞了，真所谓“若有所失”。我的隐鼠，是常在眼前游行的，或桌上，或地上。而这一日却大半天没有见，大家吃午饭了，也不见它走出来，平时，是一定出现的。我再等着，再等它一半天，然而仍然没有见。<br/>' +
  '长妈妈，一个一向带领着我的女工，也许是以为我等得太苦了罢，轻轻地来告诉我一句话。这即刻使我愤怒而且悲哀，决心和猫们为敌。她说：隐鼠是昨天晚上被猫吃去了！<br/>' +
  '当我失掉了所爱的，心中有着空虚时，我要充填以报仇的恶念！<br/>' +
  '我的报仇，就从家里饲养着的一匹花猫起手，逐渐推广，至于凡所遇见的诸猫。最先不过是追赶，袭击；后来却愈加巧妙了，能飞石击中它们的头，或诱入空屋里面，打得它垂头丧气。这作战继续得颇长久，此后似乎猫都不来近我了。但对于它们纵使怎样战胜，大约也算不得一个英雄；况且中国毕生和猫打仗的人也未必多，所以一切韬略、战绩，还是全部省略了罢。<br/>' +
  '但许多天之后，也许是已经经过了大半年，我竟偶然得到一个意外的消息：那隐鼠其实并非被猫所害，倒是它缘着长妈妈的腿要爬上去，被她一脚踏死了。<br/>' +
  '这确是先前所没有料想到的。我已经记不清当时是怎样一个感想，但和猫的感情却终于没有融和；到了北京，还因为它伤害了兔的儿女们，便旧隙夹新嫌，使出更辣的辣手。“仇猫”的话柄，也从此传扬开来。然而，这些早已是过去的事了，我已经改变态度，对猫颇为客气，倘其万不得已，则赶走而已，决不打伤它们，更何况杀害。这是我近几年的进步。经验既多，一旦大悟，知道猫的偷鱼肉，拖小鸡，深夜大叫，人们自然十之九是憎恶的，而这憎恶是在猫身上。假如我出而为人们驱除这憎恶，打伤或杀害了它，它便立刻变为可怜，那憎恶倒移在我身上了。所以，目下的办法，是凡遇猫们捣乱，至于有人讨厌时，我便站出去，在门口大声叱曰：“嘘！滚！”小小平静，即回书房，这样，就长保着御侮保家的资格。其实这方法，中国的官兵就常在实做的，他们总不肯扫清土匪或扑灭敌人，因为这么一来，就要不被重视，甚至于因失其用处而被裁汰。我想，如果能将这方法推广应用，我大概也总可望成为所谓“指导青年”的“前辈”的罢，但现下也还未决心实践，正在研究而且推敲。'

export const Data2 =
  '我总要上下四方寻求，得到一种最黑，最黑，最黑的咒文，先来诅咒一切反对白话，妨害白话者。即使人死了真有灵魂，因这罪恶的心，应该堕入地狱，也将决不改悔，总要先来诅咒一切反对白话，妨害白话者。<br/>' +
  '自从所谓“文学革命”以来，供给孩子的书籍，和欧、美、日本的一比较，虽然很可怜，但总算有图可说，只要能读下去，就可以懂得的了。可是一般别有心肠的人们，便竭力来阻遏它，要使孩子的世界中，没有一丝乐趣。<br/>' +
  '只要对于白话来加以谋害者，都应该灭亡。<br/>' +
  '这些话，绅士们自然难免要掩住耳朵的，因为就是所谓“跳到半天空，骂得体无完肤，——还不肯罢休。”而且文士们一定也要骂，以为大悖于“文格”，亦即大损于“人格”。岂不是“言者心声也”么？“文”和“人”当然是相关的，虽然人间世本来千奇百怪，教授们中也有“不尊敬”作者的人格而不能“不说他的小说好”的特别种族。但这些我都不管，因为我幸而还没有爬上“象牙之塔”去，正无须怎样小心。倘若无意中竟已撞上了，那就即刻跌下来罢。然而在跌下来的中途，当还未到地之前，还要说一遍：<br/>' +
  '只要对于白话来加以谋害者，都应该灭亡。<br/>' +
  '每看见小学生欢天喜地地看着一本粗拙的《儿童世界》之类，另想到别国的儿童用书的精美，自然要觉得中国儿童的可怜。但回忆起我和我的同窗小友的童年，却不能不以为他幸福，给我们的永逝的韶光一个悲哀的吊唁。我们那时有什么可看呢，只要略有图画的本子，就要被塾师，就是当时的“引导青年的前辈”禁止，呵斥，甚而至于打手心。我的小同学因为专读“人之初性本善”读得要枯燥而死了，只好偷偷地翻开第一页，看那题着“文星高照”四个字的恶鬼一般的魁星像，来满足他幼稚的爱美的天性。昨天看这个，今天也看这个，然而他们的眼睛里还闪出苏醒和欢喜的光辉来。<br/>' +
  '在书塾以外，禁令可比较的宽了，但这是说自己的事，各人大概不一样。我能在大众面前，冠冕堂皇地阅看的，是《文昌帝君阴骘文图说》和《玉历钞传》，都画着冥冥之中赏善罚恶的故事，雷公电母站在云中，牛头马面布满地下，不但“跳到半天空”是触犯天条的，即使半语不合，一念偶差，也都得受相当的报应。这所报的也并非“睚眦之怨”，因为那地方是鬼神为君，“公理”作宰，请酒下跪，全都无功，简直是无法可想。在中国的天地间，不但做人，便是做鬼，也艰难极了。然而究竟很有比阳间更好的处所：无所谓“绅士”，也没有“流言”。<br/>' +
  '阴间，倘要稳妥，是颂扬不得的。尤其是常常好弄笔墨的人，当前的中国，流言的治下，而又大谈“言行一致”的时候。前车可鉴，听说阿尔志跋绥夫曾答一个少女的质问说，“惟有在人生的事实这本身中寻出欢喜者，可以活下去。倘若在那里什么也不见，他们其实倒不如死。”于是乎有一个叫作密哈罗夫的，寄信嘲骂他道，“……所以我完全诚实地劝你自杀来祸福你自己的生命，因为这第一是合于逻辑，第二是你的言语和行为不至于背驰。”<br/>' +
  '其实这论法就是谋杀，他就这样地在他的人生中寻出欢喜来。阿尔志跋绥夫只发了一大通牢骚，没有自杀。密哈罗夫先生后来不知道怎样，这一个欢喜失掉了，或者另外又寻到了“什么”了罢。诚然，“这些时候，勇敢，是安稳的；情热，是毫无危险的。”<br/>' +
  '然而，对于阴间，我终于已经颂扬过了，无法追改；虽有“言行不符”之嫌，但确没有受过阎王或小鬼的半文津贴，则差可以自解。总而言之，还是仍然写下去罢：<br/>' +
  '我所看的那些阴间的图画，都是家藏的老书，并非我所专有。我所收得的最先的画图本子，是一位长辈的赠品：《二十四孝图》。这虽然不过薄薄的一本书，但是下图上说，鬼少人多，又为我一人所独有，使我高兴极了。那里面的故事，似乎是谁都知道的；便是不识字的人，例如阿长，也只要一看图画便能够滔滔地讲出这一段的事迹。但是，我于高兴之余，接着就是扫兴，因为我请人讲完了二十四个故事之后，才知道“孝”有如此之难，对于先前痴心妄想，想做孝子的计划，完全绝望了。<br/>' +
  '“人之初，性本善”么？这并非要以加研究的问题。但我还依稀记得，我幼小时候实未尝蓄意忤逆，对于父母，倒是极愿意孝顺的。不过年幼无知，只用了私见来解释“孝顺”的做法，以为无非是“听话”，“从命”，以及长大之后，给年老的父母好好地吃饭罢了。自从得了《孝子》这一本教科书以后，才知道并不然，而且还要难到几十几百倍。其中自然也有可以勉力仿效的，如“子路负米”，“黄香扇枕”之类的。“陆绩怀桔”也并不难，只要有阔人请我吃饭。“鲁迅先生作宾客而怀橘乎？”我便跪答云，“吾母性之所爱，欲归以遗母。”阔人十分佩服，于是孝子就做稳了，也非常省事。“哭竹生笋”就可疑，怕我的精诚未必会这样感动天地。但是哭不出笋来，还不过抛脸而已，到“卧冰求鲤”，可就有性命之虞了。我乡的天气是温和的，严冬中，水面也只结一层薄冰，即使孩子的重量怎样小，躺上去，也一定哗喇一声，冰破落水，鲤鱼还不及游过来。自然，必须不顾性命，这才孝感神明，会有出乎意料之外的奇迹，但那时我还小，实在不明白这些。<br/>' +
  '其中最使我不解，甚至于发生反感的，是“老莱娱亲”和“郭巨埋儿”两件事。<br/>' +
  '我至今还记得，一个躺在父母跟前的老头子，一个抱在母亲手上的小孩子，是怎样地使我发生不同的感想呵。他们一手都拿着“摇咕咚”。这玩意儿确是可爱的，北京称为小鼓，盖即鼗也，朱熹曰：“鼗，小鼓，两旁有耳；持其柄而摇之，则旁耳还自击，”咕咚咕咚地响起来。然而这东西是不该拿在老莱子手里的，他应该扶一枝拐杖。装佯，侮辱了孩子。我没有再看第二回，一到这一页，便急速地翻过去了。<br/>' +
  '那时的《二十四孝图》，早已不知去向了，目下所有的只是一本日本小田海仙所画的本子，叙老莱子事云：“行年七十，言不称老，常著五色斑斓之衣，为婴儿戏于亲侧。又常取水上堂，诈跌仆地，作婴儿啼，以娱亲意。”大约旧本也差不多，而招我反感的便是“诈跌”。无论忤逆，无论孝顺，“诈”作，听故事也不喜欢是谣言，这是凡有稍稍留心儿童心理的都知道的。<br/>' +
  '然而在较古的书上一查，却还不至于如此虚伪。师觉授《孝子传》云，“老莱子……常衣斑斓之衣，为亲取饮，上堂脚跌，恐伤父母之心，僵仆为婴儿啼。”（《太平御览》四百十三引）较之今说，似稍近于人情。不知怎地，后之君子却一定要改得他“诈”起来，心里才能舒服。邓伯道弃子救侄，想来也不过“弃”而已矣，昏妄人也必须说他将儿子捆在树上，使他追不上来才肯歇手。正如将“肉麻当作有趣”一般，以不情为伦纪，诬蔑了古人，教坏了后人。老莱子即是一例，道学先生以为他白璧无瑕时，他却已在孩子的心中死掉了。<br/>' +
  '至于玩着“摇咕咚”的郭巨的儿子，却实在值得同情。他被抱在他母亲的臂膊上，高高兴兴地笑着；他的父亲却正在掘窟窿，要将他埋掉了。说明云，“汉郭巨家贫，有子三岁，母尝减食与之。巨谓妻曰，贫乏不能供母，子又分母之食。盍埋此子？”但是刘向《孝子传》所说，却又有些不同：巨家是富的，他都给了两弟；孩子是才生的，并没有到三岁。结末又大略相象了，“及掘坑二尺，得黄金一釜，上云：天赐郭巨，官不得取，民不得夺！”<br/>' +
  '我最初实在替这孩子捏一把汗，待到掘出黄金一釜，这才觉得轻松。然而我已经不但自己不敢再想做孝子，并且怕我父亲去做孝子了。家景正在坏下去，常听到父母愁柴米；祖母又老了，倘使我的父亲竟学了郭巨，那么，该埋的不正是我么？如果一丝不走样，也掘出一釜黄金来，那自然是如天之福，但是，那时我虽然年纪小，似乎也明白天下未必有这样的巧事。<br/>' +
  '回想起来，实在很觉得傻气。这是因为人们已经知道了这些老玩意，本来谁也不实行。整饬伦纪的文电是常有的，却很少见绅士赤条条地躺在冰上面，将军跳下汽车去负米。何况我早长大了，看过几部古书，买过几本新书，什么《太平御览》咧，《古孝子传》咧，《人口问题》咧，《节制生育》咧，《二十世纪是儿童的世界》咧，可以抵抗被埋的理由多得很。不过彼一时，此一时，彼时我委实有点害怕：掘好深坑，不见黄金，连“摇咕咚”一同埋下去，盖上土，踏得实实的，又有什么法子可想呢。我想，事情虽然未必实现，但我从此总怕听到我的父母愁穷，怕看见我的白发的祖母，总觉得她是和我不两立，至少，也是一个和我的生命有些妨碍的人。后来这印象日见其淡了，但总有一些留遗，一直到她去世——这大概是送给《二十四孝图》的儒者所万料不到的罢。'
