var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "work-order-info-container",
    },
    [
      _vm.orderInfo
        ? _c("work-order-card", {
            attrs: { "order-info": _vm.orderInfo, "is-card": false },
            on: {
              "on-upload-file": _vm.onFileUploaded,
              "on-delete-file": _vm.onDeleteFile,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ht-dialog",
        { ref: "exportFileDialog", attrs: { title: "工单文件", width: "90%" } },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c(
              "div",
              { staticClass: "file-container" },
              [
                _c("span", { staticClass: "file-name" }, [
                  _vm._v("文件名称：" + _vm._s(_vm.getFileName(_vm.fileUrl))),
                ]),
                _vm._v(" "),
                _c(
                  "ht-button",
                  {
                    staticClass: "export-button",
                    attrs: {
                      loading: _vm.isDownloading,
                      type: "primary",
                      round: "",
                      icon: "el-icon-download",
                    },
                    on: { click: _vm.downloadFile },
                  },
                  [_vm._v("下载文件")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.orderInfo
        ? _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c(
                "ht-button",
                {
                  staticClass: "export-button",
                  attrs: {
                    loading: _vm.isExporting,
                    type: "primary",
                    round: "",
                    icon: "el-icon-document",
                  },
                  on: { click: _vm.onExportPdf },
                },
                [_vm._v("导出工单")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }