var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "table",
          staticClass: "width-percentage-100",
          attrs: {
            data: _vm.list,
            border: _vm.isBorder,
            "show-header": _vm.isShowHeader,
            "row-key": _vm.rowKey,
            "highlight-current-row": "",
            size: "mini",
            "empty-text": "暂无数据",
            "element-loading-text": "加载中",
            "header-cell-style": _vm.handleHeaderStyle,
            "row-style": _vm.handleRowStyle,
            "cell-style": _vm.handleCellStyle,
          },
          on: {
            "selection-change": _vm.onSelectionChange,
            "sort-change": _vm.sortChange,
          },
        },
        [
          _vm._t("isSelection"),
          _vm._v(" "),
          _vm.isShowNumber
            ? _c("ht-table-column", { attrs: { type: "index", label: "序号" } })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.tableTitles, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                "min-width": item.minWidth,
                align: item.align,
                fixed: item.fixed,
                sortable: item.sortable,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        item.format === "information"
                          ? _c("span", { staticClass: "information" }, [
                              _c("div", { staticClass: "logo" }, [
                                _c("img", {
                                  staticClass: "info-logo-size",
                                  attrs: {
                                    src: row["iLogo"] || _vm.defaultImage,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handelClickToEnlargeImage(
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "name-div" }, [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(row["iName"])),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "category" }, [
                                  _vm._v(_vm._s(row["iCategory"])),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "code" }, [
                                  _vm._v(_vm._s(row["iCode"])),
                                ]),
                              ]),
                            ])
                          : item.format === "QRCode"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    staticStyle: { "margin-right": "20px" },
                                    attrs: {
                                      placement: "right",
                                      width: "230",
                                      height: "400",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("vue-qr", {
                                          ref: "Qrcode",
                                          refInFor: true,
                                          attrs: {
                                            text: row.qrCode,
                                            size: 200,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: {
                                          width: "30px",
                                          margin: "0 auto",
                                        },
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("vue-qr", {
                                          attrs: { text: row.qrCode, size: 30 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm._t("qrCodeOperation", null, {
                                  info: { row: row, index: $index },
                                }),
                              ],
                              2
                            )
                          : item.format === "img"
                          ? _c(
                              "span",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: {
                                    src: row.imgUrls[0],
                                    "preview-src-list": row.imgUrls,
                                    fit: "contain",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.format === "time"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("removeTheSecondsOfTime")(
                                    row[item.prop]
                                  )
                                )
                              ),
                            ])
                          : item.format === "currency"
                          ? _c("span", [
                              item.currencyType
                                ? _c("span", { staticClass: "currency" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatToCurrency")(
                                          row[item.prop],
                                          row[item.currencyType]
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", { staticClass: "currency" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatToCurrency")(
                                          row[item.prop]
                                        )
                                      )
                                    ),
                                  ]),
                            ])
                          : item.format === "sixMoney"
                          ? _c("span", [
                              _c("span", { staticClass: "currency" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatMoneySixDecimal")(
                                      row[item.prop]
                                    )
                                  )
                                ),
                              ]),
                            ])
                          : item.format === "percent"
                          ? _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatToPercent")(row[item.prop])
                                  )
                                ),
                              ]),
                            ])
                          : item.format === "money"
                          ? _c("span", [
                              _c("span", { staticClass: "currency" }, [
                                _vm._v("￥" + _vm._s(row[item.prop])),
                              ]),
                            ])
                          : item.format === "bold"
                          ? _c("span", [
                              _c("span", { staticClass: "bold" }, [
                                _vm._v(_vm._s(row[item.prop])),
                              ]),
                            ])
                          : item.format === "move"
                          ? _c(
                              "span",
                              [
                                _vm.scope.$index !== 0
                                  ? _c(
                                      "ht-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleMoveUp(
                                              _vm.scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-arrow-circle-up",
                                        }),
                                        _vm._v(" 上移"),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.scope.$index + 1 !== _vm.data.length
                                  ? _c(
                                      "ht-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleMoveDown(
                                              _vm.scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-arrow-circle-down",
                                        }),
                                        _vm._v(" 下移"),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : item.format === "link"
                          ? _c("span", [
                              _c(
                                "u",
                                {
                                  staticClass: "link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickLink(
                                        row,
                                        item.label
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row[item.prop]))]
                              ),
                            ])
                          : item.format === "a"
                          ? _c("span", [
                              _c("u", { staticClass: "link" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: row[item.prop],
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(row[item.prop]))]
                                ),
                              ]),
                            ])
                          : item.format === "tooltip"
                          ? _c(
                              "span",
                              { staticClass: "table-tooltip" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content: row[item.tooltips],
                                      placement: "left",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "tooltips" }, [
                                      _vm._v(_vm._s(row[item.prop])),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : item.format === "number"
                          ? _c("span", [_vm._v(_vm._s(+row[item.prop]))])
                          : item.format === "star"
                          ? _c(
                              "span",
                              [
                                _c("el-rate", {
                                  attrs: {
                                    value: +row[item.prop],
                                    disabled: "",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.format === "color"
                          ? _c("span", [
                              _c("div", {
                                staticClass: "color-block",
                                style: { "background-color": row[item.prop] },
                              }),
                            ])
                          : item.format === "tag"
                          ? _c(
                              "span",
                              [
                                item.value && item.prop && row[item.prop]
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: item.value[row[item.prop]],
                                        },
                                      },
                                      [_vm._v(_vm._s(row[item.prop]))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : item.format === "question"
                          ? _c(
                              "span",
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row[item.prop]) +
                                    "\n          "
                                ),
                                row.questionIcon
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right-start",
                                          width: "200",
                                          trigger: "click",
                                          content: row.question,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-question-circle-o question",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : item.format === "switch"
                          ? _c(
                              "span",
                              [
                                item.prop
                                  ? _c("el-switch", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.switchChange(row)
                                        },
                                      },
                                      model: {
                                        value: row[item.prop],
                                        callback: function ($$v) {
                                          _vm.$set(row, item.prop, $$v)
                                        },
                                        expression: "row[item.prop]",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : item.format === "status"
                          ? _c(
                              "span",
                              [
                                row[item.prop]
                                  ? _c("status", {
                                      attrs: {
                                        status: row[item.prop],
                                        "status-types": item.statusType,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("span", [
                              item.secondProp
                                ? _c("span", { style: item.style }, [
                                    _vm._v(
                                      _vm._s(
                                        row[item.firstProp] &&
                                          row[item.firstProp][item.secondProp]
                                          ? row[item.firstProp][item.secondProp]
                                          : "-"
                                      )
                                    ),
                                  ])
                                : _c("span", { style: item.style }, [
                                    _vm._v(
                                      _vm._s(
                                        row[item.prop] ? row[item.prop] : "-"
                                      )
                                    ),
                                  ]),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm._t("extra"),
          _vm._v(" "),
          _vm.btWidth
            ? _c("ht-table-column-operation", {
                attrs: { width: _vm.btWidth + "px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c(
                            "span",
                            [
                              _c(
                                "ht-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: row.mainButton.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickOthersButton(
                                        row,
                                        row.mainButton.name
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.mainButton.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { trigger: "click" },
                                  on: {
                                    command: function ($event) {
                                      return _vm.handleClickOthersButton(
                                        row,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    _vm._l(row.otherButtons, function (i) {
                                      return _c(
                                        "el-dropdown-item",
                                        {
                                          key: i.name,
                                          attrs: { command: i.name },
                                        },
                                        [
                                          i.isPopoverButton
                                            ? _c(
                                                "ht-popover-button",
                                                {
                                                  attrs: {
                                                    disabled: i.disabled,
                                                    type: "text",
                                                    "danger-text": i.dangerText,
                                                    "confirm-title":
                                                      i.confirmTitle,
                                                    "button-color":
                                                      i.buttonColor &&
                                                      !i.dangerText
                                                        ? i.buttonColor
                                                        : "",
                                                    icon: i.icon,
                                                  },
                                                  on: {
                                                    confirmed: function (
                                                      $event
                                                    ) {
                                                      return _vm.confirmMethod(
                                                        row,
                                                        i.name
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(i.name) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "ht-button",
                                                {
                                                  style: { color: i.color },
                                                  attrs: {
                                                    type: "text",
                                                    icon: i.icon,
                                                  },
                                                },
                                                [_vm._v(_vm._s(i.name))]
                                              ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1408376438
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isShowPagination && _vm.page.total > 0 && _vm.list
        ? _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("ht-pagination", {
                staticStyle: { float: "right", "margin-bottom": "10px" },
                attrs: {
                  total: _vm.page.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.perPage,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "perPage", $event)
                  },
                  pagination: _vm.pagination,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }