// 会议使用开启白板
import dwCustomImMsg from '@/views/newRemoteGuidance/js/dwCustomImMsg'

export default {
  methods: {
    useLaser() {
      if (this.isPaintDrawing) { this.usePaint() }
      this.isLaserMoving = !this.isLaserMoving
      this.sendLaserMsg(this.isLaserMoving, this.enLargeVideoInfo.imUserId)
      if (this.enLargeVideoInfo && this.enLargeVideoInfo.remoteOperation) {
        this.enLargeVideoInfo.remoteOperation.isLaserMoving = this.isLaserMoving
      }
    },
    usePaint() {
      if (this.isLaserMoving) { this.useLaser() }
      this.isPaintDrawing = !this.isPaintDrawing
      this.sendPaintMsg(this.isPaintDrawing, this.enLargeVideoInfo.imUserId)
      if (this.enLargeVideoInfo && this.enLargeVideoInfo.remoteOperation) {
        this.enLargeVideoInfo.remoteOperation.isPaintDrawing = this.isPaintDrawing
      }
    },
    useClear() {
      if (this.enLarge) {
        this.enLargeVideoInfo.remoteOperation.isClearPaint = false
        setTimeout(() => {
          this.enLargeVideoInfo.remoteOperation.isClearPaint = true
        })
      } else {
        if (this.remoteStaffList.length === 1) {
          const index = this.remoteUserList.findIndex(item => item.userInfo.imUserId === this.remoteStaffList[0].userInfo.imUserId)
          if (index !== -1) {
            this.remoteUserList[index].remoteOperation.isClearPaint = false
            setTimeout(() => {
              this.remoteUserList[index].remoteOperation.isClearPaint = true
            })
          }
        }
      }
    },
    paintByRemoteFun(e) {
      const detail = e.detail
      const needResponse = this.isExpert(this.userInfo) || detail.targetUserId === this.imUserId
      if (this.isFrozenGuidanceByRemote || this.isFreezeGuidance) {
        if (detail.status === dwCustomImMsg.DwPaint.Open) {
          if (needResponse) {
            this.freezeCanvasTrack.isRemotePaint = true
            this.freezeCanvasTrack.isRemoteLaser = false
            if (!this.remotePaintTimeStamp || this.remotePaintTimeStamp < e.receivedTime) {
              this.freezeCanvasTrack.remotePaintInfo = detail
              this.remotePaintTimeStamp = e.receivedTime
            }
          }
        } else if (detail.status === dwCustomImMsg.DwPaint.Close || detail.status === dwCustomImMsg.DwPaint.Clear) {
          this.freezeCanvasTrack.isRemotePaint = false
          this.freezeCanvasTrack.isRemoteLaser = false
          this.freezeCanvasTrack.remotePaintInfo = null
          this.remotePaintTimeStamp = null

          // 标注指导打来时候可能正在进行视频的画笔绘制过激光笔绘制
          if (this.isRemotePaint) {
            this.isRemotePaint = false
            this.remotePaintInfo = detail
            this.remotePaintTimeStamp = null
          }
        }
      } else {
        if (detail.status === dwCustomImMsg.DwPaint.Open) {
          if (needResponse) {
            this.isRemotePaint = true
            if (!this.remotePaintTimeStamp || this.remotePaintTimeStamp < e.receivedTime) {
              this.remotePaintInfo = detail
              this.remotePaintTimeStamp = e.receivedTime
            }
          }
        } else if (detail.status === dwCustomImMsg.DwPaint.Close || detail.status === dwCustomImMsg.DwPaint.Clear) {
          this.isRemotePaint = false
          this.remotePaintInfo = detail
          this.remotePaintTimeStamp = null
        }
      }
    },
    laserByRemoteFun(e) {
      const detail = e.detail
      const needResponse = this.isExpert(this.userInfo) || detail.targetUserId === this.imUserId
      if (this.isExpert(this.userInfo) || detail.targetUserId === this.imUserId) {
        if ((this.isFrozenGuidanceByRemote || this.isFreezeGuidance)) {
          if (detail.status === dwCustomImMsg.DwLaser.Open) {
            if (needResponse) {
              this.freezeCanvasTrack.isRemoteLaser = true
              this.freezeCanvasTrack.isRemotePaint = false
            }
          } else if (detail.status === dwCustomImMsg.DwLaser.Close) {
            this.freezeCanvasTrack.isRemoteLaser = false
            this.freezeCanvasTrack.isRemotePaint = false

            // 标注指导打来时候可能正在进行视频的画笔绘制过激光笔绘制
            if (this.isRemoteLaser) {
              this.isRemoteLaser = false
              this.remoteLaserInfo = null
            }
          }
          this.freezeCanvasTrack.remoteLaserInfo = detail
        } else {
          if (detail.status === dwCustomImMsg.DwLaser.Open) {
            if (needResponse) {
              this.isRemoteLaser = true
              this.remoteLaserInfo = detail
            }
          } else if (detail.status === dwCustomImMsg.DwLaser.Close) {
            this.isRemoteLaser = false
            this.remoteLaserInfo = null
          }
        }
      }
    }
  }
}
