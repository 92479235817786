<template>
  <div class="warehouse-stock-container" :class="[{ 'is-card-container': isCard}]" @click="onView">
    <div class="top-section">
      <div class="name">设备：{{ warehouseInfo && warehouseInfo.name || '-' }}</div>
      <span class="button">查看详情></span>
    </div>
    <div class="middle-section">
      <div class="left">
        <div class="item quantity">库存数：{{ warehouseInfo && warehouseInfo.quantity }}</div>
      </div>
    </div>
    <div class="bottom-section">
      <!--      todo-->
      <!--      <el-button v-if="warehouseInfo.quantity > 0" type="warning" round @click="onOutbound">出库</el-button>-->
      <!--      <el-button type="primary" round @click="onInbound">入库</el-button>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarehouseStockCard',
  props: {
    warehouseInfo: { type: Object, default() { return {} } },
    isCard: { type: Boolean, default: true }
  },
  methods: {
    onView() {
      this.$emit('on-view', this.warehouseInfo)
    },
    onOutbound() {
      this.$emit('on-outbound', this.warehouseInfo)
    },
    onInbound() {
      this.$emit('on-inbound', this.warehouseInfo)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';

.warehouse-stock-container{
  font-size: 1.1rem;
  background: $primaryColorLight;
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
  color: #666666;
  .top-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .name{
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
      font-weight: bold;
    }
    .button{
      font-size: 1rem;
      color: $primaryColor;
    }
  }
  .middle-section{
    //margin: 10px 0;
    margin: 10px 0 0;
    font-size: 0.9rem;
  }
  .bottom-section{
    display: flex;
    justify-content: space-between;
    .el-button{
      flex: 1;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
</style>
