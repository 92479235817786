import _ from 'lodash'

export default {
  methods: {
    handleResize() {
      this.$forceUpdate()
      this.$nextTick(() => {
        if (this.isFocusGuidance) {
          this.getFreezeVideoRect()
          this.$refs.freezeGuidanceRef.handleResize()
        } else if (this.isFrozenGuidanceByRemote) {
          this.getLocalVideoRect()
          this.$refs.freezeGuidanceRef.handleResize()
        }
      })
    },
    enterFulScreen() {
      this.fullscreen = true
    },
    exitFullScreen() {
      this.fullscreen = false
    },
    minimizedScreen() {},
    openMeetingMessage() {
      this.$nextTick(() => {
        this.closeBoxes('boxMessageRef')
        this.$refs.boxMessageRef.toggleBoxStatus()
        this.unreadCount = 0
        this.getDialogWidth('boxMessageRef')
      })
    },
    viewAttendees() {
      this.showAttendeesBox = true
      this.$nextTick(() => {
        this.closeBoxes('boxAttendeesRef')
        this.$refs.boxAttendeesRef.toggleBoxStatus()
        this.getDialogWidth('boxAttendeesRef')
      })
    },
    viewOnlineUser() {
      this.showOnlineUserBox = true
      this.$nextTick(() => {
        this.closeBoxes('boxOnlineUserRef')
        this.$refs.boxOnlineUserRef.toggleBoxStatus()
        this.getDialogWidth('boxOnlineUserRef')
      })
    },
    selectVideoResolution(value) {
      this.videoResolution = value
      this.unpublishAll({ camera: true }).then(() => {
        this.getLocalTrack({ camera: true }).then(() => {
          this.publishAll({ camera: true })
        }).catch(() => {
          this.$message({ message: `获取视频失败`, duration: 1500, type: 'error' })
        })
      })
      this.sendVideoConfigStatusMsg(this.chatRoomId, value)
    },
    getDialogWidth(ref) {
      this.isOpenBox = this.$refs[ref] && this.$refs[ref].showBox
    },
    closeBoxes(boxRef) {
      const boxes = ['boxMessageRef', 'boxAttendeesRef', 'boxOnlineUserRef']
      boxes.forEach(item => {
        if (item !== boxRef) {
          if (this.$refs[item]) {
            this.$refs[item].showBox = false
          }
        }
      })
    },
    //  放大video
    async enlargeVideo(index) {
      this.enLarge = true
      this.enLargeVideoInfo = _.cloneDeep(this.remoteUserList[index]) // 被选中放大的用户
      if (this.enLargeVideoInfo) {
        const track = this.enLargeVideoInfo && this.enLargeVideoInfo.videoTrack // 被放大用户的视频流
        // 重新订阅资源视频流，获取大流
        this.enLargeVideoInfo.isSubscribing = true
        // 取消订阅资源和订阅资源不需要unsubscribe和subscribe处理视频播放和用户列表
        this.unsubscribe(this.room, [track], false).then(() => {
          this.subscribe(this.room, [track], false, false).then(() => {
            this.enLargeVideoInfo.isSubscribing = false
          })
        })
      }
    },
    // 缩小video
    async zoomVideo() {
      // 聚焦指导下不可以缩小
      if (this.isFocusGuidance) {
        this.$message({ message: `请先退出聚焦指导后在进行此操作`, type: 'error', center: true })
      } else {
        // 小窗口使用小流， 重新订阅小流
        const track = this.enLargeVideoInfo && this.enLargeVideoInfo.videoTrack
        // 缩小视频取消订阅重新订阅流，如果多现场订阅小流，单现场订阅大流，不需要进行聚焦的计算
        const index = this.remoteUserList.findIndex(item => item.imUserId === this.enLargeVideoInfo.imUserId)
        // 赋值改变需要在重新订阅之前，以防track ready时还是enLarge，播放对应视频到enlargeVideo上
        this.enLarge = false
        this.enLargeVideoInfo = {}
        if (index !== -1) {
          this.remoteUserList[index].isSubscribing = true
          this.$set(this.remoteUserList, index, this.remoteUserList[index])
        }
        await this.unsubscribe(this.room, [track], false)
        await this.subscribe(this.room, [track], this.remoteStaffList.length > 1, false)
        if (index !== -1) {
          this.remoteUserList[index].isSubscribing = false
          this.$set(this.remoteUserList, index, this.remoteUserList[index])
        }
        this.getMeetingUserInfo()
        // 缩小后布局发生变化，需要重新播放所有视频流
        this.remoteVideoTracks.forEach(item => {
          if (this.remoteUserList.length > 0) {
            this.$nextTick(() => {
              this.playTrack(item)
            })
          }
        })
      }
    }
  }
}
