var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "warehouseForm",
          attrs: {
            model: _vm.warehouseForm,
            rules: _vm.warehouseFormRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "仓库名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入仓库名称",
                  maxlength: "15",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.warehouseForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseForm, "name", $$v)
                  },
                  expression: "warehouseForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "仓库地址：", prop: "location" } },
            [
              _c("el-input", {
                attrs: {
                  id: "tipinput",
                  placeholder: "请输入仓库地址",
                  maxlength: "50",
                  "show-word-limit": "",
                },
                on: { change: _vm.handleLocationChange },
                model: {
                  value: _vm.warehouseForm.location,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseForm, "location", $$v)
                  },
                  expression: "warehouseForm.location",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "map-container",
            attrs: { id: "mapContainer" },
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "仓库管理员：", prop: "staffId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder:
                      _vm.staffList.length > 0
                        ? "请输入姓名进行搜索"
                        : "当前无可选员工",
                    "popper-class": _vm.selectPopperClass,
                    remote: "",
                    "remote-method": _vm.getStaffs,
                    clearable: "",
                  },
                  on: {
                    change: _vm.handleSelectStaff,
                    clear: function ($event) {
                      _vm.warehouseForm.staffId = null
                    },
                  },
                  model: {
                    value: _vm.warehouseForm.staffId,
                    callback: function ($$v) {
                      _vm.$set(_vm.warehouseForm, "staffId", $$v)
                    },
                    expression: "warehouseForm.staffId",
                  },
                },
                _vm._l(_vm.staffList, function (item) {
                  return _c("el-option", {
                    key: item.userId,
                    attrs: { label: item.fullName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "mobile" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入联系电话",
                  maxlength: "12",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.warehouseForm.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.warehouseForm, "mobile", $$v)
                  },
                  expression: "warehouseForm.mobile",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmSubmit("warehouseForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }