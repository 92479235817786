<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClearSearch">
      <template slot="left">
        <el-input v-model="listQuery.nskMaterialCode" placeholder="品番" />
        <el-input v-model="listQuery.nskMaterialName" placeholder="品名" />
        <el-input v-model="listQuery.supplierName" placeholder="供应商" />
        <el-date-picker v-model="receivingAt" type="daterange" range-separator="至" value-format="yyyy-MM-dd" placement="bottom-start" start-placeholder="来料日" on-search end-placeholder="来料日" />
        <el-date-picker v-model="checkAt" type="daterange" range-separator="至" value-format="yyyy-MM-dd" placement="bottom-start" start-placeholder="检查日" on-search end-placeholder="来料日" />
        <el-input v-model="listQuery.staffName" placeholder="检查员" />
        <el-select v-model="listQuery.status" placeholder="检查状态">
          <el-option v-for="(key,value) in ChecklistStatusEnum" :key="key" :label="key" :value="value" />
        </el-select>
        <el-select v-model="listQuery.isAbnormal" placeholder="判定结果">
          <el-option v-for="(item,index) in ChecklistAbnormalEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer" @row-click="handleRowClick">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in NskCheckListMgrListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '检查员'">{{ row.staff.fullName || '-' }}</span>
          <el-tag v-else-if="item.label === '检查状态'" :type="ChecklistStatusStyleEnum[row[item.prop]]">{{ ChecklistStatusEnum[row[item.prop]] || '-' }}</el-tag>
          <el-tag v-else-if="item.label === '判定结果' && row.finishAt" class="status" :type="row.isAbnormal ? 'danger' : 'success'">{{ row.isAbnormal ? '不合格' : '合格' }}</el-tag>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="200">
        <template v-slot="{row,$index}">
          <ht-button type="text" icon="el-icon-view" @click="onView(row,true)">详情</ht-button>
          <ht-button :loading="loadingIndex === $index" :disabled="(loadingIndex !== -1 && loadingIndex !== $index)" type="text" icon="el-icon-document" @click="onExport(row, $index)">导出</ht-button>
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该部品？" @confirmed="onDelete(row)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { NskCheckListMgrListTitle } from '@/views/incomingInspectionMgr/tableTitle'
import { ChecklistStatusStyleEnum, ChecklistStatusEnum, ChecklistAbnormalEnum } from '@/views/incomingInspectionMgr/enum'
import { mapGetters } from 'vuex'
import { getNskChecklists, deleteNskChecklist, postExportNskChecklist } from '@/api/incomingInspectionMgr/nskChecklistMgr'

export default {
  name: 'List',
  extends: HtListContainer,
  data() {
    return {
      NskCheckListMgrListTitle, ChecklistStatusStyleEnum, ChecklistStatusEnum, ChecklistAbnormalEnum,
      showCreateNskMaterialForm: false,
      showEditNskMaterialForm: false,
      receivingAt: [],
      checkAt: [],
      loadingIndex: -1
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    onClearSearch() {
      this.receivingAt = []
      this.checkAt = []
      this.onClear()
    },
    getList() {
      this.beforeGetList()
      this.listQuery.receivedStartAt = this.receivingAt && this.receivingAt[0] || null
      this.listQuery.receivedEndAt = this.receivingAt && this.receivingAt[1] || null
      this.listQuery.checkedStartAt = this.checkAt && this.checkAt[0] || null
      this.listQuery.checkedEndAt = this.checkAt && this.checkAt[1] || null
      getNskChecklists(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onView(row) {
      this.$router.push({ name: 'nskChecklistMgrView', params: { checklistId: row.id }})
    },
    onExport(row, index) {
      this.loadingIndex = index
      this.$message({ message: `正在导出检查单，请稍候`, type: 'warning', center: true })
      postExportNskChecklist(row.id).then((resp) => {
        this.loadingIndex = -1
        window.open(resp.fileUrl)
      }).catch(() => {
        this.loadingIndex = -1
      })
    },
    onDelete(row) {
      deleteNskChecklist(row.id).then(() => {
        this.$message({ message: '删除成功', type: 'success' })
        this.getList()
      })
    },
    handleRowClick(row, column) {
      if (column.label !== '操作') {
        this.onView(row)
      }
    }
  }
}
</script>

<style scoped>

</style>
