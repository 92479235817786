import userState from '@/store'

export const ObjectTypeEnum = {
  EQUIPMENT: '设备',
  NONE: '暂无'
}

export const TaskWorkOrderStatusEnum = {
  DISTRIBUTED: '已分发',
  STARTED: '执行中',
  FINISHED: '已结束'
}

export const TaskWorkOrderStatusStyleEnum = {
  DISTRIBUTED: 'info',
  STARTED: 'warning',
  FINISHED: 'success'
}

export const TaskTempTypeNameEnum = {
  PATROL: '巡检步骤',
  REPAIR: '维修模版'
}

export const TaskTempTypeEnum = {
  PATROL: '巡检',
  REPAIR: '维修'
}

export const TaskTempTypeObjectEnum = [
  { name: 'PATROL', value: '巡检', stepName: '巡检步骤', isShow: userState.getters.isHasPatrolMenu },
  { name: 'REPAIR', value: '维修', stepName: '维修模版', isShow: userState.getters.isHasRepairMenu }
]

export const TowerWorkOrderTypeObjectEnum = [
  { name: 'MAINTENANCE', value: '维保', color: '#67C23A' },
  { name: 'INSTALLATION', value: '安装', color: '#E6A23C' },
  { name: 'REMOVAL', value: '拆卸', color: '#F56C6C' },
  { name: 'WALL_ATTACHMENT', value: '附墙', color: '#2E5AFF' },
  { name: 'ADD_SECTION', value: '加节', color: '#F65B19' },
  { name: 'REDUCE_SECTION', value: '降节', color: '#F65B19' }
]

export const LoopEnum = {
  true: '1天',
  false: '无'
}

export const ResultsEnum = {
  SUCCESS: '合格',
  FAIL: '不合格',
  RECTIFICATION_SUCCESS: '整改后合格',
  IGNORE: '无此项'
}

export const ErrorLevelEnum = [
  { name: '特级隐患', value: '特级隐患', color: 'ea360f' },
  { name: '三级隐患', value: '三级隐患', color: 'ee813c' },
  { name: '二级隐患', value: '二级隐患', color: 'f6c343' },
  { name: '一级隐患', value: '一级隐患', color: '2e5aff' },
  { name: '一般隐患', value: '一般隐患', color: '2e5aff' },
  { name: '无隐患', value: '无隐患', color: '6c6c6c' }
]

export const CheckTypeEnum = [
  { name: '日常检查', value: '日常检查', color: '2e5aff' },
  { name: '专家检查', value: '专家检查', color: '2e5aff' },
  { name: '复工检查', value: '复工检查', color: '2e5aff' },
  { name: '节假日检查', value: '节假日检查', color: '2e5aff' },
  { name: '季节性检查', value: '季节性检查', color: '2e5aff' }
]

export const AccidentTypeEnum = {
  HAPPENED: '已遂',
  NOT_HAPPENED: '未遂'
}

export const FileListTitleEnum = [
  // 维保
  { name: '维保记录表', isShow: 'MAINTENANCE', files: [] },
  //   安装
  { name: '旁站记录表', isShow: 'INSTALLATION', files: [] },
  { name: '工作任务派遣书', isShow: 'INSTALLATION', files: [] },
  // { name: '作业前申请表', isShow: 'INSTALLATION', files: [] },
  { name: '实时告知表', isShow: 'INSTALLATION', files: [] },
  { name: '验收表', isShow: 'INSTALLATION', files: [] },
  { name: '安全交底', isShow: 'INSTALLATION', files: [] },
  { name: '三方旁站', isShow: 'INSTALLATION', files: [] },
  // 拆卸
  { name: '旁站记录表', isShow: 'REMOVAL', files: [] },
  { name: '工作任务派遣书', isShow: 'REMOVAL', files: [] },
  // { name: '作业前申请表', isShow: 'REMOVAL', files: [] },
  { name: '实时告知表', isShow: 'REMOVAL', files: [] },
  { name: '验收表', isShow: 'REMOVAL', files: [] },
  { name: '安全交底', isShow: 'REMOVAL', files: [] },
  { name: '三方旁站', isShow: 'REMOVAL', files: [] },
  // 附墙
  { name: '旁站记录表', isShow: 'WALL_ATTACHMENT', files: [] },
  { name: '工作任务派遣书', isShow: 'WALL_ATTACHMENT', files: [] },
  // { name: '作业前申请表', isShow: 'WALL_ATTACHMENT', files: [] },
  { name: '实时告知表', isShow: 'WALL_ATTACHMENT', files: [] },
  { name: '验收表', isShow: 'WALL_ATTACHMENT', files: [] },
  { name: '安全交底', isShow: 'WALL_ATTACHMENT', files: [] },
  { name: '三方旁站', isShow: 'WALL_ATTACHMENT', files: [] },
  // 加节
  { name: '旁站记录表', isShow: 'ADD_SECTION', files: [] },
  { name: '工作任务派遣书', isShow: 'ADD_SECTION', files: [] },
  // { name: '作业前申请表', isShow: 'ADD_SECTION', files: [] },
  { name: '实时告知表', isShow: 'ADD_SECTION', files: [] },
  { name: '验收表', isShow: 'ADD_SECTION', files: [] },
  { name: '安全交底', isShow: 'ADD_SECTION', files: [] },
  { name: '三方旁站', isShow: 'ADD_SECTION', files: [] },
  // 降节
  { name: '旁站记录表', isShow: 'REDUCE_SECTION', files: [] },
  { name: '工作任务派遣书', isShow: 'REDUCE_SECTION', files: [] },
  // { name: '作业前申请表', isShow: 'REDUCE_SECTION', files: [] },
  { name: '实时告知表', isShow: 'REDUCE_SECTION', files: [] },
  { name: '验收表', isShow: 'REDUCE_SECTION', files: [] },
  { name: '安全交底', isShow: 'REDUCE_SECTION', files: [] },
  { name: '三方旁站', isShow: 'REDUCE_SECTION', files: [] }
]

export const FileUploadStatusEnum = {
  FINISHED: '已完成',
  UNFINISHED: '未完成'
}

export const FileUploadStatusStyleEnum = {
  FINISHED: 'success',
  UNFINISHED: 'danger'
}

