// 设备清单
export const EquipmentListTitle = [
  { label: '设备名称', prop: 'name', width: 130 },
  { label: '产权编号', prop: 'code', width: 120 },
  { label: '规格型号', prop: 'specModel' },
  { label: '设备厂家', prop: 'factoryInfo' },
  { label: '出厂日期', prop: 'producedAt' },
  { label: '出厂编号', prop: 'producedNumber' },
  { label: '独立高度', prop: 'independentHeight' },
  { label: '设备价值', prop: 'equipmentValue' },
  { label: '设备区域', prop: 'province', width: 160 },
  { label: '详细地址', prop: 'address' },
  { label: '设备资料', prop: 'documentInformation' },
  { label: '创建时间', prop: 'createdAt', width: 160 },
  { label: '修改时间', prop: 'updatedAt', width: 160 },
  { label: '是否整机', prop: 'isWhole', fixed: 'right', width: 70 },
  { label: '是否异常', prop: 'isAbnormal', fixed: 'right', width: 70 },
  { label: '租赁状态', prop: 'leaseStatus', fixed: 'right', width: 70 },
  { label: '本月工单完成情况', prop: 'progressName', fixed: 'right', width: '130px' }
]

export const documentListTitle = [
  { label: '资料名称', prop: 'fileName', width: 120 },
  { label: '上传时间', prop: 'uploadAt' }
]

export const LeaseListTitle = [
  { label: '工程名称', prop: 'projectName', width: 250 },
  { label: '工程地址', prop: 'projectLocation', width: 250 },
  { label: '出租数量', prop: 'quantity' },
  { label: '出租开始时间', prop: 'startAt', width: 160 },
  { label: '出租结束时间', prop: 'endAt', width: 160 },
  { label: '状态', prop: 'projectEquipmentStatus', fixed: 'right' }
]

// 维修经验库
export const RepairExperienceLibraryListTitle = [
  { label: '设备名称', prop: 'equipmentName', width: 130 },
  { label: '产权编号', prop: 'equipmentCode', width: 120 },
  // { label: '设备类型', prop: 'equipmentType' },
  { label: '故障代码', prop: 'errorCode' },
  { label: '维修方法', prop: 'suggestion' }
]

// 库存管理-按设备
export const EquipmentStockListTitle = [
  { label: '设备名称', prop: 'name', width: 130 },
  { label: '产权编号', prop: 'code', width: 120 },
  { label: '规格型号', prop: 'specModel' },
  { label: '库存总数', prop: 'totalQuantity' },
  { label: '仓库库存数', prop: 'quantity' },
  { label: '工程现存数', prop: 'projectStockQuantity' },
  { label: '是否整机', prop: 'isWhole', fixed: 'right', width: 70 },
  { label: '是否异常', prop: 'isAbnormal', fixed: 'right', width: 70 },
  { label: '租赁状态', prop: 'leaseStatus', fixed: 'right', width: 70 }
]

// 库存管理-按设备-设备列表-仓库库存
export const EquipmentWarehouseStockInfoListTitle = [
  { label: '仓库名称', prop: 'name' },
  { label: '库存数', prop: 'quantity' }
]

export const EquipmentProjectStockInfoListTitle = [
  { label: '工程名称', prop: 'constructionProjectName' },
  { label: '现存数', prop: 'quantity' }
]

// 库存管理-按仓库
export const WarehouseStockListTitle = [
  { label: '仓库名称', prop: 'name' },
  { label: '当前库存数', prop: 'quantity' }
]

// 库存管理-按仓库-设备列表
export const WarehouseEquipmentStockListTitle = [
  { label: '设备名称', prop: 'constructionEquipmentName', width: 130 },
  { label: '产权编号', prop: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'constructionEquipmentSpecModel' },
  { label: '是否整机', prop: 'isWhole' },
  { label: '仓库库存数', prop: 'quantity' }
]

// 库存管理-按仓库-出库
export const WarehouseEquipmentStockOutboundListTitle = [
  { label: '设备名称', prop: 'name', otherProp: 'constructionEquipmentName', width: 130 },
  { label: '产权编号', prop: 'code', otherProp: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'specModel', otherProp: 'constructionEquipmentSpecModel', width: 80 },
  { label: '库存数', prop: 'quantity', otherProp: 'quantity' }
]

// 库存管理-按仓库-入库
export const WarehouseEquipmentStockInboundListTitle = [
  { label: '设备名称', prop: 'name', otherProp: 'constructionEquipmentName', width: 130 },
  { label: '产权编号1', prop: 'code', otherProp: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'specModel', otherProp: 'constructionEquipmentSpecModel', width: 80 }
]

// 库存记录-入库记录
export const StockRecordMgrInboundListTitle = [
  { label: '设备名称', prop: 'constructionEquipmentName', width: 130 },
  { label: '产权编号', prop: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'constructionEquipmentSpecModel' },
  { label: '操作类型', prop: 'action' },
  { label: '设备来源', prop: 'constructionEquipmentSource' },
  { label: '工程名称', prop: 'constructionProjectName' },
  { label: '入库仓库', prop: 'warehouseName' },
  { label: '入库数量', prop: 'quantity' },
  { label: '入库时间', prop: 'createdAt', width: 160 },
  { label: '入库人', prop: 'staff.fullName' },
  { label: '入库文件', prop: 'attachmentData', width: 160 }
]

// 库存记录-出库记录
export const StockRecordMgrOutboundListTitle = [
  { label: '设备名称', prop: 'constructionEquipmentName', width: 130 },
  { label: '产权编号', prop: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'constructionEquipmentSpecModel' },
  { label: '操作类型', prop: 'action' },
  { label: '出库仓库', prop: 'warehouseName' },
  { label: '租赁方', prop: 'constructionProjectName' },
  { label: '出库数量', prop: 'quantity' },
  { label: '出库时间', prop: 'createdAt', width: 160 },
  { label: '出库人', prop: 'staff.fullName' },
  { label: '出库单', prop: 'attachmentData', width: 160 }
]

// 库存记录-出库记录
export const StockRecordMgrTransferListTitle = [
  { label: '设备名称', prop: 'constructionEquipmentName', width: 130 },
  { label: '产权编号', prop: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'constructionEquipmentSpecModel' },
  { label: '操作类型', prop: 'action' },
  { label: '转出工程', prop: 'oldConstructionProjectName' },
  { label: '转入工程', prop: 'constructionProjectName' },
  { label: '转移数量', prop: 'quantity' },
  { label: '转移时间', prop: 'createdAt', width: 160 },
  { label: '操作人', prop: 'staff.fullName' },
  { label: '转移文件', prop: 'attachmentData', width: 160 }
]

// 仓库管理
export const WarehouseListTitle = [
  { label: '仓库名称', prop: 'name' },
  { label: '仓库地址', prop: 'location', width: 200 },
  { label: '仓库管理员', prop: 'staff' },
  { label: '联系电话', prop: 'mobile' }
]

// 设备租赁记录
export const EquipmentLeaseRecordsListTitle = [
  { label: '设备名称', prop: 'constructionEquipmentName', width: 130 },
  { label: '产权编号', prop: 'constructionEquipmentCode', width: 120 },
  { label: '规格型号', prop: 'constructionEquipmentSpecModel' },
  { label: '租用工程', prop: 'constructionProjectName' },
  { label: '执行代码', prop: 'executeCode' },
  { label: '租用开始时间', prop: 'startAt', width: 160 },
  { label: '租用结束时间', prop: 'endAt', width: 160 },
  { label: '状态', prop: 'status', fixed: 'right' }
]

// 路线巡检
export const RoutePatrolMgrListTitle = [
  { label: '工单编号', prop: 'code' },
  { label: '视频链接', prop: 'videoUrls' }
]
