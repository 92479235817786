<template>
  <div
    class="button-container"
    :class="{'click-button': isShowDot, 'is-disabled': isDisabled}"
    :style="{width: width + 'px' }"
    @click="clickButton"
  >
    <img v-if="isShowDot" class="dot" :src="RedDot" alt="">
    <el-badge v-if="isShowNum && num > 0" :value="num" class="num" />
    <img class="icon" :src="icon" alt="">
    <span class="name">{{ name }}</span>
  </div>
</template>

<script>
import RedDot from '@/assets/image/newRemoteGuidance/red_dot.png'
export default {
  name: 'OperatingButton',
  props: {
    icon: { type: String, default: undefined },
    name: { type: String, default: undefined },
    isShowDot: { type: Boolean, default: false },
    isShowNum: { type: Boolean, default: false },
    num: { type: Number, default: 0 },
    isDisabled: { type: Boolean, default: false },
    width: { type: Number, default: 60 }
  },
  data() {
    return {
      RedDot
    }
  },
  methods: {
    clickButton() {
      if (!this.isDisabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-width: 60px;
  padding: 30px 10px;
  position: relative;
  margin-right: 2px;
  cursor: pointer;
  .icon{
    height: 20px;
    margin-bottom: 5px;
  }
  .name{
    font-size: 12px;
  }
  .num{
    position: absolute;
    right: 10px;
    top: 10px;
    ::v-deep .el-badge__content{
      line-height: 16px;
    }
  }
  .dot{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    animation: flash 3s infinite;
    left: 20px;
  }
  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.button-container:hover{
  background: #d4e5fe;
  border-radius: 10px;
}
.click-button{
  background: #d4e5fe;
  border-radius: 10px;
}

.is-disabled{
  cursor: not-allowed;
}
.is-disabled:hover{
  cursor: not-allowed;
  background: none;
}
</style>
