<template>
  <div class="step-ui-detail-container" :style="{height: height}">
    <div class="view-container">
      <div class="top-section">
        <div :class="{'focused-item': isFocusedItem === 'title'}" class="view-title top-title">{{ `${currentIndex+1}/${count}` }}:{{ properties.title }}</div>
        <div class="top-button-container">
          <el-button v-if="currentIndex > 0" class="top-title-button prev-button" type="text" @click="toPrev">上一项</el-button>
          <el-button v-if="currentIndex + 1 < count" class="top-title-button next-button" type="text" @click="toNext">下一项</el-button>
          <el-button :class="{'focused-item': isFocusedItem === 'text'}" class="top-title-button voice-button" type="text" @click="nativePlayTTS(properties.guidanceVideo)">语音播报</el-button>
          <el-button class="top-title-button more-function-button" type="text" @click="moreFunction">更多功能</el-button>
          <div v-if="showMoreFunction" class="more-function-container">
            <img :src="Triangle" alt="" class="more-function-img">
            <el-button class="top-title-button guidance-button" type="text" @click="toRemoteGuidance">远程指导</el-button>
            <el-button class="top-title-button ai-button" type="text" @click="toAiQaAssistant">问答助手</el-button>
          </div>
        </div>
      </div>
      <div class="middle-section">
        <div class="left-container" :class="{'focused-item': isFocusedItem === 'referenceImage'}">
          <div class="sub-title only-text-title">参考图</div>
          <div class="img-container reference-img-container">
            <dw-el-image
              v-if="properties.referenceImage"
              ref="referenceImage"
              :src="properties.referenceImage"
              alt=""
              class="reference-img"
              :preview-src-list="[properties.referenceImage]"
              :show-icon="false"
            />
            <el-button
              v-if="properties.referenceImage"
              class="sub-title-button fullscreen-button"
              @click="fullScreenReferenceImage"
            >查看参考图</el-button>
          </div>
        </div>
        <div class="right-container" :class="{'focused-item': isFocusedItem === 'text'}">
          <div class="sub-title only-text-title">检查要求</div>
          <el-input
            v-model="properties.text"
            placeholder="检查要求"
            style="width: 100%; height: 100%"
            type="textarea"
            class="check-standard"
            disabled
          />
        </div>
      </div>
      <div class="bottom-section">
        <div class="top-container">
          <div class="left-container">
            <div class="title-container">
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length < 9"
                class="sub-title-button photo-button"
                type="text"
                @click="nativeTakePhoto"
              ><span v-if="properties.mustUploadPhoto" class="required-item">*</span>拍摄</el-button>
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length > 0"
                class="sub-title-button del-button"
                type="text"
                @click="delPhoto"
              >删除</el-button>
            </div>
            <div class="img-container">
              <dw-carousel ref="customCarousel" height="140px" :autoplay="false" arrow="always" @change="setActiveItem">
                <dw-carousel-item v-for="(item, index) in submitResults.uploadPhotos" :key="index">
                  <dw-el-image :ref="`result${index}`" :src="item" alt="" class="result-img" :preview-src-list="results.uploadPhotos" :show-icon="false" />
                </dw-carousel-item>
              </dw-carousel>
              <el-button
                v-if="submitResults.uploadPhotos && submitResults.uploadPhotos.length > 0"
                class="sub-title-button fullscreen-button"
                @click="fullScreenPhoto"
              >查看照片</el-button>
            </div>
          </div>
          <div class="right-container">
            <div class="title-container">
              <el-button class="sub-title-button fact-button" type="text" @click="inputText('factDesc')">情况描述</el-button>
              <el-button class="sub-title-button clear-button" type="text" @click="clearFactDesc">清空</el-button>
            </div>
            <el-input
              ref="rectDescRef"
              v-model="submitResults.factDesc"
              placeholder="情况描述"
              style="width: 100%; height: 100%"
              type="textarea"
              class="check-standard"
            />
          </div>
        </div>
        <div class="bottom-container">
          <div class="bottom-left-container">
            <span class="required-item">*</span>
            <span :class="{'focused-item': isFocusedItem === 'dataName'}" class="sub-title result-title result-name">{{ properties.dataName || '数据名称' }}:</span>
            <div class="result-reference-container">
              <span class="third-title">参考：</span>
              <span :class="{'focused-item': isFocusedItem === 'minValue'}" class="min-value value-item">{{ properties.minValue || '下限' }}</span>
              &nbsp;至&nbsp;
              <span :class="{'focused-item': isFocusedItem === 'maxValue'}" class="max-value value-item">{{ properties.maxValue || '上限' }}</span>
              <span :class="{'focused-item': isFocusedItem === 'dataUnit'}" class="sub-title result-title result-unit" style="margin-left: 5px">{{ properties.dataUnit || '单位' }}</span>
            </div>
            <!--            <el-input-number v-model="submitResults.value" :controls="false" class="value-input" placeholder="实际数据" />-->
            <el-input ref="value" v-model="submitResults.value" type="number" class="value-input" placeholder="实际数据" />
          </div>
          <div class="bottom-right-container">
            <el-button class="input-button bottom-button" type="text" @click="inputValue">输入数据</el-button>
            <!--            <el-button class="input-button bottom-button" type="text" @click="inputText('value')">输入数据</el-button>-->
            <el-button class="save-button bottom-button" type="text" @click="saveRecord">保存记录</el-button>
            <el-button v-if="isLastStep" class="submit-button bottom-button" type="text" @click="submitRecord">{{ isLastStep ? '提交单据' : '保存记录' }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEdit" class="edit-overlay" />

    <ht-dialog ref="inputNumDialog" :title="properties.dataName || '数据名称'" width="40%" hidden-close @close="closeInputNumDialog">
      <template slot="close">
        <el-button class="close-dialog-button" type="text" @click="closeInputNumDialog">关闭</el-button>
      </template>
      <div slot="content">
        <div class="num-item">
          <el-input v-model="submitResults.minValue" type="number" class="value-input" placeholder="最小值" />
          <el-button class="input-button" type="text" @click="inputText('minValue')">输入最小值</el-button>
        </div>
        <div class="num-item">
          <el-input v-model="submitResults.maxValue" type="number" class="value-input" placeholder="最大值" />
          <el-button class="input-button" type="text" @click="inputText('maxValue')">输入最大值</el-button>
        </div>
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/templateMgr/components/stepUiMixin'

export default {
  name: 'StepUi',
  mixins: [StepUiMixin],
  props: {
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          referenceImage: null,
          guidanceVideo: null,
          text: null,
          mustUploadPhoto: null
        }
      }
    },
    results: {
      type: Object,
      default() {
        return {
          latitude: null,
          longitude: null,
          location: null,
          uploadPhotos: [],
          factDesc: null,
          value: null
        }
      }
    }
  },
  data() {
    return {
      dictationItem: null
    }
  },
  methods: {
    setDictationResult(result) {
      if (this.dictationItem === 'factDesc') {
        this.submitResults.factDesc = this.submitResults.factDesc !== null ? this.submitResults.factDesc + result : result
      } else if (this.dictationItem === 'value') {
        this.submitResults.value = result
      }
    },
    inputText(value) {
      this.dictationItem = value
      this.nativeStartDictation()
    },
    inputValue() {
      this.$refs.value.focus()
    },
    openInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = true
    },
    closeInputNumDialog() {
      this.$refs.inputNumDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/taskMgr/templateMgr/components/stepUiCommonStyle";
@import "~@/styles/variables.scss";

.step-ui-detail-container{
  .view-container{
    .bottom-section{
      .bottom-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;

        .bottom-left-container{
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;
          .result-title{
            color: #000000;
          }
          .value-input{
            flex: 1;
            margin-right: 10px;

            ::v-deep  .el-input__inner {
              height: 40px;
              line-height: 40px;
              font-size: 1.2rem;
            }
          }

          .result-reference-container{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.0rem;
            .third-title{
              font-size: 1.3rem;
            }
            .value-item{
              min-width: 40px;
              height: 100%;
              text-align: center;
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .result-name{
            margin-right: 3px;
          }
          .result-unit{
            margin-right: 3px;
          }
        }
        .bottom-right-container{
          flex: 1;
          display: flex;
          flex-direction: row;
          .bottom-button{
            height: 40px;
            color: #ffffff;
            font-size: 1.5rem;
            background: $mobilePrimaryColor;
            border-radius: 5px;
          }
          .save-button{
            flex: 1;
          }
          .submit-button{
            flex: 1;
          }
        }
      }
    }
  }
}

.num-item{
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  .input-button{
    height: 40px;
    color: #ffffff;
    font-size: 1.5rem;
    background: $mobilePrimaryColor;
    border-radius: 5px;
    padding: 0 10px;
  }
  .value-input{
    margin-right: 20px;
    ::v-deep  .el-input__inner {
      height: 40px;
      line-height: 40px;
      font-size: 1.2rem;
    }
  }
}

.close-dialog-button{
  height: 30px;
  color: #ffffff;
  font-size: 1.3rem;
  background: $primaryColor;
  border-radius: 5px;
  padding: 0 10px;
}
</style>
