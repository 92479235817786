var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "row" },
    [
      _vm._v("\n  请选择需要配菜单的角色\n  "),
      _c(
        "el-select",
        {
          attrs: { placeholder: "请选择需要配菜单的角色" },
          on: {
            change: function ($event) {
              return _vm.selectedRole()
            },
          },
          model: {
            value: _vm.roleSelectedId,
            callback: function ($$v) {
              _vm.roleSelectedId = $$v
            },
            expression: "roleSelectedId",
          },
        },
        _vm._l(_vm.roleOptions, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.id },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.menuCardLoading,
              expression: "menuCardLoading",
            },
          ],
          staticStyle: { "margin-top": "20px" },
        },
        [
          _c(
            "el-timeline",
            [
              _c(
                "draggable",
                { attrs: { list: _vm.allMenus } },
                _vm._l(_vm.allMenus, function (menu, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: menu.name,
                      attrs: {
                        icon: "el-icon-arrow-down",
                        "hide-timestamp": true,
                      },
                    },
                    [
                      _c(
                        "el-card",
                        { staticStyle: { "margin-bottom": "10px" } },
                        [
                          menu.children.length > 1 ||
                          (menu.children[0] &&
                            menu.featureName !== menu.children[0].featureName)
                            ? _c(
                                "ht-collapse-pane",
                                {
                                  attrs: {
                                    disabled:
                                      menu.name === "newRemoteGuidanceMenu",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function (ref) {
                                          var activated = ref.activated
                                          return [
                                            !activated
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "可拖动调整节点顺序",
                                                      placement: "bottom",
                                                      effect: "light",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "30px",
                                                        },
                                                      },
                                                      [
                                                        _c("el-image", {
                                                          staticClass:
                                                            "move-icon",
                                                          attrs: {
                                                            src: _vm.movePng,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tag",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              effect: "dark",
                                                              size: "mini",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(index + 1)
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#999999",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "模块原名称：" +
                                                                _vm._s(
                                                                  menu.featureName
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.getNewRemoteGuidanceMenuInfo(
                                                          menu
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#f56c6c",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-warning",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#e6a23c",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.newRemoteGuidanceMenuDisabledInfo
                                                                  ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      height: "40px",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      staticClass: "move-icon",
                                                      attrs: {
                                                        src: _vm.movePng,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-tag",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          effect: "dark",
                                                          size: "mini",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(index + 1)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#999999",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "模块原名称：" +
                                                            _vm._s(
                                                              menu.featureName
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "35%",
                                                      },
                                                      model: {
                                                        value: menu.title,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            menu,
                                                            "title",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "menu.title",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.getNewRemoteGuidanceMenuInfo(
                                                      menu
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f56c6c",
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-warning",
                                                              staticStyle: {
                                                                color:
                                                                  "#e6a23c",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.newRemoteGuidanceMenuDisabledInfo
                                                              ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("el-checkbox", {
                                                      attrs: {
                                                        indeterminate:
                                                          menu.isIndeterminate,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleCheckAllChange(
                                                            menu
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: menu.activated,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            menu,
                                                            "activated",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "menu.activated",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "el-card",
                                    {
                                      staticStyle: { "margin-bottom": "1rem" },
                                      attrs: {
                                        slot: "content",
                                        shadow: "hover",
                                      },
                                      slot: "content",
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: "可拖动调整节点顺序",
                                            placement: "bottom",
                                            effect: "light",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "drag-cursor component-margin",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-striped",
                                                },
                                                [
                                                  _c(
                                                    "thead",
                                                    {
                                                      staticClass: "thead-dark",
                                                    },
                                                    [
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticClass:
                                                            "align-center",
                                                        },
                                                        [
                                                          _c(
                                                            "th",
                                                            {
                                                              attrs: {
                                                                scope: "col",
                                                              },
                                                            },
                                                            [_vm._v("序号")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "th",
                                                            {
                                                              attrs: {
                                                                scope: "col",
                                                              },
                                                            },
                                                            [_vm._v("菜单")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "th",
                                                            {
                                                              attrs: {
                                                                scope: "col",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "模块原名称"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "th",
                                                            {
                                                              attrs: {
                                                                scope: "col",
                                                              },
                                                            },
                                                            [_vm._v("模块所属")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "th",
                                                            {
                                                              attrs: {
                                                                scope: "col",
                                                              },
                                                            },
                                                            [_vm._v("操作")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "draggable",
                                                    {
                                                      attrs: {
                                                        tag: "tbody",
                                                        list: menu.children,
                                                      },
                                                    },
                                                    _vm._l(
                                                      menu.children,
                                                      function (
                                                        secondMenu,
                                                        secondIndex
                                                      ) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: secondIndex,
                                                            staticClass:
                                                              "align-center",
                                                          },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  secondIndex +
                                                                    1
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "50%",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      secondMenu.title,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          secondMenu,
                                                                          "title",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "secondMenu.title",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  secondMenu.featureName
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              [
                                                                secondMenu.isForWeb
                                                                  ? _c(
                                                                      "el-tag",
                                                                      [
                                                                        _vm._v(
                                                                          "WEB"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                secondMenu.isForApp
                                                                  ? _c(
                                                                      "el-tag",
                                                                      {
                                                                        attrs: {
                                                                          type: "success",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "APP"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        menu.name ===
                                                                        "newRemoteGuidanceMenu",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleCheckedCitiesChange(
                                                                            menu
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        secondMenu.activated,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            secondMenu,
                                                                            "activated",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "secondMenu.activated",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "40px",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("el-image", {
                                    staticClass: "move-icon",
                                    attrs: { src: _vm.movePng },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "primary",
                                        effect: "dark",
                                        size: "mini",
                                      },
                                    },
                                    [_vm._v(_vm._s(index + 1))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999999" } },
                                    [
                                      _vm._v(
                                        "模块原名称：" +
                                          _vm._s(menu.featureName)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticStyle: { width: "30%" },
                                    model: {
                                      value: menu.title,
                                      callback: function ($$v) {
                                        _vm.$set(menu, "title", $$v)
                                      },
                                      expression: "menu.title",
                                    },
                                  }),
                                  _vm._v(" "),
                                  menu.isForWeb
                                    ? _c("el-tag", [_vm._v("WEB")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  menu.isForApp
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("APP")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-checkbox", {
                                    staticStyle: { float: "right" },
                                    attrs: {
                                      indeterminate: menu.isIndeterminate,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleCheckAllChange(menu)
                                      },
                                    },
                                    model: {
                                      value: menu.activated,
                                      callback: function ($$v) {
                                        _vm.$set(menu, "activated", $$v)
                                      },
                                      expression: "menu.activated",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitMenuConfig },
                },
                [_vm._v("保存修改")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        { ref: "dialog", attrs: { title: "菜单预览", width: "60%" } },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c(
              "div",
              { staticClass: "app-box" },
              [
                _vm.previewMenus.length > 0
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c(
                            "div",
                            { staticClass: "app-menu" },
                            _vm._l(_vm.previewMenus, function (i) {
                              return _c(
                                "span",
                                {
                                  key: i.name + "preview",
                                  staticClass: "app-menu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickMenu(i)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(i.title) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 18 } }, [
                          _c(
                            "div",
                            { staticClass: "app-second-menu" },
                            _vm._l(_vm.previewSecondMenus, function (j) {
                              return _c(
                                "span",
                                {
                                  key: j.name + "preview",
                                  staticClass: "app-second-menu-item",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(j.title) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c("div", [_vm._v("\n          暂无菜单配置\n        ")]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }