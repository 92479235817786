<template>
  <div
    :id="id + '_video_container'"
    class="video-container"
    :class="[
      `${videoSize}-video-container`,
      `${isRemoteVideo ? 'remote' : 'local'}-${videoSize}-video-container`,
      {'android-video-container': isAndroid,
       'remote-video-container': isRemoteVideo,
       'local-video-container': !isRemoteVideo,
       'fullscreen-mobile-video-container': isFullscreen && isMobile
      }]"
  >
    <div v-if="showTopTools && id !== 'publicScreenVideo'" class="top-tools tools">
      <slot name="topLeft" />
      <slot name="topRight" />
    </div>
    <div class="middle-video-container">
      <img v-if="showImg" :src="isExpert ? Expert: Staff " alt="" class="placeholder-img">
      <video
        v-show="showVideo"
        :id="id"
        :ref="`${id}Ref`"
        class="video"
        :class="[{'is-contain': videoTrack && videoTrack._tag === 'PublicScreen'}]"
        style="object-fit: cover"
      />
      <div v-if="isRemoteVideo && !showImg && !bitRate && !isScreen || isSubscribing" class="no-bit-rate">
        <span class="text">{{ '视频加载中' }}</span>
        <div v-loading="true" class="loading" />
      </div>
      <canvas
        v-if="showFocusCanvas && isAndroid"
        id="focusCanvas"
        class="focus-canvas"
        :class="{'focus-canvas-focused':videoFocused && !showPaintCanvas && !showLaserCanvas}"
        width="50"
        height="50"
      />
      <canvas
        v-if="showPaintCanvas || showLaserCanvas || showRemotePaintCanvas || showRemoteLaserCanvas"
        id="whiteBoardCanvas"
        class="white-board-canvas"
        :width="getVideoWidth"
        :height="getVideoHeight"
        :style="{top: isRemoteVideo ? '40px': 0}"
      />
      <div v-if="noLoginType" class="bottom-tools no-login tools">
        <slot name="bottomLeft" />
      </div>
      <div v-else class="bottom-tools login tools">
        <slot name="bottomLeft" />
        <slot name="bottomRight" />
      </div>
    </div>
  </div>
</template>

<script>
import Expert from '@/assets/image/newRemoteGuidance/expert.png'
import Staff from '@/assets/image/newRemoteGuidance/staff.png'
import LaserPenRed from '@/assets/image/newRemoteGuidance/laser_pen_red.jpg'
import whiteBoard from '@/views/newRemoteGuidance/js/whiteBoard'
import { meetingVideoProperty } from '@/views/newRemoteGuidance/var/businessVar'

export default {
  name: 'PlayerView',
  mixins: [whiteBoard],
  props: {
    id: { type: String, default: '' },
    isScreen: { type: Boolean, default: false },
    videoSize: { type: String, default: 'normal-small' }, // normal-small, normal-large, full-large, full-small
    videoProperty: { type: Object, default: () => {} },
    imUserId: { type: String, default: undefined },
    isExpert: { type: Boolean, default: false },
    isRemoteVideo: { type: Boolean, default: false },
    isShareVideo: { type: Boolean, default: false },
    isRemoteMuted: { type: Boolean, default: false },
    isLargeVideo: { type: Boolean, default: true },
    showTopTools: { type: Boolean, default: false },
    noLoginType: { type: Boolean, default: false },
    videoTrack: { type: Object, default: null },
    showFocusCanvas: { type: Boolean, default: false },
    showPaintCanvas: { type: Boolean, default: false },
    showLaserCanvas: { type: Boolean, default: false },
    showRemotePaintCanvas: { type: Boolean, default: false },
    showRemoteLaserCanvas: { type: Boolean, default: false },
    remotePaintInfo: { type: Object, default: () => {} },
    remoteLaserInfo: { type: Object, default: () => {} },
    isClearPaint: { type: Boolean, default: false },
    isAndroid: { type: Boolean, default: false },
    isCamOn: { type: Boolean, default: true },
    isUvcCamOn: { type: Boolean, default: true },
    bitRate: { type: [Number, String], default: undefined },
    isSubscribing: { type: Boolean, default: false },
    isFullscreen: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false }
  },
  data() {
    return {
      Expert,
      Staff,
      LaserPenRed,
      meetingVideoProperty,
      canvasName: 'whiteBoardCanvas',
      originalRatio: 480 / 848,
      videoHeight: 'auto',
      video: null,
      videoWidthAndHeight: { width: 480, height: 848 },
      videoFocused: false,
      canvas: null,
      canvasCtx: null,
      paintPosition: { lastX: undefined, lastY: undefined, currentX: undefined, currentY: undefined },
      paintTracings: [],
      paintAllTracings: [],
      remoteControlCanvas: null,
      remoteControlCanvasCtx: null,
      paintSendTimer: null,
      laserPosition: { currentX: undefined, currentY: undefined },
      laserTracings: [],
      laserPenImg: undefined,
      laserSendTimer: null,
      remoteLaserTracings: [],
      remotePaintTracings: [],
      remoteLaserPenImg: undefined,
      focusedTimer: null
    }
  },
  computed: {
    showImg() {
      return (!this.isShareVideo || (!this.videoTrack && !this.isSubscribing) || (!this.isCamOn && !this.isUvcCamOn) || (this.isExpert && !this.isRemoteVideo)) && !this.isScreen
    },
    showVideo() {
      return this.isShareVideo && (this.videoTrack || this.isSubscribing) && (this.isCamOn || this.isUvcCamOn) && !(this.isExpert && !this.isRemoteVideo) || this.isScreen
    },
    // 视频裁剪
    getVideoClipPath() {
      if (this.videoWidthAndHeight && this.videoWidthAndHeight.width && this.videoProperty && this.videoProperty.height && this.videoProperty.width) {
        // 0：宽被裁剪，1：正常，2： 高被裁剪
        if (this.getRemoteCutDirection() === 2) {
          const height = meetingVideoProperty.normalLargeVideoWidth * (this.videoProperty.height / this.videoProperty.width)
          const cutHeight = (height - this.getRemoteVideoHeight()) / 2
          return `inset(round(50% - ${cutHeight}px) 0 round(50% - ${cutHeight}px) 0)`
        } else if (this.getRemoteCutDirection() === 0) {
          const width = this.getRemoteCutDirection() / (this.videoProperty.height / this.videoProperty.width)
          const cutWidth = (width - meetingVideoProperty.normalLargeVideoWidth) / 2
          return `inset(round(50% - ${cutWidth}px) 0 round(50% - ${cutWidth}px) 0)`
        } else {
          return 'none'
        }
      } else {
        return 'none'
      }
    },
    getVideoWidth() {
      const video = document.getElementById(this.id)
      if (video) {
        return video.offsetWidth
      } else if (this.isFullscreen && this.isMobile) {
        const container = document.getElementsByClassName('middle-video-container')[0]
        return container.offsetWidth
      } else {
        return meetingVideoProperty.normalLargeVideoWidth
      }
    },
    getVideoHeight() {
      const video = document.getElementById(this.id)
      if (video) {
        return video.offsetHeight
      } else if (this.isFullscreen && this.isMobile) {
        const container = document.getElementsByClassName('middle-video-container')[0]
        return container.offsetHeight
      } else {
        return meetingVideoProperty.normalLargeVideoHeight
      }
    }
  },
  watch: {
    showVideo() {
      if (this.showVideo) {
        this.handleResize()
        if (this.video) { this.video.removeEventListener('click', this.camManualFocused) }
        this.$nextTick(() => {
          this.video = this.showVideo ? document.getElementById(this.id) : null
          if (this.video) { this.video.addEventListener('loadedmetadata', this.getLocalVideoWH) }
          if (this.isAndroid) { this.video.addEventListener('click', this.camManualFocused) }
        })
        if (this.showPaintCanvas) { this.setPaintLine() }
        if (this.showLaserCanvas) { this.setLaserLine() }
      }
    },
    showPaintCanvas: {
      handler() {
        if (this.showPaintCanvas) {
          this.setPaintLine()
        } else {
          this.removePaintLine()
          this.clearCanvas()
        }
      },
      deep: true
    },
    showLaserCanvas: {
      handler() {
        if (this.showLaserCanvas) {
          this.setLaserLine()
        } else {
          this.removeLaserLine()
          this.clearCanvas()
        }
      },
      deep: true
    },
    showRemotePaintCanvas() {
      if (this.showRemotePaintCanvas) {
        this.setRemotePaintLine()
      } else {
        this.clearCanvas()
      }
    },
    showRemoteLaserCanvas() {
      if (this.showRemoteLaserCanvas) {
        this.setRemoteLaserLine()
      } else {
        this.clearCanvas()
      }
    },
    remotePaintInfo() {
      this.remotePaint(this.remotePaintInfo)
    },
    remoteLaserInfo() {
      this.remoteLaser(this.remoteLaserInfo)
    },
    isClearPaint() {
      if (this.isClearPaint) {
        this.clearPaintCanvas()
      }
    },
    videoSize() {
      this.handleResize()
    },
    showImg() {
      this.handleResize()
    },
    isMobile() {
      this.handleResize()
    }
  },
  mounted() {
    if (this.showVideo) {
      if (this.video) { this.video.removeEventListener('click', this.camManualFocused) }
      this.$nextTick(() => {
        this.video = this.showVideo ? document.getElementById(this.id) : null
        if (this.video) { this.video.addEventListener('loadedmetadata', this.getLocalVideoWH) }
        if (this.isAndroid) { this.video.addEventListener('click', this.camManualFocused) }
      })
    }
    this.setPaintLine()
    this.setLaserLine()
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    if (this.video) {
      this.video.removeEventListener('click', this.camManualFocused)
      this.video.removeEventListener('loadedmetadata', this.getLocalVideoWH)
    }
    if (this.showPaintCanvas && this.canvas) {
      if (this.isMobile) {
        this.canvas.removeEventListener('touchstart', this.mobilePaintDown, { passive: true })
        this.canvas.removeEventListener('touchend', this.mobilePaintUp, { passive: true })
        this.canvas.removeEventListener('touchmove', this.mobilePaintMove, { passive: true })
      } else {
        this.canvas.removeEventListener('mousedown', this.paintDown)
        this.canvas.removeEventListener('mousemove', this.paintMove)
        this.canvas.removeEventListener('mouseup', this.paintUp)
      }
    }
    if (this.showLaserCanvas && this.canvas) {
      if (this.isMobile) {
        this.canvas.removeEventListener('touchmove', this.mobileLaserMove, { passive: true })
      } else {
        this.canvas.removeEventListener('mousemove', this.laserMove)
      }
    }
    clearInterval(this.paintSendTimer)
    clearInterval(this.laserSendTimer)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        // document.getElementById(this.id)会拿到所有的video,其中包含了display = none的，display = none不需要进行计算
        const video = document.getElementById(this.id)
        if (video && video.style.display !== 'none') {
          this.getVideoStyle(video)
        }
        if (!this.isMobile) {
          this.getVideoContainerStyle()
        }
        this.repaintCanvas()
        this.$emit('handle-resize')
      })
    },
    // 设置video宽高
    getVideoStyle(video) {
      if (this.videoSize === 'full-large') {
        // 屏幕可视区域宽度大于898并且高度大于700对视频宽高进行计算
        if (window.innerWidth > 898 && window.innerHeight > 700) {
          // 剩余可显示视频位置比例和源视频比例（高比宽）： (window.innerHeight - 160) / (window.innerWidth - 40)
          const fullScreenFreeSpaceRadio = (window.innerHeight - 160) / (window.innerWidth - 40)
          const videoRadio = this.isRemoteVideo ? this.getVideoRadio : 480 / 848
          // 剩余大：代表相比于原视频宽高比大，可显示区域宽不足，则定宽，剩余小，代表高不足，定高
          if (fullScreenFreeSpaceRadio > videoRadio) {
            // 远端视频需要根据传递过来的视频比例进行裁剪显示，本地视频按照9：16比例进行显示
            video.style.width = window.innerWidth - 40 + 'px' //  window.innerWidth - 40可视区域左右各剩余20的间隔
            video.style.height = (window.innerWidth - 40) * videoRadio + 'px'
          } else {
            video.style.height = window.innerHeight - 160 + 'px'
            video.style.width = (window.innerHeight - 160) / videoRadio + 'px'
          }
        } else {
          if (this.isFullscreen && this.isMobile) {
            video.style.width = '100%'
            video.style.height = this.getRemoteVideoHeight() === 'auto' ? 'auto' : this.getRemoteVideoHeight() + 'px'
          } else {
            // 屏幕可视区域宽度小于989或高度小于700显示一个固定大小的视频
            const videoRadio = this.isRemoteVideo ? this.getVideoRadio : 480 / 848
            video.style.width = meetingVideoProperty.normalLargeVideoWidth + 'px'
            video.style.height = meetingVideoProperty.normalLargeVideoWidth * videoRadio + 'px'
          }
        }
        video.style.clipPath = this.getVideoClipPath
      } else if (this.videoSize === 'normal-large') {
        video.style.width = '100%'
        video.style.height = this.getRemoteVideoHeight() === 'auto' ? 'auto' : this.getRemoteVideoHeight() + 'px'
        video.style.clipPath = this.getVideoClipPath
      } else {
        video.style.width = '400px'
        video.style.height = '226px'
      }
      this.setWhiteBoardCanvasStyle()
    },
    getVideoContainerStyle() {
      // 剩余空间高宽比
      const freeSpaceRadio = (window.innerHeight - 160) / (window.innerWidth - 40)
      this.getLocalVideoContainerStyle(freeSpaceRadio)
      this.getRemoteVideoContainerStyle(freeSpaceRadio)
    },
    getLocalVideoContainerStyle(freeSpaceRadio) {
      const videoRadio = 480 / 848
      const localVideoContainers = document.getElementsByClassName('local-full-large-video-container')
      if (localVideoContainers && localVideoContainers.length === 1) {
        const container = localVideoContainers[0]
        if (this.showImg) {
          if (window.innerWidth > 898 && window.innerHeight > 700) {
            if (freeSpaceRadio > videoRadio) {
              container.style.width = window.innerWidth - 40 + 'px'
              container.style.height = (window.innerWidth - 40) * videoRadio + 'px'
            } else {
              container.style.height = window.innerHeight - 160 + 'px'
              container.style.width = (window.innerHeight - 160) / videoRadio + 'px'
            }
          } else {
            const videoRadio = this.isRemoteVideo ? this.getVideoRadio : 480 / 848
            container.style.width = meetingVideoProperty.normalLargeVideoWidth + 'px'
            container.style.height = meetingVideoProperty.normalLargeVideoWidth * videoRadio + 'px'
          }
        } else {
          container.style.width = '100%'
          container.style.height = '100%'
        }
      } else {
        const localVideoContainers = document.getElementsByClassName('local-video-container')
        Object.keys(localVideoContainers).forEach(item => {
          localVideoContainers[item].style.width = '100%'
          localVideoContainers[item].style.height = '100%'
        })
      }
    },
    getRemoteVideoContainerStyle(freeSpaceRadio) {
      const remoteVideoContainers = document.getElementsByClassName('remote-full-large-video-container')
      if (remoteVideoContainers && remoteVideoContainers.length === 1) {
        const container = remoteVideoContainers[0]
        if (this.showImg) {
          if (window.innerWidth > 898 && window.innerHeight > 700) {
            if (freeSpaceRadio > this.getVideoRadio) {
              container.style.width = window.innerWidth - 40 + 'px'
              container.style.height = (window.innerWidth - 40) * this.getVideoRadio + 40 + 'px'
            } else {
              container.style.height = window.innerHeight - 160 + 40 + 'px'
              container.style.width = (window.innerHeight - 160) / this.getVideoRadio + 'px'
            }
          } else {
            const videoRadio = this.isRemoteVideo ? this.getVideoRadio : 480 / 848
            container.style.width = meetingVideoProperty.normalLargeVideoWidth + 'px'
            container.style.height = meetingVideoProperty.normalLargeVideoWidth * videoRadio + 'px'
          }
        } else {
          container.style.width = '100%'
          container.style.height = '100%'
        }
      } else {
        const remoteVideoContainers = document.getElementsByClassName('remote-video-container')
        // normal-large和showImg情况下，不设置宽高会按照内容宽高来撑开
        if (this.videoSize === 'normal-large' && this.showImg) {
          Object.keys(remoteVideoContainers).forEach(item => {
            remoteVideoContainers[item].style.width = '853px'
            // remoteVideoContainers[item].style.height = '520px'
          })
        } else {
          Object.keys(remoteVideoContainers).forEach(item => {
            remoteVideoContainers[item].style.width = 'auto'
            remoteVideoContainers[item].style.height = 'auto'
          })
        }
      }
    },
    setWhiteBoardCanvasStyle() {
      const video = document.getElementById(this.id)
      if (video.clientWidth && video.clientHeight && this.showPaintCanvas || this.showLaserCanvas || this.showRemotePaintCanvas || this.showRemoteLaserCanvas) {
        this.$nextTick(() => {
          const canvas = document.getElementById(this.canvasName)
          canvas.width = video.clientWidth
          canvas.height = video.clientHeight
        })
      }
    },
    // local-video获取本地的video宽高
    getLocalVideoWH() {
      const { videoWidth, videoHeight } = this.video
      const height = meetingVideoProperty.normalLargeVideoWidth / videoWidth * videoHeight
      this.videoWidthAndHeight = { width: meetingVideoProperty.normalLargeVideoWidth, height: height }
      this.$emit('local-video-w-h', this.videoWidthAndHeight)
    },
    // 手动对焦
    camManualFocused(e) {
      // 任意点对焦功能性不强，误触后需要重启摄像头才能恢复对焦，建议两边都去除。 showFocusCanvas=false不启用
      if (this.isLargeVideo && this.showFocusCanvas) {
        this.videoFocused = true
        clearTimeout(this.focusedTimer)
        const canvas = document.getElementById('focusCanvas')
        if (canvas) {
          canvas.style.top = (e.offsetY + 25) + 'px'
          canvas.style.left = (e.offsetX - 25) + 'px'
        }
        const offsets = this.getVideoClickLocation(e.offsetX, e.offsetY)
        if (!this.isScreen && this.isRemoteVideo) {
          const data = {
            offsetX: offsets.x,
            offsetY: offsets.y,
            imUserId: this.imUserId
          }
          this.$emit('cam-manual-focused', data)
        }
        this.focusedTimer = setTimeout(() => {
          this.videoFocused = false
        }, 3000)
      }
    },
    // 获取video点击坐标（被点击用户使用）
    getVideoClickLocation(x, y) {
      const x1 = Math.round(x * this.screenWidthScale * 100) / 100
      const y1 = Math.round(y * this.screenHeightScale * 100) / 100
      return { x: x1, y: y1 }
    },
    // local：设置激光笔
    setLaserLine() {
      this.$nextTick(() => {
        const laserPen = new Image()
        laserPen.src = this.LaserPenRed
        laserPen.onload = () => {
          this.laserPenImg = laserPen
        }
        this.laserTracings = []
        if (document.getElementById(this.canvasName)) {
          this.canvas = document.getElementById(this.canvasName)
          if (this.isMobile) {
            this.canvas.addEventListener('touchmove', this.mobileLaserMove, { passive: false })
          } else {
            this.canvas.addEventListener('mousemove', this.laserMove)
          }
          this.canvasCtx = this.canvas.getContext('2d')
          this.laserSendTimer = setInterval(() => {
            if (this.laserTracings.length > 0) {
              this.getSendLaserTracings()
            }
          }, 250)
        }
      })
    },
    removeLaserLine() {
      clearInterval(this.laserSendTimer)
      this.laserPenImg = null
      this.clearCanvas()
      if (this.canvas) {
        if (this.isMobile) {
          this.canvas.removeEventListener('touchmove', this.mobileLaserMove, { passive: true })
        } else {
          this.canvas.removeEventListener('mousemove', this.laserMove)
        }
      }
    },
    setPaintLine() {
      this.$nextTick(() => {
        this.paintTracings = []
        if (document.getElementById(this.canvasName)) {
          this.canvas = document.getElementById(this.canvasName)
          if (this.isMobile) {
            this.canvas.addEventListener('touchstart', this.mobilePaintDown, { passive: false })
            this.canvas.addEventListener('touchend', this.mobilePaintUp, { passive: false })
          } else {
            this.canvas.addEventListener('mousedown', this.paintDown)
            this.canvas.addEventListener('mouseup', this.paintUp)
          }
          this.canvasCtx = this.canvas.getContext('2d')
          this.canvasCtx.lineWidth = 5
          this.canvasCtx.strokeStyle = 'red'
        }
      })
    },
    removePaintLine() {
      if (this.canvas) {
        if (this.isMobile) {
          this.canvas.removeEventListener('touchstart', this.mobilePaintDown, { passive: true })
          this.canvas.removeEventListener('touchend', this.mobilePaintUp, { passive: true })
          this.canvas.removeEventListener('touchmove', this.mobilePaintMove, { passive: true })
        } else {
          this.canvas.removeEventListener('mousedown', this.paintDown)
          this.canvas.removeEventListener('mouseup', this.paintUp)
          this.canvas.removeEventListener('mousemove', this.paintMove)
        }
      }
      clearInterval(this.paintSendTimer)
    },
    getSenderScreenSize() {
      const video = this.$refs[this.id + 'Ref']
      return { x: video.clientWidth, y: video.clientHeight }
    },
    clearPaintCanvas() {
      this.clearCanvas()
      this.sendPaintMsg(undefined, false)
    },
    // 激光笔（远端控制）
    setRemoteLaserLine() {
      this.$nextTick(() => {
        const laserPen = new Image()
        laserPen.src = this.LaserPen
        laserPen.onload = () => {
          this.laserPenImg = laserPen
        }
        if (document.getElementById(this.canvasName)) {
          this.canvas = document.getElementById(this.canvasName)
          this.canvasCtx = this.canvas.getContext('2d')
        }
      })
    },
    // 画笔设置（远端控制）
    setRemotePaintLine() {
      this.$nextTick(() => {
        this.canvas = document.getElementById(this.canvasName)
        if (this.canvas) {
          this.canvasCtx = this.canvas.getContext('2d')
          this.canvasCtx.lineWidth = 5
          this.canvasCtx.strokeStyle = 'red'
        }
      })
    },
    // 获取发送端和接收端的video比例
    getRemoteAndLocalScale(senderScreenSize) {
      const video = this.$refs[this.id + 'Ref']
      const videoSize = { x: video.clientWidth, y: video.clientHeight }
      const xScale = videoSize.x / senderScreenSize.x
      const yScale = videoSize.y / senderScreenSize.y
      return { x: xScale, y: yScale }
    },
    repaintCanvas() {
      this.$nextTick(() => {
        if (this.canvasCtx) {
          this.canvasCtx.lineWidth = 5
          this.canvasCtx.strokeStyle = 'red'
          if (this.isRemoteVideo) {
            if (this.historyPaintAllTracings.length > 0) {
              this.historyPaintAllTracings.forEach(item => {
                const scale = this.getRemoteAndLocalScale(item.style)
                item.tracings.forEach(trace => {
                  this.paintDrawing(trace.x1 * scale.x, trace.y1 * scale.y, trace.x2 * scale.x, trace.y2 * scale.y)
                })
              })
            }
            if (this.historyRemotePaintAllTracings.length > 0) {
              this.historyRemotePaintAllTracings.forEach(item => {
                item.tracings.forEach(trace => {
                  this.remotePaintDraw(trace.x1, trace.y1, trace.x2, trace.y2, item.style)
                })
              })
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.video-container{
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #efefef;
  .top-tools{
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #2D2D2D81;
  }
  .middle-video-container{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .video {
      //width: calc(50% - 10px);
      height: auto;
    }
    .is-contain{
      object-fit: contain;
    }
    > video {
      overflow: hidden;
      background-color: #efefef;
    }
    .no-bit-rate{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      .text{
        font-size: 20px;
      }
      .loading{
        margin-top: 50px;
        ::v-deep .circular{
          height: 60px;
          width: 60px;
        }
      }
    }
    .focus-canvas{
      position: absolute;
      border: none;
      top: 0;
      left: 0;
    }
    .focus-canvas-focused{
      border: 1px solid #54F834;
    }
    .white-board-canvas {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      z-index: 1;
    }
  }
  .bottom-tools{
    position: absolute;
    bottom: 10px;
    left: 10px;
    height: 35px;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    .no-login{
      justify-content: left;
    }
  }
}

.android-video-container{
  //cursor: pointer;
}

.normal-small-video-container{
  width: 400px !important;
  height: 226px !important;
  .video{
    width: 400px;
    height: 226px;
  }
  .tools{
    position: absolute;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.normal-large-video-container{
  width: 848px;
  min-height: calc(480px + 40px);
  .video{
    width: 848px;
  }
  > video {
    object-fit: cover;
  }
}

.full-small-video-container{
  width: 400px !important;
  height: 226px !important;
  .video{
    width: 100%;
    height: 100%;
  }
  .tools{
    position: absolute;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.full-large-video-container{
  min-width: 848px;
  min-height: calc(480px + 40px);
  .middle-video-container{
    width: 100%;
    .video{
      width: 100%;
    }
    > video {
      object-fit: cover;
    }
  }
}

.local-full-large-video-container{
  min-height: 480px;
}

.remote-video-container{
  display: flex;
  flex-direction: column;
  .top-tools{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .white-board-canvas{
    top: 40px;
  }
}

.local-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #efefef;
  width: 100%;
}

.local-normal-large-video-container{
  min-height: 480px;
}

.normal-small-video-container.fullscreen-mobile-video-container{
  width: 100% !important;
  .video{
    width: 100% !important;
    //aspect-ratio: 848 / 480 !important;
  }
}

.normal-large-video-container.fullscreen-mobile-video-container{
  width: 100% !important;
  .video{
    width: 100% !important;
    //aspect-ratio: 848 / 480 !important;
  }
}

.full-small-video-container.fullscreen-mobile-video-container{
  width: 100% !important;
  height: auto !important;
  .video{
    width: 100% !important;
    //aspect-ratio: 848 / 480 !important;
  }
}
.full-large-video-container.fullscreen-mobile-video-container{
  min-width: 100% !important;
  min-height: auto;
  .video{
    width: 100% !important;
    //height: auto !important;
    //aspect-ratio: 848 / 480 !important;
  }
}

.local-full-large-video-container.fullscreen-mobile-video-container{
  min-width: 100% !important;
  min-height: auto;
  //aspect-ratio: 848 / 480 !important;
  .video{
    width: 100% !important;
    //height: auto !important;
    //aspect-ratio: 848 / 480 !important;
  }
}

.local-normal-large-video-container.fullscreen-mobile-video-container{
  min-width: 100% !important;
  min-height: auto;
  //aspect-ratio: 848 / 480 !important;
}

.fullscreen-mobile-video-container {
  .middle-video-container{
    width: 100%;
  }
}
</style>
