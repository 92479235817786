<script>

// web端主调原生方法
const ANDROID_BRIDGE_NAME = 'AndroidBridge'
const ANDROID_NATIVE_METHODS = {
  getLocation: 'getLocation',
  takePhoto: 'takePhoto',
  captureVideo: 'captureVideo',
  playTTS: 'playTTS',
  startDictation: 'startDictation',
  startRemoteGuidance: 'startRemoteGuidance',
  scanCode: 'scanCode',
  onSubmit: 'onSubmit',
  openAiChat: 'openAiChat',
  startOcr: 'startOcr' // ocr识别
}

// android端回调web方法, 给window增加一层名字空间避免方法污染：AndroidCallback.callbackName()
const ANDROID_CALLBACK_NAME = 'AndroidCallback'
export const ANDROID_WEB_CALLBACKS = {
  onSetLocation: 'onSetLocation',
  onSetPhotoUrl: 'onSetPhotoUrl',
  onSetVideoUrl: 'onSetVideoUrl',
  onSetDictationResult: 'onSetDictationResult',
  onSetScanCodeResult: 'onSetScanCodeResult',
  onSetOcrResult: 'onSetOcrResult' // ocr识别结果
}

const callNativeMethod = (methodName, ...args) => {
  if (window[ANDROID_BRIDGE_NAME] && typeof window[ANDROID_BRIDGE_NAME][methodName] === 'function') {
    window[ANDROID_BRIDGE_NAME][methodName](...args)
  } else {
    console.error(`未定义原生方法: ${ANDROID_BRIDGE_NAME}.${methodName}`)
  }
}

export default {
  name: 'AndroidWebViewMixin',
  data() {
    return {
      CallbackEventMap: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      window[ANDROID_CALLBACK_NAME] = {}
      window[ANDROID_CALLBACK_NAME][ANDROID_WEB_CALLBACKS.onSetLocation] = this.onSetLocation.bind(this)
      window[ANDROID_CALLBACK_NAME][ANDROID_WEB_CALLBACKS.onSetPhotoUrl] = this.onSetPhotoUrl.bind(this)
      window[ANDROID_CALLBACK_NAME][ANDROID_WEB_CALLBACKS.onSetVideoUrl] = this.onSetVideoUrl.bind(this)
      window[ANDROID_CALLBACK_NAME][ANDROID_WEB_CALLBACKS.onSetDictationResult] = this.onSetDictationResult.bind(this)
      window[ANDROID_CALLBACK_NAME][ANDROID_WEB_CALLBACKS.onSetScanCodeResult] = this.onSetScanCodeResult.bind(this)

      window[ANDROID_CALLBACK_NAME][ANDROID_WEB_CALLBACKS.onSetOcrResult] = this.onSetOcrResult.bind(this)
      this.CallbackEventMap = {}
    })
  },
  beforeDestroy() {
    this.unRegisterAndroidCallbackHandlers()
    delete window[ANDROID_CALLBACK_NAME]
  },
  methods: {
    // vue调用原生方法有native前缀
    nativeGetLocation() {
      callNativeMethod(ANDROID_NATIVE_METHODS.getLocation)
    },
    nativeTakePhoto() {
      callNativeMethod(ANDROID_NATIVE_METHODS.takePhoto)
    },
    nativeCaptureVideo() {
      callNativeMethod(ANDROID_NATIVE_METHODS.captureVideo)
    },

    /** @type {function}
     * @param {string} message
     */
    nativePlayTTS(message) {
      callNativeMethod(ANDROID_NATIVE_METHODS.playTTS, message)
    },
    nativeOnSubmit() {
      callNativeMethod(ANDROID_NATIVE_METHODS.onSubmit)
    },
    nativeStartDictation() {
      callNativeMethod(ANDROID_NATIVE_METHODS.startDictation)
    },
    nativeStartRemoteGuidance() {
      callNativeMethod(ANDROID_NATIVE_METHODS.startRemoteGuidance)
    },
    nativeScanCode() {
      callNativeMethod(ANDROID_NATIVE_METHODS.scanCode)
    },
    nativeOpenAiChat() {
      callNativeMethod(ANDROID_NATIVE_METHODS.openAiChat)
    },
    nativeStartOcr(isMultipleMatch, regexRule) {
      const data = {
        isMultipleMatch: isMultipleMatch,
        regexRuleString: regexRule
      }
      callNativeMethod(ANDROID_NATIVE_METHODS.startOcr, JSON.stringify(data))
    },
    // ------------------------------------------------------------------
    // 原生调用web方法被转化为window.AndroidCallback.<func>，调用后发布事件，使用处接收
    /** @type {function}
     * @param {number} latitude
     * @param {number} longitude
     * @param {string} location
     * */
    onSetLocation(latitude, longitude, location) {
      this.$emit(`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetLocation}`, latitude, longitude, location)
    },

    /** @type {function}
     * @param {string} photoUrl
     * */
    onSetPhotoUrl(photoUrl) {
      this.$emit(`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetPhotoUrl}`, photoUrl)
    },

    onSetVideoUrl(videoUrl) {
      this.$emit(`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetVideoUrl}`, videoUrl)
    },

    onSetDictationResult(result) {
      this.$emit(`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetDictationResult}`, result)
    },

    onSetScanCodeResult(result) {
      this.$emit(`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetScanCodeResult}`, result)
    },

    onSetOcrResult(result) {
      this.$emit(`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetOcrResult}`, result)
    },

    // 注册用于原生调用 Web 方法的实际处理程序
    registerAndroidCallbackHandlers({
      locationHandler = (latitude, longitude, location) => {},
      photoUrlHandler = (photoUrl) => {},
      videoUrlHandler = (videoUrl) => {},
      dictationResultHandler = (result) => {},
      scanCodeResultHandler = (result) => {},
      ocrResultHandler = (result) => {}
    }) {
      this.CallbackEventMap[`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetLocation}`] = locationHandler
      this.CallbackEventMap[`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetPhotoUrl}`] = photoUrlHandler
      this.CallbackEventMap[`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetVideoUrl}`] = videoUrlHandler
      this.CallbackEventMap[`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetDictationResult}`] = dictationResultHandler
      this.CallbackEventMap[`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetScanCodeResult}`] = scanCodeResultHandler
      this.CallbackEventMap[`${ANDROID_CALLBACK_NAME}.${ANDROID_WEB_CALLBACKS.onSetOcrResult}`] = ocrResultHandler

      Object.entries(this.CallbackEventMap).forEach(([event, handler]) => {
        // noinspection JSCheckFunctionSignatures
        this.$on(event, handler)
      })
    },

    unRegisterAndroidCallbackHandlers() {
      Object.entries(this.CallbackEventMap).forEach(([event, handler]) => {
        // noinspection JSCheckFunctionSignatures
        this.$off(event, handler)
      })
    }
  }
}
</script>
