var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBox
    ? _c(
        "div",
        {
          ref: "messageBoxRef",
          staticClass: "message-box",
          class: {
            "fullscreen-message-box": _vm.fullscreen,
            "normal-message-box": !_vm.fullscreen,
            "is-mobile-fullscreen-message-box": _vm.isMobile && _vm.fullscreen,
          },
        },
        [
          _c("div", { ref: "topContainerRef", staticClass: "top-container" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s("会议消息" || _vm.chatRoomId + "会议")),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "close-icon",
              attrs: { src: _vm.Close, alt: "" },
              on: {
                click: function ($event) {
                  return _vm.toggleBoxStatus()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "outsideContainerRef",
              staticClass: "middle-container",
              attrs: { id: "chat-message-container" },
            },
            [
              _c(
                "div",
                { ref: "insideContainerRef" },
                [
                  _vm._l(_vm.messagesList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "message-item" },
                      [
                        item.msgType === "DwMeetingJoinOrLeave"
                          ? _c("div", { staticClass: "status-message" }, [
                              _c("div", { staticClass: "time-container" }, [
                                _c("div", { staticClass: "time" }, [
                                  _vm._v(
                                    _vm._s(_vm.parseTime(item.receivedTime, ""))
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content-inner" }, [
                                _vm.isSinoma ||
                                _vm.stringLongOverTen(item.senderUserName)
                                  ? _c("span", { staticClass: "user" }, [
                                      _vm._v(_vm._s("" + item.senderFullName)),
                                    ])
                                  : _c("span", { staticClass: "user" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.senderFullName +
                                            "（" +
                                            item.senderUserName +
                                            "）"
                                        )
                                      ),
                                    ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "action" }, [
                                  _vm._v(_vm._s(item.content)),
                                ]),
                              ]),
                            ])
                          : _c("div", [
                              item.messageDirection === 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-container message-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "time-container" },
                                        [
                                          _c("div", { staticClass: "time" }, [
                                            _vm._v(
                                              _vm._s(
                                                item.sentTime
                                                  ? _vm.parseTime(
                                                      item.sentTime,
                                                      ""
                                                    )
                                                  : item.chatTime
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "right-content-container",
                                        },
                                        [
                                          item.content.senderAvataUrl
                                            ? _c("img", {
                                                staticClass: "avatar",
                                                attrs: {
                                                  src: item.content
                                                    .senderAvataUrl,
                                                  alt: "",
                                                },
                                              })
                                            : _c("dw-default-avatar", {
                                                attrs: {
                                                  "full-name":
                                                    item.content.senderFullName,
                                                  width: 40,
                                                  "font-size": 14,
                                                },
                                              }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "right-box" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "message-content-container",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "arrows right-arrows",
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "box right-content-box",
                                                    },
                                                    [
                                                      item.messageType ===
                                                      "RC:TxtMsg"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "txt-container content-inner",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "content-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .content
                                                                        .content
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              item.content
                                                                .content &&
                                                              !_vm.isHiddenTranslation
                                                                ? [
                                                                    !item.content.hasOwnProperty(
                                                                      "isHideTranslating"
                                                                    ) ||
                                                                    item.content
                                                                      .isHideTranslating
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "click-button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.translate(
                                                                                    index,
                                                                                    item
                                                                                      .content
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "翻译"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "click-button",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.hideTranslate(
                                                                                      index,
                                                                                      item
                                                                                        .content
                                                                                        .content
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "收起翻译"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "loading",
                                                                                    rawName:
                                                                                      "v-loading",
                                                                                    value:
                                                                                      item
                                                                                        .content
                                                                                        .isTranslating,
                                                                                    expression:
                                                                                      "item.content.isTranslating",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "translation",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item
                                                                                    .content
                                                                                    .translation
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          )
                                                        : item.messageType ===
                                                          "RC:ImgMsg"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "img-container content-inner",
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                staticClass:
                                                                  "content-img",
                                                                attrs: {
                                                                  src: item
                                                                    .content
                                                                    .imageUri,
                                                                  "preview-src-list":
                                                                    [
                                                                      item
                                                                        .content
                                                                        .imageUri,
                                                                    ],
                                                                  fit: "contain",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "click-button",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadFile(
                                                                          item
                                                                            .content
                                                                            .imageUri
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "下载原图"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : item.messageType ===
                                                          "RC:FileMsg"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "file-container content-inner",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "content-file",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "file-icon",
                                                                    attrs: {
                                                                      src: _vm.Pdf,
                                                                      alt: "",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "file-name",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.downloadFile(
                                                                              item
                                                                                .content
                                                                                .fileUrl
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "click-button",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadFile(
                                                                          item
                                                                            .content
                                                                            .fileUrl
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "下载文件"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : item.messageDirection === 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "left-container message-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "time-container" },
                                        [
                                          _c("div", { staticClass: "time" }, [
                                            _vm._v(
                                              _vm._s(
                                                item.sentTime
                                                  ? _vm.parseTime(
                                                      item.receivedTime,
                                                      ""
                                                    )
                                                  : item.chatTime
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "left-content-container",
                                        },
                                        [
                                          item.content.senderAvataUrl
                                            ? _c("img", {
                                                staticClass: "avatar",
                                                attrs: {
                                                  src: item.content
                                                    .senderAvataUrl,
                                                  alt: "",
                                                },
                                              })
                                            : _c("dw-default-avatar", {
                                                attrs: {
                                                  "full-name":
                                                    item.content.senderFullName,
                                                  width: 40,
                                                  "font-size": 14,
                                                },
                                              }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "left-box" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "full-name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.content
                                                        .senderFullName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "message-content-container",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "arrows left-arrows",
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "box left-content-box",
                                                    },
                                                    [
                                                      item.messageType ===
                                                      "RC:TxtMsg"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "txt-container content-inner",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "content-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .content
                                                                        .content
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              item.content
                                                                .content &&
                                                              !_vm.isHiddenTranslation
                                                                ? [
                                                                    !item.content.hasOwnProperty(
                                                                      "isHideTranslating"
                                                                    ) ||
                                                                    item.content
                                                                      .isHideTranslating
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "click-button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.translate(
                                                                                    index,
                                                                                    item
                                                                                      .content
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "翻译"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "click-button",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.hideTranslate(
                                                                                      index,
                                                                                      item
                                                                                        .content
                                                                                        .content
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "收起翻译"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "loading",
                                                                                    rawName:
                                                                                      "v-loading",
                                                                                    value:
                                                                                      item
                                                                                        .content
                                                                                        .isTranslating,
                                                                                    expression:
                                                                                      "item.content.isTranslating",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "translation",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item
                                                                                    .content
                                                                                    .translation
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          )
                                                        : item.messageType ===
                                                          "RC:ImgMsg"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "img-container content-inner",
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                staticClass:
                                                                  "content-img",
                                                                attrs: {
                                                                  src: item
                                                                    .content
                                                                    .imageUri,
                                                                  "preview-src-list":
                                                                    [
                                                                      item
                                                                        .content
                                                                        .imageUri,
                                                                    ],
                                                                  fit: "contain",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "click-button",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadFile(
                                                                          item
                                                                            .content
                                                                            .imageUri
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "下载原图"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : item.messageType ===
                                                          "RC:FileMsg"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "file-container content-inner",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "content-file",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "file-icon",
                                                                    attrs: {
                                                                      src: _vm.Pdf,
                                                                      alt: "",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "file-name",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.downloadFile(
                                                                              item
                                                                                .content
                                                                                .fileUrl
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "click-button",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadFile(
                                                                          item
                                                                            .content
                                                                            .fileUrl
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "下载文件"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.isLoading
                    ? _c("div", { staticClass: "is-sending" }, [
                        _c("div", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.isLoading,
                              expression: "isLoading",
                            },
                          ],
                          staticClass: "send-loading",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "text" }, [_vm._v("发送中")]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c(
                "div",
                { staticClass: "upload-container" },
                [
                  _c("img", {
                    staticClass: "folder-icon",
                    attrs: { src: _vm.FileChoose, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("ht-upload-button", {
                    staticClass: "upload-button",
                    attrs: {
                      "button-icon": null,
                      "show-success-tip": false,
                      "file-type": ["img", "pdf"],
                      "key-prefix": "new_remote_guidance/" + _vm.roomId,
                      "multiple-selection": true,
                    },
                    on: {
                      "on-upload-start": function ($event) {
                        _vm.sendingCount++
                      },
                      "on-upload-finish": function ($event) {
                        _vm.sendingCount--
                      },
                      "on-files-uploaded": _vm.onFilesUploaded,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "content-input",
                attrs: { placeholder: "请输入内容" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.sendMsg($event)
                  },
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "send-button",
                  class: { "disabled-button": !_vm.content },
                  on: { click: _vm.sendMsg },
                },
                [_vm._v("发送")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }