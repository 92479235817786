<template>
  <div>
    <ht-action-panel :on-search="getList" :on-clear="onClear">
      <template slot="left">
        <el-select v-model="listQuery.constructionEquipmentName" placeholder="设备名称">
          <el-option v-for="(item,index) in EquipmentTypesEnum" :key="index" :label="item.name" :value="item.value" />
        </el-select>
        <el-input v-model="listQuery.constructionEquipmentCode" placeholder="产权编号" />
        <el-input v-model="listQuery.constructionEquipmentSpecModel" placeholder="规格型号" />
        <el-select v-model="listQuery.isWhole" placeholder="是否整机">
          <el-option v-for="(item,index) in EquipmentIsWholeEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="equipmentList">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="item in WarehouseEquipmentStockListTitle" :key="item.prop" :label="item.label" :prop="item.prop" :min-width="item.width || 100" :fixed="item.fixed">
        <template v-slot="{row}">
          <span v-if="item.label === '是否整机'">{{ row[item.prop] ? '是': '否' }}</span>
          <span v-else-if="item.label === '仓库库存数'">{{ row[item.prop] }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation width="100">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-truck" @click="onViewLogistics(row)">物流跟踪</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>

    <ht-dialog ref="logisticsTraceDialog" title="物流跟踪" width="800px" @close="closeLogisticsTraceDialog">
      <div slot="content">
        <logistics-trace v-if="showLogisticsTrace" ref="logisticsTrace" id-name="constructionEquipmentId" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import LogisticsTrace from '@/views/constructionEquipmentMgr/stockMgr/components/logisticsTrace'
import {
  EquipmentIsWholeEnum,
  EquipmentLeaseStatusEnum,
  EquipmentLeaseStatusStyleEnum,
  EquipmentTypesEnum
} from '@/views/constructionEquipmentMgr/enum'
import { WarehouseEquipmentStockListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getWarehouseStock } from '@/api/constructionEquipmentMgr/stockMgr'

export default {
  name: 'WarehouseStockInfo',
  components: { LogisticsTrace },
  data() {
    return {
      EquipmentTypesEnum,
      EquipmentLeaseStatusEnum, EquipmentLeaseStatusStyleEnum, EquipmentIsWholeEnum,
      WarehouseEquipmentStockListTitle,
      showLogisticsTrace: false,
      warehouseInfo: null,
      equipmentList: [],
      isLoading: false,
      listQuery: {
        constructionEquipmentName: null,
        constructionEquipmentCode: null,
        constructionEquipmentSpecModel: null
      }
    }
  },
  methods: {
    initInfo(warehouseInfo) {
      this.warehouseInfo = warehouseInfo
      this.getList()
    },
    onClear() {
      Object.keys(this.listQuery).forEach(el => {
        delete this.listQuery[el]
      })
      this.getList()
    },
    getList() {
      this.isLoading = true
      getWarehouseStock(this.warehouseInfo.id, this.listQuery).then(resp => {
        this.equipmentList = resp
        this.isLoading = false
      }).catch(() => { this.isLoading = false })
    },
    onViewLogistics(row) {
      this.showLogisticsTrace = true
      this.$refs.logisticsTraceDialog.dialogVisible = true
      this.$nextTick(() => {
        row.name = row.constructionEquipmentName
        row.code = row.constructionEquipmentCode
        row.specModel = row.constructionEquipmentSpecModel
        this.$refs.logisticsTrace.initInfo(row)
      })
    },
    closeLogisticsTraceDialog() {
      this.showLogisticsTrace = false
      this.$refs.logisticsTraceDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped>

</style>
