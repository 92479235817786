<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="getProject">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.value" :name="item.name" />
    </el-tabs>

    <template v-if="activeName === 'now'">
      <div v-if="!isView" class="title">
        {{ preAddEquipmentList && preAddEquipmentList.length > 0 ? '本次添加设备：' : '' }}
        <ht-button type="success" icon="el-icon-plus" @click="openAddEquipmentDialog">添加租用设备</ht-button>
      </div>
      <template v-if="preAddEquipmentList && preAddEquipmentList.length > 0">
        <equipment-list
          ref="addedEquipmentList"
          :show-del="!isView"
          :table-title="EquipmentAddedListTitle"
          :equipment-list="preAddEquipmentList"
          style="margin-bottom: 20px"
          @on-delete="onDeleteEquipment"
        />

        <div slot="footer" class="dialog-footer" style="text-align: center">
          <ht-button icon="el-icon-close" @click="cancelSubmit">取消</ht-button>
          <ht-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit()">保存</ht-button>
        </div>
      </template>

      <div class="title">工程现存设备：</div>
      <equipment-list
        ref="equipmentInProjectList"
        :show-stop="!isView"
        :show-edit="!isView"
        :table-title="EquipmentHasStockListTitle"
        :equipment-list="projectForm.equipmentStockItems"
        style="margin-bottom: 20px"
        @on-stop="onStopLeaseEquipment"
        @on-edit="openModifyExecuteCodeDialog"
      />
    </template>

    <template v-else>
      <div class="title">历史租用记录：</div>
      <equipment-list
        ref="leaseHistoryEquipmentList"
        :table-title="EquipmentUsedListTitle"
        :equipment-list="projectForm.equipmentItems"
        style="margin-bottom: 20px"
      />
    </template>

    <ht-dialog ref="addEquipmentDialog" title="添加设备" :width="isMobile? '100%' : '800px'" @close="closeAddEquipmentDialog">
      <div slot="content">
        <add-equipment-list v-if="showAddEquipmentList" ref="addEquipmentList" :already-add-list="preAddEquipmentList" style="margin-bottom: 20px" @on-add-equipments="onAddEquipments" />
      </div>
    </ht-dialog>

    <ht-dialog ref="transshipmentDialog" title="转移设备" :width="isMobile? '500px' : '500px'" @close="closeTransshipmentDialog">
      <div slot="content">
        <transshipment-form v-if="showTransshipmentForm" ref="transshipmentForm" @cancel-submit="closeTransshipmentDialog" @confirm-submit="closeTransshipmentDialog(true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="modifyExecuteCodeDialog" title="修改执行代码" :width="isMobile? '400px' : '400px'" @close="closeModifyExecuteCodeDialog">
      <div slot="content">
        <modify-execute-code-form v-if="showModifyExecuteCodeForm" ref="modifyExecuteCodeForm" @cancel-submit="closeModifyExecuteCodeDialog" @confirm-submit="closeModifyExecuteCodeDialog(true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="stopLeaseDialog" title="结束租用" :width="isMobile? '400px' : '400px'" @close="closeStopLeaseDialog">
      <div slot="content">
        <stop-lease-form v-if="showStopLeaseForm" ref="stopLeaseForm" @cancel-submit="closeStopLeaseDialog" @confirm-submit="closeStopLeaseDialog(true)" />
      </div>
    </ht-dialog>
  </div>
</template>

<script>
import EquipmentList from '@/views/constructionProjectMgr/projectMgr/components/equipmentList'
import AddEquipmentList from '@/views/constructionProjectMgr/projectMgr/components/addEquipmentList'
import TransshipmentForm from '@/views/constructionProjectMgr/projectMgr/components/transshipmentForm'
import ModifyExecuteCodeForm from '@/views/constructionProjectMgr/projectMgr/components/modifyExecuteCodeForm'
import StopLeaseForm from '@/views/constructionProjectMgr/projectMgr/components/stopLeaseForm'
import { postProjectEquipments, getProjectStopLease, getProject } from '@/api/constructionProjectMgr/projectMgr'
import { EquipmentUsedListTitle, EquipmentAddedListTitle, EquipmentHasStockListTitle } from '@/views/constructionProjectMgr/projectMgr/tableTitle'
import { mapGetters } from 'vuex'

export default {
  name: 'LeaseMgr',
  components: { EquipmentList, AddEquipmentList, TransshipmentForm, ModifyExecuteCodeForm, StopLeaseForm },
  props: {
    isView: { type: Boolean, default: false },
    projectId: { type: [String, Number], default: undefined }
  },
  data() {
    return {
      EquipmentAddedListTitle, EquipmentHasStockListTitle, EquipmentUsedListTitle,
      preAddEquipmentList: [],
      tabs: [
        { name: 'now', value: '租用设备' },
        { name: 'history', value: '历史租用记录' }
      ],
      activeName: 'now',
      showAddEquipmentList: false,
      projectForm: {
        code: null,
        name: null,
        contactName: null,
        contactMobile: null,
        province: null,
        city: null,
        district: null,
        address: null,
        attachmentData: [],
        equipmentItems: [],
        equipmentStockItems: []
      },
      isSubmitting: false,
      projectData: null,
      showTransshipmentForm: false,
      showModifyExecuteCodeForm: false,
      showStopLeaseForm: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    getProject() {
      getProject(this.projectId).then(resp => {
        this.projectId = resp.id
        this.projectData = resp
        this.getProjectInfo(resp)
      })
    },
    getProjectInfo(projectData) {
      this.projectData = projectData
      Object.keys(this.projectForm).forEach(key => {
        if (key === 'province' || key === 'city' || key === 'district') {
          this.projectForm[key] = projectData[key] || null
        } else {
          this.projectForm[key] = projectData[key] || ''
        }
      })
    },
    openAddEquipmentDialog() {
      this.showAddEquipmentList = true
      this.$refs.addEquipmentDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.addEquipmentList.getList()
      })
    },
    closeAddEquipmentDialog() {
      this.showAddEquipmentList = false
      this.$refs.addEquipmentDialog.dialogVisible = false
    },
    onAddEquipments(equipments) {
      this.preAddEquipmentList = [...this.preAddEquipmentList, ...equipments]
      this.closeAddEquipmentDialog()
    },
    onDeleteEquipment(index) {
      this.preAddEquipmentList.splice(index, 1)
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      const allItemsHaveExecuteCode = this.preAddEquipmentList.filter(item => item.constructionEquipmentIsWhole).every(item => {
        return item && item.executeCode
      })
      const allItemsHaveLeaseQuantity = this.preAddEquipmentList.every(item => {
        return item && item.leaseQuantity > 0
      })
      if (!allItemsHaveExecuteCode && !allItemsHaveLeaseQuantity) {
        this.$message({ message: `请填写所选设备的租用数量和执行代码`, duration: 1500, type: 'error' })
      } else if (!allItemsHaveLeaseQuantity) {
        this.$message({ message: `请填写所选设备的租用数量`, duration: 1500, type: 'error' })
      } else if (!allItemsHaveExecuteCode) {
        this.$message({ message: `请填写所选设备的执行代码`, duration: 1500, type: 'error' })
      } else if (allItemsHaveExecuteCode && allItemsHaveLeaseQuantity) {
        const data = {
          equipmentItems: this.preAddEquipmentList.map(item => {
            return {
              constructionEquipmentId: item.constructionEquipmentId,
              constructionEquipmentCode: item.constructionEquipmentCode,
              constructionEquipmentName: item.constructionEquipmentName,
              executeCode: item.executeCode,
              isWhole: item.constructionEquipmentIsWhole,
              quantity: item.leaseQuantity,
              warehouseId: item.warehouseId,
              warehouseName: item.warehouseName
            }
          })
        }
        this.isSubmitting = true
        postProjectEquipments(this.projectId, data).then(resp => {
          this.$message({ message: '保存成功', type: 'success' })
          this.preAddEquipmentList = []
          // this.$emit('confirm-submit')
          this.getProject()
          this.isSubmitting = false
        }).catch(() => { this.isSubmitting = false })
      }
    },
    openTransshipmentDialog(row) {
      this.showTransshipmentForm = true
      this.$refs.transshipmentDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.transshipmentForm.getInfo(row, this.projectId)
      })
    },
    closeTransshipmentDialog(isRefresh = false) {
      this.showTransshipmentForm = false
      this.$refs.transshipmentDialog.dialogVisible = false
      if (isRefresh) {
        this.getProject()
      }
    },
    openModifyExecuteCodeDialog(index) {
      this.showModifyExecuteCodeForm = true
      this.$refs.modifyExecuteCodeDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.modifyExecuteCodeForm.getInfo(this.projectForm.equipmentStockItems[index], this.projectId)
      })
    },
    closeModifyExecuteCodeDialog(isRefresh = false) {
      this.showModifyExecuteCodeForm = false
      this.$refs.modifyExecuteCodeDialog.dialogVisible = false
      if (isRefresh) {
        this.getProject()
      }
    },
    onStopLeaseEquipment(index) {
      const equipment = this.projectForm.equipmentStockItems[index]
      const query = {
        constructionEquipmentIds: [equipment.constructionEquipmentId].toString()
      }
      getProjectStopLease(this.projectId, query).then(resp => {
        if (resp.isHaveLoopWorkOrders) {
          // 设备有循环工单，提示：该设备已创建循环工单，结束租用后工单不再循环创建，请确认是否结束租用？
          this.$confirm('该设备已创建循环工单，结束租用后工单不再循环创建，请确认是否结束租用？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            this.openStopLeaseDialog(equipment)
          }).catch(() => {})
        } else {
          this.openStopLeaseDialog(equipment)
        }
      })
    },
    openStopLeaseDialog(equipmentInfo) {
      this.showStopLeaseForm = true
      this.$refs.stopLeaseDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.stopLeaseForm.getInfo(equipmentInfo, this.projectId)
      })
    },
    closeStopLeaseDialog(isRefresh = false) {
      this.showStopLeaseForm = false
      this.$refs.stopLeaseDialog.dialogVisible = false
      if (isRefresh) {
        this.getProject()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.list-form-item{
  ::v-deep.el-form-item__content{
    text-align: right;
  }
}
.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 20px;
  font-weight: 500;
  color: $primaryColor;
  position: relative;
  .el-button{
    position: absolute;
    right: 0;
  }
}
</style>
