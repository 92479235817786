<template>
  <el-card v-loading="isLoading" style="min-height: 400px">
    <equipment-form
      v-if="equipmentData"
      is-view
      :is-edit="isEdit === '1' || isEdit === 1"
      :equipment-id="equipmentData.id"
      :equipment-data="equipmentData"
      @cancel-submit="cancelSubmit"
      @confirm-submit="confirmSubmit"
    />
    <el-form label-width="90px" style="margin-top: 18px">
      <el-form-item label="租用详情" />
    </el-form>
    <lease-detail-list :lease-detail-list="equipmentData && equipmentData.rentalDetails" />
  </el-card>
</template>

<script>
import EquipmentForm from '@/views/constructionEquipmentMgr/equipmentMgr/components/equipmentForm'
import LeaseDetailList from '@/views/constructionEquipmentMgr/equipmentMgr/components/leaseDetailList'
import { getEquipment } from '@/api/constructionEquipmentMgr/equipmentMgr'

export default {
  name: 'EquipmentDetail',
  components: { EquipmentForm, LeaseDetailList },
  props: {
    equipmentId: { type: [String, Number], default: undefined },
    isEdit: { type: [String, Number], default: 0 }
  },
  data() {
    return {
      equipmentData: null,
      isLoading: false
    }
  },
  created() {
    this.getEquipmentDetail()
  },
  methods: {
    getEquipmentDetail() {
      this.isLoading = true
      getEquipment(this.equipmentId).then(resp => {
        this.isLoading = false
        this.equipmentData = resp
      }).catch(() => {
        this.isLoading = false
      })
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    confirmSubmit() {
      // this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.el-form-item{
  ::v-deep .el-form-item__label{
    color:#000;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
