var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "workflow-steps-container" },
    [
      _vm._l(_vm.checks, function (item, index) {
        return [
          _c(
            "transition",
            { key: index, attrs: { name: _vm.transitionName } },
            [
              _vm.currentCheckIndex === index
                ? _c("quick-patrol-record-type", {
                    key: index,
                    staticClass: "component",
                    attrs: {
                      count: _vm.count,
                      "current-index": _vm.currentIndex,
                      "is-last-step": _vm.isLastStep,
                      "check-count": _vm.checks.length,
                      "current-check-index": _vm.currentCheckIndex,
                      "is-last-check": index === _vm.checks.length - 1,
                      results: item,
                    },
                    on: {
                      "to-prev": function ($event) {
                        return _vm.handleActions("to-prev")
                      },
                      "to-next": _vm.toNext,
                      "jump-step": _vm.jumpStep,
                      "to-remote-guidance": function ($event) {
                        return _vm.handleActions("to-remote-guidance")
                      },
                      "to-ai-qa-assistant": function ($event) {
                        return _vm.handleActions("to-ai-qa-assistant")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }