// 通用记录 UniversalRecordType
export const UniversalRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

// 判断结果 ResultRecordType
export const ResultRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

// 记录数值 ValueRecordType
export const ValueRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }],
  dataName: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
  minValue: [
    { required: true, message: '请输入参考下限', trigger: 'blur' },
    { trigger: 'blur' }
  ],
  maxValue: [
    { required: true, message: '请输入参考上限', trigger: 'blur' }
  ],
  dataUnit: [{ required: true, message: '请输入数据单位', trigger: 'blur' }]
}

// 隐患登记 HiddenDangerRecordType
export const HiddenDangerRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

// 事故登记 AccidentRecordType
export const AccidentRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

// 事故登记 PhotoRecordType
export const PhotoRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

// 快速巡检 QuickPatrolRecordType
export const QuickPatrolRecordTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

// 扫码确认 ScanCodeAndConfirmType
export const ScanCodeAndConfirmTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }]
}

// 语音记录数值 VoiceRecordValueType
export const VoiceRecordValueTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }],
  dataName: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
  minValue: [
    { required: true, message: '请输入参考下限', trigger: 'blur' },
    { trigger: 'blur' }
  ],
  maxValue: [
    { required: true, message: '请输入参考上限', trigger: 'blur' }
  ],
  dataUnit: [{ required: true, message: '请输入数据单位', trigger: 'blur' }]
}

// OCR记录数值 OCRRecordValueType
export const OCRRecordValueTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }],
  dataName: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
  minValue: [
    { required: true, message: '请输入参考下限', trigger: 'blur' },
    { trigger: 'blur' }
  ],
  maxValue: [
    { required: true, message: '请输入参考上限', trigger: 'blur' }
  ],
  dataUnit: [{ required: true, message: '请输入数据单位', trigger: 'blur' }]
}

// 蓝牙记录数值 KeyboardRecordValueType
export const KeyboardRecordValueTypeRules = {
  title: [{ required: true, message: '请输入步骤名称', trigger: 'blur' }],
  dataName: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
  minValue: [
    { required: true, message: '请输入参考下限', trigger: 'blur' },
    { trigger: 'blur' }
  ],
  maxValue: [
    { required: true, message: '请输入参考上限', trigger: 'blur' }
  ],
  dataUnit: [{ required: true, message: '请输入数据单位', trigger: 'blur' }]
}
