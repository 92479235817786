var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "a-scene",
        {
          attrs: {
            "mindar-image": { imageTargetSrc: _vm.mindSrc, autoStart: false },
            "color-space": "sRGB",
            renderer: "colorManagement: true, physicallyCorrectLights",
            "vr-mode-ui": "enabled: false",
            "device-orientation-permission-ui": "enabled: false",
          },
        },
        [
          _c("a-camera", {
            attrs: {
              position: "0 0 0",
              "look-controls": "enabled: false",
              cursor: "fuse: false; rayOrigin: mouse;",
              raycaster: "near: 10; far: 10000; objects: .clickable",
            },
          }),
          _vm._v(" "),
          _c(
            "a-entity",
            {
              attrs: {
                id: "example-target",
                "mindar-image-target": "targetIndex: 0",
              },
            },
            [
              _c("a-text", {
                staticClass: "example-text",
                attrs: {
                  id: "example-text",
                  value: _vm.dynamicText,
                  color: "black",
                  align: "center",
                  width: "2",
                  position: "0 0.4 0",
                  text: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", "z-index": "1000" } },
      [
        _c("button", { attrs: { id: "example-start-button" } }, [
          _vm._v("Start"),
        ]),
        _vm._v(" "),
        _c("button", { attrs: { id: "example-pause-button" } }, [
          _vm._v("Pause"),
        ]),
        _vm._v(" "),
        _c("button", { attrs: { id: "example-pause-keep-video-button" } }, [
          _vm._v("Pause (keep video)"),
        ]),
        _vm._v(" "),
        _c("button", { attrs: { id: "example-unpause-button" } }, [
          _vm._v("UnPause"),
        ]),
        _vm._v(" "),
        _c("button", { attrs: { id: "example-stop-button" } }, [
          _vm._v("Stop"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }