<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClearSearch">
      <template slot="left">
        <el-input v-model="listQuery.roomId" placeholder="会议ID" />
        <el-input v-model="listQuery.staffName" placeholder="发起人" />
        <el-date-picker
          v-model="createdAt"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          placement="bottom-start"
          start-placeholder="创建时间"
          on-search
          end-placeholder="创建时间"
        />
        <el-select v-model="listQuery.status" placeholder="会议状态">
          <el-option
            v-for="item in statusEnum"
            :key="item.name"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </template>
    </ht-action-panel>
    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer" @row-click="handleRowClick">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="i in labelToProps" :key="i.prop" :label="i.label" :prop="i.prop" :min-width="i.width || 100">
        <template v-slot="{row}">
          <span v-if="i.label === '发起人'">{{ row.staff.fullName }}</span>
          <span v-else-if="i.label === '会议时常'">{{ row.status.name === 'FINISHED' ? row.duration || '-' : '-' }}</span>
          <el-tag v-else-if="i.label === '会议状态'" :type="getStatus(row.status.name) && getStatus(row.status.name).style">
            {{ getStatus(row.status.name) && getStatus(row.status.name).value || '-' }}
          </el-tag>
          <span v-else>{{ row[i.prop] }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="120">
        <template v-slot="{row}">
          <ht-button v-if="row.status.name === 'FINISHED'" type="text" icon="el-icon-view" @click="onView(row)">详情</ht-button>
          <ht-button
            v-else-if="userInfo && (userInfo.roles.includes('ADMIN') || userInfo.roles.includes('EXPERT')) "
            :disabled="row.status.name === 'CREATING'"
            type="text"
            icon="el-icon-plus"
            @click="joinRoom(row)"
          >加入会议</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
    <meeting-dialog
      v-if="showMeetingDialog"
      ref="meetingDialogRef"
      @close-dialog="closeDialog"
    />
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getMeeting, getMeetingList, putKickImUser } from '@/api/newRemoteGuidance/newRemoteGuidance'
import MeetingDialog from '../components/meeting'
import { mapGetters } from 'vuex'
import { EventsEnum } from '@/views/newRemoteGuidance/var/eventsVar'
import { MeetingErrorMsg } from '@/views/newRemoteGuidance/var/businessVar'
import store from '@/store'

export default {
  name: 'Index',
  components: { MeetingDialog },
  extends: HtListContainer,
  props: {
    roomId: { type: String, default: null },
    chatRoomId: { type: String, default: null },
    senderId: { type: String, default: null },
    senderFullName: { type: String, default: null },
    senderUserName: { type: String, default: null },
    isByLink: { type: [String, Number], default: 0 }
  },
  data() {
    return {
      createdAt: [],
      labelToProps: [
        { label: '会议ID', prop: 'roomId' },
        { label: '发起人', prop: 'staff.fullName' },
        { label: '创建时间', prop: 'startAt' },
        { label: '会议时长', prop: 'duration' },
        { label: '会议状态', prop: 'status' }
      ],
      showMeetingDialog: false,
      statusEnum: [
        // { name: 'CREATING', value: '创建中', style: 'primary' },
        { name: 'PROCESSING', value: '进行中', style: 'success' },
        { name: 'FINISHED', value: '已结束', style: 'info' }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'userImInfo'])
  },
  created() {
    this.getList()
    this.addEventListener()
    if (this.isByLink === '1' || this.isByLink === 1) {
      this.$nextTick(() => {
        this.handleInviteByLink()
      })
    }
  },
  beforeDestroy() {
    this.removeEventListener()
  },
  methods: {
    addEventListener() {
      document.addEventListener(EventsEnum.meetingRefreshEvent, this.getList)
    },
    removeEventListener() {
      document.removeEventListener(EventsEnum.meetingRefreshEvent, this.getList)
    },
    onClearSearch() {
      this.createdAt = []
      this.onClear()
    },
    getList() {
      this.beforeGetList()
      this.listQuery.startAt = this.createdAt[0]
      this.listQuery.endAt = this.createdAt[1]
      getMeetingList(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      }).catch(err => { this.afterGetList(null, err) })
    },
    getStatus(status) {
      const index = this.statusEnum.findIndex(item => item.name === status)
      if (index !== -1) {
        return this.statusEnum[index]
      } else {
        return ''
      }
    },
    joinRoom(row) {
      this.showMeetingDialog = true
      this.$nextTick(() => {
        getMeeting(row.roomId).then(res => {
          if (res.status && res.status.name === 'PROCESSING') {
            const roomId = res.roomId
            this.$refs.meetingDialogRef.openDialog(this.userInfo, this.userImInfo)
            this.$refs.meetingDialogRef.joinRoom(roomId, row.chatRoomId, false).catch(async(firstError) => {
              if (firstError.reason === '当前环境无法开启会议') {
                await this.joinFailFun(MeetingErrorMsg.joinRoomPermissionError, false)
              } else if (firstError.error && firstError.error.code && firstError.error.code === 53010) {
                await this.joinFailFun(MeetingErrorMsg.getMediaPermissionError)
              } else {
                putKickImUser(this.userImInfo.imUserId).then(() => {
                  this.$refs.meetingDialogRef.joinRoom(roomId, row.chatRoomId, false).catch(async(secondError) => {
                    if (secondError.reason === '加入聊天室失败') {
                      await this.joinFailFun(MeetingErrorMsg.joinChatRoomError)
                    } else if (secondError.error === 50002) {
                      this.$message({ message: MeetingErrorMsg.joinMeetingRepeat, center: true, type: 'error' })
                    } else {
                      await putKickImUser(this.userImInfo.imUserId)
                      this.$message({ message: MeetingErrorMsg.confirmMeetingStatus, center: true, type: 'error' })
                    }
                    this.$refs.meetingDialogRef.closeDialog()
                  })
                }).catch(() => {
                  this.$message({ message: MeetingErrorMsg.joinMeetingError, center: true, type: 'error' })
                  this.$refs.meetingDialogRef.closeDialog()
                })
              }
            })
          } else {
            this.$message({ message: MeetingErrorMsg.meetingFinishRefresh, center: true, type: 'error' })
            this.getList()
          }
        })
      })
    },
    async joinFailFun(errorMsg, isJoin = true) {
      this.$message({ message: errorMsg, center: true, type: 'error' })
      setTimeout(async() => {
        if (isJoin) {
          putKickImUser(this.userImInfo.imUserId)
        }
        await this.$refs.meetingDialogRef.closeDialog()
      }, 1000)
    },
    closeDialog() {
      this.showMeetingDialog = false
      this.getList()
    },
    handleRowClick(row, column) {
      if (column.label !== '操作' && row.status.name === 'FINISHED') {
        this.onView(row)
      }
    },
    onView(row) {
      const currentRouter = this.$router.currentRoute
      if (currentRouter.path.includes('newRemoteGuidance/externalCall/sinoma')) {
        this.$router.push({ name: 'externalCallSinomaMeetingRecordsView', params: {
          roomId: row.roomId, sessionKey: currentRouter.params.sessionKey
        }})
      } else if (currentRouter.path.includes('newRemoteGuidance/externalCall/common')) {
        this.$router.push({ name: 'externalCallCommonMeetingRecordsView', params: {
          roomId: row.roomId, appId: currentRouter.params.appId,
          outerId: currentRouter.params.outerId, userName: currentRouter.params.userName
        }})
      } else {
        this.$router.push({ name: 'recordsView', params: { roomId: row.roomId }})
      }
    },
    handleInviteByLink() {
      store.dispatch('user/changeIsBeingInvited', true)
      const detail = {
        msgType: 'DwMeetingInviteByLink',
        roomId: this.roomId,
        chatRoomId: this.chatRoomId,
        senderId: this.senderId,
        senderFullName: this.senderFullName,
        senderUserName: this.senderUserName
      }
      this.showMeetingDialog = true
      getMeeting(detail.roomId).then(res => {
        if (res.status && res.status.name === 'PROCESSING') {
          this.$confirm(`是否加入来自【${detail.senderFullName}】的会议`, '会议邀请提示', {
            confirmButtonText: '确定',
            cancelButtonText: '拒绝',
            confirmButtonClass: 'el-button--primary',
            cancelButtonClass: 'el-button--danger',
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,
            type: 'warning',
            center: true
          }).then(() => {
            const roomId = res.roomId
            this.$refs.meetingDialogRef.openDialog(this.userInfo, this.userImInfo)
            this.$refs.meetingDialogRef.joinRoom(roomId, detail.chatRoomId, false).catch(async(firstError) => {
              if (firstError.reason === '当前环境无法开启会议') {
                await this.joinFailFun(MeetingErrorMsg.joinRoomPermissionError, false)
              } else if (firstError.error && firstError.error.code && firstError.error.code === 53010) {
                await this.joinFailFun(MeetingErrorMsg.getMediaPermissionError)
              } else {
                putKickImUser(this.userImInfo.imUserId).then(() => {
                  this.$refs.meetingDialogRef.joinRoom(roomId, detail.chatRoomId, false).catch(async(secondError) => {
                    if (secondError.reason === '加入聊天室失败') {
                      await this.joinFailFun(MeetingErrorMsg.joinChatRoomError)
                    } else if (secondError.error === 50002) {
                      this.$message({ message: MeetingErrorMsg.joinMeetingRepeat, center: true, type: 'error' })
                    } else {
                      await putKickImUser(this.userImInfo.imUserId)
                      this.$message({ message: MeetingErrorMsg.confirmMeetingStatus, center: true, type: 'error' })
                    }
                    this.$refs.meetingDialogRef.closeDialog()
                  })
                }).catch(() => {
                  this.$message({ message: MeetingErrorMsg.joinMeetingError, center: true, type: 'error' })
                  this.$refs.meetingDialogRef.closeDialog()
                })
              }
            })
          }).catch(() => {
            this.showMeetingDialog = false
            store.dispatch('user/changeIsBeingInvited', false)
          })
        } else {
          this.showMeetingDialog = false
          this.$message({ message: MeetingErrorMsg.meetingFinishRefresh, center: true, type: 'error' })
          this.getList()
        }
      }).catch(() => {
        this.showMeetingDialog = false
      })
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}

</style>
