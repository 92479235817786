var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "attendees-list-container" }, [
    _c(
      "div",
      {
        staticClass: "top-container",
        style: { background: _vm.showBottomButton ? "#ffffff" : "#ffffff" },
      },
      _vm._l(_vm.list, function (user, index) {
        return _c(
          "div",
          { key: index, staticClass: "user-container" },
          [
            user.avatarUrl
              ? _c("el-image", {
                  staticClass: "avatar item",
                  attrs: { src: user.avatarUrl || _vm.AvatarBlue },
                })
              : _c("dw-default-avatar", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: {
                    "full-name": user.fullName,
                    width: 40,
                    "font-size": 14,
                  },
                }),
            _vm._v(" "),
            _c("div", { staticClass: "text item" }, [
              _c("span", { staticClass: "fullName" }, [
                _vm._v(_vm._s("" + user.fullName)),
              ]),
              _vm._v(" "),
              !_vm.isSinoma && !_vm.stringLongOverTen(user.userName)
                ? _c("span", { staticClass: "userName" }, [
                    _vm._v(_vm._s("(" + user.userName + ")")),
                  ])
                : _vm._e(),
              _vm._v(" "),
              user.isSelf
                ? _c("span", { staticClass: "self" }, [_vm._v("(自己)")])
                : _vm._e(),
              _vm._v(" "),
              user.isInitiator
                ? _c("span", { staticClass: "initiator" }, [_vm._v("(发起人)")])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "operation-container",
                class: {
                  "is-expect": _vm.isExpert(user),
                  "is-self": _vm.userInfo.userName === user.userName,
                  "i-am-expect": _vm.isExpert(_vm.userInfo),
                  "i-am-staff": _vm.isStaff(_vm.userInfo),
                },
              },
              [
                _c("img", {
                  staticClass: "microphone-icon icon item",
                  attrs: {
                    src: user.isMicOn ? _vm.MicrophoneOn : _vm.MicrophoneOff,
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeOneMicMute(user)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "25px", "margin-right": "10px" } },
                  [
                    _vm.isStaff(user)
                      ? _c("img", {
                          staticClass: "camera-icon icon item",
                          attrs: {
                            src: user.isCamOn ? _vm.CameraOn : _vm.CameraOff,
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeOneCamMute(user)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.showBottomButton
      ? _c("div", { staticClass: "bottom-container" }, [
          _c(
            "div",
            {
              staticClass: "microphone-container item",
              class: { "is-close": _vm.isMuteAllMic },
              on: { click: _vm.changeAllMicMute },
            },
            [
              _vm.isMuteAllMic
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: _vm.MicrophoneOff, alt: "" },
                  })
                : _c("img", {
                    staticClass: "icon",
                    attrs: { src: _vm.MicrophoneOn, alt: "" },
                  }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.isMuteAllMic ? "全部开启" : "全部关闭")),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "camera-container item",
              class: { "is-close": _vm.isMuteAllCam },
              on: { click: _vm.changeAllCamMute },
            },
            [
              _vm.isMuteAllCam
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: _vm.CameraOff, alt: "" },
                  })
                : _c("img", {
                    staticClass: "icon",
                    attrs: { src: _vm.CameraOn, alt: "" },
                  }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.isMuteAllCam ? "全部开启" : "全部关闭")),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }