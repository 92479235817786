<template>
  <div class="work-order-list-container">
    <div class="top-search-container">
      <el-select v-model="listQuery.towerWorkType" placeholder="作业类型" class="item" :popper-class="selectPopperClass" @change="getList(false, true)">
        <el-option v-for="(item,index ) in TowerWorkOrderTypeObjectEnum" :key="index" :label="item.value" :value="item.name" />
      </el-select>
      <el-select v-model="listQuery.fileUploadStatus" placeholder="文件上传状态" class="item" :popper-class="selectPopperClass" @change="getList(false, true)">
        <el-option v-for="(value,key) in FileUploadStatusEnum" :key="key" :label="value" :value="key" />
      </el-select>
      <ht-button class="mobile-button" type="info" size="mini" @click="onClear">清空</ht-button>
    </div>
    <div ref="cardListContainer" v-loading="isListLoading" class="card-list-container">
      <work-order-card v-for="(item,index) in list" :key="index" :order-info="item" @on-view="onView" @on-del="onDel" />
      <div v-if="list.length === 0" class="empty-list">
        <img :src="Empty" class="empty-img">
        <span class="empty-info">还没有数据</span>
      </div>
      <div v-if="isLoading && !isListLoading" class="loading-spinner"><img class="loading-img" :src="Loading" alt="">数据加载中...</div>
      <div v-if="list.length === page.total && listQuery.page === page.totalPages" class="already-loaded">已经到底部了</div>
    </div>
  </div>
</template>

<script>
import { deleteWorkOrder, getWorkOrders } from '@/api/taskMgr/workOrder'
import WorkOrderCard from '@/views/taskMgr/patrolWorkOrderMgr/mobile/components/workOrderCard'
import { FileUploadStatusEnum, TowerWorkOrderTypeObjectEnum } from '@/views/taskMgr/enum'
import Loading from '@/assets/image/mobile/mobile_loading.png'
import Empty from '@/assets/image/mobile/mobile_empty.png'

export default {
  name: 'List',
  components: { WorkOrderCard },
  data() {
    return {
      Loading, Empty,
      TowerWorkOrderTypeObjectEnum, FileUploadStatusEnum,
      listQuery: {
        page: 1,
        perPage: 5,
        towerWorkType: undefined,
        fileUploadStatus: undefined
      },
      list: [],
      page: {
        total: 0,
        totalPages: 0
      },
      isLoading: false,
      isListLoading: false,
      selectPopperClass: 'mobile-search-select'
    }
  },
  created() {
    this.getList(false, true)
  },
  mounted() {
    this.$nextTick(() => {
      const container = this.$refs.cardListContainer // 获取元素的引用
      if (container) {
        container.addEventListener('touchstart', this.checkDistanceToBottom, false)
        container.addEventListener('touchmove', this.checkDistanceToBottom, false)
      }
    })
  },
  beforeDestroy() {
    const container = this.$refs.cardListContainer // 获取元素的引用
    container.removeEventListener('touchstart', this.checkDistanceToBottom, false)
    container.removeEventListener('touchmove', this.checkDistanceToBottom, false)
  },
  methods: {
    onClear() {
      this.listQuery.page = 1
      this.listQuery.towerWorkType = null
      this.listQuery.fileUploadStatus = null
      this.getList(false, true)
    },
    getList(isConcat = false, isListLoading = true) {
      this.listQuery.type = 'PATROL'
      this.isLoading = true
      this.isListLoading = isListLoading
      setTimeout(() => {
        getWorkOrders(this.listQuery).then((resp) => {
          this.list = isConcat ? this.list.concat(resp.items) : resp.items
          this.page = resp.page
          this.isLoading = false
          this.isListLoading = false
        }).catch(() => {
          this.isLoading = false
          this.isListLoading = false
        })
      }, 500)
    },
    onView(item) {
      this.$router.push({ name: 'MobilePatrolWorkOrderView', params: { orderId: item.id }})
    },
    onDel(item) {
      this.$confirm('请确认是否要删除该条数据!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        customClass: 'mobile-confirm'
      }).then(() => {
        deleteWorkOrder(item.id).then(() => {
          this.$message({ message: '删除成功', center: true, type: 'success' })
          this.onClear()
        })
      }).catch(() => {})
    },
    checkDistanceToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.cardListContainer // 获取元素的引用
        const containerRect = container.getBoundingClientRect() // 获取元素相对于视口的位置信息
        const distanceToBottom = containerRect.bottom - window.innerHeight // 计算元素底部距离屏幕底部的距离

        // 当距离底部小于等于 400px 时，调用特定的方法（当前没有正在调用方法，且不是在最后一页）
        if (distanceToBottom <= 400 && !this.isLoading && this.listQuery.page < this.page.totalPages) {
          this.listQuery.page++
          this.getList(true, false)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.work-order-list-container{
  margin-bottom: 100px;
  position: relative;
  .top-search-container{
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: fixed;
    top: 50px;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0,21,41,.08);
    .el-select{
      flex: 1;
      font-size: 0.9rem;
      height: 32px;
      line-height: 32px;
      ::v-deep .el-input{
        font-size: 0.9rem;
        height: 32px;
        .el-input__inner{
          font-size:0.9rem;
          height: 32px;
        }
        .el-input__suffix{
          font-size: 0.9rem;
        }
      }
    }
    .mobile-button{
      font-size: 1rem;
    }
  }
  .card-list-container{
    margin-top: 80px;
    ::v-deep .el-loading-mask{
      .el-loading-spinner{
        top: 100px !important;
      }
    }
    .empty-list{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 100px;
      .empty-img{
        width: 30%;
        margin-bottom: 10px;
      }
      .empty-info{
        font-size: 1.2rem;
        color: #bbbbbb;
        margin-bottom: 10px;
      }
    }
    .loading-spinner{
      padding: 10px 15px;
      text-align: center;
      color: $primaryColor;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .loading-img {
        width: 30px;
        height: 30px;
        animation: rotate 4s linear infinite; /* 4秒钟内完成一次旋转，无限循环 */
        margin-right: 10px;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .already-loaded{
      padding: 10px 15px;
      text-align: center;
      color: #666666;
    }
  }
}

</style>
