<template>
  <div class="container">
    <div style="position: absolute; z-index: 1000">
      <button id="example-start-button">Start</button>
      <button id="example-pause-button">Pause</button>
      <button id="example-pause-keep-video-button">Pause (keep video)</button>
      <button id="example-unpause-button">UnPause</button>
      <button id="example-stop-button">Stop</button>
    </div>
    <a-scene
      :mindar-image="{imageTargetSrc: mindSrc, autoStart: false}"
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
    >
      <a-camera position="0 0 0" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;" raycaster="near: 10; far: 10000; objects: .clickable" />

      <a-entity id="example-target" mindar-image-target="targetIndex: 0">
        <a-text id="example-text" class="example-text" :value="dynamicText" color="black" align="center" width="2" position="0 0.4 0" text="" />
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
export default {
  name: 'TestAr',
  data() {
    return {
      mindSrc: 'https://devidia-dev-1306584943.cos.ap-shanghai.myqcloud.com/workOrder/targets.mind',
      dynamicText: 'hahahah'
    }
  },
  mounted() {
    window.addEventListener('DOMContentLoaded', this.DOMContentLoaded)
  },
  methods: {
    DOMContentLoaded() {
      const sceneEl = document.querySelector('a-scene')
      let arSystem
      sceneEl.addEventListener('loaded', function() {
        arSystem = sceneEl.systems['mindar-image-system']
      })
      const exampleTarget = document.querySelector('#example-target')
      const examplePlane = document.querySelector('#example-plane')
      const startButton = document.querySelector('#example-start-button')
      const stopButton = document.querySelector('#example-stop-button')
      const pauseButton = document.querySelector('#example-pause-button')
      const pauseKeepVideoButton = document.querySelector('#example-pause-keep-video-button')
      const unpauseButton = document.querySelector('#example-unpause-button')
      startButton.addEventListener('click', () => {
        console.log('start')
        arSystem.start() // start AR
      })
      stopButton.addEventListener('click', () => {
        arSystem.stop() // stop AR
      })
      pauseButton.addEventListener('click', () => {
        arSystem.pause() // pause AR, pause video
      })
      pauseKeepVideoButton.addEventListener('click', () => {
        arSystem.pause(true) // pause AR, keep video
      })
      unpauseButton.addEventListener('click', () => {
        arSystem.unpause() // unpause AR and video
      })
      // arReady event triggered when ready
      sceneEl.addEventListener('arReady', (event) => {
        // console.log("MindAR is ready")
      })
      // arError event triggered when something went wrong. Mostly browser compatbility issue
      sceneEl.addEventListener('arError', (event) => {
        // console.log("MindAR failed to start")
      })
      // detect target found
      exampleTarget.addEventListener('targetFound', event => {
        // this.dynamicText = '识别到了图片！'
        // // 动态添加文本组件
        // const textEntity = document.createElement('a-text')
        // textEntity.setAttribute('value', `${this.dynamicText}`)
        // textEntity.setAttribute('color', 'black')
        // textEntity.setAttribute('align', 'center')
        // textEntity.setAttribute('width', '2')
        // textEntity.setAttribute('position', '0 0.4 0')
        // exampleTarget.appendChild(textEntity)
      })
      // detect target lost
      exampleTarget.addEventListener('targetLost', event => {
        console.log('target lost')
      })
      // detect click event
      examplePlane.addEventListener('click', event => {
        console.log('plane click')
      })
    },
    handleTargetUpdate(event) {
      console.log(2121)
      // 当 MindAR 目标更新时，检查是否识别到了图片
      if (event.detail.targetIndex === 0) {
        // 识别到图片后设置动态文本
        this.dynamicText = '识别到了图片！'
        // 动态添加文本组件
        const textEntity = document.createElement('a-text')
        textEntity.setAttribute('text', `${this.dynamicText}`)
        textEntity.setAttribute('color', 'white')
        textEntity.setAttribute('align', 'center')
        textEntity.setAttribute('width', '4')
        textEntity.setAttribute('position', '0 0 0')
        document.querySelector('a-scene').appendChild(textEntity)
      }
    }
  }
}
</script>

<style scoped>
.info-container {
  /* 确保 MindAR 目标有足够的大小 */
  width: 100%;
  height: 100%;
  background: #000000;
}
</style>
