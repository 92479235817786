var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.equipmentInfo
    ? _c(
        "div",
        {
          staticClass: "equipment-card",
          class: {
            "selected-equipment-card":
              _vm.equipmentInfo.inboundQuantity > 0 ||
              (!_vm.isAdded && _vm.equipmentInfo.isChecked),
            "disabled-equipment-card":
              _vm.isProject && _vm.equipmentInfo.quantity === 0,
          },
        },
        [
          _c(
            "div",
            { staticClass: "top-item" },
            [
              _c("div", [
                _vm._v(
                  "设备：" +
                    _vm._s(_vm.equipmentInfo.name) +
                    " " +
                    _vm._s(
                      _vm.equipmentInfo.code
                        ? "(" + _vm.equipmentInfo.code + ")"
                        : ""
                    )
                ),
              ]),
              _vm._v(" "),
              _vm.isAdded
                ? _c("el-button", {
                    attrs: {
                      type: "danger",
                      icon: "el-icon-delete",
                      circle: "",
                    },
                    on: { click: _vm.delEquipment },
                  })
                : _c("el-checkbox", {
                    model: {
                      value: _vm.equipmentInfo.isChecked,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipmentInfo, "isChecked", $$v)
                      },
                      expression: "equipmentInfo.isChecked",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-item" }, [
            _c("div", [
              _c("span", [_vm._v("规格型号：")]),
              _vm._v(_vm._s(_vm.equipmentInfo.specModel || "-")),
            ]),
            _vm._v(" "),
            _vm.isProject
              ? _c("div", [
                  _c("span", [_vm._v("可入库数量：")]),
                  _vm._v(_vm._s(_vm.equipmentInfo.quantity)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("span", { staticClass: "top" }, [_vm._v("入库数量：")]),
                _vm._v(" "),
                _vm.isProject
                  ? _c("el-input-number", {
                      attrs: {
                        min: 0,
                        max: _vm.equipmentInfo.quantity,
                        disabled: _vm.equipmentInfo.quantity === 0,
                        placeholder: "请输入入库数量",
                        precision: 0,
                      },
                      model: {
                        value: _vm.equipmentInfo.inboundQuantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.equipmentInfo, "inboundQuantity", $$v)
                        },
                        expression: "equipmentInfo.inboundQuantity",
                      },
                    })
                  : _c("el-input-number", {
                      attrs: {
                        min: 0,
                        max: _vm.equipmentInfo.isWhole ? 1 : Infinity,
                        placeholder: "请输入入库数量",
                        precision: 0,
                      },
                      model: {
                        value: _vm.equipmentInfo.inboundQuantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.equipmentInfo, "inboundQuantity", $$v)
                        },
                        expression: "equipmentInfo.inboundQuantity",
                      },
                    }),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }