<template>
  <div>
    <div class="title">
      {{ newList && newList.length > 0 ? '添加记录：' : '' }}
      <ht-button type="success" icon="el-icon-plus" @click="addRevisionHistory">新增变更记录</ht-button>
    </div>
    <ht-table v-if="newList.length > 0" ref="list" :data="newList" style="margin-top: 10px">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in NskCheckTemplateAddRevisionHistoryTitle"
        :key="item.prop"
        :prop="item.prop"
        :min-width="item.width || 100"
        :show-overflow-tooltip="false"
      >
        <template v-slot:header>
          <span :class="{'is-required-title': item.isRequired}">{{ item.isRequired ? `*${item.label}` : item.label }}</span>
        </template>
        <template v-slot="{row}">
          <el-input v-if="item.label=== '变更理由'" v-model="row.changeReason" placeholder="变更理由" type="textarea" :autosize="{ minRows: 1, maxRows: 3}" :maxlength="100" />
          <el-input v-else-if="item.label=== '承认者'" v-model="row.approver" placeholder="承认者" :maxlength="100" />
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="80">
        <template v-slot="{row,$index}">
          <ht-button type="text" icon="el-icon-delete" class="danger-btn" @click="onDeleteNewHistory($index)">删除</ht-button>
        </template>
      </ht-table-column-operation>
    </ht-table>

    <div v-if="newList.length > 0" slot="footer" class="dialog-footer" style="text-align: center;margin-top: 20px">
      <ht-button icon="el-icon-close" @click="cancelAdd">取消</ht-button>
      <ht-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmAddRevisionHistory()">保存</ht-button>
    </div>

    <div class="title">历史记录：</div>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="margin-top: 10px">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in NskCheckTemplateRevisionHistoryTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '变更者'">{{ row.changer && row.changer.fullName || '-' }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation :width="80">
        <template v-slot="{row}">
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该历史记录？" @confirmed="onDeleteRevisionHistory(row)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
  </div>
</template>

<script>
import {
  NskCheckTemplateRevisionHistoryTitle,
  NskCheckTemplateAddRevisionHistoryTitle
} from '@/views/incomingInspectionMgr/tableTitle'
import {
  deleteNskTemplateRevisionHistory,
  getNskTemplateRevisionHistory, postNskTemplateRevisionHistory
} from '@/api/incomingInspectionMgr/nskTemplateMgr'
import HtListContainer from '@/components/HtListContainer'

export default {
  name: 'TemplateRevisionHistory',
  extends: HtListContainer,
  props: {
    templateId: { type: [String, Number], default: null }
  },
  data() {
    return {
      NskCheckTemplateRevisionHistoryTitle, NskCheckTemplateAddRevisionHistoryTitle,
      newList: [],
      list: [],
      isSubmitting: false
    }
  },
  created() {
    this.getRevisionHistoryList()
  },
  methods: {
    getRevisionHistoryList() {
      this.beforeGetList()
      getNskTemplateRevisionHistory(this.templateId).then((resp) => {
        this.list = resp
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    addRevisionHistory() {
      const data = {
        changeReason: null,
        approver: null
      }
      this.newList.push(data)
    },
    onDeleteNewHistory(index) {
      this.newList.splice(index, 1)
    },
    cancelAdd() {
      this.newList = []
    },
    confirmAddRevisionHistory() {
      const allHaveReason = this.newList.every(item => {
        return item && item.changeReason
      })
      if (allHaveReason) {
        postNskTemplateRevisionHistory(this.templateId, { histories: this.newList }).then(resp => {
          this.newList = []
          this.getRevisionHistoryList()
          this.$message({ message: `添加成功`, type: 'success', center: true })
        })
      } else {
        this.$message({ message: `新添加记录的变更理由不能为空`, duration: 1500, type: 'error' })
      }
    },
    onDeleteRevisionHistory(row) {
      deleteNskTemplateRevisionHistory(row.id).then(() => {
        this.getRevisionHistoryList()
        this.$message({ message: `删除成功`, type: 'success', center: true })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.is-required-title{
  color: #ff4949;
}

.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 20px;
  font-weight: 500;
  color: $primaryColor;
  position: relative;
  .el-button{
    position: absolute;
    right: 0;
  }
}

.danger-btn {
  color: red !important;
}
</style>
