var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "container",
      class: [{ "mobile-container": _vm.isMobile }],
    },
    [
      _c(
        "div",
        { staticClass: "fixed-container" },
        [
          _c(
            "ht-action-panel",
            {
              staticClass: "fixed-panel",
              attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClear },
            },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#2E5AFF",
                      "inactive-color": "#909399",
                      "active-text": "只看异常",
                    },
                    on: { change: _vm.onSearch },
                    model: {
                      value: _vm.listQuery.isAbnormal,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "isAbnormal", $$v)
                      },
                      expression: "listQuery.isAbnormal",
                    },
                  }),
                  _vm._v(" "),
                  _c("search-history-input", {
                    ref: "searchHistoryInput",
                    attrs: {
                      width: "600px",
                      "history-list": _vm.searchHistoryList,
                      "record-list": _vm.searchRecordList,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onHistorySearch()
                      },
                      "on-del": _vm.onDelSearchHistory,
                    },
                    model: {
                      value: _vm.listQuery.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "keyword", $$v)
                      },
                      expression: "listQuery.keyword",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isListLoading,
              expression: "isListLoading",
            },
          ],
          ref: "resultListContainer",
          staticClass: "result-list-container",
          style: { paddingTop: _vm.resultListContainerPaddingTop + "px" },
        },
        [
          _vm.list.length > 0
            ? _c(
                "div",
                { staticClass: "result-container" },
                _vm._l(_vm.list, function (item, index) {
                  return _c("result-item", {
                    key: index,
                    attrs: {
                      "order-info": item,
                      keyword: _vm.listQuery.keyword,
                      "is-match": _vm.isMatchSearch,
                    },
                    on: { "on-click": _vm.onView },
                  })
                }),
                1
              )
            : _c("div", { staticClass: "no-result-container" }, [
                _c("img", {
                  staticClass: "empty-img",
                  attrs: { src: _vm.Empty },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "empty-info" }, [
                  _vm._v(
                    _vm._s(
                      "未找到" +
                        (_vm.listQuery.keyword
                          ? "“" + _vm.listQuery.keyword + "”"
                          : "") +
                        "相关图片，您可以尝试更换关键词搜索"
                    )
                  ),
                ]),
              ]),
          _vm._v(" "),
          _vm.isLoading && !_vm.isListLoading
            ? _c("div", { staticClass: "loading-spinner" }, [
                _c("img", {
                  staticClass: "loading-img",
                  attrs: { src: _vm.Loading, alt: "" },
                }),
                _vm._v("数据加载中..."),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isEnd && _vm.list.length > 0
            ? _c("div", { staticClass: "already-loaded" }, [
                _vm._v("已经到底部了"),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "workOrderViewDialog",
          attrs: { title: "工单详情", width: "600px", fullscreen: "" },
          on: { close: _vm.closeWorkOrderViewDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showWorkOrderView
                ? _c("work-order-view", { ref: "workOrderView" })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }