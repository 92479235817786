import { getCascadeChinaZones } from '@/api/publicCommon/chinaZone'
const state = {
  chinaZones: undefined,
  categories: undefined,
  units: undefined
}

const mutations = {
  SET_CHINA_ZONES: (state, chinaZones) => {
    state.chinaZones = chinaZones
  },
  SET_CATEGORIES: (state, categories) => {
    state.categories = categories
  },
  SET_UNITS: (state, units) => {
    state.units = units
  }
}

const actions = {
  getChinaZones({ commit }) {
    return new Promise((resolve) => {
      if (state.chinaZones === undefined) {
        getCascadeChinaZones()
          .then(response => {
            commit('SET_CHINA_ZONES', response)
            resolve(response)
          })
          .catch()
      } else {
        resolve(state.chinaZones)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
