var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "steps-container" },
    _vm._l(_vm.getSteps, function (stepItem, stepIndex) {
      return _c(
        "div",
        {
          key: stepIndex,
          staticClass: "step-container",
          on: {
            click: function ($event) {
              return _vm.addStep(stepItem)
            },
          },
        },
        [
          _c("img", {
            staticClass: "step-img",
            attrs: { src: stepItem.img, alt: "" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "step-name" }, [
            _vm._v(_vm._s(stepItem.name)),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "add-img",
            attrs: { src: _vm.Add, alt: "" },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }