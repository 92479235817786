<template>
  <div v-loading="isLoading" class="work-order-info-container">
    <work-order-card
      v-if="orderInfo"
      :order-info="orderInfo"
      :is-card="false"
      @on-upload-file="onFileUploaded"
      @on-delete-file="onDeleteFile"
    />
    <ht-dialog ref="exportFileDialog" title="工单文件" width="90%">
      <div slot="content">
        <div class="file-container">
          <span class="file-name">文件名称：{{ getFileName(fileUrl) }}</span>
          <ht-button :loading="isDownloading" type="primary" round class="export-button" icon="el-icon-download" @click="downloadFile">下载文件</ht-button>
        </div>
      </div>
    </ht-dialog>
    <div v-if="orderInfo" class="bottom-container">
      <ht-button :loading="isExporting" type="primary" round class="export-button" icon="el-icon-document" @click="onExportPdf">导出工单</ht-button>
    </div>
  </div>
</template>

<script>
import { getWorkOrder, postWorkOrderPdf, putWorkOrder } from '@/api/taskMgr/workOrder'
import { getTowerWorkOrderType } from '@/views/taskMgr/commonJs'
import { TaskWorkOrderStatusStyleEnum, TaskWorkOrderStatusEnum } from '@/views/taskMgr/enum'
import WorkOrderCard from '@/views/taskMgr/patrolWorkOrderMgr/mobile/components/workOrderCard'
import ExportWorkOrderExcel from '@/views/taskMgr/patrolWorkOrderMgr/components/exportWorkOrderExcel'

export default {
  name: 'Detail',
  components: { WorkOrderCard },
  mixins: [ExportWorkOrderExcel],
  props: {
    orderId: { type: [String, Number], default: null }
  },
  data() {
    return {
      TaskWorkOrderStatusStyleEnum, TaskWorkOrderStatusEnum,
      orderInfo: null,
      isExporting: false,
      fileUrl: null,
      isLoading: false,
      isDownloading: false
    }
  },
  created() {
    this.getWorkOrderInfo()
  },
  methods: {
    getTowerWorkOrderType,
    getWorkOrderInfo() {
      this.isLoading = true
      getWorkOrder(this.orderId).then(resp => {
        this.orderInfo = resp
        this.isLoading = false
        this.workOrderList = [resp]
        this.steps = resp.steps
      }).catch(() => {
        this.isLoading = false
      })
    },
    onFileUploaded(index, url, file) {
      const fileItem = { fileUrl: url, fileName: file.name }
      this.orderInfo.uploadFiles[index].files.push(fileItem)
      this.putWorkOrderFun(this.orderInfo.isLoop, this.orderInfo.uploadFiles, '上传')
    },
    onDeleteFile(index, fileIndex) {
      this.orderInfo.uploadFiles[index].files.splice(fileIndex, 1)
      this.putWorkOrderFun(this.orderInfo.isLoop, this.orderInfo.uploadFiles, '删除')
    },
    putWorkOrderFun(isLoop, uploadFiles, info) {
      const data = {
        isLoop: isLoop,
        uploadFiles: uploadFiles,
        action: 'edit'
      }
      data.fileUploadStatus = data.uploadFiles && data.uploadFiles.every(item => item.files.length > 0) ? 'FINISHED' : 'UNFINISHED'
      putWorkOrder(this.orderInfo.id, data).then(() => {
        this.orderInfo.fileUploadStatus = data.fileUploadStatus
        this.$message({ message: `${info}成功`, center: true, type: 'success' })
      })
    },
    onExportPdf() {
      if (this.orderInfo && this.orderInfo.status === 'FINISHED') {
        this.isExporting = true
        this.$message({ message: `正在导出工单，请稍候`, type: 'warning', center: true })
        postWorkOrderPdf(this.orderId).then((resp) => {
          this.isExporting = false
          this.fileUrl = resp.fileUrl
          const userAgent = navigator.userAgent.toLowerCase()
          if (userAgent.indexOf('micromessenger') !== -1 && /iphone/i.test(userAgent)) {
            // this.$refs.exportFileDialog.dialogVisible = true
            this.downloadFile()
          } else {
            window.open(resp.fileUrl)
          }
        }).catch(() => {
          this.isExporting = false
        })
      } else {
        this.$message({ message: `当前工单尚未执行结束，不可进行导出操作`, type: 'error', center: true })
      }
    },
    getFileName(url) {
      // 使用 split() 函数将文件地址按照斜杠（/）或反斜杠（\）分割成数组 ，使用 pop() 函数获取数组中的最后一个元素，即文件名称
      return url ? url.split(/[\\/]/).pop() : '-'
    },
    onExport() {
      this.onExport()
    },
    async downloadFile() {
      this.isDownloading = true
      try {
        const response = await fetch(this.fileUrl)
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = this.getFileName(this.fileUrl)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        this.isDownloading = false
      } catch (error) {
        this.$message({ message: `下载失败`, type: 'error', center: true })
        this.isDownloading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.work-order-info-container{
  margin-bottom: 100px;
  position: relative;
  .bottom-container{
    width: 100%;
    padding: 10px 20px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    .export-button{
      height: 40px;
      width: 100%;
      font-size: 16px;
    }
  }
  ::v-deep .el-loading-mask{
    .el-loading-spinner{
      top: 100px !important;
    }
  }
}

.file-container{
  .file-name{
    display: block;
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 20px;
  }
  .el-button {
    height: 35px;
    width: 100%;
    font-size: 16px;
  }
}
</style>
