var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-statistics-container" },
    [
      _c(
        "div",
        { staticClass: "single-statistical-card-container" },
        _vm._l(_vm.projectStatistics, function (item, index) {
          return _c("single-statistical-card", {
            key: index,
            attrs: { title: item.statusName, count: item.projectCount },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.projectList.length > 0 || _vm.projectProgressCount > 0
        ? _c(
            "div",
            { staticClass: "statistics-chart-container" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("dw-statistic-chart", {
                        attrs: {
                          "is-loading": _vm.isProgressBarChartLoading,
                          title:
                            "工程进度统计（共" +
                            _vm.projectList.length +
                            "个）",
                          "box-style": { width: "100%" },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "statistic",
                              fn: function () {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: _vm.openProjectProgressDialog,
                                      },
                                    },
                                    [_vm._v("查看全部")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "chart",
                              fn: function () {
                                return [
                                  _c("project-progress-bar-chart", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.isProgressBarChartLoading,
                                        expression: "isProgressBarChartLoading",
                                      },
                                    ],
                                    ref: "projectProgressBarChart",
                                    attrs: { height: "300px" },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3063336223
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("dw-statistic-chart", {
                        attrs: {
                          "is-loading": _vm.isProgressPieChartLoading,
                          title: "工程分阶段完成情况统计",
                          "box-style": { width: "100%" },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "chart",
                              fn: function () {
                                return [
                                  _c("project-progress-pie-chart", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.isProgressPieChartLoading,
                                        expression: "isProgressPieChartLoading",
                                      },
                                    ],
                                    ref: "projectProgressPieChart",
                                    attrs: {
                                      count: _vm.projectProgressCount,
                                      height: "300px",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1287506177
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "projectProgressListDialog",
          attrs: { title: "工程进度统计", width: "700px" },
          on: { close: _vm.closeProjectProgressDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "ht-table",
                {
                  ref: "list",
                  staticStyle: { cursor: "pointer" },
                  attrs: { data: _vm.projectList },
                },
                [
                  _c("ht-table-column", {
                    attrs: { label: "序号", type: "index", fixed: "left" },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.listTitle, function (item) {
                    return _c("ht-table-column", {
                      key: item.prop,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        "min-width": item.width || 100,
                        fixed: item.fixed,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                item.label === "工程进度"
                                  ? [
                                      row[item.prop] || row[item.prop] === 0
                                        ? _c("el-progress", {
                                            staticStyle: { width: "95%" },
                                            attrs: {
                                              percentage: row[item.prop],
                                              "show-text": !row.isFinished,
                                              color: row.isFinished
                                                ? "#e6ebf5"
                                                : "#2e5aff",
                                            },
                                          })
                                        : [_vm._v("-")],
                                    ]
                                  : _c("span", [
                                      _vm._v(_vm._s(row[item.prop] || "-")),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }