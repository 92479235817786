var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showForm
    ? _c("order-form", {
        attrs: {
          "is-mobile": "",
          "label-width": "120px",
          "select-popper-class": "mobile-select",
        },
        on: { "confirm-submit": _vm.submitCreateOrderForm },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }