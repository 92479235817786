var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-profile-container" }, [
    _c("div", { staticClass: "user-container" }, [
      _c("div", { staticClass: "user-left-container" }, [
        _c(
          "div",
          { staticClass: "user-avatarUrl" },
          [
            _vm.user.avatarUrl
              ? _c("img", {
                  staticClass: "img-avatarUrl",
                  attrs: { src: _vm.user.avatarUrl, alt: "" },
                })
              : _c("dw-default-avatar", {
                  attrs: {
                    "full-name": _vm.user.fullName,
                    width: 100,
                    "font-size": 30,
                    background: "#ffffff",
                    color: _vm.variables.primaryColor,
                  },
                }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-info" }, [
          _c("span", { staticClass: "item" }, [
            _vm._v(_vm._s(_vm.user.fullName) + "\n          "),
            _c("span", { staticClass: "item" }, [
              _vm._v(_vm._s(_vm.user.userName)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "item" }, [
              _vm._v(_vm._s(_vm.user.roles[0])),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "qr-code-container user-right-container",
          on: { click: _vm.openQrCode },
        },
        [
          _c("vue-qr", {
            ref: "qrCode",
            attrs: { text: _vm.user.qrCode, size: 50, margin: 5 },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "footer-container",
        staticStyle: { "text-align": "center" },
      },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.logout } },
          [_vm._v("退出登录")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showQrCodePreview
      ? _c("div", { staticClass: "qr-code-preview" }, [
          _c("div", {
            staticClass: "qr-code-preview-overlay",
            on: { click: _vm.closeQrCodePreview },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "qr-code-preview-content" },
            [
              _c("vue-qr", {
                ref: "qrCode",
                attrs: { text: _vm.user.qrCode, size: 300, margin: 20 },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }