var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      class: {
        "hidden-close-dialog": _vm.hiddenClose,
        "black-background-dialog": _vm.isBlackBackground,
      },
      attrs: {
        modal: true,
        visible: _vm.dialogVisible,
        width: _vm.width,
        "close-on-click-modal": _vm.closeOnClickModal,
        fullscreen: _vm.fullscreen,
        "custom-class": _vm.customClass,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onClose,
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "title-box" }, [
          _c("div", { staticClass: "title-span" }, [
            _c("span", { style: { background: _vm.color } }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "title-content", style: { color: _vm.color } },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "title-middle-container" },
          [_vm._t("title-middle")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "close-button-container" },
          [_vm._t("close")],
          2
        ),
      ]),
      _vm._v(" "),
      _vm._t("content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }