var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-scene",
        {
          attrs: {
            "mindar-image": { imageTargetSrc: _vm.mindSrc, autoStart: true },
            "color-space": "sRGB",
            renderer: "colorManagement: true, physicallyCorrectLights",
            "vr-mode-ui": "enabled: false",
            "device-orientation-permission-ui": "enabled: false",
          },
        },
        [
          _c("a-camera", {
            attrs: {
              position: "0 0 0",
              "look-controls": "enabled: false",
              cursor: "fuse: false; rayOrigin: mouse;",
              raycaster: "near: 10; far: 10000; objects: .clickable",
            },
          }),
          _vm._v(" "),
          _c(
            "a-entity",
            { attrs: { "mindar-image-target": "targetIndex: 0" } },
            [
              _c("a-plane", {
                attrs: {
                  color: "white",
                  position: "0 0.4 0",
                  height: "0.552",
                  width: "1",
                  rotation: "0 0 0",
                },
              }),
              _vm._v(" "),
              _c("a-text", {
                attrs: {
                  value: "123",
                  color: "black",
                  align: "center",
                  width: "2",
                  position: "0 0.4 0",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a-entity",
            { attrs: { "mindar-image-target": "targetIndex: 1" } },
            [
              _c("a-plane", {
                attrs: {
                  color: "white",
                  position: "0 0.4 0",
                  height: "0.552",
                  width: "1",
                  rotation: "0 0 0",
                },
              }),
              _vm._v(" "),
              _c("a-text", {
                attrs: {
                  value: "物体正面",
                  font: _vm.frontMsdf,
                  negate: "false",
                  color: "black",
                  align: "center",
                  width: "2",
                  position: "0 0.4 0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }