import request from '@/utils/request'

export const devices = 'products/product-devices/'
export const apps = 'products/device-apps/'
export const version = 'products/device-app-versions/'

export function getBoundAppsApi(listQuery) {
  return request({ url: devices, method: 'GET', params: listQuery })
}

export function getDeviceAppsApi(listQuery) {
  return request({ url: apps, method: 'GET', params: listQuery })
}

// 历史版本获取
export function getDeviceAppRecordsApi(listQuery) {
  return request({ url: version, method: 'GET', params: listQuery })
}
