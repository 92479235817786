<template>
  <div class="warehouse-stock-mgr-container">
    <div class="top-container">
      <slot name="tabs" />
      <div class="search-container">
        <el-input v-model="listQuery.name" placeholder="仓库名称" clearable @input="onSearch" @change="onSearch" @clear="onClear('name')" />
      </div>
    </div>
    <div ref="cardListContainer" v-loading="isListLoading" class="card-list-container">
      <warehouse-stock-card
        v-for="(item,index) in list"
        :key="index"
        :warehouse-info="item"
        @on-view="onView"
        @on-outbound="onOutbound"
        @on-inbound="onInbound"
      />
      <div v-if="list.length === 0" class="empty-list">
        <img :src="Empty" class="empty-img">
        <span class="empty-info">还没有数据</span>
      </div>
      <div v-if="isLoading && !isListLoading" class="loading-spinner"><img class="loading-img" :src="Loading" alt="">数据加载中...</div>
      <div v-if="list.length === page.total && listQuery.page === page.totalPages" class="already-loaded">已经到底部了</div>
    </div>
  </div>
</template>

<script>
import Loading from '@/assets/image/mobile/mobile_loading.png'
import Empty from '@/assets/image/mobile/mobile_empty.png'
import { getWarehouseStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import WarehouseStockCard from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/warehouseStockCard'

export default {
  name: 'WarehouseStockList',
  components: { WarehouseStockCard },
  data() {
    return {
      Loading, Empty,
      listQuery: {
        page: 1,
        perPage: 5
      },
      list: [],
      page: {
        total: 0,
        totalPages: 0
      },
      isLoading: false,
      isListLoading: false
    }
  },
  created() {
    this.getList(false, true)
  },
  mounted() {
    this.$nextTick(() => {
      const container = this.$refs.cardListContainer // 获取元素的引用
      if (container) {
        container.addEventListener('touchstart', this.checkDistanceToBottom, false)
        container.addEventListener('touchmove', this.checkDistanceToBottom, false)
      }
    })
  },
  beforeDestroy() {
    const container = this.$refs.cardListContainer // 获取元素的引用
    container.removeEventListener('touchstart', this.checkDistanceToBottom, false)
    container.removeEventListener('touchmove', this.checkDistanceToBottom, false)
  },
  methods: {
    onSearch() {
      this.getList(false, true, true)
    },
    onClear(name) {
      this.listQuery[name] = null
      this.getList(false, true, true)
    },
    getList(isConcat = false, isListLoading = true, isRefresh = false) {
      this.listQuery.page = isRefresh ? 1 : this.listQuery.page
      this.isLoading = true
      this.isListLoading = isListLoading
      setTimeout(() => {
        getWarehouseStocks(this.listQuery).then((resp) => {
          this.list = isConcat ? this.list.concat(resp.items) : resp.items
          this.page = resp.page
          this.isLoading = false
          this.isListLoading = false
        }).catch(() => {
          this.isLoading = false
          this.isListLoading = false
        })
      }, 500)
    },
    onView(item) {
      this.$router.push({ name: 'MobileStockMgrWarehouseStockView', params: { warehouseId: item.id, warehouseName: item.name }})
    },
    onOutbound(item) {
      this.$router.push({ name: 'MobileStockMgrWarehouseOutbound', params: { warehouseId: item.id, warehouseName: item.name }})
    },
    onInbound(item) {
      this.$router.push({ name: 'MobileStockMgrWarehouseInbound', params: { warehouseId: item.id, warehouseName: item.name }})
    },
    checkDistanceToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.cardListContainer // 获取元素的引用
        const containerRect = container.getBoundingClientRect() // 获取元素相对于视口的位置信息
        const distanceToBottom = containerRect.bottom - window.innerHeight // 计算元素底部距离屏幕底部的距离

        // 当距离底部小于等于 400px 时，调用特定的方法（当前没有正在调用方法，且不是在最后一页）
        if (distanceToBottom <= 400 && !this.isLoading && this.listQuery.page < this.page.totalPages) {
          this.listQuery.page++
          this.getList(true, false)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.warehouse-stock-mgr-container{
  margin-bottom: 100px;
  .top-container{
    width: 100%;
    position: fixed;
    top: 50px;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0,21,41,.08);
    .search-container{
      padding: 10px 15px 15px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      .el-input{
        flex: 1;
        font-size: 0.9rem;
        height: 32px;
        line-height: 32px;
        ::v-deep .el-input__inner{
          font-size: 0.9rem;
          height: 32px;
        }
      }
      .mobile-button{
        font-size: 1rem;
      }
    }
  }
  .card-list-container{
    margin-top: 110px;
    ::v-deep .el-loading-mask{
      .el-loading-spinner{
        top: 130px !important;
      }
    }
    .empty-list{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 100px;
      .empty-img{
        width: 30%;
        margin-bottom: 10px;
      }
      .empty-info{
        font-size: 1.2rem;
        color: #bbbbbb;
        margin-bottom: 10px;
      }
    }
    .loading-spinner{
      padding: 10px 15px;
      text-align: center;
      color: $primaryColor;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .loading-img {
        width: 30px;
        height: 30px;
        animation: rotate 4s linear infinite; /* 4秒钟内完成一次旋转，无限循环 */
        margin-right: 10px;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .already-loaded{
      padding: 10px 15px;
      text-align: center;
      color: #666666;
    }
  }
}
</style>
