var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stock-detail-container" },
    [
      _c("equipment-card", {
        attrs: {
          "is-loading": _vm.isEquipmentLoading,
          "equipment-info": _vm.equipmentInfo,
        },
      }),
      _vm._v(" "),
      _c("dw-title", { attrs: { title: "库存详情", "is-mobile": "" } }),
      _vm._v(" "),
      _vm.warehouseStockList.length > 0
        ? _c(
            "div",
            { staticClass: "warehouse-container" },
            [
              _c("dw-title", { attrs: { title: "仓库库存", "is-mobile": "" } }),
              _vm._v(" "),
              _vm._l(_vm.warehouseStockList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "warehouse-item" },
                  [
                    _c("div", { staticClass: "item" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("库存数：" + _vm._s(item.quantity)),
                    ]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.projectStockList.length > 0
        ? _c(
            "div",
            { staticClass: "project-container" },
            [
              _c("dw-title", { attrs: { title: "工程现存", "is-mobile": "" } }),
              _vm._v(" "),
              _vm._l(_vm.projectStockList, function (item, index) {
                return _c("div", { key: index, staticClass: "project-item" }, [
                  _c("div", { staticClass: "item" }, [
                    _vm._v(_vm._s(item.constructionProjectName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _vm._v("现存数：" + _vm._s(item.quantity)),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.logisticsTraces.length > 0
        ? _c("dw-title", { attrs: { title: "物流跟踪", "is-mobile": "" } })
        : _vm._e(),
      _vm._v(" "),
      _c("logistics-trace", {
        attrs: {
          "is-loading": _vm.isLogisticsTracesLoading,
          "logistics-traces": _vm.logisticsTraces,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }