var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-info",
      class: { "in-meeting-user-info": _vm.isInMeeting },
    },
    [
      !_vm.isInMeeting
        ? [
            _vm.userInfo
              ? [
                  _vm.userInfo.avatarUrl
                    ? _c("el-image", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.userInfo.avatarUrl || _vm.AvatarBlue,
                        },
                      })
                    : _c("dw-default-avatar", {
                        staticClass: "avatar",
                        staticStyle: { "margin-left": "25px" },
                        attrs: {
                          "full-name": _vm.userInfo.fullName,
                          width: 40,
                          "font-size": 14,
                        },
                      }),
                  _vm._v(" "),
                  _c("span", { staticClass: "fullName" }, [
                    _vm._v(_vm._s("" + _vm.userInfo.fullName)),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "status-container",
                on: { click: _vm.queryConnect },
              },
              [
                _c("el-image", {
                  staticClass: "connected-status",
                  class: { "disconnected-status": !_vm.imIsConnected },
                  attrs: {
                    src: _vm.imIsConnected ? _vm.Connected : _vm.Disconnected,
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "hint",
                    class: {
                      "connected-hint": _vm.imIsConnected,
                      "disconnected-hint": !_vm.imIsConnected,
                    },
                  },
                  [_vm._v(_vm._s(_vm.imIsConnected ? "点击断开" : "点击连接"))]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("el-image", {
        staticClass: "minimized",
        class: { "in-meeting-minimized": _vm.isInMeeting },
        style: { zIndex: _vm.isBeingInvited && _vm.isInMeeting ? 9999 : 1 },
        attrs: { src: _vm.Minimize, title: "最小化" },
        on: { click: _vm.onMinimized },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }