// 组件
// 巡检模版
// 判断结果
import ResultRecordTypeImg from '@/views/taskMgr/templateMgr/components/resultRecordType/img.jpg'
import ResultRecordTypeStepUi from '@/views/taskMgr/templateMgr/components/resultRecordType/stepUi'
import ResultRecordTypeConfig from '@/views/taskMgr/templateMgr/components/resultRecordType/config'
// 通用记录
import UniversalRecordTypeImg from '@/views/taskMgr/templateMgr/components/universalRecordType/img.jpg'
import UniversalRecordTypeStepUi from '@/views/taskMgr/templateMgr/components/universalRecordType/stepUi'
import UniversalRecordTypeConfig from '@/views/taskMgr/templateMgr/components/universalRecordType/config'
// 记录数值
import ValueRecordTypeImg from '@/views/taskMgr/templateMgr/components/valueRecordType/img.jpg'
import ValueRecordTypeStepUi from '@/views/taskMgr/templateMgr/components/valueRecordType/stepUi'
import ValueRecordTypeConfig from '@/views/taskMgr/templateMgr/components/valueRecordType/config'
// 维修模版
// 维修辅助页
import MaintenanceAidTypeImg from '@/views/taskMgr/templateMgr/components/maintenanceAidType/img.jpg'
import MaintenanceAidType from '@/views/taskMgr/templateMgr/components/maintenanceAidType/stepUi'
import {
  ValueRecordTypeProperties,
  UniversalRecordTypeProperties,
  ResultRecordTypeProperties,
  MaintenanceAidTypeProperties
} from '@/views/taskMgr/templateMgr/components/properties'
import {
  ValueRecordTypeResults,
  UniversalRecordTypeResults,
  ResultRecordTypeResults,
  MaintenanceAidTypeResults
} from '@/views/taskMgr/templateMgr/components/results'
import {
  UniversalRecordRules,
  ResultRecordTypeRules,
  ValueRecordRules
} from '@/views/taskMgr/templateMgr/components/configRules'
// 维修记录页
import { MaintenanceAidRecordProperties } from '@/views/taskMgr/templateMgr/components/maintenanceAidSteps'

export const StepsUiProperties = [
  {
    name: '通用记录',
    type: 'PATROL',
    tag: 'UniversalRecordType',
    img: UniversalRecordTypeImg,
    stepUi: UniversalRecordTypeStepUi,
    config: UniversalRecordTypeConfig,
    configRules: UniversalRecordRules,
    properties: UniversalRecordTypeProperties,
    results: UniversalRecordTypeResults
  },
  {
    name: '判断结果',
    type: 'PATROL',
    tag: 'ResultRecordType',
    img: ResultRecordTypeImg,
    stepUi: ResultRecordTypeStepUi,
    config: ResultRecordTypeConfig,
    configRules: ResultRecordTypeRules,
    properties: ResultRecordTypeProperties,
    results: ResultRecordTypeResults
  },
  {
    name: '记录数值',
    type: 'PATROL',
    tag: 'ValueRecordType',
    img: ValueRecordTypeImg,
    stepUi: ValueRecordTypeStepUi,
    config: ValueRecordTypeConfig,
    configRules: ValueRecordRules,
    properties: ValueRecordTypeProperties,
    results: ValueRecordTypeResults
  },
  {
    name: '维修辅助页',
    type: 'REPAIR',
    tag: 'maintenanceAidType',
    img: MaintenanceAidTypeImg,
    stepUi: MaintenanceAidType,
    properties: MaintenanceAidTypeProperties,
    results: MaintenanceAidTypeResults,
    bindRecordStepUiProperties: MaintenanceAidRecordProperties
  }
]
