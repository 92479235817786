<template>
  <div class="view-container">
    <div class="top-section">
      <div class="view-title top-title">拣货-开始拣货</div>
    </div>
    <div class="goods-container" :style="{height: height - 10 + 'px'}">
      <div class="goods-list-container">
        <div class="goods-list-title-container">
          <span class="title">拣货清单</span>
          <span class="count">总数：{{ submitResults.goods.length }}</span>
          <span class="count">已拣：{{ getIsPickedGoods.length }}</span>
        </div>
        <div class="goods-list">
          <div v-for="(item, index) in submitResults.goods" :key="index" class="goods-item" :class="[{'selected-goods': index === currentGoodsIndex}]">
            {{ `货品：${item.code}` }}
            <i v-if="item.isPicked" class="el-icon-check" />
            <i v-else-if="index === currentGoodsIndex" class="el-icon-arrow-right" />
          </div>
        </div>
      </div>
      <div class="goods-info-container">
        <div class="item">{{ `货主：${submitResults.goods[currentGoodsIndex] && submitResults.goods[currentGoodsIndex].brand || ''}` }}</div>
        <div class="item">{{ `货品：${submitResults.goods[currentGoodsIndex] && submitResults.goods[currentGoodsIndex].name || ''}` }}</div>
        <div class="item line">
          <span class="item-left">{{ `自库位：${submitResults.goods[currentGoodsIndex] && submitResults.goods[currentGoodsIndex].storageCode || ''}` }}</span>
          <span class="item-right">{{ `颜色：${submitResults.goods[currentGoodsIndex] && submitResults.goods[currentGoodsIndex].color || ''}` }}</span>
        </div>
        <div class="item line">
          <div class="item-left">{{ `数量：${submitResults.goods[currentGoodsIndex] && submitResults.goods[currentGoodsIndex].count || 0}` }}</div>
          <div class="item-right">{{ `库存量：${submitResults.goods[currentGoodsIndex] && submitResults.goods[currentGoodsIndex].quantity || 0}` }}</div>
        </div>
        <div class="item">{{ `说明：${submitResults.goods[currentGoodsIndex] && submitResults.goods[currentGoodsIndex].desc || ''}` }}</div>
        <div class="item item-button">实际货品:
          <span v-if="goods" class="scan-result">{{ goods.name }}</span>
          <svg-icon slot="append" icon-class="scan" />
          <el-button type="text" class="scan-button" :disabled="buttonDisabled" @click="handleActions('to-scan-goods-code')">扫描货品</el-button>
        </div>
        <div class="item item-button">出库库位:
          <span v-if="outboundStorageCode" class="scan-result">{{ `${outboundStorageCode}` }}</span>
          <svg-icon slot="append" icon-class="scan" />
          <el-button type="text" class="scan-button" :disabled="buttonDisabled" @click="handleActions('to-scan-storage-code')">扫描库位</el-button>
        </div>
        <div class="item item-count item-bold">
          <el-button type="text" class="count-button" :disabled="buttonDisabled" @click="handleActions('to-input-count')">拣货数量</el-button>
          ： <el-input ref="value" v-model="count" disabled type="number" class="value-input scan-result" />
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <el-button v-if="currentGoodsIndex > 0" class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-prev-box')">上一个</el-button>
      <el-button v-if="currentGoodsIndex < submitResults.goods.length - 1" class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-next-box')">下一个</el-button>
      <el-button class="submit-button square-circle-button" type="text" :disabled="buttonDisabled" @click="handleActions('to-confirm')">确认</el-button>
    </div>
  </div>
</template>

<script>

import { StorageList } from '@/views/taskMgr/newTemplateMgr/components/temporaryTemplate/templateData'

export default {
  name: 'ExecuteGoodsPicking',
  props: {
    height: { type: Number, default: 480 },
    results: { type: Object, default() {
      return {
        area: null,
        areaName: null,
        floor: null,
        floorName: null,
        task: null,
        taskCode: null,
        goods: [],
        boxCode: null
      }
    } }},
  data() {
    return {
      StorageList,
      buttonDisabled: this.disabled,
      currentGoodsIndex: -1,
      currentGoods: null,
      submitResults: this.results,
      goods: null,
      outboundStorageCode: null,
      count: 0
    }
  },
  computed: {
    getUnPickGoods() {
      return this.submitResults.goods && this.submitResults.goods.filter(item => !item.isPicked) || []
    },
    getIsPickedGoods() {
      return this.submitResults.goods && this.submitResults.goods.filter(item => item.isPicked) || []
    }
  },
  watch: {
    results: {
      handler: function() {
        this.submitResults = this.results
      },
      deep: true,
      immediate: true
    },
    currentGoodsIndex: {
      handler: function() {
        this.goods = this.submitResults.goods[this.currentGoodsIndex].isPicked ? this.submitResults.goods[this.currentGoodsIndex] : null
        this.outboundStorageCode = this.submitResults.goods[this.currentGoodsIndex].outboundStorageCode || null
        this.count = this.submitResults.goods[this.currentGoodsIndex].outboundCount || 0
      }
    }
  },
  methods: {
    getCell(cellList) {
      const cell = cellList.filter(item => !item.isPicked)[0] || []
      return cell && cell.name
    },
    handleActions(action) {
      switch (action) {
        case 'to-prev-box':
          this.getSubmitResults(false, 'prev')
          this.$emit('to-prev-goods', this.submitResults, this.currentGoodsIndex)
          break
        case 'to-next-box':
          this.getSubmitResults(false, 'next')
          this.$emit('to-next-goods', this.submitResults, this.currentGoodsIndex)
          break
        case 'to-confirm':
          if (this.currentBoxIndex === this.submitResults.goods.length - 1) {
            this.getSubmitResults(true)
            if (this.goods && this.outboundStorageCode) {
              this.$emit('to-confirm', this.submitResults)
            }
          } else {
            this.getSubmitResults(true, 'next')
            if (this.goods && this.outboundStorageCode) {
              this.$emit('to-confirm', this.submitResults, this.currentGoodsIndex)
            }
          }
          break
        case 'to-scan-goods-code':
          this.$emit('to-scan-code', 'goods-code', this.submitResults)
          break
        case 'to-scan-storage-code':
          this.$emit('to-scan-code', 'goods-storage', this.submitResults)
          break
        case 'to-input-count':
          this.$emit('to-input-count', this.submitResults)
          break
      }
    },
    setScanCodeResult(type) {
      if (type === 'goods-code') {
        this.goods = this.submitResults.goods[this.currentGoodsIndex]
        this.count++
        this.$emit('play-tts', '货品确认一致')
        // if (this.getRandomValueWith50PercentChance()) {
        //
        // } else {
        //   this.$emit('play-tts', '货品扫描错误')
        // }
      } else if (type === 'goods-storage') {
        this.outboundStorageCode = StorageList[Math.floor(Math.random() * StorageList.length)].code
        this.$emit('play-tts', `出库库位为${this.outboundStorageCode}`)
      }
    },
    setCount(count) {
      if (count && !isNaN(Number(count))) {
        this.count = Number(count)
      }
    },
    getSubmitResults(isSubmit, type) {
      if (isSubmit) {
        if (this.goods && this.outboundStorageCode) {
          this.handleInbound()
        } else {
          if (!this.goods) {
            this.$message({ message: `请扫描货品`, duration: 2000, type: 'error', customClass: 'large-info-message' })
          } else if (!this.outboundStorageCode) {
            this.$message({ message: `请扫描库位`, duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
        }
      } else {
        if (this.goods && this.outboundStorageCode) {
          this.handleInbound()
        }
        this.currentGoodsIndex = type === 'next' ? this.currentGoodsIndex + 1 : this.currentGoodsIndex - 1
      }
    },
    handleInbound() {
      const goodsIndex = this.submitResults.goods && this.submitResults.goods.findIndex(item => item.code === this.goods.code)
      if (goodsIndex !== -1) {
        this.submitResults.goods[goodsIndex].isPicked = true
        this.submitResults.goods[goodsIndex].outboundStorageCode = this.outboundStorageCode
        this.submitResults.goods[goodsIndex].outboundCount = this.count
      }
    },
    getTtsText() {
      const goods = this.submitResults.goods[this.currentGoodsIndex]
      if (goods) {
        return `${goods.name || ''}、${goods.color || ''}、数量${goods.count || 0}、库存量${goods.quantity || 0}、自库位为${goods.storageCode || ''}，请扫描实际货品和库位进行确认`
      } else {
        return ''
      }
    },
    getRandomValueWith50PercentChance() {
      return Math.random() < 0.5
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.view-container{
  .goods-container{
    display: flex;
    flex-direction: row;
    margin: 0 20px 10px;
    .goods-list-container{
      display: flex;
      flex-direction: column;
      width: 40%;
      background: #ffffff;
      margin-right: 20px;
      padding: 20px;
      .goods-list-title-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .title{
          flex: 1;
          font-size: 1.2rem;
          font-weight: bold;
        }
        .count{
          font-size: 1.1rem;
          margin-left: 20px;
        }
      }
      .goods-list{
        overflow: hidden;
        .goods-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 8px 10px;
          font-size: 1.1rem;
          background: #ffffff;
          color: #000000;
          .el-icon-arrow-check{
            color: #67C23A;
          }
        }
        .selected-goods{
          background: $primaryColor;
          color: #ffffff;
          font-weight: bolder;
          i{
            color: #ffffff !important;
            font-weight: bolder;
          }
        }
      }
    }
    .goods-info-container{
      flex: 1;
      background: #ffffff;
      padding: 20px 20px 10px;
      .item {
        font-size: 1.2rem;
        margin-bottom: 15px;
      }
      .item.line{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .item-left{
          flex: 1;
          margin-right: 20px;
        }
        .item-right{
          margin-right: 30px;
        }
      }
      .item-bold{
        font-weight: bolder;
      }
      .item-button{
        .scan-result{
          margin-right: 30px;
        }
        .svg-icon{
          color: $primaryColor;
        }
        .el-button{
          padding: 0;
          font-weight: bolder;
        }
      }
      .item-count{
        display: flex;
        flex-direction: row;
        align-items: center;
        .count-button{
          padding: 0;
          font-weight: bold;
        }
        .value-input {
          display: inline-block;
          flex: 1;
          font-size: 1.2rem;
          ::v-deep .el-input__inner{
            background: #ffffff;
            border: #ffffff;
            color: #000000;
            padding: 0;
            font-weight: bolder;
          }
        }
      }
    }
  }
  .bottom-container{
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    .square-circle-button {
      flex: 1;
      height: 40px;
      color: #ffffff;
      background: $primaryColor;
      border-radius: 5px;
      font-size: 1.5rem;
    }
  }
}
</style>
