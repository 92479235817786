var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "file-container",
      class: {
        "has-more-operation":
          _vm.showView || _vm.showDownload || _vm.showTextDel,
        "need-margin": _vm.needMargin,
        "is-show-card-file-container": _vm.showCard,
      },
      style: { justifyContent: _vm.justifyContent },
    },
    [
      _vm.showCard
        ? [
            _vm.isCanPreview(_vm.url) === "image"
              ? _c("el-image", {
                  staticClass: "file-img",
                  attrs: {
                    src: _vm.url,
                    "preview-src-list": [_vm.url],
                    alt: "",
                  },
                })
              : _c(
                  "span",
                  { staticClass: "file-name", on: { click: _vm.viewFile } },
                  [_vm._v(_vm._s(_vm.name || _vm.getFileName()))]
                ),
          ]
        : [
            _c("svg-icon", {
              staticClass: "file-icon",
              staticStyle: { "font-size": "16px" },
              attrs: {
                "icon-class": "file_" + _vm.getFileTypeByExtension(),
                type: "primary",
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "file-name",
                class: { "file-name-flex-1": _vm.nameFlex1 },
                on: { click: _vm.viewFile },
              },
              [_vm._v(_vm._s(_vm.name || _vm.getFileName()))]
            ),
          ],
      _vm._v(" "),
      _vm.showView && _vm.isCanPreview(_vm.url) !== "other"
        ? _c(
            "el-link",
            {
              staticClass: "operating-button",
              attrs: {
                icon: "el-icon-view",
                type: "primary",
                href: _vm.url,
                target: "_blank",
              },
            },
            [_vm._v("预览")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDownload
        ? _c(
            "el-button",
            {
              staticClass: "operating-button",
              attrs: { type: "text", icon: "el-icon-download" },
              on: { click: _vm.viewFile },
            },
            [_vm._v("下载")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDel && _vm.showTextDel
        ? _c(
            "ht-popover-button",
            {
              staticClass: "operating-button",
              attrs: {
                type: "text",
                "danger-text": true,
                icon: "el-icon-delete",
                "confirm-title": "请确认是否要删除？",
              },
              on: { confirmed: _vm.deleteFile },
            },
            [_vm._v("删除")]
          )
        : _vm.showDel
        ? _c("el-button", {
            staticClass: "operating-button",
            attrs: { type: "danger", icon: "el-icon-delete", circle: "" },
            on: { click: _vm.deleteFile },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }