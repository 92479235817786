var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClearSearch } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("el-input", {
                attrs: { placeholder: "工单编号" },
                model: {
                  value: _vm.listQuery.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "code", $$v)
                  },
                  expression: "listQuery.code",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "工单名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "工程" },
                model: {
                  value: _vm.listQuery.projectName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "projectName", $$v)
                  },
                  expression: "listQuery.projectName",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "整机产权编号" },
                model: {
                  value: _vm.listQuery.targetCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "targetCode", $$v)
                  },
                  expression: "listQuery.targetCode",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "作业类型" },
                  model: {
                    value: _vm.listQuery.towerWorkType,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "towerWorkType", $$v)
                    },
                    expression: "listQuery.towerWorkType",
                  },
                },
                _vm._l(
                  _vm.TowerWorkOrderTypeObjectEnum,
                  function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.value, value: item.name },
                    })
                  }
                ),
                1
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd",
                  placement: "bottom-start",
                  "start-placeholder": "创建时间",
                  "end-placeholder": "创建时间",
                },
                model: {
                  value: _vm.createdAt,
                  callback: function ($$v) {
                    _vm.createdAt = $$v
                  },
                  expression: "createdAt",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd",
                  placement: "bottom-start",
                  "start-placeholder": "完成时间",
                  "end-placeholder": "完成时间",
                },
                model: {
                  value: _vm.finishedAt,
                  callback: function ($$v) {
                    _vm.finishedAt = $$v
                  },
                  expression: "finishedAt",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "工单执行状态" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                _vm._l(_vm.TaskWorkOrderStatusEnum, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "文件上传状态" },
                  model: {
                    value: _vm.listQuery.fileUploadStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "fileUploadStatus", $$v)
                    },
                    expression: "listQuery.fileUploadStatus",
                  },
                },
                _vm._l(_vm.FileUploadStatusEnum, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "整机设备名称" },
                  model: {
                    value: _vm.listQuery.targetName,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "targetName", $$v)
                    },
                    expression: "listQuery.targetName",
                  },
                },
                _vm._l(
                  _vm.EquipmentTypesEnum.filter(function (item) {
                    return item.isWhole
                  }),
                  function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.value, value: item.name },
                    })
                  }
                ),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v("新建工单")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
          on: { "row-click": _vm.handleRowClick },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.PatrolWorkOrderListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "工单类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTaskTempType(row[item.prop])
                                    ? _vm.getTaskTempType(row[item.prop]).value
                                    : "巡检"
                                )
                              ),
                            ])
                          : item.label === "作业类型"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getTowerWorkOrderType(row[item.prop])
                                    ? _vm.getTowerWorkOrderType(row[item.prop])
                                        .value
                                    : "-"
                                )
                              ),
                            ])
                          : item.label === "工程"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  (row.targetInfo &&
                                    row.targetInfo.projectName) ||
                                    "-"
                                )
                              ),
                            ])
                          : item.label === "创建人"
                          ? _c("span", [_vm._v(_vm._s(row.staff.fullName))])
                          : item.label === "执行人"
                          ? _c("span", [_vm._v(_vm._s(row.executor.fullName))])
                          : item.label === "循环周期"
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.LoopEnum[row[item.prop]])),
                            ])
                          : item.label === "异常项"
                          ? _c(
                              "span",
                              { style: { color: row[item.prop] ? "red" : "" } },
                              [_vm._v(_vm._s(row[item.prop] + "项"))]
                            )
                          : item.label === "工单执行状态"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.TaskWorkOrderStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.TaskWorkOrderStatusEnum[
                                      row[item.prop]
                                    ] || "-"
                                  )
                                ),
                              ]
                            )
                          : item.label === "文件上传状态" && row[item.prop]
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "status",
                                attrs: {
                                  type: _vm.FileUploadStatusStyleEnum[
                                    row[item.prop]
                                  ],
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.FileUploadStatusEnum[row[item.prop]]
                                  )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.isMobile
            ? _c("ht-table-column-operation", {
                attrs: { width: 110 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c("dw-table-dropdown-buttons", {
                            attrs: { buttons: row.buttons, row: row },
                            on: {
                              "handle-button-click": function ($event) {
                                return _vm.handleButtonClick(row, arguments)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  340302974
                ),
              })
            : _c("ht-table-column-operation", {
                attrs: { width: 300 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "ht-button",
                          {
                            attrs: { type: "text", icon: "el-icon-view" },
                            on: {
                              click: function ($event) {
                                return _vm.onView(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": false,
                              disabled: !row.isLoop,
                              icon: "el-icon-video-pause",
                              "confirm-title": "请确认是否要取消循环？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onCancelLoop(row)
                              },
                            },
                          },
                          [_vm._v("取消循环")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除该条数据？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onDelete(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-button",
                          {
                            attrs: {
                              loading: _vm.loadingIndex === $index,
                              disabled:
                                (_vm.loadingIndex !== -1 &&
                                  _vm.loadingIndex !== $index) ||
                                row.status !== "FINISHED",
                              type: "text",
                              icon: "el-icon-document",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onExportPdf(row, $index)
                              },
                            },
                          },
                          [_vm._v("导出工单")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "createOrderFormDialog",
          attrs: {
            title: "新建工单",
            width: _vm.isMobile ? "100%" : "600px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeCreateOrderDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showCreateOrderForm
                ? _c("order-form", {
                    on: {
                      "cancel-submit": _vm.closeCreateOrderDialog,
                      "confirm-submit": _vm.submitCreateOrderForm,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }