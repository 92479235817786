<template>
  <el-card>
    <ht-action-panel :on-search="onSearch" :on-clear="onClear">
      <template slot="left">
        <el-input v-model="listQuery.name" placeholder="仓库名称" />
      </template>
    </ht-action-panel>
    <ht-action-panel>
      <template slot="right">
        <el-button type="success" icon="el-icon-plus" @click="onCreate">新建仓库</el-button>
      </template>
    </ht-action-panel>

    <ht-table ref="list" v-loading="isLoading" :data="list" style="cursor: pointer">
      <ht-table-column label="序号" type="index" fixed="left" />
      <ht-table-column
        v-for="item in WarehouseListTitle"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width || 100"
        :fixed="isMobile ? false: item.fixed"
      >
        <template v-slot="{row}">
          <span v-if="item.label === '仓库地址'">
            {{ row[item.prop] || '-' }}
            <!--            {{ row[item.prop] ? row[item.prop] || '-' : getAddress('',row.province && row.province.name, row.city && row.city.name, row.district && row.district.name, row.address) || '-' }}-->
          </span>
          <span v-else-if="item.label === '仓库管理员'">{{ row.staff && row.staff.fullName || '-' }}</span>
          <span v-else>{{ row[item.prop] || '-' }}</span>
        </template>
      </ht-table-column>
      <ht-table-column-operation v-if="isMobile" :width="80">
        <template v-slot="{row}">
          <dw-table-dropdown-buttons :buttons="row.buttons" :row="row" @handle-button-click="handleButtonClick(row,arguments)" />
        </template>
      </ht-table-column-operation>
      <ht-table-column-operation v-else :width="130">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-edit" @click="onEdit(row)">编辑</ht-button>
          <ht-popover-button type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该设备？" @confirmed="onDelete(row)">删除</ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination style="float: right;margin-bottom: 10px" :total="page.total" :page.sync="listQuery.page" :limit.sync="listQuery.perPage" @pagination="getList" />

    <ht-dialog ref="warehouseCreateFormDialog" title="新建仓库" :width="isMobile? '100%': '600px'" :close-on-click-modal="false" @close="closeCreateWarehouseDialog">
      <div slot="content">
        <warehouse-form v-if="showWarehouseCreateForm" ref="warehouseCreateForm" @cancel-submit="closeCreateWarehouseDialog" @confirm-submit="closeCreateWarehouseDialog(true)" />
      </div>
    </ht-dialog>

    <ht-dialog ref="warehouseEditFormDialog" title="编辑仓库" :width="isMobile? '100%': '600px'" :close-on-click-modal="false" @close="closeEditWarehouseDialog">
      <div slot="content">
        <warehouse-form v-if="showWarehouseEditForm" ref="warehouseEditForm" is-edit @cancel-submit="closeEditWarehouseDialog" @confirm-submit="closeEditWarehouseDialog(true)" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getWarehouses, deleteWarehouse } from '@/api/constructionEquipmentMgr/warehouse'
import { WarehouseListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import WarehouseForm from '@/views/constructionEquipmentMgr/warehouseMgr/components/warehouseForm'
import { getAddress } from '@/utils/get-display'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { WarehouseForm },
  extends: HtListContainer,
  data() {
    return {
      WarehouseListTitle,
      showWarehouseCreateForm: false,
      showWarehouseEditForm: false,
      showWarehouseStoreList: false,
      storeCount: 0
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  created() {
    this.getList()
  },
  methods: {
    getAddress,
    getList() {
      this.beforeGetList()
      getWarehouses(this.listQuery).then((resp) => {
        resp.items.forEach(item => {
          item.buttons = [
            { name: '编辑', icon: 'el-icon-edit', isPopoverButton: false },
            { name: '删除', icon: 'el-icon-delete', isPopoverButton: true, isDangerText: true, confirmTitle: '请确认是否要删除该设备?' }
          ]
        })
        this.afterGetList(resp)
      }).catch(() => {
        this.isLoading = false
      })
    },
    onCreate() {
      this.$refs.warehouseCreateFormDialog.dialogVisible = true
      this.showWarehouseCreateForm = true
    },
    handleButtonClick(row, $argument) {
      switch ($argument[0]) {
        case '编辑':
          this.onEdit(row)
          break
        case '删除':
          this.onDelete(row)
          break
      }
    },
    onEdit(row) {
      this.showWarehouseEditForm = true
      this.$refs.warehouseEditFormDialog.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.warehouseEditForm.getWarehouseInfo(row)
      })
    },
    onDelete(row) {
      deleteWarehouse(row.id).then(() => {
        this.$message({ message: `删除成功`, duration: 1500, type: 'success' })
        this.getList()
      })
    },
    closeCreateWarehouseDialog(isRefresh = false) {
      this.showWarehouseCreateForm = false
      this.$refs.warehouseCreateFormDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    },
    closeEditWarehouseDialog(isRefresh = false) {
      this.showWarehouseEditForm = false
      this.$refs.warehouseEditFormDialog.dialogVisible = false
      if (isRefresh) {
        this.getList()
      }
    }
  }
}
</script>

<style scoped>

</style>
