<template>
  <div v-loading="isLoading" class="inbound-container">
    <equipment-card :is-loading="isEquipmentLoading" :equipment-info="equipmentInfo" />
    <el-form ref="inboundForm" class="mobile-form" :model="inboundForm" :rules="inboundFormRules" label-width="110px">
      <el-form-item label="入库文件：" prop="attachmentData">
        <dw-file
          v-if="inboundForm.attachmentData"
          :url="inboundForm.attachmentData"
          show-del
          justify-content="left"
          style="width: 100%"
          @delete-file="inboundForm.attachmentData = null"
        />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传入库文件" button-type="primary" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
      <el-form-item label="设备来源：" prop="constructionEquipmentSource">
        <el-radio-group
          v-model="inboundForm.constructionEquipmentSource"
          :disabled="equipmentInfo && equipmentInfo.isWhole ? equipmentInfo && equipmentInfo.isWhole || projectsQuantities === 0 : false"
          @change="handleEquipmentSourceChange"
        >
          <el-radio v-for="(value, key) in EquipmentSourceEnum" :key="key" :label="key">{{ value }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--       整机入库-->
      <template v-if="equipmentInfo && equipmentInfo.isWhole">
        <el-form-item v-if="inboundForm.constructionEquipmentSource === 'OLD'" label="选择工程：" prop="constructionProjectId">
          <el-select v-model="inboundForm.constructionProjectId" disabled popper-class="mobile-select" style="width: 100%">
            <el-option v-for="item in projectList" :key="item.constructionProjectId" :label="item.constructionProjectName" :value="item.constructionProjectId" />
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库：" prop="warehouseId">
          <el-select v-model="inboundForm.warehouseId" :remote-method="getWarehouses" placeholder="请选择入库仓库" popper-class="mobile-select" style="width: 100%" @change="handleWarehouseChange">
            <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="入库数量：" prop="inboundQuantity">
          <el-input v-model="inboundForm.inboundQuantity" disabled placeholder="请输入入库数量" />
        </el-form-item>
      </template>
      <!--       部件入库-->
      <template v-else>
        <el-form-item v-if="inboundForm.constructionEquipmentSource === 'OLD'" label="选择工程：" prop="constructionProjectId">
          <el-select v-model="inboundForm.constructionProjectId" filterable :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'" style="width: 100%" @change="handleSelectProject">
            <el-option v-for="item in projectList" :key="item.constructionProjectId" :label="item.constructionProjectName" :value="item.constructionProjectId" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="inboundForm.constructionEquipmentSource === 'OLD'" label="可入库数量：">
          <span class="quantities"> {{ quantities }}</span>
        </el-form-item>
        <el-form-item label="入库总数量：">
          <span class="quantities"> {{ inboundQuantities }}</span>
        </el-form-item>
        <div v-for="(item,index) in warehouseList" :key="index" class="warehouse-card" :class="{'selected-warehouse-card': item.inboundQuantity > 0}">
          <div class="left-item item">
            <span class="name top">{{ item.name }}</span>
            <span class="quantity bottom">当前库存数：{{ item.quantity }}</span>
          </div>
          <div class="right-item item">
            <span class="title top">入库数量</span>
            <el-input-number
              v-if="inboundForm.constructionEquipmentSource === 'OLD'"
              v-model="item.inboundQuantity"
              class="num bottom"
              :min="0"
              :max="quantities"
              :step="1"
              step-strictly
              :disabled="item.inboundQuantity > 0 ? false :quantities === 0"
              placeholder="请输入入库数量"
              :precision="0"
            />
            <el-input-number
              v-else
              v-model="item.inboundQuantity"
              class="num bottom"
              :min="0"
              :max="equipmentInfo && equipmentInfo.isWhole ? 1 : Infinity"
              :step="1"
              step-strictly
              placeholder="请输入入库数量"
              :precision="0"
            />
          </div>
        </div>
      </template>
    </el-form>
    <div class="bottom-container">
      <el-button round icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button round :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认入库</el-button>
    </div>
  </div>
</template>

<script>
import { EquipmentSourceEnum } from '@/views/constructionEquipmentMgr/enum'
import { getEquipment } from '@/api/constructionEquipmentMgr/equipmentMgr'
import {
  getEquipmentStockProjects,
  getWarehouseStocks,
  putEquipmentStocks
} from '@/api/constructionEquipmentMgr/stockMgr'
import equipmentCard from '@/views/constructionEquipmentMgr/stockMgr/mobile/components/equipmentCard'

export default {
  name: 'EquipmentInbound',
  components: { equipmentCard },
  props: {
    equipmentId: { type: [String, Number], default: null }
  },
  data() {
    return {
      EquipmentSourceEnum,
      inboundForm: {
        action: 'inbound',
        constructionEquipmentId: null,
        constructionEquipmentSource: null,
        constructionProjectId: null,
        projectName: null,
        warehouseItems: [],
        attachmentData: null,
        warehouseId: null,
        inboundQuantity: 1,
        isWhole: false
      },
      inboundFormRules: {
        constructionEquipmentId: [{ required: true, message: '请选择入库设备' }],
        constructionEquipmentSource: [{ required: true, message: '请选择设备来源', trigger: 'change' }],
        constructionProjectId: [{ required: true, message: '请选择选择工程', trigger: 'change' }],
        // attachmentData: [{ required: true, message: '请上传入库文件', trigger: 'change' }],
        //   辅助验证
        warehouseId: [{ required: true, message: '请选择入库仓库', trigger: 'change' }],
        inboundQuantity: [{ required: true, message: '请输入入库数量', trigger: 'blur' }]
      },
      projectsQuantities: 0,
      isSubmitting: false,
      equipmentInfo: null,
      projectList: [],
      isProjectLoading: false,
      warehouseList: [],
      quantities: 0,
      isEquipmentLoading: false,
      inboundQuantities: 0
    }
  },
  watch: {
    warehouseList: {
      handler: function() {
        this.inboundQuantities = this.warehouseList.reduce((quantity, current) => {
          return quantity + current.inboundQuantity || 0
        }, 0)
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getEquipmentInfo()
  },
  methods: {
    getEquipmentInfo() {
      this.isLoading = true
      getEquipment(this.equipmentId).then(resp => {
        this.isLoading = false
        this.equipmentInfo = resp
        this.inboundForm.constructionEquipmentId = resp.id
        this.inboundForm.isWhole = resp.isWhole
        this.getEquipmentStockProjects(resp)
        this.getWarehouses()
      }).catch(() => { this.isLoading = false })
    },
    getEquipmentStockProjects(equipmentInfo) {
      this.quantities = 0
      this.projectsQuantities = 0
      getEquipmentStockProjects(equipmentInfo.id, { action: 'inbound' }).then(resp => {
        this.projectList = resp
        this.projectsQuantities = resp.reduce((quantity, current) => {
          if (current.quantity > 0) {
            return quantity + current.quantity
          }
          return quantity
        }, 0)
        this.inboundForm.constructionEquipmentSource = this.projectsQuantities > 0 ? 'OLD' : 'NEW'
        if (equipmentInfo && equipmentInfo.isWhole) {
          if (this.projectsQuantities > 0) {
            this.inboundForm.constructionProjectId = resp[0].constructionProjectId
          }
          this.quantities = this.projectsQuantities
        }
      })
    },
    handleEquipmentSourceChange(value) {
      if (value === 'OLD') { this.getEquipmentStockProjects(this.equipmentInfo) }
      this.inboundForm.constructionProjectId = null
      this.warehouseList.forEach(item => {
        item.inboundQuantity = 0
      })
    },
    getWarehouses(value) {
      getWarehouseStocks({ equipmentId: this.equipmentInfo.id, keyword: value }).then(resp => {
        resp.forEach(item => { item.inboundQuantity = 0 })
        this.warehouseList = resp
      })
    },
    handleWarehouseChange(value) {
      this.warehouseList.forEach(item => {
        item.inboundQuantity = 0
      })
      const index = this.warehouseList.findIndex(item => item.id === value)
      if (index !== -1) {
        this.warehouseList[index].inboundQuantity = 1
      }
    },
    handleSelectProject(value) {
      const index = this.projectList.findIndex(item => item.constructionProjectId === value)
      this.quantities = index !== -1 ? this.projectList[index].quantity : 0
    },
    onFileUploaded(context, url) {
      this.inboundForm.attachmentData = url
      if (this.inboundForm.attachmentData) {
        this.$refs.inboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    confirmSubmit() {
      const inboundForm = this.getInboundForm()
      this.$refs.inboundForm.validate(valid => {
        if (valid) {
          const hasQuantity = inboundForm.warehouseItems.some(item => item.quantity > 0)
          if (!hasQuantity) {
            this.$message({ message: `请选择仓库并输入入库数量`, type: 'error', center: true })
            return false
          }
          const inboundQuantities = inboundForm.warehouseItems.reduce((quantity, current) => {
            if (current.quantity > 0) {
              return quantity + current.quantity
            }
            return quantity
          }, 0)
          if (this.inboundForm.constructionEquipmentSource === 'OLD' && inboundQuantities > this.quantities) {
            this.$message({ message: `入库总数量不能大于可入库数量`, type: 'error', center: true })
            return false
          }
          if (this.equipmentInfo && this.equipmentInfo.isWhole && inboundQuantities !== 1) {
            this.$message({ message: `该设备入库总数量只能等于1`, type: 'error', center: true })
            return false
          }
          this.isSubmitting = true
          putEquipmentStocks(inboundForm).then(() => {
            this.$message({ message: `入库成功`, type: 'success', center: true })
            this.$router.go(-1)
            this.isSubmitting = false
          }).catch(() => { this.isSubmitting = false })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getInboundForm() {
      const data = {
        action: 'inbound',
        constructionEquipmentId: null,
        constructionProjectId: null,
        warehouseItems: [],
        attachmentData: [],
        isWhole: false
      }
      data.constructionEquipmentId = this.inboundForm.constructionEquipmentId
      data.constructionProjectId = this.inboundForm.constructionProjectId
      data.warehouseItems = this.warehouseList.map(item => { return { warehouseId: item.id, quantity: item.inboundQuantity || 0 } })
      data.attachmentData = this.inboundForm.attachmentData ? [this.inboundForm.attachmentData] : []
      data.isWhole = this.inboundForm.isWhole
      return data
    }
  }
}
</script>

<style scoped  lang="scss">
@import '~@/styles/variables.scss';
@import '~@/styles/mobile-common-style.scss';

.inbound-container{
  margin: 15px 15px 100px;
  font-size: 1rem;
  .equipment-card {
    font-size: 1.1rem;
    background: $primaryColorLight;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #666666;
    margin-bottom: 15px;
    .name{
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  .quantities{
    color: $primaryColor;
    font-weight: bold;
    font-size: 1.1rem;
  }

  ::v-deep .el-button{
    font-size: 1rem;
  }

  .warehouse-card{
    display: flex;
    flex-direction: row;
    color: #666666;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(102, 102, 102, 0.3);
    .item{
      display: flex;
      flex-direction: column;
      .name{
        font-weight: bold;
        font-size: 1.1rem;
      }
      .top{
        margin-bottom: 10px;
      }
    }
    .left-item{
      flex: 1;
      justify-content: space-between;
    }
    .right-item {
      align-items: center;
    }
  }
  .selected-warehouse-card {
    background: #f0f9eb;
  }
}

.mobile-form{
  ::v-deep .el-form-item{
    margin-bottom: 10px;
    .el-form-item__label{
      text-align: right !important;
    }
  }
}

</style>
