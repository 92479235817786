<template>
  <div class="top-section" title="hf_use_description">
    <div class="view-title top-title" :class="{'focused-item': isFocusedItem === 'title'}">{{ `${currentIndex+1}/${count}` }}：{{ title || '步骤名称' }}</div>
    <div class="top-button-container">
      <slot name="other-button" />
      <el-button v-if="showScan" class="circle-button photo-button" type="text" :disabled="buttonDisabled" @click="handleActions('toNativeScanCode')">扫码</el-button>
      <el-button v-if="showJump && count > 1" class="circle-button photo-button" type="text" :disabled="buttonDisabled" @click="jumpStep">步骤跳转</el-button>
      <el-button v-if="uploadPhotos.length < 9 && showPhotoButton" class="circle-button photo-button" type="text" title="拍摄照片" :disabled="buttonDisabled" @click="handleActions('nativeTakePhoto')">拍摄照片</el-button>
      <el-badge v-if="uploadPhotos.length > 0 && showPhotoButton && showPhotos" :value="uploadPhotos.length" class="fullscreen-button-badge" type="warning">
        <el-button class="circle-button fullscreen-button" type="text" :disabled="buttonDisabled" @click="handleActions('openPhoto')">查看照片</el-button>
      </el-badge>
      <el-button v-if="showMoreFunction" class="circle-button more-function-button" type="text" :disabled="buttonDisabled" @click="handleActions('moreFunction')">更多功能</el-button>
      <div v-if="showMoreFunction" class="more-function-container">
        <img :src="Triangle" alt="" class="more-function-img">
        <el-button :class="{'focused-item': isFocusedItem === 'text'}" class="square-button" type="text" :disabled="buttonDisabled" @click="handleActions('nativePlayTTS')">语音播报</el-button>
        <el-button class="square-button top-border-button" type="text" :disabled="buttonDisabled" @click="handleActions('toRemoteGuidance')">远程指导</el-button>
        <el-button v-if="showAiQaAssistant" class="square-button top-border-button" type="text" :disabled="buttonDisabled" @click="handleActions('toAiQaAssistant')">问答助手</el-button>
      </div>
    </div>
    <web-view-carousel v-if="showPhotos" ref="customCarousel" class="topCustomCarousel" height="0" :autoplay="false" arrow="always" :show-icon="false" style="width: 0" :button-disabled="buttonDisabled" @change="carouselActiveChange">
      <web-view-carousel-item v-for="(item, index) in uploadPhotos" :key="index" :button-disabled="buttonDisabled">
        <web-view-image
          :ref="`result${index}`"
          :src="item"
          alt=""
          class="result-img"
          :preview-src-list="uploadPhotos"
          :show-icon="false"
          show-del
          :button-disabled="buttonDisabled"
          @prev="handleActions('prevPhoto', arguments)"
          @next="handleActions('nextPhoto',arguments)"
          @del-img="handleActions('delPhoto',arguments)"
          @close-viewer="handleActions('closePhoto')"
        />
      </web-view-carousel-item>
    </web-view-carousel>

    <custom-dialog ref="chooseItemDialog" :title="`选择跳转步骤`" width="500px" color="#2e5aff" @close="closeChooseItemDialog">
      <template slot="content">
        <el-radio-group v-model="chooseStep" @change="handleActions('jumpStep')">
          <el-radio v-for="item in count" :key="item" :label="item" :class="[`radio-${item.color}`]">{{ `第${item}步` }}</el-radio>
        </el-radio-group>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import Triangle from '@/assets/image/workflow/triangle.png'
import WebViewCarousel from '@/views/taskMgr/newTemplateMgr/components/webViewCarousel/src/main'
import WebViewCarouselItem from '@/views/taskMgr/newTemplateMgr/components/webViewCarousel/src/item'
import WebViewImage from '@/views/taskMgr/newTemplateMgr/components/webViewImage/src/main'
import CustomDialog from '@/views/taskMgr/newTemplateMgr/components/customDialog'

export default {
  name: 'PatrolTopSection',
  components: { WebViewCarousel, WebViewCarouselItem, WebViewImage, CustomDialog },
  props: {
    showScan: { type: Boolean, default: false },
    isFocusedItem: { type: String, default: null },
    currentIndex: { type: Number, default: 0 },
    count: { type: Number, default: 0 },
    title: { type: String, default: null },
    showPhotoButton: { type: Boolean, default: true },
    showMoreFunction: { type: Boolean, default: false },
    mustUploadPhoto: { type: Boolean, default: false },
    uploadPhotos: { type: Array, default: () => [] },
    activeImgIndex: { type: Number, default: 0 },
    buttonDisabled: { type: Boolean, default: false },
    showPhotos: { type: Boolean, default: true },
    showJump: { type: Boolean, default: true }
  },
  data() {
    return {
      Triangle,
      chooseStep: this.currentIndex + 1,
      showAiQaAssistant: process.env.VUE_APP_CONFIG_HAS_SPARK_DOCUMENT === 'true'
    }
  },
  methods: {
    handleActions(action, $arguments) {
      switch (action) {
        case 'nativeTakePhoto':
          this.$emit('native-take-photo')
          break
        case 'moreFunction':
          this.$emit('more-function')
          break
        case 'nativePlayTTS':
          this.$emit('native-play-tts')
          break
        case 'toNativeScanCode':
          this.$emit('to-native-scan-code')
          break
        case 'toRemoteGuidance':
          this.$emit('to-remote-guidance')
          break
        case 'toAiQaAssistant':
          this.$emit('to-ai-qa-assistant')
          break
        case 'setActiveItem':
          this.$emit('set-active-item', this.activeImgIndex)
          break
        case 'openPhoto':
          this.$emit('open-photo')
          break
        case 'closePhoto':
          this.$emit('close-photo')
          break
        case 'delPhoto':
          this.$emit('del-photo', $arguments[0])
          break
        case 'prevPhoto':
          this.$emit('prev-photo', $arguments[0])
          break
        case 'nextPhoto':
          this.$emit('next-photo', $arguments[0])
          break
        case 'jumpStep':
          this.$emit('jump-step', this.chooseStep - 1)
          break
      }
    },
    carouselActiveChange(val, oldVal) {
      this.$emit('set-active-item', val)
    },
    openPhoto() {
      // const refName = 'result' + this.activeImgIndex
      const refName = 'result0'
      this.$refs[refName][0].clickHandler()
    },
    closePhoto() {
      const refName = 'result' + this.activeImgIndex
      if (this.$refs[refName] && this.$refs[refName][0]) {
        this.$refs[refName][0].closeViewer()
      }
    },
    isAlreadyDel(index) {
      const refName = 'result0'
      if (this.$refs[refName] && this.$refs[refName][0]) {
        this.$refs[refName][0].changeIndex(index)
      }
    },
    jumpStep() {
      this.$refs.chooseItemDialog.dialogVisible = true
    },
    closeChooseItemDialog() {
      this.$refs.chooseItemDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
@import "~@/styles/variables.scss";

.required-item{
  color: #f56c6c;
  font-size: 1rem;
  font-weight: bold;
}

.el-radio-group{
  display: flex;
  flex-wrap: wrap;
}

.el-radio {
  flex: 1;
  padding: 10px 10px 10px 0;
  ::v-deep .el-radio__input .el-radio__inner{
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: middle;
  }
  ::v-deep .el-radio__label{
    font-size: 1.3rem;
    vertical-align: middle;
    padding-left: 5px;
  }
}
.el-radio.is-checked{
  ::v-deep .el-radio__inner {
    // #2e5aff
    border-color: $mobilePrimaryColor;
    background: $mobilePrimaryColor;
  }
  ::v-deep .el-radio__label{
    color: $mobilePrimaryColor;
  }
}
</style>
