import request from '@/utils/request'

export const security_check_records = 'security/security_check_records/'

export function getSecurityCheckApi(listQuery) {
  return request({ url: security_check_records, method: 'GET', params: listQuery })
}

export function getSecurityCheckDetailApi(recordId) {
  return request({ url: `${security_check_records}${recordId}`, method: 'GET' })
}

export function updateSecurityCheckRecords(recordId, data) {
  return request({ url: `${security_check_records}${recordId}`, method: 'PUT', data: data })
}

export function deleteSecurityCheckRecord(recordId) {
  return request({ url: `${security_check_records}${recordId}`, method: 'DELETE' })
}
