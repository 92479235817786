<template>
  <div class="equipment-statistics-container">
    <div class="statistics-chart-container">
      <el-row :gutter="24">
        <el-col :span="12">
          <div v-loading="isLeaseCountLoading" class="equipment-count-card-container">
            <equipment-count-card v-for="(item,index) in equipmentCountList" :key="index" class="card" :list="item" />
          </div>
        </el-col>
        <el-col :span="12">
          <dw-statistic-chart :is-loading="isDistributionLoading" title="设备分布" :box-style="{ 'margin-right': '20px', width: '100%', height: '260px', 'margin-top': 0 }">
            <template v-slot:statistic>
              <img v-if="equipmentDistributions.length > 0 && showEquipmentsDistribution" :src="FullScreen" alt="全屏" class="full-screen-img" @click="openDistributionsDialog">
            </template>
            <template v-slot:chart>
              <equipments-distribution v-if="showEquipmentsDistribution" ref="equipmentsDistribution" map-id="equipmentsDistributionMap" height="170px" :distributions-info="equipmentDistributions" />
            </template>
          </dw-statistic-chart>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="18">
          <el-row :gutter="24" style="margin-right: 0; margin-left: 0">
            <div class="whole-container">
              <el-col :span="16" style="padding-right: 0;padding-left: 0">
                <dw-statistic-chart :is-loading="isTrendChartLoading" title="整机租用趋势" :box-style="{ 'margin-right': '20px', width: '100%', 'margin-top': 0 }" :title-style="{width: '120px'}">
                  <template v-slot:statistic>
                    <el-date-picker
                      v-model="equipmentLeaseQueryMonths"
                      type="monthrange"
                      value-format="yyyy-MM"
                      range-separator="至"
                      start-placeholder="开始月份"
                      end-placeholder="结束月份"
                      class="month-range-date-picker"
                      :clearable="false"
                      :picker-options="monthsPickerOptions"
                      @blur="resetMinDate"
                    />
                  </template>
                  <template v-slot:chart>
                    <equipment-lease-trend-chart ref="equipmentLeaseTrendChart" height="230px" />
                  </template>
                </dw-statistic-chart>
              </el-col>
              <el-col :span="8" style="padding-right: 0;padding-left: 0">
                <dw-statistic-chart :is-loading="isRateChartLoading" title="整机租用率" :box-style="{ 'margin-right': '20px', width: '100%', 'margin-top': 0 }" :title-style="{width: '120px'}">
                  <template v-slot:statistic>
                    <el-date-picker
                      v-model="equipmentLeaseQueryMonth"
                      type="month"
                      value-format="yyyy-MM"
                      placeholder="选择月份"
                      class="month-date-picker"
                      :clearable="false"
                      :picker-options="monthPickerOptions"
                    />
                  </template>
                  <template v-slot:chart>
                    <equipment-lease-rate-chart ref="equipmentLeaseRateChart" height="230px" />
                  </template>
                </dw-statistic-chart>
              </el-col>
            </div>
          </el-row>
        </el-col>
        <el-col :span="6">
          <dw-statistic-chart :is-loading="isTimeChartLoading" title="整机年限" :box-style="{ 'margin-right': '20px', width: '100%'}">
            <template v-slot:chart>
              <equipment-time-chart ref="equipmentTimeChart" height="230px" />
            </template>
          </dw-statistic-chart>
        </el-col>
      </el-row>
    </div>
    <ht-dialog id="equipments-distributions-dialog" ref="equipmentsDistributionsDialog" title="设备分布" :close-on-click-modal="false" :fullscreen="true" @close="closeDistributionsDialog">
      <div slot="content">
        <equipments-distribution
          v-if="showEquipmentsDistributionDialog"
          ref="equipmentsDistributionDialogMap"
          map-id="equipmentsDistributionDialogMap"
          :height="equipmentsDistributionDialogMapHeight"
          :distributions-info="equipmentDistributions"
          is-fullscreen
        />
      </div>
    </ht-dialog>
  </div>
</template>

<script>

import EquipmentsDistribution from '@/views/newDashboard/components/distribution/equipmentsLDistributionInfoWindow'
import EquipmentCountCard from '@/views/newDashboard/components/card/leftAndRightCard'
import EquipmentLeaseRateChart from '@/views/newDashboard/components/chart/equipmentLeaseRateChart'
import EquipmentLeaseTrendChart from '@/views/newDashboard/components/chart/equipmentLeaseTrendChart'
import EquipmentTimeChart from '@/views/newDashboard/components/chart/equipmentTimeChart'
import {
  getEquipmentDistributionStatistics,
  getEquipmentLeaseStatistics,
  getEquipmentLeaseCount,
  getEquipmentAgeStatistics
} from '@/api/newDashboard/dashboard'
import { calculateCenter } from '@/utils/business'
import FullScreen from '@/assets/image/device/full_screen.png'

export default {
  name: 'EquipmentStatistics',
  components: {
    EquipmentsDistribution,
    EquipmentCountCard,
    EquipmentLeaseRateChart,
    EquipmentLeaseTrendChart,
    EquipmentTimeChart
  },
  data() {
    return {
      FullScreen,
      monthPickerOptions: {
        disabledDate(date) {
          return date.getTime() > Date.now()
        }
      },
      monthsPickerOptions: {
        disabledDate: (time) => {
          if (this.minDate !== null && this.maxDate === null) {
            const minMonth = this.minDate.getMonth()
            const lastYear = new Date(this.minDate).setMonth(minMonth - 11)
            const nextYear = new Date(this.minDate).setMonth(minMonth + 11)
            // 只能选 minDate 前后一年的范围  + 未来日期禁止选择
            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf() || time.getTime() > Date.now()
          }
          // 未来日期禁止选择
          return time.getTime() > Date.now()
        },
        onPick: ({ minDate, maxDate }) => {
          this.minDate = minDate
          this.maxDate = maxDate
        }
      },
      isRateChartLoading: false,
      isTrendChartLoading: false,
      isDistributionLoading: false,
      equipmentLeaseQueryMonth: null,
      equipmentLeaseQueryMonths: [],
      equipmentDistributions: [],
      showEquipmentsDistribution: false,
      showEquipmentsDistributionDialog: false,
      equipmentsDistributionDialogMapHeight: '600px',
      equipmentCountList: [
        [{ title: '整机总数', count: 0 }, { title: '出租中', count: 0 }, { title: '在库', count: 0 }],
        [{ title: '部件总数', count: 0 }, { title: '出租中', count: 0 }, { title: '在库', count: 0 }]
      ],
      isTimeChartLoading: false,
      equipmentTimeCount: 0,
      isLeaseCountLoading: false
    }
  },
  watch: {
    equipmentLeaseQueryMonth() {
      this.getEquipmentLeaseStatistics(false, true)
    },
    equipmentLeaseQueryMonths() {
      this.getEquipmentLeaseStatistics(false, false)
    }
  },
  created() {
    this.equipmentLeaseQueryMonths = this.initLastSixMonthRange()
    this.equipmentLeaseQueryMonth = this.initCurrentMonth()
    this.getEquipmentTimeStatistics()
    this.getEquipmentLeaseCount()
    this.getEquipmentDistributionStatistics()
  },
  methods: {
    calculateCenter,
    initCurrentMonth() {
      const now = new Date()
      return `${now.getFullYear()}-${now.getMonth() + 1 > 9 ? now.getMonth() + 1 : '0' + (now.getMonth() + 1).toString()}`
    },
    initLastSixMonthRange() {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 5)
      const startMonth = `${start.getFullYear()}-${start.getMonth() + 1 > 9 ? start.getMonth() + 1 : '0' + (start.getMonth() + 1).toString()}`
      const endMonth = `${end.getFullYear()}-${end.getMonth() + 1 > 9 ? end.getMonth() + 1 : '0' + (end.getMonth() + 1).toString()}`
      return [startMonth, endMonth]
    },
    resetMinDate() {
      if (this.patrolDateRange && this.patrolDateRange.length && this.minDate && !this.maxDate) {
        this.minDate = new Date(this.patrolDateRange[0])
      } else {
        this.minDate = null
      }
    },
    goToDetailView(name) {
      this.$router.push({ name: name })
    },
    getEquipmentLeaseStatistics(isAllChange = true, isSingleChange = false) {
      const query = {
        startAt: this.equipmentLeaseQueryMonths[0],
        endAt: this.equipmentLeaseQueryMonths[1],
        currentAt: this.equipmentLeaseQueryMonth
      }
      const isRate = isAllChange || isSingleChange
      const isTrend = isAllChange || !isSingleChange
      this.isRateChartLoading = isRate
      this.isTrendChartLoading = isTrend
      getEquipmentLeaseStatistics(query).then(resp => {
        this.isRateChartLoading = false
        this.isTrendChartLoading = false
        this.$nextTick(() => {
          if (isRate) { this.$refs.equipmentLeaseRateChart.getData(resp.pieData) }
          if (isTrend) { this.$refs.equipmentLeaseTrendChart.getData(resp.lineChartData) }
        })
      }).catch(() => {
        this.isRateChartLoading = false
        this.isTrendChartLoading = false
      })
    },
    getEquipmentTimeStatistics() {
      this.equipmentTimeCount = 0
      this.isTimeChartLoading = true
      getEquipmentAgeStatistics().then(resp => {
        resp.forEach(item => {
          this.equipmentTimeCount += item.equipmentCount
        })
        this.$nextTick(() => {
          this.$refs.equipmentTimeChart.getData(resp, this.equipmentTimeCount)
        })
        this.isTimeChartLoading = false
      }).catch(() => {
        this.isTimeChartLoading = false
      })
    },
    getEquipmentLeaseCount() {
      this.isLeaseCountLoading = true
      getEquipmentLeaseCount().then(resp => {
        // 整机
        this.equipmentCountList[0][0].count = resp.isWholeTotalStockQuantity || 0
        this.equipmentCountList[0][1].count = resp.isWholeProjectStockQuantity || 0
        this.equipmentCountList[0][2].count = resp.isWholeWarehouseStockQuantity || 0
        // 部件
        this.equipmentCountList[1][0].count = resp.isnotWholeTotalStockQuantity || 0
        this.equipmentCountList[1][1].count = resp.isnotWholeProjectStockQuantity || 0
        this.equipmentCountList[1][2].count = resp.isnotWholeWarehouseStockQuantity || 0
        this.isLeaseCountLoading = false
      }).catch(() => {
        this.isLeaseCountLoading = false
      })
    },
    getEquipmentDistributionStatistics() {
      this.isDistributionLoading = true
      getEquipmentDistributionStatistics().then(resp => {
        this.isDistributionLoading = false
        resp.forEach(item => {
          item.position = item.longitude ? [item.longitude, item.latitude] : null
        })
        this.equipmentDistributions = resp.filter(item => item.position)
        this.showEquipmentsDistribution = true
      }).catch(() => {
        this.isDistributionLoading = false
      })
    },
    openDistributionsDialog() {
      this.$refs.equipmentsDistributionsDialog.dialogVisible = true
      this.showEquipmentsDistributionDialog = true
      const dialog = document.getElementById('equipments-distributions-dialog')
      this.$nextTick(() => {
        // el-dialog__header : 40
        // el-dialog__body: paddingTop: 10, paddingBottom: 30 , info-container: 30
        this.equipmentsDistributionDialogMapHeight = dialog.offsetHeight - 80 - 10 - 30 - 30 + 'px'
      })
    },
    closeDistributionsDialog() {
      this.$refs.equipmentsDistributionsDialog.dialogVisible = false
      this.showEquipmentsDistributionDialog = false
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-statistics-container{
  .button-line{
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .el-button{
      font-size: 14px;
    }
  }
  .equipment-count-card-container{
    display: flex;
    flex-direction: column;
    height: calc(62px + 200px);
    .card{
      height: calc((100% - 20px) / 2)
    }
    .card:first-child{
      margin-bottom: 20px;
    }
  }

  .whole-container{
    height: 292px;
    border-radius: 5px;
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #e6ebf5;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    ::v-deep .statistic-box {
      border: none;
      box-shadow: none;
    }
  }

  .month-date-picker{
    width: 100px;
    ::v-deep .el-input__inner{
      padding-right: 10px;
    }
  }

  .month-range-date-picker{
    width: 200px;
    ::v-deep .el-range__close-icon{
      width: 0;
    }
  }
  .full-screen-img{
    height: 30px;
    cursor: pointer;
  }
}
</style>
