import Layout from '@/layout'

const SecurityCheckMgrRouter = {
  path: '/securityCheckMgr',
  component: Layout,
  redirect: 'noRedirect',
  name: 'securityCheckMenu',
  meta: { title: '安环管理', icon: 'safe_shield' },
  children: [
    // 安全巡查
    {
      path: '/securityCheck/check/list',
      component: () => import('@/views/securityCheckMgr/securityCheck/list'),
      name: 'securityCheck',
      meta: { title: '安全巡查', activeMenu: '/securityCheck/check/list' }
    },
    {
      path: '/securityCheck/check/view/:recordId',
      component: () => import('@/views/securityCheckMgr/securityCheck/view'),
      hidden: true,
      props: true,
      name: 'securityCheckView',
      meta: { title: '详情', activeMenu: '/securityCheck/check/list' }
    },
    // 隐患登记
    {
      path: '/securityCheck/hiddenDangerRegister/list',
      component: () => import('@/views/securityCheckMgr/hiddenDangerRegister/list'),
      name: 'hiddenDangerRegister',
      meta: { title: '隐患登记', activeMenu: '/securityCheck/hiddenDangerRegister/list' }
    },
    {
      path: '/securityCheck/hiddenDangerRegister/view/:recordId',
      component: () => import('@/views/securityCheckMgr/hiddenDangerRegister/view'),
      hidden: true,
      props: true,
      name: 'hiddenDangerRegisterView',
      meta: { title: '详情', activeMenu: '/securityCheck/hiddenDangerRegister/list' }
    },
    // 事故登记
    {
      path: '/securityCheck/accidentRegister/list',
      component: () => import('@/views/securityCheckMgr/accidentRegister/list'),
      name: 'accidentRegister',
      meta: { title: '事故登记', activeMenu: '/securityCheck/accidentRegister/list', bizType: 'DEFAULT' }
    },
    {
      path: '/securityCheck/accidentRegister/view/:accidentId',
      component: () => import('@/views/securityCheckMgr/accidentRegister/view'),
      hidden: true,
      props: true,
      name: 'accidentRegisterView',
      meta: { title: '详情', activeMenu: '/securityCheck/accidentRegister/list', bizType: 'DEFAULT' }
    },
    // 危险作业
    {
      path: '/securityCheck/dangerousWork/list',
      component: () => import('@/views/remoteGuidance/list'),
      name: 'dangerOperation',
      meta: { title: '危险作业', activeMenu: '/securityCheck/dangerousWork/list', bizType: 'DANGEROUS_WORK' }
    },
    {
      path: '/securityCheck/dangerousWork/view/:guidanceId',
      component: () => import('@/views/remoteGuidance/view'),
      hidden: true,
      props: true,
      name: 'dangerOperationView',
      meta: { title: '详情', activeMenu: '/securityCheck/dangerousWork/list', bizType: 'DANGEROUS_WORK' }
    }
  ]
}

export default SecurityCheckMgrRouter
