var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      ref: "dialogRef",
      class: [
        "meeting-dialog",
        {
          "fullscreen-meeting-dialog": _vm.fullscreen,
          "is-mobile-fullscreen-meeting-dialog": _vm.fullscreen && _vm.isMobile,
          "is-mobile-in-meeting": _vm.isMobile && _vm.isInMeeting,
        },
      ],
      attrs: {
        visible: _vm.meetingDialogVisible,
        modal: !_vm.isSinoma,
        "lock-scroll": "",
        "destroy-on-close": "",
        "modal-append-to-body": "",
        "show-close": false,
        fullscreen: _vm.fullscreen,
        width: _vm.meetingVideoProperty.normalMeetingDialogSmallWidth + "px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.meetingDialogVisible = $event
        },
        "before-close": _vm.beforeExitRoom,
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _c("meeting-head", {
            ref: "meetingHeadRef",
            attrs: {
              "meeting-info": _vm.meetingInfo,
              "exit-name": _vm.isCalling ? "取消会议" : "离开会议",
              "is-remote-share-screen": _vm.isRemoteShareScreen,
              "share-screen-user-info": _vm.shareScreenUserInfo,
              "is-remote-recording": _vm.isRemoteRecording,
              "recording-user-info": _vm.recordingUserInfo,
              "is-fullscreen": _vm.fullscreen,
              "is-mobile": _vm.isMobile,
            },
            on: {
              "exit-room": _vm.beforeExitRoom,
              minimized: _vm.minimizedScreen,
              "exit-full": _vm.exitFullScreen,
              "enter-full": _vm.enterFulScreen,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "meeting-body" },
        [
          _c(
            "div",
            { staticClass: "invite-list" },
            _vm._l(_vm.inviteList, function (item, index) {
              return _c("div", { key: index, staticClass: "invite-hint" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: _vm.Call, alt: "" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "content" }, [
                  _vm._v(
                    _vm._s(
                      item.fullName + "等待接听：" + item.waitingTime + "s"
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "cancel",
                    on: {
                      click: function ($event) {
                        return _vm.inviteCancel(item.imUserId)
                      },
                    },
                  },
                  [_vm._v("取消邀请")]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "meeting-video-wrapper",
              class: {
                "meeting-small-video-wrapper":
                  _vm.showRemoteVideo &&
                  !_vm.enLarge &&
                  _vm.remoteStaffList.length > 1,
                "meeting-local-video-wrapper":
                  _vm.showLocalVideo && !_vm.showRemoteVideo,
              },
            },
            [
              _vm.showLocalVideo && !_vm.showRemoteVideo
                ? _c("local-video", {
                    attrs: {
                      id: "user_" + _vm.imUserId,
                      "user-info": _vm.userInfo,
                      "is-expert": _vm.isExpert(_vm.userInfo),
                      "is-large-video": "",
                      "is-share-video":
                        _vm.isShareCameraVideo || _vm.isShareScreenVideo,
                      "is-fullscreen": _vm.fullscreen,
                      "video-track": _vm.localVideoTrack,
                      "audio-track": _vm.localAudioTrack,
                      "is-remote-paint": _vm.isStaff(_vm.userInfo)
                        ? _vm.isRemotePaint
                        : false,
                      "is-remote-laser": _vm.isStaff(_vm.userInfo)
                        ? _vm.isRemoteLaser
                        : false,
                      "remote-paint-info": _vm.isStaff(_vm.userInfo)
                        ? _vm.remotePaintInfo
                        : null,
                      "remote-laser-info": _vm.isStaff(_vm.userInfo)
                        ? _vm.remoteLaserInfo
                        : null,
                      "is-focused-guide": _vm.isFocusedGuidanceByRemote,
                      "is-mobile": _vm.isMobile,
                    },
                    on: { "local-video-w-h": _vm.getLocalVideoWh },
                  })
                : !(_vm.showLocalVideo && !_vm.showRemoteVideo)
                ? _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _vm.isRemoteShareScreen
                        ? _c("remote-video", {
                            attrs: {
                              id: "publicScreenVideo",
                              "is-screen": "",
                              "user-info": _vm.publicScreenVideo.userInfo,
                              "is-share-video": "",
                              "is-large-video": "",
                              "is-fullscreen": _vm.fullscreen,
                              "show-change-size-button": false,
                              "remote-receivers": _vm.remoteReceivers,
                              "video-track": _vm.remoteScreenVideoTrack,
                              "is-mobile": _vm.isMobile,
                            },
                          })
                        : [
                            _vm.enLarge &&
                            _vm.enLargeVideoInfo &&
                            _vm.enLargeVideoInfo.userInfo
                              ? _c("remote-video", {
                                  attrs: {
                                    id: "enlargeVideo",
                                    "video-info": _vm.enLargeVideoInfo,
                                    "user-info": _vm.enLargeVideoInfo.userInfo,
                                    "device-status":
                                      _vm.enLargeVideoInfo.deviceStatus,
                                    "video-track":
                                      _vm.enLargeVideoInfo.videoTrack &&
                                      _vm.enLargeVideoInfo.videoTrack.hasOwnProperty(
                                        "_streamId"
                                      )
                                        ? _vm.enLargeVideoInfo.videoTrack
                                        : null,
                                    "audio-track":
                                      _vm.enLargeVideoInfo.audioTrack &&
                                      _vm.enLargeVideoInfo.audioTrack.hasOwnProperty(
                                        "_streamId"
                                      )
                                        ? _vm.enLargeVideoInfo.audioTrack
                                        : null,
                                    "is-share-video":
                                      (_vm.enLargeVideoInfo.deviceStatus &&
                                        _vm.enLargeVideoInfo.deviceStatus
                                          .isCamOn) ||
                                      (_vm.enLargeVideoInfo.deviceStatus &&
                                        _vm.enLargeVideoInfo.deviceStatus
                                          .isUvcConnected &&
                                        _vm.enLargeVideoInfo.deviceStatus
                                          .isUvcCamOn),
                                    "is-large-video": "",
                                    "show-change-size-button":
                                      _vm.remoteStaffList.length > 1,
                                    "is-fullscreen": _vm.fullscreen,
                                    "remote-receivers": _vm.remoteReceivers,
                                    "is-focused-guide":
                                      _vm.enLargeVideoInfo.isFocusedGuide,
                                    "is-remote-paint": _vm.isRemotePaint,
                                    "is-remote-laser": _vm.isRemoteLaser,
                                    "remote-paint-info": _vm.remotePaintInfo,
                                    "remote-laser-info": _vm.remoteLaserInfo,
                                    "is-mobile": _vm.isMobile,
                                    "is-freeze-guidance":
                                      _vm.isFreezeGuidance ||
                                      _vm.isFrozenGuidanceByRemote,
                                  },
                                  on: {
                                    "zoom-video": _vm.zoomVideo,
                                    "cam-manual-focused": _vm.camManualFocused,
                                    "change-one-mic-mute": _vm.changeOneMicMute,
                                    "change-one-cam-mute": _vm.changeOneCamMute,
                                    "change-one-uvc-cam": _vm.changeOneUvcCam,
                                    "change-one-cam-switch":
                                      _vm.changeOneCamSwitch,
                                    "change-one-zoom-level":
                                      _vm.changeOneZoomLevel,
                                    "change-one-flashlight":
                                      _vm.changeOneFlashlight,
                                    "change-one-exposure-level":
                                      _vm.changeOneExposureLevel,
                                    "change-one-video-resolution":
                                      _vm.changeOneVideoResolution,
                                    "change-one-focus-guidance": function (
                                      $event
                                    ) {
                                      return _vm.changeOneFocusGuidance(
                                        0,
                                        $event
                                      )
                                    },
                                    "paint-drawing": _vm.sendPaintDrawingMsg,
                                    "laser-moving": _vm.sendLaserMovingMsg,
                                    "handle-resize": _vm.handleResize,
                                  },
                                })
                              : _c(
                                  "div",
                                  { staticClass: "remote-video-list-wrapper" },
                                  _vm._l(
                                    _vm.remoteUserList,
                                    function (item, index) {
                                      return _c("remote-video", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.showRemoteVideo &&
                                              item.videoTrack &&
                                              item.videoTrack.hasOwnProperty(
                                                "_streamId"
                                              ) &&
                                              item.userInfo &&
                                              item.userInfo.imUserId,
                                            expression:
                                              "showRemoteVideo && item.videoTrack && item.videoTrack.hasOwnProperty('_streamId') && item.userInfo && item.userInfo.imUserId",
                                          },
                                        ],
                                        key: index,
                                        attrs: {
                                          id: "user_" + item.imUserId,
                                          "video-info": item,
                                          "user-info": item.userInfo,
                                          "device-status": item.deviceStatus,
                                          "video-track":
                                            item.videoTrack &&
                                            item.videoTrack.hasOwnProperty(
                                              "_streamId"
                                            )
                                              ? item.videoTrack
                                              : null,
                                          "audio-track":
                                            item.audioTrack &&
                                            item.audioTrack.hasOwnProperty(
                                              "_streamId"
                                            )
                                              ? item.audioTrack
                                              : null,
                                          "is-share-video":
                                            (item.deviceStatus &&
                                              item.deviceStatus.isCamOn) ||
                                            (item.deviceStatus &&
                                              item.deviceStatus
                                                .isUvcConnected &&
                                              item.deviceStatus.isUvcCamOn),
                                          "is-large-video": _vm.isLargeVideo,
                                          "show-change-size-button":
                                            _vm.remoteStaffList.length > 1,
                                          "is-fullscreen": _vm.fullscreen,
                                          "remote-receivers":
                                            _vm.remoteReceivers,
                                          "is-focused-guide":
                                            item.isFocusedGuide,
                                          "is-mobile": _vm.isMobile,
                                        },
                                        on: {
                                          "enLarge-video": function ($event) {
                                            return _vm.enlargeVideo(index)
                                          },
                                          "cam-manual-focused":
                                            _vm.camManualFocused,
                                          "change-one-mic-mute":
                                            _vm.changeOneMicMute,
                                          "change-one-cam-mute":
                                            _vm.changeOneCamMute,
                                          "change-one-uvc-cam":
                                            _vm.changeOneUvcCam,
                                          "change-one-cam-switch":
                                            _vm.changeOneCamSwitch,
                                          "change-one-zoom-level":
                                            _vm.changeOneZoomLevel,
                                          "change-one-flashlight":
                                            _vm.changeOneFlashlight,
                                          "change-one-exposure-level":
                                            _vm.changeOneExposureLevel,
                                          "change-one-video-resolution":
                                            _vm.changeOneVideoResolution,
                                          "change-one-focus-guidance":
                                            function ($event) {
                                              return _vm.changeOneFocusGuidance(
                                                index,
                                                $event
                                              )
                                            },
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("box-message", {
            ref: "boxMessageRef",
            attrs: {
              fullscreen: _vm.fullscreen,
              "room-id": _vm.roomId,
              "chat-room-id": _vm.chatRoomId,
              "user-info": _vm.userInfo,
              "messages-list": _vm.messagesList,
              "is-mobile": _vm.isMobile,
              "is-hidden-translation": _vm.isHiddenTranslation,
            },
            on: {
              "close-box": function ($event) {
                return _vm.getDialogWidth("boxMessageRef")
              },
            },
          }),
          _vm._v(" "),
          _vm.showAttendeesBox
            ? _c("box-attendees", {
                ref: "boxAttendeesRef",
                attrs: {
                  fullscreen: _vm.fullscreen,
                  "user-ids": _vm.userIds,
                  "room-id": _vm.roomId,
                  "user-info": _vm.userInfo,
                  "show-bottom-button": _vm.isExpert(_vm.userInfo),
                  "is-mute-all-cam": _vm.isMuteAllCam,
                  "is-mute-all-mic": _vm.isMuteAllMic,
                  "is-share-audio": _vm.isShareAudio,
                  "is-share-camera-video": _vm.isShareCameraVideo,
                  "local-audio-track": _vm.localAudioTrack,
                  "local-video-track": _vm.localVideoTrack,
                  "remote-audio-tracks": _vm.remoteAudioTracks,
                  "remote-video-tracks": _vm.remoteVideoTracks,
                  "remote-device-states": _vm.remoteDeviceStates,
                  "is-mobile": _vm.isMobile,
                },
                on: {
                  "close-box": function ($event) {
                    return _vm.getDialogWidth("boxAttendeesRef")
                  },
                  "change-one-mic-mute": _vm.changeOneMicMute,
                  "change-one-cam-mute": _vm.changeOneCamMute,
                  "change-all-mic-mute": _vm.changeAllMicMute,
                  "change-all-cam-mute": _vm.changeAllCamMute,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showOnlineUserBox
            ? _c("box-online-user", {
                ref: "boxOnlineUserRef",
                attrs: {
                  fullscreen: _vm.fullscreen,
                  "user-info": _vm.userInfo,
                  "user-ids": _vm.userIds,
                  "invite-list": _vm.inviteList,
                  "is-mobile": _vm.isMobile,
                },
                on: {
                  invite: _vm.sendMeetingInviteMsg,
                  "copy-meeting-url": _vm.copyMeetingUrl,
                  "close-box": function ($event) {
                    return _vm.getDialogWidth("boxOnlineUserRef")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("meeting-footer", {
            ref: "meetingFooter",
            attrs: {
              "user-count": _vm.userIds.length + 1,
              "show-remote-video": _vm.showRemoteVideo,
              "show-share-camera-video":
                _vm.isStaff(_vm.userInfo) && !_vm.isExpert(_vm.userInfo),
              "show-share-screen-video":
                _vm.isExpert(_vm.userInfo) || _vm.isStaff(_vm.userInfo),
              "show-recording-video": _vm.isExpert(_vm.userInfo),
              "show-pen": _vm.isExpert(_vm.userInfo) && _vm.isFocusGuidance,
              "show-paint": _vm.isExpert(_vm.userInfo) && _vm.isFocusGuidance,
              "show-guidance":
                _vm.isExpert(_vm.userInfo) && _vm.isFocusGuidance,
              "show-video-resolution":
                _vm.isStaff(_vm.userInfo) && !_vm.isExpert(_vm.userInfo),
              "is-open-box": _vm.isOpenBox,
              "is-share-audio": _vm.isShareAudio,
              "is-share-camera-video": _vm.isShareCameraVideo,
              "is-share-screen-video": _vm.isShareScreenVideo,
              "is-recording-video": _vm.isRecording,
              "is-laser-moving": _vm.isLaserMoving,
              "is-paint-drawing": _vm.isPaintDrawing,
              "is-freeze-guidance": _vm.isFreezeGuidance,
              fullscreen: _vm.fullscreen,
              "message-count": _vm.unreadCount,
              "local-bitrate": _vm.localBitrate,
              "video-resolution": _vm.videoResolution,
              "en-large-video-info": _vm.enLargeVideoInfo,
              "is-mobile": _vm.isMobile,
            },
            on: {
              "audio-share-click": _vm.audioShareFun,
              "camera-video-share-click": _vm.cameraVideoShareFun,
              "screen-video-share-click": _vm.screenVideoShareFun,
              "video-recording-click": _vm.videoRecordingFun,
              "laser-click": _vm.useLaser,
              "paint-click": _vm.usePaint,
              "clear-click": _vm.useClear,
              "guidance-click": _vm.freezeGuidance,
              "message-click": _vm.openMeetingMessage,
              "attendees-click": _vm.viewAttendees,
              "invite-person-click": _vm.viewOnlineUser,
              "select-video-resolution": _vm.selectVideoResolution,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("freeze-guidance", {
        ref: "freezeGuidanceRef",
        attrs: {
          id: "freezeGuidance",
          fullscreen: _vm.fullscreen,
          "im-user-id": _vm.enLargeVideoInfo && _vm.enLargeVideoInfo.imUserId,
          "room-id": _vm.roomId,
          "frozen-guidance-info": _vm.frozenGuidanceInfo,
          "video-property":
            _vm.enLargeVideoInfo && _vm.enLargeVideoInfo.videoProperty,
          "is-remote-paint": _vm.freezeCanvasTrack.isRemotePaint,
          "is-remote-laser": _vm.freezeCanvasTrack.isRemoteLaser,
          "remote-laser-info": _vm.freezeCanvasTrack.remoteLaserInfo,
          "remote-paint-info": _vm.freezeCanvasTrack.remotePaintInfo,
          "en-large-video-rect": _vm.enLargeVideoRect,
          "is-mobile": _vm.isMobile,
        },
        on: {
          "freeze-guidance-start": _vm.freezeGuidanceStart,
          "freeze-guidance-finish": _vm.freezeGuidanceFinish,
          "freeze-guidance-start-fail": _vm.freezeGuidanceStartFail,
          "use-laser": _vm.freezeUseLaser,
          "laser-moving": _vm.sendLaserMovingMsg,
          "use-paint": _vm.freezeUsePaint,
          "paint-drawing": _vm.sendPaintDrawingMsg,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }