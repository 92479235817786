// 会议聊天
import { translateMsg } from '@/views/newRemoteGuidance/js/business'
import { getMsgFromStaffInfo } from '@/api/newRemoteGuidance/newRemoteGuidance'

export default {
  methods: {
    async getHistoryChatMessage(messages) {
      for (const item of messages) {
        const message = {}
        message.messageDirection = item.fromStaff && item.fromStaff.userName === this.userInfo.account.userName ? 1 : 2
        message.chatTime = item.chatTime
        message.content = item.content
        if (item.mediaType.name === 'FILE') {
          message.messageType = 'RC:FileMsg'
        } else if (item.mediaType.name === 'IMG') {
          message.messageType = 'RC:ImgMsg'
        } else if (item.mediaType.name === 'TXT') {
          message.messageType = 'RC:TxtMsg'
          message.content = item.content.content
          if (!message.content.translation && !message.content.extra) {
            // 正式服隐藏会议聊天的翻译功能
            if (process.env.VUE_APP_CONFIG_HAS_TRANSLATION === 'true') {
              message.content = await translateMsg(message.content.content, message.content)
            }
          } else {
            if (message.content.extra) {
              message.content.translation = message.content.extra
            }
            message.content.isHideTranslating = false
            message.content.isTranslating = false
          }
        }
        message.content = Object.assign({}, {
          senderFullName: item.fromStaff.fullName,
          senderUserName: item.fromStaff.userName
        }, message.content)
        this.messagesList.push(message)
      }
    },
    async getChatMessageFun(e) {
      const detail = e.detail
      const content = detail.content
      const chatMessageTypes = ['DW:Msg', 'RC:FileMsg', 'RC:ImgMsg', 'RC:TxtMsg']
      const messageTypeResult = chatMessageTypes.some(item => item === detail.messageType)
      const existResult = this.messagesList.some(item => item.messageId === detail.messageId)
      if (messageTypeResult && content.msgType === 'DwMeetingJoinOrLeave' && this.isShareScreenVideo) {
        this.sendMeetingScreenStatusMsg(true)
      }
      if (messageTypeResult && !existResult) {
        const data = detail
        if (detail.messageType === 'RC:TxtMsg' || detail.messageType === 'RC:ImgMsg' || detail.messageType === 'RC:FileMsg') {
          data.content = detail.content.hasOwnProperty('senderFullName') ? content : await this.getMsgFromStaff(detail)
          if (detail.messageType === 'RC:TxtMsg' && data && data.content && !data.content.translation && !data.content.extra) {
            // 正式服隐藏会议聊天的翻译功能
            if (process.env.VUE_APP_CONFIG_HAS_TRANSLATION === 'true') {
              data.content = await translateMsg(data.content.content, data.content)
            }
          } else {
            if (data.content.extra) {
              data.content.translation = data.content.extra
            }
            data.content.isHideTranslating = false
            data.content.isTranslating = false
          }
        }
        this.messagesList.push(data)
        if (this.$refs.boxMessageRef.showBox) {
          this.unreadCount = 0
        } else {
          this.unreadCount++
        }
      }
    },
    getMsgFromStaff(detail) {
      return new Promise((resolve) => {
        getMsgFromStaffInfo(detail.senderUserId).then(res => {
          const content = Object.assign({}, detail.content, { senderFullName: res.fullName, senderUserName: res.userName, senderAvatarUrl: res.avatarUrl })
          resolve(content)
        }).catch(() => {
          const index = this.onlineUserList.findIndex(item => detail.senderUserId === item.imUserId)
          let content
          if (index !== -1) {
            const user = this.onlineUserList[index]
            content = Object.assign({}, detail.content, { senderFullName: user.fulLName, senderUserName: user.userName, senderAvatarUrl: user.avatarUrl })
          } else {
            content = detail.content
          }
          resolve(content)
        })
      })
    }
  }
}
