<template>
  <div style="display: inline-block">
    <input ref="fileInputBtn" type="file" style="display: none" name="file-input" :multiple="multipleSelection" :accept="fileAccept" :value="filesValue" @change="onFilesSelected">
    <el-image v-if="selectImg" v-loading="isLoading" :src="SelectImg" class="select-img" @click="onFileInputClick" />
    <ht-button v-else :type="buttonType" size="mini" :icon="buttonIcon" :loading="isLoading" @click="onFileInputClick">{{ buttonTitle }}</ht-button>
  </div>
</template>

<script>
import { FILE_ACCEPT } from '@/utils'
import { getCosFileUrl, getImageUrl, sliceUploadFile, sliceUploadFiles } from '@/utils/cos'
import SelectImg from '@/assets/image/select_img.png'

const MAX_FILE_SIZE = 1024 * 1024 * 10

export default {
  name: 'HtUploadButton',
  components: { },
  props: {
    fileType: { type: Array, default() { return ['img', 'pdf'] } },
    keyPrefix: { type: String, required: true, default: null },
    initContext: { type: [Object, String], default: null },
    buttonType: { type: String, default: 'success' },
    buttonTitle: { type: String, default: '上传' },
    buttonIcon: { type: String, default: 'el-icon-upload' },
    multipleSelection: { type: Boolean, default: false },
    showSuccessTip: { type: Boolean, default: true },
    selectImg: { type: Boolean, default: false },
    fixedFileName: { type: Boolean, default: false }, // 单选情况下使用
    fileName: { type: String, default: undefined }
  },
  data() {
    return {
      SelectImg,
      context: this.initContext,
      isLoading: false,
      filesValue: '',
      file: null,
      files: [],
      percentage: 0
    }
  },
  computed: {
    fileAccept() {
      const a = []
      this.fileType.forEach(item => {
        a.push(FILE_ACCEPT[item])
      })
      return a
      // return FILE_ACCEPT[this.fileType]
    },
    formattedPercentage() {
      return this.percentage >= 100 ? '上传完成' : `${this.percentage}%`
    }
  },
  methods: {
    setContext(context) {
      this.context = context
    },
    onFileInputClick() {
      this.$emit('focused')
      this.filesValue = ''
      this.$nextTick(() => {
        this.$refs.fileInputBtn.click()
      })
    },
    beginUpload() {
      this.isLoading = true
      this.$emit('on-upload-start')
      this.$emit('on-upload-progress', 1, 0)
    },
    afterUploaded() {
      this.isLoading = false
      this.$emit('on-upload-progress', 0, 0)
      this.$emit('on-upload-finish')
    },
    onFilesSelected(ev) {
      ev.preventDefault()
      if (this.multipleSelection) {
        this.onUploadFiles(ev.target.files)
      } else {
        const file = ev.target.files[0]
        if (file.size >= MAX_FILE_SIZE) {
          this.$message({ message: `文件大小不能超过${MAX_FILE_SIZE / 1024 / 1024}MB`, duration: 1500, type: 'warning' })
          return
        }
        if (this.fixedFileName && file.name !== this.fileName) {
          this.$message({ message: '请确认文件名是否正确', duration: 1500, type: 'warning' })
          return
        }
        this.onUploadSingleFile(file)
      }
    },
    makeFileKey(fileName) {
      return `${this.keyPrefix}/${fileName}`
    },
    onUploadProgress(percent, speed) {
      this.$emit('on-upload-progress', percent, speed)
    },
    onUploadSingleFile(file) {
      this.beginUpload()
      const fileUpload = { key: this.makeFileKey(file.name), body: file }
      sliceUploadFile(fileUpload, null, this.onUploadProgress,
        (err, data) => {
          if (!err) {
            if (this.showSuccessTip) {
              this.$message({ message: `上传成功`, duration: 1500, type: 'success' })
            }
            const fileUrl = this.fileType === 'image' ? getImageUrl(data) : getCosFileUrl(data)
            this.$emit('on-file-uploaded', this.context, fileUrl, file)
          } else {
            this.$message({ message: `上传失败: ${err.error}`, duration: 1500, type: 'error' })
          }
          this.afterUploaded()
        })
    },
    onUploadFiles(filesToUpload) {
      this.beginUpload()
      const files = []
      for (const file of filesToUpload) {
        files.push({ key: this.makeFileKey(file.name), body: file })
      }
      sliceUploadFiles(files, null, this.onUploadProgress,
        (err, data) => {
          if (!err) {
            const urls = []
            data.files.forEach(item => {
              urls.push(this.fileType.includes('img') ? getImageUrl(item) : getCosFileUrl(item))
            })
            this.$emit('on-files-uploaded', this.context, urls, files)
          } else {
            this.$message({ message: `上传失败: ${err.error}`, duration: 1500, type: 'error' })
          }
          this.afterUploaded()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.select-img{
  width: 100px;
  height: 100px;
  cursor: pointer;
}
</style>
