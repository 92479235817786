var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContainer
    ? _c(
        "div",
        {
          staticClass: "step-ui-detail-container",
          class: { "is-used-step-container": !_vm.isEdit },
          style: { height: _vm.height + "px" },
          attrs: { title: "hf_use_description" },
        },
        [
          _vm.showList
            ? _c("get-goods-picking", {
                ref: "getGoodsPicking",
                attrs: {
                  height: _vm.getMiddleHeight,
                  results: _vm.submitResults,
                  disabled: _vm.buttonDisabled,
                },
                on: {
                  "choose-area": _vm.chooseArea,
                  "choose-floor": _vm.chooseFloor,
                  "to-scan-code": _vm.toScanCode,
                  "to-pick": _vm.toPick,
                },
              })
            : _vm.showGoods
            ? _c("execute-goods-picking", {
                ref: "executeGoodsPicking",
                attrs: {
                  height: _vm.getMiddleHeight,
                  results: _vm.submitResults,
                  disabled: _vm.buttonDisabled,
                },
                on: {
                  "to-prev-goods": _vm.toPervGoods,
                  "to-next-goods": _vm.toNextGoods,
                  "to-confirm": _vm.toConfirm,
                  "to-scan-code": _vm.toScanCode,
                  "to-input-count": _vm.toInputCount,
                  "play-tts": _vm.nativePlayTTS,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isEdit ? _c("div", { staticClass: "edit-overlay" }) : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }