<template>
  <div
    class="button-container"
    :class="{
      'is-red-button': isRed,
      'is-red-clicked': isRedClick,
      'is-white-background-button': isWhiteBackground,
      'is-disabled': isDisabled}"
    @click="clickButton"
  >
    <img class="icon" :src="icon" alt="">
    <span class="name">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'VideoButton',
  props: {
    icon: { type: String, default: undefined },
    name: { type: String, default: undefined },
    isWhiteBackground: { type: Boolean, default: false },
    isRed: { type: Boolean, default: false },
    isRedClick: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false }
  },
  methods: {
    clickButton() {
      if (!this.isDisabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-container{
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  .icon {
    width: 15px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .name{
    align-self: center;
    color: #ffffff;
    font-size: 12px;
  }
}
.is-red-button{
  background: #efefef;
  border-radius: 5px;
  padding: 0 5px;
  .name{
    color: #f56c6c;
    font-weight: bold;
  }
}

.is-red-clicked{
  background: #f56c6c;
  border-radius: 5px;
  padding: 0 5px;
  .name{
    color: #ffffff;
    font-weight: bold;
  }
}

.is-white-background-button{
  .icon {
    width: 15px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .name{
    color: #2e5aff;
  }
}

.is-disabled{
  cursor: not-allowed;
  .icon{
    filter:invert(30%);
  }
}
</style>
