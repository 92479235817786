var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "workflow-steps-container" },
    [
      _c("div", { staticClass: "workflow-title" }, [
        _vm._v(
          _vm._s("工单名称：" + ((_vm.workFlow && _vm.workFlow.name) || "-"))
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.workflowSteps, function (item, index) {
        return [
          _c(
            "transition",
            { key: index, attrs: { name: _vm.transitionName } },
            [
              _vm.currentWorkflowStepIndex === index && _vm.showWorkflowStep
                ? _c(item.stepUi, {
                    key: index,
                    tag: "component",
                    staticClass: "component",
                    attrs: {
                      count: _vm.workflowSteps.length,
                      "current-index": _vm.currentWorkflowStepIndex,
                      "is-last-step": index === _vm.workflowSteps.length - 1,
                      properties: item.properties,
                      results: item.results,
                    },
                    on: {
                      "to-prev": _vm.toPrevWorkflowStep,
                      "to-next": _vm.toNextWorkflowStep,
                      "to-remote-guidance": _vm.toRemoteGuidance,
                      "to-ai-qa-assistant": _vm.toAiQaAssistant,
                      "to-repair-record": _vm.toRepairRecord,
                      "save-record": _vm.saveRecord,
                      "submit-record": _vm.submitRecord,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
      _vm._v(" "),
      _vm._l(_vm.repairRecordsSteps, function (item, index) {
        return [
          _c(
            "transition",
            { key: index, attrs: { name: _vm.transitionName } },
            [
              _vm.currentRepairRecordsStepIndex === index &&
              !_vm.showWorkflowStep
                ? _c(item.stepInfo.stepUi, {
                    key: index,
                    tag: "component",
                    staticClass: "component",
                    attrs: {
                      count: _vm.repairRecordsSteps.length,
                      "current-index": _vm.currentRepairRecordsStepIndex,
                      "is-last-step":
                        index === _vm.repairRecordsSteps.length - 1,
                      properties: item.stepInfo.properties,
                      results: item.stepInfo.results,
                    },
                    on: {
                      "to-remote-guidance": _vm.toRemoteGuidance,
                      "to-ai-qa-assistant": _vm.toAiQaAssistant,
                      "continue-handle": _vm.continueHandleRepairRecords,
                      "submit-record": _vm.submitHandleRepairRecords,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }