import request from '@/utils/request'

const nsk_templates = 'nsk/nsk_templates/'
const nsk_template = 'nsk/nsk_template/'

// 获取模版列表
export function getNskTemplates(listQuery) {
  return request({ url: `${nsk_templates}`, method: 'GET', params: listQuery })
}

// 新增模版
export function postNskTemplates(data) {
  return request({ url: `${nsk_templates}`, method: 'POST', data: data })
}

// 修改模版
export function putNskTemplate(templateId, data) {
  return request({ url: `${nsk_template}${templateId}`, method: 'PUT', data: data })
}

// 删除模版
export function deleteNskTemplate(templateId) {
  return request({ url: `${nsk_template}${templateId}`, method: 'DELETE' })
}

// 获取模版详情
export function getNskTemplate(templateId) {
  return request({ url: `${nsk_template}${templateId}`, method: 'GET' })
}

// 导入模版
export function postImportTemplates(data) {
  return request({ url: `${nsk_templates}import_xlsx`, method: 'POST', data: data })
}

// 获取所有枚举值
export function getTemplateEnum() {
  return request({ url: `nsk/nsk_template_enum`, method: 'GET' })
}

// 获取变更记录
export function getNskTemplateRevisionHistory(templateId) {
  return request({ url: `${nsk_template}${templateId}/revision_histories/`, method: 'GET' })
}

// 增加变更记录
export function postNskTemplateRevisionHistory(templateId, data) {
  return request({ url: `${nsk_template}${templateId}/revision_histories/`, method: 'POST', data: data })
}

// 删除变更记录
export function deleteNskTemplateRevisionHistory(templateRevisionHistoryId) {
  return request({ url: `nsk/nsk_template_revision_history/${templateRevisionHistoryId}`, method: 'DELETE' })
}

