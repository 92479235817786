<template>
  <div class="config-container">
    <el-form ref="configForm" :model="configForm" :rules="configRules" class="left-container">
      <el-form-item label="步骤名称：" prop="title">
        <el-input
          v-model="configForm.title"
          placeholder="请输入步骤名称"
          maxlength="15"
          show-word-limit
          @focus="focusedItem('title')"
        />
      </el-form-item>
      <el-form-item label="检查要求：" prop="text">
        <el-input
          v-model="configForm.text"
          placeholder="请输入检查要求"
          type="textarea"
          :rows="6"
          :maxlength="150"
          show-word-limit
          @focus="focusedItem('text')"
          @change="textChange"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Config',
  props: {
    index: {
      type: Number,
      default: 0
    },
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          guidanceVideo: null,
          text: null,
          mustUploadPhoto: null
        }
      }
    },
    configRules: {
      type: Object,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      configForm: this.properties
    }
  },
  methods: {
    focusedItem(name) {
      this.$emit('focused-item', name)
    },
    textChange() {
      this.configForm.guidanceVideo = this.configForm.text
    }
  }
}
</script>

<style scoped lang="scss">
.config-container{
  padding: 20px 10px 30px;
  max-height: 700px;
  overflow-y: auto;
  position: relative;
  .right-container{
    text-align: center;
    height: 30px;
  }
}
</style>
