var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.container,
    { tag: "component" },
    [
      _c("el-card", { staticStyle: { "margin-bottom": "50px" } }, [
        _c("div", { staticClass: "title-card-container" }, [
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_90" }, [
              _vm._v("编号:"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "textStyle",
                staticStyle: { color: "#48a0ff", "font-weight": "bold" },
              },
              [_vm._v(_vm._s(_vm.guidanceItem.guidanceNo || "-"))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_90" }, [
              _vm._v("类型:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(
                _vm._s(
                  _vm.guidanceItem.bizType ? _vm.guidanceItem.bizType.value : ""
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_90" }, [
              _vm._v("发起人:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(
                _vm._s(
                  _vm.guidanceItem.staff ? _vm.guidanceItem.staff.fullName : ""
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_90" }, [
              _vm._v("参与人:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(" " + _vm._s(_vm.guidanceItem.joinUserNamesString || "-")),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_90" }, [
              _vm._v("会议开始时间:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(" " + _vm._s(_vm.guidanceItem.startAt || "-")),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-card-item-one-third" }, [
            _c("span", { staticClass: "labelStyle labelWidth_90" }, [
              _vm._v("会议时长:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "textStyle" }, [
              _vm._v(_vm._s(_vm.guidanceItem.duration || "-")),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("el-divider", [
        _c("i", { staticClass: "el-icon-chat-line-square" }),
        _vm._v(" 沟通记录"),
      ]),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _vm.bizType !== "REMOTE_GUIDANCE" && !_vm.bizView
                ? [
                    _c(
                      "ht-button",
                      {
                        attrs: {
                          disabled: _vm.reportsRecords.length === 0,
                          type: "warning",
                          icon: "el-icon-document",
                        },
                        on: {
                          click: function ($event) {
                            _vm.$refs.reportsRecordsDialog.dialogVisible = true
                          },
                        },
                      },
                      [_vm._v("查看历史图文报告\n        ")]
                    ),
                    _vm._v(" "),
                    !_vm.showSelector
                      ? _c(
                          "ht-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-document",
                            },
                            on: { click: _vm.showSelectorOfChatRecord },
                          },
                          [_vm._v("生成图文报告")]
                        )
                      : [
                          _c(
                            "ht-popover-button",
                            {
                              attrs: {
                                type: "success",
                                disabled: _vm.selectedChatRecord.length === 0,
                                icon: "el-icon-circle-check",
                                "confirm-title":
                                  "已选择" +
                                  _vm.selectedChatRecord.length +
                                  "条记录，是否确认生成报告？",
                                loading: _vm.confirmReportLoading,
                              },
                              on: { confirmed: _vm.confirmReport },
                            },
                            [_vm._v("确认生成\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "ht-button",
                            {
                              attrs: { type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelDoc()
                                },
                              },
                            },
                            [_vm._v("取消下载")]
                          ),
                        ],
                    _vm._v(" "),
                    _c(
                      "ht-button",
                      {
                        attrs: {
                          disabled:
                            !_vm.screenRecords ||
                            _vm.screenRecords.length === 0,
                          icon: "el-icon-video-play",
                        },
                        on: {
                          click: function ($event) {
                            _vm.$refs.screenRecordsDialog.dialogVisible = true
                          },
                        },
                      },
                      [_vm._v("会议录屏")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.list, "row-key": _vm.getRowKey },
          on: { "selection-change": _vm.onSelectionChange },
        },
        [
          _vm.showSelector ? _c("ht-table-column-selection") : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发送人", prop: "fromFullName" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "时间", prop: "chatTime" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.mediaType.name === "TXT"
                      ? _c("span", [_c("p", [_vm._v(_vm._s(row.content))])])
                      : row.mediaType.name === "IMG"
                      ? _c(
                          "span",
                          [
                            row.fileType === "pdf"
                              ? [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        href: row.content,
                                        target: "_blank",
                                        underline: "",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "pdf" },
                                      }),
                                      _vm._v("（查看文件）\n            "),
                                    ],
                                    1
                                  ),
                                ]
                              : row.fileType === "mp4"
                              ? _c("video-player", {
                                  ref: "videoPlayer",
                                  staticClass: "vjs-custom-skin",
                                  attrs: { options: row.playerOptions },
                                })
                              : _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: {
                                    src: row.content,
                                    "preview-src-list": [row.content],
                                  },
                                }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getRemoteGuidanceChatRecords,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "reportsRecordsDialog",
          attrs: { title: "历史图文报告", width: "50%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearFloat",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.reportsRecords } },
                [
                  _c("el-table-column", {
                    attrs: { label: "创建时间", prop: "createdAt" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "项目报告名称", prop: "name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadHistoryReport(
                                      row.ossFilePath
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.name))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "创建人", prop: "staffFullName" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("ht-pagination", {
                staticStyle: { float: "right", clear: "both" },
                attrs: {
                  total: _vm.reportsRecordsPage.total,
                  page: _vm.reportsRecordsListQuery.page,
                  limit: _vm.reportsRecordsListQuery.perPage,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.reportsRecordsListQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(
                      _vm.reportsRecordsListQuery,
                      "perPage",
                      $event
                    )
                  },
                  pagination: _vm.getProjectReportsDocRecords,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        { ref: "screenRecordsDialog", attrs: { title: "会议录屏", width: 70 } },
        [
          _c(
            "div",
            {
              staticClass: "clearFloat",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.screenRecords } },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", type: "index", fixed: "left" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "开始时间",
                      prop: "extraData.startTime",
                      "min-width": "30px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "结束时间",
                      prop: "extraData.endTime",
                      "min-width": "30px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "录屏",
                      prop: "name",
                      "min-width": "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.content
                              ? _c("video-player", {
                                  ref: "videoPlayer",
                                  staticClass: "vjs-custom-skin",
                                  attrs: { options: row.playerOptions },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "录屏时长",
                      prop: "videoDuration",
                      "min-width": "30px",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("ht-pagination", {
                staticStyle: { float: "right", clear: "both" },
                attrs: {
                  total: _vm.screenRecordsPage.total,
                  page: _vm.screenRecordsListQuery.page,
                  limit: _vm.screenRecordsListQuery.perPage,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.screenRecordsListQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(
                      _vm.screenRecordsListQuery,
                      "perPage",
                      $event
                    )
                  },
                  pagination: _vm.getRemoteGuidanceChatRecordsOfVideo,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }