var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ht-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      ref: "list",
      attrs: { data: _vm.documentList },
    },
    [
      _c("ht-table-column", {
        attrs: { label: "序号", type: "index", fixed: "left" },
      }),
      _vm._v(" "),
      _vm._l(_vm.documentListTitle, function (item) {
        return _c("ht-table-column", {
          key: item.prop,
          attrs: {
            label: item.label,
            prop: item.prop,
            "min-width": item.width || 100,
            fixed: item.fixed,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    item.label === "资料名称"
                      ? _c(
                          "span",
                          [
                            _c("dw-file", {
                              attrs: { url: row.fileUrl, "show-del": false },
                              on: { "view-file": _vm.downloadFile },
                            }),
                          ],
                          1
                        )
                      : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                  ]
                },
              },
            ],
            null,
            true
          ),
        })
      }),
      _vm._v(" "),
      _c("ht-table-column-operation", {
        attrs: { width: _vm.showOperation ? 200 : 120 },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              var $index = ref.$index
              return [
                _vm.isCanPreview(row.fileUrl) !== "other"
                  ? _c(
                      "el-link",
                      {
                        attrs: {
                          icon: "el-icon-view",
                          type: "primary",
                          href: row.fileUrl,
                          target: "_blank",
                        },
                      },
                      [_vm._v("预览")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ht-button",
                  {
                    attrs: { type: "text", icon: "el-icon-download" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadFile(row.fileUrl)
                      },
                    },
                  },
                  [_vm._v("下载")]
                ),
                _vm._v(" "),
                _vm.showOperation
                  ? _c(
                      "ht-popover-button",
                      {
                        attrs: {
                          type: "text",
                          "danger-text": true,
                          icon: "el-icon-delete",
                          "confirm-title": "请确认是否要删除该条数据？",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.onDelete($index)
                          },
                        },
                      },
                      [_vm._v("删除\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }