<template>
  <el-card>
    <ht-action-panel :on-search="mySearch" :on-clear="myClear">
      <template slot="left">
        <el-select v-model="listQuery.checkType" placeholder="检查类型">
          <el-option
            v-for="item in checkTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select v-model="listQuery.grade" placeholder="隐患等级">
          <el-option
            v-for="item in gradeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <common-search ref="commonSearch" :staff-placeholder="'登记人'" @handleSelect="handleSelect" @selectDate="selectDate" />
      </template>
      <!--      <template slot="right">-->
      <!--        <el-button v-if="!showSelector" type="danger" icon="el-icon-delete" @click="showSelectedCheckRecord">-->
      <!--          批量删除-->
      <!--        </el-button>-->
      <!--        <template v-else>-->
      <!--          <el-button type="danger" icon="el-icon-delete">-->
      <!--            确定删除-->
      <!--          </el-button>-->
      <!--          <el-button type="text" @click="showSelector = false">取消删除</el-button>-->
      <!--        </template>-->
      <!--      </template>-->
    </ht-action-panel>
    <ht-table
      ref="list"
      v-loading="isLoading"
      :data="list"
      :row-key="getRowKey"
      :default-sort="{prop: 'createdAt', order: 'descending'}"
      style="cursor: pointer"
      @row-click="handleRowClick"
      @selection-change="selectionChange"
      @sort-change="sortChange"
    >
      <!--      <el-table-column-selection v-if="showSelector" v-model="selectedCheckRecord" />-->
      <el-table-column label="序号" type="index" fixed="left" />
      <ht-table-column v-for="i in labelToProps" :key="i.prop" :label="i.label" :prop="i.prop" :sortable="i.sortable" :min-width="i.width || '120px'">
        <template v-slot="{row}">
          <span v-if="i.format === 'status'">
            <status :status="row.status.value" :status-types="{ 草稿: 'danger', 已提交: 'success' }" />
          </span>
          <span v-else>
            <span v-if="i.secondProp">{{ (row[i.firstProp] && row[i.firstProp][i.secondProp]) ? row[i.firstProp][i.secondProp] : '-' }}</span>
            <span v-else>{{ row[i.prop] }}</span>
          </span>
        </template>
      </ht-table-column>
      <ht-table-column-operation min-width="140px">
        <template v-slot="{row}">
          <ht-button type="text" icon="el-icon-view" @click="gotoView(row.id)">详情</ht-button>
          &nbsp;|&nbsp;&nbsp;
          <ht-popover-button
            icon="el-icon-delete"
            type="text"
            :danger-text="true"
            confirm-title="请确认是否要删除该条数据？"
            @confirmed="deleteItem(row)"
          >删除
          </ht-popover-button>
        </template>
      </ht-table-column-operation>
    </ht-table>
    <ht-pagination
      style="float: right;margin-bottom: 10px"
      :total="page.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.perPage"
      @pagination="getList"
    />
  </el-card>
</template>

<script>
import HtListContainer from '@/components/HtListContainer'
import { getHiddenDangerRecordsApi, deleteHiddenDangerRecordDetail } from '@/api/secureEnvironmentMgr/hiddenDangerRegister'
import { sortQuery } from '@/utils'

export default {
  extends: HtListContainer,
  data() {
    return {
      labelToProps: [
        {
          label: '编号',
          prop: 'recordNo',
          width: '150px'
        },
        {
          label: '检查类型',
          prop: 'checkType',
          firstProp: 'checkType',
          secondProp: 'value',
          sortable: 'custom',
          width: '110px'
        },
        {
          label: '隐患等级',
          prop: 'grade',
          firstProp: 'grade',
          secondProp: 'value',
          sortable: 'custom',
          width: '110px'
        },
        {
          label: '创建时间',
          prop: 'createdAt',
          sortable: 'custom',
          width: '150px'
        },
        {
          label: '登记人',
          prop: 'staff.fullName',
          firstProp: 'staff',
          secondProp: 'fullName',
          sortable: 'custom',
          width: '90px'
        },
        {
          label: '位置区域',
          prop: 'locationAndInfo',
          sortable: 'custom'
        },
        {
          label: '备注',
          prop: 'comment'
        },
        {
          label: '状态',
          format: 'status',
          prop: 'status',
          sortable: 'custom',
          width: '90px'
        }
      ],
      showSelector: false,
      selectedCheckRecord: [],
      searchForm: {
        searchStaffName: undefined,
        searchDate: undefined
      },
      checkTypeList: [
        { value: 'ROUTINE', label: '日常检查' },
        { value: 'EXPERT', label: '专家检查' },
        { value: 'RETURN', label: '复工检查' },
        { value: 'HOLIDAY', label: '节假日检查' },
        { value: 'SEASONALITY', label: '季节性检查' }
      ],
      gradeList: [
        { value: 'COMMONLY', label: '一般隐患' },
        { value: 'FIRST', label: '一级隐患' },
        { value: 'SECOND', label: '二级隐患' },
        { value: 'THIRD', label: '三级隐患' },
        { value: 'GREAT', label: '特级隐患' }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beforeGetList()
      getHiddenDangerRecordsApi(this.listQuery).then((resp) => {
        this.afterGetList(resp)
      })
    },
    getRowKey(row) {
      return row.uuid
    },
    selectionChange(val) {
      this.selectedCheckRecord = val
    },
    sortChange(ev) {
      this.listQuery.sort = sortQuery(ev)
      this.getList()
    },
    // showSelectedCheckRecord() {
    //   this.showSelector = true
    //   this.$message({
    //     message: '请勾选需要批量删除的记录',
    //     center: true,
    //     type: 'warning'
    //   })
    // },
    deleteItem(row) {
      deleteHiddenDangerRecordDetail(row.id).then(() => {
        this.$message({ message: '删除成功！', center: true, type: 'success' })
        this.getList()
      })
    },
    handleSelect(id) {
      this.listQuery.staffId = id
    },
    selectDate(date) {
      if (date) {
        this.listQuery.startAt = date[0]
        this.listQuery.endAt = date[1]
      }
    },
    handleRowClick(row, column) {
      if (column.label !== '操作' && !this.showSelector) {
        this.gotoView(row.id)
      }
    },
    gotoView(recordId) {
      this.$router.push({ name: 'hiddenDangerRegisterView', params: { recordId: recordId }})
    },
    mySearch() {
      const searchForm = this.$refs.commonSearch.searchForm
      if (searchForm.searchStaffName) {
        if (this.listQuery.staffId) {
          this.onSearch()
        } else {
          this.$message({ message: '请在下拉框内选择员工后进行搜索', center: true, type: 'warning' })
        }
      } else if (this.listQuery.startAt && this.listQuery.endAt) {
        this.onSearch()
      } else {
        this.onSearch()
      }
    },
    myClear() {
      this.$refs.commonSearch.searchForm = { searchStaffName: undefined, searchDate: undefined }
      this.onClear()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__body tr.hover-row>td{
  background-color: #eaecfc !important;
}
</style>
