var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "repair-statistics-container" }, [
    _c(
      "div",
      { staticClass: "single-statistical-card-container" },
      _vm._l(_vm.repairTaskWorkOrderStatistics, function (item, index) {
        return _c("single-statistical-card", {
          key: index,
          attrs: { title: item.name, count: item.value },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "statistics-chart-container" },
      [
        _c(
          "div",
          { staticClass: "statistics-query-container" },
          [
            _c(
              "div",
              { staticClass: "statistics-query-dimension-container" },
              _vm._l(_vm.timeDimension, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "statistics-query-dimension",
                    class: {
                      "is-selected-dimension":
                        item.name === _vm.isRepairSelectedDimension,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectQueryRepairTimeDimension(item.name)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.value))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticStyle: { width: "350px" },
              attrs: {
                type: _vm.getRepairTimeDimension.rangeType,
                "range-separator": "至",
                "start-placeholder": _vm.getRepairTimeDimension.startLabel,
                "end-placeholder": _vm.getRepairTimeDimension.endLabel,
                "picker-options": _vm.pickerOptions,
              },
              on: { blur: _vm.resetMinDate, change: _vm.repairDateRangeChange },
              model: {
                value: _vm.repairDateRange,
                callback: function ($$v) {
                  _vm.repairDateRange = $$v
                },
                expression: "repairDateRange",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("dw-statistic-chart", {
                  attrs: {
                    "is-loading": _vm.isRepairCountChartLoading,
                    title: "维修次数统计",
                    "box-style": { "margin-right": "20px", width: "100%" },
                    "title-style": { width: "220px" },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "chart",
                      fn: function () {
                        return [
                          _c("repair-count-chart", {
                            ref: "repairCountChart",
                            attrs: {
                              "first-name": "维修完成工单数",
                              "second-name": "设备数量",
                              "x-unit": "时间",
                              "y-unit": "数量",
                              height: "300px",
                              color1: _vm.variables.primaryColor,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("dw-statistic-chart", {
                  attrs: {
                    "is-loading": _vm.isRepairCountChartLoading,
                    title: "维修完成情况统计",
                    "box-style": { "margin-right": "20px", width: "100%" },
                    "title-style": { width: "220px" },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "chart",
                      fn: function () {
                        return [
                          _c("repair-finish-chart", {
                            ref: "repairFinishChart",
                            attrs: {
                              "first-name": "已完成工单数",
                              "second-name": "未完成工单数",
                              "x-unit": "数量",
                              "y-unit": "时间",
                              height: "300px",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }