export const YES_NO_OPTIONS = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const EXISTED_OR_NOT_OPTIONS = [
  { label: '有', value: true },
  { label: '无', value: false }
]

export const COLORS = {
  primary: '#1890FF',
  warning: '#FEB83D',
  danger: '#FE5153',
  info: '#92959A',
  success: '#1DC763'
}

export const DIALOG_WIDTH = {
  mini: '400px',
  medium: '600px',
  default: '800px',
  large: '1000px',
  xlarge: '1200px'
}

export class DialogCtr {
  constructor(initTableHeight = 650, offsetHeight = 160, fullscreen = false) {
    this.visible = false
    this.fullscreen = fullscreen
    this.fullscreenBtn = ['全屏', '小屏']
    this.initTableHeight = initTableHeight
    this.maxTableHeight = initTableHeight
    this.offsetHeight = offsetHeight
    this.dialogWidth = { ...DIALOG_WIDTH }
  }
  onToggleFullscreen() {
    this.fullscreen = !this.fullscreen
    if (this.initTableHeight === null) {
      return
    }
    if (this.fullscreen) {
      this.maxTableHeight = (document.documentElement.clientHeight || document.body.clientHeight) - this.offsetHeight
    } else {
      this.maxTableHeight = this.initTableHeight
    }
  }
  getFullScreenBtnTitle() {
    return this.fullscreenBtn[Number(this.fullscreen)]
  }
}

export const ROLES = {
  STAFF: '员工',
  ADMIN: '管理员'
}
