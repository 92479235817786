import { render, staticRenderFns } from "./documentList.vue?vue&type=template&id=64530197&scoped=true&"
import script from "./documentList.vue?vue&type=script&lang=js&"
export * from "./documentList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64530197",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangmin/Projects/devidia_projects/devidia-p1-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64530197')) {
      api.createRecord('64530197', component.options)
    } else {
      api.reload('64530197', component.options)
    }
    module.hot.accept("./documentList.vue?vue&type=template&id=64530197&scoped=true&", function () {
      api.rerender('64530197', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/constructionEquipmentMgr/equipmentMgr/components/documentList.vue"
export default component.exports