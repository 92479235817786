import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from 'element-ui'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
import '@/styles/business.scss'
import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
// import 'vue2-animate/src/sass/vue2-animate.scss'
import * as filters from './filters' // global filters
import '../src/styles/common.scss'
import { fabric } from 'fabric'

// 导出excel
// file-saver组件
import FileSaver from 'file-saver'
// xlsx组件
import * as XLSX from 'xlsx'
// ExcelJS
import ExcelJS from 'exceljs'

import VueVideoPlayer from 'vue-video-player'
import '../src/styles/videoPlayer.scss'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import log from './utils/log'
// region HackTech Components
import HtForm from '@/components/HtForm'
import HtButton from '@/components/HtButton'
import HtButtonAdd from '@/components/HtButtonAdd'
import HtFormSubmit from '@/components/HtFormSubmit'
import HtActionPanel from '@/components/HtActionPanel'
import HtPopoverButton from '@/components/HtPopoverButton'
import HtUploadButton from '@/components/HtUploadButton'
import HtUploadPanel from '@/components/HtUploadButton'
import HtDownloadLink from '@/components/HtDownloadLink'
import HtFileFormItem from '@/components/HtFileFormItem'
import HtCascaderZones from '@/components/HtCascaderZones'
import HtAsyncImportButton from '@/components/HtAsyncImportButton'
import HtTable from '@/components/HtTable'
import HtTableColumn from '@/components/HtTableColumn'
import HtTableColumnIndex from '@/components/HtTableColumnIndex'
import HtTableColumnSelection from '@/components/HtTableColumnSelection'
import HtTableColumnOperation from '@/components/HtTableColumnOperation'
import HtSwitch from '@/components/HtSwitch'
import HtPagination from '@/components/HtPagination'
import HtYesNoTags from '@/components/HtYesNoTags'
import HtTableDraggable from '@/components/HtTableDraggable'
import HtSelect from '@/components/HtSelect'
import HtHistoryRecorder from '@/components/HtHistoryRecorder'
import HtHelpTag from '@/components/HtHelpTag'
import HtPrice from '@/components/HtPrice'
import HtTitle from '@/components/HtTitle'
import HtAuditPanel from '@/components/HtAuditPanel'
import HtAutoWidthRow from '@/components/HtAutoWidthRow'
import HtOptions from '@/components/HtOptions'
import HtDropdown from '@/components/HtDropdown'
import HtPrint from '@/components/HtPrint'
import VueQr from 'vue-qr'
import Status from '@/views/components/status'
import CommonSearch from '@/views/components/commonSearch'
import AppTable from '@/components/table'
import HtDialog from '@/views/components/dialog/index'
import DwTitle from '@/components/DwTitle'
import DwDefaultAvatar from '@/components/DwDefaultAvatar'
import DwImage from '@/components/DwImage'
import DwFile from '@/components/DwFile'
import DwChartTitle from '@/components/DwChartTitle'
import DwStatisticChart from '@/components/DwStatisticChart'
import DwCarousel from '@/components/DwCarousel/src/main'
import DwCarouselItem from '@/components/DwCarousel/src/item'
import DwElImage from '@/components/DwElImage/src/main'
import DwElImageViewer from '@/components/DwElImage/src/image-viewer'
import DwChinaZonesSelect from '@/components/DwChinaZonesSelect'
import DwMenu from '@/components/DwMenu'
import DwMenuItem from '@/components/DwMenuItem'
import DwDivider from '@/components/DwDivider'
import DwCollapsePane from '@/components/DwCollapsePane'
import DwTinymceEditor from '@/components/DwTinymceEditor'
import TinymceEditor from '@tinymce/tinymce-vue'
import DwTableDropdownButtons from '@/components/DwTableDropdownButtons'

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
Vue.prototype.$log = log
// Vue.prototype.$fabric = fabric
Vue.prototype.isBeingInvited = false
Vue.prototype.imIsConnected = false
Vue.prototype.isInMeeting = false

// excel
Vue.prototype.FileSaver = FileSaver
Vue.prototype.XLSX = XLSX
Vue.prototype.ExcelJS = ExcelJS

Vue.use(fabric)
// import * as Sentry from '@sentry/vue'

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: 'mini' // set element-ui default size
})

Vue.component('HtDialog', HtDialog)
Vue.component(AppTable.name, AppTable)
Vue.component('CommonSearch', CommonSearch)
Vue.component('Status', Status)
Vue.component('VueQr', VueQr)
Vue.component(HtForm.name, HtForm)
Vue.component('HtPrint', HtPrint)
Vue.component(HtButton.name, HtButton)
Vue.component(HtButtonAdd.name, HtButtonAdd)
Vue.component(HtFormSubmit.name, HtFormSubmit)
Vue.component(HtActionPanel.name, HtActionPanel)
Vue.component(HtPopoverButton.name, HtPopoverButton)
Vue.component(HtUploadButton.name, HtUploadButton)
Vue.component(HtDownloadLink.name, HtDownloadLink)
Vue.component(HtFileFormItem.name, HtFileFormItem)
Vue.component(HtCascaderZones.name, HtCascaderZones)
Vue.component(HtAsyncImportButton.name, HtAsyncImportButton)
Vue.component(HtSwitch.name, HtSwitch)
Vue.component(HtTable.name, HtTable)
Vue.component(HtTableColumn.name, HtTableColumn)
Vue.component(HtTableColumnIndex.name, HtTableColumnIndex)
Vue.component(HtTableColumnSelection.name, HtTableColumnSelection)
Vue.component(HtTableColumnOperation.name, HtTableColumnOperation)
Vue.component(HtPagination.name, HtPagination)
Vue.component(HtYesNoTags.name, HtYesNoTags)
Vue.component(HtTableDraggable.name, HtTableDraggable)
Vue.component(HtSelect.name, HtSelect)
Vue.component(HtHistoryRecorder.name, HtHistoryRecorder)
Vue.component(HtUploadPanel.name, HtUploadPanel)
Vue.component(HtHelpTag.name, HtHelpTag)
Vue.component(HtPrice.name, HtPrice)
Vue.component(HtTitle.name, HtTitle)
Vue.component(HtAuditPanel.name, HtAuditPanel)
Vue.component(HtAutoWidthRow.name, HtAutoWidthRow)
Vue.component(HtOptions.name, HtOptions)
Vue.component(HtDropdown.name, HtDropdown)
Vue.component(DwTitle.name, DwTitle)
Vue.component(DwDefaultAvatar.name, DwDefaultAvatar)
Vue.component(DwImage.name, DwImage)
Vue.component(DwFile.name, DwFile)
Vue.component(DwChartTitle.name, DwChartTitle)
Vue.component(DwStatisticChart.name, DwStatisticChart)
Vue.component('DwCarousel', DwCarousel)
Vue.component('DwCarouselItem', DwCarouselItem)
Vue.component(DwElImage.name, DwElImage)
Vue.component(DwElImageViewer.name, DwElImageViewer)
Vue.component(DwChinaZonesSelect.name, DwChinaZonesSelect)
Vue.component(DwMenu.name, DwMenu)
Vue.component(DwMenuItem.name, DwMenuItem)
Vue.component(DwDivider.name, DwDivider)
Vue.component(DwCollapsePane.name, DwCollapsePane)
Vue.component('TinymceEditor', TinymceEditor)
Vue.component(DwTinymceEditor.name, DwTinymceEditor)
Vue.component(DwTableDropdownButtons.name, DwTableDropdownButtons)

// Vue-moment
const moment = require('moment')
require('moment/locale/zh-cn')
Vue.use(require('vue-moment'), {
  moment
})

// endregion
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 讯飞sparkBot使用的replaceAll，方式眼镜端还不支持，此处对不支持replaceAll的替换为replace
if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function(search, replacement) {
    if (typeof search === 'string') {
      // 转义正则表达式中的特殊字符
      const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      // 创建全局正则表达式
      const regExp = new RegExp(escapedSearch, 'g')
      return this.replace(regExp, replacement)
    }
    return this.replace(search, replacement) // 如果 search 已经是 RegExp 对象
  }
}

Vue.config.productionTip = false

// Sentry.init({
//   Vue,
//   dsn: 'https://de8510a80e1489d4dcea8cbc9f75dccd@o315407.ingest.sentry.io/4505764015308800',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', 'https://api.devidiadigital.com:5001/dw/v1/'],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//     }),
//     new Sentry.Replay()
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
