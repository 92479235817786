var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "ht-action-panel",
        { attrs: { "on-search": _vm.onSearch, "on-clear": _vm.onClearSearch } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "设备名称" },
                  model: {
                    value: _vm.listQuery.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "name", $$v)
                    },
                    expression: "listQuery.name",
                  },
                },
                _vm._l(_vm.EquipmentTypesEnum, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "产权编号" },
                model: {
                  value: _vm.listQuery.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "code", $$v)
                  },
                  expression: "listQuery.code",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "规格型号" },
                model: {
                  value: _vm.listQuery.specModel,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "specModel", $$v)
                  },
                  expression: "listQuery.specModel",
                },
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd",
                  placement: "bottom-start",
                  "start-placeholder": "出厂日期",
                  "end-placeholder": "出厂日期",
                },
                model: {
                  value: _vm.producedAt,
                  callback: function ($$v) {
                    _vm.producedAt = $$v
                  },
                  expression: "producedAt",
                },
              }),
              _vm._v(" "),
              _c("dw-china-zones-select", {
                ref: "zones",
                attrs: {
                  "province-code": _vm.listQuery.provinceCode,
                  "city-code": _vm.listQuery.cityCode,
                  "district-code": _vm.listQuery.districtCode,
                  placeholder: "请选择设备区域",
                },
                on: {
                  "update:provinceCode": function ($event) {
                    return _vm.$set(_vm.listQuery, "provinceCode", $event)
                  },
                  "update:province-code": function ($event) {
                    return _vm.$set(_vm.listQuery, "provinceCode", $event)
                  },
                  "update:cityCode": function ($event) {
                    return _vm.$set(_vm.listQuery, "cityCode", $event)
                  },
                  "update:city-code": function ($event) {
                    return _vm.$set(_vm.listQuery, "cityCode", $event)
                  },
                  "update:districtCode": function ($event) {
                    return _vm.$set(_vm.listQuery, "districtCode", $event)
                  },
                  "update:district-code": function ($event) {
                    return _vm.$set(_vm.listQuery, "districtCode", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "是否整机" },
                  model: {
                    value: _vm.listQuery.isWhole,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isWhole", $$v)
                    },
                    expression: "listQuery.isWhole",
                  },
                },
                _vm._l(_vm.EquipmentIsWholeEnum, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.value, value: item.name },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "是否异常" },
                  model: {
                    value: _vm.listQuery.isAbnormal,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isAbnormal", $$v)
                    },
                    expression: "listQuery.isAbnormal",
                  },
                },
                _vm._l(_vm.EquipmentAbnormalEnum, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.value, value: item.name },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "租赁状态" },
                  model: {
                    value: _vm.listQuery.leaseStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "leaseStatus", $$v)
                    },
                    expression: "listQuery.leaseStatus",
                  },
                },
                _vm._l(_vm.EquipmentLeaseStatusEnum, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-action-panel",
        [
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "ht-button",
                {
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v("新增设备")]
              ),
              _vm._v(" "),
              _vm.list.length > 0
                ? _c(
                    "ht-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-pie-chart" },
                      on: { click: _vm.openPatrolStatisticalCakeDialog },
                    },
                    [_vm._v("工单统计")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          staticStyle: { cursor: "pointer" },
          attrs: { data: _vm.list },
          on: { "row-click": _vm.handleRowClick },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.EquipmentListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: _vm.isMobile ? false : item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "设备区域"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row[item.prop] && row[item.prop].name
                                    ? row.province.name +
                                        "/" +
                                        row.city.name +
                                        "/" +
                                        row.district.name
                                    : "-"
                                )
                              ),
                            ])
                          : item.label === "独立高度"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row[item.prop] ? row[item.prop] + "米" : "-"
                                )
                              ),
                            ])
                          : item.label === "设备价值"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row[item.prop] ? row[item.prop] + "万元" : "-"
                                )
                              ),
                            ])
                          : item.label === "设备图片"
                          ? _c(
                              "div",
                              { staticClass: "img-container" },
                              [
                                row.img
                                  ? _c("el-image", {
                                      staticClass: "img",
                                      attrs: {
                                        src: row.img,
                                        "preview-src-list": [row.img],
                                      },
                                    })
                                  : _c("span", [_vm._v(_vm._s("-"))]),
                              ],
                              1
                            )
                          : item.label === "设备资料"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type:
                                        row.documentInformation &&
                                        row.documentInformation.length > 0
                                          ? "primary"
                                          : "info",
                                      disabled:
                                        row.documentInformation &&
                                        row.documentInformation.length === 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDocumentDialog(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        row.documentInformation &&
                                          row.documentInformation.length
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : item.label === "租赁状态"
                          ? [
                              row.isWhole
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm.EquipmentLeaseStatusStyleEnum[
                                          row[item.prop]
                                        ],
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.EquipmentLeaseStatusEnum[
                                            row[item.prop]
                                          ] || "-"
                                        )
                                      ),
                                    ]
                                  )
                                : _c("span", [_vm._v("-")]),
                            ]
                          : item.label === "是否异常"
                          ? [
                              row.isWhole
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type:
                                          _vm.getIsAbnormal(row[item.prop]) &&
                                          _vm.getIsAbnormal(row[item.prop])
                                            .style,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          (_vm.getIsAbnormal(row[item.prop]) &&
                                            _vm.getIsAbnormal(row[item.prop])
                                              .value) ||
                                            "-"
                                        )
                                      ),
                                    ]
                                  )
                                : _c("span", [_vm._v("-")]),
                            ]
                          : item.label === "本月工单完成情况"
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type:
                                    (_vm.getProgress(row[item.prop]) &&
                                      _vm.getProgress(row[item.prop]).style) ||
                                    "info",
                                  disabled: !_vm.getProgress(row[item.prop]),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openWorkOrderDialog(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getProgress(row[item.prop])
                                      ? _vm.getProgress(row[item.prop]).value +
                                          row.progressRatio
                                      : "无巡检工单"
                                  )
                                ),
                              ]
                            )
                          : item.label === "出厂日期"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.isWhole ? row[item.prop] || "-" : "-"
                                )
                              ),
                            ])
                          : item.label === "是否整机"
                          ? _c("span", [
                              _vm._v(_vm._s(row[item.prop] ? "是" : "否")),
                            ])
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.isMobile
            ? _c("ht-table-column-operation", {
                attrs: { width: 110 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("dw-table-dropdown-buttons", {
                            attrs: { buttons: row.buttons, row: row },
                            on: {
                              "handle-button-click": function ($event) {
                                return _vm.handleButtonClick(row, arguments)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2614970760
                ),
              })
            : _c("ht-table-column-operation", {
                attrs: { width: _vm.showChecklist ? 280 : 200 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "ht-button",
                          {
                            attrs: { icon: "el-icon-view", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onView(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _vm.showChecklist
                          ? _c(
                              "ht-button",
                              {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-tickets",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openChecklistDialog(row)
                                  },
                                },
                              },
                              [_vm._v("快检清单")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "ht-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ht-popover-button",
                          {
                            attrs: {
                              type: "text",
                              "danger-text": true,
                              icon: "el-icon-delete",
                              "confirm-title": "请确认是否要删除该设备？",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.onDelete(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c("ht-pagination", {
        staticStyle: { float: "right", "margin-bottom": "10px" },
        attrs: {
          total: _vm.page.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "createEquipmentFormDialog",
          attrs: {
            title: "新增设备",
            width: "800px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeCreateEquipmentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showCreateEquipmentForm
                ? _c("equipment-form", {
                    on: {
                      "cancel-submit": _vm.closeCreateEquipmentDialog,
                      "confirm-submit": _vm.submitCreateEquipmentForm,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "documentDialog",
          attrs: { title: "设备资料", width: "800px" },
          on: { close: _vm.closeDocumentDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showDocumentList
                ? _c("document-list", {
                    ref: "documentList",
                    attrs: {
                      "document-list": _vm.documentList,
                      "show-operation": false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "leaseDetailDialog",
          attrs: { title: "租用详情", width: "1000px" },
          on: { close: _vm.closeLeaseDetailDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showLeaseDetailList
                ? _c("lease-detail-list", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.leaseDetailListLoading,
                        expression: "leaseDetailListLoading",
                      },
                    ],
                    ref: "leaseDetailList",
                    attrs: { "lease-detail-list": _vm.leaseDetails },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "patrolProgressChartDialog",
          attrs: { title: "工单完成情况", width: "650px" },
          on: { close: _vm.closePatrolStatisticalCakeDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "ht-action-panel",
                [
                  _c(
                    "template",
                    { slot: "right" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          type: "month",
                          "value-format": "yyyy-MM",
                          placeholder: "选择月",
                        },
                        on: { change: _vm.patrolQueryDateChange },
                        model: {
                          value: _vm.patrolProgressQueryDate,
                          callback: function ($$v) {
                            _vm.patrolProgressQueryDate = $$v
                          },
                          expression: "patrolProgressQueryDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.showPatrolProgressChart
                ? _c("patrol-progress-chart", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isPatrolProgressChartLoading,
                        expression: "isPatrolProgressChartLoading",
                      },
                    ],
                    ref: "patrolProgressChart",
                    attrs: { count: _vm.patrolProgressCount, height: "300px" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "workOrderDialog",
          attrs: { title: "本月工单", width: "1000px" },
          on: { close: _vm.closeWorkOrderDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.getWorkOrderList },
                  model: {
                    value: _vm.workOrderActiveName,
                    callback: function ($$v) {
                      _vm.workOrderActiveName = $$v
                    },
                    expression: "workOrderActiveName",
                  },
                },
                _vm._l(_vm.getTaskTempTypeEnum(), function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.value, name: item.name },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.showWorkOrderList
                ? _c("work-order-list", {
                    ref: "workOrderList",
                    attrs: {
                      "is-loading": _vm.isWorkOrderLoading,
                      "table-title": _vm.workOrderTableTitle,
                      list: _vm.workOrderList,
                      page: _vm.workOrderPage,
                      "list-query": _vm.workOrderListQuery,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ht-dialog",
        {
          ref: "checklistFormDialog",
          attrs: { title: "快检清单", width: "600px" },
          on: { close: _vm.closeChecklistDialog },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showChecklistForm
                ? _c("checklist-form", {
                    ref: "checklistForm",
                    attrs: { "equipment-info": _vm.equipmentInfo },
                    on: {
                      "cancel-submit": _vm.closeChecklistDialog,
                      "confirm-submit": function ($event) {
                        return _vm.closeChecklistDialog(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }