<template>
  <div class="workflow-steps-container">
    <template v-for="(item, index) in checks">
      <transition :key="index" :name="transitionName">
        <quick-patrol-record-type
          v-if="currentCheckIndex === index"
          :key="index"
          :count="count"
          :current-index="currentIndex"
          :is-last-step="isLastStep"
          :check-count="checks.length"
          :current-check-index="currentCheckIndex"
          :is-last-check="index === checks.length - 1"
          :results="item"
          class="component"
          @to-prev="handleActions('to-prev')"
          @to-next="toNext"
          @jump-step="jumpStep"
          @to-remote-guidance="handleActions('to-remote-guidance')"
          @to-ai-qa-assistant="handleActions('to-ai-qa-assistant')"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import quickPatrolRecordType from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/quickPatrolRecordType/stepUi'
import { putStepResultNoAuthorization } from '@/api/taskMgr/workOrder'

export default {
  name: 'QuickPatrolWorkOrder',
  components: { quickPatrolRecordType },
  props: {
    workFlow: { type: Object, default: () => {} },
    count: { type: Number, default: 0 },
    currentIndex: { type: Number, default: 0 },
    isLastStep: { type: Boolean, default: false },
    accessToken: { type: String, default: null }
  },
  data() {
    return {
      quickPatrolRecordType,
      submitWorkFlow: this.workFlow,
      checks: [],
      currentCheckIndex: null,
      transitionName: 'fade'
    }
  },
  created() {
    this.getWorkOrderDetail()
  },
  methods: {
    getWorkOrderDetail() {
      this.checks = this.submitWorkFlow.results.checkItems
      // const index = this.checks.findIndex(item => item.isExecuting === true)
      // this.currentCheckIndex = index !== this.checks.length - 1 ? index + 1 : this.checks.length - 1
      this.currentCheckIndex = 0
      this.currentCheck = this.checks[this.currentCheckIndex]
    },
    handleActions(action) {
      this.$emit(action, this.submitWorkFlow)
      this.showMoreFunction = false
    },
    toNext(results) {
      this.submitStep(results, this.currentCheckIndex + 1 === this.checks.length).then((currentCheck) => {
        if (this.currentCheckIndex + 1 === this.checks.length) {
          if (this.count === this.currentIndex + 1) {
            this.$emit('submit-record', currentCheck)
          } else {
            this.$emit('to-next', currentCheck)
          }
        } else {
          this.currentCheckIndex++
          this.currentCheck = this.checks[this.currentCheckIndex]
        }
      })
    },
    jumpStep(index, results) {
      this.submitStep(results, this.currentCheckIndex + 1 === this.checks.length).then((currentCheck) => {
        this.$emit('jump-step', index, currentCheck)
      })
    },
    submitStep(results, isSubmit = false) {
      return new Promise((resolve, reject) => {
        this.checks.forEach(item => {
          item.isExecuting = false
        })
        this.getCurrentStepData()
        this.checks[this.currentCheckIndex].isExecuting = true
        this.submitWorkFlow.results.checkItems = this.checks
        const currentCheck = isSubmit ? { ...this.submitWorkFlow, isSubmit: true } : { ...this.submitWorkFlow }
        putStepResultNoAuthorization(this.accessToken, this.submitWorkFlow.id, currentCheck).then((resp) => {
          resolve(currentCheck)
        }).catch(() => {
          reject()
        })
      })
    },
    getCurrentStepData() {
      const step = {}
      const keys = ['id', 'isEnd', 'isStart', 'name', 'properties', 'results', 'tag']
      keys.forEach(key => {
        step[key] = this.submitWorkFlow[key]
      })
      this.submitWorkFlow = step
    }
  }
}
</script>

<style scoped lang="scss">
.workflow-steps-container{
  height: 100%;
  background: #e4edfa;
  .workflow-title{
    background: #e4edfa;
    font-size: 1.3rem;
    padding: 5px 20px;
    font-weight: bold;
  }

  .component{
    background: #e4edfa;
  }
  .step-item{
    height: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
