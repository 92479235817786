<template>
  <div class="workflow-steps-container">
    <div class="workflow-title">{{ `工单名称：${workFlow && workFlow.name || '-'}` }}</div>
    <template v-for="(item, index) in workflowSteps">
      <transition :key="index" :name="transitionName">
        <component
          :is="item.stepUi"
          v-if="currentWorkflowStepIndex === index && showWorkflowStep"
          :key="index"
          :count="workflowSteps.length"
          :current-index="currentWorkflowStepIndex"
          :is-last-step="index === workflowSteps.length - 1"
          :properties="item.properties"
          :results="item.results"
          class="component"
          @to-prev="toPrevWorkflowStep"
          @to-next="toNextWorkflowStep"
          @to-remote-guidance="toRemoteGuidance"
          @to-ai-qa-assistant="toAiQaAssistant"
          @to-repair-record="toRepairRecord"
          @save-record="saveRecord"
          @submit-record="submitRecord"
        />
      </transition>
    </template>
    <template v-for="(item, index) in repairRecordsSteps">
      <transition :key="index" :name="transitionName">
        <component
          :is="item.stepInfo.stepUi"
          v-if="currentRepairRecordsStepIndex === index && !showWorkflowStep"
          :key="index"
          :count="repairRecordsSteps.length"
          :current-index="currentRepairRecordsStepIndex"
          :is-last-step="index === repairRecordsSteps.length - 1"
          :properties="item.stepInfo.properties"
          :results="item.stepInfo.results"
          class="component"
          @to-remote-guidance="toRemoteGuidance"
          @to-ai-qa-assistant="toAiQaAssistant"
          @continue-handle="continueHandleRepairRecords"
          @submit-record="submitHandleRepairRecords"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import AndroidWebViewMixin from '@/views/components/androidWebViewMixin.vue'
import { getWorkOrderNoAuthorization, putStepResultNoAuthorization } from '@/api/taskMgr/workOrder'
import { StepsUiProperties } from '@/views/taskMgr/templateMgr/components/workFlowSteps'
import _ from 'lodash'

export default {
  name: 'WorkFlowSteps',
  mixins: [AndroidWebViewMixin],
  data() {
    return {
      StepsUiProperties,
      accessToken: null,
      transitionName: 'slide-next',
      workFlow: {},
      workflowSteps: [],
      currentWorkflowStepIndex: null,
      currentWorkflowStep: null,
      showWorkflowStep: true,
      repairRecordsSteps: [],
      currentRepairRecordsStepIndex: null,
      currentRepairRecordsStep: null
    }
  },
  created() {
    if (this.$route.query && this.$route.query.token) {
      this.accessToken = `JWT ${this.$route.query.token}`
      const templateId = this.$route.query.id
      if (templateId !== '0') {
        this.getWorkOrderDetail(this.accessToken, templateId)
      }
    }
  },
  methods: {
    getWorkOrderDetail(token, templateId) {
      getWorkOrderNoAuthorization(token, templateId).then(resp => {
        this.workFlow = resp
        if (resp.type === 'REPAIR') {
          this.handleRepairData(resp)
        } else {
          this.handlePatrolData(resp)
        }
      })
    },
    // 维修处理
    handleRepairData(resp) {
      this.currentWorkflowStepIndex = 0
      resp.steps.forEach(stepItem => {
        let step = {}
        const index = StepsUiProperties.findIndex(uiItem => uiItem.tag === stepItem.tag)
        if (index !== -1) {
          step = _.cloneDeep(StepsUiProperties[index])
          const keys = ['id', 'isEnd', 'isStart', 'properties', 'results']
          keys.forEach(key => {
            step[key] = stepItem[key]
          })
          step.properties = {
            deviceName: resp.targetName,
            deviceImg: resp.targetInfo.deviceImg,
            deviceDesc: null,
            deviceLocation: null
          }
          step.results.errorRecords = resp.targetInfo.errorList
          step.results.errorRecords.forEach(item => {
            item.stepInfo = _.cloneDeep(step.bindRecordStepUiProperties)
          })
          this.workflowSteps.push(step)
        }
      })
      this.currentWorkflowStep = this.workflowSteps[this.currentWorkflowStepIndex]
    },
    // 巡检处理
    handlePatrolData(resp) {
      this.currentWorkflowStepIndex = resp.currentWorkflowStep === 0 ? resp.currentWorkflowStep : resp.currentWorkflowStep - 1
      resp.steps.forEach(item => {
        let step = {}
        const index = StepsUiProperties.findIndex(uiItem => uiItem.tag === item.tag)
        if (index !== -1) {
          step = _.cloneDeep(StepsUiProperties[index])
          const keys = ['id', 'isEnd', 'isStart', 'properties', 'results']
          keys.forEach(key => {
            step[key] = item[key]
          })
          this.workflowSteps.push(step)
        }
      })
      this.currentWorkflowStep = this.workflowSteps[this.currentWorkflowStepIndex]
    },
    toPrevWorkflowStep(results) {
      this.transitionName = 'slide-prev'
      this.submitStep(results, false).then(() => {
        this.currentWorkflowStepIndex--
        this.currentWorkflowStep = this.workflowSteps[this.currentWorkflowStepIndex]
      })
    },
    toNextWorkflowStep(results) {
      this.transitionName = 'slide-next'
      this.submitStep(results, false).then(() => {
        this.currentWorkflowStepIndex++
        this.currentWorkflowStep = this.workflowSteps[this.currentWorkflowStepIndex]
      })
    },
    toRemoteGuidance(results) {
      this.submitStep(results, false).then(() => {
        this.nativeStartRemoteGuidance()
      })
    },
    toAiQaAssistant(results) {
      this.submitStep(results, false).then(() => {
        this.nativeOpenAiChat()
      })
    },
    saveRecord(results) {
      this.submitStep(results).then((resp) => {
        this.$message({ message: '保存成功', duration: 2000, type: 'success', customClass: 'large-info-message' })
        if (this.currentWorkflowStepIndex + 1 === this.workflowSteps.length) {
          // this.nativeOnSubmit()
        } else {
          this.currentWorkflowStepIndex++
          this.currentWorkflowStep = this.workflowSteps[this.currentWorkflowStepIndex]
        }
      })
    },
    submitRecord(results) {
      this.submitStep(results, true, true).then(() => {
        if (this.currentWorkflowStepIndex + 1 === this.workSteps.length) {
          this.nativeOnSubmit()
        } else {
          this.currentWorkflowStepIndex++
          this.currentWorkflowStep = this.workflowSteps[this.currentWorkflowStepIndex]
        }
      })
    },
    submitStep(results, checkData = true, isSubmit = false) {
      return new Promise((resolve, reject) => {
        this.currentWorkflowStep.result = results
        this.getCurrentStepData()
        const verifyStepResult = checkData && !isSubmit ? this.verifyStepData(this.currentWorkflowStep) : true
        const verifyStepsResult = isSubmit && this.currentWorkflowStep.isEnd ? this.verifyAllStepsData() : true
        const isAbnormal = this.checkDataIsAbnormal(this.currentWorkflowStep)
        if (verifyStepResult && verifyStepsResult) {
          const currentWorkflowStep = isSubmit && this.currentWorkflowStep.isEnd ? { ...this.currentWorkflowStep, isSubmit: true, isAbnormal: isAbnormal } : { ...this.currentWorkflowStep, isAbnormal: isAbnormal }
          putStepResultNoAuthorization(this.accessToken, this.currentWorkflowStep.id, currentWorkflowStep).then((resp) => {
            resolve(resp)
          }).catch(() => {
            reject()
          })
        } else {
          reject()
        }
      })
    },
    getCurrentStepData() {
      const step = {}
      const keys = ['id', 'isEnd', 'isStart', 'name', 'properties', 'results', 'tag']
      keys.forEach(key => {
        step[key] = this.currentWorkflowStep[key]
      })
      this.currentWorkflowStep = step
    },
    verifyStepData(stepData, needErrorMessage = true) {
      let verifyResult = true
      if (stepData.properties.mustUploadPhoto) {
        if (stepData.results && stepData.results.uploadPhotos && stepData.results.uploadPhotos.length < 1) {
          if (needErrorMessage) {
            this.$message({ message: '当前步骤必须拍摄照片', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      if (stepData.tag === 'ResultRecordType') {
        if (stepData.results && !stepData.results.checkResult) {
          if (needErrorMessage) {
            this.$message({ message: '当前步骤未选择检查结果', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      if (stepData.tag === 'ValueRecordType') {
        if (stepData.results && (!stepData.results.value && stepData.results.value !== 0)) {
          if (needErrorMessage) {
            this.$message({ message: '当前步骤未填写数据', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      return verifyResult
    },
    verifyAllStepsData() {
      const errorStep = []
      this.workflowSteps.forEach((step, index) => {
        if (!this.verifyStepData(step, false)) {
          errorStep.push(index + 1)
        }
      })
      if (errorStep.length > 0) {
        this.$message({ message: `当前工单第${errorStep.toString()}步有必填项未填写，请确认`, duration: 2000, type: 'error', customClass: 'large-info-message' })
        return false
      } else {
        return true
      }
    },
    checkDataIsAbnormal(stepData) {
      let checkedResult = false
      if (stepData.tag === 'ResultRecordType') {
        if (stepData.results && stepData.results.checkResult && stepData.results.checkResult === 'FAIL') {
          checkedResult = true
          return checkedResult
        }
      }
      if (stepData.tag === 'ValueRecordType') {
        if (stepData.results && stepData.results.value) {
          const minValue = parseFloat(stepData.properties.minValue)
          const maxValue = parseFloat(stepData.properties.maxValue)
          const value = parseFloat(stepData.results.value)
          if (minValue >= value || value >= maxValue) {
            checkedResult = true
            return checkedResult
          }
        }
      }
      return checkedResult
    },
    // 维修相关
    toRepairRecord() {
      this.showWorkflowStep = false
      this.currentRepairRecordsStepIndex = 0
      this.repairRecordsSteps = this.currentWorkflowStep.results.errorRecords
      this.currentRepairRecordsStep = this.repairRecordsSteps[this.currentRepairRecordsStepIndex]
    },
    continueHandleRepairRecords() {
      this.currentRepairRecordsStepIndex++
      this.currentRepairRecordsStep = this.repairRecordsSteps[this.currentRepairRecordsStepIndex]
    },
    submitHandleRepairRecords() {}
  }
}
</script>

<style scoped lang="scss">
.workflow-steps-container{
  height: 100%;
  background: #e4edfa;
  .workflow-title{
    background: #e4edfa;
    font-size: 1.3rem;
    padding: 5px 20px;
    font-weight: bold;
  }

  .component{
    background: #e4edfa;
  }
  .step-item{
    height: 100%;
  }
}

// 上一项动画
.slide-prev-enter-active {
  animation: slide-prev-enter 0.5s ease-in-out;
}
.slide-prev-leave-active {
  animation: slide-prev-leave 0.5s ease-in-out;
}

@keyframes slide-prev-enter {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-prev-leave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

// 下一项动画
.slide-next-enter-active {
  animation: slide-next-enter 0.5s ease-in-out;
}
.slide-next-leave-active {
  animation: slide-next-leave 0.5s ease-in-out;
}

@keyframes slide-next-enter {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-next-leave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
