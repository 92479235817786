var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-container", style: { width: _vm.footerWidth } },
    [
      _c("span", { staticClass: "local-hint" }, [_vm._v("本地操作")]),
      _vm._v(" "),
      _c("operating-button", {
        staticClass: "item",
        attrs: {
          name: "麦克风",
          icon: _vm.isShareAudio ? _vm.MicrophoneOpen : _vm.MicrophoneClose,
        },
        on: { click: _vm.shareAudioButtonClick },
      }),
      _vm._v(" "),
      _vm.showShareCameraVideo
        ? _c("operating-button", {
            staticClass: "item",
            attrs: {
              name: "摄像头",
              icon: _vm.isShareCameraVideo ? _vm.CameraOpen : _vm.CameraClose,
            },
            on: { click: _vm.shareVideoCameraButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showShareScreenVideo && !_vm.isMobile
        ? _c("operating-button", {
            staticClass: "item",
            attrs: {
              width: 75,
              name: _vm.isShareScreenVideo ? "结束共享" : "屏幕共享",
              icon: _vm.ScreenShare,
              "is-show-dot": _vm.isShareScreenVideo,
            },
            on: { click: _vm.shareScreenVideoButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRecordingVideo
        ? _c("operating-button", {
            staticClass: "item",
            attrs: {
              width: 75,
              name: _vm.isRecordingVideo ? "结束录屏" : "会议录屏",
              icon: _vm.ScreenRecording,
              "is-show-dot": _vm.isRecordingVideo,
            },
            on: { click: _vm.recordingVideoClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPen
        ? _c("operating-button", {
            staticClass: "item",
            attrs: {
              "is-disabled": !_vm.isHasVideo || _vm.isFreezeGuidance,
              name: "激光笔",
              icon: _vm.LaserPen,
              "is-show-dot": _vm.isLaserMoving,
            },
            on: { click: _vm.laserButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPaint
        ? _c("operating-button", {
            staticClass: "item",
            attrs: {
              "is-disabled": !_vm.isHasVideo || _vm.isFreezeGuidance,
              width: 75,
              name: "实时画笔",
              icon: _vm.Paintbrush,
              "is-show-dot": _vm.isPaintDrawing,
            },
            on: { click: _vm.paintButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPaint
        ? _c("operating-button", {
            staticClass: "item",
            attrs: {
              "is-disabled": !_vm.isHasVideo || _vm.isFreezeGuidance,
              width: 75,
              name: "清除轨迹",
              icon: _vm.Clear,
            },
            on: { click: _vm.clearButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showGuidance && !(_vm.isMobile && !_vm.isHasVideo)
        ? _c("operating-button", {
            staticClass: "item",
            attrs: {
              "is-disabled": !_vm.isHasVideo || _vm.isFreezeGuidance,
              width: 75,
              name: "标注指导",
              icon: _vm.Guidance,
              "is-show-dot": _vm.isFreezeGuidance,
            },
            on: { click: _vm.guidanceButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("operating-button", {
        staticClass: "item",
        attrs: {
          width: 75,
          name: "会议消息",
          icon: _vm.Message,
          "is-show-num": "",
          num: _vm.messageCount,
        },
        on: { click: _vm.messageButtonClick },
      }),
      _vm._v(" "),
      _c("operating-button", {
        staticClass: "item",
        attrs: {
          width: 75,
          name: "参会人员",
          icon: _vm.Attendee,
          "is-show-num": "",
          num: _vm.userCount,
        },
        on: { click: _vm.attendeeButtonClick },
      }),
      _vm._v(" "),
      _vm.showInvite
        ? _c("operating-button", {
            staticClass: "item",
            attrs: { width: 75, name: "邀请用户", icon: _vm.InvitePerson },
            on: { click: _vm.inviteButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showVideoResolution
        ? _c("operating-button", {
            staticClass: "item",
            attrs: { width: 100, name: "视频质量调整", icon: _vm.Adjust },
            on: { click: _vm.parameterButtonClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showVideoResolution && _vm.isShowVideoResolutionBox
        ? _c("box-config", {
            ref: "boxConfigRef",
            staticClass: "box-config",
            attrs: { "video-resolution": _vm.videoResolution },
            on: { "select-resolution": _vm.selectResolution },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "speed-container" }, [
        _vm.localBitrate && _vm.localBitrate.bitrateSend
          ? _c("div", { staticClass: "item" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.localBitrate.bitrateSend) +
                  " kbps\n      "
              ),
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.SendArrows, alt: "" },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.localBitrate && _vm.localBitrate.bitrateRecv
          ? _c("div", { staticClass: "item" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.localBitrate.bitrateRecv) +
                  " kbps\n      "
              ),
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.ReceiveArrows, alt: "" },
              }),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }