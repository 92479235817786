var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-container" },
    [
      _c(
        "video",
        {
          ref: "videoPlayer",
          staticClass: "video",
          attrs: { controls: "" },
          on: { play: _vm.handleCanPlay },
        },
        [_c("source", { attrs: { src: _vm.url, type: "video/mp4" } })]
      ),
      _vm._v(" "),
      _vm.showDel
        ? _c("ht-button", {
            staticClass: "del-button",
            attrs: { type: "danger", icon: "el-icon-delete", circle: "" },
            on: { click: _vm.deleteVideo },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }