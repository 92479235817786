var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          [
            [
              _c(
                "div",
                { staticClass: "right-menu-item name-size" },
                [
                  [
                    _vm.userInfo
                      ? _c("small", [_vm._v(_vm._s(_vm.userInfo.fullName))])
                      : _vm._e(),
                  ],
                ],
                2
              ),
            ],
            _vm._v(" "),
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "div",
                  { staticClass: "avatar-wrapper" },
                  [
                    _vm.userInfo && _vm.userInfo.avatarUrl
                      ? _c("img", {
                          staticClass: "user-avatar",
                          attrs: { src: _vm.AvatarImage },
                        })
                      : _c("dw-default-avatar", {
                          attrs: {
                            "full-name": _vm.userInfo && _vm.userInfo.fullName,
                            width: 40,
                            "font-size": 14,
                          },
                        }),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/profile/index" } },
                      [_c("el-dropdown-item", [_vm._v("个人中心")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      { attrs: { to: "/" } },
                      [_c("el-dropdown-item", [_vm._v("首页")])],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      { attrs: { to: "/reset-password" } },
                      [_c("el-dropdown-item", [_vm._v("修改密码")])],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-dropdown-item", { attrs: { divided: "" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v("登出")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }