var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.resultInfo
    ? _c(
        "div",
        { staticClass: "result-container" },
        [
          _c(
            "el-image",
            {
              staticClass: "left-img",
              attrs: {
                src: _vm.resultInfo.coverImg || _vm.KnowledgePlaceholder,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [_c("i", { staticClass: "el-icon-picture-outline" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-container" },
            [
              _vm.resultInfo.highlightTitle
                ? _c("h3", {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(_vm.resultInfo.highlightTitle[0]),
                    },
                    on: { click: _vm.onView },
                  })
                : _c(
                    "h3",
                    { staticClass: "title", on: { click: _vm.onView } },
                    [_vm._v(_vm._s(_vm.resultInfo.title))]
                  ),
              _vm._v(" "),
              _vm.resultInfo.highlightContent &&
              _vm.resultInfo.highlightContent.length > 0
                ? _vm._l(
                    _vm.resultInfo.highlightContent,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "highlight-content",
                          on: {
                            click: function ($event) {
                              return _vm.onView(index)
                            },
                          },
                        },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "circle" } }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getHighlightContent(item)),
                            },
                          }),
                        ],
                        1
                      )
                    }
                  )
                : _c("div", {
                    staticClass: "content",
                    domProps: {
                      innerHTML: _vm._s(_vm.getContent(_vm.resultInfo.content)),
                    },
                    on: { click: _vm.onView },
                  }),
              _vm._v(" "),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s("最新发布时间：" + _vm.resultInfo.publishedAt)),
              ]),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }