import dwCustomImMsg from '@/views/newRemoteGuidance/js/dwCustomImMsg'

export default {
  methods: {
    changeAllMicMute(val) {
      this.sendMicMuteChatRoomMsg(val)
    },
    changeAllCamMute(val) {
      if (this.isRemoteShareScreen || this.isShareScreenVideo) {
        this.$message({ message: `当前有用户正在进行屏幕共享，等其共享结束后再进行操作`, type: 'warning', center: true })
      } else {
        this.sendCamMuteChatRoomMsg(val)
      }
    },
    changeOneMicMute(imUserId, val) {
      if (imUserId === this.userInfo.imUserId) {
        this.audioShareFun(val)
      } else {
        this.sendMicMutePrivateMsg(imUserId, val)
        const obj = { isMicOn: val }
        this.changeDeviceStatus(imUserId, obj)
      }
    },
    // 修改用户的摄像头开关状态：修改位置为用户remoteVideo上，参会人列表上
    changeOneCamMute(imUserId, val) {
      // 聚焦指导模式下，开启或关闭内置摄像头，清除绘制内容，关闭画笔激光笔
      this.resetWhiteBoard(imUserId)
      if (imUserId === this.userInfo.imUserId) {
        // 参会人列表点击自己的摄像头，修改本地摄像头开关
        this.cameraVideoShareFun(val)
      } else {
        // 修改他人摄像头开关，发送相关command消息给被控制人员，
        // 并对本地存储的远端用户设备状态列表remoteDeviceStates和远端用户列表remoteUserList相关内容进行修改
        this.sendCamMutePrivateMsg(imUserId, val)
        const obj = { isCamOn: val }
        this.changeDeviceStatus(imUserId, obj)
      }
    },
    changeOneUvcCam(imUserId, val) {
      // 聚焦指导模式下，开启或关闭外置摄像头，清除绘制内容，关闭画笔激光笔
      this.resetWhiteBoard(imUserId)
      this.sendSwitchUvcCamMsg(imUserId, val)
      const obj = { isUvcCamOn: val }
      this.changeDeviceStatus(imUserId, obj)
    },
    changeOneCamSwitch(imUserId, val) {
      // 聚焦指导模式下，切换摄像头，清除绘制内容，关闭画笔激光笔
      this.resetWhiteBoard(imUserId)
      this.sendCamSwitchMsg(imUserId, val)
      const obj = { isFront: val }
      this.changeDeviceStatus(imUserId, obj)
    },
    changeOneZoomLevel(imUserId, val) {
      this.sendCamZoomMsg(imUserId, val)
      const obj = { camZoomLevel: val }
      this.changeDeviceStatus(imUserId, obj)
    },
    changeOneFlashlight(imUserId, val) {
      this.sendFlashlightMsg(imUserId, val)
      const obj = { isFlashOn: val }
      this.changeDeviceStatus(imUserId, obj)
    },
    changeOneExposureLevel(imUserId, val) {
      this.sendCamExposureMsg(imUserId, val)
      const obj = { camExposureLevel: val }
      this.changeDeviceStatus(imUserId, obj)
    },
    changeOneVideoResolution(imUserId, val) {
      this.sendVideoConfigMsg(imUserId, val)
      const obj = { videoResolution: val }
      this.changeDeviceStatus(imUserId, obj)
    },
    changeDeviceStatus(imUserId, obj) {
      const index = this.remoteUserList.findIndex(item => item.imUserId === imUserId)
      if (index !== -1 && this.remoteUserList[index] && this.remoteUserList[index].deviceStatus) {
        Object.assign(this.remoteUserList[index].deviceStatus, obj)
        this.$set(this.remoteUserList, index, this.remoteUserList[index])
        if (this.enLarge && this.enLargeVideoInfo.imUserId === imUserId) {
          this.enLargeVideoInfo = this.remoteUserList[index]
        }
      }
      const deviceIndex = this.remoteDeviceStates.findIndex(item => item.imUserId === imUserId)
      if (deviceIndex !== -1 && this.remoteDeviceStates[index] && this.remoteDeviceStates[index].deviceStatus) {
        Object.assign(this.remoteDeviceStates[index].deviceStatus, obj)
        this.$set(this.remoteDeviceStates, index, this.remoteDeviceStates[index])
      }
    },
    camManualFocused(data) {
      this.sendCamManualFocusMsg(data.imUserId, data.offsetX, data.offsetY)
    },
    meetingDeviceQueryStatusFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Command) {
        const data = {
          videoResolution: this.videoResolution,
          screenWidth: this.localVideoWH.width,
          screenHeight: this.localVideoWH.height,
          isMicOn: this.isShareAudio,
          isCamOn: this.isShareCameraVideo
        }
        this.sendDwDeviceStatusMsg(detail.senderId, data)
      }
    },
    meetingDeviceStatusFun(e) {
      // 现场设备状态同步消息处理
      const detail = e.detail
      // 聚焦指导模式下，开启或关闭内置摄像头，切换镜头，开启或关闭外置摄像头，清除绘制内容，关闭画笔激光笔
      this.resetWhiteBoard(detail.senderId)
      const deviceStatus = {
        isMicOn: detail.isMicOn || false,
        isCamOn: detail.isCamOn || false,
        isFront: detail.isFront || true,
        isFlashOn: detail.isFlashOn || false,
        isFlashSupported: detail.isFlashSupported || false,
        camZoomLevel: detail.camZoomLevel || 1,
        camExposureLevel: detail.camExposureLevel || 0,
        camExposureLevels: detail.camExposureLevels || null,
        videoResolution: detail.videoResolution,
        isUvcCamOn: detail.isUvcCamOn || false,
        isUvcConnected: detail.isUvcConnected || false
      }
      const videoProperty = {
        width: detail.screenWidth,
        height: detail.screenHeight
      }
      // 远端设备状态列表更新
      const deviceIndex = this.remoteDeviceStates.findIndex(item => item.imUserId === detail.senderId)
      if (deviceIndex === -1) {
        this.remoteDeviceStates.push({ imUserId: detail.senderId, deviceStatus, videoProperty })
      } else {
        this.remoteDeviceStates[deviceIndex] = { imUserId: detail.senderId, deviceStatus, videoProperty }
      }
      // 远端用户列表更新
      const index = this.remoteUserList.findIndex(item => item.imUserId === detail.senderId)
      if (index !== -1 && detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        this.remoteUserList[index].deviceStatus = deviceStatus
        this.remoteUserList[index].videoProperty = videoProperty
        this.$set(this.remoteUserList, index, this.remoteUserList[index])
        // 如果该状态是放大的用户的，修改enLargeVideoInfo
        if (this.enLarge && this.enLargeVideoInfo.imUserId === detail.senderId) {
          this.enLargeVideoInfo = this.remoteUserList[index]
          if (this.isFocusGuidance) {
            this.enLargeVideoInfo.isFocusedGuide = true
          }
        }
      }
    },
    // isMute静默。true为关闭false为开
    micMuteByLocalFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Command) {
        this.audioShareFun(!detail.isMute)
        setTimeout(() => {
          this.isMuteAllMic = detail.isMute
        }, 500)
      }
    },
    camMuteByLocalFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Command) {
        setTimeout(() => {
          this.isMuteAllCam = detail.isMute
        }, 500)
      }
    },
    micMuteByRemoteFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Command) {
        this.audioShareFun(!detail.isMute)
      } else if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { isMicOn: !detail.isMute }
        this.changeDeviceStatus(detail.senderId, obj)
      }
    },
    camMuteByRemoteFun(e) {
      const detail = e.detail
      // 聚焦指导模式下，开启或关闭内置摄像头，清除绘制内容，关闭画笔激光笔
      this.resetWhiteBoard(detail.senderId)
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Command) {
        this.cameraVideoShareFun(!detail.isMute)
      } else if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { isCamOn: !detail.isMute }
        this.changeDeviceStatus(detail.senderId, obj)
      }
    },
    camSwitchByRemoteFun(e) {
      // 聚焦指导模式下，切换镜头，清除绘制内容，关闭画笔激光笔
      const detail = e.detail
      this.resetWhiteBoard(detail.senderId)
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { isFront: detail.isFront }
        this.changeDeviceStatus(detail.senderId, obj)
      }
    },
    switchUvcCamByRemoteFun(e) {
      // 聚焦指导模式下，开启或关闭外置摄像头，清除绘制内容，关闭画笔激光笔
      const detail = e.detail
      this.resetWhiteBoard(detail.senderId)
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { isUvcCamOn: detail.isUvcCamOn }
        this.changeDeviceStatus(detail.senderId, obj)
      }
    },
    camZoomByRemoteFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { camZoomLevel: detail.zoomLevel }
        this.changeDeviceStatus(detail.senderId, obj)
      }
    },
    camExposureByRemoteFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { camExposureLevel: detail.exposureLevel }
        this.changeDeviceStatus(detail.senderId, obj)
      }
    },
    flashlightByRemoteFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { isFlashOn: detail.isOn }
        this.changeDeviceStatus(detail.senderId, obj)
      }
    },
    videoConfigByRemoteFun(e) {
      const detail = e.detail
      if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Status) {
        const obj = { videoResolution: detail.resolution }
        this.changeDeviceStatus(detail.senderId, obj)
      } else if (detail.direction === dwCustomImMsg.DwDeviceMessageDirection.Command) {
        if (this.videoResolution !== detail.resolution) {
          this.videoResolution = detail.resolution
          this.unpublishAll({ camera: true }).then(() => {
            this.getLocalTrack({ camera: true }).then(async() => {
              await this.publishAll({ camera: true })
            })
          })
        }
      }
    },
    resetWhiteBoard(imUserId) {
      // 修改摄像头状态的用户是被聚焦指导的用户（放大）
      if (this.isFocusGuidance && imUserId === this.enLargeVideoInfo.imUserId) {
        if (this.isPaintDrawing) { this.usePaint() }
        if (this.isLaserMoving) { this.useLaser() }
      }
    }
  }
}
