<template>
  <div>
    <el-form ref="outboundForm" :model="outboundForm" :rules="outboundFormRules" label-width="95px">
      <!--      <el-form-item label="收货方（工程方）：" prop="constructionProjectId" label-width="150px">-->
      <!--        <el-select v-model="outboundForm.constructionProjectId" v-loading="isProjectLoading" :disabled="equipmentInfo && equipmentInfo.isWhole" placeholder="收货方（工程方）" style="width: 100%">-->
      <!--          <el-option v-for="item in projectList" :key="item.constructionProjectId" :label="item.constructionProjectName" :value="item.constructionProjectId" />-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="收货方（工程方）：" prop="constructionProjectId" label-width="150px">
        <el-select
          v-model="outboundForm.constructionProjectId"
          filterable
          :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'"
          style="width: 100%"
          remote
          :remote-method="getProjects"
        >
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="出库设备：" prop="constructionEquipmentId" style="text-align: left">
        <equipment-descriptions :equipment-info="equipmentInfo" />
      </el-form-item>
      <template v-if="equipmentInfo && equipmentInfo.isWhole">
        <el-form-item label="出库仓库：" prop="warehouseId">
          <el-select v-model="outboundForm.warehouseId" disabled style="width: 100%">
            <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.name" :value="item.warehouseId" />
          </el-select>
        </el-form-item>
        <el-form-item label="出库数量：" prop="outboundQuantity">
          <el-input v-model="outboundForm.outboundQuantity" disabled placeholder="请输入出库数量" />
        </el-form-item>
        <!--        <el-form-item label="执行代码：" prop="executeCode">-->
        <!--          <el-input v-model="outboundForm.executeCode" placeholder="请输入执行代码" :maxlength="5" show-word-limit />-->
        <!--        </el-form-item>-->
      </template>
      <template v-else>
        <ht-table ref="list" :data="warehouseList" style="margin-bottom: 18px">
          <ht-table-column label="序号" type="index" fixed="left" />
          <ht-table-column
            v-for="item in EquipmentWarehouseStockInfoListTitle"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :min-width="item.width || 100"
            :fixed="item.fixed"
          >
            <template v-slot="{row}">
              <span v-if="item.label === '库存数'">{{ row[item.prop] }}</span>
              <span v-else>{{ row[item.prop] || '-' }}</span>
            </template>
          </ht-table-column>
          <ht-table-column label="出库数量" fixed="right" prop="outboundQuantity" :show-overflow-tooltip="false" width="200">
            <template v-slot="{row}">
              <el-input-number
                v-model="row.outboundQuantity"
                :min="0"
                :max="row.quantity"
                :disabled="row.quantity === 0"
                placeholder="请输入出库数量"
                :precision="0"
                style="width: 100%"
              />
            </template>
          </ht-table-column>
        </ht-table>
      </template>

      <el-form-item label="出库单：" prop="attachmentData">
        <dw-file v-if="outboundForm.attachmentData" :url="outboundForm.attachmentData" show-del justify-content="left" @delete-file="outboundForm.attachmentData = null" />
        <ht-upload-button v-else :file-type="['doc', 'pdf', 'img']" key-prefix="warehouse" button-title="上传出库单" @on-file-uploaded="onFileUploaded" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit">确认出库</el-button>
    </div>
  </div>
</template>

<script>
import { EquipmentWarehouseStockInfoListTitle } from '@/views/constructionEquipmentMgr/tableTitle'
import { getEquipmentStock, putEquipmentStocks } from '@/api/constructionEquipmentMgr/stockMgr'
import { getProjects } from '@/api/constructionProjectMgr/projectMgr'
import EquipmentDescriptions from '@/views/constructionEquipmentMgr/stockMgr/components/equipmentDescriptions'

export default {
  name: 'OutboundForm',
  components: { EquipmentDescriptions },
  data() {
    return {
      EquipmentWarehouseStockInfoListTitle,
      outboundForm: {
        action: 'outbound',
        constructionEquipmentId: null,
        constructionProjectId: null,
        warehouseItems: [],
        attachmentData: null,
        warehouseId: null,
        warehouseName: null,
        outboundQuantity: 1,
        isWhole: false
        // executeCode: null
      },
      outboundFormRules: {
        constructionEquipmentId: [{ required: true, message: '请输入出库数量' }],
        constructionProjectId: [{ required: true, message: '请选择收货方（工程方）' }],
        // attachmentData: [{ required: true, message: '请上传出库单', trigger: 'change' }],
        //   辅助验证
        warehouseId: [{ required: true, message: '请选择出库仓库', trigger: 'change' }],
        outboundQuantity: [{ required: true, message: '请输入出库数量', trigger: 'blur' }]
      },
      projectList: [],
      isProjectLoading: false,
      isSubmitting: false,
      equipmentInfo: null,
      warehouseList: []
    }
  },
  methods: {
    initInfo(equipmentInfo) {
      this.equipmentInfo = equipmentInfo
      this.outboundForm.constructionEquipmentId = equipmentInfo.id
      this.outboundForm.isWhole = equipmentInfo.isWhole
      this.getProjects()
      this.getEquipmentStock(equipmentInfo)
    },
    getProjects(value) {
      const query = {
        offset: 0,
        count: 20,
        keyword: value,
        isFinished: false
      }
      this.isProjectLoading = true
      getProjects(query).then(resp => {
        this.projectList = resp
        this.isProjectLoading = false
      }).catch(() => {
        this.isProjectLoading = false
      })
    },
    getEquipmentStock(equipmentInfo) {
      this.isWarehouseLoading = true
      getEquipmentStock(equipmentInfo.id).then(resp => {
        const warehouseList = resp.warehouseStock
        warehouseList.forEach(item => { item.outboundQuantity = 0 })
        if (equipmentInfo.isWhole) {
          const index = warehouseList.findIndex(item => item.quantity > 0)
          if (index !== -1) {
            this.outboundForm.warehouseId = warehouseList[index].warehouseId
            warehouseList[index].outboundQuantity = warehouseList[index].quantity
          }
        }
        this.warehouseList = warehouseList
        this.isWarehouseLoading = false
      }).catch(() => { this.isWarehouseLoading = false })
    },
    onFileUploaded(context, url) {
      this.outboundForm.attachmentData = url
      if (this.outboundForm.attachmentData) {
        this.$refs.outboundForm.clearValidate('attachmentData')
      }
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    confirmSubmit() {
      const outboundForm = this.getOutboundForm()
      this.$refs.outboundForm.validate(valid => {
        if (valid) {
          if (outboundForm.warehouseItems.some(item => item.quantity > 0)) {
            this.isSubmitting = true
            putEquipmentStocks(outboundForm).then(() => {
              this.$message({ message: `出库成功`, type: 'success' })
              this.$emit('confirm-submit')
              this.isSubmitting = false
            }).catch(() => { this.isSubmitting = false })
          } else {
            this.$message({ message: `请选择仓库并输入出库数量`, type: 'error', center: true })
            return false
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    getOutboundForm() {
      const data = {
        action: 'outbound',
        constructionEquipmentId: null,
        constructionProjectId: null,
        warehouseItems: [],
        attachmentData: [],
        isWhole: false
        // executeCode: null
      }
      data.constructionEquipmentId = this.outboundForm.constructionEquipmentId
      data.constructionProjectId = this.outboundForm.constructionProjectId
      data.warehouseItems = this.warehouseList.map(item => { return { warehouseId: item.warehouseId, warehouseName: item.name, quantity: item.outboundQuantity } })
      data.attachmentData = this.outboundForm.attachmentData ? [this.outboundForm.attachmentData] : []
      data.isWhole = this.outboundForm.isWhole
      // data.executeCode = this.outboundForm.executeCode
      return data
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  gap: 20px;
  margin-bottom: 10px;
}
</style>
