<template>
  <div
    v-if="showBox"
    ref="messageBoxRef"
    class="message-box"
    :class="{'fullscreen-message-box' :fullscreen , 'normal-message-box': !fullscreen , 'is-mobile-fullscreen-message-box': isMobile && fullscreen}"
  >
    <div ref="topContainerRef" class="top-container">
      <span class="name">{{ '会议消息' || `${chatRoomId}会议` }}</span>
      <img class="close-icon" :src="Close" alt="" @click="toggleBoxStatus()">
    </div>
    <div id="chat-message-container" ref="outsideContainerRef" class="middle-container">
      <div ref="insideContainerRef">
        <div v-for="(item, index) in messagesList" :key="index" class="message-item">
          <div v-if="item.msgType === 'DwMeetingJoinOrLeave'" class="status-message">
            <div class="time-container">
              <div class="time">{{ parseTime(item.receivedTime, '') }}</div>
            </div>
            <div class="content-inner">
              <span v-if="isSinoma || stringLongOverTen(item.senderUserName)" class="user">{{ `${item.senderFullName}` }}</span>
              <span v-else class="user">{{ `${item.senderFullName}（${item.senderUserName}）` }}</span>
              <span class="action">{{ item.content }}</span>
            </div>
          </div>
          <div v-else>
            <div v-if="item.messageDirection === 1" class="right-container message-container">
              <div class="time-container">
                <div class="time">{{ item.sentTime ? parseTime(item.sentTime, '') : item.chatTime }}</div>
              </div>
              <div class="right-content-container">
                <img v-if="item.content.senderAvataUrl" class="avatar" :src="item.content.senderAvataUrl" alt="">
                <dw-default-avatar v-else :full-name="item.content.senderFullName" :width="40" :font-size="14" />
                <div class="right-box">
                  <div class="message-content-container">
                    <div class="arrows right-arrows" />
                    <div class="box right-content-box">
                      <div v-if="item.messageType === 'RC:TxtMsg'" class="txt-container content-inner">
                        <span class="content-text">{{ item.content.content }}</span>
                        <!--                        正式服隐藏会议聊天的翻译功能-->
                        <template v-if="item.content.content && !isHiddenTranslation">
                          <div
                            v-if="!item.content.hasOwnProperty('isHideTranslating') || item.content.isHideTranslating"
                            class="click-button"
                            @click="translate(index,item.content.content)"
                          >翻译</div>
                          <template v-else>
                            <div class="click-button" @click="hideTranslate(index,item.content.content)">收起翻译</div>
                            <span v-loading="item.content.isTranslating" class="translation">{{ item.content.translation }}</span>
                          </template>
                        </template>
                        <!--                        正式服隐藏会议聊天的翻译功能-->
                      </div>
                      <div v-else-if="item.messageType === 'RC:ImgMsg'" class="img-container content-inner">
                        <el-image
                          class="content-img"
                          :src="item.content.imageUri"
                          :preview-src-list="[item.content.imageUri]"
                          fit="contain"
                        />
                        <div class="click-button" @click="downloadFile(item.content.imageUri)">下载原图</div>
                      </div>
                      <div v-else-if="item.messageType === 'RC:FileMsg'" class="file-container content-inner">
                        <div class="content-file">
                          <img class="file-icon" :src="Pdf" alt="">
                          <span class="file-name" @click="downloadFile(item.content.fileUrl)">{{ item.content.name }}</span>
                        </div>
                        <div class="click-button" @click="downloadFile(item.content.fileUrl)">下载文件</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="item.messageDirection === 2" class="left-container message-container">
              <div class="time-container">
                <div class="time">{{ item.sentTime ? parseTime(item.receivedTime, '') : item.chatTime }}</div>
              </div>
              <div class="left-content-container">
                <img v-if="item.content.senderAvataUrl" class="avatar" :src="item.content.senderAvataUrl" alt="">
                <dw-default-avatar v-else :full-name="item.content.senderFullName" :width="40" :font-size="14" />
                <div class="left-box">
                  <div class="full-name">{{ item.content.senderFullName }}</div>
                  <div class="message-content-container">
                    <div class="arrows left-arrows" />
                    <div class="box left-content-box">
                      <div v-if="item.messageType === 'RC:TxtMsg'" class="txt-container content-inner">
                        <span class="content-text">{{ item.content.content }}</span>
                        <!--                        正式服隐藏会议聊天的翻译功能-->
                        <template v-if="item.content.content && !isHiddenTranslation">
                          <div
                            v-if="!item.content.hasOwnProperty('isHideTranslating') || item.content.isHideTranslating"
                            class="click-button"
                            @click="translate(index,item.content.content)"
                          >翻译</div>
                          <template v-else>
                            <div class="click-button" @click="hideTranslate(index,item.content.content)">收起翻译</div>
                            <span v-loading="item.content.isTranslating" class="translation">{{ item.content.translation }}</span>
                          </template>
                        </template>
                        <!--                        正式服隐藏会议聊天的翻译功能-->
                      </div>
                      <div v-else-if="item.messageType === 'RC:ImgMsg'" class="img-container content-inner">
                        <el-image
                          class="content-img"
                          :src="item.content.imageUri"
                          :preview-src-list="[item.content.imageUri]"
                          fit="contain"
                        />
                        <div class="click-button" @click="downloadFile(item.content.imageUri)">下载原图</div>
                      </div>
                      <div v-else-if="item.messageType === 'RC:FileMsg'" class="file-container content-inner">
                        <div class="content-file">
                          <img class="file-icon" :src="Pdf" alt="">
                          <span class="file-name" @click="downloadFile(item.content.fileUrl)">{{ item.content.name }}</span>
                        </div>
                        <div class="click-button" @click="downloadFile(item.content.fileUrl)">下载文件</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="is-sending">
          <div v-loading="isLoading" class="send-loading" />
          <span class="text">发送中</span>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="upload-container">
        <img class="folder-icon" :src="FileChoose" alt="">
        <ht-upload-button
          class="upload-button"
          :button-icon="null"
          :show-success-tip="false"
          :file-type="['img', 'pdf']"
          :key-prefix="`new_remote_guidance/${roomId}`"
          :multiple-selection="true"
          @on-upload-start="sendingCount++"
          @on-upload-finish="sendingCount--"
          @on-files-uploaded="onFilesUploaded"
        />
      </div>
      <el-input v-model="content" class="content-input" placeholder="请输入内容" @keyup.enter.native="sendMsg" />
      <div class="send-button" :class="{'disabled-button': !content}" @click="sendMsg">发送</div>
    </div>
  </div></template>

<script>
import Close from '@/assets/image/newRemoteGuidance/close_bold.png'
import AvatarBlue from '@/assets/image/newRemoteGuidance/avatar_blue.jpg'
import FileChoose from '@/assets/image/newRemoteGuidance/file_choose.png'
import Pdf from '@/assets/image/newRemoteGuidance/pdf.png'
import im from '../rongCloud/im'
import { parseTime, stringLongOverTen } from '@/utils'
import { boxMoveEvent, drag, mouseDown } from '../js/boxDrag'
import { translateMsg } from '@/views/newRemoteGuidance/js/business'
import store from '@/store'

export default {
  name: 'Message',
  props: {
    fullscreen: { type: Boolean, default: false },
    roomId: { type: String, default: '' },
    chatRoomId: { type: String, default: '' },
    userInfo: { type: Object, default: () => {} },
    messagesList: { type: Array, default: () => [] },
    isMobile: { type: Boolean, default: false },
    isHiddenTranslation: { type: Boolean, default: true }
  },
  data() {
    return {
      Close, AvatarBlue, FileChoose, Pdf,
      showBox: false,
      content: undefined,
      isLoading: false,
      sendingCount: 0,
      insideContainerHeight: 0,
      stopRolling: false,
      container: null,
      messageBox: null,
      topContainer: null,
      boxPosition: { left: 0, right: 0 },
      topPosition: { startX: 0, startY: 0 },
      isSinoma: undefined
    }
  },
  watch: {
    sendingCount: {
      handler: function() {
        this.isLoading = this.sendingCount > 0
      }
    },
    insideContainerHeight(newVal) {
      this.$nextTick(() => {
        this.$refs.outsideContainerRef.scrollTop = newVal
      })
    },
    messagesList() {
      if (this.showBox) {
        this.onContainerResize()
      }
    }
  },
  beforeDestroy() {
    this.outsideContainer = this.$refs.outsideContainerRef
    if (this.outsideContainer) { this.outsideContainer.removeEventListener('scroll', this.handleScroll) }
  },
  created() {
    this.isSinoma = store.getters.isSinoma
  },
  methods: {
    parseTime, stringLongOverTen,
    toggleBoxStatus() {
      this.showBox = !this.showBox
      if (this.showBox) {
        this.$nextTick(() => {
          this.outsideContainer = this.$refs.outsideContainerRef
          if (this.outsideContainer) { this.outsideContainer.addEventListener('scroll', this.handleScroll) }
          this.stopRolling = false
          this.insideContainerHeight = this.$refs.insideContainerRef.clientHeight
          this.$refs.outsideContainerRef.scrollTop = this.insideContainerHeight
          this.messageBox = this.$refs.messageBoxRef
          this.topContainer = this.$refs.topContainerRef
          boxMoveEvent(this.messageBox, this.topContainer, this.onMouseDown, this.onDrag)
        })
      } else {
        this.outsideContainer = this.$refs.outsideContainerRef
        if (this.outsideContainer) { this.outsideContainer.removeEventListener('scroll', this.handleScroll) }
        this.stopRolling = false
        this.messageBox = this.$refs.messageBoxRef
        this.topContainer = this.$refs.topContainerRef
        this.messageBox.removeEventListener('mousemove', this.onDrag)
        this.topContainer.removeEventListener('mousedown', this.onMouseDown)
        this.$emit('close-box')
      }
    },
    onMouseDown(e) {
      mouseDown(e, this.topPosition, this.messageBox, this.onDrag)
    },
    onDrag(e) {
      drag(e, this.topPosition, this.boxPosition, this.messageBox, this.fullscreen)
    },
    onContainerResize() {
      this.$nextTick(() => {
        if (!this.stopRolling) {
          this.insideContainerHeight = this.$refs.insideContainerRef.clientHeight
        }
      })
    },
    async sendMsg() {
      if (this.content) {
        // 正式服隐藏会议聊天的翻译功能
        let translateData = {}
        translateData = !this.isHiddenTranslation ? await translateMsg(this.content, translateData) : {}
        const data = {
          senderFullName: this.userInfo.fullName,
          senderUserName: this.userInfo.account.userName,
          senderAvataUrl: this.userInfo.avatar,
          content: this.content,
          extra: translateData.translation || '',
          translation: translateData.translation || ''
        }
        im.sendTextMessageInChatRoom(this.chatRoomId, data)
        this.content = undefined
        this.stopRolling = false
      } else {
        this.$message({ message: '发送内容为空', center: true, type: 'warning' })
      }
    },
    sendImage(imageUri, content) {
      const data = {
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        senderAvataUrl: this.userInfo.avatar,
        imageUri: imageUri,
        content: content
      }
      im.sendImageMessageInChatRoom(this.chatRoomId, data)
    },
    sendFile({ name, size, type, fileUrl }) {
      const data = {
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        senderAvataUrl: this.userInfo.avatar,
        name: name,
        size: size,
        type: type,
        fileUrl: fileUrl
      }
      im.sendFileMessageInChatRoom(this.chatRoomId, data)
    },
    async onFilesUploaded(context, urls, files) {
      for (const item of urls) {
        const index = urls.indexOf(item)
        if (files[index].body.type.includes('image')) {
          const thumbnailFile = files[index].body.slice(0, 1024 * 64)
          const reader = new FileReader()
          reader.readAsDataURL(thumbnailFile)
          reader.onload = () => {
            const thumbnail = reader.result// thumbnail 就是图片的 Base64 字符串缩略图
            this.sendImage(item, thumbnail)
          }
        } else {
          const file = files[index].body
          const data = {
            name: file.name,
            size: file.size,
            type: file.type,
            fileUrl: item
          }
          this.sendFile(data)
        }
      }
      this.stopRolling = false
    },
    downloadFile(src) {
      fetch(src)
        .then(res => res.blob())
        .then(blob => {
          const eleLink = document.createElement('a')
          const objectUrl = window.URL.createObjectURL(blob)
          eleLink.download = src.split('/').pop()
          eleLink.href = objectUrl
          eleLink.click()
          window.URL.revokeObjectURL(objectUrl)
          eleLink.remove()
        })
    },
    async translate(index, content) {
      this.stopRolling = true
      const item = this.messagesList[index].content
      if (!item.translation) {
        // 正式服隐藏会议聊天的翻译功能
        if (!this.isHiddenTranslation) {
          translateMsg(content, this.messagesList[index].content).then(res => {
            this.messagesList[index].content = res
          })
        }
      } else {
        item.isHideTranslating = false
      }
      this.$set(this.messagesList, index, this.messagesList[index])
    },
    hideTranslate(index) {
      this.messagesList[index].content.isHideTranslating = true
      this.$set(this.messagesList, index, this.messagesList[index])
    },
    handleScroll() {
      const scrollTop = this.outsideContainer.scrollTop
      const scrollHeight = this.outsideContainer.scrollHeight
      const clientHeight = this.outsideContainer.clientHeight
      this.stopRolling = scrollTop + clientHeight - scrollHeight < -20
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.message-box{
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 380px;
  height: 500px;
  z-index: 1000;
  .top-container{
    height: 48px;
    padding: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F3F3;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 10px 10px 0 0;
    cursor: move;
    .close-icon{
      right: 10px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
    .name{
      flex: 1;
      height: 28px;
      line-height: 28px;
      color: #000000;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }
  .middle-container{
    flex: 1 1 auto;
    overflow-y: auto;
    background: #F3F3F3;
    .message-item{
      font-size: 14px;
      margin: 0 15px 15px 15px;
      .time-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        .time{
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          padding: 0 10px;
          margin: 10px 0;
          text-align: center;
          color: #999999;
          border-radius: 10px;
        }
      }
      .status-message{
        text-align: center;
        .content-inner{
          .user{
            color: $primaryColor;
          }
          .action{
            color: #666666;
          }
        }
      }
      .message-container{
        .avatar{
          width: 40px;
          height: 40px;
          border-radius: 40px;
        }
        .arrows{
          position: absolute;
          top: 10px;
          width: 0;
          height: 0;
          border-width: 5px 10px;
          border-style: solid;
          border-color: transparent #ffffff transparent  transparent;
        }
        .box{
          max-width: 190px;
          padding: 10px;
          position: relative;
          border-radius: 10px;
          background: #ffffff;

          .click-button{
            color: $primaryColor;
            text-align: center;
            margin-top: 10px;
            margin-bottom: -5px;
            font-size: 12px;
            cursor: pointer;
          }
          .click-button:hover {
            text-decoration: underline;
          }
          .content-inner{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
          .txt-container{
            .content-text{
              text-align: left;
              word-break: break-word;
              width: 100%;
            }
            .translation{
              width: 100%;
              margin-top: 15px;
              font-size: 14px;
              text-align: left;
              word-break: break-word;
              ::v-deep .el-loading-spinner .circular{
                width: 25px;
                height: 25px;
              }
            }
          }
          .img-container{
            .content-img{
              width: 80px;
              height: 80px;
              cursor: pointer;
            }
          }
          .file-container{
            .content-file{
              display: flex;
              flex-direction: row;
              align-items: center;
              .file-icon{
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }
              .file-name{
                cursor: pointer;
                text-decoration: underline;
              }
              .file-name:hover{
                color: $primaryColorMiddle;
              }
            }
          }
        }
      }

      .right-container{
        .right-content-container{
          display: -webkit-box;
          justify-content: flex-end;
          flex-direction: row-reverse;
          .right-box{
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            .full-name{
              color: #666666;
            }
            .message-content-container{
              position: relative;
              margin-right: -10px;
              .arrows{
                right: -15px;
                border-color: transparent transparent transparent #A9EA7A;
              }
              .right-content-box{
                right: 5px;
                background: #A9EA7A;
              }
            }

          }
        }
      }
      .left-container{
        .left-content-container{
          display: -webkit-box;
          justify-content: left;
          .left-box{
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            .full-name{
              color: #666666;
            }
            .message-content-container{
              position: relative;
              margin-top: 5px;
              margin-left: -10px;
              .arrows{
                left: -15px;
                border-color: transparent #ffffff transparent  transparent;
              }
              .left-content-box{
                left: 5px;
                background: #ffffff;
              }
            }

          }
        }
      }
      .box {
        min-height: 36px;
      }
    }
    .is-sending{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 20px 0;
      .send-loading{
        width: 50px;
        height: 50px;
        ::v-deep .el-loading-mask{
          background-color: rgba(255,255,255,.0) !important;
        }
      }
    }
  }
  .bottom-container{
    flex: 0 0 auto;
    height: 60px;
    background: #F6F6F6;
    border-top: 1px solid rgb(229,229,229);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 10px 10px;
    .upload-container{
      position: relative;
      .folder-icon{
        width: 30px;
        height: 30px;
      }
      .upload-button{
        height: 100%;
        position: absolute;
        left: 0;
        opacity: 0;
      }
    }
    .content-input{
      margin: 0 20px;
      .el-input__inner{
        height: 40px !important;
        line-height: 40px !important;
        border-radius: 20px;
        background: #F3F3F3;
        border: #ffffff;
      }
      .el-input__inner::placeholder{
        color: #2b2f3a;
      }
    }
    .send-button{
      height: 30px;
      line-height: 30px;
      padding: 0 20px;
      color: #ffffff;
      font-weight: bold;
      background: $primaryColor;
      border-radius: 30px;
      white-space: nowrap;
      cursor: pointer;
    }
    .send-button::placeholder{
      color: #2b2f3a;
    }

    .disabled-button{
      background: $primaryColorMiddle;
    }
  }
}

.is-mobile-fullscreen-message-box{
  height: 400px !important;
}
</style>
