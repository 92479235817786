<template>
  <div v-loading="loading" class="tree-list-container">
    <el-tree
      :id="treeId"
      :ref="treeRef"
      :data="treeList"
      :props="defaultProps"
      node-key="id"
      :current-node-key="currentNodeKey"
      default-expand-all
      highlight-current
      :expand-on-click-node="false"
      @node-collapse="$emit('click-outside')"
      @node-expand="$emit('click-outside')"
    >
      <div :ref="node.id" slot-scope="{ node, data }" class="custom-tree-node" :class="{'is-checked': data.checked}">
        <div class="node-name" @click="nodeClick(node, data, true)">{{ node[treeName] }}
          <span v-if="data.id === hintNodeId" class="node-hint">（{{ hintTxt }}）</span>
        </div>
        <el-checkbox v-if="data.couldSelected" v-model="data.checked" @change="handleCheck(node,data)">选择该目录</el-checkbox>
      </div>
    </el-tree>
  </div>
</template>

<script>
export default {
  name: 'SingleCheckTree',
  props: {
    treeId: { type: String, default: 'tree' },
    treeRef: { type: String, default: 'tree' },
    treeList: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    defaultProps: { type: Object, default() { return { children: 'children', label: 'name' } } },
    currentNodeKey: { type: [String, Number], default: null },
    treeName: { type: String, default: 'label' },
    treeLevelName: { type: String, default: 'level' },
    hintNodeId: { type: [String, Number], default: null },
    hintTxt: { type: String, default: null }
  },
  methods: {
    nodeClick(node, data) {
      if (data.couldSelected) {
        data.checked = !data.checked
        this.$emit('node-click', node, data)
      }
    },
    handleCheck(node, data) {
      this.$emit('node-click', node, data)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.tree-list-container{
  position: relative;
  margin: 10px 0;
}

.el-tree{
  font-size: 14px;
  ::v-deep .el-tree-node{
    .el-tree-node__content{
      height: 40px;
      line-height: 40px;
      background: #ffffff;
      .el-tree-node__expand-icon{
        font-size: 16px;
      }
    }
  }
}

.custom-tree-node{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  .node-name{
    flex: 1;
  }
  .node-hint{
    color: #f56c6c;
  }
  .node-icon{
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    padding-right: 20px;
  }
}

.is-checked{
  background: #eef2ff;
}
</style>
