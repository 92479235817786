import request from '@/utils/request'

export const hidden_danger_records = 'security/hidden_danger_records/'

export function getHiddenDangerRecordsApi(listQuery) {
  return request({ url: hidden_danger_records, method: 'GET', params: listQuery })
}

export function getHiddenDangerRecordDetailApi(recordId) {
  return request({ url: `${hidden_danger_records}${recordId}`, method: 'GET' })
}

export function updateHiddenDangerRecordDetail(recordId, data) {
  return request({ url: `${hidden_danger_records}${recordId}`, method: 'PUT', data: data })
}

export function deleteHiddenDangerRecordDetail(recordId) {
  return request({ url: `${hidden_danger_records}${recordId}`, method: 'DELETE' })
}

export function getHiddenDangerRecordItemsApi(recordId) {
  return request({ url: `${hidden_danger_records}${recordId}/items/`, method: 'GET' })
}

export function updateHiddenDangerRecordItems(recordId, data) {
  return request({ url: `${hidden_danger_records}${recordId}/items/`, method: 'PUT', data: data })
}
