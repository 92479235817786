var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "middle-section", style: { height: _vm.height + "px" } },
    [
      _vm.hasProperties
        ? _c(
            "div",
            {
              staticClass: "left-container inner-item",
              class: {
                "focused-item":
                  _vm.isFocusedItem === "referenceImage" ||
                  _vm.isFocusedItem === "text",
              },
            },
            [
              _c(
                "div",
                { staticClass: "title-container" },
                [
                  _vm.showReferenceImage
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button reference-img-button",
                          class: {
                            "focused-item":
                              _vm.isFocusedItem === "referenceImage",
                            "click-item": _vm.activeName === "referenceImage",
                          },
                          attrs: { type: "text", disabled: _vm.buttonDisabled },
                          on: {
                            click: function ($event) {
                              return _vm.handleActions("selectReferenceImage")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "参考" +
                              _vm._s(
                                _vm.isVideoOrImage(_vm.referenceImage) ===
                                  "video"
                                  ? "视频"
                                  : "图"
                              )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showText
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button text-button",
                          class: {
                            "focused-item": _vm.isFocusedItem === "text",
                            "click-item": _vm.activeName === "text",
                          },
                          attrs: { type: "text", disabled: _vm.buttonDisabled },
                          on: {
                            click: function ($event) {
                              return _vm.handleActions("selectText")
                            },
                          },
                        },
                        [_vm._v("检查要求")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPhotos
                    ? _c(
                        "el-button",
                        {
                          staticClass: "sub-title-button text-button",
                          class: { "click-item": _vm.activeName === "photos" },
                          attrs: { type: "text", disabled: _vm.buttonDisabled },
                          on: {
                            click: function ($event) {
                              return _vm.handleActions("selectPhotos")
                            },
                          },
                        },
                        [_vm._v("照片")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content-container" }, [
                _vm.showReferenceImage && _vm.activeName === "referenceImage"
                  ? _c(
                      "div",
                      { staticClass: "img-container reference-img-container" },
                      [
                        _vm.referenceImage
                          ? [
                              _vm.isVideoOrImage(_vm.referenceImage) === "video"
                                ? _c("web-view-video", {
                                    ref: "referenceImage",
                                    attrs: {
                                      src: _vm.referenceImage,
                                      "button-disabled": _vm.buttonDisabled,
                                    },
                                    on: {
                                      "on-finish-play":
                                        _vm.closeReferenceImageViewer,
                                    },
                                  })
                                : _c("web-view-image", {
                                    ref: "referenceImage",
                                    staticClass: "reference-img",
                                    attrs: {
                                      src: _vm.referenceImage,
                                      alt: "",
                                      "preview-src-list": [_vm.referenceImage],
                                      "show-icon": false,
                                      "button-disabled": _vm.buttonDisabled,
                                    },
                                    on: {
                                      "close-viewer":
                                        _vm.closeReferenceImageViewer,
                                    },
                                  }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.referenceImage
                          ? _c(
                              "el-button",
                              {
                                staticClass:
                                  "sub-title-button fullscreen-button",
                                attrs: { disabled: _vm.buttonDisabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleActions(
                                      "openReferenceImage"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isVideoOrImage(_vm.referenceImage) ===
                                      "video"
                                      ? "播放"
                                      : "查看参考图"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm.activeName === "text"
                  ? _c("div", { staticClass: "text-container" }, [
                      _c("div", { staticClass: "check-standard" }, [
                        _vm._v(_vm._s(_vm.text)),
                      ]),
                    ])
                  : _vm.showPhotos && _vm.activeName === "photos"
                  ? _c(
                      "div",
                      { staticClass: "left-img-container" },
                      [
                        _c(
                          "web-view-carousel",
                          {
                            ref: "leftCustomCarousel",
                            attrs: {
                              height: "100%",
                              autoplay: false,
                              arrow: "always",
                              "show-icon": false,
                              "button-disabled": _vm.buttonDisabled,
                            },
                            on: { change: _vm.carouselActiveChange },
                          },
                          _vm._l(_vm.uploadPhotos, function (item, index) {
                            return _c(
                              "web-view-carousel-item",
                              {
                                key: index,
                                attrs: {
                                  "button-disabled": _vm.buttonDisabled,
                                },
                              },
                              [
                                _c("web-view-image", {
                                  ref: "result" + index,
                                  refInFor: true,
                                  staticClass: "result-img",
                                  attrs: {
                                    src: item,
                                    alt: "",
                                    "preview-src-list": _vm.uploadPhotos,
                                    "show-icon": false,
                                    "button-disabled": _vm.buttonDisabled,
                                  },
                                  on: {
                                    prev: function ($event) {
                                      return _vm.handleActions(
                                        "prevPhoto",
                                        arguments
                                      )
                                    },
                                    next: function ($event) {
                                      return _vm.handleActions(
                                        "nextPhoto",
                                        arguments
                                      )
                                    },
                                    "del-img": function ($event) {
                                      return _vm.handleActions(
                                        "delPhoto",
                                        arguments
                                      )
                                    },
                                    "close-viewer": function ($event) {
                                      return _vm.handleActions("closePhoto")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _vm.uploadPhotos && _vm.uploadPhotos.length > 0
                          ? _c(
                              "el-button",
                              {
                                staticClass:
                                  "sub-title-button fullscreen-button",
                                attrs: { disabled: _vm.buttonDisabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleActions("openPhoto")
                                  },
                                },
                              },
                              [_vm._v("查看照片")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.uploadPhotos && _vm.uploadPhotos.length > 0
                          ? _c(
                              "el-button",
                              {
                                staticClass:
                                  "sub-title-button del-photo-button",
                                attrs: { disabled: _vm.buttonDisabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleActions("delPhoto")
                                  },
                                },
                              },
                              [_vm._v("删除照片")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasResults
        ? _c(
            "div",
            { staticClass: "right-container inner-item" },
            [
              _c(
                "div",
                { staticClass: "title-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "sub-title-button fact-button",
                      attrs: { type: "text", disabled: _vm.buttonDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("inputDesc")
                        },
                      },
                    },
                    [_vm._v("情况描述")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "sub-title-button clear-button",
                      attrs: { type: "text", disabled: _vm.buttonDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.handleActions("clearFactDesc")
                        },
                      },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { ref: "rectDescRef", staticClass: "check-standard" },
                [
                  _vm.factDesc !== null && typeof _vm.factDesc === "object"
                    ? [
                        _vm.factDesc.length > 0
                          ? _vm._l(_vm.factDesc, function (item, index) {
                              return _c("div", { key: index }, [
                                _vm._v(_vm._s(index + 1 + "." + item)),
                              ])
                            })
                          : _c("div"),
                      ]
                    : [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.factDesc || "请输入内容") +
                            "\n      "
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("result"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPhoto
        ? _c("div", { staticClass: "right-container inner-item" }, [
            _c(
              "div",
              { staticClass: "title-container" },
              [
                _vm.uploadPhotos && _vm.uploadPhotos.length < 9
                  ? _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button photo-button",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleActions("nativeTakePhoto")
                          },
                        },
                      },
                      [_vm._v("拍摄")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.uploadPhotos && _vm.uploadPhotos.length > 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button del-button",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleActions("delPhoto")
                          },
                        },
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img-container" },
              [
                _c(
                  "dw-carousel",
                  {
                    ref: "customCarousel",
                    attrs: { height: "100%", autoplay: false, arrow: "always" },
                    on: {
                      change: function ($event) {
                        return _vm.handleActions("setActiveItem")
                      },
                    },
                  },
                  _vm._l(_vm.uploadPhotos, function (item, index) {
                    return _c(
                      "dw-carousel-item",
                      { key: index },
                      [
                        _c("dw-el-image", {
                          ref: "result" + index,
                          refInFor: true,
                          staticClass: "result-img",
                          attrs: {
                            src: item,
                            alt: "",
                            "preview-src-list": _vm.uploadPhotos,
                            "show-icon": false,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _vm.uploadPhotos && _vm.uploadPhotos.length > 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "sub-title-button fullscreen-button",
                        on: {
                          click: function ($event) {
                            return _vm.handleActions("fullScreenPhoto")
                          },
                        },
                      },
                      [_vm._v("查看照片")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }