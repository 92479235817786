<template>
  <div :class="{'is-mobile-form': isMobile}">
    <el-form ref="orderForm" :class="{'mobile-form': isMobile}" :model="orderForm" :rules="orderFormRules" :label-width="labelWidth" style="margin-right: 20px">
      <el-form-item label="工程：" prop="projectId">
        <el-select v-model="orderForm.projectId" filterable :placeholder="projectList.length > 0 ? '请输入工程名称进行搜索': '当前无可选工程'" :popper-class="selectPopperClass" style="width: 100%" remote :remote-method="getProjects" @change="handleSelectProject">
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="orderForm.projectId" label="整机设备：" prop="targetId">
        <el-select v-model="orderForm.targetId" filterable :placeholder="equipmentList.length > 0 ? '请选择整机设备' : '当前无可选整机设备'" :popper-class="selectPopperClass" style="width: 100%" @change="handleSelectEquipment">
          <el-option v-for="item in equipmentList" :key="item.id" :label="getEquipmentName(item)" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="工作流程：" prop="taskTemplateId">
        <el-select v-model="orderForm.taskTemplateId" filterable :placeholder="workFlowList.length > 0 ? '请输入工作流程名称进行搜索': '当前无可选工作流程'" :popper-class="selectPopperClass" style="width: 100%" remote :remote-method="getWorkFlows" @change="handleSelectTaskTemplate">
          <el-option v-for="item in workFlowList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="orderForm.taskTemplateId" label="作业类型：" prop="towerWorkType">
        <el-select v-model="orderForm.towerWorkType" placeholder="请选择作业类型" disabled :popper-class="selectPopperClass" style="width: 100%">
          <el-option v-for="(item, index) in TowerWorkOrderTypeObjectEnum" :key="index" :label="item.value" :value="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="工单名称：" prop="name">
        <el-input v-model="orderForm.name" placeholder="请输入工单名称" show-word-limit maxlength="50" />
      </el-form-item>
      <el-form-item label="执行人：" prop="executorId">
        <el-select v-model="orderForm.executorId" filterable :placeholder="userList.length > 0 ? '请输入执行人姓名进行搜索' : '当前无可选执行人'" :popper-class="selectPopperClass" style="width: 100%" remote :remote-method="getStaffs">
          <el-option v-for="item in userList" :key="item.userId" :label="item.fullName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="循环周期：" prop="isLoop">
        <el-select v-model="orderForm.isLoop" placeholder="请选择循环周期" :popper-class="selectPopperClass" style="width: 100%">
          <el-option v-for="(value,key) in LoopEnum" :key="key" :label="value" :value="key" />
        </el-select>
      </el-form-item>
    </el-form>

    <div v-if="isMobile" slot="footer" class="mobile-dialog-footer" style="text-align: center">
      <el-button :loading="isSubmitting" type="primary" @click="confirmSubmit('orderForm')">下发工单</el-button>
    </div>

    <div v-else slot="footer" class="dialog-footer" style="text-align: center">
      <el-button icon="el-icon-close" @click="cancelSubmit">取消</el-button>
      <el-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="confirmSubmit('orderForm')">下发工单</el-button>
    </div>
  </div>
</template>

<script>
import { LoopEnum, TowerWorkOrderTypeObjectEnum, FileListTitleEnum } from '@/views/taskMgr/enum'
import { getProject, getProjects } from '@/api/constructionProjectMgr/projectMgr'
import { getUserMgrList } from '@/api/systemMgr/userMgr'
import { getTaskTemplates, getTaskTemplate } from '@/api/taskMgr/workflow'
import { postWorkOrders } from '@/api/taskMgr/workOrder'
import { getAddress, getEquipmentName } from '@/utils/get-display'
import { getTaskTempTypeEnum } from '@/views/taskMgr/commonJs'
import { getObject, setObject, removeObject } from '@/store/localStorage'

export default {
  name: 'OrderForm',
  props: {
    isEdit: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    labelWidth: { type: String, default: '100px' },
    selectPopperClass: { type: String, default: null }
  },
  data() {
    return {
      LoopEnum, TowerWorkOrderTypeObjectEnum, FileListTitleEnum,
      orderForm: {
        type: 'PATROL',
        name: null,
        taskTemplateId: null,
        towerWorkType: null,
        projectId: null,
        targetId: null,
        targetType: null,
        targetCode: null,
        targetName: null,
        targetInfo: null,
        executorId: null,
        isLoop: 'false',
        uploadFiles: [],
        fileUploadStatus: null
      },
      orderFormRules: {
        type: [{ required: true, message: '请选择工单类型', trigger: 'change' }],
        name: [{ required: true, message: '请输入工单名称', trigger: 'blur' }],
        taskTemplateId: [{ required: true, message: '请选择工作流程', trigger: 'change' }],
        towerWorkType: [{ required: true, message: '请选择作业类型', trigger: 'change' }],
        projectId: [{ required: true, message: '请选择工程', trigger: 'change' }],
        targetId: [{ required: true, message: '请选择整机设备', trigger: 'change' }],
        executorId: [{ required: true, message: '请选择执行人', trigger: 'change' }],
        isLoop: [{ required: true, message: '请选择是否循环', trigger: 'change' }]
      },
      workFlowList: [],
      userList: [],
      projectList: [],
      equipmentList: [],
      isSubmitting: false,
      equipmentName: null,
      towerWorkName: null,
      workFlow: null
    }
  },
  watch: {
    equipmentName() {
      this.getWorkOrderName()
    },
    towerWorkName() {
      this.getWorkOrderName()
    }
  },
  created() {
    this.getStaffs()
    this.getWorkFlows()
    this.getProjects()
  },
  methods: {
    getAddress, getTaskTempTypeEnum, getEquipmentName,
    getProjects(value) {
      const listQuery = {
        offset: 0,
        count: 20,
        keyword: value,
        isFinished: false
      }
      getProjects(listQuery).then(resp => {
        this.projectList = resp
        const workOrderProjectInfo = getObject('workOrderProjectInfo')
        let projectId = workOrderProjectInfo ? workOrderProjectInfo.projectId : null

        // 如果前20条里没有local存储的 项目id,调详情，如果没有被删除，加入项目option列表，如果被删了，不做回显，并清除local存储
        const index = this.projectList.findIndex(item => item.id === projectId)
        if (index === -1 && projectId) {
          getProject(projectId, { containDeleted: true }).then(resp => {
            if (resp.deleted || resp.isFinished) {
              projectId = null
              removeObject('workOrderProjectInfo')
            } else {
              this.projectList = [...this.projectList, resp]
              this.orderForm.projectId = projectId
              this.handleSelectProject(this.orderForm.projectId)
            }
          })
        } else if (projectId) {
          this.orderForm.projectId = projectId
          this.handleSelectProject(this.orderForm.projectId)
        }
      })
    },
    getWorkFlows(value) {
      const listQuery = { offset: 0, count: 20, keyword: value, type: 'PATROL' }
      getTaskTemplates(listQuery).then(resp => {
        this.workFlowList = resp
        const workOrderWorkFlowInfo = getObject('workOrderWorkFlowInfo')
        let taskTemplateId = workOrderWorkFlowInfo ? workOrderWorkFlowInfo.taskTemplateId : null

        // 如果前20条里没有local存储的工作流程id,调详情，如果没有被删除，加入工作流option列表，如果被删了，不做回显，并清除local存储
        const index = this.workFlowList.findIndex(item => item.id === taskTemplateId)
        console.log(index)
        if (index === -1 && taskTemplateId) {
          getTaskTemplate(taskTemplateId, { containDeleted: true }).then(resp => {
            if (resp.deleted) {
              taskTemplateId = null
              removeObject('workOrderWorkFlowInfo')
            } else {
              this.workFlowList = [...this.workFlowList, resp]
              this.orderForm.taskTemplateId = taskTemplateId
              this.handleSelectTaskTemplate(this.orderForm.taskTemplateId)
            }
          })
        } else if (taskTemplateId) {
          this.orderForm.taskTemplateId = taskTemplateId
          this.handleSelectTaskTemplate(this.orderForm.taskTemplateId)
        }
      })
    },
    handleSelectProject(value) {
      this.equipmentList = []
      this.orderForm.targetId = null
      const index = this.projectList.findIndex(item => item.id === value)
      if (index !== -1) {
        const project = this.projectList[index]
        this.orderForm.targetInfo = {
          projectName: project.name,
          projectId: project.id,
          projectAddress: project.location,
          // projectAddress: project.location ? project.location : this.getAddress('', project.province && project.province.name, project.city && project.city.name, project.district && project.district.name, project.address),
          projectInstallationUnit: project.installationUnit
        }
        getProject(project.id, { isWhole: true }).then(resp => {
          this.equipmentList = resp.equipmentItems.filter(item => item.status === 'UNDER_LEASED')
        })
        this.equipmentName = null
        //   存储选择的工程信息
        setObject('workOrderProjectInfo', { projectId: value })
      } else {
        this.equipmentList = []
        this.orderForm.targetInfo = null
        this.equipmentName = null
      }
    },
    handleSelectEquipment(value) {
      const index = this.equipmentList.findIndex(item => item.id === value)
      if (index !== -1) {
        const equipment = this.equipmentList[index]
        this.orderForm.targetId = equipment.id
        this.orderForm.targetType = 'PROJECT_EQUIPMENT'
        this.orderForm.targetName = equipment.constructionEquipmentName
        this.orderForm.targetCode = equipment.constructionEquipmentCode
        this.orderForm.targetInfo.equipmentId = equipment.constructionEquipmentId
        this.equipmentName = this.getEquipmentName(equipment)
      } else {
        this.orderForm.targetId = null
        this.orderForm.targetType = 'NONE"'
        this.orderForm.targetName = null
        this.orderForm.targetCode = null
        this.orderForm.targetInfo.equipmentId = null
        this.equipmentName = null
      }
    },
    handleSelectTaskTemplate(value) {
      const index = this.workFlowList.findIndex(item => item.id === value)
      if (index !== -1) {
        this.workFlow = this.workFlowList[index]
        this.orderForm.towerWorkType = this.workFlow.towerWorkType
        this.towerWorkName = this.handleSelectTowerWork(this.orderForm.towerWorkType)
        //   存储选择的工作流程信息
        setObject('workOrderWorkFlowInfo', { taskTemplateId: value })
      } else {
        this.workFlow = null
        this.orderForm.towerWorkType = null
        this.towerWorkName = null
      }
    },
    handleSelectTowerWork(value) {
      const index = TowerWorkOrderTypeObjectEnum.findIndex(item => item.name === value)
      let towerWorkName = ''
      if (index !== -1) {
        const towerWorkOrder = this.TowerWorkOrderTypeObjectEnum[index]
        towerWorkName = towerWorkOrder.value
      }
      return towerWorkName
    },
    getWorkOrderName() {
      const nowDate = new Date()
      const year = nowDate.getFullYear()
      const month = nowDate.getMonth() + 1 > 9 ? nowDate.getMonth() + 1 : `0${nowDate.getMonth() + 1}`
      const date = nowDate.getDate() > 9 ? nowDate.getDate() : `0${nowDate.getDate()}`
      this.orderForm.name = [this.equipmentName, this.towerWorkName, `${year}${month}${date}`].filter(value => value).join('-')
    },
    getStaffs(value) {
      const listQuery = {
        offset: 0,
        count: 20,
        keyword: value
      }
      getUserMgrList(listQuery).then(resp => {
        this.userList = resp
      })
    },
    confirmSubmit() {
      const orderForm = this.getSubmitData()
      this.$refs.orderForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true
          postWorkOrders(orderForm).then(() => {
            this.$message({ message: `新建成功`, type: 'success', center: true })
            this.$emit('confirm-submit')
            this.isSubmitting = false
          }).catch(() => {
            this.isSubmitting = false
          })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    },
    getSubmitData() {
      const submitData = {}
      const submitKey = ['name', 'type', 'towerWorkType', 'targetId', 'targetType', 'targetCode', 'targetName', 'targetInfo', 'executorId', 'taskTemplateId', 'isLoop', 'uploadFiles', 'fileUploadStatus']
      submitKey.forEach(key => {
        submitData[key] = this.orderForm[key]
      })
      // 作业类型修改涉及这里
      submitData.uploadFiles = this.getUploadFiles(submitData.towerWorkType)
      submitData.fileUploadStatus = submitData.uploadFiles.length === 0 ? null : 'UNFINISHED'
      return submitData
    },
    getUploadFiles(towerWorkType) {
      return this.FileListTitleEnum.filter(item => item.isShow === towerWorkType)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/mobile-common-style.scss';

.is-mobile-form{
  margin-top: 20px;
}

.mobile-dialog-footer{
  padding: 20px;
  .el-button{
    width: 100%;
    height: 40px;
    font-size: 16px;
  }
}
</style>
