var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title-bar" },
        [
          _c(
            "el-button",
            {
              staticClass: "back-button",
              attrs: { type: "primary", icon: "el-icon-back" },
              on: { click: _vm.onBack },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c("user-info", {
            staticClass: "user-info",
            attrs: {
              "app-id": _vm.appId,
              "outer-id": _vm.outerId,
              "user-name": _vm.userName,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("detail", { attrs: { "room-id": _vm.roomId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }