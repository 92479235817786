var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "equipmentForm",
          staticStyle: { "margin-right": "20px" },
          attrs: {
            model: _vm.equipmentForm,
            rules: _vm.equipmentFormRules,
            "label-width": "100px",
          },
        },
        [
          _vm._l(_vm.equipmentForm.checkItems, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "step-item",
                    attrs: {
                      label: "检查项" + (index + 1) + "：",
                      prop: "checkItems[" + index + "][question]",
                      rules: _vm.equipmentFormRules.question,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: "100",
                        placeholder: "请输入检查项" + (index + 1) + "内容：",
                        "show-word-limit": "",
                      },
                      model: {
                        value: item.question,
                        callback: function ($$v) {
                          _vm.$set(item, "question", $$v)
                        },
                        expression: "item.question",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-delete", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.delStep(index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "info-form-item", attrs: { "label-width": "0" } },
            [
              _c("span", { staticClass: "info" }, [
                _vm._v(
                  _vm._s(
                    _vm.equipmentForm.checkItems.length === 0
                      ? "暂无检查项"
                      : ""
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.addCheckItem },
                },
                [_vm._v("添加检查项")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isSubmitting,
                type: "primary",
                icon: "el-icon-check",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmSubmit("equipmentForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }