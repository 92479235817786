var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-container",
      class: { "click-button": _vm.isShowDot, "is-disabled": _vm.isDisabled },
      style: { width: _vm.width + "px" },
      on: { click: _vm.clickButton },
    },
    [
      _vm.isShowDot
        ? _c("img", { staticClass: "dot", attrs: { src: _vm.RedDot, alt: "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowNum && _vm.num > 0
        ? _c("el-badge", { staticClass: "num", attrs: { value: _vm.num } })
        : _vm._e(),
      _vm._v(" "),
      _c("img", { staticClass: "icon", attrs: { src: _vm.icon, alt: "" } }),
      _vm._v(" "),
      _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }