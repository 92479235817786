exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".el-carousel__container{position:relative}.el-carousel__container .el-carousel-button-container{position:absolute;background:#b5c0db;width:100%;bottom:0;height:34px;z-index:99}.el-carousel__container .el-carousel-button-container .el-carousel__arrow{font-size:1.5rem;font-weight:bold;color:#2e5aff}.el-carousel__container .el-carousel-button-container .current-index{height:34px;line-height:34px;font-size:1.5rem;color:#fff;font-weight:bold}", ""]);

// exports
exports.locals = {
	"menuText": "#666",
	"menuActiveText": "#2e5aff",
	"subMenuActiveText": "#2e5aff",
	"menuBg": "#fff",
	"menuHover": "#f1f2f4",
	"menuActiveBg": "#2e5aff",
	"subMenuBg": "#f1f2f4",
	"subMenuHover": "#dedede",
	"sideBarWidth": "210px",
	"primaryColor": "#2e5aff",
	"primaryColorMiddle": "#8099ff",
	"primaryColorLight": "#e0eaf9",
	"mobilePrimaryColor": "#2e5aff",
	"mobilePrimaryColorMiddle": "#6485fd",
	"mobilePrimaryColorLight": "#e0eaf9",
	"sidebarLogoBg": "#2e5aff",
	"sidebarLogoColor": "#fff",
	"hoverSideBarWidth": "190px"
};