var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-container" },
    _vm._l(_vm.VideoResolutions, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "config-item",
          class: [{ "is-select": _vm.videoResolution === item.name }],
          on: {
            click: function ($event) {
              return _vm.selectResolution(item)
            },
          },
        },
        [_vm._v(_vm._s(item.value))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }