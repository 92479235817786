var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ht-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "list",
          attrs: { data: _vm.getAttachmentList },
        },
        [
          _c("ht-table-column", {
            attrs: { label: "序号", type: "index", fixed: "left" },
          }),
          _vm._v(" "),
          _vm._l(_vm.AttachmentListTitle, function (item) {
            return _c("ht-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                prop: item.prop,
                "min-width": item.width || 100,
                fixed: item.fixed,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label === "附件名称"
                          ? _c(
                              "span",
                              [
                                _c("dw-file", {
                                  attrs: { url: row.url, "show-del": false },
                                  on: { "view-file": _vm.downloadFile },
                                }),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop] || "-"))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("ht-table-column-operation", {
            attrs: { width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _vm.isCanPreview(row.url) !== "other"
                      ? _c(
                          "el-link",
                          {
                            attrs: {
                              icon: "el-icon-view",
                              type: "primary",
                              href: row.url,
                              target: "_blank",
                            },
                          },
                          [_vm._v("预览")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ht-button",
                      {
                        attrs: { type: "text", icon: "el-icon-download" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(row.url)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }