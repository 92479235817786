import request from '@/utils/request'

export const BASE_USERS_URL = 'admin/users/'
export const SWITCH_ACCOUNT = 'auth/associated_users'

export function login({ username: username, password: password, action: action, userType: userType, appId: appId, sign: sign, loginType: loginType }) {
  return request.post('auth', {
    username: username,
    password: password,
    action: action,
    userType: userType,
    appId: appId,
    sign: sign,
    loginType: loginType
  })
}

export function postUserSign(data) {
  return request.post('auth/user_sign', data)
}

export function tempToken() {
  return request({ url: 'remote_guidance/temp_token', method: 'GET' })
}

export function getUserMenuApi() {
  return request({ url: 'feature_config/feature_config_templates/product/1', method: 'GET' })
}
//
// export function getUserInfo(roleOnly = false) {
//   return request.get('admin/userInfo/info', { params: { roleOnly: roleOnly }})
// // }
//
// export function getSwitchAccount() {
//   return request({ url: SWITCH_ACCOUNT, method: 'get' })
// }
//
// export function getSwitchAccountToken(userId) {
//   return request({ url: SWITCH_ACCOUNT, method: 'post', data: { 'userId': userId }})
// }
