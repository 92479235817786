<template>
  <div>
    <el-form ref="deviceForm" :model="deviceForm" :rules="rules" label-width="100px">
      <el-form-item label="设备编码：" :prop="isSync ? 'isSyncCode' : 'code'">
        <el-input
          v-model="deviceForm.code"
          :disabled="isSync"
          placeholder="请输入设备编码"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="设备名称：" prop="name">
        <el-input
          v-model="deviceForm.name"
          :disabled="isSync"
          placeholder="请输入设备名称"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="mac地址：" prop="beaconMacAddr">
        <el-input
          v-model="deviceForm.beaconMacAddr"
          placeholder="请输入mac地址"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="设备图片：" prop="imgUrls">
        <show-image
          v-if="deviceForm.imgUrls && deviceForm.imgUrls.length > 0"
          ref="logoUrl"
          :images="deviceForm.imgUrls"
          @delete-image="deleteImage(arguments, 'imgUrls')"
        />
        <template>
          <ht-upload-button
            key-prefix="customer_cnbm/devices"
            :multiple-selection="true"
            @on-files-uploaded="onFileUploaded"
          />
          <span style="display: inline-block; width:330px;font-size: 13px;color: #999999;">{{ hint }}</span>
        </template>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <ht-button @click="onCancel">取 消</ht-button>
      <ht-button type="primary" @click="onSubmit('deviceForm')">确 定</ht-button>
    </div>
  </div>
</template>

<script>
import { createEquipmentMgrApi, updateEquipmentsMgrItemById } from '@/api/devicesMgr/deviceMgr'
import { isValidMacAddress, isValidNumOrLetterOrSymbol } from '@/utils/validate'
import ShowImage from './showImage'

export default {
  name: 'DeviceForm',
  components: { ShowImage },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateBeaconMacAddr = (rule, value, callback) => {
      if (value && !(isValidMacAddress(value))) {
        callback(new Error('mac地址格式错误'))
      } else {
        callback()
      }
    }
    const validateNumOrLetterOrSymbol = (rule, value, callback) => {
      if (value && !(isValidNumOrLetterOrSymbol(value))) {
        callback(new Error('请输入数字，字母或特殊符号'))
      } else {
        callback()
      }
    }
    return {
      deviceForm: {
        code: undefined,
        name: undefined,
        imgUrls: [],
        beaconMacAddr: ''
      },
      rules: {
        code: [
          { required: true, message: '请输入设备编码', trigger: 'blur' },
          { message: '请输入数字、字母、或符号（./-_:）', trigger: 'blur', validator: validateNumOrLetterOrSymbol }
        ],
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        beaconMacAddr: [
          { required: false, trigger: 'blur', validator: validateBeaconMacAddr }
        ]
      },
      hint: '支持jpg、jpeg、png文件格式；大小不能超过5M',
      deviceId: undefined,
      isSync: false
    }
  },
  methods: {
    getDeviceInfo(deviceId, isSyncFromOuter, deviceInfo) {
      this.deviceId = deviceId
      this.isSync = isSyncFromOuter
      Object.keys(deviceInfo).forEach(key => {
        if (key === 'beaconMacAddr') {
          this.deviceForm[key] = deviceInfo[key] || ''
        } else {
          this.deviceForm[key] = deviceInfo[key]
        }
      })
    },
    onFileUploaded(context, url) {
      this.deviceForm.imgUrls = this.deviceForm.imgUrls.concat(url)
      if (this.deviceForm.imgUrls) {
        this.$refs.deviceForm.clearValidate('imgUrls')
      }
    },
    deleteImage(argument, name) {
      this.deviceForm[name] = argument[1]
    },
    onCancel() {
      this.clearValidate()
      this.$emit('on-cancel')
    },
    clearValidate() {
      this.$refs.deviceForm.clearValidate()
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            updateEquipmentsMgrItemById(this.deviceId, this.deviceForm).then(() => {
              this.$message({ message: '修改成功', type: 'success' })
              this.$emit('on-confirm')
              this.clearValidate()
            })
          } else {
            createEquipmentMgrApi(this.deviceForm).then(() => {
              this.$message({ message: '新建成功', type: 'success' })
              this.$emit('on-confirm')
              this.clearValidate()
            })
          }
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
