import SecurityCheckMgrRouter from '@/router/modules/securityEnvironment' // 安环管理
import ProjectCheckRouter from '@/router/modules/projectSiteRecord' // 现场记录
import DeviceMgrRouter from '@/router/modules/devicesMgr' // 设备数据demo
import RemoteGuidanceRouter from '@/router/modules/remoteGuidance' // 远程协助1.0
import DevicesAndAppsRouter from '@/router/modules/devicesAndApps' // 终端设备管理
import NewRemoteGuidanceRouter from '@/router/modules/newRemoteGuidance' // 远程指导
import TaskMgrRouter from '@/router/modules/taskMgr' // 工单管理
import ConstructionEquipmentMgrRouter from '@/router/modules/constructionEquipmentMgr' // 设备管理
import ConstructionProjectMgrRouter from '@/router/modules/constructionProjectMgr' // 工程管理
// import SparkDocumentMgrRouter from '@/router/modules/sparkDocumentMgr' // 问答文档管理
import KnowledgeBaseMgrRouter from '@/router/modules/knowleageBaseMgr'
import IncomingInspectionMgrRouter from '@/router/modules/incomingInspectionMgr'

import TpmRoutePatrolMgrRouter from '@/router/modules/tpmRoutePatrolMgr' // 路线巡检，临时

const asyncRoutes = [
  SecurityCheckMgrRouter,
  ProjectCheckRouter,
  DeviceMgrRouter,
  RemoteGuidanceRouter,
  DevicesAndAppsRouter,
  NewRemoteGuidanceRouter,
  TaskMgrRouter,
  ConstructionEquipmentMgrRouter,
  ConstructionProjectMgrRouter,
  // process.env.VUE_APP_CONFIG_HAS_SPARK_DOCUMENT === 'true' ? SparkDocumentMgrRouter : {},
  process.env.VUE_APP_CONFIG_HAS_KNOWLEDGE_BASE === 'true' ? KnowledgeBaseMgrRouter : {},
  IncomingInspectionMgrRouter,
  TpmRoutePatrolMgrRouter
]

export default asyncRoutes
