<template>
  <div class="external-call-container" :class="{'out-of-meeting-container': !isBeingInvited}" style="height: 100%">
    <user-info :session-key="sessionKey" @disconnect="disconnect" />
  </div>
</template>

<script>
import Connected from '@/assets/image/newRemoteGuidance/externalCall/connected.png'
import Disconnected from '@/assets/image/newRemoteGuidance/externalCall/disconnected.png'
import UserInfo from './userInfo'
import { mapGetters } from 'vuex'
import { UserTypeEnum } from '@/views/newRemoteGuidance/var/businessVar'
import { oneStopLoginSinomaFun } from '@/views/newRemoteGuidance/js/extarnalLogin'
import im from '@/views/newRemoteGuidance/rongCloud/im'
import store from '@/store'

export default {
  name: 'Index',
  components: { UserInfo },
  props: {
    sessionKey: { type: String, default: undefined }
  },
  data() {
    return {
      Connected, Disconnected,
      activeName: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'imIsConnected', 'isBeingInvited'])
  },
  created() {
    this.init()
  },
  mounted() {
    window.parent.postMessage({ type: 'MeetingFrameLoaded', params: { minWidth: '1000px', minHeight: '700px' }}, '*')
  },
  methods: {
    init() {
      oneStopLoginSinomaFun(this.sessionKey)
    },
    getUserRole(key) {
      return UserTypeEnum[key]
    },
    disconnect() {
      im.imDisconnect().then(r => {
        store.dispatch('user/setImConnectedStatue', false)
        window.parent.postMessage({ type: 'IsConnected', params: { isConnected: false }}, '*')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.external-call-container{
  position: relative;
  background: #ffffff;
}

.out-of-meeting-container{
  display: flex;
  justify-content: center;
}
</style>
