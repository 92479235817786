var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", {
        staticClass: "sidebar-container",
        staticStyle: { "box-shadow": "-1px 1px 4px rgba(0,21,41,0.3)" },
        attrs: { id: "sidebar-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: {
            hasTagsView: _vm.needTagsView,
            "mobile-in-meeting-main-container": _vm.isMobile && _vm.isInMeeting,
          },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [
              _c("navbar"),
              _vm._v(" "),
              _vm.needTagsView ? _c("tags-view") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMobile && _vm.isInMeeting
            ? _c("div", { staticClass: "mobile-info" }, [
                _vm._v("该功能需要将屏幕旋转至竖屏"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("app-main", {
            class: { "is-mobile": _vm.isMobile && _vm.isInMeeting },
          }),
          _vm._v(" "),
          _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }