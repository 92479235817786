<template>
  <ht-table ref="list" v-loading="isLoading" :data="equipmentList">
    <ht-table-column label="序号" type="index" fixed="left" />
    <ht-table-column
      v-for="item in tableTitle"
      :key="item.prop"
      :label="item.label"
      :prop="item.prop"
      :min-width="item.width || 100"
      :fixed="item.fixed"
    >
      <template v-slot="{row}">
        <template v-if="item.label === '租用数量'">
          <el-input-number v-if="showDel" v-model="row.leaseQuantity" :min="1" :max="row.constructionEquipmentIsWhole ? 1 :row.quantity" :step="1" step-strictly placeholder="租用数量" :precision="0" style="width: 90%" />
          <span v-else>{{ row.quantity }}</span>
        </template>
        <template v-else-if="item.label === '执行代码'">
          <el-input v-if="showDel && row.constructionEquipmentIsWhole" v-model="row.executeCode" :maxlength="5" show-word-limit placeholder="执行代码" style="width: 90%" />
          <span v-else>{{ row.executeCode || '-' }}</span>
        </template>
        <span v-else-if="item.label === '是否整机'">{{ row[item.prop] ? '是' : '否' }}</span>
        <el-tag v-else-if="item.label === '状态'" :type="ProjectLeaseStatusStyleEnum[row[item.prop]]">{{ ProjectLeaseStatusEnum[row[item.prop]] || '-' }}</el-tag>
        <span v-else>{{ row[item.prop] || '-' }}</span>
      </template>
    </ht-table-column>
    <ht-table-column-operation v-if="showDel || showStop || showEdit" :width="showStop ? 200 : 100">
      <template v-slot="{$index, row}">
        <el-button v-if="showEdit && row.constructionEquipmentIsWhole" type="text" icon="el-icon-edit" @click="onEdit($index)">修改执行代码</el-button>
        <ht-popover-button v-if="showStop" type="text" :danger-text="true" icon="el-icon-warning-outline" confirm-title="请确认是否要结束租用？" @confirmed="onStop($index)">结束租用</ht-popover-button>
        <ht-popover-button v-if="showDel" type="text" :danger-text="true" icon="el-icon-delete" confirm-title="请确认是否要删除该条数据？" @confirmed="onDelete($index)">删除</ht-popover-button>
      </template>
    </ht-table-column-operation>
  </ht-table>
</template>

<script>
import { ProjectLeaseStatusEnum, ProjectLeaseStatusStyleEnum } from '@/views/constructionProjectMgr/projectMgr/enum'
import HtListContainer from '@/components/HtListContainer'

export default {
  name: 'EquipmentList',
  extends: HtListContainer,
  props: {
    tableTitle: { type: Array, default: () => [] },
    equipmentList: { type: Array, default: () => [] },
    showStop: { type: Boolean, default: false },
    showDel: { type: Boolean, default: false },
    showEdit: { type: Boolean, default: false }
  },
  data() {
    return {
      ProjectLeaseStatusEnum, ProjectLeaseStatusStyleEnum
    }
  },
  methods: {
    onStop(index) {
      this.$emit('on-stop', index)
    },
    onDelete(index) {
      this.$emit('on-delete', index)
    },
    onEdit(index) {
      this.$emit('on-edit', index)
    }
  }
}
</script>
