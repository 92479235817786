import Layout from '@/layout'

const ViewRouter = {
  path: '/view',
  component: Layout,
  redirect: 'noRedirect',
  children: [
    {
      path: '/view/patrolWorkOrder/:orderId',
      component: () => import('@/views/taskMgr/patrolWorkOrderMgr/view'),
      name: 'viewPatrolWorkOrder',
      meta: { title: '巡检工单详情' },
      hidden: true,
      props: true
    },
    {
      path: '/view/repairWorkOrder/:orderId',
      component: () => import('@/views/taskMgr/repairWorkOrderMgr/view'),
      name: 'viewRepairWorkOrder',
      meta: { title: '维修工单详情' },
      hidden: true,
      props: true
    },
    {
      path: '/constructionEquipmentMgr/equipmentLeaseRecords/list',
      component: () => import('@/views/constructionEquipmentMgr/equipmentLeaseRecords/list'),
      name: 'equipmentLeaseRecords',
      meta: { title: '设备租赁记录' },
      hidden: true
    }
  ]
}

export default ViewRouter
