import request from '@/utils/request'
const construction = 'construction/'
const warehouse = 'warehouse/'
const warehouses = 'warehouses/'
const dropdown_warehouses = 'dropdown_warehouses/'

// 获取仓库列表
export function getWarehouses(listQuery) {
  return request({ url: `${construction}${warehouses}`, method: 'GET', params: listQuery })
}

// 获取仓库详情
export function getWarehouse(warehouseId) {
  return request({ url: `${construction}${warehouse}${warehouseId}`, method: 'GET' })
}

// 新增仓库
export function postWarehouses(data) {
  return request({ url: `${construction}${warehouses}`, method: 'POST', data: data })
}

// 修改仓库
export function putWarehouse(warehouseId, data) {
  return request({ url: `${construction}${warehouse}${warehouseId}`, method: 'PUT', data: data })
}

// 删除仓库
export function deleteWarehouse(warehouseId) {
  return request({ url: `${construction}${warehouse}${warehouseId}`, method: 'DELETE' })
}

// 获取仓库：下拉列表不分页
export function getDropdownWarehouses(listQuery) {
  return request({ url: `${construction}${dropdown_warehouses}`, method: 'GET', params: listQuery })
}
