<template>
  <el-card class="container">
    <div v-for="(item,index) in articleList" :key="index" class="article-container">
      <h3 class="title" @click="openArticleDialog(item)">{{ item.title }}</h3>
      <div class="info" v-html="replacePhraseWithLabel(item.info,item.keyword, '<mark>', '</mark>')" />
    </div>
    <ht-dialog ref="articleDialog" title="测试文章定位" width="800px" @close="closeArticleDialog">
      <div slot="content" class="article-detail-container">
        <h2 class="title">{{ article && article.title }}</h2>
        <div class="content" v-html="article && article.content" />
      </div>
    </ht-dialog>
  </el-card>
</template>

<script>
import { Data1, Data2 } from '@/views/test/richText/data'
export default {
  name: 'Scroll',
  data() {
    return {
      articleList: [
        {
          title: '《狗·猫·鼠》',
          keyword: '老鼠数铜钱',
          info: '老鼠的大敌其实并不是猫。春后，你听到它“咋！咋咋咋咋！”地叫着，大家称为“老鼠数铜钱”的，便知道它的可怕的屠伯已经光临了。这声音是表现绝望的惊恐的，虽然遇见猫，还不至于这样叫。',
          content: Data1
        },
        {
          title: '《二十四孝图》',
          keyword: '老莱娱亲',
          info: '其中最使我不解，甚至于发生反感的，是“老莱娱亲”和“郭巨埋儿”两件事',
          content: Data2
        }
      ],
      article: null
    }
  },
  methods: {
    openArticleDialog(item) {
      this.article = item
      // 给目标段落加标签
      this.article.content = this.replacePhraseWithLabel(item.content, item.info, '<span id="test">', '</span>')
      // 关键字设置高亮
      this.article.content = this.replacePhraseWithLabel(this.article.content, item.keyword, '<mark>', '</mark>')
      this.$refs.articleDialog.dialogVisible = true
      this.$nextTick(() => {
        // 获取要滚动到的元素
        const element = document.getElementById('test')

        // 滚动到元素的视图，设置 behavior 为 'smooth' 以实现平滑滚动
        element.scrollIntoView()
      })
    },
    closeArticleDialog() {
      this.article = null
      this.$refs.articleDialog.dialogVisible = false
    },
    replacePhraseWithLabel(text, phrase, startLabel, endLabel) {
      // 使用正则表达式查找短语
      const match = text.match(new RegExp(phrase, 'g'))

      // 如果没有找到匹配项，返回原始文本
      if (!match) {
        return text
      }

      // 将每个匹配项包裹在 <span> 标签中
      const replacedText = match.map(phrase => `${startLabel}${phrase}${endLabel}`).join('')

      // 将替换后的文本返回到调用位置
      return text.replace(new RegExp(phrase, 'g'), replacedText)
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  .article-container{
    .title{
      display: inline-block;
      cursor: pointer;
    }
    .title:hover{
      text-decoration: underline #2E5AFF;
      color: #2E5AFF;
    }
  }
}
.article-detail-container{
  height: 400px;
  .title{
    text-align: center;
  }
  .content{
    height: calc(100% - 50px);
    overflow-y: scroll;
  }
}
</style>
