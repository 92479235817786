<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container"
    :style="{height: height + 'px'}"
    :class="{'is-used-step-container': !isEdit}"
    title="hf_use_description"
  >
    <div class="view-container">
      <top-section
        ref="topSection"
        :is-focused-item="isFocusedItem"
        :current-index="currentIndex"
        :count="count"
        :title="properties.title"
        :show-more-function="showMoreFunction"
        :must-upload-photo="properties.mustUploadPhoto"
        :upload-photos="submitResults.uploadPhotos"
        :active-img-index="activeImgIndex"
        :button-disabled="buttonDisabled"
        :show-photos="!showMiddlePhotos"
        @more-function="moreFunction()"
        @native-play-tts="nativePlayTTS(ttsText)"
        @to-native-scan-code="toNativeScanCode"
        @to-remote-guidance="toRemoteGuidance"
        @to-ai-qa-assistant="toAiQaAssistant"
        @native-take-photo="toTakePhoto"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @set-active-item="setActiveItem"
        @jump-step="jumpStep"
      />
      <middle-section
        ref="middleSection"
        :is-focused-item="isFocusedItem"
        :active-name="activeName"
        :reference-image="properties.referenceImage"
        :text="properties.text"
        :fact-desc="submitResults.factDesc"
        :button-disabled="buttonDisabled"
        :height="getMiddleHeight"
        :show-text="false"
        :show-reference-image="false"
        :show-photos="showMiddlePhotos"
        :must-upload-photo="properties.mustUploadPhoto"
        :active-img-index="activeImgIndex"
        :upload-photos="submitResults.uploadPhotos"
        @select-reference-image="selectReferenceImage"
        @select-text="selectText"
        @select-photos="selectPhotos"
        @set-active-item="setActiveItem"
        @open-photo="openPhoto"
        @close-photo="closePhoto"
        @del-photo="delPhoto"
        @prev-photo="prevPhoto"
        @next-photo="nextPhoto"
        @input-desc="inputDesc"
        @clear-fact-desc="clearFactDesc"
        @open-reference-image="openReferenceImage"
        @close-reference-viewer="closeReferenceViewer"
      />
      <div class="middle-second-section">
        <div class="result-container">
          <div class="result-left-container" title="hf_use_description">
            <el-button class="result-button" type="text" :disabled="buttonDisabled" title="检查类型" @click="openChooseItemDialog('检查类型')">
              <span class="required-item">*</span>检查类型:
            </el-button>
            <el-input ref="rectDescRef" v-model="submitResults.checkType" placeholder="请选择检查类型" class="result-input" disabled />
          </div>
          <div class="result-right-container" title="hf_use_description">
            <el-button class="result-button" type="text" :disabled="buttonDisabled" title="隐患等级" @click="openChooseItemDialog('隐患等级')">
              <span class="required-item">*</span>隐患等级:
            </el-button>
            <el-input ref="rectDescRef" v-model="submitResults.errorLevel" placeholder="请选择隐患等级" class="result-input" disabled />
          </div>
        </div>
      </div>
      <bottom-section
        ref="bottomSection"
        :count="count"
        :current-index="currentIndex"
        :is-last-step="isLastStep"
        :button-disabled="buttonDisabled"
        @to-prev="toPrev()"
        @to-next="toNext()"
        @save-record="saveRecord()"
        @submit-record="submitRecord()"
      />
    </div>

    <custom-dialog ref="chooseItemDialog" :title="`选择${dialogTitle}`" width="500px" color="#2e5aff" @close="closeChooseItemDialog">
      <template slot="content">
        <el-radio-group v-model="chooseItem" @change="closeChooseItemDialog">
          <el-radio v-for="(item, key) in chooseItems" :key="key" :label="item.name" :class="[`radio-${item.color}`]">{{ item.value }}</el-radio>
        </el-radio-group>
      </template>
    </custom-dialog>

    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import { ErrorLevelEnum, CheckTypeEnum } from '@/views/taskMgr/enum'
import TopSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/topSection'
import MiddleSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/middleSection'
import BottomSection from '@/views/taskMgr/newTemplateMgr/components/patrolTemplate/comComponents/bottomSection'
import CustomDialog from '@/views/taskMgr/newTemplateMgr/components/customDialog'

export default {
  name: 'StepUi',
  components: { TopSection, MiddleSection, BottomSection, CustomDialog },
  mixins: [StepUiMixin],
  props: {
    properties: {
      type: Object,
      default() {
        return {
          title: null,
          mustUploadPhoto: null
        }
      }
    },
    results: {
      type: Object,
      default() {
        return {
          latitude: null,
          longitude: null,
          location: null,
          factDesc: null,
          errorLevel: null,
          checkType: null
        }
      }
    }
  },
  data() {
    return {
      ErrorLevelEnum, CheckTypeEnum,
      dialogTitle: null,
      chooseItem: null,
      chooseItems: ErrorLevelEnum,
      activeName: 'photos'
    }
  },
  computed: {
    getMiddleHeight() {
      return this.height - 160
    }
  },
  created() {
    const firstText = `步骤${this.currentIndex + 1}：${this.properties.title}，`
    const secondText = '请选择检查类型和隐患等级'
    this.ttsText = firstText + secondText
  },
  methods: {
    openChooseItemDialog(name) {
      if (name === '隐患等级') {
        this.dialogTitle = name
        this.chooseItems = ErrorLevelEnum
        this.chooseItem = this.submitResults.errorLevel
      } else if (name === '检查类型') {
        this.dialogTitle = name
        this.chooseItems = CheckTypeEnum
        this.chooseItem = this.submitResults.checkType
      }
      this.$refs.chooseItemDialog.dialogVisible = true
    },
    closeChooseItemDialog() {
      if (this.dialogTitle === '隐患等级') {
        this.submitResults.errorLevel = this.chooseItem
      } else if (this.dialogTitle === '检查类型') {
        this.submitResults.checkType = this.chooseItem
      }
      this.$refs.chooseItemDialog.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";
.step-ui-detail-container{
  .view-container{
    .middle-section{
      .right-container{
        display: flex;
        flex-direction: column;
        .check-standard{
          flex: 1;
        }
      }
    }
    .middle-second-section {
      padding: 0 20px;
      .result-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0 0;
        .result-left-container, .result-right-container{
          flex: 1;
          display: flex;
          flex-direction: row;
          .result-button{
            font-size: 1.5rem;
            color: #000000;
            font-weight: bold;
            padding: 0 10px 0 0 ;
          }
        }
        .result-left-container{
          margin-right: 10px;
        }

        .result-input{
          flex: 1;
        }
        ::v-deep .el-input__inner{
          height: 100%;
          color: #000000;
          font-size: 1.3rem;
          border: none;
          background: #ffffff;
          line-height: normal;
        }
      }
    }
  }
}

.el-radio-group{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 30px;
  .el-radio{
    flex-basis: 50%;
    margin-right: 0;
    font-size: 1.3rem;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ::v-deep .el-radio__input{
      font-size: 1.3rem;
      .el-radio__inner{
        font-size: 1.3rem;
      }
      .el-radio__inner{
        width: 20px;
        height: 20px;
      }
    }
    ::v-deep .el-radio__label{
      font-size: 1.3rem;
    }
  }
}

.radio-ea360f{
  color: #ea360f;
  ::v-deep .el-radio__input.is-checked{
    .el-radio__inner{
      border-color: #ea360f;
      background: #ea360f;
    }
  }
  ::v-deep .el-radio__label{
    color: #ea360f;
  }
}

.radio-ee813c{
  color: #ee813c;
  ::v-deep .el-radio__input.is-checked{
    .el-radio__inner{
      border-color: #ee813c;
      background: #ee813c;
    }
  }
  ::v-deep .el-radio__label{
    color: #ee813c;
  }
}

.radio-f6c343{
  color: #f6c343;
  ::v-deep .el-radio__input.is-checked{
    .el-radio__inner{
      border-color: #f6c343;
      background: #f6c343;
    }
  }
  ::v-deep .el-radio__label{
    color: #f6c343;
  }
}

.radio-2e5aff{
  color: #2e5aff;
  ::v-deep .el-radio__input.is-checked{
    .el-radio__inner{
      border-color: #2e5aff;
      background: #2e5aff;
    }
  }
  ::v-deep .el-radio__label{
    color: #2e5aff;
  }
}

.radio-6c6c6c{
  color: #6c6c6c;
  ::v-deep .el-radio__input.is-checked{
    .el-radio__inner{
      border-color: #6c6c6c;
      background: #6c6c6c;
    }
  }
  ::v-deep .el-radio__label{
    color: #6c6c6c;
  }
}
</style>

