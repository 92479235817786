<template>
  <div class="video-container">
    <video
      ref="videoPlayer"
      class="video"
      controls
      @ended="exitFullscreen"
      @click="clickHandler"
    >
      <source :src="src" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    src: { type: String, default: undefined },
    buttonDisabled: { type: Boolean, default: false }
  },
  methods: {
    clickHandler() {
      this.clickPlay()
    },
    clickPlay() {
      const videoPlayer = this.$refs.videoPlayer
      if (videoPlayer) {
        videoPlayer.play()
      }
      this.requestFullscreen()
    },
    clickPause() {
      const videoPlayer = this.$refs.videoPlayer
      if (videoPlayer) {
        videoPlayer.pause()
      }
    },
    clickResume() {
      const videoPlayer = this.$refs.videoPlayer
      if (videoPlayer) {
        videoPlayer.resume()
      }
    },
    clickStop() {
      const videoPlayer = this.$refs.videoPlayer
      if (videoPlayer) {
        videoPlayer.stop()
      }
      this.exitFullscreen()
    },
    requestFullscreen() {
      const videoPlayer = this.$refs.videoPlayer
      if (videoPlayer) {
        const requestFullscreen = videoPlayer.requestFullscreen || videoPlayer.mozRequestFullScreen || videoPlayer.webkitRequestFullscreen || videoPlayer.msRequestFullscreen
        if (requestFullscreen) {
          requestFullscreen.call(videoPlayer)
        }
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen()
      }
      this.$emit('on-finish-play')
    }
  }
}
</script>

<style scoped lang="scss">
.video-container{
  position: relative;
  width: 100%;
  .video{
    width: 100%;
    border: 1px solid #b4bccc;
  }
  .del-button{
    position: absolute;
    bottom: 10px;
    right: 0;
  }
}
</style>
