<template>
  <div>
    <el-form ref="roleForm" :model="roleForm" :rules="rules" label-width="120px" style="margin-right: 20px">
      <el-form-item label="角色名称：" prop="name">
        <el-input v-model="roleForm.name" placeholder="请输入角色名称" />
      </el-form-item>
      <el-form-item label="远程指导类型：" prop="remoteGuidanceRole">
        <el-select v-model="roleForm.remoteGuidanceRole" :disabled="isDefaultRole" placeholder="请选择远程指导类型" style="width: 100%">
          <el-option v-for="(value,key) in RemoteGuidanceRoleTypeEnum" :key="key" :label="value" :value="key" />
        </el-select>
        <span style="color: #f56c6c">远程指导的”联系人“菜单WEB端仅对专家开放</span>
      </el-form-item>
      <el-form-item label="角色描述：" prop="desc">
        <el-input v-model="roleForm.desc" placeholder="请输入角色描述" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="margin-left: 30%">
      <ht-button icon="el-icon-close" @click="cancelSubmit">取消</ht-button>
      <ht-button :loading="isSubmitting" type="primary" icon="el-icon-check" @click="submitRoleFormFun('roleForm')">确定</ht-button>
    </div>
  </div>
</template>

<script>
import { createRoleApi, updateRoleApi } from '@/api/systemMgr/roleMgr'
import { RemoteGuidanceRoleTypeEnum } from '@/views/systemMgr/enum'

export default {
  name: 'RoleForm',
  props: {
    isEdit: { type: Boolean, default: false },
    roleId: { type: [String, Number], default: undefined }
  },
  data() {
    return {
      RemoteGuidanceRoleTypeEnum,
      roleForm: {
        name: null,
        remoteGuidanceRole: null,
        desc: null
      },
      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        remoteGuidanceRole: [{ required: true, message: '请选择远程指导类型', trigger: 'change' }]
      },
      isSubmitting: false,
      isDefaultRole: false
    }
  },
  methods: {
    getRoleInfo(roleInfo) {
      Object.keys(this.roleForm).forEach(key => (this.roleForm[key] = roleInfo[key]))
      this.roleForm.featureMenuJson = roleInfo.featureMenuJson
      this.isDefaultRole = roleInfo.tag === 'ADMIN' || roleInfo.tag === 'EXPERT' || roleInfo.tag === 'STAFF'
    },
    submitRoleFormFun(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          const api = this.roleId ? updateRoleApi(this.roleId, this.roleForm) : createRoleApi(this.roleForm)
          api.then(() => {
            this.$message({ message: '保存成功', center: true, type: 'success' })
            this.isSubmitting = false
            this.$emit('confirm-submit')
          }).catch(() => { this.isSubmitting = false })
        } else {
          this.$message({ message: `请确认必填项是否填写或数据填写是否有误`, type: 'error', center: true })
          return false
        }
      })
    },
    cancelSubmit() {
      this.$emit('cancel-submit')
    }
  }
}
</script>

<style scoped>

</style>
