import dwCustomImMsg from './dwCustomImMsg'
import im from '@/views/newRemoteGuidance/rongCloud/im'
import { postInviteRecord } from '@/api/newRemoteGuidance/newRemoteGuidance'
import { InviteRecordType } from '@/views/newRemoteGuidance/var/businessVar'

export default {
  methods: {
    // 会议邀请消息
    sendMeetingInviteMsg(attendee, isSingle = false) {
      this.isSingleMeeting = isSingle
      const data = {
        msgType: dwCustomImMsg.DwMeetingInvite.MsgType,
        roomId: this.roomId,
        chatRoomId: this.chatRoomId,
        senderId: this.userImInfo.imUserId,
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        senderRoles: this.userInfo.roles,
        senderAvatarUrl: this.userInfo.avatarUrl
      }
      const obj = new dwCustomImMsg.DwMeetingInvite(data)
      im.sendDwCustomPrivateMsg(attendee.imUserId, obj)
      // 发出邀请，需要记录邀请记录
      const inviteRecordData = {
        type: InviteRecordType.INVITING,
        roomId: this.roomId,
        inviterImUserId: this.userImInfo.imUserId,
        inviteeImUserId: attendee.imUserId
      }
      postInviteRecord(inviteRecordData)
      setTimeout(() => { this.inviteTimerFun(attendee) })
    },
    // 会议邀请无响应
    sendMeetingInviteNoResponseMsg(attendee) {
      const data = {
        msgType: dwCustomImMsg.DwMeetingInviteCancel.MsgType,
        roomId: this.roomId,
        senderId: this.userImInfo.imUserId,
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        senderRoles: this.userInfo.roles,
        reason: dwCustomImMsg.DwMeetingInviteCancel.NO_RESPONSE
      }
      const obj = new dwCustomImMsg.DwMeetingInviteCancel(data)
      im.sendDwCustomPrivateMsg(attendee.imUserId, obj)
      // 未响应邀请，需要记录未响应邀请记录
      const inviteRecordData = {
        type: InviteRecordType.NO_RESPONSE,
        roomId: this.roomId,
        inviterImUserId: this.userImInfo.imUserId,
        inviteeImUserId: attendee.imUserId
      }
      postInviteRecord(inviteRecordData)
      this.rejectInviteFun(attendee.imUserId)
      this.meetingNoResponseFun(attendee.fullName)
    },
    // 会议邀请取消
    sendMeetingInviteCancelMsg(targetId) {
      const data = {
        msgType: dwCustomImMsg.DwMeetingInviteCancel.MsgType,
        roomId: this.roomId,
        senderId: this.userImInfo.imUserId,
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        senderRoles: this.userInfo.roles,
        reason: dwCustomImMsg.DwMeetingInviteCancel.CANCEL
      }
      const obj = new dwCustomImMsg.DwMeetingInviteCancel(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
      // 取消邀请，需要记录取消邀请记录
      const inviteRecordData = {
        type: InviteRecordType.CANCELLED,
        roomId: this.roomId,
        inviterImUserId: this.userImInfo.imUserId,
        inviteeImUserId: targetId
      }
      postInviteRecord(inviteRecordData)
    },
    sendMeetingScreenStatusMsg(val) {
      const data = {
        msgType: dwCustomImMsg.DwMeetingScreenStatus.MsgType,
        senderId: this.userImInfo.imUserId,
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        isShare: val
      }
      const obj = new dwCustomImMsg.DwMeetingScreenStatus(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendMeetingRecordingStatusMsg(val) {
      const data = {
        msgType: dwCustomImMsg.DwMeetingRecordingStatus.MsgType,
        senderId: this.userImInfo.imUserId,
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        isRecord: val
      }
      const obj = new dwCustomImMsg.DwMeetingRecordingStatus(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendMicMutePrivateMsg(targetId, val, command = true) {
      const data = {
        msgType: dwCustomImMsg.DwMicMute.MsgType,
        isMute: !val,
        senderId: this.userImInfo.imUserId,
        direction: command ? dwCustomImMsg.DwDeviceMessageDirection.Command : dwCustomImMsg.DwDeviceMessageDirection.Status
      }
      const obj = new dwCustomImMsg.DwMicMute(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendCamMutePrivateMsg(targetId, val, command = true) {
      const data = {
        msgType: dwCustomImMsg.DwCamMute.MsgType,
        isMute: !val,
        senderId: this.userImInfo.imUserId,
        direction: command ? dwCustomImMsg.DwDeviceMessageDirection.Command : dwCustomImMsg.DwDeviceMessageDirection.Status
      }
      const obj = new dwCustomImMsg.DwCamMute(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendMicMuteChatRoomMsg(val, command = true) {
      const data = {
        msgType: dwCustomImMsg.DwMicMute.MsgType,
        isMute: !val,
        senderId: this.userImInfo.imUserId,
        direction: command ? dwCustomImMsg.DwDeviceMessageDirection.Command : dwCustomImMsg.DwDeviceMessageDirection.Status
      }
      const obj = new dwCustomImMsg.DwMicMute(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj, 'sendMicMuteEvent')
    },
    sendCamMuteChatRoomMsg(val, command = true) {
      const data = {
        msgType: dwCustomImMsg.DwCamMute.MsgType,
        isMute: !val,
        senderId: this.userImInfo.imUserId,
        direction: command ? dwCustomImMsg.DwDeviceMessageDirection.Command : dwCustomImMsg.DwDeviceMessageDirection.Status
      }
      const obj = new dwCustomImMsg.DwCamMute(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj, 'sendCamMuteEvent')
    },
    sendSwitchUvcCamMsg(targetId, val) {
      const data = {
        msgType: dwCustomImMsg.DwSwitchUvcCam.MsgType,
        isUvcCam: val,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwSwitchUvcCam(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendCamSwitchMsg(targetId, val) {
      const data = {
        msgType: dwCustomImMsg.DwCamSwitch.MsgType,
        isFront: val,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwCamSwitch(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendCamZoomMsg(targetId, val) {
      const data = {
        msgType: dwCustomImMsg.DwCamZoom.MsgType,
        zoomLevel: val,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwCamZoom(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendFlashlightMsg(targetId, val) {
      const data = {
        msgType: dwCustomImMsg.DwFlashlight.MsgType,
        isOn: val,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwFlashlight(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendCamExposureMsg(targetId, val) {
      const data = {
        msgType: dwCustomImMsg.DwCamExposure.MsgType,
        exposureLevel: val,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwCamExposure(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendVideoConfigMsg(targetId, val) {
      const data = {
        msgType: dwCustomImMsg.DwVideoConfig.MsgType,
        resolution: val,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwVideoConfig(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendVideoConfigStatusMsg(targetId, val) {
      const data = {
        msgType: dwCustomImMsg.DwVideoConfig.MsgType,
        resolution: val,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Status
      }
      const obj = new dwCustomImMsg.DwVideoConfig(data)
      im.sendDwCustomChatRoomMsg(targetId, obj)
    },
    sendCamManualFocusMsg(targetId, offsetX, offsetY) {
      const data = {
        msgType: dwCustomImMsg.DwManualFocus.MsgType,
        offsetX: offsetX,
        offsetY: offsetY,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwManualFocus(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendDwQueryDeviceStatusMsg(targetId) {
      const data = {
        msgType: dwCustomImMsg.DwQueryDeviceStatus.MsgType,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Command
      }
      const obj = new dwCustomImMsg.DwQueryDeviceStatus(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendDwDeviceStatusMsg(targetId, status) {
      const data = {
        msgType: dwCustomImMsg.DwDeviceStatus.MsgType,
        senderId: this.userImInfo.imUserId,
        direction: dwCustomImMsg.DwDeviceMessageDirection.Status
      }
      const obj = new dwCustomImMsg.DwDeviceStatus(Object.assign({}, data, status))
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendLaserMsg(status, targetUserId) {
      const data = {
        msgType: dwCustomImMsg.DwLaser.MsgType,
        status: status ? dwCustomImMsg.DwLaser.Open : dwCustomImMsg.DwLaser.Close,
        targetUserId: targetUserId
      }
      const obj = new dwCustomImMsg.DwLaser(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendPaintMsg(status, targetUserId) {
      const data = {
        msgType: dwCustomImMsg.DwPaint.MsgType,
        status: status ? dwCustomImMsg.DwPaint.Open : dwCustomImMsg.DwPaint.Close,
        targetUserId: targetUserId
      }
      const obj = new dwCustomImMsg.DwPaint(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendPaintDrawingMsg(paintData, status = true, targetUserId, senderScreenSize) {
      const content = {
        msgType: dwCustomImMsg.DwPaint.MsgType,
        paintData: paintData,
        status: status ? dwCustomImMsg.DwPaint.Open : dwCustomImMsg.DwPaint.Clear,
        targetUserId: targetUserId,
        senderScreenSize: senderScreenSize
      }
      const obj = new dwCustomImMsg.DwPaint(content)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendLaserMovingMsg(laserData, status = true, targetUserId, senderScreenSize) {
      const content = {
        msgType: dwCustomImMsg.DwLaser.MsgType,
        laserData: laserData,
        status: status ? dwCustomImMsg.DwLaser.Open : dwCustomImMsg.DwLaser.Close,
        targetUserId: targetUserId,
        senderScreenSize: senderScreenSize
      }
      const obj = new dwCustomImMsg.DwLaser(content)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendFocusGuidanceMsg(focusedUserId, isFocused) {
      const data = {
        msgType: dwCustomImMsg.DwFocusGuidance.MsgType,
        senderId: this.userImInfo.imUserId,
        isFocused: isFocused,
        focusedUserId: focusedUserId
      }
      const obj = new dwCustomImMsg.DwFocusGuidance(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendFreezeGuidanceMsg(isFrozen, frozenUserId, frozenImgUrl) {
      const data = {
        msgType: dwCustomImMsg.DwFreezeGuidance.MsgType,
        senderId: this.userImInfo.imUserId,
        isFrozen: isFrozen,
        frozenUserId: frozenUserId,
        frozenImgUrl: frozenImgUrl
      }
      const obj = new dwCustomImMsg.DwFreezeGuidance(data)
      im.sendDwCustomChatRoomMsg(this.chatRoomId, obj)
    },
    sendTrackNotReadyMsg(targetId, trackId, trackTag, isVideoTrack) {
      const data = {
        msgType: dwCustomImMsg.DwTrackNotReady.MsgType,
        senderId: this.userImInfo.imUserId,
        trackId: trackId,
        trackTag: trackTag,
        isVideoTrack: isVideoTrack
      }
      const obj = new dwCustomImMsg.DwTrackNotReady(data)
      im.sendDwCustomPrivateMsg(targetId, obj)
    },
    sendImageMsg(imageUri, content) {
      const data = {
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        senderAvataUrl: this.userInfo.avatar,
        imageUri: imageUri,
        content: content
      }
      im.sendImageMessageInChatRoom(this.chatRoomId, data)
    },
    sendFileMsg({ name, size, type, fileUrl }) {
      const data = {
        senderFullName: this.userInfo.fullName,
        senderUserName: this.userInfo.account.userName,
        senderAvataUrl: this.userInfo.avatar,
        name: name,
        size: size,
        type: type,
        fileUrl: fileUrl
      }
      im.sendFileMessageInChatRoom(this.chatRoomId, data)
    }
  }
}
