import { ADMIN } from '@/views/commonVar'
import Layout from '@/layout'

const SystemMgrRouter = {
  path: '/systemMgr',
  component: Layout,
  redirect: 'noRedirect',
  name: 'systemMgrMenu',
  meta: { title: '系统管理', icon: 'system_mgr', roles: [ADMIN] },
  children: [
    {
      path: '/systemMgr/userMgr/list',
      component: () => import('@/views/systemMgr/userMgr/list'),
      name: 'userMgrList',
      meta: { title: '用户管理' }
    },
    {
      path: '/systemMgr/roleMgr/list',
      component: () => import('@/views/systemMgr/roleMgr/list'),
      name: 'roleMgrList',
      meta: { title: '角色管理' }
    },
    {
      path: '/systemMgr/menuMgr/list',
      component: () => import('@/views/systemMgr/menuMgr/index'),
      name: 'menuMgrList',
      meta: { title: '菜单管理' }
    }
  ]
}

export default SystemMgrRouter
