<template>
  <div v-if="showContainer" class="workflow-steps-container" :style="{height: windowHeight + 'px'}">
    <div id="workflowTitle" class="workflow-title">{{ `工单名称：${workFlow && workFlow.name || '-'}` }}</div>
    <template v-for="(item, index) in steps">
      <transition :key="index" :name="transitionName">
        <component
          :is="item.stepUi"
          v-if="currentStepIndex === index"
          :key="index"
          :count="steps.length"
          :current-index="currentStepIndex"
          :is-first-step="index === 0"
          :is-last-step="index === steps.length - 1"
          :properties="item.properties"
          :results="item.results"
          :height="getStepHeight"
          class="component"
          @to-prev="toPrev"
          @to-next="toNext"
          @to-native-scan-code="toNativeScanCode"
          @to-remote-guidance="toRemoteGuidance"
          @to-ai-qa-assistant="toAiQaAssistant"
          @jump-step="jumpStep"
          @save-record="saveRecord"
          @submit-record="submitRecord"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import AndroidWebViewMixin from '@/views/components/androidWebViewMixin.vue'
import { getWorkOrderNoAuthorization, putStepResultNoAuthorization } from '@/api/taskMgr/workOrder'
import { StepsUiProperties } from '@/views/taskMgr/newTemplateMgr/components/workFlowSteps'
import _ from 'lodash'

export default {
  name: 'WorkFlowSteps',
  mixins: [AndroidWebViewMixin],
  data() {
    return {
      StepsUiProperties,
      accessToken: null,
      workFlow: {},
      steps: [],
      currentStepIndex: null,
      currentStep: null,
      stepsForm: {},
      // transitionName: 'slide-next',
      transitionName: 'fade',
      windowHeight: 480,
      showContainer: true
    }
  },
  computed: {
    getStepHeight() {
      const workflowTitle = document.getElementById('workflowTitle')
      // const workflowTitleHeight = workflowTitle.offsetHeight || 40
      // return this.windowHeight - workflowTitleHeight
      return this.windowHeight - workflowTitle.offsetHeight
    }
  },
  created() {
    if (this.$route.query && this.$route.query.token) {
      this.accessToken = `JWT ${this.$route.query.token}`
      const templateId = this.$route.query.id
      if (templateId !== '0') {
        this.getWorkOrderDetail(this.accessToken, templateId)
      }
    }
    // this.reload()
  },
  mounted() {
    // this.windowHeight = window.innerHeight || 480
    this.windowHeight = window.innerHeight
  },
  methods: {
    getWorkOrderDetail(token, templateId) {
      getWorkOrderNoAuthorization(token, templateId).then(resp => {
        this.workFlow = resp
        this.currentStepIndex = resp.currentStep === 0 ? resp.currentStep : resp.currentStep - 1
        resp.steps.forEach(item => {
          let step = {}
          const index = StepsUiProperties.findIndex(uiItem => uiItem.tag === item.tag)
          if (index !== -1) {
            step = _.cloneDeep(StepsUiProperties[index])
            const keys = ['id', 'isEnd', 'isStart', 'properties', 'results']
            keys.forEach(key => {
              step[key] = item[key]
            })
            this.steps.push(step)
          }
        })
        this.currentStep = this.steps[this.currentStepIndex]
      })
    },
    toPrev(results) {
      // this.transitionName = 'slide-prev'
      this.submitStep(results, false).then(() => {
        this.currentStepIndex--
        this.currentStep = this.steps[this.currentStepIndex]
      })
    },
    toNext(results) {
      // this.transitionName = 'slide-next'
      this.submitStep(results, false).then(() => {
        this.currentStepIndex++
        this.currentStep = this.steps[this.currentStepIndex]
      })
    },
    toNativeScanCode(results) {
      this.submitStep(results, false).then(() => {
        this.nativeScanCode()
      })
    },
    toRemoteGuidance(results) {
      this.submitStep(results, false).then(() => {
        this.nativeStartRemoteGuidance()
      })
    },
    toAiQaAssistant(results) {
      this.submitStep(results, false).then(() => {
        this.nativeOpenAiChat()
      })
    },
    jumpStep(index, results) {
      this.submitStep(results, false).then(() => {
        this.currentStepIndex = index
        this.currentStep = this.steps[this.currentStepIndex]
      })
    },
    saveRecord(results) {
      this.submitStep(results, false).then(() => {})
      // this.submitStep(results).then((resp) => {
      //   this.$message({ message: '保存成功', duration: 2000, type: 'success', customClass: 'large-info-message' })
      //   if (this.currentStepIndex + 1 === this.steps.length) {
      //     // this.nativeOnSubmit()
      //   } else {
      //     this.currentStepIndex++
      //     this.currentStep = this.steps[this.currentStepIndex]
      //   }
      // })
    },
    submitRecord(results) {
      this.submitStep(results, true, true).then(() => {
        if (this.currentStepIndex + 1 === this.steps.length) {
          this.nativeOnSubmit()
        } else {
          this.currentStepIndex++
          this.currentStep = this.steps[this.currentStepIndex]
        }
      })
    },
    submitStep(results, checkData = true, isSubmit = false) {
      return new Promise((resolve, reject) => {
        this.currentStep.result = results
        this.getCurrentStepData()
        const verifyStepResult = checkData && !isSubmit ? this.verifyStepData(this.currentStep) : true
        const verifyStepsResult = isSubmit && this.currentStep.isEnd ? this.verifyAllStepsData() : true
        const isAbnormal = this.checkDataIsAbnormal(this.currentStep)
        if (verifyStepResult && verifyStepsResult) {
          const currentStep = isSubmit && this.currentStep.isEnd ? { ...this.currentStep, isSubmit: true, isAbnormal: isAbnormal } : { ...this.currentStep, isAbnormal: isAbnormal }
          putStepResultNoAuthorization(this.accessToken, this.currentStep.id, currentStep).then((resp) => {
            resolve(resp)
          }).catch(() => {
            reject()
          })
        } else {
          reject()
        }
      })
    },
    getCurrentStepData() {
      const step = {}
      const keys = ['id', 'isEnd', 'isStart', 'name', 'properties', 'results', 'tag']
      keys.forEach(key => {
        step[key] = this.currentStep[key]
      })
      this.currentStep = step
    },
    verifyStepData(stepData, needErrorMessage = true) {
      let verifyResult = true
      // if (stepData.properties.mustUploadPhoto) {
      //   if (stepData.results && stepData.results.uploadPhotos && stepData.results.uploadPhotos.length < 1) {
      //     if (needErrorMessage) {
      //       this.nativePlayTTS('当前步骤必须拍摄照片')
      //       this.$message({ message: '当前步骤必须拍摄照片', duration: 2000, type: 'error', customClass: 'large-info-message' })
      //     }
      //     verifyResult = false
      //     return verifyResult
      //   }
      // }
      if (stepData.tag === 'ResultRecordType') {
        if (stepData.results && !stepData.results.checkResult) {
          if (needErrorMessage) {
            this.nativePlayTTS('当前步骤未选择检查结果')
            this.$message({ message: '当前步骤未选择检查结果', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      if (stepData.tag === 'ValueRecordType') {
        if (stepData.results && (!stepData.results.value && stepData.results.value !== 0)) {
          if (needErrorMessage) {
            this.nativePlayTTS('当前步骤未填写数据')
            this.$message({ message: '当前步骤未填写数据', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      if (stepData.tag === 'HiddenDangerRecordType') {
        if (stepData.results && (!stepData.results.errorLevel)) {
          if (needErrorMessage) {
            this.nativePlayTTS('当前步骤未选择隐患等级')
            this.$message({ message: '当前步骤未选择隐患等级', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
        if (stepData.results && (!stepData.results.checkType)) {
          if (needErrorMessage) {
            this.nativePlayTTS('当前步骤未选择检查类型')
            this.$message({ message: '当前步骤未选择检查类型', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      if (stepData.tag === 'AccidentRecordType') {
        if (stepData.results && !stepData.results.accidentType) {
          if (needErrorMessage) {
            this.nativePlayTTS('当前步骤未选择事故类型')
            this.$message({ message: '当前步骤未选择事故类型', duration: 2000, type: 'error', customClass: 'large-info-message' })
          }
          verifyResult = false
          return verifyResult
        }
      }
      return verifyResult
    },
    verifyAllStepsData() {
      const errorStep = []
      this.steps.forEach((step, index) => {
        if (!this.verifyStepData(step, false)) {
          errorStep.push(index + 1)
        }
      })
      if (errorStep.length > 0) {
        this.nativePlayTTS(`当前工单第${errorStep.toString()}步有必填项未填写，请确认`)
        this.$message({ message: `当前工单第${errorStep.toString()}步有必填项未填写，请确认`, duration: 2000, type: 'error', customClass: 'large-info-message' })
        return false
      } else {
        return true
      }
    },
    checkDataIsAbnormal(stepData) {
      let checkedResult = false
      if (stepData.tag === 'ResultRecordType') {
        if (stepData.results && stepData.results.checkResult && stepData.results.checkResult === 'FAIL') {
          checkedResult = true
          return checkedResult
        }
      }
      if (stepData.tag === 'ValueRecordType') {
        if (stepData.results && stepData.results.value) {
          const minValue = parseFloat(stepData.properties.minValue)
          const maxValue = parseFloat(stepData.properties.maxValue)
          const value = parseFloat(stepData.results.value)
          if (minValue >= value || value >= maxValue) {
            checkedResult = true
            return checkedResult
          }
        }
      }
      if (stepData.tag === 'HiddenDangerRecordType') {
        if (stepData.results && stepData.results.errorLevel && stepData.results.errorLevel !== '无隐患') {
          checkedResult = true
          return checkedResult
        }
      }
      if (stepData.tag === 'AccidentRecordType') {
        if (stepData.results && stepData.results.accidentType && stepData.results.accidentType === 'HAPPENED') {
          checkedResult = true
          return checkedResult
        }
      }
      // 临时准入相关
      if (stepData.tag === 'VoiceRecordValueType' || stepData.tag === 'OCRRecordValueType' || stepData.tag === 'KeyboardRecordValueType') {
        if (stepData.results && stepData.results.value) {
          const minValue = parseFloat(stepData.properties.minValue)
          const maxValue = parseFloat(stepData.properties.maxValue)
          const value = parseFloat(stepData.results.value)
          if (minValue >= value || value >= maxValue) {
            checkedResult = true
            return checkedResult
          }
        }
      }
      return checkedResult
    },
    reload() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.showContainer = false
          setTimeout(() => {
            this.showContainer = true
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.workflow-steps-container{
  //position: fixed;
  height: 100%;
  //width: 100%;
  background: #e4edfa;
  .workflow-title{
    background: #e4edfa;
    font-size: 1.3rem;
    padding: 10px 20px 5px;
    font-weight: bold;
  }

  .component{
    background: #e4edfa;
  }
  .step-item{
    height: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// 上一项动画
.slide-prev-enter-active {
  animation: slide-prev-enter 0.5s ease-in-out;
}
.slide-prev-leave-active {
  animation: slide-prev-leave 0.5s ease-in-out;
}

@keyframes slide-prev-enter {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-prev-leave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

// 下一项动画
.slide-next-enter-active {
  animation: slide-next-enter 0.5s ease-in-out;
}
.slide-next-leave-active {
  animation: slide-next-leave 0.5s ease-in-out;
}

@keyframes slide-next-enter {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-next-leave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
