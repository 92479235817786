<template>
  <div
    v-if="showContainer"
    class="step-ui-detail-container"
    :style="{height: height + 'px'}"
    :class="{'is-used-step-container': !isEdit}"
    title="hf_use_description"
  >
    <get-goods-picking
      v-if="showList"
      ref="getGoodsPicking"
      :height="getMiddleHeight"
      :results="submitResults"
      :disabled="buttonDisabled"
      @choose-area="chooseArea"
      @choose-floor="chooseFloor"
      @to-scan-code="toScanCode"
      @to-pick="toPick"
    />
    <execute-goods-picking
      v-else-if="showGoods"
      ref="executeGoodsPicking"
      :height="getMiddleHeight"
      :results="submitResults"
      :disabled="buttonDisabled"
      @to-prev-goods="toPervGoods"
      @to-next-goods="toNextGoods"
      @to-confirm="toConfirm"
      @to-scan-code="toScanCode"
      @to-input-count="toInputCount"
      @play-tts="nativePlayTTS"
    />
    <div v-if="isEdit" class="edit-overlay" />
  </div>
</template>

<script>
import StepUiMixin from '@/views/taskMgr/newTemplateMgr/components/stepUiMixin'
import GetGoodsPicking from './getGoodsPicking'
import ExecuteGoodsPicking from './executeGoodsPicking'

export default {
  name: 'GoodsPicking',
  components: { GetGoodsPicking, ExecuteGoodsPicking },
  mixins: [StepUiMixin],
  props: {
    stepName: { type: String, default: '' },
    properties: { type: Object, default() { return { title: null } } },
    results: { type: Object, default() {
      return {
        area: null,
        areaName: null,
        floor: null,
        floorName: null,
        task: null,
        taskCode: null,
        goods: [],
        boxCode: null
      }
    } }
  },
  data() {
    return {
      showList: true,
      showGoods: false,
      scanType: null
    }
  },
  computed: {
    getMiddleHeight() {
      return this.height - 100
    },
    getUnPickGoods() {
      return this.submitResults.goods && this.submitResults.goods.filter(item => !item.isPicked) || []
    }
  },
  watch: {
    showList() {
      this.playTTS(1)
    }
  },
  created() {
    setTimeout(() => {
      this.playTTS(2)
    })
  },
  methods: {
    chooseArea(results) {
      this.submitResults = results
      this.playTTS(3)
    },
    chooseFloor(results) {
      this.submitResults = results
      this.playTTS(4)
    },
    toScanCode(type, results) {
      this.submitResults = results
      this.scanType = type
      this.saveRecord()
      this.nativeScanCode()
      // this.setScanCodeResult()
    },
    setScanCodeResult() {
      if (this.scanType === 'goods-box') {
        this.showList = true
        this.submitResults.boxCode = `P${this.getRandomNumber(6)}`
      } else if (this.scanType === 'goods-code') {
        this.showList = false
        this.$refs.executeGoodsPicking.setScanCodeResult('goods-code')
      } else if (this.scanType === 'goods-storage') {
        this.showList = false
        this.$refs.executeGoodsPicking.setScanCodeResult('goods-storage')
      }
    },
    toPervGoods(results, currentGoodsIndex) {
      this.submitResults = results
      this.saveRecord()
      this.showGoods = false
      setTimeout(() => {
        this.showGoods = true
        this.$nextTick(() => {
          this.$refs.executeGoodsPicking.currentGoodsIndex = currentGoodsIndex
          this.playTTS(5)
        })
      })
    },
    toNextGoods(results, currentGoodsIndex) {
      this.submitResults = results
      this.saveRecord()
      this.showGoods = false
      setTimeout(() => {
        this.showGoods = true
        this.$nextTick(() => {
          this.$refs.executeGoodsPicking.currentGoodsIndex = currentGoodsIndex
          this.playTTS(6)
        })
      })
    },
    toConfirm(results, currentGoodsIndex) {
      this.submitResults = results
      this.saveRecord()
      if (this.getUnPickGoods.length === 0) {
        this.nativePlayTTS('完成拣货')
        setTimeout(() => {
          this.submitRecord()
        }, 1000)
      } else {
        this.showGoods = false
        setTimeout(() => {
          this.showGoods = true
          this.$nextTick(() => {
            this.$refs.executeGoodsPicking.currentGoodsIndex = currentGoodsIndex === this.submitResults.goods.length - 1 ? this.submitResults.goods.findIndex(item => !item.isPicked) : currentGoodsIndex + 1
            this.playTTS(7)
          })
        })
      }
    },
    toPick(type, results) {
      this.submitResults = results
      this.scanType = null
      this.showList = false
      this.showGoods = true
      if (!this.showList && this.showGoods) {
        this.$nextTick(() => {
          const index = this.submitResults.goods.findIndex(item => !item.isPicked)
          this.$refs.executeGoodsPicking.currentGoodsIndex = index !== -1 ? index : 0
        })
      }
    },
    toInputCount() {
      this.nativeStartDictation()
    },
    setDictationResult(result) {
      this.$refs.executeGoodsPicking.setCount(result)
    },
    getRandomNumber(length) {
      let result = ''
      const characters = '0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    playTTS(code) {
      if (this.showList) {
        this.$nextTick(() => {
          const ttsText = this.$refs.getGoodsPicking.getTtsText()
          this.nativePlayTTS(ttsText)
        })
      } else if (this.showGoods) {
        this.$nextTick(() => {
          const ttsText = this.$refs.executeGoodsPicking.getTtsText()
          this.nativePlayTTS(ttsText)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stepUiCommonStyle";

.step-ui-detail-container{
  .result-container{
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .result-top-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0 0;
      .result-top-left-container, .result-top-right-container{
        flex: 1;
        display: flex;
        flex-direction: row;
        .result-button{
          font-size: 1.5rem;
          color: #000000;
          font-weight: bold;
          padding: 0 10px 0 0 ;
        }
      }
      .result-top-left-container{
        margin-right: 10px;
      }
      .result-input{
        flex: 1;
      }
      ::v-deep .el-input__inner{
        height: 100%;
        color: #000000;
        font-size: 1.3rem;
        border: none;
        background: #ffffff;
        line-height: normal;
      }
      ::v-deep .el-input-group__append{
        height: 100%;
        color: #000000;
        font-size: 1.5rem;
        border: none;
        background: #ffffff;
        line-height: normal;
        .svg-icon{
          color: #aaaaaa;
        }
      }
    }
    .result-bottom-container{
      flex: 1;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding: 10px;
      overflow: hidden;
      .result-middle-top-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .txt{
          font-size: 1.3rem;
        }
        .count{
          flex: 1;
          text-align: right;
          margin-left: 10px;
          color: #666666;
        }
        .el-button {
          height: 35px;
          color: #ffffff;
          background: $mobilePrimaryColor;
          border-radius: 5px;
          font-size: 1.3rem;
        }
      }
      .result-middle-bottom-container{
        overflow: hidden;
        margin: 10px 20px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .box-item{
          flex: 0 0 50%; /* 子元素占据容器宽度的50% */
          font-size: 1.2rem;
          padding: 10px 0;
        }
      }
    }
  }

  .bottom-container{
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    .square-circle-button {
      flex: 1;
      height: 40px;
      color: #ffffff;
      background: $mobilePrimaryColor;
      border-radius: 5px;
      font-size: 1.5rem;
    }
  }
}

.el-radio-group{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 30px;
  .el-radio{
    flex-basis: 50%;
    margin-right: 0;
    font-size: 1.3rem;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ::v-deep .el-radio__input{
      font-size: 1.3rem;
      .el-radio__inner{
        font-size: 1.3rem;
      }
      .el-radio__inner{
        width: 20px;
        height: 20px;
      }
    }
    ::v-deep .el-radio__label{
      font-size: 1.3rem;
    }
  }
}
</style>
