<template>
  <div :class="className" :style="{ width: width, height: height }" />
</template>

<script>
import echarts from 'echarts'
import { debounce } from '@/utils'

function getFormatter(params) {
  const time = params[0].axisValue
  const orderCount = `当日下发工单总数：${Number(params[0].value) + Number(params[1].value)}`
  const finishedCount = params[0].marker + params[0].seriesName + '：' + params[0].value
  const unFinishedCount = params[1].marker + params[1].seriesName + '：' + params[1].value
  return time + '<br>' + orderCount + '<br>' + finishedCount + '<br>' + unFinishedCount
}

export default {
  name: 'RepairFinishChart',
  props: {
    className: { type: String, default: 'chart' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '300px' },
    autoResize: { type: Boolean, default: true },
    firstName: { type: String, default: '' },
    secondName: { type: String, default: '' },
    xUnit: { type: String, default: '数量' },
    yUnit: { type: String, default: '时间' },
    color1: { type: String, default: '#84ce6b' },
    color2: { type: String, default: '#f8d463' }
  },
  data() {
    return {
      chart: null,
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function(params) {
            return getFormatter(params)
          }
        },
        legend: {
          data: [this.firstName, this.secondName],
          bottom: 0
        },
        grid: {
          top: '12%',
          left: '5%',
          right: '13%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          name: this.xUnit,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false // 刻度线
          },
          axisLabel: {
            formatter: function(value) {
              if (value % 1 === 0) {
                return value
              } else {
                return ''
              }
            }
          }
        },
        yAxis: {
          name: this.yUnit,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 600
          },
          type: 'category',
          data: [],
          axisLine: {
            show: false // 显示坐标轴线
          },
          axisTick: {
            show: false // 刻度线
          }
        },
        series: [
          {
            name: this.firstName,
            type: 'bar',
            stack: 'total',
            // label: { // 柱状图上的数据
            //   show: true,
            //   fontSize: 14,
            //   fontWeight: 'bold'
            // },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: {
                color: this.firstColor
              }
            },
            data: []
          },
          {
            name: this.secondName,
            type: 'bar',
            stack: 'total',
            // label: { // 柱状图上的数据
            //   show: true,
            //   fontSize: 14,
            //   fontWeight: 'bold'
            // },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: {
                color: this.color2
              }
            },
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    if (this.autoResize) {
      this.__resizeHanlder = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHanlder)
    }
    // 监听侧边栏的变化
    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.addEventListener('transitionend', this.__resizeHanlder)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHanlder)
    }

    if (document.getElementsByClassName('sidebar-container')[0]) {
      const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
      if (sidebarElm) {
        sidebarElm.removeEventListener('transitionend', this.__resizeHanlder)
      }
    }

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(option) {
      this.chart = echarts.init(this.$el)
      this.chart.setOption(option)
    },
    getData(time, currentFinishedCounts, currentUnfinishedCounts) {
      this.option.yAxis.data = time
      this.option.series[0].data = currentFinishedCounts
      this.option.series[1].data = currentUnfinishedCounts
      this.initChart(this.option)
    }
  }
}
</script>

<style scoped>

</style>
