<template>
  <div v-if="dialogVisible" class="dialog-outer-container">
    <div class="dialog-inner-container" :style="{width: width}">
      <div class="title-container">
        <div class="title" :style="{color: color, borderColor: color}">{{ title }}</div>
        <ht-button class="close-button" type="text" @click="onClose">关闭</ht-button>
      </div>
      <div class="content-container">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'
export default {
  name: 'CustomDialog',
  props: {
    width: { type: String, default: '50%' },
    title: { type: String, default: '' },
    color: { type: String, default: variables.primaryColor }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-outer-container{
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);
  .dialog-inner-container{
    background: #ffffff;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    padding: 30px;
    .title-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        border-left: 5px solid;
        font-size: 25px;
        font-weight: 500;
      }
      .close-button{
        height: 40px;
        color: #ffffff;
        font-size: 1.5rem;
        background: #2e5aff;
        border-radius: 5px;
        padding: 0 10px;
      }
    }
    .content-container{
      margin: 20px 0;
    }
  }
}
</style>
