var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ht-table",
    {
      ref: "list",
      staticStyle: { cursor: "pointer" },
      attrs: { data: _vm.videoList },
    },
    [
      _c("ht-table-column", {
        attrs: { label: "序号", type: "index", fixed: "left" },
      }),
      _vm._v(" "),
      _c("ht-table-column", {
        attrs: {
          label: "视频链接",
          prop: "url",
          "show-overflow-tooltip": false,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", href: row.url, target: "_blank" },
                  },
                  [_vm._v(_vm._s(row.url))]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }