var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBox
    ? _c(
        "div",
        {
          ref: "userListRef",
          staticClass: "on-user-list-container",
          class: {
            "fullscreen-on-user-list-container": _vm.fullscreen,
            "normal-on-user-list-container": !_vm.fullscreen,
            "is-mobile-fullscreen-on-user-list-container":
              _vm.fullscreen && _vm.isMobile,
          },
        },
        [
          _c(
            "div",
            { ref: "topContainerRef", staticClass: "top-container" },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v("在线用户（" + _vm._s(_vm.count) + "）"),
              ]),
              _vm._v(" "),
              _c(
                "ht-button",
                {
                  attrs: { type: "primary", icon: "el-icon-link" },
                  on: { click: _vm.copyMeetingUrl },
                },
                [_vm._v("复制会议链接")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "info" }, [
                _vm._v(_vm._s(_vm.isCopiedUrl ? "已复制" : "")),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "close-icon",
                attrs: { src: _vm.Close, alt: "" },
                on: { click: _vm.toggleBoxStatus },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c("contact-list-small", {
                ref: "contactListRef",
                attrs: { "user-list": _vm.userList },
                on: { invite: _vm.inviteUser },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }