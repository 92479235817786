var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { placement: "top", width: _vm.width },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { attrs: { slot: "default" }, slot: "default" }, [
        _c("p", [
          _c("i", {
            staticClass: "el-icon-warning",
            staticStyle: { color: "#e6a23c", "margin-right": "5px" },
          }),
          _vm._v(_vm._s(_vm.confirmTitle)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "text-align": "right", margin: "0" } },
          [
            _c(
              "ht-button",
              {
                attrs: { type: "text", size: "mini" },
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.negativeButtonTitle))]
            ),
            _vm._v(" "),
            _c(
              "ht-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.buttonClicked },
              },
              [_vm._v(_vm._s(_vm.positiveButtonTitle))]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.dangerText
        ? _c(
            "ht-button",
            {
              class: [
                _vm.disabled ? "disabled-btn" : "danger-btn",
                {
                  "is-disabled": _vm.disabled,
                },
              ],
              attrs: {
                slot: "reference",
                loading: _vm.loading,
                type: _vm.type,
                size: _vm.size,
                icon: _vm.icon,
                disabled: _vm.disabled,
              },
              slot: "reference",
            },
            [_vm._t("default")],
            2
          )
        : _c(
            "ht-button",
            {
              style: { color: _vm.buttonColor },
              attrs: {
                slot: "reference",
                loading: _vm.loading,
                type: _vm.type,
                size: _vm.size,
                icon: _vm.icon,
                disabled: _vm.disabled,
              },
              slot: "reference",
            },
            [_vm._t("default")],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }