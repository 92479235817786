<template>
  <div class="user-list-container">
    <template v-if="userList.length > 0">
      <div
        v-for="(user, index) in userList"
        :key="index"
        class="user-container"
        :class="{'online-user-container': user.isOnline, 'offline-user-container': !user.isOnline, 'in-meeting-user-container': user.isInGuidance}"
      >
        <el-image v-if="user.avatarUrl" class="avatar" :src="user.avatarUrl || AvatarBlue" />
        <dw-default-avatar
          v-else
          :full-name="user.fullName"
          :width="80"
          :font-size="26"
          :background="user.isOnline ? variables.primaryColor : '#eeeeee'"
          :color="user.isOnline ? '#ffffff' : '#aaaaaa'"
          style="margin-left: 25px;"
        />
        <span class="fullName text">{{ `${user.fullName}` }}<span v-if="!stringLongOverTen(user.userName)" class="userName">{{ `（${user.userName}）` }}</span> </span>
        <!--        <span class="role text">{{ getUserRoles(user.roles) }}</span>-->
        <span class="role text">{{ user.roleNames[0] }}</span>
        <span v-if="user.isInGuidance" class="in-meeting-status text status">会议中</span>
        <span v-else-if="user.isOnline" class="online-status text status">在线</span>
        <span v-else class="offline-status text status">离线</span>
        <div class="operation-container">
          <template v-if="user.isOnline & !user.isInGuidance">
            <div class="call-container">
              <svg-icon icon-class="call" class="call-icon" @click="call(user)" />
            </div>
            <el-checkbox v-model="user.checked" class="checkbox" />
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-container">
        <img class="empty-img" :src="Empty" alt="">
        <span class="empty-text">暂无在线用户</span>
      </div>
    </template>
  </div>
</template>

<script>
import AvatarBlue from '@/assets/image/newRemoteGuidance/avatar_blue.jpg'
import AvatarGrey from '@/assets/image/newRemoteGuidance/avatar_grey.png'
import MessageICon from '@/assets/image/newRemoteGuidance/message.png'
import Empty from '@/assets/image/newRemoteGuidance/empty.png'
import { getUserRoles } from '@/views/newRemoteGuidance/js/business'
import { stringLongOverTen } from '@/utils'
import variables from '@/styles/variables.scss'

export default {
  name: 'Contact',
  props: {
    userList: { type: Array, default: () => [] },
    onLineUserInfo: { type: Object, default: () => {} }
  },
  data() {
    return {
      AvatarBlue,
      AvatarGrey,
      MessageICon,
      Empty,
      isMobile: false
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    getUserRoles, stringLongOverTen,
    call(userInfo) {
      this.$emit('call', userInfo)
    },
    handleResize() {
      this.isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.user-list-container{
  text-align: center;
  width: 100%;
  min-width: 600px;
  .user-container{
    display: flex;
    height: 100px;
    margin: 20px 3px;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0 0 5px rgba(30, 30, 30, 0.2);
    .avatar{
      width: 80px;
      height: 80px;
      border-radius: 40px;
      margin-left: 30px;
    }
    .text{
      text-align: left;
      font-size: 18px;
      width: 100px;
    }

    .fullName{
      width: 200px;
      font-weight: bold;
      font-size: 20px;
      margin-left: 5px;
      .userName{
        font-weight: normal;
        font-size: 18px;
      }
    }

    .status{
      display: block;
      line-height: 30px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      border-radius: 10px;
    }

    .offline-status{
      width: 70px;
      height: 30px;
      color: #aaaaaa;
      background: #eeeeee;
      margin-right: 25px;
    }

    .online-status{
      width: 70px;
      height: 30px;
      color: #ffffff;
      background: $primaryColor;
      margin-right: 25px;
    }

    .in-meeting-status{
      width: 80px;
      height: 30px;
      color: #ffffff;
      background: #f56c6c;
      margin-right: 30px;
      margin-left: 10px;
    }

    .operation-container{
      display: flex;
      align-items: center;
      width: 120px;
      .call-container{
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin-right: 30px;
        .call-icon{
          width: 40px;
          height: 40px;
          //visibility: hidden;
          color: $primaryColor;
        }

        .call-icon:hover{
          cursor: pointer;
        }
      }
      .checkbox{
        margin-right: 30px;

        ::v-deep .el-checkbox__input{
          .el-checkbox__inner{
            width: 20px;
            height: 20px;
            border-radius: 20px;
          }
        }
        ::v-deep .el-checkbox__input .el-checkbox__inner::after{
          border: 2px solid #fff;
          border-radius: 2px;
          width: 6px;
          height: 10px;
          left: 5px;
          top: 1px;
          border-left: 0;
          border-top: 0;
        }
      }
    }
  }

  .user-container:hover {
    box-shadow: 0 0 10px rgba(7, 7, 7, 0.2);
    .call-container{
      .call-icon{
        visibility: visible;
      }
      .call-icon:hover {
        width: 40px;
        height: 40px;
      }
    }
  }

  .offline-user-container{
    .text{
      color: #aaaaaa;
    }

    .call-icon{
      color: #aaaaaa !important;
    }

    .call-icon:hover{
      cursor: not-allowed !important;
    }
  }

  .in-meeting-user-container{
    .call-icon{
      color: #aaaaaa !important;
    }

    .call-icon:hover{
      cursor: not-allowed !important;
    }
  }

  .empty-container{
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .empty-img{
      width: 120px;
      height: auto;
    }
    .empty-text{
      margin-top: 20px;
      font-size: 20px;
      color: #8a8a8a;
    }
  }
}
</style>
