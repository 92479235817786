<template>
  <div class="video-container">
    <video ref="videoPlayer" controls class="video" @play="handleCanPlay">
      <source :src="url" type="video/mp4">
    </video>
    <ht-button v-if="showDel" type="danger" icon="el-icon-delete" class="del-button" circle @click="deleteVideo" />
  </div>
</template>

<script>
export default {
  name: 'DwVideo',
  props: {
    url: { type: String, default: undefined },
    showDel: { type: Boolean, default: true }
  },
  methods: {
    deleteVideo() {
      this.$emit('delete-video')
    },
    handleCanPlay() {
      this.requestFullscreen()
    },
    requestFullscreen() {
      const videoPlayer = this.$refs.videoPlayer
      if (videoPlayer) {
        if (videoPlayer.requestFullscreen) {
          videoPlayer.requestFullscreen()
        } else if (videoPlayer.mozRequestFullScreen) { /* Firefox */
          videoPlayer.mozRequestFullScreen()
        } else if (videoPlayer.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          videoPlayer.webkitRequestFullscreen()
        } else if (videoPlayer.msRequestFullscreen) { /* IE/Edge */
          videoPlayer.msRequestFullscreen()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.video-container{
  position: relative;
  width: 100%;
  .video{
    width: 100%;
    border: 1px solid #b4bccc;
  }
  .del-button{
    position: absolute;
    bottom: 10px;
    right: 0;
  }
}
</style>
