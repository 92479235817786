<template>
  <div class="title-container" :class="{'is-mobile-title-container':isMobile }">
    <div class="title-span" :style="{background: spanColor}" />
    <div class="title-content" :style="{color: titleColor}">{{ title }}</div>
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'

export default {
  name: 'DwTitle',
  props: {
    title: { type: String, default: undefined },
    spanColor: { type: String, default: variables.primaryColor },
    titleColor: { type: String, default: variables.primaryColor },
    isMobile: { type: Boolean, default: false }
  },
  computed: {
    variables() {
      return variables
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.title-container{
  height: 50px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  .title-span{
    height: 25px;
    width: 5px;
    margin-right: 12px;
  }
  .title-content {
    font-size: 24px;
    font-weight: bold;
  }
}
.is-mobile-title-container{
  padding: 0;
  .title-span{
    height: 1.4rem !important;
    width: 4px;
    margin-right: 10px;
  }
  .title-content {
    font-size: 1.2rem !important;
  }
}
</style>
